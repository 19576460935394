import React from "react";
import { Page } from "framework7-react";
import EstimatLucratProiecte from "../Componente/EstimatLucratProiecte";

class EstimatLucratProiectePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            denumire: "Estimat/Lucrat Proiecte"
        }
    }

    render() {
        return (
            <Page
                className={"themeBackground"}
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
            >

                <div className="ptr3">
                    <EstimatLucratProiecte />
                </div>
            </Page>
        );
    }
}
export default EstimatLucratProiectePage;