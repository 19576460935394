import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";
import { Col, Row } from "framework7-react";
import SalariatiGridMeniu from "./SalariatiGridMeniu";

/**
 * @description Interfata de intretinere a meniurilor si accesului utilizatorilor la meniuri
 * @date 07/04/2022
 * @class Meniuri
 * @extends {React.Component}
 */
class Meniuri extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idSelected: null,
      idSelectedForCopy: null,
      selectedRowsData: [],
      dataLoaded: false,
      linkApi: "/api/Meniu",
      denumire: "Meniuri",
      linkApiSalariatiMeniu: "api/SalariatiMeniu",
      height: window.innerHeight - 34,
      dataSource: [],
      inputs2: [
        {
          key: "Id",
          keyBaza: "idSalariat",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
        { key: "Denumire", keyBaza: "denumire" },
        { key: "Checked", keyBaza: "Checked", tip: "number" },
        {
          key: "Ref",
          keyBaza: "refSalariat",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
      ],
      inputs: [
        { key: "Id", keyBaza: "id" },
        { key: "Denumire", keyBaza: "denumire", requiredAdd: true },
        { key: "Icon", keyBaza: "icon", requiredAdd: true },
        {
          key: "Parinte",
          keyBaza: "idParinte",
          tip: "selectbox",
          lista: this.$f7.data.Meniuri,
          requiredAdd: true,
        },
        { key: "AdresaURL", keyBaza: "adresaURL" },
        { key: "NivelAccess", keyBaza: "nivelAccess", requiredAdd: true },
        { key: "Ordine", keyBaza: "ordine", tip: "number", requiredAdd: true },
      ],
    };

    this.refDataGrid = React.createRef();
    this.refSalariatiGrid = React.createRef();
  }

  /**
   * @description Sterge din state elementele selectate pentru copiere a trasaturilor
   * @function
   * @date 07/04/2022
   * @memberof Meniuri
   */
  clearCopy = () => {
    this.setState(
      {
        selectedRowsData: [],
        idSelected: null,
        idSelectedForCopy: null,
      },
      () => {
        this.colorItems();
      }
    );
  };

  /**
   * @description Ia valorile salariatilor de au access la curentul meniu selectat
   * @function
   * @date 07/04/2022
   * @memberof Meniuri
   */
  GetValuesDreapta = () => {
    this.refSalariatiGrid.current.GetValues(this.state.idSelected);
  };

  /**
   * @description Adauga in baza de date inregistrare de copiaza trasaturile de la un meniu la altul
   * @function
   * @date 07/04/2022
   * @memberof Meniuri
   */
  SaveValuesCopiereSalariati = () => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiSalariatiMeniu +
          "/GetSalariatiMeniuriByMeniu/" +
          this.state.idSelected
      )
      .then((response) => {
        var date = response.data;
        var arrAdd = [];
        this.state.selectedRowsData.map((r) => {
          if (
            date.filter((f) => {
              return r.Id === f.id_salariat;
            }).length === 0
          ) {
            //ADAUGA IN BAZA ELEMENTUL obj!
            var obj = {
              id_salariat: r.Id,
              id_meniu: this.state.idSelected,
            };
            //add obj;
            arrAdd.push(obj);
          }
          return null;
        });

        arrAdd
          .reduce((promise, item) => {
            return promise.then(() => {
              return this.AddValueSalariatiMeniu(item)
                .then((r) => {})
                .catch((err) => {
                  console.log("err", err);
                });
            });
          }, Promise.resolve())
          .then(() => {
            this.$f7.preloader.hide();
            this.GetValuesDreapta();
          })
          .catch(() => {
            console.log("Eroare");
            this.$f7.preloader.hide();
          });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  /**
   * @description Adauga inregistrare cu accesul unui salariat la un meniu
   * @function
   * @date 07/04/2022
   * @param {*} data
   * @memberof Meniuri
   */
  AddValueSalariatiMeniu = (data) => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL +
        this.state.linkApiSalariatiMeniu +
        "/PostSALARIATI_MENIU";
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Ia valorile de sunt atribuite la copierea de salariati
   * @function
   * @date 07/04/2022
   * @memberof Meniuri
   */
  GetValuesCopiereSalariati = () => {
    return new Promise((resolve, reject) => {
      var url =
        this.state.linkApiSalariatiMeniu +
        "/GetSalariatiMeniuri/" +
        this.state.idSelectedForCopy;
      axios
        .get(url)
        .then((response) => {
          response.data.map((element) => {
            element = this.$f7.methods.restructurareBaza(
              element,
              this.state.inputs2,
              "key"
            );
            return null;
          });
          var bazaSalariati = this.$f7.methods.reconstructieBaza(
            response,
            this.state.inputs2
          );
          var allSelectedRows = bazaSalariati.filter((r) => {
            return r.Checked === 1;
          });
          this.setState(
            {
              selectedRowsData: allSelectedRows,
            },
            () => {
              resolve("a");
            }
          );
        })
        .catch((error) => {
          reject(error);
          this.$f7.methods.openNotification("Error:", error);
        });
    });
  };

  /**
   * @description Ia lista cu meniurile din baza de date
   * @function
   * @date 07/04/2022
   * @memberof Meniuri
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/Getmeniu")
      .then((response) => {
        var dataMeniu = { ...response }.data;

        this.$f7.data.MeniuriRandare = [...dataMeniu];

        this.$f7.methods.IaValori();
        var date = { ...response };
        date.data.map((eveniment) => {
          eveniment = this.$f7.methods.restructurareBaza(
            eveniment,
            this.state.inputs,
            "key"
          );
          return false;
        });

        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  if (baza.length > 0) {
                    this.refDataGrid.current.setRenderItems(baza);
                  }
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.refDataGrid.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Adauga o noua inregistrare in baza de date(Meniu)
   * @function
   * @date 07/04/2022
   * @param {*} data obiect cu valorile noului meniu
   * @memberof Meniuri
   */
  AddValue = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApi + "/Postmeniu";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza in baza de date inregistrarea selectata(Meniu)
   * @date 07/04/2022
   * @function
   * @param {*} obj noile valori ale inregistrarii selectate
   * @param {*} id id-ul inregistrarii selectate
   * @memberof Meniuri
   */
  UpdateValue = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/Getmeniu/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL + this.state.linkApi + "/Putmeniu/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Sterge din baza de date inregistrarea selectata(Meniu)
   * @date 07/04/2022
   * @param {*} id
   * @function
   * @memberof Meniuri
   */
  DeleteValue = (id) => {
    var url = this.$f7.data.baseURL + this.state.linkApi + "/Deletemeniu/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  componentDidMount = () => {
    this.GetValues();

    document.addEventListener("keydown", (e) => {
      var key = e.keyCode;
      if (key === 27) {
        this.clearCopy();
      }
    });
  };

  /**
   * @description Functie de coloreaza daca un meniu este selectat pentru copiere a trasaturilor
   * @date 07/04/2022
   * @param {*} state state-ul datagridului
   * @param {*} context context-ul datagridului
   * @function
   * @memberof Meniuri
   */
  colorItems = (state, context) => {
    if (context) {
      var tableName = context.tableName;

      var truri = document.getElementsByClassName("datagridTr");
      for (var i = 0; i < truri.length; i++) {
        var elem = truri[i];
        if (elem) {
          elem.classList.remove("dottedForCopy");
        }
      }
      if (state && tableName) {
        var itemeRandate = state.renderItems.slice(
          state.numberBefore,
          state.numberBefore + state.count
        );
        itemeRandate.forEach((elem, index) => {
          var tar = document.getElementById("trMain" + tableName + index);
          if (tar) {
            if (elem.Id === this.state.idSelectedForCopy) {
              tar.classList.add("dottedForCopy");
            } else {
              tar.classList.remove("dottedForCopy");
            }
          }
        });
      }
    }
  };

  render() {
    return (
      <Row>
        <Col width={70}>
          <DataGrid
            refresh={this.GetValues}
            dataLoaded={this.state.dataLoaded}
            name={"Meniuri"}
            ref={this.refDataGrid}
            renderUpdate={true}
            renderDelete={true}
            renderAdd={true}
            maxHeight={this.state.height}
            dataSource={this.state.dataSource}
            inputs={this.state.inputs}
            contextMenuItems={(state, context) => {
              return [
                {
                  key: "Copiere trasaturi",
                  onClick: (state2) => {
                    setTimeout(() => {
                      var id = state2.rowData.Id;
                      this.setState({ idSelectedForCopy: id }, () => {
                        this.GetValuesCopiereSalariati()
                          .then((r) => {
                            this.colorItems(state, context);
                          })
                          .catch(() => {});
                      });
                    }, 500);
                  },
                  icon: "play_arrow",
                },
                {
                  disabled: this.state.idSelectedForCopy === null,
                  key: "Aplica trasaturi",
                  onClick: (state2) => {
                    setTimeout(() => {
                      var id = state2.rowData.Id;
                      this.setState({ idSelected: id }, () => {
                        this.SaveValuesCopiereSalariati();
                      });
                    }, 500);
                  },
                  icon: "play_arrow",
                },
              ];
            }}
            renderAccordion={true}
            id={"Id"}
            refId={"Parinte"}
            initialRefId={null}
            onUpdate={(state) => {
              var eveniment = { ...state.editedItem };
              eveniment = this.$f7.methods.restructurareBaza(
                eveniment,
                this.state.inputs,
                "keyBaza"
              );
              if (eveniment.adresaURL === "") {
                eveniment.adresaURL = null;
              }
              this.UpdateValue(eveniment, eveniment.id);
            }}
            onAdd={(state) => {
              var eveniment = { ...state.editedItem };
              eveniment = this.$f7.methods.restructurareBaza(
                eveniment,
                this.state.inputs,
                "keyBaza"
              );
              if (eveniment.adresaURL === "") {
                eveniment.adresaURL = null;
              }
              delete eveniment["id"];
              this.AddValue(eveniment);
            }}
            onDelete={(state) => {
              var eveniment = { ...state.editedItem };
              var id = eveniment["Id"];
              this.DeleteValue(id);
            }}
            onRowClick={(state) => {
              this.setState({ idSelected: state.rowData.Id }, () => {
                this.GetValuesDreapta();
              });
            }}
          />
        </Col>
        <Col style={{ position: "relative" }} width={30}>
          <SalariatiGridMeniu ref={this.refSalariatiGrid} />
        </Col>
      </Row>
    );
  }
}
export default Meniuri;
