import React from "react";
import { Page } from "framework7-react";
import OreLucratePeZi2 from "../Componente/OreLucratePeZi2";

class OreLucratePeZi2Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = { denumire: "Ore lucrate pe zi" };
    }
    render() {
        return (
            <Page

                className={"themeBackground"}
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
            >

                <div className="ptr3">
                    <OreLucratePeZi2 />
                </div>
            </Page>
        );
    }
}
export default OreLucratePeZi2Page;