import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import { Tabs, Toolbar, Link, Tab, Page } from "framework7-react";
import axios from "axios";

/**
 * @description Interfata de afisare a veniturilor per task selectat
 * @date 08/04/2022
 * @class Venituri
 * @extends {React.Component}
 */
class Venituri extends React.Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;

    this.state = {
      dataLoaded: false,
      id: null,
      tab: 1,
      linkApi: "/api/Venituri",
      height: parseInt(window.innerHeight / 2),
      heightDiv: window.innerHeight - 40,
      interfaceName: Math.random().toString(36).substring(7).toString(),
      dataSource: [],
      inputsTop: [
        { key: "id", tip: "number" },
        {
          key: "idProiect",
          tip: "number",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "tipVenit",
          tip: "number",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "denumire",
          requiredAdd: true,
          requiredEdit: true,
        },
      ],
      dataSourceEstimat: [],
      inputsEstimat: [
        { key: "id", tip: "number" },
        {
          key: "suma",
          tip: "number",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "deLa",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "panaLa",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
        },
      ],
      dataSourceRealizat: [],
      inputsRealizat: [
        { key: "id", tip: "number" },
        {
          key: "suma",
          tip: "number",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "deLa",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "panaLa",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
        },
      ],
      dataSourceSalariati: [],
      inputsSalariati: [
        { key: "nume" },
        {
          key: "procent",
          tip: "number",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "suma",
          tip: "number",
          requiredAdd: true,
          requiredEdit: true,
        },
      ],
    };

    this.refDataGrid = React.createRef();
    this.refDataGridEstimat = React.createRef();
    this.refDataGridSalariati = React.createRef();
    this.refDataGridRealizat = React.createRef();
  }

  componentDidMount = () => {
    if (this.$f7.data.Proiect !== null) this.GetValues();
  };

  /**
   * @description Ia toate veniturile pe taskul selectat
   * @function
   * @date 08/04/2022
   * @memberof Venituri
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetVenituri/" +
          this.$f7.data.Proiect.iD_PROIECT
      )
      .then((response) => {
        var baza = response.data;
        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  if (baza.length > 0) {
                    this.refDataGrid.current.setRenderItems(baza);
                  }
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.refDataGrid.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor despre venituri de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Ia toate veniturile estimate pe taskul selectat
   * @function
   * @date 08/04/2022
   * @param {*} id id-ul taskului selectat
   * @memberof Venituri
   */
  GetValuesEstimat = (id) => {
    axios
      .get(
        this.$f7.data.baseURL + this.state.linkApi + "/GetVenituriEstimat/" + id
      )
      .then((response) => {
        var baza = response.data;
        this.setState(
          {
            dataSourceEstimat: baza,
          },
          () => {
            if (baza.length > 0) {
              this.refDataGridEstimat.current.setRenderItems(baza);
            }
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSourceEstimat: [],
          },
          () => {
            this.refDataGridEstimat.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor despre veniturile estimate de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesEstimat();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Ia veniturile estimate pe salariatul selectat
   * @date 08/04/2022
   * @param {*} id id-ul salariatului
   * @function
   * @memberof Venituri
   */
  GetValuesEstimatSalariati = (id) => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetVenituriEstimatSalariati/" +
          id
      )
      .then((response) => {
        var baza = response.data;

        this.setState(
          {
            dataSourceSalariati: baza,
          },
          () => {
            if (baza.length > 0) {
              this.refDataGridSalariati.current.setRenderItems(baza);
            }
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSourceSalariati: [],
          },
          () => {
            this.refDataGridSalariati.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor despre salariati de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesEstimatSalariati();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Ia realizarile pe venitul selectat
   * @function
   * @date 08/04/2022
   * @param {*} id id-ul realizarii
   * @memberof Venituri
   */
  GetValuesRealizat = (id) => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetVenituriRealizat/" +
          id
      )
      .then((response) => {
        var baza = response.data;
        this.setState(
          {
            dataSourceRealizat: baza,
          },
          () => {
            if (baza.length > 0) {
              this.refDataGridRealizat.current.setRenderItems(baza);
            }
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSourceRealizat: [],
          },
          () => {
            this.refDataGridRealizat.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor despre veniturile realizate de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesRealizat();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Ia realizarile pe salariatul selectat
   * @date 08/04/2022
   * @function
   * @param {*} id id-ulsalariatului selectat
   * @memberof Venituri
   */
  GetValuesRealizatSalariati = (id) => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetVenituriRealizatSalariati/" +
          id
      )
      .then((response) => {
        var baza = response.data;

        this.setState(
          {
            dataSourceSalariati: baza,
          },
          () => {
            if (baza.length > 0) {
              this.refDataGridSalariati.current.setRenderItems(baza);
            }
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSourceSalariati: [],
          },
          () => {
            this.refDataGridSalariati.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor despre salariati de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesRealizatSalariati();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  render() {
    return (
      <Page pageContent={false}>
        {this.$f7.data.Proiect !== null ? (
          <div style={{ maxHeight: this.state.heightDiv, overflow: "auto" }}>
            <DataGrid
              dataLoaded={this.state.dataLoaded}
              refresh={this.GetValues}
              name={"Venituri1"}
              ref={this.refDataGrid}
              inputs={this.state.inputsTop}
              renderUpdate={false}
              renderDelete={false}
              renderAdd={false}
              maxHeight={this.state.height}
              dataSource={this.state.dataSource}
              onRowClick={(state) => {
                this.setState({ id: state.rowData.id }, () => {
                  if (this.state.tab === 1) {
                    this.GetValuesEstimat(state.rowData.id);
                  } else {
                    this.GetValuesRealizat(state.rowData.id);
                  }
                });
              }}
            />

            {this.state.id !== null ? (
              <>
                <Toolbar
                  className="tbTop"
                  id={"tbarVenituri" + this.state.interfaceName}
                  tabbar={true}
                  noShadow
                  style={{ borderRadius: "16px 16px 16px 16px" }}
                  top>
                  <Link
                    tooltip={"Venituri estimate"}
                    onClick={() => {
                      this.setState(
                        {
                          tab: 1,
                        },
                        () => {
                          if (this.state.id !== null) {
                            this.GetValuesEstimat(this.state.id);
                          }
                        }
                      );
                    }}
                    tabLink={"#tab-VenituriEstimat" + this.state.interfaceName}
                    tabLinkActive>
                    Estimat
                  </Link>
                  <Link
                    tooltip={"Venituri realizate"}
                    onClick={() => {
                      this.setState(
                        {
                          tab: 2,
                        },
                        () => {
                          if (this.state.id !== null) {
                            this.GetValuesRealizat(this.state.id);
                          }
                        }
                      );
                    }}
                    tabLink={
                      "#tab-VenituriRealizat" + this.state.interfaceName
                    }>
                    Realizat
                  </Link>
                </Toolbar>

                <Tabs>
                  <Tab
                    id={"tab-VenituriEstimat" + this.state.interfaceName}
                    className="page-content"
                    tabActive>
                    <DataGrid
                      name={"Venituri2"}
                      ref={this.refDataGridEstimat}
                      inputs={this.state.inputsEstimat}
                      renderUpdate={false}
                      renderDelete={false}
                      renderAdd={false}
                      maxHeight={this.state.height}
                      dataSource={this.state.dataSourceEstimat}
                      onRowClick={(state) => {
                        this.GetValuesEstimatSalariati(state.rowData.id);
                      }}
                    />
                  </Tab>
                  <Tab
                    id={"tab-VenituriRealizat" + this.state.interfaceName}
                    className="page-content">
                    <DataGrid
                      name={"Venituri3"}
                      ref={this.refDataGridRealizat}
                      renderUpdate={false}
                      renderDelete={false}
                      renderAdd={false}
                      maxHeight={this.state.height}
                      inputs={this.state.inputsRealizat}
                      dataSource={this.state.dataSourceRealizat}
                      onRowClick={(state) => {
                        this.GetValuesRealizatSalariati(state.rowData.id);
                      }}
                    />
                  </Tab>
                </Tabs>

                <DataGrid
                  name={"Venituri4"}
                  ref={this.refDataGridSalariati}
                  renderUpdate={false}
                  renderDelete={false}
                  renderAdd={false}
                  maxHeight={this.state.height}
                  inputs={this.state.inputsSalariati}
                  dataSource={this.state.dataSourceSalariati}
                />
              </>
            ) : (
              <div
                style={{
                  marginTop: "calc((50vh / 2) - 50px)",
                  fontSize: "20px",
                }}>
                Nu a fost selectat nici un element
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              height: "calc(100vh - 50px)",
            }}>
            <h3
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                right: 0,
                transform: "translateY(-50%)",
                textTransform: "uppercase",
                textAlign: "center",
                display: "block",
              }}>
              Nu ati selectat nimic
            </h3>
          </div>
        )}
      </Page>
    );
  }
}
export default Venituri;
