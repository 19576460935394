import React from "react";
import {
  Popup,
  Page,
  Navbar,
  Row,
  Col,
  Popover,
  Segmented,
  List,
  ListItem,
  ListInput,
  NavRight,
  Link,
  Searchbar,
  Icon,
  Button,
  ListGroup,
  Range,
} from "framework7-react";
import config from "../../config";
import moment from "moment";
import TreeList, { SearchPanel } from "devextreme-react/tree-list";
import DraggingPanel from "./DraggingPanel";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";

var settings2 = {
  width: "100",
  xsmall: "100",
  small: "100",
  medium: "50",
  large: "50",
  xlarge: "50",
};
var count = Math.floor((window.innerHeight - 138) / 36);
var countMic = Math.floor(240 / 36);

/**
 * @description Interfata dinamica de randare a unui formular
 * @date 08/04/2022
 * @class PopupAddEdit
 * @extends {React.PureComponent}
 */
class PopupAddEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.desktop = this.$f7.device.desktop;
    var keyuri = [];
    var editItem = {};
    var groups = [];
    var existaElemFaraGrupa = false;
    console.log("props", props.inputs);
    if (props.inputs) {
      for (var e in props.inputs) {
        var denumire = props.inputs[e].key;
        var inputCurent = props.inputs[e];
        if (inputCurent.tip) {
          if (inputCurent.tip === "selectbox") {
            if (inputCurent.lista.length > 0) {
              editItem[denumire] = inputCurent.lista[0].value;
            }
            if (this.props.allRequired || inputCurent.required) {
              editItem[denumire] = "valoarePentruRequired";
              if (inputCurent.defaultValue !== undefined) {
                editItem[denumire] = inputCurent.defaultValue;
              }
            }
          } else if (inputCurent.tip === "selectboxmultiplu") {
            editItem[denumire] = [];
            if (this.props.allRequired || inputCurent.required) {
              editItem[denumire] = "valoarePentruRequired";
              if (inputCurent.defaultValue !== undefined) {
                editItem[denumire] = inputCurent.defaultValue;
              }
            }
          } else {
            if (inputCurent.tip === "bool") {
              editItem[denumire] = false;
              if (this.props.allRequired || inputCurent.required) {
                editItem[denumire] = "valoarePentruRequired";
                if (inputCurent.defaultValue !== undefined) {
                  editItem[denumire] = inputCurent.defaultValue;
                }
              }
            } else if (inputCurent.tip === "datebox") {
              editItem[denumire] = moment(Date.now()).format();
              if (inputCurent.defaultValue !== undefined) {
                editItem[denumire] = inputCurent.defaultValue;
              }
            } else if (inputCurent.tip === "colorpicker") {
              editItem[denumire] = "#ffffff";
              if (inputCurent.defaultValue !== undefined) {
                editItem[denumire] = inputCurent.defaultValue;
              }
            } else if (inputCurent.tip === "number") {
              editItem[denumire] = 0;
              if (inputCurent.defaultValue !== undefined) {
                editItem[denumire] = inputCurent.defaultValue;
              }
            } else {
              editItem[denumire] =
                this.props.editIndex !== undefined
                  ? this.props.dataSource[this.props.editIndex][denumire]
                  : "";
              if (inputCurent.defaultValue !== undefined) {
                editItem[denumire] = inputCurent.defaultValue;
              }
            }
          }
        } else {
          editItem[denumire] =
            this.props.editIndex !== undefined
              ? this.props.dataSource[this.props.editIndex][denumire]
              : "";
          if (inputCurent.defaultValue !== undefined) {
            editItem[denumire] = inputCurent.defaultValue;
          }
        }
        keyuri.push(denumire);

        if (inputCurent.group !== undefined) {
          if (!groups.includes(inputCurent.group)) {
            groups.push(inputCurent.group);
          }
        } else {
          existaElemFaraGrupa = true;
        }
      }
    }
    console.log(editItem);
    if (existaElemFaraGrupa === true) {
      groups.push("");
    }
    this.groups = groups;
    this.state = {
      speechDenumire: "",
      speechStarted: false,
      maxDepth: 0,
      numberBeforeAddEdit: 0,
      lista: [],
      listaRender: [],
      editIndex: props.editIndex,
      dataSource: props.dataSource !== undefined ? props.dataSource : [],
      inputs: props.inputs,
      editedItem:
        props.editIndex !== undefined && props.dataSource !== undefined
          ? props.dataSource[props.editIndex]
          : editItem,
      tableName:
        "PopupAddEdit-" + this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx"),
      tableName2:
        "PopupAddEditInsidePopup-" +
        this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx"),
      tableName3:
        "PopupAddEditInsidePopover-" +
        this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx"),
      tableName4:
        "PopupAddEditInsidePopover-" +
        this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx"),
      searchName:
        "PopupAddEditSearch-" + this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx"),
      searchName2:
        "PopoverAddEditSearch-" + this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx"),
      keys: keyuri,
      listaBool: [
        { key: "Fara", value: null, denumire: "Fara" },
        { key: "False", value: false, denumire: "False" },
        { key: "True", value: true, denumire: "True" },
      ],
      inputSelectat: this.props.inputs[0],
    };
    this.refPopover = React.createRef();
  }

  /**
   * @description Functie de cautare in selectboxul deschis
   * @function
   * @date 08/04/2022
   * @param {*} value valoarea de cautat
   * @memberof PopupAddEdit
   */
  searchAddEdit = (value) => {
    var toSearch = value.toString().toLowerCase().replace(/\s/g, "");
    var result = this.state.lista.filter((elemBaza) => {
      var res = false;
      if (elemBaza["key"]) {
        var valoare = elemBaza["key"]
          .toString()
          .toLowerCase()
          .replace(/\s/g, "");
        if (valoare.includes(toSearch)) {
          res = true;
        }
      }
      return res;
    });
    this.setState({ listaRender: result, numberBeforeAddEdit: 0 });
  };

  /**
   * @description Deschide selectboxul de tip treelist la nivelul dorit
   * @function
   * @date 08/04/2022
   * @param {*} level nivelul dorit
   * @memberof PopupAddEdit
   */
  expandToLevel = (level) => {
    this.setState({ level: level }, () => {
      this.$f7.preloader.show();
      this.state.componentaTree.forEachNode((nod) => {
        console.log(nod);
        if (nod.level < this.state.level) {
          this.state.componentaTree.expandRow(nod.data.value);
        } else {
          this.state.componentaTree.collapseRow(nod.data.value);
        }
      });
      this.$f7.preloader.hide();
    });
  };

  /**
   * @description Functia ce returneaza inputul dorit, pentru fiecare tip de input se vor face diferite setari la ListInput, pentru number spre exemplu se pune type=number etc
   * @date 08/04/2022
   * @param {*} denumire denumirea inputului curent
   * @param {*} index index-ul inputului curent
   * @param {*} maiExista bool, defineste daca mai exista elemente dupa inputul curent
   * @function
   * @memberof PopupAddEdit
   */
  renderInput = (denumire, index, maiExista) => {
    var toRender;
    if (this.state.inputs !== undefined) {
      var existaKey;
      var tip = "";
      var req = false;
      var lista = [];
      var bool = false;
      var inputCurent = null;
      for (var i in this.state.inputs) {
        if (this.state.inputs[i].key === denumire) {
          inputCurent = this.state.inputs[i];
          existaKey = true;
          tip = this.state.inputs[i].tip;
          if (tip === "selectbox" || tip === "selectboxmultiplu") {
            lista = this.state.inputs[i].lista;
          } else if (tip === "bool") {
            bool = true;
          }
          req = this.state.inputs[i].required;
        }
      }
      var valoarea = this.state.editedItem[denumire];
      var den = undefined;
      if (lista.length > 0 && tip === "selectbox") {
        var l = lista.filter((r) => {
          return r.value === valoarea;
        });
        if (l.length > 0) {
          den = l[0].key;
        }
      }

      if (
        lista.length > 0 &&
        tip === "selectboxmultiplu" &&
        valoarea !== "valoarePentruRequired"
      ) {
        lista
          .filter((r) => {
            return r.value !== null;
          })
          .map((f, index, arr) => {
            if (valoarea.includes(f.value.toString())) {
              if (den === undefined) {
                den = "";
              }
              if (index > 0) {
                den += ", " + f.key;
              } else {
                den += " " + f.key;
              }
            }
            return null;
          });
      }

      var denBool = undefined;
      if (bool) {
        var ll = this.state.listaBool.filter((r) => {
          return r.value === valoarea;
        });
        if (ll.length > 0) {
          denBool = ll[0].key;
        }
      }

      if (existaKey) {
        switch (tip) {
          case "filepicker": {
            toRender = (
              <ListInput
                disabled={
                  denumire === this.props.id ||
                  (inputCurent.disabled !== undefined &&
                    inputCurent.disabled === true)
                    ? true
                    : false
                }
                key={"file-" + this.state.tableName + denumire + index}
                floatingLabel
                label={denumire + (this.props.allRequired || req ? "*" : "")}
                type="file"
                placeholder={denumire}
                onInput={(e) => {
                  var fisier = e.target.files[0];
                  var editItem = { ...this.state.editedItem };
                  editItem[denumire] = fisier;
                  this.setState({ editedItem: editItem }, () => {});
                }}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="security"></Icon>
              </ListInput>
            );
            break;
          }
          case "filepickermultiplu": {
            toRender = (
              <ListInput
                disabled={
                  denumire === this.props.id ||
                  (inputCurent.disabled !== undefined &&
                    inputCurent.disabled === true)
                    ? true
                    : false
                }
                key={"filemultiplu-" + this.state.tableName + denumire + index}
                floatingLabel
                label={denumire + (this.props.allRequired || req ? "*" : "")}
                type="file"
                multiple
                placeholder={denumire}
                onInput={(e) => {
                  var fisiere = e.target.files;
                  var editItem = { ...this.state.editedItem };
                  editItem[denumire] = fisiere;
                  this.setState({ editedItem: editItem }, () => {});
                }}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="security"></Icon>
              </ListInput>
            );
            break;
          }
          case "colorpicker": {
            toRender = (
              <ListInput
                disabled={
                  denumire === this.props.id ||
                  (inputCurent.disabled !== undefined &&
                    inputCurent.disabled === true)
                    ? true
                    : false
                }
                key={"colorpicker-" + this.state.tableName + denumire + index}
                floatingLabel
                label={denumire + (this.props.allRequired || req ? "*" : "")}
                required={this.props.allRequired || req}
                validate={this.props.allRequired || req}
                type="colorpicker"
                placeholder={denumire}
                value={{
                  hex:
                    valoarea === "" || valoarea === null ? "#ffffff" : valoarea,
                }}
                colorPickerParams={{
                  value: {
                    hex: valoarea === "" ? "#ffffff" : valoarea,
                  },
                  openIn: "popup",
                  popupTabletFullscreen: true,
                  popupPush: true,
                  modules: [
                    "initial-current-colors",
                    "sb-spectrum",
                    "rgb-sliders",
                    "palette",
                  ],
                  sliderValue: true,
                  sliderValueEditable: true,
                  sliderLabel: true,
                  hexLabel: true,
                  hexValueEditable: true,
                  groupedModules: true,
                  palette: config.palette,
                  on: {
                    close: (e) => {
                      var valoare = e.value.hex;
                      var editItem = {
                        ...this.state.editedItem,
                      };
                      editItem[denumire] = valoare;
                      this.setState({ editedItem: editItem });
                    },
                  },
                }}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="money"></Icon>
              </ListInput>
            );
            break;
          }
          case "password": {
            toRender = (
              <ListInput
                maxlength={
                  inputCurent.maxlength !== undefined
                    ? inputCurent.maxlength
                    : 500
                }
                disabled={
                  denumire === this.props.id ||
                  (inputCurent.disabled !== undefined &&
                    inputCurent.disabled === true)
                    ? true
                    : false
                }
                key={"password-" + this.state.tableName + denumire + index}
                inputmode="text"
                floatingLabel
                label={denumire + (this.props.allRequired || req ? "*" : "")}
                required={this.props.allRequired || req}
                validate={this.props.allRequired || req}
                type="password"
                placeholder={denumire}
                value={
                  valoarea !== null && valoarea !== undefined ? valoarea : ""
                }
                onInput={(e) => {
                  var editItem = { ...this.state.editedItem };
                  editItem[denumire] = e.target.value;
                  this.setState({ editedItem: editItem }, () => {});
                }}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="security"></Icon>
              </ListInput>
            );
            break;
          }
          case "email": {
            toRender = (
              <ListInput
                maxlength={
                  inputCurent.maxlength !== undefined
                    ? inputCurent.maxlength
                    : 500
                }
                disabled={
                  denumire === this.props.id ||
                  (inputCurent.disabled !== undefined &&
                    inputCurent.disabled === true)
                    ? true
                    : false
                }
                key={"email-" + this.state.tableName + denumire + index}
                floatingLabel
                inputmode="email"
                label={denumire + (this.props.allRequired || req ? "*" : "")}
                required={this.props.allRequired || req}
                validate={this.props.allRequired || req}
                type="email"
                placeholder={denumire}
                value={
                  valoarea !== null && valoarea !== undefined ? valoarea : ""
                }
                onInput={(e) => {
                  var editItem = { ...this.state.editedItem };
                  editItem[denumire] = e.target.value;
                  this.setState({ editedItem: editItem }, () => {});
                }}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="email"></Icon>
              </ListInput>
            );
            break;
          }
          case "phone": {
            toRender = (
              <ListInput
                disabled={
                  denumire === this.props.id ||
                  (inputCurent.disabled !== undefined &&
                    inputCurent.disabled === true)
                    ? true
                    : false
                }
                key={"phone-" + this.state.tableName + denumire + index}
                floatingLabel
                label={denumire + (this.props.allRequired || req ? "*" : "")}
                required={this.props.allRequired || req}
                validate={this.props.allRequired || req}
                inputmode="tel"
                type="tel"
                minlength={10}
                maxlength={10}
                placeholder={denumire}
                value={
                  valoarea !== null && valoarea !== undefined ? valoarea : ""
                }
                onInput={(e) => {
                  var editItem = { ...this.state.editedItem };
                  editItem[denumire] = e.target.value;
                  this.setState({ editedItem: editItem }, () => {});
                }}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="phone"></Icon>
              </ListInput>
            );
            break;
          }
          case "decimal": {
            toRender = (
              <ListInput
                disabled={
                  denumire === this.props.id ||
                  (inputCurent.disabled !== undefined &&
                    inputCurent.disabled === true)
                    ? true
                    : false
                }
                key={"number-" + this.state.tableName + denumire + index}
                required={this.props.allRequired || req}
                validate={this.props.allRequired || req}
                floatingLabel
                type="number"
                step="0.01"
                label={denumire + (this.props.allRequired || req ? "*" : "")}
                placeholder={denumire}
                value={valoarea === "" ? 0 : valoarea}
                onInput={(e) => {
                  var editItem = { ...this.state.editedItem };
                  editItem[denumire] = e.target.value;
                  this.setState({ editedItem: editItem }, () => {});
                }}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="money"></Icon>
              </ListInput>
            );
            break;
          }

          case "number": {
            toRender = (
              <ListInput
                disabled={
                  denumire === this.props.id ||
                  (inputCurent.disabled !== undefined &&
                    inputCurent.disabled === true)
                    ? true
                    : false
                }
                key={"number-" + this.state.tableName + denumire + index}
                required={this.props.allRequired || req}
                validate={this.props.allRequired || req}
                floatingLabel
                inputmode="decimal"
                step="0.01"
                label={denumire + (this.props.allRequired || req ? "*" : "")}
                type="number"
                placeholder={denumire}
                value={valoarea === "" ? 0 : valoarea}
                onInput={(e) => {
                  var editItem = { ...this.state.editedItem };
                  editItem[denumire] = parseInt(e.target.value);
                  this.setState({ editedItem: editItem }, () => {});
                }}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="money"></Icon>
              </ListInput>
            );
            break;
          }
          case "datebox": {
            var minDate =
              inputCurent.minDate !== undefined ? inputCurent.minDate : null;
            var maxDate =
              inputCurent.maxDate !== undefined ? inputCurent.maxDate : null;

            toRender = (
              <ListInput
                locale="ro-RO"
                disabled={
                  denumire === this.props.id ||
                  (inputCurent.disabled !== undefined &&
                    inputCurent.disabled === true)
                    ? true
                    : false
                }
                id={"datebox-" + this.state.tableName + denumire + index}
                inputId={
                  "inputdatebox-" + this.state.tableName + denumire + index
                }
                key={"datebox-" + this.state.tableName + denumire + index}
                required={this.props.allRequired || req}
                validate={this.props.allRequired || req}
                floatingLabel
                label={
                  denumire +
                  " (LL/ZZ/AAAA HH:MM)" +
                  (this.props.allRequired || req ? "*" : "")
                }
                type="datetime-local"
                placeholder={denumire}
                calendarParams={{
                  dateFormat: "YYYY-MM-DDThh:mm",
                }}
                min={minDate}
                max={maxDate}
                onChange={(e) => {
                  var val = moment(e.target.value).format("YYYY-MM-DDTHH:mm");
                  var editItem = { ...this.state.editedItem };
                  editItem[denumire] = moment(val).format();
                  this.setState({ editedItem: editItem });
                }}
                value={[
                  valoarea === "" || valoarea === null
                    ? moment().format("YYYY-MM-DDTHH:mm")
                    : moment(valoarea).format("YYYY-MM-DDTHH:mm"),
                ]}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="today"></Icon>
              </ListInput>
            );
            break;
          }

          case "bool": {
            toRender = (
              <ListInput
                disabled={
                  denumire === this.props.id ||
                  (inputCurent.disabled !== undefined &&
                    inputCurent.disabled === true)
                    ? true
                    : false
                }
                key={"bool-" + this.state.tableName + denumire + index}
                className="roundedOutlinedSmartSelect"
                required={this.props.allRequired || req}
                validate={this.props.allRequired || req}
                floatingLabel
                label={denumire + (this.props.allRequired || req ? "*" : "")}
                value={denBool}
                title={denumire}
                onFocus={(e) => {
                  var t = e.currentTarget;
                  var inputSelectat = this.props.inputs.find((r) => {
                    return r.key === denumire;
                  });
                  this.setState(
                    {
                      listaRender: this.state.listaBool,
                      lista: this.state.listaBool,
                      denumire: denumire,
                      inputSelectat: inputSelectat,
                    },
                    () => {
                      if (this.desktop) {
                        this.$f7.popover.open("." + this.state.tableName3, t);
                      } else {
                        this.$f7.popup.open("." + this.state.tableName2);
                      }
                    }
                  );
                }}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="toggle_on"></Icon>
              </ListInput>
            );
            break;
          }
          case "selectbox": {
            toRender = (
              <ListInput
                disabled={
                  denumire === this.props.id ||
                  (inputCurent.disabled !== undefined &&
                    inputCurent.disabled === true)
                    ? true
                    : false
                }
                type="text"
                placeholder={denumire}
                value={den}
                key={"selectbox-" + this.state.tableName + denumire}
                floatingLabel
                label={denumire + (this.props.allRequired || req ? "*" : "")}
                onFocus={(e) => {
                  var t = e.currentTarget;
                  var inputSelectat = this.props.inputs.find((r) => {
                    return r.key === denumire;
                  });
                  var lis = lista;
                  lis.map((r) => {
                    if (r.ref === undefined) {
                      r.ref = -1;
                    }
                    return null;
                  });
                  this.setState(
                    {
                      listaRender: lis,
                      lista: lis,
                      denumire: denumire,
                      inputSelectat: inputSelectat,
                    },
                    () => {
                      if (this.desktop) {
                        this.$f7.popover.open("." + this.state.tableName4, t);
                      } else {
                        this.$f7.popup.open("." + this.state.tableName2);
                      }
                    }
                  );
                }}
                required={this.props.allRequired || req}
                validate={this.props.allRequired || req}
                title={denumire}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="list"></Icon>
              </ListInput>
            );
            break;
          }
          case "textarea": {
            var inpLength =
              inputCurent.maxlength !== undefined
                ? inputCurent.maxlength
                : 1000;
            var varLength =
              this.state.editedItem[denumire] === null
                ? 0
                : this.state.editedItem[denumire].length;
            toRender = (
              <ListInput
                maxlength={inpLength}
                disabled={
                  denumire === this.props.id ||
                  (inputCurent.disabled !== undefined &&
                    inputCurent.disabled === true)
                    ? true
                    : false
                }
                inputStyle={{
                  minHeight: inputCurent.inputHeight
                    ? inputCurent.inputHeight
                    : 150,
                }}
                inputmode="text"
                key={"string-" + this.state.tableName + denumire + index}
                floatingLabel
                label={denumire + (this.props.allRequired || req ? "*" : "")}
                required={this.props.allRequired || req}
                validate={this.props.allRequired || req}
                type="textarea"
                placeholder={denumire}
                value={
                  valoarea !== null && valoarea !== undefined ? valoarea : ""
                }
                onInput={(e) => {
                  var editItem = { ...this.state.editedItem };
                  editItem[denumire] = e.target.value;
                  if (this.props.onInputChanged !== undefined) {
                    this.props.onInputChanged(editItem, denumire, this.state);
                  }
                  this.setState({ editedItem: editItem }, () => {});
                }}>
                <div
                  style={{
                    position: "absolute",
                    top: 9,
                    right: 0,
                    fontSize: 10,
                  }}
                  slot="inner-end">
                  {varLength + "/" + inpLength}
                </div>

                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="create"></Icon>

                <Button
                  disabled={
                    this.state.speechDenumire !== "" &&
                    this.state.speechDenumire !== denumire
                  }
                  slot="content-end"
                  onClick={() => {
                    this.setState(
                      {
                        speechStarted: !this.state.speechStarted,
                        speechDenumire: !this.state.speechStarted
                          ? denumire
                          : "",
                      },
                      () => {
                        if (!this.state.speechStarted) {
                          //STOP
                          this.$f7.methods
                            .stopSpeech()
                            .then((text) => {})
                            .catch((error) => {
                              console.log("Err", error);
                            });
                        } else {
                          //START
                          var oldText = "";
                          this.$f7.methods.startSpeech((text, latestWord) => {
                            var editItem = { ...this.state.editedItem };
                            var comDeEditat = editItem[denumire];
                            comDeEditat += latestWord.replace(oldText, "");
                            oldText = latestWord;

                            editItem[denumire] = comDeEditat;

                            if (this.props.onInputChanged !== undefined) {
                              this.props.onInputChanged(
                                editItem,
                                denumire,
                                this.state
                              );
                            }
                            this.setState({ editedItem: editItem }, () => {});
                          });
                        }
                      }
                    );
                  }}>
                  <Icon
                    style={{
                      color:
                        this.state.speechStarted &&
                        this.state.speechDenumire === denumire
                          ? "red"
                          : "teal",
                      left: "-2px",
                    }}
                    size={20}
                    material={
                      this.state.speechStarted &&
                      this.state.speechDenumire === denumire
                        ? "mic_off"
                        : "mic"
                    }></Icon>
                </Button>
              </ListInput>
            );
            break;
          }
          case "selectboxmultiplu": {
            toRender = (
              <ListInput
                type="text"
                placeholder={denumire}
                value={den}
                key={"selectboxmultiplu-" + this.state.tableName + denumire}
                disabled={
                  denumire === this.props.id ||
                  (inputCurent.disabled !== undefined &&
                    inputCurent.disabled === true)
                    ? true
                    : false
                }
                floatingLabel
                label={denumire + (this.props.allRequired || req ? "*" : "")}
                onFocus={(e) => {
                  var t = e.currentTarget;
                  var inputSelectat = this.props.inputs.find((r) => {
                    return r.key === denumire;
                  });
                  this.setState(
                    {
                      listaRender: lista,
                      lista: lista,
                      denumire: denumire,
                      inputSelectat: inputSelectat,
                    },
                    () => {
                      if (this.desktop) {
                        this.$f7.popover.open("." + this.state.tableName3, t);
                      } else {
                        this.$f7.popup.open("." + this.state.tableName2);
                      }
                    }
                  );
                }}
                validate={this.props.allRequired || req}
                required={this.props.allRequired || req}
                title={denumire}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="list"></Icon>
              </ListInput>
            );
            break;
          }
          case "audiorecorder": {
            toRender = (
              <div style={{ display: "flex", width: "100%", height: "100%" }}>
                <Segmented
                  style={{
                    display: "inline-block",
                    width: "100px",
                    height: "51px",
                    marginTop: "-8px",
                  }}>
                  <Button
                    tooltip={"Start inregistrare audio"}
                    style={{
                      color: "teal",
                      display: "inline-block",
                      width: "50%",
                      height: "51px",
                    }}
                    onClick={() => {
                      this.setState({ recordState: RecordState.START });
                    }}>
                    <Icon material="play_arrow" />
                  </Button>
                  <Button
                    tooltip={"Stop inregistrare audio"}
                    style={{
                      color: "red",
                      display: "inline-block",
                      width: "50%",
                      height: "51px",
                    }}
                    onClick={() => {
                      this.setState({ recordState: RecordState.STOP });
                    }}>
                    <Icon material="stop" />
                  </Button>
                </Segmented>
                <AudioReactRecorder
                  canvasWidth={100}
                  canvasHeight={50}
                  state={this.state.recordState}
                  onStop={(e) => {
                    let metadata = {
                      type: "audio/wav",
                    };
                    let file = new File([e.blob], "audio.wav", metadata);
                    var editItem = { ...this.state.editedItem };
                    editItem[denumire] = file;
                    this.setState({ editedItem: editItem });
                  }}
                />
              </div>
            );
            break;
          }
          default: {
            toRender = (
              <ListInput
                disabled={
                  denumire === this.props.id ||
                  (inputCurent.disabled !== undefined &&
                    inputCurent.disabled === true)
                    ? true
                    : false
                }
                inputmode="text"
                maxlength={
                  inputCurent.maxlength !== undefined
                    ? inputCurent.maxlength
                    : 500
                }
                key={"string-" + this.state.tableName + denumire + index}
                floatingLabel
                label={denumire + (this.props.allRequired || req ? "*" : "")}
                required={this.props.allRequired || req}
                validate={this.props.allRequired || req}
                type="text"
                placeholder={denumire}
                value={
                  valoarea !== null && valoarea !== undefined ? valoarea : ""
                }
                onInput={(e) => {
                  var editItem = { ...this.state.editedItem };
                  editItem[denumire] = e.target.value;
                  if (this.props.onInputChanged !== undefined) {
                    this.props.onInputChanged(editItem, denumire, this.state);
                  }
                  this.setState({ editedItem: editItem }, () => {});
                }}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="create"></Icon>
                <Button
                  disabled={
                    this.state.speechDenumire !== "" &&
                    this.state.speechDenumire !== denumire
                  }
                  slot="content-end"
                  onClick={() => {
                    this.setState(
                      {
                        speechStarted: !this.state.speechStarted,
                        speechDenumire: !this.state.speechStarted
                          ? denumire
                          : "",
                      },
                      () => {
                        if (!this.state.speechStarted) {
                          //STOP
                          this.$f7.methods
                            .stopSpeech()
                            .then((text) => {})
                            .catch((error) => {
                              console.log("Err", error);
                            });
                        } else {
                          //START
                          var oldText = "";
                          this.$f7.methods.startSpeech((text, latestWord) => {
                            var editItem = { ...this.state.editedItem };
                            var comDeEditat = editItem[denumire];
                            comDeEditat += latestWord.replace(oldText, "");
                            oldText = latestWord;

                            editItem[denumire] = comDeEditat;

                            if (this.props.onInputChanged !== undefined) {
                              this.props.onInputChanged(
                                editItem,
                                denumire,
                                this.state
                              );
                            }
                            this.setState({ editedItem: editItem }, () => {});
                          });
                        }
                      }
                    );
                  }}>
                  <Icon
                    style={{
                      color:
                        this.state.speechStarted &&
                        this.state.speechDenumire === denumire
                          ? "red"
                          : "teal",
                      left: "-2px",
                    }}
                    size={20}
                    material={
                      this.state.speechStarted &&
                      this.state.speechDenumire === denumire
                        ? "mic_off"
                        : "mic"
                    }></Icon>
                </Button>
              </ListInput>
            );
            break;
          }
        }
      }
    }
    return denumire !== this.props.id ? (
      maiExista ? (
        <Col key={"colAddEdit-" + this.state.tableName + denumire}>
          {toRender}
        </Col>
      ) : (
        <Col
          key={"colAddEdit-" + this.state.tableName + denumire}
          {...settings2}>
          {toRender}
        </Col>
      )
    ) : null;
  };

  render() {
    return (
      <DraggingPanel
        closeByBackdropClick={false}
        backdrop={false}
        resizable
        onPanelOpened={() => {
          this.$f7.data.PopupAddEditThis = this;

          if (this.props.onAddEditOpened)
            this.props.onAddEditOpened(this.state, this);
        }}
        onPanelClosed={() => {
          if (this.props.onAddEditClosed)
            this.props.onAddEditClosed(this.state, this);
        }}
        onPanel
        style={{
          width: "55vw",
          top: 0,
          zIndex: 12000,
          height: "98vh",
        }}
        className={this.state.tableName}>
        <Page>
          <Navbar
            className={"navbar-move"}
            style={{ cursor: "move" }}
            large
            title={
              this.props.title === undefined
                ? this.props.editIndex === undefined
                  ? "Adaugare"
                  : "Edit" +
                    (this.props.coloanaDenumire !== undefined
                      ? this.props.inputs.find((x) => {
                          return x.key === this.props.coloanaDenumire;
                        }).tip === "selectbox"
                        ? " (" +
                          this.props.inputs
                            .find((x) => {
                              return x.key === this.props.coloanaDenumire;
                            })
                            .lista.find((y) => {
                              return (
                                y.value ===
                                this.state.editedItem[
                                  this.props.coloanaDenumire
                                ]
                              );
                            }).key +
                          ")"
                        : " (" +
                          this.state.editedItem[this.props.coloanaDenumire] +
                          ")"
                      : this.props.titluDenumire !== undefined
                      ? " (" + this.props.titluDenumire + ")"
                      : "")
                : this.props.title
            }>
            {this.props.NavLeft !== undefined
              ? this.props.NavLeft(this.state, this)
              : null}
            <NavRight>
              <Link
                tooltip={"Inchide"}
                onClick={() => {
                  if (this.props.onCloseClicked !== undefined) {
                    this.props.onCloseClicked(this.state);
                  }
                  this.$f7.panel.close("." + this.state.tableName);
                }}>
                Inchide
              </Link>
            </NavRight>
          </Navbar>

          <Popup
            onPopupClose={() => {
              this.$f7.searchbar.clear("." + this.state.searchName);
              this.setState({
                lista: [],
                listaRender: [],
                numberBeforeAddEdit: 0,
              });
            }}
            push={true}
            tabletFullscreen={true}
            className={this.state.tableName2}>
            <Page>
              <Navbar title={this.state.denumire}>
                <NavRight>
                  <Link tooltip={"Inchide"} popupClose>
                    Inchide
                  </Link>
                </NavRight>
              </Navbar>
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {this.state.inputSelectat.tip === "selectboxmultiplu" ? (
                    <Button
                      onClick={() => {
                        this.$f7.popup.close("." + this.state.tableName2);
                        if (this.props.onSelectionChanged !== undefined) {
                          this.props.onSelectionChanged(this.state, this);
                        }
                      }}>
                      <Icon
                        style={{ color: "var(--f7-theme-color)" }}
                        size={40}
                        material="done"></Icon>
                    </Button>
                  ) : null}

                  <Searchbar
                    className={this.state.searchName}
                    onClickDisable={() => {
                      var source = [...this.state.lista];
                      this.setState({ listaRender: source });
                    }}
                    borderColor={"transparent"}
                    onInput={(e) => {
                      var value = e.target.value;
                      this.searchAddEdit(value);
                    }}></Searchbar>
                </div>

                <List style={{ marginBottom: 8 }}>
                  {this.state.listaRender
                    .slice(
                      this.state.numberBeforeAddEdit,
                      this.state.numberBeforeAddEdit + count
                    )
                    .map((e, index) => (
                      <ListItem
                        className={"inputInaltimeNoua"}
                        checked={
                          this.state.inputSelectat.tip === "selectboxmultiplu"
                            ? e.value !== null
                              ? this.state.editedItem[
                                  this.state.denumire
                                ].includes(e.value.toString())
                              : this.state.editedItem[this.state.denumire] ===
                                e.value
                            : this.state.editedItem[this.state.denumire] ===
                              e.value
                        }
                        checkbox={
                          this.state.inputSelectat.tip === "selectboxmultiplu"
                            ? true
                            : false
                        }
                        radio={
                          this.state.inputSelectat.tip === "selectboxmultiplu"
                            ? false
                            : true
                        }
                        onClick={() => {
                          if (
                            this.state.inputSelectat.tip === "selectboxmultiplu"
                          ) {
                            var value0 = e.value;
                            var editItem0 = { ...this.state.editedItem };
                            var item = editItem0[this.state.denumire];
                            if (item === "valoarePentruRequired") {
                              item = [];
                            }
                            if (item.includes(value0.toString())) {
                              item = item.filter((r) => {
                                return r.toString() !== value0.toString();
                              });
                            } else {
                              item.push(value0.toString());
                            }
                            editItem0[this.state.denumire] = item;
                            this.setState({ editedItem: editItem0 });
                          } else {
                            var value1 = e.value;
                            var editItem1 = { ...this.state.editedItem };
                            editItem1[this.state.denumire] = value1;
                            this.setState({ editedItem: editItem1 }, () => {
                              this.$f7.popup.close("." + this.state.tableName2);
                              if (this.props.onSelectionChanged !== undefined) {
                                this.props.onSelectionChanged(this.state, this);
                              }
                            });
                          }
                        }}
                        key={"i" + index}>
                        <p style={{ whiteSpace: "nowrap", margin: 0 }}>
                          {e.key}
                        </p>
                      </ListItem>
                    ))}
                </List>

                <div style={{ width: "100%" }}>
                  <Segmented
                    style={{
                      maxWidth: "150px",
                      margin: "0 auto",
                    }}
                    tag="p">
                    <Button
                      tooltip={"Inapoi"}
                      style={{ height: 35 }}
                      disabled={
                        parseInt(this.state.numberBeforeAddEdit - count) >= 0
                          ? false
                          : true
                      }
                      small
                      onClick={() => {
                        let lastNumber = this.state.numberBeforeAddEdit - count;
                        if (lastNumber >= 0) {
                          this.setState({
                            numberBeforeAddEdit: lastNumber,
                          });
                        }
                      }}>
                      <Icon
                        style={{
                          color:
                            parseInt(this.state.numberBeforeAddEdit - count) >=
                            0
                              ? "var(--f7-theme-color)"
                              : "lightgray",
                        }}
                        size={40}
                        material="navigate_before"></Icon>
                    </Button>
                    <Button
                      tooltip={"Inainte"}
                      style={{ height: 35 }}
                      disabled={
                        parseInt(this.state.numberBeforeAddEdit + count) <=
                        parseInt(this.state.listaRender.length - 1)
                          ? false
                          : true
                      }
                      small
                      onClick={() => {
                        let lastNumber = this.state.numberBeforeAddEdit + count;
                        if (lastNumber <= this.state.listaRender.length - 1) {
                          this.setState({
                            numberBeforeAddEdit: lastNumber,
                          });
                        }
                      }}>
                      <Icon
                        style={{
                          color:
                            parseInt(this.state.numberBeforeAddEdit + count) <=
                            parseInt(this.state.listaRender.length - 1)
                              ? "var(--f7-theme-color)"
                              : "lightgray",
                        }}
                        size={40}
                        material="navigate_next"></Icon>
                    </Button>
                  </Segmented>
                </div>
              </>
            </Page>
          </Popup>

          <Popover
            onPopoverOpen={(e) => {
              var width = e.targetEl.clientWidth;
              var search = this.$f7.searchbar.get("." + this.state.searchName2);
              search.inputEl.focus();
              e.el.style.width = width.toString() + "px";
            }}
            style={{ maxHeight: 314, height: 314 }}
            onPopoverClose={() => {
              var search = this.$f7.searchbar.get("." + this.state.searchName2);
              search.clear();
              this.setState({
                lista: [],
                listaRender: [],
                numberBeforeAddEdit: 0,
              });
            }}
            className={this.state.tableName3}>
            <Page style={{ overflow: "hidden", height: "auto" }}>
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {this.state.inputSelectat.tip === "selectboxmultiplu" ? (
                    <Button
                      onClick={() => {
                        this.$f7.popover.close("." + this.state.tableName3);
                        if (this.props.onSelectionChanged !== undefined) {
                          this.props.onSelectionChanged(this.state, this);
                        }
                      }}>
                      <Icon
                        style={{ color: "var(--f7-theme-color)" }}
                        size={40}
                        material="done"></Icon>
                    </Button>
                  ) : null}

                  <Searchbar
                    style={{ width: "100%" }}
                    className={this.state.searchName2}
                    onClickDisable={() => {
                      var source = [...this.state.lista];
                      this.setState({ listaRender: source });
                    }}
                    borderColor={"transparent"}
                    onInput={(e) => {
                      var value = e.target.value;
                      this.searchAddEdit(value);
                    }}></Searchbar>

                  <Segmented
                    style={{
                      maxWidth: "150px",
                      margin: "0 auto",
                    }}
                    tag="p">
                    <Button
                      tooltip={"Inapoi"}
                      style={{ height: 35 }}
                      disabled={
                        parseInt(this.state.numberBeforeAddEdit - countMic) >= 0
                          ? false
                          : true
                      }
                      small
                      onClick={() => {
                        let lastNumber =
                          this.state.numberBeforeAddEdit - countMic;
                        if (lastNumber >= 0) {
                          this.setState({
                            numberBeforeAddEdit: lastNumber,
                          });
                        }
                      }}>
                      <Icon
                        style={{
                          color:
                            parseInt(
                              this.state.numberBeforeAddEdit - countMic
                            ) >= 0
                              ? "var(--f7-theme-color)"
                              : "lightgray",
                        }}
                        size={40}
                        material="navigate_before"></Icon>
                    </Button>
                    <Button
                      tooltip={"Inainte"}
                      style={{ height: 35 }}
                      disabled={
                        parseInt(this.state.numberBeforeAddEdit + countMic) <=
                        parseInt(this.state.listaRender.length - 1)
                          ? false
                          : true
                      }
                      small
                      onClick={() => {
                        let lastNumber =
                          this.state.numberBeforeAddEdit + countMic;
                        if (lastNumber <= this.state.listaRender.length - 1) {
                          this.setState({
                            numberBeforeAddEdit: lastNumber,
                          });
                        }
                      }}>
                      <Icon
                        style={{
                          color:
                            parseInt(
                              this.state.numberBeforeAddEdit + countMic
                            ) <= parseInt(this.state.listaRender.length - 1)
                              ? "var(--f7-theme-color)"
                              : "lightgray",
                        }}
                        size={40}
                        material="navigate_next"></Icon>
                    </Button>
                  </Segmented>
                </div>

                <List style={{ overflow: "auto", maxHeight: "240px" }}>
                  {this.state.listaRender
                    .slice(
                      this.state.numberBeforeAddEdit,
                      this.state.numberBeforeAddEdit + countMic
                    )
                    .map((e, index) => (
                      <ListItem
                        className={"inputInaltimeNoua"}
                        checked={
                          this.state.inputSelectat.tip === "selectboxmultiplu"
                            ? e.value !== null
                              ? this.state.editedItem[
                                  this.state.denumire
                                ].includes(e.value.toString())
                              : this.state.editedItem[this.state.denumire] ===
                                e.value
                            : this.state.editedItem[this.state.denumire] ===
                              e.value
                        }
                        checkbox={
                          this.state.inputSelectat.tip === "selectboxmultiplu"
                            ? true
                            : false
                        }
                        radio={
                          this.state.inputSelectat.tip === "selectboxmultiplu"
                            ? false
                            : true
                        }
                        onClick={() => {
                          if (
                            this.state.inputSelectat.tip === "selectboxmultiplu"
                          ) {
                            var value0 = e.value;
                            var editItem0 = { ...this.state.editedItem };
                            var item = editItem0[this.state.denumire];
                            if (item === "valoarePentruRequired") {
                              item = [];
                            }
                            if (item.includes(value0.toString())) {
                              item = item.filter((r) => {
                                return r.toString() !== value0.toString();
                              });
                            } else {
                              item.push(value0.toString());
                            }
                            editItem0[this.state.denumire] = item;
                            this.setState({ editedItem: editItem0 });
                          } else {
                            var value1 = e.value;
                            var editItem1 = { ...this.state.editedItem };
                            editItem1[this.state.denumire] = value1;
                            this.setState({ editedItem: editItem1 }, () => {
                              this.$f7.popover.close(
                                "." + this.state.tableName3
                              );
                              if (this.props.onSelectionChanged !== undefined) {
                                this.props.onSelectionChanged(this.state, this);
                              }
                            });
                          }
                        }}
                        key={"i2" + index}>
                        <p style={{ whiteSpace: "nowrap", margin: 0 }}>
                          {e.key}
                        </p>
                      </ListItem>
                    ))}
                </List>
              </>
            </Page>
          </Popover>

          <Popover
            onPopoverOpen={(e) => {
              var max = this.$f7.methods.maxDepth(
                -1,
                this.state.listaRender,
                "value",
                "ref"
              );
              this.setState({ showTreelist: true, maxDepth: max });
            }}
            style={{ maxHeight: 314, height: 314 }}
            onPopoverClose={() => {
              this.setState({
                lista: [],
                listaRender: [],
                numberBeforeAddEdit: 0,
                showTreelist: false,
              });
            }}
            className={this.state.tableName4}>
            <Page style={{ overflow: "hidden", height: "auto" }}>
              <div style={{ overflow: "auto", maxHeight: 314, padding: 8 }}>
                {this.state.showTreelist ? (
                  <>
                    {this.state.maxDepth > 0 ? (
                      <Range
                        label={true}
                        className={"rangeDepth"}
                        min={0}
                        max={this.state.maxDepth}
                        step={1}
                        value={this.state.level}
                        scale={true}
                        scaleSteps={this.state.maxDepth}
                        scaleSubSteps={1}
                        onRangeChanged={(e) => {
                          this.expandToLevel(e);
                        }}
                      />
                    ) : null}
                    <TreeList
                      dataStructure={"plain"}
                      virtualModeEnabled={true}
                      cacheEnabled={true}
                      filterMode={"matchOnly"}
                      selection={{ mode: "single", recursive: false }}
                      rootValue={-1}
                      keyExpr={"value"}
                      dataSource={this.state.listaRender}
                      id={"Tree"}
                      parentIdExpr={"ref"}
                      columns={[
                        {
                          dataField: "key",
                          caption: "Denumire",
                        },
                      ]}
                      focusedRowEnabled={true}
                      onInitialized={(e) => {
                        this.setState(
                          {
                            componentaTree: e.component,
                          },
                          () => {
                            var inputs = document.getElementsByClassName(
                              "dx-texteditor-input"
                            );
                            setTimeout(() => {
                              inputs[inputs.length - 1].focus();
                            }, 350);
                          }
                        );
                      }}
                      onSelectionChanged={(f) => {
                        var val = null;
                        var selectedRowsData = f.selectedRowsData;
                        if (
                          selectedRowsData !== undefined &&
                          selectedRowsData !== null &&
                          selectedRowsData.length > 0
                        ) {
                          val = selectedRowsData[0].value;
                          let item = this.state.editedItem;
                          item[this.state.denumire] = val;

                          this.setState(
                            {
                              editedItem: item,
                            },
                            () => {
                              if (this.props.onSelectionChanged !== undefined) {
                                this.props.onSelectionChanged(this.state, this);
                              }
                              this.$f7.popover.close(
                                "." + this.state.tableName4,
                                true
                              );
                            }
                          );
                        }
                      }}>
                      <SearchPanel
                        searchVisibleColumnsOnly={true}
                        visible={true}
                      />
                    </TreeList>{" "}
                  </>
                ) : null}
              </div>
            </Page>
          </Popover>

          <List
            noHairlinesBetweenMd
            noHairlinesMd
            form
            inset
            onSubmit={(e) => {
              e.preventDefault();
              if (
                this.state.function !== null &&
                this.state.function !== undefined
              ) {
                this.state.function(this.state, "." + this.state.tableName);
              } else {
                this.$f7.methods.openNotification(
                  "Nu ati setat functia de submit",
                  "Ups"
                );
              }
            }}>
            {this.groups.length > 0
              ? this.groups.map((denumire) =>
                  this.state.inputs.filter((r) => {
                    return (
                      r.group === denumire ||
                      (r.group === undefined && denumire === "")
                    );
                  }).length > 0 ? (
                    <ListGroup key={"Group-" + denumire}>
                      <ListItem title={denumire} groupTitle></ListItem>
                      <Row>
                        {this.state.inputs
                          .filter((r) => {
                            return (
                              r.group === denumire ||
                              (r.group === undefined && denumire === "")
                            );
                          })
                          .filter((r) => {
                            if (this.props.ignoreInputs !== undefined) {
                              return !this.props.ignoreInputs.includes(r.key);
                            } else {
                              return true;
                            }
                          })
                          .map((input, index, arr) => {
                            var den = input.key;
                            return this.renderInput(
                              den,
                              index,
                              index % 2 === 0 && index === arr.length - 1
                            );
                          })}
                      </Row>
                    </ListGroup>
                  ) : null
                )
              : this.state.keys
                  .filter((r) => {
                    if (this.props.ignoreInputs !== undefined) {
                      return !this.props.ignoreInputs.includes(r);
                    } else {
                      return true;
                    }
                  })
                  .map((denumire, index, arr) => {
                    return this.renderInput(
                      denumire,
                      index,
                      index % 2 === 0 && index === arr.length - 1
                    );
                  })}

            {this.props.buttons !== undefined ? (
              <Row>
                {this.props.buttons.map((item, index) => (
                  <Col key={"colPopupAddEdit" + index} width={50}>
                    <Button
                      style={{ width: "100%", marginBottom: 6 }}
                      tooltip={item.key}
                      onClick={() => {
                        if (
                          item.noInputsRequired !== undefined &&
                          item.noInputsRequired === true
                        ) {
                          item.onSubmit(
                            this.state,
                            "." + this.state.tableName,
                            this
                          );
                        } else {
                          this.setState({ function: item.onSubmit });
                        }
                      }}
                      type={
                        item.noInputsRequired !== undefined &&
                        item.noInputsRequired === true
                          ? ""
                          : "submit"
                      }
                      small
                      fill
                      key={"buttonPAE" + index}
                      bgColor="teal">
                      {item.key}
                    </Button>
                  </Col>
                ))}
              </Row>
            ) : null}
          </List>
        </Page>
        {/* </Panel> */}
      </DraggingPanel>
    );
  }
}
export default PopupAddEdit;
