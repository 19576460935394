import React from "react";
import { Page } from "framework7-react";
import Clienti from "../Componente/Clienti";
class ClientiPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { denumire: "Clienti" };
    }
    render() {
        return (
            <Page
                className={"themeBackground"}
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
            >

                <div className="ptr3">
                    <Clienti />
                </div>
            </Page>
        );
    }
}
export default ClientiPage;