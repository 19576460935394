import React, { Component } from "react";
import { Page, List, ListItem, Row, Col } from "framework7-react";
import videoPreluareTask from "../../../videoManual/Preluare_task.mp4";
import videoTaskuriAlocate from "../../../videoManual/Taskuri_alocate.mp4";
import videoTaskuriInchise from "../../../videoManual/Taskuri_inchise.mp4";
import videoActivitateTask from "../../../videoManual/Activitate_task.mp4";
import videoTaskuriPrimite from "../../../videoManual/Taskuri_primite.mp4";
import videoDetaliiProiect from "../../../videoManual/Detalii_proiect.mp4";
import videoIstoricModificari from "../../../videoManual/Istoric_modificari.mp4";
import videoAlteDetaliiTehnice from "../../../videoManual/Alte_detalii_tehnice.mp4";
import videoAdaugareTask from "../../../videoManual/Adaugare_task.mp4";
import videoMesajeTask from "../../../videoManual/Mesaje_task.mp4";
import videoConcedii from "../../../videoManual/Concedii.mp4";
import videoDelegatii from "../../../videoManual/Delegatii.mp4";

/**
 * @description Randeaza interfata de contine manualul cu instructiuni, o lista cu toate videourile si in dreapta un player video ce reda videoul selectat
 * @date 07/04/2022
 * @class ManualInstructiuniPage
 * @extends {Component}
 */
class ManualInstructiuniPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pagina: "ManualInstructiuni",
      showVideo: false,
      videoContent: null,
    };
  }

  componentDidMount = () => {};

  render() {
    return (
      <Page
        onPageInit={() => {
          this.$f7.data.paginaCurenta = this;
        }}
        id={this.state.pagina}
        style={{ overflow: "hidden" }}>
        <div style={{ height: "100%", padding: 15, boxSizing: "border-box" }}>
          <Row style={{ boxSizing: "border-box", height: "100%" }}>
            <Col resizable style={{ minWidth: 350, height: "100%" }}>
              <List style={{ margin: 0, maxHeight: "100%", overflowY: "auto" }}>
                <ListItem
                  title="Cum imi aloc un task?"
                  onClick={(e) => {
                    this.setState(
                      {
                        showVideo: false,
                        videoContent: videoPreluareTask,
                      },
                      () => {
                        this.setState({
                          showVideo: true,
                        });
                      }
                    );
                  }}></ListItem>

                <ListItem
                  title="Unde sunt task-urile mele? Cum lucrez cu ele?"
                  onClick={() => {
                    this.setState(
                      {
                        showVideo: false,
                        videoContent: videoTaskuriAlocate,
                      },
                      () => {
                        this.setState({
                          showVideo: true,
                        });
                      }
                    );
                  }}></ListItem>

                <ListItem
                  title="Cum procedez daca trebuie sa revin la un task terminat?"
                  onClick={() => {
                    this.setState(
                      {
                        showVideo: false,
                        videoContent: videoTaskuriInchise,
                      },
                      () => {
                        this.setState({
                          showVideo: true,
                        });
                      }
                    );
                  }}></ListItem>

                <ListItem
                  title="Pot vedea activitatea mea pe un task?"
                  onClick={() => {
                    this.setState(
                      {
                        showVideo: false,
                        videoContent: videoActivitateTask,
                      },
                      () => {
                        this.setState({
                          showVideo: true,
                        });
                      }
                    );
                  }}></ListItem>

                <ListItem
                  title="Pot primi taskuri? Unde le gasesc si ce optiuni am?"
                  onClick={() => {
                    this.setState(
                      {
                        showVideo: false,
                        videoContent: videoTaskuriPrimite,
                      },
                      () => {
                        this.setState({
                          showVideo: true,
                        });
                      }
                    );
                  }}></ListItem>

                <ListItem
                  title="Cum accesez detaliile unui task?"
                  onClick={() => {
                    this.setState(
                      {
                        showVideo: false,
                        videoContent: videoDetaliiProiect,
                      },
                      () => {
                        this.setState({
                          showVideo: true,
                        });
                      }
                    );
                  }}></ListItem>

                <ListItem
                  title="Exista un istoric al modificarilor asupra unui task?"
                  onClick={() => {
                    this.setState(
                      {
                        showVideo: false,
                        videoContent: videoIstoricModificari,
                      },
                      () => {
                        this.setState({
                          showVideo: true,
                        });
                      }
                    );
                  }}></ListItem>

                <ListItem
                  title="Exista si alte informatii despre task?"
                  onClick={() => {
                    this.setState(
                      {
                        showVideo: false,
                        videoContent: videoAlteDetaliiTehnice,
                      },
                      () => {
                        this.setState({
                          showVideo: true,
                        });
                      }
                    );
                  }}></ListItem>

                <ListItem
                  title="Cum adaug un task nou?"
                  onClick={() => {
                    this.setState(
                      {
                        showVideo: false,
                        videoContent: videoAdaugareTask,
                      },
                      () => {
                        this.setState({
                          showVideo: true,
                        });
                      }
                    );
                  }}></ListItem>

                <ListItem
                  title="Unde sunt mesajele mele?"
                  onClick={() => {
                    this.setState(
                      {
                        showVideo: false,
                        videoContent: videoMesajeTask,
                      },
                      () => {
                        this.setState({
                          showVideo: true,
                        });
                      }
                    );
                  }}></ListItem>

                <ListItem
                  title="Operarea concediilor"
                  onClick={() => {
                    this.setState(
                      {
                        showVideo: false,
                        videoContent: videoConcedii,
                      },
                      () => {
                        this.setState({
                          showVideo: true,
                        });
                      }
                    );
                  }}></ListItem>

                <ListItem
                  title="Operarea delegatiilor"
                  onClick={() => {
                    this.setState(
                      {
                        showVideo: false,
                        videoContent: videoDelegatii,
                      },
                      () => {
                        this.setState({
                          showVideo: true,
                        });
                      }
                    );
                  }}></ListItem>
              </List>
            </Col>

            <Col resizable>
              {this.state.showVideo ? (
                <video
                  id="video"
                  className="videoContainer"
                  width="100%"
                  height="auto"
                  controls>
                  <source src={this.state.videoContent} type="video/mp4" />
                </video>
              ) : null}
            </Col>
          </Row>
        </div>
      </Page>
    );
  }
}
export default ManualInstructiuniPage;
