import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import { Segmented, Button, List, ListItem } from "framework7-react";
import axios from "axios";
import moment from "moment";
import ATMain from "../../AlocareTask/ATMain";

/**
 * @description Afiseaza lista cu taskurile primite de catre utilizatorul selectat/logat
 * @date 08/04/2022
 * @class SarciniPrimiteGrid
 * @extends {React.Component}
 */
class SarciniPrimiteGrid extends React.Component {
  constructor(props) {
    super(props);
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.state = {
      dataLoaded: false,
      denumire: "Sarcini primite",
      height: window.innerHeight - 44,
      linkApi: "/api/SarciniSalariat",
      linkApiSalariati: "/api/Salariati",
      linkApiMesaje: "/api/Sesizari",

      dataSource: [],
      inputs: [
        { key: "💾", keyBaza: "areDocumente", tip: "boolean", disabled: true },
        { key: "IdSarcina", keyBaza: "idSarcina", tip: "number" },
        { key: "IdProiect", keyBaza: "idProiect", tip: "number" },
        { key: "Denumire", keyBaza: "denumire", columnWidth: "250px" },
        { key: "Cerinta", keyBaza: "comentariu", columnWidth: "500px" },
        { key: "Parinte", keyBaza: "parinte" },
        {
          key: "DataCreare",
          keyBaza: "dataCreare",
          tip: "datebox",
          columnWidth: "100px",
        },
        { key: "DataAlocarii", keyBaza: "dataAlocarii", tip: "datebox" },
        { key: "Deadline", keyBaza: "deadline", tip: "datebox" },
        {
          key: "Client",
          keyBaza: "idClient",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
          columnWidth: "20px",
        },
        { key: "Sursa", keyBaza: "sursa", columnWidth: "20px" },
        { key: "DeCine", keyBaza: "deCine", columnWidth: "20px" },
        {
          key: "PR",
          keyBaza: "idPrioritate",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
          columnWidth: "20px",
        },
        {
          key: "IsHidden",
          keyBaza: "isHidden",
          columnWidth: "20px",
          disabled: true,
          tip: "bool",
        },
        {
          key: "IsArhivat",
          keyBaza: "isArhivat",
          columnWidth: "20px",
          disabled: true,
          tip: "bool",
        },
        { key: "UltimaStare", keyBaza: "ultimaStare", tip: "number" },
        {
          key: "NivelProiect",
          keyBaza: "id_nivel_proiect",
          tip: "selectbox",
          lista: this.$f7.data.NivelProiect,
        },
        { key: "Path", keyBaza: "path" },
        {
          key: "OperatorAdaugare",
          keyBaza: "id_operator_adaugare",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
        { key: "BugetTimp", keyBaza: "buget_timp", tip: "number" },
        { key: "TimpPlanD", keyBaza: "timp_plan_d", tip: "number" },
        { key: "TimpLucrat", keyBaza: "timp_l", tip: "number" },
        { key: "PVuri", keyBaza: "apartenenta_pv" },
      ],

      idSelected: null,
      idProiect: null,
      idPropunere: null,
    };

    this.refDataGrid = React.createRef();
  }

  /**
   * @description Modifica in baza de date taskul selectat si adauga o sarcina cum ca taskul a fost acceptat
   * @date 08/04/2022
   * @function
   * @param {*} idSelected id-ul proiectului selectat
   * @memberof SarciniPrimiteGrid
   */
  AcceptaTask = (idSelected) => {
    return new Promise((resolve, reject) => {
      var idSelectedLocal =
        idSelected !== undefined ? idSelected : this.state.idSelected;
      if (idSelectedLocal) {
        this.$f7.methods.openMainDreaptaById(null);
        axios
          .get(
            this.$f7.data.baseURL +
              this.state.linkApi +
              "/GetSARCINI_SALARIAT/" +
              idSelectedLocal
          )
          .then((response1) => {
            let sarcina = response1.data;
            sarcina.dATA_DEALOCARII = moment().format();
            sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT; //notsure
            let urlUpdate =
              this.$f7.data.baseURL +
              this.state.linkApi +
              "/PutSARCINI_SALARIAT/" +
              sarcina.iD_SARCINA;

            axios({
              data: sarcina,
              headers: { "Content-Type": "application/json" },
              method: "put",
              url: urlUpdate,
            })
              .then((response2) => {
                var urlInserare =
                  this.$f7.data.baseURL +
                  this.state.linkApi +
                  "/PostSARCINI_SALARIAT";

                let sarcinaNoua = {
                  iD_PROIECT: sarcina.iD_PROIECT,
                  iD_SALARIAT: this.$f7.data.idSalariatFunctii,
                  dE_CINE: this.$f7.data.user.iD_SALARIAT, //notsure
                  datA_ALOCARII: moment().format(),
                  datA_DEALOCARII: null,
                  dealoC_CINE: null,
                  stare: 1,
                };

                axios({
                  data: sarcinaNoua,
                  headers: { "Content-Type": "application/json" },
                  method: "post",
                  url: urlInserare,
                })
                  .then((response3) => {
                    const numeSalariatPentru = this.$f7.data.Salariati.find(
                      (s) => s.value === sarcina.dE_CINE
                    )
                      ? this.$f7.data.Salariati.find(
                          (s) => s.value === sarcina.dE_CINE
                        ).key
                      : "Sistem";
                    const numeSalariatDeLa = this.$f7.data.Salariati.find(
                      (s) => s.value === this.$f7.data.user.iD_SALARIAT
                    )
                      ? this.$f7.data.Salariati.find(
                          (s) => s.value === this.$f7.data.user.iD_SALARIAT
                        ).key
                      : "Sistem";
                    const numeProiect = this.$f7.data.Proiecte.find(
                      (p) => p.value === sarcina.iD_PROIECT
                    )
                      ? `"${
                          this.$f7.data.Proiecte.find(
                            (p) => p.value === sarcina.iD_PROIECT
                          ).key
                        }"`
                      : "";

                    const mesaj = {
                      data_sesizare: moment(Date.now()).format(),
                      pt_cine: sarcina.dE_CINE,
                      nume_pt_cine: numeSalariatPentru,
                      de_la_cine: this.$f7.data.user.iD_SALARIAT,
                      nume_de_la_cine: numeSalariatDeLa,
                      citit: false,
                      subiect: "Task acceptat",
                      comentariu: `Taskul (${sarcina.iD_PROIECT}) ${numeProiect} a fost acceptat de ${numeSalariatDeLa}`,
                      ref_proiect: sarcina.iD_PROIECT,
                      isArhivat: false,
                    };

                    axios({
                      data: mesaj,
                      headers: { "Content-Type": "application/json" },
                      method: "post",
                      url:
                        this.$f7.data.baseURL +
                        this.state.linkApiMesaje +
                        "/Postsesizari",
                    })
                      .then((responseMesaj) => {
                        resolve(response3);
                      })
                      .catch((error) => {
                        console.log("error", error);
                      });
                  })
                  .catch((error) => {
                    console.log("error", error);
                    this.$f7.methods.openNotification("Eroare la inserare");
                    reject(error);
                  });
              })
              .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la actualizare");
                reject(error);
              });
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la preluare");
            reject(error);
          });
      } else {
        reject("Selectati o sarcina");
      }
    });
  };

  /**
   * @description Modifica in baza de date taskul selectat si adauga o sarcina cum ca taskul a fost respins
   * @function
   * @date 08/04/2022
   * @param {*} idSelected
   * @memberof SarciniPrimiteGrid
   */
  RespingeTask = (idSelected) => {
    return new Promise((resolve, reject) => {
      var idSelectedLocal =
        idSelected !== undefined ? idSelected : this.state.idSelected;
      if (idSelectedLocal) {
        this.$f7.methods.openMainDreaptaById(null);

        axios
          .get(
            this.$f7.data.baseURL +
              this.state.linkApi +
              "/GetSARCINI_SALARIAT/" +
              idSelectedLocal
          )
          .then((response1) => {
            var sarcina = response1.data;

            sarcina.stare = 3;

            var url =
              this.$f7.data.baseURL +
              this.state.linkApi +
              "/PutSARCINI_SALARIAT/" +
              sarcina.iD_SARCINA;

            axios({
              data: sarcina,
              headers: { "Content-Type": "application/json" },
              method: "put",
              url: url,
            })
              .then((response2) => {
                this.GetValues();
                this.$f7.methods.openNotification("Task respins");
                resolve(response2);
              })
              .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la actualizare");
                reject(error);
              });
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la preluare");
            reject(error);
          });
      } else {
        reject("Selectati o sarcina");
      }
    });
  };

  /**
   * @description Modifica in baza de date taskul selectat si adauga o sarcina cum ca taskul a fost refuzat
   * @function
   * @date 08/04/2022
   * @param {*} idSelected
   * @memberof SarciniPrimiteGrid
   */
  RefuzaTask = (idSelected) => {
    return new Promise((resolve, reject) => {
      var idSelectedLocal =
        idSelected !== undefined ? idSelected : this.state.idSelected;
      if (idSelectedLocal) {
        this.$f7.methods.openMainDreaptaById(null);

        axios
          .get(
            this.$f7.data.baseURL +
              this.state.linkApiSalariati +
              "/GetSALARIATI/" +
              this.$f7.data.idSalariatFunctii
          )
          .then((response1) => {
            let salariat = response1.data;
            let idSef = salariat.reF_SALARIAT;

            if (idSef !== null) {
              axios
                .get(
                  this.$f7.data.baseURL +
                    this.state.linkApi +
                    "/GetSARCINI_SALARIAT/" +
                    idSelectedLocal
                )
                .then((response2) => {
                  let sarcina = response2.data;

                  sarcina.dATA_DEALOCARII = moment().format();
                  sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT; //notsure

                  let urlUpdate =
                    this.$f7.data.baseURL +
                    this.state.linkApi +
                    "/PutSARCINI_SALARIAT/" +
                    sarcina.iD_SARCINA;

                  axios({
                    data: sarcina,
                    headers: { "Content-Type": "application/json" },
                    method: "put",
                    url: urlUpdate,
                  })
                    .then((response3) => {
                      var urlInserare =
                        this.$f7.data.baseURL +
                        this.state.linkApi +
                        "/PostSARCINI_SALARIAT";

                      let sarcinaNoua = {
                        iD_PROIECT: sarcina.iD_PROIECT,
                        iD_SALARIAT: idSef,
                        dE_CINE: this.$f7.data.idSalariatFunctii, //notsure
                        datA_ALOCARII: moment().format(),
                        datA_DEALOCARII: null,
                        dealoC_CINE: null,
                        stare: 1,
                      };

                      axios({
                        data: sarcinaNoua,
                        headers: { "Content-Type": "application/json" },
                        method: "post",
                        url: urlInserare,
                      })
                        .then((response4) => {
                          const numeSalariatPentru =
                            this.$f7.data.Salariati.find(
                              (s) => s.value === sarcina.dE_CINE
                            )
                              ? this.$f7.data.Salariati.find(
                                  (s) => s.value === sarcina.dE_CINE
                                ).key
                              : "Sistem";
                          const numeSalariatDeLa = this.$f7.data.Salariati.find(
                            (s) => s.value === this.$f7.data.user.iD_SALARIAT
                          )
                            ? this.$f7.data.Salariati.find(
                                (s) =>
                                  s.value === this.$f7.data.user.iD_SALARIAT
                              ).key
                            : "Sistem";
                          const numeSuperior = this.$f7.data.Salariati.find(
                            (s) => s.value === idSef
                          )
                            ? this.$f7.data.Salariati.find(
                                (s) => s.value === idSef
                              ).key
                            : "Sistem";
                          const numeProiect = this.$f7.data.Proiecte.find(
                            (p) => p.value === sarcina.iD_PROIECT
                          )
                            ? `"${
                                this.$f7.data.Proiecte.find(
                                  (p) => p.value === sarcina.iD_PROIECT
                                ).key
                              }"`
                            : "";

                          const mesaj = {
                            data_sesizare: moment(Date.now()).format(),
                            pt_cine: sarcina.dE_CINE,
                            nume_pt_cine: numeSalariatPentru,
                            de_la_cine: this.$f7.data.user.iD_SALARIAT,
                            nume_de_la_cine: numeSalariatDeLa,
                            citit: false,
                            subiect: "Task refuzat/trimis la superior",
                            comentariu: `Taskul (${sarcina.iD_PROIECT}) ${numeProiect} a fost refuzat de ${numeSalariatDeLa} si trimis la ${numeSuperior}`,
                            ref_proiect: sarcina.iD_PROIECT,
                            isArhivat: false,
                          };

                          axios({
                            data: mesaj,
                            headers: { "Content-Type": "application/json" },
                            method: "post",
                            url:
                              this.$f7.data.baseURL +
                              this.state.linkApiMesaje +
                              "/Postsesizari",
                          })
                            .then((responseMesaj) => {
                              resolve(response4);
                            })
                            .catch((error) => {
                              console.log("error", error);
                            });
                        })
                        .catch((error) => {
                          console.log("error", error);
                          this.$f7.methods.openNotification(
                            "Eroare la inserare"
                          );
                          reject(error);
                        });
                    })
                    .catch((error) => {
                      console.log("error", error);
                      this.$f7.methods.openNotification(
                        "Eroare la actualizare"
                      );
                      reject(error);
                    });
                })
                .catch((error) => {
                  console.log("error", error);
                  this.$f7.methods.openNotification(
                    "Eroare la preluare sarcina"
                  );
                  reject(error);
                });
            } else {
              this.$f7.methods.openNotification(
                "Nu este posibila refuzarea taskului selectat deoarece nu exista un superior ierarhic pentru a il prelua."
              );
            }
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la preluare salariat");
            reject(error);
          });
      } else {
        reject("Selectati o sarcina");
      }
    });
  };

  componentDidMount = () => {
    if (this.props.nuEsteDinAgenda === undefined) {
      this.$f7.data.selectedGridLeft = this;
    }
    this.GetValues();
  };

  GetValues = () => {
    this.setState(
      {
        dataLoaded: false,
        idSelected: null,
      },
      () => {
        this.$f7.methods.openMainDreaptaById(null);
      }
    );

    this.$f7.methods
      .getProiecte(
        this.$f7.data.idSalariatFunctii,
        2,
        this.props.checkedTaskuri === true ? 1 : 0,
        this.props.checkedAscunse === true ? 1 : 0,
        this.props.checkedArhivate === true ? 1 : 0,
        1
      )
      .then((response) => {
        var date = response;

        date.data.map((sarcina) => {
          //Valori din proiect
          if (sarcina.proiect) {
            Object.keys(sarcina.proiect).map((property) => {
              var value = sarcina.proiect[property];
              if (sarcina[property] === undefined) {
                sarcina[property] = value;
              }
              return null;
            });
            sarcina["path"] = sarcina.proiect["path"];
            if (sarcina["path"]) {
              var path = "";
              sarcina["path"].split("\\").map((f) => {
                if (f !== null && f !== undefined) {
                  var idProiect = parseInt(f);
                  var pro = this.$f7.data.allProiecte.find((p) => {
                    return p.iD_PROIECT === idProiect;
                  });
                  if (pro) {
                    path += pro.denumire + " \\ ";
                  }
                }
                return null;
              });
              sarcina["path"] = path.slice(0, -2);
            }
          }
          sarcina = this.$f7.methods.restructurareBaza(
            sarcina,
            this.state.inputs,
            "key"
          );

          switch (sarcina.Sursa) {
            case 55:
              sarcina.Sursa =
                "M(" + (sarcina.proiect.userImport % 10000000) + ")";
              break;
            case 56:
              sarcina.Sursa = "E";
              break;
            default:
              sarcina.Sursa = "C";
              break;
          }

          return null;
        });

        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Parcurge lista de taskuri selectate si apeleaza functia de acceptatask pentru fiecare
   * @function
   * @date 08/04/2022
   * @memberof SarciniPrimiteGrid
   */
  AcceptaCall = () => {
    var state = this.refDataGrid.current.state;
    if (state.selectedRows.length > 0 || this.state.idSelected !== null) {
      this.$f7.dialog.confirm(
        "Sunteti sigur ca doriti sa acceptati toate sarcinile selectate?",
        "Acceptare",
        (e) => {
          this.$f7.methods
            .preCall(this.refDataGrid)
            .then((response) => {
              this.$f7.preloader.show();

              if (state.selectedRows.length > 0) {
                state.selectedRows
                  .reduce((promise, item) => {
                    return promise.then(() => {
                      return this.AcceptaTask(item.IdSarcina, item.IdProiect)
                        .then((r) => {})
                        .catch((err) => {
                          console.log("err", err);
                        });
                    });
                  }, Promise.resolve())
                  .then(() => {
                    this.GetValues();
                    this.$f7.preloader.hide();
                    this.$f7.methods.openNotification("Taskuri acceptate.");
                  })
                  .catch(() => {
                    console.log("Eroare");
                  });
              } else {
                this.AcceptaTask()
                  .then((response) => {
                    this.GetValues();
                    this.$f7.preloader.hide();
                    this.$f7.methods.openNotification("Task acceptat.");
                  })
                  .catch((error) => {
                    this.$f7.methods.openNotification("Eroare!");
                    console.log("error", error);
                  });
              }
            })
            .catch((err) => {
              this.$f7.methods.openNotification("Eroare" + err.toString());
            });
        }
      );
    } else {
      this.$f7.methods.openNotification("Trebuie selectata o sarcina.");
    }
  };

  /**
   * @description Parcurge lista de taskuri selectate si apeleaza functia de propunetask pentru fiecare
   * @date 08/04/2022
   * @function
   * @memberof SarciniPrimiteGrid
   */
  PropuneCall = () => {
    this.$f7.dialog.confirm(
      "Sunteti sigur ca doriti sa realocati sarcinile selectate?",
      "Propunere",
      (e) => {
        this.$f7.methods
          .preCall(this.refDataGrid)
          .then((response) => {
            var listaIduri = null;
            var state = this.refDataGrid.current.state;
            if (state.selectedRows.length > 0) {
              listaIduri = [];
              state.selectedRows.map((item) => {
                listaIduri.push(item.IdProiect);
                return null;
              });
            } else {
              listaIduri = this.state.idProiect;
            }
            this.$f7.data.ATidProiect = listaIduri;
            this.$f7.data.router.navigate("/popupDinamic/", {
              props: {
                onPopupClose: (r) => {
                  this.$f7.methods.refreshStanga();
                },
                component: <ATMain Proiect={null} idProiect={null} />,
              },
            });
          })
          .catch((err) => {
            this.$f7.methods.openNotification("Eroare" + err.toString());
          });
      }
    );
  };

  /**
   * @description Parcurge lista de taskuri selectate si apeleaza functia de refuzatask pentru fiecare
   * @date 08/04/2022
   * @function
   * @memberof SarciniPrimiteGrid
   */
  RefuzaCall = () => {
    var state = this.refDataGrid.current.state;
    if (state.selectedRows.length > 0 || this.state.idSelected !== null) {
      this.$f7.dialog.confirm(
        "Sunteti sigur ca doriti sa refuzati toate sarcinile selectate?",
        "Refuzare",
        (e) => {
          this.$f7.methods
            .preCall(this.refDataGrid)
            .then((response) => {
              this.$f7.preloader.show();

              if (state.selectedRows.length > 0) {
                state.selectedRows
                  .reduce((promise, item) => {
                    return promise.then(() => {
                      return this.RefuzaTask(item.IdSarcina, item.IdProiect)
                        .then((r) => {})
                        .catch((err) => {
                          console.log("err", err);
                        });
                    });
                  }, Promise.resolve())
                  .then(() => {
                    this.GetValues();
                    this.$f7.preloader.hide();
                    this.$f7.methods.openNotification("Taskuri refuzate.");
                  })
                  .catch(() => {
                    console.log("Eroare");
                  });
              } else {
                this.RefuzaTask()
                  .then((response) => {
                    this.GetValues();
                    this.$f7.preloader.hide();
                    this.$f7.methods.openNotification("Task refuzat.");
                  })
                  .catch((error) => {
                    this.$f7.methods.openNotification("Eroare!");
                    console.log("error", error);
                  });
              }
            })
            .catch((err) => {
              this.$f7.methods.openNotification("Eroare" + err.toString());
            });
        }
      );
    } else {
      this.$f7.methods.openNotification("Trebuie selectata o sarcina.");
    }
  };

  render() {
    return (
      <>
        <div style={{ position: "relative" }}>
          <Segmented
            className="butoaneContextDatagrid"
            raised
            tag="div"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "calc(100% - 176px)",
            }}>
            <Button
              tooltip={"Accepta task"}
              fill
              small
              onClick={() => {
                this.AcceptaCall();
              }}>
              Accepta
            </Button>
            <Button
              tooltip={"Propune taskul altcuiva"}
              fill
              small
              onClick={() => {
                this.PropuneCall();
              }}>
              Propune cuiva
            </Button>
            {/*<Button
							fill
							small
							onClick={() => {
								this.$f7.dialog.confirm(
									"Sunteti sigur ca doriti sa respingeti toate sarcinile selectate?",
									"Respingere",
									(e) => {
										var state = this.refDataGrid.current.state;
										if (state.selectedRows.length > 0) {
											state.selectedRows.map((r) => {
												this.setState(
													{
														idSelected: r.IdSarcina,
														idProiect: r.IdProiect,
													},
													() => {
														this.RespingeTask();
													}
												);
											})
										} else {
											this.RespingeTask();
										}
									}
								);
							}}
						>
							Respinge
						</Button>*/}
            <Button
              tooltip={"Refuza task/Trimite superiorului"}
              fill
              small
              onClick={() => {
                this.RefuzaCall();
              }}>
              Refuza
            </Button>
            <Button
              tooltip={"Refresh"}
              fill
              small
              onClick={() => {
                this.GetValues();
              }}>
              Refresh
            </Button>
          </Segmented>

          <DataGrid
            onInitialised={(state, r, itemeRandate) => {
              this.$f7.methods.colorItems(
                state,
                r,
                itemeRandate,
                "DeCine",
                1,
                true
              );
            }}
            onRenderItemsChanged={(state, r, itemeRandate) => {
              this.$f7.methods.colorItems(
                state,
                r,
                itemeRandate,
                "DeCine",
                0,
                true
              );
            }}
            dataLoaded={this.state.dataLoaded}
            refresh={this.GetValues}
            name={"SarciniPrimiteGrid"}
            ref={this.refDataGrid}
            id={"IdSarcina"}
            onRowClick={(state) => {
              var id = state.rowData.IdSarcina;
              var pr = state.rowData.IdProiect;
              this.setState(
                {
                  idSelected: id,
                  idProiect: pr,
                },
                () => {
                  this.$f7.methods.openMainDreaptaById(this.state.idProiect);
                }
              );
            }}
            keys={[
              "💾",
              "Denumire",
              "Cerinta",
              "DataCreare",
              "Client",
              "Sursa",
              "DeCine",
              "PR",
            ]}
            ignoredInputsAdd={["Deadline"]}
            ignoreInputsEdit={["Deadline"]}
            hintInputSources={[
              "Path",
              "OperatorAdaugare",
              "BugetTimp",
              "TimpPlanD",
              "PVuri",
            ]}
            renderUpdate={false}
            renderDelete={false}
            renderAdd={false}
            renderCheckbox={true}
            maxHeight={this.state.height}
            inputs={this.state.inputs}
            dataSource={this.state.dataSource}
            contextMenuItems={[
              {
                key: "Accepta",
                onClick: (state) => {
                  setTimeout(() => {
                    this.AcceptaCall();
                  }, 1000);
                },
                icon: "playlist_add_check",
              },
              {
                key: "Propune unui salariat",
                onClick: (state) => {
                  setTimeout(() => {
                    this.PropuneCall();
                  }, 100);
                },
                icon: "send",
              },
              /*{
                                                            key: "Respinge",
                                                            onClick: (state) => {
                                                                setTimeout(() => {
                                                                    this.$f7.dialog.confirm(
                                                                        "Sunteti sigur ca doriti sa respingeti toate sarcinile selectate?",
                                                                        "Respingere",
                                                                        (e) => {
                                                                            var state = this.refDataGrid.current.state;
                                                                            if (state.selectedRows.length > 0) {
                                                                                state.selectedRows.map((r) => {
                                                                                    this.setState(
                                                                                        {
                                                                                            idSelected: r.IdSarcina,
                                                                                            idProiect: r.IdProiect,
                                                                                        },
                                                                                        () => {
                                                                                            this.RespingeTask();
                                                                                        }
                                                                                    );
                                                                                })
                                                                            } else {
                                                                                this.RespingeTask();
                                                                            }
                                                                        }
                                                                    );
                                                                }, 1000);
                                                            },
                                                            icon: "call_missed_outgoing"
                                                        },*/
              {
                key: "Refuza",
                onClick: (state) => {
                  setTimeout(() => {
                    this.RefuzaCall();
                  }, 1000);
                },
                icon: "cancel",
              },
              {
                key: "Refresh",
                onClick: (state) => {
                  setTimeout(() => {
                    this.GetValues();
                  }, 1000);
                },
                icon: "refresh",
              },
              {
                key: "Arhiveaza",
                onClick: (state) => {
                  setTimeout(() => {
                    var selectedRows = state.allSelectedRows;
                    if (selectedRows.length === 0) {
                      selectedRows = [state.rowDataAll];
                    }
                    var listaIduri = [];
                    selectedRows.map((r) => {
                      var id = r.IdProiect;
                      if (!r.IsArhivat) {
                        listaIduri.push(id);
                      }
                      return null;
                    });
                    this.$f7.methods
                      .arhiveazaDezarhiveazaProiect(listaIduri, true)
                      .then((r) => {
                        this.GetValues();
                        const nr = r.data.length;
                        this.$f7.methods.openNotification(
                          "Arhivate cu success: " + nr
                        );
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare la arhivare" + err
                        );
                      });
                  }, 1000);
                },
                icon: "compress",
              },
              {
                key: "Dezarhiveaza",
                onClick: (state) => {
                  setTimeout(() => {
                    var selectedRows = state.allSelectedRows;
                    if (selectedRows.length === 0) {
                      selectedRows = [state.rowDataAll];
                    }
                    var listaIduri = [];
                    selectedRows.map((r) => {
                      var id = r.IdProiect;
                      if (r.IsArhivat) {
                        listaIduri.push(id);
                      }
                      return null;
                    });
                    this.$f7.methods
                      .arhiveazaDezarhiveazaProiect(listaIduri, false)
                      .then((r) => {
                        this.GetValues();
                        const nr = r.data.length;
                        this.$f7.methods.openNotification(
                          "Dezarhivate cu success: " + nr
                        );
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare la dezarhivare" + err
                        );
                      });
                  }, 1000);
                },
                icon: "expand",
              },
              {
                key: "Ascunde",
                onClick: (state, context) => {
                  setTimeout(() => {
                    var selectedRows = state.allSelectedRows;
                    if (selectedRows.length === 0) {
                      selectedRows = [state.rowDataAll];
                    }
                    var listaIduri = [];
                    selectedRows.map((r) => {
                      var id = r.IdProiect;
                      if (!r.IsHidden) {
                        listaIduri.push(id);
                      }
                      return null;
                    });
                    this.$f7.methods
                      .ascundeAfiseazaProiect(listaIduri, true)
                      .then(() => {
                        this.GetValues();
                        this.$f7.methods.openNotification("Ascuns cu success");
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare la ascundere" + err
                        );
                      });
                  }, 1000);
                },
                icon: "refresh",
              },
              {
                key: "Afiseaza",
                onClick: (state, context) => {
                  setTimeout(() => {
                    var selectedRows = state.allSelectedRows;
                    if (selectedRows.length === 0) {
                      selectedRows = [state.rowDataAll];
                    }
                    var listaIduri = [];
                    selectedRows.map((r) => {
                      var id = r.IdProiect;
                      if (r.IsHidden) {
                        listaIduri.push(id);
                      }
                      return null;
                    });

                    this.$f7.methods
                      .ascundeAfiseazaProiect(listaIduri, false)
                      .then(() => {
                        this.GetValues();
                        this.$f7.methods.openNotification("Afisat cu success");
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare la afisare" + err
                        );
                      });
                  }, 1000);
                },
                icon: "refresh",
              },
              {
                key: "Trimite mesaj",
                onClick: (state) => {
                  var id = state.rowData.IdProiect;
                  this.$f7.methods.openMesaje(null, id);
                },
                icon: "send",
              },
            ]}
            onContextMenu={(state, target) => {
              var id = state.rowData.IdSarcina;
              var pr = state.rowData.IdProiect;
              this.setState(
                {
                  idSelected: id,
                  idProiect: pr,
                },
                () => {
                  this.$f7.methods.openMainDreaptaById(this.state.idProiect);
                }
              );
            }}
            coloredRow={{
              sourceCol: "Deadline",
              type: "date",
            }}
          />

          <List style={{ display: "none" }}>
            <ListItem
              className="smSe"
              validate
              smartSelect
              smartSelectParams={{
                virtualList: true,
                searchbar: true,
                openIn: "popup",
                popupTabletFullscreen: true,
                popupPush: true,
                closeOnSelect: true,
              }}>
              <select name="SelectBoxBool">
                {this.Salariati.map((obj, index) => (
                  <option
                    key={"listaSalariatiSarciniPrimiteGrid-" + index}
                    selected={this.Salariati[0].value}
                    value={obj.value}>
                    {obj.key}
                  </option>
                ))}
              </select>
            </ListItem>
          </List>
        </div>
      </>
    );
  }
}
export default SarciniPrimiteGrid;
