import React, { Component } from "react";
import DataGrid from "../../Datagrid/DataGrid";
import {
  ListItem,
  ListInput,
  List,
  Row,
  Col,
  Icon,
  Preloader
} from "framework7-react";
import moment from "moment";
import * as inputs from "../../../../inputs";
import axios from "axios";

/**
 * @description Afiseaza un datagrid cu informatiile legate repartitia taskurilor la fiecare salariat in parte cu intervale.
 * @date 06/04/2022
 * @class RepartitieSalariatiInPerioada
 * @extends {Component}
 */
class RepartitieSalariatiInPerioada extends Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;

    this.state = {
      dataLoaded: false,
      height: window.innerHeight - 44,
      dataDeLa: [moment(Date.now()).format()],
      dataPanaLa: [moment(Date.now()).format()],
      peProiecte: false,
      linkApi: "/api/RepartitieSalariatiPerioada",
      dataSource: [],
    };

    this.refDataGrid = React.createRef();
  }
  /**
   * @description este o functie recursiva pentru a seta elementului curent elementele copii.
   * @date 06/04/2022
   * @function
   * @param {*} baza baza pe care se face recursivitatea
   * @param {*} ref id-ul referinta(salariatul parinte)
   * @memberof RepartitieSalariatiInPerioada
   */
  recursiva = (baza, ref) => {
    return baza
      .filter((b) => b.Ref === ref)
      .map((m) => {
        m.childs = this.recursiva(baza, m.Id);
        return m;
      });
  };

  /**
   * @description ia valorile necesare pentur randarea datagridului de la endpointul „this.$f7.data.baseURL +this.state.linkApi +"/" +dataStart +"/" +dataEnd +  "/" +this.state.peProiecte” si primeste ca parametrii data de start,data de final(intervalul) si daca se doresc selectate per proiect.
   * @date 06/04/2022
   * @function
   * @memberof RepartitieSalariatiInPerioada
   */
  GetValues = () => {
    //'mm-dd-yy'
    let initialStartData = new Date(this.state.dataDeLa[0]);
    let initialEndData = new Date(this.state.dataPanaLa[0]);
    const dataStart =
      initialStartData.getMonth() +
      1 +
      "-" +
      initialStartData.getDate() +
      "-" +
      initialStartData.getFullYear().toString().substring(2);
    const dataEnd =
      initialEndData.getMonth() +
      1 +
      "-" +
      initialEndData.getDate() +
      "-" +
      initialEndData.getFullYear().toString().substring(2);

    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/" +
          dataStart +
          "/" +
          dataEnd +
          "/" +
          this.state.peProiecte
      )
      .then((response) => {
        let change = response;

        for (let item in change.data) {
          change.data[item]["Id"] = change.data[item].id_salariat;
          change.data[item]["Ref"] = change.data[item].ref_salariat;
          change.data[item]["Salariat"] = change.data[item].nume;
          change.data[item]["Ziua"] = change.data[item].ziua;
          change.data[item]["TimpPontaj"] = change.data[item].timp_pontat;
          change.data[item]["TimpZi"] = change.data[item].timp_zi;
          change.data[item]["DataStart"] = change.data[item].ds_proiect;
          change.data[item]["Deadline"] = change.data[item].de_proiect;
          change.data[item]["Asumat"] = change.data[item].asumat;
          change.data[item]["Proiect"] = change.data[item].proiect;
          change.data[item]["Stadiu"] = change.data[item].stadiul;
          change.data[item]["IntrarePeProiect"] = change.data[item].intrare;
          change.data[item]["IesireDePeProiect"] = change.data[item].iesire;
          change.data[item]["ComentariuRealizare"] =
            change.data[item].comentariu;
          change.data[item]["Departament"] = change.data[item].departament;
        }

        let baza = this.$f7.methods.reconstructieBaza(
          change,
          inputs.repartitieSalariati
        );
        baza = baza.filter((objBaza) => {
          return objBaza.Id !== 0;
        });
        let bazaFinala = this.recursiva(baza, 0);

        this.setState(
          {
            dataSource: bazaFinala.length === 0 ? [] : bazaFinala,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  this.refDataGrid.current.setRenderItems(bazaFinala);
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  renderGridRepartitie = (dataSource, idParinte) => {
    return (
      <DataGrid
        name={"RepartitieSalariatiInPerioada"}
        ref={idParinte === 0 ? this.refDataGrid : null}
        id="Id"
        renderAdd={false}
        renderDelete={false}
        renderUpdate={false}
        renderAccordion={true}
        accordionItem={(state, rowItemData) => {
          let childs = rowItemData.childs;
          if (childs) {
            return this.renderGridRepartitie(childs, rowItemData.Id);
          } else {
            return this.renderGridRepartitie([]);
          }
        }}
        dataSource={dataSource}
        maxHeight={this.state.height}
        inputs={inputs.repartitieSalariati}
        coloredRow={{
          sourceCol: "Deadline",
          type: "date",
        }}
      />
    );
  };

  render() {
    return this.state.dataLoaded ? (
      <div style={{ position: "relative" }}>
        <List
          noChevron
          noHairlines
          noHairlinesBetween
          noHairlinesBetweenMd
          noHairlinesMd
          className="optiuniActivitate butoaneContextDatagrid"
          style={{
            position: "absolute",
            top: -8,
            left: 0,
            width: "calc(100% - 176px)",
            margin: 0,
          }}>
          <Row style={{ width: "100%" }}>
            <Col>
              <ListInput
                validate
                required
                label="De la"
                type="datepicker"
                calendarParams={{
                  timePicker: true,
                  dateFormat: "dd.mm.yyyy",
                  closeOnSelect: true,
                  on: {
                    closed: (e) => {
                      const val = e.value[0];
                      this.setState(
                        {
                          dataDeLa: [val],
                        },
                        () => {
                          this.GetValues();
                        }
                      );
                    },
                  },
                }}
                value={this.state.dataDeLa}
                floatingLabel
                placeholder="De la">
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="today"
                  slot="content-start"
                  style={{ left: 0, top: 0 }}
                />
              </ListInput>
            </Col>
            <Col>
              <ListInput
                validate
                required
                label="Pana la"
                type="datepicker"
                calendarParams={{
                  timePicker: true,
                  dateFormat: "dd.mm.yyyy",
                  closeOnSelect: true,
                  on: {
                    closed: (e) => {
                      const val = e.value[0];
                      this.setState(
                        {
                          dataPanaLa: [val],
                        },
                        () => {
                          this.GetValues();
                        }
                      );
                    },
                  },
                }}
                value={this.state.dataPanaLa}
                floatingLabel
                placeholder="Pana la">
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="today"
                  slot="content-start"
                  style={{ left: 0, top: 0 }}
                />
              </ListInput>
            </Col>
            <Col>
              <ListItem
                className="simpleInlineCheckbox"
                checkbox
                title="Pe Proiecte"
                name="peProiecte"
                checked={this.state.peProiecte}
                onChange={(e) => {
                  this.setState(
                    {
                      peProiecte: e.currentTarget.checked,
                    },
                    () => {
                      this.GetValues();
                    }
                  );
                }}
              />
            </Col>
          </Row>
        </List>

        {this.renderGridRepartitie(this.state.dataSource, 0)}
      </div>
    ) : (
      <div
        style={{
          position: "absolute",
          width: "35px",
          height: "35px",
          top: "50vh",
          left: "50%",
        }}>
        <Preloader size={40} color={"var(--f7-theme-color)"} />
      </div>
    );
  }
}
export default RepartitieSalariatiInPerioada;