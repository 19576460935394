import React from "react";
import { Navbar, Page, Popup } from "framework7-react";
import PVSheetClientPerioada from "./PVSheetClientPerioada";
class PVSheetClientPerioadaSheet extends React.PureComponent {
    render() {
        return (
            <Popup
                className={"SheetDinamic"}
                closeOnEscape={true}
                push
            >
                <Page pageContent={false}>
                    <Navbar backLink title="Info" noShadow sliding={false}>
                    </Navbar>
                    <div style={{
                        maxHeight: "calc(50vh - 35px)",
                        padding: "34px 8px 8px 8px",
                        overflow: "auto"
                    }}>
                        <PVSheetClientPerioada />
                    </div>
                </Page>
            </Popup>
        );
    }
}
export default PVSheetClientPerioadaSheet;