import React, { Component } from "react";
import { Row, Col } from "framework7-react";
import Grid from "../../Proiecte/Agenda/Grid";
import DataGrid from "../../Datagrid/DataGrid";

/**
 * @description Primeste parametru datasource-ul cu informatiile taskurilor de la clientul selectat
 * @date 06/04/2022
 * @class OreLucrateListaSarcini
 * @extends {Component}
 */
class OreLucrateListaSarcini extends Component {
  constructor(props) {
    super(props);
    var inputs = [
      { key: "IdSarcina", keyBaza: "iD_SARCINA", tip: "number" },
      {
        key: "Proiect",
        keyBaza: "iD_PROIECT",
        tip: "selectbox",
        lista: this.$f7.data.Proiecte,
      },
      {
        key: "Salariat",
        keyBaza: "iD_SALARIAT",
        tip: "selectbox",
        lista: this.$f7.data.Salariati,
      },
      {
        key: "DeCine",
        keyBaza: "dE_CINE",
        tip: "selectbox",
        lista: this.$f7.data.Salariati,
      },
      { key: "DataAlocarii", keyBaza: "datA_ALOCARII", tip: "datebox" },
      { key: "DataDealocarii", keyBaza: "datA_DEALOCARII", tip: "datebox" },
      {
        key: "SalariatDealocare",
        keyBaza: "dealoC_CINE",
        tip: "selectbox",
        lista: this.$f7.data.Salariati,
      },
      { key: "UltimaStare", keyBaza: "stare", tip: "number" },
      { key: "NivelProiect", keyBaza: "nivelProiect", tip: "number" },
    ];
    var propsDatasource = this.props.dataSource;
    propsDatasource.map((eveniment) => {
      eveniment = this.$f7.methods.restructurareBaza(eveniment, inputs, "key");
      eveniment.NivelProiect = 2;
      switch (eveniment.UltimaStare) {
        case 4: {
          eveniment.UltimaStare = 6;
          break;
        }
        default: {
          break;
        }
      }
      return false;
    });
    var date = { data: propsDatasource };
    var baza = this.$f7.methods.reconstructieBaza(date, inputs);
    this.state = {
      showDreapta: true,
      height: window.innerHeight - 34,
      inputs: inputs,
      dataSource: baza,
    };
  }

  /**
   * @description Este apelata din datagridul randat cand este apasat un rand din datagrid pentur a afisa informatiile din dreapta(Datele generale ale taskului).
   * @function
   * @date 06/04/2022
   * @memberof OreLucrateListaSarcini
   */
  updateDreapta = () => {
    this.setState(
      {
        showDreapta: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            showDreapta: true,
          });
        }, 100);
      }
    );
  };

  render() {
    return (
      <Row>
        <Col style={{ padding: 8 }}>
          <DataGrid
            keys={["Proiect", "Salariat", "DataAlocarii"]}
            dataLoaded={true}
            dataSource={this.state.dataSource}
            inputs={this.state.inputs}
            name={"SarciniClientPopup"}
            id={"IdSarcina"}
            ref={this.refDataGrid}
            renderUpdate={false}
            renderDelete={false}
            renderAdd={false}
            maxHeight={this.state.height}
            onRowClick={(state) => {
              var id = state.rowData.Proiect;
              var Proiect = this.$f7.data.allProiecte.filter((pr) => {
                return pr.iD_PROIECT === id;
              })[0];
              this.$f7.data.Proiect = Proiect;
              this.updateDreapta();
            }}
          />
        </Col>
        <Col
          style={{
            position: "relative",
            height: "calc(100vh - 34px)",
          }}>
          {this.state.showDreapta ? <Grid /> : null}
        </Col>
      </Row>
    );
  }
}
export default OreLucrateListaSarcini;