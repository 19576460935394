import React, { Component } from "react";
import { Page } from "framework7-react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";

class Documentare extends Component {
    constructor(props) {
        super(props);

        this.height = window.innerHeight;
        this.Salariati = this.$f7.data.toggleTotiSalariatii ? this.$f7.data.Salariati : this.$f7.data.SalariatiActivi;

        this.state = {
            dataLoaded: false,
            height: window.innerHeight - 44,
            linkApi: "/api/Documente",
            dataSourceDocumente: [],
            inputs: [
                { key: "Id_Document" },
                {
                    key: "Autor",
                    tip: "selectbox",
                    lista: this.$f7.data.Salariati,
                    listaEdit: this.Salariati,
                    requiredAdd: true,
                    requiredEdit: true
                },
                {
                    key: "Data", tip: "datebox",
                    requiredAdd: true,
                    requiredEdit: true
                },
                {
                    key: "Nume_Fisier",
                    requiredAdd: true,
                    requiredEdit: true
                },
                {
                    key: "TipDocument",
                    tip: "selectbox",
                    lista: this.$f7.data.TipDocument,
                    requiredAdd: true,
                    requiredEdit: true
                },
                { key: "Comentariu" },
                { key: "Document", tip: "filepicker" },
            ],
        };

        this.refDataGridDocumentare = React.createRef();
    }

    IaValori = () => {
        this.setState({
            dataLoaded: false,
        });

        var baseURL = this.$f7.data.baseURL;
        const reqInfo = axios.get(
            baseURL + "/api/Proiecte/GetPROIECTESauArhivate/" + this.$f7.data.Proiect.iD_PROIECT
        );
        axios
            .all([reqInfo])
            .then(
                axios.spread((responseInfo) => {
                    var dataInfo = responseInfo.data;
                    this.setState({ proiect: dataInfo }, () => {
                        this.GetValues();
                    });
                })
            )
            .catch((error) => {
                this.setState(
                    {
                        proiect: null,
                        dataSourceDocumente: [],
                        dataLoaded: true,
                    },
                    () => {
                        this.refDataGridDocumentare.current.setRenderItems([]);
                        this.$f7.dialog.confirm(
                            "A aparut o eroare la preluarea datelor despre proiect de pe server. Incearca din nou.",
                            "Ups...",
                            () => {
                                this.IaValori();
                            },
                            () => { }
                        );
                    }
                );

                console.log("error", error);
            });
    };

    GetValues = () => {
        this.setState({
            dataLoaded: false,
        });

        axios
            .get(
                this.$f7.data.baseURL +
                this.state.linkApi +
                "/GetDOCUMENTEbyProiectId2/" +
                this.$f7.data.Proiect.iD_PROIECT
            )
            .then((response) => {
                let date = response;
                date.data.map((obj) => {
                    obj["Data"] = obj.datA_MODIF;
                    obj["Autor"] = obj.saL_AUTOR;
                    obj["TipDocument"] = obj.iD_TIP_DOCUMENT;
                    obj["Document"] = "";
                    return null;
                });

                let baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

                this.setState(
                    {
                        dataSourceDocumente: baza.length === 0 ? [] : baza,
                    },
                    () => {
                        setTimeout(() => {
                            this.setState(
                                {
                                    dataLoaded: true,
                                },
                                () => {
                                    this.refDataGridDocumentare.current.setRenderItems(baza);
                                }
                            );
                        }, 400);
                    }
                );
            })
            .catch((error) => {
                this.setState(
                    {
                        dataSourceDocumente: [],
                        dataLoaded: true,
                    },
                    () => {
                        this.refDataGridDocumentare.current.setRenderItems([]);
                        this.$f7.dialog.confirm(
                            "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                            "Ups...",
                            () => {
                                this.GetValues();
                            },
                            () => { }
                        );
                    }
                );

                console.log("error", error);
            });
    };

    componentDidMount = () => {
        if (this.$f7.data.Proiect !== null) this.IaValori();
    };

    AddValue = (data) => {
        var url = this.$f7.data.baseURL + this.state.linkApi + "/PostDOCUMENTE";
        axios({
            data: data,
            headers: { "Content-Type": "application/json" },
            method: "POST",
            url: url,
        })
            .then((response) => {
                this.GetValues();
                this.$f7.methods.openNotification("Adaugat cu success");
            })
            .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la adaugare");
            });
    };

    UpdateValue = (obj, id) => {
        axios
            .get(
                this.$f7.data.baseURL + this.state.linkApi + "/GetDOCUMENTEbyId/" + id
            )
            .then((response) => {
                var obj2 = response.data;
                Object.keys(obj).map((trasatura) => {
                    Object.keys(obj2).map((trasatura2) => {
                        if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
                            obj2[trasatura2] = obj[trasatura];
                        }
                        return false;
                    });
                    return false;
                });

                var url =
                    this.$f7.data.baseURL + this.state.linkApi + "/PutDOCUMENTE/" + id;
                axios({
                    data: obj2,
                    headers: { "Content-Type": "application/json" },
                    method: "put",
                    url: url,
                })
                    .then((response) => {
                        this.GetValues();
                        this.$f7.methods.openNotification("Modificat cu success");
                    })
                    .catch((error) => {
                        console.log("error", error);
                        this.$f7.methods.openNotification("Eroare la modificare");
                    });
            });
    };

    DeleteValue = (id) => {
        var url =
            this.$f7.data.baseURL + this.state.linkApi + "/DeleteDOCUMENTE/" + id;
        axios({
            headers: { "Content-Type": "application/json" },
            method: "delete",
            url: url,
        })
            .then((response) => {
                this.GetValues();
                this.$f7.methods.openNotification("Sters cu success");
            })
            .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la stergere");
            });
    };

    render() {
        return (
            <Page pageContent={false}>
                {this.$f7.data.Proiect !== null ?
                    <DataGrid
                        dataLoaded={this.state.dataLoaded}
                        refresh={this.GetValues}
                        name={"Documentare"}
                        id={"Id_Document"}
                        ref={this.refDataGridDocumentare}
                        onAdd={(state) => {
                            var obj = { ...state.editedItem };
                            var fisier = obj.Document;
                            obj.Id_Proiect = this.$f7.data.Proiect.iD_PROIECT;
                            obj.dATA_MODIF = obj["Data"];
                            obj.Nume_Fisier = fisier !== null ? fisier.name : "";
                            obj.Nume_Path = fisier !== null ? fisier.name : "";
                            obj.sAL_AUTOR = obj["Autor"];
                            obj.iD_TIP_DOCUMENT = obj["TipDocument"];
                            delete obj.Document;
                            delete obj.Autor;
                            delete obj.TipDocument;
                            delete obj.Data;
                            delete obj.Id_Document;
                            obj.id_initial = null;
                            obj.dir_parinte = null;
                            obj.ref_document = null;
                            var file = fisier;
                            if (file !== null && file !== "" && file !== undefined) {
                                var reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                    var res = reader.result;
                                    obj["continut"] = res.split(",")[1];
                                    this.AddValue(obj);
                                };
                                reader.onerror = (error) => {
                                    console.log("Error: ", error);
                                };
                            } else {
                                obj["continut"] = null;
                                this.AddValue(obj);
                            }
                        }}
                        onUpdate={(state) => {
                            var obj = { ...state.editedItem };
                            var fisier = obj.Document;
                            obj.Id_Proiect = this.$f7.data.Proiect.iD_PROIECT;
                            obj.dATA_MODIF = obj["Data"];
                            obj.Nume_Fisier = fisier !== null ? fisier.name : "";
                            obj.Nume_Path = fisier !== null ? fisier.name : "";
                            obj.sAL_AUTOR = obj["Autor"];
                            obj.iD_TIP_DOCUMENT = obj["TipDocument"];
                            delete obj.Document;
                            delete obj.Autor;
                            delete obj.TipDocument;
                            delete obj.Data;

                            var file = fisier;
                            if (
                                file !== null &&
                                file !== "" &&
                                file !== undefined &&
                                typeof file !== "string"
                            ) {
                                var reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                    var res = reader.result;
                                    obj["continut"] = res.split(",")[1];
                                    this.UpdateValue(obj, obj.Id_Document);
                                };
                                reader.onerror = (error) => {
                                    console.log("Error: ", error);
                                };
                            } else {
                                obj["continut"] = null;
                                this.UpdateValue(obj, obj.Id_Document);
                            }
                        }}
                        onDelete={(state) => {
                            this.DeleteValue(state.editedItem.Id_Document);
                        }}
                        renderUpdate={true}
                        renderDelete={true}
                        renderAdd={true}

                        maxHeight={this.state.height}
                        dataSource={this.state.dataSourceDocumente}
                        inputs={this.state.inputs}
                    />
                    :
                    <div style={{
                        position: "relative",
                        height: "calc(100vh - 50px)"
                    }}>
                        <h3 style={{
                            position: "absolute",
                            top: "50%",
                            left: 0,
                            right: 0,
                            transform: "translateY(-50%)",
                            textTransform: "uppercase",
                            textAlign: "center",
                            display: "block"
                        }}>
                            Nu ati selectat nimic
                        </h3>
                    </div>
                }
            </Page>
        );
    }
}
export default Documentare;