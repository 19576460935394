import React, { Component } from "react";
import { Icon, List, ListItem } from "framework7-react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";

 /**
  * @description Afiseaza un datagrid informatiile nume, bugetTotal, cheltuieliTotal,bugetProiect,cheltuieliProiect si un selectbox pentru a filtra proiectele.
  * @date 06/04/2022
  * @class Bugete
  * @extends {Component}
  */
 class Bugete extends Component {
   constructor(props) {
     super(props);

     this.state = {
       dataLoaded: true,
       linkApi: "/api/Bugete",
       proiect: null,
       dataSource: [],
       inputs: [
         { key: "nume" },
         { key: "bugetTotal", tip: "number" },
         { key: "cheltuieliTotal", tip: "number" },
         { key: "bugetProiecte", tip: "number" },
         { key: "cheltuieliProiecte", tip: "number" },
       ],
       height: window.innerHeight - 44,
     };

     this.refDataGrid = React.createRef();
   }
   /**
    * @description  Ia valorile necesare pentru randare de la endpointul "/GetBugete/{id}" unde id este id-ul proiectului selectat;
    * @function
    * @date 06/04/2022
    * @param {*} id
    * @memberof Bugete
    */
   GetValues = (id) => {
     this.setState({
       dataLoaded: false,
     });

     axios
       .get(this.$f7.data.baseURL + this.state.linkApi + "/GetBugete/" + id)
       .then((response) => {
         let baza = response.data;

         this.setState(
           {
             dataSource: baza,
           },
           () => {
             setTimeout(() => {
               this.setState(
                 {
                   dataLoaded: true,
                 },
                 () => {
                   if (baza.length > 0) {
                     this.refDataGrid.current.setRenderItems(baza);
                   }
                 }
               );
             }, 400);
           }
         );
       })
       .catch((error) => {
         this.setState(
           {
             dataSource: [],
             dataLoaded: true,
           },
           () => {
             this.$f7.dialog.confirm(
               "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
               "Ups...",
               () => {
                 this.GetValues(this.state.proiect);
               },
               () => {}
             );
           }
         );

         console.log("error", error);
       });
   };

   render() {
     return (
       <div style={{ position: "relative" }}>
         <List
           className="optiuniActivitate butoaneContextDatagrid"
           style={{
             position: "absolute",
             top: -8,
             left: 0,
             width: "calc(100% - 176px)",
             margin: 0,
           }}>
           <ListItem
             className="roundedOutlinedSmartSelect"
             floatingLabel
             required
             label={"Proiect"}
             validate
             value={this.$f7.data.Proiecte[0].value}
             title={"Proiect"}
             smartSelect
             smartSelectParams={{
               virtualList: true,
               searchbar: true,
               openIn: "popup",
               popupTabletFullscreen: true,
               popupPush: true,
               closeOnSelect: true,
               on: {
                 closed: (event) => {
                   var val = event.getValue();

                   this.setState(
                     {
                       proiect: val,
                     },
                     () => {
                       if (this.state.proiect === "Fara") {
                         this.$f7.methods.openNotification(
                           "Alege un proiect din lista.",
                           "Atentie!"
                         );
                       } else {
                         this.GetValues(this.state.proiect);
                       }
                     }
                   );
                 },
               },
             }}>
             <select
               defaultValue={this.$f7.data.Proiecte[0].value}
               name="Proiect">
               {this.$f7.data.Proiecte.map((obj, index) => (
                 <option key={"proiecteBugete-" + index} value={obj.value}>
                   {obj.key}
                 </option>
               ))}
             </select>
             <Icon
               slot="content-start"
               style={{ color: "var(--f7-theme-color)", left: 0, height: 14 }}
               size={20}
               material="list"
             />
           </ListItem>
         </List>

         <DataGrid
           dataLoaded={this.state.dataLoaded}
           refresh={this.GetValues}
           name={"Bugete"}
           ref={this.refDataGrid}
           renderUpdate={false}
           renderDelete={false}
           renderAdd={false}
           maxHeight={this.state.height}
           dataSource={this.state.dataSource}
           inputs={this.state.inputs}
         />
       </div>
     );
   }
 }
export default Bugete;