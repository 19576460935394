import React, { Component } from "react";
import { Page } from "framework7-react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";

class EroriRezolvari extends Component {
    constructor(props) {
        super(props);
        this.height = window.innerHeight;
        this.Salariati = this.$f7.data.toggleTotiSalariatii ? this.$f7.data.Salariati : this.$f7.data.SalariatiActivi;

        this.state = {
            dataLoaded: false,
            height: window.innerHeight - 44,
            linkApiStadiu: "/api/TipStadiu",
            linkApiFirme: "/api/Firme",
            linkApiSalariati: "/api/Salariati/GetSALARIATI",
            linkApiIncidenteById: "/api/Incidente/GetINCIDENTE",
            linkApiIncidenteUpdate: "/api/Incidente/PutINCIDENTE",
            linkApiIncidenteFull: "/api/Incidente/GetINCIDENTE",
            linkApiIncidente: "/api/Incidente/GetINCIDENTEbyProiectId",
            dataSource: [],
            input: [
                { key: "IdIncident" },
                {
                    key: "Problema",
                    requiredAdd: true,
                    requiredEdit: true
                },
                {
                    key: "DataSemnalarii", tip: "datebox",
                    requiredAdd: true,
                    requiredEdit: true
                },
                { key: "NumarInregistrare" },
                {
                    key: "ReceptionatDe",
                    tip: "selectbox",
                    lista: this.$f7.data.Salariati,
                    listaEdit: this.Salariati
                },
                { key: "ReferintaAltaProblema", tip: "selectbox", lista: [] },
                { key: "LinkDocument", tip: "filepicker" },
                {
                    key: "Responsabil",
                    tip: "selectbox",
                    lista: this.$f7.data.Salariati,
                    listaEdit: this.Salariati
                },
                { key: "TimpEstimat", tip: "number" },
                { key: "Client", tip: "selectbox", lista: this.$f7.data.Firme },
                {
                    key: "Comentariu",
                    requiredAdd: true,
                    requiredEdit: true
                },
                { key: "DataRezolvarii", tip: "datebox" },
                {
                    key: "RezolvatDe",
                    tip: "selectbox",
                    lista: this.$f7.data.Salariati,
                    listaEdit: this.Salariati
                },
                { key: "TimpRezolvare", tip: "number" },
                { key: "Stadiu", tip: "selectbox", lista: this.$f7.data.Stadiu },
                { key: "CosturiTotale", tip: "number" },
                { key: "Resurse" },
            ],
        };

        this.refDataGrid = React.createRef();
    }

    GetValues = () => {
        this.setState({
            dataLoaded: false,
        });

        const requestIncidenteFull = axios.get(
            this.$f7.data.baseURL + this.state.linkApiIncidenteFull
        );
        const requestIncidente = axios.get(
            this.$f7.data.baseURL +
            this.state.linkApiIncidente +
            "/" +
            this.$f7.data.Proiect.iD_PROIECT
        );

        axios
            .all([requestIncidenteFull, requestIncidente])
            .then(
                axios.spread((responseIncidenteFull, responseIncidente) => {
                    let incidente = responseIncidenteFull.data;
                    let change = responseIncidente;
                    let input = [...this.state.input];
                    let listaIncidente = [];
                    listaIncidente.push({ key: "Fara", value: null });

                    for (let i in incidente) {
                        let toAdd = { key: "", value: 0 };

                        if (
                            incidente[i].problema !== "" &&
                            incidente[i].problema !== null
                        ) {
                            toAdd.key = incidente[i].problema;
                            toAdd.value = incidente[i].iD_INCIDENT;
                            listaIncidente.push(toAdd);
                        }
                    }
                    input[5].lista = listaIncidente;

                    for (let item in change.data) {
                        change.data[item]["IdIncident"] = change.data[item].iD_INCIDENT;
                        change.data[item]["Problema"] =
                            change.data[item].problema === null ||
                                change.data[item].problema === ""
                                ? ""
                                : change.data[item].problema;
                        change.data[item]["DataSemnalarii"] = change.data[item].datA_SEMN;
                        change.data[item]["NumarInregistrare"] =
                            change.data[item].numaR_INREGISTRARE;
                        change.data[item]["ReceptionatDe"] = change.data[item].saL_RECEPTIE;
                        change.data[item]["ReferintaAltaProblema"] =
                            change.data[item].reF_INCIDENT;
                        change.data[item]["LinkDocument"] =
                            change.data[item].doC_LINK === null ||
                                change.data[item].doC_LINK === ""
                                ? "Fara"
                                : change.data[item].doC_LINK;
                        change.data[item]["Responsabil"] =
                            change.data[item].saL_RESPONSABIL;
                        change.data[item]["TimpEstimat"] = change.data[item].timP_ESTIMAT;
                        change.data[item]["Client"] = change.data[item].iD_CLIENT;
                        change.data[item]["Comentariu"] =
                            change.data[item].comentariu === null ||
                                change.data[item].comentariu === ""
                                ? "Fara"
                                : change.data[item].comentariu;
                        change.data[item]["DataRezolvarii"] =
                            change.data[item].datA_REZOLVARII;
                        change.data[item]["RezolvatDe"] = change.data[item].saL_REZOLVARE;
                        change.data[item]["TimpRezolvare"] =
                            change.data[item].timP_REZOLVARE;
                        change.data[item]["Stadiu"] = change.data[item].iD_STADIU;
                        change.data[item]["CosturiTotale"] =
                            change.data[item].costurI_TOTALE;
                        change.data[item]["Resurse"] =
                            change.data[item].resurse === null ||
                                change.data[item].resurse === ""
                                ? "Fara"
                                : change.data[item].resurse;
                    }

                    let baza = this.$f7.methods.reconstructieBaza(
                        change,
                        this.state.input
                    );

                    this.setState(
                        {
                            input: input,
                            dataSource: baza,
                        },
                        () => {
                            setTimeout(() => {
                                this.setState(
                                    {
                                        dataLoaded: true,
                                    },
                                    () => {
                                        this.refDataGrid.current.setRenderItems(baza);
                                    }
                                );
                            }, 400);
                        }
                    );
                })
            )
            .catch((error) => {
                this.setState(
                    {
                        dataSource: [],
                        dataLoaded: true,
                    },
                    () => {
                        this.refDataGrid.current.setRenderItems([]);
                        this.$f7.dialog.confirm(
                            "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                            "Ups...",
                            () => {
                                this.GetValues();
                            },
                            () => { }
                        );
                    }
                );

                console.log("error", error);
            });
    };

    UpdateValue = (obj, id) => {
        axios
            .get(this.$f7.data.baseURL + this.state.linkApiIncidenteById + "/" + id)
            .then((response) => {
                var obj2 = response.data;
                Object.keys(obj).map((trasatura) => {
                    Object.keys(obj2).map((trasatura2) => {
                        if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
                            obj2[trasatura2] = obj[trasatura];
                        }
                        return false;
                    });
                    return false;
                });

                var url =
                    this.$f7.data.baseURL + this.state.linkApiIncidenteUpdate + "/" + id;
                axios({
                    data: obj2,
                    headers: { "Content-Type": "application/json" },
                    method: "put",
                    url: url,
                })
                    .then((response) => {
                        this.GetValues();
                        this.$f7.methods.openNotification("Modificat cu success");
                    })
                    .catch((error) => {
                        console.log("error", error);
                        this.$f7.methods.openNotification("Eroare la modificare");
                    });
            })
            .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la modificare");
            });
    };

    componentDidMount = () => {
        if (this.$f7.data.Proiect !== null) this.GetValues();
    };

    render() {
        return (
            <Page pageContent={false}>
                {this.$f7.data.Proiect !== null ?
                    <DataGrid
                        dataLoaded={this.state.dataLoaded}
                        refresh={this.GetValues}
                        name={"EroriRezolvari"}
                        ref={this.refDataGrid}
                        renderUpdate={true}
                        renderDelete={false}
                        renderAdd={false}

                        maxHeight={this.state.height}
                        dataSource={this.state.dataSource}
                        inputs={this.state.input}
                        onUpdate={(state) => {
                            let data = { ...state.editedItem };

                            data["costurI_TOTALE"] = data.CosturiTotale;
                            data["datA_REZOLVARII"] = data.DataRezolvarii;
                            data["datA_SEMN"] = data.DataSemnalarii;
                            data["doC_LINK"] =
                                data.LinkDocument !== null
                                    ? typeof data.LinkDocument === "string"
                                        ? data.LinkDocument
                                        : data.LinkDocument.name
                                    : "";
                            data["iD_CLIENT"] = data.Client;
                            data["iD_INCIDENT"] = data.IdIncident;
                            data["iD_STADIU"] = data.Stadiu;
                            data["numaR_INREGISTRARE"] = data.NumarInregistrare;
                            data["reF_INCIDENT"] = data.ReferintaAltaProblema;
                            data["saL_RECEPTIE"] = data.ReceptionatDe;
                            data["saL_RESPONSABIL"] = data.Responsabil;
                            data["saL_REZOLVARE"] = data.RezolvatDe;
                            data["timP_ESTIMAT"] = data.TimpEstimat;
                            data["timP_REZOLVARE"] = data.TimpRezolvare;

                            delete data.CosturiTotale;
                            delete data.DataRezolvarii;
                            delete data.DataSemnalarii;
                            delete data.LinkDocument;
                            delete data.Client;
                            delete data.IdIncident;
                            delete data.Stadiu;
                            delete data.NumarInregistrare;
                            delete data.ReferintaAltaProblema;
                            delete data.ReceptionatDe;
                            delete data.Responsabil;
                            delete data.RezolvatDe;
                            delete data.TimpEstimat;
                            delete data.TimpRezolvare;

                            this.UpdateValue(data, data.iD_INCIDENT);
                        }}
                        ignoreInputsAdd={["IdIncident"]}
                    />
                    :
                    <div style={{
                        position: "relative",
                        height: "calc(100vh - 50px)"
                    }}>
                        <h3 style={{
                            position: "absolute",
                            top: "50%",
                            left: 0,
                            right: 0,
                            transform: "translateY(-50%)",
                            textTransform: "uppercase",
                            textAlign: "center",
                            display: "block"
                        }}>
                            Nu ati selectat nimic
                        </h3>
                    </div>
                }
            </Page>
        );
    }
}
export default EroriRezolvari;