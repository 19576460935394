import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";

/**
 * @description Interfata de intretinere a operatiunilor de se fac pe un client
 * @date 07/04/2022
 * @class OperatiuniClienti
 * @extends {React.Component}
 */
class OperatiuniClienti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      linkApi: "/api/TipOperatiuni",
      denumire: "Operatiuni clienti",
      height: window.innerHeight - 44,
      dataSource: [],
      inputs: [
        { key: "Id_Operatiune", tip: "number" },
        { key: "Denumire", requiredAdd: true, requiredEdit: true },
        { key: "Comentariu" },
        { key: "Pe_Task", tip: "bool" },
        {
          key: "Ref_Operatiune",
          tip: "selectbox",
          lista: this.$f7.data.Operatiuni,
        },
      ],
    };
  }

  /**
   * @description Ia valorile necesare randarii interfetei(OperatiuniClienti)
   * @function
   * @date 07/04/2022
   * @memberof OperatiuniClienti
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApi)
      .then((response) => {
        var baza = this.$f7.methods.reconstructieBaza(
          response,
          this.state.inputs
        );
        console.log("baza", baza);
        this.setState({
          dataSource: baza,
          dataLoaded: true,
        });
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Adauga o noua inregistrare in baza de date(OperatiuniClienti)
   * @date 07/04/2022
   * @function
   * @param {*} data obiect cu valorile noii inregistrari
   * @memberof OperatiuniClienti
   */
  AddValue = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApi;
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza in baza de date inregistrarea selectata(OperatiuniClienti)
   * @date 07/04/2022
   * @function
   * @param {*} obj obiect cu valorile noi ale inregistrarii
   * @param {*} id id-ul inregistrarii selectate
   * @memberof OperatiuniClienti
   */
  UpdateValue = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url = this.$f7.data.baseURL + this.state.linkApi + "/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description  Sterge din baza de date inregistrarea selectata
   * @function
   * @date 07/04/2022
   * @param {*} id id-ul inregistrarii selectate
   * @memberof OperatiuniClienti
   */
  DeleteValue = (id) => {
    var url = this.$f7.data.baseURL + this.state.linkApi + "/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return (
      <DataGrid
        dataLoaded={this.state.dataLoaded}
        refresh={this.GetValues}
        name={"OperatiuniClienti"}
        keys={["Denumire", "Comentariu", "Pe_Task"]}
        id={"Id_Operatiune"}
        refId={"Ref_Operatiune"}
        initialRefId={-1}
        renderAdd={true}
        renderDelete={true}
        renderUpdate={true}
        renderAccordion={true}
        dataSource={this.state.dataSource}
        maxHeight={this.state.height}
        inputs={this.state.inputs}
        onAdd={(state) => {
          var data = { ...state.editedItem };
          delete data.Id_Operatiune;
          this.AddValue(data);
        }}
        onDelete={(state) => {
          var data = { ...state.editedItem };
          this.DeleteValue(data.Id_Operatiune);
        }}
        onUpdate={(state) => {
          var data = { ...state.editedItem };
          this.UpdateValue(data, data.Id_Operatiune);
        }}
      />
    );
  }
}
export default OperatiuniClienti;
