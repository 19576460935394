import React from "react";
import { Page } from "framework7-react";
import GantRealizari from "../Componente/GantRealizari";

class GantRealizariPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { denumire: "Gant realizari" };
    }
    render() {
        return (
            <Page
                className={"themeBackground"}
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
            >

                <div className="ptr3">
                    <GantRealizari />
                </div>
            </Page>
        );
    }
}
export default GantRealizariPage;