import React from "react";
import { Segmented, Button } from "framework7-react";
import DataGrid from "../../Datagrid/DataGrid";
import ATMain from "../../AlocareTask/ATMain";
/**
 * @description Afiseaza sarcinile inchise ale utilizatorului selectat/logat si ale subalternilor
 * @date 08/04/2022
 * @class SarciniInchiseSubalterniGrid
 * @extends {React.Component}
 */
class SarciniInchiseSubalterniGrid extends React.Component {
  constructor(props) {
    super(props);

    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;

    this.state = {
      linkApiProiecte: "/api/Proiecte",
      durata: null,
      durataTotal: null,
      lat: 0,
      lng: 0,
      idProiectVechi: null,
      dataLoaded: false,
      denumire: "Sarcini alocate",
      height: window.innerHeight - 96,
      linkApi: "/api/SarciniSalariat",
      linkApiRealizari: "/api/RealizareSarcini",
      linkApiGetProiect: "/api/Proiecte/GetPROIECTESauArhivate",
      linkApiUpdateProiect: "/api/Proiecte/PutPROIECTE",
      linkApiDelegatii: "/api/Delegatii",
      linkApiDetaliu: "/api/DetaliuDelegatii",
      dataSource: [],
      dataSourceHidden: [],
      inputs: [
        { key: "💾", keyBaza: "areDocumente", tip: "boolean", disabled: true },
        { key: "IdSarcina", keyBaza: "idSarcina", tip: "number" },
        { key: "IdProiect", keyBaza: "idProiect", tip: "number" },
        { key: "Denumire", keyBaza: "denumire", columnWidth: "250px" },
        { key: "Cerinta", keyBaza: "comentariu", columnWidth: "500px" },
        { key: "Parinte", keyBaza: "parinte" },
        {
          key: "DataCreare",
          keyBaza: "dataCreare",
          tip: "datebox",
          columnWidth: "100px",
        },
        { key: "DataInchiderii", keyBaza: "dataAlocarii", tip: "datebox" },
        { key: "DeCine", keyBaza: "deCine" },
        { key: "Deadline", keyBaza: "deadline", tip: "datebox" },
        {
          key: "Client",
          keyBaza: "idClient",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
          columnWidth: "20px",
        },
        {
          key: "Salariat",
          keyBaza: "idSalariat",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          columnWidth: "20px",
        },
        { key: "Sursa", keyBaza: "sursa", columnWidth: "20px" },
        {
          key: "PR",
          keyBaza: "idPrioritate",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
          columnWidth: "20px",
        },

        { key: "Exista", keyBaza: "exista", tip: "number" },
        {
          key: "Departament",
          keyBaza: "departament",
          tip: "selectbox",
          lista: this.$f7.data.Departamente,
        },
        { key: "IsHidden", keyBaza: "isHidden", disabled: true, tip: "bool" },
        { key: "IsArhivat", keyBaza: "isArhivat", disabled: true, tip: "bool" },
        { key: "UltimaStare", keyBaza: "ultimaStare", tip: "number" },
        {
          key: "NivelProiect",
          keyBaza: "id_nivel_proiect",
          tip: "selectbox",
          lista: this.$f7.data.NivelProiect,
        },
        { key: "Path", keyBaza: "path" },
        {
          key: "OperatorAdaugare",
          keyBaza: "id_operator_adaugare",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          columnWidth: "20px",
        },
        { key: "BugetTimp", keyBaza: "buget_timp", tip: "number" },
        { key: "TimpPlanD", keyBaza: "timp_plan_d", tip: "number" },
        { key: "F", keyBaza: "facturat", disabled: true, tip: "bool" },
        { key: "TimpLucrat", keyBaza: "timp_l", tip: "number" },
        { key: "PVuri", keyBaza: "apartenenta_pv" },
      ],
      sfarsit: false,
      idSelected: null,
      idSarcina: null,
      idPropunere: null,
      deschideNou: false,
      proiectDeschis: null,
      inchideDupaOprire: false,
      realocaDupaOprire: false,
      comInchidereTask: false,
      idClientTelefon: null,
      idProiectParinteTelefon: null,
    };

    this.refDataGrid = React.createRef();
    this.refHiddenDataGrid = React.createRef();
  }

  componentDidMount = () => {
    this.$f7.data.selectedGridLeft = this;
    this.GetValues()
      .then((response) => {
        //axios.get("/api/SarciniSalariat/GetMantisTaskuri");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * @description Afiseza interfata de propunere a taskului selectat altcuiva
   * @function
   * @date 08/04/2022
   * @memberof SarciniInchiseSubalterniGrid
   */
  PropuneCall = () => {
    this.$f7.dialog.confirm(
      "Sunteti sigur ca doriti sa realocati sarcinile selectate?",
      "Propunere",
      (e) => {
        this.$f7.methods
          .preCall(this.refDataGrid)
          .then((response) => {
            var listaIduri = null;
            var state = this.refDataGrid.current.state;
            if (state.selectedRows.length > 0) {
              listaIduri = [];
              state.selectedRows.map((item) => {
                listaIduri.push(item.IdProiect);
                return null;
              });
            } else {
              listaIduri = this.state.idSelected;
            }
            this.$f7.data.ATidProiect = listaIduri;
            this.$f7.data.router.navigate("/popupDinamic/", {
              props: {
                onPopupClose: (r) => {
                  this.$f7.methods.refreshStanga();
                },
                component: <ATMain Proiect={null} idProiect={null} />,
              },
            });
          })
          .catch((err) => {
            this.$f7.methods.openNotification("Eroare" + err.toString());
          });
      }
    );
  };

  /**
   * @description Parcurge baza de salariati trimisa pentru a gasi salariatii subalterni
   * @date 08/04/2022
   * @param {*} baza baza de salariati de cautat
   * @param {*} refId id-ul de referinta(parinte)
   * @param {*} arr array-ul in care se adauga valorile
   * @function
   * @memberof SarciniInchiseSubalterniGrid
   */
  recursivaSalariati = (baza, refId, arr) => {
    var insideRows = baza.filter((r) => {
      return r.ref === refId;
    });
    if (insideRows.length > 0) {
      insideRows.map((p) => {
        arr.push(p);
        arr = this.recursivaSalariati(baza, p.value, arr);
        return null;
      });
    }

    return arr;
  };

  /**
   * @description Ia toate sarcinile inchise ale utilizatorului si ale subalternilor
   * @function
   * @date 08/04/2022
   * @memberof SarciniInchiseSubalterniGrid
   */
  GetValues = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          proiectDeschis: null,
          dataLoaded: false,
          idSelected: null,
          durata: null,
        },
        () => {
          this.$f7.methods.openMainDreaptaById(null);
        }
      );

      this.$f7.methods
        .getProiecte(
          this.$f7.data.idSalariatFunctii,
          6,
          this.props.checkedTaskuri === true ? 1 : 0,
          this.props.checkedAscunse === true ? 1 : 0,
          this.props.checkedArhivate === true ? 1 : 0,
          1
        )
        .then((response) => {
          var date = response;
          date.data.map((sarcina) => {
            sarcina.departament = sarcina.proiect.id_departament;
            //Valori din proiect
            if (sarcina.proiect) {
              Object.keys(sarcina.proiect).map((property) => {
                var value = sarcina.proiect[property];
                if (sarcina[property] === undefined) {
                  sarcina[property] = value;
                }
                return null;
              });

              sarcina["path"] = sarcina.proiect["path"];
              if (sarcina["path"]) {
                var path = "";
                sarcina["path"].split("\\").map((f) => {
                  if (f !== null && f !== undefined) {
                    var idProiect = parseInt(f);
                    var pro = this.$f7.data.allProiecte.find((p) => {
                      return p.iD_PROIECT === idProiect;
                    });
                    if (pro) {
                      path += pro.denumire + " \\ ";
                    }
                  }
                  return null;
                });
                sarcina["path"] = path.slice(0, -2);
              }
            }
            sarcina = this.$f7.methods.restructurareBaza(
              sarcina,
              this.state.inputs,
              "key"
            );

            switch (sarcina.Sursa) {
              case 55:
                sarcina.Sursa =
                  "M(" + (sarcina.proiect.userImport % 10000000) + ")";
                break;
              case 56:
                sarcina.Sursa = "E";
                break;
              default:
                sarcina.Sursa = "C";
                break;
            }
            return null;
          });

          var baza = this.$f7.methods.reconstructieBaza(
            date,
            this.state.inputs
          );
          var arrSubalterni = [];

          var arrSalariatiActivi = this.$f7.data.SalariatiActivi;
          var sal = this.$f7.data.Salariati.find((r) => {
            return r.value === this.$f7.data.idSalariatFunctii;
          });
          var arr = this.recursivaSalariati(arrSalariatiActivi, sal.value, []);
          arr.push(
            ...arrSalariatiActivi.filter((item) => item.value === sal.value)
          );

          var arr2 = this.recursivaSalariati(arrSalariatiActivi, sal.ref, []);
          arr2.push(
            ...arrSalariatiActivi.filter((item) => item.value === sal.ref)
          );

          var SalariatiSubalterni = arr.filter((rf) => {
            return rf.key !== "Fara";
          });
          var SalariatiSubalterniColegi = arr2.filter((rf) => {
            return rf.key !== "Fara" && rf.value !== sal.ref;
          });

          if (sal.rol < 3) {
            SalariatiSubalterniColegi.map((r) => {
              arrSubalterni.push(r.value);
              return null;
            });
          } else {
            SalariatiSubalterni.map((r) => {
              arrSubalterni.push(r.value);
              return null;
            });
          }
          baza = baza.filter((r) => {
            return arrSubalterni.includes(r.Salariat);
          });
          this.setState(
            {
              dataSource: baza,
            },
            () => {
              setTimeout(() => {
                this.setState(
                  {
                    dataLoaded: true,
                  },
                  () => {
                    resolve(response);
                  }
                );
              }, 400);
            }
          );
        })
        .catch((error) => {
          this.setState(
            {
              dataSource: [],
              dataLoaded: true,
            },
            () => {
              this.refDataGrid.current.setRenderItems([]);
              this.$f7.dialog.confirm(
                "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                "Ups...",
                () => {
                  this.GetValues()
                    .then((resp) => {
                      resolve(resp);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                },
                () => {
                  reject(error);
                }
              );
            }
          );
        });
    });
  };

  render() {
    return (
      <>
        <div style={{ position: "relative" }}>
          <div style={{ position: "relative" }}>
            <Segmented
              className="butoaneContextDatagrid"
              raised
              tag="div"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "calc(100% - 176px)",
              }}>
              <Button
                tooltip={"Propune altcuiva"}
                onClick={() => {
                  this.PropuneCall();
                }}
                small
                fill>
                Propune cuiva
              </Button>
              <Button
                tooltip={"Refresh"}
                onClick={() => {
                  this.GetValues();
                }}
                small
                fill>
                Refresh
              </Button>
            </Segmented>
            <DataGrid
              dataLoaded={this.state.dataLoaded}
              refresh={this.GetValues}
              name={"SarciniInchiseGrid"}
              ref={this.refDataGrid}
              id={"IdSarcina"}
              onRowClick={(state) => {
                var id = state.rowData.IdProiect;
                var sarcina = state.rowData.IdSarcina;
                this.setState(
                  {
                    idSelected: id,
                    idSarcina: sarcina,
                  },
                  () => {
                    this.$f7.methods.openMainDreaptaById(this.state.idSelected);
                    this.$f7.data.agendaIdProiect = this.state.idSelected;
                    // this.GetHiddenValues();
                  }
                );
              }}
              onInitialised={(state, r, itemeRandate) => {
                this.$f7.methods.colorItems(
                  state,
                  r,
                  itemeRandate,
                  "Salariat",
                  1,
                  true
                );
              }}
              onRenderItemsChanged={(state, r, itemeRandate) => {
                this.$f7.methods.colorItems(
                  state,
                  r,
                  itemeRandate,
                  "Salariat",
                  0,
                  true
                );
              }}
              keys={[
                "💾",
                "Denumire",
                "Cerinta",
                "DataCreare",
                "DataInchiderii",
                "Client",
                "Salariat",
                "Sursa",
                "PR",
                "F",
              ]}
              ignoreInputsAdd={["Deadline"]}
              ignoreInputsEdit={["Deadline"]}
              hintInputSources={[
                "Path",
                "OperatorAdaugare",
                "BugetTimp",
                "TimpPlanD",
                "PVuri",
              ]}
              renderUpdate={false}
              renderDelete={false}
              renderAdd={false}
              renderCheckbox={true}
              maxHeight={this.state.height}
              inputs={this.state.inputs}
              dataSource={this.state.dataSource}
              contextMenuItems={[
                {
                  key: "Propune altcuiva",
                  onClick: (state) => {
                    setTimeout(() => {
                      this.PropuneCall();
                    }, 100);
                  },
                  icon: "send",
                },
                {
                  key: "Refresh",
                  onClick: (state) => {
                    setTimeout(() => {
                      this.GetValues();
                    }, 1000);
                  },
                  icon: "refresh",
                },
                {
                  key: "Arhiveaza",
                  onClick: (state) => {
                    setTimeout(() => {
                      var selectedRows = state.allSelectedRows;
                      if (selectedRows.length === 0) {
                        selectedRows = [state.rowDataAll];
                      }
                      var listaIduri = [];
                      selectedRows.map((r) => {
                        var id = r.IdProiect;
                        if (!r.IsArhivat) {
                          listaIduri.push(id);
                        }
                        return null;
                      });
                      this.$f7.methods
                        .arhiveazaDezarhiveazaProiect(listaIduri, true)
                        .then((r) => {
                          this.GetValues();
                          const nr = r.data.length;
                          this.$f7.methods.openNotification(
                            "Arhivate cu success: " + nr
                          );
                        })
                        .catch((err) => {
                          this.$f7.methods.openNotification(
                            "Eroare la arhivare" + err
                          );
                        });
                    }, 1000);
                  },
                  icon: "compress",
                },
                {
                  key: "Dezarhiveaza",
                  onClick: (state) => {
                    setTimeout(() => {
                      var selectedRows = state.allSelectedRows;
                      if (selectedRows.length === 0) {
                        selectedRows = [state.rowDataAll];
                      }
                      var listaIduri = [];
                      selectedRows.map((r) => {
                        var id = r.IdProiect;
                        if (r.IsArhivat) {
                          listaIduri.push(id);
                        }
                        return null;
                      });
                      this.$f7.methods
                        .arhiveazaDezarhiveazaProiect(listaIduri, false)
                        .then((r) => {
                          this.GetValues();
                          const nr = r.data.length;
                          this.$f7.methods.openNotification(
                            "Dezarhivate cu success: " + nr
                          );
                        })
                        .catch((err) => {
                          this.$f7.methods.openNotification(
                            "Eroare la dezarhivare" + err
                          );
                        });
                    }, 1000);
                  },
                  icon: "expand",
                },
                {
                  key: "Ascunde",
                  onClick: (state, context) => {
                    setTimeout(() => {
                      var selectedRows = state.allSelectedRows;
                      if (selectedRows.length === 0) {
                        selectedRows = [state.rowDataAll];
                      }
                      var listaIduri = [];
                      selectedRows.map((r) => {
                        var id = r.IdProiect;
                        if (!r.IsHidden) {
                          listaIduri.push(id);
                        }
                        return null;
                      });
                      this.$f7.methods
                        .ascundeAfiseazaProiect(listaIduri, true)
                        .then(() => {
                          this.GetValues();
                          this.$f7.methods.openNotification(
                            "Ascuns cu success"
                          );
                        })
                        .catch((err) => {
                          this.$f7.methods.openNotification(
                            "Eroare la ascundere" + err
                          );
                        });
                    }, 1000);
                  },
                  icon: "refresh",
                },
                {
                  key: "Afiseaza",
                  onClick: (state, context) => {
                    setTimeout(() => {
                      var selectedRows = state.allSelectedRows;
                      if (selectedRows.length === 0) {
                        selectedRows = [state.rowDataAll];
                      }
                      var listaIduri = [];
                      selectedRows.map((r) => {
                        var id = r.IdProiect;
                        if (r.IsHidden) {
                          listaIduri.push(id);
                        }
                        return null;
                      });

                      this.$f7.methods
                        .ascundeAfiseazaProiect(listaIduri, false)
                        .then(() => {
                          this.GetValues();
                          this.$f7.methods.openNotification(
                            "Afisat cu success"
                          );
                        })
                        .catch((err) => {
                          this.$f7.methods.openNotification(
                            "Eroare la afisare" + err
                          );
                        });
                    }, 1000);
                  },
                  icon: "refresh",
                },
                {
                  key: "Trimite mesaj",
                  onClick: (state) => {
                    var id = state.rowData.IdProiect;
                    this.$f7.methods.openMesaje(null, id);
                  },
                  icon: "send",
                },
              ]}
              onContextMenu={(state, target) => {
                var id = state.rowData.IdProiect;
                var sarcina = state.rowData.IdSarcina;
                this.setState(
                  {
                    idSelected: id,
                    idSarcina: sarcina,
                  },
                  () => {
                    this.$f7.methods.openMainDreaptaById(this.state.idSelected);
                    this.$f7.data.agendaIdProiect = this.state.idSelected;
                  }
                );
              }}
              coloredRow={{
                sourceCol: "Deadline",
                type: "date",
              }}
            />
          </div>
        </div>
      </>
    );
  }
}
export default SarciniInchiseSubalterniGrid;
