import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import ATMain from "../../AlocareTask/ATMain";

/**
 * @description Interfata de afiseaza toate sarcinile propuse de salariatul selectat/logat
 * @date 08/04/2022
 * @class SarciniPropuseGrid
 * @extends {React.Component}
 */
class SarciniPropuseGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoaded: false,
      denumire: "Sarcini propuse",
      height: window.innerHeight - 96,
      linkApi: "/api/SarciniSalariat",
      dataSource: [],
      inputs: [
        { key: "💾", keyBaza: "areDocumente", tip: "boolean", disabled: true },
        { key: "IdSarcina", keyBaza: "idSarcina", tip: "number" },
        { key: "IdProiect", keyBaza: "idProiect", tip: "number" },
        { key: "Denumire", keyBaza: "denumire", columnWidth: "250px" },
        { key: "Cerinta", keyBaza: "comentariu", columnWidth: "500px" },
        {
          key: "DataCreare",
          keyBaza: "dataCreare",
          tip: "datebox",
          columnWidth: "100px",
        },
        {
          key: "DataAlocarii",
          keyBaza: "dataAlocarii",
          tip: "datebox",
          columnWidth: "100px",
        },
        { key: "Deadline", keyBaza: "deadline", tip: "datebox" },
        {
          key: "Client",
          keyBaza: "idClient",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
          columnWidth: "20px",
        },
        { key: "Sursa", keyBaza: "sursa", columnWidth: "20px" },
        { key: "Parinte", keyBaza: "parinte", columnWidth: "20px" },
        {
          key: "PR",
          keyBaza: "idPrioritate",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
          columnWidth: "20px",
        },
        {
          key: "Salariat",
          keyBaza: "idSalariat",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          columnWidth: "100px",
        },
        {
          key: "UltimaStare",
          keyBaza: "ultimaStare",
          tip: "number",
          columnWidth: "100px",
        },
        { key: "StareCurenta", columnWidth: "20px" },
        {
          key: "IsHidden",
          keyBaza: "isHidden",
          columnWidth: "20px",
          disabled: true,
          tip: "bool",
        },
        {
          key: "IsArhivat",
          keyBaza: "isArhivat",
          columnWidth: "20px",
          disabled: true,
          tip: "bool",
        },
        {
          key: "NivelProiect",
          keyBaza: "id_nivel_proiect",
          tip: "selectbox",
          lista: this.$f7.data.NivelProiect,
        },
        { key: "Path", keyBaza: "path" },
        {
          key: "OperatorAdaugare",
          keyBaza: "id_operator_adaugare",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
        { key: "BugetTimp", keyBaza: "buget_timp", tip: "number" },
        { key: "TimpPlanD", keyBaza: "timp_plan_d", tip: "number" },
        { key: "TimpLucrat", keyBaza: "timp_l", tip: "number" },
        { key: "PVuri", keyBaza: "apartenenta_pv" },
      ],
      idSelected: null,
      idSarcina: null,
    };

    this.refDataGrid = React.createRef();
  }

  componentDidMount = () => {
    this.$f7.data.selectedGridLeft = this;
    this.GetValues();
  };

  /**
   * @description Ia toate sarcinile propuse de catre utilizatorul selectat
   * @function
   * @date 08/04/2022
   * @memberof SarciniPropuseGrid
   */
  GetValues = () => {
    this.setState(
      {
        dataLoaded: false,
        idSelected: null,
      },
      () => {
        this.$f7.methods.openMainDreaptaById(null);
      }
    );

    this.$f7.methods
      .getProiecte(
        this.$f7.data.idSalariatFunctii,
        7,
        this.props.checkedTaskuri === true ? 1 : 0,
        this.props.checkedAscunse === true ? 1 : 0,
        this.props.checkedArhivate === true ? 1 : 0,
        this.props.checkedInchise === true ? 1 : 0
      )
      .then((response) => {
        var date = response;

        date.data.map((sarcina) => {
          //Valori din proiect
          if (sarcina.proiect) {
            Object.keys(sarcina.proiect).map((property) => {
              var value = sarcina.proiect[property];
              if (sarcina[property] === undefined) {
                sarcina[property] = value;
              }
              return null;
            });
            sarcina["path"] = sarcina.proiect["path"];
            if (sarcina["path"]) {
              var path = "";
              sarcina["path"].split("\\").map((f) => {
                if (f !== null && f !== undefined) {
                  var idProiect = parseInt(f);
                  var pro = this.$f7.data.allProiecte.find((p) => {
                    return p.iD_PROIECT === idProiect;
                  });
                  if (pro) {
                    path += pro.denumire + " \\ ";
                  }
                }
                return null;
              });
              sarcina["path"] = path.slice(0, -2);
            }
          }

          sarcina = this.$f7.methods.restructurareBaza(
            sarcina,
            this.state.inputs,
            "key"
          );

          switch (sarcina.Sursa) {
            case 55:
              sarcina.Sursa =
                "M(" + (sarcina.proiect.userImport % 10000000) + ")";
              break;
            case 56:
              sarcina.Sursa = "E";
              break;
            default:
              sarcina.Sursa = "C";
              break;
          }
          switch (sarcina.UltimaStare) {
            case 44:
              sarcina.StareCurenta = "Neacceptata";
              break;
            case 1:
            case 2:
            case 3:
              sarcina.StareCurenta = "Acceptata";
              break;
            case 6:
              sarcina.StareCurenta = "Finalizata";
              break;
            default:
              sarcina.StareCurenta = "Neacceptata";
              break;
          }

          return false;
        });

        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Afiseaza itnerfata de propunere a taskului selectat altcuiva
   * @function
   * @date 08/04/2022
   * @memberof SarciniPropuseGrid
   */
  PropuneCall = () => {
    this.$f7.dialog.confirm(
      "Sunteti sigur ca doriti sa realocati sarcinile selectate?",
      "Propunere",
      (e) => {
        this.$f7.methods
          .preCall(this.refDataGrid)
          .then((response) => {
            var listaIduri = null;
            var state = this.refDataGrid.current.state;
            if (state.selectedRows.length > 0) {
              listaIduri = [];
              state.selectedRows.map((item) => {
                if (![1, 2, 3].includes(item.UltimaStare)) {
                  listaIduri.push(item.IdProiect);
                }
                return null;
              });
            } else {
              listaIduri = this.state.idSelected;
            }
            this.$f7.data.ATidProiect = listaIduri;
            this.$f7.data.router.navigate("/popupDinamic/", {
              props: {
                onPopupClose: (r) => {
                  this.$f7.methods.refreshStanga();
                },
                component: <ATMain Proiect={null} idProiect={null} />,
              },
            });
          })
          .catch((err) => {
            this.$f7.methods.openNotification("Eroare" + err.toString());
          });
      }
    );
  };

  render() {
    return (
      <>
        <div style={{ position: "relative" }}>
          <DataGrid
            dataLoaded={this.state.dataLoaded}
            refresh={this.GetValues}
            name={"SarciniPropuseGrid"}
            ref={this.refDataGrid}
            id={"IdSarcina"}
            onRowClick={(state) => {
              var id = state.rowData.IdProiect;
              var sarcina = state.rowData.IdSarcina;
              this.setState(
                {
                  idSelected: id,
                  idSarcina: sarcina,
                },
                () => {
                  this.$f7.methods.openMainDreaptaById(this.state.idSelected);
                }
              );
            }}
            keys={[
              "💾",
              "Denumire",
              "Cerinta",
              "DataCreare",
              "Client",
              "Sursa",
              "PR",
              "Salariat",
              "StareCurenta",
            ]}
            renderUpdate={false}
            hintInputSources={[
              "Path",
              "OperatorAdaugare",
              "BugetTimp",
              "TimpPlanD",
              "PVuri",
            ]}
            renderDelete={false}
            renderAdd={false}
            renderCheckbox={true}
            onInitialised={(state, r, itemeRandate) => {
              this.$f7.methods.colorItems(
                state,
                r,
                itemeRandate,
                "Salariat",
                1,
                true
              );
            }}
            onRenderItemsChanged={(state, r, itemeRandate) => {
              this.$f7.methods.colorItems(
                state,
                r,
                itemeRandate,
                "Salariat",
                0,
                true
              );
            }}
            maxHeight={this.state.height}
            contextMenuItems={[
              {
                key: "Propune altcuiva",
                onClick: (state) => {
                  var id = state.rowData.IdProiect;
                  var sarcina = state.rowData.IdSarcina;
                  this.setState(
                    {
                      idSelected: id,
                      idSarcina: sarcina,
                    },
                    () => {
                      this.PropuneCall();
                    }
                  );
                },
                icon: "send",
              },
              {
                key: "Trimite mesaj",
                onClick: (state) => {
                  var id = state.rowData.IdProiect;
                  this.$f7.methods.openMesaje(null, id);
                },
                icon: "send",
              },
            ]}
            inputs={this.state.inputs}
            dataSource={this.state.dataSource}
            onContextMenu={(state, target) => {
              var id = state.rowData.IdProiect;
              var sarcina = state.rowData.IdSarcina;
              this.setState(
                {
                  idSelected: id,
                  idSarcina: sarcina,
                },
                () => {
                  this.$f7.methods.openMainDreaptaById(this.state.idSelected);
                }
              );
            }}
          />
        </div>
      </>
    );
  }
}
export default SarciniPropuseGrid;
