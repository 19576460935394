import React from "react";
import { Page } from "framework7-react";
import TipuriEvenimenteClienti from "../Componente/TipuriEvenimenteClienti";

class TipuriEvenimenteClientiPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { denumire: "Tipuri evenimente clienti" };
    }
    render() {
        return (
            <Page
                className={"themeBackground"}
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
            >
                <div className="ptr3">
                    <TipuriEvenimenteClienti />
                </div>
            </Page>
        );
    }
}
export default TipuriEvenimenteClientiPage;