import React from "react";
import { List, ListItem } from "framework7-react";
import axios from "axios";
import DataGrid from "../../../Datagrid/DataGrid";

/**
 * @description Afiseaza logul activitatilor pentru un salariat selectat
 * @date 08/04/2022
 * @class LogActivitateSalariat
 * @extends {React.PureComponent}
 */
class LogActivitateSalariat extends React.PureComponent {
  constructor(props) {
    super(props);
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.height = window.innerHeight;

    this.state = {
      SalariatSelectat: this.$f7.data.idSalariatFunctii,
      dataLoaded: false,
      linkApi: "/api/RealizareSarcini",
      pagina: "Log activitate",
      denumire: Math.random().toString(36).substring(7).toString(),
      height: window.innerHeight - 140,
      width: window.innerWidth - 34,
      dataSource: [],
      inputs: [
        { key: "DeCine", keyBaza: "dE_CINE", tip: "number" },
        { key: "DataTask", keyBaza: "dataCreare", tip: "datebox" },
        { key: "DataDealocare", keyBaza: "datA_DEALOCARII", tip: "datebox" },
        { key: "DeCineDealocat", keyBaza: "dealoC_CINE", tip: "number" },
        {
          key: "Task",
          keyBaza: "iD_PROIECT",
          tip: "selectbox",
          lista: this.$f7.data.Proiecte,
        },
        {
          key: "Salariat",
          keyBaza: "iD_SALARIAT",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        {
          key: "IdSarcina",
          keyBaza: "iD_SARCINA",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        { key: "Stare", keyBaza: "stare", tip: "number" },
        { key: "StareDenumire", keyBaza: "starE_DENMIRE" },
        { key: "DataActiune", keyBaza: "datA_ALOCARII", tip: "datebox" },
        {
          key: "Client",
          keyBaza: "iD_CLIENT",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
        },
        { key: "Deadline", keyBaza: "deadline", tip: "datebox" },
      ],
    };
    this.refDataGrid = React.createRef();
  }
  componentDidMount() {
    this.GetValues();
  }

  /**
   * @description Ia toata activitate pentru salariatul selectat
   * @function
   * @date 08/04/2022
   * @memberof LogActivitateSalariat
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetLogActivitateSalariat/" +
          this.state.SalariatSelectat
      )
      .then((response) => {
        var date = response.data;
        var dateFiltrate = [];
        var dateFiltrate2 = [];
        date = date.filter((r) => {
          return r.starE_DENMIRE !== null;
        });
        date.map((logCurent, index) => {
          logCurent = this.$f7.methods.restructurareBaza(
            logCurent,
            this.state.inputs,
            "key"
          );
          dateFiltrate.push(logCurent);
          dateFiltrate2.push(logCurent);
          return null;
        });

        dateFiltrate.map((logCurent, index) => {
          if (index <= dateFiltrate.length - 2) {
            var lastItem = dateFiltrate[index + 1];
            var idToRemove = null;
            if (lastItem.Task === logCurent.Task) {
              if (
                lastItem.StareDenumire === "acceptat" &&
                logCurent.StareDenumire === "asumat"
              ) {
                idToRemove = lastItem.IdSarcina;
              } else if (
                lastItem.StareDenumire === "anulat" &&
                logCurent.StareDenumire.includes("propus")
              ) {
                idToRemove = lastItem.IdSarcina;
              }
              //else if (lastItem.StareDenumire === "acceptat" && logCurent.StareDenumire.includes("propus")) {
              //    idToRemove = lastItem.IdSarcina;
              //}
              else if (
                lastItem.StareDenumire === "acceptat" &&
                logCurent.StareDenumire === "refuzat"
              ) {
                idToRemove = lastItem.IdSarcina;
              } else if (
                lastItem.StareDenumire === "asumat" &&
                logCurent.StareDenumire === "inchis"
              ) {
                idToRemove = lastItem.IdSarcina;
              }
              if (idToRemove !== null) {
                dateFiltrate2 = dateFiltrate2.filter((logFiltrare) => {
                  return logFiltrare.IdSarcina !== idToRemove;
                });
              }
            }
          }
          return null;
        });

        dateFiltrate2.map((logCurent, index) => {
          if (logCurent.StareDenumire === "anulat") {
            var l = dateFiltrate2.filter((r) => {
              return r.Task === logCurent.Task;
            });
            var primulIndex = 999999;
            l.map((t) => {
              if (dateFiltrate2.indexOf(t) < primulIndex) {
                primulIndex = dateFiltrate2.indexOf(t);
              }
              return null;
            });
            if (index !== primulIndex) {
              logCurent.StareDenumire = "deschis";
            } else {
              logCurent.StareDenumire = "asumat";
            }
          }
          return null;
        });
        this.setState(
          {
            dataSource: dateFiltrate2,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  render() {
    return (
      <>
        {this.$f7.data.rol < 2 ? (
          <List style={{ margin: "0px!important" }}>
            <ListItem
              className="listaSalariatiGrafic"
              validate
              floatingLabel
              title={"Sal."}
              label={"Sal."}
              smartSelect
              smartSelectParams={{
                virtualList: true,
                searchbar: true,
                openIn: "popup",
                popupTabletFullscreen: true,
                popupPush: true,
                closeOnSelect: true,
                on: {
                  closed: (event) => {
                    var val = event.getValue();
                    this.setState({ SalariatSelectat: val }, () => {
                      this.GetValues();
                    });
                  },
                },
              }}>
              <select name="SelectSalariatiGrafic">
                {this.Salariati.map((obj, index) => (
                  <option
                    key={"listaSalariatiLogActivitateSalariat-" + index}
                    selected={
                      obj.value !== null &&
                      parseInt(obj.value) ===
                        parseInt(this.state.SalariatSelectat)
                    }
                    value={obj.value}>
                    {obj.key}
                  </option>
                ))}
              </select>
            </ListItem>
          </List>
        ) : null}
        <DataGrid
          dataLoaded={this.state.dataLoaded}
          refresh={this.GetValues}
          contextMenuItems={[
            {
              key: "Filtrare task curent",
              onClick: (state) => {
                var filtre = { ...state.filtreInitiale };
                filtre["Task"].value = [state.rowData.Task.toString()];
                filtre["Task"].comparatie = "arraycontains";
                this.refDataGrid.current.setState({ filtre: filtre }, () => {
                  this.refDataGrid.current.saveFiltre();
                });
              },
            },
          ]}
          name={"LogActivitateSalariat"}
          id={"IdSarcina"}
          ref={this.refDataGrid}
          renderUpdate={false}
          renderDelete={false}
          renderAdd={false}
          maxHeight={this.state.height}
          inputs={this.state.inputs}
          dataSource={this.state.dataSource}
          keys={[
            "DataTask",
            "Task",
            "Salariat",
            "StareDenumire",
            "DataActiune",
            "Client",
          ]}
          coloredRow={{
            sourceCol: "Deadline",
            type: "date",
          }}
        />
      </>
    );
  }
}
export default LogActivitateSalariat;
