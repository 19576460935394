import React, { Component } from 'react'
import { Row, Col } from "framework7-react";
import SarciniAlocateGrid from "../../Proiecte/Agenda/SarciniAlocateGrid";
import SarciniRezolvateGrid from "../../Proiecte/Agenda/SarciniRezolvateGrid";
import Grid from "../../Proiecte/Agenda/Grid";

/**
 * @description Primeste parametru ce tip de interfata sa randeze(Alocate sau Rezolvate) si returneaza SarciniAlocateGrid sau SarciniRezolvateGrid
 * @date 06/04/2022
 * @class OreLucrateListaSarcini
 * @extends {Component}
 */
class OreLucrateListaSarcini extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDreapta: true,
    };
  }
  /**
   * @description Este apelata din interfetele SarciniRezolvateGrid sau SarciniAlocateGrid cand este apasat un rand din datagrid pentur a afisa informatiile din dreapta(Datele generale ale taskului).
   * @function
   * @date 06/04/2022
   * @memberof OreLucrateListaSarcini
   */
  updateDreapta = () => {
    this.setState(
      {
        showDreapta: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            showDreapta: true,
          });
        }, 100);
      }
    );
  };

  render() {
    return (
      <Row>
        <Col style={{ padding: 8 }}>
          {this.props.tip ? (
            this.props.tip === "alocate" ? (
              <SarciniAlocateGrid
                checkedTaskuri={true}
                checkedArhivate={false}
                checkedAscunse={false}
                outside
                updateDreapta={this.updateDreapta}
              />
            ) : (
              <SarciniRezolvateGrid
                checkedTaskuri={true}
                checkedArhivate={false}
                checkedAscunse={false}
                outside
                updateDreapta={this.updateDreapta}
              />
            )
          ) : null}
        </Col>
        <Col
          style={{
            position: "relative",
            height: "calc(100vh - 34px)",
          }}>
          {this.state.showDreapta ? <Grid /> : null}
        </Col>
      </Row>
    );
  }
}
export default OreLucrateListaSarcini;