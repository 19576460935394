import React from "react";
import { Page } from "framework7-react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";

/**
 * @description Interfata de afisare a rolurilor pe taskul curent selectat
 * @date 08/04/2022
 * @class FazeSiRoluri
 * @extends {React.Component}
 */
class FazeSiRoluri extends React.Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;

    this.state = {
      dataLoaded: false,
      linkApi: "/api/FazeRoluri",
      dataSource: [],
      inputs: [],
      height: window.innerHeight - 60,
    };

    this.refDataGrid = React.createRef();
  }

  /**
   * @description Ia din baza de date toate rolurile atasate la taskul curent selectat
   * @function
   * @date 08/04/2022
   * @memberof FazeSiRoluri
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetFazeRoluri/" +
          this.$f7.data.Proiect.reF_PROIECT
      )
      .then((response) => {
        var baza = response.data;
        var inputs = [
          {
            key: "idProiect",
          },
          {
            key: "refProiect",
          },
          {
            key: "denumire",
          },
        ];
        this.$f7.data.TipFaze.map((tipFaza) => {
          inputs.push({ key: tipFaza.key });
          return null;
        });

        baza.map((elemBaza) => {
          var lungime = elemBaza.arrayValori.length;

          for (let i = 0; i < lungime; i++) {
            elemBaza[this.$f7.data.TipFaze[i].key] = elemBaza.arrayValori[i];
          }
          delete elemBaza.arrayValori;
          return null;
        });

        this.setState({ inputs: inputs, dataSource: baza }, () => {
          setTimeout(() => {
            this.setState(
              {
                dataLoaded: true,
              },
              () => {
                if (baza.length > 0) {
                  this.refDataGrid.current.setRenderItems(baza);
                }
              }
            );
          }, 400);
        });
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.refDataGrid.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    if (this.$f7.data.Proiect !== null) this.GetValues();
  };

  render() {
    return (
      <Page pageContent={false}>
        {this.$f7.data.Proiect !== null ? (
          <DataGrid
            dataLoaded={this.state.dataLoaded}
            refresh={this.GetValues}
            name={"FazeSiRoluri"}
            ref={this.refDataGrid}
            renderUpdate={false}
            renderDelete={false}
            renderAdd={false}
            maxHeight={this.state.height}
            dataSource={this.state.dataSource}
            inputs={this.state.inputs}
          />
        ) : (
          <div
            style={{
              position: "relative",
              height: "calc(100vh - 50px)",
            }}>
            <h3
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                right: 0,
                transform: "translateY(-50%)",
                textTransform: "uppercase",
                textAlign: "center",
                display: "block",
              }}>
              Nu ati selectat nimic
            </h3>
          </div>
        )}
      </Page>
    );
  }
}
export default FazeSiRoluri;
