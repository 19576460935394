import React from "react";
import {
	Button,
	Icon,
	Segmented,
	Searchbar,
	List,
	ListInput,
	ListItem,
	Row,
	Col,
	CardHeader,
	Page,
	Navbar,
	Link,
	Popover,
	NavRight,
	Stepper,
	Checkbox,
	ListGroup,
} from "framework7-react";
import moment from "moment";
import { TableExport } from "tableexport";
import Sortable from "sortablejs";
import config from "../../../config";
import DataGrid from "./DataGrid";
import Wrapper from "./Wrapper";
import SelectboxOptimizat from "./SelectboxOptimizat";
//Sortable.mount(new Swap());
let checkFalse = false;
var myInterval = null;
var showPopoverTimeout = null;
var settings2 = {
	width: "100",
	xsmall: "100",
	small: "100",
	medium: "50",
	large: "50",
	xlarge: "50",
};
var culoare = "var(--f7-theme-color)";
//var culoareTint = "var(--f7-theme-color-tint)";
var initialKeysStatic = [];
var dynamicPopover = null;
var keyurile = [];
var filtre = {};
var filtreInitiale = {};
var heightTable = 0;
var count = Math.floor((window.innerHeight - 138) / 36);
var countMic = Math.floor(240 / 36);
var shiftHold = false;

class DataGrid2 extends React.Component {
	constructor(props) {
		super(props);
		this.desktop = this.$f7.device.desktop;
		var inputuri = props.inputs;
		var parents = [];
		var existaParents = false;
		this.reful = React.createRef();
		this.datagridRef = React.createRef();
		this.inaltimeRand = 20;
		keyurile = [];
		inputuri.map((input) => {
			if (input.parent) {
				existaParents = true;
			}
			keyurile.push(input.key);
			filtre[input.key] = {
				denumire: input.key,
				value: null,
				comparatie: null,
			};
			filtreInitiale[input.key] = {
				denumire: input.key,
				value: null,
				comparatie: null,
			};
			return null;
		});
		var groups = [];
		if (existaParents) {
			inputuri.map((inputCurent) => {
				if (inputCurent.parent) {
					var modificat = false;
					parents.map((parentCurent) => {
						if (parentCurent) {
							if (parentCurent.denumire) {
								if (parentCurent.denumire === inputCurent.parent) {
									parentCurent.childs = parentCurent.childs + 1;
									modificat = true;
								}
							}
						}
						return null;
					});
					if (!modificat) {
						parents.push({ denumire: inputCurent.parent, childs: 1 });
					}
				} else {
					parents.push({ denumire: "" });
				}
				if (inputCurent.group !== undefined) {
					if (!groups.includes(inputCurent.group)) {
						groups.push(inputCurent.group);
					}
				}
				return null;
			});
		}

		groups.push("");
		heightTable =
			props.renderSearch !== false ||
				props.renderAdd !== false ||
				props.renderExcel !== false ||
				props.renderFilter !== false ||
				props.renderFilterColumns !== false
				? props.paging !== false
					? parseInt(props.maxHeight - 68)
					: parseInt(props.maxHeight - this.inaltimeRand)
				: props.paging !== false
					? parseInt(props.maxHeight - 34)
					: parseInt(props.maxHeight);
		var count = props.count
			? props.count
			: parseInt(heightTable / this.inaltimeRand) - 1;
		var iDS =
			props.dataSource !== undefined
				? props.dataSource.length > 0
					? props.refId !== null &&
						props.refId !== undefined &&
						props.initialRefId !== undefined
						? props.bigDataSource !== undefined
							? props.bigDataSource.filter((r) => {
								return r[props.refId] === props.initialRefId;
							})
							: props.dataSource.filter((r) => {
								return r[props.refId] === props.initialRefId;
							})
						: props.dataSource
					: []
				: [];
		var iKeys = props.inputs
			? keyurile
			: props.dataSource
				? props.dataSource.length > 0
					? Object.keys(props.dataSource[0])
					: []
				: [];
		var numarOptiuni = 0;
		if (this.props.renderAccordion) {
			numarOptiuni += 1;
		}
		if (this.props.renderCheckbox) {
			numarOptiuni += 1;
		}
		if (this.desktop && this.props.renderOptions) {
			if (this.props.renderDelete) {
				numarOptiuni += 1;
			}
			if (this.props.renderUpdate) {
				numarOptiuni += 1;
			}
			if (this.props.renderMenu) {
				numarOptiuni += 1;
			}
		} else if (!this.desktop) {
			if (this.props.renderDelete) {
				numarOptiuni += 1;
			}
			if (this.props.renderUpdate) {
				numarOptiuni += 1;
			}
			if (this.props.renderMenu) {
				numarOptiuni += 1;
			}
		}

		//Denumiri
		this.parinteMain =
			this.props.parinteMain !== undefined ? this.props.parinteMain : this;
		this.initialDataSource = [];
		this.iDS = [];
		this.numarOptiuni = numarOptiuni;
		this.searchName =
			"PopupSearch-" + this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx");
		this.tableName =
			"TableName-" + this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx");
		this.tableNameModalAddEdit =
			"PopupAddEditInsidePopup-" +
			this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx");
		this.tableNameModalFiltrare =
			"PopupFiltrareInsidePopup-" +
			this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx");
		this.tableNamePopup =
			"Popup-" + this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx");
		this.tableNamePopupFiltre =
			"PopupFiltre-" + this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx");
		this.tableNamePopupFiltreColumns =
			"PopupFiltreColumns-" + this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx");
		this.tableNamePopupSorting =
			"PopupSorting-" + this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx");
		this.popoverContextMenuName =
			"PopoverContextMenu-" + this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx");
		this.denumireLista =
			"Lista-" + this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx");
		this.listaBool = [
			{ key: 0, value: null, denumire: "Fara" },
			{ key: 1, value: false, denumire: "False" },
			{ key: 2, value: true, denumire: "True" },
		];
		this.listaBool2 = [
			{ key: "Fara", value: null },
			{ key: "False", value: false },
			{ key: "True", value: true },
		];
		this.id = props.id ? props.id : "";
		this.widthScadere =
			this.props.childNumber !== undefined
				? this.props.childNumber * 16 + 16
				: 16;
		this.spatiiIndentare =
			this.props.childNumber !== undefined ? this.props.childNumber * 5 : 0;
		this.heightTable = heightTable;
		this.parents = parents;
		this.groups = groups;
		this.name = this.props.childNumber
			? this.props.name + this.props.childNumber
			: this.props.name + "0";

		this.state = {
			//STATE
			contextMenuOpen: false,
			modalOpen: false,
			listaDatagriduri: this.props.listaDatagriduri
				? this.props.listaDatagriduri
				: [this],
			sortingOptions:
				this.props.sortingOptions !== undefined
					? this.props.sortingOptions
					: [],
			openSorting: false,
			accordionOpenToLevel:
				this.props.accordionOpenToLevel === undefined
					? 0
					: this.props.accordionOpenToLevel,
			maxDepth: this.props.maxDepth !== undefined ? this.props.maxDepth : 0,
			resized: false,
			refId: props.refId,
			numberBeforeAddEdit: 0,
			lista: [],
			toSearch: "",
			listaRenderType: 0,
			denumire: "",
			initialRefId: props.initialRefId,
			accordionItem: props.accordionItem,
			filtre:
				this.props.filtre !== undefined ? this.props.filtre : { ...filtre },
			filtreInitiale:
				this.props.filtreInitiale !== undefined
					? this.props.filtreInitiale
					: { ...filtreInitiale },
			selectedRows:
				this.props.allSelectedRows !== undefined &&
					this.props.allSelectedRows.length > 0 &&
					props.initialRefId !== undefined &&
					props.refId !== undefined
					? this.props.allSelectedRows.filter((r) => {
						return r[props.refId] === props.initialRefId;
					})
					: [],
			allSelectedRows:
				this.props.allSelectedRows !== undefined &&
					this.props.allSelectedRows.length > 0
					? this.props.allSelectedRows
					: [],
			selectedRowsOnly:
				this.props.selectedRowsOnly !== undefined &&
					this.props.selectedRowsOnly.length > 0
					? this.props.selectedRowsOnly
					: [],
			target: null,
			accordionToOpen:
				this.props.accordionToOpen !== undefined
					? this.props.accordionToOpen
					: [],
			renderColor: this.props.renderColor ? this.props.renderColor : true,
			filtrareColumnsOpen: false,
			filtrareOpen: false,
			callSort: true,
			rowData: "",
			rowDataAll:
				this.props.rowDataAll !== undefined ? this.props.rowDataAll : [],
			cellData: "",
			lastCount: count,
			apelareRowClick: true,
			keys: this.props.keys !== undefined ? this.props.keys : iKeys,
			initialKeys: iKeys,
			bigDataSource: props.dataSource
				? props.dataSource.length > 0
					? props.dataSource
					: []
				: [],
			dataSource: iDS,
			renderItems: iDS,
			filteredItems: iDS,
			numberBefore: 0,
			lastNumberBefore: 0,
			count: count,
			realIndex: 0,
			editedItem: {},
			adding: false,
			editing: false,
			inputSelectat: this.props.inputs[0],
		};
	}

	componentDidUpdate = (prevProps, prevState, snapshot) => {
		function objectsAreSame(x, y) {
			var objectsAreSame = true;
			for (var propertyName in x) {
				if (x[propertyName] !== y[propertyName]) {
					objectsAreSame = false;
					break;
				}
			}
			return objectsAreSame;
		}

		if (document.getElementById(this.denumireLista) && this.props.dragRows) {
			var self = this;
			Sortable.create(document.getElementById(this.denumireLista), {
				// swap: true,
				group: "shared",
				multiDrag: true,
				animation: 150,
				onEnd: function (evt) {
					var f;
					if (self.denumireLista === evt.to.id) {
						f = self;
					} else {
						f = self.getThisByDenumire(evt.to.id, self.parinteMain);
					}
					if (self.props.dragRowsOnEnd !== undefined) {
						self.props.dragRowsOnEnd(f);
					}
				},
				onStart: function (evt) {
					var itemeRandate = self.state.renderItems.slice(
						self.state.numberBefore,
						self.state.numberBefore + self.state.count
					);
					var oldIndex = evt.item.id.split(self.tableName)[1];
					var item = itemeRandate[oldIndex];
					self.setState({ rowData: item });
					if (self.props.dragRowsOnStart !== undefined) {
						self.props.dragRowsOnStart(self, oldIndex, item);
					}
				},
			});
		}

		if (prevProps.dataLoaded === false && this.props.dataLoaded === true) {
			var rendItems = this.state.renderItems;
			var bigDS = this.state.bigDataSource;

			var a = JSON.stringify(rendItems);
			var a2 = JSON.stringify(bigDS);
			var b = JSON.parse(a);
			var b2 = JSON.parse(a2);
			this.iDS = b;
			this.initialDataSource = b2;

			if (this.state.sortingOptions.length > 0) {
				rendItems = this.state.renderItems.sort(this.dynamicSortNew());
				bigDS = this.state.bigDataSource.sort(this.dynamicSortNew());
			}

			this.setState(
				{
					renderItems: rendItems,
					bigDataSource: bigDS,
					allSelectedRows: [],
					selectedRows: [],
					selectedRowsOnly: [],
				},
				() => {
					if (
						this.state.rowData &&
						this.id &&
						this.state.sortingOptions.length > 0
					) {
						this.focusItemInContext(this.state.rowData, this.id, this);
					}
					if (this.props.onInitialised) {
						var itemeRandate = this.state.renderItems.slice(
							this.state.numberBefore,
							this.state.numberBefore + this.state.count
						);
						var allRendered = this.parinteMain.returnRenderedItems();
						this.props.onInitialised(
							this.state,
							this,
							itemeRandate,
							allRendered
						);
					}
				}
			);
		}

		if (this.props.onRenderItemsChanged !== undefined) {
			var renderItemsIdentice = objectsAreSame(
				this.state.renderItems,
				prevState.renderItems
			);
			var keysIdentice = objectsAreSame(this.state.keys, prevState.keys);
			var sortingOptionsIdentice = objectsAreSame(
				this.state.sortingOptions,
				prevState.sortingOptions
			);
			if (
				!keysIdentice ||
				!sortingOptionsIdentice ||
				!renderItemsIdentice ||
				prevState.count !== this.state.count ||
				this.state.numberBefore !== prevState.numberBefore ||
				(prevProps.dataLoaded === false && this.props.dataLoaded === true)
			) {
				setTimeout(() => {
					var itemeRandate = this.state.renderItems.slice(
						this.state.numberBefore,
						this.state.numberBefore + this.state.count
					);
					var allRendered = this.parinteMain.returnRenderedItems();
					this.props.onRenderItemsChanged(
						this.state,
						this,
						itemeRandate,
						allRendered
					);
				}, 100);
			}
		}
	};

	changeFilterCondition = (denumire, condition, e) => {
		var filtre = { ...this.state.filtre };
		filtre[denumire].comparatie =
			filtre[denumire].comparatie === condition ? null : condition;
		this.setState({ filtre: filtre });
	};

	resizableGrid = (table) => {
		var self = this;

		function setListeners(div) {
			var pageX, curCol, nxtCol, curColWidth, nxtColWidth;

			div.addEventListener("mousedown", function (e) {
				self.setState({ resized: true });
				curCol = e.target.parentElement;
				nxtCol = curCol.nextElementSibling;
				pageX = e.pageX;

				var padding = paddingDiff(curCol);

				curColWidth = curCol.offsetWidth - padding;
				if (nxtCol) {
					nxtColWidth = nxtCol.offsetWidth - padding;
				}
			});

			div.addEventListener("mouseover", function (e) {
				e.target.style.borderRight = "2px solid #0000ff";
			});

			div.addEventListener("mouseout", function (e) {
				e.target.style.borderRight = "";
			});

			document.addEventListener("mousemove", function (e) {
				if (curCol) {
					var diffX = e.pageX - pageX;

					if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + "px";

					curCol.style.width = curColWidth + diffX + "px";
				}
			});

			document.addEventListener("mouseup", function (e) {
				curCol = undefined;
				nxtCol = undefined;
				pageX = undefined;
				nxtColWidth = undefined;
				curColWidth = undefined;
			});
		}

		function createDiv(height) {
			var div = document.createElement("div");
			div.style.top = 0;
			div.style.right = 0;
			div.style.width = "5px";
			div.style.position = "absolute";
			div.style.cursor = "col-resize";
			div.style.userSelect = "none";
			div.style.height = height + "px";
			return div;
		}

		function paddingDiff(col) {
			if (getStyleVal(col, "box-sizing") === "border-box") {
				return 0;
			}

			var padLeft = getStyleVal(col, "padding-left");
			var padRight = getStyleVal(col, "padding-right");
			return parseInt(padLeft) + parseInt(padRight);
		}

		function getStyleVal(elm, css) {
			return window.getComputedStyle(elm, null).getPropertyValue(css);
		}

		if (table) {
			var row = table.getElementsByTagName("tr")[0],
				cols = row ? row.children : undefined;
			if (!cols) return;

			//table.style.overflow = 'hidden';

			var tableHeight = table.offsetHeight;

			for (var i = 0; i < cols.length; i++) {
				var div = createDiv(tableHeight);
				cols[i].appendChild(div);
				cols[i].style.position = "relative";
				setListeners(div);
			}
		}
	};

	renderInput = (denumire, index) => {
		var toRender;
		if (this.props.inputs) {
			var inputCurent = this.props.inputs.find((x) => {
				return x.key === denumire;
			});

			if (inputCurent !== undefined) {
				var lista = [];
				var bool = false;
				var denBool = undefined;
				var den = "Fara";
				var listaEdit = [];
				var tip = inputCurent.tip;
				if (tip === "selectbox" || tip === "selectboxmultiplu") {
					lista = inputCurent.lista;
					if (inputCurent.listaEdit !== undefined) {
						listaEdit = inputCurent.listaEdit;
					}
				} else if (tip === "bool") {
					bool = true;
				}

				var req =
					(this.state.adding === true && inputCurent.requiredAdd) ||
					(this.state.adding === false && inputCurent.requiredEdit) ||
					(this.props.allRequiredAdd && this.state.adding === true) ||
					(this.props.allRequiredEdit && this.state.adding === false);

				if (req) {
					den = undefined;
				}
				if (lista.length > 0 && tip === "selectbox") {
					var elem = lista.find((r) => {
						return r.value === this.state.editedItem[denumire];
					});
					if (elem !== undefined) {
						den = elem.key;
					}
				}
				if (
					lista.length > 0 &&
					tip === "selectboxmultiplu" &&
					this.state.editedItem[denumire] !== "valoarePentruRequired"
				) {
					lista
						.filter((r) => {
							return r.value !== null;
						})
						.map((f, index) => {
							if (
								this.state.editedItem[denumire].includes(f.value.toString())
							) {
								if (den === undefined) {
									den = "";
								}
								if (index > 0) {
									den += ", " + f.key;
								} else {
									den += " " + f.key;
								}
							}
							return null;
						});
				}
				if (bool) {
					var l = this.listaBool2.find((r) => {
						return r.value === this.state.editedItem[denumire];
					});
					if (l !== undefined) {
						denBool = l.key;
					}
				}

				switch (tip) {
					case "filepicker": {
						toRender = (
							<ListInput
								key={"file-" + this.tableName + denumire + index}
								disabled={
									denumire === this.id ||
										(inputCurent.disabled !== undefined &&
											inputCurent.disabled === true)
										? true
										: false
								}
								floatingLabel
								label={denumire + (req ? "*" : "")}
								validate={req}
								required={req}
								type="file"
								placeholder={denumire}
								onInput={(e) => {
									var fisier = e.target.files[0];
									var editItem = { ...this.state.editedItem };
									editItem[denumire] = fisier;
									this.setState({ editedItem: editItem });
								}}
							>
								<Icon
									slot="content-start"
									className={"datagridIcon"}
									size={20}
									material="security"
								/>
							</ListInput>
						);
						break;
					}
					case "colorpicker": {
						toRender = (
							<ListInput
								key={"colorpicker-" + this.tableName + denumire + index}
								disabled={
									denumire === this.id ||
										(inputCurent.disabled !== undefined &&
											inputCurent.disabled === true)
										? true
										: false
								}
								floatingLabel
								label={denumire + (req ? "*" : "")}
								validate={req}
								required={req}
								type="colorpicker"
								placeholder={denumire}
								value={{
									hex:
										this.state.editedItem[denumire] === "" ||
											this.state.editedItem[denumire] === null
											? "#ffffff"
											: this.state.editedItem[denumire],
								}}
								colorPickerParams={{
									value: {
										hex:
											this.state.editedItem[denumire] === ""
												? "#ffffff"
												: this.state.editedItem[denumire],
									},
									openIn: "popup",
									popupTabletFullscreen: true,
									popupPush: true,
									modules: [
										"initial-current-colors",
										"sb-spectrum",
										"rgb-sliders",
										"palette",
									],
									sliderValue: true,
									sliderValueEditable: true,
									sliderLabel: true,
									hexLabel: true,
									hexValueEditable: true,
									groupedModules: true,
									palette: config.palette,
									on: {
										close: (e) => {
											var valoare = e.value.hex;
											var editItem = {
												...this.state.editedItem,
											};
											editItem[denumire] = valoare;
											this.setState({ editedItem: editItem });
										},
									},
								}}
							>
								<Icon
									slot="content-start"
									className={"datagridIcon"}
									size={20}
									material="money"
								/>
							</ListInput>
						);
						break;
					}
					case "password": {
						toRender = (
							<ListInput
								key={"password-" + this.tableName + denumire + index}
								disabled={
									denumire === this.id ||
										(inputCurent.disabled !== undefined &&
											inputCurent.disabled === true)
										? true
										: false
								}
								inputmode="text"
								floatingLabel
								label={denumire + (req ? "*" : "")}
								validate={req}
								required={req}
								type="password"
								placeholder={denumire}
								value={
									this.state.editedItem[denumire] !== null &&
										this.state.editedItem[denumire] !== undefined
										? this.state.editedItem[denumire]
										: ""
								}
								onInput={(e) => {
									var editItem = { ...this.state.editedItem };
									editItem[denumire] = e.target.value;
									this.setState({ editedItem: editItem });
								}}
							>
								<Icon
									slot="content-start"
									className={"datagridIcon"}
									size={20}
									material="security"
								/>
							</ListInput>
						);
						break;
					}
					case "email": {
						toRender = (
							<ListInput
								key={"email-" + this.tableName + denumire + index}
								disabled={
									denumire === this.id ||
										(inputCurent.disabled !== undefined &&
											inputCurent.disabled === true)
										? true
										: false
								}
								floatingLabel
								inputmode="email"
								label={denumire + (req ? "*" : "")}
								validate={req}
								required={req}
								type="email"
								placeholder={denumire}
								value={
									this.state.editedItem[denumire] !== null &&
										this.state.editedItem[denumire] !== undefined
										? this.state.editedItem[denumire]
										: ""
								}
								onInput={(e) => {
									var editItem = { ...this.state.editedItem };
									editItem[denumire] = e.target.value;
									this.setState({ editedItem: editItem });
								}}
							>
								<Icon
									slot="content-start"
									className={"datagridIcon"}
									size={20}
									material="email"
								/>
							</ListInput>
						);
						break;
					}
					case "phone": {
						toRender = (
							<ListInput
								key={"phone-" + this.tableName + denumire + index}
								disabled={
									denumire === this.id ||
										(inputCurent.disabled !== undefined &&
											inputCurent.disabled === true)
										? true
										: false
								}
								floatingLabel
								label={denumire + (req ? "*" : "")}
								inputmode="tel"
								validate={req}
								required={req}
								type="tel"
								minlength={10}
								maxlength={10}
								placeholder={denumire}
								value={
									this.state.editedItem[denumire] !== null &&
										this.state.editedItem[denumire] !== undefined
										? this.state.editedItem[denumire]
										: ""
								}
								onInput={(e) => {
									var editItem = { ...this.state.editedItem };
									editItem[denumire] = e.target.value;
									this.setState({ editedItem: editItem });
								}}
							>
								<Icon
									slot="content-start"
									className={"datagridIcon"}
									size={20}
									material="phone"
								/>
							</ListInput>
						);
						break;
					}
					case "decimal": {
						toRender = (
							<ListInput
								key={"number-" + this.tableName + denumire + index}
								disabled={
									denumire === this.id ||
										(inputCurent.disabled !== undefined &&
											inputCurent.disabled === true)
										? true
										: false
								}
								floatingLabel
								label={denumire + (req ? "*" : "")}
								validate={req}
								required={req}
								type="number"
								step="0.01"
								placeholder={denumire}
								value={
									this.state.editedItem[denumire] === ""
										? 0
										: this.state.editedItem[denumire]
								}
								onInput={(e) => {
									var editItem = { ...this.state.editedItem };
									editItem[denumire] = parseFloat(e.target.value);
									this.setState({ editedItem: editItem });
								}}
							>
								<Icon
									slot="content-start"
									className={"datagridIcon"}
									size={20}
									material="money"
								/>
							</ListInput>
						);
						break;
					}

					case "number": {
						toRender = (
							<ListInput
								key={"number-" + this.tableName + denumire + index}
								disabled={
									denumire === this.id ||
										(inputCurent.disabled !== undefined &&
											inputCurent.disabled === true)
										? true
										: false
								}
								floatingLabel
								label={denumire + (req ? "*" : "")}
								validate={req}
								required={req}
								type="number"
								placeholder={denumire}
								value={
									this.state.editedItem[denumire] === ""
										? 0
										: this.state.editedItem[denumire]
								}
								onInput={(e) => {
									var editItem = { ...this.state.editedItem };
									editItem[denumire] = parseInt(e.target.value);
									this.setState({ editedItem: editItem });
								}}
							>
								<Icon
									slot="content-start"
									className={"datagridIcon"}
									size={20}
									material="money"
								/>
							</ListInput>
						);
						break;
					}
					case "datebox": {
						var minDate =
							inputCurent.minDate !== undefined
								? moment(inputCurent.minDate).format("YYYY-MM-DDTHH:mm")
								: null;
						var maxDate =
							inputCurent.maxDate !== undefined
								? moment(inputCurent.maxDate).format("YYYY-MM-DDTHH:mm")
								: null;

						toRender = (
							<ListInput
								key={"datebox-" + this.tableName + denumire + index}
								disabled={
									denumire === this.id ||
										(inputCurent.disabled !== undefined &&
											inputCurent.disabled === true)
										? true
										: false
								}
								floatingLabel
								label={denumire + (req ? "*" : "")}
								validate={req}
								required={req}
								type="datetime-local"
								placeholder={denumire}
								min={minDate}
								max={maxDate}
								calendarParams={{
									dateFormat: "YYYY-MM-DDThh:mm",
								}}
								onChange={(e) => {
									var val = moment(e.target.value).format("YYYY-MM-DDTHH:mm");
									var editItem = { ...this.state.editedItem };
									editItem[denumire] = val;
									this.setState({ editedItem: editItem });
								}}
								value={
									this.state.editedItem[denumire] === "" ||
										this.state.editedItem[denumire] === null
										? moment().format("YYYY-MM-DDTHH:mm")
										: moment(this.state.editedItem[denumire]).format(
											"YYYY-MM-DDTHH:mm"
										)
								}
							>
								<span slot="content-start">
									<Icon className={"datagridIcon"} size={20} material="today" />
								</span>
							</ListInput>
						);
						break;
					}

					case "bool": {
						toRender = (
							<ListInput
								key={"bool-" + this.tableName + denumire + index}
								className="roundedOutlinedSmartSelect"
								disabled={
									denumire === this.id ||
										(inputCurent.disabled !== undefined &&
											inputCurent.disabled === true)
										? true
										: false
								}
								floatingLabel
								label={denumire + (req ? "*" : "")}
								value={denBool}
								title={denumire}
								validate={req}
								required={req}
								onFocus={(e) => {
									var t = e.currentTarget;
									var inputSelectat = this.props.inputs.find((r) => {
										return r.key === denumire;
									});
									this.setState(
										{
											lista: this.listaBool2,
											denumire: denumire,
											inputSelectat: inputSelectat,
											listaRenderType: 1,
											target: t,
										},
										() => {
											this.openModal(this.tableNameModalAddEdit, true);
										}
									);
								}}
							>
								<Icon
									slot="content-start"
									className={"datagridIcon"}
									size={20}
									material="toggle_on"
								/>
							</ListInput>
						);
						break;
					}
					case "selectbox": {
						toRender = (
							<ListInput
								type="text"
								placeholder={denumire}
								value={den}
								key={"selectbox-" + this.tableName + denumire}
								disabled={
									denumire === this.id ||
										(inputCurent.disabled !== undefined &&
											inputCurent.disabled === true)
										? true
										: false
								}
								floatingLabel
								label={denumire + (req ? "*" : "")}
								onFocus={(e) => {
									var t = e.currentTarget;
									var inputSelectat = this.props.inputs.find((r) => {
										return r.key === denumire;
									});
									this.setState(
										{
											lista: listaEdit.length > 0 ? listaEdit : lista,
											denumire: denumire,
											inputSelectat: inputSelectat,
											listaRenderType: 1,
											target: t,
										},
										() => {
											this.openModal(this.tableNameModalAddEdit, true);
										}
									);
								}}
								validate={req}
								required={req}
								title={denumire}
							>
								<Icon
									slot="content-start"
									className={"datagridIcon"}
									size={20}
									material="list"
								/>
							</ListInput>
						);
						break;
					}
					case "textarea": {
						toRender = (
							<ListInput
								inputStyle={{
									minHeight: inputCurent.inputHeight
										? inputCurent.inputHeight
										: 150,
								}}
								inputmode="text"
								resizable
								key={"textarea-" + this.tableName + denumire + index}
								disabled={
									denumire === this.id ||
										(inputCurent.disabled !== undefined &&
											inputCurent.disabled === true)
										? true
										: false
								}
								floatingLabel
								label={denumire + (req ? "*" : "")}
								validate={req}
								required={req}
								type="textarea"
								placeholder={denumire}
								value={
									this.state.editedItem[denumire] !== null &&
										this.state.editedItem[denumire] !== undefined
										? this.state.editedItem[denumire]
										: ""
								}
								onInput={(e) => {
									var editItem = { ...this.state.editedItem };
									editItem[denumire] = e.target.value;
									this.setState({ editedItem: editItem });
								}}
							>
								<Icon
									slot="content-start"
									className={"datagridIcon"}
									size={20}
									material="create"
								/>
							</ListInput>
						);
						break;
					}
					case "selectboxmultiplu": {
						toRender = (
							<ListInput
								type="text"
								placeholder={denumire}
								value={den}
								key={"selectboxmultiplu-" + this.tableName + denumire}
								disabled={
									denumire === this.id ||
										(inputCurent.disabled !== undefined &&
											inputCurent.disabled === true)
										? true
										: false
								}
								floatingLabel
								label={denumire + (req ? "*" : "")}
								onFocus={(e) => {
									var t = e.currentTarget;
									var inputSelectat = this.props.inputs.find((r) => {
										return r.key === denumire;
									});
									this.setState(
										{
											lista: listaEdit.length > 0 ? listaEdit : lista,
											denumire: denumire,
											inputSelectat: inputSelectat,
											listaRenderType: 1,
											target: t,
										},
										() => {
											this.openModal(this.tableNameModalAddEdit, true);
										}
									);
								}}
								validate={req}
								required={req}
								title={denumire}
							>
								<Icon
									slot="content-start"
									className={"datagridIcon"}
									size={20}
									material="list"
								/>
							</ListInput>
						);
						break;
					}

					default: {
						toRender = (
							<ListInput
								inputmode="text"
								key={"string-" + this.tableName + denumire + index}
								disabled={
									denumire === this.id ||
										(inputCurent.disabled !== undefined &&
											inputCurent.disabled === true)
										? true
										: false
								}
								floatingLabel
								label={denumire + (req ? "*" : "")}
								validate={req}
								required={req}
								type="text"
								placeholder={denumire}
								value={
									this.state.editedItem[denumire] !== null &&
										this.state.editedItem[denumire] !== undefined
										? this.state.editedItem[denumire]
										: ""
								}
								onInput={(e) => {
									var editItem = { ...this.state.editedItem };
									editItem[denumire] = e.target.value;
									this.setState({ editedItem: editItem });
								}}
							>
								<Icon
									slot="content-start"
									className={"datagridIcon"}
									size={20}
									material="create"
								/>
							</ListInput>
						);
						break;
					}
				}
			}
		}
		return this.props.inputs.find((inputul) => {
			return inputul.key === denumire;
		}) !== undefined && denumire !== this.id ? (
			<Col key={"colAddEdit-" + this.tableName + denumire} {...settings2}>
				{toRender}
			</Col>
		) : null;
	};

	getPreferences = () => {
		var pref = localStorage.getItem("DataGrid-" + this.parinteMain.name);
		if (pref) {
			var preferences = JSON.parse(pref);
			if (preferences.Widths) {
				if (preferences.Widths !== null && preferences.Widths !== undefined) {
					preferences.Widths.map((r) => {
						var elems = document.getElementsByClassName(r.className);
						for (var i = 0; i < elems.length; i++) {
							var elem = elems[i];
							if (
								elem !== undefined &&
								elem !== null &&
								!elem.classList.contains("datagridOptionsTh")
							) {
								elem.style.width = r.width;
							}
						}
						return null;
					});
				}
			}
			var keys = preferences.Coloane;
			//var initialKeys = preferences.ColoaneInitiale;
			//var sortingOptions = preferences.SortingOptions;
			this.setState({ keys: keys }, () => {
				if (this.state.sortingOptions.length > 0) {
					var rendItems = this.state.renderItems.sort(this.dynamicSortNew());
					var bigDS = this.state.bigDataSource.sort(this.dynamicSortNew());
					this.setState(
						{ renderItems: rendItems, bigDataSource: bigDS },
						() => {
							if (this.state.rowData && this.id) {
								this.focusItemInContext(this.state.rowData, this.id, this);
							}
						}
					);
				}
			});
		}
	};

	savePreferences = () => {
		//Salvare widths
		if (this.props.parinteMain === undefined) {
			var table = document.getElementById(this.parinteMain.tableName);
			var datagridPreferences = {};
			if (table !== null && table !== undefined) {
				var row = table.getElementsByTagName("tr")[0],
					cols = row ? row.children : undefined;
				var arr = [];
				for (let i of cols) {
					var width = i.getBoundingClientRect().width;
					if (width === 0) {
						width = i.offsetWidth;
						if (width === 0) {
							width = i.clientWidth;
						}
					}
					var w = width.toString() + "px";
					var id = i.id;
					var className = i.classList[i.classList.length - 1];
					var obj = { id: id, width: w, className: className };
					arr.push(obj);
				}
				datagridPreferences["Widths"] = arr;
			}
			datagridPreferences["Coloane"] = this.parinteMain.state.keys;
			datagridPreferences["ColoaneInitiale"] =
				this.parinteMain.state.initialKeys;
			datagridPreferences["SortingOptions"] =
				this.parinteMain.state.sortingOptions;
			datagridPreferences["Name"] = this.parinteMain.name;
			localStorage.setItem(
				"DataGrid-" + this.parinteMain.name,
				JSON.stringify(datagridPreferences)
			);
		}
	};

	componentWillUnmount = () => {
		clearInterval(myInterval);

		this.$f7.data.datagrids[this.name] = {
			Proiect: this.$f7.data.Proiect ? this.$f7.data.Proiect.iD_PROIECT : null,
			UltimaPagina: this.state.numberBefore,
		};
		if (this.props.parinteMain !== undefined) {
			var lista = this.parinteMain.state.listaDatagriduri;
			if (
				lista.find((r) => {
					return r.tableName === this.tableName;
				})
			) {
				lista = lista.filter((r) => {
					return r.tableName !== this.tableName;
				});
				this.parinteMain.state.listaDatagriduri = lista;
			}
		}
	};

	componentDidMount = () => {
		myInterval = setInterval(() => {
			this.savePreferences();
		}, 60000);
		document.addEventListener("keydown", (e) => {
			var key = e.keyCode;
			if (!shiftHold && key === 16) {
				shiftHold = true;
			}
		});
		document.addEventListener("keyup", (e) => {
			shiftHold = false;
		});

		if (this.props.onInitialised) {
			var itemeRandate = this.state.renderItems.slice(
				this.state.numberBefore,
				this.state.numberBefore + this.state.count
			);
			var allRendered = this.parinteMain.returnRenderedItems();
			this.props.onInitialised(this.state, this, itemeRandate, allRendered);
		}
		var table = document.getElementById(this.tableName);
		if (
			(this.props.resizeColumns !== false || this.props.dragRows !== true) &&
			this.state.refId === undefined
		) {
			this.resizableGrid(table);
		}

		var newNumberBefore = undefined;

		if (this.$f7.data.datagrids[this.name] !== undefined) {
			var ultimaPaginaExistenta =
				Math.ceil(this.state.renderItems.length / this.state.count) >= 1
					? Math.ceil(this.state.renderItems.length / this.state.count)
					: 1;

			var Proiectul = this.$f7.data.datagrids[this.name].Proiect;
			var numberBefore = this.$f7.data.datagrids[this.name].UltimaPagina;
			newNumberBefore = this.$f7.data.datagrids[this.name].UltimaPagina;

			if (this.$f7.data.Proiect !== null) {
				if (Proiectul === this.$f7.data.Proiect.iD_PROIECT) {
					if (numberBefore > this.state.dataSource.length - 1) {
						newNumberBefore =
							ultimaPaginaExistenta * this.state.count - this.state.count;
					}
				}
			}
		}

		this.setState(
			{
				numberBefore:
					newNumberBefore !== undefined
						? newNumberBefore
						: this.state.numberBefore,
			},
			() => {
				//this.saveFiltre();
				var pref = localStorage.getItem("DataGrid-" + this.parinteMain.name);
				if (pref !== undefined) {
					this.getPreferences();
				} else {
					this.savePreferences();
				}
			}
		);
		if (this.props.parinteMain !== undefined) {
			var lista = this.parinteMain.state.listaDatagriduri;
			if (
				!lista.find((r) => {
					return r.tableName === this.tableName;
				})
			) {
				lista.push(this);
				this.parinteMain.state.listaDatagriduri = lista;
			}
		}
	};
	openCloseAccordion = (id, e) => {
		var name = "accordion" + id;
		var acc = this.state.accordionToOpen;
		if (acc.includes(name)) {
			acc = acc.filter((r) => {
				return r !== name;
			});
		} else {
			acc.push(name);
		}
		this.setState(
			{
				accordionToOpen: acc,
			},
			() => {
				setTimeout(() => {
					if (this.props.onRenderItemsChanged !== undefined) {
						var itemeRandate = this.state.renderItems.slice(
							this.state.numberBefore,
							this.state.numberBefore + this.state.count
						);
						var allRendered = this.parinteMain.returnRenderedItems();
						this.props.onRenderItemsChanged(
							this.state,
							this,
							itemeRandate,
							allRendered
						);
					}
				}, 500);
			}
		);
	};

	returnRenderedItems = (refId, arr) => {
		var toReturn = arr ? arr : [];
		var listaDatagriduri = this.state.listaDatagriduri;
		var ref = refId !== undefined ? refId : this.state.initialRefId;
		var context = listaDatagriduri.find((r) => {
			return r.state.initialRefId !== undefined && r.state.initialRefId === ref;
		});
		if (context && context.state.refId) {
			context.state.dataSource.map((elem) => {
				toReturn.push(elem);
				var existaChild = listaDatagriduri.find((con) => {
					return con.state.initialRefId === elem[this.id];
				});
				if (existaChild) {
					toReturn = this.returnRenderedItems(
						existaChild.state.initialRefId,
						toReturn
					);
				}
				return null;
			});
		}
		return toReturn;
	};

	checkCheckboxItemWithChilds = (itemDeAdaugat) => {
		var selectedRows = [...this.state.selectedRows];
		var selectedRowsOnly = [...this.state.selectedRowsOnly];
		var allSelectedRows = [...this.state.allSelectedRows];
		var newSelectedRows = [];
		var newAllSelectedRows = [];

		if (
			allSelectedRows.find((itemulCurent) => {
				return (
					JSON.stringify(itemulCurent) === JSON.stringify(itemDeAdaugat) ||
					(this.id !== "" && itemulCurent[this.id] === itemDeAdaugat[this.id])
				);
			}) === undefined
		) {
			newSelectedRows.push(itemDeAdaugat);
			selectedRows.push(itemDeAdaugat);
			selectedRowsOnly.push(itemDeAdaugat);

			newAllSelectedRows.push(itemDeAdaugat);
			allSelectedRows.push(itemDeAdaugat);
			if (
				this.state.refId !== undefined &&
				this.state.initialRefId !== undefined &&
				this.id !== ""
			) {
				var listaCheckeds = [];
				listaCheckeds = this.recursivaCheckbox(
					this.state.bigDataSource,
					itemDeAdaugat[this.id],
					listaCheckeds
				);
				if (listaCheckeds.length > 0) {
					allSelectedRows = allSelectedRows.concat(listaCheckeds);
					newAllSelectedRows = newAllSelectedRows.concat(listaCheckeds);
				}
			}
		}

		if (this.id !== "" && this.state.refId !== undefined) {
			allSelectedRows.map((r) => {
				var refId = r[this.state.refId];
				r.idParinti = this.getCheckboxParents(refId);
				return null;
			});
			newAllSelectedRows.map((r) => {
				var refId = r[this.state.refId];
				r.idParinti = this.getCheckboxParents(refId);
				return null;
			});
		}

		return {
			selectedRows: selectedRows,
			allSelectedRows: allSelectedRows,
			newSelectedRows: newSelectedRows,
			newAllSelectedRows: newAllSelectedRows,
			selectedRowsOnly: selectedRowsOnly,
		};
	};

	uncheckCheckboxItemWithChilds = (itemDeAdaugat) => {
		var selectedRows = [...this.state.selectedRows];
		var selectedRowsOnly = [...this.state.selectedRowsOnly];
		var allSelectedRows = [...this.state.allSelectedRows];
		var newSelectedRows = [];
		var newAllSelectedRows = [];
		newAllSelectedRows.push(itemDeAdaugat);
		selectedRows = selectedRows.filter((item, index) => {
			return (
				JSON.stringify(item) !== JSON.stringify(itemDeAdaugat) ||
				(this.id !== "" && item[this.id] !== itemDeAdaugat[this.id])
			);
		});

		selectedRowsOnly = selectedRowsOnly.filter((item, index) => {
			return (
				JSON.stringify(item) !== JSON.stringify(itemDeAdaugat) ||
				(this.id !== "" && item[this.id] !== itemDeAdaugat[this.id])
			);
		});

		allSelectedRows = allSelectedRows.filter((item, index) => {
			return (
				JSON.stringify(item) !== JSON.stringify(itemDeAdaugat) ||
				(this.id !== "" && item[this.id] !== itemDeAdaugat[this.id])
			);
		});

		if (
			this.state.refId !== undefined &&
			this.state.initialRefId !== undefined &&
			this.id !== ""
		) {
			var listaUnCheckeds = [];
			listaUnCheckeds = this.recursivaCheckbox(
				this.state.bigDataSource,
				itemDeAdaugat[this.id],
				listaUnCheckeds
			);

			var idParinti = this.getCheckboxParents(itemDeAdaugat[this.state.refId]);

			if (idParinti !== undefined && idParinti.length > 0) {
				listaUnCheckeds = listaUnCheckeds.concat(
					this.state.bigDataSource.filter((r) => idParinti.includes(r[this.id]))
				);
			}
			if (listaUnCheckeds.length > 0) {
				listaUnCheckeds.map((itemUnchecked, index) => {
					allSelectedRows = allSelectedRows.filter((r) => {
						return JSON.stringify(r) !== JSON.stringify(itemUnchecked);
					});
					return null;
				});
			}
			newAllSelectedRows = newAllSelectedRows.concat(listaUnCheckeds);
		}
		newSelectedRows.push(itemDeAdaugat);

		if (this.id !== "" && this.state.refId !== undefined) {
			allSelectedRows.map((r) => {
				var refId = r[this.state.refId];
				r.idParinti = this.getCheckboxParents(refId);
				return null;
			});
		}

		return {
			selectedRows: selectedRows,
			allSelectedRows: allSelectedRows,
			newSelectedRows: newSelectedRows,
			newAllSelectedRows: newAllSelectedRows,
			selectedRowsOnly: selectedRowsOnly,
		};
	};

	//O apelez dupa ce setez allSelectedRows in checkbox
	checkIntermediare = (ultimulItem, exista) => {
		var selectedRows = [...this.state.selectedRows];
		var allSelectedRows = [...this.state.allSelectedRows];
		var selectedRowsOnly = [...this.state.selectedRowsOnly];

		var items = [...this.state.renderItems];
		if (selectedRows.length > 1) {
			this.$f7.preloader.show();
			if (exista) {
				//sterg ultimul item ca sa adaug in selectedrows la sfarsit ca sa ma folosesc de el la deselectie
				selectedRows = selectedRows.filter((r) => {
					return JSON.stringify(r) !== JSON.stringify(ultimulItem);
				});
				selectedRowsOnly = selectedRowsOnly.filter((r) => {
					return JSON.stringify(r) !== JSON.stringify(ultimulItem);
				});
				allSelectedRows = allSelectedRows.filter((r) => {
					return JSON.stringify(r) !== JSON.stringify(ultimulItem);
				});
			}
			var penultimulItem = selectedRows[selectedRows.length - 1];
			if (!exista) {
				var res = this.uncheckCheckboxItemWithChilds(penultimulItem);
				var newSelectedRows = res.newSelectedRows;
				var newAllSelectedRows = res.newAllSelectedRows;

				selectedRows = selectedRows.filter((r) => {
					return JSON.stringify(r) !== JSON.stringify(newSelectedRows[0]);
				});

				newAllSelectedRows.map((itemUnchecked, index) => {
					allSelectedRows = allSelectedRows.filter((r) => {
						return JSON.stringify(r) !== JSON.stringify(itemUnchecked);
					});
					return null;
				});
			}
			var indexUltimulItem = items.findIndex((r) => {
				return JSON.stringify(r) === JSON.stringify(ultimulItem);
			});
			var indexPenultimulItem = items.findIndex((r) => {
				return JSON.stringify(r) === JSON.stringify(penultimulItem);
			});

			//iteme filtrate de la ultimul checkbox pana la penultimul checkbox adaugat in selectedRows
			var itemsCheckedByIntermediate = items.filter((f, index) => {
				return (
					(index < indexUltimulItem && index > indexPenultimulItem) ||
					(index > indexUltimulItem && index < indexPenultimulItem)
				);
			});
			itemsCheckedByIntermediate.map((r) => {
				if (exista) {
					var res0 = this.checkCheckboxItemWithChilds(r);
					selectedRows = selectedRows.concat(res0.newSelectedRows);
					allSelectedRows = allSelectedRows.concat(res0.newAllSelectedRows);
					selectedRowsOnly = selectedRowsOnly.concat(res0.newSelectedRows);
				} else {
					var res1 = this.uncheckCheckboxItemWithChilds(r);
					var newSelectedRows = res1.newSelectedRows;
					var newAllSelectedRows = res1.newAllSelectedRows;

					selectedRows = selectedRows.filter((r) => {
						return JSON.stringify(r) !== JSON.stringify(newSelectedRows[0]);
					});
					selectedRowsOnly = selectedRowsOnly.filter((r) => {
						return JSON.stringify(r) !== JSON.stringify(newSelectedRows[0]);
					});

					newAllSelectedRows.map((itemUnchecked, index) => {
						allSelectedRows = allSelectedRows.filter((r) => {
							return JSON.stringify(r) !== JSON.stringify(itemUnchecked);
						});
						return null;
					});
				}
				return null;
			});

			if (exista) {
				allSelectedRows.push(ultimulItem);
				selectedRows.push(ultimulItem);
				selectedRowsOnly.push(ultimulItem);
			}

			this.$f7.preloader.hide();
			this.setState(
				{
					selectedRows: selectedRows,
					allSelectedRows: allSelectedRows,
					selectedRowsOnly: selectedRowsOnly,
				},
				() => {
					this.parinteMain.setState({
						allSelectedRows: allSelectedRows,
						selectedRowsOnly: selectedRowsOnly,
					});
				}
			);
		}
	};

	openAdd = (hideClose, idRef) => {
		var realIndex =
			this.state.dataSource.length > 0 ? this.state.dataSource.length : 0;
		var editItem = {};
		this.state.initialKeys.map((denumire) => {
			if (denumire === this.id) {
				editItem[denumire] =
					this.state.dataSource.length > 0
						? parseInt(
							this.state.dataSource[this.state.dataSource.length - 1][this.id]
						) + 1
						: 1;
			} else {
				var inputCurent = this.props.inputs.find((inputul) => {
					return inputul.key === denumire;
				});

				if (inputCurent !== undefined) {
					if (inputCurent.tip) {
						switch (inputCurent.tip) {
							case "selectbox": {
								editItem[denumire] = inputCurent.lista.value;
								if (this.props.allRequiredAdd || inputCurent.requiredAdd) {
									editItem[denumire] = "valoarePentruRequired";
								}
								break;
							}
							case "selectboxmultiplu": {
								editItem[denumire] = [];
								if (this.props.allRequiredAdd || inputCurent.requiredAdd) {
									editItem[denumire] = "valoarePentruRequired";
								}
								break;
							}
							case "bool": {
								editItem[denumire] = false;
								if (this.props.allRequiredAdd || inputCurent.requiredAdd) {
									editItem[denumire] = "valoarePentruRequired";
								}
								break;
							}
							case "datebox": {
								editItem[denumire] = moment(Date.now()).format();
								break;
							}
							case "colorpicker": {
								editItem[denumire] = "#ffffff";
								break;
							}
							case "number": {
								editItem[denumire] = 0;
								break;
							}
							default: {
								editItem[denumire] = "";
								break;
							}
						}
					} else {
						editItem[denumire] = "";
					}
				} else {
					editItem[denumire] = "";
				}
				if (
					this.state.refId !== undefined &&
					idRef !== undefined &&
					denumire === this.state.refId
				) {
					editItem[denumire] = idRef;
				}
			}
			return false;
		});
		this.setState(
			{
				hideClose: hideClose,
				editing: true,
				realIndex: realIndex,
				editedItem: editItem,
				adding: true,
			},
			() => {
				this.openModal(this.tableNamePopup);
			}
		);
	};
	openUpdate = (rowItemData, hideClose, e) => {
		var popToClose = this.$f7.popover.get("." + this.popoverContextMenuName);
		if (popToClose) {
			popToClose.close();
		}
		var id = this.state.renderItems.indexOf(rowItemData);
		this.setState(
			{
				hideClose: hideClose,
				editing: true,
				realIndex: id,
				editedItem: this.state.renderItems[id],
				adding: false,
			},
			() => {
				this.openModal(this.tableNamePopup);
			}
		);
	};

	searchAddEdit = (value) => {
		var toSearch = value.toString().toLowerCase().replace(/\s/g, "");
		var result = this.state.lista.filter((elemBaza) => {
			var res = false;
			if (elemBaza["key"]) {
				var valoare = elemBaza["key"]
					.toString()
					.toLowerCase()
					.replace(/\s/g, "");
				if (valoare.includes(toSearch)) {
					res = true;
				}
			}
			return res;
		});
		this.setState({ listaRender: result, numberBeforeAddEdit: 0 });
	};

	openFiltrareColoane = (e) => {
		var popoverToClose = this.$f7.popover.get(
			"." + this.popoverContextMenuName
		);
		if (popoverToClose) {
			popoverToClose.close();
		}
		this.setState({ filtrareColumnsOpen: true }, () => {
			this.openModal(this.tableNamePopupFiltreColumns);
		});
	};

	openFiltrare = (direction, e) => {
		var popoverToClose = this.$f7.popover.get(
			"." + this.popoverContextMenuName
		);
		if (popoverToClose) {
			popoverToClose.close();
		}

		this.setState(
			{
				filtrareOpen: true,
				filterPanelDirection: direction === undefined ? "right" : direction,
			},
			() => {
				this.openModal(this.tableNamePopupFiltre);
			}
		);
	};

	addObjToArrayForExport = (bd, arrDenumiri, indent) => {
		var obj = [];
		arrDenumiri.map((denumire, ind) => {
			var valoareBaza = bd[denumire];
			var input = this.props.inputs.find((r) => {
				return r.key === denumire;
			});
			if (input !== undefined) {
				var str =
					input.tip === undefined
						? valoareBaza
						: input.tip.includes("selectbox") && input.lista !== undefined
							? input.lista.find((r) => {
								return r.value === valoareBaza;
							}) !== undefined
								? input.lista.find((r) => {
									return r.value === valoareBaza;
								}).key
								: valoareBaza
							: valoareBaza;
				if (indent !== undefined && ind === 0) {
					for (var i = 0; i <= indent; i++) {
						str = " " + str;
					}
				}
				if (valoareBaza !== undefined) {
					var objNou = {
						v: str,
						t: typeof valoareBaza === "boolean" ? "b" : "s",
					};
					obj.push(objNou);
				}
			}
			return null;
		});
		return obj;
	};

	resetFiltre = () => {
		var items = [...this.state.dataSource];
		this.setState(
			{ renderItems: items, filtre: this.state.filtreInitiale },
			() => {
				this.closeModal(this.tableNamePopupFiltre);
				if (this.props.onFilterChange) {
					this.props.onFilterChange(this.state);
				}
			}
		);
	};

	getCheckboxParents = (refId, lis, full) => {
		console.log("recursiva");
		var parinte = this.state.bigDataSource.find((r) => {
			return r[this.id] === refId;
		});

		var lista = lis !== undefined ? lis : [];
		if (parinte !== undefined && parinte !== null) {
			lista.push(full ? parinte : parinte[this.id]);
			lista = this.getCheckboxParents(parinte[this.state.refId], lista);
		}
		return lista;
	};

	saveFiltre = (s) => {
		var source = [...this.state.bigDataSource];
		var newSource = this.returnFilteredDatasource(source);
		this.setState(
			{
				renderItems: newSource,
				filteredItems: newSource,
			},
			() => {
				if (this.props.onFilterChange) {
					this.props.onFilterChange(this.state);
				}
			}
		);
		this.closeModal(this.tableNamePopupFiltre);
	};

	returnFilteredDatasource = (source, returnAllSource) => {
		var filtre = { ...this.state.filtre };
		var keyFiltre = Object.keys(filtre);
		var newSource = source.filter((item, index) => {
			var exista = true;
			keyFiltre.map((filtrul, i) => {
				var f = filtre[filtrul];
				var denumire = f.denumire;
				var val = f.value;
				var comparatie = f.comparatie;
				var itemulCurent = item[denumire];
				switch (comparatie) {
					case "<Date": {
						if (itemulCurent !== null && val !== null) {
							if (
								new Date(itemulCurent.toString()).getTime() >=
								new Date(val.toString()).getTime()
							) {
								exista = false;
							}
						} else {
							if (
								(itemulCurent === null && val !== null) ||
								(itemulCurent !== null && val === null)
							) {
								exista = false;
							}
						}

						break;
					}
					case ">Date": {
						if (itemulCurent !== null && val !== null) {
							if (
								new Date(itemulCurent.toString()).getTime() <=
								new Date(val.toString()).getTime()
							) {
								exista = false;
							}
						} else {
							if (
								(itemulCurent === null && val !== null) ||
								(itemulCurent !== null && val === null)
							) {
								exista = false;
							}
						}

						break;
					}
					case "=Date": {
						if (itemulCurent !== null && val !== null) {
							if (
								new Date(itemulCurent.toString()).getTime() !==
								new Date(val.toString()).getTime()
							) {
								exista = false;
							}
						} else {
							if (
								(itemulCurent === null && val !== null) ||
								(itemulCurent !== null && val === null)
							) {
								exista = false;
							}
						}
						break;
					}
					case "<": {
						if (itemulCurent !== null && val !== null) {
							if (parseInt(itemulCurent) >= parseInt(val)) {
								exista = false;
							}
						} else {
							if (
								(itemulCurent === null && val !== null) ||
								(itemulCurent !== null && val === null)
							) {
								exista = false;
							}
						}

						break;
					}
					case ">": {
						if (itemulCurent !== null && val !== null) {
							if (parseInt(itemulCurent) <= parseInt(val)) {
								exista = false;
							}
						} else {
							if (
								(itemulCurent === null && val !== null) ||
								(itemulCurent !== null && val === null)
							) {
								exista = false;
							}
						}

						break;
					}
					case "=": {
						if (itemulCurent !== null && val !== null) {
							if (parseInt(itemulCurent) !== parseInt(val)) {
								exista = false;
							}
						} else {
							if (
								(itemulCurent === null && val !== null) ||
								(itemulCurent !== null && val === null)
							) {
								exista = false;
							}
						}
						break;
					}
					case "bool": {
						if (itemulCurent !== null && val !== null) {
							if (typeof val === "object" && val.length > 0) {
								if (
									!val.includes(
										itemulCurent.toString().charAt(0).toUpperCase() +
										itemulCurent.toString().slice(1)
									)
								) {
									exista = false;
								}
							} else if (
								itemulCurent.toString().toLowerCase() !==
								val.toString().toLowerCase() &&
								val !== "Fara"
							) {
								exista = false;
							}
						} else {
							if (itemulCurent === null && val !== null) {
								if (typeof val === "object" && val.length) {
									if (!val.includes("Fara")) {
										exista = false;
									}
								}
							}
						}
						break;
					}
					case "contains": {
						if (itemulCurent !== null && val !== null) {
							if (!itemulCurent.toLowerCase().includes(val.toLowerCase())) {
								exista = false;
							}
						} else {
							if (
								(itemulCurent === null && val !== null) ||
								(itemulCurent !== null && val === null)
							) {
								exista = false;
							}
						}

						break;
					}
					case "arraycontains": {
						if (val.length !== 0) {
							if (itemulCurent !== null && itemulCurent !== undefined) {
								if (!val.includes(itemulCurent.toString())) {
									exista = false;
								}
							} else {
								if (!val.includes(itemulCurent)) {
									exista = false;
								}
							}
						} else {
							exista = false;
						}
						break;
					}
					default: {
						break;
					}
				}
				return null;
			});
			return exista;
		});
		var sourceFinal = newSource;
		if (
			!returnAllSource &&
			this.id !== "" &&
			this.state.refId !== undefined &&
			this.props.renderAccordion
		) {
			newSource.map((r) => {
				var idParinti = this.getCheckboxParents(
					r[this.state.refId],
					undefined,
					true
				);
				let listaToAdd = idParinti.filter((parinte) => {
					return (
						!sourceFinal.find((ef) => {
							return ef[this.id] === parinte[this.id];
						}) && parinte[this.state.refId] === this.state.initialRefId
					);
				});
				sourceFinal = sourceFinal.concat(listaToAdd);

				return null;
			});
			sourceFinal = sourceFinal.filter((r) => {
				return r[this.state.refId] === this.state.initialRefId;
			});
		}

		return returnAllSource ? newSource : sourceFinal;
	};

	recursivaExcel = (baza, arrDenumiri, refId, arr, indent) => {
		var insideRows = baza.filter((r) => {
			return r[this.state.refId] === refId;
		});
		if (insideRows.length > 0) {
			insideRows.map((p) => {
				var obj = this.addObjToArrayForExport(p, arrDenumiri, indent);
				if (p[this.id] !== refId) {
					arr.push(obj);
				}
				if (p[this.id] !== refId) {
					arr = this.recursivaExcel(
						baza,
						arrDenumiri,
						p[this.id],
						arr,
						indent + 1
					);
				}
				return null;
			});
		}
		return arr;
	};

	recursivaCheckbox = (baza, refId, arr) => {
		var insideRows = baza.filter((r) => {
			return r[this.state.refId] === refId;
		});
		if (insideRows.length > 0) {
			insideRows.map((p) => {
				if (p[this.id] !== refId) {
					arr.push(p);
				}
				if (p[this.id] !== refId) {
					arr = this.recursivaCheckbox(baza, p[this.id], arr);
				}
				return null;
			});
		}
		return arr;
	};

	getDatagridByItem = (item) => {
		var contextFinal = this;
		var contextIntermediar = this.parinteMain.state.listaDatagriduri.find(
			(x) => {
				return (
					x.state.dataSource.find((r) => {
						return Object.values(r)[0] === Object.values(item)[0];
					}) !== undefined
				);
			}
		);
		if (contextIntermediar) {
			contextFinal = contextIntermediar;
		}
		return contextFinal;
	};

	focusItemInContext = (item, colName, context) => {
		var indexElem = context.state.renderItems.findIndex((r) => {
			var valToReturn =
				colName !== undefined
					? r[colName] === item[colName]
					: Object.values(r)[0] === Object.values(item)[0];
			return valToReturn;
		});

		if (indexElem !== -1) {
			var numberBef = 0;
			while (numberBef < indexElem) {
				numberBef += context.state.count;
			}
			if (numberBef > 0) {
				numberBef -= context.state.count;
			}
			context.setState({ numberBefore: numberBef }, () => {
				var listaRendered = context.state.renderItems.slice(
					numberBef,
					numberBef + context.state.count
				);
				var indexInRendered = listaRendered.findIndex((r) => {
					var valToReturn =
						colName !== undefined
							? r[colName] === item[colName]
							: Object.values(r)[0] === Object.values(item)[0];

					return valToReturn;
				});
				if (indexInRendered !== -1) {
					var currentTable = document.getElementById(context.tableName);
					if (currentTable) {
						var trs = currentTable.getElementsByClassName("datagridTr");
						if (trs) {
							var trToFocus = trs[indexInRendered];
							if (trToFocus) {
								context.focusTR(trToFocus);
								if (context.state.columnIndex !== undefined) {
									var tds = trToFocus.querySelectorAll("td");
									var tdToFocus = tds[context.state.columnIndex + 1];
									context.focusTD(tdToFocus);
								}
							}
						}
					}
				}
			});
		}
	};

	focusItem = (it, colName) => {
		if (this.state.maxDepth === 0) {
			this.focusItemInContext(it, colName, this);
		} else {
			if (this.id !== "") {
				var item = this.state.bigDataSource.find((r) => {
					return r[colName] === it[colName];
				});
				if (item) {
					var idParinti = this.getCheckboxParents(item[this.props.refId]);

					var newOpens = this.state.accordionToOpen;
					idParinti.map((parentId) => {
						newOpens.push("accordion" + parentId);
						return null;
					});
					this.parinteMain.setState({ accordionToOpen: [] }, () => {
						this.parinteMain.setState({ accordionToOpen: newOpens }, () => {
							var context = this.getDatagridByItem(item);
							if (context) {
								this.focusItemInContext(item, colName, context);
							}
						});
					});
				}
			}
		}
	};

	focusTD = (elemTarget) => {
		if (elemTarget) {
			var tds = document
				.getElementById(this.parinteMain.tableName)
				.getElementsByClassName("tdActive");
			for (var i = 0; i < tds.length; i++) {
				if (!tds[i].classList.contains("datagridOptionsTd")) {
					if (!tds[i].classList.contains("datagridTd")) {
						tds[i].classList.add("datagridTd");
					}
					if (tds[i].classList.contains("tdActive")) {
						tds[i].classList.remove("tdActive");
					}
				}
			}
			if (!elemTarget.classList.contains("tdActive")) {
				elemTarget.classList.add("tdActive");
			}
		}
	};

	focusTR = (elemTarget) => {
		if (elemTarget) {
			var trs = document
				.getElementById(this.parinteMain.tableName)
				.getElementsByClassName("trActive");
			for (var i = 0; i < trs.length; i++) {
				if (trs[i].classList.contains("trActive")) {
					trs[i].classList.remove("trActive");
				}
			}
			elemTarget.classList.add("trActive");
		}
	};
	exportExcel = (bazaDate) => {
		var table = TableExport(document.getElementById(this.tableName), {
			exportButtons: false,
		});
		var exportData = table.getExportData();
		var xlsxData = exportData[this.tableName].xlsx;
		var datele = xlsxData.data;
		var arrDenumiri = [];
		datele[0].map((dataCurenta) => {
			arrDenumiri.push(dataCurenta.v);
			return null;
		});
		var arr = [];
		var dateNoi = datele[0];
		if (this.numarOptiuni > 0) {
			delete dateNoi[0];
		}
		var dateNoi2 = [];
		dateNoi.map((dataNoua) => {
			dateNoi2.push(dataNoua);
			return null;
		});
		arr.push(dateNoi2);

		if (
			this.state.refId !== undefined &&
			this.state.initialRefId !== undefined &&
			this.id !== ""
		) {
			arr = this.recursivaExcel(
				bazaDate,
				arrDenumiri,
				this.state.initialRefId,
				arr,
				0
			);
		} else {
			bazaDate.map((bd) => {
				var obj = this.addObjToArrayForExport(bd, arrDenumiri);
				arr.push(obj);
				return null;
			});
		}
		table.export2file(
			arr,
			xlsxData.mimeType,
			xlsxData.filename,
			xlsxData.fileExtension,
			xlsxData.merges,
			xlsxData.RTL,
			xlsxData.sheetname
		);
	};

	openExportExcel = (e) => {
		var popToClose = this.$f7.popover.get("." + this.popoverContextMenuName);
		if (popToClose) {
			popToClose.close();
		}
		var bazaDate = [...this.state.bigDataSource];
		var bazaDateFiltrate = this.returnFilteredDatasource(bazaDate);
		var suntFiltrate =
			JSON.stringify(this.state.filtreInitiale) !==
			JSON.stringify(this.state.filtre);

		if (suntFiltrate) {
			var dialogul = this.$f7.dialog.create({
				animate: true,
				title: "Tipul exportului",
				text: "Ce tip de date doriti sa exportati?",
				verticalButtons: true,
				buttons: [
					{
						text: "Toate",
						color: "white",
						cssClass: "redButton",
						onClick: (dialog, e) => {
							this.exportExcel(bazaDate);
						},
					},
					{
						text: "Filtrate",
						color: "white",
						cssClass: "greenButton",
						onClick: (dialog, e) => {
							this.exportExcel(bazaDateFiltrate);
						},
					},
				],
			});
			dialogul.open();
		} else {
			this.exportExcel(bazaDate);
		}
	};

	dynamicSortNew = () => {
		return (q, w) => {
			var a = 0;

			for (var i = 0; i < this.state.sortingOptions.length; i++) {
				var r = this.state.sortingOptions[i];
				if (q[r.key] < w[r.key]) {
					if (r.value === 1) {
						//ascendent
						a = -1;
						break;
					} else {
						//descendent
						a = 1;
						break;
					}
				} else if (q[r.key] > w[r.key]) {
					if (r.value === 1) {
						//ascendent
						a = 1;
						break;
					} else {
						//descendent
						a = -1;
						break;
					}
				}
			}
			return a;
		};
	};

	add = () => {
		var newSource = [...this.state.dataSource];
		newSource[this.state.realIndex] = this.state.editedItem;
		this.setState(
			{
				dataSource: newSource,
				renderItems: newSource,
				editing: false,
				adding: false,
			},
			() => {
				this.closeModal(this.tableNamePopup)
					.then((response) => {
						if (this.props.onAdd) {
							this.props.onAdd(this.state);
						}
					})
					.catch((err) => {
						console.log("err", err);
					});
			}
		);
	};
	openDelete = (rowItemData, e) => {
		var popToClose = this.$f7.popover.get("." + this.popoverContextMenuName);
		if (popToClose) {
			popToClose.close();
		}
		var id = this.state.dataSource.indexOf(rowItemData);
		var newBaza = this.state.dataSource.filter((elem, index) => {
			return index !== id;
		});
		var editedItem = this.state.dataSource.find((elem, index) => {
			return index === id;
		});

		var selectedRows = [...this.state.selectedRows];
		var selectedRowsOnly = [...this.state.selectedRowsOnly];
		let selectedItemIndex = selectedRows.findIndex(
			(r) => r[this.id] === editedItem[this.id]
		);
		if (selectedItemIndex > -1) {
			selectedRows.splice(selectedItemIndex, 1);
		}
		let selectedItemIndex2 = selectedRowsOnly.findIndex(
			(r) => r[this.id] === editedItem[this.id]
		);
		if (selectedItemIndex2 > -1) {
			selectedRowsOnly.splice(selectedItemIndex2, 1);
		}
		this.setState(
			{
				deleting: true,
				realIndex: id,
				editedItem: editedItem,
				apelareRowClick: false,
			},
			() => {
				var dialogul = this.$f7.dialog.create({
					animate: true,
					title: "Stergere",
					text: "Sunteti sigur?",
					verticalButtons: true,
					buttons: [
						{
							text: "Da",
							color: "white",
							cssClass: "greenButton",
							onClick: (dialog, e) => {
								this.setState(
									{
										selectedRows: selectedRows,
										selectedRowsOnly: selectedRowsOnly,
										deleting: false,
										dataSource: newBaza,
										renderItems: newBaza,
										apelareRowClick: true,
									},
									() => {
										if (this.props.onDelete) {
											this.props.onDelete(this.state);
										}
									}
								);
							},
						},
						{
							text: "Nu",
							color: "white",
							cssClass: "redButton",
							onClick: (dialog, e) => {
								this.setState({ apelareRowClick: true }, () => {
									dialog.close();
								});
							},
						},
					],
				});
				dialogul.open();
			}
		);
	};
	update = (id) => {
		var newSource = [...this.state.dataSource];
		newSource[this.state.realIndex] = this.state.editedItem;
		var selectedRows = [...this.state.selectedRows];
		let selectedItemIndex = selectedRows.findIndex(
			(r) => r[this.id] === this.state.editedItem[this.id]
		);
		if (selectedItemIndex > -1) {
			selectedRows[selectedItemIndex] = this.state.editedItem;
		}

		var selectedRowsOnly = [...this.state.selectedRowsOnly];
		let selectedItemIndex2 = selectedRowsOnly.findIndex(
			(r) => r[this.id] === this.state.editedItem[this.id]
		);
		if (selectedItemIndex2 > -1) {
			selectedRowsOnly[selectedItemIndex2] = this.state.editedItem;
		}

		this.setState(
			{
				selectedRows: selectedRows,
				selectedRowsOnly: selectedRowsOnly,
				dataSource: newSource,
				renderItems: newSource,
				editing: false,
				adding: false,
			},
			() => {
				this.closeModal(this.tableNamePopup)
					.then((response) => {
						if (this.props.onUpdate) {
							this.props.onUpdate(this.state);
						}
					})
					.catch((err) => {
						console.log("err", err);
					});
			}
		);
	};
	onChangeCheckboxItem = (rowItemData, valueData, e) => {
		var realIndex = this.state.renderItems.indexOf(rowItemData);
		var editItem = rowItemData;
		editItem[valueData] = !editItem[valueData];
		this.setState(
			{
				editedItem: editItem,
				realIndex: realIndex,
			},
			() => {
				this.update();
			}
		);
	};
	onChangeCheckbox = (rowItemData, e) => {
		var checked = e.target.checked;
		let selectedRows = [...this.state.selectedRows];
		let allSelectedRows = [...this.state.allSelectedRows];
		var selectedRowsOnly = [...this.state.selectedRowsOnly];

		var itemDeAdaugat = rowItemData;

		if (checked) {
			var res0 = this.checkCheckboxItemWithChilds(itemDeAdaugat);
			selectedRows = res0.selectedRows;
			allSelectedRows = res0.allSelectedRows;
			selectedRowsOnly = res0.selectedRowsOnly;
		} else {
			var res1 = this.uncheckCheckboxItemWithChilds(itemDeAdaugat);
			selectedRows = res1.selectedRows;
			allSelectedRows = res1.allSelectedRows;
			selectedRowsOnly = res1.selectedRowsOnly;
		}

		this.setState(
			{
				selectedRows: selectedRows,
				allSelectedRows: allSelectedRows,
				selectedRowsOnly: selectedRowsOnly,
			},
			() => {
				this.parinteMain.setState(
					{
						allSelectedRows: allSelectedRows,
						selectedRowsOnly: selectedRowsOnly,
					},
					() => {
						if (shiftHold) {
							this.checkIntermediare(itemDeAdaugat, checked);
						}
					}
				);

				if (this.props.onCheckboxChange) {
					this.props.onCheckboxChange(
						this.state,
						selectedRows,
						allSelectedRows,
						selectedRowsOnly
					);
				}
			}
		);
	};

	onStepperPlusClick = (e) => {
		let lastNumber = this.state.numberBefore + this.state.count;
		if (lastNumber <= this.state.renderItems.length - 1) {
			this.setState({
				numberBefore: this.state.numberBefore,
			});
		}
	};

	onStepperInput = (e) => {
		var val = e.target.value;
		const maxNumber = Math.ceil(
			this.state.renderItems.length / this.state.count
		);
		if (val !== "") {
			var valInt = parseInt(val);
			const NumMax = this.state.count * maxNumber - this.state.count;
			const NumLocal = this.state.count * valInt - this.state.count;
			if (valInt > 0) {
				if (valInt <= maxNumber) {
					this.setState({
						numberBefore: NumLocal,
					});
				} else {
					this.setState({
						numberBefore: NumMax,
					});
				}
			} else {
				this.setState({
					numberBefore: 0,
				});
			}
		}
	};

	onStepperMinusClick = (e) => {
		var val = this.state.numberBefore / this.state.count + 1;
		let lastNumber = this.state.numberBefore - this.state.count;
		const maxNumber = Math.ceil(
			this.state.renderItems.length / this.state.count
		);
		const NumMax = this.state.count * maxNumber - this.state.count;
		if (lastNumber >= 0) {
			if (val <= maxNumber) {
				this.setState({
					numberBefore: this.state.numberBefore,
				});
			} else {
				this.setState({
					numberBefore: NumMax,
				});
			}
		}
	};

	onContextMenuTREmpty = (e) => {
		e.preventDefault();
		let cTarget = e.currentTarget;

		var verificare =
			this.props.renderAdd ||
			this.props.renderUpdate ||
			this.props.renderDelete ||
			this.props.renderFilter ||
			this.props.renderFilterColumns ||
			(this.props.contextMenuItems !== undefined &&
				this.props.contextMenuItems.length > 0);
		if (this.props.onContextMenu !== undefined) {
			this.props.onContextMenu(this.state, this);
		}
		if (verificare && this.state.apelareRowClick) {
			this.setState({ contextMenuOpen: true }, () => {
				this.$f7.popover.open("." + this.popoverContextMenuName, cTarget);
			});
		}

		return false;
	};

	onContextMenuTR = (rowItemData, index, e) => {
		e.preventDefault();
		let cTarget = e.currentTarget;
		this.setState(
			{
				rowData: rowItemData,
				rowIndex: index,
				rowDataAll: rowItemData,
			},
			() => {
				if (this.parinteMain !== undefined) {
					this.parinteMain.setState({
						rowDataAll: rowItemData,
					});
				}
				var verificare =
					this.props.renderAdd ||
					this.props.renderUpdate ||
					this.props.renderDelete ||
					this.props.renderFilter ||
					this.props.renderFilterColumns ||
					(this.props.contextMenuItems !== undefined &&
						this.props.contextMenuItems.length > 0);
				if (this.props.onContextMenu !== undefined) {
					this.props.onContextMenu(this.state);
				}
				if (verificare && this.state.apelareRowClick) {
					this.setState({ contextMenuOpen: true }, () => {
						this.$f7.popover.open("." + this.popoverContextMenuName, cTarget);
					});
				}
			}
		);
		this.focusTR(cTarget);
		return false;
	};
	onContextMenuTD = (rowItemData, valueData, valueDataIndex, e) => {
		this.setState({
			cellData: rowItemData[valueData],
			columnIndex: valueDataIndex,
			columnName: valueData,
		});

		var cTarget = e.currentTarget;
		this.focusTD(cTarget);
	};

	onRowClick = (rowItemData, index, e) => {
		let cTarget = e.currentTarget;
		this.focusTR(cTarget);
		if (this.props.renderAccordion) {
			this.setState(
				{
					rowData: rowItemData,
					rowIndex: index,
					rowDataAll: rowItemData,
				},
				() => {
					if (this.parinteMain !== undefined) {
						this.parinteMain.state.rowDataAll = rowItemData;
					}
					if (this.props.onRowClick && this.state.apelareRowClick) {
						this.props.onRowClick(this.state, this);
					}
				}
			);
		} else {
			this.setState(
				{
					rowData: rowItemData,
					rowIndex: index,
					rowDataAll: rowItemData,
				},
				() => {
					if (this.parinteMain !== undefined) {
						this.parinteMain.state.rowDataAll = rowItemData;
					}
					if (this.props.onRowClick && this.state.apelareRowClick) {
						this.props.onRowClick(this.state);
					}
				}
			);
		}
	};

	onCellClick = (rowItemData, valueData, valueDataIndex, e) => {
		this.setState(
			{
				cellData: rowItemData[valueData],
				columnIndex: valueDataIndex,
				columnName: valueData,
			},
			() => {
				if (this.props.onCellClick && this.state.apelareRowClick) {
					this.props.onCellClick(this.state);
				}
			}
		);

		var cTarget = e.currentTarget;
		this.focusTD(cTarget);
	};

	onMouseLeaveTD = (e) => {
		if (showPopoverTimeout) {
			clearTimeout(showPopoverTimeout);
		}
		if (dynamicPopover !== null) {
			dynamicPopover.close();
		}
	};

	onMouseEnterTD = (rowItemData, e) => {
		var id =
			this.id !== null && this.id !== undefined && this.id !== ""
				? rowItemData[this.id]
				: null;
		var value = e.currentTarget.innerHTML;
		var t = e.currentTarget;
		var divID =
			id === null ? "" : "<span class='popoverTitle'>" + id + "</span>";
		if (this.props.hintInputSources !== undefined) {
			if (this.props.hintInputSources.length > 0) {
				this.props.hintInputSources.map((hintInputSource) => {
					if (rowItemData[hintInputSource] !== undefined) {
						value +=
							"<p>" +
							hintInputSource +
							" : " +
							rowItemData[hintInputSource] +
							"</p>";
					}
					return null;
				});
			}
		}
		if (this.props.hintComponent !== undefined) {
			value += this.props.hintComponent(this, this.state);
		}
		dynamicPopover = this.$f7.popover.create({
			backdrop: false,
			targetEl: "a.dynamic-popover",
			content:
				'<div class="popover">' +
				(divID ? divID : "") +
				'<div class="popover-inner" style="padding-left:6px;padding-right:6px;">' +
				"<p>" +
				value +
				"</p>" +
				"</div>" +
				"</div>",
		});
		showPopoverTimeout = setTimeout(() => {
			dynamicPopover.open(t, true);
		}, 1000);
	};

	search = (toSearch, baza, denumire) => {
		var result = baza.filter((elemBaza) => {
			var res = false;
			var valFinal = "";
			if (denumire) {
				var valoare = elemBaza[denumire];
				var inputCurent = this.props.inputs.find((elem) => {
					return elem.key === denumire;
				});
				if (inputCurent !== undefined) {
					var isSelectBox = ["selectbox", "selectboxmultiplu"].includes(
						inputCurent.tip
					);
					if (isSelectBox) {
						var resultFilter = inputCurent.lista.find((r) => {
							return r.value === valoare;
						});
						valoare = resultFilter !== undefined ? resultFilter.key : null;
					}
					if (valoare) {
						valFinal = valoare;
					}
				}
			} else {
				Object.keys(elemBaza).map((elemRow) => {
					var valoare = elemBaza[elemRow];
					var inputCurent = this.props.inputs.find((elem) => {
						return elem.key === elemRow;
					});
					if (inputCurent !== undefined) {
						var isSelectBox = ["selectbox", "selectboxmultiplu"].includes(
							inputCurent.tip
						);
						if (isSelectBox) {
							var resultFilter = inputCurent.lista.find((r) => {
								return r.value === valoare;
							});
							valoare = resultFilter !== undefined ? resultFilter.key : null;
						}
						if (valoare) {
							valFinal =
								valFinal + valoare.toString().toLowerCase().replace(/\s/g, "");
						}
					}
					return null;
				});
			}
			if (valFinal.includes(toSearch)) {
				res = true;
			}
			return res;
		});
		this.setState({
			renderItems: result,
			numberBefore: 0,
			searched: true,
			toSearch: toSearch,
		});
	};
	setRenderItems = (renderItems) => {
		this.setState(
			{
				bigDataSource: renderItems,
				dataSource: renderItems,
				renderItems: renderItems,
			},
			() => {
				this.saveFiltre();
				if (this.props.onInitialised) {
					var itemeRandate = this.state.renderItems.slice(
						this.state.numberBefore,
						this.state.numberBefore + this.state.count
					);
					var allRendered = this.parinteMain.returnRenderedItems();
					this.props.onInitialised(this.state, this, itemeRandate, allRendered);
				}
			}
		);
	};

	getThisByDenumire = (den2, e) => {
		if (e.denumireLista !== den2) {
			return e.datagridRef.current.getThisByDenumire(
				den2,
				e.datagridRef.current
			);
		}
		return e;
	};

	shouldComponentUpdate = (nextProps, nextState) => {
		var filtreNoi = {};
		if (Object.keys(nextState.filtre).length > 0) {
			filtreNoi = nextState.filtre;
		} else if (Object.keys(nextProps.filtre).length > 0) {
			filtreNoi = nextProps.filtre;
		}

		function objectsAreSame(x, y) {
			var objectsAreSame = true;
			for (var propertyName in x) {
				if (x[propertyName] !== y[propertyName]) {
					objectsAreSame = false;
					break;
				}
			}
			return objectsAreSame;
		}

		var bigDS1 =
			nextProps.dataSource !== undefined
				? nextProps.dataSource.length > 0
					? nextProps.dataSource
					: []
				: [];

		var bigDS2 = this.state.bigDataSource;
		var nextPropsDSFiltered = [];
		if (
			((nextState.dataSource !== undefined &&
				nextState.dataSource.length > 0) ||
				(nextProps.dataSource !== undefined &&
					nextProps.dataSource.length > 0)) &&
			this.state.refId !== null &&
			this.state.refId !== undefined &&
			this.state.initialRefId !== undefined
		) {
			nextPropsDSFiltered = bigDS1.filter((r) => {
				return r[this.state.refId] === this.state.initialRefId;
			});
		}
		var smallDS1 =
			nextProps.dataSource !== undefined
				? nextProps.dataSource.length > 0
					? this.state.refId !== null &&
						this.state.refId !== undefined &&
						this.state.initialRefId !== undefined
						? nextPropsDSFiltered
						: nextProps.dataSource
					: []
				: [];
		var smallDS2 =
			nextState.dataSource !== undefined
				? nextState.dataSource.length > 0
					? this.state.refId !== null &&
						this.state.refId !== undefined &&
						this.state.initialRefId !== undefined
						? nextPropsDSFiltered
						: nextState.dataSource
					: []
				: [];

		var nsRI =
			this.state.refId !== null &&
				this.state.refId !== undefined &&
				this.state.initialRefId !== undefined
				? nextState.renderItems.filter((r) => {
					return r[this.state.refId] === this.state.initialRefId;
				})
				: nextState.renderItems;

		var RI2 = nextState.renderItems !== nsRI ? nsRI : smallDS2;
		var asemanatoare2 = objectsAreSame(
			this.state.initialKeys,
			nextState.initialKeys
		);
		if (this.state.maxDepth !== nextState.maxDepth || !asemanatoare2) {
			this.setState({
				maxDepth: nextState.maxDepth,
				initialKeys: nextState.initialKeys,
				keys: nextState.keys,
			});
			return true;
		} else if (
			this.state.editing !== nextState.editing ||
			this.state.numberBefore !== nextState.numberBefore ||
			this.state.modalOpen !== nextState.modalOpen
		) {
			return true;
		} else {
			var asemanatoare = objectsAreSame(this.state.dataSource, smallDS1);
			if (
				(this.state.dataSource.length === 0 && smallDS1.length > 0) ||
				(this.state.dataSource.length !== 0 &&
					this.state.dataSource.length !== smallDS1.length) ||
				!asemanatoare
			) {
				this.setState({
					bigDataSource: bigDS1,
					dataSource: smallDS1,
					renderItems: smallDS1,
					filteredItems: smallDS1,
					filtre: filtreNoi,
					maxDepth: nextState.maxDepth,
				});
				return true;
			} else if (this.state.dataSource.length !== smallDS2.length) {
				if (!this.state.adding && !this.state.editing && !this.state.deleting) {
					this.setState({
						bigDataSource: bigDS2,
						dataSource: smallDS2,
						renderItems: RI2,
						filteredItems: smallDS2,
						filtre: filtreNoi,
						maxDepth: nextState.maxDepth,
					});
				}
				return true;
			} else if (
				this.state.accordionItem !== nextProps.accordionItem &&
				nextProps.refId === undefined
			) {
				this.setState({ accordionItem: nextProps.accordionItem });
				return true;
			}
		}
		return true;
	};

	openModal = (name, noClose) => {
		return new Promise((resolve, reject) => {
			if (noClose) {
				var denToOpen = "." + name;
				var popoverToOpen = this.$f7.popover.get(denToOpen);
				var popupToOpen = this.$f7.popup.get(denToOpen);
				var panelToOpen = this.$f7.panel.get(denToOpen);
				var sheetToOpen = this.$f7.sheet.get(denToOpen);
				if (popoverToOpen) {
					popoverToOpen.open(this.state.target);
					popoverToOpen.on("opened", () => {
						resolve(true);
					});
				} else if (sheetToOpen) {
					sheetToOpen.open();
					sheetToOpen.on("opened", () => {
						resolve(true);
					});
				} else if (popupToOpen) {
					popupToOpen.open();
					popupToOpen.on("opened", () => {
						resolve(true);
					});
				} else if (panelToOpen) {
					panelToOpen.open();
					panelToOpen.on("opened", () => {
						resolve(true);
					});
				}
			} else {
				this.closeModal(name).then(() => {
					this.setState({ modalOpen: true }, () => {
						var denToOpen = "." + name;
						var popoverToOpen = this.$f7.popover.get(denToOpen);
						var popupToOpen = this.$f7.popup.get(denToOpen);
						var panelToOpen = this.$f7.panel.get(denToOpen);
						var sheetToOpen = this.$f7.sheet.get(denToOpen);
						if (popoverToOpen) {
							popoverToOpen.open(this.state.target);
							popoverToOpen.on("opened", () => {
								resolve(true);
							});
						} else if (sheetToOpen) {
							sheetToOpen.open();
							sheetToOpen.on("opened", () => {
								resolve(true);
							});
						} else if (popupToOpen) {
							popupToOpen.open();
							popupToOpen.on("opened", () => {
								resolve(true);
							});
						} else if (panelToOpen) {
							panelToOpen.open();
							panelToOpen.on("opened", () => {
								resolve(true);
							});
						}
					});
				});
			}
		});
	};

	closeModal = (name, e) => {
		return new Promise((resolve, reject) => {
			var denToClose = "." + name;
			var popupToClose = this.$f7.popup.get(denToClose);
			var panelToClose = this.$f7.panel.get(denToClose);
			var sheetToClose = this.$f7.sheet.get(denToClose);
			if (sheetToClose) {
				if (sheetToClose.opened) {
					sheetToClose.close();
					sheetToClose.on("closed", () => {
						resolve(true);
					});
				} else {
					resolve(true);
				}
			}
			if (popupToClose) {
				if (popupToClose.opened) {
					popupToClose.close();
					popupToClose.on("closed", () => {
						resolve(true);
					});
				} else {
					resolve(true);
				}
			}
			if (panelToClose) {
				if (panelToClose.opened) {
					panelToClose.close();
					panelToClose.on("closed", () => {
						resolve(true);
					});
				} else {
					resolve(true);
				}
			}
			if (!panelToClose && !sheetToClose && !popupToClose) {
				resolve(true);
			}
		});
	};

	render() {
		return (
			<div
				className={"datagridMain"}
				style={{
					...this.props.style,
				}}
			>
				{/* POPOVER CONTEXT MENU */}
				{this.state.contextMenuOpen ? (
					<Popover
						onPopoverClosed={() => {
							setTimeout(() => {
								this.setState({ contextMenuOpen: false });
							}, 100);
						}}
						className={this.popoverContextMenuName}
					>
						<List style={{ cursor: "pointer" }} inset>
							{this.props.renderAdd ? (
								<ListItem
									key={this.tableName + "contextMenuItem-Adauga"}
									className={"inputInaltimeNoua"}
									onClick={() => {
										this.$f7.popover.close("." + this.popoverContextMenuName);
										if (
											this.state.refId !== undefined &&
											this.id !== "" &&
											this.state.initialRefId !== undefined
										) {
											this.openAdd(false, this.state.initialRefId);
										} else {
											this.openAdd();
										}
									}}
									tooltip="Adauga"
									title="Adauga"
									disabled={
										this.props.dataLoaded !== undefined &&
										!this.props.dataLoaded
									}
								>
									<Icon
										style={{ color: culoare }}
										slot="media"
										material="add"
									/>
								</ListItem>
							) : null}

							{this.props.renderAdd &&
								this.state.refId !== undefined &&
								this.id !== "" &&
								this.state.initialRefId !== undefined ? (
								<ListItem
									key={this.tableName + "contextMenuItem-AdaugaCopil"}
									className={"inputInaltimeNoua"}
									onClick={() => {
										this.$f7.popover.close("." + this.popoverContextMenuName);
										this.openAdd(false, this.state.rowData[this.id]);
									}}
									tooltip="Adauga copil"
									title="Adauga copil"
									disabled={
										this.props.dataLoaded !== undefined &&
										!this.props.dataLoaded
									}
								>
									<Icon
										style={{ color: culoare }}
										slot="media"
										material="add"
									/>
								</ListItem>
							) : null}
							{this.props.renderFilter ? (
								<ListItem
									key={this.tableName + "contextMenuItem-FiltrareValori"}
									className={"inputInaltimeNoua"}
									onClick={this.parinteMain.openFiltrare.bind(this, "right")}
									tooltip="Filtrare valori"
									title="Filtrare"
									disabled={
										this.props.dataLoaded !== undefined &&
										!this.props.dataLoaded
									}
								>
									<Icon
										style={{ color: culoare }}
										slot="media"
										material="filter_list"
									/>
								</ListItem>
							) : null}
							{this.props.renderFilterColumns ? (
								<ListItem
									key={this.tableName + "contextMenuItem-FiltrareColoane"}
									className={"inputInaltimeNoua"}
									onClick={this.parinteMain.openFiltrareColoane.bind(this)}
									tooltip="Filtrare coloane"
									title="Filtrare coloane"
									disabled={
										this.props.dataLoaded !== undefined &&
										!this.props.dataLoaded
									}
								>
									<Icon
										style={{ color: culoare }}
										slot="media"
										material="view_column"
									/>
								</ListItem>
							) : null}
							{this.props.renderExcel ? (
								<ListItem
									key={this.tableName + "contextMenuItem-ExportareExcel"}
									className={"inputInaltimeNoua"}
									onClick={this.parinteMain.openExportExcel.bind(this)}
									tooltip="Exportare excel"
									title="Export excel"
									disabled={
										this.props.dataLoaded !== undefined &&
										!this.props.dataLoaded
									}
								>
									<Icon
										style={{ color: culoare }}
										slot="media"
										material="save"
									/>
								</ListItem>
							) : null}
							{this.props.renderUpdate ? (
								<ListItem
									key={this.tableName + "contextMenuItem-Actualizare"}
									className={"inputInaltimeNoua"}
									onClick={this.openUpdate.bind(this, this.state.rowData, null)}
									disabled={
										this.props.dataLoaded !== undefined &&
										!this.props.dataLoaded
									}
									tooltip="Actualizare"
									title="Actualizare"
								>
									{" "}
									<Icon
										style={{ color: culoare }}
										slot="media"
										material="create"
									/>
								</ListItem>
							) : null}
							{this.props.renderDelete ? (
								<ListItem
									key={this.tableName + "contextMenuItem-Stergere"}
									className={"inputInaltimeNoua"}
									onClick={this.openDelete.bind(this, this.state.rowData)}
									disabled={
										this.props.dataLoaded !== undefined &&
										!this.props.dataLoaded
									}
									tooltip="Stergere"
									title="Sterge"
								>
									{" "}
									<Icon
										style={{ color: culoare }}
										slot="media"
										material="delete"
									/>
								</ListItem>
							) : null}
							{this.props.contextMenuItems !== undefined
								? typeof this.props.contextMenuItems === "object"
									? this.props.contextMenuItems.length > 0
										? this.props.contextMenuItems.map((item, index) => {
											return item !== null ? (
												<ListItem
													key={this.tableName + "contextMenuItem-" + item.key}
													className={"inputInaltimeNoua"}
													onClick={() => {
														if (item.onClick !== undefined) {
															this.$f7.popover.close(
																"." + this.popoverContextMenuName
															);
															item.onClick(this.state, this);
														}
													}}
													disabled={
														(this.props.dataLoaded !== undefined &&
															!this.props.dataLoaded) ||
														(item.disabled !== undefined &&
															item.disabled === true)
													}
													tooltip={item.key !== undefined ? item.key : ""}
													title={item.key !== undefined ? item.key : ""}
												>
													<Icon
														style={{ color: culoare }}
														slot="media"
														material={
															item.icon !== undefined ? item.icon : "settings"
														}
													/>
												</ListItem>
											) : (
												<></>
											);
										})
										: null
									: this.props
										.contextMenuItems(this.state, this)
										.map((item, index) => {
											return item !== null ? (
												<ListItem
													key={this.tableName + "contextMenuItem-" + item.key}
													className={"inputInaltimeNoua"}
													onClick={() => {
														if (item.onClick !== undefined) {
															this.$f7.popover.close(
																"." + this.popoverContextMenuName
															);
															item.onClick(this.state, this);
														}
													}}
													disabled={
														(this.props.dataLoaded !== undefined &&
															!this.props.dataLoaded) ||
														(item.disabled !== undefined &&
															item.disabled === true)
													}
													tooltip={item.key !== undefined ? item.key : ""}
													title={item.key !== undefined ? item.key : ""}
												>
													<Icon
														style={{ color: culoare }}
														slot="media"
														material={
															item.icon !== undefined ? item.icon : "settings"
														}
													/>
												</ListItem>
											) : (
												<></>
											);
										})
								: null}
						</List>
					</Popover>
				) : null}
				{/* POPUP FILTRARE COLOANE */}
				{this.state.modalOpen ? (
					<Wrapper
						onOpen={() => {
							initialKeysStatic = [...this.state.initialKeys];
						}}
						onClose={() => {
							var arr2 = [];

							initialKeysStatic.map((r) => {
								if (this.state.keys.includes(r)) {
									arr2.push(r);
								}
								return null;
							});

							this.setState(
								{
									initialKeys: initialKeysStatic,
									keys: arr2,
									adding: false,
									editing: false,
									filtrareOpen: false,
									filtrareColumnsOpen: false,
									listaRenderType: 0,
									modalOpen: false,
								},
								() => {
									this.savePreferences();
									if (this.state.sortingOptions.length > 0) {
										var rendItems = this.state.renderItems.sort(
											this.dynamicSortNew()
										);
										var bigDS = this.state.bigDataSource.sort(
											this.dynamicSortNew()
										);
										this.setState(
											{ renderItems: rendItems, bigDataSource: bigDS },
											() => {
												if (this.state.rowData && this.id) {
													this.focusItemInContext(
														this.state.rowData,
														this.id,
														this
													);
												}
											}
										);
									}
								}
							);
						}}
						name={this.tableNamePopupFiltreColumns}
						modalType={this.props.modalType}
					>
						{this.state.filtrareColumnsOpen ? (
							<Page>
								<Navbar
									title={
										"Coloane " +
										(this.props.name !== undefined
											? this.props.name.replace(/([A-Z])/g, " $1").trim()
											: "")
									}
								>
									<NavRight>
										<Link
											tooltip={"Inchide"}
											onClick={this.closeModal.bind(
												this,
												this.tableNamePopupFiltreColumns
											)}
										>
											Inchide
										</Link>
									</NavRight>
								</Navbar>

								<List
									sortable
									onSortableSort={(e) => {
										var arr = initialKeysStatic;
										var val = arr.splice(e.from, 1);
										arr.splice(e.to, 0, val[0]);
										initialKeysStatic = arr;
									}}
									sortableOpposite
									sortableEnabled
									style={{
										height: "100%",
										overflow: "auto",
										margin: "0px!important",
									}}
								>
									{this.state.initialKeys.map((item, index) => (
										<ListItem
											after={
												<Button
													onClick={() => {
														var sortOptions = [...this.state.sortingOptions];
														var exista = sortOptions.find((r) => {
															return r.key === item;
														});
														if (exista) {
															if (exista.value === 1) {
																exista.value = -1;
															} else {
																exista.value = 0;
															}

															if (exista.value === 0) {
																sortOptions = sortOptions.filter((r) => {
																	return r.key !== exista.key;
																});
															} else {
																sortOptions = sortOptions.filter((r) => {
																	return r.key !== exista.key;
																});
																sortOptions.push(exista);
															}
														} else {
															sortOptions.push({ key: item, value: 1 });
														}
														this.setState({ sortingOptions: sortOptions });
													}}
													iconMaterial={
														this.state.sortingOptions.find((r) => {
															return r.key === item;
														})
															? this.state.sortingOptions.find((r) => {
																return r.key === item;
															}).value === 1
																? "keyboard_arrow_up"
																: "keyboard_arrow_down"
															: "view_stream"
													}
													style={{ display: "inline-block" }}
												/>
											}
											key={"itemFiltre" + this.tableName + index}
											checkbox
											title={item}
											name={"demo-checkbox" + this.tableName}
											value={item}
											checked={this.state.keys.includes(item)}
											onChange={(e) => {
												var checked = e.target.checked;
												var keys = [...this.state.keys];
												if (checked) {
													keys.push(item);
													var indexKeys = [];
													keys.map((r) => {
														var inputCurent = this.props.inputs.find((f) => {
															return f.key === r;
														});
														if (inputCurent) {
															var indexInputCurent =
																this.props.inputs.findIndex((f) => {
																	return f.key === r;
																});
															indexKeys.push(indexInputCurent);
														}
														return null;
													});
													if (indexKeys.length > 0) {
														for (let i = 0; i < indexKeys.length - 1; i++) {
															for (let j = i + 1; j < indexKeys.length; j++) {
																if (indexKeys[i] > indexKeys[j]) {
																	var aux1 = indexKeys[i];
																	var aux2 = keys[i];
																	keys[i] = keys[j];
																	keys[j] = aux2;
																	indexKeys[i] = indexKeys[j];
																	indexKeys[j] = aux1;
																}
															}
														}
													}
												} else {
													keys = keys.filter((key) => {
														return key !== item;
													});
												}
												this.setState({ keys: keys }, () => {
													if (this.props.onFilterColumnsChange) {
														this.props.onFilterColumnsChange(this.state);
													}
												});
											}}
										/>
									))}
								</List>
							</Page>
						) : null}
					</Wrapper>
				) : null}
				{/* POPUP FILTRARE */}
				{this.state.modalOpen ? (
					<Wrapper
						onClose={() => {
							this.setState({
								adding: false,
								editing: false,
								filtrareOpen: false,
								filtrareColumnsOpen: false,
								listaRenderType: 0,
								modalOpen: false,
							});
						}}
						direction={this.state.filterPanelDirection}
						name={this.tableNamePopupFiltre}
						context={this}
						modalType={this.props.modalType}
					>
						{this.state.filtrareOpen ? (
							<Page>
								<Navbar
									title={
										"Filtre " +
										(this.props.name !== undefined
											? this.props.name.replace(/([A-Z])/g, " $1").trim()
											: "")
									}
								>
									<NavRight>
										<Link
											tooltip={"Inchide"}
											onClick={this.closeModal.bind(
												this,
												this.tableNamePopupFiltre
											)}
										>
											Inchide
										</Link>
									</NavRight>
								</Navbar>

								<List
									style={{
										height: "100%",
										overflow: "auto",
										margin: "0px!important",
									}}
									noHairlinesBetweenMd
									noHairlinesMd
									form
									inset
									onSubmit={(e) => {
										e.preventDefault();
										this.saveFiltre();
									}}
								>
									<Row>
										{this.state.initialKeys.map((denumire, index) => {
											var toRender;

											if (this.props.inputs) {
												var existaKey;
												var tip = "";
												var lista = [];
												var input = null;
												this.props.inputs.map((inp) => {
													if (inp.key === denumire) {
														input = inp;
														existaKey = true;
														tip = inp.tip;
														if (tip === "selectbox") {
															lista = inp.lista;
														}
													}
													return null;
												});

												if (lista.length > 0) {
													var newArr = [];
													this.state.bigDataSource.map((rI) => {
														newArr.push(rI[input.key]);
														return null;
													});
													lista = lista.filter((r) => {
														return newArr.includes(r.value);
													});
												}

												if (existaKey) {
													switch (tip) {
														case "colorpicker": {
															toRender = (
																<ListInput
																	key={
																		"item-" + this.tableName + denumire + index
																	}
																	disabled={denumire === this.id ? true : false}
																	floatingLabel
																	label={denumire}
																	type="colorpicker"
																	placeholder={denumire}
																	value={{
																		hex:
																			this.state.editedItem[denumire] === "" ||
																				this.state.editedItem[denumire] === null
																				? "#ffffff"
																				: this.state.editedItem[denumire],
																	}}
																	colorPickerParams={{
																		value: {
																			hex:
																				this.state.filtre[denumire].value === ""
																					? "#ffffff"
																					: this.state.filtre[denumire].value,
																		},
																		openIn: "popup",
																		popupTabletFullscreen: true,
																		popupPush: true,
																		modules: [
																			"initial-current-colors",
																			"sb-spectrum",
																			"rgb-sliders",
																			"palette",
																		],
																		sliderValue: true,
																		sliderValueEditable: true,
																		sliderLabel: true,
																		hexLabel: true,
																		hexValueEditable: true,
																		groupedModules: true,
																		palette: config.palette,
																		on: {
																			close: (e) => {
																				var val = e.value.hex;
																				var filtre = {
																					...this.state.filtre,
																				};
																				filtre[denumire].value = val;
																				filtre[denumire].comparatie =
																					"contains";
																				this.setState({ filtre: filtre });
																			},
																		},
																	}}
																>
																	<Icon
																		slot="content-start"
																		className={"datagridIcon"}
																		size={20}
																		material="money"
																	/>
																</ListInput>
															);
															break;
														}
														case "password": {
															toRender = (
																<ListInput
																	key={
																		"item-" + this.tableName + denumire + index
																	}
																	disabled={denumire === this.id ? true : false}
																	floatingLabel
																	inputmode="text"
																	label={denumire}
																	type="password"
																	placeholder={denumire}
																	value={
																		this.state.filtre[denumire].value !==
																			undefined &&
																			this.state.filtre[denumire].value !== null
																			? this.state.filtre[denumire].value
																			: ""
																	}
																	onInput={(e) => {
																		var val = e.target.value;
																		var filtre = { ...this.state.filtre };
																		filtre[denumire].value = val;
																		filtre[denumire].comparatie = "contains";
																		this.setState({ filtre: filtre });
																	}}
																>
																	<Icon
																		slot="content-start"
																		className={"datagridIcon"}
																		size={20}
																		material="security"
																	/>
																</ListInput>
															);
															break;
														}
														case "email": {
															toRender = (
																<ListInput
																	key={
																		"item-" + this.tableName + denumire + index
																	}
																	disabled={denumire === this.id ? true : false}
																	floatingLabel
																	label={denumire}
																	inputmode="email"
																	type="email"
																	placeholder={denumire}
																	value={
																		this.state.filtre[denumire].value !==
																			undefined &&
																			this.state.filtre[denumire].value !== null
																			? this.state.filtre[denumire].value
																			: ""
																	}
																	onInput={(e) => {
																		var val = e.target.value;
																		var filtre = { ...this.state.filtre };
																		filtre[denumire].value = val;
																		filtre[denumire].comparatie = "contains";
																		this.setState({ filtre: filtre });
																	}}
																>
																	<Icon
																		slot="content-start"
																		className={"datagridIcon"}
																		size={20}
																		material="email"
																	/>
																</ListInput>
															);
															break;
														}
														case "phone": {
															toRender = (
																<ListInput
																	key={
																		"item-" + this.tableName + denumire + index
																	}
																	disabled={denumire === this.id ? true : false}
																	inputmode="tel"
																	floatingLabel
																	label={denumire}
																	type="tel"
																	minlength={10}
																	maxlength={10}
																	placeholder={denumire}
																	value={
																		this.state.filtre[denumire].value !==
																			undefined &&
																			this.state.filtre[denumire].value !== null
																			? this.state.filtre[denumire].value
																			: ""
																	}
																	onInput={(e) => {
																		var val = e.target.value;
																		var filtre = { ...this.state.filtre };
																		filtre[denumire].value = val;
																		filtre[denumire].comparatie = "contains";
																		this.setState({ filtre: filtre });
																	}}
																>
																	<Icon
																		slot="content-start"
																		className={"datagridIcon"}
																		size={20}
																		material="phone"
																	/>
																</ListInput>
															);
															break;
														}
														case "number": {
															toRender = (
																<ListInput
																	inputmode="numeric"
																	key={
																		"item-" + this.tableName + denumire + index
																	}
																	disabled={denumire === this.id ? true : false}
																	floatingLabel
																	label={denumire}
																	type="number"
																	placeholder={denumire}
																	value={
																		this.state.filtre[denumire].value === "" ||
																			this.state.filtre[denumire].value ===
																			undefined ||
																			this.state.filtre[denumire].value === null
																			? 0
																			: this.state.filtre[denumire].value
																	}
																	onInput={(e) => {
																		var val = e.target.value;
																		var filtre = { ...this.state.filtre };
																		filtre[denumire].value = val;
																		this.setState({ filtre: filtre });
																	}}
																>
																	<Icon
																		slot="content-start"
																		className={"datagridIcon"}
																		size={20}
																		material="money"
																	/>
																	<Segmented slot="media" tag="div">
																		<Button
																			active={
																				this.state.filtre[denumire]
																					.comparatie === "<"
																			}
																			small
																			onClick={this.changeFilterCondition.bind(
																				this,
																				denumire,
																				"<"
																			)}
																		>
																			{"<"}
																		</Button>
																		<Button
																			active={
																				this.state.filtre[denumire]
																					.comparatie === "="
																			}
																			small
																			onClick={this.changeFilterCondition.bind(
																				this,
																				denumire,
																				"="
																			)}
																		>
																			{"="}
																		</Button>
																		<Button
																			active={
																				this.state.filtre[denumire]
																					.comparatie === ">"
																			}
																			small
																			onClick={this.changeFilterCondition.bind(
																				this,
																				denumire,
																				">"
																			)}
																		>
																			{">"}
																		</Button>
																	</Segmented>
																</ListInput>
															);
															break;
														}
														case "datebox": {
															toRender = (
																<ListInput
																	key={
																		"item-" + this.tableName + denumire + index
																	}
																	disabled={denumire === this.id ? true : false}
																	floatingLabel
																	label={denumire}
																	type="datepicker"
																	placeholder={denumire}
																	value={[
																		this.state.editedItem[denumire] === "" ||
																			this.state.editedItem[denumire] === null
																			? moment(Date.now()).format()
																			: this.state.filtre[denumire].value,
																	]}
																	calendarParams={{
																		timePicker: true,
																		on: {
																			closed: (e) => {
																				var val = e.value[0];
																				var filtre = {
																					...this.state.filtre,
																				};
																				filtre[denumire].value = val;
																				this.setState({ filtre: filtre });
																			},
																		},
																	}}
																>
																	<Icon
																		slot="content-start"
																		className={"datagridIcon"}
																		size={20}
																		material="today"
																	/>
																	<Segmented slot="media" tag="div">
																		<Button
																			active={
																				this.state.filtre[denumire]
																					.comparatie === "<Date"
																			}
																			small
																			onClick={this.changeFilterCondition.bind(
																				this,
																				denumire,
																				"<Date"
																			)}
																		>
																			{"<"}
																		</Button>
																		<Button
																			active={
																				this.state.filtre[denumire]
																					.comparatie === "=Date"
																			}
																			small
																			onClick={this.changeFilterCondition.bind(
																				this,
																				denumire,
																				"=Date"
																			)}
																		>
																			{"="}
																		</Button>
																		<Button
																			active={
																				this.state.filtre[denumire]
																					.comparatie === ">Date"
																			}
																			small
																			onClick={this.changeFilterCondition.bind(
																				this,
																				denumire,
																				">Date"
																			)}
																		>
																			{">"}
																		</Button>
																	</Segmented>
																</ListInput>
															);
															break;
														}
														case "bool": {
															toRender = (
																<ListInput
																	type={"text"}
																	placeholder={denumire}
																	value={this.state.filtre[denumire].value}
																	key={"item-" + this.tableName + denumire}
																	disabled={denumire === this.id ? true : false}
																	floatingLabel
																	label={denumire}
																	onFocus={(e) => {
																		var t = e.currentTarget;
																		var inputSelectat = this.props.inputs.find(
																			(r) => {
																				return r.key === denumire;
																			}
																		);
																		this.setState(
																			{
																				lista: this.listaBool3,
																				denumire: denumire,
																				inputSelectat: inputSelectat,
																				listaRenderType: 2,
																				target: t,
																			},
																			() => {
																				this.openModal(
																					this.tableNameModalFiltrare,
																					true
																				);
																			}
																		);
																	}}
																	title={denumire}
																>
																	<Icon
																		slot="content-start"
																		className={"datagridIcon"}
																		size={20}
																		material="list"
																	/>
																</ListInput>
															);
															break;
														}
														case "selectbox":
														case "selectboxmultiplu": {
															toRender = (
																<ListInput
																	type={"text"}
																	placeholder={denumire}
																	value={this.state.filtre[denumire].value}
																	key={"item-" + this.tableName + denumire}
																	disabled={denumire === this.id ? true : false}
																	floatingLabel
																	label={denumire}
																	onFocus={(e) => {
																		var t = e.currentTarget;
																		var inputSelectat = this.props.inputs.find(
																			(r) => {
																				return r.key === denumire;
																			}
																		);
																		this.setState(
																			{
																				lista: lista,
																				denumire: denumire,
																				inputSelectat: inputSelectat,
																				listaRenderType: 2,
																				target: t,
																			},
																			() => {
																				this.openModal(
																					this.tableNameModalFiltrare,
																					true
																				);
																			}
																		);
																	}}
																	title={denumire}
																>
																	<Icon
																		slot="content-start"
																		className={"datagridIcon"}
																		size={20}
																		material="list"
																	/>
																</ListInput>
															);
															break;
														}
														default: {
															toRender = (
																<ListInput
																	key={
																		"item-" + this.tableName + denumire + index
																	}
																	disabled={denumire === this.id ? true : false}
																	floatingLabel
																	label={denumire}
																	inputmode="text"
																	type="text"
																	placeholder={denumire}
																	value={
																		this.state.filtre[denumire].value !== null
																			? this.state.filtre[denumire].value
																			: ""
																	}
																	onInput={(e) => {
																		var val = e.target.value;
																		var filtre = { ...this.state.filtre };
																		filtre[denumire].value = val;
																		filtre[denumire].comparatie = "contains";
																		this.setState({ filtre: filtre });
																	}}
																>
																	<Icon
																		slot="content-start"
																		className={"datagridIcon"}
																		size={20}
																		material="create"
																	/>
																</ListInput>
															);
															break;
														}
													}
												}
											}
											return this.props.inputs.find((inputul) => {
												return inputul.key === denumire;
											}) !== undefined && denumire !== this.id ? (
												<Col
													key={"colFilter-" + this.tableName + denumire}
													{...settings2}
												>
													{toRender}
												</Col>
											) : null;
										})}
									</Row>

									<Segmented
										raised
										style={{
											margin: "8px auto",
											width: "200px",
										}}
										tag="div"
									>
										<Button
											bgColor="orange"
											fill
											onClick={() => {
												this.resetFiltre();
											}}
											small
										>
											Reset
										</Button>
										<Button type="submit" small fill bgColor="teal">
											Salveaza
										</Button>
									</Segmented>
								</List>
							</Page>
						) : null}
					</Wrapper>
				) : null}
				{this.state.modalOpen ? (
					<Wrapper
						onClose={() => {
							this.setState({
								lista: [],
								listaRenderType: 0,
							});
						}}
						modalType={"sheet"}
						name={this.tableNameModalFiltrare}
					>
						{this.state.listaRenderType === 2 ? (
							<SelectboxOptimizat
								onClick={(value) => {
									var filtre = { ...this.state.filtre };
									var arr = filtre[this.state.denumire].value;
									if (arr === null) {
										arr = [value.toString()];
									} else {
										if (arr.includes(value.toString())) {
											arr = arr.filter((r) => {
												return r !== value.toString();
											});
										} else {
											arr.push(value.toString());
										}
									}
									filtre[this.state.denumire].value = arr;
									filtre[this.state.denumire].comparatie =
										this.state.inputSelectat.tip === "bool"
											? "bool"
											: "arraycontains";
									this.setState({ filtre: filtre });
								}}
								modalName={this.tableNameModalFiltrare}
								searchName={this.searchName}
								valoare={this.state.filtre[this.state.denumire].value}
								lista={this.state.lista}
								count={
									this.props.modalTypeSelectbox === "popover" ||
										this.props.modalTypeSelectbox === undefined
										? countMic
										: count
								}
								tip={"selectboxmultiplu"}
							/>
						) : null}
					</Wrapper>
				) : null}
				{this.state.modalOpen ? (
					<Wrapper
						onClose={() => {
							this.setState({
								lista: [],
								listaRenderType: 0,
							});
						}}
						modalType={"sheet"}
						name={this.tableNameModalAddEdit}
					>
						{this.state.listaRenderType === 1 ? (
							<SelectboxOptimizat
								onClick={(value) => {
									if (this.state.inputSelectat.tip === "selectboxmultiplu") {
										var editItem0 = { ...this.state.editedItem };
										var item = editItem0[this.state.denumire];
										if (item === "valoarePentruRequired") {
											item = [];
										}
										if (item.includes(value.toString())) {
											item = item.filter((r) => {
												return r.toString() !== value.toString();
											});
										} else {
											item.push(value.toString());
										}
										editItem0[this.state.denumire] = item;
										this.setState({ editedItem: editItem0 });
									} else {
										var editItem1 = { ...this.state.editedItem };
										editItem1[this.state.denumire] = value;
										this.setState({ editedItem: editItem1 }, () => {
											this.closeModal(this.tableNameModalAddEdit);
											if (this.props.onSelectionChanged !== undefined) {
												this.props.onSelectionChanged(this.state);
											}
										});
									}
								}}
								modalName={this.tableNameModalAddEdit}
								searchName={this.searchName}
								valoare={this.state.editedItem[this.state.denumire]}
								lista={this.state.lista}
								count={
									this.props.modalTypeSelectbox === "popover" ||
										this.props.modalTypeSelectbox === undefined
										? countMic
										: count
								}
								tip={this.state.inputSelectat.tip}
							/>
						) : null}
					</Wrapper>
				) : null}

				{/* POPUP ADAUGARE/EDITARE */}
				{this.state.modalOpen ? (
					<Wrapper
						onClose={() => {
							this.setState({
								adding: false,
								editing: false,
								filtrareOpen: false,
								filtrareColumnsOpen: false,
								listaRenderType: 0,
								modalOpen: false,
							});
						}}
						modalType={this.props.modalType}
						name={this.tableNamePopup}
					>
						{this.state.editing ? (
							<Page>
								<Navbar
									title={
										this.state.adding
											? "Adaugare " +
											(this.props.name !== undefined
												? this.props.name.replace(/([A-Z])/g, " $1").trim()
												: "")
											: this.state.editing
												? "Editare" +
												(this.props.coloanaDenumire !== undefined
													? this.props.inputs.find((x) => {
														return x.key === this.props.coloanaDenumire;
													}).tip === "selectbox"
														? " (" +
														this.props.inputs
															.find((x) => {
																return x.key === this.props.coloanaDenumire;
															})
															.lista.find((y) => {
																return (
																	y.value ===
																	this.state.editedItem[
																	this.props.coloanaDenumire
																	]
																);
															}).key +
														")"
														: " (" +
														this.state.editedItem[
														this.props.coloanaDenumire
														] +
														")"
													: this.props.titluDenumire !== undefined
														? " (" + this.props.titluDenumire + ")"
														: "")
												: ""
									}
								>
									{!this.state.hideClose ? (
										<NavRight>
											<Link
												tooltip={"Inchide"}
												onClick={this.closeModal.bind(
													this,
													this.tableNamePopup
												)}
											>
												Inchide
											</Link>
										</NavRight>
									) : null}
								</Navbar>

								<>
									<List
										style={{
											height: "100%",
											overflow: "auto",
											margin: "0px!important",
										}}
										noHairlinesBetweenMd
										noHairlinesMd
										form
										inset
										onSubmit={(e) => {
											e.preventDefault();
											if (this.state.adding) {
												this.add();
											} else {
												this.update(this.state.realIndex);
											}
										}}
									>
										{this.groups.length > 0
											? this.groups.map((denumire) =>
												this.props.inputs.filter((r) => {
													var val = true;
													if (this.state.adding) {
														if (this.props.ignoreInputsAdd) {
															val = !this.props.ignoreInputsAdd.includes(
																r.key
															);
														}
													} else {
														if (this.state.editing) {
															if (this.props.ignoreInputsEdit) {
																val = !this.props.ignoreInputsEdit.includes(
																	r.key
																);
															}
														}
													}
													return (
														val &&
														(r.group === denumire ||
															(r.group === undefined && denumire === ""))
													);
												}).length > 0 ? (
													<ListGroup
														key={"listGroup" + this.tableName + denumire}
													>
														<ListItem title={denumire} groupTitle />
														<Row>
															{this.props.inputs
																.filter((r) => {
																	var val = true;
																	if (this.state.adding) {
																		if (this.props.ignoreInputsAdd) {
																			val =
																				!this.props.ignoreInputsAdd.includes(
																					r.key
																				);
																		}
																	} else {
																		if (this.state.editing) {
																			if (this.props.ignoreInputsEdit) {
																				val =
																					!this.props.ignoreInputsEdit.includes(
																						r.key
																					);
																			}
																		}
																	}
																	return (
																		val &&
																		(r.group === denumire ||
																			(r.group === undefined &&
																				denumire === ""))
																	);
																})
																.map((input, index) => {
																	var denumire = input.key;
																	return this.renderInput(denumire, index);
																})}
														</Row>
													</ListGroup>
												) : null
											)
											: this.state.keys.map((denumire, index) => {
												return this.renderInput(denumire, index);
											})}

										<Segmented
											raised
											style={{
												margin: "8px auto",
												width: "200px",
											}}
											tag="div"
										>
											<Button type="submit" small fill bgColor="teal">
												Salveaza
											</Button>
										</Segmented>
									</List>
								</>
							</Page>
						) : null}
					</Wrapper>
				) : null}

				{(this.props.renderFilter === undefined ||
					(this.props.renderFilter !== undefined &&
						this.props.renderFilter !== false) ||
					this.props.renderSearch === undefined ||
					(this.props.renderSearch !== undefined &&
						this.props.renderSearch !== false) ||
					this.props.renderExcel === undefined ||
					(this.props.renderExcel !== undefined &&
						this.props.renderExcel !== false) ||
					this.props.renderFilterColumns === undefined ||
					(this.props.renderFilterColumns !== undefined &&
						this.props.renderFilterColumns !== false) ||
					this.props.title ||
					this.props.renderAdd) &&
					this.props.child === undefined ? (
					<CardHeader
						style={{
							minHeight: this.inaltimeRand,
							height: 34,
							overflow: "hidden",
							width:
								this.props.child !== undefined
									? this.props.bigParentRefValue - this.widthScadere + "px"
									: "auto",
							transform: "translateX(0px)",
							padding: 0,
							margin: 0,
						}}
						className={
							this.props.child !== undefined ? "SegmentedTopPosition" : "vava"
						}
					>
						<div
							style={{
								overflow: "hidden",
								width: "100%",
								display: "contents",
							}}
						>
							<h4 style={{ float: "left", margin: 0 }}>
								{typeof this.props.title === "function"
									? this.props.title(this.state, this)
									: this.props.title}
							</h4>

							{this.props.renderSearch === undefined ||
								(this.props.renderSearch !== undefined &&
									this.props.renderSearch !== false) ? (
								<Searchbar
									className={"searchBar" + this.tableName}
									expandable
									onClickDisable={() => {
										var source = [...this.state.filteredItems];
										this.setState({
											renderItems: source,
											searched: false,
											toSearch: "",
										});
									}}
									onSearchbarEnable={() => {
										let listaButoane = document.getElementsByClassName(
											"butoaneContextDatagrid"
										);
										for (let i = 0; i < listaButoane.length; i++) {
											if (!listaButoane[i].classList.contains("inchis"))
												listaButoane[i].classList.add("inchis");
										}
									}}
									onSearchbarDisable={() => {
										let listaButoane = document.getElementsByClassName(
											"butoaneContextDatagrid"
										);
										for (let i = 0; i < listaButoane.length; i++) {
											if (listaButoane[i].classList.contains("inchis"))
												listaButoane[i].classList.remove("inchis");
										}
									}}
									borderColor={"transparent"}
									onInput={(e) => {
										var value = e.target.value
											.toString()
											.toLowerCase()
											.replace(/\s/g, "");
										var baza = [...this.state.renderItems];
										var bazaFull = [...this.state.bigDataSource];
										var bazaSearch = this.props.child ? baza : bazaFull;
										if (
											this.state.toSearch.length > 0 &&
											value.length > this.state.toSearch.length &&
											value.indexOf(this.state.toSearch) === 0
										) {
											bazaSearch = this.state.renderItems;
										}
										this.search(value, bazaSearch);
									}}
									style={{
										height: 34,
										width: 300,
										right: 0,
										left: "auto",
										margin: 0,
										filter: "drop-shadow(2px 0px 14px gray)",
									}}
								/>
							) : null}

							<Segmented
								style={{
									width: "fit-content",
								}}
								tag="div"
							>
								{this.props.renderSearch === undefined ||
									(this.props.renderSearch !== undefined &&
										this.props.renderSearch !== false) ? (
									<Button
										disabled={
											this.props.dataLoaded !== undefined &&
											!this.props.dataLoaded
										}
										tooltip={"Cautare"}
										active={false}
										searchbarEnable={".searchBar" + this.tableName}
										small
										className={"datagridBtnTop"}
									>
										<Icon
											className={"datagridIcon"}
											size={27}
											material="search"
										/>
									</Button>
								) : null}
								{this.props.renderAdd ? (
									<Button
										disabled={
											this.props.dataLoaded !== undefined &&
											!this.props.dataLoaded
										}
										active={false}
										tooltip={"Adaugare"}
										small
										className={"datagridBtnTop"}
										onClick={() => {
											if (
												this.state.refId !== undefined &&
												this.id !== "" &&
												this.state.initialRefId !== undefined
											) {
												this.openAdd(false, this.state.initialRefId);
											} else {
												this.openAdd();
											}
										}}
									>
										<Icon className={"datagridIcon"} size={27} material="add" />
									</Button>
								) : null}
								{this.props.renderExcel === undefined ||
									(this.props.renderExcel !== undefined &&
										this.props.renderExcel !== false) ? (
									<Button
										disabled={
											this.props.dataLoaded !== undefined &&
											!this.props.dataLoaded
										}
										active={false}
										tooltip={"Export"}
										small
										onClick={this.parinteMain.openExportExcel.bind(this)}
										className={"datagridBtnTop"}
									>
										<Icon
											className={"datagridIcon"}
											size={27}
											material="save"
										/>
									</Button>
								) : null}
								{this.props.renderFilter === undefined ||
									(this.props.renderFilter !== undefined &&
										this.props.renderFilter !== false) ? (
									<Button
										disabled={
											this.props.dataLoaded !== undefined &&
											!this.props.dataLoaded
										}
										tooltip={"Filtrare"}
										small
										onClick={this.parinteMain.openFiltrare.bind(this, "right")}
										className={"datagridBtnTop"}
									>
										<Icon
											style={{
												color:
													JSON.stringify(this.state.filtreInitiale) !==
														JSON.stringify(this.state.filtre)
														? "red"
														: "var(--f7-theme-color)",
											}}
											className={"datagridIcon"}
											size={27}
											material="filter_list"
										/>
									</Button>
								) : null}
								{this.props.renderFilterColumns === undefined ||
									(this.props.renderFilterColumns !== undefined &&
										this.props.renderFilterColumns !== false) ? (
									<Button
										disabled={
											this.props.dataLoaded !== undefined &&
											!this.props.dataLoaded
										}
										tooltip={"Filtrare coloane"}
										small
										onClick={this.parinteMain.openFiltrareColoane.bind(this)}
										className={"datagridBtnTop"}
									>
										<Icon
											style={{
												color:
													JSON.stringify(
														this.state.initialKeys.filter((r) => {
															if (this.id !== "") {
																return r !== this.id;
															} else if (this.state.refId !== undefined) {
																return r !== this.state.refId;
															} else {
																return true;
															}
														})
													) !==
														JSON.stringify(
															this.state.keys.filter((r) => {
																if (this.id !== "") {
																	return r !== this.id;
																} else if (this.state.refId !== undefined) {
																	return r !== this.state.refId;
																} else {
																	return true;
																}
															})
														)
														? "red"
														: "var(--f7-theme-color)",
											}}
											className={"datagridIcon"}
											size={27}
											material="view_column"
										/>
									</Button>
								) : null}
							</Segmented>
						</div>
					</CardHeader>
				) : null}
				{(this.state.editing || this.state.adding) && checkFalse ? null : (
					<div
						ref={this.reful}
						id={"container" + this.tableName}
						className="data-table data-table-init"
						style={{
							display: "grid",
						}}
					>
						<table
							className={
								this.props.parinteMain !== undefined ? "isDatagridChild " : ""
							}
							id={this.tableName}
						>
							<thead
								style={{
									visibility:
										this.props.child === undefined ? "visible" : "collapse",
								}}
							>
								{this.parents.length > 0 ? (
									<tr
										className="list-group-item"
										key={"header1" + this.tableName}
									>
										{this.props.renderAccordion ||
											this.props.renderCheckbox ||
											(!this.desktop &&
												(this.props.renderDelete ||
													this.props.renderUpdate ||
													this.props.renderMenu)) ||
											(this.desktop &&
												this.props.renderOptions &&
												(this.props.renderDelete ||
													this.props.renderUpdate ||
													this.props.renderMenu)) ? (
											<th key="thParentOptions" className="label-cell" />
										) : null}
										{this.parents.map((parent, index) => (
											<th
												colSpan={parent.childs ? parent.childs.toString() : "1"}
												key={"thParent" + this.tableName + index}
												id={"thParent" + parent.denumire + index}
												className="label-cell"
												style={{
													width: "fit-content",
													textAlign: "center",
													borderRight: parent.childs
														? "1px #7b7b7b5c solid"
														: null,
													borderLeft: parent.childs
														? "1px #7b7b7b5c solid"
														: null,
												}}
											>
												{parent.denumire}
											</th>
										))}
									</tr>
								) : null}
								<tr
									className="list-group-item"
									key={"header2" + this.tableName}
								>
									{this.props.renderAccordion ||
										this.props.renderCheckbox ||
										(!this.desktop &&
											(this.props.renderDelete ||
												this.props.renderUpdate ||
												this.props.renderMenu)) ||
										(this.desktop &&
											this.props.renderOptions &&
											(this.props.renderDelete ||
												this.props.renderUpdate ||
												this.props.renderMenu)) ? (
										<th
											key={"th1" + this.name + "C"}
											id={"th1" + this.name + "C"}
											className="datagridOptionsTh"
											style={{
												width: 24 * this.numarOptiuni,
												height: this.inaltimeRand,
											}}
										/>
									) : null}

									{this.state.keys
										.filter((r) => {
											var verifInitial = true;
											if (this.props.ignoreInputs) {
												verifInitial = !this.props.ignoreInputs.includes(r);
											}
											var verifIntermediar = true;

											if (this.id !== "") {
												if (r === this.id) {
													verifIntermediar = false;
												}
											}
											var verifFinal = verifInitial && verifIntermediar;
											return verifFinal;
										})
										.map((denumire, index) => (
											<th
												style={{ width: "auto" }}
												key={"th1" + this.name + index}
												id={"th1" + this.name + index}
												className={
													"numeric-cell sortable-cell datagridTh datagridTh" +
													(this.parinteMain.name + denumire)
												}
												onClick={(e) => {
													if (this.state.callSort) {
														var cT = e.currentTarget;
														cT.classList.remove("sortable-desc");
														cT.classList.remove("sortable-asc");
														cT.classList.remove("sortable-neutral");
														var sortOptions = [...this.state.sortingOptions];
														var existaSortCurent = sortOptions.find((r) => {
															return r.key === denumire;
														});
														if (sortOptions.length === 1 && existaSortCurent) {
															var val =
																existaSortCurent.value === 1
																	? -1
																	: existaSortCurent.value === -1
																		? 0
																		: 1;

															sortOptions = [{ key: denumire, value: val }];
														} else {
															sortOptions = [{ key: denumire, value: 1 }];
														}
														this.setState(
															{ sortingOptions: sortOptions },
															() => {
																var rendItems = this.state.renderItems;
																var bigDS = this.state.bigDataSource;
																if (sortOptions[0].value !== 0) {
																	rendItems = this.state.renderItems.sort(
																		this.dynamicSortNew()
																	);
																	bigDS = this.state.bigDataSource.sort(
																		this.dynamicSortNew()
																	);
																} else {
																	var e = JSON.stringify(this.iDS);
																	var e2 = JSON.stringify(
																		this.initialDataSource
																	);
																	var f = JSON.parse(e);
																	var f2 = JSON.parse(e2);
																	rendItems = f;
																	bigDS = f2;
																}

																switch (sortOptions[0].value) {
																	case 1: {
																		cT.classList.add("sortable-asc");
																		break;
																	}
																	case -1: {
																		cT.classList.add("sortable-desc");
																		break;
																	}
																	default: {
																		cT.classList.add("sortable-neutral");
																		break;
																	}
																}

																this.setState(
																	{
																		renderItems: rendItems,
																		bigDataSource: bigDS,
																	},
																	() => {
																		if (this.state.rowData && this.id) {
																			this.focusItemInContext(
																				this.state.rowData,
																				this.id,
																				this
																			);
																		}
																	}
																);
															}
														);
													}
												}}
											>
												<div className="table-head-label">{denumire}</div>

												{this.props.renderHeaderSearch ? (
													<div
														className="input input-cell"
														onClick={() => {
															this.setState({ callSort: false });
														}}
													>
														<input
															onBlur={() => {
																this.setState({ callSort: true });
															}}
															onInput={(e) => {
																var value = e.target.value
																	.toString()
																	.toLowerCase()
																	.replace(/\s/g, "");
																var baza = [...this.state.renderItems];
																var bazaFull = [...this.state.bigDataSource];
																var bazaSearch = this.props.child
																	? baza
																	: bazaFull;
																var bazaSearchFinal =
																	this.returnFilteredDatasource(bazaSearch);

																this.search(value, bazaSearchFinal);
															}}
															type="text"
															placeholder="Search"
														/>{" "}
													</div>
												) : null}
											</th>
										))}
								</tr>
							</thead>
							{this.state.renderItems.length === 0 ? (
								<tbody id={this.denumireLista}>
									<tr onContextMenu={this.onContextMenuTREmpty.bind(this)}>
										<td
											style={{ textAlign: "center", fontWeight: "bold" }}
											colSpan={
												this.state.keys.filter((r) => {
													return (
														r !== this.id &&
														(this.props.ignoreInputs === undefined
															? true
															: !this.props.ignoreInputs.includes(r))
													);
												}).length + (this.numarOptiuni > 0 ? 1 : 0)
											}
										>
											I got nothing to render.
										</td>
									</tr>
								</tbody>
							) : (
								<tbody id={this.denumireLista}>
									{this.state.renderItems
										.slice(
											this.state.numberBefore,
											this.state.numberBefore + this.state.count
										)
										.map((rowItemData, index) => {
											let bgRowColor = "";
											if (this.props.coloredRow !== undefined) {
												let rowDataKeys = Object.keys(rowItemData);
												rowDataKeys.map((rowItemKey, rowIndexKey) => {
													rowDataKeys[rowIndexKey] = rowItemKey.toLowerCase();
													return null;
												});

												if (
													rowDataKeys.includes(
														this.props.coloredRow.sourceCol.toLowerCase()
													)
												) {
													if (
														rowItemData[this.props.coloredRow.sourceCol] !==
														undefined &&
														rowItemData[this.props.coloredRow.sourceCol] !==
														null &&
														rowItemData[this.props.coloredRow.sourceCol] !==
														"Fara"
													) {
														if (this.props.coloredRow.type === "value") {
															this.props.coloredRow.rules.map((r, rI) => {
																if (
																	r.includes(
																		rowItemData[this.props.coloredRow.sourceCol]
																	)
																) {
																	bgRowColor = this.props.coloredRow.colors[rI];
																}
																return null;
															});
														} else if (this.props.coloredRow.type === "date") {
															let currentDate = moment();
															let tableDate = moment(
																rowItemData[this.props.coloredRow.sourceCol]
															);

															if (currentDate < tableDate) {
																if (
																	currentDate >=
																	tableDate.clone().subtract(2, "w")
																) {
																	bgRowColor = "rgba(201, 71, 38, 0.5)";
																} else if (
																	currentDate >=
																	tableDate.clone().subtract(1, "months")
																) {
																	bgRowColor = "rgba(235, 145, 122, 0.5)";
																} else if (
																	currentDate >=
																	tableDate
																		.clone()
																		.subtract(1, "months")
																		.subtract(2, "w")
																) {
																	bgRowColor = "rgba(255, 190, 168, 0.5)";
																} else if (
																	currentDate >=
																	tableDate.clone().subtract(2, "months")
																) {
																	bgRowColor = "rgba(255, 209, 168, 0.5)";
																} else if (
																	currentDate >=
																	tableDate.clone().subtract(3, "months")
																) {
																	bgRowColor = "rgba(255, 232, 168, 0.5)";
																} else if (
																	currentDate >=
																	tableDate.clone().subtract(4, "months")
																) {
																	bgRowColor = "rgba(233, 255, 168, 0.5)";
																} else {
																	bgRowColor = "";
																}
															} else {
																bgRowColor = "rgba(201, 71, 38, 0.5)";
															}
														}
													}
												}
											}

											return (
												<React.Fragment
													key={"Fragment" + this.tableName + index}
												>
													<tr
														className={"datagridTr "}
														style={{
															backgroundColor: bgRowColor,
														}}
														onContextMenu={this.onContextMenuTR.bind(
															this,
															rowItemData,
															index
														)}
														id={"trMain" + this.tableName + index}
														key={"trMain" + this.tableName + index}
														onClick={this.onRowClick.bind(
															this,
															rowItemData,
															index
														)}
													>
														{this.props.renderAccordion ||
															this.props.renderCheckbox ||
															(!this.desktop &&
																(this.props.renderDelete ||
																	this.props.renderUpdate ||
																	this.props.renderMenu)) ||
															(this.desktop &&
																this.props.renderOptions &&
																(this.props.renderDelete ||
																	this.props.renderUpdate ||
																	this.props.renderMenu)) ? (
															<td
																className="datagridOptionsTd"
																style={{ width: 24 * this.numarOptiuni }}
															>
																<Segmented
																	style={{
																		width: "fit-content",
																	}}
																	tag="div"
																>
																	{this.props.renderMenu ? (
																		<Button
																			small
																			className={"datagridBtnOptions"}
																			onClick={() => {
																				this.setState(
																					{
																						rowData: rowItemData,
																						rowIndex: index,
																					},
																					() => {
																						if (this.props.onMenuClick) {
																							this.props.onMenuClick(
																								this.state
																							);
																						}
																					}
																				);
																			}}
																		>
																			<Icon
																				className={"datagridIcon"}
																				size={20}
																				material="menu"
																			/>
																		</Button>
																	) : null}

																	{this.props.renderCheckbox ? (
																		<Checkbox
																			indeterminate={
																				this.state.allSelectedRows.length > 0
																					? this.id !== "" &&
																						this.state.refId !== undefined
																						? this.state.allSelectedRows.find(
																							(item, index) => {
																								return (
																									JSON.stringify(item) ===
																									JSON.stringify(
																										rowItemData
																									) ||
																									(this.id !== "" &&
																										item[this.id] ===
																										rowItemData[this.id])
																								);
																							}
																						) === undefined &&
																						this.state.allSelectedRows.find(
																							(r) => {
																								return (
																									this.recursivaCheckbox(
																										this.state.bigDataSource.filter(
																											(x) => {
																												return (
																													x[
																													this.props.refId
																													] !==
																													rowItemData[
																													this.props.refId
																													]
																												);
																											}
																										),
																										rowItemData[this.id],
																										[]
																									).find((g) => {
																										return (
																											g[this.id] ===
																											r[this.id]
																										);
																									}) !== undefined
																								);
																							}
																						) !== undefined
																						: false
																					: false
																			}
																			checked={
																				this.state.allSelectedRows.length > 0
																					? this.state.allSelectedRows.find(
																						(item, index) => {
																							return (
																								JSON.stringify(item) ===
																								JSON.stringify(
																									rowItemData
																								) ||
																								(this.id !== "" &&
																									item[this.id] ===
																									rowItemData[this.id])
																							);
																						}
																					) !== undefined
																					: false
																			}
																			disabled={
																				this.props.dataLoaded !== undefined &&
																				!this.props.dataLoaded
																			}
																			onChange={this.onChangeCheckbox.bind(
																				this,
																				rowItemData
																			)}
																		/>
																	) : null}
																	{this.props.renderAccordion !== undefined ||
																		this.props.refId !== undefined ? (
																		this.state.accordionToOpen.includes(
																			"accordion" + rowItemData[this.id]
																		) ? (
																			this.state.bigDataSource.find((x) => {
																				return (
																					x[this.props.refId] ===
																					rowItemData[this.id]
																				);
																			}) !== undefined ||
																				this.props.accordionItem !== undefined ? (
																				<Button
																					disabled={
																						this.props.dataLoaded !==
																						undefined &&
																						!this.props.dataLoaded
																					}
																					className={"datagridBtnOptions"}
																					onClick={this.openCloseAccordion.bind(
																						this,
																						rowItemData[this.id]
																					)}
																					small
																				>
																					<Icon
																						className={"datagridIcon"}
																						size={20}
																						material="keyboard_arrow_down"
																					/>
																				</Button>
																			) : (
																				<div style={{ width: 24 }} />
																			)
																		) : this.state.bigDataSource.find((x) => {
																			return (
																				x[this.props.refId] ===
																				rowItemData[this.id]
																			);
																		}) !== undefined ||
																			this.props.accordionItem !== undefined ? (
																			<Button
																				disabled={
																					this.props.dataLoaded !== undefined &&
																					!this.props.dataLoaded
																				}
																				className={"datagridBtnOptions"}
																				onClick={this.openCloseAccordion.bind(
																					this,
																					rowItemData[this.id]
																				)}
																				small
																			>
																				<Icon
																					className={"datagridIcon"}
																					size={20}
																					material="keyboard_arrow_right"
																				/>
																			</Button>
																		) : (
																			<div style={{ width: 24 }} />
																		)
																	) : null}

																	{(this.props.renderUpdate && !this.desktop) ||
																		(this.props.renderUpdate &&
																			this.props.renderOptions) ? (
																		<Button
																			small
																			className={"datagridBtnOptions"}
																			disabled={
																				this.props.dataLoaded !== undefined &&
																				!this.props.dataLoaded
																			}
																			tooltip={"Editare"}
																			onClick={this.openUpdate.bind(
																				this,
																				rowItemData,
																				null
																			)}
																		>
																			<Icon
																				className={"datagridIcon"}
																				size={20}
																				material="create"
																			/>
																		</Button>
																	) : null}

																	{(this.props.renderDelete && !this.desktop) ||
																		(this.props.renderDelete &&
																			this.props.renderOptions) ? (
																		<Button
																			small
																			className={"datagridBtnOptions"}
																			disabled={
																				this.props.dataLoaded !== undefined &&
																				!this.props.dataLoaded
																			}
																			tooltip={"Stergere"}
																			onClick={this.openDelete.bind(
																				this,
																				rowItemData
																			)}
																		>
																			<Icon
																				className={"datagridIcon"}
																				size={20}
																				material="delete"
																			/>
																		</Button>
																	) : null}
																</Segmented>
															</td>
														) : null}

														{this.state.initialKeys
															.filter((keysFilter, index) => {
																var verifIntermediara = true;
																if (this.id !== "") {
																	if (keysFilter === this.id) {
																		verifIntermediara = false;
																	}
																}

																var verifFinal =
																	this.state.keys.includes(keysFilter);
																if (this.props.ignoreInputs) {
																	verifFinal =
																		this.state.keys.includes(keysFilter) &&
																		!this.props.ignoreInputs.includes(
																			keysFilter
																		);
																}

																var verif = verifFinal && verifIntermediara;

																return verif;
															})
															.map((valueData, valueDataIndex) =>
																this.props.inputs.length > 0 ? (
																	this.props.inputs.find((inputul) => {
																		return inputul.key === valueData;
																	}) !== undefined ? (
																		<td
																			onContextMenu={this.onContextMenuTD.bind(
																				this,
																				rowItemData,
																				valueData,
																				valueDataIndex
																			)}
																			style={{
																				textIndent:
																					valueDataIndex === 0
																						? this.spatiiIndentare + "px"
																						: 0,
																			}}
																			onMouseEnter={this.onMouseEnterTD.bind(
																				this,
																				rowItemData
																			)}
																			onMouseLeave={this.onMouseLeaveTD.bind(
																				this
																			)}
																			key={
																				"td" +
																				this.tableName +
																				valueDataIndex +
																				index
																			}
																			onClick={this.onCellClick.bind(
																				this,
																				rowItemData,
																				valueData,
																				valueDataIndex
																			)}
																			className={"datagridTd " + valueData}
																		>
																			{this.props.inputs.find((inputul) => {
																				return inputul.key === valueData;
																			}) !== undefined ? (
																				this.props.inputs.find((inputul) => {
																					return inputul.key === valueData;
																				}).tip === "colorpicker" ? (
																					this.state.renderColor ? (
																						<div
																							style={{
																								borderRadius: "50%",
																								height: 15,
																								width: 15,
																								backgroundColor:
																									rowItemData[valueData],
																								float: "left",
																								marginRight: 7,
																								marginTop: 3,
																							}}
																						/>
																					) : null
																				) : null
																			) : null}

																			{[null, true, false].includes(
																				rowItemData[valueData]
																			) ? (
																				(this.props.inputs.find((inputul) => {
																					return inputul.key === valueData;
																				}) !== null &&
																					this.props.inputs.find((inputul) => {
																						return inputul.key === valueData;
																					}).tip === "bool") ||
																					[true, false].includes(
																						rowItemData[valueData]
																					) ? (
																					<Checkbox
																						disabled={
																							(this.props.dataLoaded !==
																								undefined &&
																								!this.props.dataLoaded) ||
																							(this.props.inputs.find((r) => {
																								return r.key === valueData;
																							}) !== null &&
																								this.props.inputs.find((r) => {
																									return r.key === valueData;
																								}).disabled === true)
																						}
																						onChange={this.onChangeCheckboxItem.bind(
																							this,
																							rowItemData,
																							valueData
																						)}
																						checked={rowItemData[valueData]}
																					/>
																				) : (
																					this.listaBool[0].denumire
																				)
																			) : this.props.inputs.find((inputul) => {
																				return (
																					inputul.key === valueData &&
																					inputul.lista
																				);
																			}) !== undefined ? (
																				this.props.inputs
																					.find((inputul) => {
																						return (
																							inputul.key === valueData &&
																							inputul.lista
																						);
																					})
																					.lista.find((elemLista) => {
																						return (
																							elemLista.value ===
																							rowItemData[valueData]
																						);
																					}) !== undefined ? (
																					this.props.inputs
																						.find((inputul) => {
																							return (
																								inputul.key === valueData &&
																								inputul.lista
																							);
																						})
																						.lista.find((elemLista) => {
																							return (
																								elemLista.value ===
																								rowItemData[valueData]
																							);
																						}).key
																				) : null
																			) : typeof rowItemData[valueData] ===
																				"object" ? (
																				"File"
																			) : this.props.inputs.find((inputul) => {
																				return (
																					inputul.key === valueData &&
																					inputul.tip === "datebox"
																				);
																			}) !== undefined ? (
																				moment(rowItemData[valueData]).format(
																					"'YY-MM-DD HH:mm"
																				)
																			) : (
																				rowItemData[valueData]
																			)}
																		</td>
																	) : null
																) : (
																	<td
																		className="label-cell"
																		style={{
																			width: "fit-content",
																			textAlign: "center",
																		}}
																	>
																		{[true, false].includes(
																			rowItemData[valueData]
																		)
																			? rowItemData[valueData].toString()
																			: rowItemData[valueData]}
																	</td>
																)
															)}
													</tr>
													{this.props.renderAccordion &&
														this.id !== "" &&
														this.state.accordionToOpen.includes(
															"accordion" + rowItemData[this.id]
														) ? (
														<tr
															className="hiddenRow"
															key={"trAccordion" + this.tableName + index}
														>
															<td
																colSpan={
																	this.state.keys.filter((r) => {
																		return (
																			r !== this.id &&
																			(this.props.ignoreInputs === undefined
																				? true
																				: !this.props.ignoreInputs.includes(r))
																		);
																	}).length + (this.numarOptiuni > 0 ? 1 : 0)
																}
															>
																{(this.state.refId &&
																	this.state.accordionItem === undefined) ||
																	(this.state.refId &&
																		this.state.accordionItem !== undefined &&
																		this.state.accordionItem(
																			this.state,
																			rowItemData
																		) === undefined) ? (
																	<DataGrid
																		ref={this.datagridRef}
																		{...this.props}
																		parinteMain={this.parinteMain}
																		bigParentRefValue={
																			this.props.bigParentRefValue
																		}
																		childNumber={
																			this.props.childNumber !== undefined
																				? this.props.childNumber + 1
																				: 1
																		}
																		child={true}
																		bigDataSource={this.state.bigDataSource}
																		initialRefId={rowItemData[this.id]}
																		parinte={this}
																		allSelectedRows={this.state.allSelectedRows}
																		rowDataAll={this.state.rowDataAll}
																		filtre={this.state.filtre}
																		filtreInitiale={this.state.filtreInitiale}
																		keys={this.state.keys}
																		initialKeys={this.state.initialKeys}
																		maxDepth={this.state.maxDepth}
																		accordionToOpen={this.state.accordionToOpen}
																		accordionName={
																			"trAccordion" + this.tableName + index
																		}
																		listaDatagriduri={
																			this.state.listaDatagriduri
																		}
																		sortingOptions={this.state.sortingOptions}
																	/>
																) : this.props.renderAccordion &&
																	this.state.accordionItem !== undefined &&
																	this.state.accordionItem(
																		this.state,
																		rowItemData
																	) !== undefined ? (
																	this.state.accordionItem(
																		this.state,
																		rowItemData
																	)
																) : null}
															</td>
														</tr>
													) : null}
												</React.Fragment>
											);
										})}
								</tbody>
							)}
						</table>
					</div>
				)}

				{((this.props.parinteMain === undefined && this.state.maxDepth > 0) ||
					Math.ceil(this.state.renderItems.length / this.state.count) > 1) &&
					(this.props.paging === undefined || this.props.paging !== false) &&
					this.props.renderFooter !== false ? (
					<div
						style={{
							height: 34,
							alignItems: "center",
							transform: "translateX(0px)",
							position: "sticky",
							left: 0,
							bottom: 0,
							width: "100%",
							float: "left!important",
							backgroundColor: "var(--f7-page-bg-color)",
							zIndex: 0,
							paddingBottom: "6px",
						}}
						className={"SegmentedBottomPosition"}
					>
						<div style={{ display: "none" }}>
							Per page:
							<div
								className="input input-dropdown"
								style={{ display: "inline-block", marginLeft: "15px" }}
							>
								<select
									onChange={(e) => {
										var valoare = e.target.value;
										if (valoare !== "all") {
											if (valoare !== "Auto") {
												valoare = parseInt(valoare);
												this.setState({
													count: valoare,
													lastCount: valoare,
													numberBefore: 0,
												});
											} else {
												valoare = parseInt(heightTable / 38) - 1;
												this.setState({
													count: valoare,
													lastCount: valoare,
													numberBefore: 0,
												});
											}
										} else {
											this.setState({
												count: 10000000,
												lastCount: 10000000,
											});
										}
									}}
									defaultValue={
										this.state.count === 10000000
											? "All"
											: this.state.count === parseInt(heightTable / 38) - 1
												? "Auto"
												: this.state.count
									}
								>
									<option key={this.tableName + "-count-auto"} value="Auto">
										Auto
									</option>
									<option key={this.tableName + "-count-5"} value="5">
										5
									</option>
									<option key={this.tableName + "-count-10"} value="10">
										10
									</option>
									<option key={this.tableName + "-count-25"} value="25">
										25
									</option>
									<option key={this.tableName + "-count-all"} value="all">
										All
									</option>
								</select>
							</div>
						</div>

						<div
							className="stepper-wrapper"
							style={{
								marginTop: "0px",
								marginBottom: "0px",
								marginRight: "0px",
								marginLeft: "auto",
								position: "relative",
								height: 28,
							}}
						>
							<small
								className="display-block"
								style={{
									textIndent: "35px",
									textAlign: "left",
									fontSize: "9px",
								}}
							>
								{this.state.numberBefore / this.state.count + 1} /{" "}
								{Math.ceil(this.state.renderItems.length / this.state.count) >=
									1
									? Math.ceil(this.state.renderItems.length / this.state.count)
									: 1}
							</small>
							<Stepper
								disabled={
									this.props.dataLoaded !== undefined && !this.props.dataLoaded
								}
								style={{ height: 18 }}
								onStepperPlusClick={this.onStepperPlusClick.bind(this)}
								onStepperMinusClick={this.onStepperMinusClick.bind(this)}
								onInput={this.onStepperInput.bind(this)}
								small
								value={this.state.numberBefore / this.state.count + 1}
								min={1}
								max={1000000}
								manualInputMode={true}
							/>
						</div>
					</div>
				) : null}
			</div>
		);
	}
}
export default DataGrid2;