import React from "react";
import axios from "axios";
import DataGrid from "../../pages/Datagrid/DataGrid";

/**
 * @description Datagrid ce randeaza toate taskurile deja alocate utilizatorului selectat din iterfata de alocare task
 * @date 06/04/2022
 * @class ATSalariatiGridAlocate
 * @extends {React.Component}
 */
class ATSalariatiGridAlocate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idProiectVechi: null,
      dataLoaded: false,
      denumire: "Sarcini alocate",
      height: window.innerHeight - 96,
      linkApi: "/api/SarciniSalariat",
      linkApiRealizari: "/api/RealizareSarcini",
      linkApiGetProiect: "/api/Proiecte/GetPROIECTE",
      linkApiUpdateProiect: "/api/Proiecte/PutPROIECTE",
      dataSource: [],
      dataSourceHidden: [],

      inputs: [
        { key: "IdSarcina", keyBaza: "idSarcina" },
        { key: "IdProiect", keyBaza: "idProiect" },
        { key: "Denumire", keyBaza: "denumire" },
        { key: "Parinte", keyBaza: "parinte" },
        { key: "DataAlocarii", keyBaza: "dataAlocarii", tip: "datebox" },
        {
          key: "IdPrioritate",
          keyBaza: "IdPrioritate",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
        },
        { key: "Comentariu", keyBaza: "Comentariu" },
        { key: "DeCine", keyBaza: "deCine" },
        { key: "Deadline", keyBaza: "deadline", tip: "datebox" },
      ],
      sfarsit: false,
      idSelected: null,
      idSarcina: null,
      idPropunere: null,
      deschideNou: false,
    };
    this.refDataGrid = React.createRef();
  }
  /**
   * @description Ia valorile necesare randarii interfetei de la endpointul /api/SarciniSalariat/GetSARCINI_SALARIATAlocate/ folosind id-ul salariatului din props
   * @function
   * @date 06/04/2022
   * @memberof ATSalariatiGridAlocate
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetSARCINI_SALARIATAlocate/" +
          this.props.idSalariat +
          "/1/0/0"
      )
      .then((response) => {
        var date = response;

        date.data.map((sarcina) => {
          sarcina = this.$f7.methods.restructurareBaza(
            sarcina,
            this.state.inputs,
            "key"
          );
          return null;
        });

        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  this.refDataGrid.current.setRenderItems(baza);
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.refDataGrid.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  render() {
    return this.state.dataLoaded ? (
      <DataGrid
        name={"ATSalariatiGridAlocate"}
        ref={this.refDataGrid}
        id={"IdSarcina"}
        keys={[
          "Denumire",
          "Parinte",
          "DataAlocarii",
          "IdPrioritate",
          "Comentariu",
          "DeCine",
        ]}
        ignoreInputsAdd={["Deadline"]}
        ignoreInputsEdit={["Deadline"]}
        renderUpdate={false}
        renderDelete={false}
        renderAdd={false}
        maxHeight={this.state.height}
        inputs={this.state.inputs}
        dataSource={this.state.dataSource}
        coloredRow={{
          sourceCol: "Deadline",
          type: "date",
        }}
      />
    ) : (
      <div
        style={{
          margin: "0 auto",
        }}>
        <h2>Selectati din lista un salariat.</h2>
      </div>
    );
  }
}
export default ATSalariatiGridAlocate;