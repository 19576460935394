import React from "react";
import { View, Toolbar, Link } from "framework7-react";

/**
 * @description Randeaza un toolbar de unde se selecteaza ce tip de grafic sa fie afisat legat de alocariletaskului si graficul sub acesta
 * @date 06/04/2022
 * @class ATGrafice
 * @extends {React.Component}
 */
class ATGrafice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            denumire: "ATGrafice",
            tabLinkActive: 1
        };
        this.refDataGrid = React.createRef();
    }

    render() {
        return (
            <>
                <Toolbar
                    style={{ backgroundColor: "var(--f7-theme-color)", zIndex: 1000000 }}
                    tabbar
                    noShadow
                    top>
                    <Link tooltip={"Grafic 1"} tabLinkActive={this.state.tabLinkActive === 1} onClick={() => { this.setState({ tabLinkActive: 1 }) }} href="/AlocareTaskuri/Grafice1/" >1</Link>
                    <Link tooltip={"Grafic 2"} tabLinkActive={this.state.tabLinkActive === 2} onClick={() => { this.setState({ tabLinkActive: 2 }) }} href="/AlocareTaskuri/Grafice2/" >2</Link>
                </Toolbar>
                <View
                    removeElements={true}
                    componentCache={false}
                    id="AlocareTaskuriGrafice"
                    style={{ minHeight: "50vh" }}
                    name="AlocareTaskuriGrafice"
                    onViewInit={(e) => {
                        this.$f7.data.routerAlocareTaskuriGrafice = e.router;
                    }}
                    url={"/AlocareTaskuri/Grafice1/"}
                ></View>
            </>
        )
    }
}
export default ATGrafice;