/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
	Page,
	View,
	Panel,
	Button,
	Segmented,
	Card,
	CardContent,
	Icon,
	Treeview,
	Navbar,
	TreeviewItem,
} from "framework7-react";
import axios from "axios";

/**
 * @description Randeaza meniul din stanga
 * @date 07/04/2022
 * @class SideNav
 * @extends {Component}
 */
class SideNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nivelAccess: this.$f7.data.rol !== null ? this.$f7.data.rol : 5,
      inputs: [
        { key: "Id", keyBaza: "id" },
        { key: "Denumire", keyBaza: "denumire" },
        { key: "Icon", keyBaza: "icon" },
        {
          key: "Parinte",
          keyBaza: "idParinte",
          tip: "selectbox",
          lista: this.$f7.data.Meniuri,
        },
        { key: "AdresaURL", keyBaza: "adresaURL" },
        { key: "NivelAccess", keyBaza: "nivelAccess" },
        { key: "Ordine", keyBaza: "ordine", tip: "number" },
      ],
    };

    if (this.$f7.data.MeniuriRandare[0].Parinte !== undefined) {
      var d = [...this.$f7.data.MeniuriRandare];
      d.map((eveniment) => {
        eveniment = this.$f7.methods.restructurareBaza(
          eveniment,
          this.state.inputs,
          "keyBaza"
        );
        return null;
      });
      this.$f7.data.MeniuriRandare = d;
    }
  }

  /**
   * @description Cauta care este elementul root al elementului curent(elementul parinte);
   * @function
   * @date 07/04/2022
   * @param {*} currentItem
   * @memberof SideNav
   */
  findRootURL = (currentItem) => {
    if (currentItem.idParinte !== 1) {
      var parinte = this.$f7.data.MeniuriRandare.filter((m) => {
        return m.id === currentItem.idParinte;
      })[0];

      if (parinte.idParinte === 1) {
        return parinte;
      } else {
        return this.findRootURL(parinte);
      }
    } else {
      return currentItem;
    }
  };

  /**
   * @description Transforma o valoare rgb in valoare hex pentru a putea seta o noua culoare elementului din meniu
   * @function
   * @date 07/04/2022
   * @param {*} rgba
   * @memberof SideNav
   */
  rgba2hex = (rgba) => {
    function trim(str) {
      return str.replace(/^\s+|\s+$/gm, "");
    }

    var inParts = rgba.substring(rgba.indexOf("(")).split(","),
      r = parseInt(trim(inParts[0].substring(1)), 10),
      g = parseInt(trim(inParts[1]), 10),
      b = parseInt(trim(inParts[2]), 10),
      a = parseFloat(
        trim(inParts[3].substring(0, inParts[3].length - 1))
      ).toFixed(2);
    var outParts = [
      r.toString(16),
      g.toString(16),
      b.toString(16),
      Math.round(a * 255)
        .toString(16)
        .substring(0, 2),
    ];

    // Pad single-digit output values
    outParts.forEach(function (part, i) {
      if (part.length === 1) {
        outParts[i] = "0" + part;
      }
    });

    return "#" + outParts.join("");
  };

  /**
   * @description Schimba iconita din x in meniu cand se da click pe meniu
   * @function
   * @date 07/04/2022
   * @memberof SideNav
   */
  schimbareIconita = () => {
    this.$f7.data.iconita = "menu";
    if (this.$f7.data.paginaCurenta !== undefined) {
      this.$f7.data.paginaCurenta.setState({ iconita: "menu" });
    }
    if (this.$f7.data.homePage !== undefined) {
      this.$f7.data.homePage.setState({ iconita: "menu" });
    }
  };

  /**
   * @description Functia de randare a meniului, este recursiva
   * @date 07/04/2022
   * @function
   * @param {*} idParinte id-ul de referinta, filtreaza elementele de meniu de trebuie randate dupa acest id
   * @param {*} nivel nivelul la care s-a ajuns cu elementul
   * @param {*} culoare culoarea de trebuie setata elementelor
   * @memberof SideNav
   */
  renderMenu = (idParinte, nivel, culoare) => {
    var hex = culoare;
    if (nivel !== 0) {
      var colectieHex = this.$f7.utils.colorThemeCSSProperties(
        culoare.split("#")[1]
      );
      hex = colectieHex["--f7-theme-color-tint"];
    }
    return this.$f7.data.MeniuriRandare.filter((m) => {
      return m.idParinte === idParinte;
    }).map((x, index) => {
      let adresaURL = x.adresaURL;
      return this.$f7.data.MeniuriAccesibile.includes(x.id) ? (
        <TreeviewItem
          style={{
            backgroundColor: hex,
            color: "white",
          }}
          key={index + x.denumire}
          selectable
          iconMaterial={x.icon}
          iconColor="white"
          className="treeview-item-toggle">
          <span
            onClick={() => {
              if (adresaURL !== null) {
                this.schimbareIconita();
                var currentURL =
                  this.$f7router.history[this.$f7router.history.length - 2];
                var parinte = this.findRootURL(x);
                var urlParinte = parinte.adresaURL;
                var VN = this.$f7.router.routes.filter((r) => {
                  return r.path === adresaURL;
                })[0].viewName;
                var testVN = this.$f7.views[VN];

                if (VN === "MainDreapta") {
                  this.$f7.data.routeDreapta = adresaURL;
                }
                if (currentURL === urlParinte) {
                  if (!testVN) {
                    this.$f7router.navigate(urlParinte, { reloadAll: true });
                    this.$f7.views.main.on(
                      "routeChanged",
                      (newRoute, previousRoute, router) => {
                        if (newRoute.path === urlParinte) {
                          this.$f7.views.main.off("routeChanged");
                          this.$f7router.navigate(adresaURL, {
                            reloadAll: true,
                          });
                          this.$f7.panel.close("left");
                        }
                      }
                    );
                  } else {
                    this.$f7router.navigate(adresaURL, {
                      reloadAll: true,
                      ignoreCache: true,
                    });
                    this.$f7.panel.close("left");
                  }
                } else {
                  if (adresaURL !== urlParinte && urlParinte !== null) {
                    this.$f7router.navigate(urlParinte);
                    this.$f7.views.main.on(
                      "routeChanged",
                      (newRoute, previousRoute, router) => {
                        if (newRoute.path === urlParinte) {
                          this.$f7.views.main.off("routeChanged");
                          this.$f7router.navigate(adresaURL, {
                            reloadAll: true,
                          });
                          this.$f7.panel.close("left");
                        }
                      }
                    );
                  } else {
                    this.$f7router.navigate(adresaURL, { reloadAll: true });
                    this.$f7.panel.close("left");
                  }
                }
              }
            }}
            slot="label">
            {x.denumire}
          </span>
          {this.$f7.data.MeniuriRandare.filter((m) => {
            return m.idParinte === x.id;
          }).length > 0
            ? this.renderMenu(x.id, nivel + 1, hex)
            : null}
        </TreeviewItem>
      ) : null;
    });
  };

  render() {
    return (
      <Panel
        style={{ overflow: "hidden" }}
        left
        reveal
        backdrop={false}
        closeByBackdropClick={false}>
        <Page style={{ width: 260 }}>
          <View
            id="sidenav"
            name="sidenav"
            style={{ overflow: "hidden" }}
            removeElements={false}>
            <Page style={{ overflow: "hidden" }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  backgroundColor: "var(--f7-theme-color)",
                }}>
                <Navbar title={"Meniu"} sliding={false} />
                <div
                  style={{ maxHeight: "calc(100% - 94px)", overflowY: "auto" }}>
                  <Treeview id="treeViewMeniuStanga" className="treeViewStanga">
                    {this.renderMenu(
                      1,
                      0,
                      getComputedStyle(document.documentElement)
                        .getPropertyValue("--f7-theme-color")
                        .toString()
                    )}
                  </Treeview>
                </div>
                <Card
                  style={{
                    width: "calc(100% - 16px)",
                    position: "absolute",
                    bottom: "0px",
                    backgroundColor: "var(--f7-theme-color)",
                  }}>
                  <CardContent style={{ padding: 8 }}>
                    <Segmented tag="p">
                      <Button
                        tooltip="Info"
                        small
                        panelClose
                        onClick={() => {
                          this.$f7.data.iconita = "menu";
                          this.$f7.view.main.router.navigate("/about/");
                        }}>
                        <Icon
                          style={{ color: "white" }}
                          size={27}
                          material="info"
                        />
                      </Button>
                      <Button tooltip="Setari" small href="/settings/">
                        <Icon
                          style={{ color: "white" }}
                          size={27}
                          material="settings"
                        />
                      </Button>
                      <Button
                        tooltip="Delogare"
                        small
                        onClick={() => {
                          this.$f7.dialog.confirm(
                            "Doriti sa va delogati?",
                            "Delogare",
                            () => {
                              this.$f7.data.signalRConnection.stop();
                              this.$f7.methods
                                .logout()
                                .then((response) => {
                                  this.$f7.methods.setCookie("user", "", 90);
                                  this.$f7.methods.setCookie("parola", "", 90);
                                  this.$f7.methods.setCookie(
                                    "repornire",
                                    "",
                                    90
                                  );
                                  delete axios.defaults.headers.common[
                                    "Authorization"
                                  ];
                                  this.$f7.views.main.router.navigate(
                                    "/login/"
                                  );
                                  var data = {
                                    baseURL: "",
                                    iconita: "menu",
                                    user: undefined,
                                    datagrids: {},
                                    toggleTotiSalariatii: false,
                                    timerUltimaActivitate: 5,
                                    rol: null,
                                    rolAdevarat: null,
                                    dataSourcePlanificare: [],
                                  };
                                  this.$f7.data = data;
                                })
                                .catch((err) => {
                                  this.$f7.methods.openNotification(
                                    "Eroare delogare" + err
                                  );
                                });
                            }
                          );
                        }}>
                        <Icon
                          style={{ color: "white" }}
                          size={27}
                          material="cancel"
                        />
                      </Button>
                    </Segmented>
                  </CardContent>
                </Card>
              </div>
            </Page>
          </View>
        </Page>
      </Panel>
    );
  }
}
export default SideNav;
