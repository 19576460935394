import React from "react";
import TreeList from "devextreme-react/tree-list";
import axios from "axios";
import { Range } from "framework7-react";

/**
 * @description Interfata de salariati atasati la un meniu
 * @date 07/04/2022
 * @class SalariatiGridMeniu
 * @extends {React.Component}
 */
class SalariatiGridMeniu extends React.Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;
    this.state = {
      maxDepth: 0,
      idMeniu: null,
      dataLoaded: false,
      selectedRowKeys: [],
      componentaTree: null,
      selectedRowsData: [],
      linkApiSalariatiMeniu: "api/SalariatiMeniu",
      inputs: [
        {
          key: "Id",
          keyBaza: "idSalariat",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
        { key: "Denumire", keyBaza: "denumire" },
        { key: "Checked", keyBaza: "Checked", tip: "number" },
        {
          key: "Ref",
          keyBaza: "refSalariat",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
      ],
    };
  }

  /**
   * @description Ia toti salariatii atasati la meniul curent selectat
   * @date 07/04/2022
   * @function
   * @param {*} idMeniu id-ul meniului curent selectat
   * @memberof SalariatiGridMeniu
   */
  GetValues = (idMeniu) => {
    this.setState(
      {
        dataLoaded: false,
        idMeniu: idMeniu,
        selectedRowKeys: [],
        componentaTree: null,
      },
      () => {
        var url =
          this.state.linkApiSalariatiMeniu + "/GetSalariatiMeniuri/" + idMeniu;
        axios
          .get(url)
          .then((response) => {
            response.data.map((element) => {
              element = this.$f7.methods.restructurareBaza(
                element,
                this.state.inputs,
                "key"
              );
              return null;
            });

            var bazaSalariati = this.$f7.methods.reconstructieBaza(
              response,
              this.state.inputs
            );
            var allSelectedRows = bazaSalariati.filter((r) => {
              return r.Checked === 1;
            });
            var selectedRowKeys = [];

            allSelectedRows.map((r) => {
              selectedRowKeys.push(r.Id);
              return null;
            });
            var max = this.$f7.methods.maxDepth(
              null,
              bazaSalariati,
              "Id",
              "Ref"
            );

            this.setState({
              dataSource: bazaSalariati,
              dataLoaded: true,
              selectedRowKeys: selectedRowKeys,
              selectedRowsData: allSelectedRows,
              maxDepth: max,
            });
          })
          .catch((error) => {
            this.$f7.methods.openNotification("Error:", error);
          });
      }
    );
  };

  /**
   * @description Adauga in baza de date conexiune intre salariat si meniul curent selectat
   * @function
   * @date 07/04/2022
   * @param {*} data obiectul cu referita intre meniu si salariat
   * @memberof SalariatiGridMeniu
   */
  AddValueSalariatiMeniu = (data) => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL +
        this.state.linkApiSalariatiMeniu +
        "/PostSALARIATI_MENIU";
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Sterge din baza de date conexiunea dintre salariat si meniul curent selectat
   * @function
   * @date 07/04/2022
   * @param {*} id id-ul meniului curent selectat
   * @memberof SalariatiGridMeniu
   */
  DeleteValueSalariatiMeniu = (id) => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL +
        this.state.linkApiSalariatiMeniu +
        "/DeleteSALARIATI_MENIU/" +
        id;
      axios({
        headers: { "Content-Type": "application/json" },
        method: "delete",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Adauga sau sterge, dupa caz conexiunea dintre salariat si meniul curent selectat
   * @function
   * @date 07/04/2022
   * @memberof SalariatiGridMeniu
   */
  SaveValuesDevextreme = () => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiSalariatiMeniu +
          "/GetSalariatiMeniuriByMeniu/" +
          this.state.idMeniu
      )
      .then((response) => {
        var date = response.data;
        var arrAdd = [];
        var arrDelete = [];
        this.state.selectedRowsData.map((r) => {
          if (
            date.filter((f) => {
              return r.Id === f.id_salariat;
            }).length === 0
          ) {
            //ADAUGA IN BAZA ELEMENTUL obj!
            var obj = {
              id_salariat: r.Id,
              id_meniu: this.state.idMeniu,
            };
            //add obj;
            arrAdd.push(obj);
          }
          return null;
        });
        //VERIFICA DACA EXISTA ELEMENTELE DIN BAZA DE DATE IN CELE SELECTATE, DACA NU, STERGE
        date.map((f) => {
          if (
            this.state.selectedRowsData.filter((r) => {
              return r.Id === f.id_salariat;
            }).length === 0
          ) {
            //STERG DIN BAZA ELEMENTUL f.id_scm!
            var id = f.id_salariati_meniu;
            arrDelete.push(id);
          }
          return null;
        });

        arrDelete
          .reduce((promise, item) => {
            return promise.then(() => {
              return this.DeleteValueSalariatiMeniu(item)
                .then((r) => {})
                .catch((err) => {
                  console.log("err", err);
                });
            });
          }, Promise.resolve())
          .then(() => {
            arrAdd
              .reduce((promise, item) => {
                return promise.then(() => {
                  return this.AddValueSalariatiMeniu(item)
                    .then((r) => {})
                    .catch((err) => {
                      console.log("err", err);
                    });
                });
              }, Promise.resolve())
              .then(() => {
                this.$f7.preloader.hide();
              })
              .catch(() => {
                console.log("Eroare");
                this.$f7.preloader.hide();
              });
          })
          .catch(() => {
            console.log("Eroare");
            this.$f7.preloader.hide();
          });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  /**
   * @description Deschide treelist-ul la nivelul specificat
   * @date 07/04/2022
   * @param {*} level nivelul la care sa deschida treelistul
   * @memberof SalariatiGridMeniu
   */
  expandToLevel = (level) => {
    this.setState({ level: level }, () => {
      this.$f7.preloader.show();
      this.state.componentaTree.forEachNode((nod) => {
        if (nod.level < this.state.level) {
          this.state.componentaTree.expandRow(nod.data.Id);
        } else {
          this.state.componentaTree.collapseRow(nod.data.Id);
        }
      });
      this.$f7.preloader.hide();
    });
  };

  render() {
    return this.state.dataLoaded ? (
      <>
        {this.state.maxDepth > 0 ? (
          <Range
            label={true}
            className={"rangeDepth"}
            min={0}
            max={this.state.maxDepth}
            step={1}
            value={this.state.level}
            scale={true}
            scaleSteps={this.state.maxDepth}
            scaleSubSteps={1}
            onRangeChanged={(e) => {
              this.expandToLevel(e);
            }}
          />
        ) : null}

        <TreeList
          filterMode={"fullBranch"}
          onInitialized={(e) => {
            this.setState({
              componentaTree: e.component,
            });
          }}
          searchPanel={{
            visible: true,
            width: 160,
          }}
          selection={{ mode: "multiple" }}
          onSelectionChanged={(e) => {
            var s = [];
            var sData = [];
            s = e.selectedRowKeys;
            sData = e.selectedRowsData;
            this.setState(
              {
                selectedRowKeys: s,
                componentaTree: e.component,
                selectedRowsData: sData,
              },
              () => {
                this.SaveValuesDevextreme();
              }
            );
          }}
          selectedRowKeys={this.state.selectedRowKeys}
          showColumnHeaders={true}
          showColumnLines={true}
          height={window.innerHeight - 70}
          rootValue={null}
          dataSource={this.state.dataSource}
          showBorders={true}
          keyExpr={"Id"}
          parentIdExpr={"Ref"}
          id="TreeListProiecteModuleDreapta"
          columns={[
            {
              minWidth: 100,
              dataField: "Denumire",
              caption: "Denumire",
            },
          ]}
        />
      </>
    ) : (
      <div>Selectati un meniu.</div>
    );
  }
}
export default SalariatiGridMeniu;
