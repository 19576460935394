import React from "react";
import {
    Appbar,
    NavLeft,
    Link,
    Icon,
    NavRight,
    NavTitle,
    f7ready,
    Button
} from "framework7-react";
import TopRight from "./pages/TopRight";
import axios from "axios";
import moment from "moment";
import TopTabs from "./pages/TopTabs";
import VideoCall from "./pages/Sesizari/VideoCall";

/**
 * @description Defineste bara de sus a aplicatiei unde apar butoanele de meniu, chat, notificari, setari
 * @date 07/04/2022
 * @class TopBar
 * @extends {React.Component}
 */
class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iconita: "menu",
      showBack: false,
      showAppBar: false,
      showTopRight: false,
      name: "Home",
      linkApiRealizari: "/api/RealizareSarcini",
      listaRealizari: [],
      timpTotal: {
        zile: 0,
        ore: 0,
        minute: 0,
        secunde: 0,
      },
    };
  }

  /**
   * @description Ia realizarile salariatului logat pentru a randa timpul lucrat pe ziua de azi
   * @function
   * @date 07/04/2022
   * @memberof TopBar
   */
  GetRealizariValues = () => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiRealizari +
          "/GetREALIZARE_SARCINI_BY_SALARIAT/" +
          this.$f7.data.user.iD_SALARIAT
      )
      .then((responseRealizari) => {
        let listaRealizariAzi = [];

        responseRealizari.data.forEach((item, index) => {
          if (
            moment().startOf("day").valueOf() < moment(item.intrare).valueOf()
          ) {
            listaRealizariAzi.push(item);
          }
        });

        this.setState(
          {
            listaRealizari: listaRealizariAzi,
          },
          () => {
            this.calculareTimpi();
          }
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /**
   * @description Calculeaza timpul lucrat al salariatului pe ziua curenta
   * @function
   * @date 07/04/2022
   * @memberof TopBar
   */
  calculareTimpi = () => {
    let total = 0;

    this.state.listaRealizari.forEach((item, index) => {
      const inceput = moment(item.intrare).valueOf();
      const sfarsit =
        item.iesire === null
          ? moment().valueOf()
          : moment(item.iesire).valueOf();

      total += sfarsit - inceput;
    });

    // Calculam ore, minute si secunde pentru timpul total (suma duratelor tuturor activitatilor din ziua curenta)
    const zileTotal = Math.floor(total / (24 * 60 * 60 * 1000));
    total = total % (24 * 60 * 60 * 1000);
    const oreTotal = Math.floor(total / (60 * 60 * 1000));
    total = total % (60 * 60 * 1000);
    const minuteTotal = Math.floor(total / (60 * 1000));
    total = total % (60 * 1000);
    const secundeTotal = Math.floor(total / 1000);

    const timpTotal = {
      zile: zileTotal,
      ore: oreTotal,
      minute: minuteTotal,
      secunde: secundeTotal,
    };

    this.setState({
      timpTotal: timpTotal,
    });
  };

  componentDidMount() {
    f7ready(() => {
      setTimeout(() => {
        if (this.$f7.data.user) {
          this.GetRealizariValues();
        }

        setInterval(() => {
          if (this.$f7.data.user) {
            this.GetRealizariValues();
          }
        }, 5 * 60000);
      }, 3000);
    });
  }

  render() {
    return (
      <>
        {this.state.showAppBar ? (
          <Appbar
            id={"TopBar"}
            innerClass="innerAppbar"
            className={"appBarTopBar"}>
            {this.$f7.data.rolAdevarat < 6 ? (
              <>
                <VideoCall />

                <NavLeft className={"navLeftTopBar"}>
                  <Link
                    tooltip={this.state.showBack ? "Inapoi" : "Meniu"}
                    onClick={() => {
                      if (this.state.showBack) {
                        this.state.router.back();
                      } else {
                        this.$f7.methods.openMenu(this, this.state.router);
                      }
                    }}>
                    {this.state.showBack ? (
                      <Icon material={"arrow_back"} />
                    ) : (
                      <Icon material={this.state.iconita} />
                    )}
                  </Link>
                  <TopTabs />

                  {this.state.router !== undefined ? (
                    <NavTitle className="titleTopBar" />
                  ) : null}
                </NavLeft>
              </>
            ) : (
              <Link
                key={"refreshTabButton"}
                onClick={(e) => {
                  this.$f7.views.main.router.refreshPage();
                }}>
                <Icon material={"refresh"} />
              </Link>
            )}

            {this.state.showTopRight ? (
              <NavRight>
                {this.$f7.data.rolAdevarat < 6 ? (
                  <Button
                    style={{
                      height: "34px",
                      display: "inline-flex",
                    }}
                    onClick={this.GetRealizariValues}>
                    {this.state.timpTotal.ore +
                      ":" +
                      this.state.timpTotal.minute +
                      ":" +
                      this.state.timpTotal.secunde}
                  </Button>
                ) : null}

                <TopRight />
              </NavRight>
            ) : null}
          </Appbar>
        ) : null}
      </>
    );
  }
}
export default TopBar;