import React from "react";
import { List, ListItem, Icon, Preloader } from "framework7-react";
import { RadialBarChart, RadialBar, Legend, PolarAngleAxis } from "recharts";
import axios from "axios";

/**
 * @description Interfata de afisare a stadiului proiectelor(procent lucrat/inchis)
 * @date 08/04/2022
 * @class StadiuProiecte
 * @extends {React.PureComponent}
 */
class StadiuProiecte extends React.PureComponent {
  constructor(props) {
    super(props);
    this.height = window.innerHeight;
    this.state = {
      val: undefined,
      dataLoaded: false,
      linkApi: "/api/StadiuProiecte",
      pagina: "Stadiu proiecte",
      denumire: Math.random().toString(36).substring(7).toString(),
      user: {},
      height: window.innerHeight - 140,
      width: window.innerWidth - 34,
      toolbarIndex: 0,
      dataSource: [
        {
          name: "Procent lucrat",
          uv: 0,
          fill: "#8884d8",
        },
        {
          name: "Procent inchis",
          uv: 0,
          fill: "#83a6ed",
        },
      ],
      dataSourceSelectbox: [
        { denumire: "Primul element", dataSource: { a: 1, b: 2 } },
      ],
    };
  }
  componentDidMount() {
    if (this.props.idProiect !== undefined) {
      this.GetValue(this.props.idProiect);
    } else {
      this.GetValues();
    }
  }

  /**
   * @description Ia stadiul tuturor proiectelor
   * @function
   * @date 08/04/2022
   * @memberof StadiuProiecte
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/GetStadiuProiecte")
      .then((response) => {
        var date = response;
        var dataSourceSelectbox = [];

        date.data.map((stadiu) => {
          var toAdd = {
            denumire: stadiu.denumire,
            dataSource: [
              {
                name: "Procent lucrat",
                uv: stadiu.procentLucrat === null ? 0 : stadiu.procentLucrat,
                fill: "#8884d8",
              },
              {
                name: "Procent inchis",
                uv: stadiu.procentProgres === null ? 0 : stadiu.procentProgres,
                fill: "#83a6ed",
              },
            ],
          };
          dataSourceSelectbox.push(toAdd);
          return null;
        });

        this.setState(
          {
            dataSourceSelectbox: dataSourceSelectbox,
            dataSource: dataSourceSelectbox[0].dataSource,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Ia stadiul unui proiect selectat
   * @function
   * @date 08/04/2022
   * @param {*} idProiect id-ul proiectului selectat
   * @memberof StadiuProiecte
   */
  GetValue = (idProiect) => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetStadiuProiect/" +
          idProiect
      )
      .then((response) => {
        var date = response;
        var dataSourceSelectbox = [];

        date.data.map((stadiu) => {
          var toAdd = {
            denumire: stadiu.denumire,
            dataSource: [
              {
                name: "Procent lucrat",
                uv: stadiu.procentLucrat === null ? 0 : stadiu.procentLucrat,
                fill: "#8884d8",
              },
              {
                name: "Procent inchis",
                uv: stadiu.procentProgres === null ? 0 : stadiu.procentProgres,
                fill: "#83a6ed",
              },
            ],
          };
          dataSourceSelectbox.push(toAdd);
          return null;
        });

        this.setState(
          {
            dataSourceSelectbox: dataSourceSelectbox,
            dataSource: dataSourceSelectbox[0].dataSource,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  render() {
    return this.state.dataLoaded ? (
      <>
        {this.props.idProiect === undefined ? (
          <List style={{ margin: "0px!important" }}>
            <ListItem
              key={"SelectStadiuProiecte"}
              className="roundedOutlinedSmartSelect"
              floatingLabel
              required
              label={"Select"}
              title={"Select"}
              smartSelect
              smartSelectParams={{
                virtualList: true,
                searchbar: true,
                openIn: "popup",
                popupTabletFullscreen: true,
                popupPush: true,
                closeOnSelect: true,
                on: {
                  closed: (event) => {
                    var value = event.getValue();
                    var dataSource = this.state.dataSourceSelectbox.filter(
                      (r) => {
                        return r.denumire === value;
                      }
                    )[0].dataSource;
                    this.setState({ dataSource: dataSource, val: value });
                  },
                },
              }}>
              <select name="SelectBox">
                {this.state.dataSourceSelectbox.map((obj, index) => (
                  <option
                    selected={
                      this.state.val !== undefined
                        ? this.state.val === obj.denumire
                        : this.state.dataSourceSelectbox[0].denumire ===
                          obj.denumire
                    }
                    key={"dataSourceSelectboxStadiuProiete" + index}
                    value={obj.denumire}
                    label={obj.denumire}>
                    {obj.denumire}
                  </option>
                ))}
              </select>
              <Icon
                slot="content-start"
                style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                size={20}
                material="list"
              />
            </ListItem>
          </List>
        ) : null}

        <RadialBarChart
          width={this.state.width}
          height={this.state.height}
          innerRadius={20}
          outerRadius={140}
          barSize={10}
          data={this.state.dataSource}>
          {" "}
          <PolarAngleAxis
            type="number"
            domain={[0, 100]}
            angleAxisId={0}
            tick={false}
          />
          <RadialBar
            label={{
              position: "outside",
              fill: "var(--f7-theme-color)",
            }}
            dataKey="uv"
          />
          <Legend iconSize={10} layout="vertical" verticalAlign="top" />
        </RadialBarChart>
      </>
    ) : (
      <div
        style={{
          position: "absolute",
          width: "35px",
          height: "35px",
          top: "calc(50vh)",
          left: "50%",
        }}>
        <Preloader size={40} color={"var(--f7-theme-color)"} />
      </div>
    );
  }
}
export default StadiuProiecte;
