import React from "react";
import {
  NavRight,
  Link,
  List,
  ListItem,
  Icon,
  Badge,
  NavLeft,
  Chip,
} from "framework7-react";
import SearchPopover from "./SearchPopover";
import NotificationsPopover from "./NotificationsPopover";
import MessagesPopover from "./MessagesPopover";
import UserPopover from "./UserPopover";
import moment from "moment";
import axios from "axios";
import ATMain from "./AlocareTask/ATMain";
import notificationSound from "../../styles/notification.mp3";
import EmailPopup from "./EmailPopup/EmailPopup";

/**
 * @description Randeaza toate butoanele de sunt in dreapta sus a barii de sus, butonul de mesaje, de taskuri primite, de setari
 * @date 08/04/2022
 * @class TopRight
 * @extends {React.PureComponent}
 */
class TopRight extends React.PureComponent {
  constructor(props) {
    super(props);
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    var arrProiecte = [];
    var arrPrioritati = this.$f7.data.Prioritati.filter((r) => {
      return r.value < 6 && r.value !== null;
    });
    this.linkApiTemplate = "/api/Template";
    this.linkApiSalariatModule = "/api/SalariatContracteModule";
    this.buttons = [
      {
        key: "Salveaza si preia",
        onSubmit: (state, den) => {
          if (state.editedItem.NivelProiect === 4) {
            this.salveazaSiPreia(state);
            this.setState(
              {
                flagAddTaskNotSaved: false,
              },
              () => {
                this.$f7.panel.close(den);
              }
            );
          } else {
            this.$f7.methods.openNotification("Puteti prelua doar taskuri!");
          }
        },
      },
      {
        key: "Salveaza si aloca cuiva",
        onSubmit: (state, den) => {
          if (state.editedItem.NivelProiect === 4) {
            this.salveazaSiAloca(state, den);
          } else {
            this.$f7.methods.openNotification("Puteti aloca doar taskuri!");
          }
        },
      },
      {
        key: "Salveaza ca nealocat",
        onSubmit: (state, den) => {
          this.salveaza(state);
          this.setState(
            {
              flagAddTaskNotSaved: false,
            },
            () => {
              this.$f7.panel.close(den);
            }
          );
        },
      },
    ];
    this.niveleProiecte = this.$f7.data.NivelProiect.filter((r) => {
      return r.value !== null;
    });
    this.categorii = this.$f7.data.TipProiect.filter((r) => {
      return r.value !== null && ![55, 56, 57, 58, 59, -1].includes(r.value);
    });
    this.clienti = this.$f7.data.Firme.filter((r) => {
      return r.value !== null;
    });
    this.departamente = this.$f7.data.Departamente.filter((r) => {
      return r.value !== null && r.value !== 0;
    });
    this.state = {
      rol: this.$f7.data.rol,
      templateSelectat: null,
      listaTemplate: [
        {
          idtemplate: 1,
          denumire: "1",
          continut: JSON.stringify({ a: 1, b: 2, c: 3 }),
        },
        {
          idtemplate: 2,
          denumire: "2",
          continut: JSON.stringify({ a: 3, b: 2, c: 3 }),
        },
      ],
      listaSalariatModule: [],
      countPrimite: 0,
      countMesaje: 0,
      taskIsActiv: false,
      inputs: [
        {
          key: "Client",
          keyBaza: "id_client",
          tip: "selectbox",
          lista: this.clienti,
          required: true,
          group: "Informatii",
        },
        {
          key: "Denumire",
          keyBaza: "denumire",
          group: "Informatii",
          required: true,
          maxlength: 190,
        },
        {
          key: "Cerinta",
          keyBaza: "comentariu",
          group: "Informatii",
          required: true,
          tip: "textarea",
        },
        {
          disabled: true,
          key: "NivelProiect",
          keyBaza: "id_nivel_proiect",
          tip: "selectbox",
          lista: this.niveleProiecte,
          defaultValue: 4,
          required: true,
          group: "Detalii",
        },
        {
          key: "Categorie",
          keyBaza: "id_tip_proiect",
          tip: "selectbox",
          lista: this.categorii,
          required: true,
          group: "Detalii",
        },
        {
          key: "Deadline",
          keyBaza: "deadline",
          tip: "datebox",
          required: true,
          group: "Detalii",
        },
        {
          key: "ProiectReferinta",
          keyBaza: "reF_PROIECT",
          lista: arrProiecte,
          tip: "selectbox",
          required: true,
          group: "Detalii",
        },
        {
          key: "Prioritate",
          keyBaza: "id_prioritate",
          tip: "selectbox",
          lista: arrPrioritati,
          defaultValue: 0,
          required: true,
          group: "Detalii",
        },
        {
          key: "Departament",
          keyBaza: "id_departament",
          tip: "selectbox",
          lista: this.departamente,
          defaultValue: this.$f7.data.user.iD_DEPARTAMENT,
          required: true,
          group: "Detalii",
        },
        {
          key: "Atasament",
          keyBaza: "atasamentDeTest",
          tip: "filepicker",
          required: true,
          group: "Detalii",
        },
        {
          key: "EstimareInMinute",
          keyBaza: "timp_plan_d",
          tip: "number",
          required: true,
          group: "Detalii",
          defaultValue: 1,
        },
        {
          key: "Sursa",
          keyBaza: "id_tip_sursa",
          tip: "selectbox",
          lista: this.$f7.data.TipSursa,
          defaultValue: null,
          required: true,
          group: "Detalii",
        },
        {
          key: "Audio",
          keyBaza: "audio",
          tip: "audiorecorder",
          group: "Detalii",
        },
      ],
      flagAddTaskNotSaved: true,
    };
    this.denumireSearch = "Search-" + this.$f7.utils.id("xxxx-xxxx-xxxx");
    this.denumireNotificari =
      "Notifications-" + this.$f7.utils.id("xxxx-xxxx-xxxx");
    //this.denumireMesaje = "Messages-" + this.$f7.utils.id("xxxx-xxxx-xxxx")
    this.$f7.data.denumireMesaje =
      "Messages-" + this.$f7.utils.id("xxxx-xxxx-xxxx");
    this.denumireUtilizator =
      "Utilizator-" + this.$f7.utils.id("xxxx-xxxx-xxxx");

    this.audio = new Audio(notificationSound);
  }

  /**
   * @description Ia din baza de date toate templateurile adaugate pentru adaugarea de task
   * @function
   * @date 08/04/2022
   * @memberof TopRight
   */
  GetValuesTemplate = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            this.linkApiTemplate +
            "/GetTEMPLATESalariat/" +
            this.$f7.data.user.iD_SALARIAT
        )
        .then((response) => {
          this.setState(
            {
              listaTemplate: response.data,
            },
            () => {
              resolve(response);
            }
          );
        })
        .catch((error) => {
          this.setState(
            {
              dataSource: [],
            },
            () => {
              this.$f7.dialog.confirm(
                "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                "Ups...",
                () => {
                  this.GetValuesTemplate();
                },
                () => {}
              );
            }
          );

          reject(error);
        });
    });
  };

  /**
   * @description Adauga in baza de date un template cu toate informatiile din interfata de adaugare task
   * @function
   * @date 08/04/2022
   * @param {*} data informatiile din popup de adaugare task
   * @memberof TopRight
   */
  AddValueTemplate = (data) => {
    var url = this.$f7.data.baseURL + this.linkApiTemplate + "/PostTEMPLATE";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValuesTemplate();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Salveaza taskul nou creat si apoi deschide interfata de propunere task cuiva
   * @function
   * @date 08/04/2022
   * @param {*} state state-ul popup-ului de adaugare task
   * @param {*} den denumirea popup-ului de adaugare task pentur inchiderea acestuia
   * @memberof TopRight
   */
  salveazaSiAloca = (state, den) => {
    this.salveaza(state)
      .then((response) => {
        this.setState(
          {
            flagAddTaskNotSaved: false,
          },
          () => {
            this.$f7.panel.close(den);
          }
        );

        var idProiect = response.data.iD_PROIECT;
        this.$f7.data.ATidProiect = idProiect;
        var Proiect = response.data;

        this.$f7.data.router.navigate("/popupDinamic/", {
          props: {
            onPopupClose: (r) => {
              this.$f7.methods.refreshStanga();
            },
            component: <ATMain Proiect={Proiect} idProiect={idProiect} />,
          },
        });
      })
      .catch((error) => {
        this.$f7.methods.openNotification("Eroare:" + error);
      });
  };

  /**
   * @description Salveaza proiectul si apoi apeleaza functia de asumare a taskului
   * @function
   * @date 08/04/2022
   * @param {*} state state-ul popup-ului de adaugare task nou
   * @memberof TopRight
   */
  salveazaSiPreia = (state) => {
    this.salveaza(state)
      .then((response) => {
        var idProiect = response.data.iD_PROIECT;
        this.AsumaTask(idProiect);
      })
      .catch((error) => {
        this.$f7.methods.openNotification("Eroare:" + error);
      });
  };

  /**
   * @description Salveaza taskul in nealocate
   * @function
   * @date 08/04/2022
   * @param {*} state state-ul popup-ului de adaugare task nou
   * @memberof TopRight
   */
  salveaza = (state) => {
    return new Promise((resolve, reject) => {
      var eveniment = { ...state.editedItem };
      eveniment = this.$f7.methods.restructurareBaza(
        eveniment,
        this.state.inputs,
        "keyBaza"
      );
      eveniment.isArhivat = eveniment.isArhivat === true ? 1 : 0;
      eveniment.isHidden = eveniment.isHidden === true ? 1 : 0;
      eveniment.aprobat_d = eveniment.aprobat_d === true ? 1 : 0;
      eveniment.aprobat_a = eveniment.aprobat_a === true ? 1 : 0;
      eveniment.aprobat_s = eveniment.aprobat_s === true ? 1 : 0;
      eveniment.aprobat_m = eveniment.aprobat_m === true ? 1 : 0;
      //eveniment.reF_PROIECT = eveniment.reF_PROIECT;
      eveniment.taskcomplete = 0;
      eveniment.folosit = true;
      eveniment.data_start = moment().format();
      eveniment.data_creare = moment().format();
      eveniment.valoare_minima = 0;
      //eveniment.id_nivel_proiect = 4;
      eveniment.valoare_maxima = 0;
      eveniment.iD_STADIU = 1;
      eveniment.id_operator_adaugare = this.$f7.data.user.iD_SALARIAT;
      if (eveniment.id_nivel_proiect === 4) {
        eveniment.task = true;
      }

      var file = eveniment.atasamentDeTest;
      var file2 = eveniment.audio;
      if (file !== null && file !== "" && file !== undefined) {
        var numeFisier = file.name;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var res = reader.result;
          var continutAtasament = {
            continut: res.split(",")[1],
            name: numeFisier,
          };
          this.AddValue(eveniment, continutAtasament)
            .then((response, resp) => {
              if (file2 !== null && file2 !== "" && file2 !== undefined) {
                var numeFisier = file2.name;
                var reader = new FileReader();
                reader.readAsDataURL(file2);
                reader.onload = () => {
                  var res = reader.result;
                  var continutAtasament = {
                    continut: res.split(",")[1],
                    name: numeFisier,
                  };
                  this.AddValue(eveniment, continutAtasament)
                    .then((response, resp) => {
                      resolve(response, resp);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                };
                reader.onerror = (error) => {
                  reject(error);
                };
              } else {
                resolve(response, resp);
              }
            })
            .catch((error) => {
              reject(error);
            });
        };
        reader.onerror = (error) => {
          reject(error);
        };
      } else {
        if (file2 !== null && file2 !== "" && file2 !== undefined) {
          var numeFisier2 = file2.name;
          var reader2 = new FileReader();
          reader2.readAsDataURL(file2);
          reader2.onload = () => {
            var res = reader2.result;
            var continutAtasament = {
              continut: res.split(",")[1],
              name: numeFisier2,
            };
            this.AddValue(eveniment, continutAtasament)
              .then((response, resp) => {
                resolve(response, resp);
              })
              .catch((error) => {
                reject(error);
              });
          };
          reader2.onerror = (error) => {
            reject(error);
          };
        } else {
          this.AddValue(eveniment, false)
            .then((response, resp) => {
              resolve(response, resp);
            })
            .catch((error) => {
              reject(error);
            });
        }
      }
    });
  };

  /**
   * @description Functie de asumare a taskului nou creat
   * @function
   * @date 08/04/2022
   * @param {*} id id-ul taskului nou creat
   * @memberof TopRight
   */
  AsumaTask = (id) => {
    if (id) {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/SarciniSalariat/GetSARCINI_SALARIATRespinsByProiect/" +
            id
        )
        .then((response) => {
          let sarcina = response.data;
          let url =
            this.$f7.data.baseURL + "/api/SarciniSalariat/PostSARCINI_SALARIAT";
          let sarcinaAsumata = {
            iD_PROIECT: parseInt(id),
            iD_SALARIAT: this.$f7.data.user.iD_SALARIAT,
            dE_CINE: this.$f7.data.user.iD_SALARIAT,
            datA_ALOCARII: moment().format(),
            datA_DEALOCARII: null,
            dealoC_CINE: null,
            stare: 1,
          };

          if (sarcina === "") {
            axios({
              data: sarcinaAsumata,
              headers: { "Content-Type": "application/json" },
              method: "post",
              url: url,
            })
              .then((responseAsumare) => {
                //SA DEA REFRESH
                if (this.$f7.data.homePage !== undefined) {
                  if (
                    this.$f7.data.homePage.state.selectedGrid === "0" &&
                    this.$f7.data.homePage.refAlocate.current !== null &&
                    this.$f7.views.main.router.currentRoute.path === "/"
                  ) {
                    this.$f7.data.homePage.refAlocate.current
                      .GetValues()
                      .then((responseGetValues) => {
                        this.$f7.dialog.confirm(
                          "Doriti sa porniti taskul respectiv?",
                          "Pornire",
                          () => {
                            this.$f7.data.homePage.refAlocate.current.setState(
                              {
                                idSarcina: responseAsumare.data.iD_SARCINA,
                                idSelected: responseAsumare.data.iD_PROIECT,
                              },
                              () => {
                                this.$f7.data.homePage.refAlocate.current
                                  .Inceput()
                                  .then((r) => {
                                    this.$f7.data.homePage.refAlocate.current
                                      .GetValues()
                                      .then(() => {
                                        this.$f7.preloader.hide();
                                      })
                                      .catch((e) => {
                                        this.$f7.preloader.hide();
                                        this.$f7.methods.openNotification(
                                          "Error: " + e
                                        );
                                      });
                                  })
                                  .catch((e) => {
                                    this.$f7.preloader.hide();
                                    this.$f7.methods.openNotification(
                                      "Error: " + e
                                    );
                                  });
                              }
                            );
                          }
                        );
                      })
                      .catch((errorGetValues) => {
                        console.log("error:", errorGetValues);
                      });
                  }
                }
              })
              .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la inserare");
              });
          } else {
            sarcina.datA_DEALOCARII = moment().format();
            sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT;

            let urlUpdate =
              this.$f7.data.baseURL +
              "/api/SarciniSalariat/PutSARCINI_SALARIAT/" +
              sarcina.iD_SARCINA;

            axios({
              data: sarcina,
              headers: { "Content-Type": "application/json" },
              method: "put",
              url: urlUpdate,
            })
              .then((response) => {
                axios({
                  data: sarcinaAsumata,
                  headers: { "Content-Type": "application/json" },
                  method: "post",
                  url: url,
                })
                  .then((response) => {
                    //SA DEA REFRESH
                    if (this.$f7.data.homePage !== undefined) {
                      if (
                        this.$f7.data.homePage.state.selectedGrid === "0" &&
                        this.$f7.data.homePage.refAlocate.current !== null &&
                        this.$f7.views.main.router.currentRoute.path === "/"
                      ) {
                        this.$f7.data.homePage.refAlocate.current.GetValues();
                      }
                    }
                  })
                  .catch((error) => {
                    console.log("error", error);
                    this.$f7.methods.openNotification("Eroare la inserare");
                  });
              })
              .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la actualizare");
              });
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la preluare");
        });
    } else {
      this.$f7.methods.openNotification("Selectati o sarcina");
    }
  };

  /**
   * @description Adauga un atasament taskului nou creat
   * @function
   * @date 08/04/2022
   * @param {*} data infomratiile atasamentului
   * @memberof TopRight
   */
  AddValueDocumente = (data) => {
    return new Promise((resolve, reject) => {
      var url = this.$f7.data.baseURL + "/api/Documente/PostDOCUMENTE";
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "POST",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Adauga un nou proiect in baza de date pentru taskul nou creat
   * @date 08/04/2022
   * @function
   * @param {*} data obiect cu informatiile inserate in popup-ul de adaugare task
   * @param {*} continutAtasament atasament,in cazul in care exista
   * @memberof TopRight
   */
  AddValue = (data, continutAtasament) => {
    return new Promise((resolve, reject) => {
      var url = this.$f7.data.baseURL + "/api/Proiecte/PostPROIECTE";
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          var idProiect = response.data.iD_PROIECT;
          this.$f7.data.allProiecte.push(response.data);
          this.$f7.data.nearhivateProiecte.push(response.data);
          this.$f7.data.Proiecte.push({
            key: response.data.denumire,
            value: response.data.iD_PROIECT,
          });
          if (continutAtasament !== false) {
            var obj = {};
            obj.Id_Proiect = idProiect;
            obj.dATA_MODIF = moment().format();
            obj.Nume_Fisier = continutAtasament.name;
            obj.Nume_Path = continutAtasament.name;
            obj.sAL_AUTOR = this.$f7.data.user.iD_SALARIAT;
            obj.iD_TIP_DOCUMENT = 1;
            obj.id_initial = null;
            obj.dir_parinte = null;
            obj.ref_document = null;
            obj.continut = continutAtasament.continut;
            this.AddValueDocumente(obj)
              .then((resp) => {
                resolve(response, resp);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  componentDidMount = () => {
    this.$f7.data.TopRight = this;
    if (this.$f7.data.user && this.$f7.data.rolAdevarat !== 6) {
      this.GetMessagesCount();
      this.GetPrimiteCount();
      this.GetTaskActiv();
    }

    setInterval(() => {
      if (this.$f7.data.user && this.$f7.data.rolAdevarat !== 6) {
        this.GetTaskActiv();
      }
    }, 1 * 60000);

    setInterval(() => {
      if (this.$f7.data.user && this.$f7.data.rolAdevarat !== 6) {
        this.GetMessagesCount();
      }
    }, 1 * 60000);

    setInterval(() => {
      if (this.$f7.data.user && this.$f7.data.rolAdevarat !== 6) {
        this.GetPrimiteCount();
      }
    }, 10 * 60000);

    this.$f7.data.signalRConnection.on("ReceivedMessage", (from, message) => {
      const panelMesaje = this.$f7.panel.get(
        "." + this.$f7.data.denumireMesaje
      );

      if (panelMesaje && !panelMesaje.opened) {
        this.setState(
          {
            countMesaje: this.state.countMesaje + 1,
          },
          () => {
            if (from) {
              this.$f7.methods.openNotification(
                message,
                `Mesaj nou de la ${from.user}`,
                "succes"
              );
            } else {
              this.$f7.methods.openNotification(message, `Mesaj nou`, "succes");
            }
            this.audio.play();
          }
        );
      }
    });
  };

  componentWillUnmount = () => {
    this.$f7.data.signalRConnection.off("ReceivedMessage");
  };

  /**
   * @description Propune taskul creat unui salariat
   * @date 08/04/2022
   * @function
   * @param {*} idSelected id-ul salariatului selectat
   * @memberof TopRight
   */
  PropuneTask = (idSelected) => {
    axios
      .get(
        this.$f7.data.baseURL +
          "/api/SarciniSalariat" +
          "/GetSARCINI_SALARIATRespinsByProiect/" +
          idSelected
      )
      .then((response) => {
        let sarcina = response.data;
        let url =
          this.$f7.data.baseURL +
          "/api/SarciniSalariat" +
          "/PostSARCINI_SALARIAT";
        let sarcinaPropusa = {
          iD_PROIECT: parseInt(idSelected),
          iD_SALARIAT: parseInt(this.state.idPropunere),
          dE_CINE: this.$f7.data.user.iD_SALARIAT,
          datA_ALOCARII: moment().format(),
          dATA_DEALOCARII: null,
          dealoC_CINE: null,
          stare: 2,
        };

        if (sarcina === "") {
          axios({
            data: sarcinaPropusa,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: url,
          })
            .then((response) => {
              this.$f7.methods.openNotification("Task propus");
            })
            .catch((error) => {
              console.log("error", error);
              this.$f7.methods.openNotification("Eroare la inserare");
            });
        } else {
          sarcina.datA_DEALOCARII = moment().format();
          sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT;

          let urlUpdate =
            this.$f7.data.baseURL +
            "/api/SarciniSalariat" +
            "/PutSARCINI_SALARIAT/" +
            sarcina.iD_SARCINA;

          axios({
            data: sarcina,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: urlUpdate,
          })
            .then((response) => {
              axios({
                data: sarcinaPropusa,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: url,
              })
                .then((response) => {
                  this.$f7.methods.openNotification("Task propus");
                })
                .catch((error) => {
                  console.log("error", error);
                  this.$f7.methods.openNotification("Eroare la inserare");
                });
            })
            .catch((error) => {
              console.log("error", error);
              this.$f7.methods.openNotification("Eroare la actualizare");
            });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la preluare");
      });
  };

  /**
   * @description Ia modulele si proiectele la care este atasat salariatul curent
   * @function
   * @date 08/04/2022
   * @memberof TopRight
   */
  GetValuesSalariatModule = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            this.linkApiSalariatModule +
            "/GetSALARIAT_CONTRACTE_MODULEBySalariat/" +
            this.$f7.data.user.iD_SALARIAT
        )
        .then((response) => {
          var inputuri = [...this.state.inputs];
          let lista = [];
          response.data.forEach((item) => {
            lista.push(item.ref_proiect);
          });

          var listaClientiSalariat = [];
          response.data.map((f) => {
            var referinta = f.ref_proiect;
            var proiectul = this.$f7.data.allProiecte.find((r) => {
              return r.iD_PROIECT === referinta;
            });
            if (proiectul) {
              var idClientProiect = proiectul.id_client;
              if (!listaClientiSalariat.includes(idClientProiect)) {
                listaClientiSalariat.push(idClientProiect);
              }
            }
            return null;
          });
          var listaClientiSalariatFinal = this.$f7.data.Firme.filter((r) => {
            return listaClientiSalariat.includes(r.value);
          });
          listaClientiSalariatFinal.sort((a, b) =>
            a.key > b.key ? 1 : b.key > a.key ? -1 : 0
          );
          inputuri[0].lista = listaClientiSalariatFinal;

          //SELECTEAZA PRIMUL CLIENT/PROIECT
          if (listaClientiSalariatFinal.length > 0) {
            inputuri[0].defaultValue = listaClientiSalariatFinal[0].value;
            var arrProiecte = this.$f7.data.allProiecte
              .filter((r) => {
                return (
                  (r.id_nivel_proiect === 1 || r.id_nivel_proiect === 2) &&
                  r.id_client === listaClientiSalariatFinal[0].value
                );
              })
              .filter((r) => {
                return lista.includes(r.iD_PROIECT);
              })
              .map((r) => {
                r.key = r.denumire;
                r.value = r.iD_PROIECT;
                return r;
              });
            arrProiecte.sort((a, b) =>
              a.denumire > b.denumire ? 1 : b.denumire > a.denumire ? -1 : 0
            );
            inputuri[6].lista = arrProiecte;
            if (arrProiecte.length > 0) {
              let idPr = null;
              let nrPr = 0;
              let indexPr = null;

              let listaIdProiecte = [];
              let idCat = null;
              let nrCat = 0;
              let indexCat = null;

              arrProiecte.forEach((el, index) => {
                const taskuri = this.$f7.data.allProiecte.filter(
                  (item) =>
                    item.id_nivel_proiect === 4 && item.reF_PROIECT === el.value
                );
                listaIdProiecte.push(el.value);

                if (taskuri.length > nrPr) {
                  idPr = el.value;
                  nrPr = taskuri.length;
                  indexPr = index;
                }
              });

              if (idPr !== null && indexPr !== null) {
                inputuri[6].defaultValue = arrProiecte[indexPr].value;
              } else {
                inputuri[6].defaultValue = arrProiecte[0].value;
              }

              this.categorii.forEach((cat, cIndex) => {
                const categorii = this.$f7.data.allProiecte.filter(
                  (item) =>
                    item.id_nivel_proiect === 4 &&
                    listaIdProiecte.includes(item.reF_PROIECT) &&
                    item.id_tip_proiect === cat.value
                );

                if (categorii.length > nrCat) {
                  idCat = cat.value;
                  nrCat = categorii.length;
                  indexCat = cIndex;
                }
              });

              if (idCat !== null && indexCat !== null) {
                inputuri[4].defaultValue = this.categorii[indexCat].value;
              } else {
                inputuri[4].defaultValue = this.categorii[0].value;
              }
            }
          }
          this.setState(
            {
              listaSalariatModule: lista,
              inputs: inputuri,
            },
            () => {
              resolve(response);
            }
          );
        })
        .catch((error) => {
          this.setState(
            {
              listaSalariatModule: [],
            },
            () => {
              this.$f7.dialog.confirm(
                "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                "Ups...",
                () => {
                  this.GetValuesSalariatModule();
                },
                () => {}
              );
            }
          );

          reject(error);
        });
    });
  };

  /**
   * @description Ia numarul de taskuri primite
   * @function
   * @date 08/04/2022
   * @memberof TopRight
   */
  GetPrimiteCount = () => {
    this.$f7.methods
      .getProiecte(this.$f7.data.user.iD_SALARIAT, 2, 1, 0, 0, 0)
      .then((response) => {
        var date = response.data;
        var count = date.length;

        if (count !== this.state.countPrimite) {
          this.audio.play();
        }

        this.setState({ countPrimite: count });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /**
   * @description Afiseaza notificare cu cate mesaje are necitite
   * @function
   * @date 08/04/2022
   * @param {*} count numarul de mesaje necitite
   * @memberof TopRight
   */
  HandleNotification = (count) => {
    if (count > this.state.countMesaje) {
      this.$f7.methods.refreshStanga();
      var mesaj = "";

      if (count > 1) {
        mesaj = "Aveti " + count + " mesaje noi";
      } else if (count === 1) {
        mesaj = "Aveti un mesaj nou";
      }

      if (count !== 0) {
        this.$f7.methods.openNotification(mesaj);

        if (
          (localStorage.getItem("audioMessage") !== null &&
            JSON.parse(localStorage.getItem("audioMessage")) === true) ||
          localStorage.getItem("audioMessage") === null
        ) {
          this.audio.play();
        }
      }

      this.setState({
        countMesaje: count,
      });
    }
  };

  /**
   * @description Ia numarul de mesaje necitite de user
   * @function
   * @date 08/04/2022
   * @memberof TopRight
   */
  GetMessagesCount = () => {
    axios
      .get(
        this.$f7.data.baseURL +
          "/api/Sesizari/GetsesizariNecititeById/" +
          this.$f7.data.user.iD_SALARIAT
      )
      .then((response) => {
        var res = response.data;

        if (
          (localStorage.getItem("fromSistemIgnore") !== null &&
            JSON.parse(localStorage.getItem("fromSistemIgnore")) === true) ||
          localStorage.getItem("fromSistemIgnore") === null
        ) {
          res = res.filter((item) => item.de_la_cine !== null);
        }

        var count = res.length;
        if (count > 0) {
          this.HandleNotification(count);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /**
   * @description Ia taskul activ al userului curent
   * @function
   * @date 08/04/2022
   * @memberof TopRight
   */
  GetTaskActiv = () => {
    axios
      .get(
        this.$f7.data.baseURL +
          "/api/RealizareSarcini/VerificareTaskActivByUser/" +
          this.$f7.data.user.iD_SALARIAT
      )
      .then((responseActiv) => {
        const idProiect = responseActiv.data;

        this.setState({
          taskIsActiv: idProiect === -100 ? false : true,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /**
   * @description Afiseaza o interfata de adaugare task nou
   * @function
   * @date 08/04/2022
   * @param {*} title titlul care sa apara la adaugarea taskului
   * @memberof TopRight
   */
  taskNou = (title) => {
    var inputs = [...this.state.inputs];
    this.setState({ inputs: inputs }, () => {
      this.GetValuesTemplate()
        .then((responseTemplate) => {
          this.GetValuesSalariatModule()
            .then((responseSalariatModule) => {
              let flagAdaugaLaCerinta = true;
              this.$f7.data.router.navigate("/popupAddEdit/", {
                props: {
                  title,
                  NavLeft: (state, e) => {
                    return (
                      <NavLeft>
                        <Link
                          tooltip={"Templates"}
                          onClick={() => {
                            this.$f7.data.router.navigate("/sheetDinamic/", {
                              props: {
                                title: "Templates",
                                disableClose: false,
                                component: (
                                  <List>
                                    {this.state.listaTemplate.map(
                                      (r, index) => (
                                        <ListItem
                                          value={r.idtemplate}
                                          className={"inputInaltimeNoua"}
                                          checked={
                                            this.state.templateSelectat ===
                                            r.idtemplate
                                          }
                                          radio
                                          onClick={() => {
                                            this.setState(
                                              {
                                                templateSelectat: r.idtemplate,
                                              },
                                              () => {
                                                if (r.continut !== undefined) {
                                                  this.$f7.data.PopupAddEditThis.setState(
                                                    {
                                                      editedItem: JSON.parse(
                                                        r.continut
                                                      ),
                                                    }
                                                  );
                                                }
                                                var sheetDinamic =
                                                  this.$f7.sheet.get(
                                                    ".SheetDinamic"
                                                  );
                                                sheetDinamic.close();
                                              }
                                            );
                                          }}
                                          key={index}>
                                          <p
                                            style={{
                                              whiteSpace: "nowrap",
                                              margin: 0,
                                            }}>
                                            {r.denumire}
                                          </p>
                                        </ListItem>
                                      )
                                    )}
                                  </List>
                                ),
                              },
                            });
                          }}>
                          <Icon material="menu" />
                        </Link>

                        <Link
                          tooltip={"Salveaza template"}
                          onClick={() => {
                            this.$f7.dialog.prompt(
                              "Numele template-ului",
                              "Salvare",
                              (f) => {
                                var editedItem = {
                                  ...state.editedItem,
                                };

                                var obj = {
                                  denumire: f,
                                  continut: JSON.stringify(editedItem),
                                  idSalariat: this.$f7.data.user.iD_SALARIAT,
                                };
                                this.AddValueTemplate(obj);
                              },
                              () => {}
                            );
                          }}>
                          <Icon material="save" />
                        </Link>
                      </NavLeft>
                    );
                  },
                  ignoreInputs: ["NivelProiect", "Departament"],
                  inputs: this.state.inputs,
                  id: "Id",
                  buttons: this.buttons,
                  onAddEditOpened: (state, context) => {
                    if (
                      context.props &&
                      context.props.title === "Adaugare task"
                    ) {
                      this.setState(
                        {
                          flagAddTaskNotSaved: true,
                        },
                        () => {
                          if (localStorage.getItem("addTaskItem")) {
                            const item = JSON.parse(
                              localStorage.getItem("addTaskItem")
                            );

                            context.setState({
                              editedItem: item,
                            });
                          }
                        }
                      );
                    } else {
                      if (localStorage.getItem("addTaskItem"))
                        localStorage.removeItem("addTaskItem");
                    }
                  },
                  onAddEditClosed: (state, context) => {
                    if (
                      context.props &&
                      context.props.title === "Adaugare task"
                    ) {
                      if (this.state.flagAddTaskNotSaved) {
                        if (state.editedItem) {
                          localStorage.setItem(
                            "addTaskItem",
                            JSON.stringify(state.editedItem)
                          );
                        }
                      } else {
                        localStorage.removeItem("addTaskItem");
                      }
                    } else {
                      if (localStorage.getItem("addTaskItem"))
                        localStorage.removeItem("addTaskItem");
                    }
                  },
                  onSelectionChanged: (state, context) => {
                    var denumire = state.denumire;
                    var newValue = state.editedItem[denumire];

                    if (denumire === "Client") {
                      var arrProiecte = this.$f7.data.nearhivateProiecte
                        .filter((r) => {
                          return (
                            (r.id_nivel_proiect === 1 ||
                              r.id_nivel_proiect === 2) &&
                            r.id_client === newValue
                          );
                        })
                        .filter((r) => {
                          return this.state.listaSalariatModule.includes(
                            r.iD_PROIECT
                          );
                        })
                        .map((r) => {
                          r.key = r.denumire;
                          r.value = r.iD_PROIECT;
                          return r;
                        });
                      var inputsLocal = this.state.inputs;
                      inputsLocal[6].lista = arrProiecte;
                      this.setState({ inputs: inputsLocal }, () => {
                        var editedItem = context.state.editedItem;
                        editedItem[this.state.inputs[6].key] =
                          arrProiecte[0].value;
                        context.setState({
                          editedItem: editedItem,
                        });
                      });
                      // this.state.inputs[6].lista = arrProiecte;
                    }

                    if (denumire === "Atasamente") {
                      var nrAtasamenteNoi = state.editedItem.Atasamente;
                      var nrAtasamenteExistente =
                        this.state.inputs.filter((r) => {
                          return r.group === "Atasamentele";
                        }).length - 1;
                      if (nrAtasamenteNoi > nrAtasamenteExistente) {
                        for (
                          var ii = nrAtasamenteExistente;
                          ii < nrAtasamenteNoi;
                          ii++
                        ) {
                          this.state.inputs.push({
                            key: "Atasament" + ii,
                            keyBaza: "atasament" + ii,
                            tip: "filepicker",
                            required: true,
                            group: "Atasamentele",
                          });
                        }
                      } else if (nrAtasamenteNoi < nrAtasamenteExistente) {
                        for (
                          var i = nrAtasamenteNoi;
                          i < nrAtasamenteExistente;
                          i++
                        ) {
                          this.state.inputs.pop();
                        }
                      }
                    }

                    if (denumire === "ProiectReferinta") {
                      var pro = this.$f7.data.Proiecte.find((r) => {
                        return r.value === newValue;
                      });
                      if (pro) {
                        if (
                          pro.departament !== null &&
                          pro.departament !== undefined
                        ) {
                          var dep = this.$f7.data.Departamente.find((r) => {
                            return r.value === pro.departament;
                          });
                          if (dep) {
                            var idDepartament = dep.value;
                            var editedItem1 = context.state.editedItem;
                            editedItem1[this.state.inputs[8].key] =
                              idDepartament;
                            context.setState({
                              editedItem: editedItem1,
                            });
                          }
                        }
                      }
                    }
                  },
                  onInputChanged: (item, denumire, state) => {
                    if (
                      denumire === "Denumire" &&
                      title === "Adaugare task" &&
                      flagAdaugaLaCerinta
                    ) {
                      item["Cerinta"] = item["Denumire"];
                    }

                    if (denumire === "Cerinta" && flagAdaugaLaCerinta) {
                      flagAdaugaLaCerinta = false;
                    }
                  },
                },
              });
            })
            .catch((errorSalariatModule) => {
              console.log(errorSalariatModule);
            });
        })
        .catch((errorTemplate) => {
          console.log(errorTemplate);
        });
    });
  };

  render() {
    return (
      <>
        {this.$f7.data.rolAdevarat < 6 ? (
          <>
            <SearchPopover denumire={this.denumireSearch} />
            <NotificationsPopover denumire={this.denumireNotificari} />
            <MessagesPopover denumire={this.$f7.data.denumireMesaje} />
            <UserPopover denumire={this.denumireUtilizator} />
          </>
        ) : null}
        {this.$f7.data.rolAdevarat < 6 ? (
          <NavRight>
            {this.$f7.data.rolAdevarat === 1 ? (
              <Chip
                style={{ color: "white" }}
                text={this.state.rol}
                onClick={() => {
                  this.$f7.data.rol =
                    this.$f7.data.rol > 1 ? this.$f7.data.rol - 1 : 5;
                  this.setState({ rol: this.$f7.data.rol });
                  this.$f7.views.main.router.refreshPage();
                }}
              />
            ) : null}

            <Link
              tooltip={"Task nou"}
              iconMaterial={"add"}
              onClick={() => this.taskNou("Adaugare task")}
            />
            <Link
              tooltip={"Cautare"}
              searchbarEnable={".Searchbar" + this.denumireSearch}
              iconMaterial={"search"}
            />
            <Link
              iconOnly
              tooltip={"Notificari"}
              onClick={(e) => {
                var panelNotificari = this.$f7.panel.get(
                  "." + this.denumireNotificari
                );
                if (panelNotificari) {
                  panelNotificari.toggle();
                }
              }}>
              <Icon material={"notifications"}>
                {this.state.countPrimite > 0 ? (
                  <Badge color="red">{this.state.countPrimite}</Badge>
                ) : null}
              </Icon>
            </Link>

            <Link
              iconOnly
              tooltip={"Mesaje"}
              style={{
                marginLeft: 5,
              }}
              onClick={(e) => {
                // if (this.$f7.data.draggingModal) {
                //     let dm = this.$f7.data.draggingModal;

                //     dm.openModal(SesizariChat)
                // }

                this.setState(
                  {
                    countMesaje: 0,
                  },
                  () => {
                    var panelMesaje = this.$f7.panel.get(
                      "." + this.$f7.data.denumireMesaje
                    );
                    if (panelMesaje) {
                      panelMesaje.toggle();
                    }
                  }
                );
              }}>
              <Icon material={"forum"}>
                {this.state.countMesaje > 0 ? (
                  <Badge color="red">{this.state.countMesaje}</Badge>
                ) : null}
              </Icon>
            </Link>

            <Link
              iconOnly
              tooltip={"Emailuri"}
              style={{
                marginLeft: 5,
              }}
              onClick={(e) => {
                this.$f7.data.router.navigate("/popupDinamic/", {
                  props: {
                    onPopupClose: (r) => {},
                    title: "Gmail",
                    component: <EmailPopup />,
                  },
                });
              }}>
              <Icon material={"mail_outline"}>
                {/* {this.state.countMesaje > 0 ? (
									<Badge color="red">{this.state.countMesaje}</Badge>
								) : null} */}
              </Icon>
            </Link>

            <Link
              iconOnly
              tooltip={"Utilizator"}
              style={{
                marginLeft: 5,
              }}
              onClick={(e) => {
                var panelUtilizator = this.$f7.panel.get(
                  "." + this.denumireUtilizator
                );
                if (panelUtilizator) {
                  panelUtilizator.toggle();
                }
              }}>
              <Icon material={"account_circle"}>
                {this.state.taskIsActiv ? <Badge color="teal" /> : null}
              </Icon>
            </Link>
          </NavRight>
        ) : (
          <NavLeft>
            <Link
              iconOnly
              tooltip={"Delogare"}
              style={{
                position: "absolute",
                right: 5,
              }}
              onClick={() => {
                this.$f7.dialog.confirm(
                  "Doriti sa va delogati?",
                  "Delogare",
                  () => {
                    this.$f7.data.signalRConnection.stop();
                    this.$f7.methods
                      .logout()
                      .then((response) => {
                        this.$f7.methods.setCookie("user", "", 90);
                        this.$f7.methods.setCookie("parola", "", 90);
                        this.$f7.methods.setCookie("repornire", "", 90);
                        delete axios.defaults.headers.common["Authorization"];
                        this.$f7.views.main.router.navigate("/login/");
                        var data = {
                          baseURL: "",
                          iconita: "menu",
                          user: undefined,
                          datagrids: {},
                          toggleTotiSalariatii: false,
                          timerUltimaActivitate: 5,
                          rol: null,
                          rolAdevarat: null,
                          dataSourcePlanificare: [],
                        };
                        this.$f7.data = data;
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare delogare" + err
                        );
                      });
                  }
                );
              }}>
              <Icon material={"logout"} />
            </Link>
          </NavLeft>
        )}
        <List style={{ display: "none" }}>
          <ListItem
            className="smSee"
            validate
            smartSelect
            smartSelectParams={{
              virtualList: true,
              searchbar: true,
              openIn: "popup",
              popupTabletFullscreen: true,
              popupPush: true,
              closeOnSelect: true,
            }}>
            <select name="SelectBoxBool">
              {this.Salariati.map((obj, index) => (
                <option
                  key={"smSeeTopRight-" + index}
                  selected={this.Salariati[0].value}
                  value={obj.value}>
                  {obj.key}
                </option>
              ))}
            </select>
          </ListItem>
        </List>
      </>
    );
  }
}
export default TopRight;
