import React, { Suspense } from "react";
import { Navbar, Page, Preloader, Sheet } from "framework7-react";

/**
 * @description Sheet dinamic care primeste ca parametru o componenta care sa o randeze inauntru
 * @date 08/04/2022
 * @class SheetDinamic
 * @extends {React.Component}
 */
class SheetDinamic extends React.Component {
  render() {
    return (
      <Sheet
        onSheetOpened={() => {
          this.$f7.data.SheetDinamicThis = this;
        }}
        className={"SheetDinamic"}
        closeOnEscape={!this.props.disableClose}
        tabletFullscreen={true}
        closeByBackdropClick={!this.props.disableClose}
        closeByOutsideClick={!this.props.disableClose}>
        <Page pageContent={false}>
          <Navbar
            backLink={!this.props.disableClose}
            title={this.props.title}
            noShadow
            sliding={false}></Navbar>
          <div
            style={{
              maxHeight: "calc(50vh - 35px)",
              padding: "34px 8px 8px 8px",
              overflow: "auto",
            }}>
            <Suspense
              fallback={
                <div
                  style={{
                    width: "35px",
                    margin: "0 auto",
                  }}>
                  <Preloader
                    size={40}
                    color={"var(--f7-theme-color)"}></Preloader>
                </div>
              }>
              {this.props.component}
            </Suspense>
          </div>
        </Page>
      </Sheet>
    );
  }
}
export default SheetDinamic;
