import React from "react";
import { Page } from "framework7-react";
import ATSalariati from "./ATSalariati";

class ATSalariatiPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { denumire: "DepartamentePage" };
    }
    render() {
        return (
            <Page
                pageContent={false}
                style={{ paddingTop: 0 }}
                className={"themeBackground no-padding"}
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
            >
                <ATSalariati />
            </Page>
        );
    }
}
export default ATSalariatiPage;