import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import { Segmented, Button } from "framework7-react";
import axios from "axios";
import moment from "moment";

/**
 * @description Interfata in care sunt randate toate taskurile salariatului curent -logat daca este utilizator normal sau selectat in cazul unui administrator
 * @date 08/04/2022
 * @class SarciniRezolvateGrid
 * @extends {React.Component}
 */
class SarciniRezolvateGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoaded: false,
      denumire: "Sarcini rezolvate",
      height: window.innerHeight - 96,
      linkApi: "/api/SarciniSalariat",

      dataSource: [],
      inputs: [
        { key: "💾", keyBaza: "areDocumente", tip: "boolean", disabled: true },
        { key: "IdSarcina", keyBaza: "idSarcina", tip: "number" },
        { key: "IdProiect", keyBaza: "idProiect", tip: "number" },
        { key: "Denumire", keyBaza: "denumire", columnWidth: "250px" },
        { key: "Cerinta", keyBaza: "comentariu", columnWidth: "500px" },
        {
          key: "DataCreare",
          keyBaza: "dataCreare",
          tip: "datebox",
          columnWidth: "100px",
        },
        {
          key: "DataInchiderii",
          keyBaza: "dataAlocarii",
          tip: "datebox",
          columnWidth: "100px",
        },
        { key: "Deadline", keyBaza: "deadline", tip: "datebox" },
        {
          key: "Client",
          keyBaza: "idClient",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
          columnWidth: "20px",
        },
        { key: "Sursa", keyBaza: "sursa", columnWidth: "20px" },
        { key: "Parinte", keyBaza: "parinte", columnWidth: "20px" },
        {
          key: "PR",
          keyBaza: "idPrioritate",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
          columnWidth: "20px",
        },
        {
          key: "IsHidden",
          keyBaza: "isHidden",
          columnWidth: "20px",
          disabled: true,
          tip: "bool",
        },
        {
          key: "IsArhivat",
          keyBaza: "isArhivat",
          columnWidth: "20px",
          disabled: true,
          tip: "bool",
        },
        { key: "UltimaStare", keyBaza: "ultimaStare", tip: "number" },
        {
          key: "NivelProiect",
          keyBaza: "id_nivel_proiect",
          tip: "selectbox",
          lista: this.$f7.data.NivelProiect,
        },
        { key: "Path", keyBaza: "path" },
        {
          key: "OperatorAdaugare",
          keyBaza: "id_operator_adaugare",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
        { key: "BugetTimp", keyBaza: "buget_timp", tip: "number" },
        { key: "TimpPlanD", keyBaza: "timp_plan_d", tip: "number" },
        { key: "F", keyBaza: "facturat", disabled: true, tip: "bool" },
        { key: "TimpLucrat", keyBaza: "timp_l", tip: "number" },
        { key: "PVuri", keyBaza: "apartenenta_pv" },
      ],

      idSelected: null,
      idSarcina: null,
    };

    this.refDataGrid = React.createRef();
  }

  /**
   * @description Parcurge o lista de taskuri inchise si apeleaza pentru fiecare functia de redeschidere task
   * @function
   * @date 08/04/2022
   * @memberof SarciniRezolvateGrid
   */
  RedeschideCall = () => {
    this.$f7.dialog.confirm(
      "Sunteti sigur ca doriti sa redeschideti toate sarcinile selectate?",
      "Redeschidere",
      (e) => {
        this.$f7.methods
          .preCall(this.refDataGrid)
          .then((response) => {
            this.$f7.preloader.show();
            var state = this.refDataGrid.current.state;
            if (state.selectedRows.length > 0) {
              state.selectedRows
                .reduce((promise, item) => {
                  return promise.then(() => {
                    return this.RedeschideTask(item.IdProiect, item.IdSarcina)
                      .then((r) => {})
                      .catch((err) => {
                        console.log("err", err);
                      });
                  });
                }, Promise.resolve())
                .then(() => {
                  this.GetValues();
                  this.$f7.preloader.hide();
                  this.$f7.methods.openNotification("Taskuri redeschise.");
                })
                .catch(() => {
                  console.log("Eroare");
                });
            } else {
              this.RedeschideTask()
                .then((response) => {
                  this.GetValues();
                  this.$f7.preloader.hide();
                  this.$f7.methods.openNotification("Task redeschis.");
                })
                .catch((error) => {
                  console.log("error", error);
                });
            }
          })
          .catch((err) => {
            this.$f7.methods.openNotification("Eroare" + err.toString());
          });
      }
    );
  };

  componentDidMount = () => {
    this.$f7.data.selectedGridLeft = this;
    this.GetValues();
  };

  /**
   * @description Ia valorile necesare randarii interfetei, toate taskurile rezolvate/inchise ale utilizatorului selectat
   * @function
   * @date 08/04/2022
   * @memberof SarciniRezolvateGrid
   */
  GetValues = () => {
    this.setState(
      {
        dataLoaded: false,
        idSelected: null,
      },
      () => {
        if (this.props.outside) {
          this.$f7.data.Proiect = null;
          this.props.updateDreapta();
        } else {
          this.$f7.methods.openMainDreaptaById(null);
        }
      }
    );

    this.$f7.methods
      .getProiecte(
        this.$f7.data.idSalariatFunctii,
        4,
        this.props.checkedTaskuri === true ? 1 : 0,
        this.props.checkedAscunse === true ? 1 : 0,
        this.props.checkedArhivate === true ? 1 : 0,
        1
      )
      .then((response) => {
        var date = response;

        date.data.map((sarcina) => {
          //Valori din proiect
          if (sarcina.proiect) {
            Object.keys(sarcina.proiect).map((property) => {
              var value = sarcina.proiect[property];
              if (sarcina[property] === undefined) {
                sarcina[property] = value;
              }
              return null;
            });
            sarcina["path"] = sarcina.proiect["path"];
            if (sarcina["path"]) {
              var path = "";
              sarcina["path"].split("\\").map((f) => {
                if (f !== null && f !== undefined) {
                  var idProiect = parseInt(f);
                  var pro = this.$f7.data.allProiecte.find((p) => {
                    return p.iD_PROIECT === idProiect;
                  });
                  if (pro) {
                    path += pro.denumire + " \\ ";
                  }
                }
                return null;
              });
              sarcina["path"] = path.slice(0, -2);
            }
          }
          sarcina = this.$f7.methods.restructurareBaza(
            sarcina,
            this.state.inputs,
            "key"
          );

          switch (sarcina.Sursa) {
            case 55:
              sarcina.Sursa =
                "M(" + (sarcina.proiect.userImport % 10000000) + ")";
              break;
            case 56:
              sarcina.Sursa = "E";
              break;
            default:
              sarcina.Sursa = "C";
              break;
          }

          return false;
        });

        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Redeschide taskul selectat
   * @date 08/04/2022
   * @param {*} idSelected id-ul proiectului selectat
   * @param {*} idSarcina id-ul sarcinii selectate
   * @function
   * @memberof SarciniRezolvateGrid
   */
  RedeschideTask = (idSelected, idSarcina) => {
    var idSelectedLocal =
      idSelected !== undefined ? idSelected : this.state.idSelected;
    var idSarcinaLocal =
      idSarcina !== undefined ? idSarcina : this.state.idSarcina;
    if (idSelectedLocal) {
      if (this.props.outside) {
        this.$f7.data.Proiect = null;
        this.props.updateDreapta();
      } else {
        this.$f7.methods.openMainDreaptaById(null);
      }
      return new Promise((resolve, reject) => {
        axios
          .get(
            this.$f7.data.baseURL +
              this.state.linkApi +
              "/GetSARCINI_SALARIAT/" +
              idSarcinaLocal
          )
          .then((response) => {
            let sarcina = response.data;

            sarcina.dATA_DEALOCARII = moment().format();
            sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT; //notsure

            let urlUpdate =
              this.$f7.data.baseURL +
              this.state.linkApi +
              "/PutSARCINI_SALARIAT/" +
              sarcina.iD_SARCINA;

            axios({
              data: sarcina,
              headers: { "Content-Type": "application/json" },
              method: "put",
              url: urlUpdate,
            })
              .then((response) => {
                var urlInserare =
                  this.$f7.data.baseURL +
                  this.state.linkApi +
                  "/PostSARCINI_SALARIAT";

                let sarcinaNoua = {
                  iD_PROIECT: sarcina.iD_PROIECT,
                  iD_SALARIAT: this.$f7.data.idSalariatFunctii,
                  dE_CINE: this.$f7.data.user.iD_SALARIAT, //notsure
                  datA_ALOCARII: moment().format(),
                  datA_DEALOCARII: null,
                  dealoC_CINE: null,
                  stare: 1,
                };

                axios({
                  data: sarcinaNoua,
                  headers: { "Content-Type": "application/json" },
                  method: "post",
                  url: urlInserare,
                })
                  .then((response) => {
                    resolve(response);
                  })
                  .catch((error) => {
                    console.log("error", error);
                    this.$f7.methods.openNotification("Eroare la inserare");
                    reject(error);
                  });
              })
              .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la actualizare");
                reject(error);
              });
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la preluare");
            reject(error);
          });
      });
    } else {
      this.$f7.methods.openNotification("Selectati o sarcina");
    }
  };

  render() {
    return (
      <>
        <div style={{ position: "relative" }}>
          <Segmented
            className="butoaneContextDatagrid"
            raised
            tag="div"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "calc(100% - 176px)",
            }}>
            <Button
              tooltip={"Redeschide taskul"}
              fill
              small
              onClick={() => {
                this.RedeschideCall();
              }}>
              Redeschide task
            </Button>
            <Button
              tooltip={"Refresh"}
              fill
              small
              onClick={() => {
                this.GetValues();
              }}>
              Refresh
            </Button>
          </Segmented>

          <DataGrid
            dataLoaded={this.state.dataLoaded}
            refresh={this.GetValues}
            name={"SarciniRezolvateGrid"}
            ref={this.refDataGrid}
            id={"IdSarcina"}
            onRowClick={(state) => {
              var id = state.rowData.IdProiect;
              var sarcina = state.rowData.IdSarcina;
              this.setState(
                {
                  idSelected: id,
                  idSarcina: sarcina,
                },
                () => {
                  if (this.props.outside) {
                    var Proiect = this.$f7.data.allProiecte.filter((pr) => {
                      return pr.iD_PROIECT === id;
                    })[0];
                    this.$f7.data.Proiect = Proiect;
                    this.props.updateDreapta();
                  } else {
                    this.$f7.methods.openMainDreaptaById(this.state.idSelected);
                  }
                }
              );
            }}
            keys={[
              "💾",
              "Denumire",
              "Cerinta",
              "DataInchiderii",
              "Client",
              "Sursa",
              "PR",
              "F",
            ]}
            renderUpdate={false}
            hintInputSources={[
              "Path",
              "OperatorAdaugare",
              "BugetTimp",
              "TimpPlanD",
              "PVuri",
            ]}
            renderDelete={false}
            renderAdd={false}
            renderCheckbox={true}
            maxHeight={this.state.height}
            inputs={this.state.inputs}
            onInitialised={(state, r, itemeRandate) => {
              this.$f7.methods.colorItems(
                state,
                r,
                itemeRandate,
                "Salariat",
                1,
                true
              );
            }}
            onRenderItemsChanged={(state, r, itemeRandate) => {
              this.$f7.methods.colorItems(
                state,
                r,
                itemeRandate,
                "Salariat",
                0,
                true
              );
            }}
            dataSource={this.state.dataSource}
            contextMenuItems={[
              {
                key: "Redeschide task",
                onClick: (state) => {
                  setTimeout(() => {
                    this.RedeschideCall();
                  }, 1000);
                },
                icon: "cached",
              },
              {
                key: "Refresh",
                onClick: (state) => {
                  setTimeout(() => {
                    this.GetValues();
                  }, 1000);
                },
                icon: "refresh",
              },
              {
                key: "Arhiveaza",
                onClick: (state) => {
                  setTimeout(() => {
                    var selectedRows = state.allSelectedRows;
                    if (selectedRows.length === 0) {
                      selectedRows = [state.rowDataAll];
                    }
                    var listaIduri = [];
                    selectedRows.map((r) => {
                      var id = r.IdProiect;
                      if (!r.IsArhivat) {
                        listaIduri.push(id);
                      }
                      return null;
                    });
                    this.$f7.methods
                      .arhiveazaDezarhiveazaProiect(listaIduri, true)
                      .then((r) => {
                        this.GetValues();
                        const nr = r.data.length;
                        this.$f7.methods.openNotification(
                          "Arhivate cu success: " + nr
                        );
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare la arhivare" + err
                        );
                      });
                  }, 1000);
                },
                icon: "compress",
              },
              {
                key: "Dezarhiveaza",
                onClick: (state) => {
                  setTimeout(() => {
                    var selectedRows = state.allSelectedRows;
                    if (selectedRows.length === 0) {
                      selectedRows = [state.rowDataAll];
                    }
                    var listaIduri = [];
                    selectedRows.map((r) => {
                      var id = r.IdProiect;
                      if (r.IsArhivat) {
                        listaIduri.push(id);
                      }
                      return null;
                    });
                    this.$f7.methods
                      .arhiveazaDezarhiveazaProiect(listaIduri, false)
                      .then((r) => {
                        this.GetValues();
                        const nr = r.data.length;
                        this.$f7.methods.openNotification(
                          "Dezarhivate cu success: " + nr
                        );
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare la dezarhivare" + err
                        );
                      });
                  }, 1000);
                },
                icon: "expand",
              },
              {
                key: "Ascunde",
                onClick: (state, context) => {
                  setTimeout(() => {
                    var selectedRows = state.allSelectedRows;
                    if (selectedRows.length === 0) {
                      selectedRows = [state.rowDataAll];
                    }
                    var listaIduri = [];
                    selectedRows.map((r) => {
                      var id = r.IdProiect;
                      if (!r.IsHidden) {
                        listaIduri.push(id);
                      }
                      return null;
                    });
                    this.$f7.methods
                      .ascundeAfiseazaProiect(listaIduri, true)
                      .then(() => {
                        this.GetValues();
                        this.$f7.methods.openNotification("Ascuns cu success");
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare la ascundere" + err
                        );
                      });
                  }, 1000);
                },
                icon: "refresh",
              },
              {
                key: "Afiseaza",
                onClick: (state, context) => {
                  setTimeout(() => {
                    var selectedRows = state.allSelectedRows;
                    if (selectedRows.length === 0) {
                      selectedRows = [state.rowDataAll];
                    }
                    var listaIduri = [];
                    selectedRows.map((r) => {
                      var id = r.IdProiect;
                      if (r.IsHidden) {
                        listaIduri.push(id);
                      }
                      return null;
                    });

                    this.$f7.methods
                      .ascundeAfiseazaProiect(listaIduri, false)
                      .then(() => {
                        this.GetValues();
                        this.$f7.methods.openNotification("Afisat cu success");
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare la afisare" + err
                        );
                      });
                  }, 1000);
                },
                icon: "refresh",
              },
              {
                key: "Trimite mesaj",
                onClick: (state) => {
                  var id = state.rowData.IdProiect;
                  this.$f7.methods.openMesaje(null, id);
                },
                icon: "send",
              },
            ]}
            onContextMenu={(state, target) => {
              var id = state.rowData.IdProiect;
              var sarcina = state.rowData.IdSarcina;
              this.setState(
                {
                  idSelected: id,
                  idSarcina: sarcina,
                },
                () => {
                  if (this.props.outside) {
                    var Proiect = this.$f7.data.allProiecte.filter((pr) => {
                      return pr.iD_PROIECT === id;
                    })[0];
                    this.$f7.data.Proiect = Proiect;
                    this.props.updateDreapta();
                  } else {
                    this.$f7.methods.openMainDreaptaById(this.state.idSelected);
                  }
                }
              );
            }}
          />
        </div>
      </>
    );
  }
}
export default SarciniRezolvateGrid;
