import React from "react";
import { Page, Icon, Row, Col, List, ListItem } from "framework7-react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import DataGrid from "../Datagrid/DataGrid";
import axios from "axios";

var moment = require("moment");
const localizer = momentLocalizer(moment);

/**
 * @description Interfata de planificare a taskului intr-un interval
 * @date 08/04/2022
 * @class PlanificarePage
 * @extends {React.PureComponent}
 */
class PlanificarePage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;

    this.state = {
      denumire: Math.random().toString(36).substring(7).toString(),
      salariatiDatagridHeight: (window.innerHeight - 64) / 2 - 70,
      taskuriDatagridHeight: (window.innerHeight - 64) / 2 - 70,
      height: window.innerHeight - 44,

      linkApiSalariati: "/api/Salariati/GetSALARIATI",
      linkApiPlanificareTask: "/api/Planificare/GetTasks",
      linkApiPlanificareCalendar: "/api/Planificare/GetCalendar",
      linkApiPlanificareAdd: "/api/Planificare/PostEvent",

      stadiiArray: this.$f7.data.Stadiu,

      salariatiDataSource: [],
      taskuriDataSource: [],
      calendarDataSource: [],

      stadiuSelectat: -1,
      idSalariat: this.$f7.data.user.iD_SALARIAT,
      taskSelectat: null,
      eventStart: [moment(Date.now()).format()],
      eventFinish: [moment(Date.now()).format()],

      selectBoxChangeLoaded: true,
      taskuriDataLoaded: true,
      inputsPlanificareProiect: [
        { key: "Id", tip: "number" },
        { key: "ProiectParinte" },
        { key: "Denumire" },
        { key: "Start", tip: "datebox" },
        { key: "Deadline", tip: "datebox" },
      ],
      inputsPlanificareSalariat: [
        {
          key: "Salariat",
          keyBaza: "iD_SALARIAT",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
        {
          key: "Departament",
          keyBaza: "iD_DEPARTAMENT",
          tip: "selectbox",
          lista: this.$f7.data.Departamente,
        },
        {
          key: "Profesie",
          keyBaza: "iD_PROFESIE",
          tip: "selectbox",
          lista: this.$f7.data.Profesii,
        },
      ],
    };
    this.ref1 = React.createRef();
    this.ref2 = React.createRef();
    this.ref3 = React.createRef();
  }

  /**
   * @description Ia valorile cu taskurile salariatului
   * @function
   * @date 08/04/2022
   * @memberof PlanificarePage
   */
  GetValues = () => {
    this.setState({
      selectBoxChangeLoaded: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApiSalariati)
      .then((response) => {
        let change = response;

        change.data.map((eveniment) => {
          eveniment = this.$f7.methods.restructurareBaza(
            eveniment,
            this.state.inputsPlanificareSalariat,
            "key"
          );
          return false;
        });

        let baza = this.$f7.methods.reconstructieBaza(
          change,
          this.state.inputsPlanificareSalariat
        );

        this.setState(
          {
            salariatiDataSource: baza.length === 0 ? [] : baza,
          },
          () => {
            // this.GetTaskValues();

            this.setState({
              selectBoxChangeLoaded: true,
            });
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            salariatiDataSource: [],
            selectBoxChangeLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor despre salariati de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };
  /**
   * @description Ia valorile taskurilor salariatului selectat per stadiul selectat
   * @function
   * @date 08/04/2022
   * @memberof PlanificarePage
   */
  GetTaskValues = () => {
    if (this.state.stadiuSelectat !== null) {
      this.setState({
        taskuriDataLoaded: false,
      });

      axios
        .get(
          this.$f7.data.baseURL +
            this.state.linkApiPlanificareTask +
            "/" +
            this.state.idSalariat +
            "/" +
            this.state.stadiuSelectat
        )
        .then((response) => {
          let change = response;

          let eventsArray = [];

          this.state.taskuriDataSource.map((task) => {
            response.data.map((item) => {
              if (item.taskIndexField === task.Id) {
                let itemObj = {};

                itemObj["id"] = item.id;
                itemObj["title"] = item.caption;
                itemObj["start"] = new Date(item.start);
                itemObj["end"] = new Date(item.finish);

                eventsArray.push(itemObj);
              }
              return null;
            });
            return null;
          });

          for (let item in change.data) {
            change.data[item]["Id"] = change.data[item].id_proiect;
            change.data[item]["ProiectParinte"] = change.data[item].proiect;
            change.data[item]["Denumire"] = change.data[item].denumire;
            change.data[item]["Start"] = change.data[item].data_start;
            change.data[item]["Deadline"] = change.data[item].deadline;
            let itemObj = {};
            itemObj["id"] = change.data[item]["Id"];
            itemObj["title"] = change.data[item]["Denumire"];
            itemObj["start"] = moment(change.data[item]["Start"]).format();
            itemObj["end"] = moment(change.data[item]["Deadline"]).format();

            eventsArray.push(itemObj);
          }
          console.log("events", eventsArray);

          let baza = this.$f7.methods.reconstructieBaza(
            change,
            this.state.inputsPlanificareProiect
          );

          this.setState({
            taskuriDataSource: baza,
            calendarDataSource: eventsArray,
            taskuriDataLoaded: true,
          });
        })
        .catch((error) => {
          this.setState(
            {
              taskuriDataSource: [],
              taskuriDataLoaded: true,
            },
            () => {
              this.$f7.dialog.confirm(
                "A aparut o eroare la preluarea datelor despre taskuri de pe server. Incearca din nou.",
                "Ups...",
                () => {
                  this.GetTaskValues();
                },
                () => {}
              );
            }
          );

          console.log("error", error);
        });
    } else {
      this.$f7.methods.openNotification("Trebuie selectat un stadiu!");
    }
  };

  /**
   * @description Adauga o planificare pentru un task in intervalul selectat
   * @date 08/04/2022
   * @param {*} data obiect cu informatiile planificarii
   * @function
   * @memberof PlanificarePage
   */
  AddValue = (data) => {
    let initialActualStartData = new Date(data.ActualStart);
    let initialActualFinishData = new Date(data.ActualFinish);
    let initialStartData = new Date(data.Start);
    let initialFinishData = new Date(data.Finish);

    const actualStartData =
      initialActualStartData.getMonth() +
      1 +
      "-" +
      initialActualStartData.getDate() +
      "-" +
      initialActualStartData.getFullYear().toString().substring(2) +
      " " +
      initialActualStartData.getHours() +
      ":" +
      initialActualStartData.getMinutes() +
      ":" +
      initialActualStartData.getSeconds();
    const actualFinishData =
      initialActualFinishData.getMonth() +
      1 +
      "-" +
      initialActualFinishData.getDate() +
      "-" +
      initialActualFinishData.getFullYear().toString().substring(2) +
      " " +
      initialActualFinishData.getHours() +
      ":" +
      initialActualFinishData.getMinutes() +
      ":" +
      initialActualFinishData.getSeconds();
    const startData =
      initialStartData.getMonth() +
      1 +
      "-" +
      initialStartData.getDate() +
      "-" +
      initialStartData.getFullYear().toString().substring(2) +
      " " +
      initialStartData.getHours() +
      ":" +
      initialStartData.getMinutes() +
      ":" +
      initialStartData.getSeconds();
    const finishData =
      initialFinishData.getMonth() +
      1 +
      "-" +
      initialFinishData.getDate() +
      "-" +
      initialFinishData.getFullYear().toString().substring(2) +
      " " +
      initialFinishData.getHours() +
      ":" +
      initialFinishData.getMinutes() +
      ":" +
      initialFinishData.getSeconds();

    data.ActualStart = actualStartData;
    data.ActualFinish = actualFinishData;
    data.Start = startData;
    data.Finish = finishData;

    var url = this.$f7.data.baseURL + this.state.linkApiPlanificareAdd;
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "POST",
      url: url,
    })
      .then((response) => {
        this.$f7.methods.openNotification("Adaugat cu success");
        this.GetTaskValues();
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  componentDidMount() {
    this.GetValues();
  }

  render() {
    return (
      <Page
        onPageInit={() => {
          this.$f7.data.paginaCurenta = this;
        }}
        id={"PlanificareMain" + this.state.denumire}
        className={"themeBackground"}>
        <div className="ptr3">
          <Row>
            <Col
              ref={this.ref1}
              onGridResize={() => {
                localStorage.setItem(
                  "PlanificareWidth1",
                  this.ref1.current.eventTargetEl.style.width
                );
              }}
              style={{
                width:
                  localStorage.getItem("PlanificareWidth1") === null
                    ? "32%"
                    : localStorage.getItem("PlanificareWidth1"),
              }}
              resizable>
              <div
                style={{
                  position: "relative",
                  overflow: "hidden",
                }}>
                <List
                  className="optiuniPlanificare butoaneContextDatagrid"
                  style={{
                    position: "absolute",
                    top: -8,
                    left: 0,
                    width: "calc(100% - 176px)",
                    margin: 0,
                  }}>
                  <Row>
                    <Col>
                      {this.state.selectBoxChangeLoaded ? (
                        <ListItem
                          className="roundedOutlinedSmartSelect"
                          floatingLabel
                          value={this.state.stadiuSelectat}
                          label={"Stadiu"}
                          title={"Stadiu"}
                          validate
                          smartSelect
                          smartSelectParams={{
                            virtualList: true,
                            closeOnSelect: true,
                            searchbar: true,
                            on: {
                              closed: (event) => {
                                let ev = event.getValue();

                                this.setState(
                                  {
                                    stadiuSelectat:
                                      ev === "Fara" ? null : parseInt(ev),
                                  },
                                  () => {
                                    this.GetTaskValues();
                                  }
                                );
                              },
                            },
                          }}>
                          <select
                            name="Stadiu"
                            defaultValue={this.state.stadiuSelectat}>
                            {this.state.stadiiArray.map((obj, index) => (
                              <option
                                key={"stadiiPlanificare-" + index}
                                label={obj.key}
                                value={obj.value}>
                                {obj.key}
                              </option>
                            ))}
                          </select>
                          <Icon
                            slot="content-start"
                            style={{
                              color: "var(--f7-theme-color)",
                              left: 0,
                              height: 14,
                            }}
                            size={20}
                            material="list"
                          />
                        </ListItem>
                      ) : null}
                    </Col>
                    <Col>
                      <ListItem
                        className="simpleInlineCheckbox"
                        checkbox
                        title="Toate stadiile"
                        name="stadiile"
                        checked={this.state.stadiuSelectat === -1}
                        onChange={(e) => {
                          this.setState(
                            {
                              selectBoxChangeLoaded: false,
                              stadiuSelectat: e.currentTarget.checked ? -1 : 1,
                            },
                            () => {
                              this.GetTaskValues();

                              this.setState({
                                selectBoxChangeLoaded: true,
                              });
                            }
                          );
                        }}
                      />
                    </Col>
                  </Row>
                </List>

                <DataGrid
                  name={"Planificare1"}
                  keys={["Salariat"]}
                  renderUpdate={false}
                  renderDelete={false}
                  renderAdd={false}
                  maxHeight={this.state.height}
                  inputs={this.state.inputsPlanificareSalariat}
                  dataSource={this.state.salariatiDataSource}
                  onRowClick={(e) =>
                    this.setState(
                      {
                        idSalariat: e.rowData.Salariat,
                        taskSelectat: null,
                      },
                      () => {
                        this.GetTaskValues();
                      }
                    )
                  }
                />
              </div>
            </Col>
            <Col
              ref={this.ref2}
              onGridResize={() => {
                localStorage.setItem(
                  "PlanificareWidth2",
                  this.ref2.current.eventTargetEl.style.width
                );
              }}
              style={{
                width:
                  localStorage.getItem("PlanificareWidth2") === null
                    ? "33%"
                    : localStorage.getItem("PlanificareWidth2"),
              }}
              resizable>
              <div
                style={{
                  position: "relative",
                  overflow: "hidden",
                }}>
                <DataGrid
                  dataLoaded={this.state.taskuriDataLoaded}
                  refresh={this.GetTaskValues}
                  name={"Planificare2"}
                  renderUpdate={false}
                  renderDelete={false}
                  renderAdd={false}
                  maxHeight={this.state.height}
                  inputs={this.state.inputsPlanificareProiect}
                  dataSource={this.state.taskuriDataSource}
                  onRowClick={(e) => {
                    let data = Object.assign({}, e.rowData);

                    data["TaskIndexField"] = data.Id;
                    delete data.Id;

                    delete data.ProiectParinte;

                    data["Caption"] = data.Denumire;
                    delete data.Denumire;

                    data["ActualStart"] = data.Start;
                    delete data.Start;

                    data["ActualFinish"] = data.Deadline;
                    delete data.Deadline;

                    data["Start"] = this.state.eventStart[0];
                    data["Finish"] = this.state.eventFinish[0];
                    data["ResourceId"] = this.state.idSalariat;

                    delete data.TimpEstimatOre;
                    delete data.TimpNeplanificatOre;
                    delete data.ArePlanificare;

                    this.setState(
                      {
                        taskSelectat: data,
                      },
                      () => {}
                    );
                  }}
                  coloredRow={{
                    sourceCol: "Deadline",
                    type: "date",
                  }}
                />
              </div>
            </Col>
            <Col
              ref={this.ref3}
              onGridResize={() => {
                localStorage.setItem(
                  "PlanificareWidth3",
                  this.ref3.current.eventTargetEl.style.width
                );
              }}
              style={{
                width:
                  localStorage.getItem("PlanificareWidth3") === null
                    ? "32%"
                    : localStorage.getItem("PlanificareWidth3"),
              }}
              resizable>
              <Calendar
                popup
                showMultiDayTimes
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                events={this.state.calendarDataSource}
                defaultView={Views.DAY}
                views={["month", "work_week", "week", "day", "agenda"]}
                style={{
                  height: this.state.height - 5,
                  width: "100%",
                  overflow: "hidden",
                }}
              />
            </Col>
          </Row>
        </div>
      </Page>
    );
  }
}
export default PlanificarePage;
