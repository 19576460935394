import React from "react";
import DataGrid from "../Datagrid/DataGrid";
import axios from "axios";

/**
 * @description Interfata de intretinere/afisare a detaliilor unei delegatii
 * @date 08/04/2022
 * @class DelegatiiDetalii
 * @extends {React.Component}
 */
class DelegatiiDetalii extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      linkApi: "/api/DetaliuDelegatii",
      denumire: "DetaliuDelegatii",
      height: window.innerHeight - 34,
      dataSource: [],
      inputs: [
        { key: "Id", keyBaza: "iD_DETALIU_DELEGATIE", tip: "number" },
        { key: "Activitate", keyBaza: "activitate", tip: "number" },
        { key: "IdDelegatie", keyBaza: "iD_DELEGATIE", tip: "number" },
        {
          key: "Faza",
          keyBaza: "iD_FAZA",
          tip: "selectbox",
          lista: this.$f7.data.TipFaze,
        },
        {
          key: "Firma",
          keyBaza: "iD_FIRMA",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
        },
        {
          key: "Proiect",
          keyBaza: "iD_PROIECT",
          tip: "selectbox",
          lista: this.$f7.data.Proiecte,
        },
        {
          key: "Task",
          keyBaza: "iD_TASK",
          tip: "selectbox",
          lista: this.$f7.data.Proiecte,
        },
        { key: "LatPlecare", keyBaza: "plecarE_LAT", tip: "number" },
        { key: "LngPlecare", keyBaza: "plecarE_LNG", tip: "number" },
        { key: "LatSosire", keyBaza: "sosirE_LAT", tip: "number" },
        { key: "LngPlecare", keyBaza: "sosirE_LNG", tip: "number" },
        { key: "Plecare", keyBaza: "plecare", tip: "datebox" },
        { key: "Sosire", keyBaza: "sosire", tip: "datebox" },
        { key: "Preluat", keyBaza: "preluat", tip: "bool" },
      ],
    };
    this.refDataGrid = React.createRef();
  }
  /**
   * @description Ia inregistrarile detaliilor delegatiei trimise prin props(idDelegatie)
   * @function
   * @date 08/04/2022
   * @memberof DelegatiiDetalii
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetDetaliuDelegatieCurentaTot/" +
          this.props.idDelegatie
      )
      .then((response) => {
        console.log("response", response);

        response.data.map((delegatie) => {
          delegatie = this.$f7.methods.restructurareBaza(
            delegatie,
            this.state.inputs,
            "key"
          );
          return false;
        });

        var baza = this.$f7.methods.reconstructieBaza(
          response,
          this.state.inputs
        );

        console.log("baza", baza);
        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Adauga o inregistrare noua cu referinta la delegatia selectata(trimisa prin props idDelegatie)
   * @function
   * @date 08/04/2022
   * @param {*} data obiect cu valorile noii inregistrari
   * @memberof DelegatiiDetalii
   */
  AddValue = (data) => {
    var url =
      this.$f7.data.baseURL + this.state.linkApi + "/PostDETALIU_DELEGATII";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza valorile in baza de date a inregistrarii selectate
   * @date 08/04/2022
   * @param {*} obj obiect cu noile valori ale inregistrarii
   * @param {*} id id-ul inregistrarii selectate
   * @function
   * @memberof DelegatiiDetalii
   */
  UpdateValue = (obj, id) => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetDETALIU_DELEGATII/" +
          id
      )
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL +
          this.state.linkApi +
          "/PutDETALIU_DELEGATII/" +
          id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Sterge din baza de date inregistrarea selectate
   * @date 08/04/2022
   * @param {*} id id-ul inregistrarii selectate
   * @function
   * @memberof DelegatiiDetalii
   */
  DeleteValue = (id) => {
    var url =
      this.$f7.data.baseURL +
      this.state.linkApi +
      "/DeleteDETALIU_DELEGATII/" +
      id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return (
      <DataGrid
        title={"Detalii delegatie"}
        dataLoaded={this.state.dataLoaded}
        refresh={this.GetValues}
        name={"DetaliuDelegatii"}
        id={"Id"}
        ref={this.refDataGrid}
        renderUpdate={true}
        renderDelete={true}
        renderAdd={true}
        keys={[
          "Id",
          "Activitate",
          "Faza",
          "Firma",
          "Proiect",
          "Task",
          "Plecare",
          "Sosire",
          "LatPlecare",
          "LngPlecare",
          "LatSosire",
          "LngSosire",
        ]}
        maxHeight={this.state.height}
        inputs={this.state.inputs}
        dataSource={this.state.dataSource}
        onAdd={(state) => {
          var eveniment = { ...state.editedItem };
          delete eveniment["Id"];
          eveniment = this.$f7.methods.restructurareBaza(
            eveniment,
            this.state.inputs,
            "keyBaza"
          );
          this.AddValue(eveniment);
        }}
        onDelete={(state) => {
          var data = { ...state.editedItem };
          this.DeleteValue(data.Id);
        }}
        onUpdate={(state) => {
          var eveniment = { ...state.editedItem };
          var id = eveniment["Id"];
          eveniment = this.$f7.methods.restructurareBaza(
            eveniment,
            this.state.inputs,
            "keyBaza"
          );
          this.UpdateValue(eveniment, id);
        }}
      />
    );
  }
}
export default DelegatiiDetalii;
