import React from "react";
import { Page } from "framework7-react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";

/**
 * @description Interfata de afisare a fazelor pe taskul selectat
 * @date 08/04/2022
 * @class FazeProiect
 * @extends {React.Component}
 */
class FazeProiect extends React.Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;

    this.state = {
      dataLoaded: false,
      linkApi: "/api/FazeRoluri",
      dataSource: [],
      inputs: [],
      height: window.innerHeight - 60,
    };

    this.refDataGrid = React.createRef();
  }

  /**
   * @description Ia din baza de date toate rolurile atasate taskului selectat
   * @function
   * @date 08/04/2022
   * @memberof FazeProiect
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetFazeRoluri2/" +
          this.$f7.data.Proiect.iD_PROIECT
      )
      .then((response) => {
        var baza = response.data;
        var inputs = [
          {
            key: "Proiect",
            tip: "selectbox",
            lista: this.$f7.data.Proiecte,
            parent: "Proiect",
          },
        ];
        this.$f7.data.TipFaze2.map((tipFaza) => {
          inputs.push({ key: "D_" + tipFaza.key, parent: tipFaza.key });
          inputs.push({ key: "SP_" + tipFaza.key, parent: tipFaza.key });
          inputs.push({ key: "S_" + tipFaza.key, parent: tipFaza.key });
          inputs.push({ key: "M_" + tipFaza.key, parent: tipFaza.key });
          return null;
        });
        var bazaFinal = [];

        baza.map((elemBaza) => {
          var elemBazaFinal = { Proiect: elemBaza.idProiect };
          var listaValori = elemBaza.listaValori;
          for (var i = 0; i < listaValori.length / 4; i++) {
            for (var j = 0; j < 4; j++) {
              var currentIndex = i * 4 + j;
              var key = this.$f7.data.TipFaze2[i].key;
              var value = listaValori[currentIndex];
              var arrVal = ["D_", "SP_", "S_", "M_"];
              elemBazaFinal[arrVal[j] + key] = value;
            }
          }
          bazaFinal.push(elemBazaFinal);
          return null;
        });

        this.setState(
          {
            inputs: inputs,
            dataSource: bazaFinal,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  if (baza.length > 0) {
                    this.refDataGrid.current.setRenderItems(bazaFinal);
                  }
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.refDataGrid.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    if (this.$f7.data.Proiect !== null) this.GetValues();
  };

  render() {
    return (
      <Page pageContent={false}>
        {this.$f7.data.Proiect !== null ? (
          <DataGrid
            dataLoaded={this.state.dataLoaded}
            refresh={this.GetValues}
            name={"FazeProiect"}
            ref={this.refDataGrid}
            renderUpdate={false}
            renderDelete={false}
            renderAdd={false}
            maxHeight={this.state.height}
            dataSource={this.state.dataSource}
            inputs={this.state.inputs}
          />
        ) : (
          <div
            style={{
              position: "relative",
              height: "calc(100vh - 50px)",
            }}>
            <h3
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                right: 0,
                transform: "translateY(-50%)",
                textTransform: "uppercase",
                textAlign: "center",
                display: "block",
              }}>
              Nu ati selectat nimic
            </h3>
          </div>
        )}
      </Page>
    );
  }
}
export default FazeProiect;
