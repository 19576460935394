import React, { Component } from "react";
import DataGrid from "../../Datagrid/DataGrid";
import {
  ListInput,
  List,
  Row,
  Col,
  Icon,
  Preloader,
  View,
  Link,
  Popover
} from "framework7-react";
import moment from "moment";
import axios from "axios";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import OreLucrateListaSarciniClient from "./OreLucrateListaSarciniClient";

/**
 * @description Afiseaza un datagrid in partea din stanga cu toti salariatii din firma si cat a lucrat in intervalul selectat, si cate tasuri are alocate si rezolvate, in cazul in care se apasa pe alocate se deschide un popup cu taskurile alocate ale utilizatorului, in cazul in care se apasa pe rezolvate se deschide un popup cu taskurile inchise de catre utilizator.
 * @date 06/04/2022
 * @class OreLucratePeZiClienti
 * @extends {Component}
 */
class OreLucratePeZiClienti extends Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;
    this.arrayDescrieri = [{ key: "" }];
    this.state = {
      informatiiPopover: null,
      targetPopover: null,
      responseData: null,
      colorsExplained: [
        { key: "black", value: "Normal" }, //Normal
        { key: "red", value: "Nu a lucrat" }, //Nu a lucrat
        { key: "#e6cb0d", value: "Concediu odihna" }, //CO - #e69705
        { key: "blue", value: "Concediu medical" }, //CM
        { key: "teal", value: "Crestere copil" }, //CC
        { key: "green", value: "Liber" }, //Liber
        { key: "purple", value: "Mai multe" }, //Mai multe
      ],
      checked: 3,
      checkedAscunsi: false,
      items: [
        {
          id: 1,
          group: 1,
          title: "",
          start_time: moment().add(-0.5, "hour"),
          end_time: moment().add(5, "hour"),
        },
        {
          id: 2,
          group: 2,
          title: "",
          start_time: moment(),
          end_time: moment().add(1, "hour"),
        },
        {
          id: 3,
          group: 2,
          title: "",
          start_time: moment().add(2, "hour"),
          end_time: moment().add(3, "hour"),
        },
      ],
      groups: [
        { id: 1, title: "Total", height: 34 },
        { id: 2, title: "Lucrat", height: 34 },
      ],
      dataLoaded: false,
      height: 10000,
      linkApi: "/api/OreLucrateZi/GetOreLucrateClienti",
      linkApiMesaje: "/api/Sesizari",
      dataDeLa: [moment(Date.now()).format()],
      dataPanaLa: [moment(Date.now()).format()],
      dataSource: [],
      dataSourceInitial: [],
      inputs: [
        { key: "IdSalariat", keyBaza: "idSalariat" },
        {
          key: "Nume",
          keyBaza: "nume",
        },
        {
          key: "OreLucrate",
          keyBaza: "oreLucrate",
          //tip: "number" //aici
        },
        {
          key: "RefSalariat",
          keyBaza: "refSalariat",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
        {
          key: "Lista",
          keyBaza: "lista",
        },
        {
          key: "IdEveniment",
          keyBaza: "idEveniment",
        },
        {
          key: "IsHidden",
          keyBaza: "isHidden",
          tip: "bool",
        },
        {
          key: "A",
          keyBaza: "nrTaskuriAlocate",
          tip: "number",
        },
        {
          key: "R",
          keyBaza: "nrTaskuriRezolvate",
          tip: "number",
        },
        {
          key: "Locatie",
          keyBaza: "statusLocatie",
          tip: "number",
        },
        {
          key: "ListaRezolvate",
          keyBaza: "listaTaskuriRezolvate",
        },
        {
          key: "ListaAlocate",
          keyBaza: "listaTaskuriAlocate",
        },
      ],
    };
    this.refDatagrid = React.createRef();
    this.timelineRef = React.createRef();
  }

  /**
   * @description transforma valorile din datagrid(timpul lucrat) intr-un string mai usor de inteles (h : m : s).
   * @function
   * @date 06/04/2022
   * @param {*} seconds
   * @memberof OreLucratePeZiClienti
   */
  ConvertToFriendlyTime = (seconds) => {
    const days = Math.floor(seconds / (8 * 3600));

    seconds = seconds % (8 * 3600);
    const hours = Math.floor(seconds / 3600);

    seconds = seconds % 3600;
    const minutes = Math.floor(seconds / 60);

    seconds = seconds % 60;

    let output;

    if (days) {
      if (hours) {
        if (minutes) {
          output =
            (days > 1 ? days + "z " : days + " z") +
            ", " +
            (hours > 1 ? hours + "h " : hours + "h ") +
            " : " +
            (minutes > 1 ? minutes + "m " : minutes + "m ");
        } else {
          output =
            (days > 1 ? days + "z " : days + " z") +
            " : " +
            (hours > 1 ? hours + "h " : hours + "h ");
        }
      } else {
        if (minutes) {
          output =
            (days > 1 ? days + "z " : days + " z") +
            " : " +
            (minutes > 1 ? minutes + "m " : minutes + "m ");
        } else {
          output = days > 1 ? days + "z " : days + " z";
        }
      }
    } else {
      if (hours) {
        if (minutes) {
          output =
            (hours > 1 ? hours + "h " : hours + "h ") +
            " : " +
            (minutes > 1 ? minutes + "m " : minutes + "m ");
        } else {
          output = hours > 1 ? hours + "h " : hours + "h ";
        }
      } else {
        if (minutes) {
          output = minutes > 1 ? minutes + "m " : minutes + "m ";
        } else {
          output = "0";
        }
      }
    }

    return "" + output;
  };

  /**
   * @description filtreaza datasource-ul datagridului sa fie afisate si cele ascunse sau nu, sa fie afisate si cele care nu au lucrat sau nu.
   * @date 06/04/2022
   * @function
   * @memberof OreLucratePeZiClienti
   */
  filtrare = () => {
    var baza1 = [];
    switch (this.state.checked) {
      case 2: {
        baza1 = this.state.dataSourceInitial.filter((r) => {
          return r.OreLucrate !== "0";
        });
        break;
      }
      case 3: {
        baza1 = this.state.dataSourceInitial;
        break;
      }
      case 1: {
        baza1 = this.state.dataSourceInitial.filter((r) => {
          return r.OreLucrate === "0";
        });
        break;
      }
      default: {
        break;
      }
    }

    if (!this.state.checkedAscunsi) {
      baza1 = baza1.filter((r) => {
        return !r.IsHidden;
      });
    }
    this.setState({ dataSource: baza1 });
  };

  /**
   * @description Ia valorile necesare pentru randare de la endpointul „this.$f7.data.baseURL +.state.linkApi +GetSesiuniFiltrate/" + (this.state.checkedArhivate ? 1 : 0)” caruia i se transmit ca parametru daca sunt bifate si cele arhivate sau nu, si sunt filtrate ulterior in functie de data start si data final
   * @function
   * @date 06/04/2022
   * @memberof OreLucratePeZiClienti
   */
  GetValues = () => {
    //'mm-dd-yy'
    let initialStartData = new Date(this.state.dataDeLa[0]);
    let initialEndData = new Date(this.state.dataPanaLa[0]);
    const dataStart =
      initialStartData.getDate() +
      "-" +
      (initialStartData.getMonth() + 1) +
      "-" +
      initialStartData.getFullYear().toString().substring(2);
    const dataEnd =
      initialEndData.getDate() +
      "-" +
      (initialEndData.getMonth() + 1) +
      "-" +
      initialEndData.getFullYear().toString().substring(2);

    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/" +
          dataStart +
          "/" +
          dataEnd
      )
      .then((response) => {
        let change = { data: response.data.lista };
        change.data.map((item, index) => {
          item.oreLucrate = this.ConvertToFriendlyTime(item.oreLucrate);
          item["A"] = item.nrTaskuriAlocate;
          item["R"] = item.nrTaskuriRezolvate;
          item["Locatie"] = item.statusLocatie;
          item["ListaRezolvate"] = item.listaTaskuriRezolvate;
          item["ListaAlocate"] = item.listaTaskuriAlocate;
          return null;
        });

        let baza = this.$f7.methods.reconstructieBaza(
          change,
          this.state.inputs
        );

        var baza2 = baza.filter((r) => {
          return !r.IsHidden;
        });

        this.setState(
          {
            dataSource: baza2,
            dataSourceInitial: baza,
            responseData: response.data,
          },
          () => {
            setTimeout(() => {
              this.setState({ dataLoaded: true }, () => {
                this.creareGrafic(null, null, baza2, response.data);
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          { dataSource: [], dataSourceInitial: [], dataLoaded: true },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  /**
   * @description Deschide un popupDinamic caruia i se trimite componenta un view care randeaza pagina de detalii task pentru taskul selectat.
   * @function
   * @date 06/04/2022
   * @param {*} id id-ul proiectului selectat
   * @param {*} pagina pagina de trebuie randata in popup
   * @memberof OreLucratePeZiClienti
   */
  openPopup = (id, pagina) => {
    var proiect = this.$f7.data.allProiecte.find((r) => {
      return r.iD_PROIECT === id;
    });
    if (proiect !== undefined) {
      this.$f7.data.Proiect = proiect;
      this.$f7.views.main.router.navigate("/popupDinamic/", {
        props: {
          title: (
            <Link
              tooltip={
                this.state.informatiiPopover.proiect
                  ? this.state.informatiiPopover.proiect.comentariu
                  : ""
              }>
              {this.state.informatiiPopover.denumireTask}
            </Link>
          ),
          disableClose: false,
          component: (
            <View
              removeElements={true}
              componentCache={false}
              onViewInit={(e) => {
                this.$f7.data.routerDreapta = e.router;
                setTimeout(() => {
                  if (pagina !== undefined && pagina === 1) {
                    this.$f7.data.Grid.setState({
                      activeTab:
                        "tab-Grid-Descriere" +
                        this.$f7.data.Grid.state.denumireTab,
                    });
                  }
                  if (pagina !== undefined && pagina === 2) {
                    this.$f7.data.Grid.setState({
                      activeTab:
                        "tab-Grid-TimpLucrat" +
                        this.$f7.data.Grid.state.denumireTab,
                    });
                  }
                }, 350);
              }}
              id="oreLucratePeZiSheet"
              tabDeschis={"tab-Grid-TimpLucrat"}
              name="oreLucratePeZiSheet"
              url={"/agendaGridMainDreaptaActivitate/"}
            />
          ),
        },
      });
    }
  };
  /**
   * @description Deschide un popupAddEdit(un formular) pentru a trimite un mesaj persoanei caruia ii este atasat un task.
   * @date 06/04/2022
   * @function
   * @param {*} realizare realizarea pentru care se deschide interfata de mesaje
   * @memberof OreLucratePeZiClienti
   */
  openMesaj = (realizare) => {
    const inputsMesaj = [
      {
        key: "Subiect",
        keyBaza: "subiect",
        required: true,
        group: "Info",
      },
      {
        key: "Catre",
        keyBaza: "pt_cine",
        required: true,
        tip: "selectbox",
        lista: this.$f7.data.Salariati,
        defaultValue: realizare.iD_SALARIAT,
        disabled: true,
        group: "Info",
      },
      {
        key: "NumeCatre",
        keyBaza: "nume_pt_cine",
        disabled: true,
        group: "Info",
      },
      {
        key: "DeLa",
        keyBaza: "de_la_cine",
        required: true,
        tip: "selectbox",
        lista: this.$f7.data.Salariati,
        defaultValue: this.$f7.data.user.iD_SALARIAT,
        disabled: true,
        group: "Info",
      },
      {
        key: "NumeDeLa",
        keyBaza: "nume_de_la_cine",
        disabled: true,
        group: "Info",
      },
      {
        key: "Citit",
        keyBaza: "citit",
        required: true,
        tip: "bool",
        defaultValue: false,
        disabled: true,
        group: "Info",
      },
      {
        key: "Data",
        keyBaza: "data_sesizare",
        required: true,
        tip: "datebox",
        defaultValue: moment().format(),
        disabled: true,
        group: "Info",
      },
      {
        key: "Taskul",
        keyBaza: "ref_proiect",
        required: true,
        tip: "number",
        defaultValue: realizare.iD_PROIECT,
        disabled: true,
        group: "Info",
      },
      {
        key: "Mesaj",
        keyBaza: "comentariu",
        required: true,
        tip: "textarea",
        group: "Mesaj",
      },
    ];

    this.$f7.data.router.navigate("/popupAddEdit/", {
      props: {
        title:
          'Mesaj pentru taskul "' +
          this.$f7.data.Proiecte.find((r) => {
            return r.value === realizare.iD_PROIECT;
          }).key +
          '"',
        onCloseClicked: (state) => {
          //this.GetValues();
        },
        inputs: inputsMesaj,
        ignoreInputs: [
          "DeLa",
          "NumeCatre",
          "NumeDeLa",
          "Data",
          "Taskul",
          "Citit",
        ],
        buttons: [
          {
            key: "Salveaza",
            onSubmit: (state, den) => {
              ////this.$f7.popup.close(den)
              this.$f7.panel.close(den);
              this.$f7.panel.close(den);

              var item = { ...state.editedItem };
              item.NumeCatre = this.$f7.data.Salariati.find(
                (s) => s.value === realizare.iD_SALARIAT
              ).key;
              item.NumeDeLa = this.$f7.data.Salariati.find(
                (s) => s.value === this.$f7.data.user.iD_SALARIAT
              ).key;
              item = this.$f7.methods.restructurareBaza(
                item,
                inputsMesaj,
                "keyBaza"
              );

              axios({
                data: item,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url:
                  this.$f7.data.baseURL +
                  this.state.linkApiMesaje +
                  "/Postsesizari",
              })
                .then((response) => {
                  //this.GetValues();
                  this.$f7.methods.openNotification(
                    "Mesaj trimis catre " +
                      this.$f7.data.Salariati.find(
                        (s) => s.value === realizare.iD_SALARIAT
                      ).key
                  );
                })
                .catch((error) => {
                  console.log("error", error);
                  this.$f7.methods.openNotification("Eroare");
                });
            },
          },
        ],
      },
    });
  };
  /**
   * @description transforma intr-un string frumos de citit „z : m :s” niste milisecunde primite ca parametrii.
   * @date 06/04/2022
   * @function
   * @param {*} ms milisecunde
   * @memberof OreLucratePeZiClienti
   */
  dhm = (ms) => {
    var days = Math.floor(ms / (8 * 60 * 60 * 1000));
    var daysms = ms % (8 * 60 * 60 * 1000);
    var hours = Math.floor(daysms / (60 * 60 * 1000));
    var hoursms = ms % (60 * 60 * 1000);
    var minutes = Math.floor(hoursms / (60 * 1000));
    var minutesms = ms % (60 * 1000);
    var sec = Math.floor(minutesms / 1000);
    return days + "z " + hours + "h " + minutes + "m " + sec + " s ";
  };

  /**
   * @description Creeaza dataset-ul pentru graficul din dreapta, pune culori random fiecarui element si il hashureaza daca este finalizat si ii ataseaza fiecarui element un evennimente de onmouseover care afiseaza un popup
   * @date 06/04/2022
   * @function
   * @param {*} state state-ul datagridului
   * @param {*} r contextul datagridului
   * @param {*} itemeRandate itemele randate de datagrid
   * @param {*} rD responseData-ul,daca este apelata din getValues
   * @memberof OreLucratePeZiClienti
   */
  creareGrafic = (state, r, itemeRandate, rD) => {
    var responseData = rD !== undefined ? rD : this.state.responseData;
    if (responseData) {
      var listaTimpLucratTotal = responseData.listaTimpLucratTotal;
      var listaTimpLucratTotalSalariati =
        responseData.listaTimpLucratTotalSalariati;

      var groups = [];
      var items = [];
      itemeRandate.map((d) => {
        groups.push({
          id: d.IdSalariat,
          title: d.Nume,
        });
        d.Lista.map((realizare) => {
          var numeClient = "";
          var client = this.$f7.data.Firme.find((r) => {
            return r.value === realizare.iD_CLIENT;
          });
          if (client) {
            numeClient = client.key;
          }
          var iesire = realizare.iesire;
          var manual = realizare.manual;
          var manualAprobat = realizare.aprobat;
          if (realizare.iesire === null) {
            manual = false;
            iesire = moment().format();
          }
          var rand = Math.random() * 360;
          var colorString =
            manual === true
              ? manualAprobat === true
                ? "hsl(" + rand + ", 0%, 60%)"
                : "black"
              : "hsl(" + rand + ", 100%, 60%)";
          var colorString2 =
            manual === true
              ? manualAprobat === true
                ? "hsl(" + rand + ", 10%, 40%)"
                : "black"
              : "hsl(" + rand + ", 100%, 40%)";
          let miliseconds =
            new Date(iesire).getTime() - new Date(realizare.intrare).getTime();
          var durata = this.dhm(miliseconds);

          var task =
            realizare.unitati === 999999
              ? this.$f7.data.TipEvenimentSalariati.find((r) => {
                  return r.value === parseInt(realizare.comentariu);
                }) !== undefined
                ? this.$f7.data.TipEvenimentSalariati.find((r) => {
                    return r.value === parseInt(realizare.comentariu);
                  }).key
                : ""
              : this.$f7.data.Proiecte.find((r) => {
                  return r.value === realizare.iD_PROIECT;
                }) !== undefined
              ? this.$f7.data.Proiecte.find((r) => {
                  return r.value === realizare.iD_PROIECT;
                }).key
              : "";

          var ang = "45deg";

          if (realizare.unitati === 999999) {
            var colors = [
              { key: "purple", value: -1 }, //Mai multe
              { key: "#e6cb0d", value: 1 }, //CO - #e69705
              { key: "green", value: 4 }, //Liber
              { key: "blue", value: 2 }, //CM
              { key: "black", value: 1000 }, //Normal
              { key: "red", value: 1001 }, //Nu a lucrat
            ];
            var testCuloare = colors.find((r) => {
              return r.value === parseInt(realizare.comentariu);
            });
            if (testCuloare !== undefined) {
              colorString = testCuloare.key;
              ang = "135deg";
            }
          }

          var color =
            "repeating-linear-gradient(" +
            ang +
            ", " +
            colorString +
            ", " +
            colorString +
            " 8px, " +
            colorString2 +
            " 0px, " +
            colorString2 +
            " 15px)";
          var colorFinal = realizare.iesire === null ? colorString : color;
          items.push({
            id:
              realizare.iD_PROIECT !== null
                ? realizare.iD_REALIZARE
                : Math.random(),
            group: d.IdSalariat,
            start_time: moment(realizare.intrare),
            end_time: moment(iesire),
            title: "",
            canMove: false,
            canResize: false,
            itemProps: {
              style: {
                background: colorFinal,
                border: "1px solid " + colorString,
              },
              onClick: (e) => {
                var t = e.currentTarget;
                t.style.background = "#000000";

                var pro = this.$f7.data.allProiecte.find((r) => {
                  return r.iD_PROIECT === realizare.iD_PROIECT;
                });
                var deadlineProiect = "";
                if (pro) {
                  deadlineProiect = pro.deadline;
                }

                var timpLucratTotal = "";
                var timpLucratTotalSalariat = "";

                var timpTotal0 = listaTimpLucratTotal.find((r) => {
                  return r.idProiect === realizare.iD_PROIECT;
                });
                if (timpTotal0) {
                  timpLucratTotal = this.ConvertToFriendlyTime(
                    timpTotal0.timpLucrat
                  );
                }

                var timpTotal1 = listaTimpLucratTotalSalariati.find((r) => {
                  return (
                    r.idProiect === realizare.iD_PROIECT &&
                    r.idSalariat === realizare.iD_SALARIAT
                  );
                });

                if (timpTotal1) {
                  timpLucratTotalSalariat = this.ConvertToFriendlyTime(
                    timpTotal1.timpLucrat
                  );
                }

                const idSalariatRand = d.IdSalariat;
                const sal = { IdSalariat: idSalariatRand };
                this.refDatagrid.current.focusItem(sal, "IdSalariat");

                let lista = document.getElementsByClassName("rct-hl");
                for (var i = 0; i < lista.length; i++) {
                  let timelineItem = lista.item(i);

                  if (
                    timelineItem.dataset.groupid === idSalariatRand.toString()
                  ) {
                    timelineItem.style.background =
                      "var(--f7-theme-color-tint)";
                    timelineItem.style.filter = "brightness(1.3)";
                  } else {
                    if (timelineItem.style.background !== "lightgray") {
                      timelineItem.style.background = "lightgray";
                      timelineItem.style.filter = "unset";
                    }
                  }
                }
                var informatiiPopover = {
                  numeClient: numeClient,
                  numeSalariat: d.Nume,
                  deadline: deadlineProiect,
                  inceput: realizare.intrare,
                  sfarsit: iesire,
                  durata: durata,
                  timpTotalSalariat: timpLucratTotalSalariat,
                  timpTotalTask: timpLucratTotal,
                  comentariu: realizare.comentariu,
                  observatie: realizare.comentariU1,
                  pornit: realizare.iesire === null ? 1 : 0,
                  realizare: realizare,
                  denumireTask: task,
                  proiect: pro,
                };
                this.setState({
                  informatiiPopover: informatiiPopover,
                  targetPopover: t,
                  oldColor: colorFinal,
                });

                var popoverDinamicOreLucrate = this.$f7.popover.get(
                  ".popoverDinamicOreLucrate"
                );
                popoverDinamicOreLucrate.open(t);
              },
            },
          });

          return null;
        });
        return null;
      });

      this.setState({ groups: groups, items: items });
    }
  };
  /**
   * @description aplica elementelor din datagrid stilizarea corespunzatoare din array-ul colors.
   * @date 06/04/2022
   * @function
   * @param {*} state stateul datagridului
   * @param {*} r contextul datagridului
   * @param {*} itemeRandate itemele randate de datagrid
   * @memberof OreLucratePeZiClienti
   */
  colorItems = (state, r, itemeRandate) => {
    var colors = [
      { key: "purple", value: -1 }, //Mai multe
      { key: "#e6cb0d", value: 1 }, //CO - #e69705
      { key: "green", value: 4 }, //Liber
      { key: "blue", value: 2 }, //CM
      { key: "teal", value: 12 }, //CC
      { key: "black", value: 1000 }, //Normal
      { key: "red", value: 1001 }, //Nu a lucrat
    ];
    var tableName = r.tableName;
    itemeRandate.map((elem, index) => {
      // console.log('elem:', elem);
      var taskActiv = false;
      if (elem.Lista.length > 0) {
        elem.Lista.forEach((element) => {
          if (element.iesire === null) taskActiv = true;
        });
      }

      var color = "black";
      var background;

      if (
        colors.find((fe) => {
          return fe.value === elem.IdEveniment;
        }) !== undefined
      ) {
        color = colors.find((fe) => {
          return fe.value === elem.IdEveniment;
        }).key;
      } else if (elem.OreLucrate === "0") {
        color = "red";
      }

      // switch (elem.Locatie) {
      //     case 0: if (taskActiv) background = "repeating-linear-gradient(45deg, rgba(0, 255, 0, 0.15), rgba(0, 255, 0, 0.15) 8px, rgba(255, 255, 255, 1) 0px, rgba(255, 255, 255, 1) 15px)"; else background = "rgba(0, 255, 0, 0.15)"; break;
      //     case 1: if (taskActiv) background = "repeating-linear-gradient(45deg, rgba(255, 0, 0, 0.15), rgba(255, 0, 0, 0.15) 8px, rgba(255, 255, 255, 1) 0px, rgba(255, 255, 255, 1) 15px)"; else background = "rgba(255, 0, 0, 0.15)"; break;
      //     case 2: if (taskActiv) background = "repeating-linear-gradient(45deg, rgba(0, 128, 128, 0.15), rgba(0, 128, 128, 0.15) 8px, rgba(255, 255, 255, 1) 0px, rgba(255, 255, 255, 1) 15px)"; else background = "rgba(0, 128, 128, 0.15)"; break;
      //     default: background = null;
      // }

      switch (elem.Locatie) {
        case 0:
          if (taskActiv)
            background =
              "linear-gradient(90deg, rgba(0, 255, 0, 0.15) 0, rgba(0, 255, 0, 0.15) 10px, rgba(255, 255, 255, 1) 10px, rgba(255, 255, 255, 1) 20px), linear-gradient(90deg, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 1) 10px, rgba(0, 255, 0, 0.15) 10px, rgba(0, 255, 0, 0.15) 20px)";
          else background = "rgba(0, 255, 0, 0.15)";
          break;
        case 1:
          if (taskActiv)
            background =
              "linear-gradient(90deg, rgba(255, 0, 0, 0.15) 0, rgba(255, 0, 0, 0.15) 10px, rgba(255, 255, 255, 1) 10px, rgba(255, 255, 255, 1) 20px), linear-gradient(90deg, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 1) 10px, rgba(255, 0, 0, 0.15) 10px, rgba(255, 0, 0, 0.15) 20px)";
          else background = "rgba(255, 0, 0, 0.15)";
          break;
        case 2:
          if (taskActiv)
            background =
              "linear-gradient(90deg, rgba(0, 128, 128, 0.15) 0, rgba(0, 128, 128, 0.15) 10px, rgba(255, 255, 255, 1) 10px, rgba(255, 255, 255, 1) 20px), linear-gradient(90deg, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 1) 10px, rgba(0, 128, 128, 0.15) 10px, rgba(0, 128, 128, 0.15) 20px)";
          else background = "rgba(0, 128, 128, 0.15)";
          break;
        default:
          background = null;
      }

      var elemById = document.getElementById("trMain" + tableName + index);
      if (elemById) {
        elemById.style.color = color;
        elemById.style.backgroundSize = "20px 10px, 20px 10px";
        elemById.style.backgroundPosition = "0 0, 0 10px";
        elemById.style.backgroundRepeat = "repeat-x";
        elemById.style.fontWeight = "bold";

        if (background !== undefined && background !== null) {
          elemById.style.backgroundImage = background;
          elemById.style.backgroundColor = background;
        }

        if (elem.IsHidden) {
          elemById.classList.add("ascuns");
        } else {
          if (elemById.classList.contains("ascuns")) {
            elemById.classList.remove("ascuns");
          }
        }
      }
      return null;
    });
  };

  verticalLineClassNamesForTime = (timeStart, timeEnd) => {
    let classes = [];
    classes.push("testColoana");
    return classes;
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <Popover
          onPopoverClose={() => {
            var target = this.state.targetPopover;
            target.style.background = this.state.oldColor;
            this.setState({ targetPopover: target });
          }}
          className="popoverDinamicOreLucrate">
          {this.state.informatiiPopover !== null ? (
            <>
              <Link
                popoverClose
                tooltip={
                  this.state.informatiiPopover.proiect
                    ? this.state.informatiiPopover.proiect.comentariu
                    : ""
                }
                style={{ fontSize: "small", width: "100%", margin: "6px 0px" }}
                onClick={() => {
                  this.openPopup(
                    this.state.informatiiPopover.realizare.iD_PROIECT,
                    1
                  );
                }}>
                {this.state.informatiiPopover.denumireTask}
              </Link>
              <p>
                <span className={"spanOreLucreate"}>Client:</span>
                {this.state.informatiiPopover.numeClient}
              </p>
              <p>
                <span className={"spanOreLucreate"}>Angajat:</span>
                {this.state.informatiiPopover.numeSalariat}
              </p>
              <p>
                <span className={"spanOreLucreate"}>Deadline:</span>
                {moment(this.state.informatiiPopover.deadline).format(
                  "'YY-MM-DD HH:mm"
                )}
              </p>
              <p>
                <span className={"spanOreLucreate"}>Inceput:</span>
                {moment(this.state.informatiiPopover.inceput).format(
                  "'YY-MM-DD HH:mm"
                )}
              </p>
              <p>
                <span className={"spanOreLucreate"}>Sfarsit:</span>
                {moment(this.state.informatiiPopover.sfarsit).format(
                  "'YY-MM-DD HH:mm"
                )}
              </p>
              <p>
                <span className={"spanOreLucreate"}>Durata:</span>
                {this.state.informatiiPopover.durata}
              </p>
              <p>
                <span className={"spanOreLucreate"}>Timp total salariat:</span>
                {this.state.informatiiPopover.timpTotalSalariat}
              </p>
              <p>
                <span className={"spanOreLucreate"}>Timp total task:</span>
                {this.state.informatiiPopover.timpTotalTask}
              </p>
              <p>
                <span className={"spanOreLucreate"}>Comentariu:</span>
                {this.state.informatiiPopover.comentariu}
              </p>
              <p>
                <span className={"spanOreLucreate"}>Observatie:</span>
                {this.state.informatiiPopover.observatie}
              </p>
              {this.state.informatiiPopover.proiect ? (
                <Link
                  popoverClose
                  tooltip={"Cere detalii"}
                  style={{
                    fontSize: "small",
                    width: "100%",
                    margin: "6px 0px",
                  }}
                  onClick={() => {
                    this.openMesaj(this.state.informatiiPopover.realizare);
                  }}>
                  Cere detalii
                </Link>
              ) : null}

              {this.state.informatiiPopover.proiect ? (
                <Link
                  popoverClose
                  tooltip={"Activitate task"}
                  style={{
                    fontSize: "small",
                    width: "100%",
                    margin: "6px 0px",
                  }}
                  onClick={() => {
                    this.openPopup(
                      this.state.informatiiPopover.realizare.iD_PROIECT,
                      2
                    );
                  }}>
                  Activitate task
                </Link>
              ) : null}

              {this.state.informatiiPopover.pornit === 1 ? (
                <Link
                  popoverClose
                  tooltip={"Oprire task"}
                  style={{
                    fontSize: "small",
                    width: "100%",
                    margin: "6px 0px",
                  }}
                  onClick={() => {
                    this.$f7.data.idSalariatFunctii =
                      this.state.informatiiPopover.realizare.iD_SALARIAT;
                    this.$f7.methods
                      .Sfarsit(
                        false,
                        null,
                        false,
                        false,
                        false,
                        this.state.informatiiPopover.realizare.iD_PROIECT
                      )
                      .then((r) => {
                        this.$f7.data.idSalariatFunctii =
                          this.$f7.data.user.iD_SALARIAT;
                        this.GetValues();
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification("Eroare" + err);
                      });
                  }}>
                  Oprire task
                </Link>
              ) : null}
            </>
          ) : null}
        </Popover>

        <List
          noChevron
          noHairlines
          noHairlinesBetween
          noHairlinesBetweenMd
          noHairlinesMd
          style={{
            left: 0,
            margin: 0,
          }}>
          <Row style={{ width: "100%" }}>
            {/*<Col width={10}>*/}
            {/*    <ListItem*/}
            {/*        key={"OreLucratePeZi1"}*/}
            {/*        checkbox*/}
            {/*        checked={this.state.checked === 3}*/}
            {/*        indeterminate={this.state.checked === 2}*/}
            {/*        onChange={() => {*/}
            {/*            var ch = 0;*/}
            {/*            switch (this.state.checked) {*/}
            {/*                case 1: {*/}
            {/*                    ch = 2;*/}
            {/*                    break;*/}
            {/*                }*/}
            {/*                case 2: {*/}
            {/*                    ch = 3;*/}
            {/*                    break;*/}
            {/*                }*/}
            {/*                case 3: {*/}
            {/*                    ch = 1;*/}
            {/*                    break;*/}
            {/*                }*/}
            {/*                default: {*/}
            {/*                    break;*/}
            {/*                }*/}
            {/*            }*/}
            {/*            this.setState(*/}
            {/*                {*/}
            {/*                    checked: ch*/}
            {/*                },*/}
            {/*                () => {*/}
            {/*                    this.filtrare();*/}
            {/*                    setTimeout(() => {*/}
            {/*                        this.refDatagrid.current.setRenderItems(*/}
            {/*                            this.state.dataSource*/}
            {/*                        );*/}
            {/*                    }, 500);*/}
            {/*                }*/}
            {/*            );*/}
            {/*        }}*/}
            {/*        title={*/}
            {/*            this.state.checked === 1*/}
            {/*                ? "Absenti"*/}
            {/*                : this.state.checked === 2*/}
            {/*                    ? "Prezenti"*/}
            {/*                    : "Toti"*/}
            {/*        }*/}
            {/*        floatingLabel*/}
            {/*    />*/}
            {/*</Col>*/}

            {/*<Col width={10}>*/}
            {/*    <ListItem*/}
            {/*        key={"OreLucratePeZi2"}*/}
            {/*        checkbox*/}
            {/*        checked={this.state.checkedAscunsi}*/}
            {/*        onChange={() => {*/}
            {/*            this.setState(*/}
            {/*                {*/}
            {/*                    checkedAscunsi: !this.state.checkedAscunsi*/}
            {/*                },*/}
            {/*                () => {*/}
            {/*                    this.filtrare();*/}
            {/*                    setTimeout(() => {*/}
            {/*                        this.refDatagrid.current.setRenderItems(*/}
            {/*                            this.state.dataSource*/}
            {/*                        );*/}
            {/*                    }, 500);*/}
            {/*                }*/}
            {/*            );*/}
            {/*        }}*/}
            {/*        title={"Ascunsi"}*/}
            {/*        floatingLabel*/}
            {/*    />*/}
            {/*</Col>*/}

            <Col>
              <ListInput
                key={"OreLucratePeZi3"}
                label="Interval"
                type="datepicker"
                calendarParams={{
                  rangePicker: true,
                  dateFormat: "dd.mm.yyyy",
                  closeOnSelect: true,
                  on: {
                    closed: (e) => {
                      const val = e.value;
                      this.setState(
                        {
                          dataDeLa: [val[0]],
                          dataPanaLa: [val[1]],
                        },
                        () => {
                          this.GetValues();
                        }
                      );
                    },
                  },
                }}
                value={[this.state.dataDeLa[0], this.state.dataPanaLa[0]]}
                floatingLabel
                placeholder="De la">
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="today"
                  slot="content-start"
                  style={{ left: 0, top: 0 }}
                />
              </ListInput>
            </Col>
          </Row>
        </List>

        <Row noGap>
          <Col width={25}>
            <div style={{ marginTop: 15 }}>
              <DataGrid
                onInitialised={(state, r, itemeRandate, all) => {
                  this.colorItems(state, r, all);
                }}
                onRenderItemsChanged={(state, r, itemeRandate, all) => {
                  this.creareGrafic(state, r, all);
                  //this.colorItems(state, r, itemeRandate);
                }}
                refresh={this.GetValues}
                dataLoaded={this.state.dataLoaded}
                ref={this.refDatagrid}
                id={"IdSalariat"}
                refId={"RefSalariat"}
                initialRefId={null}
                renderAccordion={true}
                name={"OreLucratePeZiClient"}
                keys={["Nume", "OreLucrate", "A", "R"]}
                maxHeight={this.state.height}
                dataSource={this.state.dataSource}
                inputs={this.state.inputs}
                count={10000}
                onCellClick={(state) => {
                  if (this.$f7.data.rol < 2) {
                    // pentru taskurile alocate
                    if (state.columnName === this.state.inputs[7].key) {
                      this.$f7.data.idSalariatFunctii =
                        state.rowDataAll.IdSalariat;
                      this.$f7.data.Proiect = null;
                      this.$f7.data.router.navigate("/popupDinamic/", {
                        props: {
                          onPopupClose: () => {
                            this.$f7.data.idSalariatFunctii =
                              this.$f7.data.user.iD_SALARIAT;
                          },
                          title: "Alocate (" + state.rowDataAll.Nume + ")",
                          component: (
                            <OreLucrateListaSarciniClient
                              dataSource={state.rowDataAll.ListaAlocate}
                            />
                          ),
                        },
                      });
                    }

                    // pentru taskurile rezolvate
                    if (state.columnName === this.state.inputs[8].key) {
                      this.$f7.data.idSalariatFunctii =
                        state.rowDataAll.IdSalariat;
                      this.$f7.data.router.navigate("/popupDinamic/", {
                        props: {
                          onPopupClose: () => {
                            this.$f7.data.idSalariatFunctii =
                              this.$f7.data.user.iD_SALARIAT;
                          },
                          title: "Rezolvate (" + state.rowDataAll.Nume + ")",
                          component: (
                            <OreLucrateListaSarciniClient
                              dataSource={state.rowDataAll.ListaRezolvate}
                            />
                          ),
                        },
                      });
                    }
                  }
                }}
                onRowClick={(state) => {
                  console.log("s", state);
                  const idSalariatRand = state.rowDataAll.IdSalariat;

                  let lista = document.getElementsByClassName("rct-hl");
                  for (var i = 0; i < lista.length; i++) {
                    let timelineItem = lista.item(i);

                    if (
                      timelineItem.dataset.groupid === idSalariatRand.toString()
                    ) {
                      timelineItem.style.background =
                        "var(--f7-theme-color-tint)";
                      timelineItem.style.filter = "brightness(1.3)";
                    } else {
                      if (timelineItem.style.background !== "lightgray") {
                        timelineItem.style.background = "lightgray";
                        timelineItem.style.filter = "unset";
                      }
                    }
                  }
                }}
                onContextMenu={(state, target) => {
                  const idSalariatRand = state.rowDataAll.IdSalariat;

                  let lista = document.getElementsByClassName("rct-hl");
                  for (var i = 0; i < lista.length; i++) {
                    let timelineItem = lista.item(i);

                    if (
                      timelineItem.dataset.groupid === idSalariatRand.toString()
                    ) {
                      timelineItem.style.background =
                        "var(--f7-theme-color-tint)";
                      timelineItem.style.filter = "brightness(1.3)";
                    } else {
                      if (timelineItem.style.background !== "lightgray") {
                        timelineItem.style.background = "lightgray";
                        timelineItem.style.filter = "unset";
                      }
                    }
                  }
                }}
              />
            </div>
          </Col>
          <Col width={75}>
            {this.state.dataLoaded ? (
              <Timeline
                hideHorizontalLines={false}
                verticalLineClassNamesForTime={
                  this.verticalLineClassNamesForTime
                }
                ref={this.timelineRef}
                lineHeight={20}
                sidebarWidth={0}
                groups={this.state.groups}
                items={this.state.items}
                defaultTimeStart={moment(this.state.dataDeLa[0]).startOf("day")}
                defaultTimeEnd={moment(this.state.dataPanaLa[0]).endOf("day")}
                onCanvasClick={(groupId, time, e) => {
                  const idSalariatRand = groupId;
                  const sal = { IdSalariat: idSalariatRand };
                  this.refDatagrid.current.focusItem(sal, "IdSalariat");

                  let lista = document.getElementsByClassName("rct-hl");
                  for (var i = 0; i < lista.length; i++) {
                    let timelineItem = lista.item(i);

                    if (
                      timelineItem.dataset.groupid === idSalariatRand.toString()
                    ) {
                      timelineItem.style.background =
                        "var(--f7-theme-color-tint)";
                      timelineItem.style.filter = "brightness(1.3)";
                    } else {
                      if (timelineItem.style.background !== "lightgray") {
                        timelineItem.style.background = "lightgray";
                        timelineItem.style.filter = "unset";
                      }
                    }
                  }
                }}>
                <TimelineHeaders
                  className={"A"}
                  style={{
                    height: 70,
                    position: "sticky",
                    top: 0,
                    zIndex: 100,
                  }}>
                  <SidebarHeader className={"B"}>
                    {({ getRootProps }) => {
                      return <div className={"D"} {...getRootProps()} />;
                    }}
                  </SidebarHeader>
                  <DateHeader className={"C"} unit="primaryHeader" />
                  <DateHeader />
                </TimelineHeaders>
              </Timeline>
            ) : (
              <Preloader />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
export default OreLucratePeZiClienti;