import React, { Component } from "react";
import {
    Row,
    Col,
    Searchbar,
    Stepper,
    Preloader,
    List,
    ListInput,
    Icon
} from "framework7-react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";
import moment from "moment";
import axios from "axios";

  /**
   * @description Afiseaza un grafic cu toate taskurile lucrate intr-un interval selectat si cat s-a estimat ca se va lucra la acel task, cand se da click pe un task din grafic se afiseaza un popup cu mai multe informatii precum numele taskului, persoana care a lucrat la el, cat s-a lucrat.
   * @date 06/04/2022
   * @class EstimatLucratSalariati
   * @extends {Component}
   */
  class EstimatLucratSalariati extends Component {
    constructor(props) {
      super(props);

      this.Salariati = this.$f7.data.toggleTotiSalariatii
        ? this.$f7.data.Salariati
        : this.$f7.data.SalariatiActivi;
      this.Salariati = this.Salariati.filter((r) => r.value !== null);

      this.state = {
        linkApiRealizari: "/api/RealizareSarcini",
        linkApiSarcini: "/api/SarciniSalariat",
        height: window.innerHeight - 100,
        heightItem: 30,
        width: window.innerWidth - 10,
        listaSalariati: [],
        listaSalariatiPagina: [],
        listaSalariatiSearch: [],
        pagina: 1,
        paginaMax: 1,
        dataLoaded: false,
        searchActive: false,
        dataDeLa: [moment().startOf("day")],
        dataPanaLa: [moment().endOf("day")],
      };
    }

    componentDidMount = () => {
      this.GetValues();
    };
    /**
     * @description Ia valorile necesare pentru randare de la endpointul „/GetSARCINI_SALARIATCorecte” si „/GetREALIZARE_SARCINI” pentru a selecta salariatii activi si realizarile acestora si calculeaza pe datasource-ul primit cat a lucrat la cele inchise,cat a lucrat la cele deschise,cat a estmat la cele inchise si cat a estimat la cele deschise
     * @function
     * @date 06/04/2022
     * @memberof EstimatLucratSalariati
     */
    GetValues = () => {
      this.setState({
        dataLoaded: false,
      });

      const requestRealizari = axios.get(
        this.$f7.data.baseURL +
          this.state.linkApiRealizari +
          "/GetREALIZARE_SARCINI"
      );
      const requestSarcini = axios.get(
        this.$f7.data.baseURL +
          this.state.linkApiSarcini +
          "/GetSARCINI_SALARIATCorecte"
      );

      axios
        .all([requestRealizari, requestSarcini])
        .then(
          axios.spread((responseRealizari, responseSarcini) => {
            const realizareSarcini = responseRealizari.data;
            const sarciniSalariat = responseSarcini.data;
            let listaSalariati = [];

            this.Salariati.forEach((element) => {
              let totalTaskuri = 0;
              let taskuriFaraEstimare = 0;

              const realizari = realizareSarcini.filter(
                (item) =>
                  item.iD_SALARIAT === element.value &&
                  item.intrare !== null &&
                  ((moment(item.intrare).valueOf() >=
                    moment(this.state.dataDeLa[0]).valueOf() &&
                    moment(item.iesire).valueOf() <=
                      moment(this.state.dataPanaLa[0]).valueOf()) ||
                    (moment(item.intrare).valueOf() <
                      moment(this.state.dataDeLa[0]).valueOf() &&
                      moment(item.iesire).valueOf() >
                        moment(this.state.dataDeLa[0]).valueOf() &&
                      moment(item.iesire).valueOf() <=
                        moment(this.state.dataPanaLa[0]).valueOf()) ||
                    (moment(item.intrare).valueOf() >=
                      moment(this.state.dataDeLa[0]).valueOf() &&
                      moment(item.intrare).valueOf() <
                        moment(this.state.dataPanaLa[0]).valueOf() &&
                      moment(item.iesire).valueOf() >
                        moment(this.state.dataPanaLa[0]).valueOf()))
              );

              let task;
              let nume = element.key;
              let timpEstimatDeschise = 0;
              let timpLucratDeschise = 0;
              let timpEstimatInchise = 0;
              let timpLucratInchise = 0;

              if (realizari.length > 0) {
                realizari.forEach((r) => {
                  let ultimaSarcina = sarciniSalariat.filter(
                    (item) => item.iD_PROIECT === r.iD_PROIECT
                  );

                  if (ultimaSarcina.length > 0) {
                    const maxIdUltimaSarcina = Math.max.apply(
                      Math,
                      ultimaSarcina.map(function (o) {
                        return o.iD_SARCINA;
                      })
                    );
                    ultimaSarcina = ultimaSarcina.find(
                      (item) => item.iD_SARCINA === maxIdUltimaSarcina
                    );
                  }

                  if (ultimaSarcina !== undefined) {
                    if (
                      task === undefined ||
                      task.iD_PROIECT !== r.iD_PROIECT
                    ) {
                      task = this.$f7.data.allProiecte.find(
                        (item) => item.iD_PROIECT === r.iD_PROIECT
                      );
                    }

                    if (task !== undefined) {
                      let inceput, sfarsit;

                      if (
                        moment(r.intrare).valueOf() <
                        moment(this.state.dataDeLa[0]).valueOf()
                      )
                        inceput = moment(this.state.dataDeLa[0]).valueOf();
                      else inceput = moment(r.intrare).valueOf();

                      if (
                        moment(r.iesire).valueOf() >
                        moment(this.state.dataPanaLa[0]).valueOf()
                      )
                        sfarsit = moment(this.state.dataPanaLa[0]).valueOf();
                      else
                        sfarsit =
                          r.iesire === null
                            ? moment().valueOf()
                            : moment(r.iesire).valueOf();

                      if (ultimaSarcina.stare === 4) {
                        let timp = 0;
                        if (
                          task.timp_plan_d !== 0 &&
                          task.timp_plan_d !== null
                        ) {
                          timp = task.timp_plan_d;
                        } else if (
                          task.timp_plan_sp !== 0 &&
                          task.timp_plan_sp !== null &&
                          task.timp_plan_sp < timp
                        ) {
                          timp = task.timp_plan_sp;
                        } else if (
                          task.timp_plan_a !== 0 &&
                          task.timp_plan_a !== null &&
                          task.timp_plan_a < timp
                        ) {
                          timp = task.timp_plan_a;
                        } else if (
                          task.timp_plan_m !== 0 &&
                          task.timp_plan_m !== null &&
                          task.timp_plan_m < timp
                        ) {
                          timp = task.timp_plan_m;
                        } else {
                          taskuriFaraEstimare++;
                        }

                        timpEstimatInchise += timp;
                        timpLucratInchise += (sfarsit - inceput) / 1000 / 60;
                      } else {
                        let timp = 0;
                        if (
                          task.timp_plan_d !== 0 &&
                          task.timp_plan_d !== null
                        ) {
                          timp = task.timp_plan_d;
                        } else if (
                          task.timp_plan_sp !== 0 &&
                          task.timp_plan_sp !== null &&
                          task.timp_plan_sp < timp
                        ) {
                          timp = task.timp_plan_sp;
                        } else if (
                          task.timp_plan_a !== 0 &&
                          task.timp_plan_a !== null &&
                          task.timp_plan_a < timp
                        ) {
                          timp = task.timp_plan_a;
                        } else if (
                          task.timp_plan_m !== 0 &&
                          task.timp_plan_m !== null &&
                          task.timp_plan_m < timp
                        ) {
                          timp = task.timp_plan_m;
                        } else {
                          taskuriFaraEstimare++;
                        }

                        timpEstimatDeschise += timp;
                        timpLucratDeschise += (sfarsit - inceput) / 1000 / 60;
                      }

                      totalTaskuri++;
                    }
                  }
                });
              }

              listaSalariati.push({
                name:
                  nume + " (" + taskuriFaraEstimare + "/" + totalTaskuri + ")",
                estimatD: timpEstimatDeschise / 60,
                lucratD: timpLucratDeschise / 60,
                estimatI: timpEstimatInchise / 60,
                lucratI: timpLucratInchise / 60,
              });
            });

            this.setState(
              {
                listaSalariati: listaSalariati,
              },
              () => {
                this.updateChart();
              }
            );
          })
        )
        .catch((error) => {
          console.log("error", error);
        });
    };
    /**
     * @description actualizeaza datasource-ul graficului
     * @function
     * @date 06/04/2022
     * @memberof EstimatLucratSalariati
     */
    updateChart = () => {
      let lista = this.state.searchActive
        ? this.state.listaSalariatiSearch
        : this.state.listaSalariati;

      this.setState(
        {
          dataLoaded: false,
          listaSalariatiPagina: lista.slice(
            (this.state.pagina - 1) *
              parseInt(this.state.height / this.state.heightItem),
            this.state.pagina *
              parseInt(this.state.height / this.state.heightItem)
          ),
          paginaMax: Math.ceil(
            lista.length / parseInt(this.state.height / this.state.heightItem)
          ),
        },
        () => {
          this.setState({
            dataLoaded: true,
          });
        }
      );
    };

    /**
     * @description Face cautare in datasource si rerandeaza elementele de le gaseste folosind functia updateChart
     * @date 06/04/2022
     * @param {*} value valoarea de cautat
     * @param {*} baza baza in care cata
     * @memberof EstimatLucratSalariati
     */
    search = (value, baza) => {
      let toSearch = value.toString().toLowerCase().replace(/\s/g, "");

      let result = baza.filter((elemBaza) => {
        let res = false;
        let valFinal = elemBaza.name
          .toString()
          .toLowerCase()
          .replace(/\s/g, "");

        if (valFinal.includes(toSearch)) {
          res = true;
        }

        return res;
      });

      this.setState(
        {
          listaSalariatiSearch: result,
          pagina: 1,
          searchActive: true,
        },
        () => {
          this.updateChart();
        }
      );
    };
    /**
     * @description transforma valorile din datagrid(timpul lucrat) intr-un string mai usor de inteles (h : m : s
     * @date 06/04/2022
     * @function
     * @param {*} seconds
     * @memberof EstimatLucratSalariati
     */
    ConvertToFriendlyTime = (seconds) => {
      const days = Math.floor(seconds / (8 * 3600));

      seconds = seconds % (8 * 3600);
      const hours = Math.floor(seconds / 3600);

      seconds = seconds % 3600;
      const minutes = Math.floor(seconds / 60);

      seconds = seconds % 60;

      let output;

      if (days) {
        if (hours) {
          if (minutes) {
            output =
              (days > 1 ? days + "z " : days + " z") +
              ", " +
              (hours > 1 ? hours + "h " : hours + "h ") +
              " : " +
              (minutes > 1 ? minutes + "m " : minutes + "m ");
          } else {
            output =
              (days > 1 ? days + "z " : days + " z") +
              " : " +
              (hours > 1 ? hours + "h " : hours + "h ");
          }
        } else {
          if (minutes) {
            output =
              (days > 1 ? days + "z " : days + " z") +
              " : " +
              (minutes > 1 ? minutes + "m " : minutes + "m ");
          } else {
            output = days > 1 ? days + "z " : days + " z";
          }
        }
      } else {
        if (hours) {
          if (minutes) {
            output =
              (hours > 1 ? hours + "h " : hours + "h ") +
              " : " +
              (minutes > 1 ? minutes + "m " : minutes + "m ");
          } else {
            output = hours > 1 ? hours + "h " : hours + "h ";
          }
        } else {
          if (minutes) {
            output = minutes > 1 ? minutes + "m " : minutes + "m ";
          } else {
            output = "0";
          }
        }
      }

      return "" + output;
    };

    render() {
      return (
        <Row>
          <Col style={{ position: "relative" }}>
            <div style={{ position: "relative", overflow: "hidden" }}>
              <List
                id={"estimatLucratSalariatiIntervalList"}
                noChevron
                noHairlines
                noHairlinesBetween
                noHairlinesBetweenMd
                noHairlinesMd
                style={{
                  width: 225,
                  float: "left",
                }}>
                <ListInput
                  key={"IntervalEstimatLucratSalariati"}
                  label="Interval"
                  type="datepicker"
                  calendarParams={{
                    rangePicker: true,
                    dateFormat: "dd.mm.yyyy",
                    closeOnSelect: true,
                    on: {
                      closed: (e) => {
                        const val = e.value;
                        this.setState(
                          {
                            dataDeLa: [val[0]],
                            dataPanaLa: [val[1]],
                          },
                          () => {
                            this.GetValues();
                          }
                        );
                      },
                    },
                  }}
                  value={[this.state.dataDeLa[0], this.state.dataPanaLa[0]]}
                  floatingLabel
                  placeholder="Interval">
                  <Icon
                    color={"var(--f7-theme-color)"}
                    size={20}
                    material="today"
                    slot="content-start"
                    style={{ left: 0, top: 0 }}
                  />
                </ListInput>
              </List>

              <Searchbar
                className={"searchBarEstimatLucratSalariati"}
                onClickDisable={() => {
                  this.setState(
                    {
                      pagina: 1,
                      searchActive: false,
                    },
                    () => {
                      this.updateChart();
                    }
                  );
                }}
                onSearchbarEnable={() => {}}
                onSearchbarDisable={() => {}}
                onInput={(e) => {
                  this.search(e.target.value, this.state.listaSalariati);
                }}
                borderColor={"transparent"}
                style={{
                  height: 34,
                  width: "calc(100% - 344px)",
                  /*filter: "drop-shadow(2px 0px 14px gray)",*/
                  float: "left",
                  margin: "15px 8px 0 8px",
                }}
              />

              <div
                style={{
                  position: "relative",
                  marginTop: 14,
                  marginRight: 8,
                  float: "right",
                }}>
                <span
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    textAlign: "center",
                  }}>
                  {this.state.paginaMax}{" "}
                  {this.state.paginaMax > 1 ? "pagini" : "pagina"}
                </span>
                <Stepper
                  style={{
                    height: 18,
                    marginTop: 15,
                  }}
                  onStepperPlusClick={(e) => {
                    if (this.state.pagina < this.state.paginaMax) {
                      this.setState(
                        {
                          pagina: this.state.pagina + 1,
                        },
                        () => {
                          this.updateChart();
                        }
                      );
                    }
                  }}
                  onStepperMinusClick={(e, f) => {
                    if (this.state.pagina > 1) {
                      this.setState(
                        {
                          pagina: this.state.pagina - 1,
                        },
                        () => {
                          this.updateChart();
                        }
                      );
                    }
                  }}
                  onInput={(e) => {
                    const val = e.target.value;

                    if (val !== "") {
                      const pag = parseInt(val);

                      if (pag >= 1 && pag <= this.state.paginaMax) {
                        this.setState(
                          {
                            pagina: pag,
                          },
                          () => {
                            this.updateChart();
                          }
                        );
                      }
                    }
                  }}
                  small
                  value={this.state.pagina}
                  min={1}
                  max={this.state.paginaMax}
                  manualInputMode={true}
                />
              </div>
            </div>

            {this.state.dataLoaded ? (
              <BarChart
                layout="vertical"
                width={this.state.width}
                height={this.state.height}
                data={this.state.listaSalariatiPagina}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={100} />
                <Tooltip
                  formatter={(value, name, props) => {
                    let output = value;

                    if (typeof value === "number") {
                      output = this.ConvertToFriendlyTime(value * 60 * 60);
                    }

                    return [output, name];
                  }}
                  separator=" → "
                  itemStyle={{ fontSize: 13, paddingBottom: 1, paddingTop: 1 }}
                  labelStyle={{ fontSize: 17, marginBottom: 5 }}
                />
                <Legend />

                <Bar
                  dataKey="lucratI"
                  name="Lucrat (inchise)"
                  stackId="lucrate"
                  fill="#8c330d"
                />
                <Bar
                  dataKey="lucratD"
                  name="Lucrat (deschise)"
                  stackId="lucrate"
                  fill="#d94f14"
                />

                <Bar
                  dataKey="estimatI"
                  name="Estimat (inchise)"
                  stackId="estimate"
                  fill="#bf9a15"
                />
                <Bar
                  dataKey="estimatD"
                  name="Estimat (deschise)"
                  stackId="estimate"
                  fill="#e6b919"
                />
              </BarChart>
            ) : (
              <div
                style={{
                  position: "absolute",
                  width: "40px",
                  height: "40px",
                  top: "calc(50vh - 34px)",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}>
                <Preloader size={40} color={"var(--f7-theme-color)"} />
              </div>
            )}
          </Col>
        </Row>
      );
    }
  }
export default  EstimatLucratSalariati 