import React, { Component } from 'react'
import { Button, Preloader } from "framework7-react"
import EmailItemList from './EmailItemList'
import EmailSideBar from './EmailSideBar'

class EmailSidePanel extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      
    }
  }

  componentDidMount = () => {
    // this.props.handleGetCount()
    this.props.handleSelectTab(0)
  }

  render() {
    const { CLIENT_ID, mailList, pageToken, noOfResults, selectedEmailId, activeTab, profileImage, profileName, handleLogoutGoogle, handleSelectEmail, handleNextPage, handlePrevPage, handleFirstPage, handleSelectTab } = this.props

    const multiplier = pageToken.length < 2 ? 0 : pageToken.length - 2
    const fromNumber = (multiplier * 20) + 1
    let toNumber = (multiplier + 1) * 20
    toNumber = toNumber > noOfResults ? noOfResults : toNumber

    return (
      <div
        id='gmailSidePanel'
        style={{ width: 300, height: '100%', zIndex: 2, boxShadow: '0 0 5px 0 #7c7c7c', backgroundColor: '#fff', display: 'flex' }}
      >
        <EmailSideBar CLIENT_ID={CLIENT_ID} activeTab={activeTab} loading={mailList.length === 0} profileImage={profileImage} profileName={profileName} handleLogoutGoogle={handleLogoutGoogle} handleSelectTab={handleSelectTab} />

        <div style={{ width: 'calc(100% - 48px)', height: '100%' }}>
          <div
            className='gmailListTrack'
            style={{ width: '100%', height: 'calc(100% - 41px)', overflow: 'hidden auto', boxShadow: 'inset 4px -4px 6px -3px #e5e5e5' }}
          >
            <div style={{ height: 'auto', padding: '4px 0', minHeight: '100%', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              {mailList.length > 0 ||
                <Preloader style={{ alignSelf: 'center' }} />
              }

              {mailList.length > 0 && mailList.map((mail, index, arr) => (
                <EmailItemList key={index} info={mail} isLast={index === arr.length - 1} isSelected={selectedEmailId === mail.id} handleSelectEmail={handleSelectEmail} />
              ))}
            </div>
          </div>

          <div className='gmailNavigationBar' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderTop: '1px solid #e9e9e9' }}>
            <Button iconMaterial='first_page' iconSize={25} onClick={handleFirstPage} disabled={pageToken.length < 3 || mailList.length === 0}></Button>
            <Button iconMaterial='chevron_left' iconSize={25} onClick={handlePrevPage} disabled={pageToken.length < 3 || mailList.length === 0}></Button>
            <div style={{ margin: '0 10px', paddingTop: 2 }}>{fromNumber} - {toNumber} din {noOfResults}</div>
            <Button iconMaterial='chevron_right' iconSize={25} onClick={handleNextPage} disabled={pageToken[pageToken.length - 1] === null || mailList.length === 0}></Button>
          </div>
        </div>
      </div>
    )
  }
}

export default EmailSidePanel;
