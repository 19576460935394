import React, { Component } from "react";
import { Page } from "framework7-react";
import DataGrid from "./../../Datagrid/DataGrid";
import axios from "axios";

/**
 * @description Afiseaza toate activitatile pe taskul selectat
 * @date 08/04/2022
 * @class VizualizareGenerala
 * @extends {Component}
 */
class VizualizareGenerala extends Component {
  constructor(props) {
    super(props);
    this.height = window.innerHeight;
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.state = {
      dataLoaded: false,
      height: window.innerHeight - 44,
      linkApiSalariati: "/api/Salariati/GetSALARIATI",
      linkApi: "/api/Vizualizare",
      dataSource: [],
      input: [
        {
          key: "Salariat",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "MomentInceput",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "MomentSfarsit",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "TimpLucratInOre" },
        { key: "Asumat", tip: "bool" },
        { key: "Functionalitate" },
        { key: "Stadiu" },
        { key: "ProcentRealizare", tip: "number" },
        { key: "Comentariu" },
      ],
    };

    this.refDataGrid = React.createRef();
  }

  /**
   * @description Ia toate activitatile realizate pe taskul curent selectat
   * @function
   * @date 08/04/2022
   * @memberof VizualizareGenerala
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    const requestVizualizare = axios.get(
      this.$f7.data.baseURL +
        this.state.linkApi +
        "/" +
        this.$f7.data.Proiect.iD_PROIECT
    );

    axios
      .all([requestVizualizare])
      .then(
        axios.spread((responseVizualizare) => {
          let change = responseVizualizare;

          for (let item in change.data) {
            change.data[item]["Salariat"] = change.data[item].iD_SALARIAT;
            change.data[item]["MomentInceput"] = change.data[item].intrare;
            change.data[item]["MomentSfarsit"] = change.data[item].iesire;
            change.data[item]["TimpLucratInOre"] =
              change.data[item].timP_LUCRAT === "" ||
              change.data[item].timP_LUCRAT === null
                ? "Fara"
                : change.data[item].timP_LUCRAT;
            change.data[item]["Asumat"] = change.data[item].asumat;
            change.data[item]["Functionalitate"] =
              change.data[item].denumire === "" ||
              change.data[item].denumire === null
                ? "Fara"
                : change.data[item].denumire;
            change.data[item]["Stadiu"] =
              change.data[item].stadiu === "" ||
              change.data[item].stadiu === null
                ? "Fara"
                : change.data[item].stadiu;
            change.data[item]["ProcentRealizare"] =
              change.data[item].procenT_REALIZARE;
            change.data[item]["Comentariu"] =
              change.data[item].comentariu === "" ||
              change.data[item].comentariu === null
                ? "Fara"
                : change.data[item].comentariu;
          }

          let baza = this.$f7.methods.reconstructieBaza(
            change,
            this.state.input
          );

          this.setState(
            {
              dataSource: baza.length === 0 ? [] : baza,
            },
            () => {
              setTimeout(() => {
                this.setState(
                  {
                    dataLoaded: true,
                  },
                  () => {
                    this.refDataGrid.current.setRenderItems(baza);
                  }
                );
              }, 400);
            }
          );
        })
      )
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.refDataGrid.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    if (this.$f7.data.Proiect !== null) this.GetValues();
  };

  render() {
    return (
      <Page pageContent={false}>
        {this.$f7.data.Proiect !== null ? (
          <DataGrid
            dataLoaded={this.state.dataLoaded}
            refresh={this.GetValues}
            name={"VizualizareGenerala"}
            ref={this.refDataGrid}
            renderUpdate={false}
            renderDelete={false}
            renderAdd={false}
            maxHeight={this.state.height}
            dataSource={this.state.dataSource}
            inputs={this.state.input}
          />
        ) : (
          <div
            style={{
              position: "relative",
              height: "calc(100vh - 50px)",
            }}>
            <h3
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                right: 0,
                transform: "translateY(-50%)",
                textTransform: "uppercase",
                textAlign: "center",
                display: "block",
              }}>
              Nu ati selectat nimic
            </h3>
          </div>
        )}
      </Page>
    );
  }
}
export default VizualizareGenerala;
