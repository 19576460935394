import React, { Component } from 'react';
import { Button } from "framework7-react"

class EmailContentBar extends Component {
  render() {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 48,
          backgroundColor: 'transparent',
          boxSizing: 'border-box',
          padding: '2px 10px',
          boxShadow: '0px 0px 6px 0px #919191',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <h3 style={{ width: '50%', height: '100%', margin: 0, display: 'flex', alignItems: 'center' }}>{this.props.subject}</h3>

        <div style={{ display: 'flex', alignItems: 'center', marginRight: -8 }}>
          <Button
            className='gmailContentTab'
            iconMaterial={this.props.activeTab === 4 ? 'delete_forever' : 'delete'}
            iconSize={25}
            onClick={() => {
              // if (this.props.activeTab === 4) this.props.handleMoveFromTrash(this.props.id)
              // else this.props.handleMoveToTrash(this.props.id)

              if (this.props.activeTab !== 4) this.props.handleMoveToTrash(this.props.id)
            }}
          >
            {this.props.activeTab === 4
              ? (
                <div className='gmailContentTabLabelMenu' style={{ width: 180 }}>
                  <span style={{ color: '#4a4a4a', whiteSpace: 'break-spaces', borderBottom: '1px solid #d1d1d1', display: 'block', paddingBottom: 5 }}>Anuleaza stergerea si muta in</span>
                  <Button iconMaterial='inbox' iconSize={20} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => this.props.handleMoveFromTrash(this.props.id, "INBOX")}>Inbox</Button>
                  <Button iconMaterial='send' iconSize={20} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => this.props.handleMoveFromTrash(this.props.id, "SENT")}>Trimise</Button>
                  {/* <Button iconMaterial='insert_drive_file' iconSize={22} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => this.props.handleMoveFromTrash(this.props.id, "DRAFT")}>Ciorne</Button> */}
                  <Button iconMaterial='error' iconSize={20} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => this.props.handleMoveFromTrash(this.props.id, "SPAM")}>Spam</Button>
                </div>
              )
              : <div>Sterge</div>
            }

            {/* <div>{this.props.activeTab === 4 ? 'Anuleaza stergerea' : 'Sterge'}</div> */}
          </Button>

          <Button
            className='gmailContentTab'
            iconMaterial='email'
            iconSize={25}
            disabled={this.props.isUnread}
            onClick={() => this.props.handleMarkMailAsUnread(this.props.id)}
          >
            <div>Marcheaza ca necitit</div>
          </Button>

          <Button
            className='gmailContentTab'
            iconMaterial='add_task'
            iconSize={25}
            onClick={this.props.handleAddTask}
          >
            <div>Transforma in task</div>
          </Button>
        </div>
      </div>
    )
  }
}

export default EmailContentBar;
