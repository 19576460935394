import React from "react";
import { Page } from "framework7-react";
import StadiuProiecte from "./StadiuProiecte";
class StadiuProiectePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { denumire: "Birouri" };
    }
    render() {
        return (
            <Page
                className={"themeBackground"}
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
            >

                <div className="ptr3">
                    <StadiuProiecte idProiect={this.props.idProiect} />
                </div>
            </Page>
        );
    }
}
export default StadiuProiectePage;