import React, { Component } from "react";
import Gantt, {
    Tasks,
    Column,
    Editing,
    Toolbar,
    Item
} from "devextreme-react/gantt";
import axios from "axios";

let tasks = [
    {
        id: 1,
        parentId: -1,
        title: "Software Development",
        start: new Date("2019-02-21T05:00:00.000Z"),
        end: new Date("2019-07-04T12:00:00.000Z"),
        progress: 31
    },
    {
        id: 2,
        parentId: 1,
        title: "Scope",
        start: new Date("2019-02-21T05:00:00.000Z"),
        end: new Date("2019-02-26T09:00:00.000Z"),
        progress: 60
    },
    {
        id: 3,
        parentId: 2,
        title: "Determine project scope",
        start: new Date("2019-02-21T05:00:00.000Z"),
        end: new Date("2019-02-21T09:00:00.000Z"),
        progress: 100
    },
    {
        id: 4,
        parentId: 2,
        title: "Secure project sponsorship",
        start: new Date("2019-02-21T10:00:00.000Z"),
        end: new Date("2019-02-22T09:00:00.000Z"),
        progress: 100
    },
    {
        id: 5,
        parentId: 2,
        title: "Define preliminary resources",
        start: new Date("2019-02-22T10:00:00.000Z"),
        end: new Date("2019-02-25T09:00:00.000Z"),
        progress: 60
    },
    {
        id: 6,
        parentId: 2,
        title: "Secure core resources",
        start: new Date("2019-02-25T10:00:00.000Z"),
        end: new Date("2019-02-26T09:00:00.000Z"),
        progress: 0
    },
    {
        id: 7,
        parentId: 2,
        title: "Scope complete",
        start: new Date("2019-02-26T09:00:00.000Z"),
        end: new Date("2019-02-26T09:00:00.000Z"),
        progress: 0
    },
    {
        id: 8,
        parentId: 1,
        title: "Analysis/Software Requirements",
        start: new Date("2019-02-26T10:00:00.000Z"),
        end: new Date("2019-03-18T09:00:00.000Z"),
        progress: 80
    },
    {
        id: 9,
        parentId: 8,
        title: "Conduct needs analysis",
        start: new Date("2019-02-26T10:00:00.000Z"),
        end: new Date("2019-03-05T09:00:00.000Z"),
        progress: 100
    },
    {
        id: 10,
        parentId: 8,
        title: "Draft preliminary software specifications",
        start: new Date("2019-03-05T10:00:00.000Z"),
        end: new Date("2019-03-08T09:00:00.000Z"),
        progress: 100
    },
    {
        id: 11,
        parentId: 8,
        title: "Develop preliminary budget",
        start: new Date("2019-03-08T10:00:00.000Z"),
        end: new Date("2019-03-12T09:00:00.000Z"),
        progress: 100
    },
    {
        id: 12,
        parentId: 8,
        title: "Review software specifications/budget with team",
        start: new Date("2019-03-12T10:00:00.000Z"),
        end: new Date("2019-03-12T14:00:00.000Z"),
        progress: 100
    },
    {
        id: 13,
        parentId: 8,
        title: "Incorporate feedback on software specifications",
        start: new Date("2019-03-13T05:00:00.000Z"),
        end: new Date("2019-03-13T14:00:00.000Z"),
        progress: 70
    },
    {
        id: 14,
        parentId: 8,
        title: "Develop delivery timeline",
        start: new Date("2019-03-14T05:00:00.000Z"),
        end: new Date("2019-03-14T14:00:00.000Z"),
        progress: 0
    },
    {
        id: 15,
        parentId: 8,
        title: "Obtain approvals to proceed (concept, timeline, budget)",
        start: new Date("2019-03-15T05:00:00.000Z"),
        end: new Date("2019-03-15T09:00:00.000Z"),
        progress: 0
    },
    {
        id: 16,
        parentId: 8,
        title: "Secure required resources",
        start: new Date("2019-03-15T10:00:00.000Z"),
        end: new Date("2019-03-18T09:00:00.000Z"),
        progress: 0
    },
    {
        id: 17,
        parentId: 8,
        title: "Analysis complete",
        start: new Date("2019-03-18T09:00:00.000Z"),
        end: new Date("2019-03-18T09:00:00.000Z"),
        progress: 0
    },
    {
        id: 18,
        parentId: 1,
        title: "Design",
        start: new Date("2019-03-18T10:00:00.000Z"),
        end: new Date("2019-04-05T14:00:00.000Z"),
        progress: 80
    },
    {
        id: 19,
        parentId: 18,
        title: "Review preliminary software specifications",
        start: new Date("2019-03-18T10:00:00.000Z"),
        end: new Date("2019-03-20T09:00:00.000Z"),
        progress: 100
    },
    {
        id: 20,
        parentId: 18,
        title: "Develop functional specifications",
        start: new Date("2019-03-20T10:00:00.000Z"),
        end: new Date("2019-03-27T09:00:00.000Z"),
        progress: 100
    },
    {
        id: 21,
        parentId: 18,
        title: "Develop prototype based on functional specifications",
        start: new Date("2019-03-27T10:00:00.000Z"),
        end: new Date("2019-04-02T09:00:00.000Z"),
        progress: 100
    },
    {
        id: 22,
        parentId: 18,
        title: "Review functional specifications",
        start: new Date("2019-04-02T10:00:00.000Z"),
        end: new Date("2019-04-04T09:00:00.000Z"),
        progress: 30
    },
    {
        id: 23,
        parentId: 18,
        title: "Incorporate feedback into functional specifications",
        start: new Date("2019-04-04T10:00:00.000Z"),
        end: new Date("2019-04-05T09:00:00.000Z"),
        progress: 0
    },
    {
        id: 24,
        parentId: 18,
        title: "Obtain approval to proceed",
        start: new Date("2019-04-05T10:00:00.000Z"),
        end: new Date("2019-04-05T14:00:00.000Z"),
        progress: 0
    },
    {
        id: 25,
        parentId: 18,
        title: "Design complete",
        start: new Date("2019-04-05T14:00:00.000Z"),
        end: new Date("2019-04-05T14:00:00.000Z"),
        progress: 0
    },
    {
        id: 26,
        parentId: 1,
        title: "Development",
        start: new Date("2019-04-08T05:00:00.000Z"),
        end: new Date("2019-05-07T12:00:00.000Z"),
        progress: 42
    },
    {
        id: 27,
        parentId: 26,
        title: "Review functional specifications",
        start: new Date("2019-04-08T05:00:00.000Z"),
        end: new Date("2019-04-08T14:00:00.000Z"),
        progress: 100
    },
    {
        id: 28,
        parentId: 26,
        title: "Identify modular/tiered design parameters",
        start: new Date("2019-04-09T05:00:00.000Z"),
        end: new Date("2019-04-09T14:00:00.000Z"),
        progress: 100
    },
    {
        id: 29,
        parentId: 26,
        title: "Assign development staff",
        start: new Date("2019-04-10T05:00:00.000Z"),
        end: new Date("2019-04-10T14:00:00.000Z"),
        progress: 100
    },
    {
        id: 30,
        parentId: 26,
        title: "Develop code",
        start: new Date("2019-04-11T05:00:00.000Z"),
        end: new Date("2019-05-01T14:00:00.000Z"),
        progress: 49
    },
    {
        id: 31,
        parentId: 26,
        title: "Developer testing (primary debugging)",
        start: new Date("2019-04-16T12:00:00.000Z"),
        end: new Date("2019-05-07T12:00:00.000Z"),
        progress: 24
    },
    {
        id: 32,
        parentId: 26,
        title: "Development complete",
        start: new Date("2019-05-07T12:00:00.000Z"),
        end: new Date("2019-05-07T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 33,
        parentId: 1,
        title: "Testing",
        start: new Date("2019-04-08T05:00:00.000Z"),
        end: new Date("2019-06-13T12:00:00.000Z"),
        progress: 23
    },
    {
        id: 34,
        parentId: 33,
        title: "Develop unit test plans using product specifications",
        start: new Date("2019-04-08T05:00:00.000Z"),
        end: new Date("2019-04-11T14:00:00.000Z"),
        progress: 100
    },
    {
        id: 35,
        parentId: 33,
        title: "Develop integration test plans using product specifications",
        start: new Date("2019-04-08T05:00:00.000Z"),
        end: new Date("2019-04-11T14:00:00.000Z"),
        progress: 100
    },
    {
        id: 36,
        parentId: 33,
        title: "Unit Testing",
        start: new Date("2019-05-07T12:00:00.000Z"),
        end: new Date("2019-05-28T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 37,
        parentId: 36,
        title: "Review modular code",
        start: new Date("2019-05-07T12:00:00.000Z"),
        end: new Date("2019-05-14T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 38,
        parentId: 36,
        title: "Test component modules to product specifications",
        start: new Date("2019-05-14T12:00:00.000Z"),
        end: new Date("2019-05-16T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 39,
        parentId: 36,
        title: "Identify anomalies to product specifications",
        start: new Date("2019-05-16T12:00:00.000Z"),
        end: new Date("2019-05-21T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 40,
        parentId: 36,
        title: "Modify code",
        start: new Date("2019-05-21T12:00:00.000Z"),
        end: new Date("2019-05-24T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 41,
        parentId: 36,
        title: "Re-test modified code",
        start: new Date("2019-05-24T12:00:00.000Z"),
        end: new Date("2019-05-28T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 42,
        parentId: 36,
        title: "Unit testing complete",
        start: new Date("2019-05-28T12:00:00.000Z"),
        end: new Date("2019-05-28T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 43,
        parentId: 33,
        title: "Integration Testing",
        start: new Date("2019-05-28T12:00:00.000Z"),
        end: new Date("2019-06-13T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 44,
        parentId: 43,
        title: "Test module integration",
        start: new Date("2019-05-28T12:00:00.000Z"),
        end: new Date("2019-06-04T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 45,
        parentId: 43,
        title: "Identify anomalies to specifications",
        start: new Date("2019-06-04T12:00:00.000Z"),
        end: new Date("2019-06-06T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 46,
        parentId: 43,
        title: "Modify code",
        start: new Date("2019-06-06T12:00:00.000Z"),
        end: new Date("2019-06-11T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 47,
        parentId: 43,
        title: "Re-test modified code",
        start: new Date("2019-06-11T12:00:00.000Z"),
        end: new Date("2019-06-13T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 48,
        parentId: 43,
        title: "Integration testing complete",
        start: new Date("2019-06-13T12:00:00.000Z"),
        end: new Date("2019-06-13T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 49,
        parentId: 1,
        title: "Training",
        start: new Date("2019-04-08T05:00:00.000Z"),
        end: new Date("2019-06-10T12:00:00.000Z"),
        progress: 25
    },
    {
        id: 50,
        parentId: 49,
        title: "Develop training specifications for end users",
        start: new Date("2019-04-08T05:00:00.000Z"),
        end: new Date("2019-04-10T14:00:00.000Z"),
        progress: 100
    },
    {
        id: 51,
        parentId: 49,
        title: "Develop training specifications for helpdesk support staff",
        start: new Date("2019-04-08T05:00:00.000Z"),
        end: new Date("2019-04-10T14:00:00.000Z"),
        progress: 100
    },
    {
        id: 52,
        parentId: 49,
        title:
            "Identify training delivery methodology (computer based training, classroom, etc.)",
        start: new Date("2019-04-08T05:00:00.000Z"),
        end: new Date("2019-04-09T14:00:00.000Z"),
        progress: 100
    },
    {
        id: 53,
        parentId: 49,
        title: "Develop training materials",
        start: new Date("2019-05-07T12:00:00.000Z"),
        end: new Date("2019-05-28T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 54,
        parentId: 49,
        title: "Conduct training usability study",
        start: new Date("2019-05-28T12:00:00.000Z"),
        end: new Date("2019-06-03T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 55,
        parentId: 49,
        title: "Finalize training materials",
        start: new Date("2019-06-03T12:00:00.000Z"),
        end: new Date("2019-06-06T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 56,
        parentId: 49,
        title: "Develop training delivery mechanism",
        start: new Date("2019-06-06T12:00:00.000Z"),
        end: new Date("2019-06-10T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 57,
        parentId: 49,
        title: "Training materials complete",
        start: new Date("2019-06-10T12:00:00.000Z"),
        end: new Date("2019-06-10T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 58,
        parentId: 1,
        title: "Documentation",
        start: new Date("2019-04-08T05:00:00.000Z"),
        end: new Date("2019-05-20T09:00:00.000Z"),
        progress: 0
    },
    {
        id: 59,
        parentId: 58,
        title: "Develop Help specification",
        start: new Date("2019-04-08T05:00:00.000Z"),
        end: new Date("2019-04-08T14:00:00.000Z"),
        progress: 80
    },
    {
        id: 60,
        parentId: 58,
        title: "Develop Help system",
        start: new Date("2019-04-22T10:00:00.000Z"),
        end: new Date("2019-05-13T09:00:00.000Z"),
        progress: 0
    },
    {
        id: 61,
        parentId: 58,
        title: "Review Help documentation",
        start: new Date("2019-05-13T10:00:00.000Z"),
        end: new Date("2019-05-16T09:00:00.000Z"),
        progress: 0
    },
    {
        id: 62,
        parentId: 58,
        title: "Incorporate Help documentation feedback",
        start: new Date("2019-05-16T10:00:00.000Z"),
        end: new Date("2019-05-20T09:00:00.000Z"),
        progress: 0
    },
    {
        id: 63,
        parentId: 58,
        title: "Develop user manuals specifications",
        start: new Date("2019-04-08T05:00:00.000Z"),
        end: new Date("2019-04-09T14:00:00.000Z"),
        progress: 65
    },
    {
        id: 64,
        parentId: 58,
        title: "Develop user manuals",
        start: new Date("2019-04-22T10:00:00.000Z"),
        end: new Date("2019-05-13T09:00:00.000Z"),
        progress: 0
    },
    {
        id: 65,
        parentId: 58,
        title: "Review all user documentation",
        start: new Date("2019-05-13T10:00:00.000Z"),
        end: new Date("2019-05-15T09:00:00.000Z"),
        progress: 0
    },
    {
        id: 66,
        parentId: 58,
        title: "Incorporate user documentation feedback",
        start: new Date("2019-05-15T10:00:00.000Z"),
        end: new Date("2019-05-17T09:00:00.000Z"),
        progress: 0
    },
    {
        id: 67,
        parentId: 58,
        title: "Documentation complete",
        start: new Date("2019-05-20T09:00:00.000Z"),
        end: new Date("2019-05-20T09:00:00.000Z"),
        progress: 0
    },
    {
        id: 68,
        parentId: 1,
        title: "Pilot",
        start: new Date("2019-03-18T10:00:00.000Z"),
        end: new Date("2019-06-24T12:00:00.000Z"),
        progress: 22
    },
    {
        id: 69,
        parentId: 68,
        title: "Identify test group",
        start: new Date("2019-03-18T10:00:00.000Z"),
        end: new Date("2019-03-19T09:00:00.000Z"),
        progress: 100
    },
    {
        id: 70,
        parentId: 68,
        title: "Develop software delivery mechanism",
        start: new Date("2019-03-19T10:00:00.000Z"),
        end: new Date("2019-03-20T09:00:00.000Z"),
        progress: 100
    },
    {
        id: 71,
        parentId: 68,
        title: "Install/deploy software",
        start: new Date("2019-06-13T12:00:00.000Z"),
        end: new Date("2019-06-14T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 72,
        parentId: 68,
        title: "Obtain user feedback",
        start: new Date("2019-06-14T12:00:00.000Z"),
        end: new Date("2019-06-21T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 73,
        parentId: 68,
        title: "Evaluate testing information",
        start: new Date("2019-06-21T12:00:00.000Z"),
        end: new Date("2019-06-24T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 74,
        parentId: 68,
        title: "Pilot complete",
        start: new Date("2019-06-24T12:00:00.000Z"),
        end: new Date("2019-06-24T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 75,
        parentId: 1,
        title: "Deployment",
        start: new Date("2019-06-24T12:00:00.000Z"),
        end: new Date("2019-07-01T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 76,
        parentId: 75,
        title: "Determine final deployment strategy",
        start: new Date("2019-06-24T12:00:00.000Z"),
        end: new Date("2019-06-25T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 77,
        parentId: 75,
        title: "Develop deployment methodology",
        start: new Date("2019-06-25T12:00:00.000Z"),
        end: new Date("2019-06-26T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 78,
        parentId: 75,
        title: "Secure deployment resources",
        start: new Date("2019-06-26T12:00:00.000Z"),
        end: new Date("2019-06-27T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 79,
        parentId: 75,
        title: "Train support staff",
        start: new Date("2019-06-27T12:00:00.000Z"),
        end: new Date("2019-06-28T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 80,
        parentId: 75,
        title: "Deploy software",
        start: new Date("2019-06-28T12:00:00.000Z"),
        end: new Date("2019-07-01T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 81,
        parentId: 75,
        title: "Deployment complete",
        start: new Date("2019-07-01T12:00:00.000Z"),
        end: new Date("2019-07-01T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 82,
        parentId: 1,
        title: "Post Implementation Review",
        start: new Date("2019-07-01T12:00:00.000Z"),
        end: new Date("2019-07-04T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 83,
        parentId: 82,
        title: "Document lessons learned",
        start: new Date("2019-07-01T12:00:00.000Z"),
        end: new Date("2019-07-02T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 84,
        parentId: 82,
        title: "Distribute to team members",
        start: new Date("2019-07-02T12:00:00.000Z"),
        end: new Date("2019-07-03T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 85,
        parentId: 82,
        title: "Create software maintenance team",
        start: new Date("2019-07-03T12:00:00.000Z"),
        end: new Date("2019-07-04T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 86,
        parentId: 82,
        title: "Post implementation review complete",
        start: new Date("2019-07-04T12:00:00.000Z"),
        end: new Date("2019-07-04T12:00:00.000Z"),
        progress: 0
    },
    {
        id: 87,
        parentId: 1,
        title: "Software development template complete",
        start: new Date("2019-07-04T12:00:00.000Z"),
        end: new Date("2019-07-04T12:00:00.000Z"),
        progress: 0
    }
];

/**
 * @description Afiseaza un grafic gantt cu toate proiectele aplicatiei
 * @date 06/04/2022
 * @class GanttProiecte
 * @extends {Component}
 */
class GanttProiecte extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linkApiProiecte: "/api/Proiecte",
      dataSource: [],
    };
  }
  /**
   * @description Ia valorile necesare pentru randare de la endpointul „this.$f7.data.baseURL + this.state.linkApiProiecte + "/GetPROIECTE"”
   * @date 06/04/2022
   * @function
   * @memberof GanttProiecte
   */
  GetProiecte = () => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApiProiecte + "/GetPROIECTE")
      .then((response) => {
        console.log("proiecte baza:", response.data);

        let lista = [];

        response.data.slice(0, 100).forEach((element) => {
          lista.push({
            id: element.iD_PROIECT,
            parentId: element.reF_PROIECT,
            title: element.denumire,
            start: new Date(element.data_start),
            end: new Date(element.data_final),
            progress: 0,
          });
        });

        this.setState({
          dataSource: lista,
        });
      })
      .catch((error) => {
        console.log("error proiecte baza:", error);
      });
  };
  /**
   * @description Parcurge datasource-ul luat cu ajutorul getValues si adauga elemente pentru graficul gantt, avand id si refId(parinte)
   * @function
   * @date 06/04/2022
   * @memberof GanttProiecte
   */
  DataSourceBuild = () => {
    let lista = [];

    this.$f7.data.allProiecte.forEach((element) => {
      lista.push({
        id: element.iD_PROIECT,
        parentId: element.reF_PROIECT,
        title: element.denumire,
        start: new Date(element.data_creare),
        end: new Date(element.deadline),
        progress: 0,
      });
    });

    this.setState({
      dataSource: lista,
    });
  };

  componentDidMount = () => {
    //this.DataSourceBuild();
    //this.GetProiecte();
    // console.log('Proiecte:', this.$f7.data.Proiecte);
    // console.log('allProiecte:', this.$f7.data.allProiecte);
    // console.log('arhivateProiecte:', this.$f7.data.arhivateProiecte);
    // console.log('nearhivateProiecte:', this.$f7.data.nearhivateProiecte);
  };

  render() {
    return (
      <Gantt
        taskListWidth={350}
        scaleType="weeks"
        height={window.innerHeight - 35}
        rootValue={0}>
        <Tasks dataSource={tasks} />

        <Toolbar>
          <Item name="undo" />
          <Item name="redo" />
          <Item name="separator" />
          <Item name="collapseAll" />
          <Item name="expandAll" />
          <Item name="separator" />
          <Item name="zoomIn" />
          <Item name="zoomOut" />
        </Toolbar>

        <Column dataField="title" caption="Subject" width={200} />
        <Column dataField="start" caption="Start Date" />
        <Column dataField="end" caption="End Date" />

        <Editing enabled={true} />
      </Gantt>
    );
  }
}
export default GanttProiecte;