import React from "react";
import DataGrid from "../../Datagrid/DataGrid";

/**
 * @description Interfata in care sunt prezentate toate proiectele/datagridurile(interfata de administrator)
 * @date 08/04/2022
 * @class SarciniToateGrid
 * @extends {React.Component}
 */
class SarciniToateGrid extends React.Component {
  constructor(props) {
    super(props);

    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;

    let clienti = [{ key: "Fara", value: null }];

    this.$f7.data.nearhivateProiecte
      .filter((client) => {
        return client.id_tip_proiect === 57;
      })
      .forEach((client) => {
        clienti.push({
          key: client.denumire,
          value: client.iD_PROIECT,
        });
      });

    this.height = window.innerHeight;
    this.state = {
      dataLoaded: false,
      linkApi: "/api/Proiecte",
      denumire: "Proiecte",
      height: window.innerHeight - 104,
      dataSource: [],
      inputs: [
        { key: "💾", keyBaza: "areDocumente", tip: "boolean", disabled: true },
        { key: "IdProiect", keyBaza: "iD_PROIECT", tip: "number" },
        { key: "Denumire", keyBaza: "denumire" },
        {
          key: "Client",
          keyBaza: "id_client",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
        },
        {
          key: "Departament",
          keyBaza: "id_departament",
          tip: "selectbox",
          lista: this.$f7.data.Departamente,
        },
        {
          key: "ProiectRef",
          keyBaza: "reF_PROIECT",
          lista: this.$f7.data.Proiecte,
          tip: "selectbox",
        },
        {
          key: "UltimaStare",
          keyBaza: "iD_STADIU",
          tip: "selectbox",
          lista: this.$f7.data.Stadiu,
        },
        { key: "Folosit", keyBaza: "folosit", tip: "bool", disabled: true },
        { key: "Task", keyBaza: "task", tip: "bool", disabled: true },
        {
          key: "IdPrioritate",
          keyBaza: "id_prioritate",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
        },
        {
          key: "NivelProiect",
          keyBaza: "id_nivel_proiect",
          tip: "selectbox",
          lista: this.$f7.data.NivelProiect,
        },
        {
          key: "TipProiect",
          keyBaza: "id_tip_proiect",
          tip: "selectbox",
          lista: this.$f7.data.TipProiect,
        },
        { key: "DataStart", keyBaza: "data_start", tip: "datebox" },
        { key: "Deadline", keyBaza: "deadline", tip: "datebox" },
        { key: "ValoareMinima", keyBaza: "valoare_minima", tip: "number" },
        { key: "ValoareMaxima", keyBaza: "valoare_maxima", tip: "number" },
        { key: "Buget", keyBaza: "buget", tip: "number" },
        { key: "BugetTimp", keyBaza: "buget_timp", tip: "number" },
        { key: "ProcRisc", keyBaza: "proC_RISC", tip: "number" },
        { key: "CostEstimat", keyBaza: "cost_estimat", tip: "number" },
        { key: "CostRealCalc", keyBaza: "cost_real_calc", tip: "number" },
        { key: "TimpPlanM", keyBaza: "timp_plan_m", tip: "number" },
        { key: "TimpPlanSP", keyBaza: "timp_plan_sp", tip: "number" },
        { key: "TimpPlanD", keyBaza: "timp_plan_d", tip: "number" },
        { key: "TimpPlanA", keyBaza: "timp_plan_a", tip: "number" },
        { key: "Comentariu", keyBaza: "comentariu" },
        {
          key: "Responsabil",
          keyBaza: "id_responsabil",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        { key: "Complet", keyBaza: "taskcomplete", tip: "number" },
        { key: "Optiuni", keyBaza: "options", tip: "number" },
        { key: "Eventtype", keyBaza: "eventtype", tip: "number" },
        {
          key: "Dezvoltator",
          keyBaza: "id_dezvoltator",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        {
          key: "SefProiect",
          keyBaza: "id_sefproiect",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        {
          key: "Arbitru",
          keyBaza: "id_arbitru",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        {
          key: "Manager",
          keyBaza: "id_manager",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        { key: "AprobatD", keyBaza: "aprobat_d", tip: "bool", disabled: true },
        { key: "AprobatA", keyBaza: "aprobat_a", tip: "bool", disabled: true },
        { key: "AprobatS", keyBaza: "aprobat_s", tip: "bool", disabled: true },
        { key: "AprobatM", keyBaza: "aprobat_m", tip: "bool", disabled: true },
        { key: "CheltuieliSalariati", keyBaza: "chelt_sal", tip: "number" },
        { key: "TimpLucrat", keyBaza: "timp_l", tip: "number" },
        { key: "Ordine", keyBaza: "ordine", tip: "number" },
        { key: "DataCreare", keyBaza: "data_creare", tip: "datebox" },
        { key: "DataFinal", keyBaza: "data_final", tip: "datebox" },
        {
          key: "Operatiune",
          keyBaza: "id_operatiuni",
          tip: "selectbox",
          lista: this.$f7.data.Operatiuni,
          requiredAdd: true,
        },
        { key: "IsHidden", keyBaza: "isHidden", tip: "bool", disabled: true },
        { key: "IsArhivat", keyBaza: "isArhivat", tip: "bool", disabled: true },
        { key: "Path", keyBaza: "path" },
        {
          key: "OperatorAdaugare",
          keyBaza: "id_operator_adaugare",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },

        { key: "F", keyBaza: "facturat", tip: "bool", disabled: true },
        { key: "PVuri", keyBaza: "apartenenta_pv" },
      ],
    };

    this.refDataGrid = React.createRef();
  }
  componentDidMount = () => {
    this.$f7.data.selectedGridLeft = this;
    //this.GetValues();
  };

  /**
   * @description Ia valorile cu toate taskurile/proiectele pentru datagrid
   * @functoin
   * @date 08/04/2022
   * @memberof SarciniToateGrid
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    this.$f7.methods
      .getProiecte(
        this.$f7.data.idSalariatFunctii,
        5,
        this.props.checkedTaskuri === true ? 1 : 0,
        this.props.checkedAscunse === true ? 1 : 0,
        this.props.checkedArhivate === true ? 1 : 0,
        1
      )
      .then((responseProiecte) => {
        //this.$f7.methods.IaValori();

        var date = responseProiecte;
        //date.data = date.data.filter((r) => {
        //    return (r.id_nivel_proiect === 1 || r.id_nivel_proiect === 2)
        //})

        date.data.map((sarcina) => {
          if (sarcina.numar_taskuri !== null && sarcina.numar_taskuri > 0) {
            sarcina.denumire =
              "(" + sarcina.numar_taskuri + ") " + sarcina.denumire;
          }

          //Valori din proiect
          if (sarcina.proiect) {
            Object.keys(sarcina.proiect).map((property) => {
              var value = sarcina.proiect[property];
              if (sarcina[property] === undefined) {
                sarcina[property] = value;
              }
              return null;
            });
            sarcina["path"] = sarcina.proiect["path"];
            if (sarcina["path"]) {
              var path = "";
              sarcina["path"].split("\\").map((f) => {
                if (f !== null && f !== undefined) {
                  var idProiect = parseInt(f);
                  var pro = this.$f7.data.allProiecte.find((p) => {
                    return p.iD_PROIECT === idProiect;
                  });
                  if (pro) {
                    path += pro.denumire + " \\ ";
                  }
                }
                return null;
              });
              sarcina["path"] = path.slice(0, -2);
            }
          }

          sarcina = this.$f7.methods.restructurareBaza(
            sarcina,
            this.state.inputs,
            "key"
          );
          return false;
        });
        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.refDataGrid.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  render() {
    return (
      <>
        <DataGrid
          onInitialised={(state, r, itemeRandate) => {
            if (r.props.parinteMain === undefined) {
              setTimeout(() => {
                this.$f7.methods.colorItems(
                  state,
                  r,
                  itemeRandate,
                  "Salariat",
                  1
                );
              }, 5000);
            }
          }}
          onRenderItemsChanged={(state, r, itemeRandate) => {
            if (r.props.parinteMain === undefined) {
              setTimeout(() => {
                this.$f7.methods.colorItems(
                  state,
                  r,
                  itemeRandate,
                  "Salariat",
                  0
                );
              }, 5000);
            }
          }}
          dataLoaded={this.state.dataLoaded}
          refresh={this.GetValues}
          ref={this.refDataGrid}
          hintInputSources={[
            "Path",
            "OperatorAdaugare",
            "BugetTimp",
            "TimpPlanD",
            "PVuri",
          ]}
          name={"SarciniToateGrid"}
          id={"IdProiect"}
          refId={"ProiectRef"}
          resizeColumns={true}
          initialRefId={-1}
          renderAdd={false}
          renderDelete={false}
          renderUpdate={false}
          renderAccordion={true}
          keys={["💾", "Denumire", "Client", "ProiectRef", "F"]}
          renderFilterColumns={true}
          dataSource={this.state.dataSource}
          maxHeight={this.state.height}
          inputs={this.state.inputs}
          contextMenuItems={[
            {
              key: "Arhiveaza",
              onClick: (state) => {
                setTimeout(() => {
                  var selectedRows = state.allSelectedRows;
                  if (selectedRows.length === 0) {
                    selectedRows = [state.rowDataAll];
                  }
                  var listaIduri = [];
                  var neinchiseFlag = false;
                  selectedRows.map((r) => {
                    var id = r.IdProiect;
                    var stare = r.UltimaStare;

                    if (!r.IsArhivat) {
                      if (stare === 6) {
                        listaIduri.push(id);
                      } else {
                        neinchiseFlag = true;
                      }
                    }
                    return null;
                  });

                  if (neinchiseFlag && listaIduri.length === 0) {
                    this.$f7.methods.openNotification(
                      "Toate taskurile selectate nu sunt inchise!"
                    );
                  } else if (neinchiseFlag && listaIduri.length > 0) {
                    this.$f7.methods.openNotification(
                      "Selectia contine si taskui neinchise!"
                    );

                    setTimeout(() => {
                      this.$f7.methods
                        .arhiveazaDezarhiveazaProiect(listaIduri, true)
                        .then((r) => {
                          this.GetValues();
                          const nr = r.data.length;
                          this.$f7.methods.openNotification(
                            "Arhivate cu success: " +
                              nr +
                              ". Taskurile neinchise din selectie nu au fost arhivate"
                          );
                        })
                        .catch((err) => {
                          this.$f7.methods.openNotification(
                            "Eroare la arhivare" + err
                          );
                        });
                    }, 4000);
                  } else {
                    this.$f7.methods
                      .arhiveazaDezarhiveazaProiect(listaIduri, true)
                      .then((r) => {
                        this.GetValues();
                        const nr = r.data.length;
                        this.$f7.methods.openNotification(
                          "Arhivate cu success: " + nr
                        );
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare la arhivare" + err
                        );
                      });
                  }
                }, 1000);
              },
              icon: "compress",
            },
            {
              key: "Dezarhiveaza",
              onClick: (state) => {
                setTimeout(() => {
                  var selectedRows = state.allSelectedRows;
                  if (selectedRows.length === 0) {
                    selectedRows = [state.rowDataAll];
                  }
                  var listaIduri = [];
                  selectedRows.map((r) => {
                    var id = r.IdProiect;
                    if (r.IsArhivat) {
                      listaIduri.push(id);
                    }
                    return null;
                  });
                  this.$f7.methods
                    .arhiveazaDezarhiveazaProiect(listaIduri, false)
                    .then((r) => {
                      this.GetValues();
                      const nr = r.data.length;
                      this.$f7.methods.openNotification(
                        "Dezarhivate cu success: " + nr
                      );
                    })
                    .catch((err) => {
                      this.$f7.methods.openNotification(
                        "Eroare la dezarhivare" + err
                      );
                    });
                }, 1000);
              },
              icon: "expand",
            },
            {
              key: "Ascunde",
              onClick: (state, context) => {
                setTimeout(() => {
                  var selectedRows = state.allSelectedRows;
                  if (selectedRows.length === 0) {
                    selectedRows = [state.rowDataAll];
                  }
                  var listaIduri = [];
                  selectedRows.map((r) => {
                    var id = r.IdProiect;
                    if (!r.IsHidden) {
                      listaIduri.push(id);
                    }
                    return null;
                  });
                  this.$f7.methods
                    .ascundeAfiseazaProiect(listaIduri, true)
                    .then(() => {
                      this.GetValues();
                      this.$f7.methods.openNotification("Ascuns cu success");
                    })
                    .catch((err) => {
                      this.$f7.methods.openNotification(
                        "Eroare la ascundere" + err
                      );
                    });
                }, 1000);
              },
              icon: "refresh",
            },
            {
              key: "Afiseaza",
              onClick: (state, context) => {
                setTimeout(() => {
                  var selectedRows = state.allSelectedRows;
                  if (selectedRows.length === 0) {
                    selectedRows = [state.rowDataAll];
                  }

                  console.log(state);
                  var listaIduri = [];
                  selectedRows.map((r) => {
                    var id = r.IdProiect;
                    if (r.IsHidden) {
                      listaIduri.push(id);
                    }
                    return null;
                  });

                  this.$f7.methods
                    .ascundeAfiseazaProiect(listaIduri, false)
                    .then(() => {
                      this.GetValues();
                      this.$f7.methods.openNotification("Afisat cu success");
                    })
                    .catch((err) => {
                      this.$f7.methods.openNotification(
                        "Eroare la afisare" + err
                      );
                    });
                }, 1000);
              },
              icon: "refresh",
            },
            {
              key: "Trimite mesaj",
              onClick: (state) => {
                var id = state.rowData.IdProiect;
                this.$f7.methods.openMesaje(null, id);
              },
              icon: "send",
            },
          ]}
          coloredRow={{
            sourceCol: "Deadline",
            type: "date",
          }}
          onRowClick={(state) => {
            var id = state.rowData.IdProiect;
            this.$f7.methods.openMainDreaptaById(id);
            //  var lvl = this.refDataGrid.current.getItemLevel(id);
          }}
          renderCheckbox={true}
        />
      </>
    );
  }
}
export default SarciniToateGrid;
