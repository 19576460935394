import React, { Component } from "react";
import DataGrid from "../../Datagrid/DataGrid";
import { ListInput, List, Icon } from "framework7-react";
import moment from "moment";
import axios from "axios";

 /**
  * @description -Afiseaza un datagrid cu toate intervalele in care a fost activ un utilizator, data de intrare si data de iesire, in cazul in care utilizatorul nu are data de final apare "Fara".
-Exista un selectbox cu care selectezi data pentru care sa se afiseze activitatea utilizatorilor

  * @date 06/04/2022
  * @class ActivitateZilnica
  * @extends {Component}
  */
 class ActivitateZilnica extends Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;

    this.state = {
      inputs: [
        { key: "Salariat" },
        { key: "Intrare" },
        { key: "Iesire" },
        { key: "Eveniment" },
      ],
      dataLoaded: false,
      height: window.innerHeight - 44,
      dataVizualizare: [moment(Date.now()).format()],
      linkApi: "/api/ActivitateZilnica",
      salariatiArray: this.$f7.data.SalariatiActivi,
      dataSource: [],
    };

    this.refDataGrid = React.createRef();
  }
  /**
   * Ia valorile necesare pentru randare de la endpointul "/api/ActivitateZilnica"
   * @param {*} rawDate 
   */
  GetValues = (rawDate) => {
    //'mm-dd-yy'
    let initialDate = new Date(rawDate[0]);
    const date =
      initialDate.getMonth() +
      1 +
      "-" +
      initialDate.getDate() +
      "-" +
      initialDate.getFullYear().toString().substring(2);

    this.setState({
      dataLoaded: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/" + date)
      .then((response) => {
        let change = response;

        for (let item in change.data) {
          change.data[item]["Salariat"] =
            change.data[item].id_salariat === "" ||
            change.data[item].id_salariat === null
              ? "Fara"
              : this.state.salariatiArray.filter((obj) => {
                  return obj.value === change.data[item].id_salariat;
                })[0].key;
          change.data[item]["Intrare"] =
            change.data[item].intrare1 === "" ||
            change.data[item].intrare1 === null
              ? "Fara"
              : change.data[item].intrare1;
          change.data[item]["Iesire"] =
            change.data[item].iesire1 === "" ||
            change.data[item].iesire1 === null
              ? "Fara"
              : change.data[item].iesire1;
          change.data[item].eveniment =
            change.data[item].eveniment === "" ||
            change.data[item].eveniment === null
              ? "Fara"
              : change.data[item].eveniment;
        }

        let baza = this.$f7.methods.reconstructieBaza(
          change,
          this.state.inputs
        );

        this.setState(
          {
            dataSource: baza.length === 0 ? [] : baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  this.refDataGrid.current.setRenderItems(baza);
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues(this.state.dataVizualizare);
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };
  
  componentDidMount = () => {
    this.GetValues(this.state.dataVizualizare);
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <List
          noChevron
          noHairlines
          noHairlinesBetween
          noHairlinesBetweenMd
          noHairlinesMd
          className="optiuniActivitate butoaneContextDatagrid"
          style={{
            position: "absolute",
            top: -8,
            left: 0,
            width: "calc(100% - 176px)",
            margin: 0,
          }}>
          <ListInput
            validate
            required
            label="Data"
            type="datepicker"
            calendarParams={{
              timePicker: true,
              dateFormat: "dd.mm.yyyy",
              closeOnSelect: true,
              on: {
                closed: (e) => {
                  const val = e.value[0];
                  this.setState(
                    {
                      dataVizualizare: [val],
                    },
                    () => {
                      this.GetValues(this.state.dataVizualizare);
                    }
                  );
                },
              },
            }}
            value={this.state.dataVizualizare}
            floatingLabel
            placeholder="Data">
            <Icon
              color={"var(--f7-theme-color)"}
              size={20}
              material="today"
              slot="content-start"
              style={{ left: 0, top: 0 }}></Icon>
          </ListInput>
        </List>
        <DataGrid
          dataLoaded={this.state.dataLoaded}
          refresh={this.GetValues}
          name={"ActivitateZilnica"}
          ref={this.refDataGrid}
          renderUpdate={false}
          renderDelete={false}
          renderAdd={false}
          maxHeight={this.state.height}
          dataSource={this.state.dataSource}
          inputs={this.state.inputs}
        />
      </div>
    );
  }
}
export default  ActivitateZilnica;
