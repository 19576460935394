import React, { Component } from 'react'
import { Page } from "framework7-react"
import { Button } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import axios from 'axios'

class Tasks extends Component {
  constructor(props) {
    super(props)

    this.Salariati = this.$f7.data.toggleTotiSalariatii ? this.$f7.data.Salariati : this.$f7.data.SalariatiActivi
  
    this.state = {
      linkApiProiecte: "/api/Proiecte",
      durata: null,
      durataTotal: null,
      lat: 0,
      lng: 0,
      idProiectVechi: null,
      dataLoaded: false,
      denumire: "Taskuri",
      height: window.innerHeight - 96,
      linkApi: "/api/SarciniSalariat",
      linkApiRealizari: "/api/RealizareSarcini",
      linkApiGetProiect: "/api/Proiecte/GetPROIECTESauArhivate",
      linkApiUpdateProiect: "/api/Proiecte/PutPROIECTE",
      linkApiDelegatii: "/api/Delegatii",
      linkApiDetaliu: "/api/DetaliuDelegatii",
      dataSource: [],
      dataSourceHidden: [],
      inputs: [
        { key: "💾", keyBaza: "areDocumente", tip: "boolean", disabled: true },
        { key: "IdSarcina", keyBaza: "idSarcina", tip: "number" },
        { key: "IdProiect", keyBaza: "idProiect", tip: "number" },
        { key: "Denumire", keyBaza: "denumire", columnWidth: "250px" },
        { key: "Cerinta", keyBaza: "comentariu", columnWidth: "500px" },
        { key: "Parinte", keyBaza: "parinte" },
        { key: "DataCreare", keyBaza: "dataCreare", tip: "datebox", columnWidth: "100px" },
        { key: "DataAlocarii", keyBaza: "dataAlocarii", tip: "datebox" },
        { key: "DeCine", keyBaza: "deCine" },
        { key: "Deadline", keyBaza: "deadline", tip: "datebox" },
        { key: "Client", keyBaza: "idClient", tip: "selectbox", lista: this.$f7.data.Firme, columnWidth: "20px" },
        { key: "Sursa", keyBaza: "sursa", columnWidth: "20px" },
        { key: "PR", keyBaza: "idPrioritate", tip: "selectbox", lista: this.$f7.data.Prioritati, columnWidth: "20px" },
        { key: "IsArhivat", keyBaza: "isArhivat", disabled: true, tip: "bool" },
        { key: "IsHidden", keyBaza: "isHidden", disabled: true, tip: "bool" },
        { key: "Path", keyBaza: "path" },
        { key: "OperatorAdaugare", keyBaza: "id_operator_adaugare", tip: "selectbox", lista: this.$f7.data.Salariati },
        { key: "BugetTimp", keyBaza: "buget_timp", tip: "number" },
        { key: "TimpPlanD", keyBaza: "timp_plan_d", tip: "number" },
        { key: "UltimaStare", keyBaza: "ultimaStare", tip: "number" },
        { key: "NivelProiect", keyBaza: "id_nivel_proiect", tip: "selectbox", lista: this.$f7.data.NivelProiect },
        { key: "F", keyBaza: "facturat", disabled: true, tip: "bool" },
        { key: "TimpLucrat", keyBaza: "timp_l", tip: "number" },
        { key: "PVuri", keyBaza: "apartenenta_pv" }
      ],
      hiddenInputs: [
        { key: "Id_Realizare" },
        { key: "NumeSalariat", tip: "selectbox", lista: this.$f7.data.Salariati, listaEdit: this.Salariati },
        { key: "Proiect", keyBaza: "iD_PROIECT", tip: "selectbox", lista: this.$f7.data.Proiecte },
        { key: "MomentInceput", tip: "datebox" },
        { key: "MomentSfarsit", tip: "datebox" },
        { key: "Unitati", tip: "number" },
        { key: "Operatie", tip: "selectbox", lista: this.$f7.data.Operatiuni, required: true, defaultValue: null },
        { key: "Client", tip: "selectbox", lista: this.$f7.data.Firme },
        { key: "Stadiu", tip: "selectbox", lista: this.$f7.data.Stadiu, required: true, defaultValue: null },
        { key: "Comentariu", required: false, defaultValue: "", tip: "textarea", inputHeight: 100, maxlength: 10000 },
        { key: "Observatie", required: false, defaultValue: "", tip: "textarea", inputHeight: 100, maxlength: 10000 },
        { key: "ComentariuPv", required: false, defaultValue: "", tip: "textarea", inputHeight: 250, maxlength: 10000 },
      ],
      sfarsit: false,
      idSelected: null,
      idSarcina: null,
      idPropunere: null,
      deschideNou: false,
      proiectDeschis: null,
      inchideDupaOprire: false,
      realocaDupaOprire: false,
      comInchidereTask: false,
      listaClientiTelefon: [],
      listaProiecteTelefon: [],
      idClientTelefon: null,
      idProiectParinteTelefon: null
    }
  }

  getValues = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        proiectDeschis: null,
        dataLoaded: false,
        idSelected: null,
        durata: null
      }, () => {
        if (this.props.outside) {
          this.$f7.data.Proiect = null
          this.props.updateDreapta()
        } else {
          this.$f7.methods.openMainDreaptaById(null)
        }
      })

      // this.$f7.methods.getProiecte(
      //   this.$f7.data.idSalariatFunctii,
      //   1,
      //   this.props.checkedTaskuri === true ? 1 : 0,
      //   this.props.checkedAscunse === true ? 1 : 0,
      //   this.props.checkedArhivate === true ? 1 : 0,
      //   1
      // )
      
      this.$f7.methods.getProiecte(this.$f7.data.idSalariatFunctii, 1, 1, 1, 1, 1).then(response => {
        const date = response

        date.data.forEach(sarcina => {
          if (sarcina.proiect) {
            Object.keys(sarcina.proiect).forEach(property => {
              const value = sarcina.proiect[property]
              if (sarcina[property] === undefined) sarcina[property] = value
            })

            sarcina["path"] = sarcina.proiect["path"]

            if (sarcina["path"]) {
              let path = ""

              sarcina["path"].split("\\").forEach(f => {
                if (f !== null && f !== undefined) {
                  const idProiect = parseInt(f)
                  const pro = this.$f7.data.allProiecte.find(p => p.iD_PROIECT === idProiect)
                  if (pro) path += pro.denumire + " \\ "
                }
              })

              sarcina["path"] = path.slice(0, -2)
            }
          }

          sarcina = this.$f7.methods.restructurareBaza(sarcina, this.state.inputs, "key")

          switch (sarcina.Sursa) {
            case 55:
              sarcina.Sursa = `M (${sarcina.proiect.userImport % 10000000})`
            break

            case 56:
              sarcina.Sursa = "E"
            break

            default:
              sarcina.Sursa = "C"
            break
          }

          if (sarcina.exista === 1) {
            this.setState({ proiectDeschis: sarcina })
          }
        })

        const baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs)

        this.setState({
          dataSource: baza
        }, () => {
          console.log("dataSource:", this.state.dataSource)

          setTimeout(() => {
            this.setState({
              dataLoaded: true
            }, () => {
              resolve(response)
            })
          }, 400)
        })
      }).catch(error => {
        this.setState({
          dataSource: [],
          dataLoaded: true
        }, () => {
          this.refDataGrid.current.setRenderItems([])
          this.$f7.dialog.confirm(
            "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
            "Ups...",
            () => {
              this.GetValues().then(resp => {
                resolve(resp)
              }).catch(error => {
                reject(error)
              })
            },
            () => {
              reject(error)
            }
          )
        })
      })
    })
  }

  componentDidMount = () => {
    console.log("props Tasks:", this.props)
    console.log("f7 Tasks:", this.$f7)

    this.$f7.data.selectedGridLeft = this

    this.getValues().then(() => {
      axios.get("/api/SarciniSalariat/GetMantisTaskuri")
    }).catch((err) => {
      console.log(err)
    })
  }

  render() {
    return (
      <Page
        className={"themeBackground"}
        onPageInit={() => {
          this.$f7.data.paginaCurenta = this
        }}
      >
        <div className="ptr3">
          <h1>Tasks</h1>
          <Button>Button</Button>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Accordion 1</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Accordion 2</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion disabled>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography>Disabled Accordion</Typography>
            </AccordionSummary>
          </Accordion>
        </div>
      </Page>
    )
  }
}

export default Tasks
