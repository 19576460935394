import React, { Component } from "react";
import { Page } from "framework7-react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";

class IstoricModificari extends Component {
    constructor(props) {
        super(props);

        this.height = window.innerHeight;

        this.Salariati = this.$f7.data.toggleTotiSalariatii ? this.$f7.data.Salariati : this.$f7.data.SalariatiActivi;

        this.state = {
            dataLoaded: false,
            height: window.innerHeight - 44,
            inputs: [
                { key: "Data", tip: "datebox" },
                { key: "Salariat", tip: "selectbox", lista: this.$f7.data.Salariati, listaEdit: this.Salariati },
                {
                    key: "TipModificare",
                    tip: "selectbox",
                    lista: [
                        { key: "Fara", value: null },
                        { key: "Sters", value: 3 },
                        { key: "Modificat", value: 2 },
                        { key: "Adaugat", value: 1 },
                    ],
                },
                { key: "Denumire" },
                { key: "Comentariu" },
                { key: "Parinte", tip: "selectbox", lista: this.$f7.data.Proiecte },
                { key: "Stadiu", tip: "selectbox", lista: this.$f7.data.Stadiu },
                { key: "Folosit", tip: "bool" },
                { key: "Task", tip: "bool" },
                { key: "Arhivat", tip: "bool" },
                { key: "Ascuns", tip: "bool" },
                {
                    key: "Prioritate",
                    tip: "selectbox",
                    lista: this.$f7.data.Prioritati,
                },
                {
                    key: "NivelProiect",
                    tip: "selectbox",
                    lista: this.$f7.data.NivelProiect,
                },
                {
                    key: "Operatiune",
                    tip: "selectbox",
                    lista: this.$f7.data.Operatiuni,
                },
                {
                    key: "TipProiect",
                    tip: "selectbox",
                    lista: this.$f7.data.TipProiect,
                },
                { key: "Clienti", tip: "selectbox", lista: this.$f7.data.Firme },
                { key: "DataCreare", tip: "datebox" },
                { key: "DataStart", tip: "datebox" },
                { key: "DataLimita", tip: "datebox" },
                { key: "DataInceputLucru", tip: "datebox" },
                { key: "DataFinal", tip: "datebox" },
                { key: "TimpEstimatDezvoltator", tip: "number" },
                { key: "TimpEstimatSefProiect", tip: "number" },
                { key: "TimpEstimatArbitru", tip: "number" },
                { key: "TimpEstimatManager", tip: "number" },
                { key: "ObservatieStadiu" },
                { key: "UserImport" },
                {
                    key: "Responsabil",
                    tip: "selectbox",
                    lista: this.$f7.data.Salariati,
                    listaEdit: this.Salariati
                },
                {
                    key: "Dezvoltator",
                    tip: "selectbox",
                    lista: this.$f7.data.Salariati,
                    listaEdit: this.Salariati
                },
                { key: "Arbitru", tip: "selectbox", lista: this.$f7.data.Salariati, listaEdit: this.Salariati },
                { key: "SefProiect", tip: "selectbox", lista: this.$f7.data.Salariati, listaEdit: this.Salariati },
                { key: "Manager", tip: "selectbox", lista: this.$f7.data.Salariati, listaEdit: this.Salariati },
                {
                    key: "Departament",
                    tip: "selectbox",
                    lista: this.$f7.data.Departamente,
                },
                { key: "DataImport", tip: "datebox" },
                { key: "Tip" },
                { key: "Termen" },
                { key: "Facturat", tip: "bool" },
                { key: "Venit" },
                { key: "ValoareMinima" },
                { key: "ValoareMaxima" },
                { key: "Buget" },
                { key: "BugetTimp" },
                { key: "ProcentRisc" },
                { key: "CostEstimat" },
                { key: "CostRealCalculat" },
                { key: "DirectorRadacina" },
                { key: "Nivel" },
                { key: "AprobatDezvoltator", tip: "bool" },
                { key: "AprobatSefProiect", tip: "bool" },
                { key: "AprobatArbitru", tip: "bool" },
                { key: "AprobatManager", tip: "bool" },
                { key: "CheltuieliSalariale" },
                { key: "TaskSimilar" },
            ],
            dataSource: [],
            linkApi: "/api/LogProiecte",
        };
        this.refDataGrid = React.createRef();
    }

    GetValues = () => {
        this.setState({
            dataLoaded: false,
        });

        axios
            .get(
                this.$f7.data.baseURL +
                this.state.linkApi +
                "/GetLogProiecteById/" +
                this.$f7.data.Proiect.iD_PROIECT
            )
            .then((response) => {
                var datele = response;
                datele.data.map((obj) => {
                    obj["tipmodificare"] = obj.rowstate;
                    obj["tip"] = obj.tip_exc;
                    obj["data"] = obj.rowTime;
                    obj["salariat"] = obj.refUser;
                    //obj["denumire"] = obj.denumire;
                    //obj["comentariu"] = obj.comentariu;
                    obj["parinte"] = obj.ref_proiect;
                    obj["stadiu"] = obj.iD_STADIU;
                    //obj["folosit"] = obj.folosit;
                    //obj["task"] = obj.task;
                    obj["arhivat"] = obj.isArhivat;
                    obj["ascuns"] = obj.isHidden;
                    obj["prioritate"] = obj.id_prioritate;
                    obj["nivelproiect"] = obj.id_nivel_proiect;
                    obj["operatiune"] = obj.id_operatiuni;
                    obj["tipproiect"] = obj.id_tip_proiect;
                    obj["clienti"] = obj.id_client;
                    obj["datacreare"] = obj.data_creare;
                    obj["datastart"] = obj.data_start;
                    obj["datalimita"] = obj.deadline;
                    obj["datainceputlucru"] = obj.data_start;
                    obj["datafinal"] = obj.data_final;
                    obj["TimpEstimatDezvoltator"] = obj.timp_plan_d;
                    obj["TimpEstimatSefProiect"] = obj.timp_plan_sp;
                    obj["timpestimatarbitru"] = obj.timp_plan_a;
                    obj["timpestimatmanager"] = obj.timp_plan_m;
                    obj["observatiestadiu"] = obj.stadiu1;
                    //obj["userimport"] = obj.userimport;
                    obj["responsabil"] = obj.id_responsabil;
                    obj["dezvoltator"] = obj.id_dezvoltator;
                    obj["arbitru"] = obj.id_arbitru;
                    obj["sefproiect"] = obj.id_sefproiect;
                    obj["manager"] = obj.id_manager;
                    obj["departament"] = obj.id_departament;
                    //obj["dataimport"] = obj.dataimport;
                    obj["termen"] = obj.id_termen;
                    //obj["facturat"] = obj.facturat;
                    //obj["venit"] = obj.venit;
                    obj["valoareminima"] = obj.valoare_minima;
                    obj["valoaremaxima"] = obj.valoare_maxima;
                    //obj["buget"] = obj.buget;
                    obj["bugettimp"] = obj.buget_timp;
                    obj["procentrisc"] = obj.proc_risc;
                    obj["costestimat"] = obj.cost_estimat;
                    obj["costrealcalculat"] = obj.cost_real_calc;
                    obj["directorradacina"] = obj.director_radacina;
                    //obj["nivel"] = obj.nivel;
                    obj["aprobatdezvoltator"] = obj.aprobat_d;
                    obj["aprobatsefproiect"] = obj.aprobat_sp;
                    obj["aprobatarbitru"] = obj.aprobat_a;
                    obj["aprobatmanager"] = obj.aprobat_m;
                    obj["cheltuielisalariale"] = obj.chelt_sal;
                    obj["tasksimilar"] = obj.idtasksimilar;
                    return false;
                });

                var baza = this.$f7.methods.reconstructieBaza(
                    datele,
                    this.state.inputs
                );

                this.setState(
                    {
                        dataSource: baza,
                    },
                    () => {
                        setTimeout(() => {
                            this.setState(
                                {
                                    dataLoaded: true,
                                },
                                () => {
                                    this.refDataGrid.current.setRenderItems(baza);
                                }
                            );
                        }, 400);
                    }
                );
            })
            .catch((error) => {
                this.setState(
                    {
                        dataSource: [],
                        dataLoaded: true,
                    },
                    () => {
                        this.refDataGrid.current.setRenderItems([]);
                        this.$f7.dialog.confirm(
                            "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                            "Ups...",
                            () => {
                                this.GetValues();
                            },
                            () => { }
                        );
                    }
                );

                console.log("error", error);
            });
    };

    componentDidMount = () => {
        if (this.$f7.data.Proiect !== null) this.GetValues();
    };

    render() {
        return (
            <Page pageContent={false}>
                {this.$f7.data.Proiect !== null ?
                    <DataGrid
                        dataLoaded={this.state.dataLoaded}
                        refresh={this.GetValues}
                        name={"IstoricModificari"}
                        ref={this.refDataGrid}
                        renderUpdate={false}
                        renderDelete={false}
                        renderAdd={false}
                        renderFilterColumns={false}

                        maxHeight={this.state.height}
                        dataSource={this.state.dataSource}
                        inputs={this.state.inputs}
                        coloredRow={{
                            sourceCol: "DataLimita",
                            type: "date"
                        }}
                    />
                    :
                    <div style={{
                        position: "relative",
                        height: "calc(100vh - 50px)"
                    }}>
                        <h3 style={{
                            position: "absolute",
                            top: "50%",
                            left: 0,
                            right: 0,
                            transform: "translateY(-50%)",
                            textTransform: "uppercase",
                            textAlign: "center",
                            display: "block"
                        }}>
                            Nu ati selectat nimic
                        </h3>
                    </div>
                }
            </Page>
        );
    }
}
export default IstoricModificari;