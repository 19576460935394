import React from "react";
import { List, ListItem, Segmented, Button, Icon, Col } from "framework7-react";

/**
 * @description Randeaza lista paginata pentru proiectele din bara de search(lista full de proiecte)
 * @date 07/04/2022
 * @class ListWithPaging
 * @extends {React.PureComponent}
 */
class ListWithPaging extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      numberBefore: 0,
      count: 3,
    };
    this.settings = props.full
      ? null
      : {
          width: "100",
          xsmall: "100",
          small: "100",
          medium: "50",
          large: "50",
          xlarge: "50",
        };
  }

  render() {
    return (
      <>
        {this.props.dataSource.length > 0 ? (
          <Col {...this.settings}>
            <div style={{ display: "flex", width: "100%" }}>
              <h3 style={{ margin: "2px" }}>{this.props.title}</h3>
              <Segmented
                style={{
                  maxWidth: "150px",
                  margin: "0 auto",
                  marginTop: "2px",
                  marginBottom: "2px",
                  marginRight: "0px",
                  marginLeft: "auto",
                }}
                tag="p">
                <Button
                  tooltip={"Inapoi"}
                  disabled={
                    parseInt(this.state.numberBefore - this.state.count) >= 0
                      ? false
                      : true
                  }
                  small
                  onClick={() => {
                    let lastNumber = this.state.numberBefore - this.state.count;
                    if (lastNumber >= 0) {
                      this.setState({
                        numberBefore: lastNumber,
                      });
                    }
                  }}>
                  <Icon
                    style={{
                      color:
                        parseInt(this.state.numberBefore - this.state.count) >=
                        0
                          ? "var(--f7-theme-color)"
                          : "lightgray",
                    }}
                    size={30}
                    material="navigate_before"
                  />
                </Button>
                <Button
                  tooltip={"Inainte"}
                  disabled={
                    parseInt(this.state.numberBefore + this.state.count) <=
                    parseInt(this.props.dataSource.length - 1)
                      ? false
                      : true
                  }
                  small
                  onClick={() => {
                    let lastNumber = this.state.numberBefore + this.state.count;
                    if (lastNumber <= this.props.dataSource.length - 1) {
                      this.setState({
                        numberBefore: lastNumber,
                      });
                    }
                  }}>
                  <Icon
                    style={{
                      color:
                        parseInt(this.state.numberBefore + this.state.count) <=
                        parseInt(this.props.dataSource.length - 1)
                          ? "var(--f7-theme-color)"
                          : "lightgray",
                    }}
                    size={30}
                    material="navigate_next"
                  />
                </Button>
              </Segmented>
            </div>
            <List>
              {this.props.dataSource
                .slice(
                  this.state.numberBefore,
                  this.state.numberBefore + this.state.count
                )
                .map((e, index) => (
                  <ListItem
                    onClick={() => {
                      if (this.props.openDetails) {
                        this.props.openDetails(e);
                      }
                    }}
                    key={"itemListWithPaging-" + index}>
                    <p>{e[this.props.denumire]}</p>
                  </ListItem>
                ))}
            </List>
          </Col>
        ) : null}
      </>
    );
  }
}
export default ListWithPaging;
