import React, { Component } from 'react';
import { Preloader } from "framework7-react"
import EmailContentBar from './EmailContentBar'

class EmailContentPanel extends Component {
  render() {
    // const { id, fromAddress, toAddress, subject, date, internalDate, body, attachmentsName, attachmentsList } = this.props.mailInfo
    const { mailInfo, selectedEmailId, isUnread, activeTab, handleMarkMailAsUnread, handleMoveToTrash, handleMoveFromTrash, handleAddTask } = this.props
    let content = ''
    
    if (mailInfo) {
      let parser = new DOMParser()
      content = parser.parseFromString(mailInfo.body, 'text/html')
      content = content.body.innerHTML
      console.log('content:', content)
    }

    return (
      <div
        id='gmailContentPanel'
        style={{ width: 'calc(100% - 300px)', height: '100%', backgroundColor: '#f0f0f0', boxSizing: 'border-box', padding: 8, overflow: 'hidden', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      >
        {selectedEmailId ? (
          mailInfo
            ? (
              <>
                <EmailContentBar id={this.props.mailInfo.id} subject={mailInfo.subject} isUnread={isUnread} activeTab={activeTab} handleMarkMailAsUnread={handleMarkMailAsUnread} handleMoveToTrash={handleMoveToTrash} handleMoveFromTrash={handleMoveFromTrash} handleAddTask={handleAddTask} />
                <div style={{ width: '100%', height: 'calc(100% - 48px)', overflowY: 'auto', boxSizing: 'border-box', padding: 8 }} dangerouslySetInnerHTML={{ __html: `<div>${content}</div>` }}></div>
              </>
            )
            : (
              <Preloader style={{ alignSelf: 'center' }} />
            )
          ) : (
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 30 }}>
              Selecteaza un email din partea stanga pentru a il deschide!
            </div>
          )
        }
      </div>
    )
  }
}

export default EmailContentPanel;
