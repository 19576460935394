import React from "react";
import { Preloader, Page, List, ListItem, Icon, Chip } from "framework7-react";
import Timeline from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import axios from "axios";
var dynamicPopover = null;
/**
 * @description Interfata de randare a unui grafic pentru un proiect
 * @date 08/04/2022
 * @class Grafic2
 * @extends {React.Component}
 */
class Grafic2 extends React.Component {
  constructor(props) {
    super(props);
    this.height = window.innerHeight;
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.groups =
      this.$f7.data.Proiect !== null
        ? [{ id: 1, title: "Total " + this.$f7.data.Proiect.denumire }]
        : [];
    var listaSalariati = [...this.Salariati];
    listaSalariati.shift();

    this.state = {
      ListaCulori: [{ id: 3, culoare: "#ffffff" }],
      ListaSalariati: listaSalariati,
      SalariatiSelectati: [this.$f7.data.idSalariatFunctii.toString()],
      dataLoaded: false,
      linkApi: "/api/RealizareSarcini",
      height: window.innerHeight - 112,
      dataSource: [],
      mode: "year",
      data: [
        {
          id: 1,
          group: 1,
          title: "",
          start_time: moment().add(-0.5, "hour"),
          end_time: moment().add(5, "hour"),
        },
        {
          id: 2,
          group: 2,
          title: "",
          start_time: moment(),
          end_time: moment().add(1, "hour"),
        },
        {
          id: 3,
          group: 2,
          title: "",
          start_time: moment().add(2, "hour"),
          end_time: moment().add(3, "hour"),
        },
      ],
      groups: [
        { id: 1, title: "Total" },
        { id: 2, title: "Lucrat" },
      ],
    };
  }

  /**
   * @description Transforma milisecundele transmise ca parametru intr-un format mai placut "z h m s"
   * @date 08/04/2022
   * @param {*} ms milisecunde
   * @function
   * @memberof Grafic2
   */
  dhm = (ms) => {
    var days = Math.floor(ms / (24 * 60 * 60 * 1000));
    var daysms = ms % (24 * 60 * 60 * 1000);
    var hours = Math.floor(daysms / (60 * 60 * 1000));
    var hoursms = ms % (60 * 60 * 1000);
    var minutes = Math.floor(hoursms / (60 * 1000));
    var minutesms = ms % (60 * 1000);
    var sec = Math.floor(minutesms / 1000);
    return days + "z " + hours + "h " + minutes + "m " + sec + " s ";
  };

  /**
   * @description Ia valorile necesare randarii graficului de realizari pentru proiectul selectat
   * @function
   * @date 08/04/2022
   * @memberof Grafic2
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });
    var Salariati = [];
    var ListaCulori = [];
    this.state.SalariatiSelectati.map((e) => {
      Salariati.push(parseInt(e));
      ListaCulori.push({
        id: parseInt(e),
        culoare: "hsl(" + Math.random() * 360 + ", 100%, 75%)",
      });
      return null;
    });
    var data = {
      IdProiect: this.$f7.data.Proiect.iD_PROIECT,
      Salariati: Salariati,
    };
    var url = this.$f7.data.baseURL + this.state.linkApi + "/GraficRequest";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "POST",
      url: url,
    })
      .then((response) => {
        var date = response.data;

        var newGroups = [...this.groups];

        var arr = [
          {
            id: 1,
            group: 1,
            start_time: moment(this.$f7.data.Proiect.data_start),
            end_time: moment(this.$f7.data.Proiect.deadline),
            title: "",
            canMove: false,
            canResize: false,
          },
        ];

        date.map((d) => {
          var lista = newGroups.filter((r) => {
            return r.id === d.iD_PROIECT;
          });

          if (lista.length === 0) {
            newGroups.push({
              id: d.iD_PROIECT,
              title: this.$f7.data.Proiecte.filter((pro) => {
                return pro.value === d.iD_PROIECT;
              })[0].key,
            });
          }
          return null;
        });

        date.map((d) => {
          var colorString = "";
          ListaCulori.map((r) => {
            if (r.id === parseInt(d.iD_SALARIAT)) {
              colorString = r.culoare;
            }
            return null;
          });

          arr.push({
            id: d.iD_REALIZARE,
            group: d.iD_PROIECT,
            start_time: moment(d.intrare),
            end_time: moment(d.iesire),
            title: "",
            canMove: false,
            canResize: false,
            itemProps: {
              style: {
                background: colorString,
                border: "1px solid " + colorString,
              },

              onClick: (e) => {
                var numeAngajat = this.$f7.data.Salariati.filter((r) => {
                  return r.value === d.iD_SALARIAT;
                })[0].key;

                let miliseconds =
                  new Date(d.iesire).getTime() - new Date(d.intrare).getTime();
                var durata = this.dhm(miliseconds);
                var t = e.currentTarget;
                dynamicPopover = this.$f7.popover.create({
                  backdrop: false,
                  targetEl: "a.dynamic-popover",
                  content:
                    '<div class="popover"><div class="popover-inner" style="padding-left:6px;padding-right:6px;"><p> Angajat:' +
                    numeAngajat +
                    "</p><p> Durata:" +
                    durata +
                    "</p></div></div>",
                });
                dynamicPopover.open(t, true);
              },
            },
          });
          return false;
        });
        this.setState(
          {
            groups: newGroups,
            data: arr,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
                ListaCulori: ListaCulori,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            data: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    if (this.$f7.data.Proiect !== null) this.GetValues();
  };

  render() {
    return (
      <Page pageContent={false}>
        {this.$f7.data.Proiect !== null ? (
          <div>
            {this.state.dataLoaded ? (
              <>
                <List style={{ margin: "8px!important" }}>
                  <ListItem
                    className="listaSalariatiGrafic"
                    validate
                    floatingLabel
                    title={"Salariati"}
                    label={"Salariati"}
                    smartSelect
                    smartSelectParams={{
                      virtualList: true,
                      searchbar: true,
                      openIn: "popup",
                      popupTabletFullscreen: true,
                      popupPush: true,
                      closeOnSelect: true,
                      on: {
                        closed: (event) => {
                          var val = event.getValue();
                          this.setState({ SalariatiSelectati: val }, () => {
                            if (this.state.SalariatiSelectati.length > 0) {
                              this.GetValues();
                            }
                          });
                        },
                      },
                    }}>
                    <select multiple name="SelectSalariatiGrafic">
                      {this.state.ListaSalariati.map((obj, index) => (
                        <option
                          key={"listaSalariatiGrafic2-" + index}
                          selected={
                            obj.value !== null &&
                            this.state.SalariatiSelectati.includes(
                              obj.value.toString()
                            )
                          }
                          value={obj.value}>
                          {obj.key}
                        </option>
                      ))}
                    </select>
                    <Icon
                      slot="content-start"
                      style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                      size={20}
                      material="list"
                    />
                  </ListItem>
                </List>
                <Timeline
                  sidebarWidth={230}
                  groups={this.state.groups}
                  items={this.state.data}
                  defaultTimeStart={moment().add(-12, "hour")}
                  defaultTimeEnd={moment().add(12, "hour")}
                />

                <div style={{ marginTop: 8 }}>
                  {this.state.ListaCulori.map((elem, index) => (
                    <Chip
                      key={"listaCuloriGrafic2-" + index}
                      style={{ backgroundColor: elem.culoare }}
                      textColor="white"
                      text={
                        this.$f7.data.Salariati.filter((r) => {
                          return r.value === elem.id;
                        })[0].key
                      }
                    />
                  ))}
                </div>
              </>
            ) : (
              <div
                style={{
                  position: "absolute",
                  width: "35px",
                  height: "35px",
                  top: "calc(50vh)",
                  left: "50%",
                }}>
                <Preloader size={40} color={"var(--f7-theme-color)"} />
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              height: "calc(100vh - 50px)",
            }}>
            <h3
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                right: 0,
                transform: "translateY(-50%)",
                textTransform: "uppercase",
                textAlign: "center",
                display: "block",
              }}>
              Nu ati selectat nimic
            </h3>
          </div>
        )}
      </Page>
    );
  }
}
export default Grafic2;
