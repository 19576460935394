import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import { Row, Col, Preloader } from "framework7-react";
import axios from "axios";

/**
 * @description Interfata de intretinere a tipurilr de proiecte si fazelor atasate acestora
 * @date 07/04/2022
 * @class TipuriProiecte
 * @extends {React.Component}
 */
class TipuriProiecte extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoaded1: false,
      dataLoaded2: false,
      linkApiProiect: "/api/TipProiect",
      linkApiFazeProiect: "/api/TipFazeTipProiect",
      linkApiFaze: "/api/TipFaze",
      denumire: "TipuriProiecte",
      height: window.innerHeight - 44,
      dataSource1: [],
      dataSource2: [],
      input1: [
        { key: "Id_Tip_Proiect", keyBaza: "iD_TIP_PROIECT", tip: "number" },
        {
          key: "Denumire",
          keyBaza: "denumire",
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "Ref_Tip_Proiect", keyBaza: "reF_TIP_PROIECT", tip: "number" },
        { key: "Ordine", keyBaza: "ordine", tip: "number" },
      ],
      input2: [
        { key: "IdTipFazeTipProiect" },
        { key: "Denumire", requiredAdd: true, requiredEdit: true },
        { key: "Checked", tip: "bool", requiredAdd: true, requiredEdit: true },
      ],
      proiectId: null,
    };

    this.refDataGrid1 = React.createRef();
    this.refDataGrid2 = React.createRef();
  }
  /**
   * @description Se iau toate inregistrarile pentru interfata de intretinere curenta(TipuriProiecte)
   * @function
   * @date 07/04/2022
   * @memberof TipuriProiecte
   */
  GetValuesProiect = () => {
    this.setState({
      dataLoaded1: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApiProiect)
      .then((response) => {
        this.$f7.methods.IaValori();

        var date = response;
        date.data.map((eveniment) => {
          eveniment = this.$f7.methods.restructurareBaza(
            eveniment,
            this.state.input1,
            "key"
          );
          return null;
        });
        var baza = this.$f7.methods.reconstructieBaza(date, this.state.input1);
        this.setState(
          {
            dataSource1: baza,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded1: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource1: [],
            dataLoaded1: true,
          },
          () => {
            this.refDataGrid1.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesProiect();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };
  /**
   * @description Adauga o inregistrare noua pentru interfata curenta(TipuriProiecte)
   * @date 07/04/2022
   * @param {*} data obiectul cu toate informatiile noii inregistrari
   * @function
   * @memberof TipuriProiecte
   */
  AddValueProiect = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApiProiect;
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValuesProiect();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };
  /**
   * @description Sterge din baza de date inregistrarea selectata
   * @date 07/04/2022
   * @param {*} id id-ul inregistrarii selectate
   * @memberof TipuriProiecte
   */
  DeleteValueProiect = (id) => {
    var url = this.$f7.data.baseURL + this.state.linkApiProiect + "/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValuesProiect();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };
  /**
   * @description Se modifica in baza de date inregistrarea selectata
   * @date 07/04/2022
   * @param {*} obj obiect cu noile informatii ale inregistrarii selectate
   * @param {*} id id-ul inregistrarii selectate
   * @memberof TipuriProiecte
   */
  UpdateValueProiect = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApiProiect + "/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url = this.$f7.data.baseURL + this.state.linkApiProiect + "/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValuesProiect();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Se iau toate fazele valabile
   * @function
   * @date 07/04/2022
   * @memberof TipuriProiecte
   */
  GetValuesFaze = () => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApiFaze)
      .then((response) => {
        var change = response;
        change.data = change.data.filter((objBaza) => {
          return objBaza.reF_ACTIUNE !== -1;
        });

        this.setState({
          dataSource2: change.data.length === 0 ? [] : change.data,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  /**
   * @description Se iau toate fazele care sunt atasate tipului de proiect selectat
   * @function
   * @date 07/04/2022
   * @memberof TipuriProiecte
   */
  GetValuesTipFaze = (id) => {
    this.GetValuesFaze();

    this.setState({
      dataLoaded2: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApiFazeProiect + "/" + id)
      .then((response) => {
        const tipFaze = response.data;
        let ds2 = [...this.state.dataSource2];
        let ob = {};

        ds2.map((el, index) => {
          if (tipFaze.length > 0) {
            for (let prop in tipFaze) {
              if (el.iD_FAZA === tipFaze[prop].iD_FAZA) {
                el["Checked"] = true;
                el["IdTipFazeTipProiect"] =
                  tipFaze[prop].iD_TIP_FAZE_TIP_PROIECT;
                break;
              } else el["Checked"] = false;
            }
          } else {
            el["Checked"] = false;
          }
          return null;
        });

        ob["data"] = ds2;
        let baza = this.$f7.methods.reconstructieBaza(ob, this.state.input2);

        baza.map((el, index) => {
          el["IdTipProiect"] = this.state.proiectId;
          el["IdFaza"] = ds2[index].iD_FAZA;

          if (ds2[index].IdTipFazeTipProiect) {
            el["IdTipFazeTipProiect"] = ds2[index].IdTipFazeTipProiect;
          }
          return null;
        });

        this.setState(
          {
            dataSource2: baza.length === 0 ? [] : baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded2: true,
                },
                () => {
                  this.refDataGrid2.current.setRenderItems(baza);
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource2: [],
            dataLoaded2: true,
          },
          () => {
            this.refDataGrid2.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesTipFaze(this.state.proiectId);
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };
  /**
   * @description Adauga o noua faza tipului de proiect selectat
   * @date 07/04/2022
   * @param {*} data obiectul cu toate informatiile noii inregistrari
   * @function
   * @memberof TipuriProiecte
   */
  AddValueTipFaze = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApiFazeProiect;
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValuesTipFaze(this.state.proiectId);
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };
  /**
   * @description Sterge din baza de date inregistrarea selectata(faza atasata tipului de proiect selectat)
   * @date 07/04/2022
   * @param {*} id id-ul inregistrarii selectate
   * @memberof TipuriProiecte
   */
  DeleteValueTipFaze = (id) => {
    var url = this.$f7.data.baseURL + this.state.linkApiFazeProiect + "/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValuesTipFaze(this.state.proiectId);
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  componentDidMount = () => {
    this.GetValuesProiect();
  };

  render() {
    return this.state.dataLoaded1 ? (
      <Row>
        <Col>
          <DataGrid
            name={"TipuriProiecte1"}
            id={"Id_Tip_Proiect"}
            refId={"Ref_Tip_Proiect"}
            ignoreInputsAdd={["Ref_Tip_Proiect"]}
            ignoreInputsEdit={["Ref_Tip_Proiect"]}
            keys={["Denumire"]}
            initialRefId={-1}
            renderAdd={true}
            renderDelete={true}
            renderUpdate={true}
            renderAccordion={true}
            maxHeight={this.state.height}
            inputs={this.state.input1}
            dataSource={this.state.dataSource1}
            onRowClick={(e) => {
              this.setState(
                {
                  proiectId: e.rowData.Id_Tip_Proiect,
                },
                () => {
                  this.GetValuesTipFaze(this.state.proiectId);
                }
              );
            }}
            onAdd={(state) => {
              var data = { ...state.editedItem };
              delete data.Id_Tip_Proiect;
              this.AddValueProiect(data);
            }}
            onDelete={(state) => {
              var data = { ...state.editedItem };

              const areCopii =
                state.bigDataSource.filter(
                  (x) => x.Ref_Tip_Proiect === data.Id_Tip_Proiect
                ).length > 0;
              if (areCopii) {
                this.GetValuesProiect();
                this.$f7.methods.openNotification(
                  "Acest element contine subelemente si nu se poate elimina! Indicatie: Incercati sa stergeti mai intai subelementele."
                );
              } else {
                this.DeleteValueProiect(data.Id_Tip_Proiect);
              }
            }}
            onUpdate={(state) => {
              var data = { ...state.editedItem };
              this.UpdateValueProiect(data, data.Id_Tip_Proiect);
            }}
          />
        </Col>
        <Col style={{ position: "relative" }}>
          {this.state.proiectId !== null ? (
            this.state.dataLoaded2 ? (
              <DataGrid
                name={"TipuriProiecte2"}
                id={"IdTipFazeTipProiect"}
                ref={this.refDataGrid2}
                title={"Faze"}
                renderUpdate={true}
                renderDelete={false}
                renderAdd={false}
                maxHeight={this.state.height}
                inputs={this.state.input2}
                dataSource={this.state.dataSource2}
                onUpdate={(state) => {
                  var data = { ...state.editedItem };

                  if (data.Checked === null) {
                    this.$f7.methods.openNotification(
                      "Nu se poate edita cu valoarea 'Fara'!"
                    );
                    this.GetValuesTipFaze(this.state.proiectId);
                  } else {
                    data["iD_FAZA"] = data.IdFaza;
                    data["iD_TIP_PROIECT"] = data.IdTipProiect;

                    delete data.IdFaza;
                    delete data.IdTipProiect;
                    delete data.Denumire;

                    if (data.Checked === true) this.AddValueTipFaze(data);
                    else this.DeleteValueTipFaze(data.IdTipFazeTipProiect);
                  }
                }}
              />
            ) : (
              <div
                style={{
                  position: "absolute",
                  width: "35px",
                  height: "35px",
                  top: "calc(50vh)",
                  left: "50%",
                }}>
                <Preloader size={40} color={"var(--f7-theme-color)"} />
              </div>
            )
          ) : (
            <div
              style={{
                margintop: "calc(50vh)",
                fontSize: "20px",
                textAlign: "center",
              }}>
              Nu a fost selectat nici un tip de proiect
            </div>
          )}
        </Col>
      </Row>
    ) : (
      <div
        style={{
          position: "absolute",
          width: "35px",
          height: "35px",
          top: "50vh",
          left: "50%",
        }}>
        <Preloader size={40} color={"var(--f7-theme-color)"} />
      </div>
    );
  }
}
export default TipuriProiecte;
