import React from "react";
import {
  Page,
  Checkbox,
  Popup,
  Button,
  Row,
  Col,
  List,
  ListInput,
  Icon,
  Toggle,
} from "framework7-react";
import DataGrid from "../Datagrid/DataGrid";
import axios from "axios";
import moment from "moment";
import logoPrint from "./../../../styles/logo-ats.png";

/**
 * @description Interfata de manangement al concediilor, utilizatorii isi pot adauga concedii/invoiri etc
 * @date 08/04/2022
 * @class Concedii
 * @extends {React.Component}
 */
class Concedii extends React.Component {
  constructor(props) {
    super(props);
    var ignoreInputsAdd = ["NumarOre", "Arhivat", "Ascuns"];
    if (!this.$f7.data.user.administrator) {
      ignoreInputsAdd.push("Salariat");
    }

    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati.filter((r) => {
          return r.key !== "Fara";
        })
      : this.$f7.data.SalariatiActivi.filter((r) => {
          return r.key !== "Fara";
        });
    this.TipEvenimentSalariati = this.$f7.data.TipEvenimentSalariati.filter(
      (r) => {
        return r.key !== "Fara" && r.value !== 3 && r.value !== 4;
      }
    );
    this.state = {
      dataLoaded: false,
      linkApi: "/api/EvenimenteSalariati",
      linkApiArh: "/api/EvenimenteSalariatiArh",
      denumire: "Concedii",
      height: window.innerHeight - 44,
      dataSource: [],
      inputs:
        this.$f7.data.user.rol >= 2
          ? [
              { key: "Id", keyBaza: "iD_EVENIMENT_SALARIAT" },
              {
                key: "TipZiLibera",
                keyBaza: "iD_EVENIMENT",
                tip: "selectbox",
                lista: this.TipEvenimentSalariati,
                requiredAdd: true,
                requiredEdit: true,
              },
              {
                key: "Salariat",
                keyBaza: "iD_SALARIAT",
                tip: "selectboxmultiplu",
                lista: this.$f7.data.Salariati,
                listaEdit: this.Salariati,
                requiredAdd: true,
                requiredEdit: true,
              },
              {
                key: "DataInceput",
                keyBaza: "data",
                tip: "datebox",
                requiredAdd: true,
                requiredEdit: true,
                minDate: moment().format(),
              },
              {
                key: "DataSfarsit",
                keyBaza: "datA_STOP",
                tip: "datebox",
                requiredAdd: true,
                requiredEdit: true,
              },
              { key: "NumarOre", keyBaza: "nR_ORE", tip: "number" },
              { key: "Comentariu", keyBaza: "comentariu" },
              { key: "Arhivat", keyBaza: "isArhivat", tip: "bool" },
              { key: "Ascuns", keyBaza: "isHidden", tip: "bool" },
            ]
          : [
              { key: "Id", keyBaza: "iD_EVENIMENT_SALARIAT" },
              {
                key: "TipZiLibera",
                keyBaza: "iD_EVENIMENT",
                tip: "selectbox",
                lista: this.TipEvenimentSalariati,
                requiredAdd: true,
                requiredEdit: true,
              },
              {
                key: "Salariat",
                keyBaza: "iD_SALARIAT",
                tip: "selectboxmultiplu",
                lista: this.$f7.data.Salariati,
                listaEdit: this.Salariati,
                requiredAdd: true,
                requiredEdit: true,
              },
              {
                key: "DataInceput",
                keyBaza: "data",
                tip: "datebox",
                requiredAdd: true,
                requiredEdit: true,
              },
              {
                key: "DataSfarsit",
                keyBaza: "datA_STOP",
                tip: "datebox",
                requiredAdd: true,
                requiredEdit: true,
              },
              { key: "NumarOre", keyBaza: "nR_ORE", tip: "number" },
              { key: "Comentariu", keyBaza: "comentariu" },
              { key: "Arhivat", keyBaza: "isArhivat", tip: "bool" },
              { key: "Ascuns", keyBaza: "isHidden", tip: "bool" },
            ],
      ignoreInputsAdd: ignoreInputsAdd,
      checkedArhivate: false,
      infoCO: null,
      ramaseCO: null,
      numeSalariatPrint: "",
      dataStartPrint: null,
      dataSfarsitPrint: null,
      numarZilePrint: null,
      inlocuitorPrint: "",
      sefProiectPrint: "",
      dataPrint: null,
      tipCererePrint: null,
    };

    this.refDataGrid = React.createRef();
  }

  /**
   * @description Ia toate concediile din baza de date pentru randare
   * @function
   * @date 08/04/2022
   * @memberof Concedii
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetEVENIMENTE_SALARIATI_FILTRAT/" +
          (this.state.checkedArhivate ? 1 : 0)
      )
      .then((response) => {
        var date = response;

        date.data.map((eveniment) => {
          eveniment = this.$f7.methods.restructurareBaza(
            eveniment,
            this.state.inputs,
            "key"
          );
          return false;
        });

        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            // this.calculConcediuOdihna();
            this.coRamase();

            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  if (baza.length > 0) {
                    this.refDataGrid.current.setRenderItems(baza);
                  }
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.refDataGrid.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });

    //axios.get(
    //	this.$f7.data.baseURL +
    //	this.state.linkApi +
    //	"/GetEVENIMENTE_SALARIATI"
    //).then((response) => {
    //	var date = response;

    //	date.data.map((eveniment) => {
    //		eveniment = this.$f7.methods.restructurareBaza(eveniment, this.state.inputs, "key");
    //		return false;
    //	});

    //	var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

    //	this.setState({
    //		dataSource: baza,
    //	}, () => {
    //		setTimeout(() => {
    //			this.setState({
    //				dataLoaded: true,
    //			}, () => {
    //				if (baza.length > 0) {
    //					this.refDataGrid.current.setRenderItems(baza);
    //				}
    //			});
    //		}, 400);
    //	});
    //}).catch((error) => {
    //	this.setState({
    //		dataSource: [],
    //		dataLoaded: true,
    //	}, () => {
    //		this.refDataGrid.current.setRenderItems([]);
    //		this.$f7.dialog.confirm(
    //			"A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
    //			"Ups...",
    //			() => {
    //				this.GetValues();
    //			},
    //			() => {}
    //		);
    //	});

    //	console.log("error", error);
    //});
  };

  /**
   * @description Adauga o noua inregistrare de tip concediu in baza de date
   * @date 08/04/2022
   * @function
   * @param {*} data obiect cu valorii noii inregistrari
   * @memberof Concedii
   */
  AddValue = (data) => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL +
        this.state.linkApi +
        "/PostEVENIMENTE_SALARIATI";
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject("Eroare la adaugare");
        });
    });
  };

  /**
   * @description Modifica in baza de date valorile inregistrarii selectate
   * @date 08/04/2022
   * @param {*} obj obiect cu noile valori ale inregistrarii selectate
   * @param {*} id id-ul inregistarii selectate
   * @memberof Concedii
   */
  UpdateValue = (obj, id) => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetEVENIMENTE_SALARIATI/" +
          id
      )
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL +
          this.state.linkApi +
          "/PutEVENIMENTE_SALARIATI/" +
          id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Sterge din baza de date inregistrarea selectata
   * @date 08/04/2022
   * @param {*} id id-ul inregistrarii selectate
   * @function
   * @memberof Concedii
   */
  DeleteValue = (id) => {
    var url =
      this.$f7.data.baseURL +
      this.state.linkApi +
      "/DeleteEVENIMENTE_SALARIATI/" +
      id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  /**
   * @description Functie de arhivare/dezarhivare a inregistrarilor selectate
   * @date 08/04/2022
   * @param {*} state state-ul datagridului,pentru a putea lua liniile selectate
   * @param {*} arhivare daca se arhiveaza sau dezarhiveaza(true,false)
   * @function
   * @memberof Concedii
   */
  ArhivareDezarhivare = (state, arhivare) => {
    let listaId = [];

    if (state.selectedRows.length > 0) {
      state.selectedRows.forEach((item) => {
        if (arhivare) {
          if (!item.Arhivat) listaId.push(item.Id);
        } else {
          if (item.Arhivat) listaId.push(item.Id);
        }
      });
    } else {
      if (arhivare) {
        if (!state.rowData.Arhivat) listaId.push(state.rowData.Id);
      } else {
        if (state.rowData.Arhivat) listaId.push(state.rowData.Id);
      }
    }

    if (listaId.length > 0) {
      const url = arhivare
        ? this.$f7.data.baseURL +
          this.state.linkApi +
          "/EvenimenteSalariatiMoveToARH"
        : this.$f7.data.baseURL +
          this.state.linkApiArh +
          "/EvenimenteSalariatiMoveFromARH";

      axios({
        data: listaId,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          const text = arhivare
            ? "Arhivate cu succes: " + listaId.length
            : "Dezarhivate cu succes: " + listaId.length;
          this.$f7.notification
            .create({
              title: "Succes",
              icon: "done",
              text: text,
              closeTimeout: 4000,
            })
            .open();

          this.GetValues();
        })
        .catch((error) => {
          console.log("error", error);

          const text = arhivare
            ? "Eroare la arhivare"
            : "Eroare la dezarhivare";
          this.$f7.notification
            .create({
              title: "Eroare",
              icon: "done",
              text: text,
              closeTimeout: 4000,
            })
            .open();
        });
    } else {
      if (arhivare) {
        this.$f7.notification
          .create({
            title: "Atentie",
            icon: "done",
            text:
              state.selectedRows.length > 0
                ? "Toate elementele selectate sunt deja arhivate!"
                : "Elementul selectat este deja arhivat!",
            closeTimeout: 4000,
          })
          .open();
      } else {
        this.$f7.notification
          .create({
            title: "Atentie",
            icon: "done",
            text:
              state.selectedRows.length > 0
                ? "Toate elementele selectate sunt deja dezarhivate!"
                : "Elementul selectat este deja dezarhivat!",
            closeTimeout: 4000,
          })
          .open();
      }
    }
  };

  /**
   * @description Coloreaza randurile in functie daca sunt arhivate sau nu
   * @date 08/04/2022
   * @param {*} state state-ul datagridului
   * @param {*} r contextul datagridului
   * @param {*} itemeRandate itemele randate de datagrid
   * @function
   * @memberof Concedii
   */
  colorItems = (state, r, itemeRandate) => {
    var tableName = r.tableName;
    if (tableName) {
      itemeRandate.forEach((elem, index) => {
        var tar = document.getElementById("trMain" + tableName + index);

        if (tar) {
          if (elem.Arhivat) tar.classList.add("arhivat");
          else tar.classList.remove("arhivat");
        }
      });
    }
  };

  /**
   * @description Functie de calcul al timpului rmas de concediu de odihna al salariatului logat
   * @function
   * @date 08/04/2022
   * @memberof Concedii
   */
  calculConcediuOdihna = () => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetCalculConcediuOdihna/" +
          this.$f7.data.idSalariatFunctii
      )
      .then((response) => {
        let infoCO = "Zile CO ramase: ";
        const keys = Object.keys(response.data);

        keys.forEach((key, index) => {
          infoCO +=
            key +
            " - " +
            response.data[key] +
            (response.data[key] === 1 ? " zi" : " zile");
          if (index < keys.length - 1) infoCO += ", ";
        });

        this.setState({
          infoCO: infoCO,
        });
      })
      .catch((error) => {
        console.log("error:", error);
      });
  };

  /**
   * @description Functie care preia numarul de zile de CO ramase din anul curent ale salariatului logat
   * @function
   * @date 08/04/2022
   * @memberof Concedii
   */
  coRamase = () => {
    axios.get(`${this.$f7.data.baseURL}${this.state.linkApi}/GetCORamase/${this.$f7.data.idSalariatFunctii}`).then((response) => {
      const ramaseCO = `Zile CO ramase: ${response.data} ${response.data === 1 ? "zi" : "zile"}`;
      
      this.setState({
        ramaseCO: ramaseCO,
      });
    }).catch((error) => {
      console.log("error:", error);
    });
  }

  /**
   * @description Functie de printare a cererii de concediu sau de invoire
   * @function
   * @date 08/04/2022
   * @param {*} state state-u datagridului
   * @memberof Concedii
   */
  printareCO = (state) => {
    const salariat = this.$f7.data.Salariati.find(
      (s) => s.value === state.rowDataAll.Salariat
    );

    this.setState(
      {
        numeSalariatPrint: salariat ? salariat.key : "",
        dataStartPrint: moment(state.rowDataAll.DataInceput),
        dataSfarsitPrint: moment(state.rowDataAll.DataSfarsit),
      },
      () => {
        if (state.rowDataAll.TipZiLibera === 1) {
          // pentru cererea de CO
          let numWorkDays = 0;
          let currentDate = this.state.dataStartPrint.clone();

          while (currentDate.isSameOrBefore(this.state.dataSfarsitPrint)) {
            if (currentDate.day() !== 0 && currentDate.day() !== 6) {
              numWorkDays++;
            }

            currentDate.add(1, "d");
          }

          this.setState(
            {
              // numarZilePrint: Math.ceil(moment.duration(this.state.dataSfarsitPrint.diff(this.state.dataStartPrint)).asDays())
              numarZilePrint: numWorkDays,
              tipCererePrint: 1,
            },
            () => {
              const popupDinamicPrintare = this.$f7.popup.get(
                ".popupDinamicPrintare"
              );
              popupDinamicPrintare.open();
            }
          );
        } else if (state.rowDataAll.TipZiLibera === 5) {
          // pentru biletul de invoire
          this.setState(
            {
              tipCererePrint: 5,
            },
            () => {
              const popupDinamicPrintare = this.$f7.popup.get(
                ".popupDinamicPrintare"
              );
              popupDinamicPrintare.open();
            }
          );
        } else {
          this.$f7.methods.openNotification(
            "Nu exista inca sablon pntru acest tip de document!"
          );
        }
      }
    );
  };

  componentDidMount = () => {
    this.$f7.data.interfataConcedii = this;
    this.GetValues();
  };

  render() {
    return (
      <Page
        className={"themeBackground"}
        onPageInit={() => {
          this.$f7.data.paginaCurenta = this;
        }}
        pageContent={false}>
        <div className="ptr3">
          <div>
            <div
              style={{
                position: "absolute",
                top: 8,
                // left: "calc(50% - 30px)",
                left: 15,
                display: "flex",
              }}>
              {/* <div>{this.state.infoCO !== null && this.state.infoCO}</div> */}
              <div>{this.state.ramaseCO !== null && this.state.ramaseCO}</div>

              <Checkbox
                checked={this.state.checkedArhivate}
                onChange={(e) => {
                  this.setState(
                    {
                      checkedArhivate: !this.state.checkedArhivate,
                    },
                    () => {
                      this.GetValues();
                    }
                  );
                }}
                style={{ display: "flex", paddingLeft: 25 }}
                name="Checkbox1">
                Afiseaza si arhivate
              </Checkbox>
            </div>

            <DataGrid
              dataLoaded={this.state.dataLoaded}
              refresh={this.GetValues}
              name={"Concediu"}
              allRequiredAdd={true}
              ignoreInputsAdd={this.state.ignoreInputsAdd}
              ignoreInputsEdit={["Salariat"]}
              keys={[
                "TipZiLibera",
                "Salariat",
                "DataInceput",
                "DataSfarsit",
                "NumarOre",
                "Comentariu",
              ]}
              id={"Id"}
              ref={this.refDataGrid}
              renderUpdate={
                this.$f7.data.user.grupuri.includes(12) ? true : false
              }
              renderDelete={
                this.$f7.data.user.grupuri.includes(12) ? true : false
              }
              renderAdd={true}
              renderCheckbox={true}
              onUpdate={(state) => {
                var eveniment = { ...state.editedItem };
                eveniment = this.$f7.methods.restructurareBaza(
                  eveniment,
                  this.state.inputs,
                  "keyBaza"
                );
                this.UpdateValue(eveniment, eveniment.iD_EVENIMENT_SALARIAT);
              }}
              maxHeight={this.state.height}
              dataSource={this.state.dataSource}
              inputs={this.state.inputs}
              onInitialised={(state, r, itemeRandate, allRendered) => {
                this.colorItems(state, r, itemeRandate);

                if (this.props.onInitialised !== undefined) {
                  this.props.onInitialised(state, r, itemeRandate, allRendered);
                }
              }}
              onRenderItemsChanged={(state, r, itemeRandate) => {
                this.colorItems(state, r, itemeRandate);
              }}
              onAdd={(state) => {
                var eveniment = { ...state.editedItem };
                eveniment = this.$f7.methods.restructurareBaza(
                  eveniment,
                  this.state.inputs,
                  "keyBaza"
                );
                if (!this.$f7.data.user.grupuri.includes(12)) {
                  eveniment.iD_SALARIAT = [
                    this.$f7.data.user.iD_SALARIAT.toString(),
                  ];
                }
                delete eveniment["iD_EVENIMENT_SALARIAT"];

                if ([1, 2, 12].includes(eveniment.iD_EVENIMENT)) {
                  let mStart = moment(eveniment.data);
                  let mStop = moment(eveniment.datA_STOP);
                  mStart.set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
                  mStop.set({ hour: 17, minute: 0, second: 0, millisecond: 0 });
                  eveniment.data = mStart.format();
                  eveniment.datA_STOP = mStop.format();
                }

                var salariati = eveniment.iD_SALARIAT;
                if (salariati.length > 0) {
                  salariati
                    .reduce((promise, item) => {
                      return promise.then(() => {
                        return this.AddValue({
                          ...eveniment,
                          iD_SALARIAT: parseInt(item),
                          isArhivat: false,
                          isHidden: false,
                        })
                          .then((r) => {})
                          .catch((err) => {
                            console.log("err", err);
                          });
                      });
                    }, Promise.resolve())
                    .then(() => {
                      this.$f7.preloader.hide();
                      this.$f7.methods.openNotification("Adaugat cu success!");
                      this.GetValues();
                    })
                    .catch((error) => {
                      this.$f7.preloader.hide();
                      this.$f7.methods.openNotification("Eroare: " + error);
                      console.log("Eroare");
                    });
                }
              }}
              onDelete={(state) => {
                var eveniment = { ...state.editedItem };
                var id = eveniment["Id"];
                this.DeleteValue(id);
              }}
              contextMenuItems={
                [1, 2].includes(this.$f7.data.rol)
                  ? [
                      {
                        key: "Arhivare",
                        onClick: (state) => {
                          setTimeout(() => {
                            this.ArhivareDezarhivare(state, true);
                          }, 1000);
                        },
                        icon: "compress",
                      },
                      {
                        key: "Dezarhivare",
                        onClick: (state) => {
                          setTimeout(() => {
                            this.ArhivareDezarhivare(state, false);
                          }, 100);
                        },
                        icon: "expand",
                      },
                      {
                        key: "Printare",
                        onClick: (state) => {
                          this.printareCO(state);
                        },
                        icon: "print",
                      },
                    ]
                  : [
                      {
                        key: "Printare",
                        onClick: (state) => {
                          this.printareCO(state);
                        },
                        icon: "print",
                      },
                    ]
              }
            />
          </div>

          <Popup className="popupDinamicPrintare">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}>
              <List
                noChevron
                form
                noHairlines
                noHairlinesBetween
                noHairlinesBetweenMd
                noHairlinesMd
                style={{ width: "80%" }}>
                <Row style={{ width: "100%", marginBottom: 30 }}>
                  <Col>
                    <ListInput
                      floatingLabel
                      label="Inlocuitor"
                      validate
                      type="text"
                      placeholder="Inlocuitor"
                      value={this.state.inlocuitorPrint}
                      onChange={(e) => {
                        this.setState({
                          inlocuitorPrint: e.currentTarget.value,
                        });
                      }}>
                      <Icon
                        color={"var(--f7-theme-color)"}
                        size={20}
                        material="list"
                        slot="content-start"
                      />
                    </ListInput>
                  </Col>
                </Row>

                {this.state.tipCererePrint === 1 && (
                  <Row style={{ width: "100%", marginBottom: 30 }}>
                    <Col>
                      <ListInput
                        floatingLabel
                        label="Sef Proiect"
                        validate
                        type="text"
                        placeholder="Sef Proiect"
                        value={this.state.sefProiectPrint}
                        onChange={(e) => {
                          this.setState({
                            sefProiectPrint: e.currentTarget.value,
                          });
                        }}>
                        <Icon
                          color={"var(--f7-theme-color)"}
                          size={20}
                          material="list"
                          slot="content-start"
                        />
                      </ListInput>
                    </Col>
                  </Row>
                )}

                <Row style={{ width: "100%", marginBottom: 30 }}>
                  <Col>
                    <div
                      style={{
                        padding: "8px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}>
                      <span style={{ fontSize: 16 }}>
                        Printeaza cu alta data
                      </span>
                      <Toggle
                        checked={this.state.dataPrint !== null}
                        color="var(--f7-theme-color)"
                        onChange={(e) => {
                          const checked = e.currentTarget.checked;
                          this.setState({
                            dataPrint: checked ? moment() : null,
                          });
                        }}
                      />
                    </div>

                    <ListInput
                      locale="ro-RO"
                      disabled={this.state.dataPrint === null}
                      floatingLabel
                      label="Selecteaza data"
                      type="datetime-local"
                      placeholder="Selecteaza data"
                      calendarParams={{
                        dateFormat: "YYYY-MM-DDThh:mm",
                      }}
                      onChange={(e) => {
                        this.setState({
                          dataPrint: moment(e.target.value),
                        });
                      }}
                      value={
                        this.state.dataPrint !== null
                          ? this.state.dataPrint.format("YYYY-MM-DDTHH:mm")
                          : moment().format("YYYY-MM-DDTHH:mm")
                      }
                      style={{
                        height: this.state.dataPrint === null ? 0 : 51,
                        transition: "all 0.3s ease-in-out",
                        overflow: "hidden",
                      }}>
                      <Icon
                        slot="content-start"
                        style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                        size={20}
                        material="today"
                      />
                    </ListInput>
                  </Col>
                </Row>

                <Row style={{ width: "100%" }}>
                  <Col>
                    <Button
                      onClick={() => {
                        this.setState(
                          {
                            numeSalariatPrint: "",
                            dataStartPrint: null,
                            dataSfarsitPrint: null,
                            numarZilePrint: null,
                            inlocuitorPrint: "",
                            sefProiectPrint: "",
                            dataPrint: null,
                            tipCererePrint: null,
                          },
                          () => {
                            const popupDinamicPrintare = this.$f7.popup.get(
                              ".popupDinamicPrintare"
                            );
                            if (popupDinamicPrintare)
                              popupDinamicPrintare.close();
                          }
                        );
                      }}>
                      Close
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      onClick={() => {
                        let content = "";
                        let titlu = "";

                        if (this.state.tipCererePrint === 1) {
                          titlu = "Cerere CO";
                          content =
                            `
                            <html style="background-color: #f7f7f7; margin: 0; padding: 0;">
                              <head><title>Cerere CO</title></head>
                              <body style="position: relative; background-color: #fff; padding: 25.4mm 22.7mm 10mm 31.7mm; font-family: Calibri; line-height: 2.1; width: 227mm; height: 350mm; margin: 15px auto;">
                                <header style="height: 12.5mm; position: absolute; text-align: center; text-transform: uppercase; font-style: italic; font-weight: bold; font-size: 15px; display: flex; align-items: start; justify-content: center; top: 10mm; left: 31.7mm; right: 22.7mm; opacity: 0.55;">
                                  <img src="${logoPrint}" alt="logoAts" style="position: absolute; width: 155px; height: auto; left: 0; top: -4px;" />
                                  <span>Cerere de concediu</span>
                                </header>

                                <div style="position: relative; margin-top: 12.5mm;">
                                  <h1 style="text-align: center; margin-bottom: 20mm;">Domnule Director,</h1>
                                  <p style="font-size: 27px; text-indent: 15mm; text-align: justify;">Subsemnatul ${this.state.numeSalariatPrint}, salariat al S.C. ATS S.R.L., vă rog a-mi acorda ${this.state.numarZilePrint}` +
                            (this.state.numarZilePrint === 1
                              ? ` zi`
                              : ` zile`) +
                            ` din concediul de odihnă, în perioada ${this.state.dataStartPrint.format(
                              "DD.MM.YYYY"
                            )} - ${this.state.dataSfarsitPrint.format(
                              "DD.MM.YYYY"
                            )}.</p>
                                  <div style="font-size: 27px; display: flex; align-items: center; justify-content: space-between; margin-top: 30mm;">
                                    <span>Înlocuitor: ${
                                      this.state.inlocuitorPrint
                                    }</span>
                                    <div style="display: flex; flex-direction: column; align-items: center;">
                                      <span>Șef proiect: ${
                                        this.state.sefProiectPrint
                                      }</span>
                                      <span>____________________________</span>
                                    </div>
                                  </div>
                                  <p style="font-size: 27px; text-align: center; margin-top: 30mm;">Vă mulțumesc!</p>
                                  <div style="font-size: 27px; display: flex; align-items: center; justify-content: space-between; margin-top: 30mm;">
                                    <span>Data: ${
                                      this.state.dataPrint === null
                                        ? moment().format("DD.MM.YYYY")
                                        : this.state.dataPrint.format(
                                            "DD.MM.YYYY"
                                          )
                                    }</span>
                                    <div style="display: flex; flex-direction: column; align-items: center;">
                                      <span>Semnătura:</span>
                                      <span>_________________</span>
                                    </div>
                                  </div>
                                </div>

                                <footer style="position: absolute; bottom: 10mm; text-align: center; font-size: 27px; left: 31.7mm; right: 22.7mm; height: 12.5mm;">
                                  <span>Domnului Director al Societății Comerciale ATS S.R.L.</span>
                                </footer>
                              </body>
                            </html>
                          `;
                        } else if (this.state.tipCererePrint === 5) {
                          titlu = "Bilet de invoire";
                          content = `
                            <html style="background-color: #f7f7f7; margin: 0; padding: 0;">
                              <head><title>Bilet de invoire</title></head>
                              <body style="position: relative; background-color: #fff; padding: 25.4mm 22.7mm 10mm 31.7mm; font-family: Calibri; line-height: 2.1; width: 227mm; height: 350mm; margin: 15px auto;">
                                <div style="position: relative; top: 50%; transform: translateY(calc(-50% - 100px));">
                                  <h1 style="text-align: center; margin-bottom: 20mm;">Bilet de voie</h1>

                                  <p style="font-size: 27px; text-indent: 15mm; text-align: justify;">Subsemnatul/a ${
                                    this.state.numeSalariatPrint
                                  } este învoit/ă în data de ${this.state.dataStartPrint.format(
                            "DD.MM.YYYY"
                          )} de la ora ${this.state.dataStartPrint.format(
                            "HH:mm"
                          )} la ora ${this.state.dataSfarsitPrint.format(
                            "HH:mm"
                          )}, urmând ca orele învoite să fie recuperate.</p>

                                  <div style="font-size: 27px; display: flex; align-items: center; justify-content: space-between; margin-top: 35mm;">
                                    <div style="display: flex; flex-direction: column; align-items: center;">
                                      <span>Înlocuitor: ${
                                        this.state.inlocuitorPrint
                                      }</span>
                                      <span>________________________</span>
                                    </div>

                                    <div style="display: flex; flex-direction: column; align-items: center;">
                                      <span>Aprobat:</span>
                                      <span>________________________</span>
                                    </div>
                                  </div>

                                  <div style="font-size: 27px; display: flex; align-items: center; justify-content: space-between; margin-top: 50mm;">
                                    <span>Data: ${
                                      this.state.dataPrint === null
                                        ? moment().format("DD.MM.YYYY")
                                        : this.state.dataPrint.format(
                                            "DD.MM.YYYY"
                                          )
                                    }</span>
                                    <span style="text-align: right; display: block; font-size: 27px;">Semnătură: ____________________</span>
                                  </div>
                                </div>
                              </body>
                            </html>
                          `;
                        }

                        const popupDinamicPrintare = this.$f7.popup.get(
                          ".popupDinamicPrintare"
                        );
                        if (popupDinamicPrintare) popupDinamicPrintare.close();

                        setTimeout(() => {
                          let myWindow = window.open("", titlu);
                          myWindow.document.write(content);

                          setTimeout(
                            () => {
                              myWindow.print();
                            },
                            this.state.tipCererePrint === 1 ? 2000 : 500
                          );

                          this.setState({
                            numeSalariatPrint: "",
                            dataStartPrint: null,
                            dataSfarsitPrint: null,
                            numarZilePrint: null,
                            inlocuitorPrint: "",
                            sefProiectPrint: "",
                            dataPrint: null,
                            tipCererePrint: null,
                          });
                        }, 500);
                      }}>
                      Preview
                    </Button>
                  </Col>
                </Row>
              </List>
            </div>
          </Popup>
        </div>
      </Page>
    );
  }
}
export default Concedii;
