import React, { Component } from "react";
import { Page } from "framework7-react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import DataGrid from "./../../Datagrid/DataGrid";
import axios from "axios";

var moment = require("moment");
const localizer = momentLocalizer(moment);

/**
 * @description Afiseaza sarcina creata pentru taskul curent selectat intr0un calendar
 * @date 08/04/2022
 * @class AlocareSarcini
 * @extends {Component}
 */
class AlocareSarcini extends Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;

    this.state = {
      dataLoaded: false,
      linkApi: "/api/PlanificareAnuala",
      height: (window.innerHeight - 120) / 2,
      dataSource: [],
      calendarSource: [],
      inputs: [
        { key: "Caption" },
        {
          key: "Finish",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Start",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
        },
      ],
    };
  }

  /**
   * @description Ia toate sarcinile pentru taskul curent selectat si le randeaza in datagrid si calendar
   * @function
   * @date 08/04/2022
   * @memberof AlocareSarcini
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/" +
          this.$f7.data.Proiect.iD_PROIECT
      )
      .then((response) => {
        let change = response;
        let calendarArray = [];
        for (let item in change.data) {
          change.data[item]["Caption"] =
            change.data[item].denumire === "" ||
            change.data[item].denumire === null
              ? "Fara"
              : change.data[item].denumire;
          change.data[item]["Finish"] = change.data[item].deadline;
          change.data[item]["Start"] = change.data[item].data_start;

          let calendarEvent = {};
          calendarEvent["id"] = change.data[item].id_proiect;
          calendarEvent["title"] = change.data[item].Caption;
          calendarEvent["start"] = new Date(change.data[item].Finish);
          calendarEvent["end"] = new Date(change.data[item].Start);
          calendarArray.push(calendarEvent);
        }

        let baza = this.$f7.methods.reconstructieBaza(
          change,
          this.state.inputs
        );

        this.setState(
          {
            dataSource: baza.length === 0 ? [] : baza,
            calendarSource: calendarArray,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            calendarSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor pentru Gantt de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    if (this.$f7.data.Proiect !== null) this.GetValues();
  };

  render() {
    return (
      <Page pageContent={false}>
        {this.$f7.data.Proiect !== null ? (
          <div
            style={{
              paddingLeft:
                "calc(var(--f7-treeview-item-padding-left) + var(--f7-treeview-children-offset) * 1)",
              paddingRight: "var(--f7-treeview-item-padding-right)",
            }}>
            <DataGrid
              dataLoaded={this.state.dataLoaded}
              refresh={this.GetValues}
              name={"AlocareSarcini"}
              renderUpdate={false}
              renderDelete={false}
              renderAdd={false}
              maxHeight={this.state.height}
              dataSource={this.state.dataSource}
              inputs={this.state.inputs}
              count={25}
            />

            <Calendar
              localizer={localizer}
              startAccessor="start"
              endAccessor="end"
              events={this.state.calendarSource}
              defaultView={Views.MONTH}
              // views={["day", "work_week"]}
              views={["month"]}
              style={{
                marginTop: "50px",
                height: parseInt(this.state.height),
                // width: window.innerWidth - 8,
              }}
            />
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              height: "calc(100vh - 50px)",
            }}>
            <h3
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                right: 0,
                transform: "translateY(-50%)",
                textTransform: "uppercase",
                textAlign: "center",
                display: "block",
              }}>
              Nu ati selectat nimic
            </h3>
          </div>
        )}
      </Page>
    );
  }
}
export default AlocareSarcini;
