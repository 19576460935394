import React from "react";
import { Panel, Popup, Popover, Sheet } from "framework7-react";
const Wrapper = ({
	children,
	modalType,
	name,
	onClose,
	target,
	onOpen,
	direction,
}) => {
	const close = () => {
		if (onClose) {
			setTimeout(() => {
				onClose();
			}, 100);
		}
	};
	const open = () => {
		if (onOpen) {
			setTimeout(() => {
				onOpen();
			}, 100);
		}
	};

	const renderWrapper = () => {
		switch (modalType) {
			case "popup": {
				return (
					<Popup
						onPopupOpen={open}
						style={{ zIndex: 13499 }}
						onPopupClosed={close}
						closeOnEscape={true}
						push
						tabletFullscreen={true}
						className={name + " popupDatagrid"}
					>
						{children}
					</Popup>
				);
			}
			case "sheet": {
				return (
					<Sheet
						onSheetOpen={open}
						style={{ zIndex: 13499 }}
						backdrop={true}
						onSheetClosed={close}
						closeByBackdropClick={true}
						className={name + " popupDatagrid"}
					>
						{children}
					</Sheet>
				);
			}
			case "popover": {
				return (
					<Popover
						onPopoverOpen={open}
						targetEl={target}
						style={{ zIndex: 13499 }}
						backdrop={true}
						onPopoverClosed={close}
						closeByBackdropClick={true}
						className={name + " popupDatagrid"}
					>
						{children}
					</Popover>
				);
			}
			default: {
				return (
					<Panel
						side={direction === undefined ? "right" : direction}
						onPanelOpen={open}
						backdrop={true}
						onPanelClosed={close}
						closeByBackdropClick={true}
						resizable
						style={{
							width: "55vw",
							minWidth: "30vw",
							top: 0,
							zIndex: 13499,
							height: "99vh",
						}}
						className={name + " popupDatagrid"}
					>
						{children}
					</Panel>
				);
			}
		}
	};

	return renderWrapper();
};
export default Wrapper;
