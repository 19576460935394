import React, { Component } from "react";
import DataGrid from "../../Datagrid/DataGrid";
import { Segmented, Button } from "framework7-react";
import moment from "moment";
import axios from "axios";

 /**
  * @description Afiseaza un datagrid cu toate realizarile pe o sarcina adaugate de catre utilizatori manual, administratorul aplicatiei avand optiunile de a respinge sau de a aproba aceste adaugari.
  * @date 06/04/2022
  * @class AprobareActivitate
  * @extends {Component}
  */
 class AprobareActivitate extends Component {
   constructor(props) {
     super(props);
     this.Salariati = this.$f7.data.toggleTotiSalariatii
       ? this.$f7.data.Salariati
       : this.$f7.data.SalariatiActivi;
     this.height = window.innerHeight;
     this.state = {
       dataLoaded: false,
       height: window.innerHeight - 44,
       linkApi: "/api/RealizareSarcini",
       dataSource: [],
       inputs: [
         { key: "Id_Realizare" },
         {
           key: "NumeSalariat",
           tip: "selectbox",
           lista: this.$f7.data.Salariati,
           listaEdit: this.Salariati,
         },
         {
           key: "MomentInceput",
           tip: "datebox",
           requiredAdd: true,
           requiredEdit: true,
           minDate: moment().format(),
           maxDate: moment().format(),
         },
         {
           key: "MomentSfarsit",
           tip: "datebox",
           requiredAdd: true,
           requiredEdit: true,
           minDate: moment().format(),
           maxDate: moment().format(),
         },
         { key: "TimpLucratInOre" },
         { key: "Client", tip: "selectbox", lista: this.$f7.data.Firme },
         {
           key: "Comentariu",
           requiredAdd: false,
           requiredEdit: false,
           tip: "textarea",
         },
         {
           key: "ComentariuPlus",
           requiredAdd: false,
           requiredEdit: false,
           tip: "textarea",
         },
       ],
       idSelected: null,
     };

     this.refDataGrid = React.createRef();
   }

   /**
    * @description Ia valorile necesare pentru randare de la endpointul "/GetREALIZARE_SARCINIManual";
    * @date 06/04/2022
    * @function
    * @memberof AprobareActivitate
    */
   GetValues = () => {
     this.setState({
       dataLoaded: false,
     });

     axios
       .get(
         this.$f7.data.baseURL +
           this.state.linkApi +
           "/GetREALIZARE_SARCINIManual"
       )
       .then((response) => {
         var date = response;

         date.data.map((obj) => {
           obj["MomentInceput"] = obj.intrare;
           obj["MomentSfarsit"] = obj.iesire;
           obj["NumeSalariat"] = obj.iD_SALARIAT;
           obj["ComentariuPlus"] = obj.comentariU1;
           obj["Client"] = obj.iD_CLIENT;

           let miliseconds =
             new Date(obj.iesire).getTime() - new Date(obj.intrare).getTime();

           obj["TimpLucratInOre"] =
             Math.floor((miliseconds / 1000 / 60 / 60) % 24) +
             "h " +
             Math.floor((miliseconds / 1000 / 60) % 60) +
             " m";
           return null;
         });

         var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

         this.setState(
           {
             dataSource: baza,
           },
           () => {
             setTimeout(() => {
               this.setState(
                 {
                   dataLoaded: true,
                 },
                 () => {
                   this.refDataGrid.current.setRenderItems(baza);
                 }
               );
             }, 400);
           }
         );
       })
       .catch((error) => {
         this.setState(
           {
             dataSource: [],
             dataLoaded: true,
           },
           () => {
             this.refDataGrid.current.setRenderItems([]);
             this.$f7.dialog.confirm(
               "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
               "Ups...",
               () => {
                 this.GetValues();
               },
               () => {}
             );
           }
         );

         console.log("error", error);
       });
   };
   /**
    * @descriptionApeleaza pentru fiecare rand selectat functia Aproba cu parametrul idSarcina al sarcinii
    * @date 06/04/2022
    * @function
    * @memberof AprobareActivitate
    */
   AbrobaCall = () => {
     var state = this.refDataGrid.current.state;

     if (state.selectedRows.length > 0) {
       this.$f7.dialog.confirm(
         "Sunteti sigur ca doriti sa aprobati toate activitatile selectate?",
         "Atentie",
         () => {
           this.$f7.preloader.show();
           state.selectedRows
             .reduce((promise, item) => {
               return promise.then(() => {
                 return this.Aproba(item.Id_Realizare)
                   .then((r) => {})
                   .catch((err) => {
                     console.log("err", err);
                   });
               });
             }, Promise.resolve())
             .then(() => {
               this.$f7.preloader.hide();
               this.$f7.methods.openNotification("Activitati aprobate!");
               this.GetValues();
             })
             .catch((error) => {
               this.$f7.preloader.hide();
               this.$f7.methods.openNotification("Eroare: " + error);
             });
         },
         () => {}
       );
     } else if (this.state.idSelected) {
       this.$f7.dialog.confirm(
         "Sunteti sigur ca doriti sa aprobati activitatea selectata?",
         "Atentie",
         () => {
           this.$f7.preloader.show();
           this.Aproba(this.state.idSelected)
             .then((response) => {
               this.$f7.preloader.hide();
               this.$f7.methods.openNotification("Activitate aprobata!");
               this.GetValues();
             })
             .catch((error) => {
               this.$f7.preloader.hide();
               this.$f7.methods.openNotification("Eroare: " + error);
             });
         },
         () => {}
       );
     } else {
       this.$f7.methods.openNotification("Selectati o activitate");
     }
   };
   /**
    * @description Apeleaza pentru fiecare rand selectat functia Respinge cu parametrul idSarcina al sarcinii selectate, care face un get la endpointul "/GetREALIZARE_SARCINI/"+idSarcina pentru a lua intreaga sarcina si pune sarcina.aprobat pe false si face un put pentru a actualiza
    * @date 06/04/2022
    * @function
    * @memberof AprobareActivitate
    */
   RespingeCall = () => {
     var state = this.refDataGrid.current.state;

     if (state.selectedRows.length > 0) {
       this.$f7.dialog.confirm(
         "Sunteti sigur ca doriti sa respingeti toate activitatile selectate?",
         "Atentie",
         () => {
           this.$f7.preloader.show();
           state.selectedRows
             .reduce((promise, item) => {
               return promise.then(() => {
                 return this.Respinge(item.Id_Realizare)
                   .then((r) => {
                     console.log("r", r);
                   })
                   .catch((err) => {
                     console.log("err", err);
                   });
               });
             }, Promise.resolve())
             .then(() => {
               this.$f7.preloader.hide();
               this.$f7.methods.openNotification("Activitati respinse!");
               this.GetValues();
             })
             .catch((error) => {
               this.$f7.preloader.hide();
               this.$f7.methods.openNotification("Eroare: " + error);
             });
         },
         () => {}
       );
     } else if (this.state.idSelected) {
       this.$f7.dialog.confirm(
         "Sunteti sigur ca doriti sa respingeti activitatea selectata?",
         "Atentie",
         () => {
           this.$f7.preloader.show();
           this.Respinge(this.state.idSelected)
             .then((response) => {
               this.$f7.preloader.hide();
               this.$f7.methods.openNotification("Activitate respinsa!");
               this.GetValues();
             })
             .catch((error) => {
               this.$f7.preloader.hide();
               this.$f7.methods.openNotification("Eroare: " + error);
               console.log("error", error);
             });
         },
         () => {}
       );
     } else {
       this.$f7.methods.openNotification("Selectati o activitate");
     }
   };

   /**
    * @description Aproba un anumit task
    * @function
    * @date 06/04/2022
    * @param {*} idSarcina id-ul sarcinii
    * @memberof AprobareActivitate
    */
   Aproba = (idSarcina) => {
     return new Promise((resolve, reject) => {
       axios
         .get(
           this.$f7.data.baseURL +
             this.state.linkApi +
             "/GetREALIZARE_SARCINI/" +
             idSarcina
         )
         .then((response) => {
           let sarcina = response.data;
           sarcina.aprobat = true;

           axios({
             data: sarcina,
             headers: { "Content-Type": "application/json" },
             method: "put",
             url:
               this.$f7.data.baseURL +
               this.state.linkApi +
               "/PutREALIZARE_SARCINI/" +
               idSarcina,
           })
             .then((responseUpdate) => {
               resolve("Aprobata!");
             })
             .catch((error) => {
               console.log("error", error);
               reject(error);
             });
         })
         .catch((error) => {
           console.log("error", error);
           reject(error);
         });
     });
   };
   /**
    * @description Respinge un anumit task
    * @function
    * @date 06/04/2022
    * @param {*} idSarcina id-ul sarcinii
    * @memberof AprobareActivitate
    */
   Respinge = (idSarcina) => {
     return new Promise((resolve, reject) => {
       axios
         .get(
           this.$f7.data.baseURL +
             this.state.linkApi +
             "/GetREALIZARE_SARCINI/" +
             idSarcina
         )
         .then((response) => {
           let sarcina = response.data;
           sarcina.aprobat = false;

           axios({
             data: sarcina,
             headers: { "Content-Type": "application/json" },
             method: "put",
             url:
               this.$f7.data.baseURL +
               this.state.linkApi +
               "/PutREALIZARE_SARCINI/" +
               idSarcina,
           })
             .then((responseUpdate) => {
               resolve("Respinsa!");
             })
             .catch((error) => {
               console.log("error", error);
               reject(error);
             });
         })
         .catch((error) => {
           console.log("error", error);
           reject(error);
         });
     });
   };

   componentDidMount = () => {
     this.GetValues();
   };

   render() {
     return (
       <div style={{ position: "relative" }}>
         <Segmented
           className="butoaneContextDatagrid"
           raised
           tag="div"
           style={{
             position: "absolute",
             top: 5,
             left: 0,
             width: "calc(100% - 176px)",
           }}>
           <Button
             tooltip={"Aproba"}
             onClick={() => {
               this.AbrobaCall();
             }}
             small
             fill>
             Aproba
           </Button>
           <Button
             tooltip={"Respinge"}
             onClick={() => {
               this.RespingeCall();
             }}
             small
             fill>
             Respinge
           </Button>
           <Button
             tooltip={"Refresh"}
             onClick={() => {
               this.GetValues();
             }}
             small
             fill>
             Refresh
           </Button>
         </Segmented>
         <DataGrid
           dataLoaded={this.state.dataLoaded}
           refresh={this.GetValues}
           name={"AprobareActivitate"}
           ref={this.refDataGrid}
           id={"Id_Realizare"}
           onRowClick={(state) => {
             var id = state.rowData.Id_Realizare;

             this.setState({
               idSelected: id,
             });
           }}
           renderUpdate={false}
           renderDelete={false}
           renderAdd={false}
           renderCheckbox={true}
           renderFilter={false}
           maxHeight={this.state.height}
           inputs={this.state.inputs}
           dataSource={this.state.dataSource}
           contextMenuItems={[
             {
               key: "Aproba",
               onClick: (state) => {
                 setTimeout(() => {
                   this.AbrobaCall();
                 }, 1000);
               },
               icon: "thumb_up",
             },
             {
               key: "Respinge",
               onClick: (state) => {
                 setTimeout(() => {
                   this.RespingeCall();
                 }, 1000);
               },
               icon: "thumb_down",
             },
             {
               key: "Refresh",
               onClick: (state) => {
                 setTimeout(() => {
                   this.GetValues();
                 }, 1000);
               },
               icon: "refresh",
             },
           ]}
           onContextMenu={(state, target) => {
             var id = state.rowData.Id_Realizare;

             this.setState({
               idSelected: id,
             });
           }}
         />
       </div>
     );
   }
 }
export default AprobareActivitate;