import React from "react";
import { List, ListItem, Segmented, Button, Icon, Col } from "framework7-react";

/**
 * @description Randeaza lista paginata de proiecte/taskuri nealocate in bara de search si exista optiune de asuma
 * @date 07/04/2022
 * @class ListWithPagingNealocate
 * @extends {React.PureComponent}
 */
class ListWithPagingNealocate extends React.PureComponent {
  constructor(props) {
    super(props);
    var count = (window.innerHeight - 150) / 52;
    this.state = {
      numberBefore: 0,
      count: props.full ? count : 3,
      arrayChecked: [],
      pageName: "Swipeout" + this.$f7.utils.id("xxxx-xxxx-xxx"),
    };
    this.settings = props.full
      ? null
      : {
          width: "100",
          xsmall: "100",
          small: "100",
          medium: "50",
          large: "50",
          xlarge: "50",
        };
  }

  render() {
    return (
      <>
        {this.props.dataSource.length > 0 ? (
          <Col {...this.settings}>
            <div style={{ display: "flex", width: "100%" }}>
              <h3 style={{ margin: "2px" }}>{this.props.title}</h3>
              <Segmented
                raised
                style={{
                  maxWidth: "70%",
                  margin: "2px 4px",
                  width: "auto",
                }}
                tag="p">
                <Button
                  tooltip={"Asuma task"}
                  style={{ width: "fit-content" }}
                  fill
                  small
                  onClick={() => {
                    if (this.state.arrayChecked.length > 0) {
                      this.state.arrayChecked.map((e) => {
                        var id = e[this.props.denumireId];
                        this.props.AsumaTask(id);
                        return null;
                      });
                      this.$f7.methods.openNotification("Asumat");
                      this.props.GetValues();
                    } else {
                      this.$f7.methods.openNotification(
                        "Nu este niciun proiect selectat"
                      );
                    }
                  }}>
                  Asuma
                </Button>
              </Segmented>
              <Segmented
                style={{
                  maxWidth: "150px",
                  margin: "0 auto",
                  marginTop: "2px",
                  marginBottom: "2px",
                  marginRight: "0px",
                  marginLeft: "auto",
                }}
                tag="p">
                <Button
                  tooltip={"Inapoi"}
                  disabled={
                    parseInt(this.state.numberBefore - this.state.count) >= 0
                      ? false
                      : true
                  }
                  small
                  onClick={() => {
                    let lastNumber = this.state.numberBefore - this.state.count;
                    if (lastNumber >= 0) {
                      this.setState({
                        numberBefore: lastNumber,
                      });
                    }
                  }}>
                  <Icon
                    style={{
                      color:
                        parseInt(this.state.numberBefore - this.state.count) >=
                        0
                          ? "var(--f7-theme-color)"
                          : "lightgray",
                    }}
                    size={30}
                    material="navigate_before"
                  />
                </Button>
                <Button
                  tooltip={"Inainte"}
                  disabled={
                    parseInt(this.state.numberBefore + this.state.count) <=
                    parseInt(this.props.dataSource.length - 1)
                      ? false
                      : true
                  }
                  small
                  onClick={() => {
                    let lastNumber = this.state.numberBefore + this.state.count;
                    if (lastNumber <= this.props.dataSource.length - 1) {
                      this.setState({
                        numberBefore: lastNumber,
                      });
                    }
                  }}>
                  <Icon
                    style={{
                      color:
                        parseInt(this.state.numberBefore + this.state.count) <=
                        parseInt(this.props.dataSource.length - 1)
                          ? "var(--f7-theme-color)"
                          : "lightgray",
                    }}
                    size={30}
                    material="navigate_next"
                  />
                </Button>
              </Segmented>
            </div>
            <List>
              {this.props.dataSource
                .slice(
                  this.state.numberBefore,
                  this.state.numberBefore + this.state.count
                )
                .map((e, index) => (
                  <ListItem
                    checkbox
                    onChange={() => {
                      var arr = [...this.state.arrayChecked];
                      if (arr.includes(e)) {
                        var index = arr.indexOf(e);
                        arr.splice(index, 1);
                      } else {
                        arr.push(e);
                      }
                      this.setState({ arrayChecked: arr });
                    }}
                    checked={this.state.arrayChecked.includes(e)}
                    title={e[this.props.denumire]}
                    after={e[this.props.denumireData]}
                    className={this.state.pageName + index}
                    key={"itemListWithPagingNealocate-" + index}
                  />
                ))}
            </List>
          </Col>
        ) : null}
      </>
    );
  }
}
export default ListWithPagingNealocate;
