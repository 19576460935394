import React from "react";
import { Page } from "framework7-react";
import ATGrafice from "./ATGrafice";

class ATGraficePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { denumire: "DepartamentePage" };
    }
    render() {
        return (
            <Page
                pageContent={false}
                className={"themeBackground"}
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
            >
                <ATGrafice />
            </Page>
        );
    }
}
export default ATGraficePage;