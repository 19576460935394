import React from "react";
import {
  Card,
  ListInput,
  List,
  CardContent,
  Row,
  Col,
  ListItem,
  Icon,
  Button,
  Page,
  Preloader,
  Segmented,
  Toolbar,
  Link,
  Tab,
  Tabs
} from "framework7-react";
import moment from "moment";
import axios from "axios";
import { saveAs } from "file-saver";

let uncontrolledInputs = {
  denumireProiect: "",
  ordine: 0,
  isTask: false,
  timpMinim: 0,
  timpSefProiect: 0,
  timpManager: 0,
  timpSupervizor: 0,
  aprobareDezvoltator: 0,
  aprobareManager: 0,
  aprobareSefProiect: 0,
  aprobareSupervizor: 0,
  bugetTimp: 0,
  buget: 0,
  risc: 0,
  directorRadacina: "",
  comentariuProiect: "",
  observatiiProiect: ""
};

class DescriereProiect extends React.PureComponent {
  constructor(props) {
    super(props);

    let denumireTab = this.$f7.utils.id("xxxx-xxxx-xxxx");

    this.state = {
      activeTab: "tab-Detalii-1" + denumireTab,
      denumireTab: denumireTab,

      dataLoaded: false,
      atasamente: [],
      linkApiProiectById: "/api/Proiecte/GetPROIECTESauArhivate",
      linkApiProiectUpdate: "/api/Proiecte/PutPROIECTE",
      linkApiAdaugaStergeRoluri: "/api/Proiecte/adaugaStergeRoluriCopii",
      linkApiPrioritati: "/api/Prioritati",
      linkApiFirme: "/api/Firme",
      linkApiNivele: "/api/TipNivelProiect",
      linkApiTipProiecte: "/api/TipProiect",
      linkApiSalariati: "/api/Salariati/GetSALARIATI",
      linkApiTipTermen: "/api/TipTermen",
      linkApiStadiu: "/api/TipStadiu",
      linkApiDepartament: "/api/Departamente",
      linkApiTipOperatiuni: "/api/TipOperatiuni",
      linkApiDocumente: "/api/Documente/GetDOCUMENTEbyProiectId",

      listaClienti: this.$f7.data.Firme,
      listaNivele: this.$f7.data.NivelProiect,
      listaTipProiecte: this.$f7.data.TipProiect,
      listaSalariati: this.$f7.data.SalariatiActivi,
      listaTipTermen: [],
      listaStadii: this.$f7.data.Stadiu,
      listaDepartamente: this.$f7.data.Departamente,
      listaOperatiuni: this.$f7.data.Operatiuni,
      listaPrioritati: this.$f7.data.Prioritati,
      listaProiecte: [...this.$f7.data.Proiecte, { key: "ROOT", value: -1 }],
      listaTipSursa: this.$f7.data.TipSursa,

      client: null,
      prioritate: null,
      nivel: null,
      tipProiect: null,
      ordine: 0,
      denumireProiect: "",
      isTask: false,
      dataCreare: [moment(Date.now()).format()],
      dataStart: [moment(Date.now()).format()],
      dataLimita: [moment(Date.now()).format()],
      termen: null,
      timpMinim: 0,
      timpSefProiect: 0,
      timpManager: 0,
      timpSupervizor: 0,
      aprobareDezvoltator: 0,
      aprobareManager: 0,
      aprobareSefProiect: 0,
      aprobareSupervizor: 0,
      numeDezvoltator: null,
      numeManager: null,
      numeSefProiect: null,
      numeSupervizor: null,
      comentariuProiect: "",
      observatiiProiect: "",
      bugetTimp: 0,
      buget: 0,
      stadiu: null,
      departament: null,
      risc: 0,
      operatie: null,
      adaugaRoluriLaCopii: false,
      eliminaRoluriLaCopii: false,
      directorRadacina: "",
      taskSimilar: null,
      taskParinte: null,
      userImport: null,
      tipSursa: null,
      path: ""
    };
  }

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  GetValues = () => {
    this.setState({
      dataLoaded: false
    });

    const requestTipTermen = axios.get(
      this.$f7.data.baseURL + this.state.linkApiTipTermen
    );
    const requestProiect = axios.get(
      this.$f7.data.baseURL +
      this.state.linkApiProiectById +
      "/" +
      this.$f7.data.Proiect.iD_PROIECT
    );

    axios
      .all([requestTipTermen, requestProiect])
      .then(
        axios.spread((responseTipTermen, responseProiect) => {
          let tipTermen = responseTipTermen.data;
          let info = responseProiect.data;
          let listaTipTermen = [];

          listaTipTermen.push({ key: "Fara", value: null });
          for (let i in tipTermen) {
            let toAdd = { key: "", value: 0 };
            toAdd.key = tipTermen[i].denumire;
            toAdd.value = tipTermen[i].iD_TERMEN;
            listaTipTermen.push(toAdd);
          }

          let stringPath = "";
          if (info.path !== null && info.path !== undefined) {
            let stringPathArray = info.path
              .split("\\")
              .filter(item => item !== "");
            if (stringPathArray.length > 0) {
              stringPathArray = stringPathArray.forEach((item, index) => {
                stringPath += this.$f7.data.Proiecte.find(
                  proiect => proiect.value === parseInt(item)
                ).key;

                if (index < stringPathArray.length - 1) {
                  stringPath += " \\ ";
                }
              });
            }
          }

          this.setState(
            {
              denumireProiect:
                info.denumire === null || info.denumire === ""
                  ? "Fara"
                  : info.denumire,
              client: info.id_client,
              prioritate: info.id_prioritate,
              nivel: info.id_nivel_proiect,
              tipProiect: info.id_tip_proiect,
              ordine: info.ordine,
              isTask: info.task,
              areDocumente: info.areDocumente,
              dataCreare: [info.data_creare],
              dataStart: [info.data_start],
              dataLimita: [info.deadline],
              termen: info.id_termen,
              timpMinim: info.timp_plan_d,
              timpSefProiect: info.timp_plan_sp,
              timpManager: info.timp_plan_m,
              timpSupervizor: info.timp_plan_a,
              aprobareDezvoltator: info.aprobat_d,
              aprobareManager: info.aprobat_m,
              aprobareSefProiect: info.aprobat_s,
              aprobareSupervizor: info.aprobat_a,
              bugetTimp: info.buget_timp,
              buget: info.buget,
              numeDezvoltator: info.id_dezvoltator,
              numeManager: info.id_manager,
              numeSefProiect: info.id_sefproiect,
              numeSupervizor: info.id_arbitru,
              stadiu: info.iD_STADIU,
              departament: info.id_departament,
              risc: info.proc_risc,
              userImport: info.userImport,
              operatie: info.id_operatiuni,
              directorRadacina:
                info.director_radacina === null || info.director_radacina === ""
                  ? "Fara"
                  : info.director_radacina,
              comentariuProiect:
                info.comentariu === null || info.comentariu === ""
                  ? ""
                  : info.comentariu,
              observatiiProiect:
                info.stadiu1 === null || info.stadiu1 === ""
                  ? ""
                  : info.stadiu1,
              taskParinte: info.reF_PROIECT,
              taskSimilar: info.idTaskSimilar,
              adaugaRoluriLaCopii: false,
              eliminaRoluriLaCopii: false,
              listaTipTermen: listaTipTermen,
              tipSursa: info.id_tip_sursa,
              path: stringPath
            },
            () => {
              uncontrolledInputs.denumireProiect = this.state.denumireProiect;
              uncontrolledInputs.ordine = this.state.ordine;
              uncontrolledInputs.isTask = this.state.isTask;
              uncontrolledInputs.timpMinim = this.state.timpMinim;
              uncontrolledInputs.timpSefProiect = this.state.timpSefProiect;
              uncontrolledInputs.timpManager = this.state.timpManager;
              uncontrolledInputs.timpSupervizor = this.state.timpSupervizor;
              uncontrolledInputs.aprobareDezvoltator = this.state.aprobareDezvoltator;
              uncontrolledInputs.aprobareManager = this.state.aprobareManager;
              uncontrolledInputs.aprobareSefProiect = this.state.aprobareSefProiect;
              uncontrolledInputs.aprobareSupervizor = this.state.aprobareSupervizor;
              uncontrolledInputs.bugetTimp = this.state.bugetTimp;
              uncontrolledInputs.buget = this.state.buget;
              uncontrolledInputs.risc = this.state.risc;
              uncontrolledInputs.directorRadacina = this.state.directorRadacina;
              uncontrolledInputs.comentariuProiect = this.state.comentariuProiect;
              uncontrolledInputs.observatiiProiect = this.state.observatiiProiect;

              setTimeout(() => {
                this.setState({
                  dataLoaded: true
                });
              }, 400);
            }
          );
        })
      )
      .catch(error => {
        this.setState(
          {
            dataLoaded: true
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor proiectului de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => { }
            );
          }
        );

        console.log("error", error);
      });
  };

  UpdateValue = (obj, adaugaStergeData, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApiProiectById + "/" + id)
      .then(response => {
        var obj2 = response.data;
        Object.keys(obj).map(trasatura => {
          Object.keys(obj2).map(trasatura2 => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL + this.state.linkApiProiectUpdate + "/" + id;
        var url2 =
          this.$f7.data.baseURL +
          this.state.linkApiAdaugaStergeRoluri +
          "/" +
          id;

        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url
        })
          .then(response => {
            if (
              adaugaStergeData.adauga === true ||
              adaugaStergeData.sterge === true
            ) {
              axios({
                data: adaugaStergeData,
                headers: { "Content-Type": "application/json" },
                method: "put",
                url: url2
              })
                .then(response => { })
                .catch(error => {
                  console.log("error", error);
                  this.$f7.methods.openNotification(
                    "Eroare la modificare roluri"
                  );
                });
            }
            this.$f7.methods.refreshStanga();
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch(error => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  saveData = () => {
    this.setState(
      {
        denumireProiect: uncontrolledInputs.denumireProiect,
        ordine: uncontrolledInputs.ordine,
        isTask: uncontrolledInputs.isTask,
        timpMinim: uncontrolledInputs.timpMinim,
        timpSefProiect: uncontrolledInputs.timpSefProiect,
        timpManager: uncontrolledInputs.timpManager,
        timpSupervizor: uncontrolledInputs.timpSupervizor,
        aprobareDezvoltator: uncontrolledInputs.aprobareDezvoltator,
        aprobareManager: uncontrolledInputs.aprobareManager,
        aprobareSefProiect: uncontrolledInputs.aprobareSefProiect,
        aprobareSupervizor: uncontrolledInputs.aprobareSupervizor,
        bugetTimp: uncontrolledInputs.bugetTimp,
        buget: uncontrolledInputs.buget,
        risc: uncontrolledInputs.risc,
        directorRadacina: uncontrolledInputs.directorRadacina,
        comentariuProiect: uncontrolledInputs.comentariuProiect,
        observatiiProiect: uncontrolledInputs.observatiiProiect
      },
      () => {
        let data = {};
        let adaugaStergeData = {};

        data["aprobat_a"] = this.state.aprobareSupervizor;
        data["aprobat_d"] = this.state.aprobareDezvoltator;
        data["aprobat_m"] = this.state.aprobareManager;
        data["aprobat_s"] = this.state.aprobareSefProiect;
        data["buget"] = this.state.buget;
        data["buget_timp"] = this.state.bugetTimp;
        data["comentariu"] =
          this.state.comentariuProiect === ""
            ? null
            : this.state.comentariuProiect;
        data["data_creare"] = moment(this.state.dataCreare[0]).format();
        data["data_start"] = moment(this.state.dataStart[0]).format();
        data["deadline"] = moment(this.state.dataLimita[0]).format();
        data["denumire"] =
          this.state.denumireProiect === "Fara"
            ? ""
            : this.state.denumireProiect;
        data["director_radacina"] =
          this.state.directorRadacina === "Fara"
            ? ""
            : this.state.directorRadacina;
        data["iD_STADIU"] = this.state.stadiu;
        data["idTaskSimilar"] = this.state.taskSimilar;
        data["id_arbitru"] = this.state.numeSupervizor;
        data["id_client"] = this.state.client;
        data["id_departament"] = this.state.departament;
        data["id_dezvoltator"] = this.state.numeDezvoltator;
        data["id_manager"] = this.state.numeManager;
        data["id_nivel_proiect"] = this.state.nivel;
        data["id_operatiuni"] = this.state.operatie;
        data["id_prioritate"] = this.state.prioritate;
        data["id_sefproiect"] = this.state.numeSefProiect;
        data["id_termen"] = this.state.termen;
        data["id_tip_proiect"] = this.state.tipProiect;
        data["ordine"] = this.state.ordine;
        data["proc_risc"] = this.state.risc;
        data["reF_PROIECT"] = this.state.taskParinte;
        data["stadiu1"] =
          this.state.observatiiProiect === ""
            ? null
            : this.state.observatiiProiect;
        data["task"] = this.state.isTask;
        data["timp_plan_a"] = this.state.timpSupervizor;
        data["timp_plan_d"] = this.state.timpMinim;
        data["timp_plan_m"] = this.state.timpManager;
        data["timp_plan_sp"] = this.state.timpSefProiect;
        data["id_tip_sursa"] = this.state.tipSursa;

        adaugaStergeData["adauga"] = this.state.adaugaRoluriLaCopii;
        adaugaStergeData["sterge"] = this.state.eliminaRoluriLaCopii;
        adaugaStergeData["idSefProiect"] = this.state.numeSefProiect;
        adaugaStergeData["idManager"] = this.state.numeManager;
        adaugaStergeData["idDezvoltator"] = this.state.numeDezvoltator;
        adaugaStergeData["idArbitru"] = this.state.numeSupervizor;

        this.UpdateValue(
          data,
          adaugaStergeData,
          this.$f7.data.Proiect.iD_PROIECT
        );
      }
    );
  };

  componentDidMount = () => {
    if (this.$f7.data.Proiect !== null) this.GetValues();
  };

  adaugaAtasamente = (file, idSelect) => {
    return new Promise((resolve, reject) => {
      if (file !== "" && file !== undefined && file !== null) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var res = reader.result;
          var continut = res.split(",")[1];
          var denumire = file.name;

          let doc = {
            sal_autor: this.$f7.data.user.iD_SALARIAT,
            id_proiect: idSelect,
            data_modif: moment().format(),
            dir_parinte: null,
            ref_document: null,
            nume_fisier: denumire,
            nume_path: denumire,
            id_initial: null,
            continut: continut,
            comentariu: null,
            id_tip_document: 2,
            id_tip_stadiu: null
          };

          axios({
            data: doc,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: this.$f7.data.baseURL + "/api/Documente/PostDOCUMENTE"
          })
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        };
        reader.onerror = error => {
          reject(error);
        };
      } else {
        reject("Nu exista fisiere!");
      }
    });
  };

  render() {
    return (
      <Page pageContent={false}>
        {this.$f7.data.Proiect !== null ? (
          <div style={{ height: "100%" }}>
            {this.state.dataLoaded ? (
              <div style={{ height: "100%" }}>
                <div
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    height: "100%",
                  }}>
                  <Segmented
                    raised
                    tag="div"
                    style={{
                      alignItems: "center",
                    }}>
                    <Toolbar tabbar={true} noShadow top>
                      <Link
                        tooltip={"Informatii generale"}
                        tabLink={"#tab-Detalii-1" + this.state.denumireTab}
                        tabLinkActive={
                          this.state.activeTab ===
                          "tab-Detalii-1" + this.state.denumireTab
                        }
                        onClick={() => {
                          this.setState({
                            activeTab:
                              "tab-Detalii-1" + this.state.denumireTab,
                          });
                        }}>
                        Info generale
                      </Link>
                      <Link
                        tooltip={"Date si timpi"}
                        tabLink={"#tab-Detalii-2" + this.state.denumireTab}
                        tabLinkActive={
                          this.state.activeTab ===
                          "tab-Detalii-2" + this.state.denumireTab
                        }
                        onClick={() => {
                          this.setState({
                            activeTab:
                              "tab-Detalii-2" + this.state.denumireTab,
                          });
                        }}>
                        Date/timpi
                      </Link>

                      <Link 
                        tooltip={"Aprobari si roluri"} 
                        tabLink={"#tab-Detalii-3" + this.state.denumireTab} 
                        tabLinkActive={ 
                          this.state.activeTab === 
                          "tab-Detalii-3" + this.state.denumireTab 
                        } 
                        onClick={() => { 
                          this.setState({ 
                            activeTab: 
                              "tab-Detalii-3" + this.state.denumireTab, 
                          }); 
                        }} 
                      > 
                        Aprobari/roluri 
                      </Link>

                      <Link
                        tooltip={"Alte informatii"}
                        tabLink={"#tab-Detalii-4" + this.state.denumireTab}
                        tabLinkActive={
                          this.state.activeTab ===
                          "tab-Detalii-4" + this.state.denumireTab
                        }
                        onClick={() => {
                          this.setState({
                            activeTab:
                              "tab-Detalii-4" + this.state.denumireTab,
                          });
                        }}>
                        Alte info
                      </Link>
                    </Toolbar>
                  </Segmented>
                  <Tabs
                    style={{
                      maxHeight: "calc(100% - 100px)",
                      height: "auto",
                      overflowY: "auto",
                    }}>
                    <Tab
                      style={{
                        height: "100%",
                        position: "relative",
                      }}
                      id={"tab-Detalii-1" + this.state.denumireTab}
                      tabActive={
                        this.state.activeTab ===
                        "tab-Detalii-1" + this.state.denumireTab
                      }>
                      {this.state.activeTab ===
                        "tab-Detalii-1" + this.state.denumireTab ? (
                        <List
                          //className="formDescriereProiect"
                          style={{ margin: 0 }}
                          noChevron
                          form
                          noHairlines
                          noHairlinesBetween
                          noHairlinesBetweenMd
                          noHairlinesMd>
                          <Card className="cardTopSpaceFT">
                            <CardContent>
                              <span className="cardContentFloatingTitle">
                                Informatii generale
                              </span>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListInput
                                    floatingLabel
                                    label="Path"
                                    validate
                                    type="text"
                                    placeholder="Path"
                                    defaultValue={this.state.path}
                                    //onChange={(e) => {
                                    //	uncontrolledInputs.denumireProiect = e.currentTarget.value;
                                    //}}
                                    //disabled={true}
                                    readonly>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                  </ListInput>
                                </Col>
                              </Row>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListItem
                                    className="roundedOutlinedSmartSelect"
                                    title="Clienti proiect"
                                    value={this.state.client}
                                    label="Clienti proiect"
                                    validate
                                    required
                                    smartSelect
                                    smartSelectParams={{
                                      virtualList: true,
                                      searchbar: true,
                                      openIn: "popup",
                                      popupTabletFullscreen: true,
                                      popupPush: true,
                                      closeOnSelect: true,
                                      on: {
                                        closed: (event) => {
                                          this.setState({
                                            client: parseInt(
                                              event.getValue()
                                            ),
                                          });
                                        },
                                      },
                                    }}
                                  //disabled={this.$f7.data.rol === 4}
                                  >
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                    <select
                                      defaultValue={this.state.client}
                                      name="Clienti proiect">
                                      {this.state.listaClienti.map(
                                        (obj, index) => (
                                          <option
                                            label={obj.key}
                                            key={
                                              "DescriereProiect-ListaClienti-" +
                                              index
                                            }
                                            value={obj.value}>
                                            {obj.key}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </ListItem>
                                </Col>
                                <Col>
                                  <ListItem
                                    className="roundedOutlinedSmartSelect"
                                    title="Prioritate"
                                    value={this.state.prioritate}
                                    label="Prioritate"
                                    validate
                                    required
                                    smartSelect
                                    smartSelectParams={{
                                      virtualList: true,
                                      searchbar: true,
                                      openIn: "popup",
                                      popupTabletFullscreen: true,
                                      popupPush: true,
                                      closeOnSelect: true,
                                      on: {
                                        closed: (event) => {
                                          this.setState({
                                            prioritate: parseInt(
                                              event.getValue()
                                            ),
                                          });
                                        },
                                      },
                                    }}
                                  //disabled={this.$f7.data.rol === 4}
                                  >
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                    <select
                                      defaultValue={this.state.prioritate}
                                      name="Prioritate">
                                      {this.state.listaPrioritati.map(
                                        (obj, index) => (
                                          <option
                                            label={obj.key}
                                            key={
                                              "DescriereProiect-ListaPrioritati-" +
                                              index
                                            }
                                            value={obj.value}>
                                            {obj.key}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </ListItem>
                                </Col>
                                <Col>
                                  <ListItem
                                    className="roundedOutlinedSmartSelect"
                                    title="Nivel Proiect"
                                    value={this.state.nivel}
                                    label="Nivel Proiect"
                                    validate
                                    required
                                    smartSelect
                                    smartSelectParams={{
                                      virtualList: true,
                                      searchbar: true,
                                      openIn: "popup",
                                      popupTabletFullscreen: true,
                                      popupPush: true,
                                      closeOnSelect: true,
                                      on: {
                                        closed: (event) => {
                                          this.setState({
                                            nivel: parseInt(
                                              event.getValue()
                                            ),
                                          });
                                        },
                                      },
                                    }}
                                  //disabled={this.$f7.data.rol === 4}
                                  >
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                    <select
                                      defaultValue={this.state.nivel}
                                      name="Nivel Proiect">
                                      {this.state.listaNivele.map(
                                        (obj, index) => (
                                          <option
                                            label={obj.key}
                                            key={
                                              "DescriereProiect-ListaNivelProiect-" +
                                              index
                                            }
                                            value={obj.value}>
                                            {obj.key}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </ListItem>
                                </Col>
                                {this.state.userImport !== undefined &&
                                  this.state.userImport !== null ? (
                                  <Col>
                                    <ListInput
                                      floatingLabel
                                      label="Id Mantis"
                                      disabled={true}
                                      validate
                                      type="text"
                                      placeholder="Id Mantis"
                                      defaultValue={
                                        this.state.userImport % 10000000
                                      }>
                                      <Icon
                                        color={"var(--f7-theme-color)"}
                                        size={20}
                                        material="list"
                                        slot="content-start"
                                      />
                                    </ListInput>
                                  </Col>
                                ) : null}
                              </Row>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListItem
                                    className="roundedOutlinedSmartSelect"
                                    title="Tip proiect"
                                    value={this.state.tipProiect}
                                    label="Tip proiect"
                                    validate
                                    required
                                    smartSelect
                                    smartSelectParams={{
                                      virtualList: true,
                                      searchbar: true,
                                      openIn: "popup",
                                      popupTabletFullscreen: true,
                                      popupPush: true,
                                      closeOnSelect: true,
                                      on: {
                                        closed: (event) => {
                                          this.setState({
                                            tipProiect: parseInt(
                                              event.getValue()
                                            ),
                                          });
                                        },
                                      },
                                    }}
                                  //disabled={this.$f7.data.rol === 4}
                                  >
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                    <select
                                      defaultValue={this.state.tipProiect}
                                      name="Tip proiect">
                                      {this.state.listaTipProiecte.map(
                                        (obj, index) => (
                                          <option
                                            label={obj.key}
                                            key={
                                              "DescriereProiect-ListaTipProiect-" +
                                              index
                                            }
                                            value={obj.value}>
                                            {obj.key}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </ListItem>
                                </Col>
                                <Col>
                                  <ListInput
                                    floatingLabel
                                    label="Ordine"
                                    validate
                                    type="number"
                                    min="0"
                                    step="1"
                                    placeholder="Ordine"
                                    defaultValue={this.state.ordine}
                                    onChange={(e) => {
                                      uncontrolledInputs.ordine = parseInt(
                                        e.currentTarget.value
                                      );
                                    }}
                                  //disabled={this.$f7.data.rol === 4}
                                  >
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                  </ListInput>
                                </Col>
                                <Col>
                                  <ListItem
                                    className="simpleInlineCheckbox"
                                    checkbox
                                    title="Este Task"
                                    name="isTask"
                                    defaultChecked={
                                      this.state.isTask !== null
                                        ? this.state.isTask
                                        : false
                                    }
                                    onChange={(e) => {
                                      uncontrolledInputs.isTask =
                                        e.currentTarget.checked;
                                    }}
                                  //disabled={this.$f7.data.rol === 4}
                                  />
                                </Col>
                              </Row>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListItem
                                    title="Tip sursa"
                                    value={this.state.tipSursa}
                                    label="Tip sursa"
                                    validate
                                    required
                                    smartSelect
                                    className="roundedOutlinedSmartSelect"
                                    smartSelectParams={{
                                      virtualList: true,
                                      searchbar: true,
                                      openIn: "popup",
                                      popupTabletFullscreen: true,
                                      popupPush: true,
                                      closeOnSelect: true,
                                      on: {
                                        closed: (event) => {
                                          this.setState({
                                            tipSursa: parseInt(
                                              event.getValue()
                                            ),
                                          });
                                        },
                                      },
                                    }}
                                  //disabled={this.$f7.data.rol === 4}
                                  >
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                    <select
                                      defaultValue={this.state.tipSursa}
                                      name="Tip sursa">
                                      {this.state.listaTipSursa.map(
                                        (obj, index) => (
                                          <option
                                            label={obj.key}
                                            key={
                                              "DescriereProiect-ListaTipSursa-" +
                                              index
                                            }
                                            value={obj.value}>
                                            {obj.key}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </ListItem>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>

                          <Card className="cardTopSpaceFT">
                            <CardContent>
                              <span className="cardContentFloatingTitle">
                                Bugete
                              </span>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListInput
                                    floatingLabel
                                    label="Buget de timp"
                                    validate
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    placeholder="Buget de timp"
                                    defaultValue={this.state.bugetTimp}
                                    onChange={(e) => {
                                      uncontrolledInputs.bugetTimp =
                                        parseFloat(e.currentTarget.value);
                                    }}
                                    disabled={this.$f7.data.rol === 4}
                                  />
                                </Col>
                                <Col>
                                  <ListInput
                                    floatingLabel
                                    label="Buget"
                                    validate
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    placeholder="Buget"
                                    defaultValue={this.state.buget}
                                    onChange={(e) => {
                                      uncontrolledInputs.buget = parseFloat(
                                        e.currentTarget.value
                                      );
                                    }}
                                    disabled={this.$f7.data.rol === 4}
                                  />
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>

                          <Card className="cardTopSpaceFT">
                            <CardContent>
                              <span className="cardContentFloatingTitle">
                                Cerinta
                              </span>
                              <ListInput
                                floatingLabel
                                //label="Comentariu"

                                validate
                                type="textarea"
                                //placeholder="Comentariu"
                                defaultValue={this.state.comentariuProiect}
                                onChange={(e) => {
                                  uncontrolledInputs.comentariuProiect =
                                    e.currentTarget.value;
                                }}
                              //disabled={this.$f7.data.rol === 4}
                              />
                            </CardContent>
                          </Card>
                        </List>
                      ) : null}
                    </Tab>

                    <Tab
                      style={{
                        height: "100%",
                        position: "relative",
                      }}
                      id={"tab-Detalii-2" + this.state.denumireTab}
                      tabActive={
                        this.state.activeTab ===
                        "tab-Detalii-2" + this.state.denumireTab
                      }>
                      {this.state.activeTab ===
                        "tab-Detalii-2" + this.state.denumireTab ? (
                        <List
                          //className="formDescriereProiect"
                          style={{ margin: 0 }}
                          noChevron
                          form
                          noHairlines
                          noHairlinesBetween
                          noHairlinesBetweenMd
                          noHairlinesMd>
                          <Card className="cardTopSpaceFT">
                            <CardContent>
                              <span className="cardContentFloatingTitle">
                                Date si termene
                              </span>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListInput
                                    disabled
                                    validate
                                    required
                                    label="Data Crearii"
                                    type="datepicker"
                                    calendarParams={{
                                      timePicker: true,
                                      dateFormat: "dd.mm.yyyy HH::mm",
                                      closeOnSelect: true,
                                    }}
                                    value={this.state.dataCreare}
                                    floatingLabel
                                    placeholder="Data Crearii">
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="today"
                                      slot="content-start"
                                    />
                                  </ListInput>
                                </Col>
                                <Col>
                                  <ListInput
                                    validate
                                    required
                                    label="Data Start"
                                    type="datepicker"
                                    calendarParams={{
                                      timePicker: true,
                                      dateFormat: "dd.mm.yyyy HH::mm",
                                      closeOnSelect: true,
                                      on: {
                                        closed: (e) => {
                                          var val = e.value[0];
                                          this.setState({
                                            dataStart: [val],
                                          });
                                        },
                                      },
                                    }}
                                    value={this.state.dataStart}
                                    floatingLabel
                                    placeholder="Data Start"
                                    disabled={this.$f7.data.rol === 4}>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="today"
                                      slot="content-start"
                                    />
                                  </ListInput>
                                </Col>
                                <Col>
                                  <ListInput
                                    validate
                                    required
                                    label="Data Limita"
                                    type="datepicker"
                                    calendarParams={{
                                      timePicker: true,
                                      dateFormat: "dd.mm.yyyy HH::mm",
                                      closeOnSelect: true,
                                      on: {
                                        closed: (e) => {
                                          var val = e.value[0];
                                          this.setState({
                                            dataLimita: [val],
                                          });
                                        },
                                      },
                                    }}
                                    value={this.state.dataLimita}
                                    floatingLabel
                                    placeholder="Data Limita"
                                    disabled={this.$f7.data.rol === 4}>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="today"
                                      slot="content-start"
                                    />
                                  </ListInput>
                                </Col>
                              </Row>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListItem
                                    className="roundedOutlinedSmartSelect"
                                    title="Termen"
                                    value={this.state.termen}
                                    label="Termen"
                                    validate
                                    required
                                    smartSelect
                                    smartSelectParams={{
                                      virtualList: true,
                                      searchbar: true,
                                      openIn: "popup",
                                      popupTabletFullscreen: true,
                                      popupPush: true,
                                      closeOnSelect: true,
                                      on: {
                                        closed: (event) => {
                                          this.setState({
                                            termen: parseInt(
                                              event.getValue()
                                            ),
                                          });
                                        },
                                      },
                                    }}>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                    <select
                                      defaultValue={this.state.termen}
                                      name="Termen">
                                      {this.state.listaTipTermen.map(
                                        (obj, index) => (
                                          <option
                                            label={obj.key}
                                            key={
                                              "DescriereProiect-ListaTipTermen-" +
                                              index
                                            }
                                            value={obj.value}>
                                            {obj.key}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </ListItem>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>

                          <Card className="cardTopSpaceFT">
                            <CardContent>
                              <span className="cardContentFloatingTitle">
                                Timpi estimati
                              </span>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListInput
                                    floatingLabel
                                    label="Timp dezv. min."
                                    validate
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    placeholder="Timp dezv. min."
                                    defaultValue={this.state.timpMinim}
                                    onChange={(e) => {
                                      uncontrolledInputs.timpMinim =
                                        parseFloat(e.currentTarget.value);
                                    }}
                                    disabled={
                                      this.$f7.data.rol > 4 ||
                                      this.state.timpSefProiect !== null ||
                                      this.state.timpSupervizor !== null ||
                                      this.state.timpManager !== null
                                    }>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                  </ListInput>
                                </Col>
                                <Col>
                                  <ListInput
                                    floatingLabel
                                    label="Sef proiect"
                                    validate
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    placeholder="Sef proiect"
                                    defaultValue={this.state.timpSefProiect}
                                    onChange={(e) => {
                                      uncontrolledInputs.timpSefProiect =
                                        parseFloat(e.currentTarget.value);
                                    }}
                                    disabled={
                                      this.$f7.data.rol > 3 ||
                                      this.state.timpSupervizor !== null ||
                                      this.state.timpManager !== null
                                    }>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                  </ListInput>
                                </Col>
                              </Row>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListInput
                                    floatingLabel
                                    label="Supervizor"
                                    validate
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    placeholder="Supervizor"
                                    defaultValue={this.state.timpSupervizor}
                                    onChange={(e) => {
                                      uncontrolledInputs.timpSupervizor =
                                        parseFloat(e.currentTarget.value);
                                    }}
                                    disabled={
                                      this.$f7.data.rol > 2 ||
                                      this.state.timpManager !== null
                                    }>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                  </ListInput>
                                </Col>

                                <Col>
                                  <ListInput
                                    floatingLabel
                                    label="Manager"
                                    validate
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    placeholder="Manager"
                                    defaultValue={this.state.timpManager}
                                    onChange={(e) => {
                                      uncontrolledInputs.timpManager =
                                        parseFloat(e.currentTarget.value);
                                    }}
                                    disabled={this.$f7.data.rol > 1}>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                  </ListInput>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        </List>
                      ) : null}
                    </Tab>

                    <Tab
                      style={{
                        height: "100%",
                        position: "relative",
                      }}
                      id={"tab-Detalii-3" + this.state.denumireTab}
                      tabActive={
                        this.state.activeTab ===
                        "tab-Detalii-3" + this.state.denumireTab
                      }>
                      {this.state.activeTab ===
                        "tab-Detalii-3" + this.state.denumireTab ? (
                        <List
                          //className="formDescriereProiect"
                          style={{ margin: 0 }}
                          noChevron
                          form
                          noHairlines
                          noHairlinesBetween
                          noHairlinesBetweenMd
                          noHairlinesMd>
                          <Card className="cardTopSpaceFT">
                            <CardContent>
                              <span className="cardContentFloatingTitle">
                                Aprobari
                              </span>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListItem
                                    className="simpleInlineCheckbox"
                                    checkbox
                                    title="Dezvoltator"
                                    name="aprobareDezvoltator"
                                    defaultChecked={
                                      this.state.aprobareDezvoltator !==
                                        null
                                        ? this.state.aprobareDezvoltator ===
                                          1
                                          ? true
                                          : false
                                        : false
                                    }
                                    onChange={(e) => {
                                      uncontrolledInputs.aprobareDezvoltator =
                                        e.currentTarget.checked ? 1 : 0;
                                    }}
                                    disabled={this.$f7.data.rol === 4}
                                  />
                                </Col>
                                <Col>
                                  <ListItem
                                    className="simpleInlineCheckbox"
                                    checkbox
                                    title="Supervizor"
                                    name="aprobareSupervizor"
                                    defaultChecked={
                                      this.state.aprobareSupervizor !== null
                                        ? this.state.aprobareSupervizor ===
                                          1
                                          ? true
                                          : false
                                        : false
                                    }
                                    onChange={(e) => {
                                      uncontrolledInputs.aprobareSupervizor =
                                        e.currentTarget.checked ? 1 : 0;
                                    }}
                                    disabled={this.$f7.data.rol === 4}
                                  />
                                </Col>
                                <Col>
                                  <ListItem
                                    className="simpleInlineCheckbox"
                                    checkbox
                                    title="Sef proiect"
                                    name="aprobareSefProiect"
                                    defaultChecked={
                                      this.state.aprobareSefProiect !== null
                                        ? this.state.aprobareSefProiect ===
                                          1
                                          ? true
                                          : false
                                        : false
                                    }
                                    onChange={(e) => {
                                      uncontrolledInputs.aprobareSefProiect =
                                        e.currentTarget.checked ? 1 : 0;
                                    }}
                                    disabled={this.$f7.data.rol === 4}
                                  />
                                </Col>
                                <Col>
                                  <ListItem
                                    className="simpleInlineCheckbox"
                                    checkbox
                                    title="Manager"
                                    name="aprobareManager"
                                    defaultChecked={
                                      this.state.aprobareManager !== null
                                        ? this.state.aprobareManager === 1
                                          ? true
                                          : false
                                        : false
                                    }
                                    onChange={(e) => {
                                      uncontrolledInputs.aprobareManager = e
                                        .currentTarget.checked
                                        ? 1
                                        : 0;
                                    }}
                                    disabled={this.$f7.data.rol === 4}
                                  />
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>

                          <Card className="cardTopSpaceFT">
                            <CardContent>
                              <span className="cardContentFloatingTitle">
                                Roluri pe proiect
                              </span>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListItem
                                    className="roundedOutlinedSmartSelect"
                                    title="Dezvoltator"
                                    value={this.state.numeDezvoltator}
                                    label="Dezvoltator"
                                    validate
                                    required
                                    smartSelect
                                    smartSelectParams={{
                                      virtualList: true,
                                      searchbar: true,
                                      openIn: "popup",
                                      popupTabletFullscreen: true,
                                      popupPush: true,
                                      closeOnSelect: true,
                                      on: {
                                        closed: (event) => {
                                          this.setState({
                                            numeDezvoltator: parseInt(
                                              event.getValue()
                                            ),
                                          });
                                        },
                                      },
                                    }}
                                    disabled={this.$f7.data.rol === 4}>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                    <select
                                      defaultValue={
                                        this.state.numeDezvoltator
                                      }
                                      name="Dezvoltator">
                                      {this.state.listaSalariati.map(
                                        (obj, index) => (
                                          <option
                                            label={obj.key}
                                            key={
                                              "DescriereProiect-ListaSalariatiDezvoltator-" +
                                              index
                                            }
                                            value={obj.value}>
                                            {obj.key}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </ListItem>
                                </Col>
                                <Col>
                                  <ListItem
                                    className="roundedOutlinedSmartSelect"
                                    title="Sef proiect"
                                    value={this.state.numeSefProiect}
                                    label="Sef proiect"
                                    validate
                                    required
                                    smartSelect
                                    smartSelectParams={{
                                      virtualList: true,
                                      searchbar: true,
                                      openIn: "popup",
                                      popupTabletFullscreen: true,
                                      popupPush: true,
                                      closeOnSelect: true,
                                      on: {
                                        closed: (event) => {
                                          this.setState({
                                            numeSefProiect: parseInt(
                                              event.getValue()
                                            ),
                                          });
                                        },
                                      },
                                    }}
                                    disabled={this.$f7.data.rol === 4}>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                    <select
                                      defaultValue={
                                        this.state.numeSefProiect
                                      }
                                      name="Sef proiect">
                                      {this.state.listaSalariati.map(
                                        (obj, index) => (
                                          <option
                                            label={obj.key}
                                            key={
                                              "DescriereProiect-ListaSalariatiSefProiect-" +
                                              index
                                            }
                                            value={obj.value}>
                                            {obj.key}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </ListItem>
                                </Col>
                              </Row>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListItem
                                    className="roundedOutlinedSmartSelect"
                                    title="Supervizor"
                                    value={this.state.numeSupervizor}
                                    label="Supervizor"
                                    validate
                                    required
                                    smartSelect
                                    smartSelectParams={{
                                      virtualList: true,
                                      searchbar: true,
                                      openIn: "popup",
                                      popupTabletFullscreen: true,
                                      popupPush: true,
                                      closeOnSelect: true,
                                      on: {
                                        closed: (event) => {
                                          this.setState({
                                            numeSupervizor: parseInt(
                                              event.getValue()
                                            ),
                                          });
                                        },
                                      },
                                    }}
                                    disabled={this.$f7.data.rol === 4}>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                    <select
                                      defaultValue={
                                        this.state.numeSupervizor
                                      }
                                      name="Supervizor">
                                      {this.state.listaSalariati.map(
                                        (obj, index) => (
                                          <option
                                            label={obj.key}
                                            key={
                                              "DescriereProiect-ListaSalariatiSupervizor-" +
                                              index
                                            }
                                            value={obj.value}>
                                            {obj.key}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </ListItem>
                                </Col>
                                <Col>
                                  <ListItem
                                    className="roundedOutlinedSmartSelect"
                                    title="Manager"
                                    value={this.state.numeManager}
                                    label="Manager"
                                    validate
                                    required
                                    smartSelect
                                    smartSelectParams={{
                                      virtualList: true,
                                      searchbar: true,
                                      openIn: "popup",
                                      popupTabletFullscreen: true,
                                      popupPush: true,
                                      closeOnSelect: true,
                                      on: {
                                        closed: (event) => {
                                          this.setState({
                                            numeManager: parseInt(
                                              event.getValue()
                                            ),
                                          });
                                        },
                                      },
                                    }}
                                    disabled={this.$f7.data.rol === 4}>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                    <select
                                      defaultValue={this.state.numeManager}
                                      name="Manager">
                                      {this.state.listaSalariati.map(
                                        (obj, index) => (
                                          <option
                                            label={obj.key}
                                            key={
                                              "DescriereProiect-ListaSalariatiManager-" +
                                              index
                                            }
                                            value={obj.value}>
                                            {obj.key}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </ListItem>
                                </Col>
                              </Row>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListItem
                                    className="simpleInlineCheckbox"
                                    checkbox
                                    title="Aplica rolurile si la copii"
                                    name="adaugaRoluriLaCopii"
                                    checked={this.state.adaugaRoluriLaCopii}
                                    onChange={(e) => {
                                      this.setState(
                                        {
                                          adaugaRoluriLaCopii:
                                            e.currentTarget.checked,
                                        },
                                        () => {
                                          if (
                                            this.state
                                              .adaugaRoluriLaCopii === true
                                          ) {
                                            this.setState({
                                              eliminaRoluriLaCopii: false,
                                            });
                                          }
                                        }
                                      );
                                    }}
                                    disabled={this.$f7.data.rol === 4}
                                  />
                                </Col>
                                <Col>
                                  <ListItem
                                    className="simpleInlineCheckbox"
                                    checkbox
                                    title="Sterge rolurile la copii"
                                    name="eliminaRoluriLaCopii"
                                    checked={
                                      this.state.eliminaRoluriLaCopii
                                    }
                                    onChange={(e) => {
                                      this.setState(
                                        {
                                          eliminaRoluriLaCopii:
                                            e.currentTarget.checked,
                                        },
                                        () => {
                                          if (
                                            this.state
                                              .eliminaRoluriLaCopii === true
                                          ) {
                                            this.setState({
                                              adaugaRoluriLaCopii: false,
                                            });
                                          }
                                        }
                                      );
                                    }}
                                    disabled={this.$f7.data.rol === 4}
                                  />
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>
                        </List>
                      ) : null}
                    </Tab>

                    <Tab
                      style={{
                        height: "100%",
                        position: "relative",
                      }}
                      id={"tab-Detalii-4" + this.state.denumireTab}
                      tabActive={
                        this.state.activeTab ===
                        "tab-Detalii-4" + this.state.denumireTab
                      }>
                      {this.state.activeTab ===
                        "tab-Detalii-4" + this.state.denumireTab ? (
                        <List
                          //className="formDescriereProiect"
                          style={{ margin: 0 }}
                          noChevron
                          form
                          noHairlines
                          noHairlinesBetween
                          noHairlinesBetweenMd
                          noHairlinesMd>
                          <Card className="cardTopSpaceFT">
                            <CardContent>
                              <span className="cardContentFloatingTitle">
                                Alte informatii
                              </span>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListInput
                                    floatingLabel
                                    label="Denumire"
                                    validate
                                    type="text"
                                    placeholder="Denumire"
                                    defaultValue={
                                      this.state.denumireProiect
                                    }
                                    onChange={(e) => {
                                      uncontrolledInputs.denumireProiect =
                                        e.currentTarget.value;
                                    }}
                                  //disabled={this.$f7.data.rol === 4}
                                  >
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                  </ListInput>
                                </Col>
                              </Row>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListItem
                                    className="roundedOutlinedSmartSelect"
                                    title="Stadiu"
                                    value={this.state.stadiu}
                                    label="Stadiu"
                                    validate
                                    required
                                    smartSelect
                                    smartSelectParams={{
                                      virtualList: true,
                                      searchbar: true,
                                      openIn: "popup",
                                      popupTabletFullscreen: true,
                                      popupPush: true,
                                      closeOnSelect: true,
                                      on: {
                                        closed: (event) => {
                                          this.setState({
                                            stadiu: parseInt(
                                              event.getValue()
                                            ),
                                          });
                                        },
                                      },
                                    }}
                                    disabled={this.$f7.data.rol === 4}>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                    <select
                                      defaultValue={this.state.stadiu}
                                      name="Stadiu">
                                      {this.state.listaStadii.map(
                                        (obj, index) => (
                                          <option
                                            label={obj.key}
                                            key={
                                              "DescriereProiect-ListaStadii-" +
                                              index
                                            }
                                            value={obj.value}>
                                            {obj.key}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </ListItem>
                                </Col>
                                <Col>
                                  <ListItem
                                    className="roundedOutlinedSmartSelect"
                                    title="Departament"
                                    value={this.state.departament}
                                    label="Departament"
                                    validate
                                    required
                                    smartSelect
                                    smartSelectParams={{
                                      virtualList: true,
                                      searchbar: true,
                                      openIn: "popup",
                                      popupTabletFullscreen: true,
                                      popupPush: true,
                                      closeOnSelect: true,
                                      on: {
                                        closed: (event) => {
                                          this.setState({
                                            departament: parseInt(
                                              event.getValue()
                                            ),
                                          });
                                        },
                                      },
                                    }}
                                    disabled={this.$f7.data.rol === 4}>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                    <select
                                      defaultValue={this.state.departament}
                                      name="Departament">
                                      {this.state.listaDepartamente.map(
                                        (obj, index) => (
                                          <option
                                            label={obj.key}
                                            key={
                                              "DescriereProiect-ListaDepartamente-" +
                                              index
                                            }
                                            value={obj.value}>
                                            {obj.key}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </ListItem>
                                </Col>
                                <Col>
                                  <ListInput
                                    floatingLabel
                                    label="Procent Risc"
                                    validate
                                    type="number"
                                    min="0"
                                    max="100"
                                    step="0.1"
                                    placeholder="Procent Risc"
                                    defaultValue={this.state.risc}
                                    onChange={(e) => {
                                      uncontrolledInputs.risc = parseFloat(
                                        e.currentTarget.value
                                      );
                                    }}
                                    disabled={this.$f7.data.rol === 4}>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                  </ListInput>
                                </Col>
                                <Col>
                                  <ListItem
                                    className="roundedOutlinedSmartSelect"
                                    title="Operatie"
                                    value={this.state.operatie}
                                    label="Operatie"
                                    validate
                                    required
                                    smartSelect
                                    smartSelectParams={{
                                      virtualList: true,
                                      searchbar: true,
                                      openIn: "popup",
                                      popupTabletFullscreen: true,
                                      popupPush: true,
                                      closeOnSelect: true,
                                      on: {
                                        closed: (event) => {
                                          this.setState({
                                            operatie: parseInt(
                                              event.getValue()
                                            ),
                                          });
                                        },
                                      },
                                    }}
                                    disabled={this.$f7.data.rol === 4}>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                    <select
                                      defaultValue={this.state.operatie}
                                      name="Operatie">
                                      {this.state.listaOperatiuni.map(
                                        (obj, index) => (
                                          <option
                                            label={obj.key}
                                            key={
                                              "DescriereProiect-ListaOperatiuni-" +
                                              index
                                            }
                                            value={obj.value}>
                                            {obj.key}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </ListItem>
                                </Col>
                              </Row>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListInput
                                    floatingLabel
                                    label="Director Radacina"
                                    validate
                                    type="text"
                                    placeholder="Director Radacina"
                                    defaultValue={
                                      this.state.directorRadacina
                                    }
                                    onChange={(e) => {
                                      uncontrolledInputs.directorRadacina =
                                        e.currentTarget.value;
                                    }}
                                    disabled={this.$f7.data.rol === 4}>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                  </ListInput>
                                </Col>
                              </Row>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <ListItem
                                    className="roundedOutlinedSmartSelect"
                                    title="Task similar"
                                    value={this.state.taskSimilar}
                                    label="Task similar"
                                    validate
                                    required
                                    smartSelect
                                    smartSelectParams={{
                                      virtualList: true,
                                      searchbar: true,
                                      openIn: "popup",
                                      popupTabletFullscreen: true,
                                      popupPush: true,
                                      closeOnSelect: true,
                                      on: {
                                        closed: (event) => {
                                          this.setState({
                                            taskSimilar: parseInt(
                                              event.getValue()
                                            ),
                                          });
                                        },
                                      },
                                    }}
                                    disabled={this.$f7.data.rol === 4}>
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                    <select
                                      defaultValue={this.state.taskSimilar}
                                      name="Task similar">
                                      {this.state.listaProiecte.map(
                                        (obj, index) => (
                                          <option
                                            label={obj.key}
                                            key={
                                              "DescriereProiect-ListaTaskSimilar-" +
                                              index
                                            }
                                            value={obj.value}>
                                            {obj.key}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </ListItem>
                                </Col>
                                <Col>
                                  <ListInput
                                    readonly
                                    floatingLabel
                                    label="Parinte task"
                                    validate
                                    type="text"
                                    placeholder="Parinte task"
                                    value={
                                      this.state.listaProiecte.filter(
                                        (item) =>
                                          item.value ===
                                          this.state.taskParinte
                                      )[0].key
                                    }
                                  //disabled={this.$f7.data.rol === 4}
                                  >
                                    <Icon
                                      color={"var(--f7-theme-color)"}
                                      size={20}
                                      material="list"
                                      slot="content-start"
                                    />
                                  </ListInput>
                                </Col>
                              </Row>
                              <Row style={{ width: "100%" }}>
                                <Col>
                                  <Button
                                    tooltip={"Adauga atasament"}
                                    fill
                                    style={{ marginTop: "8px" }}
                                    onClick={() => {
                                      let inputs = [
                                        {
                                          key: "Atasamente",
                                          tip: "filepickermultiplu",
                                          group: "Atasamentele",
                                        },
                                      ];

                                      this.$f7.data.router.navigate(
                                        "/popupAddEdit/",
                                        {
                                          props: {
                                            inputs: inputs,
                                            title:
                                              "Adaugare atasamente la '" +
                                              this.$f7.data.Proiecte.find(
                                                (r) => {
                                                  return (
                                                    r.value ===
                                                    this.$f7.data.Proiect
                                                      .iD_PROIECT
                                                  );
                                                }
                                              ).key +
                                              "'",
                                            buttons: [
                                              {
                                                key: "Salveaza",
                                                onSubmit: (state, den) => {
                                                  this.$f7.preloader.show();
                                                  this.$f7.panel.close(den);

                                                  if (
                                                    state.editedItem
                                                      .Atasamente !==
                                                    undefined &&
                                                    state.editedItem
                                                      .Atasamente.length > 0
                                                  ) {
                                                    var arrAtasamente = [];

                                                    for (const atasament of state
                                                      .editedItem
                                                      .Atasamente) {
                                                      arrAtasamente.push(
                                                        atasament
                                                      );
                                                    }

                                                    arrAtasamente
                                                      .reduce(
                                                        (promise, file) => {
                                                          return promise.then(
                                                            () => {
                                                              return this.adaugaAtasamente(
                                                                file,
                                                                this.state
                                                                  .idSarcina
                                                              )
                                                                .then(
                                                                  (r) => { }
                                                                )
                                                                .catch(
                                                                  (err) => {
                                                                    console.log(
                                                                      "err",
                                                                      err
                                                                    );
                                                                  }
                                                                );
                                                            }
                                                          );
                                                        },
                                                        Promise.resolve()
                                                      )
                                                      .then((res) => {
                                                        this.$f7.preloader.hide();
                                                        this.$f7.methods.openNotification(
                                                          "Atasamente adaugate!",
                                                          undefined,
                                                          "succes"
                                                        );
                                                      })
                                                      .catch((err) => {
                                                        console.log(
                                                          "eroare:",
                                                          err
                                                        );
                                                        this.$f7.preloader.hide();
                                                        this.$f7.methods.openNotification(
                                                          err,
                                                          undefined,
                                                          "eroare"
                                                        );
                                                      });
                                                  } else {
                                                    this.$f7.preloader.hide();
                                                    this.$f7.methods.openNotification(
                                                      "Nu au fost adaugate atasamente!",
                                                      undefined,
                                                      "eroare"
                                                    );
                                                  }
                                                },
                                              },
                                            ],
                                          },
                                        }
                                      );
                                    }}>
                                    Adauga atasament
                                  </Button>
                                </Col>

                                <Col>
                                  <Button
                                    disabled={!this.state.areDocumente}
                                    tooltip={"Vezi atasament"}
                                    onClick={() => {
                                      if (this.state.tipProiect === 55) {
                                        var idMantis =
                                          parseInt(this.state.userImport) %
                                          10000000;
                                        this.$f7.preloader.show();
                                        axios
                                          .get(
                                            "./api/SarciniSalariat/GetMantisFisiere/" +
                                            idMantis
                                          )
                                          .then((x) => {
                                            var atasamente2 = x.data;
                                            var atasamente = [
                                              { denumire: "Close" },
                                            ];
                                            atasamente2.map((o) => {
                                              atasamente.push(o);
                                              return false;
                                            });
                                            this.$f7.preloader.hide();
                                            this.setState(
                                              { atasamente: atasamente },
                                              () => {
                                                if (
                                                  this.state.atasamente
                                                    .length > 1
                                                ) {
                                                  var smS =
                                                    this.$f7.smartSelect.create(
                                                      {
                                                        el: ".listaAtasamente2",
                                                        virtualList: true,
                                                        searchbar: true,
                                                        openIn: "popup",
                                                        popupTabletFullscreen: true,
                                                        popupPush: true,
                                                        closeOnSelect: true,
                                                        on: {
                                                          closed: (
                                                            event
                                                          ) => {
                                                            var denumire =
                                                              event.getValue();
                                                            if (
                                                              denumire !==
                                                              "Close"
                                                            ) {
                                                              var el =
                                                                this.state.atasamente.filter(
                                                                  (f) => {
                                                                    return (
                                                                      f.denumire ===
                                                                      denumire
                                                                    );
                                                                  }
                                                                )[0];
                                                              var content =
                                                                el.content;
                                                              var contentType =
                                                                el.contentType;
                                                              var blob =
                                                                this.b64toBlob(
                                                                  content,
                                                                  contentType
                                                                );
                                                              saveAs(
                                                                blob,
                                                                denumire
                                                              );
                                                            }
                                                          },
                                                        },
                                                      }
                                                    );
                                                  smS.open();
                                                } else {
                                                  this.$f7.methods.openNotification(
                                                    "Nu exista atasamente",
                                                    "Ups.."
                                                  );
                                                }
                                              }
                                            );
                                          });
                                      } else {
                                        this.$f7.preloader.show();
                                        axios
                                          .get(
                                            this.$f7.data.baseURL +
                                            this.state.linkApiDocumente +
                                            "/" +
                                            this.$f7.data.Proiect
                                              .iD_PROIECT
                                          )
                                          .then((doc) => {
                                            var documente = doc.data;
                                            var atasamente = [
                                              { numE_FISIER: "Close" },
                                            ];
                                            documente.map((o) => {
                                              atasamente.push(o);
                                              return false;
                                            });
                                            this.$f7.preloader.hide();

                                            this.setState(
                                              {
                                                atasamente: atasamente,
                                              },
                                              () => {
                                                if (
                                                  this.state.atasamente
                                                    .length > 1
                                                ) {
                                                  var smS =
                                                    this.$f7.smartSelect.create(
                                                      {
                                                        el: ".listaAtasamente3",
                                                        virtualList: true,
                                                        searchbar: true,
                                                        openIn: "popup",
                                                        popupTabletFullscreen: true,
                                                        popupPush: true,
                                                        closeOnSelect: true,
                                                        on: {
                                                          closed: (
                                                            event
                                                          ) => {
                                                            var denumire =
                                                              event.getValue();

                                                            if (
                                                              denumire !==
                                                              "Close"
                                                            ) {
                                                              var el =
                                                                this.state.atasamente.filter(
                                                                  (f) => {
                                                                    return (
                                                                      f.numE_FISIER ===
                                                                      denumire
                                                                    );
                                                                  }
                                                                )[0];
                                                              var content =
                                                                el.continut;
                                                              var blob =
                                                                this.b64toBlob(
                                                                  content
                                                                );
                                                              saveAs(
                                                                blob,
                                                                denumire
                                                              );
                                                            }
                                                          },
                                                        },
                                                      }
                                                    );
                                                  smS.open();
                                                } else {
                                                  this.$f7.methods.openNotification(
                                                    "Nu exista atasamente",
                                                    "Ups.."
                                                  );
                                                }
                                              }
                                            );
                                          });
                                      }
                                    }}
                                    fill
                                    style={{
                                      marginTop: "8px",
                                    }}>
                                    Vezi atasament
                                  </Button>
                                </Col>
                              </Row>
                            </CardContent>
                          </Card>

                          <Card className="cardTopSpaceFT">
                            <CardContent>
                              <span className="cardContentFloatingTitle">
                                Observatii
                              </span>
                              <ListInput
                                floatingLabel
                                //label="Observatii"

                                validate
                                type="textarea"
                                //placeholder="Observatii"
                                defaultValue={this.state.observatiiProiect}
                                onChange={(e) => {
                                  uncontrolledInputs.observatiiProiect =
                                    e.currentTarget.value;
                                }}
                              //disabled={this.$f7.data.rol === 4}
                              />
                            </CardContent>
                          </Card>
                        </List>
                      ) : null}
                    </Tab>
                  </Tabs>

                  <Button
                    tooltip={"Salveaza"}
                    style={{
                      margin: "16px auto 0",
                      width: 150,
                    }}
                    fill
                    iconMaterial="save"
                    iconColor={"white"}
                    iconSize={20}
                    onClick={() => this.saveData()}
                    //disabled={this.$f7.data.rol === 4 && (this.state.activeTab === "tab-Detalii-2" + this.state.denumireTab || this.state.activeTab === "tab-Detalii-3" + this.state.denumireTab)}
                    disabled={
                      this.$f7.data.rol === 4 &&
                      this.state.activeTab ===
                      "tab-Detalii-3" + this.state.denumireTab
                    }>
                    Salveaza
                  </Button>
                </div>

                <List
                  //className="formDescriereProiect"
                  noChevron
                  form
                  noHairlines
                  noHairlinesBetween
                  noHairlinesBetweenMd
                  noHairlinesMd>
                  <ListItem
                    style={{ display: "none" }}
                    className="listaAtasamente2"
                    validate
                    smartSelect
                    smartSelectParams={{
                      virtualList: true,
                      searchbar: true,
                      openIn: "popup",
                      popupTabletFullscreen: true,
                      popupPush: true,
                      closeOnSelect: true,
                    }}>
                    <select name="SelectBox4">
                      {this.state.atasamente.map((obj, index) => (
                        <option
                          key={"DescriereProiect-ListaAtasamente-" + index}
                          selected={
                            this.state.atasamente[0].denumire ===
                            obj.denumire
                          }
                          value={obj.denumire}>
                          {obj.denumire}
                        </option>
                      ))}
                    </select>
                    <Icon
                      slot="content-start"
                      style={{
                        color: "var(--f7-theme-color)",
                        left: "-2px",
                      }}
                      size={20}
                      material="list"
                    />
                  </ListItem>

                  <ListItem
                    style={{ display: "none" }}
                    className="listaAtasamente3"
                    validate
                    smartSelect
                    smartSelectParams={{
                      virtualList: true,
                      searchbar: true,
                      openIn: "popup",
                      popupTabletFullscreen: true,
                      popupPush: true,
                      closeOnSelect: true,
                    }}>
                    <select name="SelectBox5">
                      {this.state.atasamente.map((obj, index) => (
                        <option
                          key={"DescriereProiect-ListaAtasament2-" + index}
                          selected={
                            this.state.atasamente[0].numE_FISIER ===
                            obj.numE_FISIER
                          }
                          value={obj.numE_FISIER}>
                          {obj.numE_FISIER}
                        </option>
                      ))}
                    </select>
                    <Icon
                      slot="content-start"
                      style={{
                        color: "var(--f7-theme-color)",
                        left: "-2px",
                      }}
                      size={20}
                      material="list"
                    />
                  </ListItem>
                </List>
              </div>
            ) : (
              <div
                style={{
                  position: "absolute",
                  width: "35px",
                  height: "35px",
                  top: "calc(50vh)",
                  left: "50%",
                }}>
                <Preloader size={40} color={"var(--f7-theme-color)"} />
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              height: "calc(100vh - 50px)",
            }}>
            <h3
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                right: 0,
                transform: "translateY(-50%)",
                textTransform: "uppercase",
                textAlign: "center",
                display: "block",
              }}>
              Nu ati selectat nimic
            </h3>
          </div>
        )}
      </Page>
    );
  }
}
export default DescriereProiect;