import React from "react";
import { Page } from "framework7-react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
var moment = require("moment");
const localizer = momentLocalizer(moment);

/**
 * @description Interfata de planificare a unui task selectat din interfata de taskuri alocate/inchise etc
 * @date 08/04/2022
 * @class PlanificareTaskuri
 * @extends {React.PureComponent}
 */
class PlanificareTaskuri extends React.PureComponent {
  constructor(props) {
    super(props);
    this.height = window.innerHeight;
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.state = {
      dataSource: this.$f7.data.dataSourcePlanificare,
    };
  }

  render() {
    return (
      <Page pageContent={false}>
        <Calendar
          popup
          showMultiDayTimes
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          events={this.state.dataSource}
          defaultView={Views.MONTH}
          views={["month", "work_week", "week", "day", "agenda"]}
          style={{
            height: this.state.height - 5,
            width: "100%",
            overflow: "hidden",
          }}
        />
      </Page>
    );
  }
}
export default PlanificareTaskuri;
