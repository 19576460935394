import React from "react";
import { Page } from "framework7-react";
import config from "../../config";
var cp;
/**
 * @description Randeaza un colorpicker cu care se seteaza tema aplicatiei
 * @date 07/04/2022
 * @class ColorPickerSetting
 * @extends {React.PureComponent}
 */
class ColorPickerSetting extends React.PureComponent {
    componentDidMount() {
        if (cp) {
            cp.destroy();
        }
        setTimeout(() => {
            cp = this.$f7.colorPicker.create({
                containerEl: "#cp",
                modules: [
                    "initial-current-colors",
                    "sb-spectrum",
                    "rgb-sliders",
                    "palette"
                ],
                sliderValue: true,
                sliderValueEditable: true,
                sliderLabel: true,
                hexLabel: true,
                hexValueEditable: true,
                groupedModules: true,
                palette: config.palette,
                value: {
                    hex:
                        localStorage.getItem("color") !== null
                            ? localStorage.getItem("color")
                            : config.color
                },
                on: {
                    /**
                     * @description seteaza culoarea temei
                     * @function
                     * @date 07/04/2022
                     * @param {*} e
                     */
                    change: e => {
                        this.$f7.methods.setCustomColor(e.value.hex);
                    }
                }
            });
        }, 100);
    }

    render() {
        return (
            <Page className="themeBackground">
                <div className="ptr3">
                    <div id="cp" />
                </div>
            </Page>
        );
    }
}
export default ColorPickerSetting;