import React, { Component } from "react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";
import Grid from "../../Proiecte/Agenda/Grid";
import { ListItem, List } from "framework7-react";

 class CineLaCeLucreaza extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: true,
            linkApi: "/api/RealizareSarcini",
            proiect: null,
            dataSource: [],
            inputs: [
                { key: "Id", keyBaza: "random", tip: "number" },
                {
                    key: "Salariat",
                    keyBaza: "id_salariat",
                    tip: "selectbox",
                    lista: this.$f7.data.Salariati
                },
                {
                    key: "Task",
                    keyBaza: "id_proiect",
                    tip: "selectbox",
                    lista: this.$f7.data.Proiecte
                },
                {
                    key: "Client",
                    keyBaza: "id_client",
                    tip: "selectbox",
                    lista: this.$f7.data.Firme
                },
                { key: "MomentInceput", keyBaza: "intrare", tip: "datebox" }
            ],
            height: window.innerHeight - 44,
            checkedManager: false
        };

        this.refDataGrid = React.createRef();
    }

    GetValues = () => {
        this.setState({
            dataLoaded: false
        });

        axios
            .get(`${this.$f7.data.baseURL}${this.state.linkApi}/GetRealizareSarciniPorniteCurente/${(this.$f7.data.rol === 1 && this.state.checkedManager) ? this.$f7.data.user.iD_SALARIAT : -100}`)
            .then(response => {
                let baza = response.data.list;
                let salList = response.data.salIds;

                baza.map(element => {
                    element = this.$f7.methods.restructurareBaza(
                        element,
                        this.state.inputs,
                        "key"
                    );
                    element.Id = this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx");
                    return null;
                });

                if (this.$f7.data.toggleTotiSalariatii === false) {
                    if (this.$f7.data.rol === 1 && this.state.checkedManager) {
                        let salariatiInactivi = this.$f7.data.SalariatiActivi.filter(r => {
                            if (baza.find(el => el.Salariat === r.value) !== undefined) return false;
                            else if (salList.find(id => id === r.value) !== undefined) return true;
                            else return false;
                        });

                        salariatiInactivi.filter(x => x.value !== null).map(r => {
                            baza.push({
                                Salariat: r.value,
                                Task: null,
                                Client: null,
                                MomentInceput: null
                            });
                            return null;
                        });
                    } else {
                        let salariatiInactivi = this.$f7.data.SalariatiActivi.filter(r => {
                            if (baza.find(f => f.Salariat === r.value) !== undefined) {
                                return false;
                            } else {
                                return true;
                            }
                        });

                        salariatiInactivi.filter(x => x.value !== null).map(r => {
                            baza.push({
                                Salariat: r.value,
                                Task: null,
                                Client: null,
                                MomentInceput: null
                            });
                            return null;
                        }); 
                    }
                }

                this.setState({
                    dataSource: baza
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            dataLoaded: true
                        }, () => {
                            if (baza.length > 0) {
                                this.refDataGrid.current.setRenderItems(baza);
                            }
                        });
                    }, 400);
                });
            })
            .catch(error => {
                this.setState({
                    dataSource: [],
                    dataLoaded: true
                }, () => {
                    if (error.response && error.response.status && error.response.status === 404) {
                        this.$f7.dialog.alert(
                            error.response.data,
                            "Atentie!",
                            () => {
                                this.setState({
                                    checkedManager: !this.state.checkedManager
                                }, () => {
                                    this.GetValues();
                                });
                            }
                        );
                    } else {
                        this.$f7.dialog.confirm(
                            "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                            "Ups...",
                            () => {
                                this.GetValues();
                            },
                            () => { }
                        );
                    }
                });
            });
    };

    componentDidMount = () => {
        const checkedManagerStored = localStorage.getItem("globalManagerState");

        this.setState({
            checkedManager: this.$f7.data.rol === 1 ? checkedManagerStored !== null ? JSON.parse(checkedManagerStored) : true : false
        }, () => {
            this.GetValues();
        })
    };

    render() {
        return (
            <>
                {this.$f7.data.rol === 1 &&
                    <List
                        noChevron
                        noHairlines
                        noHairlinesBetween
                        noHairlinesBetweenMd
                        noHairlinesMd
                        style={{ position: 'absolute', top: -16, left: 0, width: 200 }}
                    >
                        <ListItem
                            checkbox
                            checked={this.state.checkedManager}
                            onChange={() => {
                                this.setState({
                                    checkedManager: !this.state.checkedManager
                                }, () => {
                                    localStorage.setItem("globalManagerState", JSON.stringify(this.state.checkedManager));
                                    
                                    setTimeout(() => {
                                        this.GetValues();
                                    }, 500);
                                });
                            }}
                            title={"Manager View"}
                            floatingLabel
                        />
                    </List>
                }
                
                <DataGrid
                    id={"Id"}
                    dataLoaded={this.state.dataLoaded}
                    refresh={this.GetValues}
                    name={"CineLaCeLucreaza"}
                    ref={this.refDataGrid}
                    renderUpdate={false}
                    renderDelete={false}
                    renderAdd={false}
                    renderAccordion={true}
                    accordionItem={(state, rowItemData) => {
                        var Proiect = this.$f7.data.allProiecte.filter(pr => {
                            return pr.iD_PROIECT === rowItemData.Task;
                        })[0];
                        if (Proiect !== undefined) {
                            this.$f7.data.Proiect = Proiect;
                            return (
                                <div style={{ height: 905 }}>
                                    <Grid />
                                </div>
                            );
                        } else {
                            return <div> Nu exista acest proiect.</div>;
                        }
                    }}
                    maxHeight={this.state.height}
                    dataSource={this.state.dataSource}
                    inputs={this.state.inputs}
                />
            </>
        );
    }
}
export default CineLaCeLucreaza; 