import React from "react";
import { Page } from "framework7-react";
import Istoric from "../Componente/Istoric";

class IstoricProiectClientPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            denumire: "Istoric Proiect/Client"
        };
    }
    render() {
        return (
            <Page
                className={"themeBackground"}
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
            >
                <div className="ptr3">
                    <Istoric type={3} />
                </div>
            </Page>
        );
    }
}
export default IstoricProiectClientPage;