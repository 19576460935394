import React from "react";
import { Page } from "framework7-react";
import CineLaCeLucreaza from "../Componente/CineLaCeLucreaza";

class CineLaCeLucreazaPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            denumire: "CineLaCeLucreaza",
        };
    }
    render() {
        return (
            <Page
                className={"themeBackground"}
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
            >

                <div className="ptr3">
                    <CineLaCeLucreaza />
                </div>
            </Page>
        );
    }
}
export default CineLaCeLucreazaPage;