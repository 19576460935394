import React, { Component } from "react";
import DataGrid from "../../Datagrid/DataGrid";
import { ListInput, ListItem, List, Row, Col, Icon, Preloader, Chip, View, Link, Popover } from "framework7-react";
import moment from "moment";
import axios from "axios";
import Timeline, { TimelineHeaders, SidebarHeader, DateHeader } from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import GraficSalariat from "../../Proiecte/Agenda/GraficSalariat";
import OreLucrateListaSarcini from "./OreLucrateListaSarcini";
import SalariatiModule from "../../Intretinere/Componente/SalariatiModule";

/**
 * @description Afiseaza un datagrid in partea din stanga cu toti salariatii din firma si cat a lucrat in intervalul selectat, si cate tasuri are alocate si rezolvate, in cazul in care se apasa pe alocate se deschide un popup cu taskurile alocate ale utilizatorului, in cazul in care se apasa pe rezolvate se deschide un popup cu taskurile inchise de catre utilizator.
 * @date 06/04/2022
 * @class OreLucratePeZi2
 * @extends {Component}
 */
class OreLucratePeZi2 extends Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;
    this.arrayDescrieri = [{ key: "" }];

    this.state = {
      informatiiPopover: null,
      targetPopover: null,
      responseData: null,
      colorsExplained: [
        { key: "black", value: "Normal" },            // Normal
        { key: "red", value: "Nu a lucrat" },         // Nu a lucrat
        { key: "#e6cb0d", value: "Concediu odihna" }, // CO - #e69705
        { key: "blue", value: "Concediu medical" },   // CM
        { key: "teal", value: "Crestere copil" },     // CC
        { key: "green", value: "Liber" },             // Liber
        { key: "purple", value: "Mai multe" }         // Mai multe
      ],
      checked: 3,
      checkedAscunsi: false,
      checkedManager: false,
      items: [
        { id: 1, group: 1, title: "", start_time: moment().add(-0.5, "hour"), end_time: moment().add(5, "hour") },
        { id: 2, group: 2, title: "", start_time: moment(), end_time: moment().add(1, "hour") },
        { id: 3, group: 2, title: "", start_time: moment().add(2, "hour"), end_time: moment().add(3, "hour") }
      ],
      groups: [
        { id: 1, title: "Total", height: 34 },
        { id: 2, title: "Lucrat", height: 34 }
      ],
      dataLoaded: false,
      height: 10000,
      linkApi: "/api/OreLucrateZi/GetOreLucrate2",
      linkApiMesaje: "/api/Sesizari",
      linkApiRealizareSarcini: "/api/RealizareSarcini",
      dataDeLa: [moment(Date.now()).format()],
      dataPanaLa: [moment(Date.now()).format()],
      dataSource: [],
      dataSourceInitial: [],
      inputs: [
        { key: "IdSalariat", keyBaza: "idSalariat" },
        { key: "Nume", keyBaza: "nume" },
        { key: "OreLucrate", keyBaza: "oreLucrate" /*, tip: "number"*/ },
        { key: "RefSalariat", keyBaza: "refSalariat", tip: "selectbox", lista: this.$f7.data.Salariati },
        { key: "Lista", keyBaza: "lista" },
        { key: "IdEveniment", keyBaza: "idEveniment" },
        { key: "IsHidden", keyBaza: "isHidden", tip: "bool" },
        { key: "A", keyBaza: "nrTaskuriAlocate", tip: "number" },
        { key: "R", keyBaza: "nrTaskuriRezolvate", tip: "number" },
        { key: "Locatie", keyBaza: "statusLocatie", tip: "number" },
        { key: "OreNelucrate", keyBaza: "oreNelucrate" }
      ]
    };

    this.refDatagrid = React.createRef();
    this.timelineRef = React.createRef();
  }

  /**
   * @description transforma valorile din datagrid(timpul lucrat) intr-un string mai usor de inteles (h : m : s).
   * @function
   * @date 06/04/2022
   * @param {*} seconds
   * @memberof OreLucratePeZi2
   */
  ConvertToFriendlyTime = (seconds) => {
    const days = Math.floor(seconds / (8 * 3600));
    seconds = seconds % (8 * 3600);

    const hours = Math.floor(seconds / 3600);
    seconds = seconds % 3600;

    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;

    let output;

    if (days) {
      if (hours) {
        if (minutes) {
          output =
            (days > 1 ? days + "z " : days + " z") +
            ", " +
            (hours > 1 ? hours + "h " : hours + "h ") +
            " : " +
            (minutes > 1 ? minutes + "m " : minutes + "m ");
        } else {
          output =
            (days > 1 ? days + "z " : days + " z") +
            " : " +
            (hours > 1 ? hours + "h " : hours + "h ");
        }
      } else {
        if (minutes) {
          output =
            (days > 1 ? days + "z " : days + " z") +
            " : " +
            (minutes > 1 ? minutes + "m " : minutes + "m ");
        } else {
          output = days > 1 ? days + "z " : days + " z";
        }
      }
    } else {
      if (hours) {
        if (minutes) {
          output =
            (hours > 1 ? hours + "h " : hours + "h ") +
            " : " +
            (minutes > 1 ? minutes + "m " : minutes + "m ");
        } else {
          output = hours > 1 ? hours + "h " : hours + "h ";
        }
      } else {
        if (minutes) {
          output = minutes > 1 ? minutes + "m " : minutes + "m ";
        } else {
          output = "0";
        }
      }
    }

    return "" + output;
  }

  /**
   * @description filtraza datasource-ul datagridului in cazul in care se bifeaza ca se vor afisati si cei ascunsi/arhivati.
   * @function
   * @date 06/04/2022
   * @memberof OreLucratePeZi2
   */
  filtrare = () => {
    let baza1 = [];

    switch (this.state.checked) {
      case 2:
        baza1 = this.state.dataSourceInitial.filter(r => r.OreLucrate !== "0");
      break;

      case 3:
        baza1 = this.state.dataSourceInitial;
      break;

      case 1:
        baza1 = this.state.dataSourceInitial.filter(r => r.OreLucrate === "0");
      break;

      default:
        baza1 = [];
      break;
    }

    if (!this.state.checkedAscunsi) baza1 = baza1.filter(r => !r.IsHidden);
    this.setState({ dataSource: baza1 });
  }

  /**
   * @description Ia valorile necesare pentru randare de la endpointul this.$f7.data.baseURL +  this.state.linkApi +"/" +dataStart +"/" +dataEnd)” caruia i se transmit ca parametrii data de inceput si data de sfarsit(intervalul) din care sa ia proiectele/taskurile.
   * @function
   * @date 06/04/2022
   * @memberof OreLucratePeZi2
   */
  GetValues = () => {
    //'mm-dd-yy'
    const initialStartData = new Date(this.state.dataDeLa[0]);
    const initialEndData = new Date(this.state.dataPanaLa[0]);
    const dataStart = initialStartData.getDate() + "-" + (initialStartData.getMonth() + 1) + "-" + initialStartData.getFullYear().toString().substring(2);
    const dataEnd = initialEndData.getDate() + "-" + (initialEndData.getMonth() + 1) + "-" + initialEndData.getFullYear().toString().substring(2);

    this.setState({ dataLoaded: false });

    const isdCompare = new Date(initialStartData.getFullYear(), initialStartData.getMonth(), initialStartData.getDate());
    const iedCompare = new Date(initialEndData.getFullYear(), initialEndData.getMonth(), initialEndData.getDate());
    const link = `${this.$f7.data.baseURL}${this.state.linkApi}/${dataStart}/${dataEnd}/${(this.$f7.data.rol === 1 && this.state.checkedManager) ? this.$f7.data.user.iD_SALARIAT : null}`;
    // this.$f7.data.baseURL + this.state.linkApi + "/" + dataStart + "/" + dataEnd + "/" + ((this.$f7.data.rol === 1 && this.state.checkedManager) ? this.$f7.data.user.iD_SALARIAT : null)

    axios.get(link).then(response => {
      const change = { data: response.data.lista };

      change.data.forEach(item => {
        item["oreNelucrate"] = (isdCompare.getTime() === iedCompare.getTime()) ? ((28800 - item.oreLucrate) > 0 ? this.ConvertToFriendlyTime(28800 - item.oreLucrate) : 0) : "-";
        item.oreLucrate = this.ConvertToFriendlyTime(item.oreLucrate);
        item["A"] = item.nrTaskuriAlocate;
        item["R"] = item.nrTaskuriRezolvate;
        item["Locatie"] = item.statusLocatie;

        if (this.$f7.data.rol === 1 && this.state.checkedManager && item.idEveniment === null) {
          item["oreNelucrate"] = '';
          item.oreLucrate = '';
          item["A"] = '';
          item["R"] = '';
        }
      });

      // change.data.map((item, index) => {
      //   item["oreNelucrate"] = (isdCompare.getTime() === iedCompare.getTime()) ? ((28800 - item.oreLucrate) > 0 ? this.ConvertToFriendlyTime(28800 - item.oreLucrate) : 0) : "-";
      //   item.oreLucrate = this.ConvertToFriendlyTime(item.oreLucrate);
      //   item["A"] = item.nrTaskuriAlocate;
      //   item["R"] = item.nrTaskuriRezolvate;
      //   item["Locatie"] = item.statusLocatie;

      //   if (this.$f7.data.rol === 1 && this.state.checkedManager && item.idEveniment === null) {
      //     item["oreNelucrate"] = '';
      //     item.oreLucrate = '';
      //     item["A"] = '';
      //     item["R"] = '';
      //   }

      //   // switch (item.statusLocatie) {
      //   //     case 0: item["Locatie"] = "In firma"; break;
      //   //     case 1: item["Locatie"] = "Iesit din firma"; break;
      //   //     case 2: item["Locatie"] = "Acasa"; break;
      //   //     default: item["Locatie"] = "Nedefinit";
      //   // }
      //   return null;
      // });

      const reorder = (salariati, parinte) => {
        const output = [];
        const copii = salariati.filter(salariat => salariat.refSalariat === parinte.idSalariat);

        if (copii.length > 0) {
          output.push(parinte);

          copii.forEach(copil => {
            const res = reorder(salariati, copil);
            if (res.length > 0) res.forEach(r => { output.push(r) });
          });
        } else output.push(parinte);

        return output;
      }

      if (!this.state.checkedManager) {
        const parinti = change.data.filter(salariat => salariat.refSalariat === null);
        const final = [];

        parinti.forEach(parinte => {
          const res = reorder(change.data, parinte);
          res.forEach(item => { final.push(item) });
        });

        change.data = final;
      }

      let baza = this.$f7.methods.reconstructieBaza(change, this.state.inputs);

      if (!this.$f7.data.user.administrator === true || this.$f7.data.rol > 1) {
        const arr = [];

        if (this.$f7.data.rol < 3) this.$f7.data.SalariatiSubalterniColegi.forEach(r => { arr.push(r.value) }); //subalterni si colegi
        else this.$f7.data.SalariatiSubalterni.forEach(r => { arr.push(r.value) });                             //doar subalterni

        if (!this.state.checkedManager) baza = baza.filter(r => arr.includes(r.IdSalariat));
      }

      const baza2 = baza.filter(r => !r.IsHidden);

      this.setState({
        dataSource: baza2,
        dataSourceInitial: baza,
        responseData: response.data
      }, () => {
        setTimeout(() => {
          this.setState({ dataLoaded: true }, () => this.creareGrafic(null, null, baza2));
        }, 400);
      });
    }).catch(error => {
      console.log("error", error);

      this.setState({
        dataSource: [],
        dataSourceInitial: [],
        dataLoaded: true
      }, () => {
        this.$f7.dialog.confirm(
          "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
          "Ups...",
          () => this.GetValues(),
          () => {}
        );
      });
    });
  }

  componentDidMount = () => {
    const checkedManagerStored = localStorage.getItem("globalManagerState");

    this.setState({
      checkedManager: this.$f7.data.rol === 1 ? (checkedManagerStored !== null ? JSON.parse(checkedManagerStored) : true) : false
    }, () => {
      this.GetValues();
    });
  }

  /**
   * @description Deschide un popupDinamic caruia i se trimite componenta un view care randeaza pagina de concedii(link „concedii”) iar dataset-ul este filtrat sa fie afisate doar concediile persoanei selectate din grafic.
   * @function
   * @date 06/04/2022
   * @memberof OreLucratePeZi2
   */
  openConcedii = () => {
    this.$f7.views.main.router.navigate("/popupDinamic/", {
      props: {
        title: <p>Concedii</p>,
        disableClose: false,
        component: (
          <View
            id="concedii"
            name="concedii"
            url={"/concedii/"}
            removeElements={true}
            componentCache={false}
            onViewInit={() => {
              setTimeout(() => {
                const filtre = {};

                this.$f7.data.interfataConcedii.state.inputs.forEach(input => {
                  filtre[input.key] = {
                    denumire: input.key,
                    value: null,
                    comparatie: null
                  };
                });

                filtre["Salariat"].value = [this.state.informatiiPopover.idSalariat.toString()];
                filtre["Salariat"].comparatie = "arraycontains";

                this.$f7.data.interfataConcedii.refDataGrid.current.setState({ filtre: filtre });
              }, 350);
            }}
          />
        )
      }
    });
  }

  /**
   * @description Deschide un popupDinamic caruia i se trimite componenta un view care randeaza pagina de detalii task pentru taskul selectat din grafic.
   * @date 06/04/2022
   * @param {*} id id-ul proiectului
   * @param {*} pagina pagina de randat
   * @function
   * @memberof OreLucratePeZi2
   */
  openPopup = (id, pagina) => {
    const proiect = this.$f7.data.allProiecte.find(r => r.iD_PROIECT === id);

    if (proiect !== undefined) {
      this.$f7.data.Proiect = proiect;

      this.$f7.views.main.router.navigate("/popupDinamic/", {
        props: {
          disableClose: false,
          title: (
            <Link tooltip={this.state.informatiiPopover.proiect ? this.state.informatiiPopover.proiect.comentariu : ""}>
              {this.state.informatiiPopover.denumireTask}
            </Link>
          ),
          component: (
            <View
              id="oreLucratePeZiSheet"
              tabDeschis={"tab-Grid-TimpLucrat"}
              name="oreLucratePeZiSheet"
              url={"/agendaGridMainDreaptaActivitate/"}
              removeElements={true}
              componentCache={false}
              onViewInit={e => {
                this.$f7.data.routerDreapta = e.router;

                setTimeout(() => {
                  if (pagina !== undefined && pagina === 1) {
                    this.$f7.data.Grid.setState({
                      activeTab: `tab-Grid-Descriere${this.$f7.data.Grid.state.denumireTab}`,
                    });
                  }

                  if (pagina !== undefined && pagina === 2) {
                    this.$f7.data.Grid.setState({
                      activeTab: `tab-Grid-TimpLucrat${this.$f7.data.Grid.state.denumireTab}`,
                    });
                  }
                }, 350);
              }}
            />
          )
        }
      });
    } else {
      this.openConcedii();
    }
  }

  /**  
   * @description Deschide un popupAddEdit(un formular) pentru a trimite un mesaj persoanei caruia ii este atasat un task.
   * @date 06/04/2022
   * @param {*} realizare
   * @function
   * @memberof OreLucratePeZi2
   */
  openMesaj = (realizare) => {
    const inputsMesaj = [
      { key: "Subiect", keyBaza: "subiect", required: true, group: "Info" },
      { key: "Catre", keyBaza: "pt_cine", required: true, tip: "selectbox", lista: this.$f7.data.Salariati, defaultValue: realizare.iD_SALARIAT, disabled: true, group: "Info" },
      { key: "NumeCatre", keyBaza: "nume_pt_cine", disabled: true, group: "Info" },
      { key: "DeLa", keyBaza: "de_la_cine", required: true, tip: "selectbox", lista: this.$f7.data.Salariati, defaultValue: this.$f7.data.user.iD_SALARIAT, disabled: true, group: "Info" },
      { key: "NumeDeLa", keyBaza: "nume_de_la_cine", disabled: true, group: "Info" },
      { key: "Citit", keyBaza: "citit", required: true, tip: "bool", defaultValue: false, disabled: true, group: "Info" },
      { key: "Data", keyBaza: "data_sesizare", required: true, tip: "datebox", defaultValue: moment().format(), disabled: true, group: "Info" },
      { key: "Taskul", keyBaza: "ref_proiect", required: true, tip: "number", defaultValue: realizare.iD_PROIECT, disabled: true, group: "Info" },
      { key: "Mesaj", keyBaza: "comentariu", required: true, tip: "textarea", group: "Mesaj" }
    ];

    this.$f7.data.router.navigate("/popupAddEdit/", {
      props: {
        title: `Mesaj pentru taskul "${this.$f7.data.Proiecte.find(r => r.value === realizare.iD_PROIECT).key}"`,
        // onCloseClicked: (state) => { this.GetValues(); },
        inputs: inputsMesaj,
        ignoreInputs: ["DeLa", "NumeCatre", "NumeDeLa", "Data", "Taskul", "Citit"],
        buttons: [
          {
            key: "Salveaza",
            onSubmit: (state, den) => {
              this.$f7.panel.close(den);
              this.$f7.panel.close(den);

              let item = { ...state.editedItem };
              item.NumeCatre = this.$f7.data.Salariati.find(s => s.value === realizare.iD_SALARIAT).key;
              item.NumeDeLa = this.$f7.data.Salariati.find(s => s.value === this.$f7.data.user.iD_SALARIAT).key;
              item = this.$f7.methods.restructurareBaza(item, inputsMesaj, "keyBaza");

              axios({
                data: item,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: `${this.$f7.data.baseURL}${this.state.linkApiMesaje}/Postsesizari`
              }).then(() => {
                this.$f7.methods.openNotification(`Mesaj trimis catre ${this.$f7.data.Salariati.find(s => s.value === realizare.iD_SALARIAT).key}`);
              }).catch(error => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare");
              });
            }
          }
        ]
      }
    });
  }

  openNotite = (realizare) => {
    const inputsNotite = [
      { key: "ID", keyBaza: "iD_REALIZARE", required: true, tip: "number", defaultValue: realizare.iD_REALIZARE, disabled: true, group: "Info" },
      { key: "DataAdaugare", keyBaza: "datA_ADAUGARE_NOTITE", required: true, tip: "datebox", defaultValue: (realizare.datA_ADAUGARE_NOTITE ? moment(realizare.datA_ADAUGARE_NOTITE) : moment()).format('YYYY-MM-DDTHH:mm:ss'), disabled: true, group: "Info" },
      { key: "DataActualizare", keyBaza: "datA_ACTUALIZARE_NOTITE", required: true, tip: "datebox", defaultValue: (realizare.datA_ACTUALIZARE_NOTITE ? moment(realizare.datA_ACTUALIZARE_NOTITE) : moment()).format('YYYY-MM-DDTHH:mm:ss'), disabled: true, group: "Info" },
      { key: "Notite", keyBaza: "notitE_SUPERIOR", required: true, tip: "textarea", defaultValue: realizare.notitE_SUPERIOR ?? "", group: "Continut" }
    ];

    this.$f7.data.router.navigate("/popupAddEdit/", {
      props: {
        title: `Notite la taskul "${this.$f7.data.Proiecte.find(r => r.value === realizare.iD_PROIECT).key}" pentru intervalul cu id-ul ${realizare.iD_REALIZARE}`,
        inputs: inputsNotite,
        ignoreInputs: ["ID"],
        buttons: [
          {
            key: "Salveaza",
            onSubmit: (state, den) => {
              this.$f7.panel.close(den);
              this.$f7.panel.close(den);

              let item = { ...state.editedItem };
              item.DataActualizare = moment().format('YYYY-MM-DDTHH:mm:ss');
              item = this.$f7.methods.restructurareBaza(item, inputsNotite, "keyBaza");

              axios.get(`${this.$f7.data.baseURL}${this.state.linkApiRealizareSarcini}/GetREALIZARE_SARCINI/${item.iD_REALIZARE}`).then(response => {
                const data = { ...response.data, ...item }

                axios({
                  data: data,
                  headers: { "Content-Type": "application/json" },
                  method: "put",
                  url: `${this.$f7.data.baseURL}${this.state.linkApiRealizareSarcini}/PutREALIZARE_SARCINI/${data.iD_REALIZARE}`
                }).then(() => {
                  this.$f7.methods.openNotification(`Notite ${realizare.notitE_SUPERIOR ? "actualizate" : "adaugate"} la taskul "${this.$f7.data.Proiecte.find(r => r.value === data.iD_PROIECT).key}" pentru intervalul cu id-ul ${data.iD_REALIZARE}`);
                  this.GetValues();
                }).catch(error => {
                  console.log("error update", error);
                  this.$f7.methods.openNotification("Eroare");
                });
              }).catch(error => {
                console.log("error get", error);
                this.$f7.methods.openNotification("Eroare");
              })
            }
          }
        ]
      }
    });
  }

  /**
   * @description transforma intr-un string frumos de citit „z : m :s” niste milisecunde primite ca parametrii.
   * @date 06/04/2022
   * @function
   * @param {*} ms milisecunde
   * @memberof OreLucratePeZi2
   */
  dhm = (ms) => {
    const days = Math.floor(ms / (8 * 60 * 60 * 1000));
    const daysms = ms % (8 * 60 * 60 * 1000);
    const hours = Math.floor(daysms / (60 * 60 * 1000));
    const hoursms = ms % (60 * 60 * 1000);
    const minutes = Math.floor(hoursms / (60 * 1000));
    const minutesms = ms % (60 * 1000);
    const sec = Math.floor(minutesms / 1000);
    return `${days}z ${hours}h ${minutes}m ${sec}s `;
  }
  
  /**
   * @description Creeaza dataset-ul pentru graficul din dreapta, pune culori random fiecarui element si il hashureaza daca este finalizat
   * @date 06/04/2022
   * @function
   * @param {*} state state-ul datagridului
   * @param {*} r contextul datagridului
   * @param {*} itemeRandate itemele randate de datagrid
   * @memberof OreLucratePeZi2
   */
  creareGrafic = (state, r, itemeRandate) => {
    const responseData = this.state.responseData;

    if (responseData) {
      const listaTimpLucratTotal = responseData.listaTimpLucratTotal;
      const listaTimpLucratTotalSalariati = responseData.listaTimpLucratTotalSalariati;
      const groups = [];
      const items = [];

      itemeRandate.forEach(d => {
        groups.push({
          id: d.IdSalariat,
          title: d.Nume
        });

        const listaLoginuri = responseData.listaLoginuri;

        listaLoginuri.forEach(x => {
          if (x.dataIesire === null) x.dataIesire = moment().format();
        });

        d.Lista.forEach(realizare => {
          const login = listaLoginuri.find(x => {
            let iesireLocal = realizare.iesire;
            if (iesireLocal === null) iesireLocal = moment().format();
            return (realizare.intrare >= x.dataIntrare && iesireLocal >= x.dataIesire && x.idSalariat === d.IdSalariat);
          });

          let ip = "1.2.3.4";
          if (login) ip = login.ip;

          let numeClient = "";
          const client = this.$f7.data.Firme.find(r => r.value === realizare.iD_CLIENT);
          if (client) numeClient = client.key;

          let iesire = realizare.iesire;
          let manual = realizare.manual;
          var manualAprobat = realizare.aprobat;

          if (realizare.iesire === null) {
            manual = false;
            iesire = moment().format();
          }

          const rand = Math.random() * 360;
          let colorString = manual === true ? (manualAprobat === true ? `hsl(${rand}, 0%, 60%)` : "black") : `hsl(${rand}, 100%, 60%)`;
          const colorString2 = manual === true ? (manualAprobat === true ? `hsl(${rand}, 10%, 40%)` : "black") : `hsl(${rand}, 100%, 40%)`;
          const miliseconds = new Date(iesire).getTime() - new Date(realizare.intrare).getTime();
          const durata = this.dhm(miliseconds);
          const tipEvenimentSalariati = this.$f7.data.TipEvenimentSalariati.find(r => r.value === parseInt(realizare.comentariu))
          const proiect = this.$f7.data.Proiecte.find(r => r.value === realizare.iD_PROIECT)
          const task = realizare.unitati === 999999 ? (tipEvenimentSalariati !== undefined ? tipEvenimentSalariati.key : "") : (proiect !== undefined ? proiect.key : "");
          let ang = "45deg";

          if (realizare.unitati === 999999) {
            const colors = [
              { key: "purple", value: -1 },   //Mai multe
              { key: "#e6cb0d", value: 1 },   //CO - #e69705
              { key: "green", value: 4 },     //Liber
              { key: "blue", value: 2 },      //CM
              { key: "black", value: 1000 },  //Normal
              { key: "red", value: 1001 }     //Nu a lucrat
            ];

            const testCuloare = colors.find(r => r.value === parseInt(realizare.comentariu));

            if (testCuloare !== undefined) {
              colorString = testCuloare.key;
              ang = "135deg";
            }
          }
          
          const color = `repeating-linear-gradient(${ang}, ${colorString}, ${colorString} 8px, ${colorString2} 0px, ${colorString2} 15px)`;
          const colorFinal = realizare.iesire === null ? colorString : color;

          items.push({
            id: realizare.iD_PROIECT !== null ? realizare.iD_REALIZARE : Math.random(),
            group: d.IdSalariat,
            start_time: moment(realizare.intrare),
            end_time: moment(iesire),
            title: "",
            canMove: false,
            canResize: false,
            itemProps: {
              className: `timelineItem ${realizare.notitE_SUPERIOR ? "withNote" : ""}`,
              style: { background: colorFinal, border: `1px solid ${colorString}` },
              onMouseDown: e => {
                //seteaza informatiile de vor fi afisate in popup-ul cu informatii la click pe item
                const t = e.currentTarget;
                t.style.background = "#000000";

                const pro = this.$f7.data.allProiecte.find(r => r.iD_PROIECT === realizare.iD_PROIECT);
                let deadlineProiect = "";
                if (pro) deadlineProiect = pro.deadline;

                let timpLucratTotal = "";
                let timpLucratTotalSalariat = "";

                const timpTotal0 = listaTimpLucratTotal.find(r => r.idProiect === realizare.iD_PROIECT);
                if (timpTotal0) timpLucratTotal = this.ConvertToFriendlyTime(timpTotal0.timpLucrat);

                const timpTotal1 = listaTimpLucratTotalSalariati.find(r => r.idProiect === realizare.iD_PROIECT && r.idSalariat === realizare.iD_SALARIAT);
                if (timpTotal1) timpLucratTotalSalariat = this.ConvertToFriendlyTime(timpTotal1.timpLucrat);

                const idSalariatRand = d.IdSalariat;
                const sal = { IdSalariat: idSalariatRand };
                this.refDatagrid.current.focusItem(sal, "IdSalariat");

                const lista = document.getElementsByClassName("rct-hl");
                for (let i = 0; i < lista.length; i++) {
                  const timelineItem = lista.item(i);

                  if (timelineItem.dataset.groupid === idSalariatRand.toString()) {
                    timelineItem.style.background = "var(--f7-theme-color-tint)";
                    timelineItem.style.filter = "brightness(1.3)";
                  } else {
                    if (timelineItem.style.background !== "lightgray") {
                      timelineItem.style.background = "lightgray";
                      timelineItem.style.filter = "unset";
                    }
                  }
                }

                const informatiiPopover = {
                  idSalariat: idSalariatRand,
                  numeClient: numeClient,
                  numeSalariat: d.Nume,
                  deadline: deadlineProiect,
                  inceput: realizare.intrare,
                  sfarsit: iesire,
                  durata: durata,
                  timpTotalSalariat: timpLucratTotalSalariat,
                  timpTotalTask: timpLucratTotal,
                  comentariu: realizare.comentariu,
                  observatie: realizare.comentariU1,
                  pornit: realizare.iesire === null ? 1 : 0,
                  realizare: realizare,
                  denumireTask: task,
                  proiect: pro,
                  ip: ip,
                  intervalEv: realizare.iD_REALIZARE === 0 ? `${moment(realizare.ultima_activitate).format("DD.MM.YYYY")} - ${moment(realizare.iesire_user).format("DD.MM.YYYY")}` : ""
                };

                this.setState({
                  informatiiPopover: informatiiPopover,
                  targetPopover: t,
                  oldColor: colorFinal
                });

                const popoverDinamicOreLucrate = this.$f7.popover.get(".popoverDinamicOreLucrate");
                popoverDinamicOreLucrate.open(t);
              }
            }
          });
        });
      });

      this.setState({ groups: groups, items: items });
    }
  }

  /**
   * @description indenteaza textul din datagrid in cazul in care are parinte elementul curent.
   * @date 06/04/2022
   * @function
   * @param {*} salariati salariatii aplicatiei
   * @param {*} item itemul curent
   * @param {*} count numarul de indentari
   * @memberof OreLucratePeZi2
   */
  indent = (salariati, item, count) => {
    const parinte = salariati.find(salariat => salariat.IdSalariat === item.RefSalariat);

    if (parinte) return this.indent(salariati, parinte, count + 1);
    else return count;
  }

  /**
   * @description aplica elementelor din datagrid stilizarea corespunzatoare din array-ul colors.
   * @date 06/04/2022
   * @function
   * @param {*} state stateul datagridului
   * @param {*} r contextul datagridului
   * @param {*} itemeRandate itemele randate de datagrid
   * @memberof OreLucratePeZi2
   */
  colorItems = (state, r, itemeRandate) => {
    const tableName = r.tableName;
    const colors = [
      { key: "purple", value: -1 },   //Mai multe
      { key: "#e6cb0d", value: 1 },   //CO - #e69705
      { key: "green", value: 4 },     //Liber
      { key: "blue", value: 2 },      //CM
      { key: "teal", value: 12 },     //CC
      { key: "black", value: 1000 },  //Normal
      { key: "red", value: 1001 }     //Nu a lucrat
    ];

    itemeRandate.forEach((elem, index) => {
      let taskActiv = false;
      let color = "black";
      let background = null;

      if (elem.Lista.length > 0) {
        elem.Lista.forEach(element => {
          if (element.iesire === null) taskActiv = true;
        });
      }

      const feColor = colors.find(fe => fe.value === elem.IdEveniment);
      if (feColor !== undefined) color = feColor.key;
      else if (elem.OreLucrate === "0") color = "red";

      switch (elem.Locatie) {
        case 0:
          background = taskActiv
            ? "linear-gradient(90deg, rgba(0, 255, 0, 0.15) 0, rgba(0, 255, 0, 0.15) 10px, rgba(255, 255, 255, 1) 10px, rgba(255, 255, 255, 1) 20px), linear-gradient(90deg, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 1) 10px, rgba(0, 255, 0, 0.15) 10px, rgba(0, 255, 0, 0.15) 20px)"
            : "rgba(0, 255, 0, 0.15)";
        break;

        case 1:
          background = taskActiv
            ? "linear-gradient(90deg, rgba(255, 0, 0, 0.15) 0, rgba(255, 0, 0, 0.15) 10px, rgba(255, 255, 255, 1) 10px, rgba(255, 255, 255, 1) 20px), linear-gradient(90deg, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 1) 10px, rgba(255, 0, 0, 0.15) 10px, rgba(255, 0, 0, 0.15) 20px)"
            : "rgba(255, 0, 0, 0.15)";
        break;

        case 2:
          background = taskActiv
            ? "linear-gradient(90deg, rgba(0, 128, 128, 0.15) 0, rgba(0, 128, 128, 0.15) 10px, rgba(255, 255, 255, 1) 10px, rgba(255, 255, 255, 1) 20px), linear-gradient(90deg, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 1) 10px, rgba(0, 128, 128, 0.15) 10px, rgba(0, 128, 128, 0.15) 20px)"
            : "rgba(0, 128, 128, 0.15)";
        break;

        default:
          background = null;
      }

      const elemById = document.getElementById(`trMain${tableName}${index}`);
      if (elemById) {
        elemById.style.color = color;
        elemById.style.backgroundSize = "20px 10px, 20px 10px";
        elemById.style.backgroundPosition = "0 0, 0 10px";
        elemById.style.backgroundRepeat = "repeat-x";
        elemById.style.fontWeight = "bold";

        if (background !== undefined && background !== null) {
          elemById.style.backgroundImage = background;
          elemById.style.backgroundColor = background;
        }

        if (elem.IsHidden) elemById.classList.add("ascuns");
        else {
          if (elemById.classList.contains("ascuns")) elemById.classList.remove("ascuns");
        }
      }

      const numeTd = elemById.getElementsByClassName("Nume")[0];
      if (numeTd) {
        const value = this.indent(this.state.dataSourceInitial, elem, 0); // itemeRandate, elem, 0
        numeTd.style.textIndent = `${value * 15}px`;
      }
    });
  }

  /**
   * @description Adauga clasa testColoana tuturor coloanelor.
   * @date 06/04/2022
   * @function
   * @param {*} timeStart
   * @param {*} timeEnd
   * @memberof OreLucratePeZi2
   */
  verticalLineClassNamesForTime = (timeStart, timeEnd) => {
    //const currentTimeStart = moment(timeStart)
    //const currentTimeEnd = moment(timeEnd)

    const classes = [];
    classes.push("testColoana");
    return classes;
  }

  render() {
    return (
      <div style={{ position: "relative" }}>
        <Popover
          className="popoverDinamicOreLucrate"
          style={{ width: 'fit-content', maxWidth: 350, padding: 16, boxSizing: 'border-box' }}
          onPopoverClose={() => {
            const target = this.state.targetPopover;
            target.style.background = this.state.oldColor;
            this.setState({ targetPopover: target });
          }}
        >
          {this.state.informatiiPopover !== null && (
            <>
              <Link
                popoverClose
                tooltip={this.state.informatiiPopover.proiect ? this.state.informatiiPopover.proiect.comentariu : ""}
                style={{ fontSize: "small", width: "100%", margin: "6px 0px" }}
                onClick={() => this.openPopup(this.state.informatiiPopover.realizare.iD_PROIECT, 1)}
              >
                {this.state.informatiiPopover.denumireTask}
              </Link>
              
              <p>
                <span className={"spanOreLucreate"}>Client:</span>
                {this.state.informatiiPopover.numeClient}
              </p>

              <p>
                <span className={"spanOreLucreate"}>Angajat:</span>
                {this.state.informatiiPopover.numeSalariat}
              </p>

              <p>
                <span className={"spanOreLucreate"}>Deadline:</span>
                {moment(this.state.informatiiPopover.deadline).format("'YY-MM-DD HH:mm")}
              </p>

              <p>
                <span className={"spanOreLucreate"}>Inceput:</span>
                {moment(this.state.informatiiPopover.inceput).format("'YY-MM-DD HH:mm")}
              </p>

              <p>
                <span className={"spanOreLucreate"}>Sfarsit:</span>
                {moment(this.state.informatiiPopover.sfarsit).format("'YY-MM-DD HH:mm")}
              </p>

              <p>
                <span className={"spanOreLucreate"}>Durata:</span>
                {this.state.informatiiPopover.durata}
              </p>

              <p>
                <span className={"spanOreLucreate"}>Timp total salariat:</span>
                {this.state.informatiiPopover.timpTotalSalariat}
              </p>

              <p>
                <span className={"spanOreLucreate"}>Timp total task:</span>
                {this.state.informatiiPopover.timpTotalTask}
              </p>

              <p>
                <span className={"spanOreLucreate"}>Comentariu:</span>
                {this.state.informatiiPopover.comentariu}
              </p>

              <p>
                <span className={"spanOreLucreate"}>Observatie:</span>
                {this.state.informatiiPopover.observatie}
              </p>

              <p>
                <span className={"spanOreLucreate"}>IP:</span>
                {this.state.informatiiPopover.ip}
              </p>

              {this.state.informatiiPopover.intervalEv !== "" && (
                <p>
                  <span className={"spanOreLucreate"}>Interval:</span>
                  {this.state.informatiiPopover.intervalEv}
                </p>
              )}

              {[2, 95].includes(this.$f7.data.user.iD_SALARIAT) && this.state.informatiiPopover.realizare.notitE_SUPERIOR && (
                <div>
                  <hr />

                  <p>
                    <span className={"spanOreLucreate"}>Notite:</span>
                    {this.state.informatiiPopover.realizare.notitE_SUPERIOR}
                  </p>

                  <p>
                    <span className={"spanOreLucreate"}>Data adaugare:</span>
                    {moment(this.state.informatiiPopover.realizare.datA_ADAUGARE_NOTITE).format("'YY-MM-DD HH:mm")}
                  </p>

                  <p>
                    <span className={"spanOreLucreate"}>Data actualizare:</span>
                    {moment(this.state.informatiiPopover.realizare.datA_ACTUALIZARE_NOTITE).format("'YY-MM-DD HH:mm")}
                  </p>
                
                  <hr />
                </div>
              )}

              {this.state.informatiiPopover.proiect && (
                <>
                  <Link
                    popoverClose
                    tooltip={"Cere detalii"}
                    style={{ fontSize: "small", width: "100%", margin: "6px 0px" }}
                    onClick={() => this.openMesaj(this.state.informatiiPopover.realizare)}
                  >
                    Cere detalii
                  </Link>

                  <Link
                    popoverClose
                    tooltip={"Activitate task"}
                    style={{ fontSize: "small", width: "100%", margin: "6px 0px" }}
                    onClick={() => this.openPopup(this.state.informatiiPopover.realizare.iD_PROIECT, 2)}
                  >
                    Activitate task
                  </Link>
                </>
              )}

              {this.state.informatiiPopover.pornit === 1 && (
                <Link
                  popoverClose
                  tooltip={"Oprire task"}
                  style={{ fontSize: "small", width: "100%", margin: "6px 0px" }}
                  onClick={() => {
                    this.$f7.data.idSalariatFunctii = this.state.informatiiPopover.realizare.iD_SALARIAT;
                    this.$f7.methods.Sfarsit(false, null, false, false, false, this.state.informatiiPopover.realizare.iD_PROIECT).then(() => {
                      this.$f7.data.idSalariatFunctii = this.$f7.data.user.iD_SALARIAT;
                      this.GetValues();
                    }).catch(err => {
                      this.$f7.methods.openNotification("Eroare" + err);
                    });
                  }}
                >
                  Oprire task
                </Link>
              )}

              {[2, 95].includes(this.$f7.data.user.iD_SALARIAT) && (
                <Link
                  popoverClose
                  tooltip={this.state.informatiiPopover.realizare.notitE_SUPERIOR ? "Vizualizeaza / Actualizeaza notite" : "Adauga notite"}
                  style={{ fontSize: "small", width: "100%", margin: "6px 0px" }}
                  onClick={() => this.openNotite(this.state.informatiiPopover.realizare)}
                >
                  {this.state.informatiiPopover.realizare.notitE_SUPERIOR ? "Vizualizeaza / Actualizeaza notite" : "Adauga notite"}
                </Link>
              )}
            </>
          )}
        </Popover>

        <List noChevron noHairlines noHairlinesBetween noHairlinesBetweenMd noHairlinesMd style={{ left: 0, margin: 0 }}>
          <Row style={{ width: "100%" }}>
            <Col width={10}>
              <ListItem
                title={this.state.checked === 1 ? "Absenti" : (this.state.checked === 2 ? "Prezenti" : "Toti")}
                key={"OreLucratePeZi1"}
                checkbox
                floatingLabel
                checked={this.state.checked === 3}
                indeterminate={this.state.checked === 2}
                onChange={() => {
                  let ch = 0;

                  switch (this.state.checked) {
                    case 1:
                      ch = 2;
                    break;

                    case 2:
                      ch = 3;
                    break;

                    case 3:
                      ch = 1;
                    break;

                    default:
                    break;
                  }

                  this.setState({
                    checked: ch
                  }, () => {
                    this.filtrare();

                    setTimeout(() => {
                      this.refDatagrid.current.setRenderItems(this.state.dataSource);
                    }, 500);
                  });
                }}
              />
            </Col>

            <Col width={10}>
              <ListItem
                title={"Ascunsi"}
                key={"OreLucratePeZi2"}
                checkbox
                floatingLabel
                checked={this.state.checkedAscunsi}
                onChange={() => {
                  this.setState({
                    checkedAscunsi: !this.state.checkedAscunsi
                  }, () => {
                    this.filtrare();

                    setTimeout(() => {
                      this.refDatagrid.current.setRenderItems(this.state.dataSource);
                    }, 500);
                  });
                }}
              />
            </Col>

            <Col width={20}>
              <ListInput
                key={"OreLucratePeZi3"}
                label="Interval"
                type="datepicker"
                value={[this.state.dataDeLa[0], this.state.dataPanaLa[0]]}
                floatingLabel
                placeholder="De la"
                calendarParams={{
                  rangePicker: true,
                  dateFormat: "dd.mm.yyyy",
                  closeOnSelect: true,
                  on: {
                    closed: e => {
                      const val = e.value;
                      if (val.length < 2) val.push(val[0]);

                      this.setState({
                        dataDeLa: [val[0]],
                        dataPanaLa: [val[1]]
                      }, () => {
                        this.GetValues();
                      });
                    }
                  }
                }}
              >
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="today"
                  slot="content-start"
                  style={{ left: 0, top: 0 }}
                />
              </ListInput>
            </Col>

            {this.$f7.data.rol === 1 && (
              <Col width={10}>
                <ListItem
                  title={"Manager"}
                  key={"OreLucratePeZi10"}
                  checkbox
                  floatingLabel
                  checked={this.state.checkedManager}
                  onChange={() => {
                    this.setState({
                      checkedManager: !this.state.checkedManager
                    }, () => {
                      localStorage.setItem("globalManagerState", JSON.stringify(this.state.checkedManager));
                      
                      setTimeout(() => {
                        this.GetValues();
                      }, 500);
                    });
                  }}
                />
              </Col>
            )}

            <Col width={this.$f7.data.rol > 1 ? 60 : 50}>
              <div style={{ marginTop: 8 }}>
                {this.state.colorsExplained.map((elem, index) => (
                  <Chip
                    key={`chipOreLucratePeZi2${index}`}
                    style={{ backgroundColor: elem.key }}
                    textColor="white"
                    text={elem.value}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </List>

        <Row noGap>
          <Col width={25}>
            <DataGrid
              id={"IdSalariat"}
              name={"OreLucratePeZi2"}
              refresh={this.GetValues}
              dataLoaded={this.state.dataLoaded}
              style={{ marginTop: 15 }}
              ref={this.refDatagrid}
              onInitialised={(state, r, itemeRandate) => this.colorItems(state, r, itemeRandate)}
              keys={["Nume", "OreLucrate", "A", "R", "OreNelucrate"]}
              maxHeight={this.state.height}
              dataSource={this.state.dataSource}
              inputs={this.state.inputs}
              count={10000}
              onRenderItemsChanged={(state, r, itemeRandate) => {
                this.creareGrafic(state, r, itemeRandate);
                this.colorItems(state, r, itemeRandate);
              }}
              contextMenuItems={(stateM, context) => {
                return [
                  {
                    key: "Grafic detaliat",
                    onClick: (state) => {
                      this.$f7.data.idSalariatFunctii = state.rowData.IdSalariat;
                      this.$f7.data.router.navigate("/popupDinamic/", {
                        props: {
                          title: "Grafic detaliat",
                          onPopupClose: () => {
                            this.$f7.data.idSalariatFunctii = this.$f7.data.user.iD_SALARIAT;
                          },
                          component: (
                            <div>
                              <GraficSalariat
                                dataFiltrareStart={this.state.dataDeLa[0]}
                                dataFiltrareEnd={this.state.dataPanaLa[0]}
                                noSticky={true}
                                tipAfisare={"Grafic"}
                              />
                            </div>
                          )
                        }
                      });
                    }
                  },
                  {
                    key: "Tabel detaliat",
                    onClick: (state) => {
                      this.$f7.data.idSalariatFunctii = state.rowData.IdSalariat;
                      this.$f7.data.router.navigate("/popupDinamic/", {
                        props: {
                          title: "Tabel detaliat",
                          onPopupClose: () => {
                            this.$f7.data.idSalariatFunctii = this.$f7.data.user.iD_SALARIAT;
                          },
                          component: (
                            <div>
                              <GraficSalariat
                                dataFiltrareStart={this.state.dataDeLa[0]}
                                dataFiltrareEnd={this.state.dataPanaLa[0]}
                                noSticky={true}
                                tipAfisare={"Tabel"}
                              />
                            </div>
                          )
                        }
                      });
                    }
                  },

                  this.$f7.data.user.iD_SALARIAT !== stateM.rowData.IdSalariat
                    ? {
                        key: "Mesaj",
                        icon: "mail_outline",
                        onClick: (state) => {
                          const idSalariat = state.rowData.IdSalariat;
                          const panelMesaje = this.$f7.panel.get("." + this.$f7.data.denumireMesaje);
                          const mp = this.$f7.data.messagesPopover;

                          if (panelMesaje && mp) {
                            if (mp.refMesaje) {
                              mp.refMesaje.current.setState({
                                utilizatorSelectat: idSalariat,
                                idCreator: null,
                                mesaj: ""
                              }, () => {
                                mp.refMesaje.current.GetValuesDreapta();
                                panelMesaje.open();
                              });
                            }
                          }
                        }
                      }
                    : null,

                  this.$f7.data.rol < 2
                    ? {
                        key: "Salariati",
                        icon: "info",
                        onClick: (state) => {
                          this.$f7.data.router.navigate("/popupDinamic/", {
                            props: {
                              onPopupClose: () => {},
                              title: "Detalii salariati",
                              component: <SalariatiModule />
                            }
                          });
                        }
                      }
                    : null
                ];
              }}
              onCellClick={(state) => {
                if (this.$f7.data.rol < 2) {
                  // pentru taskurile alocate
                  if (state.columnName === this.state.inputs[7].key) {
                    this.$f7.data.idSalariatFunctii = state.rowDataAll.IdSalariat;
                    this.$f7.data.Proiect = null;
                    this.$f7.data.router.navigate("/popupDinamic/", {
                      props: {
                        onPopupClose: () => {
                          this.$f7.data.idSalariatFunctii = this.$f7.data.user.iD_SALARIAT;
                        },
                        title: "Alocate (" + state.rowDataAll.Nume + ")",
                        component: <OreLucrateListaSarcini tip={"alocate"} />
                      }
                    });
                  }

                  // pentru taskurile rezolvate
                  if (state.columnName === this.state.inputs[8].key) {
                    this.$f7.data.idSalariatFunctii = state.rowDataAll.IdSalariat;
                    this.$f7.data.router.navigate("/popupDinamic/", {
                      props: {
                        onPopupClose: () => {
                          this.$f7.data.idSalariatFunctii = this.$f7.data.user.iD_SALARIAT;
                        },
                        title: "Rezolvate (" + state.rowDataAll.Nume + ")",
                        component: <OreLucrateListaSarcini tip={"rezolvate"} />
                      }
                    });
                  }
                }
              }}
              onRowClick={(state) => {
                const idSalariatRand = state.rowDataAll.IdSalariat;
                const lista = document.getElementsByClassName("rct-hl");

                for (let i = 0; i < lista.length; i++) {
                  const timelineItem = lista.item(i);

                  if (timelineItem.dataset.groupid === idSalariatRand.toString()) {
                    timelineItem.style.background = "var(--f7-theme-color-tint)";
                    timelineItem.style.filter = "brightness(1.3)";
                  } else {
                    if (timelineItem.style.background !== "lightgray") {
                      timelineItem.style.background = "lightgray";
                      timelineItem.style.filter = "unset";
                    }
                  }
                }
              }}
              onContextMenu={(state, target) => {
                const idSalariatRand = state.rowDataAll.IdSalariat;
                const lista = document.getElementsByClassName("rct-hl");

                for (let i = 0; i < lista.length; i++) {
                  const timelineItem = lista.item(i);

                  if (timelineItem.dataset.groupid === idSalariatRand.toString()) {
                    timelineItem.style.background = "var(--f7-theme-color-tint)";
                    timelineItem.style.filter = "brightness(1.3)";
                  } else {
                    if (timelineItem.style.background !== "lightgray") {
                      timelineItem.style.background = "lightgray";
                      timelineItem.style.filter = "unset";
                    }
                  }
                }
              }}
            />
          </Col>

          <Col width={75}>
            {this.state.dataLoaded ? (
              <Timeline
                hideHorizontalLines={false}
                verticalLineClassNamesForTime={this.verticalLineClassNamesForTime}
                ref={this.timelineRef}
                lineHeight={20}
                sidebarWidth={0}
                groups={this.state.groups}
                items={this.state.items}
                defaultTimeStart={moment(this.state.dataDeLa[0]).startOf("day")}
                defaultTimeEnd={moment(this.state.dataPanaLa[0]).endOf("day")}
                onCanvasClick={(groupId, time, e) => {
                  const idSalariatRand = groupId;
                  const sal = { IdSalariat: idSalariatRand };
                  this.refDatagrid.current.focusItem(sal, "IdSalariat");

                  const lista = document.getElementsByClassName("rct-hl");

                  for (let i = 0; i < lista.length; i++) {
                    const timelineItem = lista.item(i);

                    if (timelineItem.dataset.groupid === idSalariatRand.toString()) {
                      timelineItem.style.background = "var(--f7-theme-color-tint)";
                      timelineItem.style.filter = "brightness(1.3)";
                    } else {
                      if (timelineItem.style.background !== "lightgray") {
                        timelineItem.style.background = "lightgray";
                        timelineItem.style.filter = "unset";
                      }
                    }
                  }
                }}
              >
                <TimelineHeaders
                  className={"A"}
                  style={{ height: 70, position: "sticky", top: 0, zIndex: 100 }}
                >
                  <SidebarHeader className={"B"}>
                    {({ getRootProps }) => {
                      return <div className={"D"} {...getRootProps()} />
                    }}
                  </SidebarHeader>

                  <DateHeader className={"C"} unit="primaryHeader" />
                  <DateHeader />
                </TimelineHeaders>
              </Timeline>
            ) : (
              <Preloader />
            )}
          </Col>
        </Row>
      </div>
    )
  }
}

export default OreLucratePeZi2;
