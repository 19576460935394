import React, { Component } from "react";
import DataGrid from "../../Datagrid/DataGrid";
import * as inputs from "../../../../inputs";
import axios from "axios";

 /**
  * @description Afiseaza un datagrid cu informatiile faza si costTotal.
  * @date 06/04/2022
  * @class CheltuieliFaze
  * @extends {Component}
  */
 class CheltuieliFaze extends Component {
   constructor(props) {
     super(props);

     this.height = window.innerHeight;

     this.state = {
       dataLoaded: false,
       height: window.innerHeight - 46,
       linkApi: "/api/CheltuieliFaze",
       dataSource: [],
     };

     //this.refDataGrid = React.createRef()
   }
   /**
    * @description Ia valorile necesare pentru randare de la endpointul „/api/CheltuieliFaze/GetCheltuieliFaze”
    * @function
    * @date 06/04/2022
    * @memberof CheltuieliFaze
    */
   GetValues = () => {
     this.setState({
       dataLoaded: false,
     });

     axios
       .get(this.$f7.data.baseURL + this.state.linkApi)
       .then((response) => {
         let change = response;

         for (let item in change.data) {
           change.data[item]["Faza"] =
             change.data[item].denumire === "" ||
             change.data[item].denumire === null
               ? "Fara"
               : change.data[item].denumire;
           change.data[item]["CostTotal"] =
             change.data[item].cost === "" || change.data[item].cost === null
               ? "Fara"
               : change.data[item].cost;
         }

         let baza = this.$f7.methods.reconstructieBaza(
           change,
           inputs.cheltuieliFaze
         );

         this.setState(
           {
             dataSource: baza.length === 0 ? [] : baza,
           },
           () => {
             setTimeout(() => {
               this.setState(
                 {
                   dataLoaded: true,
                 },
                 () => {
                   //this.refDataGrid.current.setRenderItems(baza)
                 }
               );
             }, 400);
           }
         );
       })
       .catch((error) => {
         this.setState(
           {
             dataSource: [],
             dataLoaded: true,
           },
           () => {
             this.$f7.dialog.confirm(
               "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
               "Ups...",
               () => {
                 this.GetValues();
               },
               () => {}
             );
           }
         );

         console.log("error", error);
       });
   };

   componentDidMount = () => {
     this.GetValues();
   };

   render() {
     return (
       <DataGrid
         name={"CheltuieliFaze"}
         dataLoaded={this.state.dataLoaded}
         refresh={this.GetValues}
         //ref={this.refDataGrid}
         renderUpdate={false}
         renderDelete={false}
         renderAdd={false}
         maxHeight={this.state.height}
         dataSource={this.state.dataSource}
         inputs={inputs.cheltuieliFaze}
       />
     );
   }
 }
export default CheltuieliFaze;