import React from "react";
import { Page, Segmented, Button } from "framework7-react";
import DataGrid from "../Datagrid/DataGrid";
import axios from "axios";
import moment from "moment";
import DelegatiiDetalii from "./DelegatiiDetalii";

/**
 * @description Interfata de intretienre/afisare a delegatiilor
 * @date 08/04/2022
 * @class Delegatii
 * @extends {React.Component}
 */
class Delegatii extends React.Component {
  constructor(props) {
    super(props);
    var ignoreInputsAdd = [];
    if (!this.$f7.data.user.administrator) {
      ignoreInputsAdd.push("Salariat");
    }
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.listaClienti = [...this.$f7.data.Firme];
    this.listaClienti.shift();

    var inpPlecare = this.$f7.data.user.grupuri.includes(12)
      ? [
          { key: "Id", keyBaza: "Id", tip: "number" },
          {
            key: "Salariat",
            keyBaza: "Sal",
            tip: "selectbox",
            lista: this.Salariati,
          },
          { key: "NumarDelegatie", keyBaza: "iD_DELEGATIE" },
          {
            key: "Destinatie",
            keyBaza: "destinatie",
            tip: "selectbox",
            lista: this.$f7.data.Firme,
          },
          {
            key: "Sofer",
            keyBaza: "sofer",
            tip: "selectbox",
            lista: [
              { key: "False", value: false },
              { key: "True", value: true },
            ],
          },
          { key: "KmPlecare", keyBaza: "km_plecare", tip: "number" },
          {
            key: "Masina",
            keyBaza: "id_utilaje",
            tip: "selectbox",
            lista: this.$f7.data.Utilaje,
          },
          {
            key: "MomentPlecare",
            keyBaza: "datA_DEL",
            tip: "datebox",
          },
        ]
      : [
          { key: "Id", keyBaza: "Id", tip: "number" },
          { key: "NumarDelegatie", keyBaza: "iD_DELEGATIE" },
          {
            key: "Destinatie",
            keyBaza: "destinatie",
            tip: "selectbox",
            lista: this.$f7.data.Firme,
          },
          {
            key: "Sofer",
            keyBaza: "sofer",
            tip: "selectbox",
            lista: [
              { key: "False", value: false },
              { key: "True", value: true },
            ],
          },
          { key: "KmPlecare", keyBaza: "km_plecare", tip: "number" },
          {
            key: "Masina",
            keyBaza: "id_utilaje",
            tip: "selectbox",
            lista: this.$f7.data.Utilaje,
          },
          {
            key: "MomentPlecare",
            keyBaza: "datA_DEL",
            tip: "datebox",
          },
        ];

    this.state = {
      linkApiRealizari: "/api/RealizareSarcini",
      inputsInchidere: [
        { key: "Unitati", tip: "number" },
        { key: "Operatie", tip: "selectbox", lista: this.$f7.data.Operatiuni },
        { key: "Stadiu", tip: "selectbox", lista: this.$f7.data.Stadiu },
        { key: "Comentariu" },
        { key: "ComentariuPlus" },
      ],

      inputsPlecare: inpPlecare,
      inputsAjunsAcasa: [
        { key: "Id", keyBaza: "Id", tip: "number" },
        { key: "KmSosire", keyBaza: "km_sosire", tip: "number" },
        { key: "Benzina", keyBaza: "benzina", tip: "number" },
        { key: "BenzinaOras", keyBaza: "BenzinaOras", tip: "number" },
      ],
      inputsPlecareDetaliu: [
        { key: "Id", keyBaza: "Id", tip: "number" },
        {
          key: "Destinatie",
          keyBaza: "destinatie",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
        },
      ],
      status: 0, //0 -toate delegatiile inchise, 1-plecat intr-o delegatie,2-ajuns la destinatie,
      dataLoaded: false,
      linkApi: "/api/Delegatii",
      linkApiDetaliu: "/api/DetaliuDelegatii",
      denumire: "Delegatii",
      height: window.innerHeight - 44,
      dataSource: [],
      dataSourceDetalii: [],
      inputs: [
        { key: "NumarDelegatie", keyBaza: "iD_DELEGATIE" },
        {
          key: "Salariat",
          keyBaza: "iD_SALARIAT",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        { key: "DataDelegatie", keyBaza: "datA_DEL", tip: "datebox" },
        { key: "Destinatie", keyBaza: "destinatie" },
        { key: "Sofer", keyBaza: "sofer", tip: "bool" },
        { key: "BenzinaOras", keyBaza: "benzinA_ORAS", tip: "number" },
        { key: "BenzinaSuma", keyBaza: "benzinA_SUMA", tip: "number" },
        { key: "Plecare", keyBaza: "plecarE_FIRMA", tip: "datebox" },
        { key: "Sosire", keyBaza: "sosirE_FIRMA", tip: "datebox" },
        { key: "KmPlecare", keyBaza: "km_plecare", tip: "number" },
        { key: "KmSosire", keyBaza: "km_sosire", tip: "number" },
        {
          key: "Masina",
          keyBaza: "id_utilaje",
          tip: "selectbox",
          lista: this.$f7.data.Utilaje,
        },
        { key: "Status", keyBaza: "status", tip: "number" },
      ],
      ignoreInputsAdd: ignoreInputsAdd,
    };

    this.refDataGrid = React.createRef();
  }

  /**
   * @description Ia toate inregistrarile de tip delegatie din baza de date(daca este administrator) sau filtrate doar cele ale salariatului logat daca nu este administrator
   * @date 08/04/2022
   * @function
   * @memberof Delegatii
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    var idSal = this.$f7.data.rol === 1 ? -1 : this.$f7.data.user.iD_SALARIAT;

    const requestDelegatii = axios.get(
      this.$f7.data.baseURL +
        this.state.linkApi +
        "/GetDELEGATIIBySalariat/" +
        idSal
    );
    const requestDetaliuDelegatii = axios.get(
      this.$f7.data.baseURL +
        this.state.linkApiDetaliu +
        "/GetDETALIU_DELEGATII"
    );

    axios
      .all([requestDelegatii, requestDetaliuDelegatii])
      .then(
        axios.spread((response, responseDetalii) => {
          var date = response;

          date.data.map((delegatie) => {
            delegatie = this.$f7.methods.restructurareBaza(
              delegatie,
              this.state.inputs,
              "key"
            );
            return false;
          });

          var baza = this.$f7.methods.reconstructieBaza(
            date,
            this.state.inputs
          );
          var status = 0;
          baza.map((r) => {
            if (r.status > status) {
              status = r.status;
            }
            return false;
          });
          this.setState(
            {
              status: status,
              dataSource: baza,
              dataSourceDetalii: responseDetalii.data,
            },
            () => {
              setTimeout(() => {
                this.setState(
                  {
                    dataLoaded: true,
                  },
                  () => {
                    if (baza.length > 0) {
                      this.refDataGrid.current.setRenderItems(baza);
                    }
                  }
                );
              }, 400);
            }
          );
        })
      )
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataSourceDetalii: [],
            dataLoaded: true,
          },
          () => {
            this.refDataGrid.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });

    // axios
    //     .get(
    //         this.$f7.data.baseURL +
    //         this.state.linkApi +
    //         "/GetDELEGATIIBySalariat/" +
    //         idSal
    //     )
    //     .then((response) => {
    //         var date = response;
    //         console.log('delegatii:', date.data);

    //         date.data.map((delegatie) => {
    //             delegatie = this.$f7.methods.restructurareBaza(
    //                 delegatie,
    //                 this.state.inputs,
    //                 "key"
    //             );
    //             return false;
    //         });

    //         var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);
    //         var status = 0;
    //         baza.map((r) => {
    //             if (r.status > status) {
    //                 status = r.status;
    //             }
    //             return false;
    //         })
    //         this.setState(
    //             {
    //                 status: status,
    //                 dataSource: baza,
    //             },
    //             () => {
    //                 setTimeout(() => {
    //                     this.setState(
    //                         {
    //                             dataLoaded: true,
    //                         },
    //                         () => {
    //                             if (baza.length > 0) {
    //                                 this.refDataGrid.current.setRenderItems(baza);
    //                             }
    //                         }
    //                     );
    //                 }, 400);
    //             }
    //         );
    //     })
    //     .catch((error) => {
    //         this.setState(
    //             {
    //                 dataSource: [],
    //                 dataLoaded: true,
    //             },
    //             () => {
    //                 this.refDataGrid.current.setRenderItems([]);
    //                 this.$f7.dialog.confirm(
    //                     "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
    //                     "Ups...",
    //                     () => {
    //                         this.GetValues();
    //                     },
    //                     () => { }
    //                 );
    //             }
    //         );

    //         console.log("error", error);
    //     });
  };

  /**
   * @description Adauga in baza de date o noua inregistrare(Delegatie)
   * @function
   * @date 08/04/2022
   * @param {*} data obiect cu valorile noii inregistrari
   * @memberof Delegatii
   */
  AddValue = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApi + "/PostDELEGATII";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Se adauga inregistrare atunci cand se pleaca spre client
   * @date 08/04/2022
   * @function
   * @param {*} data informatiile delegatiei
   * @param {*} editedItem item-ul selectat
   * @memberof Delegatii
   */
  AddValueDelegatie = (data, editedItem) => {
    var url = this.$f7.data.baseURL + this.state.linkApi + "/PostDELEGATII";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.AddValueDetaliu({
          iD_DELEGATIE: response.data.iD_DELEGATIE,
          iD_FIRMA: editedItem.Destinatie,
          activitate: 0,
        });
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza in baza de date inregistrarea selectata(delegatii)
   * @date 08/04/2022
   * @param {*} obj obiect cu noile valori ale inregistrarii(Delegatie)
   * @param {*} id id-ul inregistrarii selectate
   * @function
   * @memberof Delegatii
   */
  UpdateValue = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/GetDELEGATII/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL + this.state.linkApi + "/PutDELEGATII/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Sterge din baza de date inregistrarea selectata(Delegatii)
   * @date 08/04/2022
   * @param {*} id id-ul inregistrarii selectate
   * @function
   * @memberof Delegatii
   */
  DeleteValue = (id) => {
    var url =
      this.$f7.data.baseURL + this.state.linkApi + "/DeleteDELEGATII/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  /**
   * @description Adauga o inregistrare de tip detaliu delegatie in baza de date
   * @date 08/04/2022
   * @param {*} data obiectcu informatiile detaliilor inregistrarii
   * @function
   * @memberof Delegatii
   */
  AddValueDetaliu = (data) => {
    var url =
      this.$f7.data.baseURL +
      this.state.linkApiDetaliu +
      "/PostDETALIU_DELEGATII";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza in baza de date o inregistrare de tip detaliu delegatie
   * @date 08/04/2022
   * @param {*} obj obiect cu noile valori ale inregistrarii
   * @param {*} id id-ul inregistrarii selectate
   * @memberof Delegatii
   */
  UpdateValueDetaliu = (obj, id) => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiDetaliu +
          "/GetDETALIU_DELEGATII/" +
          id
      )
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL +
          this.state.linkApiDetaliu +
          "/PutDETALIU_DELEGATII/" +
          id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Actualizeaza realizarea taskului pornit la sfarsitul delegatiei
   * @date 08/04/2022
   * @param {*} obj obiect cu detaliile taskului
   * @param {*} id id-ul taskului
   * @function
   * @memberof Delegatii
   */
  UpdateValueHidden = (obj, id) => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiRealizari +
          "/GetREALIZARE_SARCINI/" +
          id
      )
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL +
          this.state.linkApiRealizari +
          "/PutREALIZARE_SARCINI/" +
          id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            if (this.state.deschideNou) {
              setTimeout(() => {
                this.Inceput();
              }, 500);
            }
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Actualizeaza in baza de date realizarea taskului cu data de final si adauga inregistrare cu data de final la delegatie
   * @date 08/04/2022
   * @function
   * @param {function} callback functie de callback
   * @memberof Delegatii
   */
  Sfarsit = (callback) => {
    var url =
      this.$f7.data.baseURL + this.state.linkApiRealizari + "/PutSfarsit";

    axios({
      data: {
        IdSalariat: this.$f7.data.user.iD_SALARIAT,
        InchisDeSalariat: false,
      },
      headers: { "Content-Type": "application/json" },
      method: "PUT",
      url: url,
    })
      .then((response) => {
        var ceReturneaza = response.data;

        if (ceReturneaza !== "Nu exista task deschis.") {
          this.$f7.data.router.navigate("/popupAddEdit/", {
            props: {
              allRequired: true,
              inputs: this.state.inputsInchidere,
              buttons: [
                {
                  key: "Salveaza",
                  onSubmit: (state, den) => {
                    var obj = { ...state.editedItem };
                    obj.iesire = moment().format();
                    obj.comentariU1 = obj["ComentariuPlus"];
                    this.UpdateValueHidden(obj, parseInt(ceReturneaza));
                    if (callback !== undefined) {
                      callback();
                    }
                    //this.$f7.popup.close(den)
                    this.$f7.panel.close(den);
                    this.$f7.methods.openNotification("Task inchis", "Success");
                  },
                },
              ],
            },
          });
        } else {
          if (callback !== undefined) {
            callback();
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la actualizare");
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  /**
   * @description Coloreaza elementele datagridului in functie de stadiul delegatiei(plecat,sosit la client etc)
   * @date 08/04/2022
   * @param {*} state state-ul datagridului
   * @param {*} r context-ul datagridului
   * @param {*} itemeRandate itemele randate ale datagridului
   * @function
   * @memberof Delegatii
   */
  colorItems = (state, r, itemeRandate) => {
    var tableName = r.tableName;
    if (tableName) {
      itemeRandate.forEach((elem, index) => {
        const detaliu = this.state.dataSourceDetalii.filter(
          (item) =>
            item.iD_DELEGATIE === elem.NumarDelegatie && item.plecare === null
        );
        var tar = document.getElementById("trMain" + tableName + index);

        if (tar) {
          if (elem.Sosire === null && detaliu.length > 0) {
            if (detaliu[0].sosire === null) {
              tar.style.backgroundColor = "rgba(76, 217, 100, 0.5)"; //"#4cd964";
            } else {
              tar.style.backgroundColor = "rgba(255, 149, 0, 0.5)"; //"#ff9500";
            }
          } else if (elem.Sosire === null && detaliu.length === 0) {
            tar.style.backgroundColor = "rgba(255, 59, 48, 0.5)"; //"#ff3b30";
          } else if (elem.Sosire !== null) {
            tar.style.backgroundColor = "rgba(33, 150, 243, 0.5)"; //"#2196f3";
          }
        }
      });
    }
  };

  render() {
    return (
      <Page
        className={"themeBackground"}
        onPageInit={() => {
          this.$f7.data.paginaCurenta = this;
        }}>
        <div className="ptr3">
          <>
            <Segmented
              className="butoaneContextDatagrid"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "calc(100% - 176px)",
              }}
              tag="div">
              <Button
                tooltip={"Plecare catre client"}
                onClick={() => {
                  if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((p) => {
                      var lat = p.coords.latitude;
                      var long = p.coords.longitude;
                      this.setState({ lat: lat, long: long });
                    });
                  }
                  this.Sfarsit(() => {
                    this.$f7.methods
                      .esteDelegatie()
                      .then((delegatieCurenta) => {
                        if (delegatieCurenta === false) {
                          this.$f7.data.router.navigate("/popupAddEdit/", {
                            props: {
                              allRequired: true,
                              inputs: this.state.inputsPlecare,
                              id: "Id",
                              buttons: [
                                {
                                  key: "Salveaza",
                                  onSubmit: (state, den) => {
                                    var editedItem = { ...state.editedItem };

                                    var obj = {
                                      iD_SALARIAT:
                                        this.$f7.data.user.grupuri.includes(12)
                                          ? editedItem.Salariat
                                          : this.$f7.data.user.iD_SALARIAT,
                                      dATA_DEL: editedItem.MomentPlecare,
                                      sofer:
                                        editedItem.Sofer === null
                                          ? 0
                                          : editedItem.Sofer,
                                      destinatie: this.$f7.data.Firme.filter(
                                        (r) => {
                                          return (
                                            r.value === editedItem.Destinatie
                                          );
                                        }
                                      )[0].key,
                                      plecarE_FIRMA: moment().format(),
                                      nR_DEL: editedItem.NumarDelegatie,
                                      iD_UTILAJE: editedItem.Masina,
                                      kM_PLECARE: editedItem.KmPlecare,
                                      status: 0,
                                    };
                                    this.AddValueDelegatie(obj, editedItem);
                                    //this.$f7.popup.close(den)
                                    this.$f7.panel.close(den);
                                  },
                                },
                              ],
                            },
                          });
                        } else {
                          var idDelegatie = delegatieCurenta.iD_DELEGATIE;
                          this.$f7.methods
                            .detaliuDelegatieCurenta(idDelegatie)
                            .then((detaliuDelegatieCurenta) => {
                              this.$f7.methods
                                .detaliuDelegatieCurentaActivitate(idDelegatie)
                                .then((detaliuDelegatieCurentaActivitate) => {
                                  if (
                                    detaliuDelegatieCurenta !== false ||
                                    detaliuDelegatieCurentaActivitate !== false
                                  ) {
                                    this.$f7.data.router.navigate(
                                      "/popupAddEdit/",
                                      {
                                        props: {
                                          allRequired: true,
                                          inputs:
                                            this.state.inputsPlecareDetaliu,
                                          id: "Id",
                                          buttons: [
                                            {
                                              key: "Salveaza",
                                              onSubmit: (state, den) => {
                                                var editedItem = {
                                                  ...state.editedItem,
                                                };

                                                var objDetaliuUpdate1 = {
                                                  plecare: moment().format(),
                                                  plecarE_LNG: this.state.long,
                                                  plecarE_LAT: this.state.lat,
                                                };
                                                var objDetaliuUpdate2 = {
                                                  plecare: moment().format(),
                                                };

                                                var objDetaliuAdd = {
                                                  iD_FIRMA: editedItem.Firma,
                                                  activitate: 0,
                                                  iD_DELEGATIE: idDelegatie,
                                                };
                                                if (
                                                  detaliuDelegatieCurenta !==
                                                  false
                                                ) {
                                                  this.UpdateValueDetaliu(
                                                    objDetaliuUpdate1,
                                                    detaliuDelegatieCurenta.iD_DETALIU_DELEGATIE
                                                  );
                                                }
                                                if (
                                                  detaliuDelegatieCurentaActivitate !==
                                                  false
                                                ) {
                                                  this.UpdateValueDetaliu(
                                                    objDetaliuUpdate2,
                                                    detaliuDelegatieCurentaActivitate.iD_DETALIU_DELEGATIE
                                                  );
                                                }
                                                this.AddValueDetaliu(
                                                  objDetaliuAdd
                                                );
                                                //this.$f7.popup.close(den)
                                                this.$f7.panel.close(den);
                                              },
                                            },
                                          ],
                                        },
                                      }
                                    );
                                  } else {
                                    this.$f7.methods.openNotification(
                                      "Nu exista delegatie pornita."
                                    );
                                  }
                                });
                            });
                        }
                      });
                  });
                }}
                style={{ width: 140, color: "white" }}
                small
                fill
                bgColor="green">
                Plec spre
              </Button>
              <Button
                tooltip={"Ajuns la client"}
                onClick={() => {
                  this.$f7.methods.esteDelegatie().then((delegatieCurenta) => {
                    if (delegatieCurenta !== false) {
                      var idDelegatie = delegatieCurenta.iD_DELEGATIE;
                      this.$f7.methods
                        .detaliuDelegatieCurenta(idDelegatie)
                        .then((detaliuDelegatieCurenta) => {
                          if (detaliuDelegatieCurenta !== false) {
                            if (detaliuDelegatieCurenta.sosire === null) {
                              this.$f7.data.router.navigate("/popupAddEdit/", {
                                props: {
                                  inputs: [
                                    {
                                      key: "DataSosire",
                                      keyBaza: "sosire",
                                      tip: "datebox",
                                      required: true,
                                    },
                                  ],
                                  buttons: [
                                    {
                                      key: "Salveaza",
                                      onSubmit: (state, den) => {
                                        var editedItem = {
                                          ...state.editedItem,
                                        };
                                        this.$f7.preloader.show();
                                        if (navigator.geolocation) {
                                          navigator.geolocation.getCurrentPosition(
                                            (p) => {
                                              var lat = p.coords.latitude;
                                              var long = p.coords.longitude;
                                              this.setState({
                                                lat: lat,
                                                long: long,
                                              });
                                            }
                                          );
                                        }
                                        setTimeout(() => {
                                          this.$f7.preloader.hide();
                                          var obj = {
                                            sosirE_LAT: this.state.lat,
                                            sosirE_LNG: this.state.lng,
                                            sosire: editedItem.DataSosire,
                                          };

                                          this.UpdateValueDetaliu(
                                            obj,
                                            detaliuDelegatieCurenta.iD_DETALIU_DELEGATIE
                                          );
                                        }, 500);
                                        //this.$f7.popup.close(den)
                                        this.$f7.panel.close(den);
                                      },
                                    },
                                  ],
                                },
                              });
                            } else {
                              this.$f7.methods.openNotification(
                                "Ati sosit deja la destinatie!"
                              );
                            }
                          } else {
                            this.$f7.methods.openNotification(
                              "Nu exista delegatie pornita"
                            );
                          }
                        });
                    } else {
                      this.$f7.methods.openNotification(
                        "Nu exista delegatie pornita!",
                        "Ups"
                      );
                    }
                  });
                }}
                style={{ width: 140, color: "white" }}
                small
                fill
                bgColor="orange">
                Ajuns client
              </Button>

              <Button
                tooltip={"Sfarsitul delegatiei"}
                onClick={() => {
                  if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((p) => {
                      var lat = p.coords.latitude;
                      var long = p.coords.longitude;
                      this.setState({ lat: lat, long: long });
                    });
                  }

                  this.$f7.dialog.confirm(
                    "Sunteti sigur ca doriti sa terminati lucrul?",
                    () => {
                      this.$f7.methods
                        .esteDelegatie()
                        .then((delegatieCurenta) => {
                          if (delegatieCurenta !== false) {
                            var idDelegatie = delegatieCurenta.iD_DELEGATIE;
                            this.$f7.methods
                              .detaliuDelegatieCurenta(idDelegatie)
                              .then((detaliuDelegatieCurenta) => {
                                this.$f7.methods
                                  .detaliuDelegatieCurentaActivitate(
                                    idDelegatie
                                  )
                                  .then((detaliuDelegatieCurentaActivitate) => {
                                    if (
                                      detaliuDelegatieCurenta !== false ||
                                      detaliuDelegatieCurentaActivitate !==
                                        false
                                    ) {
                                      var objDetaliuUpdate1 = {
                                        plecare: moment().format(),
                                        plecarE_LNG: this.state.long,
                                        plecarE_LAT: this.state.lat,
                                      };
                                      var objDetaliuUpdate2 = {
                                        plecare: moment().format(),
                                      };
                                      if (detaliuDelegatieCurenta !== false) {
                                        this.UpdateValueDetaliu(
                                          objDetaliuUpdate1,
                                          detaliuDelegatieCurenta.iD_DETALIU_DELEGATIE
                                        );
                                      }
                                      if (
                                        detaliuDelegatieCurentaActivitate !==
                                        false
                                      ) {
                                        this.UpdateValueDetaliu(
                                          objDetaliuUpdate2,
                                          detaliuDelegatieCurentaActivitate.iD_DETALIU_DELEGATIE
                                        );
                                        this.$f7.preloader.show();
                                        setTimeout(() => {
                                          this.$f7.preloader.hide();
                                          this.Sfarsit();
                                        }, 500);
                                      }
                                    } else {
                                      this.$f7.methods.openNotification(
                                        "Nu exista delegatie pornita."
                                      );
                                    }
                                  });
                              });
                          } else {
                            this.$f7.methods.openNotification(
                              "Nu exista delegatie pornita."
                            );
                          }
                        });
                    },
                    () => {}
                  );
                }}
                style={{ width: 140, color: "white" }}
                small
                fill
                bgColor="red">
                Sfarsit delegatie
              </Button>
              <Button
                tooltip={"Ajuns acasa"}
                onClick={() => {
                  this.$f7.dialog.confirm(
                    "Ati ajuns acasa?",
                    () => {
                      this.$f7.methods
                        .esteDelegatie()
                        .then((delegatieCurenta) => {
                          if (delegatieCurenta !== false) {
                            var idDelegatie = delegatieCurenta.iD_DELEGATIE;
                            this.$f7.methods
                              .detaliuDelegatieCurenta(idDelegatie)
                              .then((detaliuDelegatieCurenta) => {
                                this.$f7.methods
                                  .detaliuDelegatieCurentaActivitate(
                                    idDelegatie
                                  )
                                  .then((detaliuDelegatieCurentaActivitate) => {
                                    if (
                                      detaliuDelegatieCurenta === false ||
                                      detaliuDelegatieCurentaActivitate ===
                                        false
                                    ) {
                                      this.$f7.preloader.show();
                                      if (navigator.geolocation) {
                                        navigator.geolocation.getCurrentPosition(
                                          (p) => {
                                            var lat = p.coords.latitude;
                                            var long = p.coords.longitude;
                                            this.setState({
                                              lat: lat,
                                              long: long,
                                            });
                                          }
                                        );
                                      }

                                      setTimeout(() => {
                                        this.$f7.preloader.hide();
                                        var sofer = delegatieCurenta.sofer;

                                        if (sofer) {
                                          this.$f7.data.router.navigate(
                                            "/popupAddEdit/",
                                            {
                                              props: {
                                                allRequired: true,
                                                inputs:
                                                  this.state.inputsAjunsAcasa,
                                                id: "Id",
                                                buttons: [
                                                  {
                                                    key: "Salveaza",
                                                    onSubmit: (state, den) => {
                                                      var editedItem = {
                                                        ...state.editedItem,
                                                      };

                                                      var objDelegatie = {
                                                        kM_TOTAL:
                                                          editedItem.KmSosire -
                                                          delegatieCurenta.kM_PLECARE,
                                                        kM_SOSIRE:
                                                          editedItem.KmSosire,
                                                        sosirE_FIRMA:
                                                          moment().format(),
                                                        benzinA_SUMA:
                                                          editedItem.Benzina,
                                                        benzinA_ORAS:
                                                          editedItem.BenzinaOras,
                                                        status: 1,
                                                      };
                                                      this.UpdateValue(
                                                        objDelegatie,
                                                        idDelegatie
                                                      );
                                                      //this.$f7.popup.close(den)
                                                      this.$f7.panel.close(den);
                                                    },
                                                  },
                                                ],
                                              },
                                            }
                                          );
                                        } else {
                                          var objDelegatie = {
                                            kM_TOTAL: 0,
                                            kM_SOSIRE: 0,
                                            sosirE_FIRMA: moment().format(),
                                            benzinA_SUMA: 0,
                                            benzinA_ORAS: 0,
                                            status: 1,
                                          };
                                          this.UpdateValue(
                                            objDelegatie,
                                            idDelegatie
                                          );
                                        }
                                      }, 500);
                                    } else {
                                      this.$f7.methods.openNotification(
                                        "Nu ati apasat pe butonul de sfarsit lucru!"
                                      );
                                    }
                                  });
                              });
                          } else {
                            this.$f7.methods.openNotification(
                              "Nu exista delegatie pornita."
                            );
                          }
                        });
                    },
                    () => {}
                  );
                }}
                style={{ width: 140, color: "white" }}
                small
                fill
                bgColor="blue">
                Ajuns acasa
              </Button>
            </Segmented>
            <DataGrid
              dataLoaded={this.state.dataLoaded}
              refresh={this.GetValues}
              name={"Delegatii"}
              allRequiredAdd={true}
              ignoreInputsAdd={this.state.ignoreInputsAdd}
              ref={this.refDataGrid}
              id="NumarDelegatie"
              renderUpdate={true} //{this.$f7.data.rol > 1 ? false : true}
              renderDelete={false}
              renderAdd={false}
              onInitialised={(state, r, itemeRandate) => {
                this.colorItems(state, r, itemeRandate);
              }}
              onRenderItemsChanged={(state, r, itemeRandate) => {
                this.colorItems(state, r, itemeRandate);
              }}
              maxHeight={this.state.height}
              dataSource={this.state.dataSource}
              inputs={this.state.inputs}
              onAdd={(state) => {
                var delegatie = { ...state.editedItem };
                delegatie = this.$f7.methods.restructurareBaza(
                  delegatie,
                  this.state.inputs,
                  "keyBaza"
                );

                if (delegatie["kM_SOSIRE"] && delegatie["kM_PLECARE"]) {
                  delegatie["kM_TOTAL"] =
                    delegatie["kM_SOSIRE"] - delegatie["kM_PLECARE"];
                }
                if (!this.$f7.data.user.administrator) {
                  delegatie.iD_SALARIAT = this.$f7.data.user.iD_SALARIAT;
                }

                delete delegatie["iD_DELEGATIE"];

                this.AddValue(delegatie);
              }}
              onUpdate={(state) => {
                var delegatie = { ...state.editedItem };
                var id = delegatie["NumarDelegatie"];
                delegatie = this.$f7.methods.restructurareBaza(
                  delegatie,
                  this.state.inputs,
                  "keyBaza"
                );
                this.UpdateValue(delegatie, id);
              }}
              onDelete={(state) => {
                var delegatie = { ...state.editedItem };
                var id = delegatie["NumarDelegatie"];
                this.DeleteValue(id);
              }}
              renderAccordion={true}
              accordionItem={(state, rowItemData) => {
                var id = rowItemData.NumarDelegatie;
                if (id !== undefined) {
                  return <DelegatiiDetalii idDelegatie={id} />;
                }
              }}
            />
          </>
        </div>
      </Page>
    );
  }
}
export default Delegatii;
