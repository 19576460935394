
/** @description fisierul cu toate informatiile despre aplicatie */
const config={
  "mesajUpdate": "a\n b \n c \n d",
  "name": "Cronos",
  "version": "1.0.0",
  "website": "https://ats.com.ro/",
  "about": "Aplicatie de gestiune/management",
  "appId": "com.cronos.app",
  "authorization": "key=AAAAHdnWnE8:APA91bFsiURZYuoHJZiz98Du34wskOl_nwZUJtZSPGb4hiuhEGWpBKwMp5-3_7j1uW-LIp3CECxQsX_urMzXXPUEy_8gAon6cMNtMiNSFdme7KclcMCOa85dxgLxKqVMWf5C1eMymOKW",
  "config": {
    "apiKey": "AIzaSyBUc_1AEO4gaJV46X1oWFYVjoqUQlChwM4",
    "authDomain": "test-b33c2.firebaseapp.com",
    "databaseURL": "https://test-b33c2.firebaseio.com",
    "projectId": "test-b33c2",
    "storageBucket": "test-b33c2.appspot.com",
    "messagingSenderId": "128208772175",
    "appId": "1:128208772175:web:80b66ebe227a18a9215e3a",
    "measurementId": "G-ZMTMPDTMCX"
  },
  "topic": "Cronos",
  "color": "#99003C",
  "theme": "md",
  "durataSplash": 0,
  "topicPushNotifications": "Ff",
  "notFound": "The page requested was not found.",
  "palette": [
    [
      "#E1F5FE",
      "#B3E5FC",
      "#81D4FA",
      "#4FC3F7",
      "#29B6F6",
      "#03A9F4",
      "#039BE5",
      "#0288D1",
      "#0277BD",
      "#01579B"
    ],
    [
      "#E0F2F1",
      "#B2DFDB",
      "#80CBC4",
      "#4DB6AC",
      "#26A69A",
      "#009688",
      "#00897B",
      "#00796B",
      "#00695C",
      "#004D40"
    ],
    [
      "#F1F8E9",
      "#DCEDC8",
      "#C5E1A5",
      "#AED581",
      "#9CCC65",
      "#8BC34A",
      "#7CB342",
      "#689F38",
      "#558B2F",
      "#33691E"
    ],
    [
      "#FFFDE7",
      "#FFF9C4",
      "#FFF59D",
      "#FFF176",
      "#FFEE58",
      "#FFEB3B",
      "#FDD835",
      "#FBC02D",
      "#F9A825",
      "#F57F17"
    ],
    [
      "#FFF3E0",
      "#FFE0B2",
      "#FFCC80",
      "#FFB74D",
      "#FFA726",
      "#FF9800",
      "#FB8C00",
      "#F57C00",
      "#EF6C00",
      "#E65100"
    ],
    [
      "#F3E5F5",
      "#E1BEE7",
      "#CE93D8",
      "#BA68C8",
      "#AB47BC",
      "#9C27B0",
      "#8E24AA",
      "#7B1FA2",
      "#6A1B9A",
      "#4A148C"
    ],
    [
      "#E8EAF6",
      "#C5CAE9",
      "#9FA8DA",
      "#7986CB",
      "#5C6BC0",
      "#3F51B5",
      "#3949AB",
      "#303F9F",
      "#283593",
      "#1A237E"
    ],
    [
      "#FFEBEE",
      "#FFCDD2",
      "#EF9A9A",
      "#E57373",
      "#EF5350",
      "#F44336",
      "#E53935",
      "#D32F2F",
      "#C62828",
      "#B71C1C"
    ]
  ]
}

export default config;