import React from "react";
import { Page, List, ListItem } from "framework7-react";
/**
 * @description Pagina in care se seteaza ce tranzitie sa fie folosita in aplicatie
 * @date 07/04/2022
 * @class TranzitiePage
 * @extends {React.PureComponent}
 */
class TranzitiePage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            options: [
                { key: "Circle", value: "f7-circle" },
                { key: "Cover", value: "f7-cover" },
                { key: "Cover-V", value: "f7-cover-v" },
                { key: "Dive", value: "f7-dive" },
                { key: "Fade", value: "f7-fade" },
                { key: "Flip", value: "f7-flip" },
                { key: "Parallax", value: "f7-parallax" },
                { key: "Push", value: "f7-push" }
            ]
        };
    }

    render() {
        return (
            <Page className="themeBackground">
                <div className="ptr3">
                    <List noHairlinesMd style={{ margin: 0, paddingTop: 16 }}>
                        {this.state.options.map((item, index) => (
                            <ListItem
                                key={this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx")}
                                name="demo-radio2"
                                radio
                                required
                                id={index}
                                defaultChecked={
                                    localStorage.getItem("transition") === item.value
                                }
                                title={item.key}
                                value={item.value}
                                onClick={() => {
                                    localStorage.setItem("transition", item.value);
                                    this.$f7.methods.setTransition(item.value);
                                }}
                            />
                        ))}
                    </List>
                </div>
            </Page>
        );
    }
}
export default TranzitiePage;