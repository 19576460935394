import * as sources from "./sources";
export const Proiecte = [
    { key: "id" },
    { key: "denumire" },
    { key: "id_prioritate", tip: "number" },
    { key: "c_arh" },
    { key: "dezv", tip: "number" },
    { key: "sefproiect", tip: "number" },
    { key: "super", tip: "number" },
    { key: "manag", tip: "number" },
    { key: "rlz", tip: "number" },
    { key: "chelt_sal", tip: "number" },
    { key: "buget", tip: "number" },
    { key: "ordine", tip: "number" },
    { key: "tip_exc", tip: "number" },
];
export const SubProiecte = [
    { key: "id" },
    { key: "denumire" },
    { key: "id_prioritate", tip: "number" },
    { key: "c_arh" },
    { key: "dezv", tip: "number" },
    { key: "sefproiect", tip: "number" },
    { key: "super", tip: "number" },
    { key: "manag", tip: "number" },
    { key: "rlz", tip: "number" },
    { key: "chelt_sal", tip: "number" },
    { key: "buget", tip: "number" },
    { key: "ordine", tip: "number" },
    { key: "tip_exc", tip: "number" },
];

export const Salariat = [
    { key: "Id_Realizare" },
    { key: "NumeSalariat", tip: 'selectbox', lista: [] },
    { key: "MomentInceput", tip: "datebox" },
    { key: "MomentSfarsit", tip: "datebox" },
    { key: "TimpLucratInOre", tip: "number" },
    { key: "Unitati", tip: "number" },
    { key: "Operatie" },
    { key: "Client", tip: 'selectbox', lista: [] },
    { key: "Procent_Realizare", tip: "number" },
    { key: "Stadiu" },
    { key: "Comentariu" },
    { key: "ComentariuPlus" },
];

export const Mesaje = [
    { key: "id" },
    { key: "Expeditor" },
    { key: "Destinatar" },
    { key: "Data", tip: "datebox" },
    { key: "Subiect" },
    { key: "Atasament" },
];

export const SalariatCheltuieliVenituri = [
    { key: "NumeSalariat" },
    { key: "Procent", tip: "number" },
    { key: "Suma", tip: "number" },
];
export const CheltuieliTop = [
    { key: "TipCheltuiala", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Denumire" },
];
export const VenituriTop = [
    { key: "TipVenit", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Denumire" },
];

export const CheltuieliVenituri = [
    { key: "Suma", tip: "number" },
    { key: "DeLaData", tip: "datebox" },
    { key: "PanaLaData", tip: "datebox" },
];
export const Delegatii = [
    { key: "Salariat" },
    { key: "DataDelegatie", tip: "datebox" },
    { key: "NumarDelegatie", tip: "number" },
    { key: "Destinatie" },
    { key: "SoferSauNu", tip: "bool" },
    { key: "BenzinaOras" },
    { key: "BenzinaSuma", tip: "number" },
    { key: "Plecare", tip: "datebox" },
    { key: "Sosire", tip: "datebox" },
];

export const Concedii = [
    { key: "TipZiLibera", tip: "selectbox", lista: sources.tipZiLibera },
    { key: "Salariat", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "DataInceput", tip: "datebox" },
    { key: "DataSfarsit", tip: "datebox" },
    { key: "NumarOre", tip: "number" },
    { key: "Comentariu" },
    { key: "Stadiu", tip: "selectbox", lista: sources.tipSelectbox },
];

export const Pontaj = [
    { key: "Ziua", tip: "datebox" },
    { key: "Start", tip: "datebox" },
    { key: "Termen", tip: "datebox" },
    { key: "Denumire", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Stadiu", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Intrare", tip: "datebox" },
    { key: "Iesire", tip: "datebox" },
    { key: "Asumat", tip: "number" },
];

export const CodProiect = [
    { key: "Autor" },
    { key: "Data", tip: "datebox" },
    { key: "LinkFisier", tip: "filepicker" },
    { key: "Comentariu" },
];

export const Cerinte = [
    { key: "Autor" },
    { key: "Data", tip: "datebox" },
    { key: "NumeFisier" },
    { key: "Functionalitate" },
    { key: "Stadiu", tip: "selectbox", lista: sources.tipStadiu },
    { key: "Comentariu" },
];

export const Verificari = [
    { key: "Autor" },
    { key: "DataTestarii", tip: "datebox" },
    { key: "NumeFisierRezultat" },
    { key: "Functionalitate" },
    { key: "Comentariu" },
];

export const Documentare = [
    { key: "Autor" },
    { key: "Data", tip: "datebox" },
    { key: "NumeFisier" },
    { key: "TipDocument" },
    { key: "Comentariu" },
    { key: "Document", tip: "filepicker" },
];

export const EroriRezolvari = [
    { key: "Problema" },
    { key: "DataSemnalarii", tip: "datebox" },
    { key: "NumarInregistrare", tip: "number" },
    { key: "ReceptionatDe", tip: "selectbox", lista: sources.tipAngajati },
    {
        key: "ReferintaAltaProblema",
        tip: "selectbox",
        lista: sources.tipSelectbox,
    },
    { key: "LinkDocument" },
    { key: "Responsabil", tip: "selectbox", lista: sources.tipAngajati },
    { key: "TimpEstimat", tip: "number" },
    { key: "Client", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Comentariu" },
    { key: "DataRezolvarii", tip: "datebox" },
    { key: "RezolvatDe", tip: "selectbox", lista: sources.tipAngajati },
    { key: "TimpRezolvare", tip: "number" },
    { key: "Stadiu", tip: "selectbox", lista: sources.tipStadiu },
    { key: "CosturiTotale", tip: "number" },
    { key: "Resurse" },
];

export const Help = [
    { key: "Autor" },
    { key: "Data", tip: "datebox" },
    { key: "LinkFisier" },
    { key: "Comentariu" },
];

export const ToateDoc = [
    { key: "TipDocument" },
    { key: "Proiect" },
    { key: "Autor" },
    { key: "DataModificarii", tip: "datebox" },
    { key: "CaleFisier" },
    { key: "Comentariu" },
    { key: "Legatura" }
];

export const istoricModificari = [
    { key: "Data", tip: "datebox" },
    { key: "Salariat", tip: "selectbox", lista: sources.tipAngajati },
    { key: "TipModificare", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Denumire", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Comentariu" },
    { key: "Parinte", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Stadiu", tip: "selectbox", lista: sources.tipStadiu },
    { key: "Folosit", tip: "bool" },
    { key: "Task", tip: "bool" },
    { key: "Arhivat", tip: "bool" },
    { key: "Ascuns", tip: "bool" },
    { key: "Prioritate", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "NivelProiect", tip: "number" },
    { key: "Operatiune", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "TipProiect", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Clienti" },
    { key: "DataCreare", tip: "datebox" },
    { key: "DataStart", tip: "datebox" },
    { key: "DataLimita", tip: "datebox" },
    { key: "DataInceputLucru", tip: "datebox" },
    { key: "DataFinal", tip: "datebox" },
    { key: "TimpEstimatDezvoltator", tip: "number" },
    { key: "TimpEstimatSefProiect", tip: "number" },
    { key: "TimpEstimatArbitru", tip: "number" },
    { key: "TimpEstimatManager", tip: "number" },
    { key: "ObservatieStadiu" },
    { key: "UserImport" },
    { key: "Responsabil", tip: "selectbox", lista: sources.tipAngajati },
    { key: "Dezvoltator", tip: "selectbox", lista: sources.tipAngajati },
    { key: "Arbitru", tip: "selectbox", lista: sources.tipAngajati },
    { key: "SefProiect", tip: "selectbox", lista: sources.tipAngajati },
    { key: "Manager", tip: "selectbox", lista: sources.tipAngajati },
    { key: "Departament", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "DataImport", tip: "datebox" },
    { key: "Tip" },
    { key: "Termen", tip: "datebox" },
    { key: "Facturat", tip: "bool" },
    { key: "Venit", tip: "number" },
    { key: "ValoreMinima", tip: "number" },
    { key: "ValoreMaxima", tip: "number" },
    { key: "Buget", tip: "number" },
    { key: "BugetTimp", tip: "number" },
    { key: "ProcentRisc", tip: "number" },
    { key: "CostEstimat", tip: "number" },
    { key: "CostRealCalculat", tip: "number" },
    { key: "DirectorRadacina" },
    { key: "Nivel", tip: "number" },
    { key: "AbrobatDezvoltator", tip: "bool" },
    { key: "AprobatSefProiect", tip: "bool" },
    { key: "AprobatArbitru", tip: "bool" },
    { key: "AprobatManager", tip: "bool" },
    { key: "CheltuieliSalariale", tip: "bool" },
    { key: "TaskSimilar", tip: "selectbox", lista: sources.tipSelectbox },
];

export const FazeSiRoluri = [
    { key: "Denumire" },
    { key: "Fezabilitate", tip: "bool" },
    { key: "Juridic", tip: "bool" },
    { key: "Analiza", tip: "bool" },
    { key: "Proiecte", tip: "bool" },
    { key: "Codare", tip: "bool" },
    { key: "CodReview", tip: "bool" },
    { key: "Testare", tip: "bool" },
    { key: "Debugging_Service", tip: "bool" },
    { key: "Documentare", tip: "bool" },
    { key: "Implementare", tip: "bool" },
    { key: "Mentenanta", tip: "bool" },
    { key: "Upgrade", tip: "bool" },
];

export const FazeProiect = [
    { key: "Proiect", parent: "Denumire" },

    { key: "D_Fezabilitate", tip: "bool", parent: "Fezabilitate" },
    { key: "SP_Fezabilitate", tip: "bool", parent: "Fezabilitate" },
    { key: "S_Fezabilitate", tip: "bool", parent: "Fezabilitate" },
    { key: "M_Fezabilitate", tip: "bool", parent: "Fezabilitate" },

    { key: "D_Juridic", tip: "bool", parent: "Juridic" },
    { key: "SP_Juridic", tip: "bool", parent: "Juridic" },
    { key: "S_Juridic", tip: "bool", parent: "Juridic" },
    { key: "M_Juridic", tip: "bool", parent: "Juridic" },

    { key: "D_Analiza", tip: "bool", parent: "Analiza" },
    { key: "SP_Analiza", tip: "bool", parent: "Analiza" },
    { key: "S_Analiza", tip: "bool", parent: "Analiza" },
    { key: "M_Analiza", tip: "bool", parent: "Analiza" },

    { key: "D_Proiecte", tip: "bool", parent: "Proiecte" },
    { key: "SP_Proiecte", tip: "bool", parent: "Proiecte" },
    { key: "S_Proiecte", tip: "bool", parent: "Proiecte" },
    { key: "M_Proiecte", tip: "bool", parent: "Proiecte" },

    { key: "D_Codare", tip: "bool", parent: "Codare" },
    { key: "SP_Codare", tip: "bool", parent: "Codare" },
    { key: "S_Codare", tip: "bool", parent: "Codare" },
    { key: "M_Codare", tip: "bool", parent: "Codare" },

    { key: "D_CodReview", tip: "bool", parent: "Cod Review" },
    { key: "SP_CodReview", tip: "bool", parent: "Cod Review" },
    { key: "S_CodReview", tip: "bool", parent: "Cod Review" },
    { key: "M_CodReview", tip: "bool", parent: "Cod Review" },

    { key: "D_Testare", tip: "bool", parent: "Tastare" },
    { key: "SP_Testare", tip: "bool", parent: "Tastare" },
    { key: "S_Testare", tip: "bool", parent: "Tastare" },
    { key: "M_Testare", tip: "bool", parent: "Tastare" },

    { key: "D_Debugging_Service", tip: "bool", parent: "Debugging/Service" },
    { key: "SP_Debugging_Service", tip: "bool", parent: "Debugging/Service" },
    { key: "S_Debugging_Service", tip: "bool", parent: "Debugging/Service" },
    { key: "M_Debugging_Service", tip: "bool", parent: "Debugging/Service" },

    { key: "D_Documentare", tip: "bool", parent: "Documentare" },
    { key: "SP_Documentare", tip: "bool", parent: "Documentare" },
    { key: "S_Documentare", tip: "bool", parent: "Documentare" },
    { key: "M_Documentare", tip: "bool", parent: "Documentare" },

    { key: "D_Implementare", tip: "bool", parent: "Implementare" },
    { key: "SP_Implementare", tip: "bool", parent: "Implementare" },
    { key: "S_Implementare", tip: "bool", parent: "Implementare" },
    { key: "M_Implementare", tip: "bool", parent: "Implementare" },

    { key: "D_Mentenanta", tip: "bool", parent: "Mentenanta" },
    { key: "SP_Mentenanta", tip: "bool", parent: "Mentenanta" },
    { key: "S_Mentenanta", tip: "bool", parent: "Mentenanta" },
    { key: "M_Mentenanta", tip: "bool", parent: "Mentenanta" },

    { key: "D_Upgrade", tip: "bool", parent: "Upgrade" },
    { key: "SP_Upgrade", tip: "bool", parent: "Upgrade" },
    { key: "S_Upgrade", tip: "bool", parent: "Upgrade" },
    { key: "M_Upgrade", tip: "bool", parent: "Upgrade" },
];

export const VizualizareGenerala = [
    { key: "Salariat", tip: "selectbox", lista: sources.tipAngajati },
    { key: "MomentInceput", tip: "datebox" },
    { key: "MomentSfarsit", tip: "datebox" },
    { key: "TimpLucratInOre", tip: "number" },
    { key: "Asumat", tip: "bool" },
    { key: "Functionalitate" },
    { key: "Stadiu", tip: "selectbox", lista: sources.tipStadiu },
    { key: "ProcentRealizare", tip: "number" },
    { key: "Comentariu" },
];

//PLANIFICARI

export const PlanificareSalariat = [
    { key: "Id", tip: "number" },
    { key: "Nume" },
    { key: "Departament" },
    { key: "Profesie" }
];

export const PlanificareProiect = [
    { key: "Id", tip: "number" },
    { key: "ProiectParinte" },
    { key: "Denumire" },
    { key: "Start", tip: "datebox" },
    { key: "Deadline", tip: "datebox" },
    { key: "TimpEstimatOre", tip: "number" },
    { key: "TimpNeplanificatOre", tip: "number" },
    { key: "ArePlanificare", tip: "number" }
];

export const Sesizari = [
    { key: "DataSesizare", tip: "datebox" },
    { key: "Expeditor", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Destinatar", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Subiect" },
    { key: "Sesizare" },
    { key: "Citit", tip: "bool" },
];

export const SarciniNealocate = [
    { key: "Denumire", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Parinte", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "IdPrioritate", tip: "number" },
    { key: "Comentariu" },
    { key: "Client", tip: "selectbox", lista: sources.tipSelectbox },
];

export const SarciniPrimitePropuse = [
    { key: "Task", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Proiect", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Prioritate", tip: "number" },
    { key: "DeLaCine", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "DataPropunerii", tip: "datebox" },
    { key: "Comentariu" },
];

export const AlertaSarciniTaskuri = [
    { key: "Deadline", tip: "datebox" },
    { key: "Parinte", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Proiect", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Prioritate", tip: "number" },
    { key: "Deadline", tip: "datebox" },
    { key: "Stadiu", tip: "selectbox", lista: sources.tipSelectbox },
];

export const AlertaSarciniSesizari = [
    { key: "DataSesizare", tip: "datebox" },
    { key: "DeLaCine", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Subiect" },
    { key: "Comentariu" },
];
export const ProiecteRealizariGrid = [
    { key: "Denumire" },
    { key: "Stadiu", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Client", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "OrePlan", tip: "number" },
    { key: "OreLucrate", tip: "number" },
];

export const ProiecteRealizariSalariat = [
    { key: "Nume", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Departament", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Profesie", tip: "selectbox", lista: sources.tipSelectbox },
];

export const AlocareSarciniSalariati = [
    { key: "Nume", tip: "selectbox", lista: sources.tipAngajati },
];

export const AlocareSarciniClienti = [
    { key: "Denumire", tip: "selectbox", lista: sources.tipClienti },
];

export const AlocareSarciniRealizari = [
    { key: "Salariat", tip: "selectbox", lista: sources.tipAngajati },
    { key: "MomentInceput", tip: "datebox" },
    { key: "MomentSfarsit", tip: "datebox" },
    { key: "ProcentRealizare", tip: "number" },
    { key: "Asumat", tip: "bool" },
    { key: "Client", tip: "selectbox", lista: sources.tipClienti },
    { key: "Comentariu" },
];

export const AlocareSarciniGantt = [
    { key: "Caption" },
    { key: "Finish", tip: "datebox" },
    { key: "Start", tip: "datebox" },
    { key: "Reminder", tip: "bool" },
    { key: "AllDayEvent", tip: "bool" },
    { key: "Enabled", tip: "bool" },
];

//INTRETINERE
export const IntretinereFaze = [
    { key: "ID_Faza" },
    { key: "Denumire", requiredAdd: true, requiredEdit: true },
    { key: "Culoare", tip: "colorpicker", requiredAdd: true, requiredEdit: true },
];
export const IntretinereCuloriArboreProiecte1 = [
    { key: "IdColortree", tip: "number" },
    { key: "Nivel", tip: "number", requiredAdd: true, requiredEdit: true },
    { key: "CodCuloare", tip: "colorpicker", requiredAdd: true, requiredEdit: true },
    { key: "CodBackground", tip: "colorpicker", requiredAdd: true, requiredEdit: true },
    { key: "FontName", requiredAdd: true, requiredEdit: true },
    { key: "FontSize", tip: "number", requiredAdd: true, requiredEdit: true },
    { key: "IsBold", tip: "bool", requiredAdd: true, requiredEdit: true },
    { key: "IsItalic", tip: "bool", requiredAdd: true, requiredEdit: true },
    { key: "IsStrikeout", tip: "bool", requiredAdd: true, requiredEdit: true },
    { key: "IsUnderline", tip: "bool", requiredAdd: true, requiredEdit: true }
];

export const IntretinereCuloriArboreProiecte2 = [
    { key: "IdColorAlerta", tip: "number" },
    { key: "NrInterval", tip: "number", requiredAdd: true, requiredEdit: true },
    { key: "Culoare", tip: "colorpicker", requiredAdd: true, requiredEdit: true },
];

export const IntretinereStadii = [
    { key: "Id_Stadiu" },
    { key: "Denumire", requiredAdd: true, requiredEdit: true },
    { key: "Color_Font", tip: "colorpicker", requiredAdd: true, requiredEdit: true },
    { key: "Color_Background", tip: "colorpicker", requiredAdd: true, requiredEdit: true },
];
export const IntretinereProfesii = [{ key: "ID_Profesie" }, { key: "Denumire", requiredAdd: true, requiredEdit: true }];
export const IntretinereTipuriCheltuieli = [{ key: "Id_Tip_Chelt" }, { key: "Denumire", requiredAdd: true, requiredEdit: true }];
export const IntretinereTipuriVenit = [{ key: "Id_Tip_Venit" }, { key: "Denumire", requiredAdd: true, requiredEdit: true }];
export const IntretinereUtilaje = [{ key: "Id_Utilaj" }, { key: "Denumire", requiredAdd: true, requiredEdit: true }];
export const IntretinereBirouri = [{ key: "Id_Birou" }, { key: "Denumire", requiredAdd: true }];
export const IntretinereSursa = [
    { key: "Id_Tip_Sursa", keyBaza: "iD_TIP_SURSA" },
    {
        key: "Denumire",
        keyBaza: "denumire",
        requiredAdd: true
    }
];
export const IntretinereTermeneFinalizare = [{ key: "Id_Termen" }, { key: "Denumire", requiredAdd: true, requiredEdit: true }];
export const IntretinereTipNivelProiect = [{ key: "Id_Nivel_Proiect" }, { key: "Denumire", requiredAdd: true, requiredEdit: true }];
export const IntretinereTipuriEvenimenteClienti = [{ key: "Id_Tip_Eveniment" }, { key: "Denumire", requiredAdd: true, requiredEdit: true }];

export const IntretinereContracte = [
    { key: "Numar", tip: "number" },
    { key: "Data_Contract", tip: "datebox" },
    { key: "Act_Aditional" },
    { key: "Client", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Firma", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Valabilitate", tip: "datebox" },
    { key: "Valoare", tip: "number" },
    { key: "Garantie", tip: "number" },
    { key: "ExpirareGarantie", tip: "datebox" },
    { key: "DataPV", tip: "datebox" },
    { key: "TipAbonament", tip: "bool" },
    { key: "PenalitatiEi", tip: "number" },
    { key: "PenalitatiNoi", tip: "number" },
    { key: "Comentariu" },
];
export const IntretinereContracteOperatiuni = [
    { key: "IdContracteDetalii", tip: "number" },
    { key: "Operatiune", },
    { key: "Tarif", tip: "number" },
];

export const IntretinereContracteModule = [{ key: "IdContracteModule", tip: "number" }, { key: "Denumire" }];

export const Rapoarte = [
    { key: "Id", tip: "number" },
    {
        key: "Denumire",
        requiredAdd: true,
        requiredEdit: true
    },
    {
        key: "Creat", tip: "datebox",
        requiredAdd: true,
        requiredEdit: true
    },
    {
        key: "Modificat", tip: "datebox",
        requiredAdd: true,
        requiredEdit: true
    }
];

export const IntretinereDepartamente = [
    { key: "Id_Departament", tip: "number" },
    {
        key: "Denumire"
    },
    { key: "Ref_Departament", tip: "number" },
    { key: "Ordine", tip: "number" },
];

export const OperatiuniClienti = [
    { key: "Id_Operatiune", tip: "number" },
    { key: "Denumire", requiredAdd: true, requiredEdit: true },
    { key: "Comentariu" },
    { key: "Pe_Task", tip: "bool" },
    { key: "Ref_Operatiune", tip: "number" },
];

export const Facturare = [
    { key: "Proiect" },
    { key: "DenumireTask" },
    { key: "Facturat", tip: "bool" },
    { key: "Salariat" },
    { key: "Operatie" },
    { key: "TimpLucratInOre", tip: "number" },
    { key: "TarifOrar", tip: "number" },
    { key: "Suma", tip: "number" },
    { key: "DataCreare", tip: "datebox" },
    { key: "DataAlocare", tip: "datebox" },
    { key: "DataFinalizare", tip: "datebox" },
    { key: "Durata", tip: "number" }
];

export const activitateZilnica = [
    { key: "Salariat" },
    { key: "Intrare" },
    { key: "Iesire" },
    { key: "Eveniment" },
];

export const cheltuieliFaze = [
    { key: "Faza" },
    { key: "CostTotal", tip: "number" },
];

export const activitateOreLucrate = [
    { key: "Nume" },
    { key: "Ziua" },
    { key: "Timp", tip: "number" },
    { key: "TimpPontat", tip: "number" },
    { key: "ComSef", tip: "number" },
];

export const IntretinereTipuriProiecte = [{ key: "Denumire" }];
export const IntretinereFazeTipuriProiecte = [
    { key: "Denumire" },
    { key: "Checked", tip: "bool" },
];
export const IntretinerePVuriSus = [
    { key: "Salariat", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "DataPV", tip: "datebox" },
    { key: "NumarPV", tip: "number" },
    { key: "Client", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Aplicatia", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Listat", tip: "bool" },
    { key: "Validat", tip: "bool" },
    { key: "CaleDocument" },
    { key: "NumarFactura", tip: "number" },
    { key: "DataFactura", tip: "datebox" },
    { key: "ReprezentantClient" },
];
export const IntretinerePVuriJos = [
    { key: "Denumire" },
    { key: "Salariat", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Operatiune" },
    { key: "OreLucrate", tip: "number" },
    { key: "Suma", tip: "number" },
    { key: "Comentariu" },
    { key: "Complus" },
    { key: "TipOperatie", tip: "selectbox", lista: sources.tipSelectbox },
];

export const IntretinereSalariati = [
    { key: "Departament", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Birou", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Profesie", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Competenta" },
    { key: "Angajator", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Nume" },
    { key: "Prenume" },
    { key: "Login" },
    { key: "Salariu/Ora", tip: "number" },
    { key: "Email", tip: "email" },
    { key: "DataAngajarii", tip: "datebox" },
    { key: "DataPlecarii", tip: "datebox" },
    { key: "Prioritate", tip: "number" },
    { key: "EditareRapoarte", tip: "bool" },
    { key: "SalvarePreferinta", tip: "bool" },
    { key: "IntrebareSalvare", tip: "bool" },
    { key: "RetineLogin", tip: "bool" },
    { key: "RolDeAplicatie", tip: "selectbox", lista: sources.tipSelectbox },
];

export const salariatiCheltuieli = [
    { key: "Salariat", tip: "selectbox", lista: sources.tipAngajati },
    { key: "Timp_ore", tip: "number" },
    { key: "Salariu_ora", tip: "number" },
    { key: "CheltuieliDirecte", tip: "number" },
    { key: "CheltuieliIndirecte", tip: "number" },
    { key: "CheltuieliInd", tip: "number" },
    { key: "Total", tip: "number" },
];

export const CheltuieliProiecteSalariati = [
    { key: "Salariat" },
    { key: "Proiect" },
    { key: "Timp", tip: "number" },
    { key: "SalariuOra", tip: "number" },
    { key: "CheltuieliDirecte", tip: "number" },
    { key: "CheltuieliIndirecte", tip: "number" },
    { key: "CheltuieliIndirecte2", tip: "number" },
    { key: "Total", tip: "number" }
];

export const cheltuieliSalariatDepartament = [
    { key: "Salariat" },
    { key: "SalariuOra", tip: "number" },
    { key: "Timp", tip: "number" },
    { key: "CheltuieliDirecte", tip: "number" },
    { key: "CheltuieliIndirecte", tip: "number" },
    { key: "CheltuieliIndirecte1", tip: "number" }
];

export const salariatiVenituri = [
    { key: "NumeSalariat" },
    { key: "AniPrecedenti", tip: "number" },
    { key: "AnCurent", tip: "number" },
    { key: "LunaCurenta", tip: "number" },
];

export const logCurente = [
    { key: "NumeLogin" },
    { key: "Computer" },
    { key: "UtilizatorComp" },
    { key: "IP" },
    { key: "DataIntrare", tip: "datebox" },
    { key: "DataIesire", tip: "datebox" },
];

export const IntretinereClienti = [
    { key: "Id_Firma", tip: "number" },
    { key: "Denumire" },
    { key: "Email", tip: "email" },
    { key: "Adresa" },
    { key: "Telefon", tip: "phone" },
    { key: "Persoana_Contact" },
    { key: "Ref_Firma", tip: "number" },
];

export const IntretinereClientiRaport = [
    { key: "IdRapoarte", tip: "number" },
    { key: "Denumire" },
    { key: "DataPrintare", tip: "datebox" }
];

export const IntretinereClientiEvenimente = [
    { key: "DataOra", tip: "datebox" },
    { key: "Rezumat" },
    { key: "IdTipEveniment", tip: "selectbox", lista: sources.tipSelectbox },
];

export const IntretinereClientiTarife = [
    { key: "TipOperatie", tip: "selectbox", lista: sources.tipSelectbox },
    { key: "Suma", tip: "number" },
    { key: "DataStart", tip: "datebox" },
    { key: "DataSfarsit", tip: "datebox" },
];

export const ActivitateSalariatiProiecte = [
    { key: "DepSalariatProiect" },
    { key: "Procent" },
    { key: "Intarziere" },
    { key: "TimpLucrat" },
    { key: "TimpPlanificat" },
    { key: "Stadiu" },
];

export const bugeteActivitate = [
    { key: "Nume", tip: "selectbox", lista: sources.tipAngajati },
    { key: "BugetTotal", tip: "number" },
    { key: "CheltuieliTotale", tip: "number" },
    { key: "BugetProiecte", tip: "number" },
    { key: "CheltuieliProiecte", tip: "number" },
];

export const repartitieSalariati = [
    { key: "Id", tip: "number" },
    { key: "Ref", tip: "number" },
    { key: "Salariat" },
    { key: "Ziua", tip: "datebox" },
    { key: "TimpPontaj", tip: "number" },
    { key: "TimpZi", tip: "number" },
    { key: "DataStart", tip: "datebox" },
    { key: "Deadline", tip: "datebox" },
    { key: "Asumat", tip: "bool" },
    { key: "Proiect" },
    { key: "Stadiu" },
    { key: "IntrarePeProiect", tip: "datebox" },
    { key: "IesireDePeProiect", tip: "datebox" },
    { key: "ComentariuRealizare" },
    { key: "Departament" },
];