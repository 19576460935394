import React, { Component } from "react";
import DataGrid from "../../Datagrid/DataGrid";
import { ListInput, List, Row, Col, Icon } from "framework7-react";
import moment from "moment";
import * as inputs from "../../../../inputs";
import axios from "axios";

/**
 * @description Afiseaza un datagrid cu informatiile legate de salariul si timpul fiecarui salariat intr-un interval selectat
 * @date 06/04/2022
 * @class CheltuieliProiecteSalariati
 * @extends {Component}
 */
class CheltuieliProiecteSalariati extends Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;

    this.state = {
      dataLoaded: false,
      height: window.innerHeight - 44,
      dataDeLa: [moment(Date.now()).format()],
      dataPanaLa: [moment(Date.now()).format()],
      linkApi: "/api/CheltuieliProiecteSalariati",
      dataSource: [],
    };
  }
  /**
   * @description Ia valorile necesare pentru randare de la endpointul „/api/CheltuieliSalariatDepartament” caruia i se transmit ca parametrii data de start si de sfarsit.
   * @date 06/04/2022
   * @function
   * @memberof CheltuieliProiecteSalariati
   */
  GetValues = () => {
    //'mm-dd-yy'
    let initialStartData = new Date(this.state.dataDeLa[0]);
    let initialEndData = new Date(this.state.dataPanaLa[0]);
    const dataStart =
      initialStartData.getMonth() +
      1 +
      "-" +
      initialStartData.getDate() +
      "-" +
      initialStartData.getFullYear().toString().substring(2);
    const dataEnd =
      initialEndData.getMonth() +
      1 +
      "-" +
      initialEndData.getDate() +
      "-" +
      initialEndData.getFullYear().toString().substring(2);

    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/" +
          dataStart +
          "/" +
          dataEnd
      )
      .then((response) => {
        let change = response;

        for (let item in change.data) {
          change.data[item]["Salariat"] = change.data[item].numesal;
          change.data[item]["Proiect"] = change.data[item].denumire;
          change.data[item]["Timp"] = change.data[item].timp;
          change.data[item]["SalariuOra"] = change.data[item].salariu_ora;
          change.data[item]["CheltuieliDirecte"] = change.data[item].chelt_dir;
          change.data[item]["CheltuieliIndirecte"] =
            change.data[item].chelt_indir;
          change.data[item]["CheltuieliIndirecte2"] =
            change.data[item].chelt_indir2;
          change.data[item]["Total"] =
            change.data[item].chelt_dir +
            change.data[item].chelt_indir +
            change.data[item].chelt_indir2;
        }

        let baza = this.$f7.methods.reconstructieBaza(
          change,
          inputs.CheltuieliProiecteSalariati
        );

        this.setState(
          {
            dataSource: baza.length === 0 ? [] : baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  //this.refDataGrid.current.setRenderItems(baza)
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <List
          noChevron
          noHairlines
          noHairlinesBetween
          noHairlinesBetweenMd
          noHairlinesMd
          className="optiuniActivitate butoaneContextDatagrid"
          style={{
            position: "absolute",
            top: -8,
            left: 0,
            width: "calc(100% - 176px)",
            margin: 0,
          }}>
          <Row style={{ width: "100%" }}>
            <Col>
              <ListInput
                validate
                required
                label="De la"
                type="datepicker"
                calendarParams={{
                  timePicker: true,
                  dateFormat: "dd.mm.yyyy",
                  closeOnSelect: true,
                  on: {
                    closed: (e) => {
                      const val = e.value[0];
                      this.setState(
                        {
                          dataDeLa: [val],
                        },
                        () => {
                          this.GetValues();
                        }
                      );
                    },
                  },
                }}
                value={this.state.dataDeLa}
                floatingLabel
                placeholder="De la">
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="today"
                  slot="content-start"
                  style={{ left: 0, top: 0 }}></Icon>
              </ListInput>
            </Col>
            <Col>
              <ListInput
                validate
                required
                label="Pana la"
                type="datepicker"
                calendarParams={{
                  timePicker: true,
                  dateFormat: "dd.mm.yyyy",
                  closeOnSelect: true,
                  on: {
                    closed: (e) => {
                      const val = e.value[0];
                      this.setState(
                        {
                          dataPanaLa: [val],
                        },
                        () => {
                          this.GetValues();
                        }
                      );
                    },
                  },
                }}
                value={this.state.dataPanaLa}
                floatingLabel
                placeholder="Pana la">
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="today"
                  slot="content-start"
                  style={{ left: 0, top: 0 }}></Icon>
              </ListInput>
            </Col>
          </Row>
        </List>

        <DataGrid
          name={"CheltuieliProiecteSalariati"}
          dataLoaded={this.state.dataLoaded}
          refresh={this.GetValues}
          //ref={this.refDataGrid}
          renderUpdate={false}
          renderDelete={false}
          renderAdd={false}
          maxHeight={this.state.height}
          dataSource={this.state.dataSource}
          inputs={inputs.CheltuieliProiecteSalariati}
        />
      </div>
    );
  }
}
export default CheltuieliProiecteSalariati;