import React, { Component } from "react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";

/**
 * @description Afiseaza un datagrid cu informatiile legate de veniturile per salariat.
 * @date 06/04/2022
 * @class SalariatiVenituri
 * @extends {Component}
 */
class SalariatiVenituri extends Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.state = {
      inputs: [
        { key: "NumeSalariat" },
        { key: "AniPrecedenti", tip: "number" },
        { key: "AnCurent", tip: "number" },
        { key: "LunaCurenta", tip: "number" },
      ],
      dataLoaded: false,
      height: window.innerHeight - 46,
      linkApi: "/api/SalariatiVenituri",
      salariatiArray: this.$f7.data.Salariati,
      dataSource: [],
    };
  }

  /**
   * @description ia valorile necesare pentru randarea datagridului de la endpointul „this.$f7.data.baseURL + this.state.linkApi”;
   * @function
   * @date 06/04/2022
   * @memberof SalariatiVenituri
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApi)
      .then((response) => {
        let change = response;
        change.data.shift();

        for (let item in change.data) {
          change.data[item]["NumeSalariat"] =
            change.data[item].iD_SALARIAT === null
              ? "Fara"
              : this.state.salariatiArray.filter((obj) => {
                  return obj.value === change.data[item].iD_SALARIAT;
                })[0].key;
          change.data[item]["AniPrecedenti"] =
            change.data[item].anI_PRECEDENTI === "" ||
            change.data[item].anI_PRECEDENTI === null
              ? "Fara"
              : change.data[item].anI_PRECEDENTI;
          change.data[item]["AnCurent"] =
            change.data[item].aN_CURENT === "" ||
            change.data[item].aN_CURENT === null
              ? "Fara"
              : change.data[item].aN_CURENT;
          change.data[item]["LunaCurenta"] =
            change.data[item].lunA_CURENTA === "" ||
            change.data[item].lunA_CURENTA === null
              ? "Fara"
              : change.data[item].lunA_CURENTA;
        }

        let baza = this.$f7.methods.reconstructieBaza(
          change,
          this.state.inputs
        );

        this.setState(
          {
            dataSource: baza.length === 0 ? [] : baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  //this.refDataGrid.current.setRenderItems(baza)
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return (
      <DataGrid
        dataLoaded={this.state.dataLoaded}
        refresh={this.GetValues}
        name={"SalariatiVenituri"}
        //ref={this.refDataGrid}
        renderUpdate={false}
        renderDelete={false}
        renderAdd={false}
        maxHeight={this.state.height}
        dataSource={this.state.dataSource}
        inputs={this.state.inputs}
      />
    );
  }
}
export default SalariatiVenituri;