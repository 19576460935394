import React from "react";
import { Page, Treeview, TreeviewItem } from "framework7-react";
/**
 * @description Pagina de setari: night mode,culoare si tranzitie
 * @date 07/04/2022
 * @class SettingsPage
 * @extends {React.PureComponent}
 */
class SettingsPage extends React.PureComponent {
    render() {
        return (
            <Page>
                <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>

                    <Treeview>
                        <TreeviewItem
                            key={"setari1"}
                            selectable
                            panelClose
                            iconMaterial={"swap_horiz"}>
                            <span
                                onClick={() => {
                                    this.$f7.data.iconita = "menu";
                                    if (this.$f7.data.paginaCurenta !== undefined) {
                                        this.$f7.data.paginaCurenta.setState({ iconita: "menu" })
                                    }
                                    if (this.$f7.data.homePage !== undefined) {
                                        this.$f7.data.homePage.setState({ iconita: "menu" })
                                    }
                                    this.$f7router.navigate("/tranzitie/");
                                }}
                                slot="label">
                                Tranzitie</span></TreeviewItem>
                        <TreeviewItem
                            key={"setari2"}
                            selectable
                            panelClose
                            iconMaterial={"brightness_high"}>
                            <span
                                onClick={() => {
                                    this.$f7.data.iconita = "menu";
                                    if (this.$f7.data.paginaCurenta !== undefined) {
                                        this.$f7.data.paginaCurenta.setState({ iconita: "menu" })
                                    }
                                    if (this.$f7.data.homePage !== undefined) {
                                        this.$f7.data.homePage.setState({ iconita: "menu" })
                                    }
                                    this.$f7.methods.nightMode();
                                }}
                                slot="label">
                                Mod noapte</span></TreeviewItem>
                        <TreeviewItem
                            key={"setari3"}
                            selectable
                            panelClose
                            iconMaterial={"color_lens"}>
                            <span
                                onClick={() => {
                                    this.$f7.data.iconita = "menu";
                                    if (this.$f7.data.paginaCurenta !== undefined) {
                                        this.$f7.data.paginaCurenta.setState({ iconita: "menu" })
                                    }
                                    if (this.$f7.data.homePage !== undefined) {
                                        this.$f7.data.homePage.setState({ iconita: "menu" })
                                    }
                                    this.$f7router.navigate("/colorpicker/");
                                }}
                                slot="label">
                                Culoare tema</span></TreeviewItem>
                        <TreeviewItem
                            key={"setari4"}
                            selectable
                            panelClose
                            iconMaterial={"security"}>
                            <span
                                onClick={() => {
                                    this.$f7.data.iconita = "menu";
                                    if (this.$f7.data.paginaCurenta !== undefined) {
                                        this.$f7.data.paginaCurenta.setState({ iconita: "menu" })
                                    }
                                    if (this.$f7.data.homePage !== undefined) {
                                        this.$f7.data.homePage.setState({ iconita: "menu" })
                                    }
                                    this.$f7router.navigate("/schimbareParola/");
                                }}
                                slot="label">
                                Schimbare parola</span></TreeviewItem>

                    </Treeview>

                </div>
            </Page>
        );
    }
}
export default SettingsPage;