import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import { Segmented, Button, List, ListItem } from "framework7-react";
import axios from "axios";
import moment from "moment";
import ATMain from "../../AlocareTask/ATMain";

/**
 * @description Afiseaza interfata cu toate taskurile nealocate din departamentul selectat
 * @date 08/04/2022
 * @class SarciniNealocateGrid
 * @extends {React.Component}
 */
class SarciniNealocateGrid extends React.Component {
  constructor(props) {
    super(props);

    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;

    this.state = {
      idGet: this.$f7.data.user.administrator
        ? -100
        : this.$f7.data.user.iD_SALARIAT,
      dataLoaded: false,
      denumire: "Sarcini nealocate",
      height: window.innerHeight - 96,
      linkApi: "/api/SarciniSalariat",
      linkApiGetProiect: "/api/Proiecte/GetPROIECTESauArhivate",
      linkApiUpdateProiect: "/api/Proiecte/PutPROIECTE",
      dataSource: [],
      inputs: [
        { key: "💾", keyBaza: "areDocumente", tip: "boolean", disabled: true },
        { key: "IdProiect", keyBaza: "id", tip: "number" },
        { key: "Denumire", keyBaza: "denumire", columnWidth: "250px" },
        { key: "Cerinta", keyBaza: "comentariu", columnWidth: "500px" },
        { key: "Parinte", keyBaza: "parinte" },
        {
          key: "DataCreare",
          keyBaza: "dataCreare",
          tip: "datebox",
          columnWidth: "100px",
        },
        { key: "Client", keyBaza: "client", columnWidth: "20px" },
        { key: "Sursa", keyBaza: "sursa", columnWidth: "20px" },
        { key: "Deadline", keyBaza: "deadline", tip: "datebox" },
        {
          key: "PR",
          keyBaza: "idPrioritate",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
          columnWidth: "20px",
        },
        {
          key: "IsHidden",
          keyBaza: "isHidden",
          columnWidth: "20px",
          disabled: true,
          tip: "bool",
        },
        {
          key: "IsArhivat",
          keyBaza: "isArhivat",
          columnWidth: "20px",
          disabled: true,
          tip: "bool",
        },
        {
          key: "NivelProiect",
          keyBaza: "id_nivel_proiect",
          tip: "selectbox",
          lista: this.$f7.data.NivelProiect,
        },
        { key: "UltimaStare", keyBaza: "ultimaStare", tip: "number" },
        { key: "Path", keyBaza: "path" },
        {
          key: "OperatorAdaugare",
          keyBaza: "id_operator_adaugare",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
        { key: "BugetTimp", keyBaza: "buget_timp", tip: "number" },
        { key: "TimpPlanD", keyBaza: "timp_plan_d", tip: "number" },
        { key: "TimpLucrat", keyBaza: "timp_l", tip: "number" },
        { key: "PVuri", keyBaza: "apartenenta_pv" },
      ],

      idSelected: null,
    };

    this.refDataGrid = React.createRef();
  }

  /**
   * @description Inchide taskul selectat,adauga sarcina cu el inchis
   * @function
   * @date 08/04/2022
   * @param {*} id id-ul proiectului
   * @memberof SarciniNealocateGrid
   */
  InchideTaskVerificat = (id) => {
    return new Promise((resolve, reject) => {
      var idSelected = id !== undefined ? id : this.state.idSelected;
      let url =
        this.$f7.data.baseURL + this.state.linkApi + "/PostSARCINI_SALARIAT";
      let sarcinaRezolvata = {
        iD_PROIECT: idSelected,
        iD_SALARIAT: this.$f7.data.user.iD_SALARIAT,
        dE_CINE: this.$f7.data.user.iD_SALARIAT,
        datA_ALOCARII: moment().format(),
        dATA_DEALOCARII: null,
        dealoC_CINE: null,
        stare: 4,
      };

      axios({
        data: sarcinaRezolvata,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response3) => {
          axios
            .get(
              this.$f7.data.baseURL +
                this.state.linkApiGetProiect +
                "/" +
                idSelected
            )
            .then((responseProiect) => {
              let proiect = responseProiect.data;

              proiect.iD_STADIU = 6;

              let urlUpdateProiect =
                this.$f7.data.baseURL +
                this.state.linkApiUpdateProiect +
                "/" +
                idSelected;

              axios({
                data: proiect,
                headers: { "Content-Type": "application/json" },
                method: "put",
                url: urlUpdateProiect,
              })
                .then((response4) => {
                  resolve(response4);
                })
                .catch((error) => {
                  reject(error);
                });
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Parcurge lista de taskuri selectate si apeleaza functia InchideTaskVerificat pentru fiecare
   * @function
   * @date 08/04/2022
   * @memberof SarciniNealocateGrid
   */
  InchideCall = () => {
    this.$f7.methods
      .preCall(this.refDataGrid)
      .then((response) => {
        var state = this.refDataGrid.current.state;
        if (state.allSelectedRows.length > 0) {
          this.$f7.dialog.confirm(
            "Sunteti sigur ca doriti sa inchideti toate taskurile selectate?",
            "Warning",
            () => {
              this.$f7.preloader.show();
              state.allSelectedRows
                .reduce((promise, item) => {
                  return promise.then(() => {
                    return this.InchideTaskVerificat(item.IdProiect)
                      .then((r) => {})
                      .catch((err) => {
                        //console.log("err", err);
                      });
                  });
                }, Promise.resolve())
                .then(() => {
                  this.GetValues()
                    .then((r) => {
                      this.$f7.preloader.hide();
                      this.$f7.methods.openNotification("Taskuri inchise");
                    })
                    .catch((error) => {
                      this.$f7.preloader.hide();
                      this.$f7.methods.openNotification("Eroare: " + error);
                    });
                })
                .catch((error) => {
                  this.$f7.methods.openNotification("Eroare: " + error);
                  console.log("Eroare");
                });
            },
            () => {}
          );
        } else if (this.state.idSelected) {
          this.InchideTaskVerificat(this.state.idSelected)
            .then((response) => {
              this.GetValues()
                .then((r) => {
                  this.$f7.preloader.hide();
                  this.$f7.methods.openNotification("Task inchis.");
                })
                .catch((error) => {
                  this.$f7.preloader.hide();
                  this.$f7.methods.openNotification("Eroare: " + error);
                });
            })
            .catch((error) => {
              this.$f7.methods.openNotification("Eroare: " + error);
              console.log("error", error);
            });
        } else {
          this.$f7.methods.openNotification("Selectati o sarcina");
        }
      })
      .catch((err) => {
        console.log("err");
        this.$f7.methods.openNotification("Eroare" + err);
      });
  };

  /**
   * @description Deschide interfata de propunere task pentru taskul selectat
   * @function
   * @date 08/04/2022
   * @memberof SarciniNealocateGrid
   */
  PropuneCall = () => {
    var state = this.refDataGrid.current.state;

    if (this.state.idSelected !== null) {
      this.$f7.dialog.confirm(
        "Sunteti sigur ca doriti sa propuneti acestui salariat sarcinile selectate?",
        "Propunere",
        (e) => {
          this.$f7.methods
            .preCall(this.refDataGrid)
            .then((response) => {
              var listaIduri = null;
              if (state.selectedRows.length > 0) {
                listaIduri = [];
                state.selectedRows.map((item) => {
                  listaIduri.push(item.IdProiect);
                  return null;
                });
              } else {
                listaIduri = this.state.idSelected;
              }

              this.$f7.data.ATidProiect = listaIduri;
              this.$f7.data.router.navigate("/popupDinamic/", {
                props: {
                  onPopupClose: (r) => {
                    this.$f7.methods.refreshStanga();
                  },
                  component: <ATMain Proiect={null} idProiect={null} />,
                },
              });
            })
            .catch((err) => {
              this.$f7.methods.openNotification("Eroare" + err.toString());
            });
        }
      );
    } else {
      this.$f7.methods.openNotification("Trebuie selectata o sarcina.");
    }
  };

  /**
   * @description Parcurge lista de taskuri selectate si apeleaza functia de asumare pentru fiecare in parte
   * @function
   * @date 08/04/2022
   * @memberof SarciniNealocateGrid
   */
  PreiaCall = () => {
    var state = this.refDataGrid.current.state;
    this.$f7.dialog.confirm(
      "Sunteti sigur ca doriti sa va asumati toate sarcinile selectate?",
      "Propunere",
      (e) => {
        this.$f7.methods
          .preCall(this.refDataGrid)
          .then((response) => {
            console.log(state.selectedRows, state);
            var lista = state.selectedRows;
            if (lista.length > 0) {
              console.log("1");
              lista
                .reduce((promise, item) => {
                  return promise.then(() => {
                    return this.AsumaTask(item.IdProiect)
                      .then((r) => {
                        console.log("done");
                      })
                      .catch((err) => {
                        console.log("err", err);
                      });
                  });
                }, Promise.resolve())
                .then(() => {
                  this.GetValues();
                  this.$f7.methods.openNotification("Taskuri preluate.");
                })
                .catch(() => {
                  console.log("Eroare");
                });
            } else {
              console.log("2");
              this.AsumaTask()
                .then((response) => {
                  this.GetValues();
                  this.$f7.methods.openNotification("Task preluat.");
                })
                .catch((error) => {
                  this.$f7.methods.openNotification("Eroare!");
                  console.log("error", error);
                });
            }
          })
          .catch((err) => {
            alert(1);
            this.$f7.methods.openNotification("Eroare" + err.toString());
          });
      }
    );
  };

  /**
   * @description Adauga o sarcina pentru salariatul curent la proiectul selectat
   * @date 08/04/2022
   * @param {*} idSelected id-ul proiectului selectat
   * @memberof SarciniNealocateGrid
   */
  AsumaTask = (idSelected) => {
    return new Promise((resolve, reject) => {
      var idSelectedLocal =
        idSelected !== undefined ? idSelected : this.state.idSelected;
      console.log("id", idSelectedLocal, idSelected);
      if (idSelectedLocal) {
        axios
          .get(
            this.$f7.data.baseURL +
              this.state.linkApi +
              "/GetSARCINI_SALARIATRespinsByProiect/" +
              idSelectedLocal
          )
          .then((response1) => {
            let sarcina = response1.data;

            let url =
              this.$f7.data.baseURL +
              this.state.linkApi +
              "/PostSARCINI_SALARIAT";
            let sarcinaAsumata = {
              iD_PROIECT: parseInt(idSelectedLocal),
              iD_SALARIAT: this.$f7.data.user.iD_SALARIAT,
              dE_CINE: this.$f7.data.user.iD_SALARIAT,
              datA_ALOCARII: moment().format(),
              datA_DEALOCARII: null,
              dealoC_CINE: null,
              stare: 1,
            };

            if (sarcina === "") {
              axios({
                data: sarcinaAsumata,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: url,
              })
                .then((response2) => {
                  resolve(response2);
                })
                .catch((error) => {
                  console.log("error", error);
                  this.$f7.methods.openNotification("Eroare la inserare");
                  reject(error);
                });
            } else {
              sarcina.datA_DEALOCARII = moment().format();
              sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT;

              let urlUpdate =
                this.$f7.data.baseURL +
                this.state.linkApi +
                "/PutSARCINI_SALARIAT/" +
                sarcina.iD_SARCINA;

              axios({
                data: sarcina,
                headers: { "Content-Type": "application/json" },
                method: "put",
                url: urlUpdate,
              })
                .then((response3) => {
                  axios({
                    data: sarcinaAsumata,
                    headers: { "Content-Type": "application/json" },
                    method: "post",
                    url: url,
                  })
                    .then((response4) => {
                      resolve(response4);
                    })
                    .catch((error) => {
                      console.log("error", error);
                      this.$f7.methods.openNotification("Eroare la inserare");
                      reject(error);
                    });
                })
                .catch((error) => {
                  console.log("error", error);
                  this.$f7.methods.openNotification("Eroare la actualizare");
                  reject(error);
                });
            }
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la preluare");
            reject(error);
          });
      } else {
        this.$f7.methods.openNotification("Selectati o sarcina");
        reject("Selectati o sarcina");
      }
    });
  };

  componentDidMount = () => {
    this.$f7.data.selectedGridLeft = this;
    this.GetValues();
  };

  /**
   * @description Ia lista de taskuri nealocate in functie de departament
   * @function
   * @date 08/04/2022
   * @memberof SarciniNealocateGrid
   */
  GetValues = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          dataLoaded: false,
          idSelected: null,
        },
        () => {
          this.$f7.methods.openMainDreaptaById(null);
        }
      );

      this.$f7.methods
        .getProiecte(
          this.state.idGet,
          3,
          this.props.checkedTaskuri === true ? 1 : 0,
          this.props.checkedAscunse === true ? 1 : 0,
          this.props.checkedArhivate === true ? 1 : 0,
          1
        )
        .then((response) => {
          var date = response;
          date.data.map((sarcina) => {
            //Valori din proiect
            if (sarcina.proiect) {
              Object.keys(sarcina.proiect).map((property) => {
                var value = sarcina.proiect[property];
                if (sarcina[property] === undefined) {
                  sarcina[property] = value;
                }
                return null;
              });
              sarcina["path"] = sarcina.proiect["path"];
              if (sarcina["path"]) {
                var path = "";
                sarcina["path"].split("\\").map((f) => {
                  if (f !== null && f !== undefined) {
                    var idProiect = parseInt(f);
                    var pro = this.$f7.data.allProiecte.find((p) => {
                      return p.iD_PROIECT === idProiect;
                    });
                    if (pro) {
                      path += pro.denumire + " \\ ";
                    }
                  }
                  return null;
                });
                sarcina["path"] = path.slice(0, -2);
              }
            }

            sarcina = this.$f7.methods.restructurareBaza(
              sarcina,
              this.state.inputs,
              "key"
            );
            //sarcina.Sursa = sarcina.Sursa === "55" ? "Mantis" : "Cronos"

            switch (sarcina.Sursa) {
              case 55:
                sarcina.Sursa =
                  "M(" + (sarcina.proiect.userImport % 10000000) + ")";
                break;
              case 56:
                sarcina.Sursa = "E";
                break;
              default:
                sarcina.Sursa = "C";
                break;
            }
            return null;
          });
          var baza = this.$f7.methods.reconstructieBaza(
            date,
            this.state.inputs
          );

          this.setState(
            {
              dataSource: baza,
            },
            () => {
              setTimeout(() => {
                this.setState(
                  {
                    dataLoaded: true,
                  },
                  () => {
                    this.refDataGrid.current.setRenderItems(baza);
                    resolve(response);
                  }
                );
              }, 400);
            }
          );
        })
        .catch((error) => {
          reject(error);
          this.setState(
            {
              dataSource: [],
              dataLoaded: true,
            },
            () => {
              this.refDataGrid.current.setRenderItems([]);
              this.$f7.dialog.confirm(
                "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                "Ups...",
                () => {
                  this.GetValues();
                },
                () => {}
              );
            }
          );

          console.log("error", error);
        });
    });
  };

  render() {
    return (
      <>
        <div style={{ position: "relative" }}>
          <Segmented
            className="butoaneContextDatagrid"
            raised
            tag="div"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "calc(100% - 176px)",
            }}>
            {this.$f7.data.rol <= 3 ? (
              <Button
                tooltip={"Propune task unui salariat"}
                fill
                small
                onClick={() => {
                  this.PropuneCall();
                }}>
                Propune cuiva
              </Button>
            ) : null}
            <Button
              tooltip={"Preia taskul"}
              fill
              small
              onClick={() => {
                this.PreiaCall();
              }}>
              Preia task
            </Button>
            {this.$f7.data.rol === 1 ? (
              <>
                <Button
                  tooltip={"Afiseaza toate departamentele"}
                  fill
                  small
                  onClick={() => {
                    var id =
                      this.state.idGet === -100
                        ? this.$f7.data.user.iD_SALARIAT
                        : -100;
                    this.setState({ idGet: id }, () => {
                      this.GetValues();
                    });
                  }}>
                  {this.state.idGet === -100
                    ? "Afiseaza dep meu"
                    : "Afiseaza tot"}
                </Button>
                <Button
                  tooltip={"Inchide taskuri selectate"}
                  fill
                  small
                  onClick={() => {
                    this.InchideCall();
                  }}>
                  Inchide
                </Button>
              </>
            ) : null}
            <Button
              tooltip={"Refresh"}
              fill
              small
              onClick={() => {
                this.GetValues();
              }}>
              Refresh
            </Button>
          </Segmented>

          <DataGrid
            dataLoaded={this.state.dataLoaded}
            refresh={this.GetValues}
            name={"SarciniNealocateGrid"}
            ref={this.refDataGrid}
            id={"IdProiect"}
            onRowClick={(state) => {
              var id = state.rowData.IdProiect;
              this.setState(
                {
                  idSelected: id,
                },
                () => {
                  this.$f7.methods.openMainDreaptaById(this.state.idSelected);
                }
              );
            }}
            keys={[
              "💾",
              "Denumire",
              "Cerinta",
              "DataCreare",
              "Client",
              "Sursa",
              "PR",
            ]}
            ignoredInputsAdd={["Deadline"]}
            ignoreInputsEdit={["Deadline"]}
            hintInputSources={[
              "Path",
              "OperatorAdaugare",
              "BugetTimp",
              "TimpPlanD",
              "PVuri",
            ]}
            renderUpdate={false}
            renderDelete={false}
            renderAdd={false}
            renderCheckbox={true}
            maxHeight={this.state.height}
            inputs={this.state.inputs}
            dataSource={this.state.dataSource}
            onInitialised={(state, r, itemeRandate) => {
              this.$f7.methods.colorItems(
                state,
                r,
                itemeRandate,
                "AdaugatDe",
                1,
                true
              );
            }}
            onRenderItemsChanged={(state, r, itemeRandate) => {
              this.$f7.methods.colorItems(
                state,
                r,
                itemeRandate,
                "AdaugatDe",
                0,
                true
              );
            }}
            contextMenuItems={[
              this.$f7.data.rol <= 3
                ? {
                    key: "Propune unui salariat",
                    onClick: (state) => {
                      setTimeout(() => {
                        this.PropuneCall();
                      }, 100);
                    },
                    icon: "send",
                  }
                : null,
              {
                key: "Preia task",
                onClick: (state) => {
                  setTimeout(() => {
                    this.PreiaCall();
                  }, 1000);
                },
                icon: "playlist_add_check",
              },
              this.$f7.data.rol === 1
                ? {
                    key: "Afiseaza tot",
                    onClick: (state) => {
                      setTimeout(() => {
                        this.GetValues(-100);
                      }, 1000);
                    },
                    icon: "refresh",
                  }
                : null,
              this.$f7.data.rol === 1
                ? {
                    key: "Inchide",
                    onClick: (state) => {
                      setTimeout(() => {
                        this.InchideCall();
                      }, 1000);
                    },
                    icon: "refresh",
                  }
                : null,
              {
                key: "Refresh",
                onClick: (state) => {
                  setTimeout(() => {
                    this.GetValues();
                  }, 1000);
                },
                icon: "refresh",
              },
              {
                key: "Arhiveaza",
                onClick: (state) => {
                  setTimeout(() => {
                    var selectedRows = state.allSelectedRows;
                    if (selectedRows.length === 0) {
                      selectedRows = [state.rowDataAll];
                    }
                    var listaIduri = [];
                    selectedRows.map((r) => {
                      var id = r.IdProiect;
                      if (!r.IsArhivat) {
                        listaIduri.push(id);
                      }
                      return null;
                    });
                    this.$f7.methods
                      .arhiveazaDezarhiveazaProiect(listaIduri, true)
                      .then((r) => {
                        this.GetValues();
                        const nr = r.data.length;
                        this.$f7.methods.openNotification(
                          "Arhivate cu success: " + nr
                        );
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare la arhivare" + err
                        );
                      });
                  }, 1000);
                },
                icon: "compress",
              },
              {
                key: "Dezarhiveaza",
                onClick: (state) => {
                  setTimeout(() => {
                    var selectedRows = state.allSelectedRows;
                    if (selectedRows.length === 0) {
                      selectedRows = [state.rowDataAll];
                    }
                    var listaIduri = [];
                    selectedRows.map((r) => {
                      var id = r.IdProiect;
                      if (r.IsArhivat) {
                        listaIduri.push(id);
                      }
                      return null;
                    });
                    this.$f7.methods
                      .arhiveazaDezarhiveazaProiect(listaIduri, false)
                      .then((r) => {
                        this.GetValues();
                        const nr = r.data.length;
                        this.$f7.methods.openNotification(
                          "Dezarhivate cu success: " + nr
                        );
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare la dezarhivare" + err
                        );
                      });
                  }, 1000);
                },
                icon: "expand",
              },
              {
                key: "Ascunde",
                onClick: (state, context) => {
                  setTimeout(() => {
                    var selectedRows = state.allSelectedRows;
                    if (selectedRows.length === 0) {
                      selectedRows = [state.rowDataAll];
                    }
                    var listaIduri = [];
                    selectedRows.map((r) => {
                      var id = r.IdProiect;
                      if (!r.IsHidden) {
                        listaIduri.push(id);
                      }
                      return null;
                    });
                    this.$f7.methods
                      .ascundeAfiseazaProiect(listaIduri, true)
                      .then(() => {
                        this.GetValues();
                        this.$f7.methods.openNotification("Ascuns cu success");
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare la ascundere" + err
                        );
                      });
                  }, 1000);
                },
                icon: "refresh",
              },
              {
                key: "Afiseaza",
                onClick: (state, context) => {
                  setTimeout(() => {
                    var selectedRows = state.allSelectedRows;
                    if (selectedRows.length === 0) {
                      selectedRows = [state.rowDataAll];
                    }
                    var listaIduri = [];
                    selectedRows.map((r) => {
                      var id = r.IdProiect;
                      if (r.IsHidden) {
                        listaIduri.push(id);
                      }
                      return null;
                    });

                    this.$f7.methods
                      .ascundeAfiseazaProiect(listaIduri, false)
                      .then(() => {
                        this.GetValues();
                        this.$f7.methods.openNotification("Afisat cu success");
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare la afisare" + err
                        );
                      });
                  }, 1000);
                },
                icon: "refresh",
              },
              {
                key: "Trimite mesaj",
                onClick: (state) => {
                  var id = state.rowData.IdProiect;
                  this.$f7.methods.openMesaje(null, id);
                },
                icon: "send",
              },
              {
                key: "Detalii Task",
                icon: "arrow",
                onClick: (state) => {
                  setTimeout(() => {
                    const id = state.rowData.IdProiect;
                    this.$f7.views.main.router.navigate("/detaliiTask/", { props: { idProiect: id } })
                  }, 500)
                }
              }
            ]}
            onContextMenu={(state, target) => {
              var id = state.rowData.IdProiect;
              this.setState(
                {
                  idSelected: id,
                },
                () => {
                  this.$f7.methods.openMainDreaptaById(this.state.idSelected);
                }
              );
            }}
            coloredRow={{
              sourceCol: "Deadline",
              type: "date",
            }}
          />

          <List style={{ display: "none" }}>
            <ListItem
              className="smSe"
              validate
              smartSelect
              smartSelectParams={{
                virtualList: true,
                searchbar: true,
                openIn: "popup",
                popupTabletFullscreen: true,
                popupPush: true,
                closeOnSelect: true,
              }}>
              <select name="SelectBoxBool">
                {this.Salariati.map((obj, index) => (
                  <option
                    key={"listaSalariatiSarciniNealocateGrid-" + index}
                    selected={this.Salariati[0].value}
                    value={obj.value}>
                    {obj.key}
                  </option>
                ))}
              </select>
            </ListItem>
          </List>
        </div>
      </>
    );
  }
}
export default SarciniNealocateGrid;
