import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import * as inputs from "../../../../inputs";
import { Row, Col, Preloader } from "framework7-react";
import axios from "axios";

/**
 * @description Interfata de intretinere a contractelor
 * @date 06/04/2022
 * @class Contracte
 * @extends {React.Component}
 */
class Contracte extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoaded: false,
      dataLoadedModule: false,
      dataLoadedOperatiuni: false,
      linkApi: "/api/Contracte",
      denumire: "Contracte",
      height: (window.innerHeight - 34) / 2,
      contractId: null,
      dataSource1: [],
      dataSource2: [],
      dataSource3: [],
      input1: [
        { key: "IdContracte", tip: "number" },
        { key: "Numar", requiredAdd: true, requiredEdit: true },
        {
          key: "Data_Contract",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "Act_Aditional" },
        {
          key: "Client",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Firma",
          tip: "selectbox",
          lista: this.$f7.data.Angajatori,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Valabilitate",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Valoare",
          tip: "number",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Garantie",
          tip: "number",
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "Expirare_Garantie", tip: "datebox" },
        { key: "Data_PV", tip: "datebox" },
        { key: "Tip_Abonament", tip: "bool" },
        { key: "Penalitati_Ei", tip: "number" },
        { key: "Penalitati_Noi", tip: "number" },
        { key: "Comentariu" },
      ],
      input2: [
        { key: "IdContracteDetalii", tip: "number" },
        {
          key: "Operatiune",
          tip: "selectbox",
          lista: this.$f7.data.Operatiuni,
        },
        { key: "Tarif", tip: "number" },
      ],
    };
  }

  /**
   * @description Ia toate contractele
   * @date 06/04/2022
   * @function
   * @memberof Contracte
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/Getcontracte")
      .then((response) => {
        var change = response;

        change.data.map((el, index) => {
          el["Client"] = el.firme.iD_FIRMA;
          el["Firma"] = el.angajatori.iD_ANGAJATOR;
          return false;
        });

        var baza = this.$f7.methods.reconstructieBaza(
          change,
          this.state.input1
        );

        this.setState(
          {
            dataSource1: baza,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource1: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Adauga o noua inregistrare pentru interfata de intretinere curenta
   * @date 06/04/2022
   * @function
   * @param {*} data noile informatii
   * @memberof Contracte
   */
  AddValue = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApi + "/Postcontracte";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza inregistrare pentru interfata de intretinere curenta
   * @date 06/04/2022
   * @function
   * @param {*} obj noile valori
   * @param {*} id id-ul contractului selectat
   * @memberof Contracte
   */
  UpdateValue = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/Getcontracte/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL + this.state.linkApi + "/Putcontracte/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Sterge o inregistrare din interfata de intretinere curenta
   * @date 06/04/2022
   * @function
   * @param {*} id id-ul contractului
   * @memberof Contracte
   */
  DeleteValue = (id) => {
    var url =
      this.$f7.data.baseURL + this.state.linkApi + "/Deletecontracte/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  /**
   * @description Ia modulele pentru contractul selectat
   * @date 06/04/2022
   * @function
   * @param {*} id id-ul contractului
   * @memberof Contracte
   */
  GetValuesModule = (id) => {
    this.setState({
      dataLoadedModule: false,
    });

    axios
      .get(this.$f7.data.baseURL + "/api/ContracteModule/" + id)
      .then((response) => {
        var baza = this.$f7.methods.reconstructieBaza(
          response,
          inputs.IntretinereContracteModule
        );

        this.setState(
          {
            dataSource3: response.data.length > 0 ? baza : [],
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoadedModule: true,
              });
            }, 250);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource3: [],
            dataLoadedModule: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor despre module de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesModule(this.state.contractId);
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Adauga un modul la contractul selectat
   * @date 06/04/2022
   * @function
   * @param {*} data informatiile modulului
   * @memberof Contracte
   */
  AddValueModule = (data) => {
    var url = this.$f7.data.baseURL + "/api/ContracteModule";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValuesModule(this.state.contractId);
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza un modul atasat unui contract
   * @date 06/04/2022
   * @param {*} data noile informatii
   * @param {*} id id-ul modulului
   * @memberof Contracte
   */
  UpdateValueModule = (data, id) => {
    var url = this.$f7.data.baseURL + "/api/ContracteModule/" + id;
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "put",
      url: url,
    })
      .then((response) => {
        this.GetValuesModule(this.state.contractId);
        this.$f7.methods.openNotification("Modificat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la modificare");
      });
  };

  /**
   * @description Sterge o inregistare de tip modul
   * @date 06/04/2022
   * @function
   * @param {*} id id-ul modulului
   * @memberof Contracte
   */
  DeleteValueModule = (id) => {
    var url = this.$f7.data.baseURL + "/api/ContracteModule/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValuesModule(this.state.contractId);
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  /**
   * @description Ia operatiunile de la un anumit contract
   * @date 06/04/2022
   * @function
   * @param {*} id id-ul contractului
   * @memberof Contracte
   */
  GetValuesOperatiuni = (id) => {
    this.setState({
      dataLoadedOperatiuni: false,
    });

    axios
      .get(this.$f7.data.baseURL + "/api/ContracteDetalii/" + id)
      .then((response) => {
        var change = response;

        change.data.map((el, index) => {
          el["Operatiune"] = el.tiP_OPERATIUNI.iD_OPERATIUNE;
          return false;
        });

        var baza = this.$f7.methods.reconstructieBaza(
          change,
          this.state.input2
        );

        this.setState(
          {
            dataSource2: response.data.length > 0 ? baza : [],
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoadedOperatiuni: true,
              });
            }, 250);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource2: [],
            dataLoadedOperatiuni: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor despre operatiuni de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesOperatiuni(this.state.contractId);
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Adauga o operatiune la contractul selectat
   * @date 06/04/2022
   * @function
   * @param {*} data informatiile operatiunii
   * @memberof Contracte
   */
  AddValueOperatiuni = (data) => {
    var url = this.$f7.data.baseURL + "/api/ContracteDetalii";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValuesOperatiuni(this.state.contractId);
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza o operatiune de la contractul selectat
   * @date 06/04/2022
   * @function
   * @param {*} data noile informatii
   * @param {*} id id-ul operatiunii
   * @memberof Contracte
   */
  UpdateValueOperatiuni = (data, id) => {
    var url = this.$f7.data.baseURL + "/api/ContracteDetalii/" + id;
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "put",
      url: url,
    })
      .then((response) => {
        this.GetValuesOperatiuni(this.state.contractId);
        this.$f7.methods.openNotification("Modificat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la modificare");
      });
  };

  /**
   * @description Sterge o inregistrare de tip operatiune de la contractul selectat
   * @date 06/04/2022
   * @function
   * @param {*} id id-ul operatiunii
   * @memberof Contracte
   */
  DeleteValueOperatiuni = (id) => {
    var url = this.$f7.data.baseURL + "/api/ContracteDetalii/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValuesOperatiuni(this.state.contractId);
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return (
      <>
        <Row>
          <Col>
            <DataGrid
              dataLoaded={this.state.dataLoaded}
              refresh={this.GetValues}
              name={"Contracte1"}
              id={"IdContracte"}
              title={"Contracte"}
              renderUpdate={true}
              renderDelete={true}
              renderAdd={true}
              maxHeight={this.state.height}
              inputs={this.state.input1}
              dataSource={this.state.dataSource1}
              onAdd={(state) => {
                var data = { ...state.editedItem };

                data["id_angajator"] = data.Firma;
                data["id_client"] = data.Client;

                delete data.IdContracte;
                delete data.Firma;
                delete data.Client;

                this.AddValue(data);
              }}
              onDelete={(state) => {
                var data = { ...state.editedItem };
                this.DeleteValue(data.IdContracte);
              }}
              onUpdate={(state) => {
                var data = { ...state.editedItem };

                data["id_angajator"] = data.Firma;
                data["id_client"] = data.Client;

                delete data.Firma;
                delete data.Client;

                this.UpdateValue(data, data.IdContracte);
              }}
              onRowClick={(e) => {
                this.setState(
                  {
                    contractId: e.rowData.IdContracte,
                  },
                  () => {
                    this.GetValuesModule(this.state.contractId);
                    this.GetValuesOperatiuni(this.state.contractId);
                  }
                );
              }}
            />
          </Col>
        </Row>

        {this.state.contractId !== null ? (
          <Row style={{ position: "relative" }}>
            <Col style={{ position: "relative" }}>
              <DataGrid
                dataLoaded={this.state.dataLoadedOperatiuni}
                name={"Contracte2"}
                title={"Operatiuni"}
                renderUpdate={true}
                renderDelete={true}
                renderAdd={true}
                maxHeight={this.state.height}
                inputs={this.state.input2}
                dataSource={this.state.dataSource2}
                onAdd={(state) => {
                  var data = { ...state.editedItem };

                  if (this.state.contractId != null) {
                    data["refContracte"] = this.state.contractId;
                    data["id_operatiune"] = data.Operatiune;

                    delete data.IdContracteDetalii;
                    delete data.Operatiune;

                    this.AddValueOperatiuni(data);
                  } else {
                    this.$f7.methods.openNotification(
                      "Nu se poate adauga operatiunea fara un contract selectat!"
                    );
                  }
                }}
                onUpdate={(state) => {
                  var data = { ...state.editedItem };

                  if (this.state.contractId != null) {
                    data["refContracte"] = this.state.contractId;
                    data["id_operatiune"] = data.Operatiune;

                    delete data.Operatiune;

                    this.UpdateValueOperatiuni(data, data.IdContracteDetalii);
                  } else {
                    this.$f7.methods.openNotification(
                      "Nu se poate edita operatiunea fara un contract selectat!"
                    );
                  }
                }}
                onDelete={(state) => {
                  var data = { ...state.editedItem };
                  if (this.state.contractId != null)
                    this.DeleteValueOperatiuni(data.IdContracteDetalii);
                  else
                    this.$f7.methods.openNotification(
                      "Nu se poate sterge operatiunea fara un contract selectat!"
                    );
                }}
              />
            </Col>
            <Col style={{ position: "relative" }}>
              {this.state.dataLoadedModule ? (
                <DataGrid
                  name={"Contracte3"}
                  title={"Module"}
                  renderUpdate={true}
                  renderDelete={true}
                  renderAdd={true}
                  maxHeight={this.state.height}
                  inputs={inputs.IntretinereContracteModule}
                  dataSource={this.state.dataSource3}
                  onAdd={(state) => {
                    var data = { ...state.editedItem };

                    if (this.state.contractId != null) {
                      data.refContracte = this.state.contractId;
                      delete data.IdContracteModule;
                      this.AddValueModule(data);
                    } else {
                      this.$f7.methods.openNotification(
                        "Nu se poate adauga modul fara un contract selectat!"
                      );
                    }
                  }}
                  onDelete={(state) => {
                    var data = { ...state.editedItem };
                    if (this.state.contractId != null)
                      this.DeleteValueModule(data.IdContracteModule);
                    else
                      this.$f7.methods.openNotification(
                        "Nu se poate sterge modulul fara un contract selectat!"
                      );
                  }}
                  onUpdate={(state) => {
                    var data = { ...state.editedItem };

                    if (this.state.contractId != null) {
                      data.refContracte = this.state.contractId;
                      this.UpdateValueModule(data, data.IdContracteModule);
                    } else {
                      this.$f7.methods.openNotification(
                        "Nu se poate edita modulul fara un contract selectat!"
                      );
                    }
                  }}
                />
              ) : (
                <div
                  style={{
                    position: "absolute",
                    width: "35px",
                    height: "35px",
                    top: "calc(25vh - 40px)",
                    left: "50%",
                  }}>
                  <Preloader size={40} color={"var(--f7-theme-color)"} />
                </div>
              )}
            </Col>
          </Row>
        ) : (
          <div
            style={{
              marginTop: "calc((50vh / 2) - 50px)",
              fontSize: "20px",
              textAlign: "center",
            }}>
            Nu a fost selectat nici un contract
          </div>
        )}
      </>
    );
  }
}
export default Contracte;