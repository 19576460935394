import React, { Suspense } from "react";
import { Page, Popup, Navbar, Preloader } from "framework7-react";

/**
 * @description Popup dinamic pentur interfata de preluare apel telefonic
 * @date 08/04/2022
 * @class PopupApel
 * @extends {React.PureComponent}
 */
class PopupApel extends React.PureComponent {
  render() {
    return (
      <Popup
        className={"PopupApel"}
        closeOnEscape={!this.props.disableClose}
        closeByBackdropClick={!this.props.disableClose}
        closeByOutsideClick={!this.props.disableClose}
        tabletFullscreen={true}>
        <Page style={{ overflow: "hidden" }}>
          <Navbar
            backLink={!this.props.disableClose}
            title={this.props.title}
            noShadow
            sliding={false}></Navbar>
          <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
            <Suspense
              fallback={
                <div
                  style={{
                    width: "35px",
                    margin: "0 auto",
                  }}>
                  <Preloader
                    size={40}
                    color={"var(--f7-theme-color)"}></Preloader>
                </div>
              }>
              {this.props.component}
            </Suspense>
          </div>
        </Page>
      </Popup>
    );
  }
}
export default PopupApel;
