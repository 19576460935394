import React from "react";
import { Preloader, List, ListItem } from "framework7-react";
import axios from "axios";

/**
 * @description Interfata de atasare faze ataseaza o lista de faze unui proiect selectat
 * @date 06/04/2022
 * @class AtasareFaze
 * @extends {React.Component}
 */
class AtasareFaze extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            denumire: "Atasare faze",
            height: window.innerHeight - 34,
            listaFaze: [],
            linkApi: "/api/FazeRoluri"
        };
        this.refDataGrid = React.createRef();
    }

    /**
     * @description Adauga o lista de faze unui proiect
     * @date 06/04/2022
     * @function
     * @memberof AtasareFaze
     */
    AddValues = () => {
        var url =
            this.$f7.data.baseURL + this.state.linkApi + "/PostFazeProiectAtasare";
        var data = {
            idProiect: this.props.idProiect,
            arrayFaze: this.state.listaFaze
        };
        axios({
            data: data,
            headers: { "Content-Type": "application/json" },
            method: "POST",
            url: url
        })
            .then(response => { })
            .catch(err => {
                this.$f7.methods.openNotification("Eroare", "Ups");
            });
    };

    /**
     * @description Ia valorile(fazele) si bifeaza daca acestea sunt deja atasate taskului respectiv
     * @date 06/04/2022
     * @function
     * @memberof AtasareFaze
     */
    GetValues = () => {
        this.setState({
            dataLoaded: false
        });

        axios
            .get(
                this.$f7.data.baseURL +
                this.state.linkApi +
                "/GetFazeAtasare/" +
                this.props.idProiect
            )
            .then(response => {
                var baza = response.data;

                var listaFaze = [];
                baza.map(r => {
                    listaFaze.push(r.iD_FAZA);
                    return null;
                });
                this.setState(
                    {
                        listaFaze: listaFaze
                    },
                    () => {
                        setTimeout(() => {
                            this.setState({
                                dataLoaded: true
                            });
                        }, 400);
                    }
                );
            })
            .catch(error => {
                this.setState(
                    {
                        dataSource: [],
                        dataLoaded: true
                    },
                    () => {
                        this.$f7.dialog.confirm(
                            "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                            "Ups...",
                            () => {
                                this.GetValues();
                            },
                            () => { }
                        );
                    }
                );

                console.log("error", error);
            });
    };

    componentDidMount = () => {
        setTimeout(() => {
            var popupul = this.$f7.popup.get(".PopupDinamic");
            popupul.on("popupClose", () => {
                this.AddValues();
            });
        }, 500);
        if (this.props.idProiect !== undefined) {
            this.GetValues(this.props.idProiect);
        } else {
            this.setState({ dataLoaded: true });
        }
    };

    render() {
        return this.state.dataLoaded ? (
            <List>
                {this.$f7.data.TipFaze.map((item, index) => (
                    <ListItem
                        key={"tipFazeAtasareFaze-" + index}
                        onChange={e => {
                            var checked = e.target.checked;
                            var listaFaze = [...this.state.listaFaze];
                            if (checked) {
                                listaFaze.push(item.value);
                            } else {
                                listaFaze = listaFaze.filter(r => {
                                    return r !== item.value;
                                });
                            }
                            this.setState({ listaFaze: listaFaze });
                        }}
                        checked={this.state.listaFaze.includes(item.value)}
                        value={item.value}
                        title={item.key}
                        checkbox
                    />
                ))}
            </List>
        ) : (
            <div
                style={{
                    position: "absolute",
                    width: "35px",
                    height: "35px",
                    top: "50vh",
                    left: "50%"
                }}
            >
                <Preloader size={40} color={"var(--f7-theme-color)"} />
            </div>
        );
    }
}
export default AtasareFaze;