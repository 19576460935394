import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import * as inputs from "../../../../inputs";
import { Document, Page as Pagey } from "react-pdf";
import axios from "axios";
import {
    Row,
    ListItem,
    Icon,
    Col,
    Popup,
    Page,
    Navbar,
    NavRight,
    Segmented,
    Button,
    Link,
    Preloader,
    List
} from "framework7-react";
/**
 * @description Interfata de intretinere a clientilor
 * @date 06/04/2022
 * @class Clienti
 * @extends {React.Component}
 */
class Clienti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filtre: {},
      selectedIdComasare: "req",
      dataLoaded1: false,
      dataLoaded2: true,
      idFirma: null,
      linkApiRapoarte: "/api/RapoartePrint",
      linkApiClient: "/api/Firme",
      linkApiProiecte: "/api/Proiecte",
      linkApiEvenimente: "/api/EvenimenteClienti",
      linkApiTarife: "/api/TarifeClienti",
      denumire: "Clienti",
      height: window.innerHeight - 44,
      height2: (window.innerHeight - 44) / 2,
      dataSource: [],
      dataSourceTarife: [],
      dataSourceRapoarte: [],
      dataSourceEvenimente: [],
      popupNameEvenimente:
        "Popup" + Math.random().toString(36).substring(7).toString(),
      popupNameTarife:
        "Popup" + Math.random().toString(36).substring(7).toString(),
      inputsTarife: [
        { key: "Id_Tarif", tip: "number" },
        {
          key: "TipOperatie",
          tip: "selectbox",
          lista: this.$f7.data.Operatiuni,
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "Suma", tip: "number", requiredAdd: true, requiredEdit: true },
        {
          key: "DataStart",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "DataSfarsit",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
        },
      ],
      inputsEvenimente: [
        { key: "Id", tip: "number" },
        {
          key: "Data_Ora",
          tip: "datebox",
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "Rezumat", requiredAdd: true, requiredEdit: true },
        {
          key: "TipEveniment",
          tip: "selectbox",
          lista: this.$f7.data.TipEvenimentSalariati,
          requiredAdd: true,
          requiredEdit: true,
        },
      ],
      inputsClienti: [
        { key: "Id", keyBaza: "iD_FIRMA", tip: "number" },
        {
          key: "Denumire",
          keyBaza: "denumire",
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "Email", keyBaza: "email", tip: "email" },
        { key: "Adresa", keyBaza: "adresa" },
        { key: "Telefon", keyBaza: "telefon", tip: "phone" },
        { key: "PersoanaContact", keyBaza: "persoanA_CONTACT" },
        { key: "Ref_Firma", keyBaza: "reF_FIRMA", tip: "number" },
        { key: "Ref_Proiect", keyBaza: "ref_proiect", tip: "number" },
        { key: "IsHidden", keyBaza: "isHidden", tip: "bool" },
        { key: "Parola", keyBaza: "parola" },
      ],
    };
    this.refDataGrid2 = React.createRef();
    this.refDataGridTarife = React.createRef();
    this.refDataGridEvenimente = React.createRef();
    this.ref1 = React.createRef();
    this.ref2 = React.createRef();
    this.refDataGridFirme = React.createRef();
  }

  /**
   * @description Actualizeaza un eveniment care este atasat unui client
   * @date 06/04/2022
   * @function
   * @param {*} obj noile valori ale evenimentului
   * @param {*} id id-ul evenimentului
   * @memberof Clienti
   */
  UpdateValueEvenimente = (obj, id) => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiEvenimente +
          "/GetEVENIMENTE_CLIENTI/" +
          id
      )
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL +
          this.state.linkApiEvenimente +
          "/PutEVENIMENTE_CLIENTI/" +
          id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValuesEvenimente(this.state.idFirma);
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Sterge un eveniment atasat unui client
   * @date 06/04/2022
   * @function
   * @param {*} id id-ul evenimentului
   * @memberof Clienti
   */
  DeleteValueEvenimente = (id) => {
    var url =
      this.$f7.data.baseURL +
      this.state.linkApiEvenimente +
      "/DeleteEVENIMENTE_CLIENTI/" +
      id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValuesEvenimente(this.state.idFirma);
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  /**
   * @description Adauga un eveniment ce este atasat unui client
   * @date 06/04/2022
   * @param {*} data contine informatiile despre eveniment printre care si clientul la care este atasat
   * @memberof Clienti
   */
  AddValueEvenimente = (data) => {
    var url =
      this.$f7.data.baseURL +
      this.state.linkApiEvenimente +
      "/PostEVENIMENTE_CLIENTI";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValuesEvenimente(this.state.idFirma);
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza valorile unui client selectat
   * @date 06/04/2022
   * @function
   * @param {*} obj noile valori ale clientului selectat
   * @param {*} id id-ul clientului selectat
   * @memberof Clienti
   */
  UpdateValueClient = (obj, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(this.$f7.data.baseURL + this.state.linkApiClient + "/" + id)
        .then((response) => {
          var obj2 = response.data;
          Object.keys(obj).map((trasatura) => {
            Object.keys(obj2).map((trasatura2) => {
              if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
                obj2[trasatura2] = obj[trasatura];
              }
              return false;
            });
            return false;
          });

          var url = this.$f7.data.baseURL + this.state.linkApiClient + "/" + id;
          axios({
            data: obj2,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: url,
          })
            .then((response2) => {
              resolve(response2);
            })
            .catch((error) => {
              console.log("error", error);
              this.$f7.methods.openNotification("Eroare la modificare");
              reject(error);
            });
        })
        .catch((error) => {
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la modificare");
          reject(error);
        });
    });
  };

  /**
   * @description adauga clase de arhivat sau ascuns clientilor de sunt ascunsi/arhivati
   * @function
   * @date 06/04/2022
   * @param {*} state state-ul datagridului
   * @param {*} r contextul datagridului
   * @param {*} itemeRandate itemele randate de datagrid
   * @memberof Clienti
   */
  colorItems = (state, r, itemeRandate) => {
    var tableName = r.tableName;
    itemeRandate.forEach((elem, index) => {
      var tar = document.getElementById("trMain" + tableName + index);
      if (elem.IsHidden === true) {
        tar.classList.add("arhivat");
      } else {
        if (tar.classList.contains("arhivat")) {
          tar.classList.remove("arhivat");
        }
      }
    });
  };

  /**
   * @description Sterge o inregistrare de tip client, clientul selectat
   * @date 06/04/2022
   * @function
   * @param {*} id id-ul clientului selectat
   * @memberof Clienti
   */
  DeleteValueClient = (id) => {
    var url = this.$f7.data.baseURL + this.state.linkApiClient + "/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValuesClient();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  /**
   * @description Adauga un nou client
   * @date 06/04/2022
   * @function
   * @param {*} data informatiile noului clients
   * @memberof Clienti
   */
  AddValueClient = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApiClient + "/";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValuesClient();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza un tarif atasat unui client
   * @date 06/04/2022
   * @function
   * @param {*} obj noile valori
   * @param {*} id id-ul tarifului
   * @memberof Clienti
   */
  UpdateValueTarife = (obj, id) => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiTarife +
          "/GetTARIFE_CLIENTI/" +
          id
      )
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL +
          this.state.linkApiTarife +
          "/PutTARIFE_CLIENTI/" +
          id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValuesTarife(this.state.idFirma);
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Sterge o inregistrare de tip tarif
   * @date 06/04/2022
   * @function
   * @param {*} id id-u tarifului
   * @memberof Clienti
   */
  DeleteValueTarife = (id) => {
    var url =
      this.$f7.data.baseURL +
      this.state.linkApiTarife +
      "/DeleteTARIFE_CLIENTI/" +
      id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValuesTarife(this.state.idFirma);
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  /**
   * @description Adauga o noua inregistrare de tip tarif pentru clientul selectat
   * @date 06/04/2022
   * @function
   * @param {*} data informatiile noului tarif
   * @memberof Clienti
   */
  AddValueTarife = (data) => {
    var url =
      this.$f7.data.baseURL + this.state.linkApiTarife + "/PostTARIFE_CLIENTI";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValuesTarife(this.state.idFirma);
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Ia toti clientii pentru randare
   * @date 06/04/2022
   * @function
   * @memberof Clienti
   */
  GetValuesClient = () => {
    this.setState({
      dataLoaded1: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApiClient)
      .then((response) => {
        this.$f7.methods.IaValori();
        var date = response;
        date.data.map((eveniment) => {
          eveniment = this.$f7.methods.restructurareBaza(
            eveniment,
            this.state.inputsClienti,
            "key"
          );
          return false;
        });
        var baza = this.$f7.methods.reconstructieBaza(
          date,
          this.state.inputsClienti
        );

        var filtre = {};
        this.state.inputsClienti.map((input) => {
          filtre[input.key] = {
            denumire: input.key,
            value: null,
            comparatie: null,
          };
          return null;
        });
        filtre["IsHidden"].value = "False";
        filtre["IsHidden"].comparatie = "bool";

        this.setState(
          {
            filtre: filtre,
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded1: true,
                },
                () => {
                  //this.refDataGrid1.current.setRenderItems(bazaFinala)
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded1: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesClient();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Ia rapoartele atasate unui anumit client
   * @date 06/04/2022
   * @function
   * @param {*} id id-ul clientului
   * @memberof Clienti
   */
  GetValuesRapoarte = (id) => {
    this.setState({
      dataLoaded2: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApiRapoarte + "/" + id)
      .then((response) => {
        var baza = this.$f7.methods.reconstructieBaza(
          response,
          inputs.IntretinereClientiRaport
        );

        this.setState(
          {
            dataSourceRapoarte: baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded2: true,
                },
                () => {
                  if (baza.length > 0) {
                    this.refDataGrid2.current.setRenderItems(baza);
                  }
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSourceRapoarte: [],
            dataLoaded2: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesRapoarte(this.state.idFirma);
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Ia evenimentele atasate unui anumit client
   * @date 06/04/2022
   * @function
   * @param {*} id id-ul clientului selectat
   * @memberof Clienti
   */
  GetValuesEvenimente = (id) => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiEvenimente +
          "/GetListaEVENIMENTE_CLIENTI/" +
          id
      )
      .then((response) => {
        var date = response;

        date.data.map((obj) => {
          obj["Id"] = obj.iD_EVENIMENT_CLIENT;
          obj["TipEveniment"] = obj.iD_TIP_EVENIMENT;
          return false;
        });
        var baza = this.$f7.methods.reconstructieBaza(
          date,
          this.state.inputsEvenimente
        );
        this.setState({ dataSourceEvenimente: baza }, () => {
          if (baza.length > 0) {
            this.refDataGridEvenimente.current.setRenderItems(baza);
          }
        });
      })
      .catch((error) => {
        this.setState(
          {
            dataSourceEvenimente: [],
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesEvenimente(this.state.idFirma);
              },
              () => {}
            );
          }
        );
        console.log("error", error);
      });
  };

  /**
   * @description Comaseaza mai multe firme
   * @date 06/04/2022
   * @function
   * @param {*} selectedRowsOldStringFinal lista idurilor
   * @memberof Clienti
   */
  apelareComasare = (selectedRowsOldStringFinal) => {
    return new Promise((resolve, reject) => {
      var data = {
        listaIduri: selectedRowsOldStringFinal,
        idNou: this.state.selectedIdComasare,
      };
      this.$f7.preloader.show();
      var url = "/api/Firme/ComasareFirme";
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Afiseaza lita de firme cu care sa fie comasat
   * @date 06/04/2022
   * @function
   * @memberof Clienti
   */
  ComaseazaFirme = () => {
    var datagrid = this.refDataGridFirme.current;
    var state = datagrid.state;
    var selectedRows = state.allSelectedRows;
    if (selectedRows.length > 1) {
      this.$f7.data.router.navigate("/popupDinamic/", {
        props: {
          title: "Comasare",
          component: (
            <div>
              <List
                form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (this.state.selectedIdComasare !== "req") {
                    var selectedRowsOld = selectedRows.filter((r) => {
                      return r.Id !== this.state.selectedIdComasare;
                    });
                    var selectedRowsOldString = "";
                    selectedRowsOld.map((f) => {
                      selectedRowsOldString += f.Id.toString() + ",";
                      return null;
                    });
                    var selectedRowsOldStringFinal =
                      selectedRowsOldString.slice(0, -1);
                    this.apelareComasare(selectedRowsOldStringFinal)
                      .then((response) => {
                        this.$f7.preloader.hide();
                        this.setState({ selectedIdComasare: "req" }, () => {
                          this.GetValuesClient();
                          var popupDinamic =
                            this.$f7.popup.get(".PopupDinamic");
                          popupDinamic.close();
                          this.$f7.methods.openNotification("Success");
                        });
                      })
                      .catch((error) => {
                        this.$f7.preloader.hide();
                        this.setState({ selectedIdComasare: "req" }, () => {
                          var popupDinamic =
                            this.$f7.popup.get(".PopupDinamic");
                          popupDinamic.close();
                          this.$f7.methods.openNotification("Eroare:" + error);
                        });
                      });
                    this.setState({ selectedIdComasare: "req" }, () => {
                      var popupDinamic = this.$f7.popup.get(".PopupDinamic");
                      popupDinamic.close();
                    });
                  } else {
                    this.$f7.methods.openNotification(
                      "Trebuie sa selectati o denumire pentru firma."
                    );
                  }
                }}>
                <ListItem
                  title="Firma pastrata"
                  value={this.state.selectedIdComasare}
                  label="Firma pastrata"
                  validate
                  required
                  smartSelect
                  className="roundedOutlinedSmartSelect"
                  smartSelectParams={{
                    virtualList: true,
                    searchbar: true,
                    openIn: "popup",
                    popupTabletFullscreen: true,
                    popupPush: true,
                    closeOnSelect: true,
                    on: {
                      closed: (event) => {
                        this.setState({
                          selectedIdComasare: parseInt(event.getValue()),
                        });
                      },
                    },
                  }}>
                  <Icon
                    color={"var(--f7-theme-color)"}
                    size={20}
                    material="list"
                    slot="content-start"
                  />
                  <select name="Firma pastrata">
                    {selectedRows.map((obj, index) => (
                      <option
                        selected={
                          this.state.selectedIdComasare !== "req"
                            ? this.state.selectedIdComasare.toString() ===
                              obj.Id.toString()
                            : false
                        }
                        label={obj.Id}
                        key={"FirmaPastrataClienti-" + index}
                        value={obj.Id}>
                        {obj.Denumire}
                      </option>
                    ))}
                  </select>
                </ListItem>
                <Button type="submit" small fill bgColor="teal">
                  Salveaza
                </Button>
              </List>
            </div>
          ),
        },
      });
    }
  };

  /**
   * @description Ia tarifele clientului selectat
   * @date 06/04/2022
   * @function
   * @param {*} id id-ul clientului selectat
   * @memberof Clienti
   */
  GetValuesTarife = (id) => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiTarife +
          "/GetListaTARIFE_CLIENTI/" +
          id
      )
      .then((response) => {
        var date = response;
        date.data.map((obj) => {
          obj["TipOperatie"] = obj.iD_TIP_PROIECT;
          obj["DataStart"] = obj.datA_START;
          obj["DataSfarsit"] = obj.datA_END;
          return false;
        });

        var baza = this.$f7.methods.reconstructieBaza(
          date,
          this.state.inputsTarife
        );

        this.setState({ dataSourceTarife: baza }, () => {
          if (baza.length > 0) {
            this.refDataGridTarife.current.setRenderItems(baza);
          }
        });
      })
      .catch((error) => {
        this.setState(
          {
            dataSourceTarife: [],
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesTarife(this.state.idFirma);
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description afiseaza sau ascunde mai multi clienti
   * @date 06/04/2022
   * @function
   * @param {*} allSelectedRows lista de clienti de afisat/ascuns
   * @memberof Clienti
   */
  hideShowMultiple = (allSelectedRows) => {
    this.$f7.preloader.show();
    allSelectedRows
      .reduce((promise, item) => {
        let newItem = { ...item };
        newItem = this.$f7.methods.restructurareBaza(
          newItem,
          this.state.inputsClienti,
          "keyBaza"
        );
        newItem["isHidden"] = !newItem["isHidden"];
        return promise.then(() => {
          return this.UpdateValueClient(newItem, newItem.iD_FIRMA)
            .then((r) => {
              console.log("r", r);
            })
            .catch((err) => {
              console.log("err", err);
            });
        });
      }, Promise.resolve())
      .then(() => {
        this.$f7.preloader.hide();
        this.$f7.methods.openNotification("Rezolvat");
        this.GetValuesClient();
      })
      .catch(() => {
        this.$f7.preloader.hide();
        console.log("Eroare");
      });
  };

  componentDidMount = () => {
    this.GetValuesClient();
  };

  render() {
    return (
      <>
        <Popup
          push={true}
          tabletFullscreen={true}
          className={this.state.popupNameTarife}>
          <Page
            onPageInit={() => {
              this.$f7.data.paginaCurenta = this;
            }}>
            <Navbar title="Tarife">
              <NavRight>
                <Link tooltip={"Inchide"} popupClose>
                  Inchide
                </Link>
              </NavRight>
            </Navbar>
            <DataGrid
              name={"Clienti1"}
              ref={this.refDataGridTarife}
              id={"Id_Tarif"}
              title={"Tarife"}
              renderUpdate={true}
              renderDelete={true}
              renderAdd={true}
              dataSource={this.state.dataSourceTarife}
              maxHeight={this.state.height2}
              inputs={this.state.inputsTarife}
              onAdd={(state) => {
                var obj = { ...state.editedItem };

                obj["iD_TIP_PROIECT"] = obj["TipOperatie"];
                obj["datA_START"] = obj["DataStart"];
                obj["datA_END"] = obj["DataSfarsit"];
                obj["Id_Client"] = this.state.idFirma;
                delete obj.Id_Tarif;

                this.AddValueTarife(obj);
              }}
              onDelete={(state) => {
                var obj = { ...state.editedItem };
                var id = obj.Id_Tarif;
                this.DeleteValueTarife(id);
              }}
              onUpdate={(state) => {
                var obj = { ...state.editedItem };
                var id = obj.Id_Tarif;
                obj["iD_TIP_PROIECT"] = obj["TipOperatie"];
                obj["datA_START"] = obj["DataStart"];
                obj["datA_END"] = obj["DataSfarsit"];
                obj["Id_Client"] = this.state.idFirma;

                this.UpdateValueTarife(obj, id);
              }}
            />
          </Page>
        </Popup>

        <Popup
          push={true}
          tabletFullscreen={true}
          className={this.state.popupNameEvenimente}>
          <Page>
            <Navbar title="Evenimente">
              <NavRight>
                <Link tooltip={"Inchide"} popupClose>
                  Inchide
                </Link>
              </NavRight>
            </Navbar>
            <DataGrid
              name={"Clienti2"}
              id="Id"
              ref={this.refDataGridEvenimente}
              title={"Evenimente"}
              renderUpdate={true}
              dataSource={this.state.dataSourceEvenimente}
              renderDelete={true}
              renderAdd={true}
              maxHeight={this.state.height2}
              inputs={this.state.inputsEvenimente}
              onAdd={(state) => {
                var obj = { ...state.editedItem };

                obj["iD_TIP_EVENIMENT"] = obj["TipEveniment"];
                obj["Id_Firma"] = this.state.idFirma;
                obj["iD_SALARIAT"] = this.$f7.data.user.iD_SALARIAT;
                obj["fisier"] = null;
                delete obj.Id;
                this.AddValueEvenimente(obj);
              }}
              onDelete={(state) => {
                var obj = { ...state.editedItem };
                var id = obj.Id;
                this.DeleteValueEvenimente(id);
              }}
              onUpdate={(state) => {
                var obj = { ...state.editedItem };
                var id = obj.Id;
                obj["iD_EVENIMENT_CLIENT"] = obj["Id"];
                obj["iD_TIP_EVENIMENT"] = obj["TipEveniment"];
                this.UpdateValueEvenimente(obj, id);
              }}
            />
          </Page>
        </Popup>

        <Row>
          <Col
            ref={this.ref1}
            onGridResize={() => {
              localStorage.setItem(
                "ClientiWidthStanga",
                this.ref1.current.eventTargetEl.style.width
              );
            }}
            style={{
              width:
                localStorage.getItem("ClientiWidthStanga") === null
                  ? "49%"
                  : localStorage.getItem("ClientiWidthStanga"),
            }}
            resizable>
            <div style={{ position: "relative" }}>
              <Segmented
                className="butoaneContextDatagrid"
                raised
                style={{
                  position: "absolute",
                  width: "calc(100% - 176px)",
                  top: 2,
                  left: 0,
                }}
                tag="div">
                <Button
                  tooltip={"Evenimente"}
                  onClick={() => {
                    if (this.state.idFirma !== null) {
                      this.GetValuesEvenimente(this.state.idFirma);
                    } else {
                      this.$f7.methods.openNotification(
                        "Selecteaza mai intai un client!"
                      );
                    }
                  }}
                  fill
                  small
                  popupOpen={
                    this.state.idFirma !== null
                      ? "." + this.state.popupNameEvenimente
                      : null
                  }>
                  Evenimente
                </Button>
                <Button
                  tooltip={"Tarife"}
                  onClick={() => {
                    if (this.state.idFirma !== null) {
                      this.GetValuesTarife(this.state.idFirma);
                    } else {
                      this.$f7.methods.openNotification(
                        "Selecteaza mai intai un client!"
                      );
                    }
                  }}
                  fill
                  small
                  popupOpen={
                    this.state.idFirma !== null
                      ? "." + this.state.popupNameTarife
                      : null
                  }>
                  Tarife
                </Button>
              </Segmented>

              <DataGrid
                dataLoaded={this.state.dataLoaded1}
                refresh={this.GetValuesClient}
                ref={this.refDataGridFirme}
                keys={["Denumire", "Email", "Telefon", "PersoanaContact"]}
                renderCheckbox={true}
                name={"Clienti3"}
                id={"Id"}
                ignoreInputsAdd={["Ref_Firma", "Ref_Proiect"]}
                ignoreInputsUpdate={["Ref_Firma", "Ref_Proiect"]}
                refId={"Ref_Firma"}
                initialRefId={null}
                onInitialised={(state, r, itemeRandate) => {
                  this.colorItems(state, r, itemeRandate);
                }}
                onRenderItemsChanged={(state, r, itemeRandate) => {
                  this.colorItems(state, r, itemeRandate);
                }}
                contextMenuItems={[
                  {
                    key: "Comaseaza firme",
                    onClick: (state) => {
                      setTimeout(() => {
                        this.ComaseazaFirme();
                      }, 1000);
                    },
                    icon: "playlist_add_check",
                  },
                  {
                    key: "Hide/Show",
                    onClick: (state) => {
                      var allSelectedClients = state.allSelectedRows;
                      if (allSelectedClients.length > 0) {
                        this.hideShowMultiple(allSelectedClients);
                      } else {
                        this.hideShowMultiple([state.rowData]);
                      }
                    },
                    icon: "playlist_add_check",
                  },
                ]}
                renderUpdate={true}
                renderAccordion={true}
                //onAdd={(state) => {
                //    var obj ={...state.editedItem};
                //    obj.Ref_Firma = state.initialRefId;
                //    obj["Persoana_Contact"] = obj.PersoanaContact;
                //    delete obj.Id;
                //    delete obj.PersoanaContact;
                //    this.AddValueClient(obj);
                //}}
                onUpdate={(state) => {
                  var obj = { ...state.editedItem };
                  var idu = obj.Id;
                  obj["Persoana_Contact"] = obj.PersoanaContact;
                  delete obj.PersoanaContact;
                  obj["isHidden"] =
                    obj["IsHidden"] === null ? false : obj["IsHidden"];

                  this.UpdateValueClient(obj, idu)
                    .then((response) => {
                      axios
                        .get(
                          this.$f7.data.baseURL +
                            this.state.linkApiProiecte +
                            "/GetPROIECTE/" +
                            obj.Ref_Proiect
                        )
                        .then((responseProiect) => {
                          let dataProiect = responseProiect.data;
                          dataProiect.denumire = obj.Denumire;

                          axios({
                            data: dataProiect,
                            headers: { "Content-Type": "application/json" },
                            method: "put",
                            url:
                              this.$f7.data.baseURL +
                              this.state.linkApiProiecte +
                              "/PutPROIECTE/" +
                              dataProiect.iD_PROIECT,
                          })
                            .then((responseUpdateProiect) => {
                              this.GetValuesClient(this.state.idFirma);
                              this.$f7.methods.openNotification(
                                "Modificat cu success"
                              );
                            })
                            .catch((error) => {
                              console.log("error", error);
                            });
                        })
                        .catch((error) => {
                          console.log("error", error);
                        });
                    })
                    .catch((err) => {
                      console.log("err", err);
                    });
                }}
                //onDelete={(state) => {
                //    var obj ={...state.editedItem};
                //    var idu = obj.Id;
                //    this.DeleteValueClient(idu);
                //}}
                onRowClick={(state) => {
                  this.setState(
                    {
                      idFirma: state.rowData.Id,
                    },
                    () => {
                      this.GetValuesRapoarte(this.state.idFirma);
                    }
                  );
                }}
                // filtre={this.state.filtre}
                dataSource={this.state.dataSource}
                maxHeight={this.state.height}
                inputs={this.state.inputsClienti}
              />
            </div>
          </Col>
          <Col
            ref={this.ref2}
            onGridResize={() => {
              localStorage.setItem(
                "ClientiWidthDreapta",
                this.ref2.current.eventTargetEl.style.width
              );
            }}
            style={{
              position: "relative",
              width:
                localStorage.getItem("ClientiWidthDreapta") === null
                  ? "49%"
                  : localStorage.getItem("ClientiWidthDreapta"),
            }}
            resizable>
            {this.state.idFirma !== null ? (
              this.state.dataLoaded2 ? (
                <>
                  <DataGrid
                    name={"Clienti4"}
                    ref={this.refDataGrid2}
                    title={"Rapoarte"}
                    maxHeight={this.state.height}
                    inputs={inputs.IntretinereClientiRaport}
                    dataSource={this.state.dataSourceRapoarte}
                  />
                  <Document file="./img/document.pdf">
                    <Pagey pageNumber={1} />
                  </Document>
                </>
              ) : (
                <div
                  style={{
                    position: "absolute",
                    width: "35px",
                    height: "35px",
                    top: "calc(50vh - 100px)",
                    left: "50%",
                  }}>
                  <Preloader size={40} color={"var(--f7-theme-color)"} />
                </div>
              )
            ) : (
              <div
                style={{
                  marginTop: "calc(50vh - 72px)",
                  fontSize: "20px",
                  textAlign: "center",
                }}>
                Nu este nici un client selectat
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
}
export default Clienti;