import React from "react";
import { render } from "react-dom";
//import "./styles/bundle-custom.less";
import "./styles/bundle.scss";
//import Framework7 from "framework7/framework7.esm.bundle";
// import themes from "devextreme/ui/themes";
import Framework7 from "./bundle-custom";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.compact.css";
import Framework7React from "framework7-react";
import App from "./components/App";
Framework7.use(Framework7React);
render(React.createElement(App), document.getElementById("app"));
// themes.initialized(() =>
//   render(React.createElement(App), document.getElementById("app"))
// );