import React, { Component } from 'react';
import moment from 'moment'

class EmailItemList extends Component {
  render() {
    if (!this.props.info) return

    const { id, fromAddress, toAddress, subject, date, internalDate, isUnRead } = this.props.info
    const nameFromAddress = fromAddress.includes('<') ? fromAddress.split('<')[0].trim() : fromAddress
    const formatedDate = moment(date).format('DD MMM YYYY HH:mm')

    return (
      <div 
        className={`gmailItemList ${this.props.isSelected ? 'gmailSelected' : ''}`}
        // style={{ marginBottom: this.props.isLast ? 0 : 4 }}
        onClick={() => this.props.handleSelectEmail(id)}
      >
        <div style={{ fontSize: 15, fontWeight: isUnRead ? 'bold' : 'normal', lineHeight: 1, marginBottom: 7 }}>{subject}</div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: -4, color: 'grey' }}>
          <div>{nameFromAddress}</div>
          <div>{formatedDate}</div>
        </div>
      </div>
    )
  }
}

export default EmailItemList;
