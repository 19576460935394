import React from "react";
import { Page } from "framework7-react";
import Istoric from "../Componente/Istoric";

class IstoricClientTaskPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      denumire: "Istoric Client/Task"
    };
  }
  render() {
    return (
      <Page
        className={"themeBackground"}
        onPageInit={() => {
          this.$f7.data.paginaCurenta = this;
        }}
      >
        <div className="ptr3">
          <Istoric type={4} />
        </div>
      </Page>
    );
  }
}
export default IstoricClientTaskPage;