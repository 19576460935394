import React from "react";
import { Preloader, List, ListInput, Icon } from "framework7-react";
import { BarChart, Bar, Tooltip, XAxis, YAxis } from "recharts";
import axios from "axios";
import moment from "moment";

/**
 * @description Prima interfata de grafice, randeaza numarul de ore ramase de lucru
 * @date 06/04/2022
 * @class ATGrafice1
 * @extends {React.Component}
 */
class ATGrafice1 extends React.Component {
  constructor(props) {
    super(props);
    this.height = window.innerHeight - 200;
    this.width = window.innerWidth;
    this.height2 = window.innerHeight - 190;
    this.state = {
      data: moment(Date.now()).format(),
      width: this.width,
      dataLoaded: true,
      denumire: "ATGrafice1",
      dataSource: [
        { name: "Test", value: 50 },
        { name: "Test2", value: 35 },
        { name: "Test3", value: 120 },
        { name: "Test123", value: 120 },
        { name: "Test36", value: 120 },
        { name: "Test53", value: 120 },
        { name: "Test34", value: 120 },
        { name: "Test66", value: 120 },
      ],
      linkApi: "/api/SarciniSalariat",
    };
  }

  componentDidMount = () => {
    var data = moment().format("YYYY-MM-DD");
    this.GetValues(data);
  };
  /**
   * @description Ia valorile necesare de la endpointul  /api/SarciniSalariat/GetOreLucrateSalariatiZi/data
   * @date 06/04/2022
   * @function
   * @param {*} data data dupa care filtreaza valorile
   * @memberof ATGrafice1
   */
  GetValues = (data) => {
    this.setState({ dataLoaded: false });
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetOreLucrateSalariatiZi/" +
          data
      )
      .then((response) => {
        var date = response.data;
        var arr = [];
        date.map((r) => {
          var toAdd1 = { name: r.nume, value: r.oreLucrate };
          arr.push(toAdd1);
          return null;
        });
        this.setState(
          {
            dataSource: arr,
            width: arr.length * 120,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  render() {
    return this.state.dataLoaded ? (
      <>
        <List style={{ width: "100%" }}>
          <ListInput
            floatingLabel
            label={"Data"}
            validate={
              (this.props.allRequiredAdd && this.state.adding === true) ||
              (this.props.allRequiredEdit && this.state.adding === false)
            }
            required={
              (this.props.allRequiredAdd && this.state.adding === true) ||
              (this.props.allRequiredEdit && this.state.adding === false)
            }
            type="datepicker"
            placeholder={"Data"}
            value={[this.state.data]}
            calendarParams={{
              dateFormat: "yyyy-mm-dd",
              closeOnSelect: true,
              on: {
                closed: (e) => {
                  var val = moment(e.value[0]).format("YYYY-MM-DD");
                  this.setState({ data: val }, () => {
                    this.GetValues(val);
                  });
                },
              },
            }}>
            <Icon
              slot="content-start"
              className={"datagridIcon"}
              size={20}
              material="today"
            />
          </ListInput>
        </List>
        <div
          style={{
            maxWidth: window.innerWidth,
            overflow: "auto",
            maxHeight: this.height2,
          }}>
          <BarChart
            data={this.state.dataSource}
            width={this.state.width}
            height={this.height}>
            <Bar dataKey="value" fill="#8884d8" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
          </BarChart>
        </div>
      </>
    ) : (
      <div
        style={{
          width: "35px",
          margin: "0 auto",
        }}>
        <Preloader size={40} color={"var(--f7-theme-color)"} />
      </div>
    );
  }
}
export default ATGrafice1;