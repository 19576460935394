import React from "react";
import {
	Page,
	ListInput,
	Button,
	List,
	Row,
	Col,
	Icon,
	ListItem,
	CardContent,
	Card,
	Preloader,
} from "framework7-react";
import axios from "axios";
import moment from "moment";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";


var settings1 = {
	width: "0",
	xsmall: "0",
	small: "0",
	medium: "50",
	large: "50",
	xlarge: "50",
};
var settings3 = {
	width: "100",
	xsmall: "100",
	small: "100",
	medium: "50",
	large: "50",
	xlarge: "50",
};

/**
 * @description Pagina de logare
 * @date 07/04/2022
 * @class LoginPage
 * @extends {React.PureComponent}
 */
class LoginPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			repornire: false,
			logareAutomata: false,
			loading: false,
			username: "",
			password: "",
			typepassword: "password",
			linkApi: "/api/Sesiuni",
			inputProiecte: [
				{ key: "Id_Proiect", tip: "number" },
				{ key: "Ref_Proiect", tip: "number" },
				{ key: "Denumire" },
				{ key: "Id_Prioritate", tip: "number" },
				{ key: "Timp_Plan_D", tip: "number" },
				{ key: "Timp_Plan_A", tip: "number" },
				{ key: "Timp_Plan_SP", tip: "number" },
				{ key: "Timp_Plan_M", tip: "number" },
				{ key: "Chelt_Sal", tip: "number" },
				{ key: "Buget", tip: "number" },
				{ key: "Ordine", tip: "number" },
			],

			// new signalR
			connection: null,
		};
	}
 
	/**
	 * @description Returneaza lista de salariati parcursa recursiva pentru a ii atasa copiii
	 * @function
	 * @date 06/04/2022
	 * @param {*} baza baza pe care se face recursiva
	 * @param {*} refId id-ul referinta
	 * @param {*} arr array-ul la care se adauga elementele
	 * @memberof LoginPage
	 */
	recursivaSalariati = (baza, refId, arr) => {
		var insideRows = baza.filter((r) => {
			return r.ref === refId;
		});
		if (insideRows.length > 0) {
			insideRows.map((p) => {
				arr.push(p);
				arr = this.recursivaSalariati(baza, p.value, arr);
				return null;
			});
		}

		return arr;
	};
	
	/**
	 * @description Functie de test
	 * @date 06/04/2022
	 * @memberof LoginPage
	 */
	functieTeste = () => {
		axios.get("/api/Proiecte/ActualizareProiecteVechi").then((r) => {
			console.log("response", r);
		});
	};
	/**
	 * @description seteaza toti parametrii aplicatiei dupa ce se face logarea(toate listele pentru nomenclatoare)
	 * @date 06/04/2022
	 * @function
	 * @memberof LoginPage
	 */
	GetValuesAfterLogin = () => {
		return new Promise((resolve, reject) => {
			var baseURL = "/api/";
			var self = this;
			function reqDepartamente() {
				return axios.get(baseURL + "Departamente/GetDEPARTAMENTE2"); //rezolvat
			}
			function reqProfesii() {
				return axios.get(baseURL + "Profesii"); //rezolvat
			}
			function reqCompetente() {
				return axios.get(baseURL + "Competente"); //rezolvat
			}
			function reqAngajatori() {
				return axios.get(baseURL + "Angajatori"); //rezolvat
			}
			function reqBirouri() {
				return axios.get(baseURL + "Birouri"); //rezolvat
			}
			function reqRoluri() {
				return axios.get(baseURL + "TipRol"); //rezolvat
			}
			function reqSalariati() {
				return axios.get(baseURL + "Salariati/GetSALARIATI"); //rezolvat
			}
			function reqSalariatiActivi() {
				return axios.get(baseURL + "Salariati/GetSALARIATIACTIVI"); //rezolvat
			}
			function reqTipDocument() {
				return axios.get(baseURL + "TipDocument"); //rezolvat
			}
			function reqStadiu() {
				return axios.get(baseURL + "TipStadiu/GetTIP_STADIU2"); //rezolvat
			}
			function reqNivelProiect() {
				return axios.get(baseURL + "TipNivelProiect"); //rezolvat
			}
			function reqOperatiuni() {
				return axios.get(baseURL + "TipOperatiuni/GetTIP_OPERATIUNI2"); //rezolvat
			}
			function reqTipProiect() {
				return axios.get(baseURL + "TipProiect"); //rezolvat
			}
			function reqFirme() {
				return axios.get(baseURL + "Firme/GetFIRME2"); //rezolvat
			}
			function reqUtilaje() {
				return axios.get(baseURL + "Utilaje"); //rezolvat
			}
			function reqTipEvenimentSalariati() {
				return axios.get(baseURL + "TipEveniment"); //rezolvat
			}
			function reqTipFaze() {
				return axios.get(baseURL + "TipFaze/GetTIP_FAZE2"); //rezolvat
			}
			function reqPrioritati() {
				return axios.get(baseURL + "Prioritati"); //rezolvat
			}
			function reqMeniu() {
				//return axios.get(baseURL + "Meniu/Getmeniu");//rezolvat
				return axios.get(baseURL + "Meniu/GetmeniuOrdonat"); //rezolvat
			}
			function reqProiecte() {
				//if (self.$f7.data.user.administrator) {
				//    return axios.get(baseURL + "Proiecte/GetPROIECTEUnion/1/1/1");//rezolvat
				//} else {
				//    return axios.get(baseURL + "Proiecte/GetPROIECTEUnionUserNormal");//rezolvat
				//}
				return axios.get(baseURL + "Proiecte/GetPROIECTEUnionUserNormal"); //rezolvat
			}

			function reqEuro() {
				return axios.get(baseURL + "SarciniSalariat/GetEuro");
			}

			function reqTipSursa() {
				return axios.get(baseURL + "TipSursa/GetTIP_SURSA"); //rezolvat
			}

			function reqContracteNoi() {
				return axios.get(baseURL + "ContracteNoi");
			}

			function reqModuleNoi() {
				return axios.get(baseURL + "ModuleNoi");
			}

			function reqTipValuta() {
				return axios.get(baseURL + "TipValuta");
			}

			function reqDenumireModule() {
				return axios.get(baseURL + "Proiecte/GetModuleDinProiecte");
			}

			function reqMeniuriAccesibile() {
				return axios.get(
					baseURL +
						"SalariatiMeniu/GetMeniuriBySalariat/" +
						self.$f7.data.user.iD_SALARIAT
				);
			}

			function reqGrupuriChat() {
				return axios.get(baseURL + "GrupuriChat/GetGRUPURI_CHAT");
			}

			axios
				.all([
					reqDepartamente(),
					reqProfesii(),
					reqCompetente(),
					reqAngajatori(),
					reqBirouri(),
					reqRoluri(),
					reqSalariati(),
					reqTipDocument(),
					reqStadiu(),
					reqNivelProiect(),
					reqOperatiuni(),
					reqTipProiect(),
					reqFirme(),
					reqUtilaje(),
					reqTipEvenimentSalariati(),
					reqTipFaze(),
					reqPrioritati(),
					reqMeniu(),
					reqTipSursa(),
					reqSalariatiActivi(),
					reqProiecte(),
					reqEuro(),
					reqContracteNoi(),
					reqModuleNoi(),
					reqTipValuta(),
					reqDenumireModule(),
					reqMeniuriAccesibile(),
					reqGrupuriChat(),
				])
				.then(
					axios.spread(
						(
							responseDepartamente,
							responseProfesii,
							responseCompetente,
							responseAngajatori,
							responseBirouri,
							responseRoluri,
							responseSalariati,
							responseTipDocument,
							responseStadiu,
							responseNivelProiect,
							responseOperatiuni,
							responseTipProiect,
							responseFirme,
							responseUtilaje,
							responseTipEvenimentSalariati,
							responseTipFaze,
							responsePrioritati,
							responseMeniu,
							responseTipSursa,
							responseSalariatiActivi,
							responseProiecte,
							responseEuro,
							responseContracteNoi,
							responseModuleNoi,
							responseTipValuta,
							responseDenumireModule,
							responseMeniuriAccesibile,
							responseGrupuriChat
						) => {
							var dataDepartamente = responseDepartamente.data;
							var dataProfesii = responseProfesii.data;
							var dataCompetente = responseCompetente.data;
							var dataAngajatori = responseAngajatori.data;
							var dataBirouri = responseBirouri.data;
							var dataRoluri = responseRoluri.data;
							var dataSalariati = responseSalariati.data;
							var dataSalariatiActivi = responseSalariatiActivi.data;
							var dataTipDocument = responseTipDocument.data;
							var dataStadiu = responseStadiu.data;
							var dataNivelProiect = responseNivelProiect.data;
							var dataOperatiuni = responseOperatiuni.data;
							var dataTipProiect = responseTipProiect.data;
							var dataFirme = responseFirme.data;
							var dataUtilaje = responseUtilaje.data;
							var dataTipEvenimentSalariati =
								responseTipEvenimentSalariati.data;
							var dataTipFaze = responseTipFaze.data;
							var dataPrioritati = responsePrioritati.data;
							var dataMeniu = responseMeniu.data;
							var dataProiecteArh = responseProiecte.data.arh;
							var dataProiectePrj = responseProiecte.data.prj;
							var dataProiecteAll = dataProiecteArh.concat(dataProiectePrj);
							var dataEuro = responseEuro.data;
							var dataTipSursa = responseTipSursa.data;
							var dataContracteNoi = responseContracteNoi.data;
							var dataModuleNoi = responseModuleNoi.data;
							var dataTipValuta = responseTipValuta.data;
							var dataDenumireModule = responseDenumireModule.data;
							var dataMeniuriAccesibile = responseMeniuriAccesibile.data;
							var dataGrupuriChat = responseGrupuriChat.data;

							var arrDepartamente = [{ key: "Fara", value: null }];
							var arrProfesii = [{ key: "Fara", value: null }];
							var arrCompetente = [{ key: "Fara", value: null }];
							var arrAngajatori = [{ key: "Fara", value: null }];
							var arrBirouri = [{ key: "Fara", value: null }];
							var arrRoluri = [{ key: "Fara", value: null }];
							var arrTipDocument = [{ key: "Fara", value: null }];
							var arrSalariati = [{ key: "Fara", value: null }];
							var arrSalariatiActivi = [{ key: "Fara", value: null }];
							var arrStadiu = [{ key: "Fara", value: null }];
							var arrNivelProiect = [{ key: "Fara", value: null }];
							var arrOperatiuni = [{ key: "Fara", value: null }];
							var arrTipProiect = [{ key: "Fara", value: null }];
							var arrFirme = [{ key: "Fara", value: null }];
							var arrUtilaje = [{ key: "Fara", value: null }];
							var arrTipEvenimentSalariati = [{ key: "Fara", value: null }];
							var arrMeniu = [{ key: "Fara", value: null }];
							var arrTipFaze = [];
							var arrTipFaze2 = [];
							var arrPrioritati = [{ key: "Fara", value: null }];
							var arrProiecte = [{ key: "Fara", value: null }];
							var arrTipSursa = [{ key: "Fara", value: null }];
							var arrContracteNoi = [{ key: "Fara", value: null }];
							var arrModuleNoi = [{ key: "Fara", value: null }];
							var arrTipValuta = [{ key: "Fara", value: null }];
							var arrDenumireModule = [{ key: "Fara", value: null }];
							var arrMeniuriAccesibile = [1];
							var arrGrupuriChat = [];

							dataSalariati.map((objSalariat) => {
								arrSalariati.push({
									key: objSalariat.nume + " " + objSalariat.prenume,
									value: objSalariat.iD_SALARIAT,
									ref: objSalariat.reF_SALARIAT,
									gradient_culoare1: objSalariat.gradient_culoare1,
									gradient_culoare2: objSalariat.gradient_culoare2,
									isHidden: objSalariat.isHidden,
									isArhivat: objSalariat.isArhivat,
									rol: objSalariat.rol,
									preferinte: objSalariat.preferinte,
									grupuri: objSalariat.grupuri,
									angajator: objSalariat.iD_ANGAJATOR,
								});
								return false;
							});

							dataSalariatiActivi.map((objSalariat) => {
								arrSalariatiActivi.push({
									key: objSalariat.nume + " " + objSalariat.prenume,
									value: objSalariat.iD_SALARIAT,
									ref: objSalariat.reF_SALARIAT,
									isHidden: objSalariat.isHidden,
									isArhivat: objSalariat.isArhivat,
									preferinte: objSalariat.preferinte,
									grupuri: objSalariat.grupuri,
								});
								return false;
							});
							dataTipDocument.map((objTipDocument) => {
								arrTipDocument.push({
									key: objTipDocument.denumire,
									value: objTipDocument.iD_TIP_DOCUMENT,
								});
								return false;
							});
							dataDepartamente.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_DEPARTAMENT;
								toAdd.key = data.denumire;
								arrDepartamente.push(toAdd);
								return false;
							});
							dataProfesii.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_PROFESIE;
								toAdd.key = data.denumire;
								arrProfesii.push(toAdd);
								return false;
							});
							dataCompetente.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_COMPETENTA;
								toAdd.key = data.denumire;
								arrCompetente.push(toAdd);
								return false;
							});
							dataAngajatori.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_ANGAJATOR;
								toAdd.key = data.denumire;
								arrAngajatori.push(toAdd);
								return false;
							});
							dataBirouri.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.id_birou;
								toAdd.key = data.denumire;
								arrBirouri.push(toAdd);
								return false;
							});
							dataRoluri.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_ROL;
								toAdd.key = data.denumire;
								arrRoluri.push(toAdd);
								return false;
							});
							dataStadiu.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_STADIU;
								toAdd.key = data.denumire;
								arrStadiu.push(toAdd);
								return false;
							});
							dataNivelProiect.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_NIVEL_PROIECT;
								toAdd.key = data.denumire;
								arrNivelProiect.push(toAdd);
								return false;
							});

							dataOperatiuni.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_OPERATIUNE;
								toAdd.key = data.denumire;
								toAdd.ref = data.reF_OPERATIUNE;
								arrOperatiuni.push(toAdd);
								return false;
							});
							dataTipProiect.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = parseInt(data.iD_TIP_PROIECT);
								toAdd.key = data.denumire;
								toAdd.ref = data.reF_TIP_PROIECT;
								arrTipProiect.push(toAdd);
								return false;
							});

							dataFirme.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_FIRMA;
								toAdd.key = data.denumire;
								toAdd.ref_proiect = data.ref_proiect;
								arrFirme.push(toAdd);
								return false;
							});
							dataUtilaje.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_UTILAJ;
								toAdd.key = data.denumire;
								arrUtilaje.push(toAdd);
								return false;
							});
							dataTipEvenimentSalariati.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_EVENIMENT;
								toAdd.key = data.denumire;
								arrTipEvenimentSalariati.push(toAdd);
								return false;
							});

							dataTipFaze.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_FAZA;
								toAdd.key = data.denumire;
								if (toAdd.key !== "Faze") {
									arrTipFaze.push(toAdd);
								}
								return false;
							});

							dataTipFaze.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_FAZA;
								toAdd.key = data.denumire;
								arrTipFaze2.push(toAdd);
								return false;
							});

							dataPrioritati.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_PRIORITATE;
								toAdd.key = data.iD_PRIORITATE;
								arrPrioritati.push(toAdd);
								return false;
							});

							dataMeniu.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.id;
								toAdd.key = data.denumire;
								arrMeniu.push(toAdd);
								return false;
							});
							dataProiecteAll.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.iD_PROIECT;
								toAdd.key = data.denumire;
								toAdd.departament = data.id_departament;
								arrProiecte.push(toAdd);
								return false;
							});

							dataTipSursa.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.id_tip_sursa;
								toAdd.key = data.denumire;
								arrTipSursa.push(toAdd);
								return false;
							});

							dataContracteNoi.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.id_contract;
								toAdd.key = data.nr_contract;
								arrContracteNoi.push(toAdd);
								return false;
							});

							dataModuleNoi.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.id_modul;
								toAdd.key = data.nume_modul;
								arrModuleNoi.push(toAdd);
								return false;
							});

							dataTipValuta.map((data) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = data.id_valuta;
								toAdd.key = data.denumire;
								arrTipValuta.push(toAdd);
								return false;
							});

							dataDenumireModule.map((data, index) => {
								var toAdd = { key: "", value: 0 };
								toAdd.value = index;
								toAdd.key = data;
								arrDenumireModule.push(toAdd);
								return false;
							});

							dataMeniuriAccesibile.map((data, index) => {
								arrMeniuriAccesibile.push(data.id_meniu);
								return null;
							});

							dataGrupuriChat.map((data) => {
								arrGrupuriChat.push({
									key: data.denumirE_GRUP,
									value: data.iD_GRUP,
								});
								return null;
							});

							var arr = this.recursivaSalariati(
								arrSalariatiActivi,
								this.$f7.data.user.iD_SALARIAT,
								[]
							);
							arr.push(
								...arrSalariatiActivi.filter(
									(item) => item.value === this.$f7.data.user.iD_SALARIAT
								)
							);

							var arr2 = this.recursivaSalariati(
								arrSalariatiActivi,
								this.$f7.data.user.reF_SALARIAT,
								[]
							);
							arr2.push(
								...arrSalariatiActivi.filter(
									(item) => item.value === this.$f7.data.user.reF_SALARIAT
								)
							);

							this.$f7.data.SalariatiSubalterni = arr.filter((rf) => {
								return rf.key !== "Fara";
							});
							this.$f7.data.SalariatiSubalterniColegi = arr2.filter((rf) => {
								return (
									rf.key !== "Fara" &&
									rf.value !== this.$f7.data.user.reF_SALARIAT
								);
							});
							this.$f7.data.SalariatiSubalterniColegiSiSef = arr2.filter(
								(rf) => {
									return rf.key !== "Fara";
								}
							);
							this.$f7.data.Departamente = arrDepartamente;
							this.$f7.data.Birouri = arrBirouri;
							this.$f7.data.Profesii = arrProfesii;
							this.$f7.data.Competente = arrCompetente;
							this.$f7.data.Angajatori = arrAngajatori;
							this.$f7.data.Roluri = arrRoluri;
							this.$f7.data.Salariati = arrSalariati;
							this.$f7.data.SalariatiActivi = arrSalariatiActivi;
							this.$f7.data.TipDocument = arrTipDocument;
							this.$f7.data.Stadiu = arrStadiu;
							this.$f7.data.NivelProiect = arrNivelProiect;
							this.$f7.data.Operatiuni = arrOperatiuni;
							this.$f7.data.TipProiect = arrTipProiect;
							this.$f7.data.Firme = arrFirme;
							this.$f7.data.Utilaje = arrUtilaje;
							this.$f7.data.TipEvenimentSalariati = arrTipEvenimentSalariati;
							this.$f7.data.TipFaze = arrTipFaze;
							this.$f7.data.TipFaze2 = arrTipFaze2;
							this.$f7.data.Prioritati = arrPrioritati;
							this.$f7.data.Meniuri = arrMeniu;
							this.$f7.data.MeniuriRandare = responseMeniu.data;
							this.$f7.data.TipSursa = arrTipSursa;
							this.$f7.data.ContracteNoi = arrContracteNoi;
							this.$f7.data.ModuleNoi = arrModuleNoi;
							this.$f7.data.TipValuta = arrTipValuta;
							this.$f7.data.DenumireModule = arrDenumireModule;
							this.$f7.data.MeniuriAccesibile = arrMeniuriAccesibile;
							var Proiecte = arrProiecte;
							var Euro = dataEuro;
							this.$f7.data.Proiecte = Proiecte;
							this.$f7.data.Euro = Euro;
							this.$f7.data.allProiecte = dataProiecteAll;
							this.$f7.data.arhivateProiecte = dataProiecteArh;
							this.$f7.data.nearhivateProiecte = dataProiectePrj;
							this.$f7.data.grupuriChat = arrGrupuriChat;
							var proj = [];
							if (dataProiectePrj.length > 0) {
								proj.push(dataProiectePrj[0]);
								//this.$f7.data.Proiect = dataProiectePrj[0];
								this.$f7.data.Proiect = null;
							}
							var prj = dataProiectePrj.filter((r) => {
								return (
									r.isHidden !== 1 && r.isArhivat === 0 && r.folosit === true
								);
							});
							var responseNearhivate = { data: prj };
							var bazaNearhivate = this.$f7.methods.reconstructieBaza(
								responseNearhivate,
								this.state.inputProiecte
							);
							resolve(bazaNearhivate);
						}
					)
				)
				.catch((err) => {
					reject(err);
				});
		});
	};

	/**
	 * @description Ia toate proiectele si adauga valorile primite ca parametru
	 * @date 06/04/2022
	 * @param {*} data
	 * @memberof LoginPage
	 */
	GetValues = (data) => {
		this.setState({ dataLoaded: false });
		const reqProiecte = () => {
			return axios.get(
				this.$f7.data.baseURL +
					"/api/Proiecte/GetPROIECTE2/" +
					this.$f7.data.user.iD_SALARIAT
			);
		};
		axios.all([reqProiecte()]).then(
			axios.spread((responseProiecte) => {
				var dataProiecte = responseProiecte.data;
				var arrProiecte = [{ key: "Fara", value: null }];
				dataProiecte.map((data) => {
					var toAdd = { key: "", value: 0 };
					toAdd.value = data.iD_PROIECT;
					toAdd.key = data.denumire;
					arrProiecte.push(toAdd);
					return false;
				});
				this.$f7.data.Proiecte = arrProiecte;
				this.AddValue(data);
			})
		);
	};

	/**
	 * @description Trimite la endpointul de logare userul si parola,daca e ok seteaza headerul de autorizare pentru toate viitoarele requesturi facute cu axios
     * @function
	 * @date 07/04/2022
	 * @param {*} data
	 * @memberof LoginPage
	 */
	LoginCronos = (data) => {
		this.setState({ loading: true }, () => {
			var url = this.$f7.data.baseURL + "/api/Salariati/Login";
			axios({
				data: data,
				headers: { "Content-Type": "application/json" },
				method: "post",
				url: url,
			})
				.then((response) => {
					if (response.data !== "") {
						axios.defaults.headers.common["Authorization"] =
							"Bearer " + response.data.accessToken;
						this.$f7.data.accessToken = response.data.accessToken;
						this.$f7.data.refreshToken = response.data.refreshToken;
						this.$f7.methods.login(
							response.data.salariat,
							response.data.accessToken
						);
						this.$f7.data.user = response.data.salariat;
						this.$f7.data.idSalariatFunctii =
							response.data.salariat.iD_SALARIAT;
						this.$f7.data.rol = response.data.salariat.rol;
						this.$f7.data.rolAdevarat = response.data.salariat.rol;
						this.$f7.data.userLogareAutomata = this.state.username;
						this.$f7.data.parolaLogareAutomata = this.state.password;
						if (this.state.logareAutomata) {
							this.$f7.methods.setCookie("user", this.state.username, 90);
							this.$f7.methods.setCookie("parola", this.state.password, 90);
						} else {
							this.$f7.methods.setCookie("user", "", 90);
							this.$f7.methods.setCookie("parola", "", 90);
						}

						var latLng = this.getLatLng();
						var date = {
							idUtilizator: this.$f7.data.user.iD_SALARIAT,
							DataIntrare: moment().format(),
							UserName: this.$f7.data.user.numE_LOGIN,
							loginTime: moment().format(),
							iP_COMPUTER: data.ipAdress,
							uLTIM_MODIF: null,
							Computer: null,
							spid: null,
							lat: latLng.lat,
							lng: latLng.long,
						};
						this.AddValue(date);
					} else {
						this.setState({ loading: false }, () => {
							this.$f7.methods.openNotification(
								"Nu exista acest user sau parola este gresita."
							);
						});
					}
				})
				.catch((error) => {
					this.setState({ loading: false }, () => {
						console.log("error", error);
						this.$f7.methods.openNotification(
							"Nu exista acest user sau parola este gresita."
						);
					});
				});
		});
	};

	/**
	 * @description Returneaza un obiect cu longitudinea si latitudinea
     * @function
	 * @date 07/04/2022
	 * @memberof LoginPage
	 */
	getLatLng = () => {
		return new Promise((resolve, reject) => {
			var lat = null;
			var long = null;
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(p) => {
						lat = p.coords.latitude;
						long = p.coords.longitude;
						if (lat && long) {
							resolve({ lat: lat, long: long });
						}
					},
					(e) => {
						reject(e);
					}
				);
			} else {
				resolve({ lat: lat, long: long });
			}
		});
	};

	componentDidMount = () => {
		window.addEventListener("beforeunload", (ev) => {
			ev.preventDefault();
			this.$f7.methods.logout();
		});

		this.GetIp()
			.then((ipAdress) => {
				console.log("AA");
				this.autoLogin(ipAdress);
			})
			.catch((err) => {
				this.$f7.methods.openNotification("Eroare", err);
			});
	};

	/**
	 * @description Functie de luare a ip-ului utilizatorului curent
     * @function
	 * @date 07/04/2022
	 * @memberof LoginPage
	 */
	GetIp = () => {
		return new Promise((resolve, reject) => {
			axios
				.get("https://httpbin.org/ip")
				.then((data) => {
					resolve(data.data.origin);
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	/**
	 * @description Ia valorile de autologare din cookie si face logarea
     * @function
	 * @date 07/04/2022
	 * @param {*} ipAdress Adresa ip de adaugat la sesiune
	 * @memberof LoginPage
	 */
	autoLogin = (ipAdress) => {
		var cookieUser = this.$f7.methods.getCookie("user");
		var cookieParola = this.$f7.methods.getCookie("parola");
		var cookieRepornire = this.$f7.methods.getCookie("repornire");
		if (
			cookieRepornire !== undefined &&
			cookieRepornire !== "" &&
			cookieRepornire === "true"
		) {
			this.setState({ repornire: true });
		}
		if (
			cookieUser !== undefined &&
			cookieUser !== "" &&
			cookieParola !== undefined &&
			cookieParola !== ""
		) {
			this.setState(
				{
					username: cookieUser,
					password: cookieParola,
					logareAutomata: true,
				},
				() => {
					this.LoginCronos({
						NUME_LOGIN: cookieUser,
						PAROLA: cookieParola,
						ipAdress: ipAdress,
					});
				}
			);
		}
	};

	AddValue = (data) => {
		var url = this.$f7.data.baseURL + this.state.linkApi + "/PostSesiuni";
		var urlGet =
			this.$f7.data.baseURL +
			this.state.linkApi +
			"/GetUltimaSesiuneBySalariat/" +
			this.$f7.data.user.iD_SALARIAT;
		let outDate = localStorage.getItem("dataIesire");
		const newConnection = new HubConnectionBuilder()
			.withUrl(this.$f7.data.baseURL + "/hubs/chat")
			.configureLogging(LogLevel.Information)
			.withAutomaticReconnect()
			.build();

		//newConnection.keepAliveIntervalInMilliseconds = 15 * 1000;
		//newConnection.serverTimeoutInMilliseconds = 30 * 1000;

		axios
			.get(urlGet)
			.then((response) => {
				if (response.data.dataIesire === null) {
					if (outDate !== undefined) {
						//this.verificareData(outDate, response.data.dataIntrare);

						response.data.dataIesire = outDate;

						axios({
							data: response.data,
							headers: { "Content-Type": "application/json" },
							method: "put",
							url:
								this.$f7.data.baseURL +
								this.state.linkApi +
								"/PutSesiuni/" +
								response.data.idSesiune,
						})
							.then((response) => {})
							.catch((error) => {});
					}
					
				}
					var urlAfterLogin =
					this.$f7.data.rolAdevarat < 6
						? "/"
						: "/estimatLucratTaskuriActivitate/";
				axios({
					data: data,
					headers: { "Content-Type": "application/json" },
					method: "post",
					url: url,
				})
					.then((response) => {
						this.$f7.data.idSesiune = response.data.idSesiune;
						this.GetValuesAfterLogin()
							.then((bazaNearhivate) => {
								this.$f7.data.userPassword = this.state.password;
								this.$f7.methods.setCookie(
									"repornire",
									this.state.repornire,
									90
								);
								this.setState({ loading: false }, () => {
									if (this.state.repornire) {
										this.$f7.methods
											.VerificareTaskActivByUser(this.$f7.data.user.iD_SALARIAT)
											.then((exista, responseVerificare) => {
												if (!exista) {
													//Nu exista task pornit,pornesc ultimul task care nu este inchis.
													axios({
														data: {
															idSalariat: this.$f7.data.user.iD_SALARIAT,
														},
														headers: { "Content-Type": "application/json" },
														method: "post",
														url:
															this.$f7.data.baseURL +
															"/api/RealizareSarcini/PostInceputLatest",
													})
														.then((response) => {
															this.$f7.methods.openNotification(
																"S-a repornit ultimul task!"
															);

															this.setState(
																{
																	connection: newConnection,
																},
																() => {
																	if (this.state.connection) {
																		this.state.connection
																			.start()
																			.then((result) => {
																				console.log("Connected!");
																				this.$f7.data.signalRConnection =
																					this.state.connection;

																				this.$f7router.navigate(urlAfterLogin, {
																					reloadAll: true,
																					props: {
																						dataSourceProiecte: bazaNearhivate,
																					},
																				});
																			})
																			.catch((e) =>
																				console.log("Connection failed: ", e)
																			);
																	} else {
																		this.$f7router.navigate(urlAfterLogin, {
																			reloadAll: true,
																			props: {
																				dataSourceProiecte: bazaNearhivate,
																			},
																		});
																	}
																}
															);
														})
														.catch((err) => {
															this.$f7.methods.openNotification(
																"Nu s-a putut reporni taskul!"
															);

															this.setState(
																{
																	connection: newConnection,
																},
																() => {
																	if (this.state.connection) {
																		this.state.connection
																			.start()
																			.then((result) => {
																				console.log("Connected!");
																				this.$f7.data.signalRConnection =
																					this.state.connection;

																				this.$f7router.navigate(urlAfterLogin, {
																					reloadAll: true,
																					props: {
																						dataSourceProiecte: bazaNearhivate,
																					},
																				});
																			})
																			.catch((e) =>
																				console.log("Connection failed: ", e)
																			);
																	} else {
																		this.$f7router.navigate(urlAfterLogin, {
																			reloadAll: true,
																			props: {
																				dataSourceProiecte: bazaNearhivate,
																			},
																		});
																	}
																}
															);
														});
												} else {
													this.setState(
														{
															connection: newConnection,
														},
														() => {
															if (this.state.connection) {
																this.state.connection
																	.start()
																	.then((result) => {
																		console.log("Connected!");
																		this.$f7.data.signalRConnection =
																			this.state.connection;

																		this.$f7router.navigate(urlAfterLogin, {
																			reloadAll: true,
																			props: {
																				dataSourceProiecte: bazaNearhivate,
																			},
																		});
																	})
																	.catch((e) =>
																		console.log("Connection failed: ", e)
																	);
															} else {
																this.$f7router.navigate(urlAfterLogin, {
																	reloadAll: true,
																	props: {
																		dataSourceProiecte: bazaNearhivate,
																	},
																});
															}
														}
													);
												}
											})
											.catch((err) => {
												this.$f7.methods.openNotification("Eroare:" + err);

												this.setState(
													{
														connection: newConnection,
													},
													() => {
														if (this.state.connection) {
															this.state.connection
																.start()
																.then((result) => {
																	console.log("Connected!");
																	this.$f7.data.signalRConnection =
																		this.state.connection;

																	this.$f7router.navigate(urlAfterLogin, {
																		reloadAll: true,
																		props: {
																			dataSourceProiecte: bazaNearhivate,
																		},
																	});
																})
																.catch((e) =>
																	console.log("Connection failed: ", e)
																);
														} else {
															this.$f7router.navigate(urlAfterLogin, {
																reloadAll: true,
																props: {
																	dataSourceProiecte: bazaNearhivate,
																},
															});
														}
													}
												);
											});
									} else {
										this.setState(
											{
												connection: newConnection,
											},
											() => {
												if (this.state.connection) {
													this.state.connection
														.start()
														.then((result) => {
															console.log("Connected!");
															this.$f7.data.signalRConnection =
																this.state.connection;

															this.$f7router.navigate(urlAfterLogin, {
																reloadAll: true,
																props: {
																	dataSourceProiecte: bazaNearhivate,
																},
															});
														})
														.catch((e) =>
															console.log("Connection failed: ", e)
														);
												} else {
													this.$f7router.navigate(urlAfterLogin, {
														reloadAll: true,
														props: {
															dataSourceProiecte: bazaNearhivate,
														},
													});
												}
											}
										);
									}
								});
							})
							.catch((err) => {
								this.setState({ loading: false });
								console.log("error", err);
							});
					})
					.catch((error) => {
						console.log("error", error);
						this.$f7.methods.openNotification("Eroare la logare");
					});
			})
			.catch((error) => {
				console.log("error", error);
				this.$f7.methods.openNotification(
					"Eroare la login / verificarea ultimei sesiuni"
				);
			});
	};

	render() {
		return (
			<Page style={{ height: "100%" }} noToolbar noNavbar noSwipeback>
				<div style={{ width: "100%", height: "100%" }}>
					<Row noGap style={{ width: "100%", height: "100%" }}>
						<Col
							{...settings1}
							style={{
								background:
									"url('./img/imagineLogin.webp') var(--f7-theme-color)",
								backgroundSize: "cover",
								backgroundBlendMode: "soft-light",
								height: "100%",
							}}
							className="col-center-content topImage"
						>
							<div style={{ textAlign: "center  " }}>
								<Icon
									className={"iconNemodificat"}
									color={"white"}
									size={65}
									material="person_pin"
								/>
								<h1 style={{ color: "white", fontSize: 30, margin: 0 }}>
									Login
								</h1>
							</div>
						</Col>
						<Col {...settings3} className="col-center-content bottomForm">
							<Card className={"cardForm"}>
								<CardContent>
									<List
										mediaList
										form
										noHairlinesBetweenMd
										inset
										onSubmit={(e) => {
											e.preventDefault();
											this.GetIp()
												.then((ipAdress) => {
													this.LoginCronos({
														NUME_LOGIN: this.state.username,
														PAROLA: this.state.password,
														ipAdress: ipAdress,
													});
												})
												.catch((err) => {
													this.$f7.methods.openNotification("Error:", err);
												});
										}}
									>
										<label>
											<ListInput
												floatingLabel
												label="Username*"
												style={{
													padding: "0px",
													margin: "0px",
													listStyle: "none",
												}}
												name="Username"
												validate
												required
												inputId="Username"
												// type="email"
												placeholder="Your username"
												value={this.state.username}
												onInput={(e) => {
													this.setState({ username: e.target.value });
												}}
											>
												<Icon
													color={"var(--f7-theme-color)"}
													size={20}
													material="face"
													slot="content-start"
												/>
											</ListInput>
										</label>

										<label>
											<ListInput
												style={{
													padding: "0px",
													margin: "0px",
													listStyle: "none",
												}}
												name="Password"
												required
												floatingLabel
												label="Password*"
												inputId="Password"
												validate
												type={this.state.typepassword}
												placeholder="Your password"
												value={this.state.password}
												onInput={(e) => {
													this.setState({ password: e.target.value });
												}}
											>
												<Icon
													color={"var(--f7-theme-color)"}
													size={20}
													material="lock"
													slot="content-start"
												/>
												<Button
													tooltip={"Afisare parola"}
													style={{
														margin: "0px",
														right: "0px",
														top: "10px",
														position: "absolute",
														padding: "0px",
														width: "50px",
													}}
													slot="content-end"
													iconMaterial={"remove_red_eye"}
													onClick={() => {
														this.setState({
															typepassword:
																this.state.typepassword === "password"
																	? "text"
																	: "password",
														});
													}}
												/>
											</ListInput>
										</label>

										<Row>
											<Col>
												<ListItem
													className="simpleInlineCheckbox"
													checkbox
													title="Logare automata"
													name="logareAutomata"
													checked={this.state.logareAutomata}
													onChange={(e) => {
														this.setState({
															logareAutomata: e.currentTarget.checked,
														});
													}}
												/>
											</Col>
											<Col>
												<ListItem
													className="simpleInlineCheckbox"
													checkbox
													title="Repornire ultimul task lucrat"
													name="repornireUltimulTaskLucrat"
													checked={this.state.repornire}
													onChange={(e) => {
														this.setState({
															repornire: e.currentTarget.checked,
														});
													}}
												/>
											</Col>
										</Row>

										<ListItem>
											<Row style={{ width: "100%" }}>
												<Col className="col-center-content">
													<Button
														tooltip={"Logare"}
														disabled={this.state.loading}
														style={{
															margin: "16px",
															padding: "0 32px",
															width: "auto",
														}}
														fill
														type="submit"
													>
														{this.state.loading ? (
															<Preloader
																color={"white"}
																style={{ color: "white" }}
															/>
														) : (
															<span>Login</span>
														)}
													</Button>
												</Col>
											</Row>
										</ListItem>
									</List>
								</CardContent>
							</Card>
						</Col>
					</Row>
				</div>
				<div
					style={{
						height: 15,
						width: 15,
						position: "absolute",
						left: 0,
						bottom: 0,
					}}
					onClick={() => {
						this.functieTeste();
					}}
				/>
			</Page>
		);
	}
}

export default LoginPage;