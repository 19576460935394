import React, { Component, createRef, useState, useEffect } from 'react'
import { Page } from 'framework7-react'
import moment from 'moment'
import axios from 'axios'

import { Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, Card, CardContent } from '@mui/material'
import { PlayArrow, ExpandMore, Stop, Pause, Close } from '@mui/icons-material'

import Form, { SimpleItem, GroupItem, EmptyItem } from 'devextreme-react/form'
import TreeList, { Column as TreeColumn, Lookup } from 'devextreme-react/tree-list'
import DataGrid, { MasterDetail } from 'devextreme-react/data-grid'
import FileManager, { Permissions, Toolbar, ContextMenu, Item, FileSelectionItem, ItemView, Details, Column } from 'devextreme-react/file-manager'
import { Button } from 'devextreme-react'
import { LoadPanel } from 'devextreme-react/load-panel'
import 'devextreme-react/text-area'
import 'devextreme-react/select-box'

let scrollEventElement = null

const PVDetalii = ({ data, link, f7 }) => {
  const [DS, setDS] = useState([])

  const lista = [
    { key: "Fara", value: null },
    { key: "Asistenta tehnica la sediul beneficiarului", value: 0 },
    { key: "Asistenta de la distanta prin modem sau internet", value: 1 },
    { key: "Deplasare la sediul beneficiarului", value: 2 }
  ]

  const getPVDetalii = idPV => {
    axios.get(`${link}/${idPV}`).then(response => {
      response.data.forEach(obj => {
        obj["Id"] = obj.idProceseVerbaleDetalii
        obj["Salariat"] = obj.id_salariat
        obj["TipOperatie"] = obj.id_operatiune
      })

      const inputs = [
        { key: "Id", tip: "number" },
        { key: "Denumire", requiredAdd: true, requiredEdit: true },
        { key: "Salariat", tip: "selectbox", lista: f7.data.Salariati, requiredAdd: true, requiredEdit: true },
        { key: "Operatiune" },
        { key: "OreLucrate", tip: "number" },
        { key: "Suma", tip: "number" },
        { key: "Comentariu" },
        { key: "Complus" },
        {
          key: "TipOperatie",
          tip: "selectbox",
          lista: [
            { key: "Fara", value: null },
            { key: "Asistenta tehnica la sediul beneficiarului", value: 0 },
            { key: "Asistenta de la distanta prin modem sau internet", value: 1 },
            { key: "Deplasare la sediul beneficiarului", value: 2 }
          ]
        }
      ]

      setDS(f7.methods.reconstructieBaza(response, inputs))
    }).catch(error => {
      console.log("Task.jsx getPVDetalii error:", error)

      f7.dialog.confirm(
        "A aparut o eroare la preluarea detaliilor PV de pe server. Incearca din nou.",
        "Ups...",
        () => getPVDetalii(idPV),
        () => {}
      )

      setDS([])
    })
  }

  useEffect(() => {
    getPVDetalii(data.data.Id)
  }, [])
  
  return (
    <>
      <div>
        <h3>Detalii</h3>
      </div>

      <DataGrid
        dataSource={DS}
        showRowLines={true}
        showBorders={true}
        columnAutoWidth={true}
        rowAlternationEnabled
        hoverStateEnabled
        paging={{
          enabled: true,
          pageSize: 10
        }}
      >
        <TreeColumn dataField="Id" caption="ID" dataType="number" />
        <TreeColumn dataField="Denumire" caption="Denumire" />

        <TreeColumn dataField="Salariat" caption="Salariat" dataType="number">
          <Lookup dataSource={f7.data.Salariati} displayExpr="key" valueExpr="value" />
        </TreeColumn>

        <TreeColumn dataField="Operatiune" caption="Operatiune" />
        <TreeColumn dataField="OreLucrate" caption="Ore lucrate" dataType="number" />
        <TreeColumn dataField="Suma" caption="Suma" dataType="number" />
        <TreeColumn dataField="Comentariu" caption="Comentariu" />
        <TreeColumn dataField="Complus" caption="Comentariu extra" />

        <TreeColumn dataField="TipOperatie" caption="Tip operatie" dataType="number">
          <Lookup dataSource={lista} displayExpr="key" valueExpr="value" />
        </TreeColumn>
      </DataGrid>
    </>
  )
}

class Task extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      data: {},
      atasamente: [],
      dataSourceIstoric: [],
      dataSourceActivitate: [],
      dataSourcePV: [],
      definitie: "",
      clasaDefinitie: "",
      listaSalariatModule: [],
      listaProiecte: [],
      listaTipTermen: [],
      
      loadingMain: false,
      loadingAtasamente: false,
      loadingIstoric: false,
      loadingActivitate: false,
      loadingPV: false,
      scrolled: false
    }

    this.linkApiSalariatModule = `${this.$f7.data.baseURL}/api/SalariatContracteModule/GetSALARIAT_CONTRACTE_MODULEBySalariat/${this.$f7.data.user.iD_SALARIAT}`
    this.linkApiTipTermen = `${this.$f7.data.baseURL}/api/TipTermen`
    this.linkApiProiectById = `${this.$f7.data.baseURL}/api/Proiecte/GetPROIECTESauArhivate/${props.idProiect}`
    this.linkApiSarciniByProiect = `${this.$f7.data.baseURL}/api/SarciniSalariat/GetSARCINI_SALARIATByProiect/${props.idProiect}`
    this.linkApiAtasamente = `${this.$f7.data.baseURL}/api/Documente/GetDOCUMENTEbyProiectId/${props.idProiect}`
    this.linkApiAtasamenteMantis = `${this.$f7.data.baseURL}/api/SarciniSalariat/GetMantisFisiere`
    this.linkApiUploadAtasamente = `${this.$f7.data.baseURL}/api/Documente/PostDOCUMENTE`
    this.linkApiActivitate = `${this.$f7.data.baseURL}/api/RealizareSarcini/GetREALIZARE_SARCINI_BY_PROIECT_AND_SALARIAT/${props.idProiect}/${this.$f7.data.rol < 2 ? -100 : this.$f7.data.idSalariatFunctii}`
    this.linkApiIstoric = `${this.$f7.data.baseURL}/api/Proiecte/GetIstoricProiect`
    this.linkApiPV = `${this.$f7.data.baseURL}/api/ProceseVerbale${props.idProiect ? `/GetProceseVerbaleFiltrate/${props.idProiect}` : ""}`
    this.linkApiPVDetalii = `${this.$f7.data.baseURL}/api/ProceseVerbaleDetalii/GetProceseVerbaleDetalii`
    this.linkApiStart = `${this.$f7.data.baseURL}/api/RealizareSarcini/PostInceput`

    this.fileManagerRef = createRef()
  }

  /**
   * @description Ia modulele la care este atasat salariatul
   * @date 03/02/2023
   * @function
   * @memberof Task
   */
  getValuesSalariatModule = () => {
    return new Promise((resolve, reject) => {
      axios.get(this.linkApiSalariatModule).then(response => {
        const lista = []

        response.data.forEach(item => {
          lista.push(item.ref_proiect)
        })
  
        const listaClientiSalariat = []
        response.data.forEach(f => {
          const referinta = f.ref_proiect
          const proiectul = this.$f7.data.allProiecte.find(r => r.iD_PROIECT === referinta)

          if (proiectul) {
            const idClientProiect = proiectul.id_client
            if (!listaClientiSalariat.includes(idClientProiect)) listaClientiSalariat.push(idClientProiect)
          }
        })

        const listaClientiSalariatFinal = this.$f7.data.Firme.filter(r => listaClientiSalariat.includes(r.value))
        listaClientiSalariatFinal.sort((a, b) => a.key > b.key ? 1 : (b.key > a.key ? -1 : 0))
  
        //SELECTEAZA PRIMUL CLIENT/PROIECT
        if (listaClientiSalariatFinal.length > 0) {
          const arrProiecte = this.$f7.data.allProiecte
          .filter(r => (r.id_nivel_proiect === 1 || r.id_nivel_proiect === 2) && r.id_client === listaClientiSalariatFinal[0].value)
          .filter(r => lista.includes(r.iD_PROIECT))
          .map(r => ({
            ...r,
            key: r.denumire,
            value: r.iD_PROIECT
          }))
          
          arrProiecte.sort((a, b) => a.denumire > b.denumire ? 1 : (b.denumire > a.denumire ? -1 : 0))
        }

        this.setState({
          listaSalariatModule: lista
        }, () => {
          resolve(response)
        })
      }).catch(error => {
        console.log("Task.jsx getValuesSalariatModule error:", error)

        this.setState({
          listaSalariatModule: []
        }, () => {
          this.$f7.dialog.confirm(
            "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
            "Ups...",
            () => this.getValuesSalariatModule(),
            () => {}
          )
        })
  
        reject(error)
      })
    })
  }

  /**
   * @description Ia informatiile taskului selectat
   * @date 03/02/2023
   * @function
   * @memberof Task
   */
  getValues = () => {
    this.setState({
      loadingMain: true
    }, () => {
      axios.all([
        axios.get(this.linkApiTipTermen),
        axios.get(this.linkApiProiectById),
        axios.get(this.linkApiSarciniByProiect)
      ]).then(axios.spread((responseTipTermen, responseProiect, responseSarcini) => {
        const tipTermen = responseTipTermen.data
        const info = responseProiect.data
        const sarcini = responseSarcini.data
  
        console.log("responses", tipTermen, info, sarcini)
  
        const listaTipTermen = [{ key: "Fara", value: null }]
        const sarcina = sarcini.filter(item => item.datA_DEALOCARII === null && item.dealoC_CINE === null)
  
        for (const termen of tipTermen) {
          listaTipTermen.push({
            key: termen.denumire,
            value: termen.iD_TERMEN
          })
        }
  
        let stringPath = ""
        if (info.path !== null && info.path !== undefined) {
          const stringPathArray = info.path.split("\\").filter(item => item !== "")
  
          if (stringPathArray.length > 0) {
            stringPathArray.forEach((item, index) => {
              stringPath += this.$f7.data.Proiecte.find(proiect => proiect.value === parseInt(item)).key
              if (index < stringPathArray.length - 1) stringPath += " \\ "
            })
          }
        }
  
        const nivel = info.id_nivel_proiect
        const idDezvoltator = info.id_dezvoltator ? info.id_dezvoltator : info.id_responsabil
        const stadiu = info.iD_STADIU
        let definitie = ""
  
        const denNivel = this.$f7.data.NivelProiect.find(x => x.value === nivel)
        let denumireNivel = ""
        if (denNivel) denumireNivel = denNivel.key
  
        const clasaDefinitie = ""
        definitie += denumireNivel + " | "
  
        if (nivel === 4) {
          if (idDezvoltator) {
            const dezv = this.$f7.data.Salariati.find(x => x.value === idDezvoltator)
            let numeDezvoltator = ""
  
            if (dezv) numeDezvoltator = dezv.key
  
            definitie += numeDezvoltator + " | "
  
            let denumireStadiu = ""
  
            switch (stadiu) {
              default:
              case 1:
                denumireStadiu = "Nelucrat"
              break
  
              case 2:
                denumireStadiu = "In lucru | "
                denumireStadiu += this.convertToFriendlyTime(info.timp_l * 60)
              break
  
              case 3:
                denumireStadiu = "Lucrat | "
                denumireStadiu += this.convertToFriendlyTime(info.timp_l * 60)
              break
  
              case 6:
                denumireStadiu = "Inchis | ";
                denumireStadiu += this.convertToFriendlyTime(info.timp_l * 60)
              break
            }
  
            definitie += denumireStadiu + " | "
            definitie += info.areDocumente ? "Cu atasamente" : "Fara atasamente"
          } else {
            definitie = "Nealocat"
          }
        }
  
        this.setState({
          data: {
            path: stringPath,
            client: info.id_client,
            prioritate: info.id_prioritate,
            nivel: info.id_nivel_proiect,
            tipProiect: info.id_tip_proiect,
            isTask: info.task,
            tipSursa: info.id_tip_sursa,
            taskParinte: info.reF_PROIECT,
            stadiu: info.iD_STADIU,
            departament: info.id_departament,
            userImport: info.userImport % 10000000,
            numeReporterMantis: info.numeReporterMantis,
            emailReporterMantis: info.emailReporterMantis,
            comentariuProiect: info.comentariu === null || info.comentariu === "" ? "" : info.comentariu,
            dataCreare: [info.data_creare],
            dataStart: [info.data_start],
            dataLimita: [info.deadline],
            termen: info.id_termen,
            timpMinim: info.timp_plan_d,
            timpSefProiect: info.timp_plan_sp,
            timpSupervizor: info.timp_plan_a,
            timpManager: info.timp_plan_m,
            aprobareDezvoltator: info.aprobat_d,
            aprobareSefProiect: info.aprobat_s,
            aprobareSupervizor: info.aprobat_a,
            aprobareManager: info.aprobat_m,
            numeDezvoltator: info.id_dezvoltator,
            numeSefProiect: info.id_sefproiect,
            numeSupervizor: info.id_arbitru,
            numeManager: info.id_manager,
            denumireProiect: info.denumire === null || info.denumire === "" ? "Fara" : info.denumire,
            risc: info.proc_risc,
            operatie: info.id_operatiuni,
            directorRadacina: info.director_radacina === null || info.director_radacina === "" ? "Fara" : info.director_radacina,
            idSarcina: this.props.idProiect,
            idSarcinaSalariat: sarcina.length > 0 ? sarcina[0].iD_SARCINA : null,
            adaugatDe: info.id_operator_adaugare !== null ? this.$f7.data.Salariati.find(item => item.value === info.id_operator_adaugare).key : "",
            alocatLui: sarcina.length > 0 ? this.$f7.data.Salariati.find(item => item.value === sarcina[0].iD_SALARIAT).key : "",
            observatiiProiect: info.stadiu1 === null || info.stadiu1 === "" ? "" : info.stadiu1,
  
            areDocumente: info.areDocumente,
            ordine: info.ordine,
            bugetTimp: info.buget_timp,
            buget: info.buget,
            taskSimilar: info.idTaskSimilar
          },
          loadingMain: false,
          definitie: definitie,
          clasaDefinitie: clasaDefinitie,
          listaTipTermen: listaTipTermen,
          listaProiecte: this.$f7.data.allProiecte
            .filter(r => (r.id_nivel_proiect === 1 || r.id_nivel_proiect === 2) && r.id_client === info.id_client)
            .filter(r => this.state.listaSalariatModule.includes(r.iD_PROIECT))
            .map(r => ({ ...r, key: r.denumire, value: r.iD_PROIECT }))
        }, () => {
          this.getAtasamente()
          this.getIstoric()
          this.getActivitate()
          this.getPV()
        })
      })).catch(error => {
        console.log("Task.jsx getValues error:", error)
  
        this.setState({
          data: {},
          loadingMain: false
        }, () => {
          this.$f7.dialog.confirm(
            "A aparut o eroare la preluarea datelor proiectului de pe server. Incearca din nou.",
            "Ups...",
            () => this.getValues(),
            () => {}
          )
        })
      })
    })
  }

  /**
   * @description Ia atasamentele taskului selectat
   * @date 03/02/2023
   * @function
   * @memberof Task
   */
  getAtasamente = () => {
    this.setState({
      loadingAtasamente: true
    }, () => {
      const fileItems = []

      if (this.state.data.tipProiect === 55) {
        axios.get(`${this.linkApiAtasamenteMantis}/${this.state.data.userImport}`).then(responseMantis => {
          console.log("atasamenteMantis:", responseMantis.data)
  
          responseMantis.data.forEach(atasament => {
            fileItems.push({
              isDirectory: false,
              name: atasament.filename,
              category: '',
              dateModified: atasament.created_at,
              size: atasament.size,
              content: atasament.content,
              mantisFileId: atasament.id
            })
          })
  
          this.setState({
            atasamente: fileItems,
            loadingAtasamente: false
          })
        }).catch(error => {
          console.log("Task.jsx getAtasamente Mantis error:", error)
          
          this.setState({
            atasamente: [],
            loadingAtasamente: false
          }, () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea atasamentelor din Mantis! Incearca din nou.",
              "Eroare",
              () => this.getAtasamente(),
              () => {}
            )
          })
        })
      } else {
        axios.get(this.linkApiAtasamente).then(responseAtasamente => {
          responseAtasamente.data.forEach(atasament => {
            let contentSize = 0
            if (atasament.continut) {
              const b64Length = atasament.continut.length
              const paddingLength = atasament.continut.slice(-2).length
              contentSize = (3 * (b64Length / 4)) - paddingLength
            }
  
            fileItems.push({
              isDirectory: false,
              name: atasament.numE_FISIER,
              category: '',
              dateModified: atasament.datA_MODIF,
              size: contentSize,
              content: atasament.continut,
              docId: atasament.iD_DOCUMENT,
              projId: atasament.iD_PROIECT,
              typeId: atasament.iD_TIP_DOCUMENT,
              authorId: atasament.saL_AUTOR
            })
          })
  
          this.setState({
            atasamente: fileItems,
            loadingAtasamente: false
          })
        }).catch(error => {
          console.log("Task.jsx getAtasamente error:", error)

          this.setState({
            atasamente: [],
            loadingAtasamente: false
          }, () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea atasamentelor de pe server! Incearca din nou.",
              "Eroare",
              () => this.getAtasamente(),
              () => {}
            )
          })
        })
      }
    })
  }

  /**
   * @description Incarca atasamente la taskul selectat
   * @date 03/02/2023
   * @function
   * @param {*} e evenimentul care contine fisierul selectat /fisierele selectate
   * @memberof Task
   */
  fileUploading = e => {
    this.setState({
      loadingAtasamente: true
    }, () => {
      if (e && e.fileData) {
        const { fileData } = e
  
        const reader = new FileReader()
  
        reader.onload = () => {
          const result = reader.result
          const content = result.split(",")[1]
          
          const uploadFile = {
            sal_autor: this.$f7.data.idSalariatFunctii,
            id_proiect: this.props.idProiect,
            data_modif: moment().format(),
            dir_parinte: null,
            ref_document: null,
            nume_fisier: fileData.name,
            nume_path: fileData.name,
            id_initial: null,
            continut: content,
            comentariu: null,
            id_tip_document: 2,
            id_tip_stadiu: null,
          }
  
          axios.post(this.linkApiUploadAtasamente, uploadFile).then(response => {
            console.log("Task.jsx fileUploading response:", response)

            this.setState({
              loadingAtasamente: false
            }, () => {
              this.$f7.methods.openNotification(
                `Documentul ${fileData.name} a fost adaugat ca atasament!`,
                undefined,
                "succes"
              )
            })
          }).catch(error => {
            console.log("Task.jsx fileUploading error:", error)
  
            this.setState({
              loadingAtasamente: false
            }, () => {
              this.$f7.methods.openNotification(
                `Documentul ${fileData.name} nu a fost adaugat ca atasament din cauza unei erori! Incearca din nou.`,
                undefined,
                "eroare"
              )
            })
          })
        }
  
        reader.onerror = error => {
          console.log(`Eroare la incarcarea fisierului ${fileData.name}!`, error)
  
          this.setState({
            loadingAtasamente: false
          }, () => {
            this.$f7.methods.openNotification(
              `Eroare la incarcarea fisierului ${fileData.name}!`,
              undefined,
              "eroare"
            )
          })
        }
  
        reader.readAsDataURL(fileData)
      } else {
        this.setState({
          loadingAtasamente: false
        }, () => {
          this.$f7.methods.openNotification(
            "Fisier necorespunzator!",
            undefined,
            "eroare"
          )
        })
      }
    })
  }

  /**
   * @description Ia istoricul taskului selectat
   * @date 03/02/2023
   * @function
   * @memberof Task
   */
  getIstoric = () => {
    this.setState({
      loadingIstoric: true
    }, () => {
      const payload = {
        tipRequest: 1,
        idProiect: this.props.idProiect,
        dataStart: moment("1980-01-01T01:00:00Z").format(),
        dataEnd: moment().format()
      }
  
      axios.post(this.linkApiIstoric, payload).then(response => {
        const inputs = [
          { key: "Data", keyBaza: "data", tip: "datebox" },
          { key: "DataFinal", keyBaza: "dataFinal", tip: "datebox" },
          { key: "Salariat", keyBaza: "salariatCurent", tip: "selectbox", lista: this.$f7.data.Salariati },
          { key: "SalariatPropunere", keyBaza: "salariatVechi", tip: "selectbox", lista: this.$f7.data.Salariati },
          { key: "Comentariu", keyBaza: "comentariu" },
          { key: "ComentariuPV", keyBaza: "comentariuPV" },
          { key: "ComentariuPlus", keyBaza: "comentariu1" },
          { key: "Stare", keyBaza: "comentariuStare" },
          { key: "Durata", keyBaza: "durata" },
          { key: "IdTask", keyBaza: "idTask", tip: "number" },
          { key: "IdClient", keyBaza: "idClient", tip: "number" },
          { key: "RefTask", keyBaza: "refTask", tip: "number" }
        ]
  
        response.data.forEach(eveniment => {
          eveniment = this.$f7.methods.restructurareBaza(eveniment, inputs, "key")
        })
  
        this.setState({
          dataSourceIstoric: this.$f7.methods.reconstructieBaza(response, inputs),
          loadingIstoric: false
        })
      }).catch(error => {
        console.log("Task.jsx getIstoric error:", error)
  
        this.setState({
          dataSourceIstoric: [],
          loadingIstoric: false
        }, () => {
          this.$f7.dialog.confirm(
            "A aparut o eroare la preluarea istoricului de pe server! Incearca din nou.",
            "Eroare",
            () => this.getIstoric(),
            () => {}
          )
        })
      })
    })
  }

  /**
   * @description Ia activitatea de la taskul selectat
   * @date 03/02/2023
   * @function
   * @memberof Task
   */
  getActivitate = () => {
    this.setState({
      loadingActivitate: true
    }, () => {
      axios.get(this.linkApiActivitate).then(response => {
        console.log("Task.jsx getActivitate response:", response)
  
        response.data.forEach(obj => {
          obj["MomentInceput"] = obj.intrare
          obj["MomentSfarsit"] = obj.iesire
          obj["NumeSalariat"] = obj.iD_SALARIAT
          obj["ComentariuPlus"] = obj.comentariU1
          obj["ComentariuPv"] = obj.comentariu_pv
          obj["Client"] = obj.iD_CLIENT
          obj["U"] = obj.unitati
  
          const timpi = this.calculareTimpiTotal([obj])
          const timp = `${timpi.zile}z ${timpi.ore}h ${timpi.minute}m ${timpi.secunde}s`
            
          obj["Timp"] = timp
          obj["Stadiu"] = this.$f7.data.Proiect.iD_STADIU
          obj["Operatie"] = this.$f7.data.Proiect.id_operatiuni
  
          if (obj.manual === false) {
            obj["Status"] = "AUTO"
          } else {
            if (obj.aprobat === false) {
              obj["Status"] = "RESPINS"
            } else if (obj.aprobat === true) {
              obj["Status"] = "APROBAT"
            } else {
              obj["Status"] = "IN ASTEPTARE"
            }
          }
        })
  
        const inputs = [
          { key: "Id_Realizare" },
          { key: "Ref_Realizare" },
          { key: "NumeSalariat", tip: "selectbox", lista: this.$f7.data.Salariati, listaEdit: this.Salariati },
          { key: "MomentInceput", tip: "datebox", requiredAdd: true, requiredEdit: true },
          { key: "MomentSfarsit", tip: "datebox", requiredAdd: true, requiredEdit: true },
          { key: "Timp" },
          { key: "U", tip: "number" },
          { key: "Client", tip: "selectbox", lista: this.$f7.data.Firme },
          { key: "Comentariu", requiredAdd: false, requiredEdit: false, tip: "textarea" },
          { key: "ComentariuPv", requiredAdd: false, requiredEdit: false, tip: "textarea" },
          { key: "ComentariuPlus", requiredAdd: false, requiredEdit: false, tip: "textarea" },
          { key: "Status", requiredAdd: false, requiredEdit: false }
        ]
  
        const baza = this.$f7.methods.reconstructieBaza(response, inputs)
        const bazaTable = baza
        const bazaDistincte = Array.from(new Set(bazaTable.map(item => item.NumeSalariat)))
  
        const bazaDistincteItems = []
        bazaDistincte.map(idSal => {
          const newId = Math.floor(Math.random() * Math.floor(99999999))
          const obj = { ...bazaTable.find(f => f.NumeSalariat === idSal) }
  
          obj.Ref_Realizare = -1
          obj.Id_Realizare = newId
  
          let com = ""
          let comPlus = ""
          let comPV = ""
  
          baza.forEach(r => {
            if (r.NumeSalariat === idSal) {
              r.Ref_Realizare = obj.Id_Realizare
  
              const comm = r.Comentariu === null ? "" : r.Comentariu
              const commPlus = r.ComentariuPlus === null ? "" : r.ComentariuPlus
              const commPV = r.ComentariuPv === null ? "" : r.ComentariuPv
  
              com += " " + comm
              comPlus += " " + commPlus
              comPV += " " + commPV
            }
          })
  
          obj.Comentariu = com
          obj.ComentariuPlus = comPlus
          obj.ComentariuPv = comPV
  
          const lista = baza.filter(r => r.NumeSalariat === idSal)
          const timpi = this.calculareTimpiTotal(lista)
          const timp = `${timpi.zile}z ${timpi.ore}h ${timpi.minute}m ${timpi.secunde}s`
          
          obj.Timp = timp
          bazaDistincteItems.push(obj)
        })
  
        this.setState({
          dataSourceActivitate: baza.concat(bazaDistincteItems),
          loadingActivitate: false
        })
      }).catch(error => {
        console.log("Task.jsx getActivitate error:", error)

        this.setState({
          dataSourceActivitate: [],
          loadingActivitate: false
        }, () => {
          this.$f7.dialog.confirm(
            "A aparut o eroare la preluarea activitatii de pe server! Incearca din nou.",
            "Eroare",
            () => this.getActivitate(),
            () => {}
          )
        })
      })
    })
  }

  /**
   * @description Ia informatiile despre PV de la taskul selectat
   * @date 03/02/2023
   * @function
   * @memberof Task
   */
  getPV = () => {
    this.setState({
      loadingPV: true
    }, () => {
      axios.get(this.linkApiPV).then(response => {
        response.data.forEach(obj => {
          obj["Id"] = obj.idProceseVerbale
          obj["Salariat"] = obj.id_salariat
          obj["Client"] = obj.id_client
          obj["CaleDocument"] = obj.cale_document
          obj["NumarFactura"] = obj.numar_fact
          obj["DataFactura"] = obj.data_fact
          obj["ReprezentantClient"] = obj.reprez_client
        })
  
        const inputs = [
          { key: "Id", tip: "number" },
          { key: "Salariat", tip: "selectbox", lista: this.$f7.data.Salariati, listaEdit: this.Salariati, requiredAdd: true, requiredEdit: true },
          { key: "DataPV", tip: "datebox" },
          { key: "NumarPV", tip: "number" },
          { key: "Client", tip: "selectbox", lista: this.$f7.data.Firme },
          { key: "Aplicatia" },
          { key: "Listat", tip: "bool" },
          { key: "Validat", tip: "bool" },
          { key: "CaleDocument" },
          { key: "NumarFactura" },
          { key: "DataFactura", tip: "datebox" },
          { key: "ReprezentantClient" }
        ]
  
        this.setState({
          dataSourcePV: this.$f7.methods.reconstructieBaza(response, inputs),
          loadingPV: false
        })
      }).catch(error => {
        console.log("Task.jsx getPV error:", error)
  
        this.setState({
          dataSourcePV: [],
          loadingPV: false
        }, () => {
          this.$f7.dialog.confirm(
            "A aparut o eroare la preluarea datelor PV de pe server. Incearca din nou.",
            "Ups...",
            () => this.getPV(),
            () => {}
          )
        })
      })
    })
  }

  /**
   * @description Calculeaza timpul total realizat dintr-o lista de realizari
   * @date 03/02/2023
   * @function
   * @param {*} lista lista de realizari
   * @memberof Task
   */
  calculareTimpiTotal = lista => {
    let curent = 0;

    lista.forEach(item => {
      const inceput = moment(item.MomentInceput).valueOf()
      const sfarsit = item.MomentSfarsit === null ? moment().valueOf() : moment(item.MomentSfarsit).valueOf()
      if (inceput !== null) curent += sfarsit - inceput
    })

    // Calculam zile, ore, minute si secunde pentru timpul curent (durata activitatii curente de la un task)
    const zileActual = Math.floor(curent / (8 * 60 * 60 * 1000))
    curent = curent % (8 * 60 * 60 * 1000)
    const oreActual = Math.floor(curent / (60 * 60 * 1000))
    curent = curent % (60 * 60 * 1000)
    const minuteActual = Math.floor(curent / (60 * 1000))
    curent = curent % (60 * 1000)
    const secundeActual = Math.floor(curent / 1000)

    return {
      zile: zileActual,
      ore: oreActual,
      minute: minuteActual,
      secunde: secundeActual
    }
  }

  /**
   * @description Transforma secundele intr-un format mai placut (zile : ore : minute)
   * @date 03/02/2023
   * @function
   * @param {*} seconds numarul de secunde
   * @memberof Task
   */
  convertToFriendlyTime = seconds => {
    const days = Math.floor(seconds / (8 * 3600))

    seconds = seconds % (8 * 3600)
    const hours = Math.floor(seconds / 3600)

    seconds = seconds % 3600
    const minutes = Math.floor(seconds / 60)

    seconds = seconds % 60

    let output

    if (days) {
      if (hours) {
        if (minutes) {
          output = (days > 1 ? days + "z " : days + " z") + ", " + (hours > 1 ? hours + "h " : hours + "h ") + " : " + (minutes > 1 ? minutes + "m " : minutes + "m ")
        } else {
          output = (days > 1 ? days + "z " : days + " z") + " : " + (hours > 1 ? hours + "h " : hours + "h ")
        }
      } else {
        if (minutes) {
          output = (days > 1 ? days + "z " : days + " z") + " : " + (minutes > 1 ? minutes + "m " : minutes + "m ")
        } else {
          output = days > 1 ? days + "z " : days + " z"
        }
      }
    } else {
      if (hours) {
        if (minutes) {
          output = (hours > 1 ? hours + "h " : hours + "h ") + " : " + (minutes > 1 ? minutes + "m " : minutes + "m ")
        } else {
          output = hours > 1 ? hours + "h " : hours + "h "
        }
      } else {
        if (minutes) {
          output = minutes > 1 ? minutes + "m " : minutes + "m "
        } else {
          output = "0"
        }
      }
    }

    return "" + output
  }

  /**
   * @description Seteaza parametrul care marcheaza pagina ca "derulata"
   * @date 03/02/2023
   * @function
   * @param {*} e evenimentul primit de la listener
   * @memberof Task
   */
  setScrolled = e => {
    if (e.target.scrollTop > 15 && !this.state.scrolled) {
      this.setState({
        scrolled: true
      })
    } else if (e.target.scrollTop < 5 && this.state.scrolled) {
      this.setState({
        scrolled: false
      })
    }   
  }

  /**
   * @description Se adauga o realizare la un anumit proiect pe o anumita sarcina de la acel proiect
   * @date 03/02/2023
   * @function
   * @memberof Task
   */
  // start = () => {
  //   return new Promise((resolve, reject) => {
  //     const pos = { lat: null, lng: null }

  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(position => {
  //         pos.lat = position.coords.latitude
  //         pos.lng = position.coords.longitude
  //       })
  //     }

  //     // this.state.idSelected => this.props.idProiect
  //     // this.linkApiStart

  //     const dataCurenta = moment().format()
  //     const data = {
  //       Comentariu: "",
  //       Unitati: 1,
  //       asumat: true,
  //       comentariU1: "",
  //       dupa_lucru: 0,
  //       iD_CLIENT: null,
  //       iD_SALARIAT: this.$f7.data.idSalariatFunctii,
  //       id_proiect: this.props.idProiect,
  //       iesire: null,
  //       intrare: dataCurenta,
  //       procent_realizare: 0,
  //       manual: false,
  //       aprobat: true,
  //       ultima_activitate: dataCurenta
  //     }

  //     this.$f7.methods.esteDelegatie().then(delegatieCurenta => {
  //       if (delegatieCurenta) {
  //         this.$f7.methods.detaliuDelegatieCurenta(delegatieCurenta.iD_DELEGATIE).then(detaliuDelegatieCurenta => {
  //           if (detaliuDelegatieCurenta) {
  //             if (detaliuDelegatieCurenta.sosire !== null && detaliuDelegatieCurenta.plecare === null) {
  //               const objDetaliuAdd = {
  //                 iD_DELEGATIE: delegatieCurenta.iD_DELEGATIE,
  //                 sosire: dataCurenta,
  //                 iD_PROIECT: this.props.idProiect,
  //                 iD_FAZA: null,
  //                 iD_FIRMA: detaliuDelegatieCurenta.iD_FIRMA,
  //                 plecare: null,
  //                 preluat: false,
  //                 iD_TASK: this.state.idSarcina, //aici
  //                 sosirE_LNG: pos.lng,
  //                 sosirE_LAT: pos.lat,
  //                 //plecarE_LNG: null,
  //                 //plecarE_LAT: null,
  //                 activitate: 1
  //               }

  //               axios.post(this.linkApiStart, data).then(response => {
  //                 if (response.data === "S-a adaugat.") {
  //                   this.AddValueDetaliu(objDetaliuAdd).then(() => {
  //                     const numeProiect = this.$f7.data.Proiecte.find(proiect => proiect.value === this.props.idProiect)?.key
  //                     this.$f7.methods.openNotification(`Task "${numeProiect ?? ""}" pornit!`, "Success")
  //                     resolve(response)
  //                   }).catch(err => reject(err))
  //                 } else {
  //                   // this.$f7.preloader.hide();

  //                   const idProiect = parseInt(response.data)
  //                   const numeProiect = this.$f7.data.Proiecte.find(proiect => proiect.value === idProiect)?.key
   
  //                   this.$f7.dialog.confirm(
  //                     `Exista deja un task pornit la proiectul: ${numeProiect ?? ""}. Doriti sa il opriti?`,
  //                     "Atentie",
  //                     () => {
  //                       // this.$f7.preloader.show();
  //                       const idProiectDeschis = this.state.proiectDeschis?.IdProiect
                        
  //                       this.stop(true, idProiect, false, false, false, idProiectDeschis)
  //                       .then(stopResponse => resolve(stopResponse))
  //                       .catch(err => reject(err))
  //                     },
  //                     () => resolve(response)
  //                   )
  //                 }
  //               }).catch(error => {
  //                 console.log("error", error)
  //                 this.$f7.methods.openNotification("Eroare la adaugare")
  //                 reject(error)
  //               })
  //             } else {
  //               this.$f7.methods.openNotification("Nu puteti porni taskul inainte de a ajunge la destinatie.", "Atentie")
  //               resolve(detaliuDelegatieCurenta)
  //             }
  //           } else {
  //             this.$f7.methods.openNotification( "Nu puteti porni taskul deoarece aveti o delegatie nefinalizata.", "Atentie")
  //             reject(detaliuDelegatieCurenta)
  //           }
  //         }).catch(error => {
  //           console.log("error", error)
  //           reject(error)
  //         })
  //       } else {
  //         axios.post(this.linkApiStart, data).then(response => {
  //           if (response.data === "S-a adaugat.") {
  //             const numeProiect = this.$f7.data.Proiecte.find(proiect => proiect.value === this.props.idProiect)?.key
  //             this.$f7.methods.openNotification(`Task "${numeProiect ?? ""}" pornit!`, "Success")
  //             resolve(response)
  //           } else {
  //             // this.$f7.preloader.hide();

  //             const idProiect = parseInt(response.data)
  //             const numeProiect = this.$f7.data.Proiecte.find(proiect => proiect.value === idProiect)?.key

  //             this.$f7.dialog.confirm(
  //               `Exista deja un task pornit la proiectul: ${numeProiect ?? ""}. Doriti sa il opriti?`,
  //               "Atentie",
  //               () => {
  //                 // this.$f7.preloader.show();

  //                 const idProiectDeschis = this.state.proiectDeschis?.IdProiect
       
  //                 this.Sfarsit(true, idProiect, false, false, false, idProiectDeschis)
  //                 .then(stopResponse => resolve(stopResponse))
  //                 .catch(err => reject(err))
  //               },
  //               () => resolve(response)
  //             )
  //           }
  //         }).catch(error => {
  //           console.log("error", error)
  //           this.$f7.methods.openNotification("Eroare la adaugare")
  //           reject(error)
  //         });
  //       }
  //     }).catch(error => {
  //       console.log("error", error)
  //       reject(error)
  //     })
  //   })
  // }

  /**
   * @description Se executa o singura data la montarea componentei
   * @date 03/02/2023
   * @function
   * @memberof Task
   */
  componentDidMount = () => {
    scrollEventElement = document.getElementById("viewMain").getElementsByClassName("page-content")[0]
    scrollEventElement.addEventListener("scroll", this.setScrolled)

    this.getValuesSalariatModule().then(() => {
      this.getValues()
    }).catch(err => {
      console.log("err", err)
    })
  }

  /**
   * @description Se executa o singura data la demontarea componentei
   * @date 03/02/2023
   * @function
   * @memberof Task
   */
  componentWillUnmount = () => {
    if (scrollEventElement) {
      scrollEventElement.removeEventListener("scroll", this.setScrolled)
      scrollEventElement = null
    }
  }

  /**
   * @description Se executa la fiecare update pentru a desena componenta
   * @date 03/02/2023
   * @function
   * @memberof Task
   */
  render() {
    const s = this.state.scrolled 
    ? { position: 'fixed', top: 34, left: 0, right: 0, zIndex: 10, boxShadow: '0 0 15px 1px rgba(0, 0, 0, 0.35)' }
    : { position: 'relative', marginBottom: 16 }

    return (
      <Page
        id="viewMain"
        className={"themeBackground"}
        onPageInit={() => {
          this.$f7.data.paginaCurenta = this
        }}
      >
        <div className="ptr3" style={{ height: 'auto', padding: 12, boxSizing: 'border-box' }}>
          {/* <h1>Detalii Task</h1> */}

          {/* Control */}
          <Card style={{ transition: 'all 0.5s ease-in-out', ...s }}>
            <div className="progress-time" style={{ position: 'absolute', left: 0, bottom: 0, height: 3, backgroundColor: 'rgb(0, 181, 0)', transition: 'width 0.25s ease-in-out', width: '100%' }}></div>
            <div className="over-time" style={{ position: 'absolute', left: 0, bottom: 0, height: 3, backgroundColor: 'rgb(255, 242, 0)', transition: 'width 0.25s ease-in-out', width: '10%' }}></div>

            <CardContent style={{ padding: '4px 2px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div>
                <IconButton style={{ width: 'inherit' }}>
                  <PlayArrow />
                </IconButton>

                <IconButton style={{ width: 'inherit' }}>
                  <Stop />
                </IconButton>

                <IconButton style={{ width: 'inherit' }}>
                  <Pause />
                </IconButton>

                <IconButton style={{ width: 'inherit' }}>
                  <Close />
                </IconButton>
              </div>
              
              <div>Timp sesiune curenta</div>

              <div>Timp total / Timp estimat</div>

              <IconButton style={{ width: 'inherit' }}>
                <Close />
              </IconButton>
            </CardContent>
          </Card>

          {/* <Accordion defaultExpanded >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-control"
              id="panel-control-header"
            >
              <Typography>Control</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <IconButton style={{ width: 'inherit' }}>
                <PlayArrow />
              </IconButton>

              <IconButton style={{ width: 'inherit' }}>
                <Stop />
              </IconButton>

              <IconButton style={{ width: 'inherit' }}>
                <Pause />
              </IconButton>

              <IconButton style={{ width: 'inherit' }}>
                <Close />
              </IconButton>
            </AccordionDetails>
          </Accordion> */}
          {/* END Control */}

          {/* Detalii */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-detalii"
              id="panel-detalii-header"
            >
              <Typography>Detalii</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Form formData={this.state.data} colCount={12} labelLocation="top" scrollingEnabled={true}>
                <SimpleItem colSpan={3} dataField="idSarcinaSalariat" editorType="dxNumberBox" label={{ text: 'Id sarcina' }} editorOptions={{ readOnly: true }} />
                <SimpleItem colSpan={3} dataField="idSarcina" editorType="dxNumberBox" label={{ text: 'Id task' }} editorOptions={{ readOnly: true }} />
                <SimpleItem colSpan={3} dataField="tipProiect" editorType="dxSelectBox" label={{ text: 'Tip proiect' }} editorOptions={{ items: this.$f7.data.TipProiect, displayExpr: 'key', valueExpr: 'value' }} />
                <SimpleItem colSpan={3} dataField="userImport" editorType="dxNumberBox" label={{ text: 'Id mantis' }} editorOptions={{ readOnly: true }} />

                <SimpleItem colSpan={3} dataField="client" editorType="dxSelectBox" label={{ text: 'Client' }} editorOptions={{ items: this.$f7.data.Firme, displayExpr: 'key', valueExpr: 'value' }} />
                <SimpleItem colSpan={3} dataField="departament" editorType="dxSelectBox" label={{ text: 'Departament' }} editorOptions={{ items: this.$f7.data.Departamente, displayExpr: 'key', valueExpr: 'value' }} />
                <SimpleItem colSpan={3} dataField="tipSursa" editorType="dxSelectBox" label={{ text: 'Tip sursa' }} editorOptions={{ items: this.$f7.data.TipSursa, displayExpr: 'key', valueExpr: 'value' }} />
                <SimpleItem colSpan={3} dataField="dataCreare" editorType="dxDateBox" label={{ text: 'Data crearii' }} />

                <SimpleItem colSpan={3} dataField="numeReporterMantis" editorType="dxTextBox" label={{ text: 'Raportor mantis' }} />
                <SimpleItem colSpan={3} dataField="emailReporterMantis" editorType="dxTextBox" label={{ text: 'Email raportor mantis' }} />
                <SimpleItem colSpan={3} dataField="adaugatDe" editorType="dxTextBox" label={{ text: 'Adaugat de' }} />
                <SimpleItem colSpan={3} dataField="alocatLui" editorType="dxTextBox" label={{ text: 'Alocat la' }} />

                <SimpleItem colSpan={3} dataField="prioritate" editorType="dxSelectBox" label={{ text: 'Prioritate' }} editorOptions={{ items: this.$f7.data.Prioritati, displayExpr: 'key', valueExpr: 'value' }} />
                <SimpleItem colSpan={3} dataField="stadiu" editorType="dxSelectBox" label={{ text: 'Stadiu' }} editorOptions={{ items: this.$f7.data.Stadiu, displayExpr: 'key', valueExpr: 'value' }} />
                <SimpleItem colSpan={3} dataField="nivel" editorType="dxSelectBox" label={{ text: 'Nivel proiect' }} editorOptions={{ items: this.$f7.data.NivelProiect, displayExpr: 'key', valueExpr: 'value' }} />
                <SimpleItem colSpan={3} dataField="isTask" editorType="dxCheckBox" label={{ text: 'Este task' }} />

                <EmptyItem colSpan={12} itemType="empty" />
                <EmptyItem colSpan={12} itemType="empty" />

                <GroupItem caption="Explicatii" colSpan={12} colCount={2}>
                  <SimpleItem colSpan={2} dataField="denumireProiect" editorType="dxTextBox" label={{ text: 'Denumire' }} />
                  <SimpleItem colSpan={1} dataField="comentariuProiect" editorType="dxTextArea" label={{ text: 'Cerinta' }} editorOptions={{ height: 90 }} />
                  <SimpleItem colSpan={1} dataField="observatiiProiect" editorType="dxTextArea" label={{ text: 'Observatii' }} editorOptions={{ height: 90 }} />
                </GroupItem>
              </Form>
            </AccordionDetails>
          </Accordion>
          {/* END Detalii */}

          {/* Detalii Extinse */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-detalii-extinse"
              id="panel-detalii-extinse-header"
            >
              <Typography>Detalii extinse</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Form formData={this.state.data} colCount={12} labelLocation="top" scrollingEnabled={true}>
                <SimpleItem colSpan={4} dataField="dataStart" editorType="dxDateBox" label={{ text: 'Data start' }} />
                <SimpleItem colSpan={4} dataField="dataLimita" editorType="dxDateBox" label={{ text: 'Data limita' }} />
                <SimpleItem colSpan={4} dataField="termen" editorType="dxSelectBox" label={{ text: 'Termen' }} editorOptions={{ items: this.state.listaTipTermen, displayExpr: 'key', valueExpr: 'value' }} />

                <SimpleItem colSpan={4} dataField="taskParinte" editorType="dxSelectBox" label={{ text: 'Parinte task' }} editorOptions={{ items: this.state.listaProiecte, displayExpr: 'key', valueExpr: 'value' }} />
                <SimpleItem colSpan={4} dataField="operatie" editorType="dxSelectBox" label={{ text: 'Operatie' }} editorOptions={{ items: this.$f7.data.Operatiuni, displayExpr: 'key', valueExpr: 'value' }} />
                <SimpleItem colSpan={4} dataField="risc" editorType="dxNumberBox" label={{ text: 'Procent risc' }} />

                <SimpleItem colSpan={6} dataField="directorRadacina" editorType="dxTextBox" label={{ text: 'Director radacina' }} />
                <SimpleItem colSpan={6} dataField="path" editorType="dxTextBox" label={{ text: 'Path' }} />
              </Form>
            </AccordionDetails>
          </Accordion>
          {/* END Detalii Extinse */}

          {/* Detalii Roluri */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-roluri"
              id="panel-roluri-header"
            >
              <Typography>Detalii roluri</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Form formData={this.state.data} colCount={2} labelLocation="top" scrollingEnabled={true}>
                <SimpleItem colSpan={1} dataField="numeDezvoltator" editorType="dxSelectBox" label={{ text: 'Dezvoltator alocat' }} editorOptions={{ items: this.$f7.data.Salariati, displayExpr: 'key', valueExpr: 'value' }} />
                <SimpleItem colSpan={1} dataField="numeSefProiect" editorType="dxSelectBox" label={{ text: 'Sef proiect alocat' }} editorOptions={{ items: this.$f7.data.Salariati, displayExpr: 'key', valueExpr: 'value' }} />

                <SimpleItem colSpan={1} dataField="numeSupervizor" editorType="dxSelectBox" label={{ text: 'Supervizor alocat' }} editorOptions={{ items: this.$f7.data.Salariati, displayExpr: 'key', valueExpr: 'value' }} />
                <SimpleItem colSpan={1} dataField="numeManager" editorType="dxSelectBox" label={{ text: 'Manager alocat' }} editorOptions={{ items: this.$f7.data.Salariati, displayExpr: 'key', valueExpr: 'value' }} />
              </Form>
            </AccordionDetails>
          </Accordion>
          {/* END Detalii Roluri */}

          {/* Detalii Timpi */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-timpi"
              id="panel-timpi-header"
            >
              <Typography>Detalii timpi</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Form formData={this.state.data} colCount={2} labelLocation="top" scrollingEnabled={true}>
                <SimpleItem colSpan={1} dataField="timpMinim" editorType="dxNumberBox" label={{ text: 'Timp dezvoltator minim' }} />
                <SimpleItem colSpan={1} dataField="timpSefProiect" editorType="dxNumberBox" label={{ text: 'Timp sef proiect minim' }} />

                <SimpleItem colSpan={1} dataField="timpSupervizor" editorType="dxNumberBox" label={{ text: 'Timp supervizor minim' }} />
                <SimpleItem colSpan={1} dataField="timpManager" editorType="dxNumberBox" label={{ text: 'Timp manager minim' }} />
              </Form>
            </AccordionDetails>
          </Accordion>
          {/* END Detalii Timpi */}

          {/* Detalii Aprobari */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-aprobari"
              id="panel-aprobari-header"
            >
              <Typography>Detalii aprobari</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Form formData={this.state.data} colCount={2} labelLocation="top" scrollingEnabled={true}>
                <SimpleItem colSpan={1} dataField="aprobareDezvoltator" editorType="dxCheckBox" label={{ text: 'Aprobat de dezvoltator' }} />
                <SimpleItem colSpan={1} dataField="aprobareSefProiect" editorType="dxCheckBox" label={{ text: 'Aprobat de seful de proiect' }} />

                <SimpleItem colSpan={1} dataField="aprobareSupervizor" editorType="dxCheckBox" label={{ text: 'Aprobat de supervizor' }} />
                <SimpleItem colSpan={1} dataField="aprobareManager" editorType="dxCheckBox" label={{ text: 'Aprobat de manager' }} />
              </Form>
            </AccordionDetails>
          </Accordion>
          {/* END Detalii Aprobari */}

          {/* Atasamente */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-atasamente"
              id="panel-atasamente-header"
            >
              <Typography>Atasamente</Typography>
            </AccordionSummary>

            <AccordionDetails id="viewAtasamente">
              <FileManager
                id="fileManagerAtasamente"
                ref={this.fileManagerRef}
                fileSystemProvider={this.state.atasamente}
                onFileUploading={this.fileUploading}
              >
                <Permissions create={false} delete={false} rename={false} download={true} upload={true} />

                <ItemView showParentFolder={false} showFolders={false}>
                  <Details>
                    <Column dataField="thumbnail" />
                    <Column dataField="name" caption="Nume atasament" />
                    {/* <Column dataField="category" caption="Category" width="95" /> */}
                    <Column dataField="dateModified" caption="Data atasament" />
                    <Column dataField="size" caption="Dimensiune fisier" />
                  </Details>
                </ItemView>

                <Toolbar>
                  <Item name="upload" text="Incarca atasament" options={{ hint: 'Incarca atasament' }} />
                  <Item widget="dxButton" options={{ icon: 'refresh', hint: 'Reincarca', text: 'Reincarca', onClick: this.getAtasamente }} location="after" />
                  <Item name="separator" location="after" />
                  <Item name="switchView" />

                  <FileSelectionItem name="download" text="Descarca" options={{ hint: 'Descarca' }} />
                  <FileSelectionItem name="clearSelection" text="Deselecteaza tot" options={{ hint: 'Deselecteaza tot' }} />
                </Toolbar>

                <ContextMenu>
                  <Item name="upload" text="Incarca atasament" />
                  <Item name="download" text="Descarca" />
                </ContextMenu>
              </FileManager>

              <LoadPanel enabled={true} visible={this.state.loadingAtasamente} shading={true} shadingColor="rgba(0, 0, 0, 0.5)" position={{ of: '#viewAtasamente' }} />
            </AccordionDetails>
          </Accordion>
          {/* END Atasamente */}

          {/* Comunicare */}
          {/* <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-comunicare"
              id="panel-comunicare-header"
            >
              <Typography>Comunicare</Typography>
            </AccordionSummary>

            <AccordionDetails>
              
            </AccordionDetails>
          </Accordion> */}
          {/* END Comunicare */}

          {/* Istoric */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-istoric"
              id="panel-istoric-header"
            >
              <Typography>Istoric</Typography>
            </AccordionSummary>

            <AccordionDetails id="viewIstoric">
              <DataGrid
                dataSource={this.state.dataSourceIstoric}
                showRowLines={true}
                showBorders={true}
                columnAutoWidth={true}
                rowAlternationEnabled
                hoverStateEnabled
                paging={{
                  enabled: true,
                  pageSize: 10
                }}
              >
                <Toolbar>
                  <Item>
                    <Button icon="refresh" hint="Reincarca" text="Reincarca" onClick={this.getIstoric} />
                  </Item>
                </Toolbar>

                <TreeColumn dataField="Data" caption="Data" dataType="datetime" />
                <TreeColumn dataField="DataFinal" caption="Data final" dataType="datetime" />

                <TreeColumn dataField="Salariat" caption="Salariat" dataType="number">
                  <Lookup dataSource={this.$f7.data.Salariati} displayExpr="key" valueExpr="value" />
                </TreeColumn>

                <TreeColumn dataField="SalariatPropunere" caption="Salariat propunere" dataType="number">
                  <Lookup dataSource={this.$f7.data.Salariati} displayExpr="key" valueExpr="value" />
                </TreeColumn>

                <TreeColumn dataField="Comentariu" caption="Comentariu" />
                <TreeColumn dataField="ComentariuPv" caption="Comentariu PV" />
                <TreeColumn dataField="ComentariuPlus" caption="Extra comentariu" />
                <TreeColumn dataField="Stare" caption="Stare" />
                <TreeColumn dataField="Durata" caption="Durata" />
                <TreeColumn dataField="IdTask" caption="ID task" dataType="number" />
                <TreeColumn dataField="IdClient" caption="ID client" dataType="number" />
                <TreeColumn dataField="RefTask" caption="Ref task" dataType="number" />
              </DataGrid>

              <LoadPanel enabled={true} visible={this.state.loadingIstoric} shading={true} shadingColor="rgba(0, 0, 0, 0.5)" position={{ of: '#viewIstoric' }} />
            </AccordionDetails>
          </Accordion>
          {/* END Istoric */}

          {/* Activitate */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-activitate"
              id="panel-activitate-header"
            >
              <Typography>Activitate</Typography>
            </AccordionSummary>

            <AccordionDetails id="viewActivitate">
              {this.state.dataSourceActivitate.length > 0 && (
                <TreeList
                  dataSource={this.state.dataSourceActivitate}
                  rootValue={-1}
                  showRowLines={true}
                  showBorders={true}
                  columnAutoWidth={true}
                  keyExpr="Id_Realizare"
                  parentIdExpr="Ref_Realizare"
                  rowAlternationEnabled
                  hoverStateEnabled
                  autoExpandAll={true}
                  paging={{
                    enabled: true,
                    pageSize: 10
                  }}
                >
                  <Toolbar>
                    <Item>
                      <Button icon="refresh" hint="Reincarca" text="Reincarca" onClick={this.getActivitate} />
                    </Item>
                  </Toolbar>

                  <TreeColumn dataField="NumeSalariat" caption="Nume salariat" dataType="number">
                    <Lookup dataSource={this.$f7.data.Salariati} displayExpr="key" valueExpr="value" />
                  </TreeColumn>

                  <TreeColumn dataField="MomentInceput" caption="Moment inceput" dataType="datetime" />
                  <TreeColumn dataField="MomentSfarsit" caption="Moment sfarsit" dataType="datetime" />
                  <TreeColumn dataField="Timp" caption="Timp" />

                  <TreeColumn dataField="Client" caption="Client" dataType="number">
                    <Lookup dataSource={this.$f7.data.Firme} displayExpr="key" valueExpr="value" />
                  </TreeColumn>

                  <TreeColumn dataField="Comentariu" caption="Comentariu" />
                  <TreeColumn dataField="ComentariuPv" caption="Comentariu PV" />
                  <TreeColumn dataField="ComentariuPlus" caption="Extra comentariu" />
                  <TreeColumn dataField="Status" caption="Status" />
                  <TreeColumn dataField="Id_Realizare" caption="ID" dataType="number" />
                </TreeList>
              )}

              <LoadPanel enabled={true} visible={this.state.loadingActivitate} shading={true} shadingColor="rgba(0, 0, 0, 0.5)" position={{ of: '#viewActivitate' }} />
            </AccordionDetails>
          </Accordion>
          {/* END Activitate */}

          {/* PV */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel-pv"
              id="panel-pv-header"
            >
              <Typography>PV</Typography>
            </AccordionSummary>

            <AccordionDetails id="viewPV">
              <DataGrid
                dataSource={this.state.dataSourcePV}
                showRowLines={true}
                showBorders={true}
                columnAutoWidth={true}
                rowAlternationEnabled
                hoverStateEnabled
                paging={{
                  enabled: true,
                  pageSize: 10
                }}
              >
                <Toolbar>
                  <Item>
                    <Button icon="refresh" hint="Reincarca" text="Reincarca" onClick={this.getPV} />
                  </Item>
                </Toolbar>

                <TreeColumn dataField="Id" caption="ID" dataType="number" />

                <TreeColumn dataField="Salariat" caption="Salariat" dataType="number">
                  <Lookup dataSource={this.$f7.data.Salariati} displayExpr="key" valueExpr="value" />
                </TreeColumn>

                <TreeColumn dataField="DataPV" caption="Data PV" dataType="datetime" />
                <TreeColumn dataField="NumarPV" caption="Numar PV" />

                <TreeColumn dataField="Client" caption="Client" dataType="number">
                  <Lookup dataSource={this.$f7.data.Firme} displayExpr="key" valueExpr="value" />
                </TreeColumn>

                <TreeColumn dataField="Aplicatia" caption="Aplicatia" />
                <TreeColumn dataField="Listat" caption="Listat" dataType="boolean" />
                <TreeColumn dataField="Validat" caption="Validat" dataType="boolean" />
                <TreeColumn dataField="CaleDocument" caption="Cale document" />
                <TreeColumn dataField="NumarFactura" caption="Numar factura" />
                <TreeColumn dataField="DataFactura" caption="Data factura" dataType="datetime" />
                <TreeColumn dataField="ReprezentantClient" caption="Reprezentant client" />

                <MasterDetail
                  enabled={true}
                  component={e => <PVDetalii {...e} f7={this.$f7} link={this.linkApiPVDetalii} />}
                />
              </DataGrid>

              <LoadPanel enabled={true} visible={this.state.loadingPV} shading={true} shadingColor="rgba(0, 0, 0, 0.5)" position={{ of: '#viewPV' }} />
            </AccordionDetails>
          </Accordion>
          {/* END PV */}

          <LoadPanel enabled={true} visible={this.state.loadingMain} shading={true} shadingColor="rgba(0, 0, 0, 0.5)" position={{ of: '#viewMain' }} />
        </div>
      </Page>
    )
  }
}

export default Task
