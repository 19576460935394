import React from "react";
import {
  Preloader,
  Segmented,
  Button,
  List,
  ListItem,
  Row,
  Col,
  Fab,
  Icon,
  Checkbox,
} from "framework7-react";
import axios from "axios";
import moment from "moment";
import ProiecteGrid from "./ProiecteGrid";
import SalariatiGrid from "./SalariatiGrid";

/**
 * @description Interfata de intretinere a proiectelor si a salariatilor atasati acelui proiect
 * @date 07/04/2022
 * @class Proiecte
 * @extends {React.Component}
 */
class Proiecte extends React.Component {
  constructor(props) {
    super(props);
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.Module = this.$f7.data.DenumireModule.filter(
      (item) => item.key !== "Fara"
    );

    let clienti = [{ key: "Fara", value: null }];

    this.$f7.data.nearhivateProiecte
      .filter((client) => {
        return client.id_tip_proiect === 57;
      })
      .forEach((client) => {
        clienti.push({
          key: client.denumire,
          value: client.iD_PROIECT,
        });
      });

    this.state = {
      morphOpened: false,
      dataLoaded: false,
      linkApi: "/api/Proiecte",
      linkApiClient: "/api/Firme",
      linkApiContracte: "/api/ContracteNoi",
      linkApiSCM: "/api/SalariatContracteModule",
      denumire: "Proiecte",
      height: window.innerHeight - 34,
      dataSource: [],
      inputs: [
        { key: "Id", keyBaza: "iD_PROIECT" },
        { key: "Denumire", keyBaza: "denumire" },
        {
          key: "Client",
          keyBaza: "id_client",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
        },
        {
          key: "Departament",
          keyBaza: "id_departament",
          tip: "selectbox",
          lista: this.$f7.data.Departamente,
        },
        {
          key: "ProiectRef",
          keyBaza: "reF_PROIECT",
          lista: this.$f7.data.Proiecte,
          tip: "selectbox",
        },
        {
          key: "Stadiu",
          keyBaza: "iD_STADIU",
          tip: "selectbox",
          lista: this.$f7.data.Stadiu,
        },
        { key: "Folosit", keyBaza: "folosit", tip: "bool" },
        { key: "Task", keyBaza: "task", tip: "bool" },
        { key: "Arhivat", keyBaza: "isArhivat", tip: "bool" },
        { key: "Ascuns", keyBaza: "isHidden", tip: "bool" },
        {
          key: "IdPrioritate",
          keyBaza: "id_prioritate",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
        },
        {
          key: "NivelProiect",
          keyBaza: "id_nivel_proiect",
          tip: "selectbox",
          lista: this.$f7.data.NivelProiect,
        },
        {
          key: "TipProiect",
          keyBaza: "id_tip_proiect",
          tip: "selectbox",
          lista: this.$f7.data.TipProiect,
        },
        { key: "DataStart", keyBaza: "data_start", tip: "datebox" },
        { key: "Deadline", keyBaza: "deadline", tip: "datebox" },
        { key: "ValoareMinima", keyBaza: "valoare_minima", tip: "number" },
        { key: "ValoareMaxima", keyBaza: "valoare_maxima", tip: "number" },
        { key: "Buget", keyBaza: "buget", tip: "number" },
        { key: "BugetTimp", keyBaza: "buget_timp", tip: "number" },
        { key: "ProcRisc", keyBaza: "proC_RISC", tip: "number" },
        { key: "CostEstimat", keyBaza: "cost_estimat", tip: "number" },
        { key: "CostRealCalc", keyBaza: "cost_real_calc", tip: "number" },
        { key: "TimpPlanM", keyBaza: "timp_plan_m", tip: "number" },
        { key: "TimpPlanSP", keyBaza: "timp_plan_sp", tip: "number" },
        { key: "TimpPlanD", keyBaza: "timp_plan_d", tip: "number" },
        { key: "TimpPlanA", keyBaza: "timp_plan_a", tip: "number" },
        { key: "Comentariu", keyBaza: "comentariu" },
        {
          key: "Responsabil",
          keyBaza: "id_responsabil",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        { key: "Complet", keyBaza: "taskcomplete", tip: "number" },
        { key: "Optiuni", keyBaza: "options", tip: "number" },
        { key: "Eventtype", keyBaza: "eventtype", tip: "number" },
        {
          key: "Dezvoltator",
          keyBaza: "id_dezvoltator",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        {
          key: "SefProiect",
          keyBaza: "id_sefproiect",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        {
          key: "Arbitru",
          keyBaza: "id_arbitru",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        {
          key: "Manager",
          keyBaza: "id_manager",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        { key: "AprobatD", keyBaza: "aprobat_d", tip: "bool" },
        { key: "AprobatA", keyBaza: "aprobat_a", tip: "bool" },
        { key: "AprobatS", keyBaza: "aprobat_s", tip: "bool" },
        { key: "AprobatM", keyBaza: "aprobat_m", tip: "bool" },
        { key: "CheltuieliSalariati", keyBaza: "chelt_sal", tip: "number" },
        { key: "TimpLucrat", keyBaza: "timp_l", tip: "number" },
        { key: "Ordine", keyBaza: "ordine", tip: "number" },

        { key: "DataCreare", keyBaza: "data_creare", tip: "datebox" },
        { key: "DataFinal", keyBaza: "data_final", tip: "datebox" },
        {
          key: "Operatiune",
          keyBaza: "id_operatiuni",
          tip: "selectbox",
          lista: this.$f7.data.Operatiuni,
          requiredAdd: true,
        },
        { key: "Facturat", keyBaza: "facturat", tip: "bool" },
      ],
      inputsClienti: [
        { key: "Id", keyBaza: "iD_FIRMA", tip: "number" },
        { key: "Denumire", keyBaza: "denumire", required: true },
        { key: "Email", keyBaza: "email", tip: "email" },
        { key: "Adresa", keyBaza: "adresa" },
        { key: "Telefon", keyBaza: "telefon", tip: "phone" },
        { key: "PersoanaContact", keyBaza: "persoanA_CONTACT" },
        {
          key: "Ref_Firma",
          keyBaza: "reF_FIRMA",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
        },
        { key: "Ref_Proiect", keyBaza: "ref_proiect", tip: "number" },
      ],
      inputsContracte: [
        { key: "Id", keyBaza: "id_contract", tip: "number" },
        {
          key: "NrContractParinte",
          keyBaza: "ref_contract",
          tip: "selectbox",
          lista: this.$f7.data.ContracteNoi,
        },
        { key: "NrContract", keyBaza: "nr_contract", required: true },
        {
          key: "Client",
          keyBaza: "id_client",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
          required: true,
        },
        {
          key: "Angajator",
          keyBaza: "id_angajator",
          tip: "selectbox",
          lista: this.$f7.data.Angajatori,
          required: true,
        },
        {
          key: "DataContract",
          keyBaza: "data_contract",
          tip: "datebox",
          required: true,
        },
        {
          key: "DataInceput",
          keyBaza: "data_inceput",
          tip: "datebox",
          required: true,
        },
        { key: "DataSfarsit", keyBaza: "data_sfarsit", tip: "datebox" },
        {
          key: "Valoare",
          keyBaza: "valoare_contract",
          tip: "decimal",
          required: true,
        },
        {
          key: "Valuta",
          keyBaza: "id_tip_valuta",
          tip: "selectbox",
          lista: this.$f7.data.TipValuta,
          required: true,
        },
        {
          key: "GarantieExecutieInitialaProcent",
          keyBaza: "garantie_executie_initiala_procent",
          tip: "decimal",
        },
        {
          key: "GarantieExecutieInitialaValoare",
          keyBaza: "garantie_executie_initiala_valoare",
          tip: "decimal",
        },
        {
          key: "GarantieExecutieLunaraProcent",
          keyBaza: "garantie_executie_lunara_procent",
          tip: "decimal",
        },
        {
          key: "GarantieExecutieLunaraValoare",
          keyBaza: "garantie_executie_lunara_valoare",
          tip: "decimal",
        },
        { key: "SlaMinor", keyBaza: "sla_minor", tip: "number" },
        { key: "SlaMediu", keyBaza: "sla_mediu", tip: "number" },
        { key: "SlaMajor", keyBaza: "sla_major", tip: "number" },
        { key: "SlaCritic", keyBaza: "sla_critic", tip: "number" },
        {
          key: "DataExpirareGarantie",
          keyBaza: "data_expirare_garantie",
          tip: "datebox",
          required: true,
        },
        {
          key: "TermenGarantie",
          keyBaza: "termen_garantie_luni",
          tip: "number",
          required: true,
        },
        { key: "Abonament", keyBaza: "tip_abonament", tip: "bool" },
        { key: "PenalitatiNoi", keyBaza: "penalitati_noi", tip: "decimal" },
        { key: "PenalitatiEi", keyBaza: "penalitati_ei", tip: "decimal" },
        { key: "Comentariu", keyBaza: "comentariu" },
        {
          key: "Departament",
          keyBaza: "id_departament",
          tip: "selectbox",
          lista: this.$f7.data.Departamente,
          required: true,
        },
        { key: "Ref_Proiect", keyBaza: "ref_proiect", tip: "number" },
      ],
      inputsModule: [
        {
          key: "Clientul",
          tip: "selectbox",
          lista: clienti /*this.$f7.data.Firme*/,
          required: true,
        },
        {
          key: "Contractul",
          tip: "selectbox",
          lista: [] /*this.$f7.data.ContracteNoi*/,
          required: true,
        },
        {
          key: "Modulul",
          tip: "selectboxmultiplu",
          lista: this.Module,
          required: true,
        },
        {
          key: "Salariati",
          tip: "selectboxmultiplu",
          lista: this.Salariati.filter((item) => item.key !== "Fara"),
          required: false,
        },
      ],
    };
    this.refProiecteGrid = React.createRef();
    this.refSalariatiGrid = React.createRef();
    this.refDataGrid = React.createRef();
  }

  /**
   * @description Functia de luare a salariatilor de au acces la proiectul selectat
   * @date 07/04/2022
   * @function
   * @param {*} pro proiectul selectat
   * @memberof Proiecte
   */
  GetValuesDreapta = (pro) => {
    var idProiect = pro.Id_Proiect;
    var randeaza = true;
    randeaza =
      pro.Id_Tip_Proiect !== 57 &&
      pro.Id_Tip_Proiect !== 58 &&
      pro.Id_Proiect !== 39235 &&
      pro.Id_Proiect !== 38020 &&
      pro.Id_Proiect !== 29340 &&
      pro.Id_Nivel_Proiect < 3;
    this.refSalariatiGrid.current.GetValues(idProiect, randeaza);
  };

  /**
   * @description Ia lista cu toate proiectele
   * @function
   * @date 07/04/2022
   * @memberof Proiecte
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/GetPROIECTE")
      .then((response) => {
        this.$f7.methods.IaValori();
        var date = response;
        date.data = date.data.filter((r) => {
          return r.id_nivel_proiect === 1 || r.id_nivel_proiect === 2;
        });
        date.data.map((eveniment) => {
          eveniment = this.$f7.methods.restructurareBaza(
            eveniment,
            this.state.inputs,
            "key"
          );
          return false;
        });
        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  if (baza.length > 0) {
                    //this.refDataGrid.current.setRenderItems(baza);
                  }
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.refDataGrid.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Adauga o inregistrare noua in baza de date pentru interfata curenta(Proiecte)
   * @function
   * @date 07/04/2022
   * @param {*} data obiect cu informatiile noii inregistrari
   * @memberof Proiecte
   */
  AddValue = (data) => {
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: this.$f7.data.baseURL + this.state.linkApi + "/PostPROIECTE",
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza in baza de date inregistrarea selectata
   * @date 07/04/2022
   * @function
   * @param {*} obj obiect cu valorile noii inregistrari
   * @param {*} id id-ul inregistrarii selectate
   * @memberof Proiecte
   */
  UpdateValue = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/GetPROIECTE/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });
        var url =
          this.$f7.data.baseURL + this.state.linkApi + "/PutPROIECTE/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Sterge din baza de date inregistrarea selectata
   * @date 07/04/2022
   * @function
   * @param {*} id id-ul inregistrarii selctate
   * @memberof Proiecte
   */
  DeleteValue = (id) => {
    var url =
      this.$f7.data.baseURL + this.state.linkApi + "/DeletePROIECTE/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  /**
   * @description Adauga un proiect de tip modul
   * @function
   * @date 07/04/2022
   * @param {*} data obiect cu valorile noii inregistrari de adaugat
   * @memberof Proiecte
   */
  AddModul = (data) => {
    return new Promise((resolve, reject) => {
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: this.$f7.data.baseURL + this.state.linkApi + "/PostPROIECTE",
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject("Eroare la adaugare modul: " + error);
        });
    });
  };

  /**
   * @description Asociaza salariatii din data proiectului
   * @date 07/04/2022
   * @param {*} data obiect cu salariatii si proiectul la care sunt atasati
   * @memberof Proiecte
   */
  AsociereSalariati = (data) => {
    return new Promise((resolve, reject) => {
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url:
          this.$f7.data.baseURL +
          this.state.linkApiSCM +
          "/PostSALARIAT_CONTRACTE_MODULE",
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject("Eroare la asociere salariat: " + error);
        });
    });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return (
      <>
        {this.state.dataLoaded ? (
          <>
            <Fab
              className={
                this.state.checkedArhivate ||
                this.state.checkedAscunse ||
                this.state.checkedSelectate ||
                this.state.checkedTaskuri
                  ? "fabToateFiltrate"
                  : "fabToate"
              }
              onClick={() => {
                this.setState(
                  {
                    filterBarShow: !this.state.filterBarShow,
                  },
                  () => {
                    if (!this.state.filterBarShow && this.state.filtered) {
                      this.refProiecteGrid.current.GetValues();
                    } else {
                      this.setState({ filtered: false });
                    }
                  }
                );
              }}
              position="right-top"
              slot="fixed"
              style={{
                top: "3px",
                borderRadius: "50%",
              }}>
              <Icon
                style={{ margin: 0 }}
                md={
                  this.state.filterBarShow
                    ? "material:save"
                    : "material:filter_list"
                }
              />
            </Fab>

            <Fab
              onClick={() => {
                var panelLegend = this.$f7.panel.get(".panelLegendProiecte");
                if (panelLegend) {
                  panelLegend.open();
                }
              }}
              position="right-top"
              slot="fixed"
              style={{
                borderRadius: "50%",
                marginRight: 40,
                top: "3px",
              }}>
              <Icon style={{ margin: 0 }} md={"material:info"} />
            </Fab>

            <div
              className="filter-bar-treelist"
              style={{
                top: "3px",
                width: this.state.filterBarShow ? "calc(100% - 480px)" : "0px",
              }}>
              <Checkbox
                checked={this.state.checkedTaskuri}
                style={{ display: "flex" }}
                onChange={(e) => {
                  this.setState({
                    filtered: true,
                    checkedTaskuri: !this.state.checkedTaskuri,
                  });
                }}
                name="Checkbox1">
                Taskuri
              </Checkbox>
              <Checkbox
                checked={this.state.checkedSubtotaluri}
                onChange={(e) => {
                  this.setState({
                    filtered: true,
                    checkedSubtotaluri: !this.state.checkedSubtotaluri,
                  });
                }}
                style={{ display: "flex" }}
                name="Checkbox2">
                Subtotaluri
              </Checkbox>
              <Checkbox
                checked={this.state.checkedAscunse}
                onChange={(e) => {
                  this.setState({
                    filtered: true,
                    checkedAscunse: !this.state.checkedAscunse,
                  });
                }}
                style={{ display: "flex" }}
                name="Checkbox4">
                Ascunse
              </Checkbox>
              <Checkbox
                checked={this.state.checkedArhivate}
                onChange={(e) => {
                  this.setState({
                    filtered: true,
                    checkedArhivate: !this.state.checkedArhivate,
                  });
                }}
                style={{ display: "flex" }}
                name="Checkbox5">
                Arhivate
              </Checkbox>
              <Checkbox
                checked={this.state.checkedSelectate}
                onChange={(e) => {
                  this.setState({
                    filtered: true,
                    checkedSelectate: !this.state.checkedSelectate,
                  });
                }}
                style={{ display: "flex" }}
                name="Checkbox6">
                Selectate
              </Checkbox>
            </div>

            <div style={{ position: "relative" }}>
              <Segmented
                className="butoaneContextDatagrid"
                raised
                style={{
                  position: "absolute",
                  width: "430px",
                  top: 3,
                  left: 0,
                }}
                tag="div">
                <Button
                  tooltip={"Adauga client"}
                  fill
                  small
                  onClick={() => {
                    this.$f7.data.router.navigate("/popupAddEdit/", {
                      props: {
                        onCloseClicked: (state) => {},
                        inputs: this.state.inputsClienti,
                        ignoreInputs: ["Ref_Proiect"],
                        id: "Id",
                        buttons: [
                          {
                            key: "Salveaza",
                            onSubmit: (state, den) => {
                              this.$f7.preloader.show();

                              var obj = { ...state.editedItem };

                              obj = this.$f7.methods.restructurareBaza(
                                obj,
                                this.state.inputsClienti,
                                "keyBaza"
                              );

                              let proiectNou = {
                                denumire: obj.denumire,
                                reF_PROIECT: 39235,
                                iD_STADIU: 2,
                                folosit: true,
                                task: false,
                                isArhivat: 0,
                                isHidden: 0,
                                id_prioritate: 1,
                                id_nivel_proiect: 1,
                                id_tip_proiect: 57,
                                data_start: moment().format(),
                                deadline: moment().add(10, "y").format(),
                                timp_plan_m: 0,
                                timp_plan_sp: 0,
                                timp_plan_d: 0,
                                comentariu: "",
                                taskcomplete: 0,
                                options: 10,
                                eventtype: 0,
                                timp_plan_a: 0,
                                chelt_sal: 0,
                                timp_l: 0,
                                data_creare: moment().format(),
                                data_final: moment().add(10, "y").format(),
                                operatorInchidereTask: true,
                                isModul: false,
                              };

                              axios({
                                data: proiectNou,
                                headers: { "Content-Type": "application/json" },
                                method: "post",
                                url:
                                  this.$f7.data.baseURL +
                                  this.state.linkApi +
                                  "/PostPROIECTE",
                              })
                                .then((response) => {
                                  obj.ref_proiect = response.data.iD_PROIECT;
                                  delete obj.iD_FIRMA;
                                  axios({
                                    data: obj,
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                    method: "post",
                                    url:
                                      this.$f7.data.baseURL +
                                      this.state.linkApiClient,
                                  })
                                    .then((responseFirma) => {
                                      //this.$f7.popup.close(den)
                                      this.$f7.panel.close(den);
                                      this.$f7.preloader.hide();
                                      this.GetValues();
                                      this.$f7.methods.openNotification(
                                        "Adaugat cu success"
                                      );
                                    })
                                    .catch((error) => {
                                      console.log("error", error);
                                      this.$f7.preloader.hide();
                                      this.$f7.methods.openNotification(
                                        "Eroare la adaugare client"
                                      );
                                    });
                                })
                                .catch((error) => {
                                  console.log("error", error);
                                  this.$f7.preloader.hide();
                                  this.$f7.methods.openNotification(
                                    "Eroare la adaugare client ca proiect"
                                  );
                                });
                            },
                          },
                        ],
                      },
                    });
                  }}>
                  Adauga client
                </Button>
                <Button
                  tooltip={"Adauga contract"}
                  fill
                  small
                  onClick={() => {
                    this.$f7.data.router.navigate("/popupAddEdit/", {
                      props: {
                        onCloseClicked: (state) => {},
                        inputs: this.state.inputsContracte,
                        ignoreInputs: ["Ref_Proiect"],
                        id: "Id",
                        buttons: [
                          {
                            key: "Salveaza",
                            onSubmit: (state, den) => {
                              this.$f7.preloader.show();

                              var obj = { ...state.editedItem };

                              obj = this.$f7.methods.restructurareBaza(
                                obj,
                                this.state.inputsContracte,
                                "keyBaza"
                              );

                              axios
                                .get(
                                  this.$f7.data.baseURL +
                                    this.state.linkApiClient +
                                    "/" +
                                    obj.id_client
                                )
                                .then((response) => {
                                  let proiectNou = {
                                    denumire: "Contract nr. " + obj.nr_contract,
                                    reF_PROIECT: response.data.ref_proiect,
                                    iD_STADIU: 2,
                                    folosit: true,
                                    task: false,
                                    isArhivat: 0,
                                    isHidden: 0,
                                    id_prioritate: 1,
                                    id_nivel_proiect: 1,
                                    id_tip_proiect: 58,
                                    data_start: moment(
                                      obj.data_inceput
                                    ).format(),
                                    deadline: moment(obj.data_sfarsit).format(),
                                    timp_plan_m: 0,
                                    timp_plan_sp: 0,
                                    timp_plan_d: 0,
                                    comentariu: obj.comentariu,
                                    taskcomplete: 0,
                                    options: 10,
                                    eventtype: 0,
                                    timp_plan_a: 0,
                                    chelt_sal: 0,
                                    timp_l: 0,
                                    id_client: obj.id_client,
                                    data_creare: moment().format(),
                                    data_final: moment(
                                      obj.data_sfarsit
                                    ).format(),
                                    operatorInchidereTask: true,
                                    isModul: false,
                                  };

                                  axios({
                                    data: proiectNou,
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                    method: "post",
                                    url:
                                      this.$f7.data.baseURL +
                                      this.state.linkApi +
                                      "/PostPROIECTE",
                                  })
                                    .then((responseProiect) => {
                                      obj.ref_proiect =
                                        responseProiect.data.iD_PROIECT;
                                      obj.valoare_contract =
                                        obj.valoare_contract === ""
                                          ? 0
                                          : parseFloat(obj.valoare_contract);
                                      obj.garantie_executie_initiala_procent =
                                        obj.garantie_executie_initiala_procent ===
                                        ""
                                          ? 0
                                          : parseFloat(
                                              obj.garantie_executie_initiala_procent
                                            );
                                      obj.garantie_executie_initiala_valoare =
                                        obj.garantie_executie_initiala_valoare ===
                                        ""
                                          ? 0
                                          : parseFloat(
                                              obj.garantie_executie_initiala_valoare
                                            );
                                      obj.garantie_executie_lunara_procent =
                                        obj.garantie_executie_lunara_procent ===
                                        ""
                                          ? 0
                                          : parseFloat(
                                              obj.garantie_executie_lunara_procent
                                            );
                                      obj.garantie_executie_lunara_valoare =
                                        obj.garantie_executie_lunara_valoare ===
                                        ""
                                          ? 0
                                          : parseFloat(
                                              obj.garantie_executie_lunara_valoare
                                            );
                                      obj.penalitati_noi =
                                        obj.penalitati_noi === ""
                                          ? 0
                                          : parseFloat(obj.penalitati_noi);
                                      obj.penalitati_ei =
                                        obj.penalitati_ei === ""
                                          ? 0
                                          : parseFloat(obj.penalitati_ei);
                                      delete obj.id_contract;

                                      axios({
                                        data: obj,
                                        headers: {
                                          "Content-Type": "application/json",
                                        },
                                        method: "post",
                                        url:
                                          this.$f7.data.baseURL +
                                          this.state.linkApiContracte,
                                      })
                                        .then((responseContract) => {
                                          //this.$f7.popup.close(den)
                                          this.$f7.panel.close(den);
                                          this.$f7.preloader.hide();
                                          this.GetValues();
                                          this.$f7.methods.openNotification(
                                            "Adaugat cu success"
                                          );
                                        })
                                        .catch((error) => {
                                          console.log("error", error);
                                          this.$f7.preloader.hide();
                                          this.$f7.methods.openNotification(
                                            "Eroare la adaugare contract"
                                          );
                                        });
                                    })
                                    .catch((error) => {
                                      console.log("error", error);
                                      this.$f7.preloader.hide();
                                      this.$f7.methods.openNotification(
                                        "Eroare la adaugare contract ca proiect"
                                      );
                                    });
                                })
                                .catch((error) => {
                                  console.log("error", error);
                                  this.$f7.preloader.hide();
                                  this.$f7.methods.openNotification(
                                    "Eroare la cautare client"
                                  );
                                });
                            },
                          },
                        ],
                      },
                    });
                  }}>
                  Adauga contract
                </Button>
                <Button
                  tooltip={"Adauga modul"}
                  fill
                  small
                  onClick={() => {
                    this.$f7.data.router.navigate("/popupAddEdit/", {
                      props: {
                        onCloseClicked: (state) => {},
                        inputs: this.state.inputsModule,
                        buttons: [
                          {
                            key: "Salveaza",
                            onSubmit: (state, den) => {
                              this.$f7.preloader.show();

                              var obj = { ...state.editedItem };
                              var module = obj.Modulul;
                              var salariati = obj.Salariati;
                              let referintaModulPentruSalariat = null;

                              if (module.length > 0) {
                                axios
                                  .get(
                                    this.$f7.data.baseURL +
                                      this.state.linkApi +
                                      "/GetPROIECTE/" +
                                      obj.Contractul
                                  )
                                  .then((responseContract) => {
                                    const idClient =
                                      responseContract.data.id_client;

                                    module
                                      .reduce((promise, item) => {
                                        let proiectNou = {
                                          denumire:
                                            this.$f7.data.DenumireModule.find(
                                              (modul) =>
                                                modul.value === parseInt(item)
                                            ).key,
                                          reF_PROIECT: obj.Contractul,
                                          iD_STADIU: 2,
                                          folosit: true,
                                          task: false,
                                          isArhivat: 0,
                                          isHidden: 0,
                                          id_prioritate: 1,
                                          id_nivel_proiect: 1,
                                          id_tip_proiect: 59,
                                          data_start: moment().format(),
                                          deadline: moment()
                                            .add(10, "y")
                                            .format(),
                                          timp_plan_m: 0,
                                          timp_plan_sp: 0,
                                          timp_plan_d: 0,
                                          comentariu: "",
                                          taskcomplete: 0,
                                          options: 10,
                                          eventtype: 0,
                                          timp_plan_a: 0,
                                          chelt_sal: 0,
                                          timp_l: 0,
                                          id_client: idClient,
                                          data_creare: moment().format(),
                                          data_final: moment()
                                            .add(10, "y")
                                            .format(),
                                          operatorInchidereTask: true,
                                          isModul: false,
                                        };

                                        return promise.then(() => {
                                          return this.AddModul(proiectNou)
                                            .then((r) => {
                                              if (
                                                module.length === 1 &&
                                                salariati.length > 0
                                              )
                                                referintaModulPentruSalariat =
                                                  r.data.iD_PROIECT;
                                            })
                                            .catch((err) => {
                                              console.log("err", err);
                                            });
                                        });
                                      }, Promise.resolve())
                                      .then((t) => {
                                        if (
                                          module.length === 1 &&
                                          salariati.length > 0
                                        ) {
                                          salariati
                                            .reduce((promiseS, itemS) => {
                                              let modulSalariat = {
                                                ref_salariat: parseInt(itemS),
                                                ref_proiect:
                                                  referintaModulPentruSalariat,
                                              };

                                              return promiseS.then(() => {
                                                return this.AsociereSalariati(
                                                  modulSalariat
                                                )
                                                  .then((s) => {})
                                                  .catch((err) => {
                                                    console.log("err", err);
                                                  });
                                              });
                                            }, Promise.resolve())
                                            .then(() => {
                                              //this.$f7.popup.close(den)
                                              this.$f7.panel.close(den);
                                              this.$f7.preloader.hide();
                                              this.GetValues();
                                              this.$f7.methods.openNotification(
                                                salariati.length > 1
                                                  ? "Modul adaugat cu succes! Salariati asociati cu succes!"
                                                  : "Modul adaugat cu succes! Salariat asociat cu succes!"
                                              );
                                            })
                                            .catch((errorS) => {
                                              console.log("error", errorS);
                                              this.$f7.preloader.hide();
                                              this.$f7.methods.openNotification(
                                                "Eroare la asociere salariati: " +
                                                  errorS
                                              );
                                            });
                                        } else {
                                          //this.$f7.popup.close(den)
                                          this.$f7.panel.close(den);
                                          this.$f7.preloader.hide();
                                          this.GetValues();
                                          this.$f7.methods.openNotification(
                                            module.length > 1
                                              ? "Module adaugate cu succes!"
                                              : "Modul adaugat cu succes!"
                                          );
                                        }
                                      })
                                      .catch((error) => {
                                        console.log("error", error);
                                        this.$f7.preloader.hide();
                                        this.$f7.methods.openNotification(
                                          "Eroare la adaugare modul/module ca proiect: " +
                                            error
                                        );
                                      });
                                  })
                                  .catch((error) => {
                                    console.log("error", error);
                                    this.$f7.preloader.hide();
                                    this.$f7.methods.openNotification(
                                      "Eroare la cautare client: " + error
                                    );
                                  });
                              } else {
                                this.$f7.preloader.hide();
                                this.$f7.methods.openNotification(
                                  "Nu au fost completate corect toate campurile obligatorii"
                                );
                              }
                            },
                          },
                          {
                            key: "Vezi modulele",
                            noInputsRequired: true,
                            onSubmit: (state, den) => {
                              var obj = { ...state.editedItem };
                              var module = [];

                              if (obj.Contractul !== "valoarePentruRequired") {
                                this.$f7.data.nearhivateProiecte
                                  .filter((modul) => {
                                    return (
                                      modul.id_tip_proiect === 59 &&
                                      modul.reF_PROIECT === obj.Contractul
                                    );
                                  })
                                  .forEach((modul) => {
                                    module.push({
                                      key: modul.denumire,
                                      value: modul.iD_PROIECT,
                                    });
                                  });

                                this.$f7.data.router.navigate(
                                  "/sheetDinamic/",
                                  {
                                    props: {
                                      title:
                                        "Module asociate clientului/contractului selectat",
                                      disableClose: false,
                                      component: (
                                        <div>
                                          <List>
                                            {module.map((item, index) => {
                                              return (
                                                <ListItem key={item.value}>
                                                  {item.key}
                                                </ListItem>
                                              );
                                            })}
                                          </List>
                                        </div>
                                      ),
                                    },
                                  }
                                );
                              } else {
                                this.$f7.methods.openNotification(
                                  "Nu ati ales un client/contract!"
                                );
                              }
                            },
                          },
                        ],
                        onSelectionChanged: (state, context) => {
                          var denumire = state.denumire;
                          var newValue = state.editedItem[denumire];

                          if (denumire === "Clientul") {
                            let contracte = [{ key: "Fara", value: null }];

                            this.$f7.data.nearhivateProiecte
                              .filter((contract) => {
                                return (
                                  contract.id_tip_proiect === 58 &&
                                  contract.reF_PROIECT === newValue
                                );
                              })
                              .forEach((contract) => {
                                contracte.push({
                                  key: contract.denumire,
                                  value: contract.iD_PROIECT,
                                });
                              });
                            var inputsModule = this.state.inputsModule;
                            inputsModule[1].lista = contracte;
                            state.editedItem[this.state.inputsModule[1].key] =
                              null;
                            this.setState({ inputsModule: inputsModule });
                          }

                          if (denumire === "Contractul") {
                            let moduleAlocate = this.$f7.data.nearhivateProiecte
                              .filter((modul) => {
                                return (
                                  modul.id_tip_proiect === 59 &&
                                  modul.reF_PROIECT === newValue
                                );
                              })
                              .map((item, index) => {
                                return item.denumire;
                              });

                            let moduleFiltrate =
                              this.state.inputsModule[2].lista.filter(
                                (item) => !moduleAlocate.includes(item.key)
                              );
                            var inputsModule1 = this.state.inputsModule;
                            inputsModule1[2].lista = moduleFiltrate;
                            state.editedItem[this.state.inputsModule[2].key] =
                              [];
                            this.setState({ inputsModule: inputsModule1 });
                          }
                        },
                      },
                    });
                  }}>
                  Adauga modul
                </Button>
              </Segmented>
              <div style={{ position: "relative", top: 30 }}>
                <Row>
                  <Col
                    id={"colIntretinereProiecteStanga"}
                    width={75}
                    style={{ position: "relative" }}>
                    {" "}
                    <ProiecteGrid
                      checkedTaskuri={true}
                      checkedAscunse={this.state.checkedAscunse}
                      checkedArhivate={this.state.checkedArhivate}
                      GetValuesDreapta={this.GetValuesDreapta}
                      ref={this.refProiecteGrid}
                    />
                  </Col>
                  <Col width={25} style={{ position: "relative" }}>
                    {" "}
                    <SalariatiGrid ref={this.refSalariatiGrid} />
                  </Col>
                </Row>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              position: "absolute",
              width: "40px",
              height: "40px",
              top: "calc(50vh - 34px)",
              left: "50%",
              transform: "translateX(-50%)",
            }}>
            <Preloader size={40} color={"var(--f7-theme-color)"} />
          </div>
        )}
      </>
    );
  }
}
export default Proiecte;
