import React from "react";
import { Searchbar, Popover, Preloader, Row } from "framework7-react";
import axios from "axios";
import ListWithPaging from "./ListWithPaging/ListWithPaging";
import ListWithPagingAlocate from "./ListWithPaging/ListWithPagingAlocate";
import ListWithPagingNealocate from "./ListWithPaging/ListWithPagingNealocate";
import ListWithPagingPrimite from "./ListWithPaging/ListWithPagingPrimite";
import moment from "moment";
/**
 * @description Randeaza popoverul de sus de la search, cu taskurile alocate,primite,nealocate care au fost gasite
 * @date 08/04/2022
 * @class SearchPopover
 * @extends {React.PureComponent}
 */
class SearchPopover extends React.PureComponent {
  constructor(props) {
    super(props);
    var inpts = [
      { key: "comentariu" },
      { key: "denumire" },
      { key: "iD_PROIECT" },
      { key: "id_client" },
    ];
    var baza = this.$f7.methods.reconstructieBaza(
      { data: this.$f7.data.allProiecte },
      inpts
    );
    this.height = window.innerHeight;
    this.denumirePopover = "Popover" + props.denumire;
    this.denumireSearchbar = "Searchbar" + props.denumire;
    this.state = {
      dataSourceProiecte: baza,
      dataSourceAlocate: [],
      dataSourceNealocate: [],
      dataSourcePrimite: [],
      renderItemsProiecte: [],
      renderItemsAlocate: [],
      renderItemsNealocate: [],
      renderItemsPrimite: [],
      dataLoaded: false,
      inputs: [
        { key: "id_client", tip: "selectbox", lista: this.$f7.data.Firme },
        { key: "idClient", tip: "selectbox", lista: this.$f7.data.Firme },
        { key: "idSalariat", tip: "selectbox", lista: this.$f7.data.Salariati },
      ],
    };
  }

  /**
   * @description Inchide realizarea pentru taskul selectat
   * @date 08/04/2022
   * @param {*} idSarcina id-ul sarcinii realizae
   * @memberof SearchPopover
   */
  InchideTask = (idSarcina) => {
    if (idSarcina) {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/SarciniSalariat" +
            "/GetSARCINI_SALARIAT/" +
            idSarcina
        )
        .then((response) => {
          let sarcina = response.data;

          sarcina.dATA_DEALOCARII = moment().format();
          sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT;

          let urlUpdate =
            this.$f7.data.baseURL +
            "/api/SarciniSalariat" +
            "/PutSARCINI_SALARIAT/" +
            sarcina.iD_SARCINA;

          axios({
            data: sarcina,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: urlUpdate,
          })
            .then((response) => {
              let url =
                this.$f7.data.baseURL +
                "/api/SarciniSalariat" +
                "/PostSARCINI_SALARIAT";
              let sarcinaRezolvata = {
                iD_PROIECT: sarcina.iD_PROIECT,
                iD_SALARIAT: this.$f7.data.user.iD_SALARIAT,
                dE_CINE: this.$f7.data.user.iD_SALARIAT,
                datA_ALOCARII: moment().format(),
                dATA_DEALOCARII: null,
                dealoC_CINE: null,
                stare: 4,
              };

              axios({
                data: sarcinaRezolvata,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: url,
              })
                .then((response) => {})
                .catch((error) => {
                  console.log("error", error);
                  this.$f7.methods.openNotification("Eroare la inserare");
                });
            })
            .catch((error) => {
              console.log("error", error);
              this.$f7.methods.openNotification("Eroare la actualizare");
            });
        })
        .catch((error) => {
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la preluare");
        });
    } else {
      this.$f7.methods.openNotification("Selectati o sarcina");
    }
  };
  /**
   * @description Propune taskul salariatului selectat
   * @date 08/04/2022
   * @param {*} e salariatul curent
   * @param {*} idPropunere salariatul cui ii este alocat
   * @function
   * @memberof SearchPopover
   */
  RealocaTask = (e, idPropunere) => {
    axios
      .get(
        this.$f7.data.baseURL +
          "/api/SarciniSalariat" +
          "/GetSARCINI_SALARIAT/" +
          e
      )
      .then((response) => {
        let sarcina = response.data;

        sarcina.dATA_DEALOCARII = moment().format();
        sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT;

        let urlUpdate =
          this.$f7.data.baseURL +
          "/api/SarciniSalariat" +
          "/PutSARCINI_SALARIAT/" +
          sarcina.iD_SARCINA;

        axios({
          data: sarcina,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: urlUpdate,
        })
          .then((response) => {
            let url =
              this.$f7.data.baseURL +
              "/api/SarciniSalariat" +
              "/PostSARCINI_SALARIAT";
            let sarcinaPropusa = {
              iD_PROIECT: sarcina.iD_PROIECT,
              iD_SALARIAT: parseInt(idPropunere),
              dE_CINE: this.$f7.data.user.iD_SALARIAT,
              datA_ALOCARII: moment().format(),
              dATA_DEALOCARII: null,
              dealoC_CINE: null,
              stare: 2,
            };

            axios({
              data: sarcinaPropusa,
              headers: { "Content-Type": "application/json" },
              method: "post",
              url: url,
            })
              .then((response) => {})
              .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la inserare");
              });
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la actualizare");
          });
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la preluare");
      });
  };

  /**
   * @description Accepta taskul selectat
   * @function
   * @date 08/04/2022
   * @param {*} e id-ul taskului selectat
   * @memberof SearchPopover
   */
  AcceptaTask = (e) => {
    if (e) {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/SarciniSalariat/GetSARCINI_SALARIAT/" +
            e
        )
        .then((response) => {
          let sarcina = response.data;

          sarcina.dATA_DEALOCARII = moment().format();
          sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT;

          let urlUpdate =
            this.$f7.data.baseURL +
            "/api/SarciniSalariat/PutSARCINI_SALARIAT/" +
            sarcina.iD_SARCINA;

          axios({
            data: sarcina,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: urlUpdate,
          })
            .then((response) => {
              var urlInserare =
                this.$f7.data.baseURL +
                "/api/SarciniSalariat/PostSARCINI_SALARIAT";

              let sarcinaNoua = {
                iD_PROIECT: sarcina.iD_PROIECT,
                iD_SALARIAT: this.$f7.data.user.iD_SALARIAT,
                dE_CINE: this.$f7.data.user.iD_SALARIAT,
                datA_ALOCARII: moment().format(),
                datA_DEALOCARII: null,
                dealoC_CINE: null,
                stare: 1,
              };

              axios({
                data: sarcinaNoua,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: urlInserare,
              })
                .then((response) => {})
                .catch((error) => {
                  console.log("error", error);
                  this.$f7.methods.openNotification("Eroare la inserare");
                });
            })
            .catch((error) => {
              console.log("error", error);
              this.$f7.methods.openNotification("Eroare la actualizare");
            });
        })
        .catch((error) => {
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la preluare");
        });
    } else {
      this.$f7.methods.openNotification("Selectati o sarcina");
    }
  };

  /**
   * @description Respinge taskul care i-a fost propus
   * @function
   * @date 08/04/2022
   * @param {*} e id-ul taskului primit
   * @memberof SearchPopover
   */
  RespingeTask = (e) => {
    if (e) {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/SarciniSalariat/GetSARCINI_SALARIAT/" +
            e
        )
        .then((response) => {
          var sarcina = response.data;
          sarcina.stare = 3;
          var url =
            this.$f7.data.baseURL +
            "/api/SarciniSalariat/PutSARCINI_SALARIAT/" +
            sarcina.iD_SARCINA;

          axios({
            data: sarcina,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: url,
          })
            .then((response) => {})
            .catch((error) => {
              console.log("error", error);
              this.$f7.methods.openNotification("Eroare la actualizare");
            });
        })
        .catch((error) => {
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la preluare");
        });
    } else {
      this.$f7.methods.openNotification("Selectati o sarcina");
    }
  };

  /**
   * @description Refuza taskul care i-a fost propus
   * @date 08/04/2022
   * @function
   * @param {*} e id-ul taskului propus
   * @memberof SearchPopover
   */
  RefuzaTask = (e) => {
    if (e) {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/Salariati/GetSALARIATI/" +
            this.$f7.data.user.iD_SALARIAT
        )
        .then((response) => {
          let salariat = response.data;
          let idSef = salariat.reF_SALARIAT;

          if (idSef !== null) {
            axios
              .get(
                this.$f7.data.baseURL +
                  "/api/SarciniSalariat/GetSARCINI_SALARIAT/" +
                  e
              )
              .then((response) => {
                let sarcina = response.data;

                sarcina.dATA_DEALOCARII = moment().format();
                sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT;

                let urlUpdate =
                  this.$f7.data.baseURL +
                  "/api/SarciniSalariat/PutSARCINI_SALARIAT/" +
                  sarcina.iD_SARCINA;

                axios({
                  data: sarcina,
                  headers: { "Content-Type": "application/json" },
                  method: "put",
                  url: urlUpdate,
                })
                  .then((response) => {
                    var urlInserare =
                      this.$f7.data.baseURL +
                      "/api/SarciniSalariat/PostSARCINI_SALARIAT";

                    let sarcinaNoua = {
                      iD_PROIECT: sarcina.iD_PROIECT,
                      iD_SALARIAT: idSef,
                      dE_CINE: this.$f7.data.user.iD_SALARIAT,
                      datA_ALOCARII: moment().format(),
                      datA_DEALOCARII: null,
                      dealoC_CINE: null,
                      stare: 1,
                    };

                    axios({
                      data: sarcinaNoua,
                      headers: { "Content-Type": "application/json" },
                      method: "post",
                      url: urlInserare,
                    })
                      .then((response) => {})
                      .catch((error) => {
                        console.log("error", error);
                        this.$f7.methods.openNotification("Eroare la inserare");
                      });
                  })
                  .catch((error) => {
                    console.log("error", error);
                    this.$f7.methods.openNotification("Eroare la actualizare");
                  });
              })
              .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la preluare sarcina");
              });
          } else {
            this.$f7.methods.openNotification(
              "Nu este posibila refuzarea taskului selectat deoarece nu exista un superior ierarhic pentru a il prelua."
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la preluare salariat");
        });
    } else {
      this.$f7.methods.openNotification("Selectati o sarcina");
    }
  };

  /**
   * @description Isi asuma taskul selectat
   * @date 08/04/2022
   * @function
   * @param {*} id id-ul taskului selectat
   * @memberof SearchPopover
   */
  AsumaTask = (id) => {
    if (id) {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/SarciniSalariat/GetSARCINI_SALARIATRespinsByProiect/" +
            id
        )
        .then((response) => {
          let sarcina = response.data;
          let url =
            this.$f7.data.baseURL + "/api/SarciniSalariat/PostSARCINI_SALARIAT";
          let sarcinaAsumata = {
            iD_PROIECT: parseInt(id),
            iD_SALARIAT: this.$f7.data.user.iD_SALARIAT,
            dE_CINE: this.$f7.data.user.iD_SALARIAT,
            datA_ALOCARII: moment().format(),
            datA_DEALOCARII: null,
            dealoC_CINE: null,
            stare: 1,
          };

          if (sarcina === "") {
            axios({
              data: sarcinaAsumata,
              headers: { "Content-Type": "application/json" },
              method: "post",
              url: url,
            })
              .then((response) => {})
              .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la inserare");
              });
          } else {
            sarcina.datA_DEALOCARII = moment().format();
            sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT;

            let urlUpdate =
              this.$f7.data.baseURL +
              "/api/SarciniSalariat/PutSARCINI_SALARIAT/" +
              sarcina.iD_SARCINA;

            axios({
              data: sarcina,
              headers: { "Content-Type": "application/json" },
              method: "put",
              url: urlUpdate,
            })
              .then((response) => {
                axios({
                  data: sarcinaAsumata,
                  headers: { "Content-Type": "application/json" },
                  method: "post",
                  url: url,
                })
                  .then((response) => {})
                  .catch((error) => {
                    console.log("error", error);
                    this.$f7.methods.openNotification("Eroare la inserare");
                  });
              })
              .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la actualizare");
              });
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la preluare");
        });
    } else {
      this.$f7.methods.openNotification("Selectati o sarcina");
    }
  };

  /**
   * @description Ia toate tipurile de sarcini pentru a putea face cautare intre ele(alocate,primite,nealocate,all)
   * @function
   * @date 08/04/2022
   * @memberof SearchPopover
   */
  GetValues = () => {
    this.setState({ dataLoaded: false });
    const reqAlocate = () => {
      return axios.get(
        this.$f7.data.baseURL +
          "/api/SarciniSalariat/GetSARCINI_SALARIATAlocate/" +
          this.$f7.data.user.iD_SALARIAT +
          "/1/1/1"
      );
    };
    const reqPrimite = () => {
      return axios.get(
        this.$f7.data.baseURL +
          "/api/SarciniSalariat/GetSARCINI_SALARIATPrimite/" +
          this.$f7.data.user.iD_SALARIAT +
          "/1/1/1"
      );
    };
    const reqNealocate = () => {
      return axios.get(
        this.$f7.data.baseURL +
          "/api/SarciniSalariat/GetSARCINI_SALARIATNealocate/" +
          this.$f7.data.user.iD_SALARIAT +
          "/1/1/1"
      );
    };
    axios.all([reqAlocate(), reqPrimite(), reqNealocate()]).then(
      axios.spread((responseAlocate, responsePrimite, responseNealocate) => {
        var dataAlocate = responseAlocate.data;
        var dataPrimite = responsePrimite.data;
        var dataNealocate = responseNealocate.data;
        this.setState({
          dataSourceAlocate: dataAlocate,
          dataSourcePrimite: dataPrimite,
          dataSourceNealocate: dataNealocate,
          dataLoaded: true,
        });
      })
    );
  };

  componentDidMount = () => {
    this.GetValues();
  };

  /**
   * @description Face cautare in sursa trimisa(se face cu toate sursele din input)
   * @date 08/04/2022
   * @param {*} value valoarea de cautat
   * @param {*} baza baza in care cauta
   * @param {*} denumire denumirea coloanei in care sa caute
   * @param {*} toDo functie de callback(folosita pentru a apela in urma unei cautari, cautarea in alta sursa)
   * @memberof SearchPopover
   */
  search = (value, baza, denumire, toDo) => {
    var toSearch = value.toString().toLowerCase().replace(/\s/g, "");
    var result = baza.filter((elemBaza) => {
      var res = false;
      var valFinal = "";
      if (denumire) {
        var valoare = elemBaza[denumire];
        var inputCurent = this.state.inputs.filter((elem) => {
          return elem.key === denumire;
        })[0];
        var isSelectBox = inputCurent.tip === "selectbox";
        if (isSelectBox) {
          var resultFilter = inputCurent.lista.filter((r) => {
            return r.value === valoare;
          });
          valoare = resultFilter.length > 0 ? resultFilter[0].key : null;
        }
        if (valoare) {
          valFinal = valoare.toString().toLowerCase().replace(/\s/g, "");
        }
      } else {
        valFinal = Object.values(elemBaza)
          .join("")
          .toString()
          .toLowerCase()
          .replace(/\s/g, "");
      }
      if (valFinal.includes(toSearch)) {
        res = true;
      }
      return res;
    });
    if (toDo) {
      toDo(result);
    }
  };

  arrayUnique = (array) => {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) a.splice(j--, 1);
      }
    }

    return a;
  };

  render() {
    return (
      <>
        <Popover
          style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
          backdrop={false}
          className={this.denumirePopover}>
          {this.state.dataLoaded ? (
            <Row>
              <ListWithPaging
                openDetails={(e) => {
                  this.$f7.methods.openMainDreaptaById(
                    e.iD_PROIECT,
                    "/agendaGridMainDreapta/"
                  );
                  this.$f7.searchbar.toggle("." + this.denumireSearchbar);
                  this.$f7.popover.close("." + this.denumirePopover);
                }}
                title={"Proiecte"}
                dataSource={this.state.renderItemsProiecte}
                denumire={"denumire"}
              />

              <ListWithPagingPrimite
                title={"Proiecte primite"}
                openDetails={(e) => {
                  this.$f7.methods.openMainDreaptaById(
                    e.idProiect,
                    "/agendaGridMainDreapta/"
                  );
                  this.$f7.searchbar.toggle("." + this.denumireSearchbar);
                  this.$f7.popover.close("." + this.denumirePopover);
                }}
                dataSource={this.state.renderItemsPrimite}
                denumire={"denumire"}
                denumireId={"idSarcina"}
                denumireData={"data"}
                GetValues={this.GetValues}
                AcceptaTask={this.AcceptaTask}
                RefuzaTask={this.RefuzaTask}
                RespingeTask={this.RespingeTask}
              />

              <ListWithPagingNealocate
                openDetails={(e) => {
                  this.$f7.methods.openMainDreaptaById(
                    e.id,
                    "/agendaGridMainDreapta/"
                  );
                  this.$f7.searchbar.toggle("." + this.denumireSearchbar);
                  this.$f7.popover.close("." + this.denumirePopover);
                }}
                title={"Proiecte nealocate"}
                dataSource={this.state.renderItemsNealocate}
                denumire={"denumire"}
                denumireId={"id"}
                denumireData={"data"}
                GetValues={this.GetValues}
                AsumaTask={this.AsumaTask}
              />
              <ListWithPagingAlocate
                openDetails={(e) => {
                  this.$f7.methods.openMainDreaptaById(
                    e.idProiect,
                    "/agendaGridMainDreapta/"
                  );
                  this.$f7.searchbar.toggle("." + this.denumireSearchbar);
                  this.$f7.popover.close("." + this.denumirePopover);
                }}
                title={"Proiecte alocate"}
                dataSource={this.state.renderItemsAlocate}
                denumire={"denumire"}
                denumireId={"idSarcina"}
                denumireData={"data"}
                GetValues={this.GetValues}
                RealocaTask={this.RealocaTask}
                InchideTask={this.InchideTask}
              />
            </Row>
          ) : (
            <div
              style={{
                overflow: "hidden",
                width: "100%",
                margin: "0 auto",
                textAlign: "center",
              }}>
              <Preloader size={40} color={"var(--f7-theme-color)"}></Preloader>
              Loading datasources...
            </div>
          )}
        </Popover>
        <Searchbar
          className={this.denumireSearchbar}
          expandable
          onClickDisable={() => {
            this.$f7.searchbar.toggle("." + this.denumireSearchbar);
          }}
          borderColor={"transparent"}
          onChange={(e) => {
            var t = e.target;
            var value = e.target.value.toLowerCase();

            this.search(
              value,
              value.length > 0
                ? this.state.renderItemsProiecte
                : this.state.dataSourceProiecte,
              false,
              (result1) => {
                this.search(
                  value,
                  value.length > 0
                    ? this.state.renderItemsProiecte
                    : this.state.dataSourceProiecte,
                  "id_client",
                  (result2) => {
                    var arrUnic = this.arrayUnique(result1.concat(result2));
                    this.setState({ renderItemsProiecte: arrUnic });
                  }
                );
              }
            );
            this.search(
              value,
              this.state.dataSourceAlocate,
              false,
              (result1) => {
                this.search(
                  value,
                  this.state.dataSourceAlocate,
                  "idClient",
                  (result2) => {
                    this.search(
                      value,
                      this.state.dataSourceAlocate,
                      "idSalariat",
                      (result3) => {
                        var arrUnic = this.arrayUnique(
                          result1.concat(result2).concat(result3)
                        );
                        this.setState({ renderItemsAlocate: arrUnic });
                      }
                    );
                  }
                );
              }
            );
            this.search(
              value,
              this.state.dataSourcePrimite,
              false,
              (result1) => {
                this.search(
                  value,
                  this.state.dataSourcePrimite,
                  "idClient",
                  (result2) => {
                    this.search(
                      value,
                      this.state.dataSourcePrimite,
                      "idSalariat",
                      (result3) => {
                        var arrUnic = this.arrayUnique(
                          result1.concat(result2).concat(result3)
                        );
                        this.setState({ renderItemsPrimite: arrUnic });
                      }
                    );
                  }
                );
              }
            );
            this.search(
              value,
              this.state.dataSourceNealocate,
              false,
              (result1) => {
                this.search(
                  value,
                  this.state.dataSourceNealocate,
                  "idClient",
                  (result2) => {
                    var arrUnic = this.arrayUnique(result1.concat(result2));
                    this.setState({ renderItemsNealocate: arrUnic });
                  }
                );
              }
            );
            var opened = this.$f7.popover.get(
              "." + this.denumirePopover
            ).opened;
            if (!opened) {
              this.$f7.popover.open("." + this.denumirePopover, t);
            }
          }}></Searchbar>
      </>
    );
  }
}
export default SearchPopover;
