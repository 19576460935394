import React, { Component } from "react";
import message from "./actualizareWorker.js";
import WebWorker from "react-web-workers";
import {
  App as AppRoot,
  View,
  List,
  Row,
  Col,
  ListItem,
  Icon,
  Button,
  Panel,
  Block,
  BlockTitle,
  Page,
  Chip,
} from "framework7-react";
import routes from "../routes";
import config from "../config";
import axios from "axios";
import TopBar from "./TopBar";
import moment from "moment";
import alarmSound from "../styles/alarm.mp3";
import online from "../styles/online.mp3";
import offline from "../styles/offline.mp3";
import SpeechRecognition from "react-speech-recognition";
const [messageWorker] = WebWorker([message]);

var speechText = "";
var not = null;
var stylesheet;
var legend = [
  { color: "black", title: "In evaluare", className: "tasknormal" },
  { color: "green", title: "In lucru", className: "taskactiv" },
  { color: "orange", title: "Lucrat", className: "taskoprit" },
  { color: "red", title: "Inchis", className: "taskinchis" },
  { color: "brown", title: "Propus altcuiva", className: "taskpropus" },
  { color: "orange", title: "Acceptat", className: "taskacceptat" },
  { color: "black", title: "Ascuns", className: "ascuns" },
  { color: "black", title: "Arhivat", className: "arhivat" },
  {
    color: "black",
    title: "Deadline depasit",
    className: "taskDeadlineDepasit",
  },
];
var legendProiecte = [
  { title: "Proiect", className: "proiect" },
  { title: "Subproiect", className: "subproiect" },
  { title: "Firma", className: "firma" },
  { title: "Contract", className: "contract" },
  { title: "Modul", className: "modul" },
  { title: "Task", className: "task" },
];

/**
 * @description Principala componenta a aplicatiei, contine store-ul(toate informatiile globale) si functii globale ce pot fi accesate de catre componentele(paginile randate de router)
 * si defineste comportamentul aplicatiei(night mode, tema, culori etc);
 * @date 06/04/2022
 * @class App
 * @extends {Component}
 */

class App extends Component {
  constructor(props) {
    super(props);
    if (localStorage.getItem("ruteAlive")) {
      var rute = JSON.parse(localStorage.getItem("ruteAlive"));
      rute.map((r) => {
        var exista = routes.find((f) => {
          return f.path === r.path;
        });
        if (exista) {
          exista.keepAlive = r.keepAlive;
        }
        return null;
      });
    }
    this.online = true;
    this.started = false;
    this.reloadCalled = false;
    this.state = {
      idSalariat: null,
      linkApi: "/api/SarciniSalariat",
      linkApiRealizari: "/api/RealizareSarcini",
      linkApiGetProiect: "/api/Proiecte/GetPROIECTESauArhivate",
      linkApiUpdateProiect: "/api/Proiecte/PutPROIECTE",
      linkApiDelegatii: "/api/Delegatii",
      linkApiDetaliu: "/api/DetaliuDelegatii",
      linkApiProiecte: "/api/Proiecte",
      idProiectVechi: null,
      sfarsit: false,
      deschideNou: false,
      proiectDeschis: null,
      inchideDupaOprire: false,
      realocaDupaOprire: false,
      comInchidereTask: false,
      idClientTelefon: null,
      idProiectParinteTelefon: null,
      iconita: "menu",
      showBack: false,
      showAppBar: false,
      showTopRight: false,
      breadCrumbs: null,
      router: null,
      showSplash: true,
      routes: routes,
      transition:
        localStorage.getItem("transition") !== null
          ? localStorage.getItem("transition")
          : "f7-fade",
      themeDark: localStorage.getItem("theme")
        ? localStorage.getItem("theme") === "true"
          ? true
          : false
        : false,
    };
    this.refTopBar = React.createRef();

    this.audio = new Audio(alarmSound);
    this.audioOffline = new Audio(offline);
    this.audioOnline = new Audio(online);
  }

  /**
   * @description transforma o valoare hex in valoare int
   * @function
   * @date 07/04/2022
   * @param {*} hex valoarea hex
   * @memberof App
   */
  hexToInt = (hex) => {
    var rgb = hex.split("#")[1];
    var bgr = rgb.substring(4) + rgb.substring(2, 4) + rgb.substring(0, 2);
    var num = "00" + bgr;
    var res = parseInt(num, 16);
    return res;
  };

  /**
   * @description functia de delogare a userului curent, sterge si workerul de actualizeaza valorile taskului
   * @function
   * @date 07/04/2022
   * @memberof App
   */
  LogOut = () => {
    return new Promise((resolve, reject) => {
      var latLng = this.getLatLng()
        .then((response) => {
          var obj = {
            DataIesire: moment().format(),
            lng: latLng.long,
            lat: latLng.lat,
          };
          if (this.$f7.data.idSesiune) {
            this.saveLogOut(obj, this.$f7.data.idSesiune)
              .then((response) => {
                messageWorker?.terminate?.();
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("No id.");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Actualizeaza in baza de date sesiunea de logare curenta a salariatului cu data de final
   * @function
   * @date 07/04/2022
   * @param {*} obj obiectul cu valorile de inchidere a sesiunii
   * @param {*} id id-ul sesiunii
   * @memberof App
   */
  saveLogOut = (obj, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(this.$f7.data.baseURL + "/api/Sesiuni/GetSesiuni/" + id)
        .then((response) => {
          var obj2 = response.data;
          Object.keys(obj).map((trasatura) => {
            Object.keys(obj2).map((trasatura2) => {
              if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
                obj2[trasatura2] = obj[trasatura];
              }
              return false;
            });
            return false;
          });

          var url = this.$f7.data.baseURL + "/api/Sesiuni/PutSesiuni/" + id;
          axios({
            data: obj2,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: url,
          })
            .then((response2) => {
              resolve(response2);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Returneaza un obiect cu longitudinea si latitudinea
   * @function
   * @date 07/04/2022
   * @memberof App
   */
  getLatLng = () => {
    return new Promise((resolve, reject) => {
      var lat = null;
      var long = null;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (p) => {
            lat = p.coords.latitude;
            long = p.coords.longitude;
            if (lat && long) {
              resolve({ lat: lat, long: long });
            }
          },
          (e) => {
            resolve({ lat: lat, long: long });
          }
        );
      } else {
        resolve({ lat: lat, long: long });
      }
    });
  };

  /**
   * @description Seteaza valoarea unui cookie
   * @date 07/04/2022
   * @function
   * @param {*} cname numele cookie-ului
   * @param {*} cvalue valoarea cookie-ului
   * @param {*} exdays timpul in care expira cookie-ul
   * @memberof App
   */
  setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  /**
   * @description Deschide interfata de mesaje la salariatul selectat si ataseaza si un task mesajului daca este trimis
   * @date 08/04/2022
   * @param {*} idSalariat id-ul salariatului
   * @param {*} idTask taskul de atasat
   * @function
   * @memberof App
   */
  openMesaje = (idSalariat, idTask) => {
    return new Promise((resolve, reject) => {
      var panelMesaje = this.$f7.panel.get("." + this.$f7.data.denumireMesaje);
      if (panelMesaje) {
        panelMesaje.open();
        if (idTask !== undefined) {
          axios
            .get("/api/Proiecte/GetPROIECTE/" + idTask)
            .then((response) => {
              var idSal =
                response.data.id_dezvoltator !== null
                  ? response.data.id_dezvoltator
                  : idSalariat;
              if (idSal) {
                this.$f7.data.messagesPopover.refMesaje.current.setState(
                  {
                    idTask: response.data.iD_PROIECT,
                    utilizatorSelectat: idSal,
                  },
                  () => {
                    this.$f7.data.messagesPopover.refMesaje.current
                      .GetValuesDreapta()
                      .then(() => {
                        resolve(this);
                      })
                      .catch((err) => {
                        reject(err);
                      });
                  }
                );
              } else {
                this.$f7.methods.openNotification(
                  "Taskul respectiv nu este alocat nimanui."
                );
                reject("Taskul respectiv nu este alocat nimanui.");
              }
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          this.$f7.data.messagesPopover.refMesaje.current.setState(
            { utilizatorSelectat: idSalariat },
            () => {
              this.$f7.data.messagesPopover.refMesaje.current
                .GetValuesDreapta()
                .then((resp) => {
                  resolve(this);
                })
                .catch((err) => {
                  reject(err);
                });
            }
          );
        }
      }
    });
  };

  /**
   * @description Arhiveaza toate proiectele copil ale id-ului referinta
   * @date 08/04/2022
   * @param {*} ref id-ul de referinta
   * @memberof App
   * @function
   */
  preCall = (ref) => {
    return new Promise((resolve, reject) => {
      var state = ref.current.state;
      var selectedRows = [...state.allSelectedRows];
      if (selectedRows.length === 0) {
        selectedRows = [{ ...state.rowDataAll }];
      }
      var listaIduri = [];
      selectedRows.map((r) => {
        var id = r.IdProiect;
        if (r.IsArhivat) {
          listaIduri.push(id);
        }
        return null;
      });
      if (listaIduri.length > 0) {
        this.$f7.methods
          .arhiveazaDezarhiveazaProiect(listaIduri, false)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve("Lista goala");
      }
    });
  };

  /**
   * @description Returneaza adancimea cea mai mare din datagridul curent
   * @date 08/04/2022
   * @param {*} root id-ul root al datasource-ului
   * @param {*} dataSource dataSource-ul care este parcurs
   * @param {*} id denumirea key-ului
   * @param {*} refId denumirea key-ului de referinta
   * @function
   * @memberof App
   */
  maxDepth = (root, dataSource, id, refId) => {
    // variable to store the maximum levels
    let max = 0;
    //helper function to traverse the tree
    //recursively increment the levels by one
    const dfs = (node, levels) => {
      //compare levels and max to pass the maximum levels
      if (levels > max) max = levels;
      //traverse all children of the current node
      var childs = dataSource.filter((r) => r[refId] === node);
      for (let child of childs) {
        //increment the levels by one
        dfs(child[id], levels + 1);
      }
    };
    //when root is not null, the tree has at least one level,
    //so we pass down 0
    dfs(root, 0);
    //return the maximum levels
    return max - 1;
  };

  /**
   * @description Inchide taskul selectat
   * @date 08/04/2022
   * @param {*} id id-ul taskului
   * @param {*} idS id-ul sarcinii taskului
   * @function
   * @memberof App
   */
  InchideTaskVerificat = (id, idS) => {
    var idSelected = id;
    var idSarcina = idS;

    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            this.state.linkApi +
            "/GetSARCINI_SALARIAT/" +
            idSarcina
        )
        .then((response1) => {
          let sarcina = response1.data;

          sarcina.dATA_DEALOCARII = moment().format();
          sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT;

          let urlUpdate =
            this.$f7.data.baseURL +
            this.state.linkApi +
            "/PutSARCINI_SALARIAT/" +
            sarcina.iD_SARCINA;

          axios({
            data: sarcina,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: urlUpdate,
          })
            .then((response2) => {
              let url =
                this.$f7.data.baseURL +
                this.state.linkApi +
                "/PostSARCINI_SALARIAT";
              let sarcinaRezolvata = {
                iD_PROIECT: sarcina.iD_PROIECT,
                iD_SALARIAT: this.$f7.data.idSalariatFunctii,
                dE_CINE: this.$f7.data.idSalariatFunctii,
                datA_ALOCARII: moment().format(),
                dATA_DEALOCARII: null,
                dealoC_CINE: null,
                stare: 4,
              };

              axios({
                data: sarcinaRezolvata,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: url,
              })
                .then((response3) => {
                  axios
                    .get(
                      this.$f7.data.baseURL +
                        this.state.linkApiGetProiect +
                        "/" +
                        idSelected
                    )
                    .then((responseProiect) => {
                      let proiect = responseProiect.data;

                      proiect.iD_STADIU = 6;

                      let urlUpdateProiect =
                        this.$f7.data.baseURL +
                        this.state.linkApiUpdateProiect +
                        "/" +
                        idSelected;

                      axios({
                        data: proiect,
                        headers: { "Content-Type": "application/json" },
                        method: "put",
                        url: urlUpdateProiect,
                      }).then((response4) => {
                        resolve(response4);
                      });
                    });
                })
                .catch((error) => {
                  console.log("error", error);
                  this.$f7.methods.openNotification("Eroare la inserare");
                  reject(error);
                });
            })
            .catch((error) => {
              console.log("error", error);
              this.$f7.methods.openNotification("Eroare la actualizare");
              reject(error);
            });
        })
        .catch((error) => {
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la preluare");
          reject(error);
        });
    });
  };

  /**
   * @description Actualizeaza stadiul unui proiect cu stadiul transmis ca parametru
   * @date 08/04/2022
   * @param {*} idProiect id-ul proiectului
   * @param {*} idStadiu id-ul stadiului
   * @function
   * @memberof App
   */
  putStadiuProiect = (idProiect, idStadiu) => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL + "/api/Proiecte/PutStadiuPROIECTE/" + idProiect;
      axios({
        data: idStadiu,
        headers: { "Content-Type": "application/json" },
        method: "put",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  VerificareTaskApel = (idPrj, idActivitate) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/PrimireApel/GetCorelatieActiva/" +
            this.$f7.data.idSalariatFunctii +
            "/" +
            idPrj
        )
        .then((response) => {
          if (response.data === "") {
            resolve("Nu este task din apel!");
          } else {
            let corelatie = response.data;
            const idCorelatie = response.data.idApel;
            const idProiectApel = response.data.ref_proiect;

            let arrProiecte = this.$f7.data.allProiecte
              .filter((r) => {
                return (
                  (r.id_nivel_proiect === 1 || r.id_nivel_proiect === 2) &&
                  r.id_departament === this.$f7.data.user.iD_DEPARTAMENT
                );
              })
              .map((r) => {
                r.key = r.denumire;
                r.value = r.iD_PROIECT;
                return r;
              });
            arrProiecte.unshift({ key: "Fara", value: null });

            this.$f7.preloader.hide();

            this.$f7.data.router.navigate("/popupApel/", {
              props: {
                title: "Adaugare informatii task telefonic",
                disableClose: true,
                component: (
                  <div
                    style={{
                      boxSizing: "border-box",
                      padding: "10px 30px 30px",
                    }}>
                    <span
                      style={{
                        textAlign: "center",
                        width: "100%",
                        display: "block",
                        fontSize: "20px",
                        padding: 15,
                        boxSizing: "border-box",
                      }}>
                      Taskul
                      {
                        this.$f7.data.Proiecte.filter((p) => {
                          return p.value === idPrj;
                        })[0].key
                      }
                      este primit prin apel telefonic. Pentru a se opri corect,
                      trebuie completate urmatoarele campuri.
                    </span>

                    <List
                      style={{ margin: 0 }}
                      noChevron
                      form
                      noHairlines
                      noHairlinesBetween
                      noHairlinesBetweenMd
                      noHairlinesMd>
                      <Row style={{ alignItems: "center" }}>
                        <Col>
                          <ListItem
                            title="Client"
                            value={this.state.idClientTelefon}
                            label="Client"
                            validate
                            required
                            smartSelect
                            smartSelectParams={{
                              virtualList: true,
                              searchbar: true,
                              openIn: "popup",
                              popupTabletFullscreen: true,
                              popupPush: true,
                              closeOnSelect: true,
                              on: {
                                closed: (event) => {
                                  let clientSelectat = event.getValue();
                                  this.setState({
                                    idClientTelefon:
                                      clientSelectat === "Fara" ||
                                      clientSelectat === "null" ||
                                      clientSelectat === null
                                        ? null
                                        : parseInt(clientSelectat),
                                  });
                                },
                              },
                            }}>
                            <Icon
                              color={"var(--f7-theme-color)"}
                              size={20}
                              material="list"
                              slot="content-start"
                            />
                            <select
                              defaultValue={this.state.idClientTelefon}
                              name="Client">
                              {this.$f7.data.Firme.map((obj, index) => (
                                <option
                                  label={obj.key}
                                  key={this.$f7.utils.id(
                                    "xxxx-xxxx-xxxx-xxxx-xxxx"
                                  )}
                                  value={obj.value}>
                                  {obj.key}
                                </option>
                              ))}
                            </select>
                          </ListItem>
                        </Col>
                        <Col>
                          <ListItem
                            title="Proiect Referinta"
                            value={this.state.idProiectParinteTelefon}
                            label="Proiect Referinta"
                            validate
                            required
                            smartSelect
                            smartSelectParams={{
                              virtualList: true,
                              searchbar: true,
                              openIn: "popup",
                              popupTabletFullscreen: true,
                              popupPush: true,
                              closeOnSelect: true,
                              on: {
                                closed: (event) => {
                                  let proiectSelectat = event.getValue();
                                  this.setState({
                                    idProiectParinteTelefon:
                                      proiectSelectat === "Fara" ||
                                      proiectSelectat === "null" ||
                                      proiectSelectat === null
                                        ? null
                                        : parseInt(proiectSelectat),
                                  });
                                },
                              },
                            }}>
                            <Icon
                              color={"var(--f7-theme-color)"}
                              size={20}
                              material="list"
                              slot="content-start"
                            />
                            <select
                              defaultValue={this.state.idProiectParinteTelefon}
                              name="Proiect Referinta">
                              {arrProiecte.map((obj, index) => (
                                <option
                                  label={obj.key}
                                  key={this.$f7.utils.id(
                                    "xxxx-xxxx-xxxx-xxxx-xxxx"
                                  )}
                                  value={obj.value}>
                                  {obj.key}
                                </option>
                              ))}
                            </select>
                          </ListItem>
                        </Col>
                      </Row>
                    </List>

                    <Button
                      tooltip={"Ok"}
                      style={{
                        width: "100%",
                        maxWidth: 200,
                        margin: "20px auto 0",
                      }}
                      fill
                      onClick={() => {
                        if (
                          this.state.idClientTelefon !== null &&
                          this.state.idProiectParinteTelefon !== null
                        ) {
                          this.$f7.preloader.show();

                          corelatie["activ"] = false;

                          axios({
                            data: corelatie,
                            headers: { "Content-Type": "application/json" },
                            method: "PUT",
                            url:
                              this.$f7.data.baseURL +
                              "/api/PrimireApel/PutPRIMIRE_APEL/" +
                              idCorelatie,
                          })
                            .then((responseCorelatie) => {
                              axios
                                .get(
                                  this.$f7.data.baseURL +
                                    this.state.linkApiGetProiect +
                                    "/" +
                                    idProiectApel
                                )
                                .then((responseProiect) => {
                                  let proiect = responseProiect.data;
                                  proiect.reF_PROIECT =
                                    this.state.idProiectParinteTelefon;
                                  proiect.iD_CLIENT =
                                    this.state.idClientTelefon;

                                  axios({
                                    data: proiect,
                                    headers: {
                                      "Content-Type": "application/json",
                                    },
                                    method: "put",
                                    url:
                                      this.$f7.data.baseURL +
                                      "/api/Proiecte/PutPROIECTE/" +
                                      idProiectApel,
                                  })
                                    .then((responseProiect2) => {
                                      axios
                                        .get(
                                          this.$f7.data.baseURL +
                                            "/api/RealizareSarcini" +
                                            "/GetREALIZARE_SARCINI/" +
                                            parseInt(idActivitate)
                                        )
                                        .then((responseActivitate) => {
                                          let realizareCurenta =
                                            responseActivitate.data;
                                          realizareCurenta["ID_CLIENT"] =
                                            this.state.idClientTelefon;

                                          axios({
                                            data: realizareCurenta,
                                            headers: {
                                              "Content-Type":
                                                "application/json",
                                            },
                                            method: "put",
                                            url:
                                              this.$f7.data.baseURL +
                                              "/api/RealizareSarcini/PutREALIZARE_SARCINI/" +
                                              parseInt(idActivitate),
                                          })
                                            .then((responseActivitate2) => {
                                              this.setState(
                                                {
                                                  idClientTelefon: null,
                                                  idProiectParinteTelefon: null,
                                                },
                                                () => {
                                                  this.$f7.popup.close(
                                                    ".PopupApel"
                                                  );
                                                  resolve(
                                                    "Este task din apel!"
                                                  );
                                                }
                                              );
                                            })
                                            .catch((error) => {
                                              console.log("error", error);
                                              reject(error);
                                            });
                                        })
                                        .catch((error) => {
                                          console.log("error", error);
                                          reject(error);
                                        });
                                    })
                                    .catch((error) => {
                                      console.log("error", error);
                                      reject(error);
                                    });
                                })
                                .catch((error) => {
                                  console.log("error", error);
                                  reject(error);
                                });
                            })
                            .catch((error) => {
                              console.log("error", error);
                              reject(error);
                            });
                        } else {
                          this.$f7.methods.openNotification(
                            "Trebuie selectat atat clientul cat si proiectul parinte",
                            "Atentie"
                          );
                        }
                      }}>
                      OK
                    </Button>
                  </div>
                ),
              },
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  };

  /**
   * @description Actualizeaza inregistrarea unui proiect din baza de date
   * @date 08/04/2022
   * @param {*} obj obiect cu noile informatii ale proiectului
   * @param {*} id id-ul proiectului de actualizat
   * @function
   * @memberof App
   */
  UpdateValueProiecte = (obj, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            this.state.linkApiProiecte +
            "/GetPROIECTE/" +
            id
        )
        .then((response) => {
          var obj2 = response.data;
          var id_stadiu = obj2["iD_STADIU"];
          Object.keys(obj).map((trasatura) => {
            Object.keys(obj2).map((trasatura2) => {
              if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
                obj2[trasatura2] = obj[trasatura];
              }
              return false;
            });
            return false;
          });
          obj2["iD_STADIU"] = id_stadiu;
          var url =
            this.$f7.data.baseURL +
            this.state.linkApiProiecte +
            "/PutPROIECTE/" +
            id;
          axios({
            data: obj2,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: url,
          })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              console.log("error", error);
              this.$f7.methods.openNotification("Eroare la modificare");
              reject(error);
            });
        });
    });
  };

  /**
   * @description Actualizeaza starea unei realizari, o inchide
   * @date 08/04/2022
   * @param {*} obj obiectul cu noile informatii
   * @param {*} idRealizare id-ul realizari de inchis
   * @function
   * @memberof App
   */
  UpdateValueHidden = (obj, idRealizare) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            this.state.linkApiRealizari +
            "/GetREALIZARE_SARCINI/" +
            idRealizare
        )
        .then((response) => {
          var obj2 = response.data;
          Object.keys(obj).map((trasatura) => {
            Object.keys(obj2).map((trasatura2) => {
              if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
                obj2[trasatura2] = obj[trasatura];
              }
              return false;
            });
            return false;
          });
          obj2["manual"] = false;
          obj2["aprobat"] = true;
          obj2["ultima_activitate"] = moment().format();

          var url =
            this.$f7.data.baseURL +
            this.state.linkApiRealizari +
            "/PutREALIZARE_SARCINI/" +
            idRealizare;
          axios({
            data: obj2,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: url,
          })
            .then((response) => {
              //Sa actualizez in baza de date la proiecte
              var idProiect = obj2.iD_PROIECT;
              var stadiu = this.state.inchideDupaOprire ? 6 : obj.Stadiu;
              var operatie = obj.Operatie;

              var objProiect = { iD_STADIU: stadiu, id_operatiuni: operatie };

              this.GetUltimaSarcinaPentruProiect(idProiect)
                .then((responseSarcina) => {
                  var idSarcina = responseSarcina.data.iD_SARCINA;
                  this.UpdateValueProiecte(objProiect, idProiect)
                    .then((res) => {
                      if (this.state.deschideNou) {
                        //Cand se opreste si deschide task nou
                        setTimeout(() => {
                          this.Inceput(idProiect, idSarcina)
                            .then((r) => {
                              resolve(r);
                            })
                            .catch((err) => {
                              reject(err);
                            });
                        }, 500);
                      } else if (this.state.inchideDupaOprire) {
                        //Cand se opreste si se si inchide
                        setTimeout(() => {
                          this.InchideTaskVerificat(idProiect, idSarcina)
                            .then((r) => {
                              resolve(r);
                            })
                            .catch((err) => {
                              reject(err);
                            });
                        }, 500);
                      } else if (this.state.realocaDupaOprire) {
                        //Cand se opreste si se realoca
                        setTimeout(() => {
                          this.RealocaTaskVerificat(idProiect, idSarcina)
                            .then((r) => {
                              resolve(r);
                            })
                            .catch((err) => {
                              reject(err);
                            });
                        }, 500);
                      } else {
                        //Cand se opreste pur si simplu
                        resolve(response);
                      }
                    })
                    .catch((err) => {
                      reject(err);
                    });
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((error) => {
              reject(error);
              console.log("error", error);
              this.$f7.methods.openNotification("Eroare la modificare");
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  /**
   * @description Realoca taskul verificat
   * @date 08/04/2022
   * @param {*} idSelected id-ul proiectului selectat
   * @param {*} idSarcina id-ul sarcinii selectate
   * @function
   * @memberof App
   */
  RealocaTaskVerificat = (idSelected, idSarcina) => {
    var idSarcinaLocal =
      idSarcina !== undefined ? idSarcina : this.state.idSarcina;

    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            this.state.linkApi +
            "/GetSARCINI_SALARIAT/" +
            idSarcinaLocal
        )
        .then((response1) => {
          let sarcina = response1.data;

          sarcina.dATA_DEALOCARII = moment().format();
          sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT;

          let urlUpdate =
            this.$f7.data.baseURL +
            this.state.linkApi +
            "/PutSARCINI_SALARIAT/" +
            sarcina.iD_SARCINA;

          axios({
            data: sarcina,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: urlUpdate,
          })
            .then((response2) => {
              let url =
                this.$f7.data.baseURL +
                this.state.linkApi +
                "/PostSARCINI_SALARIAT";
              let sarcinaPropusa = {
                iD_PROIECT: sarcina.iD_PROIECT,
                iD_SALARIAT: parseInt(this.state.idPropunere),
                dE_CINE: this.$f7.data.user.iD_SALARIAT,
                datA_ALOCARII: moment().format(),
                dATA_DEALOCARII: null,
                dealoC_CINE: null,
                stare: 2,
              };

              axios({
                data: sarcinaPropusa,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: url,
              })
                .then((response3) => {
                  resolve(response3);
                })
                .catch((error) => {
                  console.log("error", error);
                  this.$f7.methods.openNotification("Eroare la inserare");
                  reject(error);
                });
            })
            .catch((error) => {
              console.log("error", error);
              this.$f7.methods.openNotification("Eroare la actualizare");
              reject(error);
            });
        })
        .catch((error) => {
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la preluare");
          reject(error);
        });
    });
  };

  /**
   * @description Calculeaza timpul total al realizarilor din lista
   * @date 08/04/2022
   * @param {*} listaRealizari lista de realizari de parcurs
   * @memberof App
   * @function
   */
  timpTotalByRealizari = (listaRealizari) => {
    let total = 0;
    listaRealizari.forEach((item, index) => {
      const inceput = moment(item.intrare).valueOf();
      const sfarsit =
        item.iesire === null
          ? moment().valueOf()
          : moment(item.iesire).valueOf();
      total += sfarsit - inceput;
    });

    // Calculam zile, ore, minute si secunde pentru timpul total (suma duratelor tuturor activitatilor de la un task)
    const zileTotal = Math.floor(total / (24 * 60 * 60 * 1000));
    total = total % (24 * 60 * 60 * 1000);
    const oreTotal = Math.floor(total / (60 * 60 * 1000));
    total = total % (60 * 60 * 1000);
    const minuteTotal = Math.floor(total / (60 * 1000));
    total = total % (60 * 1000);
    const secundeTotal = Math.floor(total / 1000);

    const timpTotal = {
      zile: zileTotal,
      ore: oreTotal,
      minute: minuteTotal,
      secunde: secundeTotal,
    };
    return timpTotal;
  };

  /**
   * @description Ia realizarile unui salariat de la un proiect
   * @date 08/04/2022
   * @param {*} idProiect id-ul proiectului selectat
   * @param {*} idSalariat id-ul salariatului selectat
   * @function
   * @memberof App
   */
  realizariByProiectAndSalariat = (idProiect, idSalariat) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/RealizareSarcini/GetREALIZARE_SARCINI_BY_PROIECT_AND_SALARIAT/" +
            idProiect +
            "/" +
            idSalariat
        )
        .then((responseRealizari) => {
          var timpTotal = this.timpTotalByRealizari(responseRealizari.data);
          resolve(timpTotal);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  GetHiddenValues = (IdProiect) => {
    return new Promise((resolve, reject) => {
      var idSelect =
        IdProiect !== undefined
          ? IdProiect
          : this.state.deschideNou && this.state.idProiectVechi !== null
          ? this.state.idProiectVechi
          : IdProiect;

      axios
        .get(
          this.$f7.data.baseURL +
            "/api/RealizareSarcini" +
            "/GetREALIZARE_SARCINI_BY_PROIECT_AND_SALARIAT/" +
            idSelect +
            "/" +
            this.$f7.data.idSalariatFunctii
        )
        .then((response) => {
          var esteMantis = false;
          if (
            this.$f7.data.allProiecte.find((r) => {
              return r.iD_PROIECT === idSelect;
            })
          ) {
            esteMantis =
              this.$f7.data.allProiecte.find((r) => {
                return r.iD_PROIECT === idSelect;
              }).id_tip_proiect === 55;
          }

          var hI = esteMantis
            ? [
                { key: "Id_Realizare", group: "Info" },
                {
                  key: "Operatie",
                  tip: "selectbox",
                  lista: this.$f7.data.Operatiuni,
                  required: true,
                  defaultValue: 0,
                  group: "Info",
                },
                {
                  key: "Stadiu",
                  tip: "selectbox",
                  lista: this.$f7.data.Stadiu,
                  required: true,
                  defaultValue: 0,
                  group: "Info",
                },
                {
                  key: "Comentariu",
                  required: true,
                  defaultValue: "",
                  tip: "textarea",
                  group: "Info",
                },
                {
                  key: "Observatie",
                  required: false,
                  defaultValue: "",
                  tip: "textarea",
                  group: "Info",
                },
                {
                  key: "ComentariuPv",
                  required: false,
                  defaultValue: "",
                  tip: "textarea",
                  group: "Info",
                },
                {
                  key: "Atasamente",
                  tip: "selectbox",
                  lista: [
                    { key: "0", value: 0 },
                    { key: "1", value: 1 },
                    { key: "2", value: 2 },
                    { key: "3", value: 3 },
                    { key: "4", value: 4 },
                    { key: "5", value: 5 },
                    { key: "6", value: 6 },
                    { key: "7", value: 7 },
                    { key: "8", value: 8 },
                    { key: "9", value: 9 },
                    { key: "10", value: 10 },
                    { key: "11", value: 11 },
                    { key: "12", value: 12 },
                    { key: "13", value: 13 },
                    { key: "14", value: 14 },
                    { key: "15", value: 15 },
                    { key: "16", value: 16 },
                    { key: "17", value: 17 },
                    { key: "18", value: 18 },
                    { key: "19", value: 19 },
                    { key: "20", value: 20 },
                  ],
                  defaultValue: 0,
                  group: "Atasamentele",
                },
              ]
            : [
                { key: "Id_Realizare", group: "Info" },
                {
                  key: "Operatie",
                  tip: "selectbox",
                  lista: this.$f7.data.Operatiuni,
                  required: true,
                  defaultValue: 0,
                  group: "Info",
                },
                {
                  key: "Stadiu",
                  tip: "selectbox",
                  lista: this.$f7.data.Stadiu,
                  required: true,
                  defaultValue: 0,
                  group: "Info2",
                },
                {
                  key: "Comentariu",
                  required: true,
                  defaultValue: "",
                  tip: "textarea",
                  group: "Info2",
                },
                {
                  key: "Observatie",
                  required: false,
                  defaultValue: "",
                  tip: "textarea",
                  group: "Info2",
                },
                {
                  key: "ComentariuPv",
                  required: false,
                  defaultValue: "",
                  tip: "textarea",
                  group: "Info2",
                },
              ];

          let date = response;
          date.data.map((obj) => {
            obj["Id_Realizare"] = obj.iD_REALIZARE;
            obj["MomentInceput"] = obj.intrare;
            obj["MomentSfarsit"] = obj.iesire;
            obj["NumeSalariat"] = obj.iD_SALARIAT;
            obj["Observatie"] = obj.comentariU1;
            obj["Client"] = obj.iD_CLIENT;
            obj["Proiect"] = obj.iD_PROIECT;
            obj["Stadiu"] = this.$f7.data.allProiecte.find((x) => {
              return x.iD_PROIECT === idSelect;
            }).iD_STADIU;
            obj["Operatie"] = this.$f7.data.allProiecte.find((x) => {
              return x.iD_PROIECT === idSelect;
            }).id_operatiuni;
            obj["ComentariuPv"] = obj.comentariu_pv;
            return null;
          });
          var proj = this.$f7.data.allProiecte.find((x) => {
            return x.iD_PROIECT === idSelect;
          });
          var stadiu = proj.iD_STADIU;
          var operatie = proj.id_operatiuni;
          var newInputs = [
            { key: "Id_Realizare" },
            {
              key: "NumeSalariat",
              tip: "selectbox",
              lista: this.$f7.data.Salariati,
              listaEdit: this.Salariati,
            },
            {
              key: "Proiect",
              keyBaza: "iD_PROIECT",
              tip: "selectbox",
              lista: this.$f7.data.Proiecte,
            },
            { key: "MomentInceput", tip: "datebox" },
            { key: "MomentSfarsit", tip: "datebox" },
            { key: "Unitati", tip: "number" },
            {
              key: "Operatie",
              tip: "selectbox",
              lista: this.$f7.data.Operatiuni,
              required: true,
              defaultValue: null,
            },
            { key: "Client", tip: "selectbox", lista: this.$f7.data.Firme },
            {
              key: "Stadiu",
              tip: "selectbox",
              lista: this.$f7.data.Stadiu,
              required: true,
              defaultValue: null,
            },
            {
              key: "Comentariu",
              required: false,
              defaultValue: "",
              tip: "textarea",
            },
            {
              key: "Observatie",
              required: false,
              defaultValue: "",
              tip: "textarea",
            },
            {
              key: "ComentariuPv",
              required: false,
              defaultValue: "",
              tip: "textarea",
            },
          ];
          hI[1].defaultValue = operatie;
          hI[2].defaultValue = stadiu;
          var baza = this.$f7.methods.reconstructieBaza(date, newInputs);
          var elemBaza;
          var ind;
          baza.forEach((r, index) => {
            if (elemBaza === undefined) {
              elemBaza = r;
              ind = index;
            }
            if (elemBaza.Id_Realizare < r.Id_Realizare) {
              elemBaza = r;
              ind = index;
            }
          });
          newInputs[0].defaultValue =
            elemBaza !== undefined ? elemBaza.Id_Realizare : null;
          var sfarsit = this.state.sfarsit;
          //TESTE
          console.log("sfarsit", sfarsit, this.state.inchideDupaOprire);
          if (sfarsit) {
            if (!this.state.inchideDupaOprire) {
              this.$f7.preloader.show();
              var ob = elemBaza;
              var id = parseInt(ob["Id_Realizare"]);
              ob.iesire = moment().format();

              if (this.state.deschideNou) {
                ob.Comentariu =
                  "Task oprit de pornirea taskului '" +
                  this.$f7.data.Proiecte.find((item) => item.value === idSelect)
                    .key +
                  "'";
                this.UpdateValueHidden(ob, id)
                  .then((responseValueHidden) => {
                    this.$f7.preloader.hide();
                    resolve(responseValueHidden);
                  })
                  .catch((errorValueHidden) => {
                    reject(errorValueHidden);
                  });
              } else if (this.state.realocaDupaOprire) {
                ob.Comentariu =
                  "Task transferat la " +
                  this.Salariati.find(
                    (item) => item.value === this.state.idPropunere
                  ).key;
                this.UpdateValueHidden(ob, id)
                  .then((responseValueHidden) => {
                    this.$f7.preloader.hide();
                    resolve(responseValueHidden);
                  })
                  .catch((errorValueHidden) => {
                    reject(errorValueHidden);
                  });
              } else {
                //obj.Comentariu = "Oprit voluntar";
                baza[ind].Comentariu = "Oprit voluntar";
                this.$f7.preloader.hide();
                this.$f7.data.router.navigate("/popupAddEdit/", {
                  props: {
                    onCloseClicked: (state) => {
                      resolve(state);
                    },
                    inputs: hI,
                    ignoreInputs: ["Stadiu", "Atasamente"],
                    id: "Id_Realizare",
                    dataSource: baza,
                    titluDenumire: this.$f7.data.Proiecte.find((r) => {
                      return r.value === elemBaza.Proiect;
                    }).key,
                    editIndex: ind,
                    buttons: [
                      {
                        key: "Salveaza",
                        onSubmit: (state, den) => {
                          var obj = { ...state.editedItem };
                          var id = parseInt(obj["Id_Realizare"]);
                          obj.iesire = moment().format();
                          obj.comentariU1 = obj["Observatie"];
                          obj.comentariu_pv = obj["ComentariuPv"];
                          this.$f7.preloader.show();
                          //this.$f7.popup.close(den)
                          this.$f7.panel.close(den);
                          this.UpdateValueHidden(obj, id)
                            .then((responseValueHidden) => {
                              this.$f7.preloader.hide();
                              resolve(responseValueHidden);
                            })
                            .catch((errorValueHidden) => {
                              reject(errorValueHidden);
                            });
                        },
                      },
                    ],
                  },
                });
              }
            } else {
              this.realizariByProiectAndSalariat(
                idSelect,
                this.$f7.data.idSalariatFunctii
              )
                .then((timpTotal) => {
                  var timpLucratTotal =
                    timpTotal.zile +
                    "z " +
                    timpTotal.ore +
                    "h " +
                    timpTotal.minute +
                    " mi " +
                    timpTotal.secunde +
                    " s ";
                  this.$f7.preloader.hide();

                  let flagAfisareComentarii = false;
                  this.$f7.data.router.navigate("/popupAddEdit/", {
                    props: {
                      onSelectionChanged: (state) => {
                        var denumire = state.denumire;
                        if (denumire === "Atasamente") {
                          var nrAtasamenteNoi = state.editedItem.Atasamente;
                          var nrAtasamenteExistente =
                            hI.filter((r) => {
                              return r.group === "Atasamentele";
                            }).length - 1;
                          if (nrAtasamenteNoi > nrAtasamenteExistente) {
                            for (
                              var ii = nrAtasamenteExistente;
                              ii < nrAtasamenteNoi;
                              ii++
                            ) {
                              hI.push({
                                key: "Atasament" + ii,
                                keyBaza: "atasament" + ii,
                                tip: "filepicker",
                                required: true,
                                group: "Atasamentele",
                              });
                            }
                          } else if (nrAtasamenteNoi < nrAtasamenteExistente) {
                            for (
                              var j = nrAtasamenteNoi;
                              j < nrAtasamenteExistente;
                              j++
                            ) {
                              hI.pop();
                            }
                          }
                        }
                      },
                      onCloseClicked: (state) => {
                        resolve(state);
                      },
                      inputs: hI,
                      id: "Id_Realizare",
                      ignoreInputs: ["Stadiu"],
                      dataSource: baza,
                      titluDenumire:
                        this.$f7.data.Proiecte.find((r) => {
                          return r.value === elemBaza.Proiect;
                        }).key +
                        " | " +
                        timpLucratTotal,
                      editIndex: ind,
                      buttons: [
                        {
                          key: "Salveaza",
                          onSubmit: (state, den) => {
                            var obj = { ...state.editedItem };
                            var id = parseInt(obj["Id_Realizare"]);
                            obj.iesire = moment().format();
                            obj.comentariU1 = obj["Observatie"];
                            obj.comentariu_pv = obj["ComentariuPv"];
                            this.$f7.preloader.show();
                            //this.$f7.popup.close(den)
                            this.$f7.panel.close(den);
                            this.UpdateValueHidden(obj, id)
                              .then((responseValueHidden) => {
                                this.$f7.preloader.hide();
                                if (state.editedItem.Atasamente !== undefined) {
                                  if (state.editedItem.Atasamente > 0) {
                                    var arrAtasamente = [];
                                    for (
                                      var i = 0;
                                      i < state.editedItem.Atasamente;
                                      i++
                                    ) {
                                      var atasamentCurent =
                                        state.editedItem["Atasament" + i];
                                      arrAtasamente.push(atasamentCurent);
                                    }
                                    arrAtasamente
                                      .reduce((promise, file) => {
                                        return promise.then(() => {
                                          return this.postMantisFile2(
                                            file,
                                            idSelect
                                          )
                                            .then((r) => {})
                                            .catch((err) => {
                                              console.log("err", err);
                                            });
                                        });
                                      }, Promise.resolve())
                                      .then(() => {
                                        resolve(responseValueHidden);
                                      })
                                      .catch((err) => {
                                        reject(err);
                                      });
                                  } else {
                                    resolve(responseValueHidden);
                                  }
                                } else {
                                  resolve(responseValueHidden);
                                }
                              })
                              .catch((errorValueHidden) => {
                                reject(errorValueHidden);
                              });
                          },
                        },
                        {
                          key: "Afiseaza comentariile",
                          noInputsRequired: true,
                          onSubmit: (state, den, context) => {
                            if (!flagAfisareComentarii) {
                              let comList = state.dataSource.map(
                                (item, index) => {
                                  return {
                                    data: item.MomentSfarsit,
                                    text: item.Comentariu + "\n",
                                  };
                                }
                              );
                              let text = "";
                              comList.forEach((item) => {
                                if (item.text !== "\n")
                                  text +=
                                    moment(item.data).format(
                                      "DD/MM/YYYY HH:mm"
                                    ) +
                                    "\n  -  " +
                                    item.text +
                                    "\n";
                              });

                              let eI = { ...context.state.editedItem };

                              if (
                                eI["ComentariuPv"] === "" ||
                                eI["ComentariuPv"] === "\n" ||
                                eI["ComentariuPv"] === null
                              ) {
                                eI["ComentariuPv"] = text;
                              } else {
                                eI["ComentariuPv"] += "\n\n" + text;
                              }

                              context.setState(
                                {
                                  editedItem: eI,
                                },
                                () => {
                                  flagAfisareComentarii = true;
                                  this.$f7.methods.openNotification(
                                    "Toate comentariile au fost afisate in campul 'Comentariu'. Le puteti sterge, edita sau copia in alte campuri.",
                                    undefined,
                                    "succes"
                                  );
                                }
                              );
                            } else {
                              this.$f7.methods.openNotification(
                                "Comentariile au fost deja afisate in campul 'ComentariuPv'.",
                                undefined,
                                "eroare"
                              );
                            }
                          },
                        },
                      ],
                    },
                  });
                })
                .catch((err) => {
                  this.$f7.methods.openNotification("Eroare:", err);
                });
            }

            this.setState({ sfarsit: false });
          } else if (this.state.comInchidereTask) {
            this.realizariByProiectAndSalariat(
              idSelect,
              this.$f7.data.idSalariatFunctii
            )
              .then((timpTotal) => {
                var timpLucratTotal =
                  timpTotal.zile +
                  "z " +
                  timpTotal.ore +
                  "h " +
                  timpTotal.minute +
                  "m " +
                  timpTotal.secunde +
                  " s ";
                this.$f7.preloader.hide();

                if (baza.length > 0) {
                  let flagAfisareComentarii = false;

                  this.$f7.data.router.navigate("/popupAddEdit/", {
                    props: {
                      onCloseClicked: (state) => {
                        resolve(state);
                      },
                      inputs: hI,
                      id: "Id_Realizare",
                      ignoreInputs: ["Stadiu"],
                      dataSource: baza,
                      titluDenumire:
                        this.$f7.data.Proiecte.find((r) => {
                          return r.value === elemBaza.Proiect;
                        }).key +
                        " | " +
                        timpLucratTotal,
                      editIndex: ind,
                      buttons: [
                        {
                          key: "Salveaza",
                          onSubmit: (state, den) => {
                            var obj = { ...state.editedItem };
                            var id = parseInt(obj["Id_Realizare"]);
                            //obj.iesire = moment().format();
                            obj.comentariU1 = obj["Observatie"];
                            this.$f7.preloader.show();
                            //this.$f7.popup.close(den)
                            this.$f7.panel.close(den);
                            this.UpdateValueHidden(obj, id)
                              .then((responseValueHidden) => {
                                this.$f7.preloader.hide();
                                if (state.editedItem.Atasamente !== undefined) {
                                  if (state.editedItem.Atasamente > 0) {
                                    var arrAtasamente = [];
                                    for (
                                      var i = 0;
                                      i < state.editedItem.Atasamente;
                                      i++
                                    ) {
                                      var atasamentCurent =
                                        state.editedItem["Atasament" + i];
                                      arrAtasamente.push(atasamentCurent);
                                    }
                                    arrAtasamente
                                      .reduce((promise, file) => {
                                        return promise.then(() => {
                                          return this.postMantisFile2(
                                            file,
                                            idSelect
                                          )
                                            .then((r) => {})
                                            .catch((err) => {
                                              console.log("err", err);
                                            });
                                        });
                                      }, Promise.resolve())
                                      .then(() => {
                                        resolve(responseValueHidden);
                                      })
                                      .catch((err) => {
                                        reject(err);
                                      });
                                  } else {
                                    resolve(responseValueHidden);
                                  }
                                } else {
                                  resolve(responseValueHidden);
                                }
                              })
                              .catch((errorValueHidden) => {
                                reject(errorValueHidden);
                              });
                          },
                        },
                        {
                          key: "Afiseaza comentariile",
                          noInputsRequired: true,
                          onSubmit: (state, den, context) => {
                            if (!flagAfisareComentarii) {
                              let comList = state.dataSource.map(
                                (item, index) => {
                                  return {
                                    data: item.MomentSfarsit,
                                    text: item.Comentariu + "\n",
                                  };
                                }
                              );
                              let text = "";
                              comList.forEach((item) => {
                                if (item.text !== "\n")
                                  text +=
                                    moment(item.data).format(
                                      "DD/MM/YYYY HH:mm"
                                    ) +
                                    "\n  -  " +
                                    item.text +
                                    "\n";
                              });

                              let eI = { ...context.state.editedItem };

                              if (
                                eI["ComentariuPv"] === "" ||
                                eI["ComentariuPv"] === "\n" ||
                                eI["ComentariuPv"] === null
                              ) {
                                eI["ComentariuPv"] = text;
                              } else {
                                eI["ComentariuPv"] += "\n\n" + text;
                              }

                              context.setState(
                                {
                                  editedItem: eI,
                                },
                                () => {
                                  flagAfisareComentarii = true;
                                  this.$f7.methods.openNotification(
                                    "Toate comentariile au fost afisate in campul 'ComentariuPv'. Le puteti sterge, edita sau copia in alte campuri.",
                                    undefined,
                                    "succes"
                                  );
                                }
                              );
                            } else {
                              this.$f7.methods.openNotification(
                                "Comentariile au fost deja afisate in campul 'ComentariuPv'.",
                                undefined,
                                "eroare"
                              );
                            }
                          },
                        },
                      ],
                      onSelectionChanged: (state) => {
                        var denumire = state.denumire;
                        if (denumire === "Atasamente") {
                          var nrAtasamenteNoi = state.editedItem.Atasamente;
                          var nrAtasamenteExistente =
                            hI.filter((r) => {
                              return r.group === "Atasamentele";
                            }).length - 1;
                          if (nrAtasamenteNoi > nrAtasamenteExistente) {
                            for (
                              var i = nrAtasamenteExistente;
                              i < nrAtasamenteNoi;
                              i++
                            ) {
                              hI.push({
                                key: "Atasament" + i,
                                keyBaza: "atasament" + i,
                                tip: "filepicker",
                                required: true,
                                group: "Atasamentele",
                              });
                            }
                          } else if (nrAtasamenteNoi < nrAtasamenteExistente) {
                            for (
                              var ii = nrAtasamenteNoi;
                              ii < nrAtasamenteExistente;
                              ii++
                            ) {
                              hI.pop();
                            }
                          }
                        }
                      },
                    },
                  });
                } else {
                  this.$f7.dialog.confirm(
                    'La taskul "' +
                      proj.denumire +
                      '" nu exista nici o activitate inregistrata. Doriti sa il inchideti oricum?',
                    "Atentie",
                    () => {
                      resolve();
                    },
                    () => {
                      reject();
                    }
                  );
                }

                this.setState({ comInchidereTask: false });
              })
              .catch((err) => {
                this.$f7.methods.openNotification("Eroare:", err);
              });
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  };

  /**
   * @description Pregateste un fisier pentru a fi incarcat pe mantis
   * @date 08/04/2022
   * @param {*} file fisierul
   * @param {*} idSelect id-ul proiectului
   * @function
   * @memberof App
   */
  postMantisFile2 = (file, idSelect) => {
    return new Promise((resolve, reject) => {
      if (file !== "" && file !== undefined && file !== null) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var res = reader.result;
          var continut = res.split(",")[1];
          var denumire = file.name;
          this.postMantisFile(
            continut,
            this.$f7.data.idSalariatFunctii,
            idSelect,
            denumire
          )
            .then((respMantisFile) => {
              resolve(respMantisFile);
            })
            .catch((error) => {
              reject(error);
            });
        };
        reader.onerror = (error) => {
          reject(error);
        };
      } else {
        resolve(true);
      }
    });
  };

  /**
   * @description Face apel la endpoint sa incarce un fisier pe mantis
   * @date 08/04/2022
   * @function
   * @param {*} continut continutul fisierului base64
   * @param {*} idSalariat id-ul salariatului de incarca
   * @param {*} idProiect id-ul proiectului la care se ataseaza fisierul
   * @param {*} denumire denumirea fisierului
   * @memberof App
   */
  postMantisFile = (continut, idSalariat, idProiect, denumire) => {
    return new Promise((resolve, reject) => {
      var url = "/api/SarciniSalariat/PostMantisAttachment";
      axios({
        data: {
          continut: continut,
          idSalariat: idSalariat,
          idProiect: idProiect,
          denumire: denumire,
        },
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Adauga detaliu la o delegatie in cazul in care se inchide un task in timp ce este in delegatie
   * @function
   * @date 08/04/2022
   * @param {*} data infomratiile inregistrariide tip detaliu delegatie(inregistreaza ca s-a realizat taskul in timpul delegatiei)
   * @memberof App
   */
  AddValueDetaliu = (data) => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL + "/api/DetaliuDelegatii/PostDETALIU_DELEGATII";
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          resolve(response);
          this.$f7.methods.openNotification("Adaugat cu success");
        })
        .catch((error) => {
          reject(error);
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la adaugare");
        });
    });
  };

  /**
   * @description Functie de inchidere a unui task, in cazul in care se transmite deschideNou inseamna ca se deschide un task nou si de aceea se inchide curentul task,
   * inchideTaskPropus inseamna ca inchide taskul in cazul in care il propune,
   * inchideDupaOprire inseamna ca il inchide dupa ce opreste realizarea curenta
   * realocaDupaOprire inseamna ca taskul este realocat dupa ce este oprit
   * @date 08/04/2022
   * @function
   * @param {*} deschideNou bool, daca se deschide task nou
   * @param {*} idProiectVechi id-ul vechiului task, in cazul in care se deschide task nou
   * @param {*} inchideTaskPropus daca se inchide taskul propus sau nu
   * @param {*} inchideDupaOprire daca se inchide taskul dupa oprire sau nu
   * @param {*} realocaDupaOprire daca se realoca taskul dupa oprire sau nu
   * @param {int} idProiectDeschisTrimis id-ul taskului deschis
   * @memberof App
   */
  Sfarsit = (
    deschideNou,
    idProiectVechi,
    inchideTaskPropus,
    inchideDupaOprire,
    realocaDupaOprire,
    idProiectDeschisTrimis
  ) => {
    return new Promise((resolve, reject) => {
      var idProiectDeschis =
        idProiectDeschisTrimis !== undefined
          ? idProiectDeschisTrimis
          : undefined;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((p) => {
          var lat = p.coords.latitude;
          var long = p.coords.longitude;
          this.setState({ lat: lat, lng: long });
        });
      }

      if (idProiectDeschis !== undefined) {
        var url =
          this.$f7.data.baseURL + this.state.linkApiRealizari + "/PutSfarsit";

        axios({
          data: {
            IdSalariat: this.$f7.data.idSalariatFunctii,
            InchisDeSalariat:
              this.$f7.data.idSalariatFunctii ===
              this.$f7.data.user.iD_SALARIAT,
          },
          headers: { "Content-Type": "application/json" },
          method: "PUT",
          url: url,
        })
          .then((response) => {
            var ceReturneaza = response.data;
            if (ceReturneaza !== "Nu exista task deschis.") {
              this.setState(
                {
                  proiectDeschis: null,
                  sfarsit: true,
                  deschideNou: deschideNou,
                  idProiectVechi: idProiectVechi,
                  inchideDupaOprire: inchideDupaOprire,
                  realocaDupaOprire: realocaDupaOprire,
                },
                () => {
                  this.VerificareTaskApel(idProiectDeschis, ceReturneaza).then(
                    (tt) => {
                      this.GetHiddenValues(idProiectDeschis)
                        .then(() => {
                          this.$f7.methods
                            .esteDelegatie()
                            .then((delegatieCurenta) => {
                              if (delegatieCurenta !== false) {
                                var idDelegatie = delegatieCurenta.iD_DELEGATIE;

                                this.$f7.methods
                                  .detaliuDelegatieCurentaActivitate(
                                    idDelegatie
                                  )
                                  .then((detaliuDelegatieCurentaActivitate) => {
                                    if (
                                      detaliuDelegatieCurentaActivitate !==
                                      false
                                    ) {
                                      detaliuDelegatieCurentaActivitate.plecare =
                                        moment().format();
                                      detaliuDelegatieCurentaActivitate.plecarE_LNG =
                                        this.state.lng;
                                      detaliuDelegatieCurentaActivitate.plecarE_LAT =
                                        this.state.lat;
                                      this.UpdateValueDetaliu(
                                        detaliuDelegatieCurentaActivitate,
                                        detaliuDelegatieCurentaActivitate.iD_DETALIU_DELEGATIE
                                      )
                                        .then((resp) => {
                                          resolve(resp);
                                        })
                                        .catch((err) => {
                                          reject(err);
                                        });
                                    } else {
                                      resolve(
                                        detaliuDelegatieCurentaActivitate
                                      );
                                    }
                                  })
                                  .catch((errr) => {
                                    reject(errr);
                                  });
                              } else {
                                resolve(response);
                              }
                            });
                        })
                        .catch((e) => {
                          reject(e);
                        });
                    }
                  );
                }
              );
            } else {
              if (!inchideTaskPropus) {
                this.$f7.methods.openNotification(
                  "Nu exista task deschis.",
                  "Eroare"
                );
              }
              resolve(response);
            }
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la actualizare");
            reject(error);
          });
      } else {
        reject("Nu exista task pornit!");
        this.$f7.methods.openNotification("Nu exista task pornit!");
      }
    });
  };

  /**
   * @description Se ia ultima sarcina de la un anume proiect
   * @date 08/04/2022
   * @function
   * @param {*} idProiect
   * @memberof App
   */
  GetUltimaSarcinaPentruProiect = (idProiect) => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL +
        "/api/SarciniSalariat/GetUltimaSarcinaPentruProiect/" +
        idProiect;
      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  /**
   * @description Se adauga o realizare la un anumit proiect pe o anumita sarcina de la acel proiect
   * @function
   * @date 08/04/2022
   * @param {*} idProiect id-ul proiectului
   * @param {*} idSarcina id-ul sarcinii
   * @memberof App
   */
  Inceput = (idProiect, idSarcina) => {
    return new Promise((resolve, reject) => {
      if (idProiect !== null) {
        var url = this.$f7.data.baseURL + "/api/RealizareSarcini/PostInceput";
        var dataCurenta = moment().format();
        var data = {
          Comentariu: "",
          Unitati: 1,
          asumat: true,
          comentariU1: "",
          dupa_lucru: 0,
          iD_CLIENT: null,
          iD_SALARIAT: this.$f7.data.user.iD_SALARIAT,
          id_proiect: idProiect,
          iesire: null,
          intrare: dataCurenta,
          procent_realizare: 0,
          manual: false,
          aprobat: true,
          ultima_activitate: dataCurenta,
        };

        this.$f7.methods
          .esteDelegatie()
          .then((delegatieCurenta) => {
            if (delegatieCurenta !== false) {
              var idDelegatie = delegatieCurenta.iD_DELEGATIE;

              this.$f7.methods
                .detaliuDelegatieCurenta(idDelegatie)
                .then((detaliuDelegatieCurenta) => {
                  if (detaliuDelegatieCurenta !== false) {
                    if (
                      detaliuDelegatieCurenta.sosire !== null &&
                      detaliuDelegatieCurenta.plecare === null
                    ) {
                      var objDetaliuAdd = {
                        iD_DELEGATIE: idDelegatie,
                        sosire: moment().format(),
                        iD_PROIECT: idProiect, //aici
                        iD_FAZA: null,
                        iD_FIRMA: detaliuDelegatieCurenta.iD_FIRMA,
                        plecare: null,
                        preluat: false,
                        iD_TASK: idSarcina, //aici
                        sosirE_LNG: 0,
                        sosirE_LAT: 0,
                        //plecarE_LNG: null,
                        //plecarE_LAT: null,
                        activitate: 1,
                      };

                      axios({
                        data: data,
                        headers: { "Content-Type": "application/json" },
                        method: "POST",
                        url: url,
                      })
                        .then((response) => {
                          var ceReturneaza = response.data;
                          if (ceReturneaza === "S-a adaugat.") {
                            this.AddValueDetaliu(objDetaliuAdd)
                              .then(() => {
                                var numeProiect = this.$f7.data.Proiecte.find(
                                  (p) => {
                                    return p.value === idProiect;
                                  }
                                );
                                var mesaj =
                                  "Inceput task " + numeProiect !== undefined
                                    ? numeProiect.key
                                    : "";
                                this.$f7.methods.openNotification(
                                  mesaj,
                                  "Success"
                                );
                                resolve(response);
                              })
                              .catch((err) => {
                                reject(err);
                              });
                          } else {
                            var idProiect = parseInt(ceReturneaza);
                            this.$f7.preloader.hide();
                            var numeProiect = this.$f7.data.Proiecte.find(
                              (p) => {
                                return p.value === idProiect;
                              }
                            );
                            if (numeProiect === undefined) {
                              numeProiect = "";
                            } else {
                              numeProiect = numeProiect.key;
                            }
                            this.$f7.dialog.confirm(
                              "Exista un task deja deschis la proiectul: " +
                                numeProiect +
                                ", doriti sa il inchideti?",
                              "Atentie",
                              () => {
                                this.$f7.preloader.show();
                                this.Sfarsit(
                                  true,
                                  idProiect,
                                  false,
                                  false,
                                  false
                                )
                                  .then((resp) => {
                                    resolve(resp);
                                  })
                                  .catch((err) => {
                                    reject(err);
                                  });
                              },
                              () => {
                                resolve(response);
                              }
                            );
                          }
                        })
                        .catch((error) => {
                          reject(error);
                          console.log("error", error);
                          this.$f7.methods.openNotification(
                            "Eroare la adaugare"
                          );
                        });
                    } else {
                      resolve(detaliuDelegatieCurenta);
                      this.$f7.methods.openNotification(
                        "Nu puteti deschide taskul inainte de a ajunge la destinatie.",
                        "Atentie"
                      );
                    }
                  } else {
                    this.$f7.methods.openNotification(
                      "Nu puteti deschide taskul deoarece aveti o delegatie nefinalizata.",
                      "Atentie"
                    );

                    reject(detaliuDelegatieCurenta);
                  }
                })
                .catch((error) => {
                  reject(error);
                  console.log("error", error);
                });
            } else {
              axios({
                data: data,
                headers: { "Content-Type": "application/json" },
                method: "POST",
                url: url,
              })
                .then((response) => {
                  var ceReturneaza = response.data;

                  if (ceReturneaza === "S-a adaugat.") {
                    var numeProiect = this.$f7.data.Proiecte.find((p) => {
                      return p.value === idProiect;
                    });

                    var mesaj =
                      "Inceput task " + numeProiect !== undefined
                        ? numeProiect.key
                        : "";
                    this.$f7.methods.openNotification(mesaj, "Success");
                    resolve(response);
                  } else {
                    var idProiectVechi = parseInt(ceReturneaza);
                    this.$f7.preloader.hide();
                    var numeProiect1 = this.$f7.data.Proiecte.find((p) => {
                      return p.value === idProiectVechi;
                    });
                    if (numeProiect1 === undefined) {
                      numeProiect1 = "";
                    } else {
                      numeProiect1 = numeProiect1.key;
                    }
                    this.$f7.dialog.confirm(
                      "Exista un task deja deschis la proiectul: " +
                        numeProiect1 +
                        ", doriti sa il opriti?",
                      "Atentie",
                      () => {
                        this.$f7.preloader.show();
                        this.Sfarsit(true, idProiectVechi, false, false, false)
                          .then((resp) => {
                            resolve(resp);
                          })
                          .catch((err) => {
                            reject(err);
                          });
                      },
                      () => {
                        resolve(response);
                      }
                    );
                  }
                })
                .catch((error) => {
                  reject(error);
                  console.log("error", error);
                  this.$f7.methods.openNotification("Eroare la adaugare");
                });
            }
          })
          .catch((error) => {
            reject(error);
            console.log("error", error);
          });
      } else {
        reject("Selecteaza mai intai un task!");
        this.$f7.methods.openNotification("Selecteaza mai intai un task!");
      }
    });
  };

  /**
   * @description Ascunde sau afiseaza o lista de proiecte
   * @function
   * @date 08/04/2022
   * @param {*} iduriAscundere lista de id-uri de proiecte
   * @param {*} ascunde bool,true daca se ascunde, false daca se afiseaza
   * @memberof App
   */
  ascundeAfiseazaProiect = (iduriAscundere, ascunde) => {
    return new Promise((resolve, reject) => {
      if (iduriAscundere !== undefined && iduriAscundere.length > 0) {
        let url =
          this.$f7.data.baseURL + "/api/TreeListProiecteOption/AscundeArata";
        let data = {
          lista: iduriAscundere,
          idSalariat: this.$f7.data.user.iD_SALARIAT,
          idDestinatie: null,
          actiune: !ascunde,
        };
        axios({
          data: data,
          headers: { "Content-Type": "application/json" },
          method: "post",
          url: url,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log("error ascunde:", error);
            reject(error);
          });
      } else {
        reject("Lista goala de iduri!");
      }
    });
  };

  /**
   * @description Arhiveaza sau dezarhiveaza o lista de proiecte
   * @date 08/04/2022
   * @param {*} iduriArhivare lista de id-uri de proiecte
   * @param {*} arhiveaza bool,true daca se arhiveaza, false daca se dezarhiveaza
   * @function
   * @memberof App
   */
  arhiveazaDezarhiveazaProiect = (iduriArhivare, arhiveaza) => {
    return new Promise((resolve, reject) => {
      if (iduriArhivare !== undefined && iduriArhivare.length > 0) {
        let url =
          this.$f7.data.baseURL +
          "/api/TreeListProiecteOption/ArhiveazaDezarhiveaza";
        let data = {
          lista: iduriArhivare,
          idSalariat: this.$f7.data.user.iD_SALARIAT,
          idDestinatie: null,
          actiune: !arhiveaza,
        };
        axios({
          data: data,
          headers: { "Content-Type": "application/json" },
          method: "post",
          url: url,
        })
          .then((response) => {
            resolve(response); //, iduriArhivare.length);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject("Lista goala de iduri!");
      }
    });
  };

  /**
   * @description Functie de luarea valorii unui cookie
   * @function
   * @date 08/04/2022
   * @param {*} cname numele cookieului de cautat
   * @memberof App
   */
  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  /**
   * @description Functie globala de a lua proiectele in functie de cum sunt filtrate(ascunse,inchise,arhivate,si taskuri)
   * @date 08/04/2022
   * @param {*} idSalariat id-ul salariatului,taskurile pentru un anumit salariat
   * @param {*} tipLista interfata pentru care se iau(inchise,alocate,primite,alocate subalterni,inchise subalterni)
   * @param {*} taskuri bool,daca se doresc si taskuri
   * @param {*} ascunse bool,daca se doresc si cele ascunse
   * @param {*} arhivate bool,daca se doresc si cele arhivate
   * @param {*} inchise bool,daca se doresc si cele inchise
   * @memberof App
   * @function
   */
  getProiecte = (idSalariat, tipLista, taskuri, ascunse, arhivate, inchise) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/Proiecte/GetProiecteFiltrate/" +
            idSalariat +
            "/" +
            tipLista +
            "/" +
            taskuri +
            "/" +
            ascunse +
            "/" +
            arhivate +
            "/" +
            inchise
        )
        .then((r) => {
          resolve(r);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  /**
   * @description Functie globala de a deschide notificari
   * @date 08/04/2022
   * @function
   * @param {*} mesaj mesajul de afisat
   * @param {*} titlu titlul mesajului de afisat
   * @param {*} tip tipul mesajului,daca este de eroare
   * @memberof App
   */
  openNotification = (mesaj, titlu, tip) => {
    if (typeof mesaj === "string") {
      var n = this.$f7.notification.create({
        title: titlu !== undefined ? titlu : "Notificare",
        text: mesaj,
        closeTimeout: 4000,
        closeOnClick: true,
        closeButton: true,
        titleRightText: moment().format("YYYY-MM-DDTHH:mm"),
        cssClass:
          tip !== undefined
            ? tip === "eroare"
              ? "notificationEroare"
              : "notificationSuccess"
            : "notificationSuccess",
      });
      if (not && not.opened) {
        not.on("close", () => {
          not = n;
          not.open?.();
        });
        not?.close?.();
      } else {
        console.log("n2", not);
        not = n;
        not?.open?.();
      }
    }
  };

  /**
   * @description Functie de a deschide un task in viewul din dreapta
   * @date 08/04/2022
   * @param {*} id id-ul proiectului
   * @param {*} viewName numele viewului
   * @function
   * @memberof App
   */
  openMainDreaptaById = (id, viewName) => {
    if (this.$f7.data.routeDreapta !== "/planificareTaskuriMainDreapta/") {
      if (id !== null && id !== undefined) {
        var Proiect = this.$f7.data.allProiecte.filter((pr) => {
          return pr.iD_PROIECT === id;
        })[0];
        this.$f7.data.Proiect = Proiect;
        if (
          this.$f7.data.routeDreapta === null ||
          this.$f7.data.routeDreapta === undefined
        ) {
          this.$f7.data.routeDreapta = "/agendaGridMainDreapta/";
        }
        if (viewName) {
          this.$f7.data.routeDreapta = viewName;
        }
        if (this.$f7.views.main.router.currentRoute.url !== "/") {
          this.$f7.views.main.router.navigate("/");

          setTimeout(() => {
            this.$f7.views.MainDreapta.router.navigate(
              this.$f7.data.routeDreapta,
              {
                ignoreCache: true,
              }
            );
          }, 1000);
        } else {
          this.$f7.views.MainDreapta.router.navigate(
            this.$f7.data.routeDreapta,
            {
              ignoreCache: true,
              reloadCurrent: true,
            }
          );
        }
      }
    }
  };

  /**
   * @description Restructureaza obiectul pe inputurile care le primeste pentru a functiona in datagrid
   * @date 08/04/2022
   * @param {*} o obiectul
   * @param {*} inps inputurile care le are datagridul
   * @param {*} neededKey daca are keye necesara
   * @function
   * @memberof App
   */
  restructurareBaza = (o, inps, neededKey) => {
    var obj = Object.assign(o);
    let inputs = [...inps];

    if (neededKey === "key") {
      inputs.map((inp) => {
        obj[inp.key] = obj[inp.keyBaza];
        delete obj[inp.keyBaza];
        return false;
      });
    } else {
      inputs.map((inp) => {
        obj[inp.keyBaza] = obj[inp.key];
        delete obj[inp.key];
        return false;
      });
    }

    return obj;
  };

  /**
   * @description Functie de pornire a recordingului si detectia ce se vorbeste
   * @date 08/04/2022
   * @param {*} functie functia de apelare la detectarea speechului
   * @function
   * @memberof App
   */
  startSpeech = (functie) => {
    speechText = "";
    SpeechRecognition.startListening({
      language: "ro-RO",
      continuous: true,
    });
    this.started = true;
    var lastWord = "";
    var f = SpeechRecognition.getRecognition();
    if (f) {
      f.onresult = (r) => {
        var resList = r.results;
        speechText = "";

        if (resList) {
          for (var i = 0; i < resList.length; i++) {
            var res = resList[i];
            var res1 = res[0];
            console.log("res", res, res1);
            if (res1) {
              var resultFin = res1["transcript"];
              speechText += resultFin;
              if (res["isFinal"]) {
                lastWord = resultFin;
                console.log("l", lastWord);
              }
            }
          }
        }
        if (functie) {
          functie(speechText, lastWord);
        }
      };
    } else {
      this.$f7.methods.openNotification(
        "Browserul nu suporta speechRecognition."
      );
    }
  };
  /**
   * @description Functia de inchidere a recordingului
   * @function
   * @date 08/04/2022
   * @memberof App
   */
  stopSpeech = () => {
    return new Promise((resolve, reject) => {
      if (this.started) {
        SpeechRecognition.stopListening();
        this.started = false;
        resolve(speechText);
      } else {
        reject("err");
      }
    });
  };

  /**
   * @description Functia de a deschide meniul din stanga
   * @date 08/04/2022
   * @param {*} r contextul aplicatiei
   * @param {*} router routerul aplicatiei
   * @function
   * @memberof App
   */
  openMenu = (r, router) => {
    if (r.$f7.views.sidenav !== undefined) {
      if (r.$f7.panel.get(".panel-left") !== undefined) {
        r.setState({ iconita: "menu" }, () => {
          r.$f7.data.iconita = "menu";
          r.$f7.panel.close("left");
        });
      } else {
        r.setState({ iconita: "close" }, () => {
          r.$f7.data.iconita = "close";
          r.$f7.panel.open("left");
        });
      }
    } else {
      r.setState({ iconita: "close" }, () => {
        r.$f7.data.iconita = "close";
        router.navigate("/sidenav/");
      });
    }
  };

  /**
   * @description Transforma un numar int intr-un hex pentru a fi folosit in stilizare
   * @date 08/04/2022
   * @param {*} num numarul
   * @function
   * @memberof App
   */
  intToHex = (num) => {
    if (num != null) {
      if (num < 0) {
        num = 0xffffffff + num + 1;
      }

      var start = "";
      var aux = num.toString(16);

      if (aux.length < 8) {
        for (var i = 0; i < 8 - aux.length; i++) {
          start += "0";
        }

        aux = start + aux;
      }

      var bgr = aux.substring(2);
      var rgb = bgr.substring(4) + bgr.substring(2, 4) + bgr.substring(0, 2);
      var res = ("#" + rgb).toUpperCase();

      return res;
    } else {
      return null;
    }
  };
  /**
   * @description Functie globala de colorare a randurilor din datagrid in functie de ce fel de inregistrare este, arhivat,ascuns etc
   * @date 08/04/2022
   * @param {*} state state-ul datagridului
   * @param {*} r contextul datagridului
   * @param {*} itemeRandate itemele randate de datagrid
   * @param {*} coloanaSalariat coloana cu numele salariatului
   * @param {*} onInitialised daca se apeleaza doar la initializare sau nu
   * @param {*} saveForPlanificare daca se salveaza pentru planificare
   * @function
   * @memberof App
   */
  colorItems = (
    state,
    r,
    itemeRandate,
    coloanaSalariat,
    onInitialised,
    saveForPlanificare
  ) => {
    var tableName = r.tableName;
    if (tableName) {
      if (itemeRandate.length > 0) {
        var dataSourcePlanificare = [];

        itemeRandate.forEach((elem, index) => {
          var tar = document.getElementById("trMain" + tableName + index);
          if (tar) {
            let itemObj = {};

            itemObj["id"] = elem.IdProiect;
            itemObj["title"] = elem.Denumire;
            itemObj["start"] = new Date(elem.DataCreare);
            itemObj["end"] = new Date(elem.Deadline);

            dataSourcePlanificare.push(itemObj);

            tar.classList.remove("taskDeadlineDepasit");
            tar.classList.remove("taskactiv");
            tar.classList.remove("taskoprit");
            tar.classList.remove("taskinchis");
            tar.classList.remove("taskpropus");
            tar.classList.remove("ascuns");
            tar.classList.remove("arhivat");

            if (elem.NivelProiect > 2) {
              if (
                moment().diff(moment(elem.Deadline), "days") > 1 &&
                elem.UltimaStare === 1
              ) {
                tar.classList.add("taskDeadlineDepasit");
              }
              if (elem.UltimaStare === 2) {
                tar.classList.add("taskactiv");
              } else if (elem.UltimaStare === 3) {
                tar.classList.add("taskoprit");
              } else if (elem.UltimaStare === 6) {
                tar.classList.add("taskinchis");
              } else if (elem.UltimaStare === 44) {
                tar.classList.add("taskpropus");
              }
            }
            if (elem.IsHidden) {
              tar.classList.add("ascuns");
            }
            if (elem.IsArhivat) {
              tar.classList.add("arhivat");
            }
          }
        });

        if (saveForPlanificare) {
          this.$f7.data.dataSourcePlanificare = dataSourcePlanificare;
          //Sa dea refresh in dreapta in cazul in care view-ul are ruta planificarii
          var views = this.$f7.views;
          if (views.MainDreapta) {
            if (views.MainDreapta.router) {
              if (views.MainDreapta.router.currentRoute) {
                if (
                  views.MainDreapta.router.currentRoute.path ===
                  "/planificareTaskuriMainDreapta/"
                ) {
                  views.MainDreapta.router.refreshPage();
                }
              }
            }
          }
        }
      }

      var pro = this.$f7.data.Proiect;
      if (pro && r && onInitialised) {
        pro.Denumire = pro.denumire;
        pro.IdProiect = pro.iD_PROIECT;
        console.log("r", r);
        r.focusItem(pro, "IdProiect");
      }
    }
    this.$f7.methods.colorSalariat(coloanaSalariat);
  };

  /**
   * @description Seteaza culoarea/tema aplicatiei
   * @function
   * @date 08/04/2022
   * @param {*} color culoarea temei
   * @memberof App
   */
  setCustomColor = (color) => {
    if (color !== null && color !== undefined) {
      localStorage.setItem("color", color);
      clearTimeout(this.timeout);
      stylesheet.innerHTML = `:root {
      ${Object.keys(this.$f7.utils.colorThemeCSSProperties(color))
        .map(
          (key) =>
            `${key}: ${this.$f7.utils.colorThemeCSSProperties(color)[key]};`
        )
        .join("\n  ")}
    }`;
    }
  };

  /**
   * @description Reconstruieste datasourceul dupa inputurile dorite
   * @date 08/04/2022
   * @param {*} response datasourceul de restructurat
   * @param {*} inps inputurile
   * @function
   * @memberof App
   */
  reconstructieBaza = (response, inps) => {
    var dataSourceLocal = [];
    var keys = {};
    var dataSourceGet = [...response.data];
    var inputs = [...inps];
    inputs.map((input, index) => {
      for (let i in dataSourceGet[0]) {
        if (input.key.toLowerCase() === i.toLowerCase()) {
          var denumireCorecta = input.key;
          var denumireIncorecta = i;
          keys[denumireCorecta] = denumireIncorecta;
        }
      }
      return false;
    });

    dataSourceGet.map((obj, indexObj) => {
      var newObj = {};
      Object.keys(keys).map((keyeCorecta, indexKeys) => {
        var keyeIncorecta = keys[keyeCorecta];
        newObj[keyeCorecta] = obj[keyeIncorecta];
        return false;
      });
      dataSourceLocal.push(newObj);
      return false;
    });

    return dataSourceLocal;
  };

  /**
   * @description Verifica daca utilizatorul este in delegatie
   * @function
   * @date 08/04/2022
   * @memberof App
   */
  esteDelegatie = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/Delegatii/GetDELEGATIIBySalariat/" +
            this.$f7.data.idSalariatFunctii
        )
        .then((response) => {
          let filtrat = response.data.filter(
            (item) => item.sosirE_FIRMA == null
          );
          if (filtrat.length > 0) return resolve(filtrat[0]);
          else resolve(false);
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  };

  /**
   * @description Ia detaliul delegatiei curente daca utilizatorul este in delegatie
   * @function
   * @date 08/04/2022
   * @param {*} idDelegatie id-ul delegatiei pornite
   * @memberof App
   */
  detaliuDelegatieCurenta = (idDelegatie) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/DetaliuDelegatii/GetDetaliuDelegatieCurenta/" +
            idDelegatie
        )
        .then((response) => {
          let filtrat = response.data.filter((item) => item.plecare == null);

          if (filtrat.length > 0) return resolve(filtrat[0]);
          else resolve(false);
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  };

  /**
   * @description Ia activitatea de la delegatia curenta
   * @date 08/04/2022
   * @param {*} idDelegatie id-ul delegatiei pornite
   * @function
   * @memberof App
   */
  detaliuDelegatieCurentaActivitate = (idDelegatie) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/DetaliuDelegatii/GetDetaliuDelegatieCurentaActivitate/" +
            idDelegatie
        )
        .then((response) => {
          let filtrat = response.data.filter((item) => item.plecare == null);

          if (filtrat.length > 0) return resolve(filtrat[0]);
          else resolve(false);
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  };

  /**
   * @description Ia valorile tuturor nomenclatoarelor din aplicatie si le salveaza in store-ul global $f7.data
   * @function
   * @date 08/04/2022
   * @param {function} callback callback de apelat dupa ce se finalizeaza tot
   * @memberof App
   */
  IaValori = (callback) => {
    var baseURL = this.$f7.data.baseURL + "/api/";
    function reqDepartamente() {
      return axios.get(baseURL + "Departamente");
    }
    function reqProfesii() {
      return axios.get(baseURL + "Profesii");
    }
    function reqCompetente() {
      return axios.get(baseURL + "Competente");
    }
    function reqAngajatori() {
      return axios.get(baseURL + "Angajatori");
    }
    function reqBirouri() {
      return axios.get(baseURL + "Birouri");
    }
    function reqRoluri() {
      return axios.get(baseURL + "TipRol");
    }
    function reqSalariati() {
      return axios.get(baseURL + "Salariati/GetSALARIATI");
    }
    function reqTipDocument() {
      return axios.get(baseURL + "TipDocument");
    }
    function reqStadiu() {
      return axios.get(baseURL + "TipStadiu");
    }
    function reqNivelProiect() {
      return axios.get(baseURL + "TipNivelProiect");
    }
    function reqOperatiuni() {
      return axios.get(baseURL + "TipOperatiuni");
    }
    function reqTipProiect() {
      return axios.get(baseURL + "TipProiect");
    }
    function reqFirme() {
      return axios.get(baseURL + "Firme");
    }
    function reqUtilaje() {
      return axios.get(baseURL + "Utilaje");
    }
    function reqTipEvenimentSalariati() {
      return axios.get(baseURL + "TipEveniment");
    }
    function reqTipFaze() {
      return axios.get(baseURL + "TipFaze");
    }
    function reqPrioritati() {
      return axios.get(baseURL + "Prioritati");
    }
    function reqMeniu() {
      return axios.get(baseURL + "Meniu/GetmeniuOrdonat");
    }
    function reqTipSursa() {
      return axios.get(baseURL + "TipSursa/GetTIP_SURSA");
    }

    axios
      .all([
        reqDepartamente(),
        reqProfesii(),
        reqCompetente(),
        reqAngajatori(),
        reqBirouri(),
        reqRoluri(),
        reqSalariati(),
        reqTipDocument(),
        reqStadiu(),
        reqNivelProiect(),
        reqOperatiuni(),
        reqTipProiect(),
        reqFirme(),
        reqUtilaje(),
        reqTipEvenimentSalariati(),
        reqTipFaze(),
        reqPrioritati(),
        reqMeniu(),
        reqTipSursa(),
      ])
      .then(
        axios.spread(
          (
            responseDepartamente,
            responseProfesii,
            responseCompetente,
            responseAngajatori,
            responseBirouri,
            responseRoluri,
            responseSalariati,
            responseTipDocument,
            responseStadiu,
            responseNivelProiect,
            responseOperatiuni,
            responseTipProiect,
            responseFirme,
            responseUtilaje,
            responseTipEvenimentSalariati,
            responseTipFaze,
            responsePrioritati,
            responseMeniu,
            responseTipSursa
          ) => {
            var dataDepartamente = responseDepartamente.data;
            var dataProfesii = responseProfesii.data;
            var dataCompetente = responseCompetente.data;
            var dataAngajatori = responseAngajatori.data;
            var dataBirouri = responseBirouri.data;
            var dataRoluri = responseRoluri.data;
            var dataSalariati = responseSalariati.data;
            var dataTipDocument = responseTipDocument.data;
            var dataStadiu = responseStadiu.data;
            var dataNivelProiect = responseNivelProiect.data;
            var dataOperatiuni = responseOperatiuni.data;
            var dataTipProiect = responseTipProiect.data;
            var dataFirme = responseFirme.data;
            var dataUtilaje = responseUtilaje.data;
            var dataTipEvenimentSalariati = responseTipEvenimentSalariati.data;
            var dataTipFaze = responseTipFaze.data;
            var dataPrioritati = responsePrioritati.data;
            var dataMeniu = responseMeniu.data;
            var dataTipSursa = responseTipSursa.data;
            var arrDepartamente = [{ key: "Fara", value: null }];
            var arrProfesii = [{ key: "Fara", value: null }];
            var arrCompetente = [{ key: "Fara", value: null }];
            var arrAngajatori = [{ key: "Fara", value: null }];
            var arrBirouri = [{ key: "Fara", value: null }];
            var arrRoluri = [{ key: "Fara", value: null }];
            var arrTipDocument = [{ key: "Fara", value: null }];
            var arrSalariati = [{ key: "Fara", value: null }];
            var arrStadiu = [{ key: "Fara", value: null }];
            var arrNivelProiect = [{ key: "Fara", value: null }];
            var arrOperatiuni = [{ key: "Fara", value: null }];
            var arrTipProiect = [{ key: "Fara", value: null }];
            var arrFirme = [{ key: "Fara", value: null }];
            var arrUtilaje = [{ key: "Fara", value: null }];
            var arrTipEvenimentSalariati = [{ key: "Fara", value: null }];
            var arrMeniu = [{ key: "Fara", value: null }];
            var arrTipSursa = [{ key: "Fara", value: null }];
            var arrTipFaze = [];
            var arrTipFaze2 = [];
            var arrPrioritati = [{ key: "Fara", value: null }];
            dataSalariati.map((objSalariat) => {
              arrSalariati.push({
                key: objSalariat.nume + " " + objSalariat.prenume,
                value: objSalariat.iD_SALARIAT,
              });
              return false;
            });
            dataTipDocument.map((objTipDocument) => {
              arrTipDocument.push({
                key: objTipDocument.denumire,
                value: objTipDocument.iD_TIP_DOCUMENT,
              });
              return false;
            });
            dataDepartamente.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.iD_DEPARTAMENT;
              toAdd.key = data.denumire;
              arrDepartamente.push(toAdd);
              return false;
            });
            dataProfesii.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.iD_PROFESIE;
              toAdd.key = data.denumire;
              arrProfesii.push(toAdd);
              return false;
            });
            dataCompetente.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.iD_COMPETENTA;
              toAdd.key = data.denumire;
              arrCompetente.push(toAdd);
              return false;
            });
            dataAngajatori.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.iD_ANGAJATOR;
              toAdd.key = data.denumire;
              arrAngajatori.push(toAdd);
              return false;
            });
            dataBirouri.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.id_birou;
              toAdd.key = data.denumire;
              arrBirouri.push(toAdd);
              return false;
            });
            dataRoluri.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.iD_ROL;
              toAdd.key = data.denumire;
              arrRoluri.push(toAdd);
              return false;
            });
            dataStadiu.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.iD_STADIU;
              toAdd.key = data.denumire;
              arrStadiu.push(toAdd);
              return false;
            });
            dataNivelProiect.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.iD_NIVEL_PROIECT;
              toAdd.key = data.denumire;
              arrNivelProiect.push(toAdd);
              return false;
            });

            dataOperatiuni.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.iD_OPERATIUNE;
              toAdd.key = data.denumire;
              arrOperatiuni.push(toAdd);
              return false;
            });
            dataTipProiect.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = parseInt(data.iD_TIP_PROIECT);
              toAdd.key = data.denumire;
              arrTipProiect.push(toAdd);
              return false;
            });

            dataFirme.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.iD_FIRMA;
              toAdd.key = data.denumire;
              arrFirme.push(toAdd);
              return false;
            });
            dataUtilaje.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.iD_UTILAJ;
              toAdd.key = data.denumire;
              arrUtilaje.push(toAdd);
              return false;
            });
            dataTipEvenimentSalariati.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.iD_EVENIMENT;
              toAdd.key = data.denumire;
              arrTipEvenimentSalariati.push(toAdd);
              return false;
            });

            dataTipFaze.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.iD_FAZA;
              toAdd.key = data.denumire;
              if (toAdd.key !== "Faze") {
                arrTipFaze.push(toAdd);
              }
              return false;
            });

            dataTipFaze.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.iD_FAZA;
              toAdd.key = data.denumire;
              arrTipFaze2.push(toAdd);
              return false;
            });

            dataPrioritati.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.iD_PRIORITATE;
              toAdd.key = data.denumire;
              arrPrioritati.push(toAdd);
              return false;
            });

            dataMeniu.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.id;
              toAdd.key = data.denumire;
              arrMeniu.push(toAdd);
              return false;
            });
            dataTipSursa.map((data) => {
              var toAdd = { key: "", value: 0 };
              toAdd.value = data.id_tip_sursa;
              toAdd.key = data.denumire;
              arrTipSursa.push(toAdd);
              return false;
            });
            this.$f7.data.Departamente = arrDepartamente;
            this.$f7.data.Birouri = arrBirouri;
            this.$f7.data.Profesii = arrProfesii;
            this.$f7.data.Competente = arrCompetente;
            this.$f7.data.Angajatori = arrAngajatori;
            this.$f7.data.Roluri = arrRoluri;
            this.$f7.data.Salariati = arrSalariati;
            this.$f7.data.TipDocument = arrTipDocument;
            this.$f7.data.Stadiu = arrStadiu;
            this.$f7.data.NivelProiect = arrNivelProiect;
            this.$f7.data.Operatiuni = arrOperatiuni;
            this.$f7.data.TipProiect = arrTipProiect;
            this.$f7.data.Firme = arrFirme;
            this.$f7.data.Utilaje = arrUtilaje;
            this.$f7.data.TipEvenimentSalariati = arrTipEvenimentSalariati;
            this.$f7.data.TipFaze = arrTipFaze;
            this.$f7.data.TipFaze2 = arrTipFaze2;
            this.$f7.data.Prioritati = arrPrioritati;
            this.$f7.data.Meniuri = arrMeniu;
            this.$f7.data.TipSursa = arrTipSursa;
            this.$f7.data.MeniuriRandare = responseMeniu.data;
            if (callback) {
              callback();
            }
          }
        )
      );
  };

  /**
   * @description Face refresh la tokenul de logare
   * @function
   * @date 08/04/2022
   * @memberof App
   */
  RefreshToken = () => {
    return new Promise((resolve, reject) => {
      var url = this.$f7.data.baseURL + "/api/Salariati/Refresh";
      axios({
        data: {
          RefreshToken: this.$f7.data.refreshToken.tokenString,
          AccessToken: this.$f7.data.accessToken,
        },
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  /**
   * @description Verifica daca exista un task activ la salariatul dorit
   * @date 08/04/2022
   * @function
   * @param {*} idSalariat id-ul salariatului
   * @memberof App
   */
  VerificareTaskActivByUser = (idSalariat) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/RealizareSarcini/VerificareTaskActivByUserToataSarcina/" +
            idSalariat
        )
        .then((responseVerificare) => {
          var realizare = responseVerificare.data;
          if (realizare !== "") {
            resolve(true, responseVerificare);
          } else {
            resolve(false);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  /**
   * @description Actualizeaza activitatea de la taskul activ al utilizatorului logat
   * @function
   * @date 08/04/2022
   * @memberof App
   */
  ActualizareActivitate = () => {
    axios
      .get(
        this.$f7.data.baseURL +
          "/api/RealizareSarcini/VerificareTaskActivSiActualizare/" +
          this.$f7.data.user.iD_SALARIAT
      )
      .then((responseVerificare) => {})
      .catch((err) => {
        console.log("err", err);
      });
  };

  /**
   * @description Face refresh la gridul selectat din stanga(gridul este salvat global in data.selectedGridLeft in mountul fiecaruia)
   * @function
   * @date 08/04/2022
   * @memberof App
   */
  refreshStanga = () => {
    if (this.$f7.data.selectedGridLeft) {
      this.$f7.data.selectedGridLeft.GetValues();
    }
  };
  /**
   * @description Functie globala de a colora coloana salariat din datagrid cu culorile(gradientul) specificate in baza de date
   * @function
   * @date 08/04/2022
   * @param {*} denumireColoana denumirea coloanei unde este numele salariatului in datagrid
   * @memberof App
   */
  colorSalariat = (denumireColoana) => {
    var tduri = document.getElementsByClassName(denumireColoana);
    for (var i = 0; i < tduri.length; i++) {
      var elem = tduri[i];
      if (elem) {
        let numeSalariat = elem.innerHTML;
        var salariat = this.$f7.data.Salariati.find((rf) => {
          return rf.key === numeSalariat;
        });
        if (salariat !== undefined) {
          if (
            salariat.gradient_culoare1 !== null &&
            salariat.gradient_culoare1 !== undefined &&
            salariat.gradient_culoare2 !== null &&
            salariat.gradient_culoare2 !== undefined
          ) {
            elem.style.backgroundColor = salariat.gradient_culoare1;
            elem.style.background =
              "linear-gradient(90deg," +
              salariat.gradient_culoare1 +
              "," +
              salariat.gradient_culoare2 +
              ")";
          } else {
            elem.style.backgroundColor = "transparent";
            elem.style.background = "transparent";
          }
        }
      }
    }
  };

  /**
   * @description Ia in local proiectele noi adaugate o data la cateva minute pentru a fi valabile in nomenclatoare
   * @function
   * @date 08/04/2022
   * @memberof App
   */
  actualizareProiecte = () => {
    var biggestId = Math.max.apply(
      Math,
      this.$f7.data.Proiecte.map(function (o) {
        return o.value;
      })
    );
    axios
      .get("/api/Proiecte/GetPROIECTEUnionUserNormalActualizare/" + biggestId)
      .then((responseProiecte) => {
        var dataProiecteArh = responseProiecte.data.arh;
        var dataProiectePrj = responseProiecte.data.prj;
        var dataProiecteAll = dataProiecteArh.concat(dataProiectePrj);

        var arr = this.$f7.data.Proiecte;
        this.$f7.data.arhivateProiecte =
          this.$f7.data.arhivateProiecte.concat(dataProiecteArh);
        this.$f7.data.nearhivateProiecte =
          this.$f7.data.nearhivateProiecte.concat(dataProiectePrj);
        this.$f7.data.allProiecte =
          this.$f7.data.allProiecte.concat(dataProiecteAll);

        if (dataProiecteAll) {
          dataProiecteAll.map((data) => {
            var toAdd = { key: "", value: 0 };
            toAdd.value = data.iD_PROIECT;
            toAdd.key = data.denumire;
            toAdd.departament = data.id_departament;
            arr.push(toAdd);
            return false;
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  /**
   * @description Verifica daca utilizatorul este online sau nu si afiseaza mesaje in cazul in care acesta este offline/deconectat
   * @function
   * @date 08/04/2022
   * @memberof App
   */
  checkConnection = () => {
    axios
      .get("/api/Salariati/CheckConnection")
      .then((response) => {
        this.displayOnline();
      })
      .catch((error) => {
        console.log("error", error);
        this.displayOffline();
      });
  };
  /**
   * @description Functie de afisare a notificarii ca utilizatorul este online
   * @function
   * @date 08/04/2022
   * @memberof App
   */
  displayOnline = () => {
    if (this.online === false && this.$f7) {
      this.online = true;
      this.$f7.methods.openNotification("Sunteti online!");
      if (localStorage.getItem("suneteConexiune") !== "false") {
        this.audioOnline.play();
      }
    }
  };

  /**
   * @description Functie de afisare a notificarii ca utilizatorul este offline
   * @function
   * @date 08/04/2022
   * @memberof App
   */
  displayOffline = () => {
    this.$f7.methods.openNotification("Sunteti offline!", "Offline", "eroare");
    this.online = false;
    if (localStorage.getItem("suneteConexiune") !== "false") {
      this.audioOffline.play();
    }
  };

  /**
   * @description Functie de logare a utilizatorului curent, porneste workerul care face actualizarea taskului o data la 30 secunde
   * @function
   * @date 08/04/2022
   * @param {*} salariat obiect cu salariatul logat
   * @param {*} accessToken tokenul de access ce va fi folosit pentru worker
   * @memberof App
   */
  login = (salariat, accessToken) => {
    this.setState(
      {
        idSalariat: salariat.iD_SALARIAT,
        accessToken,
      },
      () => {
        messageWorker?.postMessage?.({
          idSalariat: this.state.idSalariat,
          accessToken: this.state.accessToken,
          url: window.location.href,
        });
      }
    );
  };

  componentDidMount() {
    setInterval(() => {
      this.checkConnection();
    }, 60000);

    axios.interceptors.request.use(
      (request) => {
        // Edit request config
        return request;
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );

    setInterval(() => {
      if (this.$f7.data.user) {
        this.RefreshToken()
          .then((response) => {
            this.$f7.data.refreshToken = response.data.refreshToken;
            this.$f7.data.accessToken = response.data.accessToken;
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.accessToken;
            this.$f7.methods.refreshStanga();
          })
          .catch((err) => {
            delete axios.defaults.headers.common["Authorization"];
            this.$f7.views.main.router.navigate("/login/");
            this.$f7.data.signalRConnection.stop();
            var data = {
              baseURL: "",
              iconita: "menu",
              user: undefined,
              datagrids: {},
              toggleTotiSalariatii: false,
              timerUltimaActivitate: 5,
              rol: null,
              rolAdevarat: null,
              dataSourcePlanificare: [],
            };
            this.$f7.data = data;

            let dataActivare = moment();
            let dataDezactivare = moment();
            dataActivare.set({ hour: 6, minute: 0, second: 0 });
            dataDezactivare.set({ hour: 19, minute: 59, second: 59 });

            if (
              moment().isSameOrAfter(dataActivare) &&
              moment().isSameOrBefore(dataDezactivare)
            ) {
              this.audio.play();
            }
          });
      }
    }, 43200000);

    setInterval(() => {
      if (this.$f7.data.user) {
        this.actualizareProiecte();
      }
    }, 60000);

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        console.log("error", error, error.response);
        if (error.message === "Network Error" && this.reloadCalled !== true) {
          this.online = false;
          if (localStorage.getItem("suneteConexiune") !== "false") {
            this.audioOffline.play();
          }
          this.$f7.methods.openNotification(
            "Eroare conexiune sau sunteti offline!",
            "Offline",
            "eroare"
          );
          // this.reloadCalled = true;
          // window.location.reload();
        }
        const originalRequest = error.config;
        if (
          error &&
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry
        ) {
          if (this.$f7.views.main.router.currentRoute.path !== "/login/") {
            originalRequest._retry = true;
            this.RefreshToken()
              .then((response) => {
                this.$f7.data.refreshToken = response.data.refreshToken;
                this.$f7.data.accessToken = response.data.accessToken;
                axios.defaults.headers.common["Authorization"] =
                  "Bearer " + response.data.accessToken;
                return axios(originalRequest);
              })
              .catch((err) => {
                this.$f7.methods.setCookie(
                  "user",
                  this.$f7.data.userLogareAutomata,
                  90
                );
                this.$f7.methods.setCookie(
                  "parola",
                  this.$f7.data.parolaLogareAutomata,
                  90
                );
                delete axios.defaults.headers.common["Authorization"];
                this.$f7.views.main.router.navigate("/login/");
                this.$f7.data.signalRConnection.stop();
                var data = {
                  baseURL: "",
                  iconita: "menu",
                  user: undefined,
                  datagrids: {},
                  toggleTotiSalariatii: false,
                  timerUltimaActivitate: 5,
                  rol: null,
                  rolAdevarat: null,
                  dataSourcePlanificare: [],
                };
                this.$f7.data = data;
              });
          }
        }
        return Promise.reject(error);
      }
    );

    // actualizeaza data de iesire (delogare/inchidere browser/parasire aplictaie) in local storage la fiecare 20 de secunde atat timp cat aplicatia e pornita
    // data de iesire din local storage e folosita la urmatorul login pentru a actualiza data de iesire in Sesiuni si data de iesire in realizare_sarcini (pentru taskul lasat pornit)
    if (this.$f7.data.user !== undefined) {
      localStorage.setItem("dataIesire", moment().format());
    }
    setInterval(() => {
      if (this.$f7.data.user !== undefined) {
        localStorage.setItem("dataIesire", moment().format());
      }
    }, 20000); // 60000
    // end

    if (!stylesheet) {
      stylesheet = document.createElement("style");
      document.head.appendChild(stylesheet);
    }
    setTimeout(() => {
      this.setState({ showSplash: false }, () => {
        if (localStorage.getItem("color") !== undefined) {
          this.setCustomColor(localStorage.getItem("color"));
        }
        this.$f7.views.main.on("routeChange", (e, f, g) => {
          this.$f7.data.router = this.$f7.views.main.router;
        });
      });
    }, config.durataSplash);
  }

  /**
   * @description Seteaza rutele care sunt active
   * @function
   * @date 08/04/2022
   * @param {*} arrRuteAlive array rute care sunt active
   * @memberof App
   */
  setRoutesAlive = (arrRuteAlive) => {
    var ruteNoi = [];
    for (let ruta in routes) {
      var exista = arrRuteAlive.find((r) => {
        return r.path === routes[ruta].path || routes[ruta].path === "/";
      });
      routes[ruta].keepAlive = exista ? true : false;
      ruteNoi.push(routes[ruta]);
    }
    var ruteNoi2 = [];
    ruteNoi.map((r) => {
      ruteNoi2.push({ path: r.path, keepAlive: r.keepAlive });
      return null;
    });
    this.setState({ routes: ruteNoi }, () => {
      localStorage.setItem("ruteAlive", JSON.stringify(ruteNoi2));
    });
  };

  /**
   * @description Actualizeaza continutul taburilor de navigare din bara de sus
   * @function
   * @date 08/04/2022
   * @param {*} e
   * @memberof App
   */
  actualizareTopBar = (e) => {
    this.$f7.methods
      .stopSpeech()
      .then((r) => {})
      .catch((err) => {});
    var page = e.route.route;
    var name = e.route.name;
    var showAppBar = e.route.route.showAppBar;
    var showBack = e.route.route.showBack;
    var showTopRight = e.route.route.showTopRight;
    var breadCrumbs = e.route.route.breadcrumbs;
    var verificare = name !== "sidenav" && e.route.name !== "sidenav";
    if (this.$f7.data.topTabs) {
      this.$f7.data.topTabs.addTab(page);
    }
    if (this.refTopBar) {
      this.refTopBar.current.setState(
        {
          showAppBar: showAppBar,
          showBack: showBack,
          showTopRight: showTopRight,
          breadCrumbs: verificare ? breadCrumbs : this.state.breadCrumbs,
          iconita: e.route.name === "sidenav" ? "close" : "menu",
          router: e.router,
        },
        () => {
          this.$f7.data.currentPage = page;
          if (this.$f7.views.MainDreapta !== undefined) {
            if (this.$f7.views.MainDreapta.eventsListeners.routeChange) {
              if (
                this.$f7.views.MainDreapta.eventsListeners.routeChange
                  .length === 0
              ) {
                this.$f7.views.MainDreapta.on("routeChange", (r, l, m) => {
                  this.$f7.data.routerDreapta = m;
                });
              }
            } else {
              this.$f7.views.MainDreapta.on("routeChange", (r, l, m) => {
                this.$f7.data.routerDreapta = m;
              });
            }
          }
        }
      );
    }
  };

  render() {
    const f7params = {
      data: {
        baseURL: "",
        iconita: "menu",
        user: undefined,
        datagrids: {},
        toggleTotiSalariatii: false,
        timerUltimaActivitate: 5,
        rol: null,
        rolAdevarat: null,
      },
      methods: {
        login: this.login,
        getLatLng: this.getLatLng,
        logout: this.LogOut,
        openMesaje: this.openMesaje,
        startSpeech: this.startSpeech,
        stopSpeech: this.stopSpeech,
        maxDepth: this.maxDepth,
        Inceput: this.Inceput,
        Sfarsit: this.Sfarsit,
        VerificareTaskActivByUser: this.VerificareTaskActivByUser,
        colorItems: this.colorItems,
        preCall: this.preCall,
        ascundeAfiseazaProiect: this.ascundeAfiseazaProiect,
        arhiveazaDezarhiveazaProiect: this.arhiveazaDezarhiveazaProiect,
        openNotification: this.openNotification,
        colorSalariat: this.colorSalariat,
        refreshStanga: this.refreshStanga,
        getProiecte: this.getProiecte,
        getCookie: this.getCookie,
        setCookie: this.setCookie,
        openMenu: this.openMenu,
        openMainDreaptaById: this.openMainDreaptaById,
        IaValori: this.IaValori,
        restructurareBaza: this.restructurareBaza,
        reconstructieBaza: this.reconstructieBaza,
        setCustomColor: this.setCustomColor,
        setRoutesAlive: this.setRoutesAlive,
        setTransition: (transitionu) => {
          var ruteNoi = [];
          for (let ruta in routes) {
            routes[ruta].options = { transition: transitionu };
            ruteNoi.push(routes[ruta]);
          }

          this.setState({ transition: transitionu, routes: ruteNoi });
        },
        nightMode: () => {
          this.setState({ themeDark: !this.state.themeDark }, () => {
            localStorage.setItem("theme", this.state.themeDark);
            if (this.$f7.data.homePage !== null) {
              this.$f7.data.homePage.setState({
                url:
                  localStorage.getItem("theme") === "true"
                    ? "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
                    : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                attribution:
                  localStorage.getItem("theme") === "true"
                    ? '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                    : '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
              });
            }
          });
        },
        intToHex: this.intToHex,
        hexToInt: this.hexToInt,
        esteDelegatie: this.esteDelegatie,
        detaliuDelegatieCurenta: this.detaliuDelegatieCurenta,
        detaliuDelegatieCurentaActivitate:
          this.detaliuDelegatieCurentaActivitate,
      },
      statusbar: {
        androidBackgroundColor:
          localStorage.getItem("color") !== null
            ? localStorage.getItem("color")
            : config.color,
        iosBackgroundColor:
          localStorage.getItem("color") !== null
            ? localStorage.getItem("color")
            : config.color,
      },
      id: config.appId,
      name: config.name,
      version: config.version,
      theme: config.theme,
      touch: {
        disableContextMenu: true,
        mdTouchRipple: false,
      },
      language: "ro",
    };

    return (
      <AppRoot
        className={"AppRoot"}
        params={f7params}
        routes={this.state.routes}
        themeDark={this.state.themeDark}>
        <TopBar ref={this.refTopBar} />
        <View
          on={{
            pageBeforeIn: (e) => {
              this.actualizareTopBar(e);
            },
          }}
          transition={this.state.transition}
          mdSwipeBack={false}
          id="main"
          name="main"
          url={"/login/"}
          main
        />
        <Panel className={"panelLegend"} resizable right cover backdrop>
          <View>
            <Page>
              <BlockTitle medium>Legenda</BlockTitle>
              <Block strong>
                {legend.map((l, index) => (
                  <Chip
                    key={index}
                    style={{ width: "100%" }}
                    className={l.className}>
                    {l.title}
                  </Chip>
                ))}
              </Block>
            </Page>
          </View>
        </Panel>

        <Panel className={"panelLegendProiecte"} resizable right cover backdrop>
          <View>
            <Page>
              <BlockTitle medium>Legenda</BlockTitle>
              <Block strong>
                {legendProiecte.map((l, index) => (
                  <Chip
                    key={index}
                    style={{ width: "100%" }}
                    className={l.className}>
                    {l.title}
                  </Chip>
                ))}
              </Block>
            </Page>
          </View>
        </Panel>
      </AppRoot>
    );
  }
}
export default App;
