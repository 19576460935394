import React from "react";
import { List, ListItem, Button } from "framework7-react";
import axios from 'axios';

/**
 * @description Comaseaza mai multe taskuri intr-unul singur
 * @date 06/04/2022
 * @class CTMain
 * @extends {React.Component}
 */
class CTMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            denumire: "CTMain",
            proiecteFiltrate: [],
            listaFiltrata: []
        };
    }

    componentDidMount = () => {
        let proiecteFiltrate = this.props.listaProiecte.filter(item => this.props.listaSurse.includes(item.iD_PROIECT));
        let listaFiltrata = [];

        for (let item of proiecteFiltrate) {
            listaFiltrata.push(item.iD_PROIECT);
        }

        this.setState({
            proiecteFiltrate: proiecteFiltrate,
            listaFiltrata: listaFiltrata
        });
    }

    /**
     * @description Trimite la endpoint o lista de taskuri si un id destinatie(id-ul in care vor fi combinate taskurile)
     * @function
     * @date 06/04/2022
     * @memberof CTMain
     */
    comasareOnClick = () => {
        let url = this.$f7.data.baseURL + "/api/TreeListProiecteOption/Comasare";

        let data = {
            lista: this.state.listaFiltrata,
            idSalariat: this.$f7.data.user.iD_SALARIAT,
            idDestinatie: this.props.idDestinatie,
            actiune: null
        }

        axios({
            data: data,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: url,
        }).then((response) => {
            let popupul = this.$f7.popup.get(".PopupDinamic");
            this.$f7.methods.openNotification("Comasare finalizata!", "Cronos");
            popupul.close(true);
        }).catch((error) => {
            console.log("error comasare", error);
            this.$f7.methods.openNotification("Eroare la comasare");
        });
    }

    render() {
        return (
            <div
                style={{
                    maxHeight: 'calc(100vh - 74px)',
                    overflowY: 'auto'
                }}
            >
                <List style={{ margin: 8 }}>
                    {this.state.proiecteFiltrate.map((item, index) => (
                        <ListItem
                            key={item.iD_PROIECT + "-" + index}
                            onChange={(e) => {
                                let checked = e.target.checked;
                                let listaFiltrata = [...this.state.listaFiltrata];

                                if (checked) {
                                    listaFiltrata.push(item.iD_PROIECT);
                                } else {
                                    listaFiltrata = listaFiltrata.filter(id => id !== item.iD_PROIECT);
                                }

                                this.setState({
                                    listaFiltrata: listaFiltrata
                                });
                            }}
                            checked={this.state.listaFiltrata.includes(item.iD_PROIECT)}
                            value={item.iD_PROIECT}
                            title={item.iD_PROIECT + " - " + item.denumire}
                            checkbox
                        ></ListItem>
                    ))}
                </List>
                <Button
                    tooltip={"Comasare taskuri"}
                    fill
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 15
                    }}
                    onClick={this.comasareOnClick}
                >
                    Comasare
                </Button>
            </div>
        );
    }
}
export default CTMain;