import React from "react";
import { Page } from "framework7-react";
import TipuriCheltuieli from "../Componente/TipuriCheltuieli";

class TipuriCheltuieliPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { denumire: "Tipuri cheltuieli" };
    }
    render() {
        return (
            <Page
                className={"themeBackground"}
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
            >
                <div className="ptr3">

                    <TipuriCheltuieli />
                </div>
            </Page>
        );
    }
}
export default TipuriCheltuieliPage;