import React from "react";
import {
	Button,
	Page,
	Navbar,
	Icon,
	Link,
	NavRight,
	List,
	ListItem,
	Segmented,
	NavLeft,
	Searchbar,
	NavTitle,
} from "framework7-react";

import * as echarts from "echarts";
var culoare = "var(--f7-theme-color)";

//var culoareTint = "var(--f7-theme-color-tint)";
class RG extends React.Component {
	constructor(props) {
		super(props);
		this.title = this.props.context.props.name
			.replace("Grid", "")
			.replace(/([A-Z])/g, " $1")
			.trim();
		this.height = window.innerHeight - 150;
		this.width = window.innerWidth / 2 + 60;
		this.graphTypes = [
			{ name: "bar", tip: 0 },
			{ name: "line", tip: 0 },
			{ name: "line", tip: 0, area: true },
			{ name: "line", tip: 0, smooth: true },
			{ name: "line", tip: 0, smooth: true, area: true },
			{ name: "scatter", tip: 0 },
			{ name: "pie", tip: 1 },
			{ name: "sunburst", tip: 1 },
			{ name: "line", tip: 2 },
			{ name: "bar", tip: 2 },
		];
		this.inputs = this.props.context.props.inputs;
		this.inputsColumnX = this.inputs.filter(
			(x) =>
				![this.props.context.props.id, this.props.context.props.refId].includes(
					x.key
				)
		);
		this.inputsColumnY = [
			{ key: "Count", tip: "number" },
			...this.inputsColumnX.filter((x) => x.tip === "number"),
		];
		console.log("inputs:", this.inputsColumnX, this.inputsColumnY);
		this.state = {
			refId: this.props.context.props.initialRefId,
			height: this.height,
			width: this.width,
			graphType: 0,
			renderItems: this.props.renderItems,
			selectedColumn2: this.inputsColumnY[0].key,
			selectedColumn: this.inputsColumnX[0].key,
			dataSource: [],
			dataSourceToRender: [],
			dataSet: 1,
			dataSetParinti: [],
		};
	}

	setChart = () => {
		var currentGraphType = this.graphTypes[this.state.graphType];
		var chartDom = document.getElementById("section2");
		var myChart = echarts.init(chartDom);
		var tip0 = {
			xAxis: {
				type: "category",
				data: this.state.dataSourceToRender.map((x) => x.name),
				axisLabel: { interval: 0, rotate: 30 },
			},
			yAxis: {
				type: "value",
			},
		};
		var tip2 = {
			angleAxis: {},
			polar: {},
			radiusAxis: {
				type: "category",
				data: this.state.dataSourceToRender.map((x) => x.name),
				z: 10,
			},
		};
		var option = {
			title: {
				text: this.title,
				left: "center",
			},
			tooltip: {
				trigger: "item",
			},
			legend: {
				orient: "vertical",
				left: "left",
			},
			toolbox: {
				show: true,
				feature: {
					dataZoom: {
						yAxisIndex: "none",
					},
					saveAsImage: {},
				},
			},
			series: [
				{
					data: this.state.dataSourceToRender,
					type: currentGraphType.name,
					label: {
						show: true,
						position: "top",
					},
				},
			],
		};

		var ops =
			currentGraphType.tip === 0
				? { ...option, ...tip0 }
				: currentGraphType.tip === 2
					? { ...option, ...tip2 }
					: { ...option };
		if (currentGraphType.tip === 2) {
			ops.series[0].coordinateSystem = "polar";
		}
		if (currentGraphType.smooth) {
			ops.series[0].smooth = true;
		}
		if (currentGraphType.area) {
			ops.series[0].areaStyle = {};
		}

		myChart.clear();
		option && myChart.setOption(ops);
		// myChart.on("click", (params) => {
		// 	var data = params.data;
		// 	if (data) {
		// 		this.saveFiltre(data.columnX, data.name, data.comparatie);
		// 	}
		// });
	};

	setDataSource = () => {
		var bazaDate = [...this.props.context.parinteMain.state.bigDataSource];
		var dataSet = this.props.context.state.bigDataSource;
		var dataSetParinti = [];
		var columnX = this.state.selectedColumn;
		var columnY = this.state.selectedColumn2;
		console.log("b", bazaDate, columnX, columnY);
		if (this.state.dataSet !== 1) {
			dataSet =
				this.props.context.parinteMain.returnFilteredDatasource(bazaDate);
		}

		if (
			this.props.context.props.refId !== undefined &&
			this.state.refId !== undefined
		) {
			dataSetParinti.push({
				key: "0) Root",
				value: this.props.context.props.initialRefId,
			});
			console.log("dataSet", dataSet);
			var a = dataSet.filter((x, index) =>
				dataSet.find(
					(x2, index2) =>
						(x[this.props.context.id] ===
							x2[this.props.context.props.refId]) !==
						undefined && x["hasChilds"]
				)
			);
			console.log("a", a);
			var dataSetParinti2 = dataSet
				.filter((x, index) =>
					dataSet.find(
						(x2, index2) =>
							(x[this.props.context.id] ===
								x2[this.props.context.props.refId]) !==
							undefined && x["hasChilds"]
					)
				)
				.map((y) => {
					var key = this.props.context.props.inputs.find(
						(xi) => xi.key === columnX
					)?.lista
						? this.props.context.props.inputs
							.find((xi) => xi.key === columnX)
							?.lista.find((yi) => yi.value === y[columnX]).key
						: y[columnX];
					var nodeLevel = y["nodeLevel"];
					var value = y[this.props.context.props.id];
					return {
						key: nodeLevel + ") " + key,
						value,
					};
				});
			console.log("d", dataSetParinti, dataSetParinti2);
			dataSetParinti = dataSetParinti.concat(dataSetParinti2);
			console.log(
				"t",
				this.props.context.props.refId,
				this.state.refId,
				dataSet
			);
			dataSet = dataSet.filter(
				(x) => x[this.props.context.props.refId] === this.state.refId
			);
		}

		var dataSource = [];
		if (dataSet !== undefined && columnX !== undefined) {
			if (columnY === "Count") {
				//de cate ori apare columnX in dataSet
				dataSet.forEach((r) => {
					var comparatie = "contains";
					var nameVal = r[columnX];
					var name = "";
					var inp = this.props.context.props.inputs.find(
						(f) => f.key === columnX
					);
					if (inp !== undefined) {
						if (inp.tip) {
							if (inp.lista) {
								var k = inp.lista.find((c) => {
									return c.value === nameVal;
								});
								if (k !== undefined) {
									name = k.key;
								} else {
									name = nameVal;
								}
							} else {
								if (inp.tip === "bool") {
									switch (nameVal) {
										case true: {
											name = "True";
											break;
										}
										case false: {
											name = "False";
											break;
										}
										default: {
											name = "Fara";
											break;
										}
									}
								} else {
									name = nameVal;
								}
							}
							switch (inp.tip) {
								case "bool": {
									comparatie = "bool";
									break;
								}
								case "selectbox": {
									comparatie = "arraycontains";
									var f = nameVal === null ? "Fara" : nameVal.toString();
									nameVal = [f];
									break;
								}
								case "number": {
									comparatie = "=";
									break;
								}
								case "datebox": {
									comparatie = "=Date";
									nameVal = new Date(r[columnX]);
									break;
								}
								default: {
									comparatie = "contains";
									break;
								}
							}
						} else {
							name = nameVal;
						}
					} else {
						name = nameVal;
					}
					var val = dataSet.filter((f) => {
						return f[columnX] === r[columnX];
					}).length;
					var rand = Math.random() * 360;
					var colorString = "hsl(" + rand + ", 100%, 60%)";

					var obj = {
						name: name,
						value: val,
						fill: colorString,
						comparatie: comparatie,
						realVal: val,
						columnX,
						columnY,
					};
					if (
						dataSource.find((chec) => {
							return chec.name === name;
						}) === undefined
					) {
						dataSource.push(obj);
					}
					return null;
				});
			} else {
				//filtrez dataSet dupa column X si adun valorile din columnY si dau add la obj respectiv
				// var arrDenumiri = [];
				// console.log("dataS", dataSet, columnX);
				// dataSet.forEach((x) => {
				// 	if (!arrDenumiri.includes(x[columnX])) {
				// 		arrDenumiri.push(x[columnX]);
				// 	}
				// });
				// console.log("arr", arrDenumiri);
				// arrDenumiri.forEach((x) => {
				// 	var name = "";
				// 	var value = 0;
				// 	var arrDenumireCurenta = dataSet.filter((y) => y[columnX] === x);

				// 	arrDenumireCurenta.forEach((y) => {
				// 		var val = y[columnY];
				// 		var nameVal = y[columnX];
				// 		value += val;
				// 		name = nameVal;
				// 		var inp = this.props.context.props.inputs.find(
				// 			(f) => f.key === columnX
				// 		);
				// 		if (inp.tip === "selectbox") {
				// 			name = inp.lista.find((z) => z.value === nameVal).key;
				// 		}
				// 	});

				// 	var rand = Math.random() * 360;
				// 	var colorString = "hsl(" + rand + ", 100%, 60%)";

				// 	var obj = {
				// 		name,
				// 		value,
				// 		columnX,
				// 		columnY,
				// 		realVal: value,
				// 		fill: colorString,
				// 		comparatie: "=",
				// 	};
				// 	dataSource.push(obj);
				// });

				dataSet.forEach((x) => {
					var name = "";
					var value = x[columnY];
					var nameVal = x[columnX];

					name = nameVal;
					var inp = this.props.context.props.inputs.find(
						(f) => f.key === columnX
					);
					if (inp.tip === "selectbox") {
						name = inp.lista.find((z) => z.value === nameVal).key;
					}

					var rand = Math.random() * 360;
					var colorString = "hsl(" + rand + ", 100%, 60%)";
					var obj = {
						name,
						value,
						columnX,
						columnY,
						realVal: value,
						fill: colorString,
						comparatie: "=",
					};
					dataSource.push(obj);
				});
			}
			console.log("dataSource", dataSource);
			this.setState(
				{
					dataSource,
					dataSetParinti,
					dataSourceToRender: dataSource,
				},
				() => {
					this.setChart();
				}
			);
		}
	};

	saveFiltre = (coloana, value, comparatie) => {
		var filtre = {};

		this.props.context.props.inputs.forEach((input) => {
			filtre[input.key] = {
				denumire: input.key,
				value: null,
				comparatie: null,
			};
		});

		filtre[coloana].value = value;
		filtre[coloana].comparatie = comparatie;
		this.props.context.setState({ filtre: filtre, dataSet: 2 }, () => {
			this.props.context.saveFiltre();
		});
	};

	componentDidMount = () => {
		this.setDataSource();
	};

	closeModal = () => {
		var denToClose = "." + this.props.modalName;
		var popupToClose = this.$f7.popup.get(denToClose);
		var panelToClose = this.$f7.panel.get(denToClose);
		var sheetToClose = this.$f7.sheet.get(denToClose);
		popupToClose?.close?.();
		panelToClose?.close?.();
		sheetToClose?.close?.();
	};

	render() {
		return (
			<Page style={{ overflow: "hidden", height: "auto", minHeight: 300 }}>
				<Navbar>
					<NavLeft>
						<>
							<Link
								tooltip={"Reset filtrare"}
								onClick={this.props.context.resetFiltre}
							>
								Reset filtrare
							</Link>
						</>
					</NavLeft>
					<NavTitle>{this.title}</NavTitle>
					<NavRight>
						<Link
							tooltip={"Inchide"}
							onClick={() => {
								if (this.props.modalName !== undefined) {
									this.closeModal();
								}
							}}
						>
							Inchide
						</Link>
					</NavRight>
				</Navbar>
				<>
					<div style={{ display: "flex" }}>
						<List
							style={{
								margin: "0 auto",
								width: "100%",
							}}
						>
							<ListItem
								style={{
									margin: "0 auto",
									width: "100%",
								}}
								className="roundedOutlinedSmartSelect"
								floatingLabel
								required
								label={"Valoarea X"}
								validate
								title={"Valoare X"}
								smartSelect
								smartSelectParams={{
									openIn: "popover",
									closeOnSelect: true,
									on: {
										closed: (event) => {
											var val = event.getValue();

											this.setState(
												{
													selectedColumn: val,
												},
												() => {
													this.setDataSource();
												}
											);
										},
									},
								}}
							>
								<select defaultValue={this.state.selectedColumn} name="Proiect">
									{this.inputsColumnX.map((obj, index) => (
										<option key={"SelectProiectRG" + index} value={obj.key}>
											{obj.key}
										</option>
									))}
								</select>
								<Icon
									slot="content-start"
									style={{
										color: "var(--f7-theme-color)",
										left: 0,
										height: 14,
									}}
									size={20}
									material="list"
								/>
							</ListItem>
						</List>
						<List
							style={{
								margin: "0 auto",
								width: "100%",
							}}
						>
							<ListItem
								style={{
									margin: "0 auto",
									width: "100%",
								}}
								className="roundedOutlinedSmartSelect"
								floatingLabel
								required
								label={"Valoarea Y"}
								validate
								title={"Valoarea Y"}
								smartSelect
								smartSelectParams={{
									openIn: "popover",
									closeOnSelect: true,
									on: {
										closed: (event) => {
											var selectedColumn2 = event.getValue();

											this.setState(
												{
													selectedColumn2,
												},
												() => {
													this.setDataSource();
												}
											);
										},
									},
								}}
							>
								<select
									defaultValue={this.state.selectedColumn2}
									name="Proiect2"
								>
									{this.inputsColumnY.map((obj, index) => (
										<option key={"SelectProiectRG2" + index} value={obj.key}>
											{obj.key}
										</option>
									))}
								</select>
								<Icon
									slot="content-start"
									style={{
										color: "var(--f7-theme-color)",
										left: 0,
										height: 14,
									}}
									size={20}
									material="list"
								/>
							</ListItem>
						</List>

						<Segmented
							style={{
								margin: "0 auto",
							}}
							tag="p"
						>
							<Button
								style={{ height: 35, padding: 0 }}
								disabled={this.state.graphType !== 0 ? false : true}
								small
								onClick={() => {
									this.setState({ graphType: this.state.graphType - 1 }, () => {
										this.setChart();
									});
								}}
							>
								<Icon
									style={{
										margin: 0,
										color: this.state.graphType !== 0 ? culoare : "lightgray",
									}}
									size={40}
									material="navigate_before"
								/>
							</Button>
							<Button
								style={{ height: 35, padding: 0 }}
								disabled={
									this.state.graphType !== this.graphTypes.length - 1
										? false
										: true
								}
								small
								onClick={() => {
									this.setState({ graphType: this.state.graphType + 1 }, () => {
										this.setChart();
									});
								}}
							>
								<Icon
									style={{
										margin: 0,
										color:
											this.state.graphType !== this.graphTypes.length - 1
												? culoare
												: "lightgray",
									}}
									size={40}
									material="navigate_next"
								/>
							</Button>

							<Button
								style={{ height: 35, padding: 0 }}
								tooltip={
									this.state.dataSet === 1
										? "Dataset filtrat"
										: "Dataset nefiltrat"
								}
								onClick={() => {
									this.setState(
										{ dataSet: this.state.dataSet === 1 ? 2 : 1 },
										() => {
											this.setDataSource();
										}
									);
								}}
								small
							>
								<Icon
									style={{
										top: 1,
										fontSize: 30,
										left: 0,
										margin: 0,
										color: this.state.dataSet === 1 ? culoare : "red",
									}}
									size={40}
									material="filter_list"
								/>
							</Button>
						</Segmented>
					</div>
					<div style={{ display: "flex" }}>
						{this.state.dataSetParinti && this.state.dataSetParinti.length > 0 && (
							<List
								style={{
									margin: "0 auto",
									width: "50%",
								}}
							>
								<ListItem
									style={{
										margin: "0 auto",
										width: "100%",
									}}
									className="roundedOutlinedSmartSelect"
									floatingLabel
									required
									label={"Parinte"}
									validate
									title={"Parinte"}
									smartSelect
									smartSelectParams={{
										openIn: "popover",
										closeOnSelect: true,
										on: {
											closed: (event) => {
												var refId = parseInt(event.getValue());
												this.setState(
													{
														refId,
													},
													() => {
														this.setDataSource();
													}
												);
											},
										},
									}}
								>
									<select defaultValue={this.state.refId} name="Proiect23">
										{this.state.dataSetParinti.map((obj, index) => (
											<option
												key={"SelectProiectRG23" + index}
												value={obj.value}
											>
												{obj.key}
											</option>
										))}
									</select>
									<Icon
										slot="content-start"
										style={{
											color: "var(--f7-theme-color)",
											left: 0,
											height: 14,
										}}
										size={20}
										material="list"
									/>
								</ListItem>
							</List>
						)}
						<Searchbar
							onClickDisable={() => {
								var source = [...this.state.dataSource];
								this.setState({ dataSourceToRender: source });
							}}
							borderColor={"transparent"}
							onInput={(e) => {
								var value = e.target.value
									.toString()
									.toLowerCase()
									.replace(/\s/g, "");
								var ds = this.state.dataSource.filter((r) => {
									var valToReturn = false;
									if (r.name && valToReturn === false) {
										valToReturn = r.name
											.toString()
											.toLowerCase()
											.replace(/\s/g, "")
											.includes(value);
									}
									if (r.realVal && valToReturn === false) {
										valToReturn = r.realVal
											.toString()
											.toLowerCase()
											.replace(/\s/g, "")
											.includes(value);
									}
									return valToReturn;
								});
								this.setState({ dataSourceToRender: ds }, () => {
									this.setChart();
								});
							}}
							style={{
								height: 50,
								width: 300,
								right: 0,
								left: "auto",
								margin: 0,
								filter: "drop-shadow(2px 0px 4px gray)",
							}}
						/>
					</div>
					<div
						style={{ width: "100%", height: this.state.height }}
						id="section2"
					></div>
				</>
			</Page>
		);
	}
}
export default RG;