import React, { Component } from "react";
import { Row, Col, Searchbar, Stepper, Preloader } from "framework7-react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import moment from 'moment';
import axios from 'axios';

 /**
  * @description Afiseaza un grafic cu toate taskurile lucrate intr-un interval selectat si cat s-a estimat ca se va lucra la acel task, cand se da click pe un task din grafic se afiseaza un popup cu mai multe informatii precum numele taskului, persoana care a lucrat la el, cat s-a lucrat.
  * @date 06/04/2022
  * @class EstimatLucratProiecte
  * @extends {Component}
  */
 class EstimatLucratProiecte extends Component {
   constructor(props) {
     super(props);

     this.state = {
       linkApiRealizari: "/api/RealizareSarcini",
       linkApiSarcini: "/api/SarciniSalariat",
       height: window.innerHeight - 100,
       heightItem: 30,
       width: window.innerWidth - 10,
       listaProiecte: [],
       listaProiectePagina: [],
       listaProiecteSearch: [],
       pagina: 1,
       paginaMax: 1,
       dataLoaded: false,
       searchActive: false,
     };
   }

   componentDidMount = () => {
     this.GetValues();
   };
   /**
    * @description Ia valorile necesare pentru randare de la endpointul „/GetSARCINI_SALARIATCorecte” si „/GetREALIZARE_SARCINI” pentru a selecta salariatii activi si realizarile acestora.
    * @function
    * @date 06/04/2022
    * @memberof EstimatLucratProiecte
    */
   GetValues = () => {
     const requestRealizari = axios.get(
       this.$f7.data.baseURL +
         this.state.linkApiRealizari +
         "/GetREALIZARE_SARCINI"
     );
     const requestSarcini = axios.get(
       this.$f7.data.baseURL +
         this.state.linkApiSarcini +
         "/GetSARCINI_SALARIATCorecte"
     );

     axios
       .all([requestRealizari, requestSarcini])
       .then(
         axios.spread((responseRealizari, responseSarcini) => {
           const realizareSarcini = responseRealizari.data;
           const sarciniSalariat = responseSarcini.data;
           let listaProiecte = [];

           console.log("allProiecte:", this.$f7.data.allProiecte);

           this.$f7.data.allProiecte.forEach((element) => {
             if ([1, 2].includes(element.id_nivel_proiect)) {
               const taskuri = this.$f7.data.allProiecte.filter(
                 (item) =>
                   item.id_nivel_proiect === 4 &&
                   item.reF_PROIECT === element.iD_PROIECT
               );
               let totalTaskuri = 0;
               let taskuriFaraEstimare = 0;

               if (taskuri.length > 0) {
                 let nume = element.denumire;
                 let timpEstimatDeschise = 0;
                 let timpLucratDeschise = 0;
                 let timpEstimatInchise = 0;
                 let timpLucratInchise = 0;

                 taskuri.forEach((t) => {
                   let ultimaSarcina = sarciniSalariat.filter(
                     (item) => item.iD_PROIECT === t.iD_PROIECT
                   );

                   if (ultimaSarcina.length > 0) {
                     const maxIdUltimaSarcina = Math.max.apply(
                       Math,
                       ultimaSarcina.map(function (o) {
                         return o.iD_SARCINA;
                       })
                     );
                     ultimaSarcina = ultimaSarcina.find(
                       (item) => item.iD_SARCINA === maxIdUltimaSarcina
                     );
                   }

                   if (ultimaSarcina !== undefined) {
                     const realizari = realizareSarcini.filter(
                       (item) =>
                         item.iD_PROIECT === t.iD_PROIECT &&
                         item.intrare !== null
                     );

                     if (ultimaSarcina.stare === 4) {
                       // timp_plan_d
                       // timp_plan_sp
                       // timp_plan_a
                       // timp_plan_m

                       let timp = 0;
                       if (t.timp_plan_d !== 0 && t.timp_plan_d !== null) {
                         timp = t.timp_plan_d;
                       } else if (
                         t.timp_plan_sp !== 0 &&
                         t.timp_plan_sp !== null &&
                         t.timp_plan_sp < timp
                       ) {
                         timp = t.timp_plan_sp;
                       } else if (
                         t.timp_plan_a !== 0 &&
                         t.timp_plan_a !== null &&
                         t.timp_plan_a < timp
                       ) {
                         timp = t.timp_plan_a;
                       } else if (
                         t.timp_plan_m !== 0 &&
                         t.timp_plan_m !== null &&
                         t.timp_plan_m < timp
                       ) {
                         timp = t.timp_plan_m;
                       } else {
                         taskuriFaraEstimare++;
                       }

                       timpEstimatInchise += timp;

                       realizari.forEach((r) => {
                         const inceput = moment(r.intrare).valueOf();
                         const sfarsit =
                           r.iesire === null
                             ? moment().valueOf()
                             : moment(r.iesire).valueOf();
                         timpLucratInchise += (sfarsit - inceput) / 1000 / 60;
                       });
                     } else {
                       // timp_plan_d
                       // timp_plan_sp
                       // timp_plan_a
                       // timp_plan_m

                       let timp = 0;
                       if (t.timp_plan_d !== 0 && t.timp_plan_d !== null) {
                         timp = t.timp_plan_d;
                       } else if (
                         t.timp_plan_sp !== 0 &&
                         t.timp_plan_sp !== null &&
                         t.timp_plan_sp < timp
                       ) {
                         timp = t.timp_plan_sp;
                       } else if (
                         t.timp_plan_a !== 0 &&
                         t.timp_plan_a !== null &&
                         t.timp_plan_a < timp
                       ) {
                         timp = t.timp_plan_a;
                       } else if (
                         t.timp_plan_m !== 0 &&
                         t.timp_plan_m !== null &&
                         t.timp_plan_m < timp
                       ) {
                         timp = t.timp_plan_m;
                       } else {
                         taskuriFaraEstimare++;
                       }

                       timpEstimatDeschise += timp;

                       if (realizari.length > 0) {
                         realizari.forEach((r) => {
                           const inceput = moment(r.intrare).valueOf();
                           const sfarsit =
                             r.iesire === null
                               ? moment().valueOf()
                               : moment(r.iesire).valueOf();
                           timpLucratDeschise +=
                             (sfarsit - inceput) / 1000 / 60;
                         });
                       }
                     }

                     totalTaskuri++;
                   }
                 });

                 if (element.id_tip_proiect === 59) {
                   const pContract = this.$f7.data.allProiecte.find(
                     (item) => item.iD_PROIECT === element.reF_PROIECT
                   );

                   if (pContract) {
                     const pClient = this.$f7.data.allProiecte.find(
                       (item) => item.iD_PROIECT === pContract.reF_PROIECT
                     );

                     if (pClient) {
                       nume = "[" + pClient.denumire + "] " + nume;
                     }
                   }
                 }

                 listaProiecte.push({
                   name:
                     nume +
                     " (" +
                     taskuriFaraEstimare +
                     "/" +
                     totalTaskuri +
                     ")",
                   estimatD: timpEstimatDeschise / 60,
                   lucratD: timpLucratDeschise / 60,
                   estimatI: timpEstimatInchise / 60,
                   lucratI: timpLucratInchise / 60,
                 });
               }
             }
           });

           this.setState(
             {
               listaProiecte: listaProiecte,
             },
             () => {
               this.updateChart();
             }
           );
         })
       )
       .catch((error) => {
         console.log("error", error);
       });
   };
   /**
    * @description Actualizeaza datasource-ul graficului
    * @function
    * @date 06/04/2022
    * @memberof EstimatLucratProiecte
    */
   updateChart = () => {
     let lista = this.state.searchActive
       ? this.state.listaProiecteSearch
       : this.state.listaProiecte;

     this.setState(
       {
         dataLoaded: false,
         listaProiectePagina: lista.slice(
           (this.state.pagina - 1) *
             parseInt(this.state.height / this.state.heightItem),
           this.state.pagina *
             parseInt(this.state.height / this.state.heightItem)
         ),
         paginaMax: Math.ceil(
           lista.length / parseInt(this.state.height / this.state.heightItem)
         ),
       },
       () => {
         this.setState({
           dataLoaded: true,
         });
       }
     );
   };
   /**
    * @description Face cautare in datasource si rerandeaza elementele de le gaseste folosind functia updateChart
    * @function
    * @date 06/04/2022
    * @param {*} value valoarea de cautat
    * @param {*} baza baza in care sa caute
    * @memberof EstimatLucratProiecte
    */
   search = (value, baza) => {
     let toSearch = value.toString().toLowerCase().replace(/\s/g, "");

     let result = baza.filter((elemBaza) => {
       let res = false;
       let valFinal = elemBaza.name.toString().toLowerCase().replace(/\s/g, "");

       if (valFinal.includes(toSearch)) {
         res = true;
       }

       return res;
     });

     this.setState(
       {
         listaProiecteSearch: result,
         pagina: 1,
         searchActive: true,
       },
       () => {
         this.updateChart();
       }
     );
   };
   /**
    * @description transforma valorile din datagrid(timpul lucrat) intr-un string mai usor de inteles (h : m : s
    * @date 06/04/2022
    * @function
    * @param {*} seconds
    * @memberof EstimatLucratProiecte
    */
   ConvertToFriendlyTime = (seconds) => {
     const days = Math.floor(seconds / (8 * 3600));

     seconds = seconds % (8 * 3600);
     const hours = Math.floor(seconds / 3600);

     seconds = seconds % 3600;
     const minutes = Math.floor(seconds / 60);

     seconds = seconds % 60;

     let output;

     if (days) {
       if (hours) {
         if (minutes) {
           output =
             (days > 1 ? days + "z " : days + " z") +
             ", " +
             (hours > 1 ? hours + "h " : hours + "h ") +
             " : " +
             (minutes > 1 ? minutes + "m " : minutes + "m ");
         } else {
           output =
             (days > 1 ? days + "z " : days + " z") +
             " : " +
             (hours > 1 ? hours + "h " : hours + "h ");
         }
       } else {
         if (minutes) {
           output =
             (days > 1 ? days + "z " : days + " z") +
             " : " +
             (minutes > 1 ? minutes + "m " : minutes + "m ");
         } else {
           output = days > 1 ? days + "z " : days + " z";
         }
       }
     } else {
       if (hours) {
         if (minutes) {
           output =
             (hours > 1 ? hours + "h " : hours + "h ") +
             " : " +
             (minutes > 1 ? minutes + "m " : minutes + "m ");
         } else {
           output = hours > 1 ? hours + "h " : hours + "h ";
         }
       } else {
         if (minutes) {
           output = minutes > 1 ? minutes + "m " : minutes + "m ";
         } else {
           output = "0";
         }
       }
     }

     return "" + output;
   };

   render() {
     return (
       <Row>
         <Col style={{ position: "relative" }}>
           <div style={{ position: "relative", overflow: "hidden" }}>
             <Searchbar
               className={"searchBarEstimatLucratProiecte"}
               onClickDisable={() => {
                 this.setState(
                   {
                     pagina: 1,
                     searchActive: false,
                   },
                   () => {
                     this.updateChart();
                   }
                 );
               }}
               onSearchbarEnable={() => {}}
               onSearchbarDisable={() => {}}
               onInput={(e) => {
                 this.search(e.target.value, this.state.listaProiecte);
               }}
               borderColor={"transparent"}
               style={{
                 height: 34,
                 width:
                   "calc(100% - 130px)" /*, filter: "drop-shadow(2px 0px 14px gray)"*/,
                 float: "left",
               }}></Searchbar>

             <div
               style={{
                 position: "relative",
                 marginTop: 8,
                 marginRight: 8,
                 float: "right",
               }}>
               <span
                 style={{
                   position: "absolute",
                   top: 0,
                   left: 0,
                   right: 0,
                   textAlign: "center",
                 }}>
                 {this.state.paginaMax}{" "}
                 {this.state.paginaMax > 1 ? "pagini" : "pagina"}
               </span>
               <Stepper
                 style={{
                   height: 18,
                   marginTop: 15,
                 }}
                 onStepperPlusClick={(e) => {
                   if (this.state.pagina < this.state.paginaMax) {
                     this.setState(
                       {
                         pagina: this.state.pagina + 1,
                       },
                       () => {
                         this.updateChart();
                       }
                     );
                   }
                 }}
                 onStepperMinusClick={(e, f) => {
                   if (this.state.pagina > 1) {
                     this.setState(
                       {
                         pagina: this.state.pagina - 1,
                       },
                       () => {
                         this.updateChart();
                       }
                     );
                   }
                 }}
                 onInput={(e) => {
                   const val = e.target.value;

                   if (val !== "") {
                     const pag = parseInt(val);

                     if (pag >= 1 && pag <= this.state.paginaMax) {
                       this.setState(
                         {
                           pagina: pag,
                         },
                         () => {
                           this.updateChart();
                         }
                       );
                     }
                   }
                 }}
                 small
                 value={this.state.pagina}
                 min={1}
                 max={this.state.paginaMax}
                 manualInputMode={true}
               />
             </div>
           </div>

           {this.state.dataLoaded ? (
             <BarChart
               layout="vertical"
               width={this.state.width}
               height={this.state.height}
               data={this.state.listaProiectePagina}
               margin={{
                 top: 5,
                 right: 30,
                 left: 20,
                 bottom: 5,
               }}>
               <CartesianGrid strokeDasharray="3 3" />
               <XAxis type="number" />
               <YAxis dataKey="name" type="category" width={180} />
               <Tooltip
                 formatter={(value, name, props) => {
                   let output = value;

                   if (typeof value === "number") {
                     output = this.ConvertToFriendlyTime(value * 60 * 60);
                   }

                   return [output, name];
                 }}
                 separator=" → "
                 itemStyle={{ fontSize: 13, paddingBottom: 1, paddingTop: 1 }}
                 labelStyle={{ fontSize: 17, marginBottom: 5 }}
               />
               <Legend />

               <Bar
                 dataKey="lucratI"
                 name="Lucrat (inchise)"
                 stackId="lucrate"
                 fill="#8c330d"
               />
               <Bar
                 dataKey="lucratD"
                 name="Lucrat (deschise)"
                 stackId="lucrate"
                 fill="#d94f14"
               />

               <Bar
                 dataKey="estimatI"
                 name="Estimat (inchise)"
                 stackId="estimate"
                 fill="#bf9a15"
               />
               <Bar
                 dataKey="estimatD"
                 name="Estimat (deschise)"
                 stackId="estimate"
                 fill="#e6b919"
               />
             </BarChart>
           ) : (
             <div
               style={{
                 position: "absolute",
                 width: "40px",
                 height: "40px",
                 top: "calc(50vh - 34px)",
                 left: "50%",
                 transform: "translateX(-50%)",
               }}>
               <Preloader size={40} color={"var(--f7-theme-color)"}></Preloader>
             </div>
           )}
         </Col>
       </Row>
     );
   }
 }
export default EstimatLucratProiecte;