import React from "react";
import {
	Button,
	Page,
	Navbar,
	Icon,
	Link,
	NavRight,
	Searchbar,
	List,
	ListItem,
	Segmented,
	NavLeft,
} from "framework7-react";
var culoare = "var(--f7-theme-color)";
//var culoareTint = "var(--f7-theme-color-tint)";
class SelectboxOptimizat extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			numberBeforeAddEdit: 0,
			lista: this.props.lista,
			listaRender: this.props.lista,
			valoare: this.props.valoare,
		};
		this.ref = React.createRef();
	}

	searchAddEdit = (value) => {
		var toSearch = value.toString().toLowerCase().replace(/\s/g, "");
		var result = this.state.lista.filter((elemBaza) => {
			var res = false;
			if (elemBaza["key"]) {
				var valoare = elemBaza["key"]
					.toString()
					.toLowerCase()
					.replace(/\s/g, "");
				if (valoare.includes(toSearch)) {
					res = true;
				}
			}
			return res;
		});
		this.setState({ listaRender: result, numberBeforeAddEdit: 0 });
	};

	closeModal = () => {
		return new Promise((resolve, reject) => {
			var denToClose = "." + this.props.modalName;
			var popupToClose = this.$f7.popup.get(denToClose);
			var panelToClose = this.$f7.panel.get(denToClose);
			var sheetToClose = this.$f7.sheet.get(denToClose);
			if (sheetToClose) {
				if (sheetToClose.opened) {
					sheetToClose.close();
					sheetToClose.on("closed", () => {
						resolve(true);
					});
				} else {
					resolve(true);
				}
			}
			if (popupToClose) {
				if (popupToClose.opened) {
					popupToClose.close();
					popupToClose.on("closed", () => {
						resolve(true);
					});
				} else {
					resolve(true);
				}
			}
			if (panelToClose) {
				if (panelToClose.opened) {
					panelToClose.close();
					panelToClose.on("closed", () => {
						resolve(true);
					});
				} else {
					resolve(true);
				}
			}
		});
	};
	shouldComponentUpdate = (nextProps, nextState) => {
		if (nextProps.lista.length !== this.state.lista.length) {
			this.setState({
				lista: nextProps.lista,
				listaRender: nextProps.listaRender,
			});
		}
		if (nextProps.valoare !== this.state.valoare) {
			this.setState({ valoare: nextProps.valoare });
		}
		return true;
	};
	componentDidMount = () => {
		var search = this.$f7.searchbar.get("." + this.props.searchName);

		setTimeout(() => {
			search.inputEl.focus();
		}, 150);
	};

	onClickDisable = () => {
		var source = [...this.state.lista];
		this.setState({ listaRender: source });
	};
	inchide = () => {
		if (this.props.modalName !== undefined) {
			this.closeModal();
		}
	};

	onClickBefore = () => {
		let lastNumber = this.state.numberBeforeAddEdit - this.props.count;
		if (lastNumber >= 0) {
			this.setState({
				numberBeforeAddEdit: lastNumber,
			});
		}
	};
	onClickNext = () => {
		let lastNumber = this.state.numberBeforeAddEdit + this.props.count;
		if (lastNumber <= this.state.listaRender.length - 1) {
			this.setState({
				numberBeforeAddEdit: lastNumber,
			});
		}
	};
	onClickDone = () => {
		if (this.props.onSelectionChanged !== undefined) {
			this.props.onSelectionChanged(this.state);
		}
		if (this.props.modalName !== undefined) {
			this.closeModal();
		}
	};
	onClick = (v, e) => {
		if (this.props.onClick !== undefined) {
			this.props.onClick(v.value);
		}
	};

	render() {
		return (
			<Page style={{ overflow: "hidden", height: "auto", minHeight: 300 }}>
				<Navbar>
					{this.props.tip === "selectboxmultiplu" ? (
						<NavLeft>
							<Button style={{ padding: 0 }} onClick={this.onClickDone}>
								<Icon
									style={{ color: "white", margin: 0 }}
									size={35}
									material="done"
								/>
							</Button>
						</NavLeft>
					) : null}
					<NavRight>
						<Link tooltip={"Inchide"} onClick={this.inchide}>
							Inchide
						</Link>
					</NavRight>
				</Navbar>
				<>
					<div
						style={{
							height: 36,
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Searchbar
							style={{ width: "100%", margin: 0, height: 36 }}
							className={this.props.searchName}
							onClickDisable={this.onClickDisable}
							borderColor={"transparent"}
							onInput={(e) => {
								var value = e.target.value;
								this.searchAddEdit(value);
							}}
						/>

						<Segmented
							style={{
								margin: "0 auto",
							}}
							tag="p"
						>
							<Button
								style={{ height: 35, padding: 0 }}
								disabled={
									parseInt(this.state.numberBeforeAddEdit - this.props.count) >=
										0
										? false
										: true
								}
								small
								onClick={this.onClickBefore}
							>
								<Icon
									style={{
										margin: 0,
										color:
											parseInt(
												this.state.numberBeforeAddEdit - this.props.count
											) >= 0
												? culoare
												: "lightgray",
									}}
									size={40}
									material="navigate_before"
								/>
							</Button>
							<Button
								style={{ height: 35, padding: 0 }}
								disabled={
									this.state.listaRender === undefined
										? true
										: parseInt(
											this.state.numberBeforeAddEdit + this.props.count
										) <= parseInt(this.state.listaRender.length - 1)
											? false
											: true
								}
								small
								onClick={this.onClickNext}
							>
								<Icon
									style={{
										margin: 0,
										color:
											this.state.listaRender === undefined
												? "lightgray"
												: parseInt(
													this.state.numberBeforeAddEdit + this.props.count
												) <= parseInt(this.state.listaRender.length - 1)
													? culoare
													: "lightgray",
									}}
									size={40}
									material="navigate_next"
								/>
							</Button>
						</Segmented>
					</div>
					<List>
						{this.state.listaRender
							? this.state.listaRender
								.slice(
									this.state.numberBeforeAddEdit,
									this.state.numberBeforeAddEdit + this.props.count
								)
								.map((e, index) => (
									<ListItem
										className={"inputInaltimeNoua"}
										checked={
											this.props.tip === "selectboxmultiplu"
												? this.state.valoare !== null
													? e.value !== null
														? this.state.valoare.includes(e.value.toString())
														: this.state.valoare.includes(null)
													: e.value === null
												: this.state.valoare === e.value
										}
										checkbox={
											this.props.tip === "selectboxmultiplu" ? true : false
										}
										radio={
											this.props.tip === "selectboxmultiplu" ? false : true
										}
										onClick={this.onClick.bind(this, e)}
										key={"itemSelectboxOptimizat" + index}
									>
										<p style={{ whiteSpace: "nowrap", margin: 0 }}>{e.key}</p>
									</ListItem>
								))
							: null}
					</List>
				</>
			</Page>
		);
	}
}
export default SelectboxOptimizat;