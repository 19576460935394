import React from "react";
import { Button, Icon, Link } from "framework7-react";
import { ReactSortable } from "react-sortablejs";

/**
 * @description Interfata care randeaza taburile cu interfetele curent deschise in bara de sus
 * @date 08/04/2022
 * @class TopTabs
 * @extends {React.Component}
 */
class TopTabs extends React.Component {
  constructor(props) {
    super(props);
    var tabs = localStorage.getItem("tabs");
    var parsedTabs = [];
    if (tabs) {
      parsedTabs = JSON.parse(localStorage.getItem("tabs"));
      if (
        parsedTabs.find((r) => {
          return r.path === "/";
        }) === undefined
      ) {
        var toAdd = {
          keepAlive: false,
          name: "home",
          path: "/",
          showAppBar: true,
          showBack: false,
          showTopRight: true,
          viewName: "main",
        };
        parsedTabs.splice(0, 0, toAdd);
      }
    }
    this.state = {
      tabs: localStorage.getItem("tabs") ? parsedTabs : [],
      activeTabPath: "/",
    };
  }

  /**
   * @description Adauga un tab nou in state, pentru randare
   * @function
   * @date 08/04/2022
   * @param {*} page pagina de adaugat
   * @memberof TopTabs
   */
  addTab = (page) => {
    var newPage = page ? page : this.$f7.data.currentPage;
    delete newPage.keepAliveData;
    var tabs = this.state.tabs;
    var includes = tabs.find((r) => {
      return r.path === newPage.path;
    });
    if (includes === undefined) {
      tabs.push(newPage);
      this.setState({ tabs: tabs, activeTabPath: newPage.path }, () => {
        this.saveTabs();
      });
    }
  };
  /**
   * @description Sterge din state(randare) un tab
   * @function
   * @date 08/04/2022
   * @param {*} path path-ul paginii selectate pentru stergere
   * @memberof TopTabs
   */
  removeTab = (path) => {
    var tabs = this.state.tabs;
    var includes = tabs.find((r) => {
      return r.path === path;
    });
    if (includes !== undefined) {
      tabs = tabs.filter((r) => {
        return r.path !== path;
      });
      var refresh = this.state.activeTabPath === path;
      this.setState(
        { tabs: tabs, activeTabPath: refresh ? "/" : this.state.activeTabPath },
        () => {
          if (refresh) {
            this.$f7.views.main.router.navigate("/");
          }
          this.saveTabs();
        }
      );
    }
  };
  /**
   * @description Salveaza in localStorage taburile pentru a le avea si la relogare
   * @function
   * @date 08/04/2022
   * @memberof TopTabs
   */
  saveTabs = () => {
    localStorage.setItem("tabs", JSON.stringify(this.state.tabs));
  };

  /**
   * @description Da refresh la pagina curenta
   * @function
   * @date 08/04/2022
   * @memberof TopTabs
   */
  refreshTab = () => {
    this.$f7.views.main.router.refreshPage();
  };

  componentDidMount() {
    this.$f7.data.topTabs = this;
  }

  /**
   * @description Muta 2 taburi intre ele
   * @date 08/04/2022
   * @function
   * @param {*} id1 id-ul primului tab
   * @param {*} id2 id-ul celui de al doilea tab
   * @memberof TopTabs
   */
  moveTabs = (id1, id2) => {
    var tabs = this.state.tabs;
    var toMove1 = { ...tabs[id1] };
    var toMove2 = { ...tabs[id2] };
    if (toMove1 !== undefined && toMove2 !== undefined) {
      if (toMove1.path !== undefined && toMove2.path !== undefined) {
        if (toMove1.path !== toMove2.path) {
          tabs[id1] = toMove2;
          tabs[id2] = toMove1;
        }
      }
    }
    this.setState({ tabs: tabs }, () => {
      this.saveTabs();
      this.forceUpdate();
    });
  };

  render() {
    return (
      <>
        <Link
          key={"refreshTabButton"}
          onClick={() => {
            this.refreshTab();
          }}>
          <Icon material={"refresh"} />
        </Link>

        <ReactSortable
          className={"segmented segmented-strong"}
          style={{
            display: "inline-flex",
            height: 34,
            maxWidth: "calc(100vw - 415px)",
            overflowX: "auto",
            backgroundColor: "var(--f7-theme-color)",
          }}
          list={this.state.tabs}
          setList={(newState) =>
            this.setState({ tabs: newState }, () => {
              this.saveTabs();
            })
          }>
          {this.state.tabs.map((r, index) => (
            <Button
              key={"TabButton" + index}
              style={{ padding: 0, fontSize: 12, color: "white" }}
              tooltip={r.name}
              active={this.state.activeTabPath === r.path}
              onClick={() => {
                setTimeout(() => {
                  var existaTab = this.state.tabs.find((tab) => {
                    return tab.path === r.path;
                  });
                  if (existaTab) {
                    this.setState({ activeTabPath: r.path }, () => {
                      this.$f7.views.main.router.navigate(r.path);
                    });
                  }
                }, 250);
              }}>
              <>
                {r.name}
                {r.path !== "/" ? (
                  <span
                    style={{ position: "absolute", top: -11, right: 3 }}
                    onClick={() => {
                      this.removeTab(r.path);
                    }}>
                    x
                  </span>
                ) : null}
              </>
            </Button>
          ))}
        </ReactSortable>

        {/*  <Link
          key={"addTabButton"}
          onClick={e => {
            this.addTab();
          }}
        >
          <Icon material={"add"} />
        </Link> */}
      </>
    );
  }
}
export default TopTabs;
