import React, { Suspense } from "react";
import { Page, Popup, Preloader } from "framework7-react";

/**
 * @description Popup-ul in care se randeaza interfata de apel video sau audio
 * @date 08/04/2022
 * @class PopupCall
 * @extends {React.PureComponent}
 */
class PopupCall extends React.PureComponent {
  render() {
    return (
      <Popup
        className={"PopupCall"}
        closeOnEscape={true}
        tabletFullscreen={true}
        onPopupClose={() => {
          if (this.props.onPopupClose !== undefined) {
            this.props.onPopupClose(this);
          }
        }}>
        <Page style={{ overflow: "hidden" }}>
          <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
            <Suspense
              fallback={
                <div
                  style={{
                    width: "40px",
                    margin: "0 auto",
                  }}>
                  <Preloader
                    size={40}
                    color={"var(--f7-theme-color)"}></Preloader>
                </div>
              }>
              {this.props.component}
            </Suspense>
          </div>
        </Page>
      </Popup>
    );
  }
}
export default PopupCall;
