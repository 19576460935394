import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import {
  Card,
  ListInput,
  List,
  CardHeader,
  CardContent,
  Row,
  Col,
  ListItem,
  Icon,
  Button,
  Page,
} from "framework7-react";
import moment from "moment";
import axios from "axios";

/**
 * @description Afiseaza alocare taskului si sarcinilor pentru proiectul curent selectat(taskul selectat)
 * @date 08/04/2022
 * @class Alocare
 * @extends {React.Component}
 */
class Alocare extends React.Component {
  constructor(props) {
    super(props);
    this.height = window.innerHeight;
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.state = {
      linkApiProiecte: "/api/Proiecte",
      linkApiSalariati: "/api/SarciniSalariat",
      linkApiRealizareSarcini: "/api/RealizareSarcini",
      dataSourceSalariati: [],
      dataSourceRealizareSarcini: [],
      inputsRealizareSarcini: [
        { key: "Id", keyBaza: "iD_REALIZARE" },
        {
          key: "Salariat",
          keyBaza: "iD_SALARIAT",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Inceput",
          keyBaza: "intrare",
          tip: "datebox",
        },
        {
          key: "Sfarsit",
          keyBaza: "iesire",
          tip: "datebox",
        },
        {
          key: "ProcentRealizare",
          keyBaza: "pROCENT_REALIZARE",
          tip: "number",
        },
        { key: "Comentariu", keyBaza: "comentariu" },
        {
          key: "Client",
          keyBaza: "iD_CLIENT",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
          requiredAdd: true,
          requiredEdit: true,
        },
      ],
      inputsSalariati: [
        { key: "Id", keyBaza: "iD_SARCINA" },
        {
          key: "Proiect",
          keyBaza: "iD_PROIECT",
          tip: "selectbox",
          lista: this.$f7.data.Proiecte,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Salariat",
          keyBaza: "iD_SALARIAT",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
          requiredAdd: true,
          requiredEdit: true,
        },
      ],
      height: window.innerHeight - 34,
      costEstimat:
        this.$f7.data.Proiect !== null
          ? this.$f7.data.Proiect.cost_estimat
          : null,
      dataInceput:
        this.$f7.data.Proiect !== null
          ? this.$f7.data.Proiect.data_start
          : null,
      dataSfarsit:
        this.$f7.data.Proiect !== null
          ? this.$f7.data.Proiect.data_final
          : null,
      tipSarcina:
        this.$f7.data.Proiect !== null
          ? parseInt(this.$f7.data.Proiect.id_tip_actiune)
          : null,
      stadiu:
        this.$f7.data.Proiect !== null
          ? parseInt(this.$f7.data.Proiect.iD_STADIU)
          : null,
    };
    this.refDataGridSalariati = React.createRef();
    this.refDataGridRealizari = React.createRef();
  }

  /**
   * @description Ia sarcinile pentru proiectul curent selectat
   * @function
   * @date 08/04/2022
   * @memberof Alocare
   */
  GetValuesSalariati = () => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiSalariati +
          "/GetSARCINI_SALARIATByProiect/" +
          this.$f7.data.Proiect.iD_PROIECT
      )
      .then((response) => {
        var date = response;
        date.data.map((stadiu) => {
          stadiu = this.$f7.methods.restructurareBaza(
            stadiu,
            this.state.inputsSalariati,
            "key"
          );
          return null;
        });

        var baza = this.$f7.methods.reconstructieBaza(
          date,
          this.state.inputsSalariati
        );

        this.setState(
          {
            dataSourceSalariati: baza,
          },
          () => {
            if (baza.length > 0) {
              this.refDataGridSalariati.current.setRenderItems(baza);
            }
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSourceSalariati: [],
          },
          () => {
            this.refDataGridSalariati.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor despre salariati de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesSalariati();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Ia realizarile pentru sarcinile proiectului curent selectat
   * @function
   * @date 08/04/2022
   * @memberof Alocare
   */
  GetValuesRealizariSarcini = () => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiRealizareSarcini +
          "/GetREALIZARE_SARCINI_BY_PROIECT/" +
          this.$f7.data.Proiect.iD_PROIECT
      )
      .then((response) => {
        if (response.data.length > 0) {
          response.data.map((stadiu) => {
            stadiu = this.$f7.methods.restructurareBaza(
              stadiu,
              this.state.inputsRealizareSarcini,
              "key"
            );
            return null;
          });

          var baza = this.$f7.methods.reconstructieBaza(
            response,
            this.state.inputsRealizareSarcini
          );

          this.setState(
            {
              dataSourceRealizareSarcini: baza,
            },
            () => {
              if (baza.length > 0) {
                this.refDataGridRealizari.current.setRenderItems(baza);
              }
            }
          );
        }
      })
      .catch((error) => {
        this.setState(
          {
            dataSourceRealizareSarcini: [],
          },
          () => {
            this.refDataGridRealizari.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor despre realizari de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesRealizariSarcini();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Ia toate informatiile proiectului(taskului) curent selectat
   * @function
   * @date 08/04/2022
   * @memberof Alocare
   */
  GetValues = () => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiProiecte +
          "/GetPROIECTESauArhivate/" +
          this.$f7.data.Proiect.iD_PROIECT
      )
      .then((response) => {
        var proiect = response.data;

        this.setState({
          costEstimat: proiect.cost_estimat,
          tipSarcina: parseInt(proiect.id_tip_actiune),
          dataInceput: new Date(proiect.data_start),
          dataSfarsit: new Date(proiect.data_final),
          stadiu: parseInt(proiect.iD_STADIU),
        });
      })
      .catch((error) => {
        this.setState(
          {
            costEstimat: 0,
            tipSarcina: null,
            dataInceput: new Date(),
            dataSfarsit: new Date(),
            stadiu: null,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Actualizeaza infomratiile proiectului(taskului) curent selectat
   * @function
   * @date 08/04/2022
   * @memberof Alocare
   */
  UpdateValue = () => {
    var id = this.$f7.data.Proiect.iD_PROIECT;
    var obj = {
      cost_estimat: this.state.costEstimat,
      id_tip_actiune: parseInt(this.state.tipSarcina),
      data_start: this.state.dataInceput,
      data_final: this.state.dataSfarsit,
      id_stadiu: parseInt(this.state.stadiu),
    };
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiProiecte +
          "/GetPROIECTESauArhivate/" +
          id
      )
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL +
          this.state.linkApiProiecte +
          "/PutPROIECTE/" +
          id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  componentDidMount = () => {
    if (this.$f7.data.Proiect !== null) {
      this.GetValues();
      this.GetValuesSalariati();
      this.GetValuesRealizariSarcini();
    }
  };

  render() {
    return (
      <Page pageContent={false} style={{ overflowY: "auto" }}>
        {this.$f7.data.Proiect !== null ? (
          <div
            style={{
              paddingLeft:
                "calc(var(--f7-treeview-item-padding-left) + var(--f7-treeview-children-offset) * 1)",
              paddingRight: "var(--f7-treeview-item-padding-right)",
            }}>
            <List
              className="formDescriereProiect"
              noChevron
              form
              noHairlines
              noHairlinesBetween
              noHairlinesBetweenMd
              noHairlinesMd
              onSubmit={(e) => {
                e.preventDefault();
              }}>
              <Card>
                <CardHeader>
                  <div style={{ width: "100%" }}>
                    <span style={{ width: "100%", textAlign: "left" }}>
                      <h4 style={{ color: "gray" }}>Informatii generale</h4>
                    </span>
                  </div>
                </CardHeader>
                <CardContent>
                  <Row style={{ width: "100%" }}>
                    <Col>
                      <ListItem
                        className="roundedOutlinedSmartSelect"
                        title="Tip sarcina"
                        value={this.state.tipSarcina}
                        label="Tip sarcina"
                        validate
                        required
                        smartSelect
                        smartSelectParams={{
                          virtualList: true,
                          searchbar: true,
                          openIn: "popup",
                          popupTabletFullscreen: true,
                          popupPush: true,
                          closeOnSelect: true,
                          on: {
                            closed: (event) => {
                              var val = event.getValue();
                              this.setState({ tipSarcina: val });
                            },
                          },
                        }}>
                        <Icon
                          color={"var(--f7-theme-color)"}
                          size={20}
                          material="list"
                          slot="content-start"
                        />
                        <select
                          defaultValue={this.state.tipSarcina}
                          name="Tip sarcina">
                          {this.$f7.data.TipProiect.map((obj, index) => (
                            <option
                              key={"tipProiectAlocare" + index}
                              value={obj.value}>
                              {obj.key}
                            </option>
                          ))}
                        </select>
                      </ListItem>
                    </Col>
                    <Col>
                      <ListItem
                        className="roundedOutlinedSmartSelect"
                        title="Stadiu"
                        value={this.state.stadiu}
                        label="Stadiu"
                        validate
                        required
                        smartSelect
                        smartSelectParams={{
                          virtualList: true,
                          searchbar: true,
                          openIn: "popup",
                          popupTabletFullscreen: true,
                          popupPush: true,
                          closeOnSelect: true,
                          on: {
                            closed: (event) => {
                              var val = event.getValue();
                              this.setState({ stadiu: val });
                            },
                          },
                        }}>
                        <Icon
                          color={"var(--f7-theme-color)"}
                          size={20}
                          material="list"
                          slot="content-start"
                        />
                        <select defaultValue={this.state.stadiu} name="Stadiu">
                          {this.$f7.data.Stadiu.map((obj, index) => (
                            <option
                              selected={this.state.stadiu === obj.value}
                              key={"tipStadiuAlocare" + index}
                              value={obj.value}>
                              {obj.key}
                            </option>
                          ))}
                        </select>
                      </ListItem>
                    </Col>
                    <Col>
                      <ListInput
                        floatingLabel
                        label="Cost estimat"
                        validate
                        type="number"
                        min="0"
                        step="0.1"
                        placeholder="Cost estimat"
                        value={this.state.costEstimat}
                        onChange={(e) => {
                          this.setState({
                            costEstimat: parseFloat(e.currentTarget.value),
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <Col>
                      <ListInput
                        validate
                        required
                        label="Data inceput"
                        type="datepicker"
                        calendarParams={{
                          timePicker: true,
                          on: {
                            closed: (e) => {
                              var val = e.value[0];
                              this.setState({
                                dataInceput: val,
                              });
                            },
                          },
                        }}
                        value={[
                          this.state.dataInceput === null
                            ? moment(Date.now()).format()
                            : this.state.dataInceput,
                        ]}
                        floatingLabel
                        placeholder="Data inceput">
                        <Icon
                          color={"var(--f7-theme-color)"}
                          size={20}
                          material="today"
                          slot="content-start"
                        />
                      </ListInput>
                    </Col>
                    <Col>
                      <ListInput
                        validate
                        required
                        label="Data sfarsit"
                        type="datepicker"
                        calendarParams={{
                          timePicker: true,
                          on: {
                            closed: (e) => {
                              var val = e.value[0];
                              this.setState({
                                dataSfarsit: val,
                              });
                            },
                          },
                        }}
                        value={[
                          this.state.dataSfarsit === null
                            ? moment(Date.now()).format()
                            : this.state.dataSfarsit,
                        ]}
                        floatingLabel
                        placeholder="Data sfarsit">
                        <Icon
                          color={"var(--f7-theme-color)"}
                          size={20}
                          material="today"
                          slot="content-start"
                        />
                      </ListInput>
                    </Col>
                  </Row>
                </CardContent>
              </Card>

              <Button
                tooltip={"Salveaza"}
                onClick={() => {
                  this.UpdateValue();
                }}
                style={{
                  margin: "16px auto",
                  maxWidth: "300px",
                }}
                type="submit"
                fill
                iconMaterial="save"
                iconColor={"white"}
                iconSize={20}>
                Salveaza
              </Button>
            </List>
            <Card>
              <CardHeader>
                <div style={{ width: "100%" }}>
                  <span style={{ width: "100%", textAlign: "left" }}>
                    <h4 style={{ color: "gray" }}>Salariati</h4>
                  </span>
                </div>
              </CardHeader>
              <CardContent>
                <Row style={{ width: "100%" }}>
                  <Col>
                    <DataGrid
                      name={"Alocare1"}
                      ref={this.refDataGridSalariati}
                      renderUpdate={false}
                      renderDelete={false}
                      renderAdd={false}
                      maxHeight={this.state.height}
                      inputs={this.state.inputsSalariati}
                      dataSource={this.state.dataSourceSalariati}
                    />
                  </Col>
                </Row>
              </CardContent>
            </Card>{" "}
            <Card>
              <CardHeader>
                <div style={{ width: "100%" }}>
                  <span style={{ width: "100%", textAlign: "left" }}>
                    <h4 style={{ color: "gray" }}>Realizari</h4>
                  </span>
                </div>
              </CardHeader>
              <CardContent>
                <Row style={{ width: "100%" }}>
                  <Col>
                    <DataGrid
                      name={"Alocare2"}
                      ref={this.refDataGridRealizari}
                      renderUpdate={false}
                      renderDelete={false}
                      renderAdd={false}
                      maxHeight={this.state.height}
                      dataSource={this.state.dataSourceRealizareSarcini}
                      inputs={this.state.inputsRealizareSarcini}
                    />
                  </Col>
                </Row>
              </CardContent>
            </Card>
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              height: "calc(100vh - 50px)",
            }}>
            <h3
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                right: 0,
                transform: "translateY(-50%)",
                textTransform: "uppercase",
                textAlign: "center",
                display: "block",
              }}>
              Nu ati selectat nimic
            </h3>
          </div>
        )}
      </Page>
    );
  }
}
export default Alocare;
