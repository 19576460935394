import React from "react";
import { Page } from "framework7-react";
import ATGrafice1 from "./ATGrafice1";

class ATGraficePage1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = { denumire: "DepartamentePage" };
    }
    render() {
        return (
            <Page
                pageContent={false}
                className={"themeBackground paddingGrafice"}
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
            >
                <ATGrafice1 />
            </Page>
        );
    }
}
export default ATGraficePage1;