import React from "react";
import { Page } from "framework7-react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";

/**
 * @description Interfata de intretinere/afisare a mesajelor dintre salariati
 * @date 08/04/2022
 * @class Mesaje
 * @extends {React.Component}
 */
class Mesaje extends React.Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;
    this.refMesaje = React.createRef();
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.state = {
      dataLoaded: false,
      linkApi: "/api/Mesaje",
      height: window.innerHeight - 44,
      value: "Mesaj",
      inputs: [
        { key: "Id" },
        {
          key: "Expeditor",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        {
          key: "Destinatar",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "Data", tip: "datebox" },
        {
          key: "Subiect",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Mesaj",
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "Atasament" },
      ],
      dataSource: [],
    };

    this.refDataGrid = React.createRef();
  }

  componentDidMount = () => {
    this.GetValues();
  };

  /**
   * @description Adauga o noua inregistrare pentru interfata curenta(Mesaje)
   * @date 08/04/2022
   * @param {*} data obiect cu toate datele pentru noua inregistrare
   * @function
   * @memberof Mesaje
   */
  AddValue = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApi + "/PostMESAJE";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "POST",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Ia toate mesajele pentru salariatul curent autentificat
   * @date 08/04/2022
   * @function
   * @memberof Mesaje
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetMESAJEById/" +
          this.$f7.data.user.iD_SALARIAT
      )
      .then((responseMesaje) => {
        var datele = responseMesaje;
        datele.data.map((mesaj) => {
          mesaj["Id"] = mesaj.iD_MESAJ;
          mesaj["Expeditor"] = mesaj.dE_LA;
          mesaj["Destinatar"] = parseInt(mesaj.catre);
          mesaj["Mesaj"] = mesaj.continut;
          mesaj["Atasament"] = mesaj.atasment;
          return null;
        });

        var baza = this.$f7.methods.reconstructieBaza(
          datele,
          this.state.inputs
        );

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  if (baza.length > 0) {
                    this.refDataGrid.current.setRenderItems(baza);
                  }
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.refDataGrid.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  render() {
    return (
      <Page pageContent={false}>
        <DataGrid
          dataLoaded={this.state.dataLoaded}
          refresh={this.GetValues}
          name={"Mesaje"}
          onAdd={(state) => {
            var mesaj = { ...state.editedItem };
            if (mesaj.Destinatar !== null) {
              mesaj["dE_LA"] = this.$f7.data.user.iD_SALARIAT;
              mesaj["catre"] = mesaj.Destinatar.toString();
              mesaj["continut"] = mesaj.Mesaj;
              mesaj["atasment"] = mesaj.Atasament;
              delete mesaj.Id;
              delete mesaj.Expeditor;
              delete mesaj.Destinatar;
              this.AddValue(mesaj);
            } else {
              this.$f7.methods.openNotification(
                "Alegeti expeditor si destinatar!"
              );
            }
          }}
          ref={this.refDataGrid}
          renderUpdate={false}
          renderDelete={false}
          renderAdd={true}
          renderFilter={false}
          maxHeight={this.state.height}
          id="Id"
          inputs={this.state.inputs}
          dataSource={this.state.dataSource}
          ignoreInputsAdd={["Expeditor", "Data", "Atasament"]}
          ignoreInputsEdit={["Expeditor", "Data", "Atasament"]}
          keys={["Expeditor", "Destinatar", "Data", "Subiect", "Mesaj"]}
        />
      </Page>
    );
  }
}
export default Mesaje;
