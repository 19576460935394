import React from "react";
import TreeList from "devextreme-react/tree-list";
import axios from "axios";
import { Range } from "framework7-react";

/**
 * @description Randeaza lista de salariati de la itnretinerrea legaturii dintre proiect si salariati
 * @date 07/04/2022
 * @class SalariatiGrid
 * @extends {React.Component}
 */
class SalariatiGrid extends React.Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;
    this.state = {
      maxDepth: 0,
      idProiect: null,
      dataLoaded: false,
      selectedRowKeys: [],
      componentaTree: null,
      selectedRowsData: [],
      linkApiSalariatContracteModule: "api/SalariatContracteModule",
      inputs: [
        {
          key: "Id",
          keyBaza: "idSalariat",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
        { key: "Denumire", keyBaza: "denumire" },
        { key: "Checked", keyBaza: "Checked", tip: "number" },
        {
          key: "Ref",
          keyBaza: "refSalariat",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
      ],
    };
  }

  /**
   * @description Ia lista cu salariatii de sunt atasati la proiectul curent selectat
   * @function
   * @date 07/04/2022
   * @param {*} idProiect
   * @param {*} randeaza
   * @memberof SalariatiGrid
   */
  GetValues = (idProiect, randeaza) => {
    this.setState(
      {
        dataLoaded: false,
        idProiect: idProiect,
        randeaza: randeaza,
        selectedRowKeys: [],
        componentaTree: null,
      },
      () => {
        if (randeaza === true) {
          var url = "/api/Proiecte/GetPROIECTESalariati/" + idProiect;
          axios
            .get(url)
            .then((response) => {
              response.data.map((element) => {
                element = this.$f7.methods.restructurareBaza(
                  element,
                  this.state.inputs,
                  "key"
                );
                return null;
              });

              var bazaSalariati = this.$f7.methods.reconstructieBaza(
                response,
                this.state.inputs
              );
              var allSelectedRows = bazaSalariati.filter((r) => {
                return r.Checked === 1;
              });
              var selectedRowKeys = [];

              allSelectedRows.map((r) => {
                selectedRowKeys.push(r.Id);
                return null;
              });
              var max = this.$f7.methods.maxDepth(
                null,
                bazaSalariati,
                "Id",
                "Ref"
              );
              this.setState({
                dataSource: bazaSalariati,
                dataLoaded: true,
                selectedRowKeys: selectedRowKeys,
                selectedRowsData: allSelectedRows,
                maxDepth: max,
              });
            })
            .catch((error) => {
              this.$f7.methods.openNotification("Error:", error);
            });
        }
      }
    );
  };

  /**
   * @description Adauga salariatul selectat la proiectul selectat
   * @date 07/04/2022
   * @param {*} data obiectul ce contine referinta dintre salariat si proiect
   * @function
   * @memberof SalariatiGrid
   */
  AddValueSalariatContracteModule = (data) => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL +
        this.state.linkApiSalariatContracteModule +
        "/PostSALARIAT_CONTRACTE_MODULE";
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Sterge din baza de date daca utilizatorul era atasat la proiect dar nu mai trebuie sa fie
   * @date 07/04/2022
   * @function
   * @param {*} id id-ul salariatului
   * @memberof SalariatiGrid
   */
  DeleteValueSalariatContracteModule = (id) => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL +
        this.state.linkApiSalariatContracteModule +
        "/DeleteSALARIAT_CONTRACTE_MODULE/" +
        id;
      axios({
        headers: { "Content-Type": "application/json" },
        method: "delete",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Se ataseaza proiectul salariatilor selectati sau sterge daca exista deja
   * @function
   * @date 07/04/2022
   * @memberof SalariatiGrid
   */
  SaveValuesDevextreme = () => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiSalariatContracteModule +
          "/GetSALARIAT_CONTRACTE_MODULEByProiect/" +
          this.state.idProiect
      )
      .then((response) => {
        var date = response.data;
        var arrAdd = [];
        var arrDelete = [];
        this.state.selectedRowsData.map((r) => {
          if (
            date.filter((f) => {
              return r.Id === f.ref_salariat;
            }).length === 0
          ) {
            //ADAUGA IN BAZA ELEMENTUL obj!
            var obj = {
              ref_salariat: r.Id,
              ref_proiect: this.state.idProiect,
            };
            //add obj;
            arrAdd.push(obj);
          }
          return null;
        });
        //VERIFICA DACA EXISTA ELEMENTELE DIN BAZA DE DATE IN CELE SELECTATE, DACA NU, STERGE
        date.map((f) => {
          if (
            this.state.selectedRowsData.filter((r) => {
              return r.Id === f.ref_salariat;
            }).length === 0
          ) {
            //STERG DIN BAZA ELEMENTUL f.id_scm!
            var id = f.id_scm;
            arrDelete.push(id);
          }
          return null;
        });

        arrDelete
          .reduce((promise, item) => {
            return promise.then(() => {
              return this.DeleteValueSalariatContracteModule(item)
                .then((r) => {})
                .catch((err) => {
                  console.log("err", err);
                });
            });
          }, Promise.resolve())
          .then(() => {
            arrAdd
              .reduce((promise, item) => {
                return promise.then(() => {
                  return this.AddValueSalariatContracteModule(item)
                    .then((r) => {})
                    .catch((err) => {
                      console.log("err", err);
                    });
                });
              }, Promise.resolve())
              .then(() => {
                this.$f7.preloader.hide();
              })
              .catch(() => {
                console.log("Eroare");
                this.$f7.preloader.hide();
              });
          })
          .catch(() => {
            console.log("Eroare");
            this.$f7.preloader.hide();
          });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  /**
   * @description Deschide treelist la un anumit nivel
   * @function
   * @date 07/04/2022
   * @param {*} level
   * @memberof SalariatiGrid
   */
  expandToLevel = (level) => {
    this.setState({ level: level }, () => {
      this.$f7.preloader.show();
      this.state.componentaTree.forEachNode((nod) => {
        if (nod.level < this.state.level) {
          this.state.componentaTree.expandRow(nod.data.Id);
        } else {
          this.state.componentaTree.collapseRow(nod.data.Id);
        }
      });
      this.$f7.preloader.hide();
    });
  };

  render() {
    return this.state.randeaza && this.state.dataLoaded ? (
      <>
        {this.state.maxDepth > 0 ? (
          <Range
            label={true}
            className={"rangeDepth"}
            min={0}
            max={this.state.maxDepth}
            step={1}
            value={this.state.level}
            scale={true}
            scaleSteps={this.state.maxDepth}
            scaleSubSteps={1}
            onRangeChanged={(e) => {
              this.expandToLevel(e);
            }}
          />
        ) : null}
        <TreeList
          filterMode={"fullBranch"}
          onInitialized={(e) => {
            this.setState({
              componentaTree: e.component,
            });
          }}
          searchPanel={{
            visible: true,
            width: 160,
          }}
          selection={{ mode: "multiple" }}
          onSelectionChanged={(e) => {
            var s = [];
            var sData = [];
            s = e.selectedRowKeys;
            sData = e.selectedRowsData;
            this.setState(
              {
                selectedRowKeys: s,
                componentaTree: e.component,
                selectedRowsData: sData,
              },
              () => {
                this.SaveValuesDevextreme();
              }
            );
          }}
          selectedRowKeys={this.state.selectedRowKeys}
          showColumnHeaders={true}
          showColumnLines={true}
          height={window.innerHeight - 70}
          rootValue={null}
          dataSource={this.state.dataSource}
          showBorders={true}
          keyExpr={"Id"}
          parentIdExpr={"Ref"}
          id="TreeListProiecteModuleDreapta"
          columns={[
            {
              minWidth: 100,
              dataField: "Denumire",
              caption: "Denumire",
            },
          ]}
        />
      </>
    ) : (
      //<div>Selectati un proiect,subproiect sau modul.</div>
      <div
        style={{
          position: "relative",
          height: "calc(100vh - 70px)",
        }}>
        <h4
          style={{
            position: "absolute",
            top: "50%",
            left: 0,
            right: 0,
            transform: "translateY(-50%)",
            textTransform: "uppercase",
            textAlign: "center",
            display: "block",
          }}>
          Selectati un proiect, subproiect sau modul
        </h4>
      </div>
    );
  }
}
export default SalariatiGrid;
