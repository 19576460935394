import React from "react";
import {
  Page,
  Row,
  Col,
  Card,
  CardContent,
  Block,
  BlockTitle
} from "framework7-react";
import config from "../../config";
var settings = {
  width: "100",
  xsmall: "100",
  small: "100",
  medium: "50",
  large: "50",
  xlarge: "50"
};
var blockStyle = {
  wordBreak: "break-word",
  margin: "0px 0px 15px 0px"
};
/**
 * @description Pagina de randeaza o eroare atunci cand router-ul acceseaza o adresa de nu este adaugata in route
 * @date 06/04/2022
 * @class NotFoundPage
 * @extends {React.PureComponent}
 */
class NotFoundPage extends React.PureComponent {
  render() {
    return (
      <Page
        onPageInit={() => {
          this.$f7.data.paginaCurenta = this;
        }}
      >
        <div className={"ptr2"}>
          <div className={"ptr-ignore"}>
            <Row style={{ height: "100%" }}>
              <Col
                className="col-center-content topImage"
                {...settings}
                style={{
                  position: "relative",
                  height: "100%",
                  display: "grid",
                  alignItems: "center"
                }}
              >
                <div
                  style={{ backgroundImage: 'url("./img/imagineLogin.webp")' }}
                  className="imagineCover"
                />
              </Col>
              <Col {...settings} className="col-center-content bottomForm">
                <Card
                  className={"cardForm"}
                  title={
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        margin: 0
                      }}
                    >
                      <b>Not found</b>
                    </p>
                  }
                  style={{ width: "100%", padding: 0 }}
                >
                  <CardContent style={{ padding: 0 }}>
                    <Block inset style={blockStyle}>
                      <BlockTitle>Error</BlockTitle>
                      {config.notFound}
                    </Block>
                  </CardContent>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Page>
    );
  }
}
export default NotFoundPage;