import React, { Suspense } from "react";
import { Page, Popup, Navbar, Preloader } from "framework7-react";

/**
 * @description Popup dinamic care primeste ca parametru o componenta care sa o randeze inauntru
 * @date 08/04/2022
 * @class PopupDinamic
 * @extends {React.PureComponent}
 */
class PopupDinamic extends React.PureComponent {
  render() {
    return (
      <Popup
        className={"PopupDinamic"}
        closeOnEscape={true}
        tabletFullscreen={true}
        onPopupClose={() => {
          if (this.props.onPopupClose !== undefined) {
            this.props.onPopupClose(this);
          }
        }}>
        <Page style={{ overflow: "hidden" }}>
          <Navbar
            backLink
            title={this.props.title !== undefined ? this.props.title : "Info"}
            noShadow
            sliding={false}></Navbar>
          <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
            <Suspense
              fallback={
                <div
                  style={{
                    width: "35px",
                    margin: "0 auto",
                  }}>
                  <Preloader
                    size={40}
                    color={"var(--f7-theme-color)"}></Preloader>
                </div>
              }>
              {this.props.component}
            </Suspense>
          </div>
        </Page>
      </Popup>
    );
  }
}
export default PopupDinamic;
