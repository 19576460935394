import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";
import { Row, Col, Preloader } from "framework7-react";

/**
 * @description Interfata de adaugare a contractelor si a modulelor contractelor
 * @date 07/04/2022
 * @class ContracteModule
 * @extends {React.Component}
 */
class ContracteModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoadedContracte: false,
      dataLoadedModule: false,
      linkApiContracte: "/api/ContracteNoi",
      linkApiProiecte: "/api/Proiecte",
      linkApiClient: "/api/Firme",
      denumire: "Contracte/Module",
      height: window.innerHeight - 44,
      dataSourceContracte: [],
      dataSourceModule: [],
      inputsContracte: [
        { key: "Id", keyBaza: "id_contract", tip: "number" },
        {
          key: "NrContractParinte",
          keyBaza: "ref_contract",
          tip: "selectbox",
          lista: this.$f7.data.ContracteNoi,
        },
        { key: "NrContract", keyBaza: "nr_contract", requiredAdd: true },
        {
          key: "Client",
          keyBaza: "id_client",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
          requiredAdd: true,
        },
        {
          key: "Angajator",
          keyBaza: "id_angajator",
          tip: "selectbox",
          lista: this.$f7.data.Angajatori,
          requiredAdd: true,
        },
        {
          key: "DataContract",
          keyBaza: "data_contract",
          tip: "datebox",
          requiredAdd: true,
        },
        {
          key: "DataInceput",
          keyBaza: "data_inceput",
          tip: "datebox",
          requiredAdd: true,
        },
        { key: "DataSfarsit", keyBaza: "data_sfarsit", tip: "datebox" },
        {
          key: "Valoare",
          keyBaza: "valoare_contract",
          tip: "decimal",
          requiredAdd: true,
        },
        {
          key: "Valuta",
          keyBaza: "id_tip_valuta",
          tip: "selectbox",
          lista: this.$f7.data.TipValuta,
          requiredAdd: true,
        },
        {
          key: "GarantieExecutieInitialaProcent",
          keyBaza: "garantie_executie_initiala_procent",
          tip: "decimal",
        },
        {
          key: "GarantieExecutieInitialaValoare",
          keyBaza: "garantie_executie_initiala_valoare",
          tip: "decimal",
        },
        {
          key: "GarantieExecutieLunaraProcent",
          keyBaza: "garantie_executie_lunara_procent",
          tip: "decimal",
        },
        {
          key: "GarantieExecutieLunaraValoare",
          keyBaza: "garantie_executie_lunara_valoare",
          tip: "decimal",
        },
        { key: "SlaMinor", keyBaza: "sla_minor", tip: "number" },
        { key: "SlaMediu", keyBaza: "sla_mediu", tip: "number" },
        { key: "SlaMajor", keyBaza: "sla_major", tip: "number" },
        { key: "SlaCritic", keyBaza: "sla_critic", tip: "number" },
        {
          key: "DataExpirareGarantie",
          keyBaza: "data_expirare_garantie",
          tip: "datebox",
          requiredAdd: true,
        },
        {
          key: "TermenGarantie",
          keyBaza: "termen_garantie_luni",
          tip: "number",
          requiredAdd: true,
        },
        { key: "Abonament", keyBaza: "tip_abonament", tip: "bool" },
        { key: "PenalitatiNoi", keyBaza: "penalitati_noi", tip: "decimal" },
        { key: "PenalitatiEi", keyBaza: "penalitati_ei", tip: "decimal" },
        { key: "Comentariu", keyBaza: "comentariu" },
        {
          key: "Departament",
          keyBaza: "id_departament",
          tip: "selectbox",
          lista: this.$f7.data.Departamente,
          requiredAdd: true,
        },
        { key: "RefProiect", keyBaza: "ref_proiect", tip: "number" },
      ],
      inputsModule: [
        { key: "Id", keyBaza: "value", tip: "number" },
        { key: "Denumire", keyBaza: "key" },
      ],
      idContract: null,
      idClient: null,
    };

    this.refDataGridContracte = React.createRef();
    this.refDataGridModule = React.createRef();
    this.ref1 = React.createRef();
    this.ref2 = React.createRef();
  }

  /**
   * @description Ia toate contractele pentru randare
   * @function
   * @date 07/04/2022
   * @memberof ContracteModule
   */
  GetValuesContracte = () => {
    this.setState({
      dataLoadedContracte: false,
      idContract: null,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApiContracte)
      .then((response) => {
        this.$f7.methods.IaValori();
        var date = response;

        date.data.map((item) => {
          item = this.$f7.methods.restructurareBaza(
            item,
            this.state.inputsContracte,
            "key"
          );
          return false;
        });

        var baza = this.$f7.methods.reconstructieBaza(
          date,
          this.state.inputsContracte
        );

        this.setState(
          {
            dataSourceContracte: baza,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoadedContracte: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSourceContracte: [],
            dataLoadedContracte: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesContracte();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Actualizeaza valorile unui contract selectat
   * @date 07/04/2022
   * @param {*} obj noile valori ale contractului
   * @param {*} id contractul selectat
   * @memberof ContracteModule
   */
  UpdateValueContracte = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApiContracte + "/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: this.$f7.data.baseURL + this.state.linkApiContracte + "/" + id,
        })
          .then((response) => {
            axios
              .get(
                this.$f7.data.baseURL +
                  this.state.linkApiClient +
                  "/" +
                  obj.id_client
              )
              .then((responseClient) => {
                axios
                  .get(
                    this.$f7.data.baseURL +
                      this.state.linkApiProiecte +
                      "/GetPROIECTE/" +
                      obj.ref_proiect
                  )
                  .then((responseProiect) => {
                    let dataProiect = responseProiect.data;
                    dataProiect.denumire = "Contract nr. " + obj.nr_contract;
                    dataProiect.reF_PROIECT = responseClient.data.ref_proiect;

                    axios({
                      data: dataProiect,
                      headers: { "Content-Type": "application/json" },
                      method: "put",
                      url:
                        this.$f7.data.baseURL +
                        this.state.linkApiProiecte +
                        "/PutPROIECTE/" +
                        dataProiect.iD_PROIECT,
                    })
                      .then((responseUpdateProiect) => {
                        this.GetValuesContracte();
                        this.$f7.methods.openNotification(
                          "Modificat cu success"
                        );
                      })
                      .catch((error) => {
                        console.log("error", error);
                      });
                  })
                  .catch((error) => {
                    console.log("error", error);
                  });
              })
              .catch((error) => {
                console.log("error", error);
              });
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Ia modulele atasate unui contract
   * @function
   * @date 07/04/2022
   * @memberof ContracteModule
   */
  GetValuesContracteModule = () => {
    this.setState({
      dataLoadedModule: false,
    });

    let module = [];

    this.$f7.data.nearhivateProiecte
      .filter((modul) => {
        return (
          modul.id_tip_proiect === 59 && modul.id_client === this.state.idClient
        );
      })
      .forEach((modul) => {
        module.push({
          key: modul.denumire,
          value: modul.iD_PROIECT,
        });
      });

    module.map((item) => {
      item = this.$f7.methods.restructurareBaza(
        item,
        this.state.inputsModule,
        "key"
      );
      return false;
    });

    this.setState(
      {
        dataSourceModule: module,
      },
      () => {
        setTimeout(() => {
          this.setState({
            dataLoadedModule: true,
          });
        }, 400);
      }
    );
  };

  componentDidMount = () => {
    this.GetValuesContracte();
  };

  render() {
    return this.state.dataLoadedContracte ? (
      <>
        <Row>
          <Col
            ref={this.ref1}
            onGridResize={() => {
              localStorage.setItem(
                "ContracteModuleWidthStanga",
                this.ref1.current.eventTargetEl.style.width
              );
            }}
            style={{
              width:
                localStorage.getItem("ContracteModuleWidthStanga") === null
                  ? "49%"
                  : localStorage.getItem("ContracteModuleWidthStanga"),
            }}
            resizable>
            <DataGrid
              name={"ContracteModule1"}
              ref={this.refDataGridContracte}
              id={"Id"}
              //ignoreInputsAdd={["Ref_Firma"]}
              ignoreInputsEdit={["RefProiect"]}
              renderOptions={true}
              //renderAdd={true}
              renderUpdate={true}
              //renderDelete={true}
              //renderAccordion={true}

              inputs={this.state.inputsContracte}
              dataSource={this.state.dataSourceContracte}
              maxHeight={this.state.height}
              title={"Contracte"}
              onRowClick={(state) => {
                this.setState(
                  {
                    idContract: state.rowData.Id,
                    idClient: state.rowData.Client,
                  },
                  () => {
                    this.GetValuesContracteModule();
                  }
                );
              }}
              onUpdate={(state) => {
                var item = { ...state.editedItem };
                item = this.$f7.methods.restructurareBaza(
                  item,
                  this.state.inputsContracte,
                  "keyBaza"
                );

                this.UpdateValueContracte(item, item.id_contract);
              }}
            />
          </Col>
          <Col
            ref={this.ref2}
            onGridResize={() => {
              localStorage.setItem(
                "ContracteModuleWidthDreapta",
                this.ref2.current.eventTargetEl.style.width
              );
            }}
            style={{
              position: "relative",
              width:
                localStorage.getItem("ContracteModuleWidthDreapta") === null
                  ? "49%"
                  : localStorage.getItem("ContracteModuleWidthDreapta"),
            }}
            resizable>
            {this.state.idContract !== null ? (
              this.state.dataLoadedModule ? (
                <DataGrid
                  name={"ContracteModule2"}
                  ref={this.refDataGridModule}
                  id={"Id"}
                  ignoreInputsAdd={["RefContract"]}
                  ignoreInputsUpdate={["RefContract"]}
                  inputs={this.state.inputsModule}
                  dataSource={this.state.dataSourceModule}
                  maxHeight={this.state.height}
                  title={"Module"}
                />
              ) : (
                <div
                  style={{
                    position: "absolute",
                    width: "35px",
                    height: "35px",
                    top: "calc(50vh - 100px)",
                    left: "50%",
                  }}>
                  <Preloader size={40} color={"var(--f7-theme-color)"} />
                </div>
              )
            ) : (
              <div
                style={{
                  marginTop: "calc(50vh - 72px)",
                  fontSize: "20px",
                  textAlign: "center",
                }}>
                Nu este nici un contract selectat
              </div>
            )}
          </Col>
        </Row>
      </>
    ) : (
      <div
        style={{
          position: "absolute",
          width: "35px",
          height: "35px",
          top: "50vh",
          left: "50%",
        }}>
        <Preloader size={40} color={"var(--f7-theme-color)"} />
      </div>
    );
  }
}
export default ContracteModule;
