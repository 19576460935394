import React, { Component } from "react";
import { Page } from "framework7-react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";

class Help extends Component {
    constructor(props) {
        super(props);
        this.height = window.innerHeight;
        this.Salariati = this.$f7.data.toggleTotiSalariatii ? this.$f7.data.Salariati : this.$f7.data.SalariatiActivi;
        this.state = {
            dataLoaded: false,
            height: window.innerHeight - 44,
            linkApiDocumenteDelete: "/api/Documente/DeleteDOCUMENTE",
            linkApiDocumenteById: "/api/Documente/GetDOCUMENTEbyId",
            linkApiDocumenteUpdate: "/api/Documente/PutDOCUMENTE",
            linkApiDocumenteAdd: "/api/Documente/PostDOCUMENTE",
            linkApiDocumente: "/api/Documente/GetDOCUMENTEbyProiectId2",
            linkApiSalariati: "/api/Salariati/GetSALARIATI",
            dataSourceHelp: [],
            input: [
                { key: "IdDocument" },
                {
                    key: "Autor",
                    tip: "selectbox",
                    lista: this.$f7.data.Salariati,
                    listaEdit: this.Salariati,
                    requiredAdd: true,
                    requiredEdit: true
                },
                {
                    key: "Data", tip: "datebox",
                    requiredAdd: true,
                    requiredEdit: true
                },
                { key: "LinkFisier", tip: "filepicker" },
                { key: "Comentariu" },
            ],
        };

        this.refDataGridHelp = React.createRef();
    }

    GetValues = () => {
        this.setState({
            dataLoaded: false,
        });
        const requestDocumente = axios.get(
            this.$f7.data.baseURL +
            this.state.linkApiDocumente +
            "/" +
            this.$f7.data.Proiect.iD_PROIECT
        );

        axios
            .all([requestDocumente])
            .then(
                axios.spread((responseDocumente) => {
                    let change = responseDocumente;

                    for (let item in change.data) {
                        change.data[item]["IdDocument"] = change.data[item].iD_DOCUMENT;
                        change.data[item]["Autor"] = change.data[item].saL_AUTOR;
                        change.data[item]["Data"] =
                            change.data[item].datA_MODIF === null ||
                                change.data[item].datA_MODIF === ""
                                ? null
                                : change.data[item].datA_MODIF;
                        change.data[item]["LinkFisier"] =
                            change.data[item].numE_PATH === null ||
                                change.data[item].numE_PATH === ""
                                ? null
                                : change.data[item].numE_PATH;
                        change.data[item]["Comentariu"] =
                            change.data[item].comentariu === null ||
                                change.data[item].comentariu === ""
                                ? "Fara"
                                : change.data[item].comentariu;
                    }

                    let baza = this.$f7.methods.reconstructieBaza(
                        change,
                        this.state.input
                    );

                    this.setState(
                        {
                            dataSourceHelp: baza.length === 0 ? [] : baza,
                        },
                        () => {
                            setTimeout(() => {
                                this.setState(
                                    {
                                        dataLoaded: true,
                                    },
                                    () => {
                                        this.refDataGridHelp.current.setRenderItems(baza);
                                    }
                                );
                            }, 400);
                        }
                    );
                })
            )
            .catch((error) => {
                this.setState(
                    {
                        dataSourceHelp: [],
                        dataLoaded: true,
                    },
                    () => {
                        this.refDataGridHelp.current.setRenderItems([]);
                        this.$f7.dialog.confirm(
                            "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                            "Ups...",
                            () => {
                                this.GetValues();
                            },
                            () => { }
                        );
                    }
                );

                console.log("error", error);
            });
    };

    AddValue = (data) => {
        var url = this.$f7.data.baseURL + this.state.linkApiDocumenteAdd;
        axios({
            data: data,
            headers: { "Content-Type": "application/json" },
            method: "post",
            url: url,
        })
            .then((response) => {
                this.GetValues();
                this.$f7.methods.openNotification("Adaugat cu success");
            })
            .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la adaugare");
            });
    };

    UpdateValue = (obj, id) => {
        axios
            .get(this.$f7.data.baseURL + this.state.linkApiDocumenteById + "/" + id)
            .then((response) => {
                var obj2 = response.data;
                Object.keys(obj).map((trasatura) => {
                    Object.keys(obj2).map((trasatura2) => {
                        if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
                            obj2[trasatura2] = obj[trasatura];
                        }
                        return false;
                    });
                    return false;
                });

                var url =
                    this.$f7.data.baseURL + this.state.linkApiDocumenteUpdate + "/" + id;
                axios({
                    data: obj2,
                    headers: { "Content-Type": "application/json" },
                    method: "put",
                    url: url,
                })
                    .then((response) => {
                        this.GetValues();
                        this.$f7.methods.openNotification("Modificat cu success");
                    })
                    .catch((error) => {
                        console.log("error", error);
                        this.$f7.methods.openNotification("Eroare la modificare");
                    });
            })
            .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la modificare");
            });
    };

    DeleteValue = (id) => {
        var url =
            this.$f7.data.baseURL + this.state.linkApiDocumenteDelete + "/" + id;
        axios({
            headers: { "Content-Type": "application/json" },
            method: "delete",
            url: url,
        })
            .then((response) => {
                this.GetValues();
                this.$f7.methods.openNotification("Sters cu success");
            })
            .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la stergere");
            });
    };

    componentDidMount = () => {
        if (this.$f7.data.Proiect !== null) this.GetValues();
    };

    render() {
        return (
            <Page pageContent={false}>
                {this.$f7.data.Proiect !== null ?
                    <DataGrid
                        dataLoaded={this.state.dataLoaded}
                        refresh={this.GetValues}
                        name={"Help"}
                        ref={this.refDataGridHelp}
                        renderUpdate={true}
                        renderDelete={true}
                        renderAdd={true}

                        maxHeight={this.state.height}
                        dataSource={this.state.dataSourceHelp}
                        inputs={this.state.input}
                        onAdd={(state) => {
                            let data = { ...state.editedItem };
                            var fisier = data.LinkFisier;
                            data["saL_AUTOR"] = data.Autor;
                            data["datA_MODIF"] = data.Data;
                            data["numE_PATH"] =
                                fisier !== null && fisier !== "" && fisier !== undefined
                                    ? fisier.name
                                    : null;
                            data["iD_PROIECT"] = this.$f7.data.Proiect.iD_PROIECT;
                            delete data.IdDocument;
                            delete data.Autor;
                            delete data.Data;
                            delete data.LinkFisier;
                            var file = fisier;

                            if (file !== null && file !== "" && file !== undefined) {
                                var reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                    var res = reader.result;
                                    data["continut"] = res.split(",")[1];
                                    this.AddValue(data);
                                };
                                reader.onerror = (error) => {
                                    console.log("Error: ", error);
                                };
                            } else {
                                data["continut"] = null;
                                this.AddValue(data);
                            }
                        }}
                        onUpdate={(state) => {
                            let data = { ...state.editedItem };

                            data["iD_DOCUMENT"] = data.IdDocument;
                            data["saL_AUTOR"] = data.Autor;
                            data["datA_MODIF"] = data.Data;
                            data["numE_PATH"] =
                                data.LinkFisier !== null
                                    ? typeof data.LinkFisier === "string"
                                        ? data.LinkFisier
                                        : data.LinkFisier.name
                                    : "";
                            data["iD_PROIECT"] = this.$f7.data.Proiect.iD_PROIECT;
                            var file = data.LinkFisier;
                            delete data.IdDocument;
                            delete data.Autor;
                            delete data.Data;
                            delete data.LinkFisier;

                            if (
                                file !== null &&
                                file !== "" &&
                                file !== undefined &&
                                typeof file !== "string"
                            ) {
                                var reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => {
                                    var res = reader.result;
                                    data["continut"] = res.split(",")[1];
                                    this.UpdateValue(data, data.iD_DOCUMENT);
                                };
                                reader.onerror = (error) => {
                                    console.log("Error: ", error);
                                };
                            } else {
                                data["continut"] = null;
                                this.UpdateValue(data, data.iD_DOCUMENT);
                            }
                        }}
                        onDelete={(state) => {
                            let data = { ...state.editedItem };
                            this.DeleteValue(data.IdDocument);
                        }}
                        ignoreInputsAdd={["IdDocument"]}
                    />
                    :
                    <div style={{
                        position: "relative",
                        height: "calc(100vh - 50px)"
                    }}>
                        <h3 style={{
                            position: "absolute",
                            top: "50%",
                            left: 0,
                            right: 0,
                            transform: "translateY(-50%)",
                            textTransform: "uppercase",
                            textAlign: "center",
                            display: "block"
                        }}>
                            Nu ati selectat nimic
                        </h3>
                    </div>
                }
            </Page>
        );
    }
}
export default Help;