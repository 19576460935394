import React from "react";
import { Navbar, NavRight, Link } from "framework7-react";
import SarciniPrimiteGrid from "./Proiecte/Agenda/SarciniPrimiteGrid";
import DraggingPanel from "./DraggingPanel";

/**
 * @description Popoverul in care se randeaza notificarile(taskuri primite)
 * @date 08/04/2022
 * @class NotificationsPopover
 * @extends {React.PureComponent}
 */
class NotificationsPopover extends React.PureComponent {
  constructor(props) {
    super(props);
    this.denumirePopover = props.denumire;
    this.state = {
      dataSourceProiecte: props.dataSource,
      dataSourcePrimite: [],
      dataSourceNoiNealocate: [],
      tabActiv: "TabNotificariPrimite" + this.denumirePopover,
    };
    this.refSarciniPrimite = React.createRef();
  }

  /**
   * @description Apeleaza luarea de sarcini primite
   * @function
   * @date 08/04/2022
   * @memberof NotificationsPopover
   */
  GetValues = () => {
    this.refSarciniPrimite.current.GetValues();
    if (this.refSarciniPrimite) {
      if (this.refSarciniPrimite.current.refDataGrid) {
        var table = document.getElementById(
          this.refSarciniPrimite.current.refDataGrid.current.tableName
        );
        this.refSarciniPrimite.current.refDataGrid.current.resizableGrid(table);
      }
    }
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return (
      <>
        <DraggingPanel
          closeByBackdropClick={false}
          backdrop={false}
          resizable
          onPanelOpen={() => {
            this.GetValues();
          }}
          style={{
            width: "55vw",
            top: 0,
            zIndex: 10000,
            height: "98vh",
          }}
          className={this.denumirePopover}>
          <Navbar
            className={"navbar-move"}
            style={{ cursor: "move" }}
            title={"Notificari"}>
            <NavRight>
              <Link
                tooltip={"Inchide"}
                onClick={() => {
                  if (this.props.onCloseClicked !== undefined) {
                    this.props.onCloseClicked(this.state);
                  }
                  this.$f7.panel.close("." + this.denumirePopover);
                }}>
                Inchide
              </Link>
            </NavRight>
          </Navbar>
          <SarciniPrimiteGrid
            nuEsteDinAgenda={true}
            ref={this.refSarciniPrimite}
            checkedTaskuri={true}
            checkedArhivate={true}
            checkedAscunse={true}
          />
        </DraggingPanel>
      </>
    );
  }
}

export default NotificationsPopover;
