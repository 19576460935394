import React from "react";
import {
    Link,
    Toolbar,
    Tab,
    Tabs,
    Segmented,
    Button,
    Icon
} from "framework7-react";
import DescriereProiect from "./DescriereProiect";
import IstoricModificari from "./IstoricModificari";
import CodProiect from "./CodProiect";
import Cerinte from "./Cerinte";
import Verificari from "./Verificari";
import Documentare from "./Documentare";
import Help from "./Help";
import ToateDoc from "./ToateDoc";
import EroriRezolvari from "./EroriRezolvari";

class Tehnice extends React.Component {
    constructor(props) {
        super(props);

        let denumireTab = Math.random()
            .toString(36)
            .substring(7)
            .toString();

        this.state = {
            denumireTab: denumireTab,
            toolbarIndex: 0,
            activeTab: "tab-DescriereProiect" + denumireTab
        };
    }

    focusToolbar = upDown => {
        var length =
            document
                .getElementById("tehnice-tbar")
                .getElementsByClassName("toolbar-inner")[0]
                .getElementsByTagName("a").length - 1;

        this.setState(
            {
                toolbarIndex:
                    upDown === "up"
                        ? this.state.toolbarIndex + 1 > length
                            ? this.state.toolbarIndex
                            : this.state.toolbarIndex + 1
                        : this.state.toolbarIndex - 1 < 0
                            ? 0
                            : this.state.toolbarIndex - 1
            },
            () => {
                document
                    .getElementById("tehnice-tbar")
                    .getElementsByClassName("toolbar-inner")[0]
                    .getElementsByTagName("a")[this.state.toolbarIndex].focus();
            }
        );
    };

    componentDidMount = () => { };

    render() {
        return (
            <>
                <Segmented
                    raised
                    tag="div"
                    style={{
                        backgroundColor: "var(--f7-theme-color)",
                        alignItems: "center",
                        borderRadius: "0px 0px 16px 16px"
                    }}
                >
                    <Button
                        tooltip={"Inapoi"}
                        style={{
                            minWidth: "40px",
                            width: "40px",
                            color: "white",
                            backgroundColor: "var(--f7-theme-color)"
                        }}
                        onClick={() => {
                            this.focusToolbar("down");
                        }}
                    >
                        <Icon
                            style={{ color: "white" }}
                            size={27}
                            material="navigate_before"
                        />
                    </Button>

                    <Toolbar
                        id="tehnice-tbar"
                        tabbar={true}
                        scrollable={true}
                        noShadow
                        top
                    >
                        <Link
                            tooltip={"Descrierea proiectului"}
                            tabLink={"#tab-DescriereProiect" + this.state.denumireTab}
                            tabLinkActive={
                                this.state.activeTab ===
                                "tab-DescriereProiect" + this.state.denumireTab
                            }
                            onClick={() => {
                                this.setState({
                                    activeTab: "tab-DescriereProiect" + this.state.denumireTab
                                });
                            }}
                        >
                            Descriere Proiect
                        </Link>
                        <Link
                            tooltip={"Istoricul modificarilor"}
                            tabLink={"#tab-IstoricModificari" + this.state.denumireTab}
                            tabLinkActive={
                                this.state.activeTab ===
                                "tab-IstoricModificari" + this.state.denumireTab
                            }
                            onClick={() => {
                                this.setState({
                                    activeTab: "tab-IstoricModificari" + this.state.denumireTab
                                });
                            }}
                        >
                            Istoric Modificari
                        </Link>
                        <Link
                            tooltip={"Cerinte task"}
                            tabLink={"#tab-Cerinte" + this.state.denumireTab}
                            tabLinkActive={
                                this.state.activeTab === "tab-Cerinte" + this.state.denumireTab
                            }
                            onClick={() => {
                                this.setState({
                                    activeTab: "tab-Cerinte" + this.state.denumireTab
                                });
                            }}
                        >
                            Cerinte
                        </Link>
                        <Link
                            tooltip={"Cod proiect"}
                            tabLink={"#tab-CodProiect" + this.state.denumireTab}
                            tabLinkActive={
                                this.state.activeTab ===
                                "tab-CodProiect" + this.state.denumireTab
                            }
                            onClick={() => {
                                this.setState({
                                    activeTab: "tab-CodProiect" + this.state.denumireTab
                                });
                            }}
                        >
                            Cod Proiect
                        </Link>
                        <Link
                            tooltip={"Verificari"}
                            tabLink={"#tab-Verificari" + this.state.denumireTab}
                            tabLinkActive={
                                this.state.activeTab ===
                                "tab-Verificari" + this.state.denumireTab
                            }
                            onClick={() => {
                                this.setState({
                                    activeTab: "tab-Verificari" + this.state.denumireTab
                                });
                            }}
                        >
                            Verificari
                        </Link>
                        <Link
                            tooltip={"Documentare"}
                            tabLink={"#tab-Documentare" + this.state.denumireTab}
                            tabLinkActive={
                                this.state.activeTab ===
                                "tab-Documentare" + this.state.denumireTab
                            }
                            onClick={() => {
                                this.setState({
                                    activeTab: "tab-Documentare" + this.state.denumireTab
                                });
                            }}
                        >
                            Documentare
                        </Link>
                        <Link
                            tooltip={"Help"}
                            tabLink={"#tab-Help" + this.state.denumireTab}
                            tabLinkActive={
                                this.state.activeTab === "tab-Help" + this.state.denumireTab
                            }
                            onClick={() => {
                                this.setState({
                                    activeTab: "tab-Help" + this.state.denumireTab
                                });
                            }}
                        >
                            Help
                        </Link>
                        <Link
                            tooltip={"Toate doc"}
                            tabLink={"#tab-ToateDoc" + this.state.denumireTab}
                            tabLinkActive={
                                this.state.activeTab === "tab-ToateDoc" + this.state.denumireTab
                            }
                            onClick={() => {
                                this.setState({
                                    activeTab: "tab-ToateDoc" + this.state.denumireTab
                                });
                            }}
                        >
                            Toate Doc
                        </Link>
                        <Link
                            tooltip={"Erori si rezolvari"}
                            tabLink={"#tab-EroriRezolvari" + this.state.denumireTab}
                            tabLinkActive={
                                this.state.activeTab ===
                                "tab-EroriRezolvari" + this.state.denumireTab
                            }
                            onClick={() => {
                                this.setState({
                                    activeTab: "tab-EroriRezolvari" + this.state.denumireTab
                                });
                            }}
                        >
                            Erori si rezolvari
                        </Link>
                    </Toolbar>

                    <Button
                        tooltip={"Inainte"}
                        style={{
                            minWidth: "40px",
                            width: "40px",
                            color: "white",
                            backgroundColor: "var(--f7-theme-color)"
                        }}
                        onClick={() => {
                            this.focusToolbar("up");
                        }}
                    >
                        <Icon
                            style={{ color: "white" }}
                            size={27}
                            material="navigate_next"
                        />
                    </Button>
                </Segmented>

                <Tabs>
                    <Tab
                        id={"tab-DescriereProiect" + this.state.denumireTab}
                        //className="page-content"
                        tabActive={
                            this.state.activeTab ===
                            "tab-DescriereProiect" + this.state.denumireTab
                        }
                        style={{ overflowY: "auto", height: "calc(100vh - 175px)" }}
                    >
                        {this.state.activeTab ===
                            "tab-DescriereProiect" + this.state.denumireTab ? (
                            <DescriereProiect />
                        ) : null}
                    </Tab>
                    <Tab
                        id={"tab-IstoricModificari" + this.state.denumireTab}
                        //className="page-content"
                        tabActive={
                            this.state.activeTab ===
                            "tab-IstoricModificari" + this.state.denumireTab
                        }
                    >
                        {this.state.activeTab ===
                            "tab-IstoricModificari" + this.state.denumireTab ? (
                            <IstoricModificari />
                        ) : null}
                    </Tab>
                    <Tab
                        id={"tab-Cerinte" + this.state.denumireTab}
                        //className="page-content"
                        tabActive={
                            this.state.activeTab === "tab-Cerinte" + this.state.denumireTab
                        }
                    >
                        {this.state.activeTab === "tab-Cerinte" + this.state.denumireTab ? (
                            <Cerinte />
                        ) : null}
                    </Tab>
                    <Tab
                        id={"tab-CodProiect" + this.state.denumireTab}
                        //className="page-content"
                        tabActive={
                            this.state.activeTab === "tab-CodProiect" + this.state.denumireTab
                        }
                    >
                        {this.state.activeTab ===
                            "tab-CodProiect" + this.state.denumireTab ? (
                            <CodProiect />
                        ) : null}
                    </Tab>
                    <Tab
                        id={"tab-Verificari" + this.state.denumireTab}
                        //className="page-content"
                        tabActive={
                            this.state.activeTab === "tab-Verificari" + this.state.denumireTab
                        }
                    >
                        {this.state.activeTab ===
                            "tab-Verificari" + this.state.denumireTab ? (
                            <Verificari />
                        ) : null}
                    </Tab>
                    <Tab
                        id={"tab-Documentare" + this.state.denumireTab}
                        //className="page-content"
                        tabActive={
                            this.state.activeTab ===
                            "tab-Documentare" + this.state.denumireTab
                        }
                    >
                        {this.state.activeTab ===
                            "tab-Documentare" + this.state.denumireTab ? (
                            <Documentare />
                        ) : null}
                    </Tab>
                    <Tab
                        id={"tab-Help" + this.state.denumireTab}
                        //className="page-content"
                        tabActive={
                            this.state.activeTab === "tab-Help" + this.state.denumireTab
                        }
                    >
                        {this.state.activeTab === "tab-Help" + this.state.denumireTab ? (
                            <Help />
                        ) : null}
                    </Tab>
                    <Tab
                        id={"tab-ToateDoc" + this.state.denumireTab}
                        //className="page-content"
                        tabActive={
                            this.state.activeTab === "tab-ToateDoc" + this.state.denumireTab
                        }
                    >
                        {this.state.activeTab ===
                            "tab-ToateDoc" + this.state.denumireTab ? (
                            <ToateDoc />
                        ) : null}
                    </Tab>
                    <Tab
                        id={"tab-EroriRezolvari" + this.state.denumireTab}
                        //className="page-content"
                        tabActive={
                            this.state.activeTab ===
                            "tab-EroriRezolvari" + this.state.denumireTab
                        }
                    >
                        {this.state.activeTab ===
                            "tab-EroriRezolvari" + this.state.denumireTab ? (
                            <EroriRezolvari />
                        ) : null}
                    </Tab>
                </Tabs>
            </>
        );
    }
}
export default Tehnice;