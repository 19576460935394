import React, { Component } from "react";
import DataGrid from "../../Datagrid/DataGrid";
import { ListInput, List, Row, Col, Icon } from "framework7-react";
import moment from "moment";
import * as inputs from "../../../../inputs";
import axios from "axios";
import GraficSalariat from "../../Proiecte/Agenda/GraficSalariat";

/**
 * @description Afiseaza un datagrid in partea din stanga cu toti salariatii din firma si cat a lucrat in intervalul selectat, si cate tasuri are alocate si rezolvate, in cazul in care se apasa pe alocate se deschide un popup cu taskurile alocate ale utilizatorului, in cazul in care se apasa pe rezolvate se deschide un popup cu taskurile inchise de catre utilizator.
 * @date 06/04/2022
 * @class OreLucratePeZi
 * @extends {Component}
 */
class OreLucratePeZi extends Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;

    this.state = {
      dataLoaded: false,
      height: window.innerHeight - 44,
      linkApi: "/api/OreLucrateZi/GetOreLucrate1",
      dataDeLa: [moment(Date.now()).format()],
      dataPanaLa: [moment(Date.now()).format()],
      dataSource: [],
      inputs: [
        { key: "Nume", tip: "selectbox", lista: this.$f7.data.Salariati },
        { key: "Ziua", tip: "datebox" },
        { key: "Timp", tip: "number" },
        { key: "TimpPontat", tip: "number" },
        { key: "ComSef", tip: "number" },
      ],
    };

    this.refDataGrid = React.createRef();
  }

  /**
   * @description Ia valorile necesare pentru randare de la endpointul this.$f7.data.baseURL +  this.state.linkApi +"/" +dataStart +"/" +dataEnd)” caruia i se transmit ca parametrii data de inceput si data de sfarsit(intervalul) din care sa ia proiectele/taskurile.
   * @function
   * @date 06/04/2022
   * @memberof OreLucratePeZi
   */
  GetValues = () => {
    //'mm-dd-yy'
    let initialStartData = new Date(this.state.dataDeLa[0]);
    let initialEndData = new Date(this.state.dataPanaLa[0]);
    const dataStart =
      initialStartData.getDate() +
      "-" +
      initialStartData.getMonth() +
      1 +
      "-" +
      initialStartData.getFullYear().toString().substring(2);
    const dataEnd =
      initialEndData.getDate() +
      "-" +
      initialEndData.getMonth() +
      1 +
      "-" +
      initialEndData.getFullYear().toString().substring(2);

    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/" +
          dataStart +
          "/" +
          dataEnd
      )
      .then((response) => {
        let change = response;

        for (let item in change.data) {
          change.data[item]["Nume"] =
            change.data[item].id_salariat === "" ||
            change.data[item].id_salariat === null
              ? "Fara"
              : change.data[item].id_salariat;
          change.data[item]["Ziua"] =
            change.data[item].ziua === null ? "Fara" : change.data[item].ziua;
          change.data[item]["Timp"] =
            change.data[item].timp === null ? "Fara" : change.data[item].timp;
          change.data[item]["TimpPontat"] =
            change.data[item].timp_pontat === null
              ? "Fara"
              : change.data[item].timp_pontat;
          change.data[item]["ComSef"] =
            change.data[item].com_sef === null
              ? "Fara"
              : change.data[item].com_sef;
        }

        let baza = this.$f7.methods.reconstructieBaza(
          change,
          inputs.activitateOreLucrate
        );

        this.setState(
          {
            dataSource: baza.length === 0 ? [] : baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  //this.refDataGrid.current.setRenderItems(baza)
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <List
          noChevron
          noHairlines
          noHairlinesBetween
          noHairlinesBetweenMd
          noHairlinesMd
          className="optiuniActivitate butoaneContextDatagrid"
          style={{
            position: "absolute",
            top: -8,
            left: 0,
            width: "calc(100% - 176px)",
            margin: 0,
          }}>
          <Row style={{ width: "100%" }}>
            <Col>
              <ListInput
                validate
                required
                label="De la"
                type="datepicker"
                calendarParams={{
                  timePicker: true,
                  dateFormat: "dd.mm.yyyy",
                  closeOnSelect: true,
                  on: {
                    closed: (e) => {
                      const val = e.value[0];
                      this.setState(
                        {
                          dataDeLa: [val],
                        },
                        () => {
                          this.GetValues();
                        }
                      );
                    },
                  },
                }}
                value={this.state.dataDeLa}
                floatingLabel
                placeholder="De la">
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="today"
                  slot="content-start"
                  style={{ left: 0, top: 0 }}
                />
              </ListInput>
            </Col>
            <Col>
              <ListInput
                validate
                required
                label="Pana la"
                type="datepicker"
                calendarParams={{
                  timePicker: true,
                  dateFormat: "dd.mm.yyyy",
                  closeOnSelect: true,
                  on: {
                    closed: (e) => {
                      const val = e.value[0];
                      this.setState(
                        {
                          dataPanaLa: [val],
                        },
                        () => {
                          this.GetValues();
                        }
                      );
                    },
                  },
                }}
                value={this.state.dataPanaLa}
                floatingLabel
                placeholder="Pana la">
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="today"
                  slot="content-start"
                  style={{ left: 0, top: 0 }}
                />
              </ListInput>
            </Col>
          </Row>
        </List>

        <DataGrid
          dataLoaded={this.state.dataLoaded}
          refresh={this.GetValues}
          name={"OreLucratePeZi"}
          ref={this.refDataGrid}
          renderAccordion={true}
          accordionItem={(state, rowItemData) => {
            return (
              <div style={{ height: 500 }}>
                {" "}
                <GraficSalariat
                  dataFiltrare={rowItemData.Ziua}
                  noSticky={true}
                  idSalariat={rowItemData.Nume}
                />{" "}
              </div>
            );
          }}
          renderUpdate={false}
          renderDelete={false}
          renderAdd={false}
          maxHeight={this.state.height}
          dataSource={this.state.dataSource}
          inputs={this.state.inputs}
        />
      </div>
    );
  }
}
export default OreLucratePeZi;