import React, { Component } from "react";
import AnswerCall from "./AnswerCall";

let currentId = "";

class VideoCall extends Component {
	constructor(props) {
		super(props);

		this.state = {
			connection: null,
			call: false,
			userCall: null,
			callerSignal: null,
			groupID: null,
			activeCall: false,
			isBusy: false,
		};
	}

	componentDidMount = () => {
		this.setState(
			{
				connection: this.$f7.data.signalRConnection,
			},
			() => {
				this.$f7.data.callComponent = this;
				console.log("connection:", this.state.connection);

				if (this.state.connection) {
					const grupuri = this.$f7.data.Salariati.find(
						(item) => item.value === this.$f7.data.user.iD_SALARIAT
					).grupuri;
					this.newUser(
						this.$f7.data.Salariati.find(
							(item) => item.value === this.$f7.data.user.iD_SALARIAT
						).key,
						this.$f7.data.user.iD_SALARIAT,
						grupuri !== null && grupuri !== undefined ? grupuri : ""
					);

					this.state.connection.on("CallIncome", async (user, signal) => {
						console.log("CallIncome:", user, signal);

						if (!this.state.activeCall) {
							this.setState(
								{
									userCall: user,
									callerSignal: signal,
								},
								() => {
									this.setState({
										call: true,
									});
								}
							);
						} else {
							try {
								this.$f7.methods.openNotification(
									user.user +
									" a incercat sa te apeleze! Contacteaza-l dupa ce termini.",
									undefined,
									"succes"
								);
								await this.state.connection.send("BusyCall", user.connectionId);
							} catch (e) {
								console.log(e);
							}
						}
					});

					this.state.connection.on("CallGroupIncome", async (user, groupID) => {
						console.log("CallGroupIncome:", user, groupID);

						if (!this.state.activeCall) {
							this.setState(
								{
									userCall: user,
									groupID: groupID,
								},
								() => {
									this.setState({
										call: true,
									});
								}
							);
						} else {
							try {
								this.$f7.methods.openNotification(
									user.user +
									" a incercat sa te apeleze! Contacteaza-l dupa ce termini.",
									undefined,
									"succes"
								);
								await this.state.connection.send("BusyCall", user.connectionId);
							} catch (e) {
								console.log(e);
							}
						}
					});

					this.state.connection.on("CallStop", () => {
						if (this.$f7.data.oneToOneCall) {
							this.$f7.data.oneToOneCall.recorderStop(false);
						} else {
							const popup = this.$f7.popup.get(".PopupCall");
							if (popup && popup.opened) {
								this.$f7.popup.close(".PopupCall");
							}
						}

						if (this.state.call) {
							this.setState({
								userCall: null,
								callerSignal: null,
								groupID: null,
								call: false,
							});
						}
					});

					this.state.connection.on("NotAnswer", (from, isGroup) => {
						this.$f7.methods.openNotification(
							from.user + " nu a raspuns!",
							undefined,
							"eroare"
						);

						if (!isGroup) {
							const popup = this.$f7.popup.get(".PopupCall");
							if (popup && popup.opened) {
								this.$f7.popup.close(".PopupCall");
							}
						}
					});

					this.state.connection.on("IsBusy", (user) => {
						this.setState(
							{
								isBusy: true,
							},
							() => {
								const popup = this.$f7.popup.get(".PopupCall");
								if (popup && popup.opened) {
									this.$f7.popup.close(".PopupCall");
								}

								this.$f7.methods.openNotification(
									user.user + " este angajat in alt apel! Incearca mai tarziu.",
									undefined,
									"eroare"
								);
							}
						);
					});

					this.state.connection.onreconnecting((c) => {
						currentId = this.state.connection.connectionId;
						console.log("onReconnecting:", c);
					});

					this.state.connection.onreconnected(async (connectionId) => {
						console.log(
							"onReconnected -",
							"current id:",
							currentId,
							" - new id:",
							connectionId
						);

						try {
							await this.state.connection.send(
								"UserUpdateAtReconnect",
								(this.$f7.data.Salariati.find(
									(item) => item.value === this.$f7.data.user.iD_SALARIAT
								).key,
									this.$f7.data.user.iD_SALARIAT,
									connectionId,
									grupuri !== null && grupuri !== undefined ? grupuri : "")
							);
						} catch (e) {
							console.log(e);
						}
					});
				}

				const appRootWrapper = document.getElementById("framework7-root");
				const callWrapper = document.getElementById("callWrapper");
				appRootWrapper.appendChild(callWrapper);
			}
		);
	};

	componentWillUnmount = () => {
		this.state.connection.off("CallIncome");
		this.state.connection.off("CallGroupIncome");
		this.state.connection.off("CallStop");
		this.state.connection.off("NotAnswer");
		this.state.connection.off("IsBusy");
	};

	newUser = async (userName, userId, groups) => {
		if (this.state.connection.state === "Connected") {
			try {
				await this.state.connection.send("NewUser", userName, userId, groups);
			} catch (e) {
				console.log(e);
			}
		} else {
			alert("No connection to server yet.");
		}
	};

	rejectCall = async (connectionId) => {
		if (this.state.connection.state === "Connected") {
			try {
				await this.state.connection.send("AcceptCall", connectionId, false, "");
			} catch (e) {
				console.log(e);
			}
		} else {
			alert("No connection to server yet.");
		}
	};

	endCall = async (id) => {
		if (this.state.connection.state === "Connected") {
			try {
				await this.state.connection.send(
					this.state.groupID === null ? "EndCall" : "LeaveGroup",
					id
				);

				if (this.state.groupID === null) {
					this.setState({
						callerSignal: null,
						activeCall: false,
					});
				} else {
					this.setState({
						groupID: null,
						activeCall: false,
					});
				}
			} catch (e) {
				console.log(e);
			}
		} else {
			alert("No connection to server yet.");
		}
	};

	notAnswer = async () => {
		if (this.state.connection.state === "Connected") {
			try {
				await this.state.connection.send(
					"SendNotAnswer",
					this.state.userCall.connectionId,
					this.state.groupID
				);

				this.setState({
					userCall: null,
					groupID: null,
					callerSignal: null,
					call: false,
				});
			} catch (e) {
				console.log(e);
			}
		}
	};

	render() {
		return (
			<div id="callWrapper">
				{this.state.call && !this.state.activeCall ? (
					<AnswerCall
						context={this}
						user={this.state.userCall}
						callerSignal={this.state.callerSignal}
						groupID={this.state.groupID}
						activeCall={this.state.activeCall}
						endCall={this.endCall}
						rejectCall={this.rejectCall}
						notAnswer={this.notAnswer}
					/>
				) : null}
			</div>
		);
	}
}
export default VideoCall;
