import {
  List,
  ListInput,
  Icon,
  Segmented,
  Button,
  ListItem,
} from "framework7-react";
import React from "react";
import moment from "moment";
import PVMain from "./PVMain";

/**
 * @description Interfata de selectie a clientului si intervalului dorit pentru procesul verbal
 * @date 08/04/2022
 * @class PVSheetClientPerioada
 * @extends {React.Component}
 */
class PVSheetClientPerioada extends React.Component {
  constructor(props) {
    super(props);
    this.listaClienti = [...this.$f7.data.Firme];
    this.listaClienti.shift();
    this.state = {
      denumire: "PVSheetClientPerioada",
      dataStart: moment().format(),
      dataSfarsit: moment().format(),
      idClient: this.listaClienti[0].value,
    };
  }

  render() {
    return (
      <div style={{ width: "100%", margin: "0 auto" }}>
        <List
          form
          inset
          onSubmit={(e) => {
            e.preventDefault();
            var sheetDinamic = this.$f7.sheet.get(".SheetDinamic");
            sheetDinamic.close();
            this.$f7.data.router.navigate("/popupDinamic/", {
              props: {
                component: <PVMain {...this.state} />,
              },
            });
          }}>
          <ListInput
            floatingLabel
            label={"Data start"}
            validate={true}
            required={true}
            type="datepicker"
            placeholder={"Data start"}
            value={[this.state.dataStart]}
            calendarParams={{
              closeOnSelect: true,
              on: {
                closed: (e) => {
                  var val = moment(e.value[0]).format();
                  this.setState({ dataStart: val });
                },
              },
            }}>
            <Icon
              slot="content-start"
              className={"datagridIcon"}
              size={20}
              material="today"
            />
          </ListInput>
          <ListInput
            floatingLabel
            label={"Data sfarsit"}
            validate={true}
            required={true}
            type="datepicker"
            placeholder={"Data sfarsit"}
            value={[this.state.dataSfarsit]}
            calendarParams={{
              closeOnSelect: true,
              on: {
                closed: (e) => {
                  var val = moment(e.value[0]).format();
                  this.setState({ dataSfarsit: val });
                },
              },
            }}>
            <Icon
              slot="content-start"
              className={"datagridIcon"}
              size={20}
              material="today"
            />
          </ListInput>

          <ListItem
            title="Client"
            value={this.state.idClient}
            label="Client"
            validate
            required
            smartSelect
            className="roundedOutlinedSmartSelect"
            smartSelectParams={{
              openIn: "popup",
              searchbar: true,
              searchbarPlaceholder: "Cautati clientul",
              closeOnSelect: true,
              on: {
                closed: (event) => {
                  var val = parseInt(event.getValue());
                  this.setState({
                    idClient: val,
                  });
                },
              },
            }}>
            <Icon
              color={"var(--f7-theme-color)"}
              size={20}
              material="list"
              slot="content-start"
            />
            <select name="Client">
              {this.listaClienti.map((obj, index) => (
                <option
                  selected={this.state.idClient === obj.value}
                  label={obj.key}
                  key={"llistaClientiPVSheetClientPerioada" + index}
                  value={obj.value}>
                  {obj.key}
                </option>
              ))}
            </select>
          </ListItem>

          <Segmented
            raised
            style={{
              margin: "8px auto",
              width: "200px",
            }}
            tag="div">
            <Button
              tooltip={"Salveaza"}
              type="submit"
              small
              fill
              bgColor="teal">
              Salveaza
            </Button>
          </Segmented>
        </List>
      </div>
    );
  }
}
export default PVSheetClientPerioada;
