import React, { Suspense } from "react";
import {
  Segmented,
  Toolbar,
  Link,
  Tab,
  Tabs,
  Page,
  Preloader,
} from "framework7-react";
import IstoricTaskGrid from "./IstoricTaskGrid";
const ActivitateTaskGrid = React.lazy(() => import("./ActivitateTaskGrid"));
const DescriereProiectGrid = React.lazy(() => import("./DescriereProiectGrid"));
//const IstoricModificariGrid = React.lazy(() => import('./IstoricModificariGrid'));
const PV = React.lazy(() => import("../../Intretinere/Componente/PV"));

/**
 * @description Afiseaza un toolbar din care selecteaza utilizatorul ce interfata doreste:descriere task,activitate,istoric,pv-uri si un view in care este randata tipul de interfata selectata
 * @date 08/04/2022
 * @class Grid
 * @extends {React.Component}
 */
class Grid extends React.Component {
  constructor(props) {
    super(props);
    this.height = window.innerHeight;

    let denumireTab = this.$f7.utils.id("xxxx-xxxx-xxxx");
    var tabActiv = localStorage.getItem("tabActivDreapta")
      ? localStorage.getItem("tabActivDreapta")
      : "tab-Grid-TimpLucrat";

    this.state = {
      activeTab:
        this.props.tabDeschis !== undefined
          ? this.props.tabDeschis + denumireTab
          : tabActiv + denumireTab,
      denumireTab: denumireTab,
      height: window.innerHeight - 204,
      height2: window.innerHeight - 44,
    };
  }

  componentDidMount = () => {
    this.$f7.data.Grid = this;
  };

  componentWillUnmount = () => {
    this.$f7.data.Grid = undefined;
  };

  render() {
    return (
      <Page pageContent={false}>
        {this.$f7.data.Proiect !== null ? (
          <div style={{ position: "relative", overflow: "hidden" }}>
            <>
              <Segmented
                raised
                tag="div"
                style={{
                  alignItems: "center",
                }}>
                <Toolbar tabbar={true} noShadow top>
                  <Link
                    tooltip={"Detalii task"}
                    tabLink={"#tab-Grid-Descriere" + this.state.denumireTab}
                    tabLinkActive={
                      this.state.activeTab ===
                      "tab-Grid-Descriere" + this.state.denumireTab
                    }
                    onClick={() => {
                      this.setState(
                        {
                          activeTab:
                            "tab-Grid-Descriere" + this.state.denumireTab,
                        },
                        () => {
                          localStorage.setItem(
                            "tabActivDreapta",
                            "tab-Grid-Descriere"
                          );
                        }
                      );
                    }}>
                    Detalii
                  </Link>
                  <Link
                    tooltip={"Activitatea pe task"}
                    tabLink={"#tab-Grid-TimpLucrat" + this.state.denumireTab}
                    tabLinkActive={
                      this.state.activeTab ===
                      "tab-Grid-TimpLucrat" + this.state.denumireTab
                    }
                    onClick={() => {
                      this.setState(
                        {
                          activeTab:
                            "tab-Grid-TimpLucrat" + this.state.denumireTab,
                        },
                        () => {
                          localStorage.setItem(
                            "tabActivDreapta",
                            "tab-Grid-TimpLucrat"
                          );
                        }
                      );
                    }}>
                    Activitate Task
                  </Link>
                  <Link
                    tooltip={"Procese verbale task"}
                    tabLink={
                      "#tab-Grid-ProceseVerbaleTask" + this.state.denumireTab
                    }
                    tabLinkActive={
                      this.state.activeTab ===
                      "tab-Grid-ProceseVerbaleTask" + this.state.denumireTab
                    }
                    onClick={() => {
                      this.setState(
                        {
                          activeTab:
                            "tab-Grid-ProceseVerbaleTask" +
                            this.state.denumireTab,
                        },
                        () => {
                          localStorage.setItem(
                            "tabActivDreapta",
                            "tab-Grid-ProceseVerbaleTask"
                          );
                        }
                      );
                    }}>
                    PV task
                  </Link>
                  <Link
                    tooltip={"Istoric"}
                    tabLink={"#tab-Grid-IstoricTask" + this.state.denumireTab}
                    tabLinkActive={
                      this.state.activeTab ===
                      "tab-Grid-IstoricTask" + this.state.denumireTab
                    }
                    onClick={() => {
                      this.setState(
                        {
                          activeTab:
                            "tab-Grid-IstoricTask" + this.state.denumireTab,
                        },
                        () => {
                          localStorage.setItem(
                            "tabActivDreapta",
                            "tab-Grid-IstoricTask"
                          );
                        }
                      );
                    }}>
                    Istoric
                  </Link>
                  {/*<Link*/}
                  {/*  tooltip={"Istoricul taskului"}*/}
                  {/*  tabLink={"#tab-Grid-Istoric" + this.state.denumireTab}*/}
                  {/*  tabLinkActive={*/}
                  {/*    this.state.activeTab ===*/}
                  {/*    "tab-Grid-Istoric" + this.state.denumireTab*/}
                  {/*  }*/}
                  {/*  onClick={() => {*/}
                  {/*    this.setState({*/}
                  {/*      activeTab: "tab-Grid-Istoric" + this.state.denumireTab*/}
                  {/*    });*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Istoric*/}
                  {/*</Link>*/}
                </Toolbar>
              </Segmented>
              <Tabs
                routable
                style={{
                  height: this.state.height2 - 48,
                }}>
                <Tab
                  style={{
                    height: "100%",
                    position: "relative",
                  }}
                  id={"tab-Grid-Descriere" + this.state.denumireTab}
                  tabActive={
                    this.state.activeTab ===
                    "tab-Grid-Descriere" + this.state.denumireTab
                  }>
                  {this.state.activeTab ===
                  "tab-Grid-Descriere" + this.state.denumireTab ? (
                    <Suspense
                      fallback={
                        <Preloader size={50} color={"var(--f7-theme-color)"} />
                      }>
                      <DescriereProiectGrid
                        idSarcina={this.$f7.data.Proiect.iD_PROIECT}
                      />
                    </Suspense>
                  ) : null}
                </Tab>

                {/*<tab*/}
                {/*    style={{*/}
                {/*        height: "100%",*/}
                {/*        position: "relative",*/}
                {/*    }}*/}
                {/*    id={"tab-grid-istoric" + this.state.denumiretab}*/}
                {/*    tabactive={*/}
                {/*        this.state.activetab ===*/}
                {/*        "tab-grid-istoric" + this.state.denumiretab*/}
                {/*    }*/}
                {/*>*/}
                {/*    {this.state.activetab ===*/}
                {/*        "tab-grid-istoric" + this.state.denumiretab ? (*/}

                {/*            <suspense fallback={<preloader size={50} color={"var(--f7-theme-color)"}></preloader>}>*/}
                {/*                <istoricmodificarigrid idsarcina={this.$f7.data.proiect.id_proiect} />*/}
                {/*            </suspense>*/}

                {/*        ) : null}*/}
                {/*</tab>*/}

                <Tab
                  style={{
                    height: "100%",
                    position: "relative",
                  }}
                  id={"tab-Grid-TimpLucrat" + this.state.denumireTab}
                  tabActive={
                    this.state.activeTab ===
                    "tab-Grid-TimpLucrat" + this.state.denumireTab
                  }>
                  {this.state.activeTab ===
                  "tab-Grid-TimpLucrat" + this.state.denumireTab ? (
                    <Suspense
                      fallback={
                        <Preloader size={50} color={"var(--f7-theme-color)"} />
                      }>
                      <ActivitateTaskGrid
                        idSarcina={this.$f7.data.Proiect.iD_PROIECT}
                      />
                    </Suspense>
                  ) : null}
                </Tab>

                <Tab
                  style={{
                    height: "100%",
                    position: "relative",
                  }}
                  id={"tab-Grid-ProceseVerbaleTask" + this.state.denumireTab}
                  tabActive={
                    this.state.activeTab ===
                    "tab-Grid-ProceseVerbaleTask" + this.state.denumireTab
                  }>
                  {this.state.activeTab ===
                  "tab-Grid-ProceseVerbaleTask" + this.state.denumireTab ? (
                    <Suspense
                      fallback={
                        <Preloader size={50} color={"var(--f7-theme-color)"} />
                      }>
                      <PV idTask={this.$f7.data.Proiect.iD_PROIECT} />
                    </Suspense>
                  ) : null}
                </Tab>

                <Tab
                  style={{
                    height: "100%",
                    position: "relative",
                  }}
                  id={"tab-Grid-IstoricTask" + this.state.denumireTab}
                  tabActive={
                    this.state.activeTab ===
                    "tab-Grid-IstoricTask" + this.state.denumireTab
                  }>
                  {this.state.activeTab ===
                  "tab-Grid-IstoricTask" + this.state.denumireTab ? (
                    <Suspense
                      fallback={
                        <Preloader size={50} color={"var(--f7-theme-color)"} />
                      }>
                      <IstoricTaskGrid />
                    </Suspense>
                  ) : null}
                </Tab>
              </Tabs>
            </>
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              height: "calc(100vh - 50px)",
            }}>
            <h3
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                right: 0,
                transform: "translateY(-50%)",
                textTransform: "uppercase",
                textAlign: "center",
                display: "block",
              }}>
              Nu ati selectat nimic
            </h3>
          </div>
        )}
      </Page>
    );
  }
}
export default Grid;
