import React from "react";
import { Page } from "framework7-react";
import LogActivitateSalariat from "./LogActivitateSalariat";
class LogActivitateSalariatPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { denumire: "Log activitate" };
    }
    render() {
        return (
            <Page
                className={"themeBackground"}
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
            >

                <div className="ptr3">
                    <LogActivitateSalariat {...this.props} />
                </div>
            </Page>
        );
    }
}
export default LogActivitateSalariatPage;