import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";

/**
 * @description Interfata de intretinere a datelor clientilor
 * @date 07/04/2022
 * @class DateClienti
 * @extends {React.Component}
 */
class DateClienti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      idFirma: null,
      linkApiEmail: "/api/Email",
      denumire: "Date Clienti",
      height: window.innerHeight - 44,
      dataSource: [],
      inputsDateClienti: [
        { key: "Id", keyBaza: "id", tip: "number" },
        {
          key: "Nume contact",
          keyBaza: "name",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Email",
          keyBaza: "mail",
          tip: "email",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Telefon",
          keyBaza: "telefon",
          tip: "phone",
          requiredAdd: false,
          requiredEdit: false,
        },
        {
          key: "Departament",
          keyBaza: "departament",
          tip: "selectbox",
          lista: this.$f7.data.Departamente,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Client",
          keyBaza: "ref_Firma",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
          requiredAdd: true,
          requiredEdit: true,
        },
      ],
    };
  }

  /**
   * @description Actualizeaza valorile in baza de date a inregistrarii selectate
   * @date 07/04/2022
   * @function
   * @param {*} obj noile valori ale inregistrarii selectate
   * @param {*} id id-ul inregistrarii selectate
   * @memberof DateClienti
   */
  UpdateValueDateClienti = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApiEmail + "/" + id)
      .then((response) => {
        let obj2 = response.data;

        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: this.$f7.data.baseURL + this.state.linkApiEmail + "/" + id,
        })
          .then((response) => {
            this.GetValuesDateClienti();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Sterge din baza de date inregistrarea selectata
   * @date 07/04/2022
   * @function
   * @param {*} id id-ul inregistrarii selectate
   * @memberof DateClienti
   */
  DeleteValueDateClienti = (id) => {
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: this.$f7.data.baseURL + this.state.linkApiEmail + "/" + id,
    })
      .then((response) => {
        this.GetValuesDateClienti();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  /**
   * @description Adauga o noua inregistrare in baza de date
   * @date 07/04/2022
   * @function
   * @param {*} data informatiile noii inregistrari
   * @memberof DateClienti
   */
  AddValueDateClienti = (data) => {
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: this.$f7.data.baseURL + this.state.linkApiEmail + "/",
    })
      .then((response) => {
        this.GetValuesDateClienti();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Ia valorile pentru randarea datelor clientilor
   * @function
   * @date 07/04/2022
   * @memberof DateClienti
   */
  GetValuesDateClienti = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApiEmail)
      .then((response) => {
        let date = response;
        date.data.map((item) => {
          item = this.$f7.methods.restructurareBaza(
            item,
            this.state.inputsDateClienti,
            "key"
          );
          return false;
        });

        let baza = this.$f7.methods.reconstructieBaza(
          date,
          this.state.inputsDateClienti
        );

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesDateClienti();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    this.GetValuesDateClienti();
  };

  render() {
    return (
      <DataGrid
        dataLoaded={this.state.dataLoaded}
        refresh={this.GetValues}
        name={"DateClienti"}
        renderOptions={true}
        id="Id"
        //coloanaDenumire={"Denumire"}
        //ref={this.refDataGrid}
        renderUpdate={true}
        renderDelete={this.$f7.data.rol < 3 ? true : false}
        renderAdd={true}
        maxHeight={this.state.height}
        dataSource={this.state.dataSource}
        inputs={this.state.inputsDateClienti}
        onUpdate={(state) => {
          let item = { ...state.editedItem };

          item = this.$f7.methods.restructurareBaza(
            item,
            this.state.inputsDateClienti,
            "keyBaza"
          );

          this.UpdateValueDateClienti(item, item.id);
        }}
        onAdd={(state) => {
          let item = { ...state.editedItem };

          item = this.$f7.methods.restructurareBaza(
            item,
            this.state.inputsDateClienti,
            "keyBaza"
          );
          delete item["id"];

          this.AddValueDateClienti(item);
        }}
        onDelete={(state) => {
          let item = { ...state.editedItem };
          let id = item["Id"];
          this.DeleteValueDateClienti(id);
        }}
      />
    );
  }
}
export default DateClienti;
