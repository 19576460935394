import React from "react";
import { PieChart, Pie, Legend, Tooltip, Cell } from "recharts";
import { Preloader } from "framework7-react";
import axios from "axios";

/**
 * @description Randeaza un grafic cu Numarul de ore lucrat si ramas
 * @date 06/04/2022
 * @class ATGrafice2
 * @extends {React.PureComponent}
 */
class ATGrafice2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.height = window.innerHeight - 100;
    this.width = window.innerWidth;
    this.height2 = window.innerHeight - 100;
    this.state = {
      dataLoaded: false,
      denumire: "ATGrafice2",
      dataSource: [{ name: "Test", value: 0 }],
      linkApi: "/api/SarciniSalariat",
    };
  }

  /**
   * @description Ia valorile necesare pentru randarea graficului de la endpointul /api/SarciniSalariat/GetDateGrafic
   * @date 06/04/2022
   * @memberof ATGrafice2
   */
  GetValues = () => {
    this.setState({ dataLoaded: false });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/GetDateGrafic")
      .then((response) => {
        var date = response.data;
        var arr = [];

        date.map((r) => {
          var lucrat = 0;
          var ramas = 0;
          if (r.lucrat !== null) {
            if (r.lucrat >= 0) {
              lucrat = r.lucrat;
            }
          }
          if (r.ramas !== null) {
            if (r.ramas >= 0) {
              ramas = r.ramas;
            }
          }
          var toAdd1 = { name: r.salariat + " lucrat", value: lucrat };
          var toAdd2 = { name: r.salariat + " ramas", value: ramas };
          arr.push(toAdd1);
          arr.push(toAdd2);
          return false;
        });

        this.setState(
          {
            dataSource: arr,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return this.state.dataLoaded ? (
      <>
        <PieChart width={this.width} height={this.height}>
          <Pie
            dataKey="value"
            isAnimationActive={true}
            data={this.state.dataSource}
            outerRadius={160}
            fill="#8884d8"
            label>
            {this.state.dataSource.map((entry, index) => (
              <Cell
                key={"cellATGrafice2-" + index}
                fill={"#" + Math.floor(Math.random() * 16777215).toString(16)}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend
            wrapperStyle={{ overflow: "auto", maxHeight: this.height2 }}
            verticalAlign={"top"}
            align={"right"}
            layout={"vertical"}
          />
        </PieChart>
      </>
    ) : (
      <div
        style={{
          width: "35px",
          margin: "0 auto",
        }}>
        <Preloader size={40} color={"var(--f7-theme-color)"} />
      </div>
    );
  }
}
export default ATGrafice2;