import React from "react";
import { Page } from "framework7-react";
import ProiecteRealizari from "./ProiecteRealizari";
class ProiecteRealizariPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.height = window.innerHeight;
        this.state = {
            pagina: "Proiecte realizari",
            denumire: Math.random().toString(36).substring(7).toString(),
            user: {},
            height: window.innerHeight - 34,
            toolbarIndex: 0,
        };
    }

    render() {
        return (
            <Page
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
                id={this.state.pagina}
                className={"themeBackground"}
            >

                <div className="ptr3">
                    <ProiecteRealizari />
                </div>
            </Page>
        );
    }
}
export default ProiecteRealizariPage;