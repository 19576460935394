import React from "react";
import { Preloader, Button } from "framework7-react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";

/**
 * @description Interfata de intretinere a PV-urilor
 * @date 07/04/2022
 * @class PV
 * @extends {React.Component}
 */
class PV extends React.Component {
  constructor(props) {
    super(props);
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.state = {
      dataLoaded1: false,
      dataLoaded2: false,
      idPv: null,
      dataSource: [],
      dataSourceDetalii: [],
      linkApi:
        props.idTask !== undefined
          ? "/api/ProceseVerbale/GetProceseVerbaleFiltrate/" + props.idTask
          : "/api/ProceseVerbale",
      linkApiDetalii: "/api/ProceseVerbaleDetalii",
      denumire: "PV",
      height: (window.innerHeight - 34) / 2,
      inputs: [
        { key: "Id", tip: "number" },
        {
          key: "Salariat",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "DataPV", tip: "datebox" },
        { key: "NumarPV", tip: "number" },
        { key: "Client", tip: "selectbox", lista: this.$f7.data.Firme },
        { key: "Aplicatia" },
        { key: "Listat", tip: "bool" },
        { key: "Validat", tip: "bool" },
        { key: "CaleDocument" },
        { key: "NumarFactura" },
        { key: "DataFactura", tip: "datebox" },
        { key: "ReprezentantClient" },
      ],
      inputsDetalii: [
        { key: "Id", tip: "number" },
        { key: "Denumire", requiredAdd: true, requiredEdit: true },
        {
          key: "Salariat",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "Operatiune" },
        { key: "OreLucrate", tip: "number" },
        { key: "Suma", tip: "number" },
        { key: "Comentariu" },
        { key: "Complus" },
        {
          key: "TipOperatie",
          tip: "selectbox",
          lista: [
            { key: "Fara", value: null },
            { key: "Asistenta tehnica la sediul beneficiarului", value: 0 },
            {
              key: "Asistenta de la distanta prin modem sau internet",
              value: 1,
            },
            { key: "Deplasare la sediul beneficiarului", value: 2 },
          ],
        },
      ],
    };
    this.refDataGrid1 = React.createRef();
    this.refDataGrid2 = React.createRef();
  }
  /**
   * @description Actualizeaza in baza de date inregistrarea selectata(PV)
   * @date 07/04/2022
   * @function
   * @param {*} obj obiect cu valorile noi ale inregistrarii
   * @param {*} id id-ul inregistrarii selectate
   * @memberof PV
   */
  UpdateValue = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url = this.$f7.data.baseURL + this.state.linkApi + "/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Actualizeaza in baza de date inregistrarea selectata(PVDetalii)
   * @date 07/04/2022
   * @function
   * @param {*} obj obiect cu valorile noi ale inregistrarii
   * @param {*} id id-ul inregistrarii selectate
   * @memberof PV
   */
  UpdateValueDetalii = (obj, id) => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiDetalii +
          "/GetProceseVerbaleDetaliiUpdate/" +
          id
      )
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL +
          this.state.linkApiDetalii +
          "/PutProceseVerbaleDetalii/" +
          id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValuesDetalii();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };
  /**
   * @description Ia valorile necesare randarii interfetei(PV)
   * @function
   * @date 07/04/2022
   * @memberof PV
   */
  GetValues = () => {
    this.setState({
      dataLoaded1: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApi)
      .then((response) => {
        var date = response;
        date.data.map((obj) => {
          obj["Id"] = obj.idProceseVerbale;
          obj["Salariat"] = obj.id_salariat;
          obj["Client"] = obj.id_client;
          obj["CaleDocument"] = obj.cale_document;
          obj["NumarFactura"] = obj.numar_fact;
          obj["DataFactura"] = obj.data_fact;
          obj["ReprezentantClient"] = obj.reprez_client;
          return null;
        });
        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded1: true,
                },
                () => {
                  this.refDataGrid1.current.setRenderItems(baza);
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded1: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };
  /**
   * @description Ia valorile(detaliile pv-ului selectat) necesare randarii interfetei(PV detalii)
   * @function
   * @date 07/04/2022
   * @memberof PV
   */
  GetValuesDetalii = (id) => {
    this.setState({
      dataLoaded2: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiDetalii +
          "/GetProceseVerbaleDetalii/" +
          id
      )
      .then((response) => {
        var date = response;
        date.data.map((obj) => {
          obj["Id"] = obj.idProceseVerbaleDetalii;
          obj["Salariat"] = obj.id_salariat;
          obj["TipOperatie"] = obj.id_operatiune;
          return null;
        });
        var baza = this.$f7.methods.reconstructieBaza(
          date,
          this.state.inputsDetalii
        );

        this.setState(
          {
            dataSourceDetalii: baza.length > 0 ? baza : [],
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded2: true,
                },
                () => {
                  if (baza.length > 0) {
                    this.refDataGrid2.current.setRenderItems(baza);
                  }
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSourceDetalii: [],
            dataLoaded2: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesDetalii(this.state.idPv);
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return this.state.dataLoaded1 ? (
      <>
        <DataGrid
          name={"PV1"}
          ref={this.refDataGrid1}
          id={"Id"}
          title={() => {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: 16 }}>Lista PV</div>
                <Button
                  fill
                  onClick={() => {
                    if (this.state.idPv) {
                      var url =
                        "http://dev03:8888/result?report=Altele%5C%5CPROCES_VERBAL_SERVICE_ATS&id=" +
                        this.state.idPv;

                      setTimeout(() => {
                        window.open(url, "_blank");
                      }, 1000);
                    }
                  }}>
                  Afiseaza PV
                </Button>
              </div>
            );
          }}
          renderUpdate={true}
          contextMenuItems={() => {
            return [
              {
                key: "Afiseza PV",
                onClick: () => {
                  var url =
                    "http://dev03:8888/result?report=Altele%5C%5CPROCES_VERBAL_SERVICE_ATS&id=" +
                    this.state.idPv;

                  setTimeout(() => {
                    window.open(url, "_blank");
                  }, 1000);
                },
              },
            ];
          }}
          onUpdate={(state) => {
            var obj = { ...state.editedItem };

            obj["idProceseVerbale"] = obj["Id"];
            obj["id_salariat"] = obj["Salariat"];
            obj["id_client"] = obj["Client"];
            obj["cale_document"] = obj["CaleDocument"];
            obj["numar_fact"] = obj["NumarFactura"];
            obj["data_fact"] = obj["DataFactura"];
            obj["reprez_client"] = obj["ReprezentantClient"];
            delete obj.Id;
            delete obj.Salariat;
            delete obj.Client;
            delete obj.CaleDocument;
            delete obj.NumarFactura;
            delete obj.DataFactura;
            delete obj.ReprezentantClient;
            this.UpdateValue(obj, obj["idProceseVerbale"]);
          }}
          onRowClick={(state) => {
            this.setState(
              {
                idPv: state.rowData.Id,
              },
              () => {
                this.GetValuesDetalii(this.state.idPv);
              }
            );
          }}
          maxHeight={this.state.height}
          dataSource={this.state.dataSource}
          inputs={this.state.inputs}
        />

        {this.state.idPv !== null ? (
          this.state.dataLoaded2 ? (
            <DataGrid
              name={"PV2"}
              id={"Id"}
              ref={this.refDataGrid2}
              title={"Detalii"}
              renderUpdate={true}
              dataSource={this.state.dataSourceDetalii}
              maxHeight={this.state.height}
              inputs={this.state.inputsDetalii}
              onUpdate={(state) => {
                var obj = { ...state.editedItem };
                obj["idProceseVerbaleDetalii"] = obj["Id"];
                obj["id_salariat"] = obj["Salariat"];
                obj["id_operatiune"] = obj["TipOperatie"];
                delete obj.TipOperatie;
                delete obj.Id;
                delete obj.Salariat;

                this.UpdateValueDetalii(obj, obj["idProceseVerbaleDetalii"]);
              }}
            />
          ) : (
            <div
              style={{
                position: "absolute",
                width: "35px",
                height: "35px",
                top: "calc(75vh - 70px)",
                left: "50%",
              }}>
              <Preloader size={40} color={"var(--f7-theme-color)"} />
            </div>
          )
        ) : (
          <div
            style={{
              marginTop: "calc((50vh / 2) - 50px)",
              fontSize: "20px",
              textAlign: "center",
            }}>
            Nu a fost selectat nici un PV
          </div>
        )}
      </>
    ) : (
      <div
        style={{
          position: "absolute",
          width: "35px",
          height: "35px",
          top: "50vh",
          left: "50%",
        }}>
        <Preloader size={40} color={"var(--f7-theme-color)"} />
      </div>
    );
  }
}
export default PV;
