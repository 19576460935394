import React, { Component } from 'react'
import { GoogleLogout } from 'react-google-login'
import { Button } from "framework7-react"

class EmailSideBar extends Component {
  render() {
    const { CLIENT_ID, activeTab, loading, profileImage, profileName, handleLogoutGoogle, handleSelectTab } = this.props

    return (
      <div style={{ width: 48, height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', borderRight: '1px solid #e9e9e9' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            className={`gmailSideTab ${activeTab === 0 ? 'activeGmailSideTab' : ''}`}
            iconMaterial='inbox'
            iconSize={22}
            disabled={loading}
            onClick={() => handleSelectTab(0)}
          >
            <div>Inbox</div>
          </Button>

          <Button
            className={`gmailSideTab ${activeTab === 1 ? 'activeGmailSideTab' : ''}`}
            iconMaterial='send'
            iconSize={22}
            disabled={loading}
            onClick={() => handleSelectTab(1)}
          >
            <div>Trimise</div>
          </Button>

          <Button
            className={`gmailSideTab ${activeTab === 2 ? 'activeGmailSideTab' : ''}`}
            iconMaterial='insert_drive_file'
            iconSize={22}
            disabled={loading}
            onClick={() => handleSelectTab(2)}
          >
            <div>Ciorne</div>
          </Button>

          <Button
            className={`gmailSideTab ${activeTab === 3 ? 'activeGmailSideTab' : ''}`}
            iconMaterial='error'
            iconSize={22}
            disabled={loading}
            onClick={() => handleSelectTab(3)}
          >
            <div>Spam</div>
          </Button>

          <Button
            className={`gmailSideTab ${activeTab === 4 ? 'activeGmailSideTab' : ''}`}
            iconMaterial='delete'
            iconSize={22}
            disabled={loading}
            onClick={() => handleSelectTab(4)}
          >
            <div>Gunoi</div>
          </Button>
        </div>

        <div>
          {profileImage && profileName.length > 0 &&
            <Button className='gmailSideTab external' href='https://mail.google.com/' target='_blank' style={{ width: 40, height: 40, margin: '0 auto 10px', borderRadius: '50%', backgroundImage: `url(${profileImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div>{profileName}</div>
            </Button>
          }

          <GoogleLogout
            clientId={CLIENT_ID}
            buttonText="Logout"
            onLogoutSuccess={handleLogoutGoogle}
            render={prop => (
              <Button className='gmailSideTab' iconMaterial='logout' iconSize={22} disabled={loading} onClick={prop.onClick}>
                <div>Deconectare</div>
              </Button>
            )}
          >
          </GoogleLogout>
        </div>
      </div>
    )
  }
}

export default EmailSideBar;
