import React, { Component } from 'react'
import { List, ListInput, Icon, Button } from 'framework7-react';
import readXlsxFile from 'read-excel-file'
import DataGrid from '../../Datagrid/DataGrid';
import moment from 'moment';
import axios from 'axios';
import { saveAs } from "file-saver";

class ImportExcel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            height: window.innerHeight - 110,
            dataSource: [],
            dataFull: [],
            inputs: [
                { key: "Id", keyBaza: "ID_PROIECT" },
                { key: "Parinte", keyBaza: "REF_PROIECT", tip: "number" },
                { key: "Denumire", keyBaza: "DENUMIRE" },
                { key: "Cerinta", keyBaza: "comentariu" },
                { key: "DataCreare", keyBaza: "data_creare", tip: "datebox" },
                { key: "DataStart", keyBaza: "data_start", tip: "datebox" },
                { key: "Deadline", keyBaza: "deadline", tip: "datebox" },
                { key: "DataFinal", keyBaza: "data_final", tip: "datebox" },
                { key: "Client", keyBaza: "id_client", tip: "selectbox", lista: this.$f7.data.Firme },
                { key: "Categorie", keyBaza: "id_tip_proiect", tip: "selectbox", lista: this.$f7.data.TipProiect },
                { key: "Departament", keyBaza: "id_departament", tip: "selectbox", lista: this.$f7.data.Departamente },
                { key: "NivelProiect", keyBaza: "id_nivel_proiect", tip: "selectbox", lista: this.$f7.data.NivelProiect },
                { key: "EsteTask", keyBaza: "TASK", tip: "bool" },
                { key: "Prioritate", keyBaza: "id_prioritate", tip: "selectbox", lista: this.$f7.data.Prioritati },
                { key: "TimpEstimat", keyBaza: "timp_plan_d", tip: "number" },
                { key: "Sursa", keyBaza: "id_tip_sursa", tip: "selectbox", lista: this.$f7.data.TipSursa },
                { key: "EsteFolosit", keyBaza: "FOLOSIT", tip: "bool" },
                { key: "Stadiu", keyBaza: "ID_STADIU", tip: "selectbox", lista: this.$f7.data.Stadiu },
                { key: "EsteModul", keyBaza: "isModul", tip: "bool" },
                { key: "EsteArhivat", keyBaza: "isArhivat", tip: "number" },
                { key: "EsteAscuns", keyBaza: "isHidden", tip: "number" }
            ],
            dataLoaded: true
        }
    }

    componentDidMount = () => {
    }

    b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    render() {
        return (
            <div>
                <div>
                    <List
                        style={{
                            display: 'inline-block',
                            width: 'calc(100% - 110px)'
                        }}
                    >
                        <ListInput
                            key={"file-import"}
                            floatingLabel
                            label={"Fisier excel"}
                            type="file"
                            placeholder={"Fisier excel"}
                            clearButton={true}
                            onInput={(e) => {
                                let fisier = e.target.files[0];

                                if (fisier) {
                                    this.setState({
                                        dataLoaded: false
                                    });

                                    readXlsxFile(fisier).then((rows) => {
                                        console.log('fisier date:', rows);

                                        let listaProiecte = [];
                                        rows.forEach(row => {
                                            const taskuri = rows.filter(item => item[1] === row[0]);

                                            listaProiecte.push({
                                                ID_PROIECT: row[0],
                                                REF_PROIECT: row[1],
                                                DENUMIRE: row[2],
                                                comentariu: row[3] !== null ? row[3] : "",
                                                data_creare: moment().format(),
                                                data_start: moment().format(),
                                                deadline: row[6] !== null ? moment((row[6] - 25569) * 24 * 60 * 60 * 1000).startOf('day').format() : moment().add(1, 'months').format(),
                                                data_final: row[6] !== null ? moment((row[6] - 25569) * 24 * 60 * 60 * 1000).startOf('day').format() : moment().add(1, 'months').format(),
                                                id_client: row[9] !== null ? row[9] : 23, // ATS
                                                id_tip_proiect: row[8] !== null ? row[8] : 4, // Dezvoltare
                                                id_departament: this.props.idDepartament !== undefined ? this.props.idDepartament : null,
                                                id_nivel_proiect: taskuri.length > 0 ? 2 : 4,
                                                TASK: taskuri.length > 0 ? false : true,
                                                id_prioritate: 1,
                                                timp_plan_d: row[7] !== null ? row[7] : 0,
                                                id_tip_sursa: 10,
                                                FOLOSIT: true,
                                                ID_STADIU: 1,
                                                isModul: false,
                                                isArhivat: 0,
                                                isHidden: 0,
                                                id_operator_adaugare: this.$f7.data.user.iD_SALARIAT
                                            });
                                        });

                                        listaProiecte.shift();
                                        listaProiecte = listaProiecte.filter(item => item.ID_PROIECT !== null);

                                        let dataFull = listaProiecte.map(p => ({ ...p }));
                                        this.setState({
                                            dataFull: dataFull
                                        }, () => {
                                            console.log('lista proiecte full:', this.state.dataFull);
                                        });

                                        let date = {
                                            data: listaProiecte
                                        }

                                        date.data.map((eveniment) => {
                                            eveniment = this.$f7.methods.restructurareBaza(
                                                eveniment,
                                                this.state.inputs,
                                                "key"
                                            );
                                            return false;
                                        });

                                        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

                                        this.setState({
                                            dataSource: baza
                                        }, () => {
                                            setTimeout(() => {
                                                this.setState({
                                                    dataLoaded: true
                                                });
                                            }, 400);
                                        });
                                    });
                                }
                            }}
                            onInputClear={() => {
                                this.setState({
                                    dataSource: [],
                                    dataFull: [],
                                    dataLoaded: false
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            dataLoaded: true
                                        });
                                    }, 400);
                                });
                            }}
                        >
                            <Icon
                                slot="content-start"
                                style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                                size={20}
                                material="security"
                            ></Icon>
                        </ListInput>
                    </List>

                    <Button
                        disabled={this.state.dataSource.length === 0}
                        tooltip={"Upload"}
                        fill
                        small
                        style={{
                            display: 'inline-block',
                            height: '100%',
                            marginLeft: 10
                        }}
                        onClick={() => {
                            if (this.state.dataFull.length > 0 && this.props.idParinte) {
                                this.$f7.preloader.show();

                                axios({
                                    data: { listaProiecte: this.state.dataFull, idParinte: this.props.idParinte },
                                    headers: { "Content-Type": "application/json" },
                                    method: "post",
                                    url: this.$f7.data.baseURL + "/api/Proiecte/ImportExcel"
                                }).then((response) => {
                                    this.$f7.preloader.hide();
                                    this.$f7.methods.openNotification("Taskuri importate cu succes!");
                                }).catch((error) => {
                                    console.log("error:", error);
                                    this.$f7.preloader.hide();
                                    this.$f7.methods.openNotification("Eroare la import");
                                });
                            }
                        }}
                    >
                        <Icon material="backup" />
                    </Button>

                    <Button
                        tooltip={"Descarca sablonul"}
                        fill
                        small
                        style={{
                            display: 'inline-block',
                            height: '100%'
                        }}
                        onClick={() => {
                            axios.get(
                                this.$f7.data.baseURL +
                                "/api/Proiecte/GetSablonExcel"
                            ).then((response) => {
                                let file = response.data;

                                if (file !== "") {
                                    const blob = this.b64toBlob(file);
                                    saveAs(blob, "Sablon_taskuri.xlsx");
                                }
                            }).catch((error) => {
                                console.log("error:", error);
                            });
                        }}
                    >
                        <Icon material="file_download" />
                    </Button>
                </div>

                <DataGrid
                    name={"Import excel"}
                    dataLoaded={this.state.dataLoaded}
                    renderUpdate={false}
                    renderDelete={false}
                    renderAdd={false}
                    renderExcel={false}
                    maxHeight={this.state.height}
                    dataSource={this.state.dataSource}
                    inputs={this.state.inputs}
                    keys={["Denumire", "Cerinta", "DataCreare", "Deadline", "Client", "Categorie", "Departament", "NivelProiect", "TimpEstimat"]}
                    renderAccordion={true}
                    id={"Id"}
                    refId={"Parinte"}
                    initialRefId={null}
                />
            </div>
        )
    }
}
export default ImportExcel;