import React from "react";
import {
  Page,
  Row,
  Col,
  View,
  List,
  ListItem,
  ListInput,
  Icon,
  Fab,
  Checkbox,
  Popover,
  Range,
} from "framework7-react";
import axios from "axios";
import moment from "moment";

import SarciniAlocateGrid from "./Proiecte/Agenda/SarciniAlocateGrid";
import SarciniAlocateSubalterniGrid from "./Proiecte/Agenda/SarciniAlocateSubalterniGrid";
import SarciniInchiseSubalterniGrid from "./Proiecte/Agenda/SarciniInchiseSubalterniGrid";
import SarciniNealocateGrid from "./Proiecte/Agenda/SarciniNealocateGrid";
import SarciniPropuseGrid from "./Proiecte/Agenda/SarciniPropuseGrid";
import SarciniPropuseSubalterniGrid from "./Proiecte/Agenda/SarciniPropuseSubalterniGrid";
import SarciniRezolvateGrid from "./Proiecte/Agenda/SarciniRezolvateGrid";
import SarciniPrimiteGrid from "./Proiecte/Agenda/SarciniPrimiteGrid";
import SarciniToateGrid from "./Proiecte/Agenda/SarciniToateGrid";
import TotalTaskuriSalariat from "./Proiecte/Agenda/TotalTaskuriSalariat/TotalTaskuriSalariat";
import TreeList, { SearchPanel } from "devextreme-react/tree-list";

let intervalOprireAutomata;

/**
 * @description Interfata principala unde se randeaza alocarea taskurilor(alocate,primite,inchise etc)
 * @date 08/04/2022
 * @class HomePage
 * @extends {React.PureComponent}
 */
class HomePage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.Salariati = this.Salariati.filter((r) => r.value !== null);

    this.state = {
      maxDepth: 0,
      idSalariatFunctii: this.$f7.data.idSalariatFunctii,
      morphOpened: false,
      filtered: false,
      checkedTaskuri: false,
      checkedSubtotaluri: false,
      checkedSelectate: false,
      checkedAscunse: false,
      checkedArhivate: false,
      checkedInchise: false,
      filterBarShow: false,
      selectedGrid: "0",
      preaMic: false,
      settings2:
        parseInt(window.innerWidth) < 1000
          ? {
              width: "100",
              xsmall: "100",
              small: "100",
              medium: "100",
              large: "50",
              xlarge: "50",
            }
          : {},
    };
    this.ref1 = React.createRef();
    this.ref2 = React.createRef();
    this.refAlocate = React.createRef();
    this.refAlocateSubalterni = React.createRef();
    this.refInchiseSubalterni = React.createRef();
    this.refNealocate = React.createRef();
    this.refInchise = React.createRef();
    this.refPrimite = React.createRef();
    this.refPropuse = React.createRef();
    this.refPropuseSubalterni = React.createRef();
    this.refTotalTaskuri = React.createRef();
    this.refToate = React.createRef();
  }

  /**
   * @description calculeaza width-ul interfetei si pune listenere pe resize-ul din mijloc pentru a seta in localStorage
   * @function
   * @date 08/04/2022
   * @memberof HomePage
   */
  componentDidMount = () => {
    var width = parseInt(window.innerWidth);

    if (width < 1000) {
      if (this.state.preaMic !== true) {
        this.setState({
          preaMic: true,
          settings2: {
            width: "100",
            xsmall: "100",
            small: "100",
            medium: "100",
            large: "50",
            xlarge: "50",
          },
        });
      }
    } else {
      if (this.state.preaMic === true) {
        this.setState({ preaMic: false, settings2: {} });
      }
    }

    document
      .getElementsByClassName("resize-handler")[0]
      .addEventListener("mouseup", () => {
        var width1 = this.ref1.current.eventTargetEl.style.width;
        if (width1 !== undefined) {
          if (
            width1.split("calc(") !== undefined &&
            width1.split("calc(").length > 0
          ) {
            if (
              width1.split("calc(")[1].split("%") !== undefined &&
              width1.split("calc(")[1].split("%").length > 0
            ) {
              var width1Final = parseFloat(
                width1.split("calc(")[1].split("%")[0]
              );
              var width2Final = 99 - width1Final;
              localStorage.setItem(
                "HomeWidthStanga",
                width1Final.toString() + "%"
              );
              localStorage.setItem(
                "HomeWidthDreapta",
                width2Final.toString() + "%"
              );
            }
          }
        }
      });
    document
      .getElementsByClassName("resize-handler")[0]
      .addEventListener("dblclick", () => {
        localStorage.removeItem("HomeWidthStanga");
        localStorage.removeItem("HomeWidthDreapta");
      });

    window.addEventListener("resize", (e) => {
      var width = parseInt(window.innerWidth);

      if (width < 1000) {
        if (this.state.preaMic !== true) {
          this.setState({
            preaMic: true,
            settings2: {
              width: "100",
              xsmall: "100",
              small: "100",
              medium: "100",
              large: "50",
              xlarge: "50",
            },
          });
        }
      } else {
        if (this.state.preaMic === true) {
          this.setState({ preaMic: false, settings2: {} });
        }
      }
    });

    this.AlertaTaskOpritAutomat();
  };

  /**
   * @description Deschide treelistul la nivelul selectat
   * @date 08/04/2022
   * @param {*} level nivelul selectat
   * @memberof HomePage
   */
  expandToLevel = (level) => {
    this.setState({ level: level }, () => {
      this.$f7.preloader.show();
      this.state.componentaTree.forEachNode((nod) => {
        if (nod.level < this.state.level) {
          this.state.componentaTree.expandRow(nod.data.value);
        } else {
          this.state.componentaTree.collapseRow(nod.data.value);
        }
      });
      this.$f7.preloader.hide();
    });
  };

  /**
   * @description Afiseaza mesaj ca taskul a fost oprit pentru deconectare sau pentru parasirea incintei
   * @function
   * @date 08/04/2022
   * @memberof HomePage
   */
  AlertaTaskOpritAutomat = () => {
    clearInterval(intervalOprireAutomata);

    this.GetActivitatiOpriteAutomat()
      .then((lista) => {
        axios
          .get(
            this.$f7.data.baseURL +
              "/api/RealizareSarcini/VerificareTaskActivByUserToataSarcina/" +
              this.$f7.data.user.iD_SALARIAT
          )
          .then((sarcinaActiva) => {
            if (lista.length > 0) {
              let realizare = lista[0];

              let stadiu = realizare.proiecte.iD_STADIU;
              let mesaj =
                'Taskul "' +
                realizare.proiecte.denumire +
                '" a fost oprit datorita parasirii incintei la ' +
                moment(realizare.iesire).format("DD-MM-YYYY HH:mm:ss") +
                ".";

              if (sarcinaActiva.data === "") {
                if (stadiu !== 3) {
                  mesaj +=
                    " Nu se mai poate reporni deoarece este deja inchis sau propus altcuiva. Este necesara doar confirmarea acestui mesaj.";

                  this.$f7.dialog.alert(mesaj, "Atentie", () => {
                    realizare.avertizare_oprire_automata = false;

                    this.UpdateActivitateOpritaAutomat(
                      realizare,
                      realizare.iD_REALIZARE,
                      false
                    )
                      .then(() => {
                        intervalOprireAutomata = setInterval(
                          this.AlertaTaskOpritAutomat,
                          120000
                        );
                      })
                      .catch((error) => {
                        console.log("error", error);
                        intervalOprireAutomata = setInterval(
                          this.AlertaTaskOpritAutomat,
                          120000
                        );
                      });
                  });
                } else {
                  mesaj += " Doriti sa il reporniti?";

                  this.$f7.dialog.confirm(
                    mesaj,
                    "Atentie",
                    () => {
                      // ok
                      realizare.avertizare_oprire_automata = false;

                      this.UpdateActivitateOpritaAutomat(
                        realizare,
                        realizare.iD_REALIZARE,
                        true
                      )
                        .then(() => {
                          this.$f7.methods.refreshStanga();
                          intervalOprireAutomata = setInterval(
                            this.AlertaTaskOpritAutomat,
                            120000
                          );
                        })
                        .catch((error) => {
                          console.log("error", error);
                          intervalOprireAutomata = setInterval(
                            this.AlertaTaskOpritAutomat,
                            120000
                          );
                        });
                    },
                    () => {
                      // cancel
                      realizare.avertizare_oprire_automata = false;

                      this.UpdateActivitateOpritaAutomat(
                        realizare,
                        realizare.iD_REALIZARE,
                        false
                      )
                        .then(() => {
                          intervalOprireAutomata = setInterval(
                            this.AlertaTaskOpritAutomat,
                            120000
                          );
                        })
                        .catch((error) => {
                          console.log("error", error);
                          intervalOprireAutomata = setInterval(
                            this.AlertaTaskOpritAutomat,
                            120000
                          );
                        });
                    }
                  );
                }
              } else {
                mesaj +=
                  " Exista deja un task pornit (acesta sau altul). Este necesara doar confirmarea acestui mesaj.";

                this.$f7.dialog.alert(mesaj, "Atentie", () => {
                  realizare.avertizare_oprire_automata = false;

                  this.UpdateActivitateOpritaAutomat(
                    realizare,
                    realizare.iD_REALIZARE,
                    false
                  )
                    .then(() => {
                      intervalOprireAutomata = setInterval(
                        this.AlertaTaskOpritAutomat,
                        120000
                      );
                    })
                    .catch((error) => {
                      console.log("error", error);
                      intervalOprireAutomata = setInterval(
                        this.AlertaTaskOpritAutomat,
                        120000
                      );
                    });
                });
              }
            } else {
              intervalOprireAutomata = setInterval(
                this.AlertaTaskOpritAutomat,
                120000
              );
            }
          })
          .catch((error) => {
            console.log("error sarcina activa:", error);
            intervalOprireAutomata = setInterval(
              this.AlertaTaskOpritAutomat,
              120000
            );
          });
      })
      .catch((error) => {
        console.log("error activitati:", error);
        intervalOprireAutomata = setInterval(
          this.AlertaTaskOpritAutomat,
          120000
        );
      });
  };

  /**
   * @description Verifica daca taskul este oprit automat de deconectare
   * @function
   * @date 08/04/2022
   * @memberof HomePage
   */
  GetActivitatiOpriteAutomat = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/RealizareSarcini/GetREALIZARE_SARCINI_BY_SALARIAT/" +
            this.$f7.data.user.iD_SALARIAT
        )
        .then((response) => {
          let doarAvertizari = response.data.filter(
            (item) =>
              item.oprire_eveniment_usa === true &&
              item.avertizare_oprire_automata === true
          );
          resolve(doarAvertizari);
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  };

  /**
   * @description Actualizeaza ca a fost repornit sau nu taskul oprit automat in baza de date
   * @date 08/04/2022
   * @param {*} data obiectul cu noile valori ale realizarii oprite automat
   * @param {*} id id-ul taskului oprit automat
   * @param {*} restart daca este sau nu restartat acest task
   * @function
   * @memberof HomePage
   */
  UpdateActivitateOpritaAutomat = (data, id, restart) => {
    return new Promise((resolve, reject) => {
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "put",
        url:
          this.$f7.data.baseURL +
          "/api/RealizareSarcini/PutREALIZARE_SARCINI/" +
          id,
      })
        .then((response) => {
          if (restart) {
            var realizareNoua = {
              Comentariu: "",
              Unitati: 1,
              asumat: true,
              comentariU1: "",
              dupa_lucru: 0,
              iD_CLIENT: null,
              iD_SALARIAT: this.$f7.data.user.iD_SALARIAT,
              id_proiect: data.iD_PROIECT,
              iesire: null,
              intrare: moment().format(),
              procent_realizare: 0,
              manual: false,
              aprobat: true,
              ultima_activitate: moment().format(),
            };

            axios({
              data: realizareNoua,
              headers: { "Content-Type": "application/json" },
              method: "POST",
              url: this.$f7.data.baseURL + "/api/RealizareSarcini/PostInceput",
            })
              .then((response2) => {
                var ceReturneaza = response2.data;

                if (ceReturneaza === "S-a adaugat.") {
                  this.$f7.methods.openNotification(
                    "Taskul a fost repornit!",
                    "Succes"
                  );
                  resolve();
                } else {
                  this.$f7.methods.openNotification(
                    "Taskul nu se poate reporni! Exista un alt task deja pornit!",
                    "Atentie"
                  );
                  resolve();
                }
              })
              .catch((error) => {
                this.$f7.methods.openNotification(
                  "Nu se poate reporni taskul!",
                  "Atentie"
                );
                reject(error);
              });
          } else {
            resolve();
          }
        })
        .catch((error) => {
          this.$f7.methods.openNotification(
            "Nu se poate actualiza taskul oprit automat!",
            "Eroare"
          );
          reject(error);
        });
    });
  };

  render() {
    return (
      <Page
        id="HomePage"
        onPageInit={() => {
          this.$f7.data.homePage = this;
        }}
        onPageAfterIn={() => {
          if (this.$f7router.previousRoute.path === "/login/") {
            if (this.state.selectedGrid === "0") {
              this.refAlocate.current.GetValues();
            } else if (this.state.selectedGrid === "1") {
              this.refNealocate.current.GetValues();
            } else if (this.state.selectedGrid === "2") {
              this.refInchise.current.GetValues();
            } else if (this.state.selectedGrid === "3") {
              this.refPrimite.current.GetValues();
            } else if (this.state.selectedGrid === "4") {
              this.setState({ checkedTaskuri: true }, () => {
                this.refToate.current.GetValues();
              });
            } else if (this.state.selectedGrid === "5") {
              this.refAlocateSubalterni.current.GetValues();
            } else if (this.state.selectedGrid === "6") {
              this.refPropuse.current.GetValues();
            } else if (this.state.selectedGrid === "7") {
              this.refInchiseSubalterni.current.GetValues();
            }
          }
        }}
        className={"themeBackground"}>
        <div className="ptr3">
          <Row style={{ padding: "8px 4px 0px 4px" }}>
            <Col
              ref={this.ref1}
              {...this.state.settings2}
              style={
                this.state.preaMic
                  ? {}
                  : {
                      width:
                        localStorage.getItem("HomeWidthStanga") === null
                          ? "69%"
                          : localStorage.getItem("HomeWidthStanga"),
                    }
              }
              resizable={!this.state.preaMic}>
              <div
                style={{
                  position: "relative",
                  overflow: "hidden",
                  minHeight: "45vh",
                }}>
                <div style={{ display: "flex", height: 60 }}>
                  <List
                    id={"listaTipSarcini"}
                    style={{
                      marginBottom: 8,
                      width: 200,
                    }}>
                    <ListItem
                      key={"listaTipSarcini"}
                      className="roundedOutlinedSmartSelect"
                      floatingLabel
                      label={"Tip sarcini"}
                      value={this.state.selectedGrid}
                      title={"Tip sarcini"}
                      validate
                      smartSelect
                      smartSelectParams={{
                        cssClass: "popoverSelectBoxTop",
                        openIn: "popover",
                        closeOnSelect: true,
                        on: {
                          closed: (event) => {
                            var val = event.getValue();
                            this.setState(
                              {
                                selectedGrid: val,
                                filtered: false,
                                checkedTaskuri: false,
                                checkedSubtotaluri: false,
                                checkedSelectate: false,
                                checkedAscunse: false,
                                checkedArhivate: false,
                                checkedInchise: false,
                                filterBarShow: false,
                              },
                              () => {
                                if (val === "4") {
                                  this.setState(
                                    { checkedTaskuri: true },
                                    () => {
                                      this.refToate.current.GetValues();
                                    }
                                  );
                                }
                              }
                            );
                          },
                        },
                      }}>
                      <select name="SelectBoxTop">
                        <option
                          selected={this.state.selectedGrid === "0"}
                          key={"ListaTipSarcini-0"}
                          value={"0"}>
                          Alocate
                        </option>
                        {this.$f7.data.user.rol <= 3 ? (
                          <option
                            selected={this.state.selectedGrid === "5"}
                            key={"ListaTipSarcini-5"}
                            value={"5"}>
                            Alocate subalterni
                          </option>
                        ) : null}
                        {this.$f7.data.user.rol <= 3 ? (
                          <option
                            selected={this.state.selectedGrid === "7"}
                            key={"ListaTipSarcini-7"}
                            value={"7"}>
                            Inchise subalterni
                          </option>
                        ) : null}
                        <option
                          selected={this.state.selectedGrid === "3"}
                          key={"ListaTipSarcini-3"}
                          value={"3"}>
                          Primite
                        </option>
                        <option
                          selected={this.state.selectedGrid === "6"}
                          key={"ListaTipSarcini-6"}
                          value={"6"}>
                          Propuse
                        </option>
                        <option
                          selected={this.state.selectedGrid === "8"}
                          key={"ListaTipSarcini-8"}
                          value={"8"}>
                          Propuse subalterni
                        </option>
                        <option
                          selected={this.state.selectedGrid === "1"}
                          key={"ListaTipSarcini-1"}
                          value={"1"}>
                          Nealocate
                        </option>
                        <option
                          selected={this.state.selectedGrid === "2"}
                          key={"ListaTipSarcini-2"}
                          value={"2"}>
                          Inchise
                        </option>
                        {this.$f7.data.user.administrator ? (
                          <option
                            selected={this.state.selectedGrid === "4"}
                            key={"ListaTipSarcini-4"}
                            value={"4"}>
                            Toate
                          </option>
                        ) : null}
                      </select>
                      <Icon
                        color={"var(--f7-theme-color)"}
                        slot="content-start"
                        size={20}
                        material="list"
                      />
                    </ListItem>
                  </List>

                  {this.$f7.data.user.administrator ? (
                    <List
                      id={"listaSalariati"}
                      style={{
                        marginBottom: 8,
                        marginLeft: 10,
                        width: 200,
                      }}>
                      <ListInput
                        className="roundedOutlinedSmartSelect"
                        type="text"
                        placeholder={"Salariat"}
                        value={
                          this.$f7.data.Salariati.find((r) => {
                            return r.value === this.state.idSalariatFunctii;
                          })
                            ? this.$f7.data.Salariati.find((r) => {
                                return r.value === this.state.idSalariatFunctii;
                              }).key
                            : this.state.idSalariatFunctii
                        }
                        key={"selectboxSalariatiFunctii"}
                        floatingLabel
                        label={"Salariat"}
                        onFocus={(e) => {
                          var t = e.currentTarget;
                          this.$f7.popover.open(
                            ".popoverSalariatiAdministrator",
                            t
                          );
                        }}
                        title={"Salariat"}>
                        <Icon
                          slot="content-start"
                          style={{
                            color: "var(--f7-theme-color)",
                            left: "-2px",
                          }}
                          size={20}
                          material="list"
                        />
                      </ListInput>
                    </List>
                  ) : null}
                </div>

                <Fab
                  onClick={() => {
                    var panelLegend = this.$f7.panel.get(".panelLegend");
                    if (panelLegend) {
                      panelLegend.open();
                    }
                  }}
                  position="right-top"
                  slot="fixed"
                  style={{
                    borderRadius: "50%",
                    marginRight: 40,
                  }}>
                  <Icon style={{ margin: 0 }} md={"material:info"} />
                </Fab>
                <Fab
                  className={
                    this.state.checkedArhivate ||
                    this.state.checkedAscunse ||
                    this.state.checkedSelectate ||
                    this.state.checkedTaskuri ||
                    this.state.checkedInchise
                      ? "fabToateFiltrate"
                      : "fabToate"
                  }
                  onClick={() => {
                    this.setState(
                      {
                        filterBarShow: !this.state.filterBarShow,
                      },
                      () => {
                        if (!this.state.filterBarShow && this.state.filtered) {
                          if (this.state.selectedGrid === "4") {
                            this.refToate.current.GetValues();
                          }
                          if (this.state.selectedGrid === "0") {
                            this.refAlocate.current.GetValues();
                          }
                          if (this.state.selectedGrid === "1") {
                            this.refNealocate.current.GetValues();
                          }
                          if (this.state.selectedGrid === "2") {
                            this.refInchise.current.GetValues();
                          }
                          if (this.state.selectedGrid === "3") {
                            this.refPrimite.current.GetValues();
                          }
                          if (this.state.selectedGrid === "5") {
                            this.refAlocateSubalterni.current.GetValues();
                          }
                          if (this.state.selectedGrid === "6") {
                            this.refPropuse.current.GetValues();
                          }
                          if (this.state.selectedGrid === "7") {
                            this.refInchiseSubalterni.current.GetValues();
                          }
                          if (this.state.selectedGrid === "8") {
                            this.refPropuseSubalterni.current.GetValues();
                          }
                          if (this.state.selectedGrid === "9") {
                            this.refTotalTaskuri.current.GetValues();
                          }
                        } else {
                          this.setState({ filtered: false });
                        }
                      }
                    );
                  }}
                  position="right-top"
                  slot="fixed"
                  style={{
                    borderRadius: "50%",
                  }}>
                  <Icon
                    style={{ margin: 0 }}
                    md={
                      this.state.filterBarShow
                        ? "material:save"
                        : "material:filter_list"
                    }
                  />
                </Fab>

                <div
                  className="filter-bar-treelist"
                  style={{
                    width: this.state.filterBarShow
                      ? "calc(100% - 200px)"
                      : "0px",
                  }}>
                  {this.state.selectedGrid === "4" ? (
                    <Checkbox
                      checked={this.state.checkedTaskuri}
                      style={{ display: "flex" }}
                      onChange={(e) => {
                        this.setState({
                          filtered: true,
                          checkedTaskuri: !this.state.checkedTaskuri,
                        });
                      }}
                      name="Checkbox1">
                      Taskuri
                    </Checkbox>
                  ) : null}
                  {this.state.selectedGrid === "4" ? (
                    <Checkbox
                      checked={this.state.checkedSubtotaluri}
                      onChange={(e) => {
                        this.setState({
                          filtered: true,
                          checkedSubtotaluri: !this.state.checkedSubtotaluri,
                        });
                      }}
                      style={{ display: "flex" }}
                      name="Checkbox2">
                      Subtotaluri
                    </Checkbox>
                  ) : null}
                  <Checkbox
                    checked={this.state.checkedAscunse}
                    onChange={(e) => {
                      this.setState({
                        filtered: true,
                        checkedAscunse: !this.state.checkedAscunse,
                      });
                    }}
                    style={{ display: "flex" }}
                    name="Checkbox4">
                    Ascunse
                  </Checkbox>
                  <Checkbox
                    checked={this.state.checkedArhivate}
                    onChange={(e) => {
                      this.setState({
                        filtered: true,
                        checkedArhivate: !this.state.checkedArhivate,
                      });
                    }}
                    style={{ display: "flex" }}
                    name="Checkbox5">
                    Arhivate
                  </Checkbox>
                  {this.state.selectedGrid === "4" ? (
                    <Checkbox
                      checked={this.state.checkedSelectate}
                      onChange={(e) => {
                        this.setState({
                          filtered: true,
                          checkedSelectate: !this.state.checkedSelectate,
                        });
                      }}
                      style={{ display: "flex" }}
                      name="Checkbox6">
                      Selectate
                    </Checkbox>
                  ) : null}
                  {this.state.selectedGrid === "5" ||
                  this.state.selectedGrid === "6" ||
                  this.state.selectedGrid === "8" ? (
                    <Checkbox
                      checked={this.state.checkedInchise}
                      onChange={(e) => {
                        this.setState({
                          filtered: true,
                          checkedInchise: !this.state.checkedInchise,
                        });
                      }}
                      style={{ display: "flex" }}
                      name="Checkbox6">
                      Inchise
                    </Checkbox>
                  ) : null}
                </div>

                {this.state.selectedGrid === "9" ? (
                  <TotalTaskuriSalariat ref={this.refTotalTaskuri} />
                ) : this.state.selectedGrid === "8" ? (
                  <SarciniPropuseSubalterniGrid
                    checkedInchise={this.state.checkedInchise}
                    checkedTaskuri={true}
                    checkedAscunse={this.state.checkedAscunse}
                    checkedArhivate={this.state.checkedArhivate}
                    ref={this.refPropuseSubalterni}
                  />
                ) : this.state.selectedGrid === "7" ? (
                  <SarciniInchiseSubalterniGrid
                    checkedInchise={this.state.checkedInchise}
                    checkedTaskuri={true}
                    checkedAscunse={this.state.checkedAscunse}
                    checkedArhivate={this.state.checkedArhivate}
                    ref={this.refInchiseSubalterni}
                  />
                ) : this.state.selectedGrid === "6" ? (
                  <SarciniPropuseGrid
                    checkedInchise={this.state.checkedInchise}
                    checkedTaskuri={true}
                    checkedAscunse={this.state.checkedAscunse}
                    checkedArhivate={this.state.checkedArhivate}
                    ref={this.refPropuse}
                  />
                ) : this.state.selectedGrid === "5" ? (
                  <SarciniAlocateSubalterniGrid
                    checkedInchise={this.state.checkedInchise}
                    checkedTaskuri={true}
                    checkedAscunse={this.state.checkedAscunse}
                    checkedArhivate={this.state.checkedArhivate}
                    ref={this.refAlocateSubalterni}
                  />
                ) : this.state.selectedGrid === "0" ? (
                  <SarciniAlocateGrid
                    checkedTaskuri={true}
                    checkedAscunse={this.state.checkedAscunse}
                    checkedArhivate={this.state.checkedArhivate}
                    ref={this.refAlocate}
                  />
                ) : this.state.selectedGrid === "1" ? (
                  <SarciniNealocateGrid
                    checkedTaskuri={true}
                    checkedAscunse={this.state.checkedAscunse}
                    checkedArhivate={this.state.checkedArhivate}
                    ref={this.refNealocate}
                  />
                ) : this.state.selectedGrid === "2" ? (
                  <SarciniRezolvateGrid
                    checkedTaskuri={true}
                    checkedAscunse={this.state.checkedAscunse}
                    checkedArhivate={this.state.checkedArhivate}
                    ref={this.refInchise}
                  />
                ) : this.state.selectedGrid === "3" ? (
                  <SarciniPrimiteGrid
                    checkedTaskuri={true}
                    checkedAscunse={this.state.checkedAscunse}
                    checkedArhivate={this.state.checkedArhivate}
                    ref={this.refPrimite}
                  />
                ) : (
                  <SarciniToateGrid
                    checkedTaskuri={this.state.checkedTaskuri}
                    checkedAscunse={this.state.checkedAscunse}
                    checkedArhivate={this.state.checkedArhivate}
                    ref={this.refToate}
                    {...this.props}
                  />
                )}
              </div>
            </Col>

            <Col
              ref={this.ref2}
              {...this.state.settings2}
              style={
                this.state.preaMic
                  ? {}
                  : {
                      height: window.innerHeight - 48,
                      width:
                        localStorage.getItem("HomeWidthDreapta") === null
                          ? "29%"
                          : localStorage.getItem("HomeWidthDreapta"),
                    }
              }
              resizable={!this.state.preaMic}>
              <View
                on={{
                  pageBeforeIn: (e, f, g) => {
                    this.$f7.methods
                      .stopSpeech()
                      .then((r) => {})
                      .catch((err) => {});
                  },
                }}
                removeElements={true}
                componentCache={false}
                id="MainDreapta"
                style={{ minHeight: "50vh" }}
                name="MainDreapta"
                onViewInit={(e) => {
                  this.$f7.data.routerDreapta = e.router;
                }}
                url={"/agendaGridMainDreapta/"}
              />
            </Col>
          </Row>
        </div>

        <Popover
          onPopoverOpen={(e) => {
            var max = this.$f7.methods.maxDepth(
              null,
              this.Salariati,
              "value",
              "ref"
            );

            this.setState({ showTreelist: true, maxDepth: max });
          }}
          style={{ maxHeight: 314, height: 314, width: 250 }}
          onPopoverClose={() => {
            this.setState({ showTreelist: false });
          }}
          className={"popoverSalariatiAdministrator"}>
          <Page style={{ overflow: "hidden", height: "auto" }}>
            <div
              style={{
                position: "relative",
                overflow: "auto",
                maxHeight: 314,
                padding: 8,
              }}>
              {this.state.showTreelist ? (
                <>
                  {this.state.maxDepth > 0 ? (
                    <Range
                      label={true}
                      className={"rangeDepth"}
                      min={0}
                      max={this.state.maxDepth}
                      step={1}
                      value={this.state.level}
                      scale={true}
                      scaleSteps={this.state.maxDepth}
                      scaleSubSteps={1}
                      onRangeChanged={(e) => {
                        this.expandToLevel(e);
                      }}
                    />
                  ) : null}
                  <TreeList
                    filterMode={"fullBranch"}
                    selection={{ mode: "single", recursive: false }}
                    rootValue={-1}
                    keyExpr={"value"}
                    dataSource={this.Salariati}
                    id={"Tree"}
                    parentIdExpr={"ref"}
                    showColumnHeaders={true}
                    showColumnLines={true}
                    showBorders={true}
                    columns={[
                      {
                        dataField: "key",
                        caption: "Denumire",
                      },
                    ]}
                    focusedRowEnabled={true}
                    onInitialized={(e) => {
                      this.setState({ componentaTree: e.component }, () => {
                        var inputs = document.getElementsByClassName(
                          "dx-texteditor-input"
                        );
                        setTimeout(() => {
                          inputs[inputs.length - 1].focus();
                        }, 350);
                      });
                    }}
                    onSelectionChanged={(f) => {
                      var val = null;
                      var selectedRowsData = f.selectedRowsData;
                      if (
                        selectedRowsData !== undefined &&
                        selectedRowsData !== null &&
                        selectedRowsData.length > 0
                      ) {
                        val = selectedRowsData[0].value;
                        this.setState({ idSalariatFunctii: val }, () => {
                          this.$f7.data.Proiect = null;
                          this.$f7.methods.openMainDreaptaById(null);
                          this.$f7.data.idSalariatFunctii = parseInt(val);
                          this.$f7.methods.refreshStanga();
                          this.$f7.popover.close(
                            ".popoverSalariatiAdministrator",
                            true
                          );
                        });
                      }
                    }}>
                    <SearchPanel visible={true} />
                  </TreeList>
                </>
              ) : null}
            </div>
          </Page>
        </Popover>
      </Page>
    );
  }
}
export default HomePage;
