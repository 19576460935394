import React from "react";
import TreeList from "devextreme-react/tree-list";
import axios from "axios";
import {
  Row,
  Col,
  List,
  ListItem,
  Icon,
  ListInput,
  Button,
  Preloader,
} from "framework7-react";
import moment from "moment";
var settings2 = {
  width: "50",
  xsmall: "100",
  small: "100",
  medium: "80",
  large: "80",
  xlarge: "80",
};
var settings1 = {
  width: "50",
  xsmall: "100",
  small: "100",
  medium: "20",
  large: "20",
  xlarge: "20",
};

/**
 * @description Afiseaza realizarile dintr-un interval selectat per un proiect selectat
 * @date 08/04/2022
 * @class ProiecteRealizari
 * @extends {React.Component}
 */
class ProiecteRealizari extends React.Component {
  constructor(props) {
    super(props);
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;

    this.state = {
      dataLoaded: true,
      linkApi: "/api/ProiecteRealizari",
      denumire: "Proiecte Realizari",
      dataSource: [],
      rootValue: null,
      dataStart: null,
      dataFinal: null,
      salariati: this.Salariati[0].value,
      idProiect: this.$f7.data.Proiecte[0].value,
      name: Math.random().toString(36).substring(7).toString(),
    };
  }

  /**
   * @description Ia realizarile dintr-un interval selectat pentru proiectul selectat si salariatul selectat
   * @function
   * @date 08/04/2022
   * @memberof ProiecteRealizari
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetPROIECTEREALIZARI?" +
          "parinte=" +
          this.state.idProiect +
          "&dataStart=" +
          this.state.dataStart +
          "&dataEnd=" +
          this.state.dataFinal +
          "&salariati=" +
          this.state.salariati.toString()
      )
      .then((response) => {
        this.setState(
          {
            dataSource: response.data,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  render() {
    return (
      <Row style={{ padding: "8px 4px 0px 4px" }}>
        <Col {...settings1}>
          <List>
            <ListInput
              key={"DeLaProiecteRealizari"}
              floatingLabel
              label={"De la"}
              validate
              type="datepicker"
              placeholder={"De la"}
              calendarParams={{
                value: [
                  this.state.dataStart === "" || this.state.dataStart === null
                    ? new Date()
                    : new Date(this.state.dataStart),
                ],
                toolbarCloseText: "Done",
                toolbar: true,
                openIn: "popover",
                footer: true,
                dateFormat: {
                  month: "numeric",
                  day: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                },
                timePicker: true,
                on: {
                  closed: (e, val) => {
                    var value = e.getValue();
                    this.setState({ dataStart: moment(value[0]).format() });
                  },
                },
              }}
              value={[
                this.state.dataStart === "" || this.state.dataStart === null
                  ? new Date()
                  : new Date(this.state.dataStart),
              ]}>
              <Icon
                slot="content-start"
                style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                size={20}
                material="today"
              />
            </ListInput>
            <ListInput
              key={"PanaLaProiecteRealizari"}
              floatingLabel
              label={"Pana la"}
              validate
              type="datepicker"
              placeholder={"Pana la"}
              calendarParams={{
                value: [
                  this.state.dataFinal === "" || this.state.dataFinal === null
                    ? new Date()
                    : new Date(this.state.dataFinal),
                ],
                toolbarCloseText: "Done",
                toolbar: true,
                openIn: "popover",
                footer: true,
                dateFormat: {
                  month: "numeric",
                  day: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                },
                timePicker: true,
                on: {
                  closed: (e, val) => {
                    var value = e.getValue();
                    this.setState({ dataFinal: moment(value[0]).format() });
                  },
                },
              }}
              value={[
                this.state.dataFinal === "" || this.state.dataFinal === null
                  ? new Date()
                  : new Date(this.state.dataFinal),
              ]}>
              <Icon
                slot="content-start"
                style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                size={20}
                material="today"
              />
            </ListInput>
            <ListItem
              key={"ProiectulProiecteRealizari"}
              className="roundedOutlinedSmartSelect"
              floatingLabel
              required
              label={"Proiectul"}
              validate
              value={this.state.idProiect}
              title={"Proiectul"}
              smartSelect
              smartSelectParams={{
                virtualList: true,
                searchbar: true,
                openIn: "popup",
                popupTabletFullscreen: true,
                popupPush: true,
                closeOnSelect: true,
                on: {
                  closed: (event) => {
                    var val = event.getValue();
                    this.setState({ idProiect: val });
                  },
                },
              }}>
              <select
                defaultValue={this.state.idProiect}
                name="SelectBoxProiectul">
                {this.$f7.data.Proiecte.map((obj, index) => (
                  <option
                    key={"ProiecteProiecteRealizari" + index}
                    value={obj.value}>
                    {obj.key}
                  </option>
                ))}
              </select>
              <Icon
                slot="content-start"
                style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                size={20}
                material="list"
              />
            </ListItem>
            <ListItem
              key={"SalariatProiecteRealizari"}
              className="roundedOutlinedSmartSelect"
              floatingLabel
              required
              label={"Salariat"}
              validate
              value={this.state.salariati}
              title={"Salariati"}
              smartSelect
              smartSelectParams={{
                virtualList: true,
                searchbar: true,
                openIn: "popup",
                popupTabletFullscreen: true,
                popupPush: true,
                closeOnSelect: true,
                on: {
                  closed: (event) => {
                    var val = event.getValue();
                    this.setState({ salariati: val });
                  },
                },
              }}>
              <select
                defaultValue={this.state.salariati}
                name="SelectBoxProiectul">
                {this.Salariati.map((obj, index) => (
                  <option
                    key={"listaSalariatiProiecteRealizari" + index}
                    value={obj.value}>
                    {obj.key}
                  </option>
                ))}
              </select>
              <Icon
                slot="content-start"
                style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                size={20}
                material="list"
              />
            </ListItem>
          </List>

          <Button
            tooltip={"Afiseaza"}
            fill
            style={{ maxWidth: 200, margin: "0 auto", marginTop: 16 }}
            onClick={() => {
              if (
                this.state.dataFinal !== null &&
                this.state.dataStart !== null &&
                this.state.idProiect !== null &&
                this.state.salariati !== null
              ) {
                this.setState({ rootValue: this.state.idProiect }, () => {
                  this.GetValues();
                });
              } else {
                this.$f7.methods.openNotification(
                  "Nu sunt inserate toate datele necesare."
                );
              }
            }}>
            Afiseaza
          </Button>
        </Col>
        <Col {...settings2}>
          {this.state.dataLoaded ? (
            <TreeList
              filterMode={"fullBranch"}
              rootValue={this.state.rootValue}
              dataSource={this.state.dataSource}
              showBorders={true}
              columnMinWidth={70}
              keyExpr={"idProiect"}
              parentIdExpr={"refProiect"}
              id="TreeList"
            />
          ) : (
            <div
              style={{
                position: "absolute",
                width: "35px",
                height: "35px",
                top: "calc(50vh)",
                left: "50%",
              }}>
              <Preloader size={40} color={"var(--f7-theme-color)"} />
            </div>
          )}
        </Col>
      </Row>
    );
  }
}
export default ProiecteRealizari;
