import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";
import {
  Segmented,
  Button,
  Icon,
  Popup,
  NavRight,
  Navbar,
  Page,
  Link,
  List,
  ListInput,
  TreeviewItem,
  Treeview,
} from "framework7-react";

/**
 * @description Interfata de intretinere a salariatilor din firma
 * @date 07/04/2022
 * @class Salariati
 * @extends {React.Component}
 */
class Salariati extends React.Component {
  constructor(props) {
    super(props);
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.state = {
      dataLoaded: false,
      currentUser: null,
      departamente: this.$f7.data.Departamente,
      users: [{ Nume: "Cristea", Prenume: "Ciprian", Ref_Salariat: null }],
      salariatiArhivati: [],
      inputs: [
        {
          key: "Id_Salariat",
          keyBaza: "iD_SALARIAT",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        {
          key: "Angajator",
          keyBaza: "iD_ANGAJATOR",
          tip: "selectbox",
          lista: this.$f7.data.Angajatori,
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "Nume", keyBaza: "nume", requiredAdd: true, requiredEdit: true },
        {
          key: "Prenume",
          keyBaza: "prenume",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Nume_Login",
          keyBaza: "numE_LOGIN",
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "Activ", keyBaza: "activ", tip: "bool" },
        {
          key: "Ref_Salariat",
          keyBaza: "reF_SALARIAT",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        {
          key: "Ordine",
          keyBaza: "ordine",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
        },
        { key: "Data_Angajare", keyBaza: "datA_ANGAJARE", tip: "datebox" },
        { key: "Data_Plecare", keyBaza: "datA_PLECARE", tip: "datebox" },
        {
          key: "Adresa_Email",
          keyBaza: "adresA_EMAIL",
          tip: "email",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Salariu_Ora",
          keyBaza: "salariU_ORA",
          tip: "number",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Departament",
          keyBaza: "iD_DEPARTAMENT",
          tip: "selectbox",
          lista: this.$f7.data.Departamente,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Birou",
          keyBaza: "id_birou",
          tip: "selectbox",
          lista: this.$f7.data.Birouri,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Profesie",
          keyBaza: "iD_PROFESIE",
          tip: "selectbox",
          lista: this.$f7.data.Profesii,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "RolDeAplicatie",
          keyBaza: "rol",
          tip: "selectbox",
          lista: this.$f7.data.Roluri,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Competenta",
          keyBaza: "competente",
          tip: "selectbox",
          lista: this.$f7.data.Competente,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "EditRap",
          keyBaza: "reporT_BUILDER",
          tip: "bool",
        },
        { key: "ApiToken", keyBaza: "apitoken" },
        { key: "RetineLogin", keyBaza: "retinE_USER", tip: "bool" },
        { key: "SalvarePreferinta", keyBaza: "savE_PREF", tip: "bool" },
        { key: "IntrebareSalvare", keyBaza: "asK_PREF", tip: "bool" },
        { key: "GrCol1", keyBaza: "gradient_culoare1", tip: "colorpicker" },
        { key: "GrCol2", keyBaza: "gradient_culoare2", tip: "colorpicker" },
        { key: "Arhivat", keyBaza: "isArhivat", tip: "bool" },
        { key: "Ascuns", keyBaza: "isHidden", tip: "bool" },
      ],
      linkApi: "/api/Salariati",
      linkApiARH: "/api/SalariatiArh",
      password: "",
      passwordConfirm: "",
      denumire: "Salariati",
      height: window.innerHeight - 44,
      inclusivArhivati: false,
      popupNameOrganigrama:
        "Popup" + Math.random().toString(36).substring(7).toString(),
      popupNameSchimbareParola:
        "Popup" + Math.random().toString(36).substring(7).toString(),
      popupNameDepartamente:
        "Popup" + Math.random().toString(36).substring(7).toString(),
    };
    this.refDataGrid1 = React.createRef();
  }

  setUsers = (users) => {
    var inputsUsers = [
      { key: "Id_Salariat", tip: "number" },
      { key: "Nume" },
      { key: "Prenume" },
      { key: "Ref_Salariat", tip: "number" },
      { key: "Id_Departament", tip: "number" },
    ];
    var baza = this.$f7.methods.reconstructieBaza(
      {
        data: users.data.filter((u) => {
          return u.activ;
        }),
      },
      inputsUsers
    );
    this.setState({ users: baza });
  };

  /**
   * @description Ia lista de salariati pentru interfata de intretinere si ii adauga in salariati arhivati pe cei de sunt arhivati
   * @function
   * @date 07/04/2022
   * @memberof Salariati
   */
  GetValues = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        dataLoaded: false,
      });
      axios
        .get(this.$f7.data.baseURL + this.state.linkApi + "/GetSALARIATI")
        .then((responseSalariati) => {
          var date = responseSalariati;
          date.data.map((item) => {
            item = this.$f7.methods.restructurareBaza(
              item,
              this.state.inputs,
              "key"
            );
            return false;
          });
          var baza = this.$f7.methods.reconstructieBaza(
            date,
            this.state.inputs
          );
          var salariatiArhivati = baza.filter((r) => {
            return r.Arhivat === true;
          });
          this.setUsers(responseSalariati);
          var filtre = {};
          this.state.inputs.map((input) => {
            filtre[input.key] = {
              denumire: input.key,
              value: null,
              comparatie: null,
            };
            return null;
          });

          filtre["Activ"].value = "True";
          filtre["Activ"].comparatie = "bool";

          if (!this.state.inclusivArhivati) {
            baza = baza.filter((r) => {
              return r.Arhivat === false;
            });
          }

          this.setState(
            {
              dataSource: baza,
              salariatiArhivati: salariatiArhivati,
              filtre: filtre,
            },
            () => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  //this.refDataGrid1.current.setRenderItems(baza);
                  resolve(responseSalariati);
                }
              );
            }
          );
        })
        .catch((error) => {
          this.setState(
            {
              dataSource: [],
              dataLoaded: true,
            },
            () => {
              this.$f7.dialog.confirm(
                "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                "Ups...",
                () => {
                  this.GetValues();
                },
                () => {
                  reject(error);
                }
              );
            }
          );
        });
    });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  /**
   * @description Adauga o noua inregistrare in baza de date, un nou salariat
   * @function
   * @date 07/04/2022
   * @param {*} data obiectul cu toate informatiile noii inregistrari
   * @memberof Salariati
   */
  AddValue = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApi + "/PostSALARIATI";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Se actualizeaza in baza de date inregistrarea selectata
   * @date 07/04/2022
   * @function
   * @param {*} obj obiect cu noile informatii ale inregistrarii selectate
   * @param {*} id id-ul inregistrarii selectate
   * @memberof Salariati
   */
  UpdateValue = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/GetSALARIATI/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });
        var url =
          this.$f7.data.baseURL + this.state.linkApi + "/PutSALARIATI/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            // this.$f7.methods.openNotification("Modificat cu success");
            this.$f7.notification
              .create({
                title: "Succes",
                icon: '<i class="icon material-icons">done</i>',
                text: "Modificat cu succes",
                closeTimeout: 4000,
              })
              .open();
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Schimba parola salariatului selectat
   * @date 07/04/2022
   * @function
   * @param {*} obj obiectul cu noile informatii ale salariatului, practic vechiul obiect dar cu parola modificata
   * @param {*} id id-ul salariatului selectat
   * @memberof Salariati
   */
  ChangePassword = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/GetSALARIATI/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });
        var url =
          this.$f7.data.baseURL +
          this.state.linkApi +
          "/ChangePasswordSALARIATI/" +
          id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Randeaza sub forma de treelist toate departamentele
   * @function
   * @date 07/04/2022
   * @memberof Salariati
   */
  renderTreeViewDepartamente = () => {
    var departamente = [...this.state.departamente];
    var useri = [...this.state.users];
    return departamente.map((departament, index) => (
      <TreeviewItem
        key={"SalariatiDepartamente-" + departament.key + index}
        iconMaterial="meeting_room"
        label={departament.key}>
        {useri
          .filter((userFilter) => {
            return userFilter.Id_Departament === departament.value;
          })
          .map((user, index2) => (
            <TreeviewItem
              key={
                "SalariatiDepartamente-" +
                user.Nume +
                " " +
                user.Prenume +
                index +
                index2
              }
              iconMaterial="person"
              label={user.Nume + " " + user.Prenume}
            />
          ))}
      </TreeviewItem>
    ));
  };

  /**
   * @description Randeaza datasource-ul(salariatii) sub forma de treelist
   * @date 07/04/2022
   * @param {*} dataSource datasource-ul de randare
   * @function
   * @memberof Salariati
   */
  renderTreeViewUseri = (dataSource) => {
    var useriChilds = dataSource.filter((obj) => {
      return obj.Ref_Salariat === null;
    });
    return useriChilds.map((obj, index) => (
      <TreeviewItem
        key={"SalariatiUseri-" + obj.Nume + "-" + obj.Prenume + index}
        iconMaterial="person"
        label={obj.Nume + " " + obj.Prenume}>
        {this.renderTreeViewItemsUseri(dataSource, obj)}
      </TreeviewItem>
    ));
  };
  /**
   * @description Functia recursiva de este apelata pentru randarea utilizatorilor
   * @date 07/04/2022
   * @param {*} dataSource datasource-ul de randare
   * @param {*} parinte id-ul parintelui
   * @function
   * @memberof Salariati
   */
  renderTreeViewItemsUseri = (dataSource, parinte) => {
    var ds = dataSource.filter((obj) => {
      return obj.Ref_Salariat !== null;
    });
    var useriChilds = ds.filter((obj) => {
      return obj.Ref_Salariat === parinte.Id_Salariat;
    });
    if (useriChilds.length > 0) {
      return useriChilds.map((obj, index) => (
        <TreeviewItem
          key={"SalariatiUseriItems-" + obj.Nume + "-" + obj.Prenume + index}
          iconMaterial="person"
          label={obj.Nume + " " + obj.Prenume}>
          {this.renderTreeViewItemsUseri(ds, obj)}
        </TreeviewItem>
      ));
    } else {
      return null;
    }
  };

  /**
   * @description Sterge din baza de date inregistrarea selectata(Salariati)
   * @date 07/04/2022
   * @param {*} id id-ul inregistrarii selectate
   * @memberof Salariati
   */
  DeleteValue = (id) => {
    var url =
      this.$f7.data.baseURL + this.state.linkApi + "/DeleteSALARIATI/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  /**
   * @description Arhiveaza sau dezarhiveaza un salariat in baza de date
   * @date 07/04/2022
   * @param {*} info obiectul cu informatiile salariatului selectat(inregistrarea selectata)
   * @param {*} arhiveaza true daca se arhiveaza sau false daca se dezarhiveaza utilizatorul
   * @memberof Salariati
   */
  ArhiveazaSalariat = (info, arhiveaza) => {
    const idSelectat = info.rowData.Id_Salariat;
    const numeSelectat = info.rowData.Nume + " " + info.rowData.Prenume;
    const areCopii =
      info.bigDataSource.filter((item) => item[info.refId] === idSelectat)
        .length > 0;
    const esteArhivat =
      this.state.salariatiArhivati.filter(
        (item) => item.Id_Salariat === idSelectat
      ).length > 0;

    if (arhiveaza) {
      if (esteArhivat) {
        this.$f7.methods.openNotification(
          "Acest salariat este deja arhivat!",
          "Atentie"
        );
      } else {
        if (areCopii) {
          this.$f7.methods.openNotification(
            "Acest salariat are subalternii (vizibili sau/si invizibili) si nu se poate arhiva direct. Incercati sa arhivati sau sa mutati mai intai subalternii.",
            "Atentie"
          );
        } else {
          let data = [];
          data.push(idSelectat);

          this.$f7.dialog.confirm(
            'Arhivati salariatul "' + numeSelectat + '"?',
            "Atentie",
            () => {
              // da
              axios({
                data: data,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url:
                  this.$f7.data.baseURL +
                  this.state.linkApi +
                  "/SalariatiMoveToARH",
              })
                .then((response) => {
                  this.$f7.methods.openNotification(
                    '"' + numeSelectat + '" a fost arhivat cu success'
                  );
                  this.GetValues();
                })
                .catch((error) => {
                  console.log("error", error);
                  this.$f7.methods.openNotification("Eroare la arhivare!");
                });
            },
            () => {
              // nu
            }
          );
        }
      }
    } else {
      if (esteArhivat) {
        let data = [];
        data.push(idSelectat);

        this.$f7.dialog.confirm(
          'Dezarhivati salariatul "' + numeSelectat + '"?',
          "Atentie",
          () => {
            // da
            axios({
              data: data,
              headers: { "Content-Type": "application/json" },
              method: "post",
              url:
                this.$f7.data.baseURL +
                this.state.linkApiARH +
                "/SalariatiMoveFromARH",
            })
              .then((response) => {
                this.$f7.methods.openNotification(
                  '"' + numeSelectat + '" a fost dezarhivat cu success'
                );
                this.GetValues();
              })
              .catch((error) => {
                console.log("error", error);
                this.$f7.methods.openNotification("Eroare la dezarhivare!");
              });
          },
          () => {
            // nu
          }
        );
      } else {
        this.$f7.methods.openNotification(
          "Acest salariat nu este arhivat!",
          "Atentie"
        );
      }
    }
  };

  render() {
    return (
      <>
        <Popup
          push={true}
          tabletFullscreen={true}
          className={this.state.popupNameOrganigrama}>
          <Page>
            <Navbar title="Organigrama">
              <NavRight>
                <Link tooltip={"Inchide"} popupClose>
                  Inchide
                </Link>
              </NavRight>
            </Navbar>

            <Treeview>{this.renderTreeViewUseri(this.state.users)}</Treeview>
          </Page>
        </Popup>

        <Popup
          push={true}
          tabletFullscreen={true}
          className={this.state.popupNameDepartamente}>
          <Page>
            <Navbar title="Departamente">
              <NavRight>
                <Link tooltip={"Inchide"} popupClose>
                  Inchide
                </Link>
              </NavRight>
            </Navbar>
            {this.renderTreeViewDepartamente()}
          </Page>
        </Popup>

        <Popup
          push={true}
          tabletFullscreen={true}
          className={this.state.popupNameSchimbareParola}>
          <Page>
            <Navbar title="Modificare parola">
              <NavRight>
                <Link tooltip={"Inchide"} popupClose>
                  Inchide
                </Link>
              </NavRight>
            </Navbar>
            <List
              form
              onSubmit={(e) => {
                e.preventDefault();

                if (this.state.password === this.state.passwordConfirm) {
                  var obj = { ...this.state.currentUser };
                  this.$f7.dialog.confirm(
                    "Sunteti sigur ca doriti salvarea parolei?",
                    "Confirmare",
                    () => {
                      obj["Id_Departament"] = obj.Departament;
                      obj["Id_Birou"] = obj.Birou;
                      obj["Id_Profesie"] = obj.Profesie;
                      obj["Id_Competenta"] = obj.Competenta;
                      obj["Id_Angajator"] = obj.Angajator;
                      obj["Save_Pref"] = obj.SalvarePreferinta;
                      obj["Ask_Pref"] = obj.IntrebareSalvare;
                      obj["Report_Builder"] = obj.EditRap;
                      obj["Rol"] = obj.RolDeAplicatie;
                      obj["Retine_User"] = obj.RetineLogin;
                      obj["Parola"] = this.state.password;
                      delete obj.Departament;
                      delete obj.Birou;
                      delete obj.Profesie;
                      delete obj.Competenta;
                      delete obj.Angajator;
                      delete obj.SalvarePreferinta;
                      delete obj.IntrebareSalvare;
                      delete obj.EditRap;
                      delete obj.RolDeAplicatie;
                      delete obj.RetineLogin;
                      this.ChangePassword(obj, obj.Id_Salariat);
                      this.$f7.popup.close(
                        "." + this.state.popupNameSchimbareParola
                      );
                    }
                  );
                } else {
                  this.$f7.methods.openNotification(
                    "Inserati din nou cele 2 parole."
                  );
                }
              }}>
              <ListInput
                key={"item-1"}
                floatingLabel
                inputmode="text"
                label={"Parola"}
                validate
                type="password"
                placeholder={"Parola"}
                value={this.state.password}
                onInput={(e) => {
                  var val = e.target.value;
                  this.setState({ password: val });
                }}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="security"></Icon>
              </ListInput>
              <ListInput
                key={"item-2"}
                floatingLabel
                inputmode="text"
                label={"Confirmare parola"}
                validate
                type="password"
                placeholder={"Confirmare parola"}
                value={this.state.passwordConfirm}
                onInput={(e) => {
                  var val = e.target.value;
                  this.setState({ passwordConfirm: val });
                }}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="security"></Icon>
              </ListInput>
              <Segmented
                raised
                style={{ maxWidth: 400, margin: "0 auto", marginTop: 8 }}
                tag="div">
                <Button tooltip={"Salveaza"} fill small type="submit">
                  Salveaza
                </Button>
              </Segmented>
            </List>
          </Page>
        </Popup>

        <div style={{ position: "relative" }}>
          <Segmented
            className="butoaneContextDatagrid"
            raised
            style={{
              position: "absolute",
              width: "calc(100% - 176px)",
              top: 3,
              left: 0,
            }}
            tag="div">
            <Button
              tooltip={"Organigrama salariati"}
              fill
              small
              popupOpen={"." + this.state.popupNameOrganigrama}>
              Organigrama
            </Button>
            <Button
              tooltip={"Departamente"}
              fill
              small
              popupOpen={"." + this.state.popupNameDepartamente}>
              Departamente
            </Button>
            <Button
              tooltip={"Modificare parola"}
              fill
              small
              popupOpen={
                this.state.currentUser
                  ? "." + this.state.popupNameSchimbareParola
                  : null
              }
              onClick={() => {
                if (this.state.currentUser === null) {
                  this.$f7.methods.openNotification("Selecteaza un salariat!");
                }
              }}>
              Modifica parola
            </Button>
            <Button
              tooltip={
                this.state.inclusivArhivati
                  ? "Afiseaza doar nearhivati"
                  : "Afiseaza inclusiv arhivati"
              }
              fill
              small
              onClick={() => {
                this.setState(
                  {
                    inclusivArhivati: !this.state.inclusivArhivati,
                  },
                  () => {
                    this.GetValues();
                  }
                );
              }}>
              {this.state.inclusivArhivati
                ? "Afiseaza doar nearhivati"
                : "Afiseaza inclusiv arhivati"}
            </Button>
          </Segmented>
          <DataGrid
            dataLoaded={this.state.dataLoaded}
            refresh={this.GetValues}
            renderAccordion={true}
            ignoreInputsAdd={["Data_Plecare"]}
            name={"Salariati"}
            dragRows={true}
            id={"Id_Salariat"}
            refId={"Ref_Salariat"}
            initialRefId={null}
            ref={this.refDataGrid1}
            renderUpdate={true}
            renderDelete={true}
            renderAdd={true}
            maxHeight={this.state.height}
            dataSource={this.state.dataSource}
            inputs={this.state.inputs}
            filtre={this.state.filtre}
            onAdd={(state) => {
              var obj = { ...state.editedItem };
              obj = this.$f7.methods.restructurareBaza(
                obj,
                this.state.inputs,
                "keyBaza"
              );
              delete obj.iD_SALARIAT;
              this.AddValue(obj);
            }}
            onUpdate={(state) => {
              var obj = { ...state.editedItem };

              obj = this.$f7.methods.restructurareBaza(
                obj,
                this.state.inputs,
                "keyBaza"
              );

              this.UpdateValue(obj, obj.iD_SALARIAT);
            }}
            onDelete={(state) => {
              var obj = { ...state.editedItem };
              this.DeleteValue(obj.Id_Salariat);
            }}
            onRowClick={(state) => {
              this.setState(
                {
                  currentUser: state.rowData,
                },
                () => {}
              );
            }}
            contextMenuItems={[
              {
                key: "Arhiveaza",
                onClick: (state) => {
                  setTimeout(() => {
                    this.ArhiveazaSalariat(state, true);
                  }, 500);
                },
                icon: "horizontal_split",
              },
              {
                key: "Dezarhiveaza",
                onClick: (state) => {
                  setTimeout(() => {
                    this.ArhiveazaSalariat(state, false);
                  }, 500);
                },
                icon: "horizontal_split",
              },
            ]}
            dragRowsOnStart={(e, oldIndex) => {
              var itemeRandate = e.state.renderItems.slice(
                e.state.numberBefore,
                e.state.numberBefore + e.state.count
              );
              var item = itemeRandate[oldIndex];
              if (item === undefined) {
                item = itemeRandate[itemeRandate.length - 1];
              }
              this.setState({ rowItemData: item });
            }}
            dragRowsOnEnd={(e) => {
              if (this.state.rowItemData !== null) {
                var obj = { Ref_Salariat: e.state.initialRefId };
                var id = this.state.rowItemData.Id_Salariat;
                this.$f7.dialog.confirm(
                  "Sunteti sigur?",
                  "Confirm",
                  () => {
                    this.UpdateValue(obj, id);
                  },
                  () => {
                    this.GetValues();
                  }
                );
              }
            }}
          />
        </div>
      </>
    );
  }
}
export default Salariati;
