import React from "react";
import { Row, Col, Fab, Checkbox, List, ListInput } from "framework7-react";
import ATSalariatiGridAlocate from "./ATSalariatiGridAlocate";
import axios from "axios";
import moment from "moment";
import TreeList from "devextreme-react/tree-list";
/**
 * @description Randeaza lista de salariati din interfata de alocare task
 * @date 06/04/2022
 * @class ATSalariati
 * @extends {React.Component}
 */
class ATSalariati extends React.Component {
  constructor(props) {
    super(props);
    this.inaltime = window.innerHeight - 34 - 28 - 28 - 8 - 50;
    this.listaSalariati = this.$f7.data.toggleTotiSalariatii
      ? [...this.$f7.data.Salariati].filter((r) => {
          return r.key !== "Fara";
        })
      : [...this.$f7.data.SalariatiActivi].filter((r) => {
          return r.key !== "Fara";
        });

    this.state = {
      maxDepth: 0,
      level: 0,
      levelSalariat: 0,
      levelMe: 0,
      showHidden: true,
      showArh: false,
      SalariatSelectat: null,
      vlData: { items: [] },
      linkApi: "/api/SarciniSalariat",
      listaSarcini: [],
      listaSalariati: [],
      listaSalariatiInitial: this.listaSalariati,
      testArr: [],
      instructiuni: "",
      linkApiSesizari: "/api/Sesizari",
    };
    this.refGrid = React.createRef();
  }

  /**
   * @description Adauga un mesaj cu instructiuni taskului de urmeaza a fi propus(ii trimite mesaj pe chat)
   * @date 06/04/2022
   * @function
   * @param {*} data continutul mesajului
   * @memberof ATSalariati
   */
  AddMesaj = (data) => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL + this.state.linkApiSesizari + "/Postsesizari";
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Ia informatiile tuturor taskurilor alocate(pentru randarea numarului cate taskuri alocate are fiecare salariat)
   * @function
   * @date 06/04/2022
   * @memberof ATSalariati
   */
  GetValues = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            this.state.linkApi +
            "/GetSARCINI_SALARIATAlocateAll/1/0/0/0"
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Aloca taskul unui salariat, ia taskul dupa id, dupa aceea face un post cu o sarcina cu noile informatii(noua persoana caruia ii apartine taskul)
   * @date 06/04/2022
   * @param {*} id id-ul taskului
   * @param {*} idSalariat id-ul salariatului
   * @memberof ATSalariati
   */
  AlocaTask = (id, idSalariat) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/SarciniSalariat/GetSARCINI_SALARIATAlocatByProiect/" +
            id
        )
        .then((response) => {
          let sarcina = response.data;
          let url =
            this.$f7.data.baseURL + "/api/SarciniSalariat/PostSARCINI_SALARIAT";

          let sarcinaAlocata = {
            iD_PROIECT: parseInt(id),
            iD_SALARIAT: idSalariat,
            dE_CINE: this.$f7.data.user.iD_SALARIAT,
            datA_ALOCARII: moment().format(),
            datA_DEALOCARII:
              this.state.levelMe < this.state.levelSalariat ||
              this.$f7.data.user.administrator === true
                ? moment().format()
                : null, //null
            dealoC_CINE:
              this.state.levelMe < this.state.levelSalariat ||
              this.$f7.data.user.administrator === true
                ? this.$f7.data.user.iD_SALARIAT
                : null, //null
            stare: 2,
          };

          let sarcinaAlocata2 = {
            iD_PROIECT: parseInt(id),
            iD_SALARIAT: idSalariat,
            dE_CINE: this.$f7.data.user.iD_SALARIAT,
            datA_ALOCARII: moment().format(),
            datA_DEALOCARII: null,
            dealoC_CINE: null,
            stare: 1,
          };
          console.log("sarcina", sarcina);

          if (sarcina === "") {
            axios({
              data: sarcinaAlocata,
              headers: { "Content-Type": "application/json" },
              method: "post",
              url: url,
            })
              .then((response1) => {
                if (
                  this.state.levelMe < this.state.levelSalariat ||
                  this.$f7.data.user.administrator === true
                ) {
                  //PENTRU ALOCARE FARA SA ACCEPTE
                  axios({
                    data: sarcinaAlocata2,
                    headers: { "Content-Type": "application/json" },
                    method: "post",
                    url: url,
                  })
                    .then((response2) => {
                      resolve(response2);
                    })
                    .catch((error) => {
                      console.log("error", error);
                      reject(error);
                    });
                } else {
                  resolve(response1);
                }
              })
              .catch((error) => {
                console.log("error", error);
                reject(error);
              });
          } else {
            sarcina.datA_DEALOCARII = moment().format();
            sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT;

            let urlUpdate =
              this.$f7.data.baseURL +
              "/api/SarciniSalariat/PutSARCINI_SALARIAT/" +
              sarcina.iD_SARCINA;

            axios({
              data: sarcina,
              headers: { "Content-Type": "application/json" },
              method: "put",
              url: urlUpdate,
            })
              .then((response) => {
                axios({
                  data: sarcinaAlocata,
                  headers: { "Content-Type": "application/json" },
                  method: "post",
                  url: url,
                })
                  .then((response1) => {
                    const numeSalariatPentru = this.$f7.data.Salariati.find(
                      (s) => s.value === sarcina.dE_CINE
                    )
                      ? this.$f7.data.Salariati.find(
                          (s) => s.value === sarcina.dE_CINE
                        ).key
                      : "Sistem";
                    const numeSalariatDeLa = this.$f7.data.Salariati.find(
                      (s) => s.value === this.$f7.data.user.iD_SALARIAT
                    )
                      ? this.$f7.data.Salariati.find(
                          (s) => s.value === this.$f7.data.user.iD_SALARIAT
                        ).key
                      : "Sistem";
                    const numeTrimisLa = this.$f7.data.Salariati.find(
                      (s) => s.value === idSalariat
                    )
                      ? this.$f7.data.Salariati.find(
                          (s) => s.value === idSalariat
                        ).key
                      : "";
                    const numeProiect = this.$f7.data.Proiecte.find(
                      (p) => p.value === sarcina.iD_PROIECT
                    )
                      ? `"${
                          this.$f7.data.Proiecte.find(
                            (p) => p.value === sarcina.iD_PROIECT
                          ).key
                        }"`
                      : "";

                    const mesaj = {
                      data_sesizare: moment(Date.now()).format(),
                      pt_cine: sarcina.dE_CINE,
                      nume_pt_cine: numeSalariatPentru,
                      de_la_cine: this.$f7.data.user.iD_SALARIAT,
                      nume_de_la_cine: numeSalariatDeLa,
                      citit: false,
                      subiect: "Task propus/alocat altcuiva",
                      comentariu: `Taskul (${sarcina.iD_PROIECT}) ${numeProiect} a fost propus/alocat lui ${numeTrimisLa}.`,
                      ref_proiect: sarcina.iD_PROIECT,
                      isArhivat: false,
                    };

                    if (
                      this.state.levelMe < this.state.levelSalariat ||
                      this.$f7.data.user.administrator === true
                    ) {
                      //PENTRU ALOCARE FARA SA ACCEPTE
                      axios({
                        data: sarcinaAlocata2,
                        headers: { "Content-Type": "application/json" },
                        method: "post",
                        url: url,
                      })
                        .then((response2) => {
                          if (sarcina.stare === 2) {
                            axios({
                              data: mesaj,
                              headers: { "Content-Type": "application/json" },
                              method: "post",
                              url:
                                this.$f7.data.baseURL +
                                this.state.linkApiSesizari +
                                "/Postsesizari",
                            })
                              .then((responseMesaj) => {
                                resolve(response2);
                              })
                              .catch((error) => {
                                console.log("error", error);
                              });
                          } else {
                            resolve(response2);
                          }
                        })
                        .catch((error) => {
                          console.log("error", error);
                          reject(error);
                        });
                    } else {
                      if (sarcina.stare === 2) {
                        axios({
                          data: mesaj,
                          headers: { "Content-Type": "application/json" },
                          method: "post",
                          url:
                            this.$f7.data.baseURL +
                            this.state.linkApiSesizari +
                            "/Postsesizari",
                        })
                          .then((responseMesaj) => {
                            resolve(response1);
                          })
                          .catch((error) => {
                            console.log("error", error);
                          });
                      } else {
                        resolve(response1);
                      }
                    }
                  })
                  .catch((error) => {
                    console.log("error", error);
                    reject(error);
                  });
              })
              .catch((error) => {
                console.log("error", error);
                reject(error);
              });
          }
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  };

  /**
   * @description Primeste array-ul de taskuri alocate si id-ul salariatului si returneaza numarul de taskuri care apartine salariatului respectiv
   * @date 06/04/2022
   * @function
   * @param {*} idSalariat salariatul curent
   * @param {*} arr arrayul cu toate sarcinile alocate
   * @memberof ATSalariati
   * @returns numarul de taskuri alocate salariatului curent
   */
  GetNumarSarciniBySalariat = (idSalariat, arr) => {
    var lista = arr !== undefined ? [...arr] : [...this.state.listaSarcini];
    let sarciniFiltrate = lista.filter(
      (item) => item.idSalariat === idSalariat
    );
    return sarciniFiltrate.length;
  };

  componentDidMount = () => {
    this.GetValues()
      .then((response) => {
        var arr = this.$f7.data.SalariatiSubalterniColegiSiSef;
        arr.map((elem) => {
          elem.numar = this.GetNumarSarciniBySalariat(
            elem.value,
            response.data
          );
          return null;
        });
        console.log("arr", arr);
        var max = this.$f7.methods.maxDepth(null, arr, "value", "ref");

        this.setState(
          {
            listaSarcini: response.data,
            listaSalariati: arr,
            listaSalariatiInitial: arr,
            maxDepth: max + 1,
            level: max,
          },
          () => {
            this.filterSalariati();
          }
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /**
   * @description Filtreaza salariatii daca sa fie afisati si cei arhivati/ascunsi
   * @date 06/04/2022
   * @function
   * @memberof ATSalariati
   */
  filterSalariati = () => {
    var arhivate = this.state.showArh ? [true, false] : [false];
    var ascunse = this.state.showHidden ? [true, false] : [false];
    var lista = [...this.state.listaSalariatiInitial];
    var listaFiltrata = lista.filter((r) => {
      return arhivate.includes(r.isArhivat) && ascunse.includes(r.isHidden);
    });

    this.setState({ listaSalariati: listaFiltrata }, () => {
      setTimeout(() => {
        var arr = [];
        var f = this.getParents(
          this.state.componentaTree.getNodeByKey(
            this.$f7.data.idSalariatFunctii
          ),
          arr
        );
        f = f.reverse();
        f.map((x) => {
          this.state.componentaTree.expandRow(x);
          return null;
        });
      }, 100);
    });
  };

  /**
   * @description Ia toti parintii unui salariat(toti superiorii)
   * @date 06/04/2022
   * @param {*} obj salariatul curent
   * @function
   * @param {*} parents parintii elementului curent
   * @memberof ATSalariati
   */
  getParents = (obj, parents) => {
    var p = parents ? parents : [];
    if (obj.level >= 0) {
      p.push(obj.key);
      p = this.getParents(obj.parent, p);
    }
    return p;
  };

  /**
   * @description Deschide treelistul la un anumit nivel
   * @date 06/04/2022
   * @param {*} level nivelul la care sa fie deschis treelist-ul
   * @function
   * @memberof ATSalariati
   */
  expandToLevel = (level) => {
    this.setState({ level: level }, () => {
      this.$f7.preloader.show();
      this.state.componentaTree.forEachNode((nod) => {
        console.log(nod);
        if (nod.level < this.state.level) {
          this.state.componentaTree.expandRow(nod.data.value);
        } else {
          this.state.componentaTree.collapseRow(nod.data.value);
        }
      });
      this.$f7.preloader.hide();
    });
  };

  render() {
    return (
      <div style={{ position: "relative", height: "100%" }}>
        <Row style={{ overflow: "auto", height: "75%" }}>
          <Col
            width={25}
            style={{ maxHeight: "calc(100vh - 80px)", overflow: "auto" }}>
            <div
              id={"CheckboxuriLevel"}
              style={{ position: "relative", display: "block", height: 34 }}>
              <div
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -75%)",
                  display: "flex",
                }}>
                {new Array(this.state.maxDepth).fill(0).map((a, index) => (
                  <Checkbox
                    key={"CheckboxATSalariati-" + index}
                    checked={this.state.level === index}
                    onChange={(e) => {
                      this.setState({ level: index }, () => {
                        this.expandToLevel(this.state.level);
                      });
                    }}>
                    {index}
                  </Checkbox>
                ))}
              </div>
            </div>
            <div
              id={"CheckboxAlocareTaskuriSalariati"}
              style={{
                position: "absolute",
                top: 5,
                left: 0,
                display: "flex",
              }}>
              <Checkbox
                checked={this.state.showHidden}
                onChange={(e) => {
                  this.setState({ showHidden: !this.state.showHidden }, () => {
                    this.filterSalariati();
                  });
                }}
                style={{
                  marginRight: 8,
                }}>
                Ascunsi
              </Checkbox>
              <Checkbox
                checked={this.state.showArh}
                onChange={(e) => {
                  this.setState({ showArh: !this.state.showArh }, () => {
                    this.filterSalariati();
                  });
                }}>
                Arhivati
              </Checkbox>
            </div>
            <div style={{ position: "relative" }}>
              <TreeList
                filterMode={"fullBranch"}
                onInitialized={(e) => {
                  this.setState({
                    componentaTree: e.component,
                  });
                }}
                autoExpandAll={true}
                focusedRowEnabled={true}
                onFocusedRowChanged={(e) => {
                  var f = e.component.getNodeByKey(
                    this.$f7.data.user.iD_SALARIAT
                  );
                  if (e.row !== undefined) {
                    if (e.row.data !== undefined) {
                      var data = e.row.data;
                      var levelSalariat = e.row.level;
                      var levelMe = f.level;
                      this.setState(
                        {
                          SalariatSelectat: data.value,
                          levelSalariat: levelSalariat,
                          levelMe: levelMe,
                        },
                        () => {
                          if (this.state.SalariatSelectat !== null) {
                            this.refGrid.current.GetValues();
                          }
                        }
                      );
                    }
                  }
                }}
                focusedRowKey={this.state.SalariatSelectat}
                rootValue={
                  this.state.listaSalariati.find(
                    (item) => item.value === this.$f7.data.user.reF_SALARIAT
                  )?.ref
                }
                dataSource={this.state.listaSalariati}
                keyExpr={"value"}
                parentIdExpr={"ref"}
                id="TreeListAlocareTaskSalariati"
                columns={[
                  {
                    width: 200,
                    dataField: "key",
                    caption: "Denumire",
                  },
                  {
                    width: 50,
                    dataField: "numar",
                    caption: "Alocate",
                  },
                  {
                    width: 50,
                    dataField: "isHidden",
                    caption: "Ascuns",
                  },
                ]}
                showColumnHeaders={true}
                showColumnLines={true}
                showBorders={true}
                searchPanel={{
                  visible: true,
                  width: 160,
                }}
              />
            </div>
          </Col>
          <Col width={75}>
            <ATSalariatiGridAlocate
              ref={this.refGrid}
              idSalariat={this.state.SalariatSelectat}
            />
          </Col>
        </Row>
        <Row>
          <List largeInset style={{ width: "100%" }}>
            <ListInput
              floatingLabel
              label={"Instructiuni"}
              placeholder={"Instructiuni"}
              type={"text"}
              value={this.state.instuctiuni}
              onInput={(e) => {
                var val = e.target.value;
                this.setState({ instructiuni: val });
              }}
            />
          </List>
        </Row>
        <Fab
          onClick={() => {
            if (this.state.SalariatSelectat !== null) {
              this.$f7.preloader.show();

              if (typeof this.$f7.data.ATidProiect === "number") {
                this.AlocaTask(
                  this.$f7.data.ATidProiect,
                  this.state.SalariatSelectat
                )
                  .then((s) => {
                    var pro = this.$f7.data.allProiecte.find((pr) => {
                      return pr.iD_PROIECT === this.$f7.data.ATidProiect;
                    });

                    if (this.state.instructiuni.length > 0) {
                      var obj = {
                        pt_cine: this.state.SalariatSelectat,
                        data_sesizare: moment(Date.now()).format(),
                        nume_pt_cine: this.$f7.data.Salariati.filter((f) => {
                          return f.value === this.state.SalariatSelectat;
                        })[0].key,
                        citit: false,
                        de_la_cine: this.$f7.data.user.iD_SALARIAT,
                        nume_de_la_cine:
                          this.$f7.data.user.nume +
                          " " +
                          this.$f7.data.user.prenume,
                        subiect: "Instructiuni task alocat",
                        comentariu: pro
                          ? pro.denumire + " : " + this.state.instructiuni
                          : this.state.instructiuni,
                      };
                      this.AddMesaj(obj)
                        .then((response) => {
                          this.$f7.preloader.hide();
                          this.$f7.popup.close(".PopupDinamic");
                          this.$f7.methods.openNotification(
                            "Sarcina alocata la " +
                              this.state.listaSalariati.find(
                                (item) =>
                                  item.value === this.state.SalariatSelectat
                              ).key
                          );
                        })
                        .catch((err) => {
                          console.log("err", err);
                          this.$f7.methods.openNotification("Eroare:", err);
                        });
                    } else {
                      this.$f7.preloader.hide();
                      this.$f7.popup.close(".PopupDinamic");
                      this.$f7.methods.openNotification(
                        "Sarcina alocata la " +
                          this.state.listaSalariati.find(
                            (item) => item.value === this.state.SalariatSelectat
                          ).key
                      );
                    }
                  })
                  .catch((error) => {
                    this.$f7.preloader.hide();
                    this.$f7.popup.close(".PopupDinamic");
                    this.$f7.methods.openNotification("Eroare" + error);
                    console.log("Eroare:", error);
                  });
              } else {
                var denumire = "";
                this.$f7.data.ATidProiect.map((r) => {
                  var pro = this.$f7.data.allProiecte.find((pr) => {
                    return pr.iD_PROIECT === r;
                  });
                  if (pro) {
                    denumire += " " + pro.denumire;
                  }
                  return null;
                });
                denumire += " : ";

                this.$f7.data.ATidProiect.reduce((promise, item) => {
                  return promise.then(() => {
                    return this.AlocaTask(item, this.state.SalariatSelectat)
                      .then((s) => {})
                      .catch((err) => {});
                  });
                }, Promise.resolve())
                  .then(() => {
                    if (this.state.instructiuni.length > 0) {
                      var obj = {
                        pt_cine: this.state.SalariatSelectat,
                        data_sesizare: moment(Date.now()).format(),
                        nume_pt_cine: this.$f7.data.Salariati.filter((f) => {
                          return f.value === this.state.SalariatSelectat;
                        })[0].key,
                        citit: false,
                        de_la_cine: this.$f7.data.user.iD_SALARIAT,
                        nume_de_la_cine:
                          this.$f7.data.user.nume +
                          " " +
                          this.$f7.data.user.prenume,
                        subiect: "Instructiuni task alocat",
                        comentariu: denumire + this.state.instructiuni,
                      };
                      this.AddMesaj(obj)
                        .then((response) => {
                          this.$f7.preloader.hide();
                          this.$f7.popup.close(".PopupDinamic");
                          this.$f7.methods.openNotification(
                            "Sarcina alocata la " +
                              this.state.listaSalariati.find(
                                (item) =>
                                  item.value === this.state.SalariatSelectat
                              ).key
                          );
                        })
                        .catch((err) => {
                          console.log("err", err);
                          this.$f7.methods.openNotification("Eroare:", err);
                        });
                    } else {
                      this.$f7.preloader.hide();
                      this.$f7.popup.close(".PopupDinamic");
                      this.$f7.methods.openNotification(
                        "Sarcina alocata la " +
                          this.state.listaSalariati.find(
                            (item) => item.value === this.state.SalariatSelectat
                          ).key
                      );
                    }
                  })
                  .catch((error) => {
                    this.$f7.preloader.hide();
                    this.$f7.popup.close(".PopupDinamic");
                    this.$f7.methods.openNotification("Eroare");

                    console.log("Eroare:", error);
                  });
              }
            } else {
              this.$f7.methods.openNotification(
                "Trebuie selectat un salariat!",
                "Atentie"
              );
            }
          }}
          position="center-bottom"
          slot="fixed"
          text="Aloca"
          color="red"
        />
      </div>
    );
  }
}
export default ATSalariati;