import React, { Component } from "react";
import { Panel } from "framework7-react";

/**
 * @description Randeaza un panel(in dreapta) care merge mutat
 * @date 08/04/2022
 * @class DraggingPanel
 * @extends {Component}
 */
class DraggingPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      resizing: false,
      panel: null,
      moveElement: null,
      resizeElement: null,
      diffX: 0,
      diffY: 0,
      rPrevX: 0,
      rPrevY: 0,
      style: this.props.style
        ? {
            ...this.props.style,
            left: this.props.side && this.props.side === "left" ? 0 : "unset",
            right:
              this.props.side === undefined ||
              (this.props.side && this.props.side === "right")
                ? 0
                : "unset",
          }
        : {},
    };

    this.refPanel = React.createRef();
  }

  /**
   * @description Functia de mutare a panelului atunci cand incepe mutarea
   * @date 08/04/2022
   * @function
   * @param {*} e evenimentul mutarii
   * @memberof DraggingPanel
   */
  onDragStart = (e) => {
    const rect = this.refPanel.current.__reactRefs.el.getBoundingClientRect();
    let dX;

    if (
      this.props.side === undefined ||
      (this.props.side && this.props.side === "right")
    ) {
      dX = rect.right - e.screenX;
    } else if (this.props.side && this.props.side === "left") {
      dX = e.screenX - rect.left;
    }

    this.setState({
      diffX: dX,
      diffY: e.screenY - rect.top,
      dragging: true,
    });
  };

  /**
   * @description Evenimentul de mutare a panelului
   * @date 08/04/2022
   * @function
   * @param {*} e Evenimentul de mutare a panelului
   * @memberof DraggingPanel
   */
  onDragging = (e) => {
    if (this.state.dragging) {
      let style = { ...this.state.style };
      style.top = e.screenY - this.state.diffY;

      if (
        this.props.side === undefined ||
        (this.props.side && this.props.side === "right")
      ) {
        style.right = window.innerWidth - (e.screenX + this.state.diffX);
      } else if (this.props.side && this.props.side === "left") {
        style.left = e.screenX - this.state.diffX;
      }

      this.setState({
        style: style,
      });
    }
  };

  /**
   * @description Evenimentul de final al mutari
   * @date 08/04/2022
   * @function
   * @param {*} e Evenimentul de final al mutarii
   * @memberof DraggingPanel
   */
  onDragEnd = (e) => {
    this.setState({
      dragging: false,
    });
  };
  // END Functiile de mutare panel

  /**
   * @description Functie de facut resize al panelului
   * @date 08/04/2022
   * @function
   * @param {*} e Functie de facut resize al panelului
   * @memberof DraggingPanel
   */
  onResizeStart = (e) => {
    this.setState({
      rPrevX: e.clientX,
      rPrevY: e.clientY,
      resizing: true,
    });
  };

  /**
   * @description Functie de resize,se ia noua marime a panelului
   * @date 08/04/2022
   * @function
   * @param {*} e Evenimentul de resize
   * @memberof DraggingPanel
   */
  onResizing = (e) => {
    if (this.state.resizing) {
      const rect = this.refPanel.current.__reactRefs.el.getBoundingClientRect();
      let style = { ...this.state.style };
      style.height = rect.height - (this.state.rPrevY - e.clientY);

      if (
        this.props.side === undefined ||
        (this.props.side && this.props.side === "right")
      ) {
        style.width = rect.width + (this.state.rPrevX - e.clientX);
      } else if (this.props.side && this.props.side === "left") {
        style.width = rect.width - (this.state.rPrevX - e.clientX);
      }

      this.setState({
        style: style,
        rPrevX: e.clientX,
        rPrevY: e.clientY,
      });
    }
  };

  /**
   * @description Seteaza stadiul de resize pe false
   * @date 08/04/2022
   * @function
   * @param {*} e evenimentul de final al resize-ului
   * @memberof DraggingPanel
   */
  onResizeEnd = (e) => {
    this.setState({
      resizing: false,
    });
  };
  // END Functiile de redimensionare panel

  componentDidMount = () => {
    const panel = document.getElementsByClassName(this.props.className)[0];

    if (panel) {
      const moveElement = panel.getElementsByClassName("navbar-move")[0];
      const resizeElement = panel.getElementsByClassName("panel-resize")[0];

      if (moveElement && resizeElement) {
        this.setState(
          {
            panel: panel,
            moveElement: moveElement,
            resizeElement: resizeElement,
          },
          () => {
            // adaugare listenere pentru mutare fereastra
            moveElement.addEventListener("mousedown", this.onDragStart);
            moveElement.addEventListener("mouseup", this.onDragEnd);
            moveElement.addEventListener("mousemove", this.onDragging);

            // adaugare listenere pentru redimensionare fereastra
            resizeElement.addEventListener("mousedown", this.onResizeStart);
            resizeElement.addEventListener("mouseup", this.onResizeEnd);
            window.addEventListener("mousemove", this.onResizing);
          }
        );
      }
    }
  };

  componentWillUnmount = () => {
    // eliminare listenere pentru mutare fereastra
    //this.state.moveElement.removeEventListener('mousedown', this.onDragStart);
    //this.state.moveElement.addEventListener('mouseup', this.onDragEnd);
    //this.state.moveElement.addEventListener('mousemove', this.onDragging);
    //// eliminare listenere pentru redimensionare fereastra
    //this.state.resizeElement.removeEventListener('mousedown', this.onResizeStart);
    //this.state.resizeElement.removeEventListener('mouseup', this.onResizeEnd);
    //window.removeEventListener('mousemove', this.onResizing);
  };

  render() {
    return (
      <Panel
        ref={this.refPanel}
        side={this.props.side ? this.props.side : "right"}
        closeByBackdropClick={this.props.closeByBackdropClick}
        backdrop={this.props.backdrop}
        resizable={this.props.resizable ? this.props.resizable : false}
        onPanelOpened={this.props.onPanelOpened}
        onPanelOpen={this.props.onPanelOpen}
        onPanelClose={this.props.onPanelClose}
        onPanelClosed={this.props.onPanelClosed}
        style={this.state.style}
        className={this.props.className ? this.props.className : ""}>
        {this.props.children}
        <div
          className={"panel-resize"}
          style={{
            position: "absolute",
            width: 15,
            height: 15,
            bottom: -4,
            left:
              this.props.side === undefined ||
              (this.props.side && this.props.side === "right")
                ? -4
                : "unset",
            right: this.props.side && this.props.side === "left" ? -4 : "unset",
            backgroundColor: "transparent",
            zIndex: 2,
            cursor:
              this.props.side === undefined ||
              (this.props.side && this.props.side === "right")
                ? "sw-resize"
                : this.props.side && this.props.side === "left"
                ? "se-resize"
                : "auto",
            borderBottom: "2px solid black",
            borderLeft:
              this.props.side === undefined ||
              (this.props.side && this.props.side === "right")
                ? "2px solid black"
                : "unset",
            borderRight:
              this.props.side && this.props.side === "left"
                ? "2px solid black"
                : "unset",
          }}></div>
      </Panel>
    );
  }
}
export default DraggingPanel;
