import React, { Component } from "react";
import { Preloader, Page } from "framework7-react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import axios from "axios";

var moment = require("moment");
const localizer = momentLocalizer(moment);

/**
 * @description Afiseaza toate intervalele lucrate pe taskul curent intr-un calendar
 * @date 08/04/2022
 * @class PlanificareAnuala
 * @extends {Component}
 */
class PlanificareAnuala extends Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;

    this.state = {
      dataLoaded: false,
      denumire: Math.random().toString(36).substring(7).toString(),
      calendarHeight: parseInt((window.innerHeight - 104) / 3),
      linkApi: "/api/PlanificareAnuala",
      calendarDataSource: [],
      firstCalendarDate: new Date(),
    };
  }

  /**
   * @description Ia toate realizarile de pe taskul curent selectat
   * @function
   * @date 08/04/2022
   * @memberof PlanificareAnuala
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/" +
          this.$f7.data.Proiect.iD_PROIECT
      )
      .then((response) => {
        let eventsArray = [];

        response.data.map((item) => {
          let itemObj = {};

          itemObj["id"] = item.id_proiect;
          itemObj["title"] = item.denumire;
          itemObj["start"] = new Date(item.data_start);
          itemObj["end"] = new Date(item.deadline);
          itemObj["complete"] = item.taskComplete;

          eventsArray.push(itemObj);
          return null;
        });

        this.setState(
          {
            calendarDataSource: eventsArray,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            calendarDataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount() {
    if (this.$f7.data.Proiect !== null) this.GetValues();
  }

  render() {
    return (
      <Page pageContent={false}>
        {this.$f7.data.Proiect !== null ? (
          <div>
            {this.state.dataLoaded ? (
              <>
                <Calendar
                  popup
                  showMultiDayTimes
                  localizer={localizer}
                  startAccessor="start"
                  endAccessor="end"
                  events={this.state.calendarDataSource}
                  defaultView={Views.MONTH}
                  //views={["month", "work_week", "week", "day", "agenda"]}
                  views={["month"]}
                  style={{
                    marginTop: "15px",
                    height: this.state.calendarHeight,
                  }}
                  onNavigate={(changedDate) => {
                    this.setState({
                      firstCalendarDate: changedDate,
                    });
                  }}
                />

                <Calendar
                  className="hideControlsCalendar"
                  popup
                  showMultiDayTimes
                  localizer={localizer}
                  startAccessor="start"
                  endAccessor="end"
                  events={this.state.calendarDataSource}
                  defaultView={Views.MONTH}
                  //views={["month", "work_week", "week", "day", "agenda"]}
                  views={["month"]}
                  style={{
                    marginTop: "15px",
                    height: this.state.calendarHeight,
                  }}
                  date={
                    new Date(
                      moment(this.state.firstCalendarDate).add(1, "months")
                    )
                  }
                />

                <Calendar
                  className="hideControlsCalendar"
                  popup
                  showMultiDayTimes
                  localizer={localizer}
                  startAccessor="start"
                  endAccessor="end"
                  events={this.state.calendarDataSource}
                  defaultView={Views.MONTH}
                  //views={["month", "work_week", "week", "day", "agenda"]}
                  views={["month"]}
                  style={{
                    marginTop: "15px",
                    height: this.state.calendarHeight,
                  }}
                  date={
                    new Date(
                      moment(this.state.firstCalendarDate).add(2, "months")
                    )
                  }
                />
              </>
            ) : (
              <div
                style={{
                  position: "absolute",
                  width: "35px",
                  height: "35px",
                  top: "calc(50vh)",
                  left: "50%",
                }}>
                <Preloader size={40} color={"var(--f7-theme-color)"} />
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              position: "relative",
              height: "calc(100vh - 50px)",
            }}>
            <h3
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                right: 0,
                transform: "translateY(-50%)",
                textTransform: "uppercase",
                textAlign: "center",
                display: "block",
              }}>
              Nu ati selectat nimic
            </h3>
          </div>
        )}
      </Page>
    );
  }
}
export default PlanificareAnuala;
