import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import * as inputs from "../../../../inputs";
import { Row, Col } from "framework7-react";
import axios from "axios";

/**
 * @description Interfata de intretinere a culorilor din arborele de proiect
 * @date 07/04/2022
 * @class CuloriArboreProiecte
 * @extends {React.Component}
 */
class CuloriArboreProiecte extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoaded: false,
      linkApi1: "/api/ColorTree",
      linkApi2: "/api/ColorAlerta",
      denumire: "Culori arbore proiecte",
      height: (window.innerHeight - 34) / 2,
      dataSource1: [],
      dataSource2: [],
      idUser: this.$f7.data.user.iD_SALARIAT,
    };
  }

  /**
   * @description Ia valorile pentru randarea primului datagrid
   * @function
   * @date 07/04/2022
   * @param {*} id
   * @memberof CuloriArboreProiecte
   */
  GetValues1 = (id) => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi1 +
          "/GetCOLORTREEByUser/" +
          id
      )
      .then((response) => {
        var change = response;

        change.data.map((el, index) => {
          el["IdColortree"] = el.iD_COLOR_TREE;
          el["CodCuloare"] = this.$f7.methods.intToHex(el.coD_CULOARE);
          el["CodBackground"] = this.$f7.methods.intToHex(el.coD_BACKGROUND);
          el["FontName"] = el.fonT_NAME;
          el["FontSize"] = el.fonT_SIZE;
          el["IsBold"] = el.iS_BOLD;
          el["IsItalic"] = el.iS_ITALIC;
          el["IsStrikeout"] = el.iS_STRIKEOUT;
          el["IsUnderline"] = el.iS_UNDERLINE;
          return null;
        });

        var baza = this.$f7.methods.reconstructieBaza(
          change,
          inputs.IntretinereCuloriArboreProiecte1
        );

        this.setState(
          {
            dataSource1: response.data.length > 0 ? baza : [],
          },
          () => {
            this.GetValues2(this.state.idUser);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource1: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues1(this.state.idUser);
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Functia de actualizare a valorii selectate din primul datagrid
   * @date 07/04/2022
   * @param {*} obj obiectul cu noile valori setate
   * @param {*} id id-ul inregistrarii selectate
   * @memberof CuloriArboreProiecte
   */
  UpdateValue1 = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi1 + "/GetCOLORTREE/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL + this.state.linkApi1 + "/PutCOLORTREE/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues1(this.state.idUser);
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Ia valorile pentru randarea celui de al doilea datagrid
   * @function
   * @date 07/04/2022
   * @param {*} id id-ul salariatului selectat
   * @memberof CuloriArboreProiecte
   */
  GetValues2 = (id) => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi2 +
          "/GetCOLOR_ALERTAByUser/" +
          id
      )
      .then((response) => {
        var change = response;

        change.data.map((el, index) => {
          el["IdColorAlerta"] = el.iD_COLOR_ALERTA;
          el["NrInterval"] = el.nR_INTERVAL;
          el["Culoare"] = this.$f7.methods.intToHex(el.culoare);
          return null;
        });

        var baza = this.$f7.methods.reconstructieBaza(
          change,
          inputs.IntretinereCuloriArboreProiecte2
        );

        this.setState(
          {
            dataSource2: response.data.length > 0 ? baza : [],
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource2: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea unor date de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues1(this.state.idUser);
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Actualizeaza valoarea din cel de al doilea datagrid
   * @date 07/04/2022
   * @function
   * @param {*} obj obiectul cu noile valori ale inregistrarii selectate
   * @param {*} id id-ul inregistrarii selectate
   * @memberof CuloriArboreProiecte
   */
  UpdateValue2 = (obj, id) => {
    axios
      .get(
        this.$f7.data.baseURL + this.state.linkApi2 + "/GetCOLOR_ALERTA/" + id
      )
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL +
          this.state.linkApi2 +
          "/PutCOLOR_ALERTA/" +
          id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues1(this.state.idUser);
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  componentDidMount = () => {
    this.GetValues1(this.state.idUser);
  };

  render() {
    return (
      <>
        <Row>
          <Col>
            <DataGrid
              dataLoaded={this.state.dataLoaded}
              refresh={this.GetValues}
              name={"CuloriArboreProiecte1"}
              id={"IdColortree"}
              ref={this.refDataGrid1}
              renderUpdate={true}
              renderDelete={false}
              renderAdd={false}
              maxHeight={this.state.height}
              inputs={inputs.IntretinereCuloriArboreProiecte1}
              dataSource={this.state.dataSource1}
              onUpdate={(state) => {
                var data = { ...state.editedItem };

                data["coD_CULOARE"] = this.$f7.methods.hexToInt(
                  data.CodCuloare
                );
                data["coD_BACKGROUND"] = this.$f7.methods.hexToInt(
                  data.CodBackground
                );
                data["fonT_NAME"] = data.FontName;
                data["fonT_SIZE"] = data.FontSize;
                data["iS_BOLD"] = data.IsBold;
                data["iS_ITALIC"] = data.IsItalic;
                data["iS_STRIKEOUT"] = data.IsStrikeout;
                data["iS_UNDERLINE"] = data.IsUnderline;
                data["iD_COLOR_TREE"] = data.IdColortree;
                data["iD_USER"] = this.state.idUser;

                delete data.CodCuloare;
                delete data.CodBackground;
                delete data.FontName;
                delete data.FontSize;
                delete data.IsBold;
                delete data.IsItalic;
                delete data.IsStrikeout;
                delete data.IsUnderline;
                delete data.IdColortree;

                this.UpdateValue1(data, data.iD_COLOR_TREE);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DataGrid
              dataLoaded={this.state.dataLoaded}
              refresh={this.GetValues}
              name={"CuloriArboreProiecte2"}
              id={"IdColorAlerta"}
              ref={this.refDataGrid2}
              renderUpdate={true}
              renderDelete={false}
              renderAdd={false}
              maxHeight={this.state.height}
              inputs={inputs.IntretinereCuloriArboreProiecte2}
              dataSource={this.state.dataSource2}
              onUpdate={(state) => {
                var data = { ...state.editedItem };

                data["culoare"] = this.$f7.methods.hexToInt(data.Culoare);
                data["iD_COLOR_ALERTA"] = data.IdColorAlerta;
                data["iD_USER"] = this.state.idUser;
                data["nR_INTERVAL"] = data.NrInterval;

                delete data.Culoare;
                delete data.IdColorAlerta;
                delete data.NrInterval;

                this.UpdateValue2(data, data.iD_COLOR_ALERTA);
              }}
            />
          </Col>
        </Row>
      </>
    );
  }
}
export default CuloriArboreProiecte;
