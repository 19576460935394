import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";
import {
  Segmented,
  Button,
  Icon,
  Popup,
  NavRight,
  Navbar,
  Page,
  Link,
  List,
  ListInput,
  TreeviewItem,
  Treeview,
  Preloader,
  Row,
  Col,
  Range,
} from "framework7-react";
import TreeList from "devextreme-react/tree-list";

/**
 * @description Interfata de intretinere a salariatilor si atasarea modulelor la salariat
 * @date 07/04/2022
 * @class SalariatiModule
 * @extends {React.Component}
 */
class SalariatiModule extends React.Component {
  constructor(props) {
    super(props);
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.state = {
      maxDepth: 0,
      idSelectedForCopy: null,
      idSelected: null,
      salariatSelectat: null,
      cutting: false,
      filtre: {},
      idSalariat: null,
      selectedRowsData: [],
      allSelectedRows: [],
      selectedRowKeys: [],
      dataSource: [],
      dataSourceProiecte: [],
      dataLoaded2: false,
      dataLoaded: false,
      currentUser: null,
      departamente: this.$f7.data.Departamente,
      users: [{ Nume: "Cristea", Prenume: "Ciprian", Ref_Salariat: null }],
      salariatiArhivati: [],
      inputs: [
        {
          key: "Id_Salariat",
          keyBaza: "iD_SALARIAT",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
          columnWidth: "500px",
        },
        {
          key: "Angajator",
          keyBaza: "iD_ANGAJATOR",
          tip: "selectbox",
          lista: this.$f7.data.Angajatori,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Nume",
          keyBaza: "nume",
          requiredAdd: true,
          requiredEdit: true,
          columnWidth: "500px",
        },
        {
          key: "Prenume",
          keyBaza: "prenume",
          requiredAdd: true,
          requiredEdit: true,
          columnWidth: "500px",
        },
        {
          key: "Nume_Login",
          keyBaza: "numE_LOGIN",
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "Activ", keyBaza: "activ", tip: "bool", columnWidth: "10px" },
        {
          key: "Ref_Salariat",
          keyBaza: "reF_SALARIAT",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        {
          key: "Ordine",
          keyBaza: "ordine",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
        },
        { key: "Data_Angajare", keyBaza: "datA_ANGAJARE", tip: "datebox" },
        { key: "Data_Plecare", keyBaza: "datA_PLECARE", tip: "datebox" },
        {
          key: "Adresa_Email",
          keyBaza: "adresA_EMAIL",
          tip: "email",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Salariu_Ora",
          keyBaza: "salariU_ORA",
          tip: "number",
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Departament",
          keyBaza: "iD_DEPARTAMENT",
          tip: "selectbox",
          lista: this.$f7.data.Departamente,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Birou",
          keyBaza: "id_birou",
          tip: "selectbox",
          lista: this.$f7.data.Birouri,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Profesie",
          keyBaza: "iD_PROFESIE",
          tip: "selectbox",
          lista: this.$f7.data.Profesii,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "RolDeAplicatie",
          keyBaza: "rol",
          tip: "selectbox",
          lista: this.$f7.data.Roluri,
          requiredAdd: true,
          requiredEdit: true,
        },
        {
          key: "Competenta",
          keyBaza: "iD_COMPETENTA",
          tip: "selectbox",
          lista: this.$f7.data.Competente,
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "EditareRapoarte", keyBaza: "reporT_BUILDER", tip: "bool" },
        { key: "ApiToken", keyBaza: "apitoken" },
        { key: "RetineLogin", keyBaza: "retinE_USER", tip: "bool" },
        { key: "SalvarePreferinta", keyBaza: "savE_PREF", tip: "bool" },
        { key: "IntrebareSalvare", keyBaza: "asK_PREF", tip: "bool" },
        {
          key: "Arhivat",
          keyBaza: "isArhivat",
          tip: "bool",
          columnWidth: "10px",
        },
        {
          key: "Ascuns",
          keyBaza: "isHidden",
          tip: "bool",
          columnWidth: "10px",
        },
        {
          key: "Administrator",
          keyBaza: "administrator",
          tip: "bool",
          columnWidth: "10px",
        },
      ],
      inputsProiecte: [
        { key: "Id", keyBaza: "iD_PROIECT" },
        {
          key: "Ref",
          keyBaza: "reF_PROIECT",
          lista: this.$f7.data.Proiecte,
          tip: "selectbox",
        },
        { key: "Denumire", keyBaza: "denumire" },
        { key: "TopEvent", keyBaza: "topevent" },
        { key: "TipProiect", keyBaza: "id_tip_proiect" },
      ],
      linkApi: "/api/Salariati",
      linkApiARH: "/api/SalariatiArh",
      linkApiProiecte: "/api/Proiecte",
      linkApiSalariatContracteModule: "api/SalariatContracteModule",
      password: "",
      passwordConfirm: "",
      denumire: "Salariati",
      height: window.innerHeight - 64,
      inclusivArhivati: false,
      popupNameOrganigrama:
        "Popup" + Math.random().toString(36).substring(7).toString(),
      popupNameSchimbareParola:
        "Popup" + Math.random().toString(36).substring(7).toString(),
      popupNameDepartamente:
        "Popup" + Math.random().toString(36).substring(7).toString(),
    };
    this.refDataGrid1 = React.createRef();
    this.refDataGrid2 = React.createRef();
  }

  /**
   * @description Returneaza lista cu toate nodurile din treelist care sunt selectate
   * @date 07/04/2022
   * @param {*} treeListInstance referinta catre treelist
   * @param {*} parentKeys keyurile parinte
   * @param {*} skipParent daca sa returneze si parintele sau nu
   * @memberof SalariatiModule
   * @returns lista cu toate nodurile din treelist care sunt selectate
   */
  getAllSelectedNodes = (treeListInstance, parentKeys, skipParent) => {
    let result = [];

    parentKeys.forEach((key) => {
      let insertIndex = result.length,
        node = treeListInstance.getNodeByKey(key),
        parentNode = node.parent,
        childKeys = node.children.map((child) => {
          return child.key;
        });

      while (parentNode.level >= 0 && !skipParent) {
        const pKey = parentNode.key;
        if (
          result.find((nodeItem) => nodeItem.key === pKey) &&
          treeListInstance.isRowSelected(parentNode.key)
        ) {
          result.splice(insertIndex, 0, parentNode);
          parentNode = parentNode.parent;
        } else {
          break;
        }
      }

      result.push(node);
      result = result.concat(
        this.getAllSelectedNodes(treeListInstance, childKeys, true)
      );
    });

    return result;
  };

  /**
   * @description coloreaza fiecare rand din datagrid in functie de starea acestuia, arhivat,ascuns,copiat etc
   * @date 07/04/2022
   * @param {*} state state-ul datagridului
   * @param {*} r context-ul datagridului
   * @param {*} itemeRandate itemele randate de catre datagrid
   * @memberof SalariatiModule
   */
  colorItems = (state, r, itemeRandate) => {
    var tableName = r.tableName;
    if (tableName) {
      itemeRandate.forEach((elem, index) => {
        var tar = document.getElementById("trMain" + tableName + index);
        if (tar) {
          if (elem.Ascuns) {
            tar.classList.add("ascuns");
          } else {
            if (tar.classList.contains("ascuns")) {
              tar.classList.remove("ascuns");
            }
          }
          if (elem.Arhivat) {
            tar.classList.add("arhivat");
          } else {
            if (tar.classList.contains("arhivat")) {
              tar.classList.remove("arhivat");
            }
          }
          if (elem.Activ) {
            tar.classList.add("taskactiv");
          } else {
            if (tar.classList.contains("taskactiv")) {
              tar.classList.remove("taskactiv");
            }
          }
          if (elem.Id_Salariat === this.state.idSelectedForCopy) {
            tar.classList.add("dottedForCopy");
          } else {
            if (tar.classList.contains("dottedForCopy")) {
              tar.classList.remove("dottedForCopy");
            }
          }
        }
      });
    }
  };

  /**
   * @description Seteaza informatiile salariatilor
   * @function
   * @date 07/04/2022
   * @param {*} users lista de salariati
   * @memberof SalariatiModule
   */
  setUsers = (users) => {
    var inputsUsers = [
      { key: "Id_Salariat", tip: "number" },
      { key: "Nume" },
      { key: "Prenume" },
      { key: "Ref_Salariat", tip: "number" },
    ];
    var baza = this.$f7.methods.reconstructieBaza(
      {
        data: users.data.filter((u) => {
          return u.activ;
        }),
      },
      inputsUsers
    );
    this.setState({ users: baza });
  };

  /**
   * @description Parcurge lista de proiecte/module selectate, vede daca utilizatorul le are deja atasate, daca da le sterge, daca nu, le adauga in baza de date
   * @function
   * @date 07/04/2022
   * @memberof SalariatiModule
   */
  SaveValuesDevextreme = () => {
    //10000000

    this.$f7.preloader.show();

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiSalariatContracteModule +
          "/GetSALARIAT_CONTRACTE_MODULEBySalariat/" +
          this.state.idSalariat
      )
      .then((response) => {
        var date = response.data;
        var arrAdd = [];
        var arrDelete = [];

        //VERIFICA DACA EXISTA ELEMENTELE NOI SELECTATE IN BAZA DE DATE, DACA NU,ADAUGA
        this.state.selectedRowsData.map((r) => {
          if (
            date.filter((f) => {
              return r.Id === f.ref_proiect;
            }).length === 0
          ) {
            //ADAUGA IN BAZA ELEMENTUL obj!
            var obj = { ref_salariat: this.state.idSalariat };
            obj["ref_proiect"] = r.Id;
            //add obj;
            if (r.TipProiect !== 57 && r.TipProiect !== 58) {
              arrAdd.push(obj);
            }
          }
          return null;
        });
        //VERIFICA DACA EXISTA ELEMENTELE DIN BAZA DE DATE IN CELE SELECTATE, DACA NU, STERGE
        date.map((f) => {
          if (
            this.state.selectedRowsData.filter((r) => {
              return r.Id === f.ref_proiect;
            }).length === 0
          ) {
            //STERG DIN BAZA ELEMENTUL f.id_scm!
            var id = f.id_scm;
            arrDelete.push(id);
          }
          return null;
        });

        arrDelete
          .reduce((promise, item) => {
            return promise.then(() => {
              return this.DeleteValueSalariatContracteModule(item)
                .then((r) => {})
                .catch((err) => {
                  console.log("err", err);
                });
            });
          }, Promise.resolve())
          .then(() => {
            arrAdd
              .reduce((promise, item) => {
                return promise.then(() => {
                  return this.AddValueSalariatContracteModule(item)
                    .then((r) => {})
                    .catch((err) => {
                      console.log("err", err);
                    });
                });
              }, Promise.resolve())
              .then(() => {
                this.$f7.preloader.hide();
              })
              .catch(() => {
                this.$f7.preloader.hide();
              });
          })
          .catch(() => {
            this.$f7.preloader.hide();
          });
      })
      .catch(() => {
        this.$f7.preloader.hide();
      });
  };

  /**
   * @description Adauga o noua legatura intre modul/proiect si salariatul selectat
   * @function
   * @date 07/04/2022
   * @param {*} data obiect cu informatiile legate de referentiere(ref modul,ref salariat)
   * @memberof SalariatiModule
   */
  AddValueSalariatContracteModule = (data) => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL +
        this.state.linkApiSalariatContracteModule +
        "/PostSALARIAT_CONTRACTE_MODULE";
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Sterge legatura dintre un salariat si proiectul specificat
   * @date 07/04/2022
   * @param {*} id id-ul inregistrarii selectate
   * @function
   * @memberof SalariatiModule
   */
  DeleteValueSalariatContracteModule = (id) => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL +
        this.state.linkApiSalariatContracteModule +
        "/DeleteSALARIAT_CONTRACTE_MODULE/" +
        id;
      axios({
        headers: { "Content-Type": "application/json" },
        method: "delete",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Functie ce este apelata cand se apasa pe un salariat si se ia proiectele la care este acesta atasat
   * @function
   * @date 07/04/2022
   * @memberof SalariatiModule
   */
  GetValuesDreapta = () => {
    this.setState(
      {
        dataLoaded2: false,
        allSelectedRows: [],
        dataSourceProiecte: [],
        selectedRowKeys: [],
      },
      () => {
        axios
          .get(
            this.$f7.data.baseURL +
              this.state.linkApiProiecte +
              "/GetPROIECTEModule/" +
              this.state.idSalariat
          )
          .then((responseProiecte) => {
            responseProiecte.data.map((eveniment) => {
              eveniment = this.$f7.methods.restructurareBaza(
                eveniment,
                this.state.inputsProiecte,
                "key"
              );
              return false;
            });
            var bazaProiecte = this.$f7.methods.reconstructieBaza(
              responseProiecte,
              this.state.inputsProiecte
            );

            var allSelectedRows = bazaProiecte.filter((r) => {
              return r.TopEvent === 1;
            });

            var selectedRowKeys = [];
            allSelectedRows.map((r) => {
              selectedRowKeys.push(r.Id);
              return null;
            });

            var max = this.$f7.methods.maxDepth(-1, bazaProiecte, "Id", "Ref");
            console.log("max", max);
            this.setState({
              dataSourceProiecte: bazaProiecte,
              allSelectedRows: allSelectedRows,
              dataLoaded2: true,
              selectedRowKeys: selectedRowKeys,
              maxDepth: max,
            });
          })
          .catch((error) => {
            this.setState(
              {
                dataSourceProiecte: [],
                allSelectedRows: [],
                dataLoaded2: true,
                selectedRowKeys: [],
              },
              () => {
                this.$f7.dialog.confirm(
                  "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                  "Ups...",
                  () => {
                    this.GetValuesDreapta();
                  },
                  () => {}
                );
              }
            );

            console.log("error", error);
          });
      }
    );
  };

  /**
   * @description Ia lista de salariati
   * @function
   * @date 07/04/2022
   * @memberof SalariatiModule
   */
  GetValues = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        dataLoaded: false,
      });

      var filtre = {};
      this.state.inputs.map((input) => {
        filtre[input.key] = {
          denumire: input.key,
          value: null,
          comparatie: null,
        };
        return null;
      });

      axios
        .get(this.$f7.data.baseURL + this.state.linkApi + "/GetSALARIATI")
        .then((responseSalariati) => {
          var date = responseSalariati;
          date.data.map((eveniment) => {
            eveniment = this.$f7.methods.restructurareBaza(
              eveniment,
              this.state.inputs,
              "key"
            );
            return false;
          });

          var baza = this.$f7.methods.reconstructieBaza(
            date,
            this.state.inputs
          );
          var salariatiArhivati = baza.filter((r) => {
            return r.Arhivat;
          });
          this.setUsers(responseSalariati);

          filtre["Activ"].value = "True";
          filtre["Activ"].comparatie = "bool";

          if (!this.state.inclusivArhivati) {
            baza = baza.filter((r) => {
              return !r.Arhivat;
            });
          }

          this.setState(
            {
              dataSource: baza,
              salariatiArhivati: salariatiArhivati,
              filtre: filtre,
            },
            () => {
              setTimeout(() => {
                this.setState(
                  {
                    dataLoaded: true,
                  },
                  () => {
                    resolve(responseSalariati);
                  }
                );
              }, 400);
            }
          );
        })
        .catch((error) => {
          reject(error);
          this.setState(
            {
              dataSource: [],
              dataLoaded: true,
              filtre: filtre,
            },
            () => {
              this.$f7.dialog.confirm(
                "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                "Ups...",
                () => {
                  this.GetValues();
                },
                () => {}
              );
            }
          );

          console.log("error", error);
        });
    });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  /**
   * @description Adauga o inregistrare noua pentru interfata curenta(un salariat nou)
   * @function
   * @date 07/04/2022
   * @param {*} data obiect cu informatiile inregistrarii noi
   * @memberof SalariatiModule
   */
  AddValue = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApi + "/PostSALARIATI";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza valorile inregistrarii selectate
   * @date 07/04/2022
   * @param {*} obj obiect cu valorile noi ale inregistrarii selectate
   * @param {*} id id-ul inregistrarii selectate
   * @function
   * @memberof SalariatiModule
   */
  UpdateValue = (obj, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(this.$f7.data.baseURL + this.state.linkApi + "/GetSALARIATI/" + id)
        .then((response) => {
          var obj2 = response.data;
          Object.keys(obj).map((trasatura) => {
            Object.keys(obj2).map((trasatura2) => {
              if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
                obj2[trasatura2] = obj[trasatura];
              }
              return false;
            });
            return false;
          });
          var url =
            this.$f7.data.baseURL + this.state.linkApi + "/PutSALARIATI/" + id;
          axios({
            data: obj2,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: url,
          })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject("Eroare la modificare" + error);
            });
        })
        .catch((error) => {
          reject("Eroare la modificare" + error);
        });
    });
  };

  /**
   * @description Schimba parola utilizatorului curent selectat
   * @date 07/04/2022
   * @param {*} obj informatiile utilizatorului curent selectat
   * @param {*} id id-ul utilizatorului curent selectat
   * @function
   * @memberof SalariatiModule
   */
  ChangePassword = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/GetSALARIATI/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });
        var url =
          this.$f7.data.baseURL +
          this.state.linkApi +
          "/ChangePasswordSALARIATI/" +
          id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Randeaza departamentele sub forma de treelist
   * @function
   * @date 07/04/2022
   * @memberof SalariatiModule
   */
  renderTreeViewDepartamente = () => {
    var departamente = [...this.state.departamente];
    var useri = [...this.state.users];
    return departamente.map((departament, index) => (
      <TreeviewItem
        key={"SalariatiDepartamente-" + departament.key + index}
        iconMaterial="meeting_room"
        label={departament.key}>
        {useri
          .filter((userFilter) => {
            return userFilter.Id_Departament === departament.value;
          })
          .map((user, index2) => (
            <TreeviewItem
              key={
                "SalariatiDepartamente-" +
                user.Nume +
                " " +
                user.Prenume +
                index +
                index2
              }
              iconMaterial="person"
              label={user.Nume + " " + user.Prenume}
            />
          ))}
      </TreeviewItem>
    ));
  };

  /**
   * @description Ia trasaturile de la salariatul selectat pentru copiere
   * @function
   * @date 07/04/2022
   * @memberof SalariatiModule
   */
  GetValuesCopiereSalariati = () => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL +
        this.state.linkApiProiecte +
        "/GetPROIECTEModule/" +
        this.state.idSelectedForCopy;
      axios
        .get(url)
        .then((responseProiecte) => {
          responseProiecte.data.map((eveniment) => {
            eveniment = this.$f7.methods.restructurareBaza(
              eveniment,
              this.state.inputsProiecte,
              "key"
            );
            return false;
          });
          var bazaProiecte = this.$f7.methods.reconstructieBaza(
            responseProiecte,
            this.state.inputsProiecte
          );

          var allSelectedRows = bazaProiecte.filter((r) => {
            return r.TopEvent === 1;
          });
          this.setState(
            {
              selectedRowsData: allSelectedRows,
            },
            () => {
              resolve("a");
            }
          );
        })
        .catch((error) => {
          reject(error);
          this.$f7.methods.openNotification("Error:", error);
        });
    });
  };

  /**
   * @description Salveaza proiectele de la un salariat la altul
   * @function
   * @date 07/04/2022
   * @memberof SalariatiModule
   */
  SaveValuesCopiereSalariati = () => {
    var url =
      this.$f7.data.baseURL +
      this.state.linkApiProiecte +
      "/GetPROIECTEModule/" +
      this.state.idSelected;
    axios
      .get(url)
      .then((response) => {
        var date = response.data;
        date = date.filter((r) => {
          return r.topevent === 1;
        });
        var arrAdd = [];
        this.state.selectedRowsData.map((r) => {
          if (
            date.filter((f) => {
              return r.Id === f.iD_PROIECT;
            }).length === 0
          ) {
            //ADAUGA IN BAZA ELEMENTUL obj!
            var obj = {
              ref_proiect: r.Id,
              ref_salariat: this.state.idSelected,
            };
            //add obj;
            arrAdd.push(obj);
          }
          return null;
        });

        arrAdd
          .reduce((promise, item) => {
            return promise.then(() => {
              return this.AddValueSalariatContracteModule(item)
                .then((r) => {})
                .catch((err) => {
                  console.log("err", err);
                });
            });
          }, Promise.resolve())
          .then(() => {
            this.$f7.preloader.hide();
            this.setState({ idSelectedForCopy: null, idSelected: null });
            this.GetValuesDreapta();
          })
          .catch(() => {
            this.$f7.preloader.hide();
          });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  /**
   * @description Deschide treelist pana la nivelul specificat
   * @function
   * @date 07/04/2022
   * @param {*} level nivelul specificat
   * @memberof SalariatiModule
   */
  expandToLevel = (level) => {
    this.setState({ level: level }, () => {
      this.$f7.preloader.show();
      this.state.componentaTree.forEachNode((nod) => {
        if (nod.level < this.state.level) {
          this.state.componentaTree.expandRow(nod.data.Id);
        } else {
          this.state.componentaTree.collapseRow(nod.data.Id);
        }
      });
      this.$f7.preloader.hide();
    });
  };

  /**
   * @description functia de parseaza primul nivel de utilizatori pentru randare     *
   * @function
   * @date 07/04/2022
   * @param {*} dataSource datasourceul utilizatorilor
   * @memberof SalariatiModule
   */
  renderTreeViewUseri = (dataSource) => {
    var useriChilds = dataSource.filter((obj) => {
      return obj.Ref_Salariat === null;
    });
    return useriChilds.map((obj, index) => (
      <TreeviewItem
        key={"SalariatiUseri-" + obj.Nume + "-" + obj.Prenume + index}
        iconMaterial="person"
        label={obj.Nume + " " + obj.Prenume}>
        {this.renderTreeViewItemsUseri(dataSource, obj)}
      </TreeviewItem>
    ));
  };

  /**
   * @description Functie de randare recursiva a utilizatorilor ca un treelist
   * @date 07/04/2022
   * @function
   * @param {*} dataSource datasourceul salariatilor care vor fi randati
   * @param {*} parinte id-ul parinte(initial root)
   * @memberof SalariatiModule
   */
  renderTreeViewItemsUseri = (dataSource, parinte) => {
    var ds = dataSource.filter((obj) => {
      return obj.Ref_Salariat !== null;
    });
    var useriChilds = ds.filter((obj) => {
      return obj.Ref_Salariat === parinte.Id_Salariat;
    });
    if (useriChilds.length > 0) {
      return useriChilds.map((obj, index) => (
        <TreeviewItem
          key={"SalariatiUseriItems-" + obj.Nume + "-" + obj.Prenume + index}
          iconMaterial="person"
          label={obj.Nume + " " + obj.Prenume}>
          {this.renderTreeViewItemsUseri(ds, obj)}
        </TreeviewItem>
      ));
    } else {
      return null;
    }
  };

  /**
   * @description Functie de mutare a unui salariat
   * @date 07/04/2022
   * @function
   * @param {*} refParinte id-ul viitorului parinte al salariatului selectat
   * @param {*} ignoreChilds daca sa copieze si copiii sa nu la copiere
   * @memberof SalariatiModule
   */
  insert = (refParinte, ignoreChilds) => {
    return new Promise((resolve, reject) => {
      var ch = this.state.dataSource.filter((r) => {
        return r.Ref_Salariat === this.state.salariatSelectat.Id_Salariat;
      });
      var hasChilds = ch.length > 0;
      if (hasChilds && ignoreChilds !== undefined && ignoreChilds !== true) {
        var dialogul = this.$f7.dialog.create({
          animate: true,
          title: "Mutare",
          text: "Salariatul are subalterni,il mutati cu tot cu subalterni?",
          verticalButtons: true,
          buttons: [
            {
              text: "Da",
              color: "white",
              cssClass: "greenButton",
              onClick: (dialog, e) => {
                var obj = { reF_SALARIAT: refParinte };
                var id = this.state.salariatSelectat.Id_Salariat;
                this.UpdateValue(obj, id)
                  .then((response) => {
                    resolve(response);
                  })
                  .catch((err) => {
                    reject(err);
                  });
              },
            },
            {
              text: "Nu",
              color: "white",
              cssClass: "redButton",
              onClick: (dialog, e) => {
                var obj = { reF_SALARIAT: refParinte };
                var id = this.state.salariatSelectat.Id_Salariat;
                this.UpdateValue(obj, id)
                  .then((response) => {
                    ch.reduce((promise, item) => {
                      return promise.then(() => {
                        let obj2 = {
                          reF_SALARIAT:
                            this.state.salariatSelectat.Ref_Salariat,
                        };
                        return this.UpdateValue(obj2, item.Id_Salariat)
                          .then((r) => {})
                          .catch((err) => {});
                      });
                    }, Promise.resolve())
                      .then((response) => {
                        resolve(response);
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  })
                  .catch((err) => {
                    reject(err);
                  });
              },
            },
          ],
        });
        dialogul.open();
      } else {
        var obj = { reF_SALARIAT: refParinte };
        var id = this.state.salariatSelectat.Id_Salariat;
        this.UpdateValue(obj, id)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  };

  /**
   * @description Sterge din baza de date inregistrarea curenta(salariatul selectat)
   * @date 07/04/2022
   * @param {*} id id-ul inregistrarii curent selectate
   * @memberof SalariatiModule
   * @function
   */
  DeleteValue = (id) => {
    var url =
      this.$f7.data.baseURL + this.state.linkApi + "/DeleteSALARIATI/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  /**
   * @description Arhiveaza sau dezarhiveaza salariatul curent selectat
   * @date 07/04/2022
   * @param {*} info informatiile inregistrarii selectate curent(salariatul curent)
   * @param {*} arhiveaza true daca trebuie arhivat sau false pentru dezarhivarea
   * @function
   * @memberof SalariatiModule
   */
  ArhiveazaSalariat = (info, arhiveaza) => {
    const idSelectat = info.rowData.Id_Salariat;
    const numeSelectat = info.rowData.Nume + " " + info.rowData.Prenume;
    const areCopii =
      info.bigDataSource.filter((item) => item[info.refId] === idSelectat)
        .length > 0;

    if (arhiveaza) {
      if (areCopii) {
        this.$f7.methods.openNotification(
          "Acest salariat are subalternii (vizibili sau/si invizibili) si nu se poate arhiva direct. Incercati sa arhivati sau sa mutati mai intai subalternii.",
          "Atentie"
        );
      } else {
        let data = [];
        data.push(idSelectat);
        // da
        axios({
          data: data,
          headers: { "Content-Type": "application/json" },
          method: "post",
          url:
            this.$f7.data.baseURL + this.state.linkApi + "/SalariatiMoveToARH",
        })
          .then((response) => {
            this.$f7.methods.openNotification(
              '"' + numeSelectat + '" a fost arhivat cu success'
            );
            this.GetValues();
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la arhivare!");
          });
      }
    } else {
      let data = [];
      data.push(idSelectat);
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url:
          this.$f7.data.baseURL +
          this.state.linkApiARH +
          "/SalariatiMoveFromARH",
      })
        .then((response) => {
          this.$f7.methods.openNotification(
            '"' + numeSelectat + '" a fost dezarhivat cu success'
          );
          this.GetValues();
        })
        .catch((error) => {
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la dezarhivare!");
        });
    }
  };

  render() {
    return (
      <>
        <Popup
          push={true}
          tabletFullscreen={true}
          className={this.state.popupNameOrganigrama}>
          <Page>
            <Navbar title="Organigrama">
              <NavRight>
                <Link tooltip={"Inchide"} popupClose>
                  Inchide
                </Link>
              </NavRight>
            </Navbar>

            <Treeview>{this.renderTreeViewUseri(this.state.users)}</Treeview>
          </Page>
        </Popup>

        <Popup
          push={true}
          tabletFullscreen={true}
          className={this.state.popupNameDepartamente}>
          <Page>
            <Navbar title="Departamente">
              <NavRight>
                <Link tooltip={"Inchide"} popupClose>
                  Inchide
                </Link>
              </NavRight>
            </Navbar>
            {this.renderTreeViewDepartamente()}
          </Page>
        </Popup>

        <Popup
          push={true}
          tabletFullscreen={true}
          className={this.state.popupNameSchimbareParola}>
          <Page>
            <Navbar title="Modificare parola">
              <NavRight>
                <Link tooltip={"Inchide"} popupClose>
                  Inchide
                </Link>
              </NavRight>
            </Navbar>
            <List
              form
              onSubmit={(e) => {
                e.preventDefault();

                if (this.state.password === this.state.passwordConfirm) {
                  var obj = { ...this.state.currentUser };
                  this.$f7.dialog.confirm(
                    "Sunteti sigur ca doriti salvarea parolei?",
                    "Confirmare",
                    () => {
                      obj["Id_Departament"] = obj.Departament;
                      obj["Id_Birou"] = obj.Birou;
                      obj["Id_Profesie"] = obj.Profesie;
                      obj["Id_Competenta"] = obj.Competenta;
                      obj["Id_Angajator"] = obj.Angajator;
                      obj["Save_Pref"] = obj.SalvarePreferinta;
                      obj["Ask_Pref"] = obj.IntrebareSalvare;
                      obj["Report_Builder"] = obj.EditareRapoarte;
                      obj["Rol"] = obj.RolDeAplicatie;
                      obj["Retine_User"] = obj.RetineLogin;
                      obj["Parola"] = this.state.password;
                      delete obj.Departament;
                      delete obj.Birou;
                      delete obj.Profesie;
                      delete obj.Competenta;
                      delete obj.Angajator;
                      delete obj.SalvarePreferinta;
                      delete obj.IntrebareSalvare;
                      delete obj.EditareRapoarte;
                      delete obj.RolDeAplicatie;
                      delete obj.RetineLogin;
                      this.ChangePassword(obj, obj.Id_Salariat);
                      this.$f7.popup.close(
                        "." + this.state.popupNameSchimbareParola
                      );
                    }
                  );
                } else {
                  this.$f7.methods.openNotification(
                    "Inserati din nou cele 2 parole."
                  );
                }
              }}>
              <ListInput
                key={"item-1"}
                floatingLabel
                inputmode="text"
                label={"Parola"}
                validate
                type="password"
                placeholder={"Parola"}
                value={this.state.password}
                onInput={(e) => {
                  var val = e.target.value;
                  this.setState({ password: val });
                }}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="security"
                />
              </ListInput>
              <ListInput
                key={"item-2"}
                floatingLabel
                inputmode="text"
                label={"Confirmare parola"}
                validate
                type="password"
                placeholder={"Confirmare parola"}
                value={this.state.passwordConfirm}
                onInput={(e) => {
                  var val = e.target.value;
                  this.setState({ passwordConfirm: val });
                }}>
                <Icon
                  slot="content-start"
                  style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                  size={20}
                  material="security"
                />
              </ListInput>
              <Segmented
                tooltip={"Salveaza"}
                raised
                style={{ maxWidth: 400, margin: "0 auto", marginTop: 8 }}
                tag="div">
                <Button fill small type="submit">
                  Salveaza
                </Button>
              </Segmented>
            </List>
          </Page>
        </Popup>
        <div style={{ position: "relative" }}>
          <Row>
            <Col width={35}>
              <DataGrid
                title={() => {
                  return (
                    <Segmented
                      raised
                      style={{
                        top: 3,
                        left: 0,
                        width: 250,
                      }}
                      tag="div">
                      {/*                                 <Button*/}
                      {/*                                     tooltip={"Organigrama salariati"}*/}
                      {/*                                     fill*/}
                      {/*                                     small*/}
                      {/*                                     popupOpen={"." + this.state.popupNameOrganigrama}*/}

                      {/*                                 >*/}
                      {/*                                     Organigrama*/}
                      {/*</Button>*/}
                      {/*                                 <Button*/}
                      {/*                                     tooltip={"Departamente"}*/}
                      {/*                                     fill*/}
                      {/*                                     small*/}
                      {/*                                     popupOpen={"." + this.state.popupNameDepartamente}*/}
                      {/*                                 >*/}
                      {/*                                     Departamente*/}
                      {/*</Button>*/}
                      <Button
                        tooltip={"Modificare parola"}
                        fill
                        small
                        popupOpen={
                          this.state.currentUser
                            ? "." + this.state.popupNameSchimbareParola
                            : null
                        }
                        onClick={() => {
                          if (this.state.currentUser === null) {
                            this.$f7.methods.openNotification(
                              "Selecteaza un salariat!"
                            );
                          }
                        }}>
                        Mod. parola
                      </Button>
                      <Button
                        tooltip={
                          this.state.inclusivArhivati
                            ? "Afiseaza activi"
                            : "Afiseaza inclusiv arhivati"
                        }
                        fill
                        small
                        onClick={() => {
                          this.setState(
                            {
                              inclusivArhivati: !this.state.inclusivArhivati,
                            },
                            () => {
                              this.GetValues();
                            }
                          );
                        }}>
                        {this.state.inclusivArhivati
                          ? "Fara arhivati"
                          : "Cu arhivati"}
                      </Button>
                    </Segmented>
                  );
                }}
                onInitialised={(state, r, itemeRandate) => {
                  this.colorItems(state, r, itemeRandate);
                }}
                onRenderItemsChanged={(state, r, itemeRandate) => {
                  this.colorItems(state, r, itemeRandate);
                }}
                dataLoaded={this.state.dataLoaded}
                refresh={this.GetValues}
                keys={["Nume", "Prenume", "Activ", "Arhivat", "Ascuns"]}
                renderAccordion={true}
                name={"Salariati"}
                dragRows={true}
                renderFilterColumns={true}
                id={"Id_Salariat"}
                refId={"Ref_Salariat"}
                initialRefId={null}
                ref={this.refDataGrid1}
                renderUpdate={true}
                renderDelete={true}
                renderAdd={true}
                maxHeight={this.state.height}
                dataSource={this.state.dataSource}
                inputs={this.state.inputs}
                filtre={this.state.filtre}
                onAdd={(state) => {
                  var obj = { ...state.editedItem };
                  obj = this.$f7.methods.restructurareBaza(
                    obj,
                    this.state.inputs,
                    "keyBaza"
                  );
                  delete obj["iD_SALARIAT"];
                  this.AddValue(obj);
                }}
                onUpdate={(state) => {
                  var obj = { ...state.editedItem };
                  var esteArhivat =
                    this.state.salariatiArhivati.filter((r) => {
                      return r.Id_Salariat === obj.Id_Salariat;
                    }).length > 0;

                  obj = this.$f7.methods.restructurareBaza(
                    obj,
                    this.state.inputs,
                    "keyBaza"
                  );
                  this.UpdateValue(obj, obj.iD_SALARIAT)
                    .then((response) => {
                      this.GetValues()
                        .then(() => {
                          if (esteArhivat === true && obj.isArhivat !== true) {
                            //De dezarhivat
                            this.ArhiveazaSalariat(state, false);
                          } else if (
                            esteArhivat !== true &&
                            obj.isArhivat === true
                          ) {
                            this.ArhiveazaSalariat(state, true);
                          }
                          this.$f7.methods.openNotification(
                            "Modificat cu success"
                          );
                        })
                        .catch((err) => {
                          this.openNotification("Eroare" + err);
                        });
                    })
                    .catch((err) => {
                      this.openNotification("Eroare" + err);
                    });
                }}
                onDelete={(state) => {
                  var obj = { ...state.editedItem };
                  this.DeleteValue(obj.Id_Salariat);
                }}
                onRowClick={(state) => {
                  this.setState(
                    {
                      currentUser: state.rowData,
                      idSalariat: state.rowData.Id_Salariat,
                    },
                    () => {
                      this.GetValuesDreapta();
                    }
                  );
                }}
                contextMenuItems={(state, context) => {
                  return [
                    {
                      key: "Arhiveaza",
                      onClick: (state) => {
                        setTimeout(() => {
                          this.ArhiveazaSalariat(state, true);
                        }, 500);
                      },
                      icon: "compress",
                    },
                    {
                      key: "Dezarhiveaza",
                      onClick: (state) => {
                        setTimeout(() => {
                          this.ArhiveazaSalariat(state, false);
                        }, 500);
                      },
                      icon: "expand",
                    },
                    {
                      disabled: this.state.cutting,
                      key: "Cut",
                      onClick: (state) => {
                        setTimeout(() => {
                          var salariat = state.rowDataAll;
                          this.setState({
                            cutting: true,
                            salariatSelectat: salariat,
                          });
                        }, 500);
                      },
                      icon: "horizontal_split",
                    },
                    {
                      disabled: !this.state.cutting,
                      key: "Insereaza pe acelasi nivel",
                      onClick: (state) => {
                        setTimeout(() => {
                          var refSalariat = state.rowDataAll.Ref_Salariat;
                          this.insert(refSalariat, false)
                            .then((r) => {
                              this.setState({
                                salariatSelectat: null,
                                cutting: false,
                              });
                              this.$f7.methods.openNotification(
                                "Mutat cu success!",
                                "Succes",
                                "succes"
                              );
                              this.GetValues();
                            })
                            .catch((err) => {
                              this.setState({
                                salariatSelectat: null,
                                cutting: false,
                              });
                              this.$f7.methods.openNotification(
                                "Eroare la mutare:" + err,
                                "Eroare",
                                "eroare"
                              );
                            });
                        }, 500);
                      },
                      icon: "horizontal_split",
                    },
                    {
                      disabled: !this.state.cutting,
                      key: "Insereaza ca copil",
                      onClick: (state) => {
                        setTimeout(() => {
                          var idSalariat = state.rowDataAll.Id_Salariat;
                          this.insert(idSalariat, false)
                            .then((r) => {
                              this.setState({
                                salariatSelectat: null,
                                cutting: false,
                              });
                              this.$f7.methods.openNotification(
                                "Mutat cu success!",
                                "Succes",
                                "succes"
                              );
                              this.GetValues();
                            })
                            .catch((err) => {
                              this.setState({
                                salariatSelectat: null,
                                cutting: false,
                              });
                              this.$f7.methods.openNotification(
                                "Eroare la mutare:" + err,
                                "Eroare",
                                "eroare"
                              );
                            });
                        }, 500);
                      },
                      icon: "horizontal_split",
                    },
                    {
                      disabled: !this.state.cutting,
                      key: "Insereaza ca parinte",
                      onClick: (state) => {
                        setTimeout(() => {
                          var oldSalariat = this.state.salariatSelectat;
                          var idOldSalariat = oldSalariat.Id_Salariat;
                          this.setState(
                            { salariatSelectat: state.rowDataAll },
                            () => {
                              this.insert(idOldSalariat, true)
                                .then((r) => {
                                  this.setState({
                                    salariatSelectat: null,
                                    cutting: false,
                                  });
                                  this.$f7.methods.openNotification(
                                    "Mutat cu success!",
                                    "Succes",
                                    "succes"
                                  );
                                  this.GetValues();
                                })
                                .catch((err) => {
                                  this.setState({
                                    salariatSelectat: null,
                                    cutting: false,
                                  });
                                  this.$f7.methods.openNotification(
                                    "Eroare la mutare:" + err,
                                    "Eroare",
                                    "eroare"
                                  );
                                });
                            }
                          );
                        }, 500);
                      },
                      icon: "horizontal_split",
                    },
                    {
                      key: "Copiere trasaturi",
                      onClick: (state2) => {
                        setTimeout(() => {
                          var id = state2.rowData.Id_Salariat;
                          this.setState({ idSelectedForCopy: id }, () => {
                            this.GetValuesCopiereSalariati()
                              .then((r) => {
                                var itemeRandate = state.renderItems.slice(
                                  state.numberBefore,
                                  state.numberBefore + state.count
                                );
                                this.colorItems(state, context, itemeRandate);
                              })
                              .catch(() => {});
                          });
                        }, 500);
                      },
                      icon: "play_arrow",
                    },
                    {
                      disabled: this.state.idSelectedForCopy === null,
                      key: "Aplica trasaturi",
                      onClick: (state2) => {
                        setTimeout(() => {
                          var id = state2.rowData.Id_Salariat;
                          this.setState({ idSelected: id }, () => {
                            this.SaveValuesCopiereSalariati();
                          });
                        }, 500);
                      },
                      icon: "play_arrow",
                    },
                    {
                      key: "Schimbare parola",
                      onClick: (state2) => {
                        setTimeout(() => {
                          this.setState(
                            {
                              currentUser: state2.rowData,
                              idSalariat: state2.rowData.Id_Salariat,
                            },
                            () => {
                              this.$f7.popup.open(
                                "." + this.state.popupNameSchimbareParola
                              );
                            }
                          );
                        }, 500);
                      },
                    },
                  ];
                }}
                dragRowsOnStart={(e, oldIndex) => {
                  var itemeRandate = e.state.renderItems.slice(
                    e.state.numberBefore,
                    e.state.numberBefore + e.state.count
                  );
                  var item = itemeRandate[oldIndex];
                  if (item === undefined) {
                    item = itemeRandate[itemeRandate.length - 1];
                  }
                  this.setState({ rowItemData: item });
                }}
                dragRowsOnEnd={(e) => {
                  if (this.state.rowItemData !== null) {
                    var obj = { Ref_Salariat: e.state.initialRefId };
                    var id = this.state.rowItemData.Id_Salariat;
                    this.$f7.dialog.confirm(
                      "Sunteti sigur?",
                      "Confirm",
                      () => {
                        this.UpdateValue(obj, id);
                      },
                      () => {
                        this.GetValues();
                      }
                    );
                  }
                }}
              />
            </Col>
            <Col /*style={{ marginTop: 35 }}*/ width={65}>
              {this.state.idSalariat !== null ? (
                <div style={{ position: "relative" }}>
                  {this.state.dataLoaded2 ? (
                    <>
                      {this.state.maxDepth > 0 ? (
                        <Range
                          label={true}
                          className={"rangeDepth"}
                          min={0}
                          max={this.state.maxDepth}
                          step={1}
                          value={this.state.level}
                          scale={true}
                          scaleSteps={this.state.maxDepth}
                          scaleSubSteps={1}
                          onRangeChanged={(e) => {
                            this.expandToLevel(e);
                          }}
                        />
                      ) : null}
                      <TreeList
                        filterMode={"fullBranch"}
                        onInitialized={(e) => {
                          this.setState({
                            componentaTree: e.component,
                          });
                        }}
                        searchPanel={{
                          visible: true,
                          width: 160,
                        }}
                        selection={{ mode: "multiple", recursive: true }}
                        onSelectionChanged={(e) => {
                          var fe = this.getAllSelectedNodes(
                            e.component,
                            e.selectedRowKeys
                          );
                          var s = [];
                          var sData = [];
                          fe.map((r) => {
                            if (!s.includes(r.key)) {
                              s.push(r.key);
                            }
                            if (
                              sData.filter((f) => {
                                return f.Id === r.data.Id;
                              }).length === 0
                            ) {
                              sData.push(r.data);
                            }
                            return null;
                          });

                          this.setState(
                            {
                              selectedRowKeys: s,
                              componentaTree: e.component,
                              selectedRowsData: sData,
                            },
                            () => {
                              this.SaveValuesDevextreme();
                            }
                          );
                        }}
                        selectedRowKeys={this.state.selectedRowKeys}
                        showColumnHeaders={true}
                        showColumnLines={true}
                        height={window.innerHeight - 130}
                        rootValue={-1}
                        dataSource={this.state.dataSourceProiecte}
                        showBorders={true}
                        keyExpr={"Id"}
                        parentIdExpr={"Ref"}
                        id="TreeList"
                        columns={[
                          {
                            minWidth: 100,
                            dataField: "Denumire",
                            caption: "Denumire",
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        width: "35px",
                        height: "35px",
                        top: "calc(50vh - 31px)",
                        left: "50%",
                      }}>
                      <Preloader size={40} color={"var(--f7-theme-color)"} />
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    position: "relative",
                    height: "calc(100vh - 62px)",
                  }}>
                  <h3
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: 0,
                      right: 0,
                      transform: "translateY(-50%)",
                      textTransform: "uppercase",
                      textAlign: "center",
                      display: "block",
                    }}>
                    Nu ati selectat nimic
                  </h3>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export default SalariatiModule;
