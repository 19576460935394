import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import * as inputs from "../../../../inputs";
import axios from "axios";
/**
 * @description Interfata de intretinere a tipurilor de nivele de proiect
 * @date 07/04/2022
 * @class TipNivelProiect
 * @extends {React.Component}
 */
class TipNivelProiect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoaded: false,
      linkApi: "/api/TipNivelProiect",
      denumire: "TipNivelProiect",
      height: window.innerHeight - 34,
    };

    this.refDataGrid = React.createRef();
  }
  /**
   * @description Se iau toate inregistrarile pentru interfata de intretinere curenta(TipNivelProiect)
   * @function
   * @date 07/04/2022
   * @memberof TipNivelProiect
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApi)
      .then((response) => {
        this.$f7.methods.IaValori();

        var baza = this.$f7.methods.reconstructieBaza(
          response,
          inputs.IntretinereTipNivelProiect
        );

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  this.refDataGrid.current.setRenderItems(baza);
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.refDataGrid.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };
  /**
   * @description Adauga o inregistrare noua pentru interfata curenta(TipNivelProiect)
   * @date 07/04/2022
   * @param {*} data obiectul cu toate informatiile noii inregistrari
   * @function
   * @memberof TipNivelProiect
   */
  AddValue = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApi;
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };
  /**
   * @description Se modifica in baza de date inregistrarea selectata
   * @date 07/04/2022
   * @param {*} obj obiect cu noile informatii ale inregistrarii selectate
   * @param {*} id id-ul inregistrarii selectate
   * @memberof TipNivelProiect
   */
  UpdateValue = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url = this.$f7.data.baseURL + this.state.linkApi + "/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };
  /**
   * @description Sterge din baza de date inregistrarea selectata
   * @date 07/04/2022
   * @param {*} id id-ul inregistrarii selectate
   * @memberof TipNivelProiect
   */
  DeleteValue = (id) => {
    var url = this.$f7.data.baseURL + this.state.linkApi + "/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return (
      <DataGrid
        dataLoaded={this.state.dataLoaded}
        refresh={this.GetValues}
        dataSource={this.state.dataSource}
        name={"TipNivelProiect"}
        ref={this.refDataGrid}
        id="Id_Nivel_Proiect"
        renderUpdate={true}
        renderDelete={true}
        renderAdd={true}
        maxHeight={this.state.height}
        inputs={inputs.IntretinereTipNivelProiect}
        onAdd={(state) => {
          var data = { ...state.editedItem };
          delete data.Id_Nivel_Proiect;
          this.AddValue(data);
        }}
        onDelete={(state) => {
          var data = { ...state.editedItem };
          this.DeleteValue(data.Id_Nivel_Proiect);
        }}
        onUpdate={(state) => {
          var data = { ...state.editedItem };
          this.UpdateValue(data, data.Id_Nivel_Proiect);
        }}
      />
    );
  }
}
export default TipNivelProiect;
