import React, { Component } from 'react'
import { GoogleLogin } from 'react-google-login'
import axios from 'axios'
import moment from 'moment'

import EmailSidePanel from './EmailSidePanel'
import EmailContentPanel from './EmailContentPanel'

class EmailPopup extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      CLIENT_ID: '861515634037-piqene34uaasttrld79h543uij81qgco.apps.googleusercontent.com',
      SCOPES: 'https://mail.google.com/',
      // DISCOVERY_DOCS: ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"],
      // SCOPES: 'https://www.googleapis.com/auth/gmail.readonly',

      activeTab: null,
      mailCategoryList: ["INBOX", "SENT", "DRAFT", "SPAM", "TRASH"],

      accessToken: null,
      isSignedIn: false,
      gmailAddress: '',
      profileImage: null,
      profileName: '',

      mailList: [],
      pageToken: [null],
      noOfResults: 0,
      selectedEmailId: null,
      selectedEmailInfo: null,
      selectedIsUnread: false
    }
  }

  // Handlers for Google Auth
  handleSuccessGoogle = response => {
    console.log(response)

    this.setState({
      accessToken: response.accessToken,
      isSignedIn: response.isSignedIn(),
      gmailAddress: response.profileObj.email,
      profileImage: response.profileObj.imageUrl,
      profileName: response.profileObj.name
    })
  }

  handleFailureGoogle = response => {
    console.log(response)
  }

  handleLogoutGoogle = response => {
    console.log(response)

    this.setState({
      accessToken: null,
      isSignedIn: false,
      gmailAddress: ''
    })
  }
  // END - Handlers for Google Auth

  handleGetCount = () => {
    if (this.state.accessToken && this.state.activeTab !== null) {
      axios({
        data: {
          AccessToken: this.state.accessToken,
          IdSalariat: this.$f7.data.user.iD_SALARIAT,
          MailAddress: this.state.gmailAddress,
          PageToken: this.state.pageToken[0],
          MailCategory: this.state.mailCategoryList[this.state.activeTab]
        },
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: this.$f7.data.baseURL + "/api/Gmail2/GetMailCount",
      }).then(response => {
        console.log('gmail count Email Popup:', response.data)

        this.setState({
          noOfResults: response.data
        }, () => {
          console.log('state:', this.state)
          this.handleGetMailList()
        })
      }).catch(err => {
        console.log('gmail error:', err.response)
        if (err.response.data) {
          alert(err.response.data.error_description)
        }
      })
    } else {
      alert("Nu esti logat cu nici un cont de Gmail!")
    }
  }

  handleGetMailList = () => {
    if (this.state.accessToken && this.state.activeTab !== null) {
      axios({
        data: {
          AccessToken: this.state.accessToken,
          IdSalariat: this.$f7.data.user.iD_SALARIAT,
          MailAddress: this.state.gmailAddress,
          PageToken: this.state.pageToken[this.state.pageToken.length - 1],
          MailCategory: this.state.mailCategoryList[this.state.activeTab]
        },
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: this.$f7.data.baseURL + "/api/Gmail2/GetMailsPaginated",
      }).then(response => {
        console.log('gmail list Email Popup:', response.data)

        this.setState({
          mailList: response.data.mailsDetails,
          pageToken: [...this.state.pageToken, response.data.pageToken]
        })
      }).catch(err => {
        console.log('gmail error:', err.response)
        if (err.response.data) {
          alert(err.response.data.error_description + "\nIncarc automat emailurile din INBOX.")
          this.handleSelectTab(0)
        }
      })
    } else {
      alert("Nu esti logat cu nici un cont de Gmail!")
    }
  }

  handleSelectEmail = id => {
    this.setState({
      selectedEmailId: this.state.selectedEmailId === id ? null : id
    }, () => {
      if (this.state.selectedEmailId) this.handleGetFullMailById()
    })
  }

  handleGetFullMailById = () => {
    if (this.state.selectedEmailId) {
      this.setState({
        selectedEmailInfo: null
      }, () => {
        const pageToken = this.state.pageToken[this.state.pageToken.length - 1]

        axios({
          data: { AccessToken: this.state.accessToken, IdSalariat: this.$f7.data.user.iD_SALARIAT, MailAddress: this.state.gmailAddress, PageToken: pageToken, Id: this.state.selectedEmailId },
          headers: { "Content-Type": "application/json" },
          method: "post",
          url: this.$f7.data.baseURL + "/api/Gmail2/GetFullMailById",
        }).then(response => {
          console.log(`gmail full id: "${this.state.selectedEmailId}" :`, response.data)
  
          let isUnread = false
          const selectedMail = this.state.mailList.find(m => m.id === response.data.id)
          if (selectedMail) {
            isUnread = selectedMail.isUnRead
          }

          this.setState({
            selectedEmailInfo: response.data,
            selectedIsUnread: isUnread
          }, () => {
            this.handleMarkMailAsRead(response.data.id)
          })
        }).catch(err => {
          console.log('gmail full error:', err.response)
          if (err.response.data) {
            alert(err.response.data.error_description)
          }
        })
      })
    } else {
      alert("Nu a fost selectat nici un email!")
    }
  }

  handleMarkMailAsRead = id => {
    if (this.state.selectedIsUnread) {
      axios({
        data: {
          AccessToken: this.state.accessToken,
          IdSalariat: this.$f7.data.user.iD_SALARIAT,
          MailAddress: this.state.gmailAddress,
          Id: id
        },
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: this.$f7.data.baseURL + "/api/Gmail2/MsgMarkAsRead",
      }).then(response => {
        console.log("gmail mark as read:", response.data)

        if (response.data) {
          let pageToken = [...this.state.pageToken]
          pageToken.pop()

          this.setState({
            pageToken,
            selectedIsUnread: false
          }, () => {
            this.handleGetCount()
          })
        }
      }).catch(err => {
        console.log('gmail mark as read error:', err.response)
        if (err.response.data) {
          alert(err.response.data.error_description)
        }
      })
    }
  }

  handleMarkMailAsUnread = id => {
    axios({
      data: {
        AccessToken: this.state.accessToken,
        IdSalariat: this.$f7.data.user.iD_SALARIAT,
        MailAddress: this.state.gmailAddress,
        Id: id
      },
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: this.$f7.data.baseURL + "/api/Gmail2/MsgMarkAsUnread",
    }).then(response => {
      console.log("gmail mark as unread:", response.data)

      if (response.data) {
        let pageToken = [...this.state.pageToken]
        pageToken.pop()

        this.setState({
          pageToken,
          mailList: [],
          selectedEmailId: null,
          selectedEmailInfo: null
        }, () => {
          this.handleGetCount()
        })
      }
    }).catch(err => {
      console.log('gmail mark as unread error:', err.response)
      if (err.response.data) {
        alert(err.response.data.error_description)
      }
    })
  }

  handleMoveToTrash = id => {
    axios({
      data: {
        AccessToken: this.state.accessToken,
        IdSalariat: this.$f7.data.user.iD_SALARIAT,
        MailAddress: this.state.gmailAddress,
        Id: id
      },
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: this.$f7.data.baseURL + "/api/Gmail2/MsgMoveToTrash",
    }).then(response => {
      console.log("gmail move to trash:", response.data)

      if (response.data) {
        let pageToken = [...this.state.pageToken]
        pageToken.pop()

        this.setState({
          pageToken,
          mailList: [],
          selectedEmailId: null,
          selectedEmailInfo: null,
          selectedIsUnread: false
        }, () => {
          this.handleGetCount()
        })
      }
    }).catch(err => {
      console.log('gmail move to trash error:', err.response)
      if (err.response.data) {
        alert(err.response.data.error_description)
      }
    })
  }

  handleMoveFromTrash = (id, destination) => {
    axios({
      data: {
        AccessToken: this.state.accessToken,
        IdSalariat: this.$f7.data.user.iD_SALARIAT,
        MailAddress: this.state.gmailAddress,
        MailCategory: destination,
        Id: id
      },
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: this.$f7.data.baseURL + "/api/Gmail2/MsgMoveFromTrash",
    }).then(response => {
      console.log("gmail move from trash:", response.data)

      if (response.data) {
        let pageToken = [...this.state.pageToken]
        pageToken.pop()

        this.setState({
          pageToken,
          mailList: [],
          selectedEmailId: null,
          selectedEmailInfo: null,
          selectedIsUnread: false
        }, () => {
          this.handleGetCount()
        })
      }
    }).catch(err => {
      console.log('gmail move from trash error:', err.response)
      if (err.response.data) {
        alert(err.response.data.error_description)
      }
    })
  }

  // Handlers for pagination buttons
  handleNextPage = () => {
    this.setState({
      mailList: []
    }, () => {
      // this.handleGetMailList()

      axios({
        data: {
          AccessToken: this.state.accessToken,
          IdSalariat: this.$f7.data.user.iD_SALARIAT,
          MailAddress: this.state.gmailAddress,
          MailCategory: "INBOX"
        },
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: this.$f7.data.baseURL + "/api/Gmail2/GetLocalMailsPaginated",
      }).then(response => {
        console.log("test GetLocalMailsPaginated:", response.data)
      }).catch(err => {
        console.log('test GetLocalMailsPaginated error:', err.response)
      })
    })
  }

  handlePrevPage = () => {
    let pageToken = [...this.state.pageToken]
    pageToken.pop()
    pageToken.pop()

    this.setState({
      pageToken,
      mailList: []
    }, () => {
      this.handleGetMailList()
    })
  }

  handleFirstPage = () => {
    this.setState({
      pageToken: [null],
      mailList: []
    }, () => {
      this.handleGetMailList()
    })
  }
  // END - Handlers for pagination buttons

  handleSelectTab = tabId => {
    this.setState({
      activeTab: tabId,

      mailList: [],
      pageToken: [null],
      noOfResults: 0,
      selectedEmailId: null,
      selectedEmailInfo: null
    }, () => {
      this.handleGetCount()
    })
  }

  handleAddTask = () => {
    console.log(this.state.selectedEmailInfo, this.state.mailList.find(m => m.id === this.state.selectedEmailId))

    let inputs = [...this.$f7.data.TopRight.state.inputs]
    inputs.find(x => x.key === "Denumire").defaultValue = this.state.selectedEmailInfo.subject
    inputs.find(x => x.key === "Cerinta").defaultValue = this.state.selectedEmailInfo.bodyText.trim() + "\n\nPrimit pe email de la:\n   - " + this.state.selectedEmailInfo.fromAddress
    inputs.find(x => x.key === "Deadline").defaultValue = moment().add(10, 'days').format()
    inputs.find(x => x.key === "Prioritate").defaultValue = 1
    inputs.find(x => x.key === "Atasament").disabled = true
    inputs.find(x => x.key === "EstimareInMinute").defaultValue = 120
    inputs.find(x => x.key === "Sursa").defaultValue = 1
    inputs.find(x => x.key === "Sursa").disabled = true

    this.$f7.data.TopRight.setState({
      inputs
    }, () => {
      this.$f7.data.TopRight.taskNou("Adaugare task email")
    })
  }

  // componentDidMount = () => {
  //   if (this.state.accessToken) {
  //     axios({
  //       data: {
  //         AccessToken: this.state.accessToken,
  //         IdSalariat: this.$f7.data.user.iD_SALARIAT,
  //         MailAddress: this.state.gmailAddress
  //       },
  //       headers: { "Content-Type": "application/json" },
  //       method: "post",
  //       url: this.$f7.data.baseURL + "/api/Gmail2/GetLocalMailsPaginated",
  //     }).then(response => {
  //       console.log("test GetLocalMailsPaginated:", response.data)
  //     }).catch(err => {
  //       console.log('test GetLocalMailsPaginated error:', err.response)
  //     })
  //   }
  // }
  
  render() {
    return (
      <div
        id='gmailPopup'
        style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f0f0f0' }}
      >
        {this.state.isSignedIn ||
          <div style={{ width: 'fit-content' }}>
            <GoogleLogin
              clientId={this.state.CLIENT_ID}
              buttonText="Login"
              onSuccess={this.handleSuccessGoogle}
              onFailure={this.handleFailureGoogle}
              cookiePolicy={'single_host_origin'}
              scope={this.state.SCOPES}
              isSignedIn={true}
            />
          </div>
        }

        {this.state.isSignedIn &&
          <>
            <EmailSidePanel
              CLIENT_ID={this.state.CLIENT_ID}
              mailList={this.state.mailList}
              pageToken={this.state.pageToken}
              noOfResults={this.state.noOfResults}
              selectedEmailId={this.state.selectedEmailId}
              activeTab={this.state.activeTab}
              profileImage={this.state.profileImage}
              profileName={this.state.profileName}
              handleLogoutGoogle={this.handleLogoutGoogle}
              handleGetCount={this.handleGetCount}
              handleSelectEmail={this.handleSelectEmail}
              handleNextPage={this.handleNextPage}
              handlePrevPage={this.handlePrevPage}
              handleFirstPage={this.handleFirstPage}
              handleSelectTab={this.handleSelectTab}
            />

            <EmailContentPanel
              mailInfo={this.state.selectedEmailInfo}
              selectedEmailId={this.state.selectedEmailId}
              isUnread={this.state.selectedIsUnread}
              activeTab={this.state.activeTab}
              handleMarkMailAsUnread={this.handleMarkMailAsUnread}
              handleMoveToTrash={this.handleMoveToTrash}
              handleMoveFromTrash={this.handleMoveFromTrash}
              handleAddTask={this.handleAddTask}
            />
          </>
        }
      </div>
    )
  }
}

export default EmailPopup
