import React from "react";
import {
  Page,
  List,
  ListInput,
  Icon,
  Segmented,
  Button,
} from "framework7-react";
import axios from "axios";
/**
 * @description In aceasta itnerfata se poate schimba parola utilizatorului logat
 * @date 07/04/2022
 * @class SchimbareParolaPage
 * @extends {React.PureComponent}
 */
class SchimbareParolaPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordConfirm: "",
      linkApi: "/api/Salariati",
    };
  }

  /**
   * @description Schimba parola inregistrarii selectate
   * @date 07/04/2022
   * @param {*} obj obiect cu toate informatiile inregistrarii selectate
   * @param {*} id id-ul inregistrarii selectate
   * @function
   * @memberof SchimbareParolaPage
   */
  ChangePassword = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/GetSALARIATI/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });
        var url =
          this.$f7.data.baseURL +
          this.state.linkApi +
          "/ChangePasswordSALARIATI/" +
          id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  render() {
    return (
      <Page style={{ overflow: "hidden" }}>
        <div className="ptr3">
          <List
            form
            onSubmit={(e) => {
              e.preventDefault();

              if (
                this.state.password !== "" &&
                this.state.passwordConfirm !== "" &&
                this.state.password === this.state.passwordConfirm
              ) {
                var obj = { ...this.$f7.data.user };
                this.$f7.dialog.confirm(
                  "Sunteti sigur ca doriti salvarea parolei?",
                  "Confirmare",
                  () => {
                    obj["Parola"] = this.state.password;
                    this.ChangePassword(obj, obj.iD_SALARIAT);
                    this.$f7router.back();
                  }
                );
              } else {
                this.$f7.methods.openNotification(
                  "Inserati din nou cele 2 parole."
                );
              }
            }}>
            <ListInput
              key={"item-1"}
              floatingLabel
              inputmode="text"
              label={"Parola"}
              validate
              type="password"
              placeholder={"Parola"}
              value={this.state.password}
              onInput={(e) => {
                var val = e.target.value;
                this.setState({ password: val });
              }}>
              <Icon
                slot="content-start"
                style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                size={20}
                material="security"></Icon>
            </ListInput>
            <ListInput
              key={"item-2"}
              floatingLabel
              inputmode="text"
              label={"Confirmare parola"}
              validate
              type="password"
              placeholder={"Confirmare parola"}
              value={this.state.passwordConfirm}
              onInput={(e) => {
                var val = e.target.value;
                this.setState({ passwordConfirm: val });
              }}>
              <Icon
                slot="content-start"
                style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                size={20}
                material="security"></Icon>
            </ListInput>
            <Segmented
              raised
              style={{ maxWidth: 400, margin: "0 auto", marginTop: 8 }}
              tag="div">
              <Button tooltip={"Salveaza"} fill small type="submit">
                Salveaza
              </Button>
            </Segmented>
          </List>
        </div>
      </Page>
    );
  }
}
export default SchimbareParolaPage;
