import React, { Component } from "react";
import { Page } from "framework7-react";
import DataGrid from "../../Datagrid/DataGrid";
import * as inputs from "./../../../../inputs";
import axios from "axios";

class ToateDoc extends Component {
    constructor(props) {
        super(props);

        this.height = window.innerHeight;

        this.state = {
            dataLoaded: false,
            height: window.innerHeight - 44,
            linkApiDocumente: "/api/Documente/GetDOCUMENTEByProiectId",
            dataSourceDocumente: [],
        };

        this.refDataGridDocumente = React.createRef();
    }

    GetValues = () => {
        this.setState({
            dataLoaded: false,
        });

        axios
            .get(
                this.$f7.data.baseURL +
                this.state.linkApiDocumente +
                "/" +
                this.$f7.data.Proiect.iD_PROIECT
            )
            .then((response) => {
                let change = response;

                for (let item in change.data) {
                    change.data[item]["TipDocument"] =
                        change.data[item].tiP_DOCUMENT != null
                            ? change.data[item].tiP_DOCUMENT.denumire
                            : "Fara";
                    change.data[item]["Proiect"] = change.data[item].proiecte.denumire;
                    change.data[item]["Autor"] =
                        change.data[item].salariati != null
                            ? change.data[item].salariati.nume +
                            " " +
                            change.data[item].salariati.prenume
                            : "Fara";
                    change.data[item]["DataModificarii"] =
                        change.data[item].datA_MODIF === null ||
                            change.data[item].datA_MODIF === ""
                            ? null
                            : change.data[item].datA_MODIF;
                    change.data[item]["CaleFisier"] =
                        change.data[item].numE_PATH === null ||
                            change.data[item].numE_PATH === ""
                            ? null
                            : change.data[item].numE_PATH;
                    change.data[item]["Comentariu"] =
                        change.data[item].comentariu === null ||
                            change.data[item].comentariu === ""
                            ? "Fara"
                            : change.data[item].comentariu;
                    change.data[item]["Legatura"] =
                        change.data[item].reF_DOCUMENT === null ||
                            change.data[item].reF_DOCUMENT === ""
                            ? "Fara"
                            : change.data[item].reF_DOCUMENT;
                }

                let baza = this.$f7.methods.reconstructieBaza(change, inputs.ToateDoc);

                this.setState(
                    {
                        dataSourceDocumente: baza.length === 0 ? [] : baza,
                    },
                    () => {
                        setTimeout(() => {
                            this.setState(
                                {
                                    dataLoaded: true,
                                },
                                () => {
                                    this.refDataGridDocumente.current.setRenderItems(baza);
                                }
                            );
                        }, 400);
                    }
                );
            })
            .catch((error) => {
                this.setState(
                    {
                        dataSourceDocumente: [],
                        dataLoaded: true,
                    },
                    () => {
                        this.refDataGridDocumente.current.setRenderItems([]);
                        this.$f7.dialog.confirm(
                            "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                            "Ups...",
                            () => {
                                this.GetValues();
                            },
                            () => { }
                        );
                    }
                );

                console.log("error", error);
            });
    };

    componentDidMount = () => {
        if (this.$f7.data.Proiect !== null) this.GetValues();
    };

    render() {
        return (
            <Page pageContent={false}>
                {this.$f7.data.Proiect !== null ?
                    <DataGrid
                        dataLoaded={this.state.dataLoaded}
                        refresh={this.GetValues}
                        name={"ToateDoc"}
                        ref={this.refDataGridDocumente}
                        renderUpdate={false}
                        renderDelete={false}
                        renderAdd={false}

                        maxHeight={this.state.height}
                        dataSource={this.state.dataSourceDocumente}
                        inputs={inputs.ToateDoc}
                    />
                    :
                    <div style={{
                        position: "relative",
                        height: "calc(100vh - 50px)"
                    }}>
                        <h3 style={{
                            position: "absolute",
                            top: "50%",
                            left: 0,
                            right: 0,
                            transform: "translateY(-50%)",
                            textTransform: "uppercase",
                            textAlign: "center",
                            display: "block"
                        }}>
                            Nu ati selectat nimic
                        </h3>
                    </div>
                }
            </Page>
        );
    }
}
export default ToateDoc;