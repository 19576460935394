import React, { Component } from "react";
import { Chart } from "react-google-charts";
import axios from "axios";
import { Page } from "framework7-react";

/**
 * @description Afiseaza informatiile de comparare ale taskului selectat
 * @date 08/04/2022
 * @class Comparare
 * @extends {Component}
 */
class Comparare extends Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;

    this.state = {
      height: window.innerHeight - 44,
      linkApi: "/api/FazeRoluri",
      dataSource: [
        [
          { type: "string", label: "Task ID" },
          { type: "string", label: "Task Name" },
          { type: "date", label: "Start Date" },
          { type: "date", label: "End Date" },
          { type: "number", label: "Duration" },
          { type: "number", label: "Percent Complete" },
          { type: "string", label: "Dependencies" },
        ],
        [
          "Nugl",
          "Nugl",
          new Date(1999, 0, 1),
          new Date(1999, 0, 1),
          null,
          100,
          null,
        ],
      ],
    };

    this.refDataGrid = React.createRef();
  }

  /**
   * @description Ia toate informatiile de comparare ale taskului scurent selectat
   * @function
   * @date 08/04/2022
   * @memberof Comparare
   */
  GetValues = () => {
    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetComparare/" +
          this.$f7.data.Proiect.iD_PROIECT
      )
      .then((response) => {
        var baza = response.data;

        var bazaFinal = [
          [
            { type: "string", label: "Task ID" },
            { type: "string", label: "Task Name" },
            { type: "date", label: "Start Date" },
            { type: "date", label: "End Date" },
            { type: "number", label: "Duration" },
            { type: "number", label: "Percent Complete" },
            { type: "string", label: "Dependencies" },
          ],
        ];
        baza.map((elemBaza) => {
          if (elemBaza.denumire !== "Parteneriate") {
            bazaFinal.push([
              elemBaza.denumire,
              elemBaza.denumire,
              new Date(elemBaza.dataStart),
              new Date(elemBaza.dataDeadline),
              new Date(elemBaza.dataDeadline).getTime() -
                new Date(elemBaza.dataStart).getTime(),
              0,
              null,
            ]);
          }
          return false;
        });
        this.setState({ dataSource: bazaFinal });
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de comparare de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    if (this.$f7.data.Proiect !== null) this.GetValues();
  };

  render() {
    return (
      <Page pageContent={false}>
        {this.$f7.data.Proiect !== null ? (
          <Chart
            width={"100%"}
            height={this.state.height}
            chartType="Gantt"
            loader={<div>Loading Chart</div>}
            data={this.state.dataSource}
            rootProps={{ "data-testid": "1" }}
          />
        ) : (
          <div
            style={{
              position: "relative",
              height: "calc(100vh - 50px)",
            }}>
            <h3
              style={{
                position: "absolute",
                top: "50%",
                left: 0,
                right: 0,
                transform: "translateY(-50%)",
                textTransform: "uppercase",
                textAlign: "center",
                display: "block",
              }}>
              Nu ati selectat nimic
            </h3>
          </div>
        )}
      </Page>
    );
  }
}
export default Comparare;
