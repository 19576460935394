import React from "react";
import {
  Page,
  Card,
  CardContent,
  Row,
  Col,
  Treeview,
  TreeviewItem,
  Button,
  Segmented,
} from "framework7-react";
import DataGrid from "./../Datagrid/DataGrid";
import * as inputs from "./../../../inputs";
import axios from "axios";
import moment from "moment";

/**
 * @description Interfata de afisare a tuturor rapoartelor din fastreport
 * @date 08/04/2022
 * @class RapoartePage
 * @extends {React.PureComponent}
 */
class RapoartePage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;

    this.$f7.data.Judete = [
      { value: 1, key: "SIBIU" },
      { value: 3, key: "BISTRIȚA-NĂSĂUD" },
      { value: 4, key: "TIMIS" },
      { value: 5, key: "BUZĂU" },
      { value: 6, key: "TELEORMAN" },
      { value: 8, key: "ARAD" },
      { value: 9, key: "ARGEȘ" },
      { value: 10, key: "CONSTANȚA" },
      { value: 11, key: "GORJ" },
      { value: 12, key: "DÂMBOVIȚA" },
      { value: 13, key: "DAMBOVITA-SOTANGA" },
      { value: 14, key: "NEAMȚ" },
    ];

    this.state = {
      src: null,
      pagina: "Rapoarte",
      denumire: Math.random().toString(36).substring(7).toString(),
      height: window.innerHeight - 44,

      linkApiStructuraRapoarte: "/api/StructuraRapoarte",
      linkApiRapoarte: "/api/FrRapoarte",

      treeSource: [],
      dataSource: [],
      fullDataSource: [],

      dataLoaded: true,
      selectedItem: {
        denumire: null,
        id: null,
      },
      raportSelectat: null,
    };
    this.ref1 = React.createRef();
    this.ref2 = React.createRef();
  }

  /**
   * @description Functie recursiva folosita pentru a atasa unui raport rapoartele copii ale acestuia
   * @function
   * @date 08/04/2022
   * @param {*} baza baza pe care se face filtrare(toate rapoartele)
   * @param {*} ref id-ul de referinta dupa care se face filtrarea
   * @memberof RapoartePage
   */
  recursiva = (baza, ref) => {
    return baza
      .filter((b) => b.idParinte === ref)
      .map((m) => {
        m.childs = this.recursiva(baza, m.idStructuraRapoarte);
        return m;
      });
  };

  /**
   * @description Ia structura rapoartelor pentru a le afisa in treelist
   * @function
   * @date 08/04/2022
   * @memberof RapoartePage
   */
  GetTreeViewValues = () => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApiStructuraRapoarte)
      .then((response) => {
        let bazaFinala = this.recursiva(response.data, -1);

        this.setState({
          treeSource: bazaFinala.length === 0 ? [] : bazaFinala,
        });
      })
      .catch((error) => {
        this.setState(
          {
            treeSource: [],
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea structurii rapoartelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetTreeViewValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Ia rapoartele din baza de date filtrat dupa tipul de raport selectat in treelist
   * @function
   * @date 08/04/2022
   * @memberof RapoartePage
   */
  GetGridValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiRapoarte +
          "/GetFrRapoarte/" +
          this.state.selectedItem.id
      )
      .then((response) => {
        //let initial = Object.assign([], response.data)
        let change = response;

        for (let item in change.data) {
          change.data[item]["Id"] = change.data[item].idRaport;
          change.data[item]["Denumire"] = change.data[item].numeRaport;
          change.data[item]["Creat"] = change.data[item].dataCreare;
          change.data[item]["Modificat"] = change.data[item].dataModificare;
        }

        let baza = this.$f7.methods.reconstructieBaza(change, inputs.Rapoarte);
        var arrFiltrare = [14, 29, 35, 40, 41, 48, 52, 59, 60, 64];
        if (this.$f7.data.rol > 3) {
          baza = baza.filter((i) => {
            return arrFiltrare.includes(parseInt(i.Id));
          });
        }

        this.setState(
          {
            dataSource: baza.length === 0 ? [] : baza,
            fullDataSource: baza.length === 0 ? [] : response.data,
            raportSelectat: null,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            fullDataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetGridValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Adauga in baza de date o noua inregistrare(Raport)
   * @date 08/04/2022
   * @param {*} data obiect cu toate informatiile noii inregistrari(Raport)
   * @function
   * @memberof RapoartePage
   */
  AddValue = (data) => {
    var url =
      this.$f7.data.baseURL + this.state.linkApiRapoarte + "/PostRapoarte";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetGridValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza in baza de date inregistrarea selectata
   * @date 08/04/2022
   * @param {*} obj obiect cu noile valori ale inregistrarii selectate
   * @param {*} id id-ul inregistrarii selectate
   * @memberof RapoartePage
   */
  UpdateValue = (obj, id) => {
    axios
      .get(
        this.$f7.data.baseURL + this.state.linkApiRapoarte + "/GetRaport/" + id
      )
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL +
          this.state.linkApiRapoarte +
          "/PutRapoarte/" +
          id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetGridValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Sterge din baza de date inregistrarea selectata(Raport)
   * @date 08/04/2022
   * @param {*} id id-ul inregistrarii selectate
   * @function
   * @memberof RapoartePage
   */
  DeleteValue = (id) => {
    var url =
      this.$f7.data.baseURL +
      this.state.linkApiRapoarte +
      "/DeleteRapoarte/" +
      id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetGridValues();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  componentDidMount = () => {
    this.GetTreeViewValues();
  };

  /**
   * @description Functia de randare a treelist-ului din stanga cu tipurile de raporte in functie de id-ul de referinta
   * @function
   * @date 08/04/2022
   * @param {*} items rapoartele
   * @memberof RapoartePage
   */
  renderTreeview = (items) => {
    return items.map((item, index) => {
      if (item.childs) {
        return (
          <TreeviewItem
            key={item.denumire + "-" + index}
            label={item.denumire}
            selectable
            selected={this.state.selectedItem.denumire === item.denumire}
            onClick={(e) =>
              this.toggleSelectable(e, item.denumire, item.idStructuraRapoarte)
            }
            style={{
              padding: 0,
            }}>
            {this.renderTreeview(item.childs)}
          </TreeviewItem>
        );
      } else {
        return (
          <TreeviewItem
            key={item.denumire + "-" + index}
            label={item.denumire}
            selectable
            selected={this.state.selectedItem.denumire === item.denumire}
            onClick={(e) =>
              this.toggleSelectable(e, item.denumire, item.idStructuraRapoarte)
            }
            style={{
              padding: 0,
            }}
          />
        );
      }
    });
  };

  /**
   * @description Face toggle daca se pot selecta itemele
   * @date 08/04/2022
   * @param {*} e evenimentul
   * @param {*} item item-ul selectat
   * @param {*} id id-ul elementului selectat
   * @memberof RapoartePage
   */
  toggleSelectable = (e, item, id) => {
    var self = this;
    var $ = self.$$;
    if ($(e.target).is(".treeview-toggle")) return;
    self.setState(
      {
        selectedItem: {
          denumire: item,
          id: id,
        },
      },
      () => {
        this.GetGridValues();
      }
    );
  };

  render() {
    return (
      <Page
        onPageInit={() => {
          this.$f7.data.paginaCurenta = this;
        }}
        id={this.state.pagina}
        style={{
          overflow: "auto",
          textAlign: "center",
          backgroundColor: "var(--f7-theme-color)",
        }}>
        <div className="ptr3" style={{ background: "white" }}>
          <Card
            style={{
              overflowY: "auto",
              width: "100%",
              height: "100%",
              padding: 2,
              margin: 0,
              boxSizing: "border-box",
            }}>
            <CardContent style={{ padding: 2 }}>
              <Row>
                <Col
                  ref={this.ref1}
                  onGridResize={() => {
                    localStorage.setItem(
                      "RapoarteWidthStanga",
                      this.ref1.current.eventTargetEl.style.width
                    );
                  }}
                  width={20}>
                  <Treeview
                    style={{
                      overflow: "hidden",
                      height: "calc(100vh - 48px)",
                      overflowY: "auto",
                    }}>
                    {this.renderTreeview(this.state.treeSource)}
                  </Treeview>
                </Col>
                <Col
                  ref={this.ref2}
                  onGridResize={() => {
                    localStorage.setItem(
                      "RapoarteWidthDreapta",
                      this.ref2.current.eventTargetEl.style.width
                    );
                  }}
                  width={80}>
                  {this.state.selectedItem.id !== null ? (
                    <div style={{ position: "relative" }}>
                      <Segmented
                        className="butoaneContextDatagrid"
                        raised
                        tag="div"
                        style={{
                          position: "absolute",
                          width: "calc(100% - 176px)",
                          top: 0,
                          left: 0,
                        }}>
                        <Button
                          tooltip={"Vizualizare raport"}
                          disabled={
                            this.state.raportSelectat === null ? true : false
                          }
                          onClick={() => {
                            console.log("r", this.state);
                            var raportFull = this.state.fullDataSource.find(
                              (x) => x.idRaport === this.state.raportSelectat.Id
                            );
                            if (raportFull?.inputs) {
                              var inputs = JSON.parse(raportFull?.inputs);

                              inputs.forEach((x) => {
                                if (x.tip === "selectbox") {
                                  x.lista = this.$f7.data[x.lista];
                                }
                              });

                              this.$f7.data.router.navigate("/popupAddEdit/", {
                                props: {
                                  title: "Raport",
                                  inputs: inputs,
                                  buttons: [
                                    {
                                      key: "Salveaza",
                                      onSubmit: (state, den) => {
                                        var editedItem = state.editedItem;
                                        inputs.forEach((x) => {
                                          if (x.tip === "datebox") {
                                            editedItem[x.key] = moment(
                                              editedItem[x.key]
                                            ).format("DD.MM.YYYY");
                                          } else if (x.tip === "selectbox") {
                                            var key = x.lista.find(
                                              (y) =>
                                                y.value === editedItem[x.key]
                                            )?.key;
                                            editedItem[x.key + "Key"] = key;
                                            editedItem[x.key + "Value"] =
                                              editedItem[x.key];
                                            delete editedItem[x.key];
                                          }
                                        });
                                        var extra = "";
                                        Object.keys(state.editedItem).forEach(
                                          (x) => {
                                            var value = state.editedItem[x];
                                            extra += `&${x}=${value}`;
                                          }
                                        );
                                        var url =
                                          "http://dev03:8888/result?report=" +
                                          this.state.raportSelectat.Id +
                                          extra;
                                        this.setState(
                                          {
                                            src: url,
                                          },
                                          () => {
                                            window.open(url, "_blank");
                                          }
                                        );
                                      },
                                    },
                                  ],
                                },
                              });
                            } else {
                              var url =
                                "http://dev03:8888/result?report=" +
                                this.state.raportSelectat.Id;
                              this.setState({ src: url }, () => {
                                window.open(url, "_blank");
                              });
                            }
                          }}
                          fill
                          small
                          iconMaterial="pageview"
                          iconColor={"white"}
                          iconSize={20}>
                          Vizualizare Raport
                        </Button>
                      </Segmented>

                      <DataGrid
                        dataLoaded={this.state.dataLoaded}
                        refresh={this.GetGridValues}
                        name={"RapoartePage"}
                        id={"Id"}
                        renderUpdate={true}
                        renderDelete={true}
                        renderAdd={false}
                        maxHeight={this.state.height}
                        dataSource={this.state.dataSource}
                        inputs={inputs.Rapoarte}
                        onRowClick={(e) => {
                          this.setState({ raportSelectat: e.rowData });
                        }}
                        onDelete={(state) => {
                          var data = { ...state.editedItem };
                          this.DeleteValue(data.Id);
                        }}
                        onUpdate={(state) => {
                          var data = { ...state.editedItem };

                          data["idRapoarte"] = data.Id;
                          delete data.Id;

                          data["denumire"] = data.Denumire;
                          delete data.Denumire;

                          data["dataCreare"] = data.Creat;
                          delete data.Creat;

                          data["dataModificare"] = data.Modificat;
                          delete data.Modificat;

                          this.UpdateValue(data, data.idRapoarte);
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        margintop: "calc(50vh)",
                        fontSize: "20px",
                      }}>
                      Nu a fost selectat nici un element
                    </div>
                  )}
                </Col>
                {/*<Col width={50}>*/}
                {/*    {this.state.src !== null ?*/}
                {/*        <iframe src={this.state.src} title="Raport"></iframe>*/}
                {/*        : <div>Selectati un raport.</div>}                                    */}
                {/*</Col>*/}
              </Row>
            </CardContent>
          </Card>
        </div>
      </Page>
    );
  }
}
export default RapoartePage;
