import React from "react";
import { Panel } from "framework7-react";
import SesizariChat from "./Sesizari/SesizariChat";

/**
 * @description Popoverul in care se randeaza chatul
 * @date 08/04/2022
 * @class MessagesPopover
 * @extends {React.PureComponent}
 */
class MessagesPopover extends React.PureComponent {
  constructor(props) {
    super(props);
    this.denumirePopover = props.denumire;
    this.refMesaje = React.createRef();
  }

  /**
   * @description Apeleaza functia de luare a chatului din dreapta la initializarea chatului
   * @function
   * @date 08/04/2022
   * @memberof MessagesPopover
   */
  GetValues = () => {
    if (this.refMesaje) {
      this.refMesaje.current.GetValuesDreapta();
    }
  };

  componentDidMount = () => {
    this.$f7.data.messagesPopover = this;
  };

  render() {
    return (
      <Panel
        resizable={true}
        onPanelOpened={() => {
          this.GetValues();

          var $ = this.$$;
          var self = this;
          $(document).on("keydown", function (e) {
            var code = e.keyCode || e.which;
            if (code === 13 && e.altKey) {
              var txtAreaList = $("#mesajInputWrapper textarea");
              if (txtAreaList.length > 0) {
                var txtArea = txtAreaList[0];
                var currentValue = txtArea.value;
                txtArea.value = currentValue + " \n";
              }
            } else if (code === 13) {
              e.preventDefault();
              self.refMesaje.current.sendMessage();
              return false;
            }
          });
        }}
        style={{
          width: "50%",
          zIndex: 10000,
          minWidth: 350,
        }}
        backdrop={true}
        className={this.denumirePopover}
        onPanelClose={() => {
          var $ = this.$$;
          $(document).off("keydown");
        }}>
        <div>
          <SesizariChat denumire={this.denumirePopover} ref={this.refMesaje} />
        </div>
      </Panel>
    );
  }
}

export default MessagesPopover;
