import HomePage from "./components/pages/HomePage";
import PopupDinamic from "./components/pages/PopupDinamic";
import PopupCall from "./components/pages/PopupCall";
import PopupApel from "./components/pages/PopupApel";
import AboutPage from "./components/Basic/AboutPage";
import SettingsPage from "./components/SideModals/SettingsPage";
import LoginPage from "./components/User/LoginPage";
import NotFoundPage from "./components/Basic/NotFoundPage";
// TEMPLATE FINISH

import SideNav from "./components/SideModals/SideNav";
import ColorPickerSetting from "./components/Settings/Colorpicker";
import TranzitiePage from "./components/Settings/Tranzitie";
import PlanificarePage from "./components/pages/Planificare/PlanificarePage";
import FacturarePage from "./components/pages/Facturare/FacturarePage";
import StadiuProiectePage from "./components/pages/StadiuProiecte/StadiuProiectePage";
import ProiecteRealizariPage from "./components/pages/ProiecteRealizari/ProiecteRealizariPage";
import RapoartePage from "./components/pages/Rapoarte/RapoartePage";
import Mesaje from "./components/pages/Proiecte/Mesaje/Mesaje";
import Grid from "./components/pages/Proiecte/Agenda/Grid";
import Tehnice from "./components/pages/Proiecte/Tehnice/Tehnice";
import Cheltuieli from "./components/pages/Proiecte/CheltuieliVenituri/Cheltuieli";
import Venituri from "./components/pages/Proiecte/CheltuieliVenituri/Venituri";
import FazeSiRoluri from "./components/pages/Proiecte/FazeRoluri/FazeSiRoluri";
import FazeProiect from "./components/pages/Proiecte/FazeRoluri/FazeProiect";
import DescriereProiect from "./components/pages/Proiecte/Tehnice/DescriereProiect";
import IstoricModificari from "./components/pages/Proiecte/Tehnice/IstoricModificari";
import Cerinte from "./components/pages/Proiecte/Tehnice/Cerinte";
import CodProiect from "./components/pages/Proiecte/Tehnice/CodProiect";
import Verificari from "./components/pages/Proiecte/Tehnice/Verificari";
import Documentare from "./components/pages/Proiecte/Tehnice/Documentare";
import Help from "./components/pages/Proiecte/Tehnice/Help";
import PlanificareTaskuri from "./components/pages/Proiecte/PlanificareTaskuri";
import ToateDoc from "./components/pages/Proiecte/Tehnice/ToateDoc";
import EroriRezolvari from "./components/pages/Proiecte/Tehnice/EroriRezolvari";
import PlanificareAnuala from "./components/pages/Proiecte/Vizualizare/PlanificareAnuala";
import VizualizareGenerala from "./components/pages/Proiecte/Vizualizare/VizualizareGenerala";
import AlocareSarcini from "./components/pages/Proiecte/Vizualizare/AlocareSarcini";
import Comparare from "./components/pages/Proiecte/Vizualizare/Comparare";
import Alocare from "./components/pages/Proiecte/Vizualizare/Alocare";
import Concedii from "./components/pages/Pontaj/Concedii";
import Delegatii from "./components/pages/Pontaj/Delegatii";
import BirouriPage from "./components/pages/Intretinere/Pagini/BirouriPage";
import ActivitateZilnicaPage from "./components/pages/Activitate/Pagini/ActivitateZilnicaPage";
import AprobareActivitatePage from "./components/pages/Activitate/Pagini/AprobareActivitatePage";
import ClientiPage from "./components/pages/Intretinere/Pagini/ClientiPage";
import DateClientiPage from "./components/pages/Intretinere/Pagini/DateClientiPage";
import ContractePage from "./components/pages/Intretinere/Pagini/ContractePage";
import CuloriArboreProiectePage from "./components/pages/Intretinere/Pagini/CuloriArboreProiectePage";
import DepartamentePage from "./components/pages/Intretinere/Pagini/DepartamentePage";
import FazePage from "./components/pages/Intretinere/Pagini/FazePage";
import OperatiuniClientiPage from "./components/pages/Intretinere/Pagini/OperatiuniClientiPage";
import ProfesiiPage from "./components/pages/Intretinere/Pagini/ProfesiiPage";
import PVPage from "./components/pages/Intretinere/Pagini/PVPage";
import SalariatiPage from "./components/pages/Intretinere/Pagini/SalariatiPage";
import StadiiPage from "./components/pages/Intretinere/Pagini/StadiiPage";
import CursValutarPage from "./components/pages/Intretinere/Pagini/CursValutarPage";
import TermeneFinalizarePage from "./components/pages/Intretinere/Pagini/TermeneFinalizarePage";
import TipNivelProiectPage from "./components/pages/Intretinere/Pagini/TipNivelProiectPage";
import TipuriCheltuieliPage from "./components/pages/Intretinere/Pagini/TipuriCheltuieliPage";
import TipuriEvenimenteClientiPage from "./components/pages/Intretinere/Pagini/TipuriEvenimenteClientiPage";
import TipuriProiectePage from "./components/pages/Intretinere/Pagini/TipuriProiectePage";
import TipuriVenitPage from "./components/pages/Intretinere/Pagini/TipuriVenitPage";
import UtilajePage from "./components/pages/Intretinere/Pagini/UtilajePage";
import BugetePage from "./components/pages/Activitate/Pagini/BugetePage";
import CheltuieliFazePage from "./components/pages/Activitate/Pagini/CheltuieliFazePage";
import CheltuieliProiecteSalariatiPage from "./components/pages/Activitate/Pagini/CheltuieliProiecteSalariatiPage";
import CheltuieliSalariatDepartamentPage from "./components/pages/Activitate/Pagini/CheltuieliSalariatDepartamentPage";
import GantRealizariPage from "./components/pages/Activitate/Pagini/GantRealizariPage";
import LogariCurentePage from "./components/pages/Activitate/Pagini/LogariCurentePage";
import OreLucratePeZiPage from "./components/pages/Activitate/Pagini/OreLucratePeZiPage";
import OreLucratePeZi2Page from "./components/pages/Activitate/Pagini/OreLucratePeZi2Page";
import OreLucratePeZiClientiPage from "./components/pages/Activitate/Pagini/OreLucratePeZiClientiPage";
import RepartitieSalariatiInPerioadaPage from "./components/pages/Activitate/Pagini/RepartitieSalariatiInPerioadaPage";
import SalariatiProiectePage from "./components/pages/Activitate/Pagini/SalariatiProiectePage";
import SalariatiVenituriPage from "./components/pages/Activitate/Pagini/SalariatiVenituriPage";
import StadiuAtlasV3Page from "./components/pages/Activitate/Pagini/StadiuAtlasV3Page";
import MeniuriPage from "./components/pages/Intretinere/Pagini/MeniuriPage";
import Grafic2 from "./components/pages/Proiecte/Agenda/Grafic2";
import ProiectePage from "./components/pages/Intretinere/Pagini/ProiectePage";
import PopupAddEdit from "./components/pages/PopupAddEdit";
import ManualInstructiuniPage from "./components/pages/ManualInstructiuni/ManualInstructiuniPage";
import GraficSalariat from "./components/pages/Proiecte/Agenda/GraficSalariat";
import ATSalariatiPage from "./components/pages/AlocareTask/ATSalariatiPage";
import ATDepartamentePage from "./components/pages/AlocareTask/ATDepartamentePage";
import ATGraficePage from "./components/pages/AlocareTask/ATGraficePage";
import ATGraficePage2 from "./components/pages/AlocareTask/ATGraficePage2";
import ATGraficePage1 from "./components/pages/AlocareTask/ATGraficePage1";
import SheetDinamic from "./components/pages/SheetDinamic";
import SursaPage from "./components/pages/Intretinere/Pagini/SursaPage";
import PVSheetClientPerioadaSheet from "./components/pages/ProceseVerbale/PVSheetClientPerioadaSheet";
import SchimbareParolaPage from "./components/pages/SchimbareParola/SchimbareParolaPage";
import CineLaCeLucreazaPage from "./components/pages/Activitate/Pagini/CineLaCeLucreazaPage";
import LogActivitateSalariatPage from "./components/pages/Proiecte/Agenda/LogActivitateSalariat/LogActivitateSalariatPage";
import ModulePage from "./components/pages/Intretinere/Pagini/ModulePage";
import ContracteModulePage from "./components/pages/Intretinere/Pagini/ContracteModulePage";
import SalariatiModulePage from "./components/pages/Intretinere/Pagini/SalariatiModulePage";
import TipValutePage from "./components/pages/Intretinere/Pagini/TipValutePage";
import AngajatoriPage from "./components/pages/Intretinere/Pagini/AngajatoriPage";
import TotalTaskuriSalariatPage from "./components/pages/Proiecte/Agenda/TotalTaskuriSalariat/TotalTaskuriSalariatPage";
import EstimatLucratProiectePage from "./components/pages/Activitate/Pagini/EstimatLucratProiectePage";
import EstimatLucratTaskuriPage from "./components/pages/Activitate/Pagini/EstimatLucratTaskuriPage";
import EstimatLucratSalariatiPage from "./components/pages/Activitate/Pagini/EstimatLucratSalariatiPage";
import EstimatLucratClientiPage from "./components/pages/Activitate/Pagini/EstimatLucratClientiPage";
import GanttProiectePage from "./components/pages/Activitate/Pagini/GanttProiectePage";
import IstoricTaskPage from "./components/pages/Activitate/Componente/Istoric/Pagini/IstoricTaskPage";
import IstoricProiectTaskPage from "./components/pages/Activitate/Componente/Istoric/Pagini/IstoricProiectTaskPage";
import IstoricProiectClientPage from "./components/pages/Activitate/Componente/Istoric/Pagini/IstoricProiectClientPage";
import IstoricClientTaskPage from "./components/pages/Activitate/Componente/Istoric/Pagini/IstoricClientTaskPage";
import Tasks from "./components/pages/Proiecte/Agenda/InterfeteNoi/Tasks/Tasks";
import Task from "./components/pages/Proiecte/Agenda/InterfeteNoi/Task/Task";

/** 
 * @description un array ce contine toate rutele valabile din aplicatie ce pot fi accesate cu ajutorul routerului din framework7
 */
const routes= [
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "home",
        path: "/",
        component: HomePage,
        viewName: "main",
        keepAlive: true,
        breadcrumbs: ["home"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "concedii",
        path: "/concedii/",
        component: Concedii,
        breadcrumbs: ["pontaj", "concedii"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "delegatii",
        path: "/delegatii/",
        component: Delegatii,
        viewName: "main",
        breadcrumbs: ["pontaj", "delegatii"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "rapoarteMain",
        path: "/rapoarteMain/",
        component: RapoartePage,
        viewName: "main",
        breadcrumbs: ["rapoarte"]
    },
    // Activitate
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "activitateZilnica",
        path: "/activitateZilnicaActivitate/",
        component: ActivitateZilnicaPage,
        viewName: "main",
        breadcrumbs: ["activitate", "activitate zilnica"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "AprobareActivitate",
        path: "/activitateAprobareActivitate/",
        component: AprobareActivitatePage,
        viewName: "main",
        breadcrumbs: ["activitate", "aprobare activitate"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "Bugete",
        path: "/bugeteActivitate/",
        component: BugetePage,
        viewName: "main",
        breadcrumbs: ["activitate", "bugete"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "cheltuieliFaze",
        path: "/cheltuieliFazeActivitate/",
        component: CheltuieliFazePage,
        viewName: "main",
        breadcrumbs: ["activitate", "cheltuieli pe faze"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "cheltuieliProiecteSalariati",
        path: "/cheltuieliProiecteSalariatiActivitate/",
        component: CheltuieliProiecteSalariatiPage,
        viewName: "main",
        breadcrumbs: ["activitate", "cheltuieli pe proiecte si salariati"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "cheltuieliSalariatDepartament",
        path: "/cheltuieliSalariatDepartamentActivitate/",
        component: CheltuieliSalariatDepartamentPage,
        viewName: "main",
        breadcrumbs: ["activitate", "cheltuieli pe salariati/departament"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "gantRealizari",
        path: "/gantRealizariActivitate/",
        component: GantRealizariPage,
        viewName: "main",
        breadcrumbs: ["activitate", "gantt realizari"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "logariCurente",
        path: "/logariCurenteActivitate/",
        component: LogariCurentePage,
        viewName: "main",
        breadcrumbs: ["activitate", "logari curente"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "oreLucratePeZi",
        path: "/oreLucratePeZiActivitate/",
        component: OreLucratePeZiPage,
        viewName: "main",
        breadcrumbs: ["activitate", "ore lucrate pe zi"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "istoricTask",
        path: "/activitateIstoricTask/",
        component: IstoricTaskPage,
        viewName: "main",
        breadcrumbs: ["activitate", "istoric task"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "istoricProiectTaskuri",
        path: "/activitateIstoricProiectTaskuri/",
        component: IstoricProiectTaskPage,
        viewName: "main",
        breadcrumbs: ["activitate", "istoric proiect/taskuri"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "istoricProiectClienti",
        path: "/activitateIstoricProiectClienti/",
        component: IstoricProiectClientPage,
        viewName: "main",
        breadcrumbs: ["activitate", "istoric proiect/clienti"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "istoricClientTaskuri",
        path: "/activitateIstoricClientTaskuri/",
        component: IstoricClientTaskPage,
        viewName: "main",
        breadcrumbs: ["activitate", "istoric client/taskuri"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "oreLucratePeZi2",
        path: "/oreLucratePeZi2Activitate/",
        component: OreLucratePeZi2Page,
        viewName: "main",
        breadcrumbs: ["activitate", "ore lucrate pe zi"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "oreLucratePeZiClienti",
        path: "/oreLucratePeZiClientiActivitate/",
        component: OreLucratePeZiClientiPage,
        viewName: "main",
        breadcrumbs: ["activitate", "ore lucrate clienti"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "repartitieSalariatiInPerioada",
        path: "/repartitieSalariatiInPerioadaActivitate/",
        component: RepartitieSalariatiInPerioadaPage,
        viewName: "main",
        breadcrumbs: ["activitate", "repartitie salariati in perioada"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "salariatiProiecte",
        path: "/salariatiProiecteActivitate/",
        component: SalariatiProiectePage,
        viewName: "main",
        breadcrumbs: ["activitate", "salariati pe proiecte"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "salariatiVenituri",
        path: "/salariatiVenituriActivitate/",
        component: SalariatiVenituriPage,
        viewName: "main",
        breadcrumbs: ["activitate", "salariati venituri"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "stadiuAtlasV3",
        path: "/stadiuAtlasV3Activitate/",
        component: StadiuAtlasV3Page,
        viewName: "main",
        breadcrumbs: ["activitate", "stadiu atlas v3"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "cineLaCeLucreaza",
        path: "/cineLaCeLucreaza/",
        component: CineLaCeLucreazaPage,
        viewName: "main",
        breadcrumbs: ["activitate", "cine la ce lucreaza"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "estimatLucratTaskuriActivitate",
        path: "/estimatLucratTaskuriActivitate/",
        component: EstimatLucratTaskuriPage,
        viewName: "main",
        breadcrumbs: ["activitate", "estimat/lucrat client"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "estimatLucratProiecteActivitate",
        path: "/estimatLucratProiecteActivitate/",
        component: EstimatLucratProiectePage,
        viewName: "main",
        breadcrumbs: ["activitate", "estimat/lucrat proiecte"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "estimatLucratClientiActivitate",
        path: "/estimatLucratClientiActivitate/",
        component: EstimatLucratClientiPage,
        viewName: "main",
        breadcrumbs: ["activitate", "estimat/lucrat clienti"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "estimatLucratSalariatiActivitate",
        path: "/estimatLucratSalariatiActivitate/",
        component: EstimatLucratSalariatiPage,
        viewName: "main",
        breadcrumbs: ["activitate", "estimat/lucrat salariati"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "ganttProiecteActivitate",
        path: "/ganttProiecteActivitate/",
        component: GanttProiectePage,
        viewName: "main",
        breadcrumbs: ["activitate", "gantt proiecte"]
    },
    // Intretinere

    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "meniuri",
        path: "/meniuriIntretinere/",
        component: MeniuriPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "meniuri"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "module",
        path: "/moduleIntretinere/",
        component: ModulePage,
        viewName: "main",
        breadcrumbs: ["intretinere", "module"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "cursvalutar",
        path: "/cursValutarIntretinere/",
        component: CursValutarPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "cursvalutar"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "tipValute",
        path: "/tipValuteIntretinere/",
        component: TipValutePage,
        viewName: "main",
        breadcrumbs: ["intretinere", "tip valute"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "angajatori",
        path: "/angajatoriIntretinere/",
        component: AngajatoriPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "angajatori"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "contracteModule",
        path: "/contracteModuleIntretinere/",
        component: ContracteModulePage,
        viewName: "main",
        breadcrumbs: ["intretinere", "contracte/module"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "sursa",
        path: "/sursaIntretinere/",
        component: SursaPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "sursa"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "birouri",
        path: "/birouriIntretinere/",
        component: BirouriPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "birouri"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "clienti",
        path: "/clientiIntretinere/",
        component: ClientiPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "clienti"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "date clienti",
        path: "/dateClientiIntretinere/",
        component: DateClientiPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "date clienti"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "contracte",
        path: "/contracteIntretinere/",
        component: ContractePage,
        viewName: "main",
        breadcrumbs: ["intretinere", "contracte"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "culoriArboreProiecte",
        path: "/culoriArboreProiecteIntretinere/",
        component: CuloriArboreProiectePage,
        viewName: "main",
        breadcrumbs: ["intretinere", "culori arbore proiecte"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "departamente",
        path: "/departamenteIntretinere/",
        component: DepartamentePage,
        viewName: "main",
        breadcrumbs: ["intretinere", "departamente"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "faze",
        path: "/fazeIntretinere/",
        component: FazePage,
        viewName: "main",
        breadcrumbs: ["intretinere", "faze"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "proiecte",
        path: "/proiecteIntretinere/",
        component: ProiectePage,
        viewName: "main",
        breadcrumbs: ["intretinere", "proiecte"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "operatiuniClienti",
        path: "/operatiuniClientiIntretinere/",
        component: OperatiuniClientiPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "operatiuni clienti"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "profesii",
        path: "/profesiiIntretinere/",
        component: ProfesiiPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "profesii"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "PV",
        path: "/PVIntretinere/",
        component: PVPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "pv"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "salariati",
        path: "/salariatiIntretinere/",
        component: SalariatiPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "salariati"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "salariatimodule",
        path: "/salariatiModuleIntretinere/",
        component: SalariatiModulePage,
        viewName: "main",
        breadcrumbs: ["intretinere", "salariati module"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "stadii",
        path: "/stadiiIntretinere/",
        component: StadiiPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "stadii"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "termeneFinalizare",
        path: "/termeneFinalizareIntretinere/",
        component: TermeneFinalizarePage,
        viewName: "main",
        breadcrumbs: ["intretinere", "termene de finalizare"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "tipNivel",
        path: "/tipNivelIntretinere/",
        component: TipNivelProiectPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "tipuri nivele proiect"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "tipuriCheltuieli",
        path: "/tipuriCheltuieliIntretinere/",
        component: TipuriCheltuieliPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "tipuri cheltuieli"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "tipuriEvenimenteClienti",
        path: "/tipuriEvenimenteClientiIntretinere/",
        component: TipuriEvenimenteClientiPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "tipuri evenimente clienti"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "tipuriProiecte",
        path: "/tipuriProiecteIntretinere/",
        component: TipuriProiectePage,
        viewName: "main",
        breadcrumbs: ["intretinere", "tipuri proiecte"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "tipuriVenit",
        path: "/tipuriVenitIntretinere/",
        component: TipuriVenitPage,
        viewName: "main",
        breadcrumbs: ["intretinere", "tipuri venit"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "utilaje",
        path: "/utilajeIntretinere/",
        component: UtilajePage,
        viewName: "main",
        breadcrumbs: ["intretinere", "utilaje"]
    },

    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "facturareMain",
        path: "/facturareMain/",
        component: FacturarePage,
        viewName: "main",
        breadcrumbs: ["facturare"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "stadiuProiecteMain",
        path: "/stadiuProiecteMain/",
        component: StadiuProiectePage,
        viewName: "main",
        breadcrumbs: ["studiu proiecte"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "proiecteRealizariMain",
        path: "/proiecteRealizariMain/",
        component: ProiecteRealizariPage,
        viewName: "main",
        breadcrumbs: ["proiecte realizari"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "planificareMain",
        path: "/planificareMain/",
        component: PlanificarePage,
        viewName: "main",
        breadcrumbs: ["planificare"]
    },
    {
        showBack: true,
        showAppBar: true,
        showTopRight: true,
        name: "about",
        path: "/about/",
        component: AboutPage,
        viewName: "main",
        breadcrumbs: ["despre"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "sidenav",
        path: "/sidenav/",
        keepAlive: true,
        panel: {
            component: SideNav
        },
        breadcrumbs: null
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "sheetDinamic",
        path: "/sheetDinamic/",
        sheet: {
            component: SheetDinamic
        },
        breadcrumbs: null
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "PVSheetClientPerioadaSheet",
        path: "/PVSheetClientPerioadaSheet/",
        viewName: "main",
        popup: {
            component: PVSheetClientPerioadaSheet
        },
        breadcrumbs: null
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "popupDinamic",
        path: "/popupDinamic/",
        popup: {
            component: PopupDinamic
        },
        breadcrumbs: null
    },
    {
        showBack: false,
        showAppBar: false,
        showTopRight: false,
        name: "popupCall",
        path: "/popupCall/",
        popup: {
            component: PopupCall
        },
        breadcrumbs: null
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "popupApel",
        path: "/popupApel/",
        popup: {
            component: PopupApel
        },
        breadcrumbs: null
    },
    {
        showBack: true,
        showAppBar: true,
        showTopRight: true,
        name: "schimbareParola",
        path: "/schimbareParola/",
        component: SchimbareParolaPage,
        breadcrumbs: null,
        viewName: "main"
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "popupAddEdit",
        path: "/popupAddEdit/",
        panel: {
            closeByBackdropClick: false,
            component: PopupAddEdit
        },
        breadcrumbs: null
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "settings",
        path: "/settings/",
        component: SettingsPage,
        viewName: "sidenav",
        breadcrumbs: null
    },
    {
        showBack: true,
        showAppBar: true,
        showTopRight: true,
        name: "colorpicker",
        path: "/colorpicker/",
        component: ColorPickerSetting,
        viewName: "main",
        breadcrumbs: null
    },
    {
        showBack: true,
        showAppBar: true,
        showTopRight: true,
        name: "tranzitie",
        path: "/tranzitie/",
        component: TranzitiePage,
        viewName: "main",
        breadcrumbs: null
    },
    {
        showBack: false,
        showAppBar: false,
        showTopRight: false,
        name: "login",
        path: "/login/",
        viewName: "main",
        breadcrumbs: null,
        component: LoginPage
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "mesajeMainDreapta",
        path: "/mesajeMainDreapta/",
        component: Mesaje,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "mesaje"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "agendaGridMainDreapta",
        path: "/agendaGridMainDreapta/",
        viewName: "MainDreapta",
        component: Grid,
        breadcrumbs: ["agenda", "grid"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "agendaGridMainDreaptaActivitate",
        path: "/agendaGridMainDreaptaActivitate/",
        component: Grid,
        breadcrumbs: ["agenda", "grid"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "graficSalariat",
        path: "/graficSalariat/",
        component: GraficSalariat,
        viewName: "main",
        breadcrumbs: ["agenda", "grafic"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "logActivitateSalariat",
        path: "/logActivitateSalariat/",
        component: LogActivitateSalariatPage,
        viewName: "main",
        breadcrumbs: ["agenda", "log"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "agendaGraficMainDreapta",
        path: "/agendaGraficMainDreapta/",
        component: Grafic2,
        viewName: "MainDreapta",
        breadcrumbs: ["agenda", "grafic"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "agendaTotalTaskuriSalariat",
        path: "/agendaTotalTaskuriSalariat/",
        component: TotalTaskuriSalariatPage,
        viewName: "main",
        breadcrumbs: ["agenda", "total taskuri"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "taskuri",
        path: "/taskuri/",
        component: Tasks,
        viewName: "main",
        breadcrumbs: ["agenda", "taskuri"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "detaliiTask",
        path: "/detaliiTask/",
        component: Task,
        viewName: "main",
        breadcrumbs: ["agenda", "detalii task"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "tehniceMainDreapta",
        path: "/tehniceMainDreapta/",
        component: Tehnice,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "tehnice"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "cheltuieliMainDreapta",
        path: "/cheltuieliMainDreapta/",
        component: Cheltuieli,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "cheltuieli/venituri", "cheltuieli"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "venituriMainDreapta",
        path: "/venituriMainDreapta/",
        component: Venituri,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "cheltuieli/venituri", "venituri"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "fazeRoluriMainDreapta",
        path: "/fazeRoluriMainDreapta/",
        component: FazeSiRoluri,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "faze/roluri"]
    },

    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "descriereProiectMainDreapta",
        path: "/descriereProiectMainDreapta/",
        component: DescriereProiect,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "tehnice", "detalii proiect"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "istoricModificariMainDreapta",
        path: "/istoricModificariMainDreapta/",
        component: IstoricModificari,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "tehnice", "istoric modificari"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "cerinteMainDreapta",
        path: "/cerinteMainDreapta/",
        component: Cerinte,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "tehnice", "cerinte"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "codProiectMainDreapta",
        path: "/codProiectMainDreapta/",
        component: CodProiect,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "tehnice", "cod proiect"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "verificariMainDreapta",
        path: "/verificariMainDreapta/",
        component: Verificari,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "tehnice", "verificari"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "documentareMainDreapta",
        path: "/documentareMainDreapta/",
        component: Documentare,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "tehnice", "documentare"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "helpMainDreapta",
        path: "/helpMainDreapta/",
        component: Help,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "tehnice", "help"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "planificareTaskuriMainDreapta",
        path: "/planificareTaskuriMainDreapta/",
        component: PlanificareTaskuri,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "tehnice", "planificare taskuri"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "toateDocMainDreapta",
        path: "/toateDocMainDreapta/",
        component: ToateDoc,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "tehnice", "toate doc"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "eroriSiRezolvariMainDreapta",
        path: "/eroriSiRezolvariMainDreapta/",
        component: EroriRezolvari,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "tehnice", "erori si rezolvari"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "fazeProiectMainDreapta",
        path: "/fazeProiectMainDreapta/",
        component: FazeProiect,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "faze/roluri", "faze proiect"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "ganttMainDreapta",
        path: "/ganttMainDreapta/",
        component: AlocareSarcini,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "vizualizare", "alocare sarcini", "gantt"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "comparareMainDreapta",
        path: "/comparareMainDreapta/",
        component: Comparare,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "vizualizare", "alocare sarcini", "comparare"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "alocareMainDreapta",
        path: "/alocareMainDreapta/",
        component: Alocare,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "vizualizare", "alocare sarcini", "alocare"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "planificareAnualaMainDreapta",
        path: "/planificareAnualaMainDreapta/",
        component: PlanificareAnuala,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "vizualizare", "planificare anuala"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "vizualizareGeneralaMainDreapta",
        path: "/vizualizareGeneralaMainDreapta/",
        component: VizualizareGenerala,
        viewName: "MainDreapta",
        breadcrumbs: ["proiecte", "vizualizare", "vizualizare generala"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "AlocareTaskuriSalariati",
        path: "/AlocareTaskuri/Salariati/",
        component: ATSalariatiPage,
        viewName: "AlocareTaskuri",
        breadcrumbs: ["AlocareTaskuri", "Salariati"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "AlocareTaskuriDepartamente",
        path: "/AlocareTaskuri/Departamente/",
        component: ATDepartamentePage,
        viewName: "AlocareTaskuri",
        breadcrumbs: ["AlocareTaskuri", "Departamente"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "AlocareTaskuriGrafic",
        path: "/AlocareTaskuri/Grafice/",
        component: ATGraficePage,
        viewName: "AlocareTaskuri",
        breadcrumbs: ["AlocareTaskuri", "Grafice"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "AlocareTaskuriGrafic1",
        path: "/AlocareTaskuri/Grafice1/",
        component: ATGraficePage1,
        viewName: "AlocareTaskuriGrafice",
        breadcrumbs: ["AlocareTaskuri", "Grafice", "1"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "AlocareTaskuriGrafic2",
        path: "/AlocareTaskuri/Grafice2/",
        component: ATGraficePage2,
        viewName: "AlocareTaskuriGrafice",
        breadcrumbs: ["AlocareTaskuri", "Grafice", "2"]
    },
    {
        showBack: false,
        showAppBar: true,
        showTopRight: true,
        name: "ManualInstructiuni",
        path: "/manualInstructiuni/",
        component: ManualInstructiuniPage,
        viewName: "main",
        breadcrumbs: ["Manual Instructiuni"]
    },

    {
        showBack: true,
        showAppBar: true,
        showTopRight: true,
        name: "error",
        path: "(.*)",
        component: NotFoundPage,
        viewName: "main",
        breadcrumbs: null
    }
];

export default routes;