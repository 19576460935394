import React from "react";
import { View, Toolbar, Link } from "framework7-react";

/**
 * @description Randeaza un toolbar de unde se selecteaza cui sa fie alocat taskul, unui salariat sau unui departament
 * @date 06/04/2022
 * @class ATMain
 * @extends {React.Component}
 */
class ATMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            denumire: "ATMain",
            tabLinkActive: 1
        };
        this.refDataGrid = React.createRef();
    }

    render() {
        return (
            <>
                <Toolbar
                    style={{ backgroundColor: "var(--f7-theme-color)" }}
                    tabbar
                    noShadow
                    top>
                    <Link tooltip={"Salariati"} onClick={() => { this.setState({ tabLinkActive: 1 }) }} href="/AlocareTaskuri/Salariati/" tabLinkActive={this.state.tabLinkActive === 1}>Salariati</Link>
                    <Link tooltip={"Departamente"} onClick={() => { this.setState({ tabLinkActive: 2 }) }} href="/AlocareTaskuri/Departamente/" tabLinkActive={this.state.tabLinkActive === 2}>Departamente</Link>
                    {this.$f7.data.rol < 2 ? <Link onClick={() => { this.setState({ tabLinkActive: 3 }) }} href="/AlocareTaskuri/Grafice/" tabLinkActive={this.state.tabLinkActive === 3}>Grafic</Link> : null}
                </Toolbar>
                <View
                    removeElements={true}
                    componentCache={false}
                    id="AlocareTaskuri"
                    style={{
                        minHeight: "50vh", maxHeight: "calc(100% - 34px)"
                    }}
                    name="AlocareTaskuri"
                    onViewInit={(e) => {
                        this.$f7.data.routerAlocareTaskuri = e.router;
                    }}
                    url={"/AlocareTaskuri/Salariati/"}
                ></View>
            </>
        )
    }
}
export default ATMain;