import React from "react";
import { List, ListItem, Row, Col, Fab, Searchbar } from "framework7-react";
import ATDepartamenteGridAlocate from "./ATDepartamenteGridAlocate";
import axios from 'axios';
/**
 * @description Afiseaza o lista cu toate departamentele in partea din stanga si in partea din dreapta toate taskurile de sunt deja alocate pe departamentul respectiv
 * @date 06/04/2022
 * @class ATDepartamente
 * @extends {React.Component}
 */
class ATDepartamente extends React.Component {
  constructor(props) {
    super(props);
    this.inaltime = window.innerHeight - 34 - 28 - 28 - 8 - 50;
    this.listaDepartamente = [...this.$f7.data.Departamente];
    this.listaDepartamente.shift();
    this.state = {
      DepartamentSelectat: null,
      vlData: { items: [] },
      linkApi: "/api/Proiecte",
      linkApiSarcini: "/api/SarciniSalariat",
    };
    this.refGrid = React.createRef();
  }

  /**
   * @description Ia din baza de date sarcina creata, ii pune starea pe 3 si o actualizeaza cu PUT.
   * @date 06/04/2022
   * @function
   * @param {*} idProiect id-ul proiectului de anulat
   * @memberof ATDepartamente
   */
  AnulareAsumareTask = (idProiect) => {
    return new Promise((resolve, reject) => {
      if (idProiect !== undefined && idProiect !== null) {
        this.$f7.methods.openMainDreaptaById(null);
        axios
          .get(
            this.$f7.data.baseURL +
              this.state.linkApiSarcini +
              "/GetSARCINI_SALARIATByProiect/" +
              idProiect
          )
          .then((responseSarcina) => {
            if (responseSarcina.data !== "") {
              var elemBaza;
              responseSarcina.data.forEach((r, index) => {
                if (elemBaza === undefined) {
                  elemBaza = r;
                }
                if (elemBaza.iD_SARCINA < r.iD_SARCINA) {
                  elemBaza = r;
                }
              });
              axios
                .get(
                  this.$f7.data.baseURL +
                    this.state.linkApiSarcini +
                    "/GetSARCINI_SALARIAT/" +
                    elemBaza.iD_SARCINA
                )
                .then((response1) => {
                  var sarcina = response1.data;
                  sarcina.stare = 3;
                  var url =
                    this.$f7.data.baseURL +
                    this.state.linkApiSarcini +
                    "/PutSARCINI_SALARIAT/" +
                    sarcina.iD_SARCINA;

                  axios({
                    data: sarcina,
                    headers: { "Content-Type": "application/json" },
                    method: "put",
                    url: url,
                  })
                    .then((response2) => {
                      resolve(response2);
                    })
                    .catch((error) => {
                      console.log("error", error);
                      reject(error);
                    });
                })
                .catch((error) => {
                  console.log("error", error);
                  reject(error);
                });
            } else {
              resolve(responseSarcina);
            }
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject("Selectati o sarcina");
      }
    });
  };
  /**
   * @description Actualizeaza proiectul in baza de date cu noul departament de care tine.
   * @date 06/04/2022
   * @function
   * @param {*} obj noile valori ale taskului
   * @param {*} id id-ul taskului
   * @memberof ATDepartamente
   */
  UpdateValue = (obj, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(this.$f7.data.baseURL + this.state.linkApi + "/GetPROIECTE/" + id)
        .then((response1) => {
          var obj2 = response1.data;
          Object.keys(obj).map((trasatura) => {
            Object.keys(obj2).map((trasatura2) => {
              if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
                obj2[trasatura2] = obj[trasatura];
              }
              return false;
            });
            return false;
          });

          var url =
            this.$f7.data.baseURL + this.state.linkApi + "/PutPROIECTE/" + id;
          axios({
            data: obj2,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: url,
          })
            .then((response2) => {
              this.AnulareAsumareTask(response1.data.iD_PROIECT)
                .then((responseAnulare) => {
                  resolve(response2);
                })
                .catch((errorAnulare) => {
                  reject(errorAnulare);
                });
            })
            .catch((error) => {
              console.log("error", error);
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        <Row>
          <Col width={20}>
            <Searchbar
              style={{ width: "100%" }}
              searchContainer=".listaDepartamente"
              searchIn=".listaDepartamente-info"
            />
            <List
              virtualList={true}
              virtualListParams={{
                height: 50,
                searchAll: (query, items) => {
                  const found = [];
                  for (let i = 0; i < items.length; i += 1) {
                    var elem = items[i].key;
                    var stringArr = Object.values(elem).join("").toLowerCase();
                    if (stringArr.includes(query.toLowerCase())) {
                      found.push(i);
                    }
                  }
                  return found;
                },
                items: this.listaDepartamente,
                renderExternal: (virtualList, virtualListData) => {
                  this.setState({ vlData: virtualListData });
                },
              }}
              className="listaDepartamente searchbar-found"
              style={{ margin: 0, maxHeight: this.inaltime, overflow: "auto" }}>
              {this.state.vlData.items.map((e, index) => (
                <ListItem
                  key={"listaDepartamente-info-" + index}
                  virtualListIndex={this.listaDepartamente.indexOf(e)}
                  onClick={() => {
                    this.setState({ DepartamentSelectat: e.value }, () => {
                      this.refGrid.current.GetValues();
                    });
                  }}
                  radio
                  checked={this.state.DepartamentSelectat === e.value}
                  value={e.value}
                  checkbox>
                  <span className={"listaDepartamente-info"} slot="title">
                    {e.key}
                  </span>
                </ListItem>
              ))}
            </List>
          </Col>
          <Col width={80}>
            <ATDepartamenteGridAlocate
              ref={this.refGrid}
              idDepartament={this.state.DepartamentSelectat}
            />
          </Col>
        </Row>
        <Fab
          onClick={() => {
            if (this.state.DepartamentSelectat !== null) {
              this.$f7.dialog.confirm(
                "Sunteti sigur ca doriti sa alocati acestui departament sarcina?",
                "Propunere",
                (f) => {
                  //var obj = { id_departament: this.state.DepartamentSelectat };
                  //this.UpdateValue(obj, this.$f7.data.ATidProiect)

                  var obj = { id_departament: this.state.DepartamentSelectat };

                  if (typeof this.$f7.data.ATidProiect === "number") {
                    this.UpdateValue(obj, this.$f7.data.ATidProiect)
                      .then((s) => {
                        this.$f7.preloader.hide();
                        this.$f7.methods.openNotification(
                          "Sarcina alocata",
                          "Succes",
                          () => {
                            this.$f7.popup.close(".PopupDinamic");
                          }
                        );
                      })
                      .catch((error) => {
                        this.$f7.preloader.hide();
                        this.$f7.methods.openNotification(
                          error,
                          "Eroare",
                          () => {
                            this.$f7.popup.close(".PopupDinamic");
                          }
                        );
                        console.log("Eroare:", error);
                      });
                  } else {
                    this.$f7.data.ATidProiect.reduce((promise, item) => {
                      return promise.then(() => {
                        return this.UpdateValue(obj, item)
                          .then((s) => {})
                          .catch((err) => {});
                      });
                    }, Promise.resolve())
                      .then(() => {
                        this.$f7.preloader.hide();
                        this.$f7.methods.openNotification(
                          "Sarcini alocate",
                          "Succes",
                          () => {
                            this.$f7.popup.close(".PopupDinamic");
                          }
                        );
                      })
                      .catch((error) => {
                        this.$f7.preloader.hide();
                        this.$f7.methods.openNotification(
                          error,
                          "Eroare",
                          () => {
                            this.$f7.popup.close(".PopupDinamic");
                          }
                        );
                        console.log("Eroare:", error);
                      });
                  }
                }
              );
            } else {
              this.$f7.methods.openNotification(
                "Trebuie selectat un departament!",
                "Ups"
              );
            }
          }}
          style={{ bottom: "0px" }}
          position="center-bottom"
          slot="fixed"
          text="Aloca"
          color="red"></Fab>
      </div>
    );
  }
}
export default ATDepartamente;