import React from "react";
import {
    Row,
    Col,
    Searchbar,
    Stepper,
    Preloader,
    ListInput,
    Icon,
    List,
    Popover,
    Link,
    View,
    ListItem
} from "framework7-react";
import moment from "moment";
import axios from "axios";
import {
    Chart,
    Series,
    CommonSeriesSettings,
    Export,
    Tooltip
} from "devextreme-react/chart";

 /**
  * @description Afiseaza un grafic cu toate taskurile lucrate intr-un interval selectat si cat s-a estimat ca se va lucra la acel task, cand se da click pe un task din grafic se afiseaza un popup cu mai multe informatii precum numele taskului, persoana care a lucrat la el, cat s-a lucrat.
  * @date 06/04/2022
  * @class EstimatLucratClienti
  * @extends {React.PureComponent}
  */
 class EstimatLucratClienti extends React.PureComponent {
   constructor(props) {
     super(props);

     this.state = {
       checkedListeGoale: false,
       coordinates: null,
       targetPopover: null,
       informatiiPopover: null,
       linkApiRealizari: "/api/RealizareSarcini",
       linkApiSarcini: "/api/SarciniSalariat",
       height: window.innerHeight - 110,
       heightItem: 30,
       width: window.innerWidth - 10,
       listaProiecte: [],
       listaProiectePagina: [],
       listaProiecteSearch: [],
       pagina: 1,
       paginaMax: 1,
       dataLoaded: false,
       searchActive: false,
       dataDeLa: [moment(Date.now()).format()],
       dataPanaLa: [moment(Date.now()).format()],
     };
   }

   componentDidMount = () => {
     this.GetValues();
   };
   /**
    * @description Deschide un popup in care afiseaza informatii despre taskul selectat cat si doua butoane care deschid interfetele de descriere task si activitate task din router
    * @date 06/04/2022
    * @function
    * @param {*} id id-ul proiectului
    * @param {*} pagina pagina de trebuie randata
    * @memberof EstimatLucratClienti
    */
   openPopup = (id, pagina) => {
     var proiect = this.$f7.data.allProiecte.find((r) => {
       return r.iD_PROIECT === id;
     });
     if (proiect !== undefined) {
       this.$f7.data.Proiect = proiect;
       this.$f7.views.main.router.navigate("/popupDinamic/", {
         props: {
           title: (
             <Link
               tooltip={
                 this.state.informatiiPopover.proiect
                   ? this.state.informatiiPopover.proiect.comentariu
                   : ""
               }>
               {this.state.informatiiPopover.denumireTask}
             </Link>
           ),
           disableClose: false,
           component: (
             <View
               removeElements={true}
               componentCache={false}
               onViewInit={(e) => {
                 this.$f7.data.routerDreapta = e.router;
                 setTimeout(() => {
                   if (pagina !== undefined && pagina === 1) {
                     this.$f7.data.Grid.setState({
                       activeTab:
                         "tab-Grid-Descriere" +
                         this.$f7.data.Grid.state.denumireTab,
                     });
                   }
                   if (pagina !== undefined && pagina === 2) {
                     this.$f7.data.Grid.setState({
                       activeTab:
                         "tab-Grid-TimpLucrat" +
                         this.$f7.data.Grid.state.denumireTab,
                     });
                   }
                 }, 350);
               }}
               id="oreLucratePeZiSheet"
               tabDeschis={"tab-Grid-TimpLucrat"}
               name="oreLucratePeZiSheet"
               url={"/agendaGridMainDreaptaActivitate/"}
             />
           ),
         },
       });
     }
   };
   /**
    * @description Ia valorile necesare pentru randare de la endpointul „/api/OreLucrateZi/GetLucratEstimatClient” caruia i se transmit ca parametrii data de start si de sfarsit.
    * @date 06/04/2022
    * @function
    * @memberof EstimatLucratClienti
    */
   GetValues = () => {
     this.setState({ dataLoaded: false }, () => {
       let initialStartData = new Date(this.state.dataDeLa[0]);
       let initialEndData = new Date(this.state.dataPanaLa[0]);
       const dataStart =
         initialStartData.getDate() +
         "-" +
         (initialStartData.getMonth() + 1) +
         "-" +
         initialStartData.getFullYear().toString().substring(2);
       const dataEnd =
         initialEndData.getDate() +
         "-" +
         (initialEndData.getMonth() + 1) +
         "-" +
         initialEndData.getFullYear().toString().substring(2);

       axios
         .get(
           "/api/OreLucrateZi/GetLucratEstimatClient" +
             "/" +
             dataStart +
             "/" +
             dataEnd
         )
         .then((r) => {
           var data = r.data;
           console.log("data", data);
           var lista = data.lista;
           var labels = [];
           lista.map((client) => {
             client.listaProiecteDechise.map((pro) => {
               var timpLucrat = pro.timp_l;
               var timpEstimat = pro.timp_plan_d;
               var idProiect = pro.id_proiecte;
               var labelToAddDeschisEstimat =
                 client.idFirma + "DeschisEstimat" + idProiect;
               var labelToAddDeschisLucrat =
                 client.idFirma + "DeschisLucrat" + idProiect;
               client[labelToAddDeschisEstimat] = timpEstimat;
               client[labelToAddDeschisLucrat] = timpLucrat;
               if (
                 labels.find((x) => {
                   return x === labelToAddDeschisEstimat;
                 }) === undefined
               ) {
                 labels.push(labelToAddDeschisEstimat);
               }
               if (
                 labels.find((x) => {
                   return x === labelToAddDeschisLucrat;
                 }) === undefined
               ) {
                 labels.push(labelToAddDeschisLucrat);
               }
               return null;
             });
             client.listaProiecteInchise.map((pro) => {
               var timpLucrat = pro.timp_l;
               var timpEstimat = pro.timp_plan_d;
               var idProiect = pro.id_proiecte;
               var labelToAddInchisEstimat =
                 client.idFirma + "InchisEstimat" + idProiect;
               var labelToAddInchisLucrat =
                 client.idFirma + "InchisLucrat" + idProiect;
               client[labelToAddInchisEstimat] = timpEstimat;
               client[labelToAddInchisLucrat] = timpLucrat;
               if (
                 labels.find((x) => {
                   return x === labelToAddInchisEstimat;
                 }) === undefined
               ) {
                 labels.push(labelToAddInchisEstimat);
               }
               if (
                 labels.find((x) => {
                   return x === labelToAddInchisLucrat;
                 }) === undefined
               ) {
                 labels.push(labelToAddInchisLucrat);
               }
               return null;
             });
             return null;
           });
           labels.map((l) => {
             lista.map((c) => {
               if (!c[l]) {
                 c[l] = null;
               }
               return null;
             });
             return null;
           });
           this.setState(
             {
               labels: labels,
               listaProiecte: lista,
             },
             () => {
               this.updateChart();
               console.log("state", this.state);
             }
           );
         });
     });
   };
   /**
    * @description actualizeaza datasource-ul graficului
    * @function
    * @date 06/04/2022
    * @memberof EstimatLucratClienti
    */
   updateChart = () => {
     let lista = this.state.searchActive
       ? this.state.listaProiecteSearch
       : this.state.listaProiecte;

     console.log("lista", lista);

     if (this.state.checkedListeGoale) {
       lista = lista.filter((r) => {
         return (
           r.listaProiecteDechise.filter((f) => {
             return f.timp_l > 0 || f.timp_plan_d > 0;
           }).length > 0 ||
           r.listaProiecteInchise.filter((f) => {
             return f.timp_l > 0 || f.timp_plan_d > 0;
           }).length > 0
         );
       });
     }

     var labels = [];
     var li = lista.slice((this.state.pagina - 1) * 8, this.state.pagina * 8);

     li.map((client) => {
       client.listaProiecteDechise.map((pro) => {
         var timpLucrat = pro.timp_l;
         var timpEstimat = pro.timp_plan_d;
         var idProiect = pro.id_proiecte;
         var labelToAddDeschisEstimat =
           client.idFirma + "DeschisEstimat" + idProiect;
         var labelToAddDeschisLucrat =
           client.idFirma + "DeschisLucrat" + idProiect;
         client[labelToAddDeschisEstimat] = timpEstimat;
         client[labelToAddDeschisLucrat] = timpLucrat;
         if (
           labels.find((x) => {
             return x === labelToAddDeschisEstimat;
           }) === undefined
         ) {
           labels.push(labelToAddDeschisEstimat);
         }
         if (
           labels.find((x) => {
             return x === labelToAddDeschisLucrat;
           }) === undefined
         ) {
           labels.push(labelToAddDeschisLucrat);
         }
         return null;
       });
       client.listaProiecteInchise.map((pro) => {
         var timpLucrat = pro.timp_l;
         var timpEstimat = pro.timp_plan_d;
         var idProiect = pro.id_proiecte;
         var labelToAddInchisEstimat =
           client.idFirma + "InchisEstimat" + idProiect;
         var labelToAddInchisLucrat =
           client.idFirma + "InchisLucrat" + idProiect;
         client[labelToAddInchisEstimat] = timpEstimat;
         client[labelToAddInchisLucrat] = timpLucrat;
         if (
           labels.find((x) => {
             return x === labelToAddInchisEstimat;
           }) === undefined
         ) {
           labels.push(labelToAddInchisEstimat);
         }
         if (
           labels.find((x) => {
             return x === labelToAddInchisLucrat;
           }) === undefined
         ) {
           labels.push(labelToAddInchisLucrat);
         }
         return null;
       });
       return null;
     });
     labels.map((l) => {
       li.map((c) => {
         if (!c[l]) {
           c[l] = null;
         }
         return null;
       });
       return null;
     });

     this.setState(
       {
         dataLoaded: false,
         listaProiectePagina: li,
         labels: labels,
         paginaMax: Math.ceil(this.state.listaProiecte.length / 8),
       },
       () => {
         this.setState({
           dataLoaded: true,
         });
       }
     );
   };
   /**
    * @description  Face cautare in datasource si rerandeaza elementele de le gaseste
    * @date 06/04/2022
    * @param {*} value ce valoare sa caute
    * @param {*} baza baza in care cauta
    * @memberof EstimatLucratClienti
    */
   search = (value, baza) => {
     let toSearch = value.toString().toLowerCase().replace(/\s/g, "");

     let result = baza.filter((elemBaza) => {
       let res = false;
       let valFinal = elemBaza.numeFirma
         .toString()
         .toLowerCase()
         .replace(/\s/g, "");

       if (valFinal.includes(toSearch)) {
         res = true;
       }

       return res;
     });

     this.setState(
       {
         listaProiecteSearch: result,
         pagina: 1,
         searchActive: true,
       },
       () => {
         this.updateChart();
       }
     );
   };

   /**
    * @description transforma valorile din datagrid(timpul lucrat) intr-un string mai usor de inteles (h : m : s
    * @date 06/04/2022
    * @function
    * @param {*} seconds
    * @memberof EstimatLucratClienti
    */
   ConvertToFriendlyTime = (seconds) => {
     const days = Math.floor(seconds / (8 * 3600));

     seconds = seconds % (8 * 3600);
     const hours = Math.floor(seconds / 3600);

     seconds = seconds % 3600;
     const minutes = Math.floor(seconds / 60);

     seconds = seconds % 60;

     let output;

     if (days) {
       if (hours) {
         if (minutes) {
           output =
             (days > 1 ? days + "z " : days + " z") +
             ", " +
             (hours > 1 ? hours + "h " : hours + "h ") +
             " : " +
             (minutes > 1 ? minutes + "m " : minutes + "m ");
         } else {
           output =
             (days > 1 ? days + "z " : days + " z") +
             " : " +
             (hours > 1 ? hours + "h " : hours + "h ");
         }
       } else {
         if (minutes) {
           output =
             (days > 1 ? days + "z " : days + " z") +
             " : " +
             (minutes > 1 ? minutes + "m " : minutes + "m ");
         } else {
           output = days > 1 ? days + "z " : days + " z";
         }
       }
     } else {
       if (hours) {
         if (minutes) {
           output =
             (hours > 1 ? hours + "h " : hours + "h ") +
             " : " +
             (minutes > 1 ? minutes + "m " : minutes + "m ");
         } else {
           output = hours > 1 ? hours + "h " : hours + "h ";
         }
       } else {
         if (minutes) {
           output = minutes > 1 ? minutes + "m " : minutes + "m ";
         } else {
           output = "0";
         }
       }
     }

     return "" + output;
   };
   /**
    * @description Este o functie ce returneaza un tooltip customizat pentru acest grafic sa contina mai multe informatii decat cel normal
    * @function
    * @date 06/04/2022
    * @param {*} { active, payload, label }
    * @memberof EstimatLucratClienti
    */
   CustomTooltip = ({ active, payload, label }) => {
     if (active && payload && payload.length) {
       return (
         <div className="custom-tooltip">
           <p className="label">{`${label} : ${payload[0].value}`}</p>
         </div>
       );
     }

     return null;
   };

   render() {
     return (
       <Row>
         <Col style={{ position: "relative" }}>
           <div
             style={{
               position: "relative",
               overflow: "hidden",
               display: "flex",
             }}>
             <Popover className="popoverEstimatLucratClienti">
               {this.state.informatiiPopover !== null ? (
                 <>
                   <Link
                     popoverClose
                     tooltip={
                       this.state.informatiiPopover.proiect
                         ? this.state.informatiiPopover.proiect.comentariu
                         : ""
                     }
                     style={{
                       fontSize: "small",
                       width: "100%",
                       margin: "6px 0px",
                     }}
                     onClick={() => {
                       this.openPopup(
                         this.state.informatiiPopover.proiect.id_proiecte,
                         1
                       );
                     }}>
                     {this.state.informatiiPopover.denumireTask}
                   </Link>
                   <p>
                     <span className={"spanOreLucreate"}>Tip:</span>
                     {this.state.informatiiPopover.tip}
                   </p>
                   <p>
                     <span className={"spanOreLucreate"}>Stare:</span>
                     {this.state.informatiiPopover.stare}
                   </p>
                   <p>
                     <span className={"spanOreLucreate"}>Client:</span>
                     {this.state.informatiiPopover.numeClient}
                   </p>
                   <p>
                     <span className={"spanOreLucreate"}>Angajat:</span>
                     {this.state.informatiiPopover.numeSalariat}
                   </p>
                   <p>
                     <span className={"spanOreLucreate"}>Deadline:</span>
                     {moment(this.state.informatiiPopover.deadline).format(
                       "'YY-MM-DD HH:mm"
                     )}
                   </p>
                   <p>
                     <span className={"spanOreLucreate"}>Lucrat:</span>
                     {this.state.informatiiPopover.lucrat}
                   </p>{" "}
                   <p>
                     <span className={"spanOreLucreate"}>Estimat:</span>
                     {this.state.informatiiPopover.estimat}
                   </p>
                   {this.state.informatiiPopover.proiect ? (
                     <Link
                       popoverClose
                       tooltip={"Cere detalii"}
                       style={{
                         fontSize: "small",
                         width: "100%",
                         margin: "6px 0px",
                       }}
                       onClick={() => {
                         var id =
                           this.state.informatiiPopover.proiect.id_proiecte;
                         this.$f7.methods.openMesaje(null, id);
                       }}>
                       Cere detalii
                     </Link>
                   ) : null}
                   {this.state.informatiiPopover.proiect ? (
                     <Link
                       popoverClose
                       tooltip={"Activitate task"}
                       style={{
                         fontSize: "small",
                         width: "100%",
                         margin: "6px 0px",
                       }}
                       onClick={() => {
                         this.openPopup(
                           this.state.informatiiPopover.proiect.id_proiecte,
                           2
                         );
                       }}>
                       Activitate task
                     </Link>
                   ) : null}
                 </>
               ) : null}
             </Popover>

             <List style={{ width: 350 }} id={"ListaEstimatLucratClienti"}>
               <ListItem
                 key={"EstimatLucratClientiCheckListeGoale"}
                 checkbox
                 checked={this.state.checkedListeGoale}
                 onChange={() => {
                   this.setState(
                     {
                       checkedListeGoale: !this.state.checkedListeGoale,
                     },
                     () => {
                       this.updateChart();
                     }
                   );
                 }}
                 title={"Doar lucrate"}
                 floatingLabel
               />
               <ListInput
                 key={"OreLucratePeZi3"}
                 label="Interval"
                 type="datepicker"
                 calendarParams={{
                   rangePicker: true,
                   dateFormat: "dd.mm.yyyy",
                   closeOnSelect: true,
                   on: {
                     closed: (e) => {
                       const val = e.value;
                       this.setState(
                         {
                           dataDeLa: [val[0]],
                           dataPanaLa: [val[1]],
                         },
                         () => {
                           this.GetValues();
                         }
                       );
                     },
                   },
                 }}
                 value={[this.state.dataDeLa[0], this.state.dataPanaLa[0]]}
                 floatingLabel
                 placeholder="De la">
                 <Icon
                   color={"var(--f7-theme-color)"}
                   size={20}
                   material="today"
                   slot="content-start"
                   style={{ left: 0, top: 0 }}
                 />
               </ListInput>
             </List>

             <Searchbar
               className={"searchBarEstimatLucratProiecte"}
               onClickDisable={() => {
                 this.setState(
                   {
                     pagina: 1,
                     searchActive: false,
                   },
                   () => {
                     this.updateChart();
                   }
                 );
               }}
               onSearchbarEnable={() => {}}
               onSearchbarDisable={() => {}}
               onInput={(e) => {
                 this.search(e.target.value, this.state.listaProiecte);
               }}
               borderColor={"transparent"}
               style={{
                 height: 34,
                 width:
                   "calc(100% - 130px)" /*, filter: "drop-shadow(2px 0px 14px gray)"*/,
                 float: "left",
                 margin: 0,
                 marginTop: 20,
               }}
             />

             <div
               style={{
                 position: "relative",
                 marginTop: 15,
                 marginRight: 8,
                 float: "right",
               }}>
               <span
                 style={{
                   position: "absolute",
                   top: 0,
                   left: 0,
                   right: 0,
                   textAlign: "center",
                 }}>
                 {this.state.paginaMax}{" "}
                 {this.state.paginaMax > 1 ? "pagini" : "pagina"}
               </span>
               <Stepper
                 style={{
                   height: 18,
                   marginTop: 15,
                 }}
                 onStepperPlusClick={(e) => {
                   if (this.state.pagina < this.state.paginaMax) {
                     this.setState(
                       {
                         pagina: this.state.pagina + 1,
                       },
                       () => {
                         this.updateChart();
                       }
                     );
                   }
                 }}
                 onStepperMinusClick={(e, f) => {
                   if (this.state.pagina > 1) {
                     this.setState(
                       {
                         pagina: this.state.pagina - 1,
                       },
                       () => {
                         this.updateChart();
                       }
                     );
                   }
                 }}
                 onInput={(e) => {
                   const val = e.target.value;

                   if (val !== "") {
                     const pag = parseInt(val);

                     if (pag >= 1 && pag <= this.state.paginaMax) {
                       this.setState(
                         {
                           pagina: pag,
                         },
                         () => {
                           this.updateChart();
                         }
                       );
                     }
                   }
                 }}
                 small
                 value={this.state.pagina}
                 min={1}
                 max={this.state.paginaMax}
                 manualInputMode={true}
               />
             </div>
           </div>

           {this.state.dataLoaded ? (
             <Chart
               size={{ height: this.state.height }}
               rotated={true}
               legend={{ visible: false }}
               id="chart"
               title="Activitate"
               dataSource={this.state.listaProiectePagina}
               argumentAxis={{
                 label: {
                   customizeText: (val) => {
                     return val.value.slice(0, 16);
                   },
                   wordWrap: "breakWord",
                 },
               }}
               onPointClick={(e, f) => {
                 var coordinates = e.target.getCenterCoord();
                 var data = e.target.data;
                 var denumireValue = e.target.series.name;
                 if (denumireValue) {
                   var tip = "Estimat";
                   var stare = "Inchis";
                   var listaDenumire = denumireValue.split("Estimat");
                   if (listaDenumire.length === 1) {
                     listaDenumire = denumireValue.split("Lucrat");
                     tip = "Lucrat";
                   }
                   var idProiect = parseInt(listaDenumire[1]);

                   listaDenumire = denumireValue.split("Inchis");
                   if (listaDenumire.length === 1) {
                     listaDenumire = denumireValue.split("Deschis");
                     stare = "Deschis";
                   }
                   var idClient = parseInt(listaDenumire[0]);

                   console.log(tip, stare, idProiect, idClient);

                   var client = this.$f7.data.Firme.find((x) => {
                     return x.value === idClient;
                   });
                   var pro = data.listaProiecteDechise.find((x) => {
                     return x.id_proiecte === idProiect;
                   });
                   if (!pro) {
                     pro = data.listaProiecteInchise.find((x) => {
                       return x.id_proiecte === idProiect;
                     });
                   }
                   var sal = this.$f7.data.Salariati.find((x) => {
                     return x.value === pro.id_dezvoltator;
                   });

                   var informatiiPopover = {
                     numeClient: client.key,
                     numeSalariat: sal ? sal.key : "",
                     deadline: pro.deadline,
                     inceput: 0,
                     sfarsit: 0,
                     durata: 0,
                     timpTotalSalariat: 0,
                     timpTotalTask: 0,
                     comentariu: 0,
                     observatie: 0,
                     pornit: 0,
                     realizare: null,
                     denumireTask: pro.denumire,
                     proiect: pro,
                     lucrat: pro.timp_l,
                     estimat: pro.timp_plan_d,
                     tip: "Timp " + tip,
                     stare: stare,
                   };
                   this.setState(
                     {
                       informatiiPopover: informatiiPopover,
                     },
                     () => {
                       var popoverEstimatLucratClienti = this.$f7.popover.get(
                         ".popoverEstimatLucratClienti"
                       );
                       console.log(popoverEstimatLucratClienti);
                       popoverEstimatLucratClienti.params.targetX =
                         coordinates.x;
                       popoverEstimatLucratClienti.params.targetY =
                         coordinates.y;
                       popoverEstimatLucratClienti.open();
                     }
                   );
                 }
               }}>
               <CommonSeriesSettings
                 argumentField="numeFirma"
                 type="stackedBar"
               />
               {this.state.labels.map((label) => (
                 <Series
                   key={"label" + label}
                   valueField={label}
                   name={label}
                   stack={label.includes("Estimat") ? "Estimat" : "Lucrat"}
                 />
               ))}
               <Export enabled={true} />
               <Tooltip enabled={true} />
             </Chart>
           ) : (
             <div
               style={{
                 position: "absolute",
                 width: "40px",
                 height: "40px",
                 top: "calc(50vh - 34px)",
                 left: "50%",
                 transform: "translateX(-50%)",
               }}>
               <Preloader size={40} color={"var(--f7-theme-color)"} />
             </div>
           )}
         </Col>
       </Row>
     );
   }
 }
export default EstimatLucratClienti;