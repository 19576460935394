import React from "react";
import axios from "axios";
import moment from "moment";
import DataGrid from "../../Datagrid/DataGrid";
import { ListItem, List, ListInput, Icon } from "framework7-react";

/**
 * @description Randeaza o interfata de afiseaza tot istoricul unui task, cat s-a lucrat la el etc
 * @date 08/04/2022
 * @class IstoricTaskGrid
 * @extends {React.Component}
 */
class IstoricTaskGrid extends React.Component {
  constructor(props) {
    super(props);
    this.height = window.innerHeight - 100;
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;

    this.state = {
      rowSelected: null,
      items: [
        {
          id: 1,
          group: 1,
          title: "",
          start_time: moment().add(-0.5, "hour"),
          end_time: moment().add(5, "hour"),
        },
        {
          id: 2,
          group: 2,
          title: "",
          start_time: moment(),
          end_time: moment().add(1, "hour"),
        },
        {
          id: 3,
          group: 2,
          title: "",
          start_time: moment().add(2, "hour"),
          end_time: moment().add(3, "hour"),
        },
      ],
      groups: [
        { id: 1, title: "Total", height: 34 },
        { id: 2, title: "Lucrat", height: 34 },
      ],
      dataSource: [],
      dataLoaded: false,
      inputs: [
        { key: "Data", keyBaza: "data", tip: "datebox" },
        { key: "DataFinal", keyBaza: "dataFinal", tip: "datebox" },
        {
          key: "Salariat",
          keyBaza: "salariatCurent",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
        {
          key: "SalariatPropunere",
          keyBaza: "salariatVechi",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
        { key: "Comentariu", keyBaza: "comentariu" },
        { key: "ComentariuPV", keyBaza: "comentariuPV" },
        { key: "ComentariuPlus", keyBaza: "comentariu1" },
        { key: "Stare", keyBaza: "comentariuStare" },
        { key: "Durata", keyBaza: "durata" },
        { key: "IdTask", keyBaza: "idTask", tip: "number" },
        { key: "IdClient", keyBaza: "idClient", tip: "number" },
        { key: "RefTask", keyBaza: "refTask", tip: "number" },
      ],
    };
    this.refDataGrid = React.createRef();
    this.timelineRef = React.createRef();
  }

  /**
   * @description Ia lista de istoric a proiectului curent selectat
   * @function
   * @date 08/04/2022
   * @memberof IstoricTaskGrid
   */
  GetValues = () => {
    this.setState({ dataLoaded: false }, () => {
      var url = "/api/Proiecte/GetIstoricProiect";
      var dateRequest = {
        tipRequest: 1,
        idProiect: this.$f7.data.Proiect.iD_PROIECT,
        dataStart: moment("1980-01-01T01:00:00Z").format(),
        dataEnd: moment().format(),
      };

      axios({
        data: dateRequest,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      }).then((r) => {
        var date = r;

        date.data.map((eveniment) => {
          eveniment = this.$f7.methods.restructurareBaza(
            eveniment,
            this.state.inputs,
            "key"
          );
          return false;
        });

        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

        this.setState({
          dataLoaded: true,
          dataSource: baza,
          // items: f.dataSource,
          //groups: f.groups
        });
      });
    });
  };
  componentDidMount = () => {
    this.GetValues();
  };

  generateGroups = (date, newGroups, popoverName, useRef) => {
    var arr = [];
    var informatiiPopover = {};
    date.map((d) => {
      var idForGroup = d[useRef];
      var pro = this.$f7.data.allProiecte.find((pro) => {
        return pro.iD_PROIECT === idForGroup;
      });
      if (pro) {
        //GENERARE newGroups
        var lista = newGroups.find((r) => {
          return r.id === idForGroup;
        });
        if (!lista) {
          var title = pro.denumire;
          if (useRef === "IdClient") {
            var f = this.$f7.data.Firme.find((v) => {
              return v.value === idForGroup;
            });
            if (f) {
              title = f.key;
            }
          }
          newGroups.push({
            id: idForGroup,
            title: title,
            mantis: pro.id_tip_proiect === 55,
            stadiu: pro.iD_STADIU,
          });
        }

        //GENERARE sursa grafic
        var rand = Math.random() * 360;
        var colorString = "hsl(" + rand + ", 100%, 60%)";
        arr.push({
          id: d.IdTask,
          group: idForGroup,
          start_time: moment(d.Data),
          end_time: moment(d.DataFinal),
          title: "",
          canMove: false,
          canResize: false,
          itemProps: {
            style: {
              background: colorString,
              border: "1px solid " + colorString,
            },
            onClick: (e) => {
              var t = e.currentTarget;
              console.log("pro", pro);
              var sal = this.$f7.data.Salariati.find((x) => {
                return x.value === pro.id_dezvoltator;
              });
              var client = this.$f7.data.Firme.find((x) => {
                return x.value === pro.id_client;
              });
              informatiiPopover = {
                numeClient: client.key,
                numeSalariat: sal ? sal.key : "",
                deadline: pro.deadline,
                inceput: moment(d.Data).format(),
                sfarsit: moment(d.DataFinal).format(),
                durata: d.Durata,
                timpTotalSalariat: 0,
                timpTotalTask: 0,
                comentariu: d.Comentariu,
                observatie: 0,
                pornit: d.Stare === 2,
                realizare: null,
                denumireTask: pro.denumire,
                proiect: pro,
                lucrat: pro.timp_l,
                estimat: pro.timp_plan_d,
              };
              var popoverToOpen = this.$f7.popover.get("." + popoverName);
              if (popoverToOpen) {
                popoverToOpen.open(t);
              }
            },
          },
        });
        return false;
      }
      return null;
    });
    return {
      groups: newGroups,
      dataSource: arr,
      informatiiPopover: informatiiPopover,
    };
  };

  render() {
    console.log("this.height:", this.height);

    return (
      <div style={{ height: '100%', overflowY: 'auto' }}>
        <div>
          <DataGrid
            dataLoaded={this.state.dataLoaded}
            refresh={this.GetValues}
            name={"IstoricModificariTaskGrid"}
            ref={this.refDataGrid}
            renderUpdate={false}
            renderDelete={false}
            renderAdd={false}
            maxHeight={this.height}
            dataSource={this.state.dataSource}
            inputs={this.state.inputs}
            onRowClick={(rowData, index, e) => {
              this.setState({
                rowSelected: rowData
              })
            }}
          />

          {this.state.rowSelected !== null &&
            <List
              // form
              noChevron
              noHairlines
              noHairlinesBetween
              noHairlinesBetweenMd
              noHairlinesMd
            >
              <ListInput
                inputStyle={{ minHeight: 120 }}
                inputmode={"text"}
                key={"InputComentariuIstoricGrid"}
                floatingLabel
                label={"Comentariu"}
                type={"textarea"}
                placeholder={"Comentariu"}
                value={this.state.rowSelected.rowData.Comentariu}
                readonly={true}
              >
                <Icon
                  slot="content-start"
                  className={"datagridIcon"}
                  size={20}
                  material="create"
                />
              </ListInput>

              <ListInput
                inputStyle={{ minHeight: 120 }}
                inputmode={"text"}
                key={"InputComentariuPlusIstoricGrid"}
                floatingLabel
                label={"Comentariu Plus"}
                type={"textarea"}
                placeholder={"Comentariu Plus"}
                value={this.state.rowSelected.rowData.ComentariuPlus}
                readonly={true}
              >
                <Icon
                  slot="content-start"
                  className={"datagridIcon"}
                  size={20}
                  material="create"
                />
              </ListInput>

              <ListInput
                inputStyle={{ minHeight: 120 }}
                inputmode={"text"}
                key={"InputComentariuPVIstoricGrid"}
                floatingLabel
                label={"Comentariu PV"}
                type={"textarea"}
                placeholder={"Comentariu PV"}
                value={this.state.rowSelected.rowData.ComentariuPV}
                readonly={true}
              >
                <Icon
                  slot="content-start"
                  className={"datagridIcon"}
                  size={20}
                  material="create"
                />
              </ListInput>
            </List>
          }
        </div>
      </div>
    );
  }
}
export default IstoricTaskGrid;
