import React from "react";
import { Page } from "framework7-react";
import RepartitieSalariatiInPerioada from "../Componente/RepartitieSalariatiInPerioada";

class RepartitieSalariatiInPerioadaPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { denumire: "Repartitie salariati in perioada" };
    }
    render() {
        return (
            <Page
                className={"themeBackground"}
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
            >

                <div className="ptr3">
                    <RepartitieSalariatiInPerioada />
                </div>
            </Page>
        );
    }
}
export default RepartitieSalariatiInPerioadaPage;