import React from "react";
import {
    Page,
    Icon,
    Card,
    CardContent,
    Row,
    Col,
    ListItem,
    ListInput,
    Button,
    List
} from "framework7-react";
import moment from "moment";
import DataGrid from "./../Datagrid/DataGrid";
import * as inputs from "./../../../inputs";
import axios from "axios";

/**
 * @description Pagina de facturare
 * @date 06/04/2022
 * @class FacturarePage
 * @extends {React.PureComponent}
 */
class FacturarePage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.height = window.innerHeight;

        this.state = {
            dataLoaded: false,
            pagina: "Facturare",
            denumire: Math.random()
                .toString(36)
                .substring(7)
                .toString(),
            height: window.innerHeight - 44,
            linkApi: "/api/Facturare",
            dataSource: [],
            hiddenDataSource: [],
            clientiArray: this.$f7.data.Firme,
            clientul: 0,
            cursBNR: parseFloat(this.$f7.data.Euro),
            cursCalc: parseFloat(this.$f7.data.Euro),
            deLa: [moment(Date.now()).format()],
            panaLa: [moment(Date.now()).format()],
            doarNefacturate: false,
            rowSelectat: null,
            showOptions: false
        };

        //this.refDataGrid = React.createRef();
    }

    /**
     * @description Ia lista tuturor inregistrarilor de tip facturare
     * @function
     * @date 07/04/2022
     * @memberof FacturarePage
     */
    GetValues = () => {
        //'mm-dd-yy'
        let initialStartData = new Date(this.state.deLa[0]);
        let initialEndData = new Date(this.state.panaLa[0]);
        const dataStart =
            initialStartData.getMonth() +
            1 +
            "-" +
            initialStartData.getDate() +
            "-" +
            initialStartData
                .getFullYear()
                .toString()
                .substring(2);
        const dataEnd =
            initialEndData.getMonth() +
            1 +
            "-" +
            initialEndData.getDate() +
            "-" +
            initialEndData
                .getFullYear()
                .toString()
                .substring(2);

        this.setState({
            dataLoaded: false
        });

        axios
            .get(
                this.$f7.data.baseURL +
                this.state.linkApi +
                "/" +
                dataStart +
                "/" +
                dataEnd +
                "/" +
                this.state.clientul +
                "/" +
                this.state.doarNefacturate +
                "/" +
                this.state.cursCalc
            )
            .then(response => {
                let change = response;

                for (let item in change.data) {
                    change.data[item]["Proiect"] = change.data[item].proiect;
                    change.data[item]["DenumireTask"] = change.data[item].denumire;
                    change.data[item]["Facturat"] = change.data[item].facturat;
                    change.data[item]["Salariat"] = change.data[item].numesal;
                    change.data[item]["Operatie"] = change.data[item].operatie;
                    change.data[item]["TimpLucratInOre"] = change.data[item].timp;
                    change.data[item]["TarifOrar"] = change.data[item].tarif;
                    change.data[item]["Suma"] = change.data[item].suma;
                    change.data[item]["DataCreare"] = change.data[item].data_creare;
                    change.data[item]["DataAlocare"] = change.data[item].data_alocarii;
                    change.data[item]["DataFinalizare"] = change.data[item].data_final;
                    change.data[item]["Durata"] = change.data[item].durata;
                }

                let baza = this.$f7.methods.reconstructieBaza(change, inputs.Facturare);

                this.setState(
                    {
                        dataSource: baza.length === 0 ? [] : baza,
                        hiddenDataSource: baza.length === 0 ? [] : response.data
                    },
                    () => {
                        setTimeout(() => {
                            this.setState(
                                {
                                    dataLoaded: true
                                },
                                () => {
                                    //this.refDataGrid.current.setRenderItems(baza)
                                }
                            );
                        }, 400);
                    }
                );
            })
            .catch(error => {
                this.setState(
                    {
                        dataSource: [],
                        hiddenDataSource: [],
                        dataLoaded: true
                    },
                    () => {
                        this.$f7.dialog.confirm(
                            "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                            "Ups...",
                            () => {
                                this.GetValues();
                            },
                            () => { }
                        );
                    }
                );

                console.log("error", error);
            });
    };

    componentDidMount = () => {
        this.GetValues();
    };

    render() {
        return (
            <Page
                onPageInit={() => {
                    this.$f7.data.paginaCurenta = this;
                }}
                id={this.state.pagina}
                style={{
                    overflow: "auto",
                    textAlign: "center",
                    backgroundColor: "var(--f7-theme-color)"
                }}
            >
                <div className="ptr3" style={{ background: "white" }}>
                    <Card
                        style={{
                            overflowY: "hidden",
                            width: "100%",
                            height: "100%",
                            padding: 2,
                            margin: 0,
                            boxSizing: "border-box"
                        }}
                    >
                        <CardContent>
                            <List
                                noChevron
                                noHairlines
                                noHairlinesBetween
                                noHairlinesBetweenMd
                                noHairlinesMd
                            >
                                <Card
                                    style={{
                                        position: "absolute",
                                        left: 0,
                                        right: 0,
                                        top: this.state.showOptions ? 0 + "px" : -218 + "px",
                                        zIndex: 25,
                                        transition: "all 0.35s ease-in-out"
                                    }}
                                >
                                    <CardContent>
                                        <Row style={{ width: "100%" }}>
                                            <Col>
                                                <ListItem
                                                    className="roundedOutlinedSmartSelect"
                                                    title="Clientul"
                                                    value={this.state.clientul}
                                                    floatingLabel
                                                    label="Clientul"
                                                    validate
                                                    required
                                                    smartSelect
                                                    smartSelectParams={{
                                                        virtualList: true,
                                                        searchbar: true,
                                                        openIn: "popup",
                                                        popupTabletFullscreen: true,
                                                        popupPush: true,
                                                        closeOnSelect: true,
                                                        on: {
                                                            closed: event => {
                                                                this.setState(
                                                                    {
                                                                        clientul: parseInt(event.getValue())
                                                                    },
                                                                    () => {
                                                                        //this.GetValues()
                                                                    }
                                                                );
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <Icon
                                                        color={"var(--f7-theme-color)"}
                                                        size={20}
                                                        material="account_circle"
                                                        slot="content-start"
                                                    />
                                                    <select
                                                        name="Clientul"
                                                        defaultValue={this.state.clientul}
                                                    >
                                                        {this.state.clientiArray.map((obj, index) => (
                                                            <option
                                                                key={"clientiFacturare-" + index}
                                                                label={obj.key}
                                                                value={obj.value}
                                                            >
                                                                {obj.key}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </ListItem>
                                            </Col>
                                            <Col>
                                                <ListInput
                                                    floatingLabel
                                                    label="Curs BNR"
                                                    validate
                                                    type="number"
                                                    min="0"
                                                    step="0.0001"
                                                    placeholder="Curs BNR"
                                                    value={this.state.cursBNR}
                                                    onChange={e => {
                                                        this.setState(
                                                            {
                                                                cursBNR: e.currentTarget.value
                                                            },
                                                            () => {
                                                                //this.GetValues()
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <Icon
                                                        color={"var(--f7-theme-color)"}
                                                        size={20}
                                                        material="account_circle"
                                                        slot="content-start"
                                                    />
                                                </ListInput>
                                            </Col>
                                            <Col>
                                                <ListInput
                                                    floatingLabel
                                                    label="Curs Calc"
                                                    validate
                                                    type="number"
                                                    min="0"
                                                    step="0.0001"
                                                    placeholder="Curs Calc"
                                                    value={this.state.cursCalc}
                                                    onChange={e => {
                                                        this.setState(
                                                            {
                                                                cursCalc: e.currentTarget.value
                                                            },
                                                            () => {
                                                                //this.GetValues()
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <Icon
                                                        color={"var(--f7-theme-color)"}
                                                        size={20}
                                                        material="account_circle"
                                                        slot="content-start"
                                                    />
                                                </ListInput>
                                            </Col>
                                        </Row>
                                        <Row style={{ width: "100%" }}>
                                            <Col>
                                                <ListInput
                                                    validate
                                                    required
                                                    label="De la"
                                                    type="datepicker"
                                                    calendarParams={{
                                                        timePicker: true,
                                                        dateFormat: "dd.mm.yyyy",
                                                        closeOnSelect: true,
                                                        on: {
                                                            closed: e => {
                                                                const val = e.value[0];
                                                                this.setState(
                                                                    {
                                                                        deLa: [val]
                                                                    },
                                                                    () => {
                                                                        //this.GetValues()
                                                                    }
                                                                );
                                                            }
                                                        }
                                                    }}
                                                    value={this.state.deLa}
                                                    floatingLabel
                                                    placeholder="De la"
                                                >
                                                    <Icon
                                                        color={"var(--f7-theme-color)"}
                                                        size={20}
                                                        material="today"
                                                        slot="content-start"
                                                    />
                                                </ListInput>
                                            </Col>
                                            <Col>
                                                <ListInput
                                                    validate
                                                    required
                                                    label="Pana la"
                                                    type="datepicker"
                                                    calendarParams={{
                                                        timePicker: true,
                                                        dateFormat: "dd.mm.yyyy",
                                                        closeOnSelect: true,
                                                        on: {
                                                            closed: e => {
                                                                const val = e.value[0];
                                                                this.setState(
                                                                    {
                                                                        panaLa: [val]
                                                                    },
                                                                    () => {
                                                                        //this.GetValues()
                                                                    }
                                                                );
                                                            }
                                                        }
                                                    }}
                                                    value={this.state.panaLa}
                                                    floatingLabel
                                                    placeholder="Pana la"
                                                >
                                                    <Icon
                                                        color={"var(--f7-theme-color)"}
                                                        size={20}
                                                        material="today"
                                                        slot="content-start"
                                                    />
                                                </ListInput>
                                            </Col>
                                            <Col>
                                                <ListItem
                                                    className="simpleInlineCheckbox"
                                                    checkbox
                                                    title="Doar nefacturate"
                                                    name="nefacturate"
                                                    checked={this.state.doarNefacturate}
                                                    onChange={e => {
                                                        this.setState(
                                                            {
                                                                doarNefacturate: e.currentTarget.checked
                                                            },
                                                            () => {
                                                                //this.GetValues()
                                                            }
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ width: "100%", marginTop: "8px" }}>
                                            <Col>
                                                <Button
                                                    tooltip={"Tiparire anexa"}
                                                    fill
                                                    iconMaterial="print"
                                                    iconColor={"white"}
                                                    iconSize={20}
                                                >
                                                    Tiparire anexa
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    tooltip={"Afiseaza"}
                                                    fill
                                                    onClick={() => {
                                                        this.setState(
                                                            {
                                                                showOptions: false
                                                            },
                                                            () => {
                                                                this.GetValues();
                                                            }
                                                        );
                                                    }}
                                                >
                                                    Afiseaza
                                                </Button>
                                            </Col>
                                        </Row>
                                        <div
                                            style={{
                                                position: "absolute",
                                                bottom: -2,
                                                left: "50%",
                                                transform: this.state.showOptions
                                                    ? "translate(-50%, 50%) rotate(180deg)"
                                                    : "translate(-50%, 100%) rotate(0)",
                                                width: "auto",
                                                height: "auto",
                                                borderRadius: "50%",
                                                backgroundColor: "#fff",
                                                padding: 5,
                                                cursor: "pointer",
                                                transition: "all 0.35s ease-in-out"
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    showOptions: !this.state.showOptions
                                                });
                                            }}
                                        >
                                            <Icon
                                                material="keyboard_arrow_down"
                                                style={{ fontSize: 30, margin: 0 }}
                                            />
                                        </div>
                                    </CardContent>
                                </Card>
                                <DataGrid
                                    dataLoaded={this.state.dataLoaded}
                                    refresh={this.GetValues}
                                    name={"FacturarePage"}
                                    //ref={this.refDataGrid}
                                    renderUpdate={false}
                                    renderDelete={false}
                                    renderAdd={false}
                                    renderCheckbox={true}
                                    maxHeight={this.state.height}
                                    dataSource={this.state.dataSource}
                                    inputs={inputs.Facturare}
                                    onCheckboxChange={e => { }}
                                />
                            </List>
                        </CardContent>
                    </Card>
                </div>
            </Page>
        );
    }
}
export default FacturarePage;