import Template7 from 'template7';
import $ from 'dom7';
// F7 Class
import Framework7 from 'framework7/components/app/app-class';

// Import Helpers
import Request from 'framework7/utils/request';
import Utils from 'framework7/utils/utils';
import Support from 'framework7/utils/support';
import Device from 'framework7/utils/device';

// Core Modules
import DeviceModule from 'framework7/modules/device/device';
import SupportModule from 'framework7/modules/support/support';
import UtilsModule from 'framework7/modules/utils/utils';
import ResizeModule from 'framework7/modules/resize/resize';
import RequestModule from 'framework7/modules/request/request';
import TouchModule from 'framework7/modules/touch/touch';
import ClicksModule from 'framework7/modules/clicks/clicks';
import RouterModule from 'framework7/modules/router/router';
import RouterTemplateLoaderModule from 'framework7/modules/router/template-loader'; // NO_LITE
import RouterComponentLoaderModule from 'framework7/modules/router/component-loader'; // NO_LITE
import ComponentModule, { Component } from 'framework7/modules/component/component'; // NO_LITE
import HistoryModule from 'framework7/modules/history/history';
import ServiceWorkerModule from 'framework7/modules/service-worker/service-worker';

// Core Components
import Statusbar from 'framework7/components/statusbar/statusbar';
import View from 'framework7/components/view/view';
import Navbar from 'framework7/components/navbar/navbar';
import Toolbar from 'framework7/components/toolbar/toolbar';
import Subnavbar from 'framework7/components/subnavbar/subnavbar';
import TouchRipple from 'framework7/components/touch-ripple/touch-ripple';
import Modal from 'framework7/components/modal/modal';
import Router from 'framework7/modules/router/router-class';// NO_LITE

import Appbar from 'framework7/components/appbar/appbar';
import Dialog from 'framework7/components/dialog/dialog';
import Popup from 'framework7/components/popup/popup';
// import LoginScreen from 'framework7/components/login-screen/login-screen';
import Popover from 'framework7/components/popover/popover';
import Actions from 'framework7/components/actions/actions';
import Sheet from 'framework7/components/sheet/sheet';
import Toast from 'framework7/components/toast/toast';
import Preloader from 'framework7/components/preloader/preloader';
// import Progressbar from 'framework7/components/progressbar/progressbar';
import Sortable from 'framework7/components/sortable/sortable';
// import Swipeout from 'framework7/components/swipeout/swipeout';
import Accordion from 'framework7/components/accordion/accordion';
// import ContactsList from 'framework7/components/contacts-list/contacts-list';
import VirtualList from 'framework7/components/virtual-list/virtual-list';
// import ListIndex from 'framework7/components/list-index/list-index';
// import Timeline from 'framework7/components/timeline/timeline';
import Tabs from 'framework7/components/tabs/tabs';
import Panel from 'framework7/components/panel/panel';
import Card from 'framework7/components/card/card';
import Chip from 'framework7/components/chip/chip';
import Form from 'framework7/components/form/form';
import Input from 'framework7/components/input/input';
import Checkbox from 'framework7/components/checkbox/checkbox';
import Radio from 'framework7/components/radio/radio';
import Toggle from 'framework7/components/toggle/toggle';
import Range from 'framework7/components/range/range';
import Stepper from 'framework7/components/stepper/stepper';
import SmartSelect from 'framework7/components/smart-select/smart-select';
import Grid from 'framework7/components/grid/grid';
import Calendar from 'framework7/components/calendar/calendar';
import Picker from 'framework7/components/picker/picker';
// import InfiniteScroll from 'framework7/components/infinite-scroll/infinite-scroll';
// import PullToRefresh from 'framework7/components/pull-to-refresh/pull-to-refresh';
// import Lazy from 'framework7/components/lazy/lazy';
import DataTable from 'framework7/components/data-table/data-table';
import Fab from 'framework7/components/fab/fab';
import Searchbar from 'framework7/components/searchbar/searchbar';
import Messages from 'framework7/components/messages/messages';
import Messagebar from 'framework7/components/messagebar/messagebar';
// import Swiper from 'framework7/components/swiper/swiper';
// import PhotoBrowser from 'framework7/components/photo-browser/photo-browser';
import Notification from 'framework7/components/notification/notification';
// import Autocomplete from 'framework7/components/autocomplete/autocomplete';
import Tooltip from 'framework7/components/tooltip/tooltip';
// import Gauge from 'framework7/components/gauge/gauge';
// import Skeleton from 'framework7/components/skeleton/skeleton';
import Menu from 'framework7/components/menu/menu';
import ColorPicker from 'framework7/components/color-picker/color-picker';
import Treeview from 'framework7/components/treeview/treeview';
// import TextEditor from 'framework7/components/text-editor/text-editor';
import Elevation from 'framework7/components/elevation/elevation';
import Typography from 'framework7/components/typography/typography';
// import Vi from 'framework7/components/vi/vi';

if (typeof window !== 'undefined') {
    // Template7
    if (!window.Template7) window.Template7 = Template7;

    // Dom7
    if (!window.Dom7) window.Dom7 = $;
}

// Install Core Modules & Components
Router.use([ // NO_LITE
    RouterTemplateLoaderModule, // NO_LITE
    RouterComponentLoaderModule, // NO_LITE
]); // NO_LITE

Framework7.use([
    DeviceModule,
    SupportModule,
    UtilsModule,
    ResizeModule,
    RequestModule,
    TouchModule,
    ClicksModule,
    RouterModule,
    HistoryModule,
    ComponentModule, // NO_LITE
    ServiceWorkerModule,
    Statusbar,
    View,
    Navbar,
    Toolbar,
    Subnavbar,
    TouchRipple,
    Modal,
    Appbar,
    Dialog,
    Popup,
    // LoginScreen,
    Popover,
    Actions,
    Sheet,
    Toast,
    Preloader,
    // Progressbar,
    Sortable,
    // Swipeout,
    Accordion,
    // ContactsList,
    VirtualList,
    // ListIndex,
    // Timeline,
    Tabs,
    Panel,
    Card,
    Chip,
    Form,
    Input,
    Checkbox,
    Radio,
    Toggle,
    Range,
    Stepper,
    SmartSelect,
    Grid,
    Calendar,
    Picker,
    // InfiniteScroll,
    // PullToRefresh,
    // Lazy,
    DataTable,
    Fab,
    Searchbar,
    Messages,
    Messagebar,
    // Swiper,
    // PhotoBrowser,
    Notification,
    // Autocomplete,
    Tooltip,
    // Gauge,
    // Skeleton,
    Menu,
    ColorPicker,
    Treeview,
    // TextEditor,
    Elevation,
    Typography,
    // Vi
]);

export {
    Template7, $ as Dom7, Request, Utils, Device, Support, Component,
};
export default Framework7;