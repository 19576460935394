
/**
 * @description web worker(folosit pentru a rula intr-un thread separat) ce face actualizarea taskului curent activ, foloseste endpointul api/RealizareSarcini/VerificareTaskActivSiActualizare/" + data.idSalariat
 * @date 06/04/2022 
 * @function
 */
const message = () => {
	let text = "Hello I am Your Web Worker";

	const sentData = () => {
		// eslint-disable-next-line no-restricted-globals
		self.postMessage({ text });
	};

	// eslint-disable-next-line no-restricted-globals
	self.onmessage = ({ data }) => {
		if (data) {
			setInterval(() => {
				if (data.idSalariat && data.accessToken) {
					var url =
						data.url +
						"api/RealizareSarcini/VerificareTaskActivSiActualizare/" +
						data.idSalariat;
					fetch(url, {
						headers: { Authorization: "Bearer " + data.accessToken },
					})
						.then((response) => {})
						.catch((error) => {
							console.log("error:", error);
						});
				}
			}, 30000);
		}
	};
};

export default message;
