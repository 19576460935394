import React, { Component } from "react";
import {
    Row,
    Col,
    Searchbar,
    Stepper,
    Preloader,
    List,
    ListItem,
    Icon,
    Button,
    Panel,
    View,
    Page,
    Block,
    BlockTitle
} from "framework7-react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Text
} from "recharts";
import moment from "moment";
import axios from "axios";

var legend = [
    { color: "black", title: "In evaluare" },
    { color: "green", title: "In lucru" },
    { color: "orange", title: "Lucrat" },
    { color: "red", title: "Inchis" },
    { color: "brown", title: "Propus altcuiva" }
];

class CustomTick extends Component {
    render() {
        const { payload, context, ...otherProps } = this.props;
        var otP = otherProps;
        var pro = context.state.listaProiecte.find(x => {
            return x.idProiect === payload.value;
        });
        if (pro) {
            var color = "black";
            switch (pro.stadiu) {
                case 6: {
                    color = "red";
                    break;
                }
                case 1: {
                    color = "black";
                    break;
                }
                case 2: {
                    color = "green";
                    break;
                }
                case 3: {
                    color = "orange";
                    break;
                }
                case 44: {
                    color = "brown";
                    break;
                }
                default: {
                    color = "black";
                    break;
                }
            }
            otP.style = { fill: color };
        }
        var nume = pro ? pro.name : payload.value;
        return <Text {...otP}>{nume}</Text>;
    }
}

  class EstimatLucratTaskuri extends Component {
    constructor(props) {
        super(props);

        this.state = {
            linkApiRealizari: "/api/RealizareSarcini",
            linkApiSarcini: "/api/SarciniSalariat",
            height: window.innerHeight - 100,
            heightItem: 30,
            width: window.innerWidth - 10,
            listaProiecteAll: [],
            listaProiecte: [],
            listaProiectePagina: [],
            listaProiecteSearch: [],
            pagina: 1,
            paginaMax: 1,
            dataLoaded: false,
            searchActive: false,
            listaFiltrare: ["1", "2", "3", "6", "44"]
        };
    }

    componentDidMount = () => {
        this.GetValues();
    };

    dynamicSort = property => {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result =
                a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
            return result * sortOrder;
        };
    };

    GetValues = () => {
        const requestRealizari = axios.get(
            this.$f7.data.baseURL +
            this.state.linkApiRealizari +
            "/GetREALIZARE_SARCINI"
        );
        const requestSarcini = axios.get(
            this.$f7.data.baseURL +
            this.state.linkApiSarcini +
            "/GetSARCINI_SALARIATCorecte"
        );

        axios
            .all([requestRealizari, requestSarcini])
            .then(
                axios.spread((responseRealizari, responseSarcini) => {
                    const realizareSarcini = responseRealizari.data;
                    const sarciniSalariat = responseSarcini.data;
                    let listaProiecte = [];

                    this.$f7.data.allProiecte
                        .filter(x => x.id_client === this.$f7.data.user.iD_SALARIAT)
                        .reverse()
                        .sort(this.dynamicSort("iD_STADIU"))
                        .forEach(element => {
                            if ([4].includes(element.id_nivel_proiect)) {
                                const taskuri = this.$f7.data.allProiecte
                                    .filter(x => x.id_client === this.$f7.data.user.iD_SALARIAT)
                                    .filter(
                                        item =>
                                            item.id_nivel_proiect === 4 &&
                                            item.iD_PROIECT === element.iD_PROIECT
                                    );
                                let totalTaskuri = 0;
                                let taskuriFaraEstimare = 0;

                                if (taskuri.length > 0) {
                                    let nume = element.denumire;
                                    let idProiect = element.iD_PROIECT;
                                    let stadiu = element.iD_STADIU;
                                    let timpEstimatDeschise = 0;
                                    let timpLucratDeschise = 0;
                                    let timpEstimatInchise = 0;
                                    let timpLucratInchise = 0;

                                    taskuri.forEach(t => {
                                        let ultimaSarcina = sarciniSalariat.filter(
                                            item => item.iD_PROIECT === t.iD_PROIECT
                                        );

                                        if (ultimaSarcina.length > 0) {
                                            const maxIdUltimaSarcina = Math.max.apply(
                                                Math,
                                                ultimaSarcina.map(function (o) {
                                                    return o.iD_SARCINA;
                                                })
                                            );
                                            ultimaSarcina = ultimaSarcina.find(
                                                item => item.iD_SARCINA === maxIdUltimaSarcina
                                            );
                                        }

                                        if (ultimaSarcina !== undefined) {
                                            const realizari = realizareSarcini.filter(
                                                item =>
                                                    item.iD_PROIECT === t.iD_PROIECT &&
                                                    item.intrare !== null
                                            );

                                            if (ultimaSarcina.stare === 4) {
                                                // timp_plan_d
                                                // timp_plan_sp
                                                // timp_plan_a
                                                // timp_plan_m

                                                let timp = 0;
                                                if (t.timp_plan_d !== 0 && t.timp_plan_d !== null) {
                                                    timp = t.timp_plan_d;
                                                } else if (
                                                    t.timp_plan_sp !== 0 &&
                                                    t.timp_plan_sp !== null &&
                                                    t.timp_plan_sp < timp
                                                ) {
                                                    timp = t.timp_plan_sp;
                                                } else if (
                                                    t.timp_plan_a !== 0 &&
                                                    t.timp_plan_a !== null &&
                                                    t.timp_plan_a < timp
                                                ) {
                                                    timp = t.timp_plan_a;
                                                } else if (
                                                    t.timp_plan_m !== 0 &&
                                                    t.timp_plan_m !== null &&
                                                    t.timp_plan_m < timp
                                                ) {
                                                    timp = t.timp_plan_m;
                                                } else {
                                                    taskuriFaraEstimare++;
                                                }

                                                timpEstimatInchise += timp;

                                                realizari.forEach(r => {
                                                    const inceput = moment(r.intrare).valueOf();
                                                    const sfarsit =
                                                        r.iesire === null
                                                            ? moment().valueOf()
                                                            : moment(r.iesire).valueOf();
                                                    timpLucratInchise += (sfarsit - inceput) / 1000 / 60;
                                                });
                                            } else {
                                                // timp_plan_d
                                                // timp_plan_sp
                                                // timp_plan_a
                                                // timp_plan_m

                                                let timp = 0;
                                                if (t.timp_plan_d !== 0 && t.timp_plan_d !== null) {
                                                    timp = t.timp_plan_d;
                                                } else if (
                                                    t.timp_plan_sp !== 0 &&
                                                    t.timp_plan_sp !== null &&
                                                    t.timp_plan_sp < timp
                                                ) {
                                                    timp = t.timp_plan_sp;
                                                } else if (
                                                    t.timp_plan_a !== 0 &&
                                                    t.timp_plan_a !== null &&
                                                    t.timp_plan_a < timp
                                                ) {
                                                    timp = t.timp_plan_a;
                                                } else if (
                                                    t.timp_plan_m !== 0 &&
                                                    t.timp_plan_m !== null &&
                                                    t.timp_plan_m < timp
                                                ) {
                                                    timp = t.timp_plan_m;
                                                } else {
                                                    taskuriFaraEstimare++;
                                                }

                                                timpEstimatDeschise += timp;

                                                if (realizari.length > 0) {
                                                    realizari.forEach(r => {
                                                        const inceput = moment(r.intrare).valueOf();
                                                        const sfarsit =
                                                            r.iesire === null
                                                                ? moment().valueOf()
                                                                : moment(r.iesire).valueOf();
                                                        timpLucratDeschise +=
                                                            (sfarsit - inceput) / 1000 / 60;
                                                    });
                                                }
                                            }

                                            totalTaskuri++;
                                        }
                                    });

                                    if (element.id_tip_proiect === 59) {
                                        const pContract = this.$f7.data.allProiecte.find(
                                            item => item.iD_PROIECT === element.reF_PROIECT
                                        );

                                        if (pContract) {
                                            const pClient = this.$f7.data.allProiecte.find(
                                                item => item.iD_PROIECT === pContract.reF_PROIECT
                                            );

                                            if (pClient) {
                                                nume = "[" + pClient.denumire + "] " + nume;
                                            }
                                        }
                                    }

                                    listaProiecte.push({
                                        name2:
                                            nume +
                                            " (" +
                                            taskuriFaraEstimare +
                                            "/" +
                                            totalTaskuri +
                                            ")",
                                        name: nume,
                                        estimatD: timpEstimatDeschise / 60,
                                        lucratD: timpLucratDeschise / 60,
                                        estimatI: timpEstimatInchise / 60,
                                        lucratI: timpLucratInchise / 60,
                                        stadiu: stadiu,
                                        idProiect: idProiect
                                    });
                                }
                            }
                        });

                    this.setState(
                        {
                            listaProiecte: listaProiecte,
                            listaProiecteAll: listaProiecte
                        },
                        () => {
                            this.updateChart();
                        }
                    );
                })
            )
            .catch(error => {
                console.log("error", error);
            });
    };

    updateChart = () => {
        let lista = this.state.searchActive
            ? this.state.listaProiecteSearch
            : this.state.listaProiecte;

        this.setState(
            {
                dataLoaded: false,
                listaProiectePagina: lista.slice(
                    (this.state.pagina - 1) *
                    parseInt(this.state.height / this.state.heightItem),
                    this.state.pagina *
                    parseInt(this.state.height / this.state.heightItem)
                ),
                paginaMax: Math.ceil(
                    lista.length / parseInt(this.state.height / this.state.heightItem)
                )
            },
            () => {
                this.setState({
                    dataLoaded: true
                });
            }
        );
    };

    search = (value, baza) => {
        let toSearch = value
            .toString()
            .toLowerCase()
            .replace(/\s/g, "");

        let result = baza.filter(elemBaza => {
            let res = false;
            let valFinal = elemBaza.name
                .toString()
                .toLowerCase()
                .replace(/\s/g, "");

            if (valFinal.includes(toSearch)) {
                res = true;
            }

            return res;
        });

        this.setState(
            {
                listaProiecteSearch: result,
                pagina: 1,
                searchActive: true
            },
            () => {
                this.updateChart();
            }
        );
    };

    ConvertToFriendlyTime = seconds => {
        const days = Math.floor(seconds / (8 * 3600));

        seconds = seconds % (8 * 3600);
        const hours = Math.floor(seconds / 3600);

        seconds = seconds % 3600;
        const minutes = Math.floor(seconds / 60);

        seconds = seconds % 60;

        let output;

        if (days) {
            if (hours) {
                if (minutes) {
                    output =
                        (days > 1 ? days + "z " : days + " z") +
                        ", " +
                        (hours > 1 ? hours + "h " : hours + "h ") +
                        " : " +
                        (minutes > 1 ? minutes + "m " : minutes + "m ");
                } else {
                    output =
                        (days > 1 ? days + "z " : days + " z") +
                        " : " +
                        (hours > 1 ? hours + "h " : hours + "h ");
                }
            } else {
                if (minutes) {
                    output =
                        (days > 1 ? days + "z " : days + " z") +
                        " : " +
                        (minutes > 1 ? minutes + "m " : minutes + "m ");
                } else {
                    output = days > 1 ? days + "z " : days + " z";
                }
            }
        } else {
            if (hours) {
                if (minutes) {
                    output =
                        (hours > 1 ? hours + "h " : hours + "h ") +
                        " : " +
                        (minutes > 1 ? minutes + "m " : minutes + "m ");
                } else {
                    output = hours > 1 ? hours + "h " : hours + "h ";
                }
            } else {
                if (minutes) {
                    output = minutes > 1 ? minutes + "m " : minutes + "m ";
                } else {
                    output = "0";
                }
            }
        }

        return "" + output;
    };

    render() {
        return (
            <>
                <Panel className={"panelClienti"} resizable right cover backdrop>
                    <View>
                        <Page>
                            <BlockTitle medium>Legenda</BlockTitle>
                            <Block strong>
                                {legend.map(l => (
                                    <div
                                        key={"legendaDiv" + l.title}
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            margin: 6
                                        }}
                                    >
                                        <div
                                            key={"legendaColor" + l.title}
                                            style={{
                                                width: 20,
                                                height: 20,
                                                borderRadius: "50%",
                                                backgroundColor: l.color
                                            }}
                                        />
                                        <div
                                            key={"legendaTitle" + l.title}
                                            style={{ paddingLeft: 6 }}
                                        >
                                            {l.title}
                                        </div>
                                    </div>
                                ))}
                            </Block>
                        </Page>
                    </View>
                </Panel>
                <Row>
                    <Col style={{ position: "relative" }}>
                        <div
                            style={{
                                position: "relative",
                                overflow: "hidden",
                                display: "flex"
                            }}
                        >
                            <List
                                id={"ListEstimatLucratTaskuri"}
                                style={{ width: 400, margin: "0px!important" }}
                            >
                                <ListItem
                                    title="Stadiu"
                                    value={this.state.client}
                                    label="Stadiu"
                                    validate
                                    required
                                    smartSelect
                                    className="roundedOutlinedSmartSelect"
                                    smartSelectParams={{
                                        virtualList: true,
                                        searchbar: true,
                                        openIn: "popup",
                                        popupTabletFullscreen: true,
                                        popupPush: true,
                                        closeOnSelect: true,
                                        on: {
                                            closed: event => {
                                                var listaFiltrare = event.getValue();
                                                var listaPro = this.state.listaProiecteAll.filter(x => {
                                                    return (
                                                        x.stadiu !== null &&
                                                        listaFiltrare.includes(x.stadiu.toString())
                                                    );
                                                });
                                                this.setState(
                                                    {
                                                        listaFiltrare: listaFiltrare,
                                                        listaProiecte: listaPro,
                                                        pagina: 1
                                                    },
                                                    () => {
                                                        this.updateChart();
                                                    }
                                                );
                                            }
                                        }
                                    }}
                                >
                                    <Icon
                                        color={"var(--f7-theme-color)"}
                                        size={20}
                                        material="list"
                                        slot="content-start"
                                    />
                                    <select name="Filtrare" multiple>
                                        {[
                                            { key: "Nelucare", value: 1 },
                                            { key: "Pornite", value: 2 },
                                            { key: "Oprite", value: 3 },
                                            { key: "Inchise", value: 6 },
                                            { key: "Propus altcuiva", value: 44 }
                                        ].map((obj, index) => (
                                            <option
                                                label={obj.key}
                                                selected={this.state.listaFiltrare.includes(
                                                    obj.value.toString()
                                                )}
                                                key={"TipFiltrareEstimatLucratTaskuri-" + index}
                                                value={obj.value}
                                            >
                                                {obj.key}
                                            </option>
                                        ))}
                                    </select>
                                </ListItem>
                            </List>
                            <Searchbar
                                className={"searchBarEstimatLucratProiecte"}
                                onClickDisable={() => {
                                    this.setState(
                                        {
                                            pagina: 1,
                                            searchActive: false
                                        },
                                        () => {
                                            this.updateChart();
                                        }
                                    );
                                }}
                                onSearchbarEnable={() => { }}
                                onSearchbarDisable={() => { }}
                                onInput={e => {
                                    this.search(e.target.value, this.state.listaProiecte);
                                }}
                                borderColor={"transparent"}
                                style={{
                                    height: 45,
                                    width:
                                        "calc(100% - 130px)" /*, filter: "drop-shadow(2px 0px 14px gray)"*/,
                                    float: "left",
                                    margin: "auto 6px"
                                }}
                            />

                            <div
                                style={{
                                    position: "relative",
                                    marginTop: 8,
                                    marginRight: 8,
                                    float: "right"
                                }}
                            >
                                <span
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        textAlign: "center"
                                    }}
                                >
                                    {this.state.paginaMax}{" "}
                                    {this.state.paginaMax > 1 ? "pagini" : "pagina"}
                                </span>
                                <Stepper
                                    style={{
                                        height: 18,
                                        marginTop: 15
                                    }}
                                    onStepperPlusClick={e => {
                                        if (this.state.pagina < this.state.paginaMax) {
                                            this.setState(
                                                {
                                                    pagina: this.state.pagina + 1
                                                },
                                                () => {
                                                    this.updateChart();
                                                }
                                            );
                                        }
                                    }}
                                    onStepperMinusClick={(e, f) => {
                                        if (this.state.pagina > 1) {
                                            this.setState(
                                                {
                                                    pagina: this.state.pagina - 1
                                                },
                                                () => {
                                                    this.updateChart();
                                                }
                                            );
                                        }
                                    }}
                                    onInput={e => {
                                        const val = e.target.value;

                                        if (val !== "") {
                                            const pag = parseInt(val);

                                            if (pag >= 1 && pag <= this.state.paginaMax) {
                                                this.setState(
                                                    {
                                                        pagina: pag
                                                    },
                                                    () => {
                                                        this.updateChart();
                                                    }
                                                );
                                            }
                                        }
                                    }}
                                    small
                                    value={this.state.pagina}
                                    min={1}
                                    max={this.state.paginaMax}
                                    manualInputMode={true}
                                />
                            </div>
                            <Button
                                onClick={() => {
                                    var panelClienti = this.$f7.panel.get(".panelClienti");
                                    if (panelClienti) {
                                        panelClienti.open();
                                    }
                                }}
                                round
                                fill
                                small
                                style={{ marginTop: 20, padding: 2 }}
                            >
                                <Icon color={"white"} size={20} material="info" />
                            </Button>
                        </div>

                        {this.state.dataLoaded ? (
                            <BarChart
                                layout="vertical"
                                width={this.state.width}
                                height={this.state.height}
                                data={this.state.listaProiectePagina}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" />
                                <YAxis
                                    tick={<CustomTick context={this} />}
                                    dataKey="idProiect"
                                    style={{ fill: "blue" }}
                                    type="category"
                                    width={180}
                                />
                                <Tooltip
                                    formatter={(value, name, props) => {
                                        let output = value;

                                        var pro = this.state.listaProiecte.find(x => {
                                            return x.idProiect === parseInt(name);
                                        });
                                        if (typeof value === "number") {
                                            output = this.ConvertToFriendlyTime(value * 60 * 60);
                                        }
                                        var nume = pro ? pro.name : name;
                                        return [output, nume];
                                    }}
                                    separator=" → "
                                    itemStyle={{ fontSize: 13, paddingBottom: 1, paddingTop: 1 }}
                                    labelStyle={{ fontSize: 17, marginBottom: 5 }}
                                />
                                <Legend />

                                <Bar
                                    dataKey="lucratI"
                                    name="Lucrat (inchis)"
                                    stackId="lucrate"
                                    fill="#8c330d"
                                />
                                <Bar
                                    dataKey="lucratD"
                                    name="Lucrat (deschis)"
                                    stackId="lucrate"
                                    fill="#d94f14"
                                />

                                <Bar
                                    dataKey="estimatI"
                                    name="Estimat (inchis)"
                                    stackId="estimate"
                                    fill="#bf9a15"
                                />
                                <Bar
                                    dataKey="estimatD"
                                    name="Estimat (deschis)"
                                    stackId="estimate"
                                    fill="#e6b919"
                                />
                            </BarChart>
                        ) : (
                            <div
                                style={{
                                    position: "absolute",
                                    width: "40px",
                                    height: "40px",
                                    top: "calc(50vh - 34px)",
                                    left: "50%",
                                    transform: "translateX(-50%)"
                                }}
                            >
                                <Preloader size={40} color={"var(--f7-theme-color)"} />
                            </div>
                        )}
                    </Col>
                </Row>
            </>
        );
    }
}
export default  EstimatLucratTaskuri