import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";

/**
 * @description Interfata de intretinere a departamentelor
 * @date 07/04/2022
 * @class Departamente
 * @extends {React.Component}
 */
class Departamente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      levelToOpen: 0,
      rowItemData: null,
      dataLoaded: false,
      linkApi: "/api/Departamente",
      denumire: "Departamente",
      height: window.innerHeight - 34,
      dataSource: [],
      inputs: [
        { key: "Id_Departament", keyBaza: "iD_DEPARTAMENT", tip: "number" },
        {
          key: "Denumire",
          keyBaza: "denumire",
          requiredAdd: true,
          requiredEdit: true,
        },
        { key: "Ref_Departament", keyBaza: "reF_DEPARTAMENT", tip: "number" },
        { key: "Ordine", keyBaza: "ordine", tip: "number" },
      ],
    };
  }

  /**
   * @description Ia valorile pentru randare
   * @function
   * @date 07/04/2022
   * @memberof Departamente
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApi)
      .then((response) => {
        this.$f7.methods.IaValori();

        var date = response;
        date.data.map((eveniment) => {
          eveniment = this.$f7.methods.restructurareBaza(
            eveniment,
            this.state.inputs,
            "key"
          );
          return null;
        });
        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Adauga o inregistrare noua in baza de date pentru interfata curenta
   * @date 07/04/2022
   * @function
   * @param {*} data obiect cu noua inregistrare de va fi adaugata
   * @memberof Departamente
   */
  AddValue = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApi;
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza o inregistrare din curenta interfata in baza de date
   * @date 07/04/2022
   * @function
   * @param {*} obj obiectul cu noile valori
   * @param {*} id id-ul inregistrarii selectate
   * @memberof Departamente
   */
  UpdateValue = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url = this.$f7.data.baseURL + this.state.linkApi + "/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Sterge din baza de date inregistrarea selectata
   * @date 07/04/2022
   * @param {*} id id-ul inregistrarii selectate
   * @function
   * @memberof Departamente
   */
  DeleteValue = (id) => {
    var url = this.$f7.data.baseURL + this.state.linkApi + "/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return (
      <DataGrid
        //accordionOpenToLevel={this.state.levelToOpen}
        onRenderItemsChanged={(a) => {
          console.log("A", a);
        }}
        dataLoaded={this.state.dataLoaded}
        refresh={this.GetValues}
        name={"Departamente"}
        dragRows={true}
        initialRefId={0}
        renderCheckbox={true}
        refId={"Ref_Departament"}
        id={"Id_Departament"}
        ignoreInputsAdd={["Ref_Departament"]}
        ignoreInputsEdit={["Ref_Departament"]}
        renderAdd={true}
        renderDelete={true}
        renderUpdate={true}
        renderAccordion={true}
        dataSource={this.state.dataSource}
        maxHeight={this.state.height}
        inputs={this.state.inputs}
        onAdd={(state) => {
          var data = { ...state.editedItem };
          delete data.Id_Departament;
          //data.Ref_Departament = state.initialRefId;
          this.AddValue(data);
        }}
        onDelete={(state) => {
          var data = { ...state.editedItem };
          this.DeleteValue(data.Id_Departament);
        }}
        onUpdate={(state) => {
          var data = { ...state.editedItem };
          // data.Ref_Departament = state.initialRefId;
          this.UpdateValue(data, data.Id_Departament);
        }}
        dragRowsOnStart={(e, oldIndex) => {
          var itemeRandate = e.state.renderItems.slice(
            e.state.numberBefore,
            e.state.numberBefore + e.state.count
          );
          var item = itemeRandate[oldIndex];
          if (item === undefined) {
            item = itemeRandate[itemeRandate.length - 1];
          }

          this.setState({ rowItemData: item });
        }}
        dragRowsOnEnd={(e) => {
          if (this.state.rowItemData !== null) {
            var obj = { Ref_Departament: e.state.initialRefId };
            var id = this.state.rowItemData.Id_Departament;

            this.$f7.dialog.confirm(
              "Sunteti sigur?",
              "Confirm",
              () => {
                this.UpdateValue(obj, id);
              },
              () => {
                this.GetValues();
              }
            );
          }
        }}
      />
    );
  }
}
export default Departamente;
