import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import * as inputs from "../../../../inputs";
import axios from "axios";
/**
 * @description Interfata de intretinere a surselor
 * @date 07/04/2022
 * @class Sursa
 * @extends {React.Component}
 */
class Sursa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      linkApi: "/api/TipSursa",
      denumire: "Sursa",
      height: window.innerHeight - 34,
      dataSource: [],
    };
    this.refDataGrid = React.createRef();
  }
  /**
   * @description Se iau toate inregistrarile pentru interfata de intretinere curenta(Sursa)
   * @function
   * @date 07/04/2022
   * @memberof Sursa
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/GetTIP_SURSA")
      .then((response) => {
        this.$f7.methods.IaValori();

        var baza = this.$f7.methods.reconstructieBaza(
          response,
          inputs.IntretinereSursa
        );

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  this.refDataGrid.current.setRenderItems(baza);
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };
  /**
   * @description Adauga o inregistrare noua pentru interfata curenta(Sursa)
   * @date 07/04/2022
   * @param {*} data obiectul cu toate informatiile noii inregistrari
   * @function
   * @memberof Sursa
   */
  AddValue = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApi + "/PostTIP_SURSA";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };
  /**
   * @description Se modifica in baza de date inregistrarea selectata
   * @date 07/04/2022
   * @param {*} obj obiect cu noile informatii ale inregistrarii selectate
   * @param {*} id id-ul inregistrarii selectate
   * @memberof Sursa
   */
  UpdateValue = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/GetTIP_SURSA/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL + this.state.linkApi + "/PutTIP_SURSA/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };
  /**
   * @description Sterge din baza de date inregistrarea selectata
   * @date 07/04/2022
   * @param {*} id id-ul inregistrarii selectate
   * @memberof Sursa
   */
  DeleteValue = (id) => {
    var url =
      this.$f7.data.baseURL + this.state.linkApi + "/DeleteTIP_SURSA/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return (
      <DataGrid
        dataLoaded={this.state.dataLoaded}
        refresh={this.GetValues}
        name={"Sursa"}
        id={"Id_Tip_Sursa"}
        ref={this.refDataGrid}
        renderUpdate={true}
        renderDelete={true}
        renderAdd={true}
        maxHeight={this.state.height}
        inputs={inputs.IntretinereSursa}
        dataSource={this.state.dataSource}
        onAdd={(state) => {
          var data = { ...state.editedItem };
          delete data.Id_Tip_Sursa;
          this.AddValue(data);
        }}
        onDelete={(state) => {
          var data = { ...state.editedItem };
          this.DeleteValue(data.Id_Tip_Sursa);
        }}
        onUpdate={(state) => {
          var data = { ...state.editedItem };
          this.UpdateValue(data, data.Id_Tip_Sursa);
        }}
        ignoreInputsAdd={["Id_Tip_Sursa"]}
      />
    );
  }
}
export default Sursa;
