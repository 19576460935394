import React from "react";
import {
  Popover,
  Messages,
  Message,
  Page,
  Row,
  Col,
  List,
  ListItem,
  Link,
  Messagebar,
  Badge,
  Button,
  Segmented,
  Icon,
  Searchbar,
  Checkbox,
} from "framework7-react";
import axios from "axios";
import moment from "moment";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import TreeList from "devextreme-react/tree-list";
import { Template } from "devextreme-react/core/template";
import Picker from "emoji-picker-react";
import MainCall from "./MainCall";
import GroupCall from "./GroupCall";
import notificationSound from "../../../styles/notification.mp3";
import { saveAs } from "file-saver";

/**
 * @description Interfata popupului de chat, unde se poate comunica cu alti salariati
 * @date 08/04/2022
 * @class SesizariChat
 * @extends {React.Component}
 */
class SesizariChat extends React.Component {
  constructor(props) {
    super(props);
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.state = {
      forward: { show: false, message: "" },
      openPopoverEmoji: false,
      idTask: null,
      filtrat: true,
      filterValue: ["esteLogat", "anyof", [true]],
      chatType: localStorage.getItem("chatType")
        ? parseInt(localStorage.getItem("chatType"))
        : 2,
      componentaTree: null,
      arhivate: false,
      speechStarted: false,
      audioFile: null,
      recording: false,
      sheetVisible: false,
      idCreator: null,
      mesaj: "",
      dataLoaded: false,
      dataLoaded2: false,
      linkApi: "/api/Sesizari",
      denumire: "SesizariChat",
      height: window.innerHeight - 34,
      utilizatori: [],
      utilizatoriRender: [],
      dataSource: [],
      inputs: [
        { key: "Id", keyBaza: "id_sesizare", columnWidth: "10px" },
        {
          key: "Data",
          keyBaza: "data_sesizare",
          tip: "datebox",
          columnWidth: "10px",
        },
        { key: "NumeDeLaCine", keyBaza: "nume_de_la_cine" },
        { key: "NumePentruCine", keyBaza: "nume_pt_cine" },
        {
          key: "DeLa",
          keyBaza: "de_la_cine",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
          columnWidth: "10px",
        },
        {
          key: "Pentru",
          keyBaza: "pt_cine",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
          requiredAdd: true,
          requiredEdit: true,
          columnWidth: "10px",
        },
        {
          key: "Subiect",
          keyBaza: "subiect",
          requiredAdd: true,
          requiredEdit: true,
          columnWidth: "350px",
        },
        {
          key: "Mesaj",
          keyBaza: "comentariu",
          requiredAdd: true,
          requiredEdit: true,
          columnWidth: "350px",
        },
        {
          key: "NumeTask",
          keyBaza: "ref_proiect",
          tip: "selectbox",
          lista: this.$f7.data.Proiecte,
          columnWidth: "350px",
        },
        { key: "Citit", keyBaza: "citit", columnWidth: "10px" },
        {
          key: "IsArhivat",
          keyBaza: "isArhivat",
          tip: "bool",
          columnWidth: "10px",
        },
        { key: "CititDe", keyBaza: "citit_de" },
      ],
      checkedArhivate: true,
      utilizatorSelectat: null,
    };

    this.audio = new Audio(notificationSound);
  }

  /**
   * @description Adauga in baza de date un nou mesaj(o sesizare) pentru conversatia curenta
   * @date 08/04/2022
   * @param {*} data obiect cu informatiile sesizarii
   * @function
   * @memberof SesizariChat
   */
  AddValue = (data) => {
    this.setState({ speechStarted: false }, () => {
      this.$f7.methods
        .stopSpeech()
        .then((r) => {})
        .catch((err) => {});
      var url = this.$f7.data.baseURL + this.state.linkApi + "/Postsesizari";
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          this.GetValuesDreapta();
        })
        .catch((error) => {
          console.log("error", error);
          this.$f7.methods.openNotification(
            "Eroare la trimitere",
            undefined,
            "eroare"
          );
        });
    });
  };

  /**
   * @description Modifica o inregistrare din baza de date (o sesizare)
   * @date 08/04/2022
   * @param {*} obj obiectul cu noile valori ale inregistrarii
   * @param {*} id id-ul inregistrarii selectate
   * @function
   * @param {*} showMessage true sau false daca sa afiseze notificare ca s-a modificat
   * @memberof SesizariChat
   */
  UpdateValue = (obj, id, showMessage) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/Getsesizari/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url =
          this.$f7.data.baseURL + this.state.linkApi + "/Putsesizari/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();

            if (showMessage) {
              this.$f7.methods.openNotification("Mesajul a fost modificat");
            } else {
              if (this.$f7.data.TopRight !== undefined)
                this.$f7.data.TopRight.GetMessagesCount();
              this.$f7.methods.openNotification(
                "Mesajul selectat a fost marcat ca citit"
              );
            }
          })
          .catch((error) => {
            console.log("error", error);
            if (showMessage) {
              this.$f7.methods.openNotification("Eroare la actualizare");
            } else {
              this.$f7.methods.openNotification("Eroare la marcare ca citit");
            }
          });
      });
  };

  /**
   * @description Converteste din secunde intr-un format mai frumos de citit (h:m:s)
   * @date 08/04/2022
   * @param {*} seconds
   * @function
   * @memberof SesizariChat
   */
  ConvertToFriendlyTime = (seconds) => {
    const days = Math.floor(seconds / (24 * 3600));

    seconds = seconds % (24 * 3600);
    const hours = Math.floor(seconds / 3600);

    seconds = seconds % 3600;
    const minutes = Math.floor(seconds / 60);

    seconds = seconds % 60;

    let output;

    if (days) {
      if (hours) {
        if (minutes) {
          output =
            (days > 1 ? days + "z " : days + " z") +
            ", " +
            (hours > 1 ? hours + "h " : hours + "h ") +
            " : " +
            (minutes > 1 ? minutes + "m " : minutes + "m ");
        } else {
          output =
            (days > 1 ? days + "z " : days + " z") +
            " : " +
            (hours > 1 ? hours + "h " : hours + "h ");
        }
      } else {
        if (minutes) {
          output =
            (days > 1 ? days + "z " : days + " z") +
            " : " +
            (minutes > 1 ? minutes + "m " : minutes + "m ");
        } else {
          output = days > 1 ? days + "z " : days + " z";
        }
      }
    } else {
      if (hours) {
        if (minutes) {
          output =
            (hours > 1 ? hours + "h " : hours + "h ") +
            " : " +
            (minutes > 1 ? minutes + "m " : minutes + "m ");
        } else {
          output = hours > 1 ? hours + "h " : hours + "h ";
        }
      } else {
        if (minutes) {
          output = minutes > 1 ? minutes + "m " : minutes + "m ";
        } else {
          output = "0";
        }
      }
    }
    if (output === "0") {
      return "";
    }

    return "" + output;
  };

  /**
   * @description Sterge un mesaj din conversatia cu salariatul curent
   * @date 08/04/2022
   * @param {*} id id-ul inregistrarii(Sesizarii)
   * @function
   * @memberof SesizariChat
   */
  DeleteValue = (id) => {
    var url =
      this.$f7.data.baseURL + this.state.linkApi + "/Deletesesizari/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValuesDreapta();
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  componentDidMount = () => {
    //this.GetValues();
    //this.GetValuesDreapta();

    this.$f7.data.signalRConnection.on(
      "ReceivedExplicitMessage",
      (from, message) => {
        if (this.state.utilizatoriRender.length > 0) {
          if (from.salariatId === this.state.utilizatorSelectat) {
            const sal = this.$f7.data.Salariati.find(
              (s) => s.value === from.salariatId
            );
            let dataSource = [...this.state.dataSource];

            dataSource.push({
              Citit: false,
              Data: moment().format(),
              DeLa: from.salariatId,
              Id:
                dataSource.length > 0
                  ? 1000000 + dataSource[dataSource.length - 1].Id
                  : 1000000,
              IsArhivat: false,
              Mesaj: message,
              NumeDeLaCine: sal ? sal.key : "",
              NumeTask: null,
              Pentru: this.$f7.data.user.iD_SALARIAT,
              Subiect: "",
            });

            this.setState({
              dataSource: dataSource,
            });
          } else {
            let users = [...this.state.utilizatoriRender];
            let user = users.find((u) => u.idSalariat === from.salariatId);
            user.nrNecitite = user.nrNecitite + 1;

            this.setState(
              {
                utilizatoriRender: users,
              },
              () => {
                const mesaj =
                  message.length > 80
                    ? message.substring(0, 80) + "..."
                    : message;
                if (from) {
                  this.$f7.methods.openNotification(
                    mesaj,
                    `Mesaj nou de la ${from.user}`,
                    "succes"
                  );
                } else {
                  this.$f7.methods.openNotification(
                    mesaj,
                    `Mesaj nou`,
                    "succes"
                  );
                }
                this.audio.play();
              }
            );
          }
        }
      }
    );
  };

  /**
   * @description Sterge conexiunea la signalR
   * @date 08/04/2022
   * @function
   * @memberof SesizariChat
   */
  componentWillUnmount = () => {
    this.$f7.data.signalRConnection.off("ReceivedExplicitMessage");
  };

  /**
   * @description Transforma un base64 in blob pentru a adauga atasamente la mesaj si returneaza blobul
   * @date 08/04/2022
   * @param {*} b64Data
   * @param {string} [contentType=""]
   * @param {number} [sliceSize=512]
   * @memberof SesizariChat
   */
  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
  /**
   * @description Ia mesajele pentru conversatia curent selectata(salariatul curent selectat)
   * @date 08/04/2022
   * @function
   * @memberof SesizariChat
   */
  GetValuesDreapta = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          dataLoaded2: false,
          mesaj: "",
        },
        () => {
          var utilizatorSelectat =
            this.state.utilizatorSelectat === null
              ? -1000
              : this.state.utilizatorSelectat;
          var arhivate = this.state.arhivate ? 1 : 0;
          axios
            .get(
              this.$f7.data.baseURL +
                this.state.linkApi +
                "/GetConversatiiByIds/" +
                this.$f7.data.user.iD_SALARIAT +
                "/" +
                utilizatorSelectat +
                "/" +
                arhivate
            )
            .then((response) => {
              var date = response;
              if (!this.state.checkedArhivate) {
                date.data = date.data.filter(
                  (item) => item.isArhivat === false
                );
              }
              date.data.map((sesizare) => {
                sesizare = this.$f7.methods.restructurareBaza(
                  sesizare,
                  this.state.inputs,
                  "key"
                );
                return false;
              });

              var baza = this.$f7.methods.reconstructieBaza(
                date,
                this.state.inputs
              );
              this.setState(
                {
                  dataSource: baza,
                  dataLoaded2: true,
                  forward: {
                    show: false,
                    message: "",
                  },
                },
                () => {
                  this.GetValues().then(() => {
                    var $ = this.$$;
                    var txtAreaList = $("#mesajInputWrapper textarea");
                    if (txtAreaList.length > 0) {
                      var txtArea = txtAreaList[0];
                      txtArea.focus();
                      resolve(this);
                    }
                  });
                }
              );
            })
            .catch((error) => {
              this.$f7.methods.openNotification("Eroare:" + error);
              reject(error);
            });
        }
      );
    });
  };

  /**
   * @description Functie de returneaza salariatii selectati ca string separat prin ,
   * @function
   * @date 08/04/2022
   * @param {*} sal salaraitii selectati
   * @memberof SesizariChat
   */
  returnSalariati = (sal) => {
    var str = "Citit: ";
    sal.split(",").map((salariat) => {
      var f = this.$f7.data.Salariati.find((r) => {
        return r.value === parseInt(salariat);
      });
      if (f) {
        str += f.key + ", ";
      }
      return null;
    });
    return str;
  };

  /**
   * @description Functie de cautare in lista de salariati
   * @function
   * @date 08/04/2022
   * @param {*} value valoarea care se cauta
   * @memberof SesizariChat
   */
  search = (value) => {
    var toSearch = value.toString().toLowerCase().replace(/\s/g, "");
    var result = this.state.utilizatori.filter((elemBaza) => {
      var res = false;
      var valoare = elemBaza.denumire
        .toString()
        .toLowerCase()
        .replace(/\s/g, "");
      if (valoare.includes(toSearch)) {
        res = true;
      }
      return res;
    });
    this.setState({ utilizatoriRender: result });
  };

  /**
   * @description Ia lista de salariati si daca s-a conversat cu acestia sau nu
   * @function
   * @date 08/04/2022
   * @memberof SesizariChat
   */
  GetValues = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          dataLoaded: false,
        },
        () => {
          axios
            .get(
              this.$f7.data.baseURL +
                this.state.linkApi +
                "/GetSalariatiConversatiById/" +
                this.$f7.data.user.iD_SALARIAT
            )
            .then((response) => {
              var utilizatoriFinal = response.data;
              utilizatoriFinal.map((r) => {
                if (r.refSalariat === null) {
                  r.refSalariat = -1;
                }
                return null;
              });

              this.setState(
                {
                  utilizatori: response.data,
                  utilizatoriRender: response.data,
                  dataLoaded: true,
                },
                () => {
                  resolve(true);
                }
              );
            })
            .catch((error) => {
              this.setState(
                {
                  dataSource: [],
                  dataLoaded: true,
                },
                () => {
                  this.$f7.dialog.confirm(
                    "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                    "Ups...",
                    () => {
                      this.GetValues();
                    },
                    () => {
                      reject(false);
                    }
                  );
                }
              );

              console.log("error", error);
            });
        }
      );
    });
  };

  /**
   * @description Functia de arhivare si dezarhivare a mesajelor
   * @date 08/04/2022
   * @param {*} lista lista mesajelor
   * @param {*} arhivare true sau false daca trebuie arhivat sau dezarhivat
   * @memberof SesizariChat
   * @function
   */
  ArhiveazaDezarhiveazaMesaje = (lista, arhivare) => {
    return new Promise((resolve, reject) => {
      axios({
        data: { lista: lista, arhivare: arhivare },
        headers: { "Content-Type": "application/json" },
        method: "post",
        url:
          this.$f7.data.baseURL +
          this.state.linkApi +
          "/ArhivareDezarhivareMesaje",
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Adauga in baza de date o inregistrare de tip sesizare(mesaj)
   * @date 08/04/2022
   * @param {*} subiect subiectul mesajului
   * @param {*} utSel utilizatorul selectat(cu care se vorbeste curent)
   * @param {*} msg mesajul de trimis
   * @function
   * @memberof SesizariChat
   */
  sendMessage = (subiect, utSel, msg) => {
    var message = msg ?? this.state.mesaj;
    var utilizatorSelectat = utSel ?? this.state.utilizatorSelectat;
    console.log("t", utSel, msg, utilizatorSelectat, message);
    if (
      (message !== null && message !== undefined && message.length > 0) ||
      this.state.audioFile !== null
    ) {
      var sesizare = {};
      var salGasit = this.$f7.data.Salariati.find((f) => {
        return f.value === utilizatorSelectat;
      });

      sesizare["data_sesizare"] = moment(Date.now()).format();
      sesizare["pt_cine"] = utilizatorSelectat;
      sesizare["nume_pt_cine"] = salGasit ? salGasit.key : "";
      sesizare["de_la_cine"] = this.$f7.data.user.iD_SALARIAT;
      sesizare["nume_de_la_cine"] =
        this.$f7.data.user.nume + " " + this.$f7.data.user.prenume;
      sesizare["citit"] = false;
      sesizare["subiect"] = subiect ? subiect : "";
      sesizare["comentariu"] = message;
      sesizare["ref_proiect"] = this.state.idTask;

      if (this.state.audioFile) {
        var numeFisier = this.state.audioFile.name;
        var fisierType = this.state.audioFile.type;
        var reader = new FileReader();
        reader.readAsDataURL(this.state.audioFile);
        reader.onload = () => {
          var res = reader.result;
          var mesajAudio = {
            comentariu: res.split(",")[1],
            subiect: numeFisier + ";" + fisierType,
          };
          sesizare["comentariu"] = mesajAudio.comentariu;
          sesizare["subiect"] = mesajAudio.subiect;
          this.setState(
            {
              audioFile: null,
              recording: false,
              recordState: RecordState.STOP,
              idTask: null,
            },
            async () => {
              this.AddValue(sesizare);

              if (utilizatorSelectat > 0) {
                if (this.$f7.data.signalRConnection.state === "Connected") {
                  try {
                    await this.$f7.data.signalRConnection.send(
                      "Message",
                      "Atasament primit. Redeschide fereastra de mesaje pentru incarcare!",
                      utilizatorSelectat
                    );
                  } catch (e) {
                    console.log(e);
                  }
                } else {
                  alert("No connection to server yet.");
                }
              }
            }
          );
        };
        reader.onerror = (error) => {
          console.log("error", error);
        };
      } else {
        this.setState(
          {
            audioFile: null,
            recording: false,
            recordState: RecordState.STOP,
            idTask: null,
          },
          async () => {
            this.AddValue(sesizare);

            if (utilizatorSelectat > 0) {
              if (this.$f7.data.signalRConnection.state === "Connected") {
                try {
                  await this.$f7.data.signalRConnection.send(
                    "Message",
                    message,
                    utilizatorSelectat
                  );
                } catch (e) {
                  console.log(e);
                }
              } else {
                alert("No connection to server yet.");
              }
            }
          }
        );
      }
    }
  };

  renderButton = (button) => {
    return (
      <div style={{ padding: 0, display: "flex", alignItems: "center" }}>
        <div
          style={{
            display: "inline-block",
            height: 24,
            width: 3,
            backgroundColor: button.data.esteLogat ? "green" : "red",
          }}
        />
        <p
          style={{
            display: "inline-block",
            margin: 0,
            color:
              button.data.conversat ||
              this.state.utilizatorSelectat === button.data.idSalariat
                ? "black"
                : "gray",
          }}>
          {button.text}
        </p>
        {button.data.nrNecitite > 0 ? (
          <Badge
            style={{
              display: "inline-block",
              backgroundColor: "var(--f7-theme-color)",
            }}>
            {button.data.nrNecitite}
          </Badge>
        ) : null}
      </div>
    );
  };

  render() {
    return (
      <Page id={"PaginaMesaje"}>
        <Row>
          <Segmented
            style={{
              height: 68,
              position: "fixed",
              left: 0,
              bottom: 0,
              width: "30%",
            }}>
            <Button
              fill
              round
              bgColor={"orange"}
              style={{
                padding: "4px 0px",
                height: "100%",
                display: "flex",
              }}
              onClick={() => {
                this.setState(
                  { chatType: this.state.chatType === 1 ? 2 : 1 },
                  () => {
                    localStorage.setItem("chatType", this.state.chatType);
                  }
                );
              }}
              tooltip={this.state.chatType === 1 ? "Treelist" : "List"}>
              <Icon
                color={"white"}
                size={20}
                material={this.state.chatType === 1 ? "segment" : "reorder"}
              />
            </Button>

            <Button
              fill
              round
              bgColor={"red"}
              style={{
                padding: "4px 0px",
                height: "100%",
                display: "flex",
              }}
              onClick={() => {
                this.setState({ arhivate: !this.state.arhivate }, () => {
                  this.GetValuesDreapta();
                });
              }}
              tooltip={!this.state.arhivate ? "Arhivate" : "Nearhivate"}>
              <Icon
                color={"white"}
                size={20}
                material={
                  !this.state.arhivate ? "visibility" : "visibility_off"
                }
              />
            </Button>

            <Button
              tooltip={"Creare grup"}
              style={{
                padding: "4px 0px",
                height: "100%",
                display: "flex",
              }}
              fill
              round
              onClick={() => {
                this.$f7.data.router.navigate("/popupAddEdit/", {
                  props: {
                    allRequired: true,
                    inputs: [
                      {
                        key: "Denumire",
                        keyBaza: "denumire_grup",
                        group: "Info",
                      },
                      {
                        key: "Salariati",
                        keyBaza: "salariati",
                        tip: "selectboxmultiplu",
                        lista: this.Salariati,
                        group: "Detalii",
                      },
                    ],
                    title: "Creare grup",
                    buttons: [
                      {
                        key: "Salveaza",
                        onSubmit: (state, den) => {
                          var obj = { ...state.editedItem };
                          var url = "/api/GrupuriChat/PostGRUPURI_CHAT";
                          var urlAtribuire =
                            "/api/Salariati/AtribuieGrupLaSalariati";

                          axios({
                            data: {
                              denumire_grup: obj.Denumire,
                              id_creator: this.$f7.data.user.iD_SALARIAT,
                            },
                            headers: { "Content-Type": "application/json" },
                            method: "post",
                            url: url,
                          })
                            .then((response) => {
                              var idGrup = response.data.iD_GRUP;
                              var listaIduri = [];
                              obj.Salariati.map((idSal) => {
                                var intIdSal = parseInt(idSal);
                                listaIduri.push(intIdSal);
                                return null;
                              });

                              axios({
                                data: {
                                  IdGrup: idGrup,
                                  ListaIdSalariati: listaIduri,
                                },
                                headers: {
                                  "Content-Type": "application/json",
                                },
                                method: "post",
                                url: urlAtribuire,
                              })
                                .then((response2) => {
                                  this.$f7.methods.openNotification(
                                    "Grup creat"
                                  );
                                  this.$f7.panel.close(den);
                                })
                                .catch((err) => {
                                  this.$f7.methods.openNotification(
                                    "Eroare:" + err
                                  );
                                  this.$f7.panel.close(den);
                                });
                            })
                            .catch((err) => {
                              this.$f7.methods.openNotification(
                                "Eroare:" + err
                              );
                              this.$f7.panel.close(den);
                            });
                        },
                      },
                    ],
                  },
                });
              }}>
              <Icon color={"white"} size={20} material="add" />
            </Button>
            {this.state.utilizatorSelectat < 0 &&
            (this.$f7.data.user.administrator ||
              this.$f7.data.user.iD_SALARIAT === this.state.idCreator) ? (
              <Button
                tooltip={"Editare grup"}
                style={{
                  height: "100%",
                  padding: "4px 0px",
                  display: "flex",
                }}
                fill
                round
                bgColor={"red"}
                onClick={() => {
                  var idGrupGet = -1 * this.state.utilizatorSelectat;
                  axios
                    .get("/api/GrupuriChat/GetGrupEditare/" + idGrupGet)
                    .then((responseGetGrupEditare) => {
                      var dateGrupEditare = responseGetGrupEditare.data;
                      var denGrup = dateGrupEditare.denumireGrup;
                      var listaSalariati = dateGrupEditare.listaSalariati;

                      this.$f7.data.router.navigate("/popupAddEdit/", {
                        props: {
                          allRequired: true,
                          inputs: [
                            {
                              key: "Denumire",
                              keyBaza: "denumire_grup",
                              defaultValue: denGrup,
                              group: "Info",
                            },
                            {
                              key: "Salariati",
                              keyBaza: "salariati",
                              tip: "selectboxmultiplu",
                              lista: this.Salariati,
                              defaultValue: listaSalariati,
                              group: "Detalii",
                            },
                          ],
                          title: "Editare grup",
                          buttons: [
                            {
                              key: "Salveaza",
                              onSubmit: (state, den) => {
                                var obj = { ...state.editedItem };
                                var urlAtribuire =
                                  "/api/GrupuriChat/ActualizareGrupEditare";

                                axios({
                                  data: {
                                    idGrup: idGrupGet,
                                    denumireGrup: obj.Denumire,
                                    listaSalariati: obj.Salariati,
                                  },
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                  method: "post",
                                  url: urlAtribuire,
                                })
                                  .then((response) => {
                                    this.$f7.methods.openNotification(
                                      "Grup modificat"
                                    );
                                    this.$f7.panel.close(den);
                                  })
                                  .catch((err) => {
                                    this.$f7.methods.openNotification(
                                      "Eroare:" + err
                                    );
                                    this.$f7.panel.close(den);
                                  });
                              },
                            },
                          ],
                        },
                      });
                    })
                    .catch((err) => {
                      this.$f7.methods.openNotification("Eroare" + err);
                    });
                }}>
                <Icon color={"white"} size={20} material="settings" />
              </Button>
            ) : null}
            {this.state.utilizatorSelectat !== null ? (
              <Button
                fill
                round
                bgColor={"green"}
                style={{
                  padding: "4px 0px",
                  height: "100%",
                  display: "flex",
                }}
                onClick={() => {
                  if (this.$f7.data.callComponent) {
                    this.$f7.data.callComponent.setState({
                      activeCall: true,
                    });
                  }

                  this.$f7.data.router.navigate("/popupCall/", {
                    props: {
                      onPopupClose: async () => {
                        if (
                          this.$f7.data.signalRConnection.state === "Connected"
                        ) {
                          try {
                            if (this.$f7.data.callComponent) {
                              if (!this.$f7.data.callComponent.state.isBusy) {
                                await this.$f7.data.signalRConnection.send(
                                  this.state.utilizatorSelectat < 0
                                    ? "LeaveGroup"
                                    : "EndCallByUserId",
                                  this.state.utilizatorSelectat < 0
                                    ? -1 * this.state.utilizatorSelectat
                                    : this.state.utilizatorSelectat
                                );
                              }

                              this.$f7.data.callComponent.setState({
                                activeCall: false,
                                isBusy: false,
                              });
                            } else {
                              await this.$f7.data.signalRConnection.send(
                                this.state.utilizatorSelectat < 0
                                  ? "LeaveGroup"
                                  : "EndCallByUserId",
                                this.state.utilizatorSelectat < 0
                                  ? -1 * this.state.utilizatorSelectat
                                  : this.state.utilizatorSelectat
                              );
                            }
                          } catch (e) {
                            console.log(e);
                          }
                        } else {
                          alert("No connection to server yet.");
                        }
                      },
                      component:
                        this.state.utilizatorSelectat < 0 ? (
                          <GroupCall
                            call={true}
                            group={-1 * this.state.utilizatorSelectat}
                          />
                        ) : (
                          <MainCall
                            call={true}
                            callerSignal={null}
                            userId={this.state.utilizatorSelectat}
                          />
                        ),
                    },
                  });
                }}
                tooltip={"Apeleaza"}>
                <Icon color={"white"} size={20} material={"call"} />
              </Button>
            ) : null}
          </Segmented>
          <Col
            width={30}
            style={{
              overflow: "auto",
              height: "calc(100vh - 110px)",
              position: "sticky",
              top: 0,
              left: 0,
            }}>
            {this.state.chatType === 2 ? (
              <List id={"ListaChat"}>
                <Searchbar
                  className={this.searchName}
                  onClickDisable={() => {
                    var source = [...this.state.utilizatori];
                    this.setState({ utilizatoriRender: source });
                  }}
                  borderColor={"transparent"}
                  onInput={(e) => {
                    var value = e.target.value;
                    this.search(value);
                  }}
                />
                {this.state.utilizatoriRender.map((r, index) => (
                  <ListItem
                    key={"utilizatorSesizari" + index}
                    title={r.denumire}
                    style={{
                      color:
                        r.conversat ||
                        this.state.utilizatorSelectat === r.idSalariat
                          ? "black"
                          : "gray",
                      backgroundColor:
                        this.state.utilizatorSelectat === r.idSalariat
                          ? "var(--f7-theme-color-tint)"
                          : "transparent",
                    }}
                    after={
                      this.state.forward.show ? (
                        <Button
                          tooltip={"Forward"}
                          style={{
                            padding: 0,
                          }}>
                          <Icon
                            color={
                              r.DeLa === this.$f7.data.user.iD_SALARIAT
                                ? "white"
                                : "var(--f7-theme-color)"
                            }
                            size={16}
                            material={"forward"}
                          />
                        </Button>
                      ) : r.nrNecitite > 0 ? (
                        <Badge
                          style={{
                            backgroundColor: "var(--f7-theme-color)",
                          }}>
                          {r.nrNecitite}
                        </Badge>
                      ) : null
                    }
                    onClick={() => {
                      if (!this.state.forward.show) {
                        console.log("1");
                        this.setState(
                          {
                            utilizatorSelectat: r.idSalariat,
                            mesaj: "",
                            idCreator: r.idCreator,
                          },
                          () => {
                            this.GetValuesDreapta();
                          }
                        );
                      } else {
                        console.log("2");
                        this.sendMessage(
                          null,
                          r.idSalariat,
                          this.state.forward.message
                        );
                      }
                    }}>
                    <div
                      slot="media"
                      style={{
                        height: 24,
                        width: 3,
                        backgroundColor: r.esteLogat ? "green" : "red",
                      }}
                    />
                    {!r.esteLogat ? (
                      <div
                        style={{
                          fontSize: 9,
                          position: "absolute",
                          top: 0,
                          right: 0,
                        }}
                        slot="">
                        {this.ConvertToFriendlyTime(r.ultimaLogare)}
                      </div>
                    ) : null}
                  </ListItem>
                ))}
              </List>
            ) : (
              <>
                <div style={{ zIndex: 1, position: "absolute" }}>
                  <Checkbox
                    style={{ position: "absolute", top: 0, left: 0 }}
                    checked={this.state.filtrat}
                    onChange={() => {
                      this.setState({
                        filtrat: !this.state.filtrat,
                        filterValue: !this.state.filtrat
                          ? ["esteLogat", "anyof", [true]]
                          : ["esteLogat", "anyof", [true, false, null]],
                      });
                    }}
                  />
                  <span style={{ marginLeft: 20 }}>
                    {this.state.filtrat ? "Toti" : "Activi"}
                  </span>
                </div>

                <TreeList
                  filterMode={"fullBranch"}
                  onInitialized={(e) => {
                    this.setState({
                      componentaTree: e.component,
                    });
                  }}
                  autoExpandAll={true}
                  focusedRowEnabled={true}
                  onFocusedRowChanged={(e) => {
                    if (e.row !== undefined) {
                      if (e.row.data !== undefined) {
                        var data = e.row.data;
                        this.setState(
                          {
                            utilizatorSelectat: data.idSalariat,
                            mesaj: "",
                            idCreator: data.idCreator,
                          },
                          () => {
                            this.GetValuesDreapta();
                          }
                        );
                      }
                    }
                  }}
                  filterValue={this.state.filterValue}
                  focusedRowKey={this.state.utilizatorSelectat}
                  rootValue={-1}
                  dataSource={this.state.utilizatoriRender}
                  keyExpr={"idSalariat"}
                  parentIdExpr={"refSalariat"}
                  id="TreeListMesajeSalariati"
                  columns={[
                    {
                      width: 200,
                      dataField: "denumire",
                      caption: "Denumire",
                      cellTemplate: "cellTemplateTest",
                    },
                    {
                      width: 0,
                      dataField: "esteLogat",
                    },
                  ]}
                  showColumnHeaders={true}
                  showColumnLines={true}
                  showBorders={true}
                  searchPanel={{
                    visible: true,
                    width: 160,
                  }}>
                  <Template
                    name="cellTemplateTest"
                    render={this.renderButton}
                  />
                </TreeList>
              </>
            )}
          </Col>
          <Col width={70}>
            <>
              <Popover
                style={{ width: "fit-content" }}
                className="popoverEmoji"
                onPopoverClosed={() => {
                  this.setState({ openPopoverEmoji: false });
                }}>
                {this.state.openPopoverEmoji ? (
                  <Picker
                    onEmojiClick={(e, f) => {
                      var emoji = f.emoji;
                      var mesaj = this.state.mesaj;
                      mesaj += emoji;
                      this.setState({ mesaj: mesaj }, () => {
                        var popoverEmoji =
                          this.$f7.popover.get(".popoverEmoji");
                        if (popoverEmoji) {
                          popoverEmoji.close();
                        }
                      });
                    }}
                  />
                ) : null}
              </Popover>

              <Messages>
                {this.state.dataSource.map((r, index, arr) => (
                  <Message
                    style={{ minWidth: 100 }}
                    className={
                      this.state.dataLoaded2 === false
                        ? "skeleton-effect-fade"
                        : ""
                    }
                    onRightClick={(e) => {
                      console.log("e", e);
                    }}
                    name={r.NumeDeLaCine}
                    key={index}
                    text={
                      r.Mesaj.length > 500 ? (
                        r.Subiect.includes(";") ? (
                          r.Subiect.split(";")[1].includes("audio") ? (
                            <audio
                              style={{ height: 30, width: 170 }}
                              src={
                                "data:" +
                                r.Subiect.split(";")[1] +
                                ";base64," +
                                r.Mesaj
                              }
                              controls
                            />
                          ) : r.Subiect.includes("image") ? (
                            <img
                              alt={"Imagine"}
                              style={{ width: "100%", maxWidth: 300 }}
                              src={
                                "data:" +
                                r.Subiect.split(";")[1] +
                                ";base64," +
                                r.Mesaj
                              }
                            />
                          ) : r.Subiect.includes("video") ? (
                            <video
                              controls
                              style={{ width: "100%", maxWidth: 300 }}>
                              <source
                                type={r.Subiect.split(";")[1]}
                                src={
                                  "data:" +
                                  r.Subiect.split(";")[1] +
                                  ";base64," +
                                  r.Mesaj
                                }
                              />
                            </video>
                          ) : (
                            <div
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                var content = r.Mesaj;
                                var contentType = r.Subiect.split(";")[1];
                                var blob = this.b64toBlob(content, contentType);
                                var denumire = r.Subiect.split(";")[0];
                                saveAs(blob, denumire);
                              }}>
                              {" "}
                              {r.Subiect.split(";")[0]}
                            </div>
                          )
                        ) : (
                          r.Mesaj
                        )
                      ) : (
                        r.Mesaj
                      )
                    }
                    type={
                      r.DeLa === this.$f7.data.user.iD_SALARIAT
                        ? "sent"
                        : "received"
                    }
                    tail={true}
                    textHeader={
                      r.NumeTask ? (
                        <span
                          style={{
                            float: "left",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            this.$f7.methods.openMainDreaptaById(r.NumeTask);
                            var panelMesaje = this.$f7.panel.get(
                              "." + this.props.denumire
                            );
                            if (panelMesaje) {
                              panelMesaje.close();
                            }
                          }}>
                          Catre task
                        </span>
                      ) : null
                    }
                    textFooter={
                      <div>
                        <Icon
                          tooltip={
                            r.CititDe !== null && r.CititDe !== undefined
                              ? this.returnSalariati(r.CititDe)
                              : r.Citit
                              ? "Citit: " + r.NumePentruCine
                              : "Necitit"
                          }
                          color={
                            r.DeLa === this.$f7.data.user.iD_SALARIAT
                              ? "white"
                              : "var(--f7-theme-color)"
                          }
                          size={16}
                          material={
                            r.CititDe !== null
                              ? "done"
                              : r.Citit
                              ? "done"
                              : "close"
                          }
                        />
                        <span>{moment(r.Data).format("DD.MM HH:mm")}</span>
                      </div>
                    }
                    first={index === 0 ? true : r.DeLa !== arr[index - 1].DeLa}
                    sameName={
                      index === 0 ? false : r.DeLa === arr[index - 1].DeLa
                    }>
                    {r.DeLa === this.$f7.data.user.iD_SALARIAT ||
                    r.DeLa === null ? (
                      <Button
                        tooltip={!r.IsArhivat ? "Arhiveaza" : "Dezarhiveaza"}
                        onClick={() => {
                          if (!r.IsArhivat) {
                            //arhiveaza
                            this.ArhiveazaDezarhiveazaMesaje([r.Id], true)
                              .then((response) => {
                                this.GetValuesDreapta();
                              })
                              .catch((err) => {
                                this.$f7.methods.openNotification(
                                  "Eroare la arhivare/dezarhivare:" + err
                                );
                              });
                          } else {
                            //dezarhiveaza
                            this.ArhiveazaDezarhiveazaMesaje([r.Id], false)
                              .then((response) => {
                                this.GetValuesDreapta();
                              })
                              .catch((err) => {
                                this.$f7.methods.openNotification(
                                  "Eroare la arhivare/dezarhivare:" + err
                                );
                              });
                          }
                        }}
                        //   style={{ position: "absolute", top: 0, right: 0, width: 20, height: 20 }}
                        style={{
                          minWidth: 20,
                          padding: 0,
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          width: 20,
                          height: 20,
                        }}>
                        <Icon
                          color={
                            r.DeLa === this.$f7.data.user.iD_SALARIAT
                              ? "white"
                              : "var(--f7-theme-color)"
                          }
                          size={16}
                          material={
                            r.IsArhivat ? "visibility" : "visibility_off"
                          }
                        />
                      </Button>
                    ) : null}
                    {r.DeLa === this.$f7.data.user.iD_SALARIAT ? (
                      <Button
                        tooltip={"Sterge"}
                        onClick={() => {
                          var id = r.Id;
                          this.DeleteValue(id);
                        }}
                        // style={{ position: "absolute", top: 20, right: 0, width: 20, height: 20 }}
                        style={{
                          minWidth: 20,
                          padding: 0,
                          position: "absolute",
                          bottom: 0,
                          left: 20,
                          width: 20,
                          height: 20,
                        }}>
                        <Icon
                          color={
                            r.DeLa === this.$f7.data.user.iD_SALARIAT
                              ? "white"
                              : "var(--f7-theme-color)"
                          }
                          size={16}
                          material={"delete"}
                        />
                      </Button>
                    ) : null}
                    {r.Subiect.split(";")[1] ? (
                      <Button
                        tooltip={"Descarca"}
                        onClick={() => {
                          var content = r.Mesaj;
                          var contentType = r.Subiect.split(";")[1];
                          var blob = this.b64toBlob(content, contentType);
                          var denumire = r.Subiect.split(";")[0];
                          saveAs(blob, denumire);
                        }}
                        style={{
                          minWidth: 20,
                          padding: 0,
                          position: "absolute",
                          bottom: 0,
                          left: 40,
                          width: 20,
                          height: 20,
                        }}>
                        <Icon
                          color={
                            r.DeLa === this.$f7.data.user.iD_SALARIAT
                              ? "white"
                              : "var(--f7-theme-color)"
                          }
                          size={16}
                          material={"file_download"}
                        />
                      </Button>
                    ) : null}
                    <Button
                      tooltip={"Forward"}
                      onClick={() => {
                        this.setState({
                          forward: {
                            show: true,
                            message: r.Mesaj,
                          },
                        });
                      }}
                      style={{
                        minWidth: 20,
                        padding: 0,
                        position: "absolute",
                        bottom: 0,
                        left: 60,
                        width: 20,
                        height: 20,
                      }}>
                      <Icon
                        color={
                          r.DeLa === this.$f7.data.user.iD_SALARIAT
                            ? "white"
                            : "var(--f7-theme-color)"
                        }
                        size={16}
                        material={"forward"}
                      />
                    </Button>
                  </Message>
                ))}
              </Messages>

              <Messagebar
                name={"mesajWrapperName"}
                id={"mesajInputWrapper"}
                inputId={"mesajInput"}
                resizable={true}
                disabled={
                  !this.$f7.data.user.administrator &&
                  this.state.utilizatorSelectat === null
                }
                style={{
                  position: "fixed",
                  bottom: 0,
                  left: "30%",
                  width: "70%",
                  backgroundColor: "var(--f7-actions-bg-color)",
                  height: "auto",
                  maxHeight: "300px",
                }}
                placeholder={"Mesaj..."}
                value={this.state.mesaj}
                onInput={(e) => {
                  this.setState({ mesaj: e.target.value });
                }}>
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    top: "0px",
                    height: "30px",
                  }}>
                  <AudioReactRecorder
                    canvasWidth={30}
                    canvasHeight={50}
                    state={this.state.recordState}
                    onStop={(e) => {
                      let metadata = {
                        type: "audio/wav",
                      };
                      let file = new File([e.blob], "audio.wav", metadata);
                      this.setState({ audioFile: file }, () => {
                        this.sendMessage();
                      });
                    }}
                  />

                  <Link
                    style={{ padding: 0, color: "orange", height: "100%" }}
                    tooltip={"Emoji"}
                    iconSize={20}
                    iconMd={"material:emoji_emotions"}
                    slot="inner-start"
                    onClick={(e) => {
                      this.setState({ openPopoverEmoji: true }, () => {
                        var popoverEmoji =
                          this.$f7.popover.get(".popoverEmoji");
                        var t = e.target;
                        if (popoverEmoji) {
                          popoverEmoji.open(t);
                        }
                      });
                    }}
                  />

                  <Link
                    style={{ padding: 0, height: "100%" }}
                    tooltip={
                      this.state.recording
                        ? "Stop inregistrare audio"
                        : "Start inregistrare audio"
                    }
                    iconMd={
                      this.state.recording
                        ? "material:stop"
                        : "material:play_arrow"
                    }
                    slot="inner-start"
                    onClick={() => {
                      this.setState({
                        recording: !this.state.recording,
                        recordState: this.state.recording
                          ? RecordState.STOP
                          : RecordState.START,
                      });
                    }}
                  />
                  <Link
                    style={{ padding: 0, height: "100%" }}
                    tooltip={
                      this.state.speechStarted
                        ? "Oprire speech to text"
                        : "Start speech to text"
                    }
                    slot="inner-start"
                    onClick={() => {
                      this.setState(
                        { speechStarted: !this.state.speechStarted },
                        () => {
                          if (!this.state.speechStarted) {
                            //STOP
                            this.$f7.methods
                              .stopSpeech()
                              .then((text) => {})
                              .catch((error) => {
                                console.log("Err", error);
                              });
                          } else {
                            //START
                            var oldText = "";
                            this.$f7.methods.startSpeech((text, latestWord) => {
                              var comDeEditat = this.state.mesaj;

                              comDeEditat += latestWord.replace(oldText, "");
                              oldText = latestWord;
                              this.setState({ mesaj: comDeEditat }, () => {});
                            });
                          }
                        }
                      );
                    }}>
                    <Icon
                      style={{
                        color: this.state.speechStarted ? "red" : "teal",
                        left: "-2px",
                      }}
                      size={20}
                      material={this.state.speechStarted ? "mic_off" : "mic"}
                    />
                  </Link>
                  <input
                    onChange={(e, f, g) => {
                      var file = e.target.files[0];
                      this.setState({ audioFile: file }, () => {
                        this.sendMessage();
                      });
                    }}
                    type="file"
                    id="theFilePicker"
                    style={{ display: "none" }}
                  />
                  <Link
                    style={{
                      padding: 0,
                      color: "darkgray",
                      height: "100%",
                    }}
                    tooltip={"Atasamente"}
                    iconMd={"material:attach_file"}
                    slot="inner-start"
                    onClick={(e) => {
                      var elem = document.getElementById("theFilePicker");
                      if (elem && document.createEvent) {
                        var evt = document.createEvent("MouseEvents");
                        evt.initEvent("click", true, false);
                        elem.dispatchEvent(evt);
                      }
                    }}
                  />

                  {this.state.idTask ? (
                    <Link
                      onClick={() => {
                        this.$f7.dialog.confirm(
                          "Doriti sa detasati taskul de la mesaj?",
                          "Atentie",
                          () => {
                            this.setState({ idTask: null });
                          },
                          () => {}
                        );
                      }}
                      style={{ padding: 0, height: "100%" }}
                      tooltip={
                        "Task: " +
                        this.$f7.data.Proiecte.find((r) => {
                          return r.value === this.state.idTask;
                        }).key
                      }
                      slot="inner-start">
                      <Icon
                        style={{ color: "red" }}
                        size={20}
                        material={"assignment"}
                      />
                    </Link>
                  ) : null}
                </div>
                <Link
                  style={{
                    padding: 0,
                    position: "absolute",
                    right: 0,
                    height: "100%",
                    color: "white",
                    backgroundColor: "teal",
                  }}
                  iconMd="material:send"
                  onClick={() => {
                    this.sendMessage();
                  }}
                />
              </Messagebar>
            </>
          </Col>
        </Row>
      </Page>
    );
  }
}
export default SesizariChat;
