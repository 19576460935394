import React from "react";
import {
  Segmented,
  Button,
  List,
  ListItem,
  Icon,
  Row,
  Col,
} from "framework7-react";
import axios from "axios";
import DataGrid from "../../Datagrid/DataGrid";
import moment from "moment";
import ATMain from "../../AlocareTask/ATMain";
/**
 * @description Afiseaza lista cu toate taskurile alocate utilizatorului curent selectat/logat
 * @date 08/04/2022
 * @class SarciniAlocateGrid
 * @extends {React.Component}
 */
class SarciniAlocateGrid extends React.Component {
  constructor(props) {
    super(props);

    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;

    this.state = {
      linkApiProiecte: "/api/Proiecte",
      durata: null,
      durataTotal: null,
      lat: 0,
      lng: 0,
      idProiectVechi: null,
      dataLoaded: false,
      denumire: "Sarcini alocate",
      height: window.innerHeight - 96,
      linkApi: "/api/SarciniSalariat",
      linkApiRealizari: "/api/RealizareSarcini",
      linkApiGetProiect: "/api/Proiecte/GetPROIECTESauArhivate",
      linkApiUpdateProiect: "/api/Proiecte/PutPROIECTE",
      linkApiDelegatii: "/api/Delegatii",
      linkApiDetaliu: "/api/DetaliuDelegatii",
      dataSource: [],
      dataSourceHidden: [],
      inputs: [
        { key: "💾", keyBaza: "areDocumente", tip: "boolean", disabled: true },
        { key: "IdSarcina", keyBaza: "idSarcina", tip: "number" },
        { key: "IdProiect", keyBaza: "idProiect", tip: "number" },
        { key: "Denumire", keyBaza: "denumire", columnWidth: "250px" },
        { key: "Cerinta", keyBaza: "comentariu", columnWidth: "500px" },
        { key: "Parinte", keyBaza: "parinte" },
        {
          key: "DataCreare",
          keyBaza: "dataCreare",
          tip: "datebox",
          columnWidth: "100px",
        },
        { key: "DataAlocarii", keyBaza: "dataAlocarii", tip: "datebox" },
        { key: "DeCine", keyBaza: "deCine" },
        { key: "Deadline", keyBaza: "deadline", tip: "datebox" },
        {
          key: "Client",
          keyBaza: "idClient",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
          columnWidth: "20px",
        },
        { key: "Sursa", keyBaza: "sursa", columnWidth: "20px" },
        {
          key: "PR",
          keyBaza: "idPrioritate",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
          columnWidth: "20px",
        },
        { key: "IsArhivat", keyBaza: "isArhivat", disabled: true, tip: "bool" },
        { key: "IsHidden", keyBaza: "isHidden", disabled: true, tip: "bool" },
        { key: "Path", keyBaza: "path" },
        {
          key: "OperatorAdaugare",
          keyBaza: "id_operator_adaugare",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
        },
        { key: "BugetTimp", keyBaza: "buget_timp", tip: "number" },
        { key: "TimpPlanD", keyBaza: "timp_plan_d", tip: "number" },
        { key: "UltimaStare", keyBaza: "ultimaStare", tip: "number" },
        {
          key: "NivelProiect",
          keyBaza: "id_nivel_proiect",
          tip: "selectbox",
          lista: this.$f7.data.NivelProiect,
        },
        { key: "F", keyBaza: "facturat", disabled: true, tip: "bool" },
        { key: "TimpLucrat", keyBaza: "timp_l", tip: "number" },
        { key: "PVuri", keyBaza: "apartenenta_pv" },
      ],
      hiddenInputs: [
        { key: "Id_Realizare" },
        {
          key: "NumeSalariat",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        {
          key: "Proiect",
          keyBaza: "iD_PROIECT",
          tip: "selectbox",
          lista: this.$f7.data.Proiecte,
        },
        { key: "MomentInceput", tip: "datebox" },
        { key: "MomentSfarsit", tip: "datebox" },
        { key: "Unitati", tip: "number" },
        {
          key: "Operatie",
          tip: "selectbox",
          lista: this.$f7.data.Operatiuni,
          required: true,
          defaultValue: null,
        },
        { key: "Client", tip: "selectbox", lista: this.$f7.data.Firme },
        {
          key: "Stadiu",
          tip: "selectbox",
          lista: this.$f7.data.Stadiu,
          required: true,
          defaultValue: null,
        },
        {
          key: "Comentariu",
          required: false,
          defaultValue: "",
          tip: "textarea",
          inputHeight: 100,
          maxlength: 10000,
        },
        {
          key: "Observatie",
          required: false,
          defaultValue: "",
          tip: "textarea",
          inputHeight: 100,
          maxlength: 10000,
        },
        {
          key: "ComentariuPv",
          required: false,
          defaultValue: "",
          tip: "textarea",
          inputHeight: 250,
          maxlength: 10000,
        },
      ],
      sfarsit: false,
      idSelected: null,
      idSarcina: null,
      idPropunere: null,
      deschideNou: false,
      proiectDeschis: null,
      inchideDupaOprire: false,
      realocaDupaOprire: false,
      comInchidereTask: false,
      //dataTaskUitat: moment().format(),
      listaClientiTelefon: [],
      listaProiecteTelefon: [],
      idClientTelefon: null,
      idProiectParinteTelefon: null,
    };

    this.refDataGrid = React.createRef();
    this.refHiddenDataGrid = React.createRef();
  }

  /**
   * @description Actualizeaza informatiile taskului curent selectat in baza de date
   * @date 08/04/2022
   * @param {*} obj obiect cu noile valori ale proiectului selectat
   * @param {*} id id-ul proiectului selectat
   * @function
   * @memberof SarciniAlocateGrid
   */
  UpdateValueProiecte = (obj, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            this.state.linkApiProiecte +
            "/GetPROIECTE/" +
            id
        )
        .then((response) => {
          var obj2 = response.data;
          var id_stadiu = obj2["iD_STADIU"];
          Object.keys(obj).map((trasatura) => {
            Object.keys(obj2).map((trasatura2) => {
              if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
                obj2[trasatura2] = obj[trasatura];
              }
              return false;
            });
            return false;
          });
          obj2["iD_STADIU"] = id_stadiu;
          var url =
            this.$f7.data.baseURL +
            this.state.linkApiProiecte +
            "/PutPROIECTE/" +
            id;
          axios({
            data: obj2,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: url,
          })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              console.log("error", error);
              this.$f7.methods.openNotification("Eroare la modificare");
              reject(error);
            });
        });
    });
  };

  componentDidMount = () => {
    // axios.get("/api/SarciniSalariat/GetMantisFirme"); //TREBUIE STERS!!!
    // axios.get("/api/SarciniSalariat/GetMantisHasAttachments");

    this.$f7.data.selectedGridLeft = this;
    this.GetValues()
      .then((response) => {
        axios.get("/api/SarciniSalariat/GetMantisTaskuri");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  AcceptareApel = () => {
    return new Promise((resolve, reject) => {
      this.$f7.methods.esteDelegatie().then((delegatieCurenta) => {
        if (delegatieCurenta !== false) {
          this.$f7.methods.openNotification(
            "Aceasta functie nu este disponibila daca exista o delegatie activa!",
            "Avertizare"
          );
          resolve("nu se poate prelua alt apel");
        } else {
          let mesaj =
            this.state.proiectDeschis !== null
              ? "Taskul '" +
                this.$f7.data.Proiecte.filter((p) => {
                  return p.value === this.state.proiectDeschis.IdProiect;
                })[0].key +
                "' este pornit in acest moment. Nu iti face griji, <u>acesta se opreste automat</u>."
              : "<u>Nu exista</u> un alt task pornit in acest moment.";

          this.$f7.dialog.confirm(
            "<h4>Doriti sa preluati un apel telefonic?</h4><small>" +
              mesaj +
              "</small>",
            "Avertizare",
            () => {
              //Ok
              this.$f7.preloader.show();
              let currentDate = moment();
              let task = {
                DENUMIRE:
                  "Solicitare telefonica de la un client la data " +
                  currentDate.format(),
                REF_PROIECT: 38878,
                ID_STADIU: 2,
                FOLOSIT: true,
                TASK: true,
                isArhivat: 0,
                isHidden: 0,
                id_prioritate: 5,
                id_nivel_proiect: 4,
                id_tip_actiune: null,
                id_tip_proiect: 23,
                data_start: currentDate.format(),
                deadline: currentDate.clone().add(2, "days").format(),
                valoare_minima: null,
                valoare_maxima: null,
                buget: null,
                buget_timp: null,
                proc_risc: null,
                cost_estimat: null,
                cost_real_calc: null,
                timp_plan_m: null,
                timp_plan_sp: null,
                timp_plan_d: 30,
                comentariu:
                  "Solicitare telefonica de la un client la data " +
                  currentDate.format() +
                  ". Necesita editare/completare dupa terminarea apelului." +
                  (this.state.proiectDeschis !== null
                    ? " Taskul '" +
                      this.$f7.data.Proiecte.filter(
                        (p) => p.value === this.state.proiectDeschis.IdProiect
                      )[0].key +
                      "' a fost oprit automat la inceputul apelului si necesita atentie."
                    : ""),
                id_departament: this.$f7.data.user.iD_DEPARTAMENT,
                id_responsabil: null,
                director_radacina: null,
                taskcomplete: null,
                options: null,
                eventtype: null,
                nivel: null,
                id_dezvoltator: null,
                id_sefproiect: null,
                id_arbitru: null,
                id_manager: null,
                timp_plan_a: null,
                topevent: null,
                aprobat_d: null,
                aprobat_a: null,
                aprobat_s: null,
                aprobat_m: null,
                chelt_sal: null,
                //parinti: null,
                timp_l: null,
                ordine: null,
                id_client: null,
                data_creare: currentDate.format(),
                id_termen: null,
                data_final: currentDate.clone().add(2, "days").format(),
                id_operatiuni: null,
                facturat: null,
                venit: null,
                stadiu1: null,
                tip_exc: null,
                userImport: null,
                dataImport: null,
                idTaskSimilar: null,
                operatorInchidereTask: true,
                id_tip_sursa: 2,
                isModul: false,
                id_operator_adaugare: this.$f7.data.user.iD_SALARIAT,
              };

              axios({
                data: task,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: this.$f7.data.baseURL + "/api/Proiecte/PostPROIECTE",
              })
                .then((response) => {
                  let idTaskNou = response.data.iD_PROIECT;
                  this.$f7.data.allProiecte.push(response.data);
                  this.$f7.data.nearhivateProiecte.push(response.data);
                  this.$f7.data.Proiecte.push({
                    key: response.data.denumire,
                    value: idTaskNou,
                  });

                  let apelPrimit = {
                    ref_proiect: idTaskNou,
                    ref_salariat: this.$f7.data.idSalariatFunctii,
                    data_primire: moment().format(),
                    activ: true,
                  };

                  axios({
                    data: apelPrimit,
                    headers: { "Content-Type": "application/json" },
                    method: "post",
                    url:
                      this.$f7.data.baseURL +
                      "/api/PrimireApel/PostPRIMIRE_APEL",
                  })
                    .then((response1) => {
                      axios
                        .get(
                          this.$f7.data.baseURL +
                            this.state.linkApi +
                            "/GetSARCINI_SALARIATRespinsByProiect/" +
                            idTaskNou
                        )
                        .then((response2) => {
                          let sarcina = response2.data;

                          if (sarcina === "") {
                            let sarcinaAsumata = {
                              iD_PROIECT: parseInt(idTaskNou),
                              iD_SALARIAT: this.$f7.data.idSalariatFunctii,
                              dE_CINE: this.$f7.data.user.iD_SALARIAT, //notsure
                              datA_ALOCARII: moment().format(),
                              datA_DEALOCARII: null,
                              dealoC_CINE: null,
                              stare: 1,
                            };

                            axios({
                              data: sarcinaAsumata,
                              headers: { "Content-Type": "application/json" },
                              method: "post",
                              url:
                                this.$f7.data.baseURL +
                                this.state.linkApi +
                                "/PostSARCINI_SALARIAT",
                            })
                              .then((response3) => {
                                let idSarcinaNoua = response3.data.iD_SARCINA;

                                axios({
                                  data: {
                                    IdSalariat: this.$f7.data.idSalariatFunctii,
                                    InchisDeSalariat: true,
                                  },
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                  method: "put",
                                  url:
                                    this.$f7.data.baseURL +
                                    this.state.linkApiRealizari +
                                    "/PutSfarsit",
                                })
                                  .then((response4) => {
                                    let ceReturneaza = response4.data;

                                    if (
                                      ceReturneaza !== "Nu exista task deschis."
                                    ) {
                                      axios
                                        .get(
                                          this.$f7.data.baseURL +
                                            this.state.linkApiRealizari +
                                            "/GetREALIZARE_SARCINI/" +
                                            parseInt(ceReturneaza)
                                        )
                                        .then((response5) => {
                                          let realizareCurenta = response5.data;
                                          realizareCurenta["comentariU1"] =
                                            "Activitate intrerupta de preluarea unui apel de la un client la data " +
                                            currentDate.format() +
                                            ". \nDe revenit si editat/completat aceasta activitate!";

                                          axios({
                                            data: realizareCurenta,
                                            headers: {
                                              "Content-Type":
                                                "application/json",
                                            },
                                            method: "put",
                                            url:
                                              this.$f7.data.baseURL +
                                              this.state.linkApiRealizari +
                                              "/PutREALIZARE_SARCINI/" +
                                              parseInt(ceReturneaza),
                                          })
                                            .then((response6) => {
                                              let data = {
                                                Comentariu:
                                                  "Despre ce a fost apelul? Completeaza aici!",
                                                Unitati: 1,
                                                asumat: true,
                                                comentariU1:
                                                  "De editat dupa sfarsirea conversatiei telefonice!",
                                                dupa_lucru: 0,
                                                iD_CLIENT: null,
                                                iD_SALARIAT:
                                                  this.$f7.data
                                                    .idSalariatFunctii,
                                                id_proiect: idTaskNou,
                                                iesire: null,
                                                intrare: currentDate.format(),
                                                procent_realizare: 0,
                                              };

                                              axios({
                                                data: data,
                                                headers: {
                                                  "Content-Type":
                                                    "application/json",
                                                },
                                                method: "post",
                                                url:
                                                  this.$f7.data.baseURL +
                                                  this.state.linkApiRealizari +
                                                  "/PostInceput",
                                              })
                                                .then((response7) => {
                                                  let ceReturneaza2 =
                                                    response7.data;

                                                  if (
                                                    ceReturneaza2 ===
                                                    "S-a adaugat."
                                                  ) {
                                                    this.$f7.preloader.hide();

                                                    this.$f7.methods.openNotification(
                                                      "Task pornit pentru apelul preluat: " +
                                                        this.$f7.data.Proiecte.filter(
                                                          (p) => {
                                                            return (
                                                              p.value ===
                                                              idTaskNou
                                                            );
                                                          }
                                                        )[0].key,
                                                      "Success"
                                                    );

                                                    this.setState(
                                                      {
                                                        idSelected: idTaskNou,
                                                        idSarcina:
                                                          idSarcinaNoua,
                                                      },
                                                      () => {
                                                        this.GetValues();
                                                        resolve(
                                                          "apel preluat cu oprire task curent"
                                                        );
                                                      }
                                                    );
                                                  }
                                                })
                                                .catch((error) => {
                                                  console.log("error", error);
                                                  this.$f7.preloader.hide();
                                                  reject(error);
                                                });
                                            })
                                            .catch((error) => {
                                              console.log("error", error);
                                              this.$f7.preloader.hide();
                                              reject(error);
                                            });
                                        })
                                        .catch((error) => {
                                          console.log("error", error);
                                          this.$f7.preloader.hide();
                                          reject(error);
                                        });
                                    } else {
                                      let data = {
                                        Comentariu:
                                          "Despre ce a fost apelul? Completeaza aici!",
                                        Unitati: 1,
                                        asumat: true,
                                        comentariU1:
                                          "De editat dupa sfarsirea conversatiei telefonice!",
                                        dupa_lucru: 0,
                                        iD_CLIENT: null,
                                        iD_SALARIAT:
                                          this.$f7.data.idSalariatFunctii,
                                        id_proiect: idTaskNou,
                                        iesire: null,
                                        intrare: currentDate.format(),
                                        procent_realizare: 0,
                                      };

                                      axios({
                                        data: data,
                                        headers: {
                                          "Content-Type": "application/json",
                                        },
                                        method: "post",
                                        url:
                                          this.$f7.data.baseURL +
                                          this.state.linkApiRealizari +
                                          "/PostInceput",
                                      })
                                        .then((response8) => {
                                          let ceReturneaza3 = response8.data;

                                          if (
                                            ceReturneaza3 === "S-a adaugat."
                                          ) {
                                            this.$f7.preloader.hide();

                                            this.$f7.methods.openNotification(
                                              "Task pornit pentru apelul preluat: " +
                                                this.$f7.data.Proiecte.filter(
                                                  (p) => {
                                                    return (
                                                      p.value === idTaskNou
                                                    );
                                                  }
                                                )[0].key,
                                              "Success"
                                            );

                                            this.setState(
                                              {
                                                idSelected: idTaskNou,
                                                idSarcina: idSarcinaNoua,
                                              },
                                              () => {
                                                this.GetValues();
                                                resolve("apel preluat");
                                              }
                                            );
                                          }
                                        })
                                        .catch((error) => {
                                          console.log("error", error);
                                          this.$f7.preloader.hide();
                                          reject(error);
                                        });
                                    }
                                  })
                                  .catch((error) => {
                                    console.log("error", error);
                                    this.$f7.preloader.hide();
                                    reject(error);
                                  });
                              })
                              .catch((error) => {
                                console.log("error", error);
                                this.$f7.preloader.hide();
                                reject(error);
                              });
                          } else {
                            reject("exista deja");
                          }
                        })
                        .catch((error) => {
                          console.log("error", error);
                          this.$f7.preloader.hide();
                          //this.$f7.methods.openNotification("Eroare la verificare sarcini");
                          reject(error);
                        });
                    })
                    .catch((error) => {
                      console.log("error", error);
                      this.$f7.preloader.hide();
                      //this.$f7.methods.openNotification("Eroare la inserarea corelatiei salariat/task din apel");
                      reject(error);
                    });
                })
                .catch((error) => {
                  console.log("error", error);
                  this.$f7.preloader.hide();
                  //this.$f7.methods.openNotification("Eroare la adaugare task");
                  reject(error);
                });
            },
            () => {
              //Cancel
              reject("anulare");
            }
          );
        }
      });
    });
  };

  PrimireApel = () => {
    if (this.state.proiectDeschis === null) {
      this.AcceptareApel()
        .then((m) => {})
        .catch((e) => {
          this.$f7.methods.openNotification(e, "Eroare");
        });
    } else {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/PrimireApel/GetCorelatieActiva/" +
            this.$f7.data.idSalariatFunctii +
            "/" +
            this.state.proiectDeschis.IdProiect
        )
        .then((response) => {
          if (response.data === "") {
            this.AcceptareApel()
              .then((m) => {})
              .catch((e) => {
                this.$f7.methods.openNotification(e, "Eroare");
              });
          } else {
            this.$f7.methods.openNotification(
              "Nu puteti prelua un apel telefonic atat timp cat aveti altul in derulare!",
              "Atentie"
            );
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$f7.methods.openNotification(
            "Eroare la verificare corelatie activa"
          );
        });
    }
  };

  /**
   * @description Pregateste fisierul pentru a fi transmis in mantis
   * @date 08/04/2022
   * @param {*} file fisierul
   * @param {*} idSelect id-ul proiectului
   * @function
   * @memberof SarciniAlocateGrid
   */
  postMantisFile2 = (file, idSelect) => {
    return new Promise((resolve, reject) => {
      if (file !== "" && file !== undefined && file !== null) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var res = reader.result;
          var continut = res.split(",")[1];
          var denumire = file.name;
          this.postMantisFile(
            continut,
            this.$f7.data.idSalariatFunctii,
            idSelect,
            denumire
          )
            .then((respMantisFile) => {
              resolve(respMantisFile);
            })
            .catch((error) => {
              reject(error);
            });
        };
        reader.onerror = (error) => {
          reject(error);
        };
      } else {
        resolve(true);
      }
    });
  };

  /**
   * @description Transmite fisierul si il ataseaza la ttaskul din mantis
   * @date 08/04/2022
   * @param {*} continut continutul fisierului base64
   * @param {*} idSalariat id-ul salariatului curent
   * @param {*} idProiect id-ul proiectului selectat
   * @param {*} denumire denumirea fisierului
   * @memberof SarciniAlocateGrid
   */
  postMantisFile = (continut, idSalariat, idProiect, denumire) => {
    return new Promise((resolve, reject) => {
      var url = "/api/SarciniSalariat/PostMantisAttachment";
      axios({
        data: {
          continut: continut,
          idSalariat: idSalariat,
          idProiect: idProiect,
          denumire: denumire,
        },
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Returneaza timpul total lucrat al realizarilor din lista transmisa
   * @function
   * @date 08/04/2022
   * @param {*} listaRealizari lista de realizari transmisa pentur parcurgere si calculare
   * @memberof SarciniAlocateGrid
   */
  timpTotalByRealizari = (listaRealizari) => {
    let total = 0;
    listaRealizari.forEach((item, index) => {
      const inceput = moment(item.intrare).valueOf();
      const sfarsit =
        item.iesire === null
          ? moment().valueOf()
          : moment(item.iesire).valueOf();
      total += sfarsit - inceput;
    });

    // Calculam zile, ore, minute si secunde pentru timpul total (suma duratelor tuturor activitatilor de la un task)
    const zileTotal = Math.floor(total / (24 * 60 * 60 * 1000));
    total = total % (24 * 60 * 60 * 1000);
    const oreTotal = Math.floor(total / (60 * 60 * 1000));
    total = total % (60 * 60 * 1000);
    const minuteTotal = Math.floor(total / (60 * 1000));
    total = total % (60 * 1000);
    const secundeTotal = Math.floor(total / 1000);

    const timpTotal = {
      zile: zileTotal,
      ore: oreTotal,
      minute: minuteTotal,
      secunde: secundeTotal,
    };
    return timpTotal;
  };

  /**
   * @description Ia lista de realizari pentru un proiect si un salariat selectat
   * @date 08/04/2022
   * @param {*} idProiect id-ul proiectului selectat
   * @param {*} idSalariat id-ul salariatului selectat
   * @function
   * @memberof SarciniAlocateGrid
   */
  realizariByProiectAndSalariat = (idProiect, idSalariat) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            "/api/RealizareSarcini/GetREALIZARE_SARCINI_BY_PROIECT_AND_SALARIAT/" +
            idProiect +
            "/" +
            idSalariat
        )
        .then((responseRealizari) => {
          var timpTotal = this.timpTotalByRealizari(responseRealizari.data);
          resolve(timpTotal);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Face cancel la functia de inchidere task
   * @function
   * @date 08/04/2022
   * @memberof SarciniAlocateGrid
   */
  CancelSfarsit = () => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL + this.state.linkApiRealizari + "/CancelSfarsit";
      axios({
        data: {
          IdSalariat: this.$f7.data.idSalariatFunctii,
          InchisDeSalariat: true,
        },
        headers: { "Content-Type": "application/json" },
        method: "PUT",
        url: url,
      })
        .then((r) => {
          resolve(r);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  GetHiddenValues = (IdProiect) => {
    return new Promise((resolve, reject) => {
      var idSelect =
        IdProiect !== undefined
          ? IdProiect
          : this.state.deschideNou && this.state.idProiectVechi !== null
          ? this.state.idProiectVechi
          : this.state.idSelected;

      axios
        .get(
          this.$f7.data.baseURL +
            this.state.linkApiRealizari +
            "/GetREALIZARE_SARCINI_BY_PROIECT_AND_SALARIAT/" +
            idSelect +
            "/" +
            this.$f7.data.idSalariatFunctii
        )
        .then((response) => {
          var esteMantis = false;
          if (
            this.$f7.data.allProiecte.find((r) => {
              return r.iD_PROIECT === idSelect;
            })
          ) {
            esteMantis =
              this.$f7.data.allProiecte.find((r) => {
                return r.iD_PROIECT === idSelect;
              }).id_tip_proiect === 55;
          }

          var hI = esteMantis
            ? [
                { key: "Id_Realizare", group: "Info" },
                {
                  key: "Operatie",
                  tip: "selectbox",
                  lista: this.$f7.data.Operatiuni,
                  required: true,
                  defaultValue: 0,
                  group: "Info",
                },
                {
                  key: "Stadiu",
                  tip: "selectbox",
                  lista: this.$f7.data.Stadiu,
                  required: true,
                  defaultValue: 0,
                  group: "Info2",
                },
                {
                  key: "Comentariu",
                  required: true,
                  defaultValue: "",
                  tip: "textarea",
                  group: "Info2",
                  inputHeight: 100,
                  maxlength: 10000,
                },
                {
                  key: "Observatie",
                  required: false,
                  defaultValue: "",
                  tip: "textarea",
                  group: "Info2",
                  inputHeight: 100,
                  maxlength: 10000,
                },
                {
                  key: "ComentariuPv",
                  required: false,
                  defaultValue: "",
                  tip: "textarea",
                  group: "Info2",
                  inputHeight: 250,
                  maxlength: 10000,
                },
                {
                  key: "Atasamente",
                  tip: "selectbox",
                  lista: [
                    { key: "0", value: 0 },
                    { key: "1", value: 1 },
                    { key: "2", value: 2 },
                    { key: "3", value: 3 },
                    { key: "4", value: 4 },
                    { key: "5", value: 5 },
                    { key: "6", value: 6 },
                    { key: "7", value: 7 },
                    { key: "8", value: 8 },
                    { key: "9", value: 9 },
                    { key: "10", value: 10 },
                    { key: "11", value: 11 },
                    { key: "12", value: 12 },
                    { key: "13", value: 13 },
                    { key: "14", value: 14 },
                    { key: "15", value: 15 },
                    { key: "16", value: 16 },
                    { key: "17", value: 17 },
                    { key: "18", value: 18 },
                    { key: "19", value: 19 },
                    { key: "20", value: 20 },
                  ],
                  defaultValue: 0,
                  group: "Atasamentele",
                },
              ]
            : [
                { key: "Id_Realizare", group: "Info" },
                {
                  key: "Operatie",
                  tip: "selectbox",
                  lista: this.$f7.data.Operatiuni,
                  required: true,
                  defaultValue: 0,
                  group: "Info",
                },
                {
                  key: "Stadiu",
                  tip: "selectbox",
                  lista: this.$f7.data.Stadiu,
                  required: true,
                  defaultValue: 0,
                  group: "Info2",
                },
                {
                  key: "Comentariu",
                  required: true,
                  defaultValue: "",
                  tip: "textarea",
                  group: "Info2",
                  inputHeight: 100,
                  maxlength: 10000,
                },
                {
                  key: "Observatie",
                  required: false,
                  defaultValue: "",
                  tip: "textarea",
                  group: "Info2",
                  inputHeight: 100,
                  maxlength: 10000,
                },
                {
                  key: "ComentariuPv",
                  required: false,
                  defaultValue: "",
                  tip: "textarea",
                  group: "Info2",
                  inputHeight: 250,
                  maxlength: 10000,
                },
              ];

          let date = response;
          date.data.map((obj) => {
            obj["Id_Realizare"] = obj.iD_REALIZARE;
            obj["MomentInceput"] = obj.intrare;
            obj["MomentSfarsit"] = obj.iesire;
            obj["NumeSalariat"] = obj.iD_SALARIAT;
            obj["Observatie"] = obj.comentariU1;
            obj["Client"] = obj.iD_CLIENT;
            obj["Proiect"] = obj.iD_PROIECT;
            obj["Stadiu"] = this.$f7.data.allProiecte.find((x) => {
              return x.iD_PROIECT === idSelect;
            }).iD_STADIU;
            obj["Operatie"] = this.$f7.data.allProiecte.find((x) => {
              return x.iD_PROIECT === idSelect;
            }).id_operatiuni;
            obj["ComentariuPv"] = obj.comentariu_pv;
            return null;
          });
          var proj = this.$f7.data.allProiecte.find((x) => {
            return x.iD_PROIECT === idSelect;
          });
          var stadiu = proj.iD_STADIU;
          var operatie = proj.id_operatiuni;
          var newInputs = [...this.state.hiddenInputs];
          hI[1].defaultValue = operatie;
          hI[2].defaultValue = stadiu;
          var baza = this.$f7.methods.reconstructieBaza(date, newInputs);
          var elemBaza;
          var ind;
          baza.forEach((r, index) => {
            if (elemBaza === undefined) {
              elemBaza = r;
              ind = index;
            }
            if (elemBaza.Id_Realizare < r.Id_Realizare) {
              elemBaza = r;
              ind = index;
            }
          });
          newInputs[0].defaultValue =
            elemBaza !== undefined ? elemBaza.Id_Realizare : null;
          var sfarsit = this.state.sfarsit;
          //TESTE

          if (sfarsit) {
            if (!this.state.inchideDupaOprire) {
              this.$f7.preloader.show();

              var ob = elemBaza;
              //var ob = baza[0];
              var id = parseInt(ob["Id_Realizare"]);
              ob.iesire = moment().format();

              if (this.state.deschideNou) {
                ob.Comentariu +=
                  "Task oprit de pornirea taskului '" +
                  this.$f7.data.Proiecte.find(
                    (item) => item.value === this.state.idSelected
                  ).key +
                  "'";

                this.UpdateValueHidden(ob, id)
                  .then((responseValueHidden) => {
                    this.$f7.preloader.hide();
                    resolve(responseValueHidden);
                  })
                  .catch((errorValueHidden) => {
                    reject(errorValueHidden);
                  });
              } else if (this.state.realocaDupaOprire) {
                ob.Comentariu =
                  "Task transferat la " +
                  this.Salariati.find(
                    (item) => item.value === this.state.idPropunere
                  ).key;
                this.UpdateValueHidden(ob, id)
                  .then((responseValueHidden) => {
                    this.$f7.preloader.hide();
                    resolve(responseValueHidden);
                  })
                  .catch((errorValueHidden) => {
                    reject(errorValueHidden);
                  });
              } else {
                //obj.Comentariu = "Oprit voluntar";
                console.log("bazaInd", baza[ind]);
                if (
                  baza[ind].Comentariu === null ||
                  baza[ind].Comentariu.length < 1
                ) {
                  baza[ind].Comentariu = "Oprit voluntar";
                }

                this.$f7.preloader.hide();
                this.$f7.data.router.navigate("/popupAddEdit/", {
                  props: {
                    inputs: hI,
                    ignoreInputs: ["Stadiu", "Atasamente"],
                    id: "Id_Realizare",
                    dataSource: baza,
                    titluDenumire: this.$f7.data.Proiecte.find((r) => {
                      return r.value === elemBaza.Proiect;
                    }).key,
                    editIndex: ind,
                    buttons: [
                      {
                        key: "Salveaza",
                        onSubmit: (state, den) => {
                          var obj = { ...state.editedItem };
                          var id = parseInt(obj["Id_Realizare"]);
                          obj.iesire = moment().format();
                          obj.comentariU1 = obj["Observatie"];
                          obj.comentariu_pv = obj["ComentariuPv"];
                          this.$f7.preloader.show();
                          //this.$f7.popup.close(den)
                          this.$f7.panel.close(den);
                          this.UpdateValueHidden(obj, id)
                            .then((responseValueHidden) => {
                              this.$f7.preloader.hide();
                              resolve(responseValueHidden);
                            })
                            .catch((errorValueHidden) => {
                              reject(errorValueHidden);
                            });
                        },
                      },
                    ],
                  },
                });
              }
            } else {
              this.realizariByProiectAndSalariat(
                idSelect,
                this.$f7.data.idSalariatFunctii
              )
                .then((timpTotal) => {
                  var timpLucratTotal =
                    timpTotal.zile +
                    "z " +
                    timpTotal.ore +
                    "h " +
                    timpTotal.minute +
                    " mi " +
                    timpTotal.secunde +
                    " s ";
                  this.$f7.preloader.hide();

                  let flagAfisareComentarii = false;
                  this.$f7.data.router.navigate("/popupAddEdit/", {
                    props: {
                      onSelectionChanged: (state) => {
                        var denumire = state.denumire;
                        //var newValue = state.editedItem[denumire];
                        if (denumire === "Atasamente") {
                          var nrAtasamenteNoi = state.editedItem.Atasamente;
                          var nrAtasamenteExistente =
                            hI.filter((r) => {
                              return r.group === "Atasamentele";
                            }).length - 1;
                          if (nrAtasamenteNoi > nrAtasamenteExistente) {
                            for (
                              var i = nrAtasamenteExistente;
                              i < nrAtasamenteNoi;
                              i++
                            ) {
                              hI.push({
                                key: "Atasament" + i,
                                keyBaza: "atasament" + i,
                                tip: "filepicker",
                                required: true,
                                group: "Atasamentele",
                              });
                            }
                          } else if (nrAtasamenteNoi < nrAtasamenteExistente) {
                            for (
                              var j = nrAtasamenteNoi;
                              j < nrAtasamenteExistente;
                              j++
                            ) {
                              hI.pop();
                            }
                          }
                        }
                      },

                      inputs: hI,
                      id: "Id_Realizare",
                      ignoreInputs: ["Stadiu"],
                      dataSource: baza,
                      titluDenumire:
                        this.$f7.data.Proiecte.find((r) => {
                          return r.value === elemBaza.Proiect;
                        }).key +
                        " | " +
                        timpLucratTotal,
                      editIndex: ind,
                      buttons: [
                        {
                          key: "Salveaza",
                          onSubmit: (state, den) => {
                            var obj = { ...state.editedItem };
                            var id = parseInt(obj["Id_Realizare"]);
                            obj.iesire = moment().format();
                            obj.comentariU1 = obj["Observatie"];
                            obj.comentariu_pv = obj["ComentariuPv"];
                            this.$f7.preloader.show();
                            //this.$f7.popup.close(den)
                            this.$f7.panel.close(den);
                            this.UpdateValueHidden(obj, id)
                              .then((responseValueHidden) => {
                                this.$f7.preloader.hide();
                                if (state.editedItem.Atasamente !== undefined) {
                                  if (state.editedItem.Atasamente > 0) {
                                    var arrAtasamente = [];
                                    for (
                                      var i = 0;
                                      i < state.editedItem.Atasamente;
                                      i++
                                    ) {
                                      var atasamentCurent =
                                        state.editedItem["Atasament" + i];
                                      arrAtasamente.push(atasamentCurent);
                                    }
                                    arrAtasamente
                                      .reduce((promise, file) => {
                                        return promise.then(() => {
                                          return this.postMantisFile2(
                                            file,
                                            idSelect
                                          )
                                            .then((r) => {})
                                            .catch((err) => {
                                              console.log("err", err);
                                            });
                                        });
                                      }, Promise.resolve())
                                      .then(() => {
                                        resolve(responseValueHidden);
                                      })
                                      .catch((err) => {
                                        reject(err);
                                      });
                                  } else {
                                    resolve(responseValueHidden);
                                  }
                                } else {
                                  resolve(responseValueHidden);
                                }
                              })
                              .catch((errorValueHidden) => {
                                reject(errorValueHidden);
                              });
                          },
                        },
                        {
                          key: "Afiseaza comentariile",
                          noInputsRequired: true,
                          onSubmit: (state, den, context) => {
                            if (!flagAfisareComentarii) {
                              let comList = state.dataSource.map(
                                (item, index) => {
                                  return {
                                    data: item.MomentSfarsit,
                                    text: item.Comentariu + "\n",
                                  };
                                }
                              );
                              let text = "";
                              comList.forEach((item) => {
                                if (item.text !== "\n")
                                  text +=
                                    moment(item.data).format(
                                      "DD/MM/YYYY HH:mm"
                                    ) +
                                    "\n  -  " +
                                    item.text +
                                    "\n";
                              });

                              let eI = { ...context.state.editedItem };
                              //eI["Comentariu"] = text;

                              if (
                                eI["ComentariuPv"] === "" ||
                                eI["ComentariuPv"] === "\n" ||
                                eI["ComentariuPv"] === null
                              ) {
                                eI["ComentariuPv"] = text;
                              } else {
                                eI["ComentariuPv"] += "\n\n" + text;
                              }

                              context.setState(
                                {
                                  editedItem: eI,
                                },
                                () => {
                                  flagAfisareComentarii = true;
                                  this.$f7.methods.openNotification(
                                    "Toate comentariile au fost afisate in campul 'Comentariu'. Le puteti sterge, edita sau copia in alte campuri.",
                                    undefined,
                                    "succes"
                                  );
                                }
                              );
                            } else {
                              this.$f7.methods.openNotification(
                                "Comentariile au fost deja afisate in campul 'ComentariuPv'.",
                                undefined,
                                "eroare"
                              );
                            }
                          },
                        },
                      ],
                    },
                  });
                })
                .catch((err) => {
                  this.$f7.methods.openNotification("Eroare:", err);
                });
            }

            this.setState({ sfarsit: false });
          } else if (this.state.comInchidereTask) {
            this.realizariByProiectAndSalariat(
              idSelect,
              this.$f7.data.idSalariatFunctii
            )
              .then((timpTotal) => {
                var timpLucratTotal =
                  timpTotal.zile +
                  "z " +
                  timpTotal.ore +
                  "h " +
                  timpTotal.minute +
                  "m " +
                  timpTotal.secunde +
                  " s ";
                this.$f7.preloader.hide();

                if (baza.length > 0) {
                  let flagAfisareComentarii = false;

                  this.$f7.data.router.navigate("/popupAddEdit/", {
                    props: {
                      inputs: hI,
                      id: "Id_Realizare",
                      ignoreInputs: ["Stadiu"],
                      dataSource: baza,
                      titluDenumire:
                        this.$f7.data.Proiecte.find((r) => {
                          return r.value === elemBaza.Proiect;
                        }).key +
                        " | " +
                        timpLucratTotal,
                      editIndex: ind,
                      buttons: [
                        {
                          key: "Salveaza",
                          onSubmit: (state, den) => {
                            var obj = { ...state.editedItem };
                            var id = parseInt(obj["Id_Realizare"]);
                            obj.comentariU1 = obj["Observatie"];
                            obj.comentariu_pv = obj["ComentariuPv"];
                            this.$f7.preloader.show();
                            this.$f7.panel.close(den);
                            this.UpdateValueHidden(obj, id)
                              .then((responseValueHidden) => {
                                this.$f7.preloader.hide();
                                if (state.editedItem.Atasamente !== undefined) {
                                  if (state.editedItem.Atasamente > 0) {
                                    var arrAtasamente = [];
                                    for (
                                      var i = 0;
                                      i < state.editedItem.Atasamente;
                                      i++
                                    ) {
                                      var atasamentCurent =
                                        state.editedItem["Atasament" + i];
                                      arrAtasamente.push(atasamentCurent);
                                    }
                                    arrAtasamente
                                      .reduce((promise, file) => {
                                        return promise.then(() => {
                                          return this.postMantisFile2(
                                            file,
                                            idSelect
                                          )
                                            .then((r) => {})
                                            .catch((err) => {
                                              console.log("err", err);
                                            });
                                        });
                                      }, Promise.resolve())
                                      .then(() => {
                                        resolve(responseValueHidden);
                                      })
                                      .catch((err) => {
                                        reject(err);
                                      });
                                  } else {
                                    resolve(responseValueHidden);
                                  }
                                } else {
                                  resolve(responseValueHidden);
                                }
                              })
                              .catch((errorValueHidden) => {
                                reject(errorValueHidden);
                              });
                          },
                        },
                        {
                          key: "Afiseaza comentariile",
                          noInputsRequired: true,
                          onSubmit: (state, den, context) => {
                            if (!flagAfisareComentarii) {
                              let comList = state.dataSource.map(
                                (item, index) => {
                                  return {
                                    data: item.MomentSfarsit,
                                    text: item.Comentariu + "\n",
                                  };
                                }
                              );
                              let text = "";
                              comList.forEach((item) => {
                                if (item.text !== "\n")
                                  text +=
                                    moment(item.data).format(
                                      "DD/MM/YYYY HH:mm"
                                    ) +
                                    "\n  -  " +
                                    item.text +
                                    "\n";
                              });

                              let eI = { ...context.state.editedItem };
                              //eI["Comentariu"] = text;

                              if (
                                eI["ComentariuPv"] === "" ||
                                eI["ComentariuPv"] === "\n" ||
                                eI["ComentariuPv"] === null
                              ) {
                                eI["ComentariuPv"] = text;
                              } else {
                                eI["ComentariuPv"] += "\n\n" + text;
                              }

                              context.setState(
                                {
                                  editedItem: eI,
                                },
                                () => {
                                  flagAfisareComentarii = true;
                                  this.$f7.methods.openNotification(
                                    "Toate comentariile au fost afisate in campul 'ComentariuPv'. Le puteti sterge, edita sau copia in alte campuri.",
                                    undefined,
                                    "succes"
                                  );
                                }
                              );
                            } else {
                              this.$f7.methods.openNotification(
                                "Comentariile au fost deja afisate in campul 'ComentariuPv'.",
                                undefined,
                                "eroare"
                              );
                            }
                          },
                        },
                      ],
                      onSelectionChanged: (state) => {
                        var denumire = state.denumire;
                        //var newValue = state.editedItem[denumire];
                        if (denumire === "Atasamente") {
                          var nrAtasamenteNoi = state.editedItem.Atasamente;
                          var nrAtasamenteExistente =
                            hI.filter((r) => {
                              return r.group === "Atasamentele";
                            }).length - 1;
                          if (nrAtasamenteNoi > nrAtasamenteExistente) {
                            for (
                              var i = nrAtasamenteExistente;
                              i < nrAtasamenteNoi;
                              i++
                            ) {
                              hI.push({
                                key: "Atasament" + i,
                                keyBaza: "atasament" + i,
                                tip: "filepicker",
                                required: true,
                                group: "Atasamentele",
                              });
                            }
                          } else if (nrAtasamenteNoi < nrAtasamenteExistente) {
                            for (
                              var j = nrAtasamenteNoi;
                              j < nrAtasamenteExistente;
                              j++
                            ) {
                              hI.pop();
                            }
                          }
                        }
                      },
                    },
                  });
                } else {
                  this.$f7.dialog.confirm(
                    'La taskul "' +
                      proj.denumire +
                      '" nu exista nici o activitate inregistrata. Doriti sa il inchideti oricum?',
                    "Atentie",
                    () => {
                      resolve();
                    },
                    () => {
                      reject();
                    }
                  );
                }

                this.setState({ comInchidereTask: false });
              })
              .catch((err) => {
                this.$f7.methods.openNotification("Eroare:", err);
              });
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  };

  UpdateValueHidden = (obj, id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            this.state.linkApiRealizari +
            "/GetREALIZARE_SARCINI/" +
            id
        )
        .then((response) => {
          var obj2 = response.data;
          Object.keys(obj).map((trasatura) => {
            Object.keys(obj2).map((trasatura2) => {
              if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
                obj2[trasatura2] = obj[trasatura];
              }
              return false;
            });
            return false;
          });
          obj2["manual"] = false;
          obj2["aprobat"] = true;
          obj2["ultima_activitate"] = moment().format();

          var url =
            this.$f7.data.baseURL +
            this.state.linkApiRealizari +
            "/PutREALIZARE_SARCINI/" +
            id;
          axios({
            data: obj2,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: url,
          })
            .then((response) => {
              //Sa actualizez in baza de date la proiecte

              var idProiect = obj2.iD_PROIECT;
              var stadiu = this.state.inchideDupaOprire ? 6 : obj.Stadiu;
              var operatie = obj.Operatie;

              var objProiect = { iD_STADIU: stadiu, id_operatiuni: operatie };
              this.UpdateValueProiecte(objProiect, idProiect)
                .then((res) => {
                  if (this.state.deschideNou) {
                    //Cand se opreste si deschide task nou
                    setTimeout(() => {
                      this.setState(
                        {
                          deschideNou: false,
                          inchideDupaOprire: false,
                          realocaDupaOprire: false,
                        },
                        () => {
                          this.Inceput()
                            .then((r) => {
                              resolve(r);
                            })
                            .catch((err) => {
                              reject(err);
                            });
                        }
                      );
                    }, 500);
                  } else if (this.state.inchideDupaOprire) {
                    //Cand se opreste si se si inchide

                    setTimeout(() => {
                      this.setState(
                        {
                          deschideNou: false,
                          inchideDupaOprire: false,
                          realocaDupaOprire: false,
                        },
                        () => {
                          this.InchideTaskVerificat()
                            .then((r) => {
                              resolve(r);
                            })
                            .catch((err) => {
                              reject(err);
                            });
                        }
                      );
                    }, 500);
                  } else if (this.state.realocaDupaOprire) {
                    this.setState(
                      {
                        deschideNou: false,
                        inchideDupaOprire: false,
                        realocaDupaOprire: false,
                      },
                      () => {
                        resolve(response);
                      }
                    );
                  } else {
                    //Cand se opreste pur si simplu
                    this.setState(
                      {
                        deschideNou: false,
                        inchideDupaOprire: false,
                        realocaDupaOprire: false,
                      },
                      () => {
                        resolve(response);
                      }
                    );
                  }
                })
                .catch((err) => {
                  reject(err);
                });
            })
            .catch((error) => {
              reject(error);
              console.log("error", error);
              this.$f7.methods.openNotification("Eroare la modificare");
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  GetValues = () => {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          proiectDeschis: null,
          dataLoaded: false,
          idSelected: null,
          durata: null,
        },
        () => {
          if (this.props.outside) {
            this.$f7.data.Proiect = null;
            this.props.updateDreapta();
          } else {
            this.$f7.methods.openMainDreaptaById(null);
          }
        }
      );

      this.$f7.methods
        .getProiecte(
          this.$f7.data.idSalariatFunctii,
          1,
          this.props.checkedTaskuri === true ? 1 : 0,
          this.props.checkedAscunse === true ? 1 : 0,
          this.props.checkedArhivate === true ? 1 : 0,
          1
        )
        .then((response) => {
          var date = response;

          date.data.map((sarcina) => {
            //Valori din proiect
            // var pro=sarcina.proiect;
            if (sarcina.proiect) {
              Object.keys(sarcina.proiect).map((property) => {
                var value = sarcina.proiect[property];
                if (sarcina[property] === undefined) {
                  sarcina[property] = value;
                }
                return null;
              });

              sarcina["path"] = sarcina.proiect["path"];
              if (sarcina["path"]) {
                var path = "";
                sarcina["path"].split("\\").map((f) => {
                  if (f !== null && f !== undefined) {
                    var idProiect = parseInt(f);
                    var pro = this.$f7.data.allProiecte.find((p) => {
                      return p.iD_PROIECT === idProiect;
                    });
                    if (pro) {
                      path += pro.denumire + " \\ ";
                    }
                  }
                  return null;
                });
                sarcina["path"] = path.slice(0, -2);
              }
            }

            sarcina = this.$f7.methods.restructurareBaza(
              sarcina,
              this.state.inputs,
              "key"
            );

            switch (sarcina.Sursa) {
              case 55:
                sarcina.Sursa =
                  "M(" + (sarcina.proiect.userImport % 10000000) + ")";
                break;
              case 56:
                sarcina.Sursa = "E";
                break;
              default:
                sarcina.Sursa = "C";
                break;
            }
            if (sarcina.exista === 1) {
              this.setState({ proiectDeschis: sarcina });
            }

            return null;
          });

          var baza = this.$f7.methods.reconstructieBaza(
            date,
            this.state.inputs
          );

          this.setState(
            {
              dataSource: baza,
            },
            () => {
              setTimeout(() => {
                this.setState(
                  {
                    dataLoaded: true,
                  },
                  () => {
                    resolve(response);
                  }
                );
              }, 400);
            }
          );
        })
        .catch((error) => {
          this.setState(
            {
              dataSource: [],
              dataLoaded: true,
            },
            () => {
              this.refDataGrid.current.setRenderItems([]);
              this.$f7.dialog.confirm(
                "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                "Ups...",
                () => {
                  this.GetValues()
                    .then((resp) => {
                      resolve(resp);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                },
                () => {
                  reject(error);
                }
              );
            }
          );
        });
    });
  };

  /**
   * @description Adauga o inregistrare de tip detaliu delegatie la delegatia curent pornita
   * @date 08/04/2022
   * @param {*} data biect cu toate informatiile noii inregistrari
   * @function
   * @memberof SarciniAlocateGrid
   */
  AddValueDetaliu = (data) => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL +
        this.state.linkApiDetaliu +
        "/PostDETALIU_DELEGATII";
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          resolve(response);
          this.$f7.methods.openNotification("Adaugat cu success");
        })
        .catch((error) => {
          reject(error);
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la adaugare");
        });
    });
  };

  UpdateValueDetaliu = (data, id) => {
    return new Promise((resolve, reject) => {
      var url =
        this.$f7.data.baseURL +
        this.state.linkApiDetaliu +
        "/PutDETALIU_DELEGATII/" +
        id;

      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "put",
        url: url,
      })
        .then((response) => {
          resolve(response);
          this.$f7.methods.openNotification("Modificat cu success");
        })
        .catch((error) => {
          reject(error);
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la modificare");
        });
    });
  };
  /**
   * @description Se adauga o realizare la un anumit proiect pe o anumita sarcina de la acel proiect
   * @function
   * @date 08/04/2022
   * @memberof SarciniAlocateGrid
   */
  Inceput = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((p) => {
          var lat = p.coords.latitude;
          var long = p.coords.longitude;
          this.setState({ lat: lat, lng: long });
        });
      }
      if (this.state.idSelected !== null) {
        var url =
          this.$f7.data.baseURL + this.state.linkApiRealizari + "/PostInceput";
        var dataCurenta = moment().format();
        var data = {
          Comentariu: "",
          Unitati: 1,
          asumat: true,
          comentariU1: "",
          dupa_lucru: 0,
          iD_CLIENT: null,
          iD_SALARIAT: this.$f7.data.idSalariatFunctii,
          id_proiect: this.state.idSelected,
          iesire: null,
          intrare: dataCurenta,
          procent_realizare: 0,
          manual: false,
          aprobat: true,
          ultima_activitate: dataCurenta,
        };

        this.$f7.methods
          .esteDelegatie()
          .then((delegatieCurenta) => {
            if (delegatieCurenta !== false) {
              var idDelegatie = delegatieCurenta.iD_DELEGATIE;

              this.$f7.methods
                .detaliuDelegatieCurenta(idDelegatie)
                .then((detaliuDelegatieCurenta) => {
                  if (detaliuDelegatieCurenta !== false) {
                    if (
                      detaliuDelegatieCurenta.sosire !== null &&
                      detaliuDelegatieCurenta.plecare === null
                    ) {
                      var objDetaliuAdd = {
                        iD_DELEGATIE: idDelegatie,
                        sosire: moment().format(),
                        iD_PROIECT: this.state.idSelected, //aici
                        iD_FAZA: null,
                        iD_FIRMA: detaliuDelegatieCurenta.iD_FIRMA,
                        plecare: null,
                        preluat: false,
                        iD_TASK: this.state.idSarcina, //aici
                        sosirE_LNG: this.state.lng,
                        sosirE_LAT: this.state.lat,
                        //plecarE_LNG: null,
                        //plecarE_LAT: null,
                        activitate: 1,
                      };

                      axios({
                        data: data,
                        headers: { "Content-Type": "application/json" },
                        method: "POST",
                        url: url,
                      })
                        .then((response) => {
                          var ceReturneaza = response.data;
                          if (ceReturneaza === "S-a adaugat.") {
                            this.AddValueDetaliu(objDetaliuAdd)
                              .then(() => {
                                var numeProiect = this.$f7.data.Proiecte.find(
                                  (p) => {
                                    return p.value === this.state.idSelected;
                                  }
                                );
                                var mesaj =
                                  "Inceput task " + numeProiect !== undefined
                                    ? numeProiect.key
                                    : "";
                                this.$f7.methods.openNotification(
                                  mesaj,
                                  "Success"
                                );
                                resolve(response);
                                //this.GetValues().then((r) => { resolve(r); }).catch((e) => { reject(e); });
                              })
                              .catch((err) => {
                                reject(err);
                              });
                          } else {
                            var idProiect = parseInt(ceReturneaza);
                            this.$f7.preloader.hide();
                            var numeProiect = this.$f7.data.Proiecte.find(
                              (p) => {
                                return p.value === idProiect;
                              }
                            );
                            if (numeProiect === undefined) {
                              numeProiect = "";
                            } else {
                              numeProiect = numeProiect.key;
                            }
                            this.$f7.dialog.confirm(
                              "Exista un task deja deschis la proiectul: " +
                                numeProiect +
                                ", doriti sa il inchideti?",
                              "Atentie",
                              () => {
                                this.$f7.preloader.show();
                                var idProiectDeschis = undefined;
                                if (this.state.proiectDeschis) {
                                  idProiectDeschis =
                                    this.state.proiectDeschis.IdProiect;
                                }
                                this.Sfarsit(
                                  true,
                                  idProiect,
                                  false,
                                  false,
                                  false,
                                  idProiectDeschis
                                )
                                  .then((resp) => {
                                    resolve(resp);
                                  })
                                  .catch((err) => {
                                    reject(err);
                                  });
                              },
                              () => {
                                resolve(response);
                              }
                            );
                          }
                        })
                        .catch((error) => {
                          reject(error);
                          console.log("error", error);
                          this.$f7.methods.openNotification(
                            "Eroare la adaugare"
                          );
                        });
                    } else {
                      resolve(detaliuDelegatieCurenta);
                      this.$f7.methods.openNotification(
                        "Nu puteti deschide taskul inainte de a ajunge la destinatie.",
                        "Atentie"
                      );
                    }
                  } else {
                    this.$f7.methods.openNotification(
                      "Nu puteti deschide taskul deoarece aveti o delegatie nefinalizata.",
                      "Atentie"
                    );

                    reject(detaliuDelegatieCurenta);
                  }
                })
                .catch((error) => {
                  reject(error);
                  console.log("error", error);
                });
            } else {
              axios({
                data: data,
                headers: { "Content-Type": "application/json" },
                method: "POST",
                url: url,
              })
                .then((response) => {
                  var ceReturneaza = response.data;

                  if (ceReturneaza === "S-a adaugat.") {
                    var numeProiect0 = this.$f7.data.Proiecte.find((p) => {
                      return p.value === this.state.idSelected;
                    });

                    var mesaj =
                      "Inceput task " + numeProiect0 !== undefined
                        ? numeProiect0.key
                        : "";
                    this.$f7.methods.openNotification(mesaj, "Success");
                    resolve(response);
                    // this.GetValues().then((resp) => {resolve(resp);}).catch((err) => { reject(err);});
                  } else {
                    var idProiect = parseInt(ceReturneaza);
                    this.$f7.preloader.hide();
                    var numeProiect1 = this.$f7.data.Proiecte.find((p) => {
                      return p.value === idProiect;
                    });
                    if (numeProiect1 === undefined) {
                      numeProiect1 = "";
                    } else {
                      numeProiect1 = numeProiect1.key;
                    }
                    this.$f7.dialog.confirm(
                      "Exista un task deja deschis la proiectul: " +
                        numeProiect1 +
                        ", doriti sa il opriti?",
                      "Atentie",
                      () => {
                        this.$f7.preloader.show();
                        var idProiectDeschis = undefined;
                        if (this.state.proiectDeschis) {
                          idProiectDeschis =
                            this.state.proiectDeschis.IdProiect;
                        }
                        this.Sfarsit(
                          true,
                          idProiect,
                          false,
                          false,
                          false,
                          idProiectDeschis
                        )
                          .then((resp) => {
                            resolve(resp);
                          })
                          .catch((err) => {
                            reject(err);
                          });
                      },
                      () => {
                        resolve(response);
                      }
                    );
                  }
                })
                .catch((error) => {
                  reject(error);
                  console.log("error", error);
                  this.$f7.methods.openNotification("Eroare la adaugare");
                });
            }
          })
          .catch((error) => {
            reject(error);
            console.log("error", error);
          });
      } else {
        reject("Selecteaza mai intai un task!");
        this.$f7.methods.openNotification("Selecteaza mai intai un task!");
      }
    });
  };

  VerificareTaskApel = (idPrj, idActivitate) => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((p) => {
          var lat = p.coords.latitude;
          var long = p.coords.longitude;
          this.setState({ lat: lat, lng: long });
        });
      }

      const requestCorelatieApel = axios.get(
        this.$f7.data.baseURL +
          "/api/PrimireApel/GetCorelatieActiva/" +
          this.$f7.data.user.iD_SALARIAT +
          "/" +
          idPrj
      );
      const requestSalariatModule = axios.get(
        this.$f7.data.baseURL +
          "/api/SalariatContracteModule/GetSALARIAT_CONTRACTE_MODULEBySalariat/" +
          this.$f7.data.user.iD_SALARIAT
      );

      axios
        .all([requestCorelatieApel, requestSalariatModule])
        .then(
          axios.spread((responseCorelatie, responseModule) => {
            if (responseCorelatie.data === "") {
              resolve("Nu este task din apel!");
            } else {
              let corelatie = responseCorelatie.data;
              const idCorelatie = corelatie.idApel;
              const idProiectApel = corelatie.ref_proiect;

              let lista = [];
              responseModule.data.forEach((item) =>
                lista.push(item.ref_proiect)
              );

              let listaClientiSalariat = [];
              responseModule.data.map((f) => {
                let referinta = f.ref_proiect;
                let proiectul = this.$f7.data.allProiecte.find(
                  (r) => r.iD_PROIECT === referinta
                );

                if (proiectul) {
                  let idClientProiect = proiectul.id_client;

                  if (!listaClientiSalariat.includes(idClientProiect)) {
                    listaClientiSalariat.push(idClientProiect);
                  }
                }

                return null;
              });

              let listaClientiSalariatFinal = this.$f7.data.Firme.filter((r) =>
                listaClientiSalariat.includes(r.value)
              );
              listaClientiSalariatFinal.sort((a, b) =>
                a.key > b.key ? 1 : b.key > a.key ? -1 : 0
              );

              let defaultValueClienti, listaProiecteInput;

              if (listaClientiSalariatFinal.length > 0) {
                defaultValueClienti = listaClientiSalariatFinal[0].value;

                let arrProiecte = this.$f7.data.allProiecte
                  .filter(
                    (r) =>
                      (r.id_nivel_proiect === 1 || r.id_nivel_proiect === 2) &&
                      r.id_client === listaClientiSalariatFinal[0].value
                  )
                  .filter((r) => lista.includes(r.iD_PROIECT))
                  .map((r) => {
                    r.key = r.denumire;
                    r.value = r.iD_PROIECT;
                    return r;
                  });
                arrProiecte.sort((a, b) =>
                  a.denumire > b.denumire ? 1 : b.denumire > a.denumire ? -1 : 0
                );
                listaProiecteInput = arrProiecte;
              }

              this.setState(
                {
                  listaClientiTelefon: listaClientiSalariatFinal,
                  listaProiecteTelefon: listaProiecteInput,
                  idClientTelefon: defaultValueClienti,
                  idProiectParinteTelefon: listaProiecteInput[0].value,
                },
                () => {
                  console.log("lista clienti:", this.state.listaClientiTelefon);
                  console.log(
                    "lista proiecte/module:",
                    this.state.listaProiecteTelefon
                  );
                  console.log("default clienti:", this.state.idClientTelefon);
                  console.log(
                    "default proiecte/module:",
                    this.state.idProiectParinteTelefon
                  );

                  this.$f7.preloader.hide();
                  this.$f7.data.router.navigate("/popupApel/", {
                    props: {
                      title: "Adaugare informatii task telefonic",
                      disableClose: true,
                      component: (
                        <div
                          style={{
                            boxSizing: "border-box",
                            padding: "10px 30px 30px",
                          }}>
                          <span
                            style={{
                              textAlign: "center",
                              width: "100%",
                              display: "block",
                              fontSize: "20px",
                              padding: 15,
                              boxSizing: "border-box",
                            }}>
                            Taskul
                            {
                              this.$f7.data.Proiecte.filter(
                                (p) => p.value === idPrj
                              )[0].key
                            }
                            este primit prin apel telefonic. Pentru a se opri
                            corect, trebuie completate urmatoarele campuri.
                          </span>

                          <List
                            style={{ margin: 0 }}
                            noChevron
                            form
                            noHairlines
                            noHairlinesBetween
                            noHairlinesBetweenMd
                            noHairlinesMd>
                            <Row style={{ alignItems: "center" }}>
                              <Col>
                                <ListItem
                                  title="Client"
                                  value={this.state.idClientTelefon}
                                  label="Client"
                                  validate
                                  required
                                  smartSelect
                                  smartSelectParams={{
                                    virtualList: true,
                                    searchbar: true,
                                    openIn: "popup",
                                    popupTabletFullscreen: true,
                                    popupPush: true,
                                    closeOnSelect: true,
                                    on: {
                                      closed: (event) => {
                                        let clientSelectat = event.getValue();
                                        this.setState({
                                          idClientTelefon:
                                            clientSelectat === "Fara" ||
                                            clientSelectat === "null" ||
                                            clientSelectat === null
                                              ? null
                                              : parseInt(clientSelectat),
                                        });
                                      },
                                    },
                                  }}>
                                  <Icon
                                    color={"var(--f7-theme-color)"}
                                    size={20}
                                    material="list"
                                    slot="content-start"
                                  />
                                  <select
                                    defaultValue={this.state.idClientTelefon}
                                    name="Client">
                                    {this.state.listaClientiTelefon.map(
                                      (obj, index) => (
                                        <option
                                          label={obj.key}
                                          key={
                                            "listaClientiTelefonSarciniAlocateGrid-" +
                                            index
                                          }
                                          value={obj.value}>
                                          {obj.key}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </ListItem>
                              </Col>
                              <Col>
                                <ListItem
                                  title="Proiect/Modul Referinta"
                                  value={this.state.idProiectParinteTelefon}
                                  label="Proiect/Modul Referinta"
                                  validate
                                  required
                                  smartSelect
                                  smartSelectParams={{
                                    virtualList: true,
                                    searchbar: true,
                                    openIn: "popup",
                                    popupTabletFullscreen: true,
                                    popupPush: true,
                                    closeOnSelect: true,
                                    on: {
                                      closed: (event) => {
                                        let proiectSelectat = event.getValue();
                                        this.setState({
                                          idProiectParinteTelefon:
                                            proiectSelectat === "Fara" ||
                                            proiectSelectat === "null" ||
                                            proiectSelectat === null
                                              ? null
                                              : parseInt(proiectSelectat),
                                        });
                                      },
                                    },
                                  }}>
                                  <Icon
                                    color={"var(--f7-theme-color)"}
                                    size={20}
                                    material="list"
                                    slot="content-start"
                                  />
                                  <select
                                    defaultValue={
                                      this.state.idProiectParinteTelefon
                                    }
                                    name="Proiect/Modul Referinta">
                                    {this.state.listaProiecteTelefon.map(
                                      (obj, index) => (
                                        <option
                                          label={obj.key}
                                          key={
                                            "listaProiecteTelefonSarciniAlocateGrid-" +
                                            index
                                          }
                                          value={obj.value}>
                                          {obj.key}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </ListItem>
                              </Col>
                            </Row>
                          </List>

                          <Button
                            tooltip={"Ok"}
                            style={{
                              width: "100%",
                              maxWidth: 200,
                              margin: "20px auto 0",
                            }}
                            fill
                            onClick={() => {
                              if (
                                this.state.idClientTelefon !== null &&
                                this.state.idProiectParinteTelefon !== null
                              ) {
                                this.$f7.preloader.show();

                                corelatie["activ"] = false;

                                axios({
                                  data: corelatie,
                                  headers: {
                                    "Content-Type": "application/json",
                                  },
                                  method: "PUT",
                                  url:
                                    this.$f7.data.baseURL +
                                    "/api/PrimireApel/PutPRIMIRE_APEL/" +
                                    idCorelatie,
                                })
                                  .then((responseCorelatie) => {
                                    axios
                                      .get(
                                        this.$f7.data.baseURL +
                                          this.state.linkApiGetProiect +
                                          "/" +
                                          idProiectApel
                                      )
                                      .then((responseProiect) => {
                                        let proiect = responseProiect.data;
                                        proiect.reF_PROIECT =
                                          this.state.idProiectParinteTelefon;
                                        proiect.iD_CLIENT =
                                          this.state.idClientTelefon;

                                        const prParinte =
                                          this.$f7.data.allProiecte.find(
                                            (item) =>
                                              item.iD_PROIECT ===
                                              this.state.idProiectParinteTelefon
                                          );
                                        if (prParinte) {
                                          proiect.path =
                                            prParinte.path +
                                            proiect.iD_PROIECT +
                                            "\\";
                                        }

                                        axios({
                                          data: proiect,
                                          headers: {
                                            "Content-Type": "application/json",
                                          },
                                          method: "put",
                                          url:
                                            this.$f7.data.baseURL +
                                            this.state.linkApiUpdateProiect +
                                            "/" +
                                            idProiectApel,
                                        })
                                          .then((responseProiect2) => {
                                            axios
                                              .get(
                                                this.$f7.data.baseURL +
                                                  this.state.linkApiRealizari +
                                                  "/GetREALIZARE_SARCINI/" +
                                                  parseInt(idActivitate)
                                              )
                                              .then((responseActivitate) => {
                                                let realizareCurenta =
                                                  responseActivitate.data;
                                                realizareCurenta["ID_CLIENT"] =
                                                  this.state.idClientTelefon;

                                                axios({
                                                  data: realizareCurenta,
                                                  headers: {
                                                    "Content-Type":
                                                      "application/json",
                                                  },
                                                  method: "put",
                                                  url:
                                                    this.$f7.data.baseURL +
                                                    this.state
                                                      .linkApiRealizari +
                                                    "/PutREALIZARE_SARCINI/" +
                                                    parseInt(idActivitate),
                                                })
                                                  .then(
                                                    (responseActivitate2) => {
                                                      this.setState(
                                                        {
                                                          listaClientiTelefon:
                                                            [],
                                                          listaProiecteTelefon:
                                                            [],
                                                          idClientTelefon: null,
                                                          idProiectParinteTelefon:
                                                            null,
                                                        },
                                                        () => {
                                                          this.$f7.popup.close(
                                                            ".PopupApel"
                                                          );
                                                          resolve(
                                                            "Este task din apel!"
                                                          );
                                                        }
                                                      );
                                                    }
                                                  )
                                                  .catch((error) => {
                                                    console.log("error", error);
                                                    reject(error);
                                                  });
                                              })
                                              .catch((error) => {
                                                console.log("error", error);
                                                reject(error);
                                              });
                                          })
                                          .catch((error) => {
                                            console.log("error", error);
                                            reject(error);
                                          });
                                      })
                                      .catch((error) => {
                                        console.log("error", error);
                                        reject(error);
                                      });
                                  })
                                  .catch((error) => {
                                    console.log("error", error);
                                    reject(error);
                                  });
                              } else {
                                this.$f7.methods.openNotification(
                                  "Trebuie selectat atat clientul cat si proiectul parinte",
                                  "Atentie"
                                );
                              }
                            }}>
                            OK
                          </Button>
                        </div>
                      ),
                    },
                  });
                }
              );
            }
          })
        )
        .catch((error) => {
          console.log("error", error);
          reject(error);
        });
    });
  };

  /**
   * @description Functie de inchidere a unui task, in cazul in care se transmite deschideNou inseamna ca se deschide un task nou si de aceea se inchide curentul task,
   * inchideTaskPropus inseamna ca inchide taskul in cazul in care il propune,
   * inchideDupaOprire inseamna ca il inchide dupa ce opreste realizarea curenta
   * realocaDupaOprire inseamna ca taskul este realocat dupa ce este oprit
   * @date 08/04/2022
   * @function
   * @param {*} deschideNou bool, daca se deschide task nou
   * @param {*} idProiectVechi id-ul vechiului task, in cazul in care se deschide task nou
   * @param {*} inchideTaskPropus daca se inchide taskul propus sau nu
   * @param {*} inchideDupaOprire daca se inchide taskul dupa oprire sau nu
   * @param {*} realocaDupaOprire daca se realoca taskul dupa oprire sau nu
   * @memberof SarciniAlocateGrid
   */
  Sfarsit = (
    deschideNou,
    idProiectVechi,
    inchideTaskPropus,
    inchideDupaOprire,
    realocaDupaOprire
  ) => {
    return new Promise((resolve, reject) => {
      var idProiectDeschis = undefined;
      if (this.state.proiectDeschis !== null) {
        idProiectDeschis = this.state.proiectDeschis.IdProiect;
      }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((p) => {
          var lat = p.coords.latitude;
          var long = p.coords.longitude;
          this.setState({ lat: lat, lng: long });
        });
      }

      if (idProiectDeschis !== undefined) {
        //if (this.state.idSelected !== null) {
        var url =
          this.$f7.data.baseURL + this.state.linkApiRealizari + "/PutSfarsit";

        axios({
          data: {
            IdSalariat: this.$f7.data.idSalariatFunctii,
            InchisDeSalariat: true,
          },
          headers: { "Content-Type": "application/json" },
          method: "PUT",
          url: url,
        })
          .then((response) => {
            var ceReturneaza = response.data;
            if (ceReturneaza !== "Nu exista task deschis.") {
              this.setState(
                {
                  proiectDeschis: null,
                  sfarsit: true,
                  deschideNou: deschideNou,
                  idProiectVechi: idProiectVechi,
                  inchideDupaOprire: inchideDupaOprire,
                  realocaDupaOprire: realocaDupaOprire,
                },
                () => {
                  //if (this.$f7.data.verificareTaskUitat) {
                  //    this.$f7.sheet.close(".SheetDinamic");
                  //}

                  this.VerificareTaskApel(idProiectDeschis, ceReturneaza).then(
                    (tt) => {
                      this.GetHiddenValues(idProiectDeschis)
                        .then(() => {
                          this.$f7.methods
                            .esteDelegatie()
                            .then((delegatieCurenta) => {
                              if (delegatieCurenta !== false) {
                                var idDelegatie = delegatieCurenta.iD_DELEGATIE;

                                this.$f7.methods
                                  .detaliuDelegatieCurentaActivitate(
                                    idDelegatie
                                  )
                                  .then((detaliuDelegatieCurentaActivitate) => {
                                    if (
                                      detaliuDelegatieCurentaActivitate !==
                                      false
                                    ) {
                                      detaliuDelegatieCurentaActivitate.plecare =
                                        moment().format();
                                      detaliuDelegatieCurentaActivitate.plecarE_LNG =
                                        this.state.lng;
                                      detaliuDelegatieCurentaActivitate.plecarE_LAT =
                                        this.state.lat;
                                      this.UpdateValueDetaliu(
                                        detaliuDelegatieCurentaActivitate,
                                        detaliuDelegatieCurentaActivitate.iD_DETALIU_DELEGATIE
                                      )
                                        .then((resp) => {
                                          resolve(resp);
                                        })
                                        .catch((err) => {
                                          reject(err);
                                        });
                                    } else {
                                      resolve(
                                        detaliuDelegatieCurentaActivitate
                                      );
                                    }
                                  })
                                  .catch((errr) => {
                                    reject(errr);
                                  });
                              } else {
                                resolve(response);
                              }
                            });
                        })
                        .catch((e) => {
                          reject(e);
                        });
                    }
                  );
                }
              );

              //this.$f7.methods.openNotification("Task oprit", "Success");
            } else {
              if (!inchideTaskPropus) {
                this.$f7.methods.openNotification(
                  "Nu exista task deschis.",
                  "Eroare"
                );
              }
              resolve(response);
            }
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la actualizare");
            reject(error);
          });
      } else {
        reject("Nu exista task pornit!");
        this.$f7.methods.openNotification("Nu exista task pornit!");
      }
    });
  };

  /**
   * @description Inchide taskul selectat
   * @date 08/04/2022
   * @param {*} id id-ul proiectului
   * @param {*} idS id-ul sarcinii
   * @function
   * @memberof SarciniAlocateGrid
   */
  InchideTask = (id, idS) => {
    var idSelected = id !== undefined ? id : this.state.idSelected;
    var idSarcina = idS !== undefined ? idS : this.state.idSarcina;
    //if (idSelected) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            this.state.linkApiRealizari +
            "/VerificareTaskActivByUser/" +
            this.$f7.data.idSalariatFunctii
        )
        .then((responseVerificare) => {
          if (this.props.outside) {
            this.$f7.data.Proiect = null;
            this.props.updateDreapta();
          } else {
            this.$f7.methods.openMainDreaptaById(null);
          }
          if (responseVerificare.data === idSelected) {
            this.$f7.preloader.hide();
            this.$f7.dialog.confirm(
              "Taskul '" +
                this.$f7.data.Proiecte.filter((p) => {
                  return p.value === responseVerificare.data;
                })[0].key +
                "' este deschis. Doriti sa il opriti si sa il inchideti?",
              "Eroare",
              () => {
                this.$f7.preloader.show();
                var idProiectDeschis = undefined;
                if (this.state.proiectDeschis) {
                  idProiectDeschis = this.state.proiectDeschis.IdProiect;
                }
                this.Sfarsit(false, null, false, true, false, idProiectDeschis)
                  .then((responseSfarsit) => {
                    resolve(responseSfarsit);
                  })
                  .catch((errSfarsit) => {
                    reject(errSfarsit);
                  });
              },
              () => {
                resolve(responseVerificare);
              }
            );
          } else {
            this.InchideTaskVerificat(idSelected, idSarcina)
              .then((r) => {
                resolve(r);
              })
              .catch((e) => {
                reject(e);
              });
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la verificare");
          reject(error);
        });
    });
    //} else {
    //    this.$f7.methods.openNotification("Selectati o sarcina");
    //}
  };

  /**
   * @description Inchide taskul selectat
   * @function
   * @date 08/04/2022
   * @param {*} id id-ul proiectului
   * @param {*} idS id-ul sarcinii
   * @memberof SarciniAlocateGrid
   */
  InchideTaskVerificat = (id, idS) => {
    //var idSelected = id !== undefined ? id : this.state.idSelected;
    var idSarcina = idS !== undefined ? idS : this.state.idSarcina;

    //if (idSelected) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          this.$f7.data.baseURL +
            this.state.linkApi +
            "/GetSARCINI_SALARIAT/" +
            idSarcina
        )
        .then((response1) => {
          let sarcina = response1.data;

          sarcina.dATA_DEALOCARII = moment().format();
          sarcina.dealoC_CINE = this.$f7.data.user.iD_SALARIAT; //notsure

          let urlUpdate =
            this.$f7.data.baseURL +
            this.state.linkApi +
            "/PutSARCINI_SALARIAT/" +
            sarcina.iD_SARCINA;

          axios({
            data: sarcina,
            headers: { "Content-Type": "application/json" },
            method: "put",
            url: urlUpdate,
          })
            .then((response2) => {
              let url =
                this.$f7.data.baseURL +
                this.state.linkApi +
                "/PostSARCINI_SALARIAT";
              let sarcinaRezolvata = {
                iD_PROIECT: sarcina.iD_PROIECT,
                iD_SALARIAT: this.$f7.data.idSalariatFunctii,
                dE_CINE: this.$f7.data.user.iD_SALARIAT, //notsure
                datA_ALOCARII: moment().format(),
                dATA_DEALOCARII: null,
                dealoC_CINE: null,
                stare: 4,
              };

              axios({
                data: sarcinaRezolvata,
                headers: { "Content-Type": "application/json" },
                method: "post",
                url: url,
              })
                .then((response3) => {
                  resolve(response3);
                })
                .catch((error) => {
                  console.log("error", error);
                  this.$f7.methods.openNotification("Eroare la inserare");
                  reject(error);
                });
            })
            .catch((error) => {
              console.log("error", error);
              this.$f7.methods.openNotification("Eroare la actualizare");
              reject(error);
            });
        })
        .catch((error) => {
          console.log("error", error);
          this.$f7.methods.openNotification("Eroare la preluare");
          reject(error);
        });
    });
  };

  /**
   * @description Transmite taskul selectat inapoi in taskuri nealocate
   * @function
   * @date 08/04/2022
   * @param {*} idSarcina id-ul sarcinii selectate
   * @param {*} idProiect id-ul proiectului selectat
   * @memberof SarciniAlocateGrid
   */
  AnulareAsumareTask = (idSarcina, idProiect) => {
    var idSarcinaLocal =
      idSarcina !== undefined ? idSarcina : this.state.idSarcina;
    var idProiectLocal =
      idProiect !== undefined ? idProiect : this.state.idSelected;

    if (idSarcinaLocal) {
      if (this.props.outside) {
        this.$f7.data.Proiect = null;
        this.props.updateDreapta();
      } else {
        this.$f7.methods.openMainDreaptaById(null);
      }
      return new Promise((resolve, reject) => {
        axios
          .get(
            this.$f7.data.baseURL +
              this.state.linkApiRealizari +
              "/VerificareTaskActivByUser/" +
              this.$f7.data.idSalariatFunctii
          )
          .then((responseVerificare) => {
            if (idProiectLocal !== responseVerificare.data) {
              axios
                .get(
                  this.$f7.data.baseURL +
                    this.state.linkApiRealizari +
                    "/GetREALIZARE_SARCINI_BY_SALARIAT/" +
                    this.$f7.data.idSalariatFunctii
                )
                .then((responseVerificare2) => {
                  var exista = responseVerificare2.data.filter(
                    (item) => item.iD_PROIECT === idProiectLocal
                  );

                  if (exista.length === 0) {
                    axios
                      .get(
                        this.$f7.data.baseURL +
                          this.state.linkApi +
                          "/GetSARCINI_SALARIAT/" +
                          idSarcinaLocal
                      )
                      .then((response1) => {
                        var sarcina = response1.data;

                        sarcina.stare = 3;

                        var url =
                          this.$f7.data.baseURL +
                          this.state.linkApi +
                          "/PutSARCINI_SALARIAT/" +
                          sarcina.iD_SARCINA;

                        axios({
                          data: sarcina,
                          headers: { "Content-Type": "application/json" },
                          method: "put",
                          url: url,
                        })
                          .then((response2) => {
                            resolve(response2);
                          })
                          .catch((error) => {
                            console.log("error", error);
                            reject(error);
                          });
                      })
                      .catch((error) => {
                        console.log("error", error);
                        reject(error);
                      });
                  } else {
                    this.$f7.methods.openNotification(
                      "Un task la care s-a lucrat nu se poate anula!"
                    );
                    reject("Un task la care s-a lucrat nu se poate anula!");
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                  reject(error);
                });
            } else {
              this.$f7.methods.openNotification(
                "Un task pornit nu se poate anula!"
              );
              reject("Un task pornit nu se poate anula!");
            }
          })
          .catch((error) => {
            console.log("error", error);
            reject(error);
          });
      });
    } else {
      this.$f7.methods.openNotification("Selectati o sarcina");
    }
  };

  /**
   * @description Anuleaza toate taskurile asumate selectate,le trimite inapoi in nealocate
   * @function
   * @date 08/04/2022
   * @memberof SarciniAlocateGrid
   */
  AnulareAsumareCall = () => {
    this.$f7.methods
      .preCall(this.refDataGrid)
      .then((response) => {
        this.$f7.dialog.confirm(
          "Sunteti sigur ca doriti sa anulati toate sarcinile selectate?",
          "Anulare",
          (e) => {
            var state = this.refDataGrid.current.state;
            if (state.selectedRows.length > 0) {
              state.selectedRows
                .reduce((promise, item) => {
                  return promise.then(() => {
                    return this.AnulareAsumareTask(
                      item.IdSarcina,
                      item.IdProiect
                    )
                      .then((r) => {})
                      .catch((err) => {
                        console.log("err", err);
                      });
                  });
                }, Promise.resolve())
                .then(() => {
                  this.GetValues();
                  this.$f7.methods.openNotification("Taskuri anulate.");
                })
                .catch(() => {
                  console.log("Eroare");
                });
            } else {
              this.AnulareAsumareTask()
                .then((response) => {
                  this.GetValues();
                  this.$f7.methods.openNotification("Task anulat.");
                })
                .catch((error) => {
                  this.$f7.methods.openNotification("Eroare!");
                  console.log("error", error);
                });
            }
          }
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  /**
   * @description Transforma milisecunde intr-un format mai usor de inteles h,m,s
   * @date 08/04/2022
   * @param {*} ms
   * @memberof SarciniAlocateGrid
   */
  dhm = (ms) => {
    var days = Math.floor(ms / (24 * 60 * 60 * 1000));
    var daysms = ms % (24 * 60 * 60 * 1000);
    var hours = Math.floor(daysms / (60 * 60 * 1000));
    var hoursms = ms % (60 * 60 * 1000);
    var minutes = Math.floor(hoursms / (60 * 1000));
    return days + "z " + hours + "h " + minutes + "m ";
  };

  /**
   * @description Deschide interfata de propunere a taskurilor selectate
   * @function
   * @date 08/04/2022
   * @memberof SarciniAlocateGrid
   */
  PropuneCall = () => {
    this.$f7.methods
      .preCall(this.refDataGrid)
      .then((response) => {
        this.$f7.dialog.confirm(
          "Sunteti sigur ca doriti sa realocati sarcinile selectate?",
          "Propunere",
          (e) => {
            var listaIduri = null;
            var existaDeschis = false;
            // var projDeschis = null;

            var state = this.refDataGrid.current.state;
            if (state.selectedRows.length > 0) {
              listaIduri = [];
              state.selectedRows.map((item) => {
                listaIduri.push(item.IdProiect);
                if (
                  this.state.proiectDeschis !== null &&
                  this.state.proiectDeschis.IdSarcina === item.IdSarcina
                ) {
                  existaDeschis = true;
                  //  projDeschis = { IdProiect: item.IdProiect, IdSarcina: item.IdSarcina };
                }
                return null;
              });
            } else {
              listaIduri = this.state.idSelected;
              if (
                this.state.proiectDeschis !== null &&
                this.state.proiectDeschis.IdSarcina === this.state.idSarcina
              ) {
                existaDeschis = true;
                //  projDeschis = { IdProiect: this.state.idSelected, IdSarcina: this.state.idSarcina };
              } else {
                existaDeschis = false;
              }
            }
            if (existaDeschis) {
              this.$f7.preloader.show();
              var idProiectDeschis = undefined;
              if (this.state.proiectDeschis) {
                idProiectDeschis = this.state.proiectDeschis.IdProiect;
              }
              this.Sfarsit(false, null, false, false, false, idProiectDeschis)
                .then((r) => {
                  this.$f7.data.ATidProiect = listaIduri;
                  this.$f7.data.router.navigate("/popupDinamic/", {
                    props: {
                      onPopupClose: (r) => {
                        this.$f7.methods.refreshStanga();
                      },
                      component: <ATMain Proiect={null} idProiect={null} />,
                    },
                  });
                })
                .catch((err) => {
                  console.log("err", err);
                });
            } else {
              this.$f7.data.ATidProiect = listaIduri;
              this.$f7.data.router.navigate("/popupDinamic/", {
                props: {
                  onPopupClose: (r) => {
                    this.$f7.methods.refreshStanga();
                  },
                  component: <ATMain Proiect={null} idProiect={null} />,
                },
              });
            }
          }
        );
      })
      .catch((err) => {
        console.log("err");
        this.$f7.methods.openNotification("Eroare" + err);
      });
  };

  /**
   * @description Parcurge lista de taskuri selectate si apeleaza functia de inchidere pentru fiecare in parte
   * @function
   * @date 08/04/2022
   * @memberof SarciniAlocateGrid
   */
  InchideCall = () => {
    this.$f7.methods
      .preCall(this.refDataGrid)
      .then((response) => {
        var state = this.refDataGrid.current.state;

        if (state.selectedRows.length > 0) {
          this.$f7.dialog.confirm(
            "Sunteti sigur ca doriti sa inchideti toate taskurile selectate?",
            "Warning",
            () => {
              this.$f7.preloader.show();
              state.selectedRows
                .reduce((promise, item) => {
                  var functia = null;
                  if (this.state.proiectDeschis !== null) {
                    console.log(
                      this.state.proiectDeschis,
                      this.state.proiectDeschis.IdSarcina,
                      item.IdSarcina
                    );
                  }

                  if (
                    this.state.proiectDeschis !== null &&
                    this.state.proiectDeschis.IdSarcina === item.IdSarcina
                  ) {
                    functia = this.InchideTask;
                  } else {
                    functia = this.InchideTaskVerificat;
                  }

                  if (
                    this.state.proiectDeschis !== null &&
                    this.state.proiectDeschis.IdSarcina === item.IdSarcina
                  ) {
                    return promise.then(() => {
                      return functia(item.IdProiect, item.IdSarcina)
                        .then((r) => {})
                        .catch((err) => {
                          //console.log("err", err);
                        });
                    });
                  } else {
                    return promise.then(() => {
                      this.setState(
                        {
                          comInchidereTask: true,
                        },
                        () => {}
                      );

                      return this.GetHiddenValues(item.IdProiect)
                        .then(() => {
                          return functia(item.IdProiect, item.IdSarcina)
                            .then((r) => {})
                            .catch((err) => {
                              //console.log("err", err);
                            });
                        })
                        .catch((e) => {
                          //reject(e);
                        });
                    });
                  }
                }, Promise.resolve())
                .then(() => {
                  this.GetValues()
                    .then((r) => {
                      this.$f7.methods.openMainDreaptaById(
                        this.$f7.data.Proiect.iD_PROIECT
                      );
                      this.$f7.preloader.hide();
                      this.$f7.methods.openNotification("Taskuri inchise");
                    })
                    .catch((error) => {
                      this.$f7.methods.openMainDreaptaById(
                        this.$f7.data.Proiect.iD_PROIECT
                      );
                      this.$f7.preloader.hide();
                      this.$f7.methods.openNotification("Eroare: " + error);
                    });
                })
                .catch((error) => {
                  this.$f7.methods.openMainDreaptaById(
                    this.$f7.data.Proiect.iD_PROIECT
                  );
                  this.$f7.methods.openNotification("Eroare: " + error);
                  console.log("Eroare");
                });
            },
            () => {}
          );
        } else if (this.state.idSelected) {
          var functia = null;
          var id = this.state.idSelected;
          var idS = this.state.idSarcina;

          if (
            this.state.proiectDeschis !== null &&
            this.state.proiectDeschis.IdSarcina === idS
          ) {
            functia = this.InchideTask;
          } else {
            functia = this.InchideTaskVerificat;
          }

          if (
            this.state.proiectDeschis !== null &&
            this.state.proiectDeschis.IdSarcina === idS
          ) {
            functia(id, idS)
              .then((response) => {
                this.GetValues()
                  .then((r) => {
                    this.$f7.preloader.hide();
                    this.$f7.methods.openNotification("Task inchis.");
                  })
                  .catch((error) => {
                    this.$f7.preloader.hide();
                    this.$f7.methods.openNotification("Eroare: " + error);
                  });
              })
              .catch((error) => {
                this.$f7.methods.openNotification("Eroare: " + error);
                console.log("error", error);
              });
          } else {
            this.setState(
              {
                comInchidereTask: true,
              },
              () => {
                this.GetHiddenValues(id)
                  .then(() => {
                    functia(id, idS)
                      .then((response) => {
                        this.GetValues()
                          .then((r) => {
                            this.$f7.methods.openMainDreaptaById(
                              this.$f7.data.Proiect.iD_PROIECT
                            );
                            this.$f7.preloader.hide();
                            this.$f7.methods.openNotification("Task inchis.");
                          })
                          .catch((error) => {
                            this.$f7.methods.openMainDreaptaById(
                              this.$f7.data.Proiect.iD_PROIECT
                            );
                            this.$f7.preloader.hide();
                            this.$f7.methods.openNotification(
                              "Eroare: " + error
                            );
                          });
                      })
                      .catch((error) => {
                        this.$f7.methods.openMainDreaptaById(
                          this.$f7.data.Proiect.iD_PROIECT
                        );
                        this.$f7.methods.openNotification("Eroare: " + error);
                        console.log("error", error);
                      });
                  })
                  .catch((e) => {
                    //reject(e);
                  });
              }
            );
          }
        } else {
          this.$f7.methods.openNotification("Selectati o sarcina");
        }
      })
      .catch((err) => {
        console.log("err");
        this.$f7.methods.openNotification("Eroare" + err);
      });
  };

  render() {
    return (
      <>
        <div style={{ position: "relative" }}>
          <div style={{ position: "relative" }}>
            <Segmented
              className="butoaneContextDatagrid"
              raised
              tag="div"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "calc(100% - 176px)",
              }}>
              <Button
                tooltip={"Start"}
                onClick={() => {
                  this.$f7.preloader.show();
                  this.Inceput()
                    .then((r) => {
                      this.GetValues()
                        .then(() => {
                          this.$f7.preloader.hide();
                          this.$f7.methods.openMainDreaptaById(
                            this.$f7.data.Proiect.iD_PROIECT
                          );
                        })
                        .catch((e) => {
                          this.$f7.preloader.hide();
                          this.$f7.methods.openNotification("Error: " + e);
                          this.$f7.methods.openMainDreaptaById(
                            this.$f7.data.Proiect.iD_PROIECT
                          );
                        });
                    })
                    .catch((e) => {
                      this.$f7.preloader.hide();
                      this.$f7.methods.openNotification("Error: " + e);
                      this.$f7.methods.openMainDreaptaById(
                        this.$f7.data.Proiect.iD_PROIECT
                      );
                    });
                }}
                small
                fill>
                Start
              </Button>
              <Button
                tooltip={"Stop"}
                onClick={() => {
                  this.$f7.methods
                    .preCall(this.refDataGrid)
                    .then(() => {
                      this.$f7.preloader.show();
                      var idProiectDeschis = undefined;
                      if (this.state.proiectDeschis) {
                        idProiectDeschis = this.state.proiectDeschis.IdProiect;
                      }
                      this.Sfarsit(
                        false,
                        null,
                        false,
                        false,
                        false,
                        idProiectDeschis
                      )
                        .then((r) => {
                          this.GetValues()
                            .then(() => {
                              this.$f7.preloader.hide();
                              this.$f7.methods.openMainDreaptaById(
                                this.$f7.data.Proiect.iD_PROIECT
                              );
                            })
                            .catch((e) => {
                              this.$f7.preloader.hide();
                              this.$f7.methods.openMainDreaptaById(
                                this.$f7.data.Proiect.iD_PROIECT
                              );
                              this.$f7.methods.openNotification("Error: " + e);
                            });
                        })
                        .catch((e) => {
                          this.$f7.preloader.hide();
                          this.$f7.methods.openMainDreaptaById(
                            this.$f7.data.Proiect.iD_PROIECT
                          );
                          this.$f7.methods.openNotification("Error: " + e);
                        });
                    })
                    .catch((e) => {
                      this.$f7.methods.openMainDreaptaById(
                        this.$f7.data.Proiect.iD_PROIECT
                      );
                      this.$f7.methods.openNotification("Error: " + e);
                    });
                }}
                small
                fill>
                Stop
              </Button>
              <Button
                tooltip={"Inchide task"}
                onClick={() => {
                  this.InchideCall();
                }}
                small
                fill>
                Inchide
              </Button>
              <Button
                tooltip={"Propune altcuiva"}
                onClick={() => {
                  this.PropuneCall();
                }}
                small
                fill>
                Propune cuiva
              </Button>
              <Button
                tooltip={"Anulare task"}
                onClick={() => {
                  this.AnulareAsumareCall();
                }}
                small
                fill>
                Anulare
              </Button>
              <Button
                tooltip={"Primire apel telefonic"}
                onClick={() => {
                  this.PrimireApel();
                  //this.$f7.dialog.alert("Functie temporar dezactivata pentru mentenanta!", "Atentie");
                }}
                small
                fill>
                Primire Apel
              </Button>
              <Button
                tooltip={"Refresh"}
                onClick={() => {
                  this.GetValues();
                }}
                small
                fill>
                Refresh
              </Button>
            </Segmented>
            <DataGrid
              refresh={this.GetValues}
              dataLoaded={this.state.dataLoaded}
              name={"SarciniAlocateGrid"}
              ref={this.refDataGrid}
              id={"IdSarcina"}
              onRowClick={(state) => {
                var id = state.rowData.IdProiect;
                var sarcina = state.rowData.IdSarcina;
                this.setState(
                  {
                    idSelected: id,
                    idSarcina: sarcina,
                  },
                  () => {
                    if (this.props.outside) {
                      var Proiect = this.$f7.data.allProiecte.filter((pr) => {
                        return pr.iD_PROIECT === id;
                      })[0];
                      this.$f7.data.Proiect = Proiect;
                      this.props.updateDreapta();
                    } else {
                      this.$f7.methods.openMainDreaptaById(
                        this.state.idSelected
                      );
                    }
                    this.$f7.data.agendaIdProiect = this.state.idSelected;
                    // this.GetHiddenValues();
                  }
                );
              }}
              onInitialised={(state, r, itemeRandate) => {
                this.$f7.methods.colorItems(
                  state,
                  r,
                  itemeRandate,
                  "DeCine",
                  1,
                  true
                );
              }}
              onRenderItemsChanged={(state, r, itemeRandate) => {
                this.$f7.methods.colorItems(
                  state,
                  r,
                  itemeRandate,
                  "DeCine",
                  0,
                  true
                );
              }}
              keys={[
                "💾",
                "Denumire",
                "Cerinta",
                "DataCreare",
                "Client",
                "Sursa",
                "PR",
              ]}
              ignoreInputsAdd={["Deadline"]}
              ignoreInputsEdit={["Deadline"]}
              hintInputSources={[
                "Path",
                "OperatorAdaugare",
                "BugetTimp",
                "TimpPlanD",
                "PVuri",
              ]}
              renderUpdate={false}
              renderDelete={false}
              renderAdd={false}
              renderCheckbox={true}
              renderFilterColumns={true}
              maxHeight={this.state.height}
              inputs={this.state.inputs}
              dataSource={this.state.dataSource}
              contextMenuItems={(state, context) => {
                return [
                  {
                    key: "Start",
                    onClick: (state) => {
                      setTimeout(() => {
                        this.$f7.preloader.show();
                        this.Inceput()
                          .then((r) => {
                            this.GetValues()
                              .then(() => {
                                this.$f7.preloader.hide();
                                this.$f7.methods.openMainDreaptaById(
                                  this.$f7.data.Proiect.iD_PROIECT
                                );
                              })
                              .catch((e) => {
                                console.log("err", e);
                                this.$f7.preloader.hide();
                                this.$f7.methods.openMainDreaptaById(
                                  this.$f7.data.Proiect.iD_PROIECT
                                );
                              });
                          })
                          .catch((e) => {
                            this.$f7.preloader.hide();
                            console.log("err", e);
                            this.$f7.methods.openMainDreaptaById(
                              this.$f7.data.Proiect.iD_PROIECT
                            );
                          });
                      }, 1000);
                    },
                    icon: "play_arrow",
                  },
                  {
                    key: "Stop",
                    onClick: (state) => {
                      setTimeout(() => {
                        this.$f7.dialog.confirm(
                          "Sunteti sigur ca doriti sa opriti aceasta sarcina?",
                          "Oprire",
                          (ef) => {
                            this.$f7.methods
                              .preCall(this.refDataGrid)
                              .then(() => {
                                this.$f7.preloader.show();
                                var idProiectDeschis = undefined;
                                if (this.state.proiectDeschis) {
                                  idProiectDeschis =
                                    this.state.proiectDeschis.IdProiect;
                                }
                                this.Sfarsit(
                                  false,
                                  null,
                                  false,
                                  false,
                                  false,
                                  idProiectDeschis
                                )
                                  .then((r) => {
                                    this.GetValues()
                                      .then(() => {
                                        this.$f7.preloader.hide();
                                        this.$f7.methods.openMainDreaptaById(
                                          this.$f7.data.Proiect.iD_PROIECT
                                        );
                                      })
                                      .catch((e) => {
                                        this.$f7.preloader.hide();
                                        this.$f7.methods.openMainDreaptaById(
                                          this.$f7.data.Proiect.iD_PROIECT
                                        );
                                        this.$f7.methods.openNotification(
                                          "Error: " + e
                                        );
                                      });
                                  })
                                  .catch((e) => {
                                    this.$f7.preloader.hide();
                                    this.$f7.methods.openMainDreaptaById(
                                      this.$f7.data.Proiect.iD_PROIECT
                                    );
                                    this.$f7.methods.openNotification(
                                      "Error: " + e
                                    );
                                  });
                              })
                              .catch((e) => {
                                this.$f7.methods.openMainDreaptaById(
                                  this.$f7.data.Proiect.iD_PROIECT
                                );
                                this.$f7.methods.openNotification(
                                  "Error: " + e
                                );
                              });
                          }
                        );
                      }, 1000);
                    },
                    icon: "stop",
                  },
                  {
                    key: "Inchide task",
                    onClick: (state) => {
                      setTimeout(() => {
                        this.InchideCall();
                      }, 1000);
                    },
                    icon: "done",
                  },
                  {
                    key: "Propune altcuiva",
                    onClick: (state) => {
                      setTimeout(() => {
                        this.PropuneCall();
                      }, 100);
                    },
                    icon: "send",
                  },
                  {
                    key: "Anulare",
                    onClick: (state) => {
                      setTimeout(() => {
                        this.AnulareAsumareCall();
                      }, 1000);
                    },
                    icon: "close",
                  },
                  {
                    key: "Primire Apel",
                    onClick: (state) => {
                      setTimeout(() => {
                        this.PrimireApel();
                        //this.$f7.dialog.alert("Functie temporar dezactivata pentru mentenanta!", "Atentie");
                      }, 1000);
                    },
                    icon: "phone",
                  },
                  {
                    key: "Refresh",
                    onClick: (state) => {
                      setTimeout(() => {
                        this.GetValues();
                      }, 1000);
                    },
                    icon: "refresh",
                  },
                  {
                    disabled:
                      context.state.allSelectedRows.length > 0 &&
                      context.state.allSelectedRows.filter((r) => {
                        return r.IsArhivat;
                      }).length === context.state.allSelectedRows.length,
                    key: "Arhiveaza",
                    onClick: (state) => {
                      setTimeout(() => {
                        var selectedRows = state.allSelectedRows;
                        if (selectedRows.length === 0) {
                          selectedRows = [state.rowDataAll];
                        }
                        var listaIduri = [];
                        selectedRows.map((r) => {
                          var id = r.IdProiect;
                          if (!r.IsArhivat) {
                            listaIduri.push(id);
                          }
                          return null;
                        });
                        this.$f7.methods
                          .arhiveazaDezarhiveazaProiect(listaIduri, true)
                          .then((r) => {
                            this.GetValues();
                            const nr = r.data.length;
                            this.$f7.methods.openNotification(
                              "Arhivate cu success: " + nr
                            );
                          })
                          .catch((err) => {
                            this.$f7.methods.openNotification(
                              "Eroare la arhivare" + err
                            );
                          });
                      }, 1000);
                    },
                    icon: "compress",
                  },
                  {
                    disabled:
                      context.state.allSelectedRows.length > 0 &&
                      context.state.allSelectedRows.filter((r) => {
                        return !r.IsArhivat;
                      }).length === context.state.allSelectedRows.length,
                    key: "Dezarhiveaza",
                    onClick: (state) => {
                      setTimeout(() => {
                        var selectedRows = state.allSelectedRows;
                        if (selectedRows.length === 0) {
                          selectedRows = [state.rowDataAll];
                        }
                        var listaIduri = [];
                        selectedRows.map((r) => {
                          var id = r.IdProiect;
                          if (r.IsArhivat) {
                            listaIduri.push(id);
                          }
                          return null;
                        });
                        this.$f7.methods
                          .arhiveazaDezarhiveazaProiect(listaIduri, false)
                          .then((r) => {
                            this.GetValues();
                            const nr = r.data.length;
                            this.$f7.methods.openNotification(
                              "Dezarhivate cu success: " + nr
                            );
                          })
                          .catch((err) => {
                            this.$f7.methods.openNotification(
                              "Eroare la dezarhivare" + err
                            );
                          });
                      }, 1000);
                    },
                    icon: "expand",
                  },
                  {
                    disabled:
                      context.state.allSelectedRows.length > 0 &&
                      context.state.allSelectedRows.filter((r) => {
                        return r.IsHidden;
                      }).length === context.state.allSelectedRows.length,
                    key: "Ascunde",
                    onClick: (state, context) => {
                      setTimeout(() => {
                        var selectedRows = state.allSelectedRows;
                        if (selectedRows.length === 0) {
                          selectedRows = [state.rowDataAll];
                        }
                        var listaIduri = [];
                        selectedRows.map((r) => {
                          var id = r.IdProiect;
                          if (!r.IsHidden) {
                            listaIduri.push(id);
                          }
                          return null;
                        });
                        this.$f7.methods
                          .ascundeAfiseazaProiect(listaIduri, true)
                          .then(() => {
                            this.GetValues();
                            this.$f7.methods.openNotification(
                              "Ascuns cu success"
                            );
                          })
                          .catch((err) => {
                            this.$f7.methods.openNotification(
                              "Eroare la ascundere" + err
                            );
                          });
                      }, 1000);
                    },
                    icon: "refresh",
                  },
                  {
                    disabled:
                      context.state.allSelectedRows.length > 0 &&
                      context.state.allSelectedRows.filter((r) => {
                        return !r.IsHidden;
                      }).length === context.state.allSelectedRows.length,
                    key: "Afiseaza",
                    onClick: (state, context) => {
                      setTimeout(() => {
                        var selectedRows = state.allSelectedRows;
                        if (selectedRows.length === 0) {
                          selectedRows = [state.rowDataAll];
                        }
                        var listaIduri = [];
                        selectedRows.map((r) => {
                          var id = r.IdProiect;
                          if (r.IsHidden) {
                            listaIduri.push(id);
                          }
                          return null;
                        });

                        this.$f7.methods
                          .ascundeAfiseazaProiect(listaIduri, false)
                          .then(() => {
                            this.GetValues();
                            this.$f7.methods.openNotification(
                              "Afisat cu success"
                            );
                          })
                          .catch((err) => {
                            this.$f7.methods.openNotification(
                              "Eroare la afisare" + err
                            );
                          });
                      }, 1000);
                    },
                    icon: "refresh",
                  },
                  {
                    key: "Trimite mesaj",
                    onClick: (state) => {
                      var id = state.rowData.IdProiect;
                      //var sarcina = state.rowData.IdSarcina;
                      this.$f7.methods.openMesaje(null, id);
                    },
                    icon: "send",
                  },
                  {
                    key: "Detalii Task",
                    icon: "arrow",
                    onClick: (state) => {
                      setTimeout(() => {
                        const id = state.rowData.IdProiect;
                        this.$f7.views.main.router.navigate("/detaliiTask/", { props: { idProiect: id } })
                      }, 500)
                    }
                  }
                ];
              }}
              onContextMenu={(state, target) => {
                var id = state.rowData.IdProiect;
                var sarcina = state.rowData.IdSarcina;
                this.setState(
                  {
                    idSelected: id,
                    idSarcina: sarcina,
                  },
                  () => {
                    if (this.props.outside) {
                      var Proiect = this.$f7.data.allProiecte.filter((pr) => {
                        return pr.iD_PROIECT === id;
                      })[0];
                      this.$f7.data.Proiect = Proiect;
                      this.props.updateDreapta();
                    } else {
                      this.$f7.methods.openMainDreaptaById(
                        this.state.idSelected
                      );
                    }
                    this.$f7.data.agendaIdProiect = this.state.idSelected;
                  }
                );
              }}
              coloredRow={{
                sourceCol: "Deadline",
                type: "date",
              }}
            />
          </div>
        </div>
      </>
    );
  }
}
export default SarciniAlocateGrid;
