import React, { Component } from "react";
import { Row, Col, Treeview, TreeviewItem, Preloader } from "framework7-react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import axios from "axios";

var moment = require("moment");
const localizer = momentLocalizer(moment);

class GantRealizari extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataLoaded: false,
            dataCalendarLoaded: true,
            selectedItem: null,
            selectedId: null,
            linkApi: "/api/GanttRealizari",
            treeDataSource: [],
            calendarDataSource: [],
            listaDepartamente: this.$f7.data.Departamente
        };
        this.ref1 = React.createRef();
        this.ref2 = React.createRef();
    }

    recursiva = (baza, ref, cat) => {
        let bazaFiltrata = [];
        let newCat = "";

        if (cat === "D") {
            bazaFiltrata = baza.filter(
                b =>
                    b.refProiect === null &&
                    b.refSalariat === null &&
                    b.departament === true &&
                    b.id_departament === ref
            );
            newCat = "S";
        } else if (cat === "S") {
            bazaFiltrata = baza.filter(
                b =>
                    b.refProiect === null &&
                    b.refSalariat === null &&
                    !b.departament &&
                    b.id_departament === ref
            );
            newCat = "P";
        } else if (cat === "P") {
            bazaFiltrata = baza.filter(
                b =>
                    b.refProiect !== null &&
                    b.refSalariat !== null &&
                    b.id_departament === ref
            );
            newCat = "P";
        } else {
            bazaFiltrata = [];
        }

        return bazaFiltrata.map(m => {
            m.childs = this.recursiva(baza, m.id_salariat, newCat);
            return m;
        });
    };

    toggleSelectable(e, item) {
        var self = this;
        var $ = self.$$;
        if ($(e.target).is(".treeview-toggle")) return;
        self.setState(
            {
                selectedItem: item.nume_dep,
                selectedId: parseInt(item.id_departament)
            },
            () => {
                if (
                    this.state.selectedId !== -1 &&
                    item.refProiect === null &&
                    item.refSalariat === null
                )
                    this.GetValuesCalendar(this.state.selectedId);
            }
        );
    }

    GetValuesTree = () => {
        this.setState({
            dataLoaded: false
        });

        axios
            .get(this.$f7.data.baseURL + this.state.linkApi)
            .then(response => {
                let gRealizari = response.data;
                let ld = [...this.state.listaDepartamente];
                let departamenteDeAdaugat = [];

                ld.shift();

                for (let i = 0; i < ld.length; i++) {
                    let aux = {};

                    aux["id_salariat"] = 9000 + ld[i].value;
                    aux["id_departament"] = -1;
                    aux["nume_dep"] = ld[i].key;
                    aux["refSalariat"] = null;
                    aux["refProiect"] = null;
                    aux["departament"] = true;

                    departamenteDeAdaugat.push(aux);
                }

                let realizariFull = [...departamenteDeAdaugat, ...gRealizari];
                let realizariFinal = this.recursiva(realizariFull, -1, "D");

                this.setState(
                    {
                        treeDataSource: realizariFinal
                    },
                    () => {
                        setTimeout(() => {
                            this.setState({
                                dataLoaded: true
                            });
                        }, 400);
                    }
                );
            })
            .catch(error => {
                this.setState(
                    {
                        treeDataSource: [],
                        dataLoaded: true
                    },
                    () => {
                        this.$f7.dialog.confirm(
                            "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                            "Ups...",
                            () => {
                                this.GetValuesTree();
                            },
                            () => { }
                        );
                    }
                );

                console.log("error", error);
            });
    };

    GetValuesCalendar = () => {
        this.setState({
            dataCalendarLoaded: false
        });

        axios
            .get(
                this.$f7.data.baseURL + this.state.linkApi + "/" + this.state.selectedId
            )
            .then(response => {
                let eventsArray = [];

                response.data.map(item => {
                    let itemObj = {};
                    itemObj["id"] = item.id_departament;
                    itemObj["title"] = item.caption;
                    itemObj["start"] = new Date(item.data_start);
                    itemObj["end"] = new Date(item.data_end);
                    eventsArray.push(itemObj);
                    return null;
                });

                this.setState(
                    {
                        calendarDataSource: eventsArray
                    },
                    () => {
                        setTimeout(() => {
                            this.setState({
                                dataCalendarLoaded: true
                            });
                        }, 400);
                    }
                );
            })
            .catch(error => {
                this.setState(
                    {
                        calendarDataSource: [],
                        dataCalendarLoaded: true
                    },
                    () => {
                        this.$f7.dialog.confirm(
                            "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
                            "Ups...",
                            () => {
                                this.GetValuesCalendar(this.state.selectedId);
                            },
                            () => { }
                        );
                    }
                );

                console.log("error", error);
            });
    };

    componentDidMount = () => {
        this.GetValuesTree();
    };

    renderTreeview = items => {
        return items.map((item, index) => {
            if (item.childs) {
                return (
                    <TreeviewItem
                        key={item.nume_dep + "-" + index}
                        label={item.nume_dep}
                        selectable
                        selected={this.state.selectedItem === item.nume_dep}
                        onClick={e => this.toggleSelectable(e, item)}
                        style={{
                            padding: 0
                        }}
                    >
                        {this.renderTreeview(item.childs)}
                    </TreeviewItem>
                );
            } else {
                return (
                    <TreeviewItem
                        key={item.nume_dep + "-" + index}
                        label={item.nume_dep}
                        selectable
                        selected={this.state.selectedItem === item.nume_dep}
                        onClick={e => this.toggleSelectable(e, item)}
                        style={{
                            padding: 0
                        }}
                    />
                );
            }
        });
    };

    render() {
        return this.state.dataLoaded ? (
            <Row>
                <Col
                    ref={this.ref1}
                    onGridResize={() => {
                        localStorage.setItem(
                            "GanttWidthStanga",
                            this.ref1.current.eventTargetEl.style.width
                        );
                    }}
                    style={{
                        width:
                            localStorage.getItem("GanttWidthStanga") === null
                                ? "49%"
                                : localStorage.getItem("GanttWidthStanga")
                    }}
                    resizable
                >
                    <Treeview
                        style={{
                            overflow: "hidden",
                            height: "calc(100vh - 44px)",
                            overflowY: "auto",
                            paddingTop: "10px",
                            boxSizing: "border-box"
                        }}
                    >
                        {this.renderTreeview(this.state.treeDataSource)}
                    </Treeview>
                </Col>

                <Col
                    ref={this.ref2}
                    onGridResize={() => {
                        localStorage.setItem(
                            "GanttWidthDreapta",
                            this.ref2.current.eventTargetEl.style.width
                        );
                    }}
                    style={{
                        position: "relative",
                        width:
                            localStorage.getItem("GanttWidthDreapta") === null
                                ? "49%"
                                : localStorage.getItem("GanttWidthDreapta")
                    }}
                    resizable
                >
                    {this.state.dataCalendarLoaded ? (
                        <Calendar
                            localizer={localizer}
                            startAccessor="start"
                            endAccessor="end"
                            events={this.state.calendarDataSource}
                            defaultView={Views.MONTH}
                            views={["month"]}
                            style={{
                                marginTop: "10px",
                                height: parseInt(window.innerHeight - 60),
                                width: "100%"
                            }}
                        />
                    ) : (
                        <div
                            style={{
                                position: "absolute",
                                width: "35px",
                                height: "35px",
                                top: "calc(50vh)",
                                left: "50%"
                            }}
                        >
                            <Preloader size={40} color={"var(--f7-theme-color)"} />
                        </div>
                    )}
                </Col>
            </Row>
        ) : (
            <div
                style={{
                    position: "absolute",
                    width: "35px",
                    height: "35px",
                    top: "50vh",
                    left: "50%"
                }}
            >
                <Preloader size={40} color={"var(--f7-theme-color)"} />
            </div>
        );
    }
}
export default GantRealizari