import React, { Component } from "react";
import { Button, Icon } from "framework7-react";
import Peer from "simple-peer";
import VideoStreamMerger from "video-stream-merger";
import { saveAs } from "file-saver";

class OtherVideo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			stream: null,
		};

		this.ref = React.createRef();
	}

	componentDidMount = () => {
		this.props.peer.on("stream", (stream) => {
			this.setState(
				{
					stream: stream,
				},
				() => {
					this.ref.current.srcObject = stream;
					if (this.props.merger) this.props.mergerDraw(this.props.merger);
				}
			);
		});
	};

	recorderStop = (notEnd) => {
		this.setState(
			{
				onlyStop: notEnd,
			},
			() => {
				if (this.state.record) {
					if (this.state.mediaRecorder) this.state.mediaRecorder.stop();
				} else {
					const popup = this.$f7.popup.get(".PopupCall");
					if (popup && popup.opened) {
						this.$f7.popup.close(".PopupCall");
					}
				}
			}
		);
	};

	render() {
		return (
			<div
				className="multiOtherVideo"
				style={{
					position: "relative",
					width:
						this.props.count < 9
							? this.props.count < 4
								? "50%"
								: "33.33%"
							: "248px",
					height:
						this.props.count < 9
							? this.props.count < 4
								? "50%"
								: "33.33%"
							: "140px",
					overflow: "hidden",
					backgroundColor: "rgb(64 64 64)",
					float: "left",
				}}
			>
				<span
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						color: "#fff",
						zIndex: 1,
						backgroundColor: "rgba(0, 0, 0, 0.4)",
						padding: "4px 10px",
					}}
				>
					{this.props.user.user}
				</span>

				{this.props.noUserVideo ? (
					<Icon
						color={"white"}
						size={100}
						material={"videocam_off"}
						style={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							zIndex: 10,
						}}
					/>
				) : null}

				<video
					style={{
						transform: "scale(-1.3, 1.3)",
						visibility: this.props.noUserVideo ? "hidden" : "visible",
					}}
					ref={this.ref}
					autoPlay
					playsInline
					width="100%"
					height="100%"
				/>
			</div>
		);
	}
}

class GroupCall extends Component {
	constructor(props) {
		super(props);

		this.state = {
			rConnection: this.$f7.data.signalRConnection,
			groupId: this.props.group,
			myStream: null,
			peers: [],
			peersRef: [],
			micOff: false,
			spkOffAll: false,
			videoOff:
				this.props.audioOnly !== undefined && this.props.audioOnly
					? true
					: false,
			record: false,
			merger: null,
			mediaRecorder: null,
			onlyStop: false,
		};

		this.myVideo = React.createRef();
	}

	componentDidMount = () => {
		navigator.mediaDevices
			.getUserMedia({
				video: {
					width: { max: 1280, ideal: 640 },
					height: { max: 720, ideal: 360 },
				},
				audio: true,
			})
			.then((stream) => {
				if (this.props.audioOnly) {
					const tracks = stream.getTracks();
					tracks.forEach((track) => {
						if (track.kind === "video") track.enabled = false;
					});
				}

				this.setState(
					{
						myStream: stream,
					},
					async () => {
						console.log("myStream:", this.state.myStream.getTracks());
						this.myVideo.current.srcObject = this.state.myStream;

						if (this.state.rConnection.state === "Connected") {
							console.log("groupId:", this.state.groupId);
							await this.state.rConnection.send(
								"JoinGroup",
								this.state.groupId
							);

							this.state.rConnection.on(
								"AllUsers",
								/*async*/(users) => {
									//await this.state.rConnection.send('ShareCameraForGroup', !this.state.videoOff, this.state.groupId)

									console.log("AllUsers:", users);

									let peers = [];
									let peersRef = [];

									users.forEach((user) => {
										let peer = this.createPeer(
											user.connectionId,
											this.state.rConnection.connectionId,
											this.state.myStream
										);

										peersRef.push({
											user: user,
											peerID: user.connectionId,
											peer,
											noUserVideo: true,
										});

										peers.push({
											user: user,
											peerID: user.connectionId,
											peer,
											noUserVideo: true,
										});
									});

									this.setState(
										{
											peers: peers,
											peersRef: peersRef,
										},
										async () => {
											await this.state.rConnection.send(
												"ShareCameraForGroup",
												!this.state.videoOff,
												this.state.groupId
											);
										}
									);
								}
							);

							this.state.rConnection.on(
								"UserJoined",
								(signal, callerID, user) => {
									console.log("UserJoined:", signal, callerID, user);
									let peer = this.addPeer(
										signal,
										callerID,
										this.state.myStream
									);

									this.setState(
										{
											peers: [
												...this.state.peers,
												{
													user: user,
													peerID: callerID,
													peer,
													noUserVideo: true,
												},
											],
											peersRef: [
												...this.state.peersRef,
												{
													user: user,
													peerID: callerID,
													peer,
													noUserVideo: true,
												},
											],
										},
										async () => {
											await this.state.rConnection.send(
												"ShareCameraForGroup",
												!this.state.videoOff,
												this.state.groupId
											);
										}
									);
								}
							);

							this.state.rConnection.on(
								"ReceivingReturnedSignal",
								(signal, id) => {
									let item = this.state.peersRef.find((p) => p.peerID === id);
									item.peer.signal(signal);
								}
							);

							this.state.rConnection.on("CameraStatus", (cameraStatus) => {
								console.log("CameraStatus", cameraStatus);

								const objectArray = Object.entries(cameraStatus);
								const peers = [...this.state.peers];
								const peersRef = [...this.state.peersRef];

								objectArray.forEach((item) => {
									if (item[0] !== this.state.rConnection.connectionId) {
										const peer = peers.find((p) => p.peerID === item[0]);
										const peerRef = peersRef.find((p) => p.peerID === item[0]);

										if (peer) {
											peer.noUserVideo = !item[1];
										}

										if (peerRef) {
											peerRef.noUserVideo = !item[1];
										}
									}
								});

								this.setState(
									{
										peers: peers,
										peersRef: peersRef,
									},
									() => {
										console.log("CameraStatus peers:", this.state.peers);
									}
								);
							});

							this.state.rConnection.on("UserLeave", (user) => {
								let peerObj = this.state.peersRef.find(
									(p) => p.peerID === user.connectionId
								);
								if (peerObj) {
									peerObj.peer.destroy();
								}

								const peers = this.state.peersRef.filter(
									(p) => p.peerID !== user.connectionId
								);
								this.setState(
									{
										peers: peers,
										peersRef: peers,
									},
									() => {
										this.$f7.methods.openNotification(
											user.user + " a parasit apelul!",
											undefined,
											"eroare"
										);
										if (this.state.merger) this.mergerDraw(this.state.merger);
									}
								);
							});
						}
					}
				);
			});
	};

	componentWillUnmount = () => {
		console.log("peers la demontare:", this.state.peers, this.state.peersRef);

		if (this.state.myStream) {
			this.state.myStream.getTracks().forEach((track) => {
				if (track.readyState === "live") {
					track.stop();
				}
			});
		}

		this.state.rConnection.off("AllUsers");
		this.state.rConnection.off("UserJoined");
		this.state.rConnection.off("ReceivingReturnedSignal");
		this.state.rConnection.off("CameraStatus");
		this.state.rConnection.off("UserLeave");
	};

	createPeer = (userToSignal, callerID, stream) => {
		let peer = new Peer({
			initiator: true,
			trickle: false,
			stream,
		});

		peer.on("signal", async (signal) => {
			if (this.state.rConnection.state === "Connected") {
				await this.state.rConnection.send(
					"SendingSignal",
					userToSignal,
					callerID,
					signal
				);
			}
		});

		return peer;
	};

	addPeer = (incomingSignal, callerID, stream) => {
		let peer = new Peer({
			initiator: false,
			trickle: false,
			stream,
		});

		peer.on("signal", async (signal) => {
			if (this.state.rConnection.state === "Connected") {
				await this.state.rConnection.send("ReturningSignal", signal, callerID);
			}
		});

		peer.signal(incomingSignal);

		return peer;
	};

	mergerDraw = (merger) => {
		const streams = [this.state.myStream];
		this.state.peers.forEach((peer) => {
			streams.push(peer.ref.current.state.stream);
		});

		const streamCount = streams.length;
		// const perRowAndCol = streamCount < 37 ? (streamCount < 26 ? (streamCount < 17 ? (streamCount < 10 ? (streamCount < 5 ? 2 : 3) : 4) : 5) : 6) : 7
		const width =
			streamCount < 37
				? streamCount < 26
					? streamCount < 17
						? streamCount < 10
							? streamCount < 5
								? merger.width / 2
								: merger.width / 3
							: merger.width / 4
						: merger.width / 5
					: merger.width / 6
				: merger.width / 7;
		const height =
			streamCount < 37
				? streamCount < 26
					? streamCount < 17
						? streamCount < 10
							? streamCount < 5
								? merger.height / 2
								: merger.height / 3
							: merger.height / 4
						: merger.height / 5
					: merger.height / 6
				: merger.height / 7;

		let pen_x = 0;
		let pen_y = 0;

		streams.forEach((stream, index) => {
			merger.addStream(stream, {
				x: pen_x,
				y: pen_y,
				width: width,
				height: height,
				mute: false,
			});

			pen_x += width;

			if (pen_x === merger.width) {
				pen_x = 0;
				pen_y += height;
			}
		});
	};

	recorderStop = (notEnd) => {
		this.setState(
			{
				onlyStop: notEnd,
			},
			() => {
				if (this.state.record) {
					if (this.state.mediaRecorder) this.state.mediaRecorder.stop();
				} else {
					const popup = this.$f7.popup.get(".PopupCall");
					if (popup && popup.opened) {
						this.$f7.popup.close(".PopupCall");
					}
				}
			}
		);
	};

	render() {
		console.log("peers:", this.state.peers);
		console.log("connection:", this.state.rConnection);

		return (
			<div
				style={{
					position: "relative",
					width: "100%",
					height: "100%",
					overflowY: "auto",
				}}
			>
				<div
					style={{
						position: "relative",
						width: "100%",
						height: this.state.peers.length < 9 ? "100%" : "auto",
						backgroundColor: "rgb(64 64 64)",
					}}
				>
					<div
						className="multiMyVideo"
						style={{
							position: "relative",
							width:
								this.state.peers.length < 9
									? this.state.peers.length < 4
										? "50%"
										: "33.33%"
									: "248px",
							height:
								this.state.peers.length < 9
									? this.state.peers.length < 4
										? "50%"
										: "33.33%"
									: "140px",
							overflow: "hidden",
							backgroundColor: "rgb(64 64 64)",
							float: "left",
						}}
					>
						<span
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								color: "#fff",
								zIndex: 1,
								backgroundColor: "rgba(0, 0, 0, 0.4)",
								padding: "4px 10px",
							}}
						>
							Eu
						</span>

						{this.state.videoOff ? (
							<Icon
								color={"white"}
								size={100}
								material={"videocam_off"}
								style={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									zIndex: 10,
								}}
							/>
						) : null}

						<video
							style={{
								transform: "scale(-1.3, 1.3)",
								visibility: this.state.videoOff ? "hidden" : "visible",
							}}
							muted
							ref={this.myVideo}
							autoPlay
							playsInline
							width="100%"
							height="100%"
						/>

						<div
							className="multiCallButtonsWrapper"
							style={{
								position: "absolute",
								bottom: 0,
								left: 0,
								right: 0,
								display: "flex",
								justifyContent: "space-around",
								flexDirection: "row",
								paddingTop: 5,
								transform: "translateY(100%)",
								transition: "0.35s all ease-in-out",
								backgroundColor: "rgba(0, 0, 0, 0.3)",
								zIndex: 15,
							}}
						>
							<Button
								disabled={
									this.state.myStream === null || this.state.peers.length === 0
								}
								tooltip={
									this.state.record
										? "Opreste inregistrarea"
										: "Inregistreaza conversatia"
								}
								onClick={() => {
									if (!this.state.record) {
										if (this.state.myStream && this.state.peers.length > 0) {
											let merger = new VideoStreamMerger({
												width: 1366,
												height: 768,
											});
											// this.state.merger = merger
											// this.mergerDraw(this.state.merger)
											this.mergerDraw(merger);
											merger.start();

											let mediaRecorder = new MediaRecorder(merger.result);

											this.setState(
												{
													merger: merger,
													record: true,
													mediaRecorder: mediaRecorder,
												},
												() => {
													mediaRecorder.start(10000);

													let chunks = [];
													mediaRecorder.ondataavailable = (e) => {
														chunks.push(e.data);
													};

													mediaRecorder.onstop = (e) => {
														const clipName = prompt(
															"Enter a name for your clip"
														);
														const blob = new Blob(chunks, {
															type: "video/webm;codecs=vp9",
														});
														saveAs(blob, `${clipName}.webm`);
														chunks = [];

														this.setState(
															{
																record: false,
																merger: null,
																mediaRecorder: null,
															},
															() => {
																mediaRecorder = null;
																merger.destroy();

																if (!this.state.onlyStop) {
																	const popup =
																		this.$f7.popup.get(".PopupCall");
																	if (popup && popup.opened) {
																		this.$f7.popup.close(".PopupCall");
																	}
																}
															}
														);
													};
												}
											);
										}
									} else {
										// if (this.state.mediaRecorder)
										//   this.state.mediaRecorder.stop()
										this.recorderStop(true);
									}
								}}
							>
								<Icon
									color={"red"}
									size={30}
									material={
										this.state.record ? "stop_circle" : "radio_button_checked"
									}
								/>
							</Button>

							<Button
								tooltip={
									this.state.micOff
										? "Porneste microfonul"
										: "Opreste microfonul"
								}
								onClick={() => {
									if (this.state.myStream) {
										console.log("myStream:", this.state.myStream.getTracks());

										this.setState(
											{
												micOff: !this.state.micOff,
											},
											() => {
												this.state.myStream.getTracks().forEach((track) => {
													if (track.kind === "audio")
														track.enabled = !this.state.micOff;
												});
											}
										);
									}
								}}
							>
								<Icon
									color={this.state.micOff ? "red" : "white"}
									size={30}
									material={this.state.micOff ? "mic_off" : "mic"}
								/>
							</Button>

							<Button
								tooltip={
									this.state.spkOffAll
										? "Porneste sunet grup"
										: "Opreste sunet grup"
								}
								onClick={() => {
									this.setState(
										{
											spkOffAll: !this.state.spkOffAll,
										},
										() => {
											this.state.peers.forEach((peer) => {
												peer.ref.current.ref.current.muted =
													this.state.spkOffAll;
											});
										}
									);
								}}
							>
								<Icon
									color={this.state.spkOffAll ? "red" : "white"}
									size={30}
									material={this.state.spkOffAll ? "volume_off" : "volume_up"}
								/>
							</Button>

							<Button
								tooltip={
									this.state.videoOff ? "Porneste camera" : "Opreste camera"
								}
								onClick={() => {
									this.setState(
										{
											videoOff: !this.state.videoOff,
										},
										async () => {
											const tracks = this.state.myStream.getTracks();
											tracks.forEach((track) => {
												if (track.kind === "video")
													track.enabled = !this.state.videoOff;
											});

											if (this.state.rConnection.state === "Connected") {
												await this.state.rConnection.send(
													"ShareCameraForGroup",
													!this.state.videoOff,
													this.state.groupId
												);
											}
										}
									);
								}}
							>
								<Icon
									color={this.state.videoOff ? "red" : "white"}
									size={30}
									material={this.state.videoOff ? "videocam_off" : "videocam"}
								/>
							</Button>

							<Button
								tooltip={"Paraseste grupul"}
								onClick={() => {
									this.recorderStop(false);
									// const popup = this.$f7.popup.get(".PopupCall");
									// if (popup && popup.opened) {
									//   this.$f7.popup.close(".PopupCall");
									// }
								}}
							>
								<Icon color={"white"} size={30} material={"call_end"} />
							</Button>
						</div>
					</div>

					{this.state.peers.map((peer) => {
						let ref = React.createRef();
						peer["ref"] = ref;

						return (
							<OtherVideo
								ref={ref}
								key={peer.peerID}
								peer={peer.peer}
								user={peer.user}
								count={this.state.peers.length}
								noUserVideo={peer.noUserVideo}
								merger={this.state.merger}
								mergerDraw={this.mergerDraw}
							/>
						);
					})}
				</div>
			</div>
		);
	}
}
export default GroupCall;
