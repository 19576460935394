import React, { Component } from "react";
import { Checkbox, List, ListInput, Icon, ListItem } from "framework7-react";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";
import moment from "moment";

/**
 * @description Afiseaza un datagrid cu informatiile legate de logarile dintr-un interval al utilizatorilor, cu data de intrare si data de iesire din aplicatie cu optiuni de a alege intervalul si daca se doresc si cele arhivate.
 * @date 06/04/2022
 * @class LogariCurente
 * @extends {Component}
 */
class LogariCurente extends Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;

    this.state = {
      dataLoaded: false,
      height: window.innerHeight - 158,
      linkApi: "/api/Sesiuni",
      dataSource: [],
      dataDeLa: [moment(Date.now()).format()],
      dataPanaLa: [moment(Date.now()).format()],
      inputs: [
        { key: "Id", keyBaza: "idSesiune" },
        { key: "NumeLogin", keyBaza: "numeLogin" },
        { key: "Computer", keyBaza: "computer" },
        { key: "UtilizatorComp", keyBaza: "utilizatorComp" },
        { key: "IP", keyBaza: "ip" },
        { key: "DataIntrare", keyBaza: "dataIntrare", tip: "datebox" },
        { key: "DataIesire", keyBaza: "dataIesire", tip: "datebox" },
        { key: "IsArhivat", keyBaza: "isArhivat", tip: "bool" },
      ],
      checkedArhivate: false,
    };
    this.refDataGrid = React.createRef();
  }
  /**
   * @description Ia valorile necesare pentru randare de la endpointul „this.$f7.data.baseURL +.state.linkApi +GetSesiuniFiltrate/" + (this.state.checkedArhivate ? 1 : 0)” caruia i se transmit ca parametru daca sunt bifate si cele arhivate sau nu, si sunt filtrate ulterior in functie de data start si data final
   * @function
   * @date 06/04/2022
   * @memberof LogariCurente
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetSesiuniFiltrate/" +
          (this.state.checkedArhivate ? 1 : 0)
      )
      .then((response) => {
        var date = response;

        console.log("sesiuni:", date);

        date.data.map((eveniment) => {
          eveniment = this.$f7.methods.restructurareBaza(
            eveniment,
            this.state.inputs,
            "key"
          );
          return false;
        });

        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState({
                dataLoaded: true,
              });
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };
  /**
   * @description se apeleaza endpointul „this.$f7.data.baseURL +  this.state.linkApi +ArhivareDezarhivareSesiuni" caruia i se transmite lista si tipul de arhivare(daca se arhiveaza sau dezarhiveaza)
   * @function
   * @date 06/04/2022
   * @param {*} lista
   * @param {*} arhivare
   * @memberof LogariCurente
   */
  ArhiveazaDezarhiveazaLogari = (lista, arhivare) => {
    axios({
      data: { lista: lista, arhivare: arhivare },
      headers: { "Content-Type": "application/json" },
      method: "post",
      url:
        this.$f7.data.baseURL +
        this.state.linkApi +
        "/ArhivareDezarhivareSesiuni",
    })
      .then((response) => {
        this.$f7.methods.openNotification(
          arhivare
            ? response.data === 1
              ? "1 logare arhivata."
              : response.data + " logari arhivate."
            : response.data === 1
            ? "1 logare dezarhivata."
            : response.data + " logari dezarhivate.",
          undefined,
          "succes"
        );
        this.GetValues();
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification(
          arhivare
            ? "Eroare la arhivare logari!"
            : "Eroare la dezarhivare logari!",
          undefined,
          "eroare"
        );
      });
  };
  /**
   * @description este context-ul datagridului, itemeRandate este lista de iteme randate, se parcurg acestea si se aplica clasa de arhivat sau nu in functie de daca sunt arhivate sua nu
   * @date 06/04/2022
   * @function
   * @param {*} state state-ul datagridului
   * @param {*} r contextul datagridului
   * @param {*} itemeRandate itemele randate de datagrid
   * @memberof LogariCurente
   */
  colorItems = (state, r, itemeRandate) => {
    var tableName = r.tableName;
    if (tableName) {
      itemeRandate.forEach((elem, index) => {
        var tar = document.getElementById("trMain" + tableName + index);

        if (tar) {
          if (elem.IsArhivat) tar.classList.add("arhivat");
          else tar.classList.remove("arhivat");
        }
      });
    }
  };
  /**
   * @description schimba intervalul de filtrare al datagridului in fucntie de data start si data final selectata ca interval
   * @function
   * @date 06/04/2022
   * @memberof LogariCurente
   */
  changeInterval = () => {
    var interval = [this.state.dataDeLa[0], this.state.dataPanaLa[0]];
    if (interval && interval.length > 1) {
      var filtre = { ...this.refDataGrid.current.state.filtreInitiale };
      filtre["DataIntrare"].value = interval[0];
      filtre["DataIntrare"].comparatie = ">Date";
      filtre["DataIesire"].value = interval[1];
      filtre["DataIesire"].comparatie = "<Date";
      this.refDataGrid.current.setState({ filtre: filtre }, () => {
        this.refDataGrid.current.saveFiltre();
      });
    }
  };

  render() {
    return (
      <div>
        <List
          style={{ width: "100%", margin: "0px !important" }}
          id={"ListaLogariCurente"}>
          <ListInput
            key={"OreLucratePeZi3"}
            label="Interval"
            style={{ margin: "0px 6px" }}
            type="datepicker"
            calendarParams={{
              rangePicker: true,
              dateFormat: "dd.mm.yyyy",
              closeOnSelect: true,
              on: {
                closed: (e) => {
                  const val = e.value;
                  this.setState(
                    {
                      dataDeLa: [val[0]],
                      dataPanaLa: [val[1]],
                    },
                    () => {
                      this.changeInterval();
                    }
                  );
                },
              },
            }}
            value={[this.state.dataDeLa[0], this.state.dataPanaLa[0]]}
            floatingLabel
            placeholder="De la">
            <Icon
              color={"var(--f7-theme-color)"}
              size={20}
              material="today"
              slot="content-start"
              style={{ left: 0, top: 0 }}
            />
          </ListInput>
          <ListItem
            style={{ margin: "0px 6px" }}
            floatingLabel
            checkbox
            title="Afiseaza si arhivate"
            name="checkedArhivate"
            defaultChecked={this.state.checkedArhivate}
            onChange={(e) => {
              this.setState(
                { checkedArhivate: e.currentTarget.checked },
                () => {
                  this.GetValues();
                }
              );
            }}
          />
        </List>

        <DataGrid
          ref={this.refDataGrid}
          dataLoaded={this.state.dataLoaded}
          refresh={this.GetValues}
          name={"LogariCurente"}
          id={"Id"}
          keys={[
            "NumeLogin",
            "Computer",
            "UtilizatorComp",
            "IP",
            "DataIntrare",
            "DataIesire",
          ]}
          renderOptions={true}
          renderUpdate={false}
          renderDelete={false}
          renderAdd={false}
          renderCheckbox={true}
          maxHeight={this.state.height}
          dataSource={this.state.dataSource}
          inputs={this.state.inputs}
          contextMenuItems={(state, context) => {
            return [
              {
                key: "Refresh",
                onClick: (state) => {
                  setTimeout(() => {
                    this.GetValues();
                  }, 1000);
                },
                icon: "refresh",
              },
              {
                disabled:
                  context.state.allSelectedRows.length > 0 &&
                  context.state.allSelectedRows.filter((r) => {
                    return r.IsArhivat;
                  }).length === context.state.allSelectedRows.length,
                key: "Arhiveaza",
                onClick: (state) => {
                  setTimeout(() => {
                    var selectedRows = state.allSelectedRows;
                    if (selectedRows.length === 0) {
                      selectedRows = [state.rowDataAll];
                    }
                    var listaIduri = [];
                    selectedRows.map((r) => {
                      var id = r.Id;
                      if (!r.IsArhivat) {
                        listaIduri.push(id);
                      }
                      return null;
                    });

                    this.ArhiveazaDezarhiveazaLogari(listaIduri, true);
                  }, 1000);
                },
                icon: "compress",
              },
              {
                disabled:
                  context.state.allSelectedRows.length > 0 &&
                  context.state.allSelectedRows.filter((r) => {
                    return !r.IsArhivat;
                  }).length === context.state.allSelectedRows.length,
                key: "Dezarhiveaza",
                onClick: (state) => {
                  setTimeout(() => {
                    var selectedRows = state.allSelectedRows;
                    if (selectedRows.length === 0) {
                      selectedRows = [state.rowDataAll];
                    }
                    var listaIduri = [];
                    selectedRows.map((r) => {
                      var id = r.Id;
                      if (r.IsArhivat) {
                        listaIduri.push(id);
                      }
                      return null;
                    });

                    this.ArhiveazaDezarhiveazaLogari(listaIduri, false);
                  }, 1000);
                },
                icon: "expand",
              },
            ];
          }}
          onInitialised={(state, r, itemeRandate) => {
            this.colorItems(state, r, itemeRandate);
          }}
          onRenderItemsChanged={(state, r, itemeRandate) => {
            this.colorItems(state, r, itemeRandate);
          }}
        />
      </div>
    );
  }
}
export default LogariCurente;