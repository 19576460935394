import React, { Component } from "react";
import { Button, Icon } from "framework7-react";
import MainCall from "./MainCall";
import GroupCall from "./GroupCall";
import CallSound from "./../../../styles/chat_call.mp3";

class AnswerCall extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chipName: "",
            numeGrup: ""
        };

        this.audio = new Audio(CallSound);
        this.audio.loop = true;

        this.timeOut = null;
    }

    componentDidMount = () => {
        const nameArray = this.props.user.user.split(" ");
        let initiale = "";
        let numeGrup = "";

        nameArray.forEach(item => {
            initiale += item[0];
        });

        if (this.props.groupID !== null && this.props.groupID !== undefined) {
            const grup = this.$f7.data.grupuriChat.find(
                g => g.value === this.props.groupID
            );

            if (grup) {
                numeGrup = grup.key;
            }
        }

        this.setState(
            {
                chipName: initiale,
                numeGrup: numeGrup
            },
            () => {
                if (this.audio) {
                    this.audio.addEventListener("canplaythrough", event => {
                        this.audio.play();
                    });
                }
            }
        );

        this.timeOut = setTimeout(() => {
            this.props.notAnswer();
        }, 15000);
    };

    componentWillUnmount = () => {
        if (this.audio) {
            this.audio.pause();
            // this.audio = null;
        }

        if (this.timeOut) {
            clearTimeout(this.timeOut);
            this.timeOut = null;
        }
    };

    render() {
        return (
            <div
                style={{
                    position: "absolute",
                    top: "50vh",
                    left: "50vw",
                    width: 200,
                    height: 250,
                    backgroundColor: "#fff",
                    transform: "translate(-50%, -50%)",
                    zIndex: 98999,
                    filter: "drop-shadow(2px 4px 6px black)",
                    borderRadius: 10,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}
            >
                <h2
                    style={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "15px",
                        backgroundColor: "var(--f7-theme-color)",
                        margin: "0 0 12px 0",
                        padding: "6px 0",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10
                    }}
                >
                    {this.props.groupID !== null && this.props.groupID !== undefined
                        ? `${this.props.user.user} te apeleaza pe grupul ${this.state.numeGrup
                        }`
                        : `${this.props.user.user} te apeleaza`}
                </h2>

                <div
                    className="nameWave"
                    style={{
                        width: 75,
                        height: 75,
                        backgroundColor: "var(--f7-theme-color)",
                        color: "#fff",
                        margin: "0 auto",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "35px",
                        letterSpacing: "1.3px",
                        filter: "drop-shadow(0 0 0px var(--f7-theme-color))",
                        animation: "pulse 2s infinite"
                    }}
                >
                    {this.state.chipName}
                </div>

                <div
                    style={{
                        backgroundColor: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                        marginTop: 12
                    }}
                >
                    <Button
                        tooltip={"Raspunde cu video"}
                        onClick={() => {
                            this.props.context.setState(
                                {
                                    call: false,
                                    activeCall: true
                                },
                                () => {
                                    this.$f7.data.router.navigate("/popupCall/", {
                                        props: {
                                            onPopupClose: () => {
                                                this.props.endCall(
                                                    this.props.groupID === null ||
                                                        this.props.groupID === undefined
                                                        ? this.props.user.connectionId
                                                        : this.props.groupID
                                                );
                                            },
                                            //title: (this.props.groupID !== null && this.props.groupID !== undefined) ? `Apel pe grupul ${this.state.numeGrup}` : `Apel cu ${this.props.user.user}`,
                                            component:
                                                this.props.groupID !== null &&
                                                    this.props.groupID !== undefined ? (
                                                    <GroupCall
                                                        acceptCall={true}
                                                        //connectionId={this.props.user.connectionId}
                                                        group={this.props.groupID}
                                                    />
                                                ) : (
                                                    <MainCall
                                                        acceptCall={true}
                                                        callerSignal={this.props.callerSignal}
                                                        connectionId={this.props.user.connectionId}
                                                    />
                                                )
                                        }
                                    });
                                }
                            );
                        }}
                    >
                        <Icon color={"green"} size={30} material={"videocam"} />
                    </Button>

                    <Button
                        tooltip={"Raspunde cu audio"}
                        onClick={() => {
                            this.props.context.setState(
                                {
                                    call: false,
                                    activeCall: true
                                },
                                () => {
                                    this.$f7.data.router.navigate("/popupCall/", {
                                        props: {
                                            onPopupClose: () => {
                                                this.props.endCall(
                                                    this.props.groupID === null ||
                                                        this.props.groupID === undefined
                                                        ? this.props.user.connectionId
                                                        : this.props.groupID
                                                );
                                            },
                                            //title: (this.props.groupID !== null && this.props.groupID !== undefined) ? `Apel pe grupul ${this.state.numeGrup}` : `Apel cu ${this.props.user.user}`,
                                            component:
                                                this.props.groupID !== null &&
                                                    this.props.groupID !== undefined ? (
                                                    <GroupCall
                                                        acceptCall={true}
                                                        //connectionId={this.props.user.connectionId}
                                                        group={this.props.groupID}
                                                        audioOnly={true}
                                                    />
                                                ) : (
                                                    <MainCall
                                                        acceptCall={true}
                                                        callerSignal={this.props.callerSignal}
                                                        connectionId={this.props.user.connectionId}
                                                        audioOnly={true}
                                                    />
                                                )
                                        }
                                    });
                                }
                            );
                        }}
                    >
                        <Icon color={"green"} size={30} material={"phone"} />
                    </Button>

                    <Button
                        tooltip={"Respinge apel"}
                        onClick={() => {
                            this.props.context.setState(
                                {
                                    call: false
                                },
                                () => {
                                    if (
                                        this.props.groupID === null ||
                                        this.props.groupID === undefined
                                    )
                                        this.props.rejectCall(this.props.user.connectionId);
                                    this.props.context.setState({
                                        userCall: null,
                                        groupID: null,
                                        callerSignal: null
                                    });
                                }
                            );
                        }}
                    >
                        <Icon color={"red"} size={30} material={"call_end"} />
                    </Button>
                </div>
            </div>
        );
    }
}
export default AnswerCall;