import React from "react";
import DataGrid from "../../pages/Datagrid/DataGrid";
import axios from "axios";

/**
 * @description Afiseaza lista de taskuri alocate departamentului selectat in ATDepartamente.
 * @date 06/04/2022
 * @class ATDepartamenteGridAlocate
 * @extends {React.Component}
 */
class ATDepartamenteGridAlocate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoaded: false,
      denumire: "Sarcini nealocate",
      height: window.innerHeight - 96,
      linkApi: "/api/SarciniSalariat",
      dataSource: [],
      inputs: [
        { key: "Proiect", keyBaza: "Proiect" },
        { key: "DataStart", keyBaza: "DataStart", tip: "datebox" },
        { key: "Deadline", keyBaza: "Deadline", tip: "datebox" },
        { key: "Stadiu", keyBaza: "Stadiu" },
        { key: "Salariat", keyBaza: "Salariat" },
        { key: "TimpAlocat", keyBaza: "TimpAlocat", tip: "number" },
        { key: "TimpConsumat", keyBaza: "TimpConsumat", tip: "number" },
        { key: "TimpZi", keyBaza: "TimpZi", tip: "number" },
        { key: "TimpRamas", keyBaza: "TimpRamas", tip: "number" },
      ],
    };

    this.refDataGrid = React.createRef();
  }

  /**
   * @description  Ia din baza de date de la endpointul „this.$f7.data.baseURL + this.state.linkApi +"/GetTaskuriDepartament/" +this.props.idDepartament” toate taskurile alocate departamentului, se trimite ca parametru idDepartament.
   * @function
   * @date 06/04/2022
   * @memberof ATDepartamenteGridAlocate
   */
  GetValues = () => {
    this.setState({ dataLoaded: false });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApi +
          "/GetTaskuriDepartament/" +
          this.props.idDepartament
      )
      .then((response) => {
        var date = response;

        date.data.map((sarcina) => {
          sarcina = this.$f7.methods.restructurareBaza(
            sarcina,
            this.state.inputs,
            "key"
          );
          return null;
        });
        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);

        this.setState(
          {
            dataSource: baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  this.refDataGrid.current.setRenderItems(baza);
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.refDataGrid.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  render() {
    return (
      <DataGrid
        dataLoaded={this.state.dataLoaded}
        refresh={this.GetValues}
        name={"ATDepartamenteGridAlocate"}
        ref={this.refDataGrid}
        id={"Id"}
        keys={[
          "Proiet",
          "DataStart",
          "Stadiu",
          "Salariat",
          "TimpAlocat",
          "TimpConsumat",
          "TimpZi",
          "TimpRamas",
        ]}
        ignoredInputsAdd={["Deadline"]}
        ignoreInputsEdit={["Deadline"]}
        renderUpdate={false}
        renderDelete={false}
        renderAdd={false}
        maxHeight={this.state.height}
        inputs={this.state.inputs}
        dataSource={this.state.dataSource}
        coloredRow={{
          sourceCol: "Deadline",
          type: "date",
        }}
      />
    );
  }
}
export default ATDepartamenteGridAlocate;