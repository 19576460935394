
import React from "react";
import {
  Page,
  Row,
  Col,
  Card,
  CardContent,
  Block,
  BlockTitle,
} from "framework7-react";
import config from "../../config";
var settings = {
  width: "100",
  xsmall: "100",
  small: "100",
  medium: "50",
  large: "50",
  xlarge: "50",
};
var blockStyle = {
  wordBreak: "break-word",
  margin: "0px 0px 15px 0px",
};

/**
 * @description Pagina de randeaza toate informatiile despre aplicatie precum versiune, adresa(link) etc
 * @date 06/04/2022
 * @class AboutPage
 * @extends {React.PureComponent}
 */
class AboutPage extends React.PureComponent {
  render() {
    return (  
      <Page
        onPageInit={() => {
          this.$f7.data.paginaCurenta = this;
        }}>
        <div className={"ptr2"}>
          <div className={"ptr-ignore"}>
            <Row style={{ height: "100%" }}>
              <Col
                className="col-center-content topImage"
                {...settings}
                style={{
                  position: "relative",
                  height: "100%",
                  display: "grid",
                  alignItems: "center",
                }}>
                <div
                  style={{ backgroundImage: 'url("./img/imagineLogin.webp")' }}
                  className="imagineCover"
                />
              </Col>
              <Col {...settings} className="col-center-content bottomForm">
                <Card
                  className={"cardForm"}
                  style={{ width: "100%", padding: 0 }}>
                  <div
                    slot="header"
                    style={{ width: "100%", textAlign: "center", margin: 0 }}>
                    <b>Despre aplicatie</b>
                  </div>

                  <CardContent style={{ padding: 0 }}>
                    <Block inset style={blockStyle}>
                      <BlockTitle>Nume</BlockTitle>
                      {config.name}
                    </Block>

                    <Block inset style={blockStyle}>
                      <BlockTitle>Versiune</BlockTitle>
                      {config.version}
                    </Block>

                    <Block inset style={blockStyle}>
                      <BlockTitle>Website</BlockTitle>
                      <a
                        className="external"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={config.website}>
                        {config.website}
                      </a>
                    </Block>

                    <Block inset style={blockStyle}>
                      <BlockTitle>Info</BlockTitle>
                      {config.about}
                    </Block>
                  </CardContent>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </Page>
    );
  }
}
export default AboutPage;