import React from "react";
import {
  List,
  ListItem,
  Segmented,
  Toolbar,
  Link,
  Tabs,
  Tab,
} from "framework7-react";
import DataGrid from "../../../Datagrid/DataGrid";

/**
 * @description Afiseaza toate taskurile unui salariat, cele alocate, primite si inchise, in aceeasi interfata
 * @date 08/04/2022
 * @class TotalTaskuriSalariat
 * @extends {React.PureComponent}
 */
class TotalTaskuriSalariat extends React.PureComponent {
  constructor(props) {
    super(props);

    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.height = window.innerHeight;
    let denumireTab = this.$f7.utils.id("xxxx-xxxx-xxxx");

    this.Salariati = this.Salariati.filter((item) => item.value !== null);

    this.state = {
      SalariatSelectat:
        props.idSalariat === undefined
          ? this.$f7.data.user.iD_SALARIAT
          : this.$f7.data.idSalariatFunctii,
      linkApi: "/api/RealizareSarcini",
      pagina: "Total taskuri",
      denumire: Math.random().toString(36).substring(7).toString(),
      denumireTab: denumireTab,
      activeTab:
        props.activeInchise !== undefined && props.activeInchise
          ? "tab-Taskuri-Inchise" + denumireTab
          : "tab-Taskuri-Alocate" + denumireTab,
      height: window.innerHeight - 200,
      totalAlocate: 0,
      totalPrimite: 0,
      totalInchise: 0,
      dataLoadedAlocate: false,
      dataLoadedPrimite: false,
      dataLoadedInchise: false,
      dataSourceAlocate: [],
      dataSourcePrimite: [],
      dataSourceInchise: [],
      inputsAlocate: [
        { key: "IdSarcina", keyBaza: "idSarcina" },
        { key: "IdProiect", keyBaza: "idProiect" },
        { key: "Denumire", keyBaza: "denumire", columnWidth: "250px" },
        { key: "Cerinta", keyBaza: "comentariu", columnWidth: "500px" },
        { key: "Parinte", keyBaza: "parinte" },
        {
          key: "DataCreare",
          keyBaza: "dataCreare",
          tip: "datebox",
          columnWidth: "100px",
        },
        { key: "DataAlocarii", keyBaza: "dataAlocarii", tip: "datebox" },
        { key: "DeCine", keyBaza: "deCine" },
        { key: "Deadline", keyBaza: "deadline", tip: "datebox" },
        {
          key: "Client",
          keyBaza: "idClient",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
          columnWidth: "20px",
        },
        { key: "Sursa", keyBaza: "sursa", columnWidth: "20px" },
        {
          key: "PR",
          keyBaza: "idPrioritate",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
          columnWidth: "20px",
        },
        { key: "IsArhivat", keyBaza: "isArhivat" },
        { key: "IsHidden", keyBaza: "isHidden" },
        { key: "Path", keyBaza: "path" },
        { key: "OperatorAdaugare", keyBaza: "id_operator_adaugare" },
        { key: "BugetTimp", keyBaza: "buget_timp" },
        { key: "TimpPlanD", keyBaza: "timp_plan_d" },
        { key: "UltimaStare", keyBaza: "ultimaStare", tip: "number" },
        { key: "NivelProiect", keyBaza: "id_nivel_proiect" },
      ],
      inputsPrimite: [
        { key: "IdSarcina", keyBaza: "idSarcina" },
        { key: "IdProiect", keyBaza: "idProiect" },
        { key: "Denumire", keyBaza: "denumire", columnWidth: "250px" },
        { key: "Cerinta", keyBaza: "comentariu", columnWidth: "500px" },
        { key: "Parinte", keyBaza: "parinte" },
        {
          key: "DataCreare",
          keyBaza: "dataCreare",
          tip: "datebox",
          columnWidth: "100px",
        },
        { key: "DataAlocarii", keyBaza: "dataAlocarii", tip: "datebox" },
        { key: "Deadline", keyBaza: "deadline", tip: "datebox" },
        {
          key: "Client",
          keyBaza: "idClient",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
          columnWidth: "20px",
        },
        { key: "Sursa", keyBaza: "sursa", columnWidth: "20px" },
        { key: "DeCine", keyBaza: "deCine", columnWidth: "20px" },
        {
          key: "PR",
          keyBaza: "idPrioritate",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
          columnWidth: "20px",
        },
        { key: "IsHidden", keyBaza: "isHidden", columnWidth: "20px" },
        { key: "IsArhivat", keyBaza: "isArhivat", columnWidth: "20px" },
        { key: "UltimaStare", keyBaza: "ultimaStare", tip: "number" },
        { key: "NivelProiect", keyBaza: "id_nivel_proiect" },
      ],
      inputsInchise: [
        { key: "IdSarcina", keyBaza: "idSarcina" },
        { key: "IdProiect", keyBaza: "idProiect" },
        { key: "Denumire", keyBaza: "denumire", columnWidth: "250px" },
        { key: "Cerinta", keyBaza: "comentariu", columnWidth: "500px" },
        {
          key: "DataCreare",
          keyBaza: "dataCreare",
          tip: "datebox",
          columnWidth: "100px",
        },
        {
          key: "DataInchiderii",
          keyBaza: "dataAlocarii",
          tip: "datebox",
          columnWidth: "100px",
        },
        {
          key: "Client",
          keyBaza: "idClient",
          tip: "selectbox",
          lista: this.$f7.data.Firme,
          columnWidth: "20px",
        },
        { key: "Sursa", keyBaza: "sursa", columnWidth: "20px" },
        { key: "Parinte", keyBaza: "parinte", columnWidth: "20px" },

        {
          key: "PR",
          keyBaza: "idPrioritate",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
          columnWidth: "20px",
        },
        { key: "IsHidden", keyBaza: "isHidden", columnWidth: "20px" },
        { key: "IsArhivat", keyBaza: "isArhivat", columnWidth: "20px" },
        { key: "UltimaStare", keyBaza: "ultimaStare", tip: "number" },
        { key: "NivelProiect", keyBaza: "id_nivel_proiect" },
      ],
    };

    this.refDataGridAlocate = React.createRef();
    this.refDataGridPrimite = React.createRef();
    this.refDataGridInchise = React.createRef();
  }

  componentDidMount() {
    this.GetValues();
  }

  /**
   * @description Ia toate sarcinile salariatului selectat
   * @function
   * @date 08/04/2022
   * @memberof TotalTaskuriSalariat
   */
  GetValues = () => {
    this.GetValuesAlocate();
    this.GetValuesPrimite();
    this.GetValuesInchise();
  };

  /**
   * @description Ia taskurile alocate salariatului selectat
   * @function
   * @date 08/04/2022
   * @memberof TotalTaskuriSalariat
   */
  GetValuesAlocate = () => {
    this.setState({
      dataLoadedAlocate: false,
    });

    this.$f7.methods
      .getProiecte(this.state.SalariatSelectat, 1, 1, 0, 0, 1)
      .then((response) => {
        var date = response;

        date.data.map((sarcina) => {
          if (sarcina.proiect) {
            sarcina["path"] = sarcina.proiect["path"];
            if (sarcina["path"]) {
              var path = "";
              sarcina["path"].split("\\").map((f) => {
                if (f !== null && f !== undefined) {
                  var idProiect = parseInt(f);
                  var pro = this.$f7.data.allProiecte.find((p) => {
                    return p.iD_PROIECT === idProiect;
                  });
                  if (pro) {
                    path += pro.denumire + " \\ ";
                  }
                }
                return null;
              });
              sarcina["path"] = path.slice(0, -2);
            }
            sarcina["id_operator_adaugare"] =
              sarcina.proiect["id_operator_adaugare"];
            if (sarcina["id_operator_adaugare"] !== undefined) {
              var sal = this.$f7.data.Salariati.find((r) => {
                return r.value === sarcina["id_operator_adaugare"];
              });
              if (sal) {
                sarcina["id_operator_adaugare"] = sal.key;
              }
            }
            sarcina["buget_timp"] = sarcina.proiect["buget_timp"];
            sarcina["timp_plan_d"] = sarcina.proiect["timp_plan_d"];
          }

          sarcina = this.$f7.methods.restructurareBaza(
            sarcina,
            this.state.inputsAlocate,
            "key"
          );

          switch (sarcina.Sursa) {
            case 55:
              sarcina.Sursa =
                "M(" + (sarcina.proiect.userImport % 10000000) + ")";
              break;
            case 56:
              sarcina.Sursa = "E";
              break;
            default:
              sarcina.Sursa = "C";
              break;
          }

          return null;
        });

        var baza = this.$f7.methods.reconstructieBaza(
          date,
          this.state.inputsAlocate
        );

        this.setState(
          {
            dataSourceAlocate: baza,
            totalAlocate: baza.length,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoadedAlocate: true,
                },
                () => {
                  // this.refDataGridAlocate.current.setRenderItems(baza);
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSourceAlocate: [],
            dataLoadedAlocate: true,
          },
          () => {
            this.refDataGrid.current.setRenderItems([]);
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesAlocate();
              },
              () => {}
            );
          }
        );
      });
  };

  /**
   * @description Ia taskurile primite ale salariatului selectat
   * @function
   * @date 08/04/2022
   * @memberof TotalTaskuriSalariat
   */
  GetValuesPrimite = () => {
    this.setState({
      dataLoadedPrimite: false,
    });

    this.$f7.methods
      .getProiecte(this.state.SalariatSelectat, 2, 1, 0, 0, 1)
      .then((response) => {
        var date = response;

        date.data.map((sarcina) => {
          sarcina = this.$f7.methods.restructurareBaza(
            sarcina,
            this.state.inputsPrimite,
            "key"
          );

          switch (sarcina.Sursa) {
            case 55:
              sarcina.Sursa =
                "M(" + (sarcina.proiect.userImport % 10000000) + ")";
              break;
            case 56:
              sarcina.Sursa = "E";
              break;
            default:
              sarcina.Sursa = "C";
              break;
          }

          return null;
        });

        var baza = this.$f7.methods.reconstructieBaza(
          date,
          this.state.inputsPrimite
        );

        this.setState(
          {
            dataSourcePrimite: baza,
            totalPrimite: baza.length,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoadedPrimite: true,
                },
                () => {
                  //this.refDataGridPrimite.current.setRenderItems(baza);
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSourcePrimite: [],
            dataLoadedPrimite: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesPrimite();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Ia taskurile inchise ale salariatului selectat
   * @function
   * @date 08/04/2022
   * @memberof TotalTaskuriSalariat
   */
  GetValuesInchise = () => {
    this.setState({
      dataLoadedInchise: false,
    });

    this.$f7.methods
      .getProiecte(this.state.SalariatSelectat, 4, 1, 0, 0, 1)
      .then((response) => {
        var date = response;

        date.data.map((sarcina) => {
          sarcina = this.$f7.methods.restructurareBaza(
            sarcina,
            this.state.inputsInchise,
            "key"
          );

          switch (sarcina.Sursa) {
            case 55:
              sarcina.Sursa =
                "M(" + (sarcina.proiect.userImport % 10000000) + ")";
              break;
            case 56:
              sarcina.Sursa = "E";
              break;
            default:
              sarcina.Sursa = "C";
              break;
          }

          return false;
        });

        var baza = this.$f7.methods.reconstructieBaza(
          date,
          this.state.inputsInchise
        );

        this.setState(
          {
            dataSourceInchise: baza,
            totalInchise: baza.length,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoadedInchise: true,
                },
                () => {
                  //this.refDataGridInchise.current.setRenderItems(baza);
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSourceInchise: [],
            dataLoadedInchise: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValuesInchise();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  render() {
    return (
      <>
        {this.$f7.data.rol < 2 && this.props.idSalariat === undefined ? (
          <List
            id="listSalariatiTotalTaskuri"
            style={{
              padding: "0px 5px",
            }}>
            <ListItem
              className="listaSalariatiTotalTaskuri"
              validate
              floatingLabel
              title={"Salariat"}
              label={"Salariat"}
              smartSelect
              smartSelectParams={{
                virtualList: true,
                searchbar: true,
                openIn: "popup",
                popupTabletFullscreen: true,
                popupPush: true,
                closeOnSelect: true,
                on: {
                  closed: (event) => {
                    var val = event.getValue();
                    this.setState(
                      {
                        SalariatSelectat: val,
                        totalAlocate: 0,
                        totalPrimite: 0,
                        totalInchise: 0,
                        //dataSourceAlocate: [],
                        //dataSourcePrimite: [],
                        //dataSourceInchise: []
                      },
                      () => {
                        this.GetValues();
                      }
                    );
                  },
                },
              }}>
              <select name="SelectSalariatiTotalTaskuri">
                {this.Salariati.map((obj, index) => (
                  <option
                    key={"listaSalariatiTotalTaskuri" + index}
                    selected={
                      obj.value !== null &&
                      parseInt(obj.value) ===
                        parseInt(this.state.SalariatSelectat)
                    }
                    value={obj.value}>
                    {obj.key}
                  </option>
                ))}
              </select>
            </ListItem>
          </List>
        ) : null}

        <div>
          <Segmented
            raised
            tag="div"
            style={{
              alignItems: "center",
              marginTop: 5,
            }}>
            <Toolbar tabbar={true} noShadow top>
              <Link
                tooltip={"Taskuri alocate"}
                tabLink={"#tab-Taskuri-Alocate" + this.state.denumireTab}
                tabLinkActive={
                  this.state.activeTab ===
                  "tab-Taskuri-Alocate" + this.state.denumireTab
                }
                onClick={() => {
                  this.setState({
                    activeTab: "tab-Taskuri-Alocate" + this.state.denumireTab,
                  });
                }}>
                Task alocate ({this.state.totalAlocate})
              </Link>

              <Link
                tooltip={"Taskuri primite"}
                tabLink={"#tab-Taskuri-Primite" + this.state.denumireTab}
                tabLinkActive={
                  this.state.activeTab ===
                  "tab-Taskuri-Primite" + this.state.denumireTab
                }
                onClick={() => {
                  this.setState({
                    activeTab: "tab-Taskuri-Primite" + this.state.denumireTab,
                  });
                }}>
                Task primite ({this.state.totalPrimite})
              </Link>

              <Link
                tooltip={"Taskuri rezolvate/inchise"}
                tabLink={"#tab-Taskuri-Inchise" + this.state.denumireTab}
                tabLinkActive={
                  this.state.activeTab ===
                  "tab-Taskuri-Inchise" + this.state.denumireTab
                }
                onClick={() => {
                  this.setState({
                    activeTab: "tab-Taskuri-Inchise" + this.state.denumireTab,
                  });
                }}>
                Task inchise ({this.state.totalInchise})
              </Link>
            </Toolbar>
          </Segmented>

          <Tabs
            style={{
              maxHeight: "calc(100% - 100px)",
              height: "auto",
              overflowY: "auto",
              padding: "0px 5px",
            }}>
            <Tab
              style={{
                height: "100%",
                position: "relative",
              }}
              id={"tab-Taskuri-Alocate" + this.state.denumireTab}
              tabActive={
                this.state.activeTab ===
                "tab-Taskuri-Alocate" + this.state.denumireTab
              }>
              {this.state.activeTab ===
              "tab-Taskuri-Alocate" + this.state.denumireTab ? (
                <DataGrid
                  dataLoaded={this.state.dataLoadedAlocate}
                  name={"TotalTaskuriAlocateSalariat"}
                  ref={this.refDataGridAlocate}
                  id={"IdSarcina"}
                  onRowClick={(state) => {
                    var id = state.rowData.IdProiect;
                    this.$f7.methods.openMainDreaptaById(id);
                    this.$f7.data.agendaIdProiect = id;
                  }}
                  onInitialised={(state, r, itemeRandate) => {
                    this.$f7.methods.colorItems(state, r, itemeRandate);
                  }}
                  onRenderItemsChanged={(state, r, itemeRandate) => {
                    this.$f7.methods.colorItems(state, r, itemeRandate);
                  }}
                  keys={[
                    "Denumire",
                    "Cerinta",
                    "DataCreare",
                    "Client",
                    "Sursa",
                    "PR",
                  ]}
                  ignoreInputsAdd={["Deadline"]}
                  ignoreInputsEdit={["Deadline"]}
                  hintInputSources={[
                    "Path",
                    "OperatorAdaugare",
                    "BugetTimp",
                    "TimpPlanD",
                  ]}
                  renderUpdate={false}
                  renderDelete={false}
                  renderAdd={false}
                  renderCheckbox={false}
                  maxHeight={this.state.height}
                  inputs={this.state.inputsAlocate}
                  dataSource={this.state.dataSourceAlocate}
                  contextMenuItems={[
                    {
                      key: "Refresh",
                      onClick: (state) => {
                        setTimeout(() => {
                          this.setState(
                            {
                              totalAlocate: 0,
                            },
                            () => {
                              this.GetValuesAlocate();
                            }
                          );
                        }, 1000);
                      },
                      icon: "refresh",
                    },
                  ]}
                  coloredRow={{
                    sourceCol: "Deadline",
                    type: "date",
                  }}
                />
              ) : null}
            </Tab>

            <Tab
              style={{
                height: "100%",
                position: "relative",
              }}
              id={"tab-Taskuri-Primite" + this.state.denumireTab}
              tabActive={
                this.state.activeTab ===
                "tab-Taskuri-Primite" + this.state.denumireTab
              }>
              {this.state.activeTab ===
              "tab-Taskuri-Primite" + this.state.denumireTab ? (
                <DataGrid
                  onRowClick={(state) => {
                    var id = state.rowData.IdProiect;
                    this.$f7.methods.openMainDreaptaById(id);
                    this.$f7.data.agendaIdProiect = id;
                  }}
                  dataLoaded={this.state.dataLoadedPrimite}
                  name={"TotalTaskuriPrimiteSalariat"}
                  ref={this.refDataGridPrimite}
                  id={"IdSarcina"}
                  onInitialised={(state, r, itemeRandate) => {
                    this.$f7.methods.colorItems(
                      state,
                      r,
                      itemeRandate,
                      "DeCine"
                    );
                  }}
                  onRenderItemsChanged={(state, r, itemeRandate) => {
                    this.$f7.methods.colorItems(
                      state,
                      r,
                      itemeRandate,
                      "DeCine"
                    );
                  }}
                  keys={[
                    "Denumire",
                    "Cerinta",
                    "DataCreare",
                    "Client",
                    "Sursa",
                    "DeCine",
                    "PR",
                  ]}
                  ignoredInputsAdd={["Deadline"]}
                  ignoreInputsEdit={["Deadline"]}
                  renderUpdate={false}
                  renderDelete={false}
                  renderAdd={false}
                  renderCheckbox={false}
                  maxHeight={this.state.height}
                  inputs={this.state.inputsPrimite}
                  dataSource={this.state.dataSourcePrimite}
                  contextMenuItems={[
                    {
                      key: "Refresh",
                      onClick: (state) => {
                        setTimeout(() => {
                          this.setState(
                            {
                              totalPrimite: 0,
                            },
                            () => {
                              this.GetValuesPrimite();
                            }
                          );
                        }, 1000);
                      },
                      icon: "refresh",
                    },
                  ]}
                  coloredRow={{
                    sourceCol: "Deadline",
                    type: "date",
                  }}
                />
              ) : null}
            </Tab>

            <Tab
              style={{
                height: "100%",
                position: "relative",
              }}
              id={"tab-Taskuri-Inchise" + this.state.denumireTab}
              tabActive={
                this.state.activeTab ===
                "tab-Taskuri-Inchise" + this.state.denumireTab
              }>
              {this.state.activeTab ===
              "tab-Taskuri-Inchise" + this.state.denumireTab ? (
                <DataGrid
                  onRowClick={(state) => {
                    var id = state.rowData.IdProiect;
                    this.$f7.methods.openMainDreaptaById(id);
                    this.$f7.data.agendaIdProiect = id;
                  }}
                  dataLoaded={this.state.dataLoadedInchise}
                  name={"TotalTaskuriInchiseSalariat"}
                  ref={this.refDataGridInchise}
                  id={"IdSarcina"}
                  onInitialised={(state, r, itemeRandate) => {
                    this.$f7.methods.colorItems(state, r, itemeRandate);
                  }}
                  onRenderItemsChanged={(state, r, itemeRandate) => {
                    this.$f7.methods.colorItems(state, r, itemeRandate);
                  }}
                  keys={[
                    "Denumire",
                    "Cerinta",
                    "DataInchiderii",
                    "Client",
                    "Sursa",
                    "PR",
                    "F",
                  ]}
                  renderUpdate={false}
                  renderDelete={false}
                  renderAdd={false}
                  renderCheckbox={false}
                  maxHeight={this.state.height}
                  inputs={this.state.inputsInchise}
                  dataSource={this.state.dataSourceInchise}
                  contextMenuItems={[
                    {
                      key: "Refresh",
                      onClick: (state) => {
                        setTimeout(() => {
                          this.setState(
                            {
                              totalInchise: 0,
                            },
                            () => {
                              this.GetValuesInchise();
                            }
                          );
                        }, 1000);
                      },
                      icon: "refresh",
                    },
                  ]}
                />
              ) : null}
            </Tab>
          </Tabs>
        </div>
      </>
    );
  }
}
export default TotalTaskuriSalariat;
