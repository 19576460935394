import React from "react";
import {
  Button,
  Treeview,
  TreeviewItem,
  Panel,
  Page,
  Icon,
  Segmented,
  Toggle,
} from "framework7-react";
import axios from "axios";
import moment from "moment";

/**
 * @description Afiseaza taskul curent pornit si de cat timp se lucreaza la el dar si informatii de tip setari
 * @date 08/04/2022
 * @class UserPopover
 * @extends {React.PureComponent}
 */
class UserPopover extends React.PureComponent {
  constructor(props) {
    super(props);
    this.denumirePopover = props.denumire;

    this.Salariati = this.$f7.data.Salariati.filter(
      (item) =>
        item.preferinte !== null &&
        item.value !== this.$f7.data.user.iD_SALARIAT
    );
    this.Salariati.forEach((el) => {
      el.ref = -1;
    });

    this.state = {
      suneteConexiune:
        localStorage.getItem("suneteConexiune") !== undefined
          ? JSON.parse(localStorage.getItem("suneteConexiune"))
          : true,
      logareAutomata: false,
      repornire: false,
      linkApiRealizari: "/api/RealizareSarcini",
      linkApiSalariati: "/api/Salariati",
      linkApiEvenimente: "/api/EvenimenteSalariati",
      idProiectActiv: null,
      listaRealizari: [],
      timpTotal: {
        zile: 0,
        ore: 0,
        minute: 0,
        secunde: 0,
      },
      timpCurent: {
        zile: 0,
        ore: 0,
        minute: 0,
        secunde: 0,
      },
      utilizatori: this.Salariati,
      utilizatorImportPreferinte: null,
      audioMessage: true,
      fromSistemIgnore: true,

      zileCOramase: null,
      timpInvoit: null,
      timpExtra: null
    };

    this.timeInterval = null;
  }

  /**
   * @description Ia taskul activ al utilizatorului
   * @function
   * @date 08/04/2022
   * @memberof UserPopover
   */
  GetValues = () => {
    axios.all([
      axios.get(`${this.$f7.data.baseURL}${this.state.linkApiEvenimente}/GetCORamase/${this.$f7.data.idSalariatFunctii}`),
      axios.get(`${this.$f7.data.baseURL}${this.state.linkApiEvenimente}/GetUserTime/${this.$f7.data.idSalariatFunctii}`)
    ]).then(([responseCO, responseTime]) => {
      this.setState({
        zileCOramase: responseCO.data,
        timpInvoit: this.ConvertToFriendlyTime(responseTime.data.timpInvoit),
        timpExtra: this.ConvertToFriendlyTime(responseTime.data.timpExtra)
      });
    }).catch(err => {
      console.log("error", err);
    });


    // axios.get(`${this.$f7.data.baseURL}${this.state.linkApiEvenimente}/GetCORamase/${this.$f7.data.idSalariatFunctii}`).then(response => {
    //   this.setState({
    //     zileCOramase: response.data
    //   });
    // }).catch(err => {
    //   console.log("error", err);
    // });

    axios
      .get(
        this.$f7.data.baseURL +
          this.state.linkApiRealizari +
          "/VerificareTaskActivByUser/" +
          this.$f7.data.user.iD_SALARIAT
      )
      .then((responseActiv) => {
        const idProiect = responseActiv.data;

        if (idProiect !== -100) {
          axios
            .get(
              this.$f7.data.baseURL +
                this.state.linkApiRealizari +
                "/GetREALIZARE_SARCINI_BY_PROIECT_AND_SALARIAT/" +
                idProiect +
                "/" +
                this.$f7.data.user.iD_SALARIAT
            )
            .then((responseRealizari) => {
              this.setState(
                {
                  idProiectActiv: idProiect,
                  listaRealizari: responseRealizari.data,
                },
                () => {
                  if (this.timeInterval !== null) {
                    clearInterval(this.timeInterval);
                  }

                  this.calculareTimpi();

                  this.timeInterval = setInterval(() => {
                    this.calculareTimpi();
                  }, 1000);
                }
              );
            })
            .catch((error) => {
              console.log("error", error);
            });
        } else {
          this.setState(
            {
              idProiectActiv: null,
            },
            () => {
              if (this.timeInterval !== null) {
                clearInterval(this.timeInterval);
              }
            }
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /**
   * @description Calculeaza timpul taskului curent pornit, dar si timpul total al acestui task de cand a fost creat
   * @function
   * @date 08/04/2022
   * @memberof UserPopover
   */
  calculareTimpi = () => {
    let total = 0;
    let curent = 0;

    this.state.listaRealizari.forEach((item) => {
      const inceput = moment(item.intrare).valueOf();
      const sfarsit =
        item.iesire === null
          ? moment().valueOf()
          : moment(item.iesire).valueOf();

      if (item.iesire === null) {
        curent = sfarsit - inceput;
      }

      total += sfarsit - inceput;
    });

    // Calculam zile, ore, minute si secunde pentru timpul total (suma duratelor tuturor activitatilor de la un task)
    const zileTotal = Math.floor(total / (24 * 60 * 60 * 1000));
    total = total % (24 * 60 * 60 * 1000);
    const oreTotal = Math.floor(total / (60 * 60 * 1000));
    total = total % (60 * 60 * 1000);
    const minuteTotal = Math.floor(total / (60 * 1000));
    total = total % (60 * 1000);
    const secundeTotal = Math.floor(total / 1000);

    // Calculam zile, ore, minute si secunde pentru timpul curent (durata activitatii curente de la un task)
    const zileActual = Math.floor(curent / (24 * 60 * 60 * 1000));
    curent = curent % (24 * 60 * 60 * 1000);
    const oreActual = Math.floor(curent / (60 * 60 * 1000));
    curent = curent % (60 * 60 * 1000);
    const minuteActual = Math.floor(curent / (60 * 1000));
    curent = curent % (60 * 1000);
    const secundeActual = Math.floor(curent / 1000);

    const timpTotal = {
      zile: zileTotal,
      ore: oreTotal,
      minute: minuteTotal,
      secunde: secundeTotal,
    };

    const timpCurent = {
      zile: zileActual,
      ore: oreActual,
      minute: minuteActual,
      secunde: secundeActual,
    };

    this.setState({
      timpTotal: timpTotal,
      timpCurent: timpCurent,
    });
  };

  /**
   * @description transforma valorile de tip interval de timp din valoare numerica (secunde) intr-un string mai usor de inteles (h : m : s).
   * @function
   * @date 06/04/2022
   * @param {*} seconds
   * @memberof OreLucratePeZi2
   */
  ConvertToFriendlyTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);

    seconds = seconds % 3600;
    const minutes = Math.round(seconds / 60);

    let output;

    if (hours) {
      if (minutes) {
        output =
          (hours > 1 ? hours + "h " : hours + "h ") +
          " : " +
          (minutes > 1 ? minutes + "m " : minutes + "m ");
      } else {
        output = hours > 1 ? hours + "h " : hours + "h ";
      }
    } else {
      if (minutes) {
        output = minutes > 1 ? minutes + "m " : minutes + "m ";
      } else {
        output = "0";
      }
    }

    return "" + output;
  };

  componentDidMount = () => {
    var cookieUser = this.$f7.methods.getCookie("user");
    var cookieParola = this.$f7.methods.getCookie("parola");
    var cookieRepornire = this.$f7.methods.getCookie("repornire");
    if (
      cookieRepornire !== undefined &&
      cookieRepornire !== "" &&
      cookieRepornire === "true"
    ) {
      this.setState({ repornire: true });
    }
    if (
      cookieUser !== undefined &&
      cookieUser !== "" &&
      cookieParola !== undefined &&
      cookieParola !== ""
    ) {
      this.setState({
        username: cookieUser,
        password: cookieParola,
        logareAutomata: true,
      });
    }

    if (localStorage.getItem("audioMessage")) {
      this.setState({
        audioMessage: JSON.parse(localStorage.getItem("audioMessage")),
      });
    } else {
      localStorage.setItem("audioMessage", true);
    }

    if (localStorage.getItem("fromSistemIgnore")) {
      this.setState({
        fromSistemIgnore: JSON.parse(localStorage.getItem("fromSistemIgnore")),
      });
    } else {
      localStorage.setItem("fromSistemIgnore", true);
    }
  };

  render() {
    return (
      <Panel
        resizable={true}
        onPanelOpen={() => {
          this.GetValues();
        }}
        style={{
          width: "310px",
          padding: "8px",
        }}
        backdrop={true}
        className={this.denumirePopover}>
        <Page>
          <div style={{ textAlign: "center" }}>
            <h2
              className={"navbar-move"}
              style={{
                cursor: "move",
                textAlign: "center",
                padding: "0 8px",
              }}>
              Hei,{" "}
              {
                this.$f7.data.Salariati.find(
                  (item) => item.value === this.$f7.data.user.iD_SALARIAT
                ).key
              }
              !
            </h2>
            <div
              style={{
                textAlign: "center",
                backgroundColor: "var(--f7-theme-color)",
                borderRadius: "8px 8px 0 0",
                color: "#fff",
                marginBottom: this.state.idProiectActiv !== null ? -8 : 0,
                padding:
                  this.state.idProiectActiv !== null
                    ? "4px 10px 0"
                    : "6px 10px",
                fontSize: "12px",
              }}>
              {this.state.idProiectActiv !== null ? (
                this.$f7.data.Proiecte.find(
                  (item) => item.value === this.state.idProiectActiv
                ).key
              ) : (
                <div>Nu aveti nici un task pornit</div>
              )}
            </div>

            {this.state.idProiectActiv !== null ? (
              <div
                style={{
                  backgroundColor: "var(--f7-theme-color)", //"#080808",
                  color: "#fff",
                  padding: 16,
                  //borderRadius: 8
                }}>
                <div
                  style={{
                    textAlign: "right",
                    textTransform: "uppercase",
                    //textDecoration: "underline",
                    fontSize: "10px",
                    marginRight: window.innerWidth < 700 ? 50 : 15,
                    marginBottom: 3,
                  }}>
                  Total:{" "}
                  {this.state.timpTotal.zile < 10
                    ? "0" + this.state.timpTotal.zile
                    : this.state.timpTotal.zile}
                  ,{" "}
                  {this.state.timpTotal.ore < 10
                    ? "0" + this.state.timpTotal.ore
                    : this.state.timpTotal.ore}
                  :
                  {this.state.timpTotal.minute < 10
                    ? "0" + this.state.timpTotal.minute
                    : this.state.timpTotal.minute}
                  :
                  {this.state.timpTotal.secunde < 10
                    ? "0" + this.state.timpTotal.secunde
                    : this.state.timpTotal.secunde}
                </div>

                <div>
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "25%",
                      height: "70px",
                      fontSize: 45,
                      lineHeight: "45px",
                      textAlign: "center",
                    }}>
                    {this.state.timpCurent.zile < 10
                      ? "0" + this.state.timpCurent.zile
                      : this.state.timpCurent.zile}
                    <span
                      style={{
                        fontSize: "11px",
                        lineHeight: 1.5,
                        display: "block",
                        textTransform: "uppercase",
                        marginTop: 3,
                        color: "rgba(255, 255, 255, 0.75)",
                      }}>
                      zile
                    </span>

                    <span
                      style={{
                        position: "absolute",
                        top: -3,
                        right: -4,
                        fontSize: "30px",
                        color: "rgba(255, 255, 255, 0.75)",
                      }}>
                      :
                    </span>
                  </div>

                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "25%",
                      height: "70px",
                      fontSize: 45,
                      lineHeight: "45px",
                      textAlign: "center",
                    }}>
                    {this.state.timpCurent.ore < 10
                      ? "0" + this.state.timpCurent.ore
                      : this.state.timpCurent.ore}
                    <span
                      style={{
                        fontSize: "11px",
                        lineHeight: 1.5,
                        display: "block",
                        textTransform: "uppercase",
                        marginTop: 3,
                        color: "rgba(255, 255, 255, 0.75)",
                      }}>
                      ore
                    </span>

                    <span
                      style={{
                        position: "absolute",
                        top: -3,
                        right: -4,
                        fontSize: "30px",
                        color: "rgba(255, 255, 255, 0.75)",
                      }}>
                      :
                    </span>
                  </div>

                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "25%",
                      height: "70px",
                      fontSize: 45,
                      lineHeight: "45px",
                      textAlign: "center",
                    }}>
                    {this.state.timpCurent.minute < 10
                      ? "0" + this.state.timpCurent.minute
                      : this.state.timpCurent.minute}
                    <span
                      style={{
                        fontSize: "11px",
                        lineHeight: 1.5,
                        display: "block",
                        textTransform: "uppercase",
                        marginTop: 3,
                        color: "rgba(255, 255, 255, 0.75)",
                      }}>
                      minute
                    </span>

                    <span
                      style={{
                        position: "absolute",
                        top: -3,
                        right: -4,
                        fontSize: "30px",
                        color: "rgba(255, 255, 255, 0.75)",
                      }}>
                      :
                    </span>
                  </div>

                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "25%",
                      height: "70px",
                      fontSize: 45,
                      lineHeight: "45px",
                      textAlign: "center",
                    }}>
                    {this.state.timpCurent.secunde < 10
                      ? "0" + this.state.timpCurent.secunde
                      : this.state.timpCurent.secunde}
                    <span
                      style={{
                        fontSize: "11px",
                        lineHeight: 1.5,
                        display: "block",
                        textTransform: "uppercase",
                        marginTop: 3,
                        color: "rgba(255, 255, 255, 0.75)",
                      }}>
                      secunde
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div style={{ paddingBottom: 40 }}>
            <div style={{ padding: 8, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div className="timeUserInfo" style={{ width: '33.33%', height: 50, textAlign: 'center' }}>
                <h3 style={{ margin: 0, textDecoration: 'underline' }}>CO ramas</h3>
                <div style={{ fontSize: 20 }}>
                  {this.state.zileCOramase ?? "-" }/<span style={{ fontSize: 12 }}>21</span>
                </div>
              </div>

              <div className="timeUserInfo" style={{ width: '33.33%', height: 50, textAlign: 'center' }}>
                <h3 style={{ margin: 0, textDecoration: 'underline' }}>Timp invoit</h3>
                <div style={{ fontSize: 20 }}>{this.state.timpInvoit ?? "-"}</div>
              </div>
              
              <div className="timeUserInfo" style={{ width: '33.33%', height: 50, textAlign: 'center' }}>
                <h3 style={{ margin: 0, textDecoration: 'underline' }}>Timp extra</h3>
                <div style={{ fontSize: 20 }}>{this.state.timpExtra ?? "-"}</div>
              </div>
            </div>


            <div
              style={{
                padding: "8px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              <span style={{ fontSize: 16 }}>Avertizare sonora mesaje</span>
              <Toggle
                checked={this.state.audioMessage}
                color="var(--f7-theme-color)"
                onChange={(e) => {
                  var checked = e.currentTarget.checked;
                  this.setState(
                    {
                      audioMessage: checked,
                    },
                    () => {
                      localStorage.setItem(
                        "audioMessage",
                        this.state.audioMessage
                      );
                    }
                  );
                }}
              />
            </div>

            <div
              style={{
                padding: "8px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              <span style={{ fontSize: 16 }}>Notificari Sistem</span>
              <Toggle
                checked={!this.state.fromSistemIgnore}
                color="var(--f7-theme-color)"
                onChange={(e) => {
                  var checked = e.currentTarget.checked;
                  this.setState(
                    {
                      fromSistemIgnore: !checked,
                    },
                    () => {
                      localStorage.setItem(
                        "fromSistemIgnore",
                        this.state.fromSistemIgnore
                      );
                    }
                  );
                }}
              />
            </div>

            <div
              style={{
                padding: "8px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              <span style={{ fontSize: 16 }}>Repornire ultimul task</span>
              <Toggle
                checked={this.state.repornire}
                color="var(--f7-theme-color)"
                onChange={(e) => {
                  var checked = e.currentTarget.checked;
                  this.setState(
                    {
                      repornire: checked,
                    },
                    () => {
                      this.$f7.methods.setCookie(
                        "repornire",
                        this.state.repornire,
                        90
                      );
                    }
                  );
                }}
              />
            </div>

            <div
              style={{
                padding: "8px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              <span style={{ fontSize: 16 }}>Logare automata</span>
              <Toggle
                checked={this.state.logareAutomata}
                color="var(--f7-theme-color)"
                onChange={(e) => {
                  var checked = e.currentTarget.checked;
                  this.setState(
                    {
                      logareAutomata: checked,
                    },
                    () => {
                      if (this.state.logareAutomata) {
                        this.$f7.methods.setCookie(
                          "user",
                          this.$f7.data.user.numE_LOGIN,
                          90
                        );
                        this.$f7.methods.setCookie(
                          "parola",
                          this.$f7.data.userPassword,
                          90
                        );
                      } else {
                        this.$f7.methods.setCookie("user", "", 90);
                        this.$f7.methods.setCookie("parola", "", 90);
                      }
                    }
                  );
                }}
              />
            </div>

            <div
              style={{
                padding: "8px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}>
              <span style={{ fontSize: 16 }}>Sunete conexiune</span>
              <Toggle
                checked={this.state.suneteConexiune}
                color="var(--f7-theme-color)"
                onChange={(e) => {
                  var checked = e.currentTarget.checked;
                  this.setState(
                    {
                      suneteConexiune: checked,
                    },
                    () => {
                      localStorage.setItem("suneteConexiune", checked);
                    }
                  );
                }}
              />
            </div>

            <Treeview id={"UserTreeview"}>
              <TreeviewItem
                style={{ padding: 4 }}
                key={"setari1"}
                selectable
                panelClose
                iconMaterial={"swap_horiz"}>
                <span
                  onClick={() => {
                    this.$f7.data.iconita = "menu";
                    if (this.$f7.data.paginaCurenta !== undefined) {
                      this.$f7.data.paginaCurenta.setState({ iconita: "menu" });
                    }
                    if (this.$f7.data.homePage !== undefined) {
                      this.$f7.data.homePage.setState({ iconita: "menu" });
                    }
                    this.$f7.views.main.router.navigate("/tranzitie/");
                  }}
                  slot="label">
                  Tranzitie
                </span>
              </TreeviewItem>
              <TreeviewItem
                style={{ padding: 4 }}
                key={"setari2"}
                selectable
                panelClose
                iconMaterial={"brightness_high"}>
                <span
                  onClick={() => {
                    this.$f7.data.iconita = "menu";
                    if (this.$f7.data.paginaCurenta !== undefined) {
                      this.$f7.data.paginaCurenta.setState({ iconita: "menu" });
                    }
                    if (this.$f7.data.homePage !== undefined) {
                      this.$f7.data.homePage.setState({ iconita: "menu" });
                    }
                    this.$f7.methods.nightMode();
                  }}
                  slot="label">
                  Mod noapte
                </span>
              </TreeviewItem>
              <TreeviewItem
                style={{ padding: 4 }}
                key={"setari3"}
                selectable
                panelClose
                iconMaterial={"color_lens"}>
                <span
                  onClick={() => {
                    this.$f7.data.iconita = "menu";
                    if (this.$f7.data.paginaCurenta !== undefined) {
                      this.$f7.data.paginaCurenta.setState({ iconita: "menu" });
                    }
                    if (this.$f7.data.homePage !== undefined) {
                      this.$f7.data.homePage.setState({ iconita: "menu" });
                    }
                    this.$f7.views.main.router.navigate("/colorpicker/");
                  }}
                  slot="label">
                  Culoare tema
                </span>
              </TreeviewItem>
              <TreeviewItem
                style={{ padding: 4 }}
                key={"setari4"}
                selectable
                panelClose
                iconMaterial={"security"}>
                <span
                  onClick={() => {
                    this.$f7.data.iconita = "menu";
                    if (this.$f7.data.paginaCurenta !== undefined) {
                      this.$f7.data.paginaCurenta.setState({ iconita: "menu" });
                    }
                    if (this.$f7.data.homePage !== undefined) {
                      this.$f7.data.homePage.setState({ iconita: "menu" });
                    }
                    this.$f7.views.main.router.navigate("/schimbareParola/");
                  }}
                  slot="label">
                  Schimbare parola
                </span>
              </TreeviewItem>
            </Treeview>
          </div>

          <Segmented
            raised
            style={{
              position: "fixed",
              margin: "10px auto 0",
              // width: "250px",
              left: 0,
              right: 0,
              bottom: 0,
            }}
            tag="div">
            <Button
              panelClose
              fill
              style={{ width: "calc(100% - 150px)" }}
              tooltip="Delogare"
              small
              onClick={() => {
                this.$f7.dialog.confirm(
                  "Doriti sa va delogati?",
                  "Delogare",
                  () => {
                    this.$f7.data.signalRConnection.stop();
                    this.$f7.methods
                      .logout()
                      .then(() => {
                        this.$f7.methods.setCookie("user", "", 90);
                        this.$f7.methods.setCookie("parola", "", 90);
                        this.$f7.methods.setCookie("repornire", "", 90);
                        delete axios.defaults.headers.common["Authorization"];
                        this.$f7.views.main.router.navigate("/login/");
                        var data = {
                          baseURL: "",
                          iconita: "menu",
                          user: undefined,
                          datagrids: {},
                          toggleTotiSalariatii: false,
                          timerUltimaActivitate: 5,
                          rol: null,
                          rolAdevarat: null,
                          dataSourcePlanificare: [],
                        };
                        this.$f7.data = data;
                      })
                      .catch((err) => {
                        this.$f7.methods.openNotification(
                          "Eroare delogare" + err
                        );
                      });
                  }
                );
              }}>
              Delogare
            </Button>

            <Button
              panelClose
              tooltip={"Salveaza preferintele pe server"}
              fill
              small
              style={{
                width: 50,
              }}
              onClick={() => {
                const preferinteleDinLocal = JSON.stringify(localStorage);

                axios
                  .get(
                    this.$f7.data.baseURL +
                      this.state.linkApiSalariati +
                      "/GetSALARIATI/" +
                      this.$f7.data.user.iD_SALARIAT
                  )
                  .then((response) => {
                    let salariat = response.data;

                    if (salariat.preferinte !== null) {
                      this.$f7.dialog.confirm(
                        "Doriti sa suprascrieti preferintele existente pe server?",
                        "Atentie",
                        () => {
                          salariat.preferinte = preferinteleDinLocal;

                          axios({
                            data: salariat,
                            headers: { "Content-Type": "application/json" },
                            method: "put",
                            url:
                              this.$f7.data.baseURL +
                              this.state.linkApiSalariati +
                              "/PutSALARIATI/" +
                              salariat.iD_SALARIAT,
                          })
                            .then((responseUpdate) => {
                              this.$f7.methods.openNotification(
                                "Preferinte salvate cu succes.",
                                undefined,
                                "succes"
                              );
                              console.log("responseUpdate:", responseUpdate);
                            })
                            .catch((error) => {
                              console.log("error", error);
                            });
                        },
                        () => {}
                      );
                    } else {
                      salariat.preferinte = preferinteleDinLocal;

                      axios({
                        data: salariat,
                        headers: { "Content-Type": "application/json" },
                        method: "put",
                        url:
                          this.$f7.data.baseURL +
                          this.state.linkApiSalariati +
                          "/PutSALARIATI/" +
                          salariat.iD_SALARIAT,
                      })
                        .then((responseUpdate) => {
                          console.log("responseUpdate:", responseUpdate);
                        })
                        .catch((error) => {
                          console.log("error", error);
                        });
                    }
                  })
                  .catch((error) => {
                    console.log("error", error);
                  });
              }}>
              <Icon material="backup" />
            </Button>

            <Button
              panelClose
              tooltip={"Restaureaza preferintele de pe server"}
              fill
              small
              style={{
                width: 50,
              }}
              onClick={() => {
                axios
                  .get(
                    this.$f7.data.baseURL +
                      this.state.linkApiSalariati +
                      "/GetSALARIATI/" +
                      this.$f7.data.user.iD_SALARIAT
                  )
                  .then((response) => {
                    const salariat = response.data;
                    let preferinteServer;

                    if (salariat.preferinte !== null) {
                      preferinteServer = JSON.parse(salariat.preferinte);
                    } else {
                      this.$f7.dialog.alert(
                        "Nu ai nici o preferinta salvata pe server!",
                        "Atentie"
                      );
                    }

                    if (preferinteServer !== undefined) {
                      this.$f7.dialog.confirm(
                        "Este necesara reincarcarea paginii pentru aplicarea preferintelor restaurate. Doriti sa continuati?",
                        "Atentie",
                        () => {
                          for (const key in preferinteServer) {
                            if (
                              Object.hasOwnProperty.call(
                                preferinteServer,
                                key
                              ) &&
                              key !== "dataIesire"
                            ) {
                              const element = preferinteServer[key];
                              localStorage.setItem(key, element);
                            }
                          }

                          this.$f7.dialog.alert(
                            "Preferinte restaurate cu succes.",
                            "Info",
                            () => {
                              window.location.reload();
                            }
                          );
                        },
                        () => {}
                      );
                    }
                  })
                  .catch((error) => {
                    console.log("error", error);
                  });
              }}>
              <Icon material="cloud_download" />
            </Button>

            <Button
              //panelClose
              tooltip={"Importa preferinte"}
              fill
              small
              style={{
                width: 50,
              }}
              onClick={() => {
                this.$f7.data.router.navigate("/popupAddEdit/", {
                  props: {
                    title: "Import preferinte",
                    onCloseClicked: () => {},
                    inputs: [
                      {
                        key: "Salariat",
                        keyBaza: "salariat",
                        required: true,
                        tip: "selectbox",
                        lista: this.state.utilizatori,
                        defaultValue: this.state.utilizatorImportPreferinte,
                      },
                    ],
                    buttons: [
                      {
                        key: "Importa",
                        onSubmit: (state, den) => {
                          let item = { ...state.editedItem };

                          if (item.Salariat !== null) {
                            const user = this.state.utilizatori.find(
                              (s) => s.value === item.Salariat
                            );

                            if (user) {
                              const preferinte = JSON.parse(user.preferinte);

                              if (preferinte !== undefined) {
                                this.$f7.dialog.confirm(
                                  "Este necesara reincarcarea paginii pentru aplicarea preferintelor importate. Doriti sa continuati?",
                                  "Atentie",
                                  () => {
                                    for (const key in preferinte) {
                                      if (
                                        Object.hasOwnProperty.call(
                                          preferinte,
                                          key
                                        ) &&
                                        key !== "dataIesire"
                                      ) {
                                        const element = preferinte[key];
                                        localStorage.setItem(key, element);
                                      }
                                    }

                                    this.$f7.panel.close(den);

                                    this.$f7.dialog.alert(
                                      "Preferinte importate cu succes.",
                                      "Info",
                                      () => {
                                        window.location.reload();
                                      }
                                    );
                                  },
                                  () => {}
                                );
                              }
                            }
                          } else {
                            this.$f7.dialog.alert(
                              "Nu ai selectat nimic!",
                              "Atentie"
                            );
                          }
                        },
                      },
                    ],
                  },
                });
              }}>
              <Icon material="import_export" />
            </Button>
          </Segmented>
        </Page>
        {/* </DraggingPanel> */}
      </Panel>
    );
  }
}
export default UserPopover;
