import React from "react";
import axios from "axios";
import moment from "moment";
import {
  Preloader,
  List,
  ListItem,
  ListInput,
  Icon,
  Link,
  Popover,
  View
} from "framework7-react";
//import DataGrid from "../../Datagrid/DataGrid";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";

class IstoricTaskGrid extends React.Component {
  constructor(props) {
    super(props);
    this.height = window.innerHeight - 100;
    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;
    this.lista = this.Salariati;

    switch (this.props.type) {
      case 1: {
        var l = this.$f7.data.allProiecte.filter(x => {
          return x.id_nivel_proiect === 4;
        });
        var listaFinala = this.$f7.data.Proiecte.filter(x => {
          return l.find(y => {
            return x.value === y.iD_PROIECT;
          });
        });
        this.lista = listaFinala;
        break;
      }
      case 2: {
        var l2 = this.$f7.data.allProiecte.filter(x => {
          return x.id_nivel_proiect === 1 || x.id_nivel_proiect === 2;
        });
        var listaFinala2 = this.$f7.data.Proiecte.filter(x => {
          return l2.find(y => {
            return x.value === y.iD_PROIECT;
          });
        });
        this.lista = listaFinala2;
        break;
      }
      case 3: {
        var f = this.$f7.data.allProiecte.filter(x => {
          return x.id_tip_proiect === 59;
        });

        var l3 = [...new Map(f.map(item => [item["denumire"], item])).values()];
        var listaFinala3 = this.$f7.data.Proiecte.filter(x => {
          return l3.find(y => {
            return x.value === y.iD_PROIECT;
          });
        });

        this.lista = listaFinala3;
        break;
      }
      case 4: {
        this.lista = this.$f7.data.Firme;
        break;
      }
      default: {
        break;
      }
    }

    this.state = {
      informatiiPopover: null,
      idProiect: this.lista[0],
      dataStart: moment("1980-01-01T01:00:00Z").format(),
      dataEnd: moment().format(),
      items: [],
      groups: [],
      dataSource: [],
      dataLoaded: false,
      inputs: [
        { key: "Data", keyBaza: "data", tip: "datebox" },
        { key: "DataFinal", keyBaza: "dataFinal", tip: "datebox" },
        {
          key: "Salariat",
          keyBaza: "salariatCurent",
          tip: "selectbox",
          lista: this.$f7.data.Salariati
        },
        {
          key: "SalariatPropunere",
          keyBaza: "salariatVechi",
          tip: "selectbox",
          lista: this.$f7.data.Salariati
        },
        { key: "Comentariu", keyBaza: "comentariu" },
        { key: "ComentariuPV", keyBaza: "comentariuPV" },
        { key: "Stare", keyBaza: "comentariuStare" },
        { key: "Durata", keyBaza: "durata" },
        { key: "IdTask", keyBaza: "idTask", tip: "number" },
        { key: "IdClient", keyBaza: "idClient", tip: "number" },
        { key: "RefTask", keyBaza: "refTask", tip: "number" }
      ]
    };
    this.timelineRef = React.createRef();
  }

  openPopup = (id, pagina) => {
    var proiect = this.$f7.data.allProiecte.find(r => {
      return r.iD_PROIECT === id;
    });
    var sal = this.$f7.data.Salariati.find(x => {
      return x.value === id;
    });
    if (proiect !== undefined) {
      this.$f7.data.Proiect = proiect;
      this.$f7.views.main.router.navigate("/popupDinamic/", {
        props: {
          title: (
            <Link
              tooltip={
                this.state.informatiiPopover.proiect
                  ? this.state.informatiiPopover.proiect.comentariu
                  : ""
              }
            >
              {this.state.informatiiPopover.denumireTask}
            </Link>
          ),
          disableClose: false,
          component: (
            <View
              removeElements={true}
              componentCache={false}
              onViewInit={e => {
                this.$f7.data.routerDreapta = e.router;
                setTimeout(() => {
                  if (pagina !== undefined && pagina === 1) {
                    this.$f7.data.Grid.setState({
                      activeTab:
                        "tab-Grid-Descriere" +
                        this.$f7.data.Grid.state.denumireTab
                    });
                  }
                  if (pagina !== undefined && pagina === 2) {
                    this.$f7.data.Grid.setState({
                      activeTab:
                        "tab-Grid-TimpLucrat" +
                        this.$f7.data.Grid.state.denumireTab
                    });
                  }
                }, 350);
              }}
              id="oreLucratePeZiSheet"
              tabDeschis={"tab-Grid-TimpLucrat"}
              name="oreLucratePeZiSheet"
              url={"/agendaGridMainDreaptaActivitate/"}
            />
          )
        }
      });
    } else if (sal) {
      this.openConcedii(id);
    }
  };

  GetValues = () => {
    this.setState({ dataLoaded: false }, () => {
      var url = "/api/Proiecte/GetIstoricProiect";
      var dateRequest = {
        tipRequest: this.props.type,
        idProiect: this.state.idSelectat,
        dataStart: this.state.dataStart,
        dataEnd: this.state.dataEnd
      };
      axios({
        data: dateRequest,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url
      }).then(r => {
        var date = r;

        date.data.map(eveniment => {
          eveniment = this.$f7.methods.restructurareBaza(
            eveniment,
            this.state.inputs,
            "key"
          );
          return false;
        });

        var baza = this.$f7.methods.reconstructieBaza(date, this.state.inputs);
        var ref = [1, 2, 4].includes(dateRequest.tipRequest)
          ? "IdTask"
          : "IdClient";
        var f = this.generateGroups(baza, [], "popoverIstoric", ref);
        this.setState({
          dataLoaded: true,
          dataSource: baza,
          items: f.dataSource,
          groups: f.groups
        });
      });
    });
  };
  componentDidMount = () => {
    this.GetValues();
  };

  generateGroups = (date, newGroups, popoverName, useRef) => {
    var arr = [];
    var informatiiPopover = {};
    date.map(d => {
      var idForGroup = d[useRef];
      var pro = this.$f7.data.allProiecte.find(pro => {
        return pro.iD_PROIECT === idForGroup;
      });
      if (pro) {
        //GENERARE newGroups
        var lista = newGroups.find(r => {
          return r.id === idForGroup;
        });
        if (!lista) {
          var title = pro.denumire;
          if (useRef === "IdClient") {
            var f = this.$f7.data.Firme.find(v => {
              return v.value === idForGroup;
            });
            if (f) {
              title = f.key;
            }
          }
          newGroups.push({
            id: idForGroup,
            title: title,
            mantis: pro.id_tip_proiect === 55,
            stadiu: pro.iD_STADIU
          });
        }

        //GENERARE sursa grafic
        var rand = Math.random() * 360;
        var colorString = "hsl(" + rand + ", 100%, 60%)";
        arr.push({
          id: this.$f7.utils.id("xxxx-xxxx-xxxx-xxxx-xxxx"),
          group: idForGroup,
          start_time: moment(d.Data),
          end_time: moment(d.DataFinal),
          title: "",
          canMove: false,
          canResize: false,
          itemProps: {
            style: {
              background: colorString,
              border: "1px solid " + colorString
            },
            onClick: e => {
              var t = e.currentTarget;
              console.log("pro", pro);
              var sal = this.$f7.data.Salariati.find(x => {
                return x.value === d.Salariat;
              });
              var client = this.$f7.data.Firme.find(x => {
                return x.value === d.IdClient;
              });
              informatiiPopover = {
                numeClient: client.key,
                numeSalariat: sal ? sal.key : "",
                deadline: pro.deadline,
                inceput: moment(d.Data).format(),
                sfarsit: moment(d.DataFinal).format(),
                durata: d.Durata,
                timpTotalSalariat: 0,
                timpTotalTask: 0,
                comentariu: d.Comentariu,
                observatie: 0,
                pornit: d.Stare === 2,
                realizare: null,
                denumireTask: pro.denumire,
                proiect: pro,
                lucrat: pro.timp_l,
                estimat: pro.timp_plan_d
              };
              var popoverToOpen = this.$f7.popover.get("." + popoverName);
              if (popoverToOpen) {
                this.setState({ informatiiPopover: informatiiPopover }, () => {
                  popoverToOpen.open(t);
                });
              }
            }
          }
        });
        return false;
      }
      return null;
    });
    return {
      groups: newGroups,
      dataSource: arr,
      informatiiPopover: informatiiPopover
    };
  };

  render() {
    return (
      <>
        {this.state.dataLoaded ? (
          <>
            <Popover className="popoverIstoric">
              {this.state.informatiiPopover !== null ? (
                <>
                  <Link
                    popoverClose
                    tooltip={
                      this.state.informatiiPopover.proiect
                        ? this.state.informatiiPopover.proiect.comentariu
                        : ""
                    }
                    style={{
                      fontSize: "small",
                      width: "100%",
                      margin: "6px 0px"
                    }}
                    onClick={() => {
                      this.openPopup(
                        this.state.informatiiPopover.proiect.iD_PROIECT,
                        1
                      );
                    }}
                  >
                    {this.state.informatiiPopover.denumireTask}
                  </Link>
                  <p>
                    <span className={"spanOreLucreate"}>Client:</span>
                    {this.state.informatiiPopover.numeClient}
                  </p>
                  <p>
                    <span className={"spanOreLucreate"}>Angajat:</span>
                    {this.state.informatiiPopover.numeSalariat}
                  </p>
                  <p>
                    <span className={"spanOreLucreate"}>Deadline:</span>
                    {moment(this.state.informatiiPopover.deadline).format(
                      "'YY-MM-DD HH:mm"
                    )}
                  </p>
                  <p>
                    <span className={"spanOreLucreate"}>Durata:</span>
                    {this.state.informatiiPopover.durata}
                  </p>
                  <p>
                    <span className={"spanOreLucreate"}>Lucrat:</span>
                    {this.state.informatiiPopover.lucrat}
                  </p>{" "}
                  <p>
                    <span className={"spanOreLucreate"}>Estimat:</span>
                    {this.state.informatiiPopover.estimat}
                  </p>
                  {this.state.informatiiPopover.proiect ? (
                    <Link
                      popoverClose
                      tooltip={"Cere detalii"}
                      style={{
                        fontSize: "small",
                        width: "100%",
                        margin: "6px 0px"
                      }}
                      onClick={() => {
                        var id = this.state.informatiiPopover.proiect
                          .iD_PROIECT;
                        this.$f7.methods.openMesaje(null, id);
                      }}
                    >
                      Cere detalii
                    </Link>
                  ) : null}
                  {this.state.informatiiPopover.proiect ? (
                    <Link
                      popoverClose
                      tooltip={"Activitate task"}
                      style={{
                        fontSize: "small",
                        width: "100%",
                        margin: "6px 0px"
                      }}
                      onClick={() => {
                        this.openPopup(
                          this.state.informatiiPopover.proiect.iD_PROIECT,
                          2
                        );
                      }}
                    >
                      Activitate task
                    </Link>
                  ) : null}
                </>
              ) : null}
            </Popover>
            <Timeline
              defaultTimeStart={moment(this.state.dataStart).startOf("day")}
              defaultTimeEnd={moment(this.state.dataEnd).endOf("day")}
              hideHorizontalLines={false}
              ref={this.timelineRef}
              groups={this.state.groups}
              items={this.state.items}
              lineHeight={20}
              sidebarWidth={250}
            >
              <TimelineHeaders>
                <SidebarHeader>
                  {({ getRootProps }) => {
                    return <div {...getRootProps()} />;
                  }}
                </SidebarHeader>
                <DateHeader unit="primaryHeader" />
                <DateHeader />
              </TimelineHeaders>
            </Timeline>
            <div
              style={{
                position: "fixed",
                width: "100%",
                height: 58,
                left: 0,
                bottom: 0,
                backgroundColor: "white"
              }}
            >
              <List
                style={{
                  width: "100%"
                }}
              >
                <ListItem
                  style={{ width: 155, display: "inline-block" }}
                  validate
                  title={"Lista"}
                  label={"Lista"}
                  className={"roundedOutlinedSmartSelect"}
                  floatingLabel
                  smartSelect
                  smartSelectParams={{
                    virtualList: true,
                    searchbar: true,
                    openIn: "popup",
                    popupTabletFullscreen: true,
                    popupPush: true,
                    closeOnSelect: true,
                    on: {
                      closed: event => {
                        var val = event.getValue();
                        var vechiulId = this.state.idSelectat;
                        if (val !== vechiulId) {
                          this.setState({ idSelectat: val }, () => {
                            this.GetValues();
                          });
                        }
                      }
                    }
                  }}
                >
                  <Icon
                    color={"var(--f7-theme-color)"}
                    size={20}
                    material="list"
                    slot="content-start"
                  />
                  <select name="SelectIdIstoric">
                    {this.lista.map((obj, index) => (
                      <option
                        key={"SelectIdIstoric-" + index}
                        selected={
                          obj.value !== null &&
                          parseInt(obj.value) ===
                          parseInt(this.state.idSelectat)
                        }
                        value={obj.value}
                      >
                        {obj.key}
                      </option>
                    ))}
                  </select>
                </ListItem>

                <ListInput
                  style={{ display: "inline-block", top: -15 }}
                  key={"IstoricInterval"}
                  label="Interval"
                  type="datepicker"
                  calendarParams={{
                    rangePicker: true,
                    dateFormat: "dd.mm.yyyy",
                    closeOnSelect: true,
                    on: {
                      closed: e => {
                        const val = e.value;
                        var dataStart = val[0];
                        var dataEnd = val[1];
                        this.setState(
                          {
                            dataStart,
                            dataEnd
                          },
                          () => {
                            this.GetValues();
                          }
                        );
                      }
                    }
                  }}
                  value={[this.state.dataStart, this.state.dataEnd]}
                  floatingLabel
                  placeholder="De la"
                >
                  <Icon
                    color={"var(--f7-theme-color)"}
                    size={20}
                    material="today"
                    slot="content-start"
                  />
                </ListInput>
              </List>
            </div>
          </>
        ) : (
          <div
            style={{
              position: "absolute",
              width: "35px",
              height: "35px",
              top: "calc(50vh)",
              left: "50%"
            }}
          >
            <Preloader size={40} color={"var(--f7-theme-color)"} />
          </div>
        )}
      </>
    );
  }
}
export default IstoricTaskGrid;