import React, { Component } from "react";
import { Row, Col, ListItem, List, Icon } from "framework7-react";
import * as sources from "../../../../sources";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";

const data = [
    {
        name: "Page A",
        uv: 4000,
        pv: 2400,
        amt: 2400
    },
    {
        name: "Page B",
        uv: 3000,
        pv: 1398,
        amt: 2210
    },
    {
        name: "Page C",
        uv: 2000,
        pv: 9800,
        amt: 2290
    },
    {
        name: "Page D",
        uv: 2780,
        pv: 3908,
        amt: 2000
    },
    {
        name: "Page E",
        uv: 1890,
        pv: 4800,
        amt: 2181
    },
    {
        name: "Page F",
        uv: 2390,
        pv: 3800,
        amt: 2500
    },
    {
        name: "Page G",
        uv: 3490,
        pv: 4300,
        amt: 2100
    }
];
class StadiuAtlasV3 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceProiect: sources.tipSelectbox,
            height: window.innerHeight - 100,
            width: window.innerWidth - 5,
            dataSourceAngajati: sources.tipSelectbox
        };
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <List
                            style={{
                                margin: "5px 0 5px 5px"
                            }}
                        >
                            <ListItem
                                key="SmartSelectStadiuAtlasProiect"
                                className="roundedOutlinedSmartSelect"
                                floatingLabel
                                label={"Proiect"}
                                title={"Proiect"}
                                validate
                                smartSelect
                                smartSelectParams={{
                                    virtualList: true,
                                    searchbar: true,
                                    openIn: "popup",
                                    popupTabletFullscreen: true,
                                    popupPush: true,
                                    closeOnSelect: true
                                }}
                            >
                                <select name="SelectBoxProiecte">
                                    {this.state.dataSourceProiect.map((obj, index) => (
                                        <option
                                            key={"proiecteStadiuAtlasV3-" + index}
                                            value={obj.value}
                                        >
                                            {obj.key}
                                        </option>
                                    ))}
                                </select>
                                <Icon
                                    slot="content-start"
                                    style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                                    size={20}
                                    material="list"
                                />
                            </ListItem>
                        </List>
                    </Col>
                    <Col>
                        <List
                            style={{
                                margin: "5px 5px 5px 0"
                            }}
                        >
                            <ListItem
                                key="SmartSelectStadiuAtlasProiect"
                                className="roundedOutlinedSmartSelect"
                                floatingLabel
                                label={"Angajat"}
                                title={"Angajat"}
                                validate
                                smartSelect
                                smartSelectParams={{
                                    virtualList: true,
                                    searchbar: true,
                                    openIn: "popup",
                                    popupTabletFullscreen: true,
                                    popupPush: true,
                                    closeOnSelect: true
                                }}
                            >
                                <select name="SelectBoxAngajati">
                                    {this.state.dataSourceAngajati.map((obj, index) => (
                                        <option
                                            key={"angajatiStadiuAtlasV3-" + index}
                                            value={obj.value}
                                        >
                                            {obj.key}
                                        </option>
                                    ))}
                                </select>
                                <Icon
                                    slot="content-start"
                                    style={{ color: "var(--f7-theme-color)", left: "-2px" }}
                                    size={20}
                                    material="list"
                                />
                            </ListItem>
                        </List>
                    </Col>
                </Row>
                <Row>
                    {" "}
                    <BarChart
                        layout="vertical"
                        width={this.state.width}
                        height={this.state.height}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis type="number" />
                        <YAxis dataKey="name" type="category" />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="pv" fill="#8884d8" />
                        <Bar dataKey="uv" fill="#808080" />
                    </BarChart>{" "}
                </Row>
            </>
        );
    }
}
export default StadiuAtlasV3;