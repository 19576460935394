import React from "react";
import TreeList, {
  RowDragging,
  Column,
  ColumnChooser,
  SearchPanel,
  Selection,
} from "devextreme-react/tree-list";
import { Preloader, View, Range } from "framework7-react";
import axios from "axios";
import moment from "moment";
import AtasareFazePage from "../../AtasareFaze/AtasareFazePage";
import { TableExport } from "tableexport";
import ATMain from "../../AlocareTask/ATMain";
import CTMain from "../../ComasareTask/CTMain";
import StadiuProiectePage from "../../StadiuProiecte/StadiuProiectePage";
import ImportExcel from "./ImportExcel";

/**
 * @description Reprezinta lista de proiecte din interfata de intretinere proiecte
 * @date 07/04/2022
 * @class ProiecteGrid
 * @extends {React.PureComponent}
 */
class ProiecteGrid extends React.PureComponent {
  constructor(props) {
    super(props);
    this.niveleProiecte = this.$f7.data.NivelProiect.filter((r) => {
      return r.value !== null;
    });
    this.categorii = this.$f7.data.TipProiect.filter((r) => {
      return r.value !== null && ![55, 56, 57, 58, 59, -1].includes(r.value);
    });
    this.clienti = this.$f7.data.Firme.filter((r) => {
      return r.value !== null;
    });
    this.departamente = this.$f7.data.Departamente.filter((r) => {
      return r.value !== null && r.value !== 0;
    });

    this.height = window.innerHeight;
    var denumire = Math.random().toString(36).substring(7).toString();
    this.state = {
      maxDepth: 0,
      level: 0,
      isCutting: false,
      focusedKey: null,
      dataLoaded2: false,
      componentaTree: null,
      selectedKeys: [],
      inputsProiecte1: [
        { key: "Denumire", keyBaza: "denumire" },
        { key: "Cerinta", keyBaza: "comentariu" },
        {
          key: "NivelProiect",
          keyBaza: "id_nivel_proiect",
          tip: "selectbox",
          lista: this.$f7.data.NivelProiect,
        },
        {
          key: "Categorie",
          keyBaza: "id_tip_proiect",
          tip: "selectbox",
          lista: this.categorii,
        },

        {
          key: "Client",
          keyBaza: "id_client",
          tip: "selectbox",
          lista: this.clienti,
        },
        {
          key: "Deadline",
          keyBaza: "deadline",
          tip: "datebox",
        },
        {
          key: "ProiectRef",
          keyBaza: "reF_PROIECT",
          lista: this.$f7.data.Proiecte,
          tip: "selectbox",
        },
        {
          key: "IdPrioritate",
          keyBaza: "id_prioritate",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
        },
        {
          key: "Departament",
          keyBaza: "id_departament",
          tip: "selectbox",
          lista: this.departamente,
        },
        {
          key: "Atasament",
          keyBaza: "atasamentDeTest",
          tip: "filepicker",
        },
        {
          key: "Manager",
          keyBaza: "id_manager",
          tip: "selectbox",
          lista: this.$f7.data.Salariati //aici
        }
      ],
      inputsProiecte2: [
        { key: "Denumire", keyBaza: "denumire" },
        { key: "Cerinta", keyBaza: "comentariu" },
        {
          key: "NivelProiect",
          keyBaza: "id_nivel_proiect",
          tip: "selectbox",
          lista: this.$f7.data.NivelProiect,
        },
        {
          key: "Categorie",
          keyBaza: "id_tip_proiect",
          tip: "selectbox",
          lista: this.categorii,
        },

        {
          key: "Client",
          keyBaza: "id_client",
          tip: "selectbox",
          lista: this.clienti,
        },
        {
          key: "Deadline",
          keyBaza: "deadline",
          tip: "datebox",
        },
        {
          key: "IdPrioritate",
          keyBaza: "id_prioritate",
          tip: "selectbox",
          lista: this.$f7.data.Prioritati,
        },
        {
          key: "Departament",
          keyBaza: "id_departament",
          tip: "selectbox",
          lista: this.departamente,
        },
        {
          key: "Atasament",
          keyBaza: "atasamentDeTest",
          tip: "filepicker",
        },
      ],
      denumire: denumire,
      dataSourceProiecte: props.dataSourceProiecte,
      dataSourceProiecteFull: props.dataSourceProiecte,
      dataSourceProiecteDeFolosit: props.dataSourceProiecte,
      selectedGrid: "0",
      linkApi: "/api/Proiecte",
    };
  }
  componentDidMount = () => {
    document.addEventListener("keyup", (e) => {
      if (this.state.isCutting && e.keyCode === 27) {
        this.setState({
          isCutting: false,
        });
      }
    });

    this.GetValues();
  };

  /**
   * @description Se verifica stadiul proiectului curent selectat
   * @date 07/04/2022
   * @function
   * @param {*} idProiect id-ul proiectului selectat
   * @memberof ProiecteGrid
   */
  VerificareStadiuProiect = (idProiect) => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL +
          "/api/StadiuProiecte/GetStadiuProiect/" +
          idProiect
      )
      .then((response) => {
        if (response.data.length > 0) {
          this.$f7.data.router.navigate("/popupDinamic/", {
            props: {
              component: <StadiuProiectePage idProiect={idProiect} />,
            },
          });
        } else {
          this.setState({ dataLoaded: true }, () => {
            this.$f7.methods.openNotification(
              "Nu exista date pentru calculare."
            );
          });
        }
      })
      .catch((error) => {
        this.setState(
          {
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Se actualizeaza inregistrarea selectata in baza de date
   * @date 07/04/2022
   * @param {*} obj obiect cu valorile noi ale proiectului
   * @param {*} id id-ul proiectului selectat
   * @function
   * @memberof ProiecteGrid
   */
  UpdateValueProiect = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + "/api/Proiecte/GetPROIECTE/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });
        var url = this.$f7.data.baseURL + "/api/Proiecte/PutPROIECTE/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Aproba sau nu un proiect
   * @date 07/04/2022
   * @param {*} Proiect proiectul selectat in datagrid
   * @param {*} aproba true daca se aproba proiectul sau false daca nu se aproba
   * @memberof ProiecteGrid
   */
  AprobaRevoca = (Proiect, aproba) => {
    let urlUpdate =
      this.$f7.data.baseURL + "/api/Proiecte/PutPROIECTE/" + Proiect.iD_PROIECT;
    var nuExista = false;
    if (Proiect.id_dezvoltator === this.$f7.data.user.iD_SALARIAT) {
      Proiect.aprobat_d = aproba ? 1 : 0;
    } else if (Proiect.id_manager === this.$f7.data.user.iD_SALARIAT) {
      Proiect.aprobat_m = aproba ? 1 : 0;
    } else if (Proiect.id_arbitru === this.$f7.data.user.iD_SALARIAT) {
      Proiect.aprobat_a = aproba ? 1 : 0;
    } else if (Proiect.id_sefproiect === this.$f7.data.user.iD_SALARIAT) {
      Proiect.aprobat_s = aproba ? 1 : 0;
    } else {
      nuExista = true;
    }
    if (!nuExista) {
      axios({
        data: Proiect,
        headers: { "Content-Type": "application/json" },
        method: "put",
        url: urlUpdate,
      })
        .then((responsHid) => {})
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      this.$f7.methods.openNotification(
        "Nu sunteti alocat acestui task.",
        "Ups"
      );
    }
  };

  /**
   * @description Se iau valorile pentru interfata curenta de intretinere in functie de checkboxurile bifate,daca se iau si taskuri,si cele ascunse,si cele arhivate etc
   * @function
   * @date 07/04/2022
   * @memberof ProiecteGrid
   */
  GetValues = () => {
    this.setState({ dataLoaded: false }, () => {
      this.$f7.methods
        .getProiecte(
          this.$f7.data.user.iD_SALARIAT,
          5,
          this.props.checkedTaskuri === true ? 1 : 0,
          this.props.checkedAscunse === true ? 1 : 0,
          this.props.checkedArhivate === true ? 1 : 0,
          1
        )
        .then((responseProiecte) => {
          var dataProiecte = responseProiecte.data;
          var arrProiecte = [{ key: "Fara", value: null }];
          dataProiecte = dataProiecte.filter((item) => item.folosit === true);
          if (this.props.checkedSelectate) {
            var selectate = this.state.componentaTree.getSelectedRowKeys("all");
            dataProiecte = dataProiecte.filter((r) => {
              return selectate.includes(r.iD_PROIECT);
            });
          }

          dataProiecte.map((data) => {
            var toAdd = { key: "", value: 0 };
            toAdd.value = data.iD_PROIECT;
            toAdd.key = data.denumire;
            arrProiecte.push(toAdd);
            return false;
          });

          var f = { data: dataProiecte };
          var baza = this.$f7.methods.reconstructieBaza(f, [
            { key: "Id_Proiect", tip: "number" },
            { key: "Ref_Proiect", tip: "number" },
            { key: "Denumire" },
            { key: "Id_Prioritate", tip: "number" },
            { key: "Timp_Plan_D", tip: "number" },
            { key: "Timp_Plan_A", tip: "number" },
            { key: "Timp_Plan_SP", tip: "number" },
            { key: "Timp_Plan_M", tip: "number" },
            { key: "Chelt_Sal", tip: "number" },
            { key: "Buget", tip: "number" },
            { key: "Ordine", tip: "number" },
            { key: "Comentariu" },
            { key: "Data_Creare", tip: "datebox" },
            { key: "Id_Client", tip: "number" },
            { key: "Deadline", tip: "datebox" },
            { key: "Id_Tip_Proiect", tip: "number" },
            { key: "Id_Nivel_Proiect", tip: "number" },
            { key: "Id_Operator_Adaugare" },
            { key: "Id_Stadiu", tip: "number" },
            { key: "Id_Departament", tip: "number" },
          ]);

          var g = { data: responseProiecte.data };
          var bazaFull = this.$f7.methods.reconstructieBaza(g, [
            { key: "Id_Proiect", tip: "number" },
            { key: "Ref_Proiect", tip: "number" },
            { key: "Denumire" },
            { key: "Id_Prioritate", tip: "number" },
            { key: "Timp_Plan_D", tip: "number" },
            { key: "Timp_Plan_A", tip: "number" },
            { key: "Timp_Plan_SP", tip: "number" },
            { key: "Timp_Plan_M", tip: "number" },
            { key: "Chelt_Sal", tip: "number" },
            { key: "Buget", tip: "number" },
            { key: "Ordine", tip: "number" },
            { key: "Comentariu" },
            { key: "Data_Creare", tip: "datebox" },
            { key: "Id_Client", tip: "number" },
            { key: "Id_Tip_Proiect", tip: "number" },
            { key: "Id_Nivel_Proiect", tip: "number" },
            { key: "Id_Operator_Adaugare" },
            { key: "Id_Stadiu", tip: "number" },
            { key: "Id_Departament", tip: "number" },
          ]);

          baza.map((r) => {
            var id = r.Id_Client;
            var client = this.$f7.data.Firme.find((f) => {
              return f.value === id;
            });
            if (client !== undefined) {
              r.Client = client.key;
            }

            if (r.Id_Operator_Adaugare !== null) {
              r.Id_Operator_Adaugare = this.$f7.data.Salariati.find(
                (f) => f.value === r.Id_Operator_Adaugare
              ).key;
            }
            return null;
          });
          bazaFull.map((r) => {
            var id = r.Id_Client;
            var client = this.$f7.data.Firme.find((f) => {
              return f.value === id;
            });
            if (client !== undefined) {
              r.Client = client.key;
            }

            if (r.Id_Operator_Adaugare !== null) {
              r.Id_Operator_Adaugare = this.$f7.data.Salariati.find(
                (f) => f.value === r.Id_Operator_Adaugare
              ).key;
            }
            return null;
          });
          var max = this.$f7.methods.maxDepth(
            -1,
            baza,
            "Id_Proiect",
            "Ref_Proiect"
          );
          console.log("max", max);
          this.setState(
            {
              dataLoaded: true,
              dataSourceProiecte: [...baza],
              dataSourceProiecteFull: [...bazaFull],
              dataSourceProiecteDeFolosit: [...baza],
              maxDepth: max,
            },
            () => {}
          );
        })
        .catch((e) => {
          this.$f7.methods.openNotification("Eroare refresh", "Ups");
          console.log("refresh error:", e);
        });
    });
  };

  /**
   * @description Se parcurge treelistul proiectelor si se seteaza la final numarul maxim de nivele
   * @function
   * @date 07/04/2022
   * @memberof ProiecteGrid
   */
  getLevels = () => {
    return new Promise((resolve, reject) => {
      var level = 0;
      if (this.state.componentaTree) {
        this.state.componentaTree.forEachNode((nod) => {
          if (nod.level > level) {
            level = nod.level;
          }
        });
      }
      this.setState({ maxDepth: level + 1 }, resolve(true));
    });
  };

  /**
   * @description Deschide treelistul la un anumit nivel
   * @function
   * @date 07/04/2022
   * @param {*} level nivelul la care se deschide treelistul
   * @memberof ProiecteGrid
   */
  expandToLevel = (level) => {
    this.setState({ level: level }, () => {
      this.$f7.preloader.show();
      this.state.componentaTree.forEachNode((nod) => {
        if (nod.level < this.state.level) {
          this.state.componentaTree.expandRow(nod.data.Id_Proiect);
        } else {
          this.state.componentaTree.collapseRow(nod.data.Id_Proiect);
        }
      });
      this.$f7.preloader.hide();
    });
  };

  /**
   * @description Se modifica timpii din milisecunde in ore is zile
   * @function
   * @date 07/04/2022
   * @param {*} tip
   * @memberof ProiecteGrid
   */
  ChangeTimpi = (tip) => {
    const arr = [...this.state.dataSourceProiecteDeFolosit];
    var newArr = [];
    arr.map((r) => {
      newArr.push({ ...r });
      return null;
    });
    if (tip === "Ore") {
      newArr.map((r) => {
        r["Timp_Plan_D"] = r["Timp_Plan_D"] / 60;
        r["Timp_Plan_A"] = r["Timp_Plan_A"] / 60;
        r["Timp_Plan_SP"] = r["Timp_Plan_SP"] / 60;
        r["Timp_Plan_M"] = r["Timp_Plan_M"] / 60;
        return null;
      });
    } else if (tip === "Zile") {
      newArr.map((r) => {
        r["Timp_Plan_D"] = r["Timp_Plan_D"] / (60 * 24);
        r["Timp_Plan_A"] = r["Timp_Plan_A"] / (60 * 24);
        r["Timp_Plan_SP"] = r["Timp_Plan_SP"] / (60 * 24);
        r["Timp_Plan_M"] = r["Timp_Plan_M"] / (60 * 24);
        return null;
      });
    }
    this.setState({ dataSourceProiecte: newArr });
  };

  /**
   * @description Se adauga un document la proiectul selectat
   * @date 07/04/2022
   * @function
   * @param {*} data informatiile documentului
   * @memberof ProiecteGrid
   */
  AddValueDocumente = (data) => {
    var url = this.$f7.data.baseURL + "/api/Documente/PostDOCUMENTE";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "POST",
      url: url,
    })
      .then((response) => {
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Se adauga o noua inregistrare de tip proiect
   * @function
   * @date 07/04/2022
   * @param {*} data informatiile noii inregistrari(proiect)
   * @param {*} continutAtasament atasamentul,undefined in cazul in care nu exista atasament
   * @memberof ProiecteGrid
   */
  AddValue = (data, continutAtasament) => {
    var url = this.$f7.data.baseURL + "/api/Proiecte/PostPROIECTE";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.$f7.methods.openNotification("Adaugat cu success");

        if (continutAtasament !== false) {
          var obj = {};
          obj.Id_Proiect = response.data.iD_PROIECT;
          obj.dATA_MODIF = moment().format();
          obj.Nume_Fisier = continutAtasament.name;
          obj.Nume_Path = continutAtasament.name;
          obj.sAL_AUTOR = this.$f7.data.user.iD_SALARIAT;
          obj.iD_TIP_DOCUMENT = 1;
          obj.id_initial = null;
          obj.dir_parinte = null;
          obj.ref_document = null;
          obj.continut = continutAtasament.continut;
          this.AddValueDocumente(obj);
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  findSubItems = (idParinte, datasource) => {
    var output = [];
    var listaCopii = datasource.filter(
      (item) => item.Ref_Proiect === idParinte
    );
    if (listaCopii.length > 0) {
      listaCopii.map((item) => {
        output.push(item.Id_Proiect);
        var newArr = this.findSubItems(item.Id_Proiect, datasource);
        if (newArr.length > 0) {
          newArr.map((elem) => {
            output.push(elem);
            return null;
          });
        }
        return null;
      });
    }
    return output;
  };

  /**
   * @description Ascunde proiectul selectat
   * @date 07/04/2022
   * @function
   * @param {*} menuClickEvent eventul de click
   * @param {*} itemMenuClickEvent eventul de click al itemului
   * @param {*} ascunde daca sa se ascunda sau nu(true false)
   * @memberof ProiecteGrid
   */
  ascundeSelectia = (menuClickEvent, itemMenuClickEvent, ascunde) => {
    let url =
      this.$f7.data.baseURL + "/api/TreeListProiecteOption/AscundeArata";

    function filterNodes(id) {
      let node = menuClickEvent.component.getNodeByKey(id);

      if (!node.hasChildren) {
        idsForUpdate.push(id);
      } else {
        idsWithChildren.push(id);
      }
    }
    if (ascunde) {
      let idsForUpdate = [];
      let idsWithChildren = [];

      if (this.state.selectedKeys.length > 0) {
        for (let id of this.state.selectedKeys) {
          filterNodes(id);
        }
      } else {
        filterNodes(menuClickEvent.row.key);
      }

      if (idsForUpdate.length > 0) {
        let data = {
          lista: idsForUpdate,
          idSalariat: this.$f7.data.user.iD_SALARIAT,
          idDestinatie: null,
          actiune: false,
        };

        axios({
          data: data,
          headers: { "Content-Type": "application/json" },
          method: "post",
          url: url,
        })
          .then((response) => {
            let dataAscunse = response.data;
            let message = "";
            let ascunseMessage =
              "<div style='font-weight: bold; margin-bottom: 16px;'>Ascuns(e) cu success!</div>";
            let dejaAscunseMessage =
              "<div style='text-decoration: underline;'>Urmatoarele sunt deja ascunse:</div><ul style='margin-top: 0;'>";
            let ascunse = false;
            let dejaAscunse = false;

            for (let item of dataAscunse) {
              if (item.modificat === 0) {
                dejaAscunseMessage +=
                  "<li>" + item.iD_PROIECT + " - " + item.denumire + "</li>";
                dejaAscunse = true;
              } else {
                ascunse = true;
              }
            }
            dejaAscunseMessage += "</ul>";

            message += ascunse ? ascunseMessage : "";
            message += dejaAscunse ? dejaAscunseMessage : "";

            if (idsWithChildren.length > 0) {
              message +=
                "<div style='text-decoration: underline;'>Urmatoarele au copii si nu se pot ascunde direct:</div><ul style='margin-top: 0;'>";
              for (let id of idsWithChildren) {
                let node = menuClickEvent.component.getNodeByKey(id);

                message +=
                  "<li>" + node.key + " - " + node.data.Denumire + "</li>";
              }
              message +=
                "</ul><small>Poti incerca ascunderea copiiilor acestor proiecte prin selectie multipla, daca acestia nu au copii la randul lor.</small>";

              idsWithChildren = [];
            }

            idsForUpdate = [];
            this.$f7.methods.openNotification(message, "Cronos");
            this.GetValues();
          })
          .catch((error) => {
            console.log("error ascunde:", error);
            this.$f7.methods.openNotification("Eroare la ascundere", "Cronos");
            this.GetValues();
          });
      } else {
        if (idsWithChildren.length > 0) {
          let message =
            "<div style='text-decoration: underline;'>Urmatoarele au copii si nu se pot ascunde direct:</div><ul style='margin-top: 0;'>";
          for (let id of idsWithChildren) {
            let node = menuClickEvent.component.getNodeByKey(id);

            message += "<li>" + node.key + " - " + node.data.Denumire + "</li>";
          }
          message +=
            "</ul><small>Poti incerca ascunderea copiiilor acestor proiecte prin selectie multipla, daca acestia nu au copii la randul lor.</small>";

          this.$f7.methods.openNotification(message);
          idsWithChildren = [];
        }
      }
    } else {
      if (this.state.selectedKeys.length > 0) {
        let data = {
          lista: this.state.selectedKeys,
          idSalariat: this.$f7.data.user.iD_SALARIAT,
          idDestinatie: null,
          actiune: true,
        };

        axios({
          data: data,
          headers: { "Content-Type": "application/json" },
          method: "post",
          url: url,
        })
          .then((response) => {
            let dataAduse = response.data;
            let message = "";
            let aduseMessage =
              "<div style='font-weight: bold; margin-bottom: 16px;'>Aduse din ascunse cu success!</div>";
            let dejaAduseMessage =
              "<div style='text-decoration: underline;'>Urmatoarele sunt deja aduse:</div><ul style='margin-top: 0;'>";
            let aduse = false;
            let dejaAduse = false;

            for (let item of dataAduse) {
              if (item.modificat === 0) {
                dejaAduseMessage +=
                  "<li>" + item.iD_PROIECT + " - " + item.denumire + "</li>";
                dejaAduse = true;
              } else {
                aduse = true;
              }
            }
            dejaAduseMessage += "</ul>";

            message += aduse ? aduseMessage : "";
            message += dejaAduse ? dejaAduseMessage : "";

            this.$f7.methods.openNotification(message, "Cronos");
            this.GetValues();
          })
          .catch((error) => {
            console.log("error aduse:", error);
            this.$f7.methods.openNotification("Eroare la aducere", "Cronos");
            this.GetValues();
          });
      } else {
        let data = {
          lista: [menuClickEvent.row.key],
          idSalariat: this.$f7.data.user.iD_SALARIAT,
          idDestinatie: null,
          actiune: true,
        };

        axios({
          data: data,
          headers: { "Content-Type": "application/json" },
          method: "post",
          url: url,
        })
          .then((response) => {
            let dataAduse = response.data;
            let message = "";
            if (dataAduse[0].modificat === 1) {
              message = "Adus din ascunse cu success!";
            } else {
              message = "Este deja adus!";
            }

            this.$f7.methods.openNotification(message, "Cronos");
            this.GetValues();
          })
          .catch((error) => {
            console.log("error aduse:", error);
            this.$f7.methods.openNotification("Eroare la aducere", "Cronos");
            this.GetValues();
          });
      }
    }
  };

  /**
   * @description Copiaza proiectele selectat la nivelul curent
   * @function
   * @date 07/04/2022
   * @param {*} elements elementele de copiat(proiectele)
   * @param {*} idParinte id-ul parintelui la care se vor adauga aceste elemente
   * @memberof ProiecteGrid
   */
  paste = (elements, idParinte) => {
    var pro = this.state.dataSourceProiecte.find((r) => {
      return r.Id_Proiect === idParinte;
    });
    if (pro) {
      this.copiere(elements, pro.Ref_Proiect)
        .then((r) => {
          this.$f7.methods.openNotification("Modificat cu success");
          this.setState({ selectedKeys: [], focusedKey: null }, () => {
            this.GetValues();
          });
        })
        .catch((err) => {
          this.$f7.methods.openNotification("Eroare:", err);
        });
    }
  };

  /**
   * @description Se copiaza elementele si se adauga ca copii elementului selectat
   * @date 07/04/2022
   * @param {*} elements elementele care se copiaza
   * @param {*} idParinte id-ul la care sa vor adauga elementele
   * @memberof ProiecteGrid
   */
  pasteCopii = (elements, idParinte) => {
    var pro = this.state.dataSourceProiecte.find((r) => {
      return r.Id_Proiect === idParinte;
    });
    if (pro) {
      this.copiere(elements, pro.Id_Proiect)
        .then((r) => {
          this.$f7.methods.openNotification("Modificat cu success");
          this.setState({ selectedKeys: [], focusedKey: null }, () => {
            this.GetValues();
          });
        })
        .catch((err) => {
          this.$f7.methods.openNotification("Eroare:", err);
        });
    }
  };

  /**
   * @description Functia de copiere a proiectelor
   * @date 07/04/2022
   * @param {*} arr arrayul proiectelor de copiat
   * @param {*} refId id-ul de referinta
   * @memberof ProiecteGrid
   */
  copiere = (arr, refId) => {
    return new Promise((resolve, reject) => {
      var obj = { idArr: arr, refId: refId };
      var url = this.$f7.data.baseURL + this.state.linkApi + "/Copiere";
      axios({
        data: obj,
        headers: { "Content-Type": "application/json" },
        method: "post",
        url: url,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * @description Arhiveaza sau dezarhiveaza proiectele selectate
   * @date 07/04/2022
   * @param {*} menuClickEvent evenimentul clickului de pe meniu
   * @param {*} itemMenuClickEvent evenimentul clickului de pe item-ul meniului
   * @param {*} arhiveaza true daca arhiveaza sau false daca dezarhiveaza
   * @param {*} refId id-ul de referinta
   * @memberof ProiecteGrid
   */
  arhiveazaDezarhiveaza = (
    menuClickEvent,
    itemMenuClickEvent,
    arhiveaza,
    refId
  ) => {
    let url =
      this.$f7.data.baseURL +
      "/api/TreeListProiecteOption/ArhiveazaDezarhiveaza";

    function getAllChildren(id) {
      let output = [];

      let node = menuClickEvent.component.getNodeByKey(id);
      if (node.hasChildren) {
        for (let child of node.children) {
          let recArr = getAllChildren(child.key);

          if (recArr.length > 0) {
            for (let id of recArr) {
              output.push(id);
            }
          }
        }
      }
      output.push(id);

      return output;
    }
    let idsForUpdate = [];
    var proiecteForUpdate = [];
    var idsLeft = [];
    var proiecteLeft = [];
    if (this.state.selectedKeys.length > 0) {
      for (let id of this.state.selectedKeys) {
        let subItems = getAllChildren(id);

        for (let subItem of subItems) {
          idsForUpdate.push(subItem);
          var pro = this.state.dataSourceProiecte.find((f) => {
            return f.Id_Proiect === subItem;
          });
          if (pro) {
            proiecteForUpdate.push(pro);
          }
        }
      }
    } else {
      let subItems = getAllChildren(menuClickEvent.row.key);

      for (let subItem of subItems) {
        idsForUpdate.push(subItem);
        var pro1 = this.state.dataSourceProiecte.find((f) => {
          return f.Id_Proiect === subItem;
        });
        if (pro1) {
          proiecteForUpdate.push(pro1);
        }
      }
    }

    //Parcurg proiectele selectate,pentru fiecare fac un filter pe refId atat in datasource cat si in idsForUpdate si verif

    proiecteForUpdate.map((pro) => {
      if (
        this.state.dataSourceProiecte.filter((r) => {
          return r.Ref_Proiect === pro.Ref_Proiect;
        }).length ===
        proiecteForUpdate.filter((r) => {
          return r.Ref_Proiect === pro.Ref_Proiect;
        }).length
      ) {
        if (
          !idsForUpdate.includes(pro.Ref_Proiect) &&
          !idsLeft.includes(pro.Ref_Proiect)
        ) {
          var proLeft = this.state.dataSourceProiecte.find((r) => {
            return r.Id_Proiect === pro.Ref_Proiect;
          });
          if (proLeft) {
            idsLeft.push(proLeft.Id_Proiect);
            proiecteLeft.push(proLeft);
          }
        }
      }
      return null;
    });

    function functie() {
      if (arhiveaza) {
        let data = {
          lista: idsForUpdate,
          idSalariat: this.$f7.data.user.iD_SALARIAT,
          idDestinatie: null,
          actiune: false,
        };

        axios({
          data: data,
          headers: { "Content-Type": "application/json" },
          method: "post",
          url: url,
        })
          .then((response) => {
            let dataArhivate = response.data;
            let message = "";
            let arhivateMessage =
              "<div style='font-weight: bold; margin-bottom: 16px;'>Arhivat(e) cu success!</div>";
            let dejaArhivateMessage =
              "<div style='text-decoration: underline;'>Urmatoarele sunt deja arhivate:</div><ul style='margin-top: 0;'>";
            let arhivate = false;
            let dejaArhivate = false;

            for (let item of dataArhivate) {
              if (item.modificat === 0) {
                dejaArhivateMessage +=
                  "<li>" + item.iD_PROIECT + " - " + item.denumire + "</li>";
                dejaArhivate = true;
              } else {
                arhivate = true;
              }
            }
            dejaArhivateMessage += "</ul>";

            message += arhivate ? arhivateMessage : "";
            message += dejaArhivate ? dejaArhivateMessage : "";

            idsForUpdate = [];

            this.$f7.methods.openNotification(message, "Cronos");
            this.setState({ focusedKey: refId }, () => {
              this.GetValues();
            });
          })
          .catch((error) => {
            console.log("error arhivare", error);
            idsForUpdate = [];
            this.$f7.methods.openNotification("Eroare la arhivare");
          });
      } else {
        let data = {
          lista: idsForUpdate,
          idSalariat: this.$f7.data.user.iD_SALARIAT,
          idDestinatie: null,
          actiune: true,
        };
        axios({
          data: data,
          headers: { "Content-Type": "application/json" },
          method: "post",
          url: url,
        })
          .then((response) => {
            let dataDezarhivate = response.data;
            console.log("response dezarhivate:", dataDezarhivate);

            let message = "";
            let dezarhivateMessage =
              "<div style='font-weight: bold; margin-bottom: 16px;'>Adus(e) din arhivate cu success!</div>";
            let dejaDezarhivateMessage =
              "<div style='text-decoration: underline;'>Urmatoarele sunt deja aduse din arhivate:</div><ul style='margin-top: 0;'>";
            let dezarhivate = false;
            let dejaDezarhivate = false;

            for (let item of dataDezarhivate) {
              if (item.modificat === 0) {
                dejaDezarhivateMessage +=
                  "<li>" + item.iD_PROIECT + " - " + item.denumire + "</li>";
                dejaDezarhivate = true;
              } else {
                dezarhivate = true;
              }
            }
            dejaDezarhivateMessage += "</ul>";

            message += dezarhivate ? dezarhivateMessage : "";
            message += dejaDezarhivate ? dejaDezarhivateMessage : "";

            idsForUpdate = [];

            this.$f7.methods.openNotification(message, "Cronos");
            this.setState({ focusedKey: refId }, () => {
              this.GetValues();
            });
          })
          .catch((error) => {
            console.log("error dezarhivare", error);
            idsForUpdate = [];
            this.$f7.methods.openNotification("Eroare la aducere din arhivate");
          });
      }
    }

    let func = functie.bind(this);
    if (idsLeft.length > 0) {
      this.$f7.dialog
        .create({
          title: "Confirmare",
          text: "Doriti sa adaugati si parintii ce lipsesc din select?",

          buttons: [
            {
              bold: true,
              color: "red",
              text: "Nu",
              onClick: () => {
                func();
              },
            },
            {
              bold: true,
              color: "green",
              text: "Da",
              onClick: () => {
                idsLeft.map((f) => {
                  idsForUpdate.push(f);
                  return null;
                });
                func();
              },
            },
          ],
        })
        .open();
    } else {
      func();
    }
  };

  /**
   * @description Sterge proiectul si copiii proiectului selectat
   * @date 07/04/2022
   * @param {*} menuClickEvent evenimentul clickului pe meniului
   * @param {*} itemMenuClickEvent evenimentul clickului pe item meniu
   * @memberof ProiecteGrid
   */
  stergeProiecte = (menuClickEvent, itemMenuClickEvent) => {
    this.$f7.dialog.confirm(
      "Sunteti sigur ca doriti sa stergeti?",
      "Confirmare",
      () => {
        let idsForUpdate = [];

        function getAllChildren(id) {
          let output = [];

          let node = menuClickEvent.component.getNodeByKey(id);
          if (node.hasChildren) {
            for (let child of node.children) {
              let recArr = getAllChildren(child.key);

              if (recArr.length > 0) {
                for (let id of recArr) {
                  output.push(id);
                }
              }
            }
          }
          output.push(id);

          return output;
        }

        if (this.state.selectedKeys.length > 0) {
          for (let id of this.state.selectedKeys) {
            let subItems = getAllChildren(id);

            for (let subItem of subItems) {
              idsForUpdate.push(subItem);
            }
          }
        } else {
          let subItems = getAllChildren(menuClickEvent.row.key);

          for (let subItem of subItems) {
            idsForUpdate.push(subItem);
          }
        }

        let url = this.$f7.data.baseURL + "/api/TreeListProiecteOption/Sterge";
        let data = {
          lista: idsForUpdate,
          idSalariat: this.$f7.data.user.iD_SALARIAT,
          idDestinatie: null,
          actiune: null,
        };

        axios({
          data: data,
          headers: { "Content-Type": "application/json" },
          method: "post",
          url: url,
        })
          .then((response) => {
            let dataSterse = response.data;

            let message = "";
            let sterseMessage =
              "<div style='font-weight: bold; margin-bottom: 16px;'>Sters(e) cu success!</div>";
            let dejaSterseMessage =
              "<div style='text-decoration: underline;'>Urmatoarele sunt deja sterse:</div><ul style='margin-top: 0;'>";
            let sterse = false;
            let dejaSterse = false;

            for (let item of dataSterse) {
              if (item.modificat === 0) {
                dejaSterseMessage +=
                  "<li>" + item.iD_PROIECT + " - " + item.denumire + "</li>";
                dejaSterse = true;
              } else {
                sterse = true;
              }
            }
            dejaSterseMessage += "</ul>";

            message += sterse ? sterseMessage : "";
            message += dejaSterse ? dejaSterseMessage : "";

            message +=
              "<small>Atentie: Daca unul (sau mai multe) elemente din selectie raman in treelist dupa terminarea operatiri de stergere, acele elemente sunt arhivate si nu se pot sterge direct! Incercati stergerea lor dupa dezarhivare.</small>";

            idsForUpdate = [];

            this.$f7.methods.openNotification(message, "Cronos");
            this.GetValues();
          })
          .catch((error) => {
            console.log("error stergere", error);
            idsForUpdate = [];
            this.$f7.methods.openNotification("Eroare la stergere");
          });
      }
    );
  };

  render() {
    return this.state.dataLoaded ? (
      <>
        {this.state.maxDepth > 0 ? (
          <Range
            label={true}
            className={"rangeDepth"}
            min={0}
            max={this.state.maxDepth}
            step={1}
            value={this.state.level}
            scale={true}
            scaleSteps={this.state.maxDepth}
            scaleSubSteps={1}
            onRangeChanged={(e) => {
              this.expandToLevel(e);
            }}
          />
        ) : null}
        <TreeList
          filterMode={"fullBranch"}
          allowColumnResizing={true}
          focusedRowEnabled={true}
          focusedRowKey={this.state.focusedKey}
          onInitialized={(e) => {
            this.setState({
              componentaTree: e.component,
            });
          }}
          renderAsync={true}
          onRowPrepared={(e) => {
            var elemData = e.data;

            if (elemData !== undefined) {
              if (e.data["Deadline"] !== null) {
                let bgRowColor = "";
                let currentDate = moment();
                let tableDate = moment(e.data["Deadline"]);
                if (elemData.Id_Nivel_Proiect === 4) {
                  if (currentDate < tableDate) {
                    if (currentDate >= tableDate.clone().subtract(2, "w")) {
                      //bgRowColor = "#d96548";
                      bgRowColor = "rgba(201, 71, 38, 0.3)";
                    } else if (
                      currentDate >= tableDate.clone().subtract(1, "months")
                    ) {
                      //bgRowColor = "#eb917a";
                      bgRowColor = "rgba(235, 145, 122, 0.3)";
                    } else if (
                      currentDate >=
                      tableDate.clone().subtract(1, "months").subtract(2, "w")
                    ) {
                      //bgRowColor = "#ffbea8";
                      bgRowColor = "rgba(255, 190, 168, 0.3)";
                    } else if (
                      currentDate >= tableDate.clone().subtract(2, "months")
                    ) {
                      //bgRowColor = "#ffd1a8";
                      bgRowColor = "rgba(255, 209, 168, 0.3)";
                    } else if (
                      currentDate >= tableDate.clone().subtract(3, "months")
                    ) {
                      //bgRowColor = "#ffe8a8";
                      bgRowColor = "rgba(255, 232, 168, 0.3)";
                    } else if (
                      currentDate >= tableDate.clone().subtract(4, "months")
                    ) {
                      //bgRowColor = "#e9ffa8";
                      bgRowColor = "rgba(233, 255, 168, 0.3)";
                    } else {
                      bgRowColor = "";
                    }
                  } else {
                    //bgRowColor = "#c94726";
                    bgRowColor = "rgba(201, 71, 38, 0.3)";
                  }
                  e.rowElement.style.backgroundColor = bgRowColor;
                }
              }

              if (
                elemData.Id_Tip_Proiect !== 57 &&
                elemData.Id_Tip_Proiect !== 58
              ) {
                if (elemData.Id_Tip_Proiect === 59) {
                  //ESTE MODUL
                  e.rowElement.style.color = "#cc7904";
                  e.rowElement.style.fontWeight = "bold";
                } else if (elemData.Id_Nivel_Proiect === 1) {
                  //ESTE PROIECT

                  e.rowElement.style.color = "#1785a3";
                  e.rowElement.style.fontWeight = "bold";
                } else if (elemData.Id_Nivel_Proiect === 2) {
                  //ESTE SUBPROIECT
                  e.rowElement.style.color = "#24bf89";
                  e.rowElement.style.fontWeight = "bold";
                } else if (elemData.Id_Nivel_Proiect === 4) {
                  var tar = e.rowElement;
                  if (elemData.Id_Stadiu === 2) {
                    tar.classList.add("taskactiv");
                  } else if (elemData.Id_Stadiu !== 2) {
                    if (tar.classList.contains("taskactiv")) {
                      tar.classList.remove("taskactiv");
                    }
                  }

                  if (elemData.Id_Stadiu === 3) {
                    tar.classList.add("taskoprit");
                  } else if (elemData.Id_Stadiu !== 3) {
                    if (tar.classList.contains("taskoprit")) {
                      tar.classList.remove("taskoprit");
                    }
                  }

                  if (elemData.Id_Stadiu === 6) {
                    tar.classList.add("taskinchis");
                  } else if (elemData.Id_Stadiu !== 6) {
                    if (tar.classList.contains("taskinchis")) {
                      tar.classList.remove("taskinchis");
                    }
                  }
                  if (elemData.Id_Stadiu === 44) {
                    tar.classList.add("taskpropus");
                  } else if (elemData.Id_Stadiu !== 44) {
                    if (tar.classList.contains("taskpropus")) {
                      tar.classList.remove("taskpropus");
                    }
                  }
                }
              } else {
                if (elemData.Id_Tip_Proiect === 57) {
                  e.rowElement.style.color = "#8f57ad";

                  e.rowElement.style.fontWeight = "bold";
                } else if (elemData.Id_Tip_Proiect === 58) {
                  e.rowElement.style.color = "#c259b4";
                  e.rowElement.style.fontWeight = "bold";
                }
              }
            }
          }}
          onContextMenuPreparing={(e) => {
            // Add a custom menu item

            var idProiect = e.row.data.Id_Proiect;
            var refProiect = e.row.data.Ref_Proiect;
            this.setState({ focusedKey: idProiect });
            var Proiect = null;
            var ProiectList = this.$f7.data.allProiecte.filter((pro) => {
              return pro.iD_PROIECT === idProiect;
            });
            if (ProiectList.length > 0) {
              Proiect = ProiectList[0];
            }

            if (this.state.selectedKeys.length > 0) {
              this.$f7.data.ATidProiect = this.state.selectedKeys;
            } else {
              this.$f7.data.ATidProiect = idProiect;
            }

            this.$f7.data.ATProiect = Proiect;

            e.items = [
              {
                text: "Adauga task in pozitia curenta",
                onItemClick: (f) => {
                  this.$f7.data.router.navigate("/popupAddEdit/", {
                    props: {
                      allRequired: true,
                      inputs: this.state.inputsProiecte2,
                      id: "Id",
                      buttons: [
                        {
                          key: "Salveaza",
                          onSubmit: (state, den) => {
                            var eveniment = { ...state.editedItem };
                            eveniment = this.$f7.methods.restructurareBaza(
                              eveniment,
                              this.state.inputsProiecte2,
                              "keyBaza"
                            );
                            eveniment.isArhivat =
                              eveniment.isArhivat === true ? 1 : 0;
                            eveniment.isHidden =
                              eveniment.isHidden === true ? 1 : 0;
                            eveniment.aprobat_d =
                              eveniment.aprobat_d === true ? 1 : 0;
                            eveniment.aprobat_a =
                              eveniment.aprobat_a === true ? 1 : 0;
                            eveniment.aprobat_s =
                              eveniment.aprobat_s === true ? 1 : 0;
                            eveniment.aprobat_m =
                              eveniment.aprobat_m === true ? 1 : 0;
                            //eveniment.reF_PROIECT = eveniment.reF_PROIECT;
                            eveniment.taskcomplete = 0;
                            eveniment.folosit = true;
                            eveniment.data_start = moment().format();
                            eveniment.data_creare = moment().format();
                            eveniment.valoare_minima = 0;
                            eveniment.valoare_maxima = 0;
                            eveniment.iD_STADIU = 1;
                            eveniment["reF_PROIECT"] = idProiect;
                            eveniment.id_operator_adaugare =
                              this.$f7.data.user.iD_SALARIAT;
                            if (eveniment.id_tip_proiect === 4) {
                              eveniment.task = true;
                            }
                            var file = eveniment.atasamentDeTest;
                            if (
                              file !== null &&
                              file !== "" &&
                              file !== undefined
                            ) {
                              var numeFisier = file.name;
                              var reader = new FileReader();
                              reader.readAsDataURL(file);
                              reader.onload = () => {
                                var res = reader.result;
                                var continutAtasament = {
                                  continut: res.split(",")[1],
                                  name: numeFisier,
                                };
                                this.AddValue(eveniment, continutAtasament);
                              };
                              reader.onerror = (error) => {
                                console.log("Error: ", error);
                              };
                            } else {
                              this.AddValue(eveniment, false);
                            }
                            //this.$f7.popup.close(den)
                            this.$f7.panel.close(den);
                          },
                        },
                      ],
                    },
                  });
                },
              },
              {
                text: "Adauga task undeva in arbore",
                onItemClick: (f) => {
                  this.$f7.data.router.navigate("/popupAddEdit/", {
                    props: {
                      allRequired: true,
                      inputs: this.state.inputsProiecte1,
                      id: "Id",
                      buttons: [
                        {
                          key: "Salveaza",
                          onSubmit: (state, den) => {
                            var eveniment = { ...state.editedItem };
                            eveniment = this.$f7.methods.restructurareBaza(
                              eveniment,
                              this.state.inputsProiecte1,
                              "keyBaza"
                            );
                            eveniment.isArhivat =
                              eveniment.isArhivat === true ? 1 : 0;
                            eveniment.isHidden =
                              eveniment.isHidden === true ? 1 : 0;
                            eveniment.aprobat_d =
                              eveniment.aprobat_d === true ? 1 : 0;
                            eveniment.aprobat_a =
                              eveniment.aprobat_a === true ? 1 : 0;
                            eveniment.aprobat_s =
                              eveniment.aprobat_s === true ? 1 : 0;
                            eveniment.aprobat_m =
                              eveniment.aprobat_m === true ? 1 : 0;
                            //eveniment.reF_PROIECT = eveniment.reF_PROIECT;
                            eveniment.taskcomplete = 0;
                            eveniment.folosit = true;
                            eveniment.data_start = moment().format();
                            eveniment.data_creare = moment().format();
                            eveniment.valoare_minima = 0;
                            eveniment.valoare_maxima = 0;
                            eveniment.iD_STADIU = 1;
                            eveniment.id_operator_adaugare =
                              this.$f7.data.user.iD_SALARIAT;
                            if (eveniment.id_tip_proiect === 4) {
                              eveniment.task = true;
                            }
                            var file = eveniment.atasamentDeTest;
                            if (
                              file !== null &&
                              file !== "" &&
                              file !== undefined
                            ) {
                              var numeFisier = file.name;
                              var reader = new FileReader();
                              reader.readAsDataURL(file);
                              reader.onload = () => {
                                var res = reader.result;
                                var continutAtasament = {
                                  continut: res.split(",")[1],
                                  name: numeFisier,
                                };
                                this.AddValue(eveniment, continutAtasament);
                              };
                              reader.onerror = (error) => {
                                console.log("Error: ", error);
                              };
                            } else {
                              this.AddValue(eveniment, false);
                            }
                            //this.$f7.popup.close(den)
                            this.$f7.panel.close(den);
                          },
                        },
                      ],
                    },
                  });
                },
              },
              {
                text: "Modificare proiect",
                onItemClick: (f) => {
                  var proiect = this.$f7.data.allProiecte.find((r) => {
                    return r.iD_PROIECT === idProiect;
                  });
                  if (proiect !== undefined) {
                    this.$f7.data.Proiect = proiect;
                    this.$f7.views.main.router.navigate("/popupDinamic/", {
                      props: {
                        title: "Info",
                        disableClose: false,
                        component: (
                          <div style={{ height: 500 }}>
                            <View
                              removeElements={true}
                              componentCache={false}
                              style={{ minHeight: "50vh" }}
                              onViewInit={(e) => {
                                this.$f7.data.routerDreapta = e.router;
                              }}
                              id="oreLucratePeZiSheet"
                              name="oreLucratePeZiSheet"
                              url={"/agendaGridMainDreaptaActivitate/"}
                            />
                          </div>
                        ),
                      },
                    });
                  }
                },
              },
              {
                text: "Atasare faze",
                onItemClick: (f) => {
                  this.$f7.data.router.navigate("/popupDinamic/", {
                    props: {
                      component: <AtasareFazePage idProiect={idProiect} />,
                    },
                  });
                },
              },
              {
                text: "Comasare functionalitati",
                onItemClick: (f) => {
                  let listaIdSurse = [];
                  let idDestinatie = e.row.key;

                  if (this.state.selectedKeys.length > 0) {
                    if (this.state.selectedKeys.includes(idDestinatie)) {
                      this.$f7.methods.openNotification(
                        "Taskul destinatie nu poate fi si sursa in acelasi timp!"
                      );
                    } else {
                      for (let id of this.state.selectedKeys) {
                        let node = e.component.getNodeByKey(id);

                        if (!node.hasChildren) {
                          listaIdSurse.push(id);
                        }
                      }

                      if (listaIdSurse.length > 0) {
                        this.$f7.methods.openNotification(
                          "Taskurile care au copii nu vor aparea in fereastra urmatoare!",
                          "Atentie"
                        );
                        this.$f7.data.router.navigate("/popupDinamic/", {
                          props: {
                            component: (
                              <CTMain
                                listaSurse={listaIdSurse}
                                idDestinatie={idDestinatie}
                                listaProiecte={this.$f7.data.allProiecte}
                              />
                            ),
                          },
                        });
                      } else {
                        this.$f7.methods.openNotification(
                          "Toate taskurile selectate au copii."
                        );
                      }
                    }
                  } else {
                    this.$f7.methods.openNotification(
                      "Pentru a comasa functionalitatile trebuie sa selectati cel putin un task."
                    );
                  }
                },
              },
              {
                text: "Alocare taskuri",
                onItemClick: (f) => {
                  this.$f7.data.router.navigate("/popupDinamic/", {
                    props: {
                      onPopupClose: (r) => {
                        this.$f7.methods.refreshStanga();
                      },
                      component: (
                        <ATMain Proiect={Proiect} idProiect={idProiect} />
                      ),
                    },
                  });
                },
              },
              {
                text: "Refresh",
                onItemClick: (f) => {
                  this.GetValues();
                },
              },
              {
                text: "Aproba",
                onItemClick: (f) => {
                  this.AprobaRevoca(Proiect, true);
                },
              },
              {
                text: "Revoca",
                onItemClick: (f) => {
                  this.AprobaRevoca(Proiect, false);
                },
              },
              {
                text: "Minute",
                onItemClick: (f) => {
                  this.ChangeTimpi("Minute");
                },
              },
              {
                text: "Ore",
                onItemClick: (f) => {
                  this.ChangeTimpi("Ore");
                },
              },
              {
                text: "Zile",
                onItemClick: (f) => {
                  this.ChangeTimpi("Zile");
                },
              },
              {
                text: "Anulare filtre",
                onItemClick: (f) => {
                  this.$f7.data.homePage.setState(
                    {
                      checkedTaskuri: false,
                      checkedSubtotaluri: false,
                      checkedSelectate: false,
                      checkedAscunse: false,
                      checkedArhivate: false,
                    },
                    () => {
                      this.GetValues();
                    }
                  );
                },
              },
              {
                text: "Ascunde selectia",
                onItemClick: (f) => {
                  this.ascundeSelectia(e, f, true);
                },
              },
              {
                text: "Arhiveaza selectia",
                onItemClick: (f) => {
                  this.arhiveazaDezarhiveaza(e, f, true, refProiect);
                },
              },
              {
                text: "Adu din ascunse",
                onItemClick: (f) => {
                  this.ascundeSelectia(e, f, false);
                },
              },
              {
                text: "Adu din arhiva",
                onItemClick: (f) => {
                  this.arhiveazaDezarhiveaza(e, f, false, refProiect);
                },
              },
              {
                text: this.state.isCutting ? "Anulare Cut" : "Cut",
                onItemClick: (f) => {
                  this.setState({
                    isCutting: this.state.isCutting ? false : true,
                  });
                },
              },
              {
                disabled: !this.state.isCutting,
                text: "Insereaza pe acelasi nivel",
                onItemClick: (f) => {
                  this.setState({ isCutting: false }, () => {
                    this.paste(this.state.selectedKeys, this.state.focusedKey);
                  });
                },
              },
              {
                disabled: !this.state.isCutting,
                text: "Insereaza ca copii",
                onItemClick: (f) => {
                  this.setState({ isCutting: false }, () => {
                    this.pasteCopii(
                      this.state.selectedKeys,
                      this.state.focusedKey
                    );
                  });
                },
              },

              {
                text: "Sterge",
                onItemClick: (f) => {
                  this.stergeProiecte(e, f);
                },
              },
              {
                text: "Export Excel",
                onItemClick: (fg) => {
                  var treeList = this.state.componentaTree;
                  var arrDenum = [
                    "Denumire",
                    "Id_Prioritate",
                    "Timp_Plan_D",
                    "Timp_Plan_SP",
                    "Timp_Plan_M",
                    "Timp_Plan_A",
                    "Chelt_Sal",
                    "Buget",
                    "Ordine",
                  ];
                  var arrFinal = [];
                  treeList.forEachNode((node) => {
                    var arrLocal = [];
                    arrDenum.map((denumire) => {
                      var data = node.data;
                      var val =
                        data[denumire] !== null
                          ? data[denumire].toString()
                          : "";
                      var valFinal = "";
                      if (node.level > 0 && denumire === "Denumire") {
                        for (let i = 0; i < node.level; i++) {
                          valFinal = valFinal + "   ";
                        }
                      }
                      valFinal = valFinal + val;
                      var toAdd = { v: valFinal, t: "" };
                      arrLocal.push(toAdd);
                      return null;
                    });
                    arrFinal.push(arrLocal);
                  });
                  var f =
                    document.getElementsByClassName("dx-treelist-table")[1];
                  var table = TableExport(f, { exportButtons: false });
                  var exportData = table.getExportData();
                  Object.keys(exportData).map((den) => {
                    var xlsxData = exportData[den].xlsx;
                    table.export2file(
                      arrFinal,
                      xlsxData.mimeType,
                      xlsxData.filename,
                      xlsxData.fileExtension,
                      xlsxData.merges,
                      xlsxData.RTL,
                      xlsxData.sheetname
                    );
                    return null;
                  });
                },
              },
              {
                text: "Calculeaza procent realizare",
                onItemClick: (f) => {
                  this.VerificareStadiuProiect(idProiect);
                },
              },
              {
                text: "Proces verbal pe client si perioada",
                onItemClick: (f) => {
                  this.$f7.data.router.navigate("/PVSheetClientPerioadaSheet/");
                },
              },
              {
                disabled: !(
                  e.row.data.Id_Nivel_Proiect !== undefined &&
                  e.row.data.Id_Nivel_Proiect !== null &&
                  [1, 2].includes(e.row.data.Id_Nivel_Proiect)
                ),
                text: "Import taskuri Excel",
                onItemClick: (f) => {
                  const idParinte = e.row.key;
                  const idDepartament = e.row.data.Id_Departament;

                  this.$f7.data.router.navigate("/popupDinamic/", {
                    props: {
                      onPopupClose: () => {},
                      title: "Import taskuri din Excel",
                      component: (
                        <ImportExcel
                          idParinte={idParinte}
                          idDepartament={idDepartament}
                        />
                      ),
                    },
                  });
                },
              },
            ];
          }}
          onRowClick={(e) => {
            if (e) {
              if (e.node) {
                if (e.node.data) {
                  var pro = e.node.data;
                  this.setState({ focusedKey: pro.Id }, () => {
                    if (this.props.GetValuesDreapta !== undefined) {
                      this.props.GetValuesDreapta(pro);
                    }
                  });
                }
              }
            }
          }}
          autoNavigateToFocusedRow={false}
          onSelectionChanged={(e) => {
            this.setState({
              selectedKeys: e.selectedRowKeys,
              componentaTree: e.component,
            });
            if (e.currentSelectedRowKeys !== undefined) {
              if (e.currentSelectedRowKeys.length > 0) {
                var pro = e.selectedRowsData.find((r) => {
                  return r.Id_Proiect === e.currentSelectedRowKeys[0];
                });
                this.setState({ focusedKey: pro.Id }, () => {
                  if (this.props.GetValuesDreapta !== undefined) {
                    this.props.GetValuesDreapta(pro);
                  }
                });
              }
            }
          }}
          dataStructure={"plain"}
          virtualModeEnabled={true}
          selectedRowKeys={this.state.selectedKeys}
          showColumnHeaders={true}
          showColumnLines={true}
          height={window.innerHeight - 70}
          rootValue={-1}
          dataSource={this.state.dataSourceProiecte}
          showBorders={true}
          keyExpr={"Id_Proiect"}
          parentIdExpr={"Ref_Proiect"}
          id={"TreeListProiecteModuleStanga"}
          repaintChangesOnly={true}>
          <Column
            width={300}
            dataField={"Denumire"}
            caption={"Den"}
            visible={true}
          />
          <Column
            width={150}
            dataField={"Comentariu"}
            caption={"Cerinta"}
            visible={true}
          />
          <Column
            dataField={"Data_Creare"}
            caption={"DataCreare"}
            visible={true}
          />
          <Column dataField={"Client"} caption={"Client"} visible={true} />
          <Column dataField={"Timp_Plan_D"} caption={"Dezv"} visible={false} />
          <Column
            dataField={"Timp_Plan_SP"}
            caption={"SefProiect"}
            visible={false}
          />
          <Column
            dataField={"Timp_Plan_M"}
            caption={"Manager"}
            visible={false}
          />
          <Column
            dataField={"Timp_Plan_A"}
            caption={"Arbitru"}
            visible={false}
          />
          <Column
            dataField={"Chelt_Sal"}
            caption={"CheltSal"}
            visible={false}
          />
          <Column dataField={"Buget"} caption={"Buget"} visible={false} />
          <Column
            dataField={"Id_Operator_Adaugare"}
            caption={"Adaugat_De"}
            visible={true}
          />

          <SearchPanel visible={true} width={250} />
          <Selection mode={"multiple"} recursive={true} />
          <ColumnChooser enabled={true} mode={"select"} />

          <RowDragging
            onReorder={(e) => {
              let visibleRows = e.component.getVisibleRows(),
                sourceData = e.itemData,
                targetData = visibleRows[e.toIndex].data,
                dropInside = e.dropInsideItem;

              var elements = [];
              elements.push(sourceData.Id_Proiect);

              var refId;

              if (dropInside) {
                refId = targetData.Id_Proiect;
              } else {
                refId = targetData.Ref_Proiect;
              }

              if (
                sourceData.Ref_Proiect !== refId &&
                sourceData.Id_Proiect !== refId
              ) {
                this.copiere(elements, refId)
                  .then((r) => {
                    this.$f7.methods.openNotification("Modificat cu success");
                    this.GetValues();
                  })
                  .catch((err) => {
                    this.$f7.methods.openNotification("Eroare:", err);
                  });
              }
            }}
            allowDropInsideItem={true}
            allowReordering={true}
            showDragIcons={true}
          />
        </TreeList>
      </>
    ) : (
      <div
        style={{
          position: "absolute",
          width: "40px",
          height: "40px",
          top: "calc(50vh - 65px)",
          left: "50%",
          transform: "translateX(-50%)",
        }}>
        <Preloader size={40} color={"var(--f7-theme-color)"} />
      </div>
    );
  }
}
export default ProiecteGrid;
