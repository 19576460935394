export const Proiecte = [
    {
        id: 1,
        denumire: "ATS",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 60,
        sefproiect: 0,
        super: 0,
        manag: 0,
        rlz: 60,
        chelt_sal: 4000,
        buget: 5000,
        ordine: 0,
        tip_exc: 0,
    },
    {
        id: 2,
        denumire: "Parc auto",
        id_prioritate: 0,
        c_arh: "C",
        dezv: 60,
        sefproiect: 0,
        super: 44,
        manag: 0,
        rlz: 60,
        chelt_sal: 4000,
        buget: 540,
        ordine: 0,
        tip_exc: 3,
    },
    {
        id: 3,
        denumire: "Registre Agricole",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 124,
        sefproiect: 0,
        super: 0,
        manag: 16,
        rlz: 60,
        chelt_sal: 4000,
        buget: 5000,
        ordine: 0,
        tip_exc: 0,
    },
    {
        id: 4,
        denumire: "Februarie 2018",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 644,
        sefproiect: 0,
        super: 0,
        manag: 0,
        rlz: 110,
        chelt_sal: 4000,
        buget: 5000,
        ordine: 1,
        tip_exc: 0,
    },
    {
        id: 5,
        denumire: "Martie 2018",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 60,
        sefproiect: 225,
        super: 5,
        manag: 0,
        rlz: 60,
        chelt_sal: 4020,
        buget: 5000,
        ordine: 0,
        tip_exc: 0,
    },
    {
        id: 6,
        denumire: "Aprilie 2018",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 60,
        sefproiect: 225,
        super: 5,
        manag: 0,
        rlz: 60,
        chelt_sal: 4020,
        buget: 5000,
        ordine: 0,
        tip_exc: 0,
    },
    {
        id: 7,
        denumire: "Mai 2018",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 60,
        sefproiect: 225,
        super: 5,
        manag: 0,
        rlz: 60,
        chelt_sal: 4020,
        buget: 5000,
        ordine: 0,
        tip_exc: 0,
    },
    {
        id: 8,
        denumire: "Iunie 2018",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 60,
        sefproiect: 225,
        super: 5,
        manag: 0,
        rlz: 60,
        chelt_sal: 4020,
        buget: 5000,
        ordine: 0,
        tip_exc: 0,
    },
    {
        id: 9,
        denumire: "Iulie 2018",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 60,
        sefproiect: 225,
        super: 5,
        manag: 0,
        rlz: 60,
        chelt_sal: 4020,
        buget: 5000,
        ordine: 0,
        tip_exc: 0,
    },
    {
        id: 10,
        denumire: "August 2018",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 60,
        sefproiect: 225,
        super: 5,
        manag: 0,
        rlz: 60,
        chelt_sal: 4020,
        buget: 5000,
        ordine: 0,
        tip_exc: 0,
    },
    {
        id: 11,
        denumire: "Septembrie 2018",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 60,
        sefproiect: 225,
        super: 5,
        manag: 0,
        rlz: 60,
        chelt_sal: 4020,
        buget: 5000,
        ordine: 0,
        tip_exc: 0,
    },
    {
        id: 12,
        denumire: "Octombrie 2018",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 60,
        sefproiect: 225,
        super: 5,
        manag: 0,
        rlz: 60,
        chelt_sal: 4020,
        buget: 5000,
        ordine: 0,
        tip_exc: 0,
    },
    {
        id: 13,
        denumire: "Noiembrie 2018",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 60,
        sefproiect: 225,
        super: 5,
        manag: 0,
        rlz: 60,
        chelt_sal: 4020,
        buget: 5000,
        ordine: 0,
        tip_exc: 0,
    },
];

export const SubProiecte = [
    {
        id: 1,
        denumire: "Subproiect 1",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 60,
        sefproiect: 0,
        super: 0,
        manag: 0,
        rlz: 60,
        chelt_sal: 4000,
        buget: 5000,
        ordine: 0,
        tip_exc: 0,
    },
    {
        id: 2,
        denumire: "Subproiect 2",
        id_prioritate: 0,
        c_arh: "C",
        dezv: 60,
        sefproiect: 0,
        super: 44,
        manag: 0,
        rlz: 60,
        chelt_sal: 4000,
        buget: 540,
        ordine: 0,
        tip_exc: 3,
    },
    {
        id: 3,
        denumire: "Subproiect 3",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 124,
        sefproiect: 0,
        super: 0,
        manag: 16,
        rlz: 60,
        chelt_sal: 4000,
        buget: 5000,
        ordine: 0,
        tip_exc: 0,
    },
    {
        id: 4,
        denumire: "Subproiect 4",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 644,
        sefproiect: 0,
        super: 0,
        manag: 0,
        rlz: 110,
        chelt_sal: 4000,
        buget: 5000,
        ordine: 1,
        tip_exc: 0,
    },
    {
        id: 5,
        denumire: "Subproiect 5",
        id_prioritate: 10,
        c_arh: "C",
        dezv: 60,
        sefproiect: 225,
        super: 5,
        manag: 0,
        rlz: 60,
        chelt_sal: 4020,
        buget: 5000,
        ordine: 0,
        tip_exc: 0,
    },
];
export const Salariat = [
    {
        id: 1,
        NumeSalariat: "Cristea Ciprian",
        MomentInceput: "2020-04-13T09:05:43+03:00",
        MomentSfarsit: "2020-04-13T09:05:43+03:00",
        TimpLucratInOre: 2,
        Unitati: 1,
        Operatie: "Rezolvat",
        Client: "ATS",
        ProcentDeRealizare: 100,
        Stadiu: "Rezolvat",
        Comentariu: "Am rezolvat",
        ComentariuPlus: "",
    },
    {
        id: 2,
        NumeSalariat: "Cristea Ciprian",
        MomentInceput: "2020-04-13T09:05:43+03:00",
        MomentSfarsit: "2020-04-13T09:05:43+03:00",
        TimpLucratInOre: 1,
        Unitati: 1,
        Operatie: "Rezolvat",
        Client: "Ad",
        ProcentDeRealizare: 70,
        Stadiu: "Rezolvat",
        Comentariu: "Am rezolvat",
        ComentariuPlus: "",
    },
    {
        id: 3,
        NumeSalariat: "Cristea Ciprian",
        MomentInceput: "2020-04-13T09:05:43+03:00",
        MomentSfarsit: "2020-04-13T09:05:43+03:00",
        TimpLucratInOre: 1,
        Unitati: 3,
        Operatie: "Rezolvat",
        Client: "ATS",
        ProcentDeRealizare: 10,
        Stadiu: "Rezolvat",
        Comentariu: "Am rezolvat",
        ComentariuPlus: "",
    },
];

export const Mesaje = [
    {
        id: 1,
        Expeditor: "Ciprian",
        Destinatar: "Alexandru",
        Data: "2020-04-13T09:05:43+03:00",
        Subiect: "Subiectul meu",
        Atasament: "",
    },
    {
        id: 2,
        Expeditor: "Ciprian",
        Destinatar: "Alexandru2",
        Data: "2020-04-13T09:05:43+03:00",
        Subiect: "Subiectul meu",
        Atasament: "",
    },
    {
        id: 3,
        Expeditor: "Ciprian",
        Destinatar: "Alexandru",
        Data: "2020-04-13T09:05:43+03:00",
        Subiect: "Subiectul meu",
        Atasament: "",
    },
];

export const Cheltuieli = [
    {
        TipCheltuiala: 0,
        Denumire: "Salariu 1",
    },
    {
        TipCheltuiala: 0,
        Denumire: "Salariu 2",
    },
];

export const Venituri = [
    {
        TipVenit: 0,
        Denumire: "Salariu 1",
    },
    {
        TipVenit: 0,
        Denumire: "Salariu 2",
    },
];

export const CheltuieliEstimat = [
    {
        Suma: 50,
        DeLaData: "2020-04-13T09:05:43+03:00",
        PanaLaData: "2020-04-13T09:05:43+03:00",
    },
];
export const CheltuieliRealizat = [
    {
        Suma: 60,
        DeLaData: "2020-04-13T09:05:43+03:00",
        PanaLaData: "2020-04-13T09:05:43+03:00",
    },
];
export const VenituriRealizat = [
    {
        Suma: 40,
        DeLaData: "2020-04-13T09:05:43+03:00",
        PanaLaData: "2020-04-13T09:05:43+03:00",
    },
];
export const VenituriEstimat = [
    {
        Suma: 30,
        DeLaData: "2020-04-13T09:05:43+03:00",
        PanaLaData: "2020-04-13T09:05:43+03:00",
    },
];
export const SalariatCheltuieliVenituri = [
    {
        NumeSalariat: "Ciprian",
        Procent: 50,
        Suma: 200,
    },
];
export const Delegatii = [
    {
        Salariat: "Ciprian",
        DataDelegatie: "2020-04-13T09:05:43+03:00",
        NumarDelegatie: 3,
        Destinatie: "Dambovita",
        SoferSauNu: true,
        BenzinaOras: "Dambovita",
        BenzinaSuma: 30,
        Plecare: "2020-04-13T09:05:43+03:00",
        Sosire: "2020-04-13T09:05:43+03:00",
    },
];
export const tipZiLibera = [
    { key: "Concediu odihna", value: 0 },
    { key: "Concediu odihna 2", value: 1 },
    { key: "Concediu odihna 3", value: 2 },
    { key: "Concediu odihna 4", value: 3 },
];

export const tipSelectbox = [
    { key: "Valoarea 0", value: 0 },
    { key: "Valoarea 1", value: 1 },
    { key: "Valoarea 2", value: 2 },
    { key: "Valoarea 3", value: 3 },
    { key: "Valoarea 4", value: 4 },
    { key: "Valoarea 5", value: 5 },
    { key: "Valoarea 6", value: 6 },
    { key: "Valoarea 7", value: 7 },
    { key: "Valoarea 8", value: 8 },
    { key: "Valoarea 9", value: 9 },
    { key: "Valoarea 10", value: 10 },
    { key: "Valoarea 11", value: 11 },
    { key: "Valoarea 12", value: 12 },
    { key: "Valoarea 13", value: 13 },
    { key: "Valoarea 14", value: 14 },
    { key: "Valoarea 15", value: 15 },
];

export const tipStadiu = [
    { key: "deschis", value: "deschis" },
    { key: "in lucru", value: "in lucru" },
    { key: "inchis", value: "inchis" },
];

export const tipAngajati = [
    { key: "Ciprian", value: "Ciprian" },
    { key: "Alexandru", value: "Alexandru" },
    { key: "Tibi", value: "Tibi" },
];

export const tipClienti = [
    { key: "Client 1", value: "Client 1" },
    { key: "Client 2", value: "Client 2" },
    { key: "Client 3", value: "Client 3" },
];

export const Concedii = [
    {
        TipZiLibera: 0,
        Salariat: 1,
        DataInceput: "2020-04-13T09:05:43+03:00",
        DataSfarsit: "2020-04-14T09:05:43+03:00",
        NumarOre: 24,
        Comentariu: "comentariu",
        Stadiu: 1,
    },
];
export const Pontaj = [
    {
        Ziua: "2020-04-13T09:05:43+03:00",
        Start: "2020-04-13T09:05:43+03:00",
        Termen: "2020-04-13T09:05:43+03:00",
        Denumire: 0,
        Stadiu: 0,
        Intrare: "2020-04-13T09:05:43+03:00",
        Iesire: "2020-04-13T09:05:43+03:00",
        Asumat: 0,
    },
];

export const CodProiect = [
    {
        Autor: "Alexandru",
        Data: "2020-04-13T09:05:43+03:00",
        LinkFisier: "link",
        Comentariu: "Un comentariu",
    },
    {
        Autor: "Ciprian",
        Data: "2020-04-13T09:05:43+03:00",
        LinkFisier: "link",
        Comentariu: "Un comentariu",
    },
    {
        Autor: "Tibi",
        Data: "2020-04-13T09:05:43+03:00",
        LinkFisier: "link",
        Comentariu: "Un comentariu",
    },
];

export const Cerinte = [
    {
        Autor: "Alexandru",
        Data: "2020-04-13T09:05:43+03:00",
        NumeFisier: "Numele fisierului",
        Functionalitate: "Toate functionalitatile",
        Stadiu: "inchis",
        Comentariu: "Un comentariu",
    },
    {
        Autor: "Ciprian",
        Data: "2020-04-13T09:05:43+03:00",
        NumeFisier: "Numele fisierului",
        Functionalitate: "Toate functionalitatile",
        Stadiu: "deschis",
        Comentariu: "Un comentariu",
    },
    {
        Autor: "Tibi",
        Data: "2020-04-13T09:05:43+03:00",
        NumeFisier: "Numele fisierului",
        Functionalitate: "Toate functionalitatile",
        Stadiu: "in lucru",
        Comentariu: "Un comentariu",
    },
];

export const Verificari = [
    {
        Autor: "Alexandru",
        DataTestarii: "2020-04-13T09:05:43+03:00",
        NumeFisierRezultat: "Numele fisierului rezultat",
        Functionalitate: "Functionalitate testata",
        Comentariu: "Un comentariu",
    },
    {
        Autor: "Ciprian",
        DataTestarii: "2020-04-13T09:05:43+03:00",
        NumeFisierRezultat: "Numele fisierului rezultat",
        Functionalitate: "Functionalitate testata",
        Comentariu: "Un comentariu",
    },
    {
        Autor: "Tibi",
        DataTestarii: "2020-04-13T09:05:43+03:00",
        NumeFisierRezultat: "Numele fisierului rezultat",
        Functionalitate: "Functionalitate testata",
        Comentariu: "Un comentariu",
    },
];

export const Documentare = [
    {
        Autor: "Alexandru",
        Data: "2020-04-13T09:05:43+03:00",
        NumeFisier: "Numele fisierului",
        TipDocument: "Tipul documentului",
        Comentariu: "Un comentariu",
    },
    {
        Autor: "Ciprian",
        Data: "2020-04-13T09:05:43+03:00",
        NumeFisier: "Numele fisierului",
        TipDocument: "Tipul documentului",
        Comentariu: "Un comentariu",
    },
    {
        Autor: "Tibi",
        Data: "2020-04-13T09:05:43+03:00",
        NumeFisier: "Numele fisierului",
        TipDocument: "Tipul documentului",
        Comentariu: "Un comentariu",
    },
];

export const EroriRezolvari = [
    {
        Problema: "Problema 1",
        DataSemnalarii: "2020-04-13T09:05:43+03:00",
        NumarInregistrare: 1255,
        ReceptionatDe: "Ciprian",
        ReferintaAltaProblema: 0,
        LinkDocument: "link la document",
        Responsabil: "Ciprian",
        TimpEstimat: 5,
        Client: 1,
        Comentariu: "Un comentariu",
        DataRezolvarii: "2020-04-13T09:05:43+03:00",
        RezolvatDe: "Ciprian",
        TimpRezolvare: 6,
        Stadiu: "in lucru",
        CosturiTotale: 500,
        Resurse: "resurse folosite",
    },
    {
        Problema: "Problema 2",
        DataSemnalarii: "2020-04-13T09:05:43+03:00",
        NumarInregistrare: 1256,
        ReceptionatDe: "Alexandru",
        ReferintaAltaProblema: 0,
        LinkDocument: "link la document",
        Responsabil: "Alexandru",
        TimpEstimat: 2,
        Client: 1,
        Comentariu: "Un comentariu",
        DataRezolvarii: "2020-04-13T09:05:43+03:00",
        RezolvatDe: "Alexandru",
        TimpRezolvare: 3,
        Stadiu: "inchis",
        CosturiTotale: 200,
        Resurse: "resurse folosite",
    },
    {
        Problema: "Problema 3",
        DataSemnalarii: "2020-04-13T09:05:43+03:00",
        NumarInregistrare: 1257,
        ReceptionatDe: "Tibi",
        ReferintaAltaProblema: 0,
        LinkDocument: "link la document",
        Responsabil: "Tibi",
        TimpEstimat: 10,
        Client: 1,
        Comentariu: "Un comentariu",
        DataRezolvarii: "2020-04-13T09:05:43+03:00",
        RezolvatDe: "Tibi",
        TimpRezolvare: 15,
        Stadiu: "deschis",
        CosturiTotale: 1000,
        Resurse: "resurse folosite",
    },
];

export const Help = [
    {
        Autor: "Alexandru",
        Data: "2020-04-13T09:05:43+03:00",
        LinkFisier: "link la fisier",
        Comentariu: "un comentariu",
    },
    {
        Autor: "Ciprian",
        Data: "2020-04-13T09:05:43+03:00",
        LinkFisier: "link la fisier",
        Comentariu: "un comentariu",
    },
];

export const ToateDoc = [
    {
        TipDocument: "tipul documentului",
        Problema: "problema semnalata",
        Proiect: 2,
        Autor: "Alexandru",
        DataSemnalarii: "2020-04-13T09:05:43+03:00",
        DataModificarii: "2020-04-13T09:05:43+03:00",
        CaleFisier: "calea catre fisier",
        Comentariu: "un comentariu",
        Legatura: "legatura",
    },
];

export const istoricModificari = [
    {
        Data: "2020-04-13T09:05:43+03:00",
        Salariat: "Alexandru",
        TipModificare: 3,
        Denumire: 5,
        Comentariu: "un comentariu",
        Parinte: 1,
        Stadiu: "in lucru",
        Folosit: false,
        Task: true,
        Arhivat: false,
        Ascuns: false,
        Prioritate: 10,
        NivelProiect: 25,
        Operatiune: 4,
        TipProiect: 6,
        Clienti: "nume client",
        DataCreare: "2020-04-13T09:05:43+03:00",
        DataStart: "2020-04-13T09:05:43+03:00",
        DataLimita: "2020-04-13T09:05:43+03:00",
        DataInceputLucru: "2020-04-13T09:05:43+03:00",
        DataFinal: "2020-04-13T09:05:43+03:00",
        TimpEstimatDezvoltator: 150,
        TimpEstimatSefProiect: 140,
        TimpEstimatArbitru: 150,
        TimpEstimatManager: 160,
        ObservatieStadiu: "observatii",
        UserImport: "import ceva",
        Responsabil: "Ciprian",
        Dezvoltator: "Ciprian",
        Arbitru: "Alexandru",
        SefProiect: "Tibi",
        Manager: "Tibi",
        Departament: 12,
        DataImport: "2020-04-13T09:05:43+03:00",
        Tip: "tipul",
        Termen: "2020-04-13T09:05:43+03:00",
        Facturat: false,
        Venit: 1500,
        ValoreMinima: 1500,
        ValoreMaxima: 2500,
        Buget: 2000,
        BugetTimp: 150,
        ProcentRisc: 15,
        CostEstimat: 1500,
        CostRealCalculat: 1684,
        DirectorRadacina: "directorul radacina",
        Nivel: 4,
        AbrobatDezvoltator: true,
        AprobatSefProiect: true,
        AprobatArbitru: true,
        AprobatManager: true,
        CheltuieliSalariale: 8000,
        TaskSimilar: 8,
    },
];

export const FazeSiRoluri = [
    {
        Denumire: "testare modificari",
        Fezabilitate: false,
        Juridic: false,
        Analiza: true,
        Proiecte: false,
        Codare: false,
        CodReview: false,
        Testare: false,
        Debugging_Service: false,
        Documentare: false,
        Implementare: false,
        Mentenanta: false,
        Upgrade: false,
    },
    {
        Denumire: "denumirea proiectului 1",
        Fezabilitate: true,
        Juridic: false,
        Analiza: false,
        Proiecte: false,
        Codare: false,
        CodReview: false,
        Testare: false,
        Debugging_Service: false,
        Documentare: false,
        Implementare: false,
        Mentenanta: false,
        Upgrade: false,
    },
    {
        Denumire: "denumirea proiectului 2",
        Fezabilitate: true,
        Juridic: true,
        Analiza: false,
        Proiecte: false,
        Codare: true,
        CodReview: false,
        Testare: false,
        Debugging_Service: false,
        Documentare: false,
        Implementare: true,
        Mentenanta: false,
        Upgrade: false,
    },
];

export const FazeProiect = [
    {
        Proiect: "testare modificari",

        D_Fezabilitate: false,
        SP_Fezabilitate: false,
        S_Fezabilitate: false,
        M_Fezabilitate: false,

        D_Juridic: false,
        SP_Juridic: false,
        S_Juridic: false,
        M_Juridic: false,

        D_Analiza: true,
        SP_Analiza: true,
        S_Analiza: true,
        M_Analiza: false,

        D_Proiecte: false,
        SP_Proiecte: false,
        S_Proiecte: false,
        M_Proiecte: false,

        D_Codare: false,
        SP_Codare: false,
        S_Codare: false,
        M_Codare: false,

        D_CodReview: false,
        SP_CodReview: false,
        S_CodReview: false,
        M_CodReview: false,

        D_Testare: false,
        SP_Testare: false,
        S_Testare: false,
        M_Testare: false,

        D_Debugging_Service: false,
        SP_Debugging_Service: false,
        S_Debugging_Service: false,
        M_Debugging_Service: false,

        D_Documentare: false,
        SP_Documentare: false,
        S_Documentare: false,
        M_Documentare: false,

        D_Implementare: false,
        SP_Implementare: false,
        S_Implementare: false,
        M_Implementare: false,

        D_Mentenanta: false,
        SP_Mentenanta: false,
        S_Mentenanta: false,
        M_Mentenanta: false,

        D_Upgrade: false,
        SP_Upgrade: false,
        S_Upgrade: false,
        M_Upgrade: false,
    },
    {
        Proiect: "denumirea proiectului 1",

        D_Fezabilitate: true,
        SP_Fezabilitate: true,
        S_Fezabilitate: true,
        M_Fezabilitate: true,

        D_Juridic: false,
        SP_Juridic: false,
        S_Juridic: false,
        M_Juridic: false,

        D_Analiza: false,
        SP_Analiza: false,
        S_Analiza: false,
        M_Analiza: false,

        D_Proiecte: false,
        SP_Proiecte: false,
        S_Proiecte: false,
        M_Proiecte: false,

        D_Codare: false,
        SP_Codare: false,
        S_Codare: false,
        M_Codare: false,

        D_CodReview: false,
        SP_CodReview: false,
        S_CodReview: false,
        M_CodReview: false,

        D_Testare: false,
        SP_Testare: false,
        S_Testare: false,
        M_Testare: false,

        D_Debugging_Service: false,
        SP_Debugging_Service: false,
        S_Debugging_Service: false,
        M_Debugging_Service: false,

        D_Documentare: false,
        SP_Documentare: false,
        S_Documentare: false,
        M_Documentare: false,

        D_Implementare: false,
        SP_Implementare: false,
        S_Implementare: false,
        M_Implementare: false,

        D_Mentenanta: false,
        SP_Mentenanta: false,
        S_Mentenanta: false,
        M_Mentenanta: false,

        D_Upgrade: false,
        SP_Upgrade: false,
        S_Upgrade: false,
        M_Upgrade: false,
    },
    {
        Proiect: "denumirea proiectului 2",

        D_Fezabilitate: false,
        SP_Fezabilitate: true,
        S_Fezabilitate: false,
        M_Fezabilitate: true,

        D_Juridic: false,
        SP_Juridic: false,
        S_Juridic: true,
        M_Juridic: false,

        D_Analiza: false,
        SP_Analiza: false,
        S_Analiza: false,
        M_Analiza: false,

        D_Proiecte: false,
        SP_Proiecte: false,
        S_Proiecte: false,
        M_Proiecte: false,

        D_Codare: true,
        SP_Codare: false,
        S_Codare: true,
        M_Codare: false,

        D_CodReview: false,
        SP_CodReview: false,
        S_CodReview: false,
        M_CodReview: false,

        D_Testare: false,
        SP_Testare: false,
        S_Testare: false,
        M_Testare: false,

        D_Debugging_Service: false,
        SP_Debugging_Service: false,
        S_Debugging_Service: false,
        M_Debugging_Service: false,

        D_Documentare: false,
        SP_Documentare: false,
        S_Documentare: false,
        M_Documentare: false,

        D_Implementare: true,
        SP_Implementare: true,
        S_Implementare: true,
        M_Implementare: true,

        D_Mentenanta: false,
        SP_Mentenanta: false,
        S_Mentenanta: false,
        M_Mentenanta: false,

        D_Upgrade: false,
        SP_Upgrade: false,
        S_Upgrade: false,
        M_Upgrade: false,
    },
];

export const AlocareSarciniRealizari = [
    {
        Salariat: "Alexandru",
        MomentInceput: "2020-04-13T09:05:43+03:00",
        MomentSfarsit: "2020-04-13T09:05:43+03:00",
        ProcentRealizare: 55,
        Asumat: true,
        Client: "Client 3",
        Comentariu: "un comentariu",
    },
    {
        Salariat: "Ciprian",
        MomentInceput: "2020-04-13T09:05:43+03:00",
        MomentSfarsit: "2020-04-13T09:05:43+03:00",
        ProcentRealizare: 70,
        Asumat: false,
        Client: "Client 3",
        Comentariu: "un comentariu",
    },
];

export const AlocareSarciniGantt = [
    {
        Caption: "testare modificari",
        Finish: "2020-04-13T09:05:43+03:00",
        Start: "2020-04-13T09:05:43+03:00",
        Reminder: false,
        AllDayEvent: false,
        Enabled: false,
    },
];

export const VizualizareGenerala = [
    {
        Salariat: "Alexandru",
        MomentInceput: "2020-04-13T09:05:43+03:00",
        MomentSfarsit: "2020-04-13T09:05:43+03:00",
        TimpLucratInOre: 5,
        Asumat: true,
        Functionalitate: "numele functionalitatii",
        Stadiu: "deschis",
        ProcentRealizare: 40,
        Comentariu: "un comentariu",
    },
    {
        Salariat: "Ciprian",
        MomentInceput: "2020-04-13T09:05:43+03:00",
        MomentSfarsit: "2020-04-13T09:05:43+03:00",
        TimpLucratInOre: 10,
        Asumat: true,
        Functionalitate: "numele functionalitatii",
        Stadiu: "inchis",
        ProcentRealizare: 80,
        Comentariu: "un comentariu",
    },
];

//PLANIFICARI

export const PlanificareSalariat = [
    { Nume: "Ciprian", Departament: 0, Profesie: "Programator" },
];

export const PlanificareProiect = [
    {
        ProiectParinte: 0,
        Denumire: "Denumire proiect",
        Start: "2020-04-13T09:05:43+03:00",
        Deadline: "2020-04-13T09:05:43+03:00",
        TimpEstimatOre: 2,
        TimpNeplanificatOre: 1,
    },
];

export const StadiuProiecte = [
    {
        name: "Procent lucrat",
        uv: 31.47,
        fill: "#8884d8",
    },
    {
        name: "Procent inchis",
        uv: 26.69,
        fill: "#83a6ed",
    },
];

export const ProiecteRealizariSalariati = [
    {
        Nume: 1,
        Departament: 1,
        Profesie: 1,
        childs: [
            { Nume: 1, Departament: 1, Profesie: 1 },
            { Nume: 2, Departament: 1, Profesie: 1 },
        ],
    },
    { Nume: 2, Departament: 1, Profesie: 1 },
    {
        Nume: 3,
        Departament: 1,
        Profesie: 1,
        childs: [
            { Nume: 1, Departament: 1, Profesie: 1 },
            { Nume: 2, Departament: 1, Profesie: 1 },
        ],
    },
];

export const Rapoarte = [
    {
        Denumire: "Raport 1",
        Creat: "2020-04-13T09:05:43+03:00",
        Modificat: "2020-04-13T09:05:43+03:00",
    },
    {
        Denumire: "Raport 2",
        Creat: "2020-04-13T09:05:43+03:00",
        Modificat: "2020-04-13T09:05:43+03:00",
    },
    {
        Denumire: "Raport 3",
        Creat: "2020-04-13T09:05:43+03:00",
        Modificat: "2020-04-13T09:05:43+03:00",
    },
];

export const IntretinereDepartamente = [
    { Denumire: "Management", Ordine: 1 },
    { Denumire: "Contabilitate", Ordine: 2 },
    { Denumire: "Comercial", Ordine: 3 },
    { Denumire: "Juridic", Ordine: 4 },
    {
        Denumire: "Juridic",
        Ordine: 4,
        childs: [
            { Denumire: "Management", Ordine: 5 },
            { Denumire: "Contabilitate", Ordine: 6 },
            { Denumire: "Comercial", Ordine: 7 },
            { Denumire: "Juridic", Ordine: 8 },
        ],
    },
];

export const OperatiuniClienti = [
    { Denumire: "Altele", Comentariu: "", PeTask: false },
    { Denumire: "Altele 2", Comentariu: "", PeTask: false },
    { Denumire: "Altele 3", Comentariu: "", PeTask: false },
    { Denumire: "Altele 4", Comentariu: "", PeTask: false },
    {
        Denumire: "Altele 5",
        Comentariu: "",
        PeTask: false,
        childs: [
            { Denumire: "Altele 6", Comentariu: "", PeTask: false },
            { Denumire: "Altele 7", Comentariu: "", PeTask: false },
            { Denumire: "Altele 8", Comentariu: "", PeTask: false },
            { Denumire: "Altele 9", Comentariu: "", PeTask: false },
            { Denumire: "Altele 10", Comentariu: "", PeTask: false },
        ],
    },
];

export const Facturare = [
    {
        Proiect: 2,
        DenumireTask: "schimbare zona",
        Facturat: false,
        Salariat: "Ciprian",
        Operatie: "",
        TimpLucratInOre: 237,
        TarifOrar: 0,
        Suma: 0,
        DataCreare: "2020-04-13T09:05:43+03:00",
        DataAlocare: "2020-04-13T09:05:43+03:00",
        DataFinalizare: "2020-04-13T09:05:43+03:00",
        Durata: 240,
    },
    {
        Proiect: 5,
        DenumireTask: "Preluare din v2 in v3",
        Facturat: false,
        Salariat: "Alexandru",
        Operatie: "Upgrade",
        TimpLucratInOre: 136,
        TarifOrar: 0,
        Suma: 0,
        DataCreare: "2020-04-13T09:05:43+03:00",
        DataAlocare: "2020-04-13T09:05:43+03:00",
        DataFinalizare: "2020-04-13T09:05:43+03:00",
        Durata: 140,
    },
    {
        Proiect: 10,
        DenumireTask: "Studiu configurare hardware",
        Facturat: false,
        Salariat: "Tibi",
        Operatie: "Documentatie produs",
        TimpLucratInOre: 4,
        TarifOrar: 0,
        Suma: 0,
        DataCreare: "2020-04-13T09:05:43+03:00",
        DataAlocare: "2020-04-13T09:05:43+03:00",
        DataFinalizare: "2020-04-13T09:05:43+03:00",
        Durata: 4,
    },
];

export const activitateZilnica = [
    {
        Salariat: "Alexandru",
        Intrare: "9:36",
        Iesire: "10:5",
        Eveniment: "LN",
    },
    {
        Salariat: "Alexandru",
        Intrare: "10:55",
        Iesire: "11:1",
        Eveniment: "LN",
    },
    {
        Salariat: "Alexandru",
        Intrare: "13:45",
        Iesire: "14:55",
        Eveniment: "LN",
    },
    {
        Salariat: "Ciprian",
        Intrare: "8:14",
        Iesire: "11:55",
        Eveniment: "LN",
    },
    {
        Salariat: "Ciprian",
        Intrare: "13:15",
        Iesire: "17:1",
        Eveniment: "LN",
    },
];

export const cheltuieliFaze = [
    {
        Faza: "fezabilitate",
        CostTotal: 5621.9125,
    },
    {
        Faza: "juridic",
        CostTotal: 5621.9125,
    },
    {
        Faza: "analiza",
        CostTotal: 1900626.0,
    },
    {
        Faza: "proiectare",
        CostTotal: 205902449,
    },
    {
        Faza: "codare",
        CostTotal: 203375421,
    },
];

export const activitateOreLucrate = [
    {
        Nume: "Alexandru",
        Ziua: "2020-04-13T09:05:43+03:00",
        Timp: 7.78,
        TimpPontat: 7.78,
        ComSef: 0,
    },
    {
        Nume: "Ciprian",
        Ziua: "2020-04-13T09:05:43+03:00",
        Timp: 5.42,
        TimpPontat: 5.42,
        ComSef: 0,
    },
    {
        Nume: "Tibi",
        Ziua: "2020-04-13T09:05:43+03:00",
        Timp: 8.62,
        TimpPontat: 8.62,
        ComSef: 0,
    },
];

export const salariatiCheltuieli = [
    {
        Salariat: "Alexandru",
        Timp_ore: 55.5,
        Salariu_ora: 14,
        CheltuieliDirecte: 777,
        CheltuieliIndirecte: 0,
        CheltuieliInd: 0,
        Total: 777,
    },
    {
        Salariat: "Ciprian",
        Timp_ore: 55.5,
        Salariu_ora: 14,
        CheltuieliDirecte: 777,
        CheltuieliIndirecte: 0,
        CheltuieliInd: 0,
        Total: 777,
    },
    {
        Salariat: "Tibi",
        Timp_ore: 55.5,
        Salariu_ora: 14,
        CheltuieliDirecte: 777,
        CheltuieliIndirecte: 0,
        CheltuieliInd: 0,
        Total: 777,
    },
];

export const salariatiVenituri = [
    {
        NumeSalariat: "Alexandru",
        AniPrecedenti: 0,
        AnCurent: 0,
        LunaCurenta: 0,
    },
    {
        NumeSalariat: "Ciprian",
        AniPrecedenti: 0,
        AnCurent: 0,
        LunaCurenta: 0,
    },
    {
        NumeSalariat: "Tibi",
        AniPrecedenti: 0,
        AnCurent: 0,
        LunaCurenta: 0,
    },
];

export const logCurente = [
    {
        NumeLogin: "nicolae",
        Computer: "WKS03",
        UtilizatorComp: "nicolae",
        IP: "192.168.1.23",
        DataIntrare: "2020-04-13T09:05:43+03:00",
        DataIesire: "2020-04-13T09:05:43+03:00",
    },
    {
        NumeLogin: "tion53",
        Computer: "WKS10",
        UtilizatorComp: "tion53",
        IP: "192.168.1.10",
        DataIntrare: "2020-04-13T09:05:43+03:00",
        DataIesire: "2020-04-13T09:05:43+03:00",
    },
    {
        NumeLogin: "blidia",
        Computer: "WKS08",
        UtilizatorComp: "blidia",
        IP: "192.168.1.5",
        DataIntrare: "2020-04-13T09:05:43+03:00",
        DataIesire: "2020-04-13T09:05:43+03:00",
    },
    {
        NumeLogin: "ioana",
        Computer: "WKS09",
        UtilizatorComp: "Ioana",
        IP: "192.168.1.9",
        DataIntrare: "2020-04-13T09:05:43+03:00",
        DataIesire: "2020-04-13T09:05:43+03:00",
    },
];

export const IntretinereClienti = [
    {
        Denumire: "Client 1",
        Email: "em@gmail.com",
        Adresa: "",
        Telefon: "0700000000",
        PersoanaContact: "",
        childs: [
            {
                Denumire: "Client 11",
                Email: "em@gmail.com",
                Adresa: "",
                Telefon: "0700000000",
                PersoanaContact: "",
            },
            {
                Denumire: "Client 12",
                Email: "em@gmail.com",
                Adresa: "",
                Telefon: "0700000000",
                PersoanaContact: "",
            },
            {
                Denumire: "Client 13",
                Email: "em@gmail.com",
                Adresa: "",
                Telefon: "0700000000",
                PersoanaContact: "",
            },
            {
                Denumire: "Client 14",
                Email: "em@gmail.com",
                Adresa: "",
                Telefon: "0700000000",
                PersoanaContact: "",
            },
        ],
    },
    {
        Denumire: "Client 2",
        Email: "em@gmail.com",
        Adresa: "",
        Telefon: "0700000000",
        PersoanaContact: "",
    },
    {
        Denumire: "Client 3",
        Email: "em@gmail.com",
        Adresa: "",
        Telefon: "0700000000",
        PersoanaContact: "",
    },
    {
        Denumire: "Client 4",
        Email: "em@gmail.com",
        Adresa: "",
        Telefon: "0700000000",
        PersoanaContact: "",
    },
];

export const bugeteActivitate = [
    {
        Nume: "Alexandru",
        BugetTotal: 3241,
        CheltuieliTotale: 320386.95,
        BugetProiecte: 0,
        CheltuieliProiecte: 136.3,
    },
    {
        Nume: "Ciprian",
        BugetTotal: 200,
        CheltuieliTotale: 237989.9,
        BugetProiecte: 0,
        CheltuieliProiecte: 236165.25,
    },
    {
        Nume: "Tibi",
        BugetTotal: 0,
        CheltuieliTotale: 258385.2,
        BugetProiecte: 0,
        CheltuieliProiecte: 0,
    },
];

export const repartitieSalariati = [
    {
        Salariat: "Alexandru",
        Ziua: "",
        TimpPontaj: "0:0",
        TimpZi: "0:0",
        DataStart: "",
        Deadline: "",
        Asumat: false,
        Proiect: "",
        Stadiu: "",
        IntrarePeProiect: "",
        IesireDePeProiect: "",
        ComentariuRealizare: "",
        Departament: "",
        childs: [
            {
                Salariat: "",
                Ziua: "22.07.2019",
                TimpPontaj: "0:0",
                TimpZi: "0:0",
                DataStart: "",
                Deadline: "",
                Asumat: false,
                Proiect: "",
                Stadiu: "",
                IntrarePeProiect: "",
                IesireDePeProiect: "",
                ComentariuRealizare: "",
                Departament: "",
            },
            {
                Salariat: "",
                Ziua: "20.07.2019",
                TimpPontaj: "0:0",
                TimpZi: "0:0",
                DataStart: "",
                Deadline: "",
                Asumat: false,
                Proiect: "",
                Stadiu: "",
                IntrarePeProiect: "",
                IesireDePeProiect: "",
                ComentariuRealizare: "",
                Departament: "",
            },
            {
                Salariat: "",
                Ziua: "25.07.2019",
                TimpPontaj: "0:0",
                TimpZi: "0:0",
                DataStart: "",
                Deadline: "",
                Asumat: false,
                Proiect: "",
                Stadiu: "",
                IntrarePeProiect: "",
                IesireDePeProiect: "",
                ComentariuRealizare: "",
                Departament: "",
            },
            {
                Salariat: "",
                Ziua: "15.07.2019",
                TimpPontaj: "0:0",
                TimpZi: "0:0",
                DataStart: "",
                Deadline: "",
                Asumat: false,
                Proiect: "",
                Stadiu: "",
                IntrarePeProiect: "",
                IesireDePeProiect: "",
                ComentariuRealizare: "",
                Departament: "",
            },
        ],
    },
    {
        Salariat: "Ciprian",
        Ziua: "",
        TimpPontaj: "0:0",
        TimpZi: "0:0",
        DataStart: "",
        Deadline: "",
        Asumat: false,
        Proiect: "",
        Stadiu: "",
        IntrarePeProiect: "",
        IesireDePeProiect: "",
        ComentariuRealizare: "",
        Departament: "",
        childs: [
            {
                Salariat: "",
                Ziua: "19.07.2019",
                TimpPontaj: "0:0",
                TimpZi: "0:0",
                DataStart: "",
                Deadline: "",
                Asumat: false,
                Proiect: "",
                Stadiu: "",
                IntrarePeProiect: "",
                IesireDePeProiect: "",
                ComentariuRealizare: "",
                Departament: "",
                childs: [
                    {
                        Salariat: "",
                        Ziua: "",
                        TimpPontaj: "",
                        TimpZi: "",
                        DataStart: "2020-04-13T09:05:43+03:00",
                        Deadline: "2020-04-13T09:05:43+03:00",
                        Asumat: true,
                        Proiect: "LICITATII",
                        Stadiu: "evaluare",
                        IntrarePeProiect: "2020-04-13T09:05:43+03:00",
                        IesireDePeProiect: "2020-04-13T09:05:43+03:00",
                        ComentariuRealizare: "comentariu",
                        Departament: "departament",
                    },
                ],
            },
            {
                Salariat: "",
                Ziua: "22.07.2019",
                TimpPontaj: "0:0",
                TimpZi: "0:0",
                DataStart: "",
                Deadline: "",
                Asumat: false,
                Proiect: "",
                Stadiu: "",
                IntrarePeProiect: "",
                IesireDePeProiect: "",
                ComentariuRealizare: "",
                Departament: "",
                childs: [
                    {
                        Salariat: "",
                        Ziua: "",
                        TimpPontaj: "",
                        TimpZi: "",
                        DataStart: "2020-04-13T09:05:43+03:00",
                        Deadline: "2020-04-13T09:05:43+03:00",
                        Asumat: true,
                        Proiect: "ALTE LICITATII",
                        Stadiu: "evaluare",
                        IntrarePeProiect: "2020-04-13T09:05:43+03:00",
                        IesireDePeProiect: "2020-04-13T09:05:43+03:00",
                        ComentariuRealizare: "comentariu",
                        Departament: "departament",
                    },
                ],
            },
            {
                Salariat: "",
                Ziua: "23.07.2019",
                TimpPontaj: "0:0",
                TimpZi: "0:0",
                DataStart: "",
                Deadline: "",
                Asumat: false,
                Proiect: "",
                Stadiu: "",
                IntrarePeProiect: "",
                IesireDePeProiect: "",
                ComentariuRealizare: "",
                Departament: "",
            },
            {
                Salariat: "",
                Ziua: "20.07.2019",
                TimpPontaj: "0:0",
                TimpZi: "0:0",
                DataStart: "",
                Deadline: "",
                Asumat: false,
                Proiect: "",
                Stadiu: "",
                IntrarePeProiect: "",
                IesireDePeProiect: "",
                ComentariuRealizare: "",
                Departament: "",
            },
        ],
    },
    {
        Salariat: "Tibi",
        Ziua: "",
        TimpPontaj: "0:0",
        TimpZi: "0:0",
        DataStart: "",
        Deadline: "",
        Asumat: false,
        Proiect: "",
        Stadiu: "",
        IntrarePeProiect: "",
        IesireDePeProiect: "",
        ComentariuRealizare: "",
        Departament: "",
        childs: [
            {
                Salariat: "",
                Ziua: "19.07.2019",
                TimpPontaj: "0:0",
                TimpZi: "0:0",
                DataStart: "",
                Deadline: "",
                Asumat: false,
                Proiect: "",
                Stadiu: "",
                IntrarePeProiect: "",
                IesireDePeProiect: "",
                ComentariuRealizare: "",
                Departament: "",
                childs: [
                    {
                        Salariat: "",
                        Ziua: "",
                        TimpPontaj: "",
                        TimpZi: "",
                        DataStart: "2020-04-13T09:05:43+03:00",
                        Deadline: "2020-04-13T09:05:43+03:00",
                        Asumat: true,
                        Proiect: "LICITATII",
                        Stadiu: "evaluare",
                        IntrarePeProiect: "2020-04-13T09:05:43+03:00",
                        IesireDePeProiect: "2020-04-13T09:05:43+03:00",
                        ComentariuRealizare: "comentariu",
                        Departament: "departament",
                    },
                ],
            },
            {
                Salariat: "",
                Ziua: "22.07.2019",
                TimpPontaj: "0:0",
                TimpZi: "0:0",
                DataStart: "",
                Deadline: "",
                Asumat: false,
                Proiect: "",
                Stadiu: "",
                IntrarePeProiect: "",
                IesireDePeProiect: "",
                ComentariuRealizare: "",
                Departament: "",
                childs: [
                    {
                        Salariat: "",
                        Ziua: "",
                        TimpPontaj: "",
                        TimpZi: "",
                        DataStart: "2020-04-13T09:05:43+03:00",
                        Deadline: "2020-04-13T09:05:43+03:00",
                        Asumat: true,
                        Proiect: "ALTE LICITATII",
                        Stadiu: "evaluare",
                        IntrarePeProiect: "2020-04-13T09:05:43+03:00",
                        IesireDePeProiect: "2020-04-13T09:05:43+03:00",
                        ComentariuRealizare: "comentariu",
                        Departament: "departament",
                    },
                ],
            },
            {
                Salariat: "",
                Ziua: "23.07.2019",
                TimpPontaj: "0:0",
                TimpZi: "0:0",
                DataStart: "",
                Deadline: "",
                Asumat: false,
                Proiect: "",
                Stadiu: "",
                IntrarePeProiect: "",
                IesireDePeProiect: "",
                ComentariuRealizare: "",
                Departament: "",
            },
            {
                Salariat: "",
                Ziua: "20.07.2019",
                TimpPontaj: "0:0",
                TimpZi: "0:0",
                DataStart: "",
                Deadline: "",
                Asumat: false,
                Proiect: "",
                Stadiu: "",
                IntrarePeProiect: "",
                IesireDePeProiect: "",
                ComentariuRealizare: "",
                Departament: "",
            },
        ],
    },
];

export const tasks = [{
    'id': 1,
    'parentId': 0,
    'title': 'Software Development',
    'start': new Date('2019-02-21T05:00:00.000Z'),
    'end': new Date('2019-07-04T12:00:00.000Z'),
    'progress': 31
}, {
    'id': 2,
    'parentId': 1,
    'title': 'Scope',
    'start': new Date('2019-02-21T05:00:00.000Z'),
    'end': new Date('2019-02-26T09:00:00.000Z'),
    'progress': 60
}, {
    'id': 3,
    'parentId': 2,
    'title': 'Determine project scope',
    'start': new Date('2019-02-21T05:00:00.000Z'),
    'end': new Date('2019-02-21T09:00:00.000Z'),
    'progress': 100
}, {
    'id': 4,
    'parentId': 2,
    'title': 'Secure project sponsorship',
    'start': new Date('2019-02-21T10:00:00.000Z'),
    'end': new Date('2019-02-22T09:00:00.000Z'),
    'progress': 100
}, {
    'id': 5,
    'parentId': 2,
    'title': 'Define preliminary resources',
    'start': new Date('2019-02-22T10:00:00.000Z'),
    'end': new Date('2019-02-25T09:00:00.000Z'),
    'progress': 60
}, {
    'id': 6,
    'parentId': 2,
    'title': 'Secure core resources',
    'start': new Date('2019-02-25T10:00:00.000Z'),
    'end': new Date('2019-02-26T09:00:00.000Z'),
    'progress': 0
}, {
    'id': 7,
    'parentId': 2,
    'title': 'Scope complete',
    'start': new Date('2019-02-26T09:00:00.000Z'),
    'end': new Date('2019-02-26T09:00:00.000Z'),
    'progress': 0
}, {
    'id': 8,
    'parentId': 1,
    'title': 'Analysis/Software Requirements',
    'start': new Date('2019-02-26T10:00:00.000Z'),
    'end': new Date('2019-03-18T09:00:00.000Z'),
    'progress': 80
}, {
    'id': 9,
    'parentId': 8,
    'title': 'Conduct needs analysis',
    'start': new Date('2019-02-26T10:00:00.000Z'),
    'end': new Date('2019-03-05T09:00:00.000Z'),
    'progress': 100
}, {
    'id': 10,
    'parentId': 8,
    'title': 'Draft preliminary software specifications',
    'start': new Date('2019-03-05T10:00:00.000Z'),
    'end': new Date('2019-03-08T09:00:00.000Z'),
    'progress': 100
}, {
    'id': 11,
    'parentId': 8,
    'title': 'Develop preliminary budget',
    'start': new Date('2019-03-08T10:00:00.000Z'),
    'end': new Date('2019-03-12T09:00:00.000Z'),
    'progress': 100
}, {
    'id': 12,
    'parentId': 8,
    'title': 'Review software specifications/budget with team',
    'start': new Date('2019-03-12T10:00:00.000Z'),
    'end': new Date('2019-03-12T14:00:00.000Z'),
    'progress': 100
}, {
    'id': 13,
    'parentId': 8,
    'title': 'Incorporate feedback on software specifications',
    'start': new Date('2019-03-13T05:00:00.000Z'),
    'end': new Date('2019-03-13T14:00:00.000Z'),
    'progress': 70
}, {
    'id': 14,
    'parentId': 8,
    'title': 'Develop delivery timeline',
    'start': new Date('2019-03-14T05:00:00.000Z'),
    'end': new Date('2019-03-14T14:00:00.000Z'),
    'progress': 0
}, {
    'id': 15,
    'parentId': 8,
    'title': 'Obtain approvals to proceed (concept, timeline, budget)',
    'start': new Date('2019-03-15T05:00:00.000Z'),
    'end': new Date('2019-03-15T09:00:00.000Z'),
    'progress': 0
}];