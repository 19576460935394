import React from "react";
import axios from "axios";
import moment from "moment";
import {
  List,
  ListInput,
  Icon,
  ListItem,
  Row,
  Col,
  Button,
} from "framework7-react";
import DataGrid from "../Datagrid/DataGrid";

/**
 * @description Interfata de generare a procesului verbal
 * @date 08/04/2022
 * @class PVMain
 * @extends {React.PureComponent}
 */
class PVMain extends React.PureComponent {
  constructor(props) {
    super(props);

    this.Salariati = this.$f7.data.toggleTotiSalariatii
      ? this.$f7.data.Salariati
      : this.$f7.data.SalariatiActivi;

    this.listaFirme = [
      { key: "ATS", value: 1 },
      { key: "ECOSOFT", value: 2 },
    ];
    this.state = {
      linkApiPV: "/api/ProceseVerbale",
      linkApiPVDetalii: "/api/ProceseVerbaleDetalii",
      listaContracte: [{ key: "Fara", value: null }],
      listaContracteFull: [],
      dataPV: moment().format(), //
      dataFactura: moment().format(), //
      idSalariat: this.$f7.data.user.iD_SALARIAT, //
      numarPV: null, //
      reprezentantClient: "", //
      idFirma: 1, //
      numarFactura: null, //
      validat: false, //
      caleDocument: "", //
      idContract: null, //
      listaModuleSelectate: [], //
      listaModule: [{ key: "Fara", value: null }], //
      height: parseInt((window.innerHeight - 144) / 2),
      dataLoaded: false,
      denumire: "PVMain",
      dataSource: [],
      dataSourceInitial: [],
      inputs: [
        { key: "Id", keyBaza: "idRealizare", tip: "number" },
        {
          key: "Proiect",
          keyBaza: "idProiect",
          tip: "selectbox",
          lista: this.$f7.data.Proiecte,
        },
        { key: "Cerinta", keyBaza: "cerinta" },
        {
          key: "Salariat",
          keyBaza: "idSalariat",
          tip: "selectbox",
          lista: this.$f7.data.Salariati,
          listaEdit: this.Salariati,
        },
        { key: "MinuteLucrate", keyBaza: "minuteLucrate", tip: "number" },
        { key: "MinuteFacturate", keyBaza: "minuteLucrat", tip: "number" },
        { key: "Suma", keyBaza: "suma", tip: "number" },
        { key: "Comentariu", keyBaza: "comentariu" },
        { key: "ComentariuPlus", keyBaza: "comentariuPlus" },
        { key: "ComentariuPV", keyBaza: "comentariuPV" },
        {
          key: "Operatiune",
          keyBaza: "operatiune",
          tip: "selectbox",
          lista: [{ key: "Fara", value: null }],
        },
        {
          key: "TipOperatiune",
          keyBaza: "idTipOperatiune",
          tip: "selectbox",
          lista: this.$f7.data.Operatiuni,
        },
        {
          key: "ProiectRef",
          keyBaza: "refProiect",
          tip: "selectbox",
          lista: this.$f7.data.Proiecte,
        },
        {
          key: "F",
          keyBaza: "numarProceseVerbale",
          tip: "bool",
          disabled: true,
        },
        { key: "Intrare", keyBaza: "intrare", tip: "datebox" },
        { key: "Iesire", keyBaza: "iesire", tip: "datebox" },
        { key: "IsArhivat", keyBaza: "arhivat", tip: "bool", disabled: true },
      ],
    };
    this.refDatagrid = React.createRef();
  }

  /**
   * @description Ia lista contractelor pentru clientul selectat
   * @date 08/04/2022
   * @function
   * @param {*} idClient id-ul clientului
   * @memberof PVMain
   */
  GetListaContracte = (idClient) => {
    axios
      .get("./api/ContracteNoi/GetcontracteClient/" + idClient)
      .then((response) => {
        var date = response.data;
        var arr = [{ key: "Fara", value: null }];
        date.map((contract) => {
          arr.push({
            key: contract.nr_contract + "/" + contract.data_contract,
            value: contract.id_contract,
          });
          return null;
        });
        this.setState({ listaContracte: arr, listaContracteFull: date });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /**
   * @description Sterge toate stilizarile elementelor din datagrid si ataseaza clasa de arhivat daca este arhivat
   * @function
   * @date 08/04/2022
   * @param {*} state stateul datagridului
   * @param {*} r contextul datagridului
   * @param {*} itemeRandate itemele randate de datagrid
   * @param {*} coloanaSalariat denumirea coloanei de salariat pentru a colora in functie de salariat
   * @param {*} onInitialised true/false daca se apeleaza functia doar la initializarea datagridului
   * @memberof PVMain
   */
  colorItems = (state, r, itemeRandate, coloanaSalariat, onInitialised) => {
    var tableName = r.tableName;
    if (tableName) {
      itemeRandate.forEach((elem, index) => {
        var tar = document.getElementById("trMain" + tableName + index);
        if (tar) {
          tar.classList.remove("taskDeadlineDepasit");
          tar.classList.remove("taskactiv");
          tar.classList.remove("taskoprit");
          tar.classList.remove("taskinchis");
          tar.classList.remove("taskpropus");
          tar.classList.remove("ascuns");
          tar.classList.remove("arhivat");
          if (elem.IsArhivat) {
            tar.classList.add("arhivat");
          }
        }
      });
    }
    this.$f7.methods.colorSalariat(coloanaSalariat);
  };

  /**
   * @description Adauga o noua inregistrare in baza de date de tip PV si deschide un tab nou in browser sa afiseze noul proces verbal
   * @function
   * @date 08/04/2022
   * @param {*} data informatiile noului proces verbal
   * @memberof PVMain
   */
  AddValuePV = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApiPV;
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        var idProcesVerbalAdaugat = response.data.idProceseVerbale;
        var stateGrid = this.refDatagrid.current.state;
        var selectedRows = stateGrid.selectedRows;
        var adaugateDeja = [];
        selectedRows.map((d) => {
          var listaSalariatProiectCurent = selectedRows.filter((r) => {
            return r.Proiect === d.Proiect && r.Salariat === d.Salariat;
          });
          var existaPV =
            listaSalariatProiectCurent.find((r) => {
              return r.Comentariu && r.Comentariu.length > 0;
            }) !== undefined;
          var comentariu = existaPV ? d.ComentariuPV : d.Comentariu;
          var dataPVDetalii = {
            refProceseVerbale: idProcesVerbalAdaugat,
            id_proiect: d.Proiect,
            denumire: this.$f7.data.allProiecte.filter((r) => {
              return r.iD_PROIECT === d.Proiect;
            })[0].denumire,
            oreLucrate:
              d.MinuteFacturate !== 0 ? d.MinuteFacturate : d.MinuteLucrate,
            comentariu: comentariu,
            complus: d.ComentariuPlus,
            id_salariat: d.Salariat,
            id_realizare: d.Id,
            numesal: this.$f7.data.Salariati.filter((r) => {
              return r.value === d.Salariat;
            })[0].key,
            comentariu_p: d.ComentariuPlus,
            tip_operatie: d.TipOperatiune,
            suma: d.Suma,
            operatiune:
              this.state.inputs[10].lista.filter((re) => {
                return re.value === d.Operatiune;
              })[0].key === "Fara"
                ? ""
                : this.state.inputs[10].lista.filter((re) => {
                    return re.value === d.Operatiune;
                  })[0].key,
            id_operatiune: d.Operatiune,
          };
          adaugateDeja.push(dataPVDetalii);
          this.AddValuePVDetalii(dataPVDetalii);
          return null;
        });
        this.$f7.methods.openNotification("Adaugat cu success");
        var url = "";
        var urlEcosoft =
          "http://dev03:8888/result?report=43&id=" + idProcesVerbalAdaugat;
        var urlATS =
          "http://dev03:8888/result?report=73&id=" + idProcesVerbalAdaugat;
        if (this.state.idFirma === 1) {
          url = urlATS;
        } else {
          url = urlEcosoft;
        }
        setTimeout(() => {
          window.open(url, "_blank");
        }, 1000);
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Adauga in baza de date o inregistrare de tip detaliu proces verbal
   * @function
   * @date 08/04/2022
   * @param {*} data obiect cu detaliile inregistrarii
   * @memberof PVMain
   */
  AddValuePVDetalii = (data) => {
    var url =
      this.$f7.data.baseURL +
      this.state.linkApiPVDetalii +
      "/PostProceseVerbaleDetalii";
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {})
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Filtreaza modulele in functie de contractul selectat
   * @date 08/04/2022
   * @param {*} val id-ul clientului
   * @memberof PVMain
   */
  selectContractNew = (val) => {
    if (val !== null && val !== undefined && val !== "Fara") {
      var idFirmaNoua = this.state.idFirma;
      var urlModuleContractClient =
        "./api/SarciniSalariat/GetModuleContractClient/" + val;
      var c = this.state.listaContracteFull.filter((r) => {
        return r.id_contract.toString() === val.toString();
      })[0];
      idFirmaNoua = c !== undefined ? c.id_angajator : 1;
      axios
        .get(urlModuleContractClient)
        .then((responseModuleContractClient) => {
          //MODULE
          var listaModule = [{ key: "Fara", value: null }];
          var dateModule = responseModuleContractClient.data;
          dateModule.map((r) => {
            listaModule.push({ key: r.denumire, value: r.idContracteModule });
            return null;
          });
          this.setState({
            listaModuleSelectate: [],
            listaModule: listaModule,
            idContract: val,
            idFirma: idFirmaNoua,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  /**
   * @description Ia toate realizarile facute in intervalul selectat pentru clientul selectat
   * @function
   * @date 08/04/2022
   * @memberof PVMain
   */
  GetValues = () => {
    this.setState({ dataLoaded: false }, () => {
      var data = {
        idClient: this.props.idClient,
        dataStart: moment(this.props.dataStart).format(),
        dataSfarsit: moment(this.props.dataSfarsit).format(),
      };
      var url = "./api/SarciniSalariat/GetListaDetaliiRapoarteProiectPerioada";
      axios({
        data: data,
        headers: { "Content-Type": "application/json" },
        method: "POST",
        url: url,
      })
        .then((response) => {
          var date = response;
          date.data.map((eveniment) => {
            eveniment = this.$f7.methods.restructurareBaza(
              eveniment,
              this.state.inputs,
              "key"
            );
            eveniment.TipOperatiune = null;
            eveniment.Operatiune = null;
            eveniment.MinuteFacturate = 0;
            eveniment.F = eveniment.F > 0 ? true : false;
            return null;
          });

          var baza = this.$f7.methods.reconstructieBaza(
            date,
            this.state.inputs
          );

          this.setState(
            {
              dataSource: baza,
              dataSourceInitial: baza,
            },
            () => {
              setTimeout(() => {
                this.setState(
                  {
                    dataLoaded: true,
                  },
                  () => {
                    this.refDatagrid.current.setRenderItems(baza);
                    this.GetListaContracte(this.props.idClient);
                  }
                );
              }, 400);
            }
          );
        })
        .catch((error) => {
          console.log("error", error);
        });
    });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  /**
   * @description se apeleaza pentru a filtra datagridul in functie de modulul selectat
   * @date 08/04/2022
   * @function
   * @memberof PVMain
   */
  filterDataSource = () => {
    var filteredDataSource = [];
    if (
      this.state.listaModuleSelectate.includes("Fara") ||
      this.state.listaModuleSelectate.length === 0
    ) {
      filteredDataSource = [...this.state.dataSourceInitial];
    } else {
      filteredDataSource = this.state.dataSourceInitial.filter((r) => {
        return this.state.listaModuleSelectate.includes(
          r.ProiectRef.toString()
        );
      });
    }
    this.setState({ dataSource: filteredDataSource });
  };

  render() {
    return (
      <>
        <List
          inset
          form
          onSubmit={(e) => {
            e.preventDefault();
            var stateGrid = this.refDatagrid.current.state;
            var contract = { numar: null, data: null, refContracte: null };
            if (this.state.idContract !== null) {
              console.log(this.state.idContract, this.state.listaContracteFull);
              var c = this.state.listaContracteFull.find((r) => {
                return (
                  r.id_contract.toString() === this.state.idContract.toString()
                );
              });
              if (c) {
                contract = {
                  numar: c.nr_contract,
                  data: moment(c.data_contract).format(),
                  refContracte: c.id_contract,
                };
              }
            }
            var dataPV = {
              id_salariat: this.state.idSalariat,
              dataPV: this.state.dataPV,
              dataOperare: moment().format(),
              numarPV: this.state.numarPV,
              id_client: this.props.idClient,
              numarContract: contract.nr_contract,
              dataContract: contract.data,
              refContracte: contract.refContracte,
              id_angajator: this.state.idFirma,
              validat: this.state.validat,
              cale_document: this.state.caleDocument,
              numar_fact:
                this.state.numarFactura !== null
                  ? this.state.numarFactura
                  : null,
              data_fact:
                this.state.numarFactura !== null
                  ? this.state.dataFactura
                  : null,
              reprez_client:
                this.state.reprezentantClient !== ""
                  ? this.state.reprezentantClient
                  : null,
              listat: false,
              aplicatia: this.state.listaModuleSelectate
                .filter((r) => {
                  return r !== "Fara";
                })
                .join(","),
            };
            if (stateGrid.selectedRows.length > 0) {
              this.$f7.dialog.confirm(
                "Sunteti sigur ca doriti sa creati procesul verbal?",
                "Atentie",
                () => {
                  this.AddValuePV(dataPV);
                }
              );
            } else {
              this.$f7.methods.openNotification(
                "Trebuie selectata din datagrid o intrare."
              );
            }
          }}>
          <Row>
            <Col>
              <ListInput
                floatingLabel
                label={"Data PV"}
                validate={true}
                required={true}
                type="datepicker"
                placeholder={"Data PV"}
                value={[this.state.dataPV]}
                calendarParams={{
                  closeOnSelect: true,
                  on: {
                    closed: (e) => {
                      var val = moment(e.value[0]).format();
                      this.setState({ dataPV: val });
                    },
                  },
                }}>
                <Icon
                  slot="content-start"
                  className={"datagridIcon"}
                  size={20}
                  material="today"
                />
              </ListInput>
            </Col>
            <Col>
              <ListInput
                floatingLabel
                label="Numar PV"
                validate={true}
                required={true}
                type="text"
                placeholder="NumarPV"
                defaultValue={this.state.numarPV}
                onChange={(e) => {
                  var val = e.currentTarget.value;
                  this.setState({ numarPV: val });
                }}>
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="list"
                  slot="content-start"
                />
              </ListInput>
            </Col>
          </Row>

          <Row>
            <Col>
              <ListItem
                className="roundedOutlinedSmartSelect"
                title="Firma"
                value={this.state.idFirma}
                label="Firma"
                validate={true}
                required={true}
                smartSelect
                smartSelectParams={{
                  virtualList: true,
                  searchbar: true,
                  openIn: "popup",
                  popupTabletFullscreen: true,
                  popupPush: true,
                  closeOnSelect: true,
                  on: {
                    closed: (event) => {
                      this.setState({
                        idFirmat: parseInt(event.getValue()),
                      });
                    },
                  },
                }}>
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="list"
                  slot="content-start"
                />
                <select name="Salariat">
                  {this.listaFirme.map((obj, index) => (
                    <option
                      selected={
                        this.state.idFirma !== null &&
                        this.state.idFirma === obj.value
                      }
                      label={obj.key}
                      key={"listaFirmePVMain-" + index}
                      value={obj.value}>
                      {obj.key}
                    </option>
                  ))}
                </select>
              </ListItem>
            </Col>
            <Col>
              <ListItem
                className="roundedOutlinedSmartSelect"
                title="Salariat"
                value={this.state.idSalariat}
                label="Salariat"
                validate={true}
                required={true}
                smartSelect
                smartSelectParams={{
                  virtualList: true,
                  searchbar: true,
                  openIn: "popup",
                  popupTabletFullscreen: true,
                  popupPush: true,
                  closeOnSelect: true,
                  on: {
                    closed: (event) => {
                      this.setState({
                        idSalariat: parseInt(event.getValue()),
                      });
                    },
                  },
                }}>
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="list"
                  slot="content-start"
                />
                <select name="Salariat">
                  {this.Salariati.map((obj, index) => (
                    <option
                      selected={this.state.idSalariat === obj.value}
                      label={obj.key}
                      key={"listaSalariatiPVMain-" + index}
                      value={obj.value}>
                      {obj.key}
                    </option>
                  ))}
                </select>
              </ListItem>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListInput
                floatingLabel
                label={"Data Factura"}
                type="datepicker"
                placeholder={"Data Factura"}
                value={[this.state.dataFactura]}
                calendarParams={{
                  closeOnSelect: true,
                  on: {
                    closed: (e) => {
                      var val = moment(e.value[0]).format();
                      this.setState({ dataFactura: val });
                    },
                  },
                }}>
                <Icon
                  slot="content-start"
                  className={"datagridIcon"}
                  size={20}
                  material="today"
                />
              </ListInput>
            </Col>
            <Col>
              {" "}
              <ListInput
                floatingLabel
                label="Numar Factura"
                inputmode="text"
                type="text"
                placeholder="Numar Factura"
                value={this.state.numarFactura}
                onInput={(e) => {
                  var val = e.target.value;
                  this.setState({ numarFactura: val });
                }}>
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="list"
                  slot="content-start"
                />
              </ListInput>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListItem
                className="roundedOutlinedSmartSelect"
                title="Contract"
                value={this.state.idContract}
                label="Contract"
                validate={true}
                required={true}
                smartSelect
                smartSelectParams={{
                  virtualList: true,
                  searchbar: true,
                  openIn: "popup",
                  popupTabletFullscreen: true,
                  popupPush: true,
                  closeOnSelect: true,
                  on: {
                    closed: (event) => {
                      var val = event.getValue();
                      console.log("val", val);
                      this.selectContractNew(val);
                    },
                  },
                }}>
                <Icon
                  color={"var(--f7-theme-color)"}
                  size={20}
                  material="list"
                  slot="content-start"
                />
                <select name="Contract">
                  {this.state.listaContracte.map((obj, index) => (
                    <option
                      selected={
                        obj.value === null || obj.key === "Fara"
                          ? this.state.idContract === null
                            ? true
                            : this.state.idContract.toString() === "Fara"
                          : this.state.idContract === null
                          ? false
                          : this.state.idContract.toString() ===
                            obj.value.toString()
                      }
                      label={obj.key}
                      key={"listaContractePVMain-" + index}
                      value={obj.value}>
                      {obj.key}
                    </option>
                  ))}
                </select>
              </ListItem>
            </Col>
            <Col>
              <ListInput
                floatingLabel
                label={"Reprezentant client"}
                inputmode="text"
                type="text"
                placeholder={"Reprezentant client"}
                value={this.state.reprezentantClient}
                onInput={(e) => {
                  var val = e.target.value;
                  this.setState({ reprezentantClient: val });
                }}>
                <Icon
                  slot="content-start"
                  className={"datagridIcon"}
                  size={20}
                  material="create"
                />
              </ListInput>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListInput
                floatingLabel
                label={"Cale document"}
                inputmode="text"
                type="text"
                placeholder={"Cale document"}
                value={this.state.caleDocument}
                onInput={(e) => {
                  var val = e.target.value;
                  this.setState({ caleDocument: val });
                }}>
                <Icon
                  slot="content-start"
                  className={"datagridIcon"}
                  size={20}
                  material="create"
                />
              </ListInput>
            </Col>
            <Col>
              <ListItem
                onChange={(e) => {
                  var checked = e.currentTarget.checked;
                  this.setState({ validat: checked });
                }}
                checkbox
                checked={this.state.validat}>
                Validat
              </ListItem>
            </Col>
          </Row>

          <ListItem
            className="roundedOutlinedSmartSelect"
            title="Module"
            value={this.state.listaModuleSelectate}
            label="Module"
            validate={false}
            required={false}
            smartSelect
            smartSelectParams={{
              virtualList: true,
              searchbar: true,
              openIn: "popup",
              popupTabletFullscreen: true,
              popupPush: true,
              closeOnSelect: true,
              on: {
                closed: (event) => {
                  var listaModuleSelectate = event.getValue();
                  console.log("lista", listaModuleSelectate);
                  this.setState(
                    { listaModuleSelectate: listaModuleSelectate },
                    () => {
                      this.filterDataSource();
                    }
                  );
                },
              },
            }}>
            <Icon
              color={"var(--f7-theme-color)"}
              size={20}
              material="list"
              slot="content-start"
            />
            <select multiple name="Module">
              {this.state.listaModule.map((obj, index) => (
                <option
                  selected={
                    obj.value === null || obj.value === "Fara"
                      ? this.state.listaModuleSelectate.includes("Fara")
                      : this.state.listaModuleSelectate.includes(
                          obj.value.toString()
                        )
                  }
                  label={obj.key}
                  key={"listaModulePVMain-" + index}
                  value={obj.value}>
                  {obj.key}
                </option>
              ))}
            </select>
          </ListItem>

          <ListItem>
            <DataGrid
              refresh={this.GetValues}
              dataLoaded={this.state.dataLoaded}
              name={"PVMain"}
              id={"Id"}
              renderCheckbox={true}
              renderUpdate={true}
              ref={this.refDatagrid}
              ignoreInputsEdit={["Proiect", "Salariat", "MinuteLucrate"]}
              maxHeight={this.state.height}
              dataSource={this.state.dataSource}
              inputs={this.state.inputs}
              onUpdate={() => {}}
              onInitialised={(state, r, itemeRandate) => {
                this.colorItems(state, r, itemeRandate, "Salariat", 1);
              }}
              onRenderItemsChanged={(state, r, itemeRandate) => {
                this.colorItems(state, r, itemeRandate, "Salariat", 0);
              }}
            />
          </ListItem>

          <Button
            style={{ width: 150, margin: "0 auto" }}
            tooltip={"Salveaza"}
            type="submit"
            small
            fill
            bgColor="teal">
            Salveaza
          </Button>
        </List>
      </>
    );
  }
}
export default PVMain;
