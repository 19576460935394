import React from "react";
import DataGrid from "../../Datagrid/DataGrid";
import * as inputs from "../../../../inputs";
import axios from "axios";

/**
 * @description Interfata de intretinere a fazelor unui proeict
 * @date 07/04/2022
 * @class Faze
 * @extends {React.Component}
 */
class Faze extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      linkApi: "/api/TipFaze",
      denumire: "Faze",
      height: window.innerHeight - 34,
      dataSource: [],
    };
    this.refDataGrid = React.createRef();
  }

  /**
   * @description Functie de structurare a bazei de date,ataseaza o culoare bazei de culori
   * @function
   * @date 07/04/2022
   * @param {*} baza baza care va fi parcursa pentru structurare
   * @memberof Faze
   */
  StructurareBaza = (baza) => {
    var bazaCuCulori = [];
    baza.map((objBaza) => {
      var obj = objBaza;
      obj.Culoare = this.$f7.methods.intToHex(obj.Culoare);
      bazaCuCulori.push(obj);
      return false;
    });
    return bazaCuCulori;
  };

  /**
   * @description Ia din baza de date valorile necesare randarii(Fazele)
   * @function
   * @date 07/04/2022
   * @memberof Faze
   */
  GetValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApi)
      .then((response) => {
        var baza = this.$f7.methods.reconstructieBaza(
          response,
          inputs.IntretinereFaze
        );
        var bazaCuCulori = this.StructurareBaza(baza);

        this.setState(
          {
            dataSource: bazaCuCulori,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  this.refDataGrid.current.setRenderItems(bazaCuCulori);
                }
              );
            }, 400);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  /**
   * @description Adauga in baza de date o inregistrare noua pentru curenta interfata de intretinere
   * @date 07/04/2022
   * @param {*} data obiect cu informatiile noii inregistrari de va fi adaugata
   * @function
   * @memberof Faze
   */
  AddValue = (data) => {
    var url = this.$f7.data.baseURL + this.state.linkApi;
    axios({
      data: data,
      headers: { "Content-Type": "application/json" },
      method: "post",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Adaugat cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la adaugare");
      });
  };

  /**
   * @description Actualizeaza datele uni valori din baza de date legate de interfata curenta de intretinere
   * @function
   * @date 07/04/2022
   * @param {*} obj obiect cu noile valori ale inregistrarii
   * @param {*} id id-ul inregistarii selectate
   * @memberof Faze
   */
  UpdateValue = (obj, id) => {
    axios
      .get(this.$f7.data.baseURL + this.state.linkApi + "/" + id)
      .then((response) => {
        var obj2 = response.data;
        Object.keys(obj).map((trasatura) => {
          Object.keys(obj2).map((trasatura2) => {
            if (trasatura.toLowerCase() === trasatura2.toLowerCase()) {
              obj2[trasatura2] = obj[trasatura];
            }
            return false;
          });
          return false;
        });

        var url = this.$f7.data.baseURL + this.state.linkApi + "/" + id;
        axios({
          data: obj2,
          headers: { "Content-Type": "application/json" },
          method: "put",
          url: url,
        })
          .then((response) => {
            this.GetValues();
            this.$f7.methods.openNotification("Modificat cu success");
          })
          .catch((error) => {
            console.log("error", error);
            this.$f7.methods.openNotification("Eroare la modificare");
          });
      });
  };

  /**
   * @description Sterge din baza de date inregistrarea selectata
   * @date 07/04/2022
   * @param {*} id id-ul inregistarii selectate
   * @memberof Faze
   */
  DeleteValue = (id) => {
    var url = this.$f7.data.baseURL + this.state.linkApi + "/" + id;
    axios({
      headers: { "Content-Type": "application/json" },
      method: "delete",
      url: url,
    })
      .then((response) => {
        this.GetValues();
        this.$f7.methods.openNotification("Sters cu success");
      })
      .catch((error) => {
        console.log("error", error);
        this.$f7.methods.openNotification("Eroare la stergere");
      });
  };

  componentDidMount = () => {
    this.GetValues();
  };

  render() {
    return (
      <DataGrid
        dataLoaded={this.state.dataLoaded}
        refresh={this.GetValues}
        name={"Faze"}
        ref={this.refDataGrid}
        renderUpdate={true}
        renderDelete={true}
        id="ID_Faza"
        onAdd={(state) => {
          var data = { ...state.editedItem };
          data.Ref_Actiune = 1;
          delete data.ID_Faza;
          data.Culoare = this.$f7.methods.hexToInt(data.Culoare);
          this.AddValue(data);
        }}
        onDelete={(state) => {
          var data = { ...state.editedItem };
          this.DeleteValue(data.ID_Faza);
        }}
        onUpdate={(state) => {
          var data = { ...state.editedItem };
          data.Ref_Actiune = 1;
          data.Culoare = this.$f7.methods.hexToInt(data.Culoare);
          this.UpdateValue(data, data.ID_Faza);
        }}
        dataSource={this.state.dataSource}
        renderAdd={true}
        maxHeight={this.state.height}
        inputs={inputs.IntretinereFaze}
      />
    );
  }
}
export default Faze;
