import React, { Component } from "react";
import { List, ListItem, Preloader, Icon } from "framework7-react";
import * as inputs from "../../../../inputs";
import DataGrid from "../../Datagrid/DataGrid";
import axios from "axios";

/**
 * @description Afiseaza un datagrid cu informatiile legate repartitia taskurilor la fiecare salariat per proiect
 * @date 06/04/2022
 * @class SalariatiProiecte
 * @extends {Component}
 */
class SalariatiProiecte extends Component {
  constructor(props) {
    super(props);

    this.height = window.innerHeight;

    this.state = {
      dataLoaded: false,
      dataGridLoaded: false,
      denumire: "SalariatiProiecte",
      height: window.innerHeight - 44,
      linkApi: "/api/SalariatiProiecte",
      proiect: null,
      dataSourceProiecte: [],
      dataSource: [],
    };

    this.refDataGrid = React.createRef();
  }
  /**
   * @description ia toate proiectele ce pot fi selectate de la endpoint-ul „this.$f7.data.baseURL + this.state.linkApi”
   * @function
   * @date 06/04/2022
   * @memberof SalariatiProiecte
   */
  GetComboBoxValues = () => {
    this.setState({
      dataLoaded: false,
    });

    axios
      .get(this.$f7.data.baseURL + this.state.linkApi)
      .then((response) => {
        let proiecte = [];

        response.data.map((item, index) => {
          let obj = {};

          obj["key"] = item.denumire;
          obj["value"] = item.id_proiect;

          proiecte.push(obj);
          return null;
        });

        this.setState(
          {
            proiect: proiecte[0].value,
            dataSourceProiecte: proiecte,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataLoaded: true,
                },
                () => {
                  this.GetValues();
                }
              );
            }, 200);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataLoaded: true,
            dataGridLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetComboBoxValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };
  /**
   * @description ia valorile necesare pentur randarea datagridului de la endpointul „this.$f7.data.baseURL + this.state.linkApi + "/" + this.state.proiect „ si primeste ca parametru proiectul selectat.
   * @date 06/04/2022
   * @function
   * @memberof SalariatiProiecte
   */
  GetValues = () => {
    this.setState({
      dataGridLoaded: false,
    });

    axios
      .get(
        this.$f7.data.baseURL + this.state.linkApi + "/" + this.state.proiect
      )
      .then((response) => {
        let change = response;

        for (let item in change.data) {
          change.data[item]["IdSalariat"] =
            change.data[item].id_salariat === "" ||
            change.data[item].id_salariat === null
              ? "Fara"
              : change.data[item].id_salariat;
          change.data[item]["IdDepartament"] =
            change.data[item].id_departament === "" ||
            change.data[item].id_departament === null
              ? "Fara"
              : change.data[item].id_departament;
          change.data[item]["DepSalariatProiect"] =
            change.data[item].proiect === "" ||
            change.data[item].proiect === null
              ? "Fara"
              : change.data[item].proiect;
          change.data[item]["Procent"] =
            change.data[item].procent === null
              ? change.data[item].procent
              : change.data[item].procent + " %";
          change.data[item]["Intarziere"] =
            change.data[item].intarziere === null
              ? change.data[item].intarziere
              : change.data[item].intarziere + " %";
          change.data[item]["TimpLucrat"] =
            change.data[item].timp_lucrat === "" ||
            change.data[item].timp_lucrat === null
              ? "Fara"
              : change.data[item].timp_lucrat;
          change.data[item]["TimpPlanificat"] =
            change.data[item].chelt_est === "" ||
            change.data[item].chelt_est === null
              ? "Fara"
              : change.data[item].chelt_est;
          change.data[item]["Stadiu"] =
            change.data[item].stadiu === "" || change.data[item].stadiu === null
              ? "Fara"
              : change.data[item].stadiu;
        }

        let baza = this.$f7.methods.reconstructieBaza(
          change,
          inputs.ActivitateSalariatiProiecte
        );

        this.setState(
          {
            dataSource: baza.length === 0 ? [] : baza,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  dataGridLoaded: true,
                },
                () => {
                  this.refDataGrid.current.setRenderItems(baza);
                }
              );
            }, 200);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            dataSource: [],
            dataGridLoaded: true,
          },
          () => {
            this.$f7.dialog.confirm(
              "A aparut o eroare la preluarea datelor de pe server. Incearca din nou.",
              "Ups...",
              () => {
                this.GetValues();
              },
              () => {}
            );
          }
        );

        console.log("error", error);
      });
  };

  componentDidMount = () => {
    this.GetComboBoxValues();
  };

  render() {
    return (
      <div style={{ position: "relative" }}>
        {this.state.dataLoaded ? (
          <List
            noChevron
            noHairlines
            noHairlinesBetween
            noHairlinesBetweenMd
            noHairlinesMd
            className="optiuniActivitate butoaneContextDatagrid"
            style={{
              position: "absolute",
              top: -8,
              left: 0,
              width: "calc(100% - 176px)",
              margin: 0,
            }}>
            <ListItem
              key="SmartSelectSalariatProiecte"
              className="roundedOutlinedSmartSelect"
              value={this.state.proiect}
              floatingLabel
              label={"Proiect"}
              title={"Proiect"}
              validate
              smartSelect
              smartSelectParams={{
                virtualList: true,
                searchbar: true,
                openIn: "popup",
                popupTabletFullscreen: true,
                popupPush: true,
                closeOnSelect: true,
                on: {
                  closed: (event) => {
                    this.setState(
                      {
                        proiect: parseInt(event.getValue()),
                      },
                      () => {
                        this.GetValues();
                      }
                    );
                  },
                },
              }}>
              <select
                defaultValue={this.state.proiect}
                name="SelectBoxProiecte">
                {this.state.dataSourceProiecte.map((obj, index) => (
                  <option
                    key={"proiecteSalariatiProiecte-" + index}
                    label={obj.key}
                    value={obj.value}>
                    {obj.key}
                  </option>
                ))}
              </select>
              <Icon
                slot="content-start"
                style={{ color: "var(--f7-theme-color)", left: 0, height: 14 }}
                size={20}
                material="list"
              />
            </ListItem>
          </List>
        ) : (
          <div
            style={{
              position: "absolute",
              width: "35px",
              height: "35px",
              top: "50vh",
              left: "50%",
            }}>
            <Preloader size={40} color={"var(--f7-theme-color)"} />
          </div>
        )}
        <DataGrid
          dataLoaded={this.state.dataGridLoaded}
          name={"SalariatiProiecte"}
          ref={this.refDataGrid}
          renderUpdate={false}
          renderDelete={false}
          renderAdd={false}
          maxHeight={this.state.height}
          dataSource={this.state.dataSource}
          inputs={inputs.ActivitateSalariatiProiecte}
        />
      </div>
    );
  }
}
export default SalariatiProiecte;