import React, { Component } from "react";
import { Button, Icon } from "framework7-react";
import Peer from "simple-peer";
import VideoStreamMerger from "video-stream-merger";
import { saveAs } from "file-saver";

class MainCall extends Component {
	constructor(props) {
		super(props);

		this.state = {
			rConnection: this.$f7.data.signalRConnection,
			callerSignal: this.props.callerSignal,
			myStream: null,
			userStream: null,
			myPeer: null,
			noUserVideo: false,
			micOff: false,
			spkOff: false,
			videoOff:
				this.props.audioOnly !== undefined && this.props.audioOnly
					? true
					: false,
			record: false,
			mediaRecorder: null,
			mediaStream: null,
			oldTrack: null,
			shareScreen: false,
			onlyStop: false,
		};

		this.myVideo = React.createRef();
		this.userVideo = React.createRef();
	}

	componentDidMount = () => {
		navigator.mediaDevices
			.getUserMedia({
				video: {
					width: { max: 1280, ideal: 640 },
					height: { max: 720, ideal: 360 },
				},
				audio: true,
			})
			.then((stream) => {
				this.$f7.data.oneToOneCall = this;

				if (this.props.audioOnly) {
					const tracks = stream.getTracks();
					tracks.forEach((track) => {
						if (track.kind === "video") track.enabled = false;
					});
				}

				this.setState(
					{
						myStream: stream,
					},
					() => {
						console.log("myStream la apel:", this.state.myStream.getTracks());
						this.myVideo.current.srcObject = this.state.myStream;

						if (this.props.acceptCall) {
							this.answerCall(this.props.connectionId);
						}

						if (this.props.call) {
							this.callUser(this.props.userId);
						}
					}
				);
			});
	};

	componentWillUnmount = () => {
		if (this.state.myStream) {
			this.state.myStream.getTracks().forEach((track) => {
				//if (track.readyState === 'live') {
				track.stop();
				//}
			});
		}

		this.state.rConnection.off("AnswerCall");
		this.state.rConnection.off("CallUserOffline");

		if (this.state.myPeer) this.state.myPeer.destroy();

		this.$f7.data.oneToOneCall = null;
	};

	callUser = (userId) => {
		const peer = new Peer({
			initiator: true,
			trickle: false,
			stream: this.state.myStream,
		});

		peer.on("signal", async (data) => {
			if (this.state.rConnection.state === "Connected") {
				await this.state.rConnection.send("CallUser", userId, data);
			}
		});

		peer.on("stream", (stream) => {
			let noVideo = true;
			stream.getTracks().forEach((track) => {
				if (track.kind === "video") noVideo = false;
			});

			this.setState(
				{
					userStream: stream,
					noUserVideo: noVideo,
				},
				() => {
					console.log("userStream la call:", this.state.userStream.getTracks());
					this.userVideo.current.srcObject = this.state.userStream;
				}
			);
		});

		this.state.rConnection.on("AnswerCall", (action, user, signal) => {
			if (action) {
				peer.signal(signal);
			} else {
				this.$f7.methods.openNotification("Apel respins!", undefined, "eroare");
				this.$f7.popup.close(".PopupCall");
			}
		});

		this.state.rConnection.on("CallUserOffline", (offline) => {
			console.log("CallUserOffline:", offline);
			this.$f7.popup.close(".PopupCall");
			this.$f7.methods.openNotification(
				"Utilizatorul este offline!",
				undefined,
				"eroare"
			);
		});
		console.log("p", peer);

		peer.on("data", (data) => {
			const d = JSON.parse(data);
			console.log(d);

			if (typeof d === "object") {
				if (d.video !== undefined) {
					this.setState({
						noUserVideo: !d.video,
					});
				}
			}
		});

		this.setState({
			myPeer: peer,
		});
	};

	answerCall = (connectionId) => {
		const peer = new Peer({
			initiator: false,
			trickle: false,
			stream: this.state.myStream,
		});

		peer.on("signal", async (data) => {
			if (this.state.rConnection.state === "Connected") {
				await this.state.rConnection.send(
					"AcceptCall",
					connectionId,
					true,
					data
				);
			}
		});

		peer.on("stream", (stream) => {
			this.setState(
				{
					userStream: stream,
				},
				() => {
					this.userVideo.current.srcObject = this.state.userStream;
				}
			);
		});

		peer.signal(this.state.callerSignal);
		console.log("p", peer);

		peer.on("data", (data) => {
			const d = JSON.parse(data);
			console.log(d);

			if (typeof d === "object") {
				if (d.video !== undefined) {
					this.setState({
						noUserVideo: !d.video,
					});
				}
			}
		});

		this.setState(
			{
				myPeer: peer,
			},
			() => {
				if (this.props.audioOnly)
					setTimeout(() => {
						peer.send(JSON.stringify({ video: false }));
					}, 1500);
			}
		);
	};

	recorderStop = (notEnd) => {
		this.setState(
			{
				onlyStop: notEnd,
			},
			() => {
				if (this.state.record) {
					if (this.state.mediaRecorder) this.state.mediaRecorder.stop();
				} else {
					const popup = this.$f7.popup.get(".PopupCall");
					if (popup && popup.opened) {
						this.$f7.popup.close(".PopupCall");
					}
				}
			}
		);
	};

	render() {
		return (
			<>
				<div
					style={{
						position: "relative",
						width: "100%",
						height: "100%",
						overflow: "hidden",
						backgroundColor: "rgb(64 64 64)",
					}}
				>
					{this.state.noUserVideo ? (
						<Icon
							color={"white"}
							size={100}
							material={"videocam_off"}
							style={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								zIndex: 10,
							}}
						/>
					) : null}

					<video
						style={{
							transform: "scaleX(-1)",
							visibility: this.state.noUserVideo ? "hidden" : "visible",
						}}
						ref={this.userVideo}
						autoPlay
						playsInline
						width="100%"
						height="100%"
					/>

					<div
						className="singleMyVideo"
						style={{
							position: "absolute",
							right: 15,
							bottom: 15,
							width: 302,
							height: 170,
							overflow: "hidden",
							filter: "drop-shadow(2px 4px 6px black)",
							backgroundColor: "rgb(64 64 64)",
						}}
					>
						{this.state.videoOff ? (
							<Icon
								color={"white"}
								size={50}
								material={"videocam_off"}
								style={{
									position: "absolute",
									top: "50%",
									left: "50%",
									transform: "translate(-50%, -50%)",
									zIndex: 10,
								}}
							/>
						) : null}

						<video
							style={{
								transform: "scaleX(-1)",
								visibility: this.state.videoOff ? "hidden" : "visible",
							}}
							muted
							ref={this.myVideo}
							autoPlay
							playsInline
							width="100%"
							height="100%"
						/>

						<div
							className="singleCallButtonsWrapper"
							style={{
								position: "absolute",
								bottom: 0,
								left: 0,
								right: 0,
								display: "flex",
								justifyContent: "space-around",
								flexDirection: "row",
								paddingTop: 3,
								transform: "translateY(100%)",
								transition: "0.35s all ease-in-out",
								backgroundColor: "rgba(0, 0, 0, 0.3)",
								zIndex: 15,
							}}
						>
							<Button
								disabled={
									this.state.myStream === null || this.state.userStream === null
								}
								tooltip={
									this.state.record
										? "Opreste inregistrarea"
										: "Inregistreaza conversatia"
								}
								onClick={() => {
									if (!this.state.record) {
										if (this.state.myStream && this.state.userStream) {
											console.log("myStream:", this.state.myStream.getTracks());
											console.log(
												"userStream:",
												this.state.userStream.getTracks()
											);

											let merger = new VideoStreamMerger({
												width: 1366,
												height: 768,
											});

											merger.addStream(this.state.userStream, {
												x: 0,
												y: 0,
												width: merger.width,
												height: merger.height,
												mute: false,
											});

											merger.addStream(this.state.myStream, {
												x: merger.width - 263,
												y: merger.height - 155,
												width: 248,
												height: 140,
												mute: false,
											});

											merger.start();
											let mediaRecorder = new MediaRecorder(merger.result);

											this.setState(
												{
													record: true,
													mediaRecorder: mediaRecorder,
												},
												() => {
													mediaRecorder.start(10000);

													let chunks = [];
													mediaRecorder.ondataavailable = (e) => {
														chunks.push(e.data);
													};

													mediaRecorder.onstop = (e) => {
														const clipName = prompt("Numele inregistrarii");
														const blob = new Blob(chunks, {
															type: "video/webm;codecs=vp9",
														});
														saveAs(blob, `${clipName}.webm`);
														chunks = [];

														this.setState(
															{
																record: false,
																mediaRecorder: null,
															},
															() => {
																mediaRecorder = null;
																merger.destroy();

																if (!this.state.onlyStop) {
																	const popup =
																		this.$f7.popup.get(".PopupCall");
																	if (popup && popup.opened) {
																		this.$f7.popup.close(".PopupCall");
																	}
																}
															}
														);
													};
												}
											);
										}
									} else {
										//if (this.state.mediaRecorder) this.state.mediaRecorder.stop()
										this.recorderStop(true);
									}
								}}
							>
								<Icon
									color={"red"}
									size={25}
									material={
										this.state.record ? "stop_circle" : "radio_button_checked"
									}
								/>
							</Button>

							<Button
								disabled={this.state.myPeer === null}
								tooltip={
									this.state.micOff
										? "Partajeaza ecranul"
										: "Opreste partajarea"
								}
								onClick={() => {
									if (this.state.myPeer) {
										if (!this.state.shareScreen) {
											navigator.mediaDevices
												.getDisplayMedia({
													video: { cursor: "always" },
													audio: false,
												})
												.then((mediaStream) => {
													this.setState(
														{
															mediaStream: mediaStream,
															shareScreen: true,
														},
														() => {
															const oldTrack =
																this.state.myStream.getVideoTracks()[0];

															this.setState(
																{
																	oldTrack: oldTrack,
																},
																() => {
																	this.state.myPeer.replaceTrack(
																		oldTrack,
																		this.state.mediaStream.getVideoTracks()[0],
																		this.state.myStream
																	);
																	console.log("myStream:", this.state.myStream);
																}
															);

															//this.state.myPeer.addTrack(this.state.mediaStream.getVideoTracks()[0], this.state.myStream)
															//this.state.myPeer.addStream(this.state.mediaStream)
														}
													);
												});
										} else {
											this.state.myPeer.replaceTrack(
												this.state.myStream.getVideoTracks()[0],
												this.state.oldTrack,
												this.state.myStream
											);

											this.setState(
												{
													oldTrack: null,
													shareScreen: false,
												},
												() => {
													if (this.state.mediaStream) {
														this.state.mediaStream
															.getTracks()
															.forEach((track) => {
																if (track.readyState === "live") {
																	track.stop();
																}
															});

														this.setState({
															mediaStream: null,
														});
													}
												}
											);

											//this.state.myPeer.removeTrack(this.state.mediaStream.getVideoTracks()[0], this.state.myStream)
											//this.state.myPeer.removeStream(this.state.mediaStream)
										}
									}
								}}
							>
								<Icon
									color={this.state.shareScreen ? "red" : "white"}
									size={25}
									material={"cast"}
								/>
							</Button>

							<Button
								tooltip={
									this.state.micOff
										? "Porneste microfonul"
										: "Opreste microfonul"
								}
								onClick={() => {
									if (this.state.myStream) {
										console.log("myStream:", this.state.myStream.getTracks());

										this.setState(
											{
												micOff: !this.state.micOff,
											},
											() => {
												this.state.myStream.getTracks().forEach((track) => {
													if (track.kind === "audio")
														track.enabled = !this.state.micOff;
												});
											}
										);
									}
								}}
							>
								<Icon
									color={this.state.micOff ? "red" : "white"}
									size={25}
									material={this.state.micOff ? "mic_off" : "mic"}
								/>
							</Button>

							<Button
								tooltip={this.state.spkOff ? "Porneste sunet" : "Opreste sunet"}
								onClick={() => {
									this.setState(
										{
											spkOff: !this.state.spkOff,
										},
										() => {
											this.userVideo.current.muted = this.state.spkOff;
										}
									);
								}}
							>
								<Icon
									color={this.state.spkOff ? "red" : "white"}
									size={25}
									material={this.state.spkOff ? "volume_off" : "volume_up"}
								/>
							</Button>

							<Button
								tooltip={
									this.state.videoOff ? "Porneste camera" : "Opreste camera"
								}
								onClick={() => {
									this.setState(
										{
											videoOff: !this.state.videoOff,
										},
										() => {
											const tracks = this.state.myStream.getTracks();
											tracks.forEach((track) => {
												if (track.kind === "video")
													track.enabled = !this.state.videoOff;
											});
											this.state.myPeer.send(
												JSON.stringify({ video: !this.state.videoOff })
											);
										}
									);
								}}
							>
								<Icon
									color={this.state.videoOff ? "red" : "white"}
									size={25}
									material={this.state.videoOff ? "videocam_off" : "videocam"}
								/>
							</Button>

							<Button
								tooltip={"Termina apelul"}
								onClick={() => {
									this.recorderStop(false);
									// const popup = this.$f7.popup.get(".PopupCall");
									// if (popup && popup.opened) {
									//     this.$f7.popup.close(".PopupCall");
									// }
								}}
							>
								<Icon color={"white"} size={25} material={"call_end"} />
							</Button>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default MainCall;